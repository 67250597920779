<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_expense_master')">
            <div class="col-12 page_title paddingnone">
                <div class="page_title_left">
                    <span>{{ allLabelMessages.expense }}</span>
                    <p v-if="totalRecords > 0">{{ totalRecords }} {{ allLabelMessages.results_found }}</p>
                </div>
            </div>    
            <filter-component
                :key="filterCount"
                :filterCounts="filterCount"
                :parentHeaders="headers"
                @applyFilterParentComponent="applyFilterParentComponent"
                @resetSorting="resetSorting"
            ></filter-component>
            <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                <button v-show="permissionNameArray.includes('can_delete_expense_master') && selectedDeleteRows.length >= 1" type="button" v-on:click.prevent="deleteAssetWistExpenses()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedDeleteRows.length}} Record(s)</button>
                <button v-show="permissionNameArray.includes('can_export_invoice_expense_master')" id="exportExpenseData" type="button" v-on:click.prevent="exportExpenseData()" class="btn btn-primary waves-effect pull-right ml-2">Export Invoice {{selectedRows.length}} Record(s)</button>
                <button v-show="permissionNameArray.includes('can_expense_include_expense_master') && !isTransferPO" id="changeExpenseStatus" type="button" v-on:click.prevent="addAmountStatus()" class="btn btn-primary waves-effect pull-right ml-2">Expense Include Status For {{selectedRows.length}} Record(s)</button>
            </div>
            <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable"

            <common-loader
              refId="commonLoaderListAssetExpenseTab"
              ref="commonLoaderListAssetExpenseTab"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
                <div class="row">
                    <div class="col-sm-12 col-md-2 PerPage">
                        <v-select title="Select Per Page"
                                  v-model="perPage"
                                  @input="changePerPage"
                                  name="customPerPage"
                                  id="customPerPage"
                                  label="text"
                                  :clearable="false"
                                  :searchable="false"
                                  :filterable="false"
                                  :options="paginationDropDownArr"
                                  placeholder="Select Per Page">
                        </v-select>
                    </div>
                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                        <p class="pageRowCount">
                            {{startEndRecord}} of {{totalRecords}}
                        </p>
                    </div>

                    <!-- v-if="totalRecords >= perPage" -->
                    <div class="col-sm-12 col-md-6 Pagination">
                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <p class="pageJumpLabel">Page : </p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
            <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                <table class="table custom-table jsmartable">
                    <tbody>
                    <template v-if="assetWiseExpensesArr && assetWiseExpensesArr.length > 0">
                        <tr v-for="(assetexpenses, index) in assetWiseExpensesArr" :key="index">
                            <td class="">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" v-model="selectedRows" :value="assetexpenses.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + assetexpenses.id" name="case[]">
                                           <label class="custom-control-label" :for="'customCheck' + assetexpenses.id"></label>
                                </div>
                            </td>
                            <td>
                                <div class="actions" style="width: 142px;">
                                    <a v-if="assetexpenses.rebill_contact_id != ''" href="javascript:void(0)" title='RPO' class="exceptEdit rpo mar-right-0">
                                        RPO
                                    </a>
                                    <template v-if="assetexpenses.from_excede == '0'">
                                        <a v-if="assetexpenses.group_mapper_uniq_id != '' && assetexpenses.group_mapper_uniq_id != null" href="javascript:void(0)" title='Expenses In Group' class="mar-right-0">
                                            <i class="exceptEdit fa fa-check-circle" aria-hidden="true"></i>
                                            <!--<i class="exceptEdit fa fa-bookmark" aria-hidden="true"></i>-->
                                            <!--<i class="exceptEdit fa fa-circle" aria-hidden="true"></i>-->
                                            <!--<i class="exceptEdit fa fa-circle-o" aria-hidden="true"></i>-->
                                        </a>
                                    </template>
                                    <template v-if="assetexpenses.from_excede == '0'">
                                        <a v-show="permissionNameArray.includes('can_add_expense_master')" class="mar-right-0" title='Edit Expense' v-on:click="editAssetWiseExpense(assetexpenses.id)">
                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                        </a>
                                        <a v-show="permissionNameArray.includes('can_delete_expense_master')" href="javascript:void(0)" title='Delete Asset Expenses' class="mar-right-0" v-on:click.prevent="deleteAssetWistExpenses(assetexpenses.id)">
                                            <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                        </a>
                                    </template>
                                    <a 
                                        v-show="permissionNameArray.includes('can_export_invoice_expense_master')"
                                        href="javascript:void(0)" 
                                        title='Export Invoice' 
                                        class="mar-right-0" 
                                        v-on:click="exportExpenseData(assetexpenses.id)">
                                        <i class="exceptEdit fa fa-file-text-o" aria-hidden="true"></i>
                                    </a>
                                    <a title='Excede PO' v-if="assetexpenses.from_excede === 1" href="javascript:void(0)" class="mar-right-0"> 
                                        <i  class="exceptEdit fa fa-etsy color-71aa3f" aria-hidden="true"></i>
                                    </a>
                                    
                                    <template v-if="assetexpenses.expense_category !== 'Transfer PO'">
                                        <template v-if="permissionNameArray.includes('can_expense_include_expense_master')">
                                            <template v-if="assetexpenses.exclude_from_total === 1">
                                                <a title='Exclude From Total'  href="javascript:void(0)" class="mar-right-0" v-on:click.prevent="addAmountStatus(assetexpenses.id)"> 
                                                    <i  class="exceptEdit fa fa-calculator color-c3202b" aria-hidden="true"></i>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a title='Include In Total'  href="javascript:void(0)" class="mar-right-0" v-on:click.prevent="addAmountStatus(assetexpenses.id)"> 
                                                    <i  class="exceptEdit fa fa-calculator color-71aa3f" aria-hidden="true"></i>
                                                </a>
                                            </template>
                                        </template>
                                    </template>   

                                    <a title='Purchase Price' v-if="assetexpenses.is_purchase == 'Yes'" href="javascript:void(0)" class="mar-right-0"> 
                                        <i class="exceptEdit fa fa-circle color-0771b4" aria-hidden="true"></i>
                                    </a>
                                    <a v-else 
                                        href="javascript:void(0)" 
                                        title='Mark Purchase Price'
                                        class="mar-right-0"
                                        v-on:click="markIsPurchase(assetexpenses.id)">
                                        <i class="exceptEdit fa fa-check color-098de1" aria-hidden="true"></i>
                                    </a>

                                    <a v-if="assetexpenses.manually_edit != 0"
                                        title="Manually Edited"
                                        href="javascript:void(0)"
                                        class="mar-right-0">
                                        <i class="exceptEdit fa fa-pencil-square color-c3202b"
                                            aria-hidden="true"
                                        ></i>
                                    </a>
                                </div>
                            </td>
                            <td :class="rowClass.stock">{{assetexpenses.stock}}</td>
                            <td :class="rowClass.vinNo">{{assetexpenses.vinNo}}</td>
                            <td :class="rowClass.vinNo">{{assetexpenses.vendor}}</td>
                            <td :class="rowClass.excede_po">{{assetexpenses.excede_po}}</td>
                            <td :class="rowClass.invoice_id">{{assetexpenses.invoice_id}}</td>
                            <td :class="rowClass.expense_category">{{assetexpenses.expense_category}}</td>
                            <td :class="rowClass.summary">{{assetexpenses.summary}}</td>
                            <td :class="rowClass.reference_no">{{assetexpenses.reference_no}}</td>
                            <td :class="rowClass.estimated_cost">{{assetexpenses.estimated_cost}}</td>
                            <td :class="rowClass.actual_cost">{{assetexpenses.actual_cost}}</td>
                            <td :class="rowClass.entered">{{assetexpenses.entered}}</td>
                            <td :class="rowClass.paid_date">{{assetexpenses.paid_date}}</td>
                            <!--<td :class="rowClass.rebill_contact">{{assetexpenses.rebill_contact}}</td>
                            <td :class="rowClass.rebill_paid_date">{{assetexpenses.rebill_paid_date}}</td>-->
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                {{allStaticMessages.data_not_available}}
                            </td>
                        </tr>
                    </template>
                    </tbody>
                    <thead>
                        <tr>
                            <th class="">
                                <div class="custom-control custom-checkbox">
                                    <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                    <label class="custom-control-label" for="selectAllCheckBox"></label>
                                </div>
                            </th>
                    <template v-for="(data, headerIndex) in headers">
                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                        <th
                        :class="data.column_full_class + ' column-header'"
                        :key="headerIndex"
                        v-if="data.name != 'Action'"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                        >
                        {{ data.name }}
                        </th>
                    </template>
                    </tr>
                    </thead>
                </table>
            </div>
            <!-- Pagination Start -->
            <div class="vgt-wrap__footer crm">
                <div class="row">
                    <div class="col-sm-12 col-md-2 PerPage">
                        <v-select title="Select Per Page"
                                  v-model="perPage"
                                  @input="changePerPage"
                                  name="customPerPage"
                                  id="customPerPage"
                                  label="text"
                                  :clearable="false"
                                  :searchable="false"
                                  :filterable="false"
                                  :options="paginationDropDownArr"
                                  placeholder="Select Per Page">
                        </v-select>
                    </div>
                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                        <p class="pageRowCount">
                            {{startEndRecord}} of {{totalRecords}}
                        </p>
                    </div>

                    <!-- v-if="totalRecords >= perPage" -->
                    <div class="col-sm-12 col-md-6 Pagination">
                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <p class="pageJumpLabel">Page : </p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import CommonLoader from "@/components/partials/CommonLoader.vue";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListAssetExpenseTab',
        mixins: [commonMixins],
        components: {
            'filter-component': FilterComponent,
            'no-access': NoAccessPage,
            "common-loader": CommonLoader,
        },
        data() {
            return {
                assetId: 0,
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                axleTypeArr: [],
                headers: [],
                filters: {},
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                selectedDeleteRows: [],
                startEndRecord: "",
                selectAll: false,
                assetWiseExpensesArr: [],
                filterCount: 0,
                jumpToHistoryPage: 1,
            }
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAll: function (selectAll) {
             self.clickHeaderCheckBox();
             },*/
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadAssetExpenses(self.assetId);
            },
            currentPage: function () {
                self.loadAssetExpenses(self.assetId);
            }*/
        },
        created: function () {
            self = this;
            //self.getLoginIdPermissions();
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            var listHistoryData = self.loadFilters("listExpenseTab", self);
            self.jumpToHistoryPage =
            listHistoryData === null ||
            typeof listHistoryData === "undefined" ||
            listHistoryData == ""
                ? 1
                : listHistoryData.page;
            let headersNames = [];
            headersNames['filters'] = self.filters;
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                self.listExecutionState = false;
                self.loadAssetExpenses(self.assetId);
            }
            if (!self.permissionNameArray.includes('can_export_invoice_expense_master') && !self.permissionNameArray.includes('can_delete_expense_master') && !self.permissionNameArray.includes('can_add_expense_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadAssetExpenses(self.assetId);
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadAssetExpenses(self.assetId);
                }
            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
                self.selectedDeleteRows = [];
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    self.selectedDeleteRows = [];
                    if (self.selectAll) {
                        for (let i in self.assetWiseExpensesArr) {
                            self.selectedRows.push(self.assetWiseExpensesArr[i].id);
                            if (self.assetWiseExpensesArr[i].from_excede != 1) {
                                self.selectedDeleteRows.push(self.assetWiseExpensesArr[i].id);
                            }
                        }
                    }
                }, 100);
            },
            applyFilterParentComponent: function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]["column_name"] != "null") {
                    key = data[i]["column_name"];
                    value = data[i]["field"];
                    self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadAssetExpenses(self.assetId);
            },
            setAlreadyAppliedFilters: function () {
            if (self.appliedHeaders.length > 0) {
                self.headers = self.appliedHeaders;
            }
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectedDeleteRows = [];
                    self.selectAll = false;
                    if (self.selectedRows.length == self.assetWiseExpensesArr.length) {
                        self.selectAll = true;
                    }
                    $.each(self.selectedRows, function (i) {
                        $.each(self.assetWiseExpensesArr, function (j) {
                            if (self.assetWiseExpensesArr[j].id == self.selectedRows[i]) {
                                if (self.assetWiseExpensesArr[j].from_excede != 1) {
                                    self.selectedDeleteRows.push(self.assetWiseExpensesArr[j].id);
                                }
                            }
                        });
                    });
                }, 100);
            },
            editAssetWiseExpense: function (editId) {
                this.$parent.chagneComponent(editId);
            },
            syncAssetExpenses: function () {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters: self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    asset_id: self.assetId
                };
                HTTP.post("/sync/assetwiseexpenses", fields, config)
                        .then(function (data) {
                            if (data.status == 200) {
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                self.assetWiseExpensesArr = data.data.content.data;
                                self.headers = data.data.content.headers;

                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                if (data.data.content.per_page !== self.totalRecords) {
                                    self.perPage = parseInt(data.data.content.per_page);
                                    self.jumpToPage = parseInt(data.data.content.current_page);
                                } else {
                                    if (self.perPage == 'All') {
                                        self.jumpToPage = 1;
                                    }
                                    self.perPageValue = self.totalRecords;
                                    self.currentPage = 1;
                                }
                                // self.setAlreadyAppliedFilters();
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                self.sortFieldDisplay(self.columnName, self.sortType);
                                self.$emit('updateParentDisplayMethod',5);
                            }
                        })
                        .catch(function (error) {
                            self.catchHandler(error, function () {});
                        })
            },
            loadAssetExpenses: function (assetId) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadAssetExpenses(self.assetId);
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters: self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    asset_id: assetId
                };
                $("#commonLoaderListAssetExpenseTab")
                    .find(".loading-container.lodingTable")
                    .show();
                this.storeFilters("listExpenseTab", fields, false);
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/assetwiseexpenses/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            $("#commonLoaderListAssetExpenseTab")
                               .find(".loading-container.lodingTable")
                               .hide();
                            if (data.status == 200) {
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                    // alert(self.totalRecords);
                                    let seconds = 5000;
                                    self.$swal({
                                        type: 'error',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        showConfirmButton: false,
                                        timer: seconds,
                                    });
                                    setTimeout(() => {
                                        self.perPage = 500;
                                        self.loadAssetExpenses(self.assetId);
                                    }, seconds);
                                    /* self.$swal({
                                        title: '',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        type: 'error',
                                        confirmButtonText: 'OK',
                                        showCancelButton: true,
                                        showLoaderOnConfirm: true
                                    }).then((result) => {
                                        if (result.value) {
                                            self.logout();
                                        } else {
                                            self.perPage = 15;
                                            self.loadAssetExpenses();
                                        }
                                    }); */
                                }
                                self.assetWiseExpensesArr = data.data.content.data;
                                self.headers = data.data.content.headers;
                                self.loadFilterInputs(
                                    data.data.content.headers,
                                    self.filters
                                );
                                self.addColumnClass(self.headers);

                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                if (data.data.content.per_page !== self.totalRecords) {
                                    self.perPage = parseInt(data.data.content.per_page);
                                    self.jumpToPage = parseInt(data.data.content.current_page);
                                } else {
                                    if (self.perPage == "All") {
                                    self.jumpToPage = 1;
                                    }
                                    self.perPageValue = self.totalRecords;
                                    self.currentPage = 1;
                                }
                                self.setAlreadyAppliedFilters();
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                self.sortFieldDisplay(self.columnName, self.sortType);
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            $("#commonLoaderListAssetExpenseTab")
                               .find(".loading-container.lodingTable")
                               .hide();
                            self.catchHandler(error, function () {});
                        })
            },
            sortTableData: function (name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                    self.sortType = self.sortType == "desc" ? "asc" : "desc";
                    } else {
                    self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadAssetExpenses(self.assetId);
                }
            },
            deleteAssetWistExpenses: function (deleteAssetWiseId) {
                let deleteArray = [];
                deleteAssetWiseId = (deleteAssetWiseId === null || typeof (deleteAssetWiseId) === "undefined" || deleteAssetWiseId == '' || deleteAssetWiseId == 0) ? 0 : deleteAssetWiseId;
                if (deleteAssetWiseId != 0) {
                    deleteArray.push(deleteAssetWiseId);
                } else {
                    deleteArray = self.selectedDeleteRows;
                }
                if (deleteArray.length >= 1) {
                    self.$swal({
                        title: '',
                        text: 'Are you sure that you want to delete this Expense(s)!',
                        type: 'info',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        // showCloseButton: true,
                        showLoaderOnConfirm: true,
                    }).then((result) => {
                        if (result.value) {
                            var config = {
                                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                                data: {
                                    deleteIdArr: deleteArray,
                                }
                            };
                            HTTP.delete("/assetexpenses/delete", config)
                                    .then(function (response) {
                                        if (response.data.code == '200') {
                                            self.$emit('updateParentDisplayMethod',5);
                                            self.loadAssetExpenses(self.assetId);
                                        }
                                    })
                                    .catch(function (error) {
                                        self.catchHandler(error, function () {});
                                    });
                        }
                    });
                }
            },
            addAmountStatus: function (id) {
                let changeStatArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if (id != 0) {
                    changeStatArray.push(id);
                } else {
                    changeStatArray = self.selectedRows;
                }
                if (changeStatArray.length >= 1) {
                    self.$swal({
                        title: '',
                        text: 'Are you sure that you want to change Expense(s) status!',
                        type: 'info',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        // showCloseButton: true,
                        showLoaderOnConfirm: true,
                    }).then((result) => {
                        if (result.value) {
                            var config = {
                                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                                data: {
                                    deleteIdArr: changeStatArray,
                                }
                            };
                            HTTP.delete("/assetexpenses/changestatus", config)
                                    .then(function (response) {
                                        if (response.data.code == '200') {
                                            self.$emit('updateParentDisplayMethod',5);
                                            self.loadAssetExpenses(self.assetId);
                                        }
                                    })
                                    .catch(function (error) {
                                        self.catchHandler(error, function () {});
                                    });
                        }
                    });
                }
            },
            markIsPurchase: function (id) {
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };
                var fields = {
                    id: id,
                    asset_id: self.assetId
                };
                HTTP.post("/assetexpenses/markispurchase", fields, config)
                    .then(function (response) {
                        if (response.data.code == '200') {
                            self.loadAssetExpenses(self.assetId);
                        }
                    })
                    .catch(function (error) {
                        self.catchHandler(error, function () {});
                    });
            },
            exportExpenseData: function (assetWiseId) {
                /*self.exportData = true;
                 if (self.exportData == true) {
                 $('#exportExpenseData').text('Exporting...');
                 $('#exportExpenseData').prop("disabled", true);
                 self.loadOfferSubmissionData();
                 }*/
                let expenseArray = [];
                assetWiseId = (assetWiseId === null || typeof (assetWiseId) === "undefined" || assetWiseId == '' || assetWiseId == 0) ? 0 : assetWiseId;
                if (assetWiseId != 0) {
                    expenseArray.push(assetWiseId);
                } else {
                    expenseArray = self.selectedRows;
                }

                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };
                var fields = {
                    expenseArr: expenseArray,
                };
                $('#exportExpenseData').prop("disabled", true);
                HTTP.post("/assetexpenses/export/invoice", fields, config)
                        .then(function (response) {
                            if (response.data.code == '200') {
                                self.loadAssetExpenses(self.assetId);
                                self.downloadFile(response.data.content.web);
                                // location.href = response.data.content.web;
                            }
                            $('#exportExpenseData').prop("disabled", false);

                        })
                        .catch(function (error) {
                            self.catchHandler(error, function () {});
                        });
            },
        },
        computed: {
            isTransferPO() {
                return self.selectedRows.some((rowId) => {
                    const selectedExpense = self.assetWiseExpensesArr.find(expense => expense.id === rowId);
                    return selectedExpense && selectedExpense.expense_category === 'Transfer PO';
                });
            },
        }
    }
    EventBus.$on("sync-expense-list", function (where) {
        self.syncAssetExpenses();

    });
</script>