<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_invoice_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Asset -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.asset_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="asset" name="asset" @input="assetError = ''"
                                              label="text" :filterable="false" :options="assetArr"
                                              @search="onSearchAsset" :clearable="false"
                                              :placeholder="allStaticMessages.asset_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssetRecordNotFound">
                                                {{allStaticMessages.asset_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{assetError}}</span>
                                </div>
                            </div>
                            <!-- issue_date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.issue_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="issueDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="allStaticMessages.issue_date_place_holder"
                                            name="issueDate"
                                            @input="changeDatePickerDate()"
                                    >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{issueDateError}}</span>
                                </div>
                            </div>
                            <!-- type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="typeError = ''" id="type" v-model="type" class="form-control" type="text" :placeholder="allStaticMessages.enter_type_place_holder"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{typeError}}</span>
                                </div>
                            </div>
                            <!-- Lienholder Id -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.lienholder_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="lienholder"
                                            @change="changedValue"
                                            @input="lienholderError = ''"
                                            @search="onSearchLineholder"
                                            @clear="onClearLineholder"
                                            name="lienholder"
                                            id="lienholder"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="lienholderArr"
                                            :placeholder="allStaticMessages.acquire_from_place_holder">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isLineholderRecordNotFound">
                                                  {{allStaticMessages.acquire_from_search}}
                                              </template>
                                              <template v-else>
                                                  {{allStaticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                    </v-select>
                                    <!--<input @input="lienholderError = ''" id="lienholder" v-model="lienholder" class="form-control" type="text"/>-->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lienholderError}}</span>
                                </div>
                            </div>
                            <!-- deposit -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.deposit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" @input="depositError = ''" id="deposit" v-model="deposit" class="form-control" type="text" :placeholder="allStaticMessages.deposit_place_holder"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{depositError}}</span>
                                </div>
                            </div>
                            <!-- deposit_ref_no -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.deposit_ref_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="depositRefNoError = ''" id="depositRefNo" v-model="depositRefNo" class="form-control" type="text" :placeholder="allStaticMessages.deposit_ref_no_place_holder"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{depositRefNoError}}</span>
                                </div>
                            </div>
                            <!-- Notes -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="notesError = ''" id="notes" v-model="notes" class="form-control" type="text" :placeholder="allStaticMessages.notes_place_holder"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{notesError}}</span>
                                </div>
                            </div>
                            <!--Location-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Location"
                                              v-model="location"
                                              @change="changedValue"
                                              @input="locationError = ''"
                                              name="location"
                                              id="location"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="locationArr"
                                              :placeholder="allStaticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div> -->
                            <!-- Customer Display -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_display_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select :title="allLabelMessages.customer_display_title"
                                              v-model="customerDisplay"
                                              @change="changedValue"
                                              name="customerDisplay"
                                              id="customerDisplay"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="customerDisplayArr"
                                              :placeholder="allStaticMessages.customer_display_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>
                            <!-- Include Salesman -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.include_salesman_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select :title="allLabelMessages.include_salesman_title"
                                              v-model="includeSalesman"
                                              @change="changedValue"
                                              name="includeSalesman"
                                              id="includeSalesman"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="includeSalesmanArr"
                                              :placeholder="allStaticMessages.include_salesman_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addInvoiceBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addInvoice()">
                            <input id="addAndContinueInvoiceBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addInvoice(flag='Save and Continue')">
                            <input id="cancelInvoice" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    Vue.component("app-footer", Footer);
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    export default {
        name: 'AddUpdateInvoice',
        components: {
            datePicker,
            'no-access' : NoAccessPage,
        },
        mixins: [commonMixins],
        data () {
            return {
                asset: "",
                assetArr: [],
                isAssetRecordNotFound: false,
                issueDate: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                type: "",
                lienholder: "",
                lienholderArr : [],
                isLineholderRecordNotFound: false,
                deposit: "",
                depositRefNo: "",
                notes: "",
                location: "",
                locationArr: [],
                customerDisplay: {text: 'No', value: 'no'},
                includeSalesman: {text: 'No', value: 'no'},
                customerDisplayArr: [
                    {
                        text: 'No',
                        value: 'no'
                    },
                    {
                        text: 'Yes',
                        value: 'yes'
                    }
                ],
                includeSalesmanArr: [
                    {
                        text: 'No',
                        value: 'no'
                    },
                    {
                        text: 'Yes',
                        value: 'yes'
                    }
                ],

                assetError: "",
                issueDateError: "",
                typeError: "",
                lienholderError: "",
                depositError: "",
                depositRefNoError: "",
                notesError: "",
                locationError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Invoice",
                permissionNameArray : ['can_add_invoice_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('invoice-list');
            EventBus.$emit("menu-selection-changed", "invoice-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getInvoiceDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueInvoiceBtn").hide();
                self.pageTitle = "Edit Invoice";
            }
            // self.loadAllData('Asset', true, self.loadAssetDataCallback, "undefined", '');
            // self.loadAllData('Location', false, self.loadLocationDataCallback);
            // self.loadAllData(['Location', 'Asset], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push('/invoice/list');
            },
            changeDatePickerDate : function () {
                self.issueDateError = "";
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            loadMasterDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.locationArr = callbackData.Location;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            /* loadLocationDataCallback: function(callbackData) {
                self.locationArr = callbackData.Location;
            }, */
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchLineholder(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadLineholderCallback, loading, search);
                }
            },
            loadLineholderCallback: function (callbackData) {
                self.isLineholderRecordNotFound = false;
                self.lienholderArr = callbackData.Contact;
                if(self.lienholderArr.length == 0) {
                    self.isLineholderRecordNotFound = true;
                }
            },
            onClearLineholder : function() {
                self.lienholderError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.asset = "";
                self.issueDate = "";
                self.type = "";
                self.lienholder = "";
                self.deposit = "";
                self.depositRefNo = "";
                self.notes = "";
                self.location = "";
                self.customerDisplay = {
                    text: 'No',
                    value: 'no'
                };
                self.includeSalesman = {
                    text: 'No',
                    value: 'no'
                };
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getInvoiceDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/invoice/get', config)
                    .then(response => {
                        self.asset = {text: self.formatString(response.data.content.vinNo), value: response.data.content.asset_id, location_id: response.data.content.location_id, companyId: response.data.content.company_id};
                        self.location = {text: self.formatString(response.data.content.locName), value: response.data.content.location_id};
                        self.issueDate = self.datePickerDateFormat(response.data.content.issue_date);
                        self.type = response.data.content.type;
                        /*self.lienholder = response.data.content.lienholder_id;*/
                        self.lienholder = {text: self.formatString(response.data.content.line_first_name), value: response.data.content.lienholder_id};
                        self.deposit = response.data.content.deposit;
                        self.depositRefNo = response.data.content.deposit_ref_no;
                        self.notes = response.data.content.note;
                        self.customerDisplay = {text: self.formatString(response.data.content.customer_display), value: response.data.content.customer_display};
                        self.includeSalesman = {text: self.formatString(response.data.content.include_salesman), value: response.data.content.include_salesman};
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.asset == null || !self.asset.value) {
                    self.assetError = "Please select Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.asset) {
                    if(!self.asset.companyId || self.asset.companyId === null || typeof self.asset.companyId === "undefined") {
                        self.assetError = "Please assign the company first to the inventory!";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                /* if (self.location == null || !self.location.value) {
                    self.locationError = "Please select location";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (!self.type) {
                    self.typeError = "Please enter Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.issueDate) {
                    self.issueDateError = "Please select Issue Date";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.lienholder == null || !self.lienholder.value) {
                    self.lienholderError = "Please enter lienholder";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.deposit) {
                    self.depositError = "Please enter deposit";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.depositRefNo) {
                    self.depositRefNoError = "Please enter deposit Ref No";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.notes) {
                    self.notesError = "Please enter note";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addInvoice(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('asset_id', self.asset.value);
                        data.append('company_id', self.asset.companyId);
                        data.append('location_id', self.asset.location_id);
                        data.append('issue_date', self.apiRequestDateFormat(self.issueDate));
                        data.append('type', self.type);
                        //data.append('lienholder_id', self.lienholder);
                        data.append('lienholder_id', self.lienholder.value);
                        data.append('deposit', self.deposit);
                        data.append('deposit_ref_no', self.depositRefNo);
                        data.append('note', self.notes);
                        data.append('customer_display', self.customerDisplay.value);
                        data.append('include_salesman', self.includeSalesman.value);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/invoice/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>