<template>
    <div>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div :id="openAt == 'pageView' ? 'page-content-wrapper' : ''" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_opportunity_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">{{openAt == 'pageView' ? 'Back' : 'Close'}}</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <!-- Opportunity Number -->
                        <div class="row no-margin" id="oppTop">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.opportunity_number_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="oppNumber" disabled v-model="oppNumber" class="form-control disabled" type="text" maxlength="19"/>
                                        <!-- <span class="form-control">{{oppNumber}}</span> -->
                                    </div>
                                </div>


                                <!--START: Date Open Section -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.date_open_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <date-picker
                                                v-model="dateOpen"
                                                :config="dateOpenOptions"
                                                class="form-control"
                                                autocomplete="off"
                                                :placeholder="staticInputLabelMessages.date_open_place_holder"
                                                name="dateOpen"
                                                @input="dateOpenError = ''"
                                        >
                                        </date-picker>
                                        <span class="help is-danger">{{dateOpenError}}</span>
                                    </div>
                                </div>

                        </div>
                        <div class="row no-margin">
                            <!-- Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="locationId" name="locationId"
                                            label="text" 
                                            :filterable="false" 
                                            :options="locationArr"
                                            @search="onSearchLocation" 
                                            :clearable="true" 
                                            @input="locationError = ''"
                                            :placeholder="allStaticMessages.location_custom_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isLocationRecordNotFound">
                                                {{allStaticMessages.location_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>

                        </div>
                        <div class="row no-margin">
                            <!-- Salesman -->
                            <div class="col-6 forms-block" ref="salesPersonHeight">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesperson_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0" id="oppSalesman">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <v-select v-model="salesmanId"
                                              @change="changedValue"
                                              @input="salesmanError = ''"
                                              @search="onSearchSalesman"
                                              @clear="onClearSalesman"
                                              name="salesman"
                                              id="salesman"
                                              label="text"
                                              :clearable="false"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="salesmanArr"
                                              :placeholder="staticMessages.acquire_by_place_holder">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isSalesmanRecordNotFound">
                                                        {{staticInputLabelMessages.salesperson_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{staticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{salesmanError}}</span>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-primary" title="Add Salesperson" @click="addRemoveSalesPerson()">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="salesPersonList.length>0">
                                    <div v-for="(salesperson,index) in salesPersonList" :key="index">
                                        <div class="col-3 paddingleft0" :id="'oppSalesman-'+index">
                                            <div class="form-label">{{'Salesperson-'+(index+1)+':'}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <v-select
                                                    v-model="salesperson.salesPersonId"
                                                    @input="salespersonListError[index] = ''"
                                                    @search="onSearchSalesman"
                                                    name="salesman"
                                                    id="salesman"
                                                    label="text"
                                                    :clearable="false"
                                                    :searchable="true"
                                                    :filterable="false"
                                                    :options="salesmanArr"
                                                    :placeholder="staticMessages.acquire_by_place_holder">
                                                        <span slot="no-options" @click="$refs.select.open = false">
                                                            <template v-if="!isSalesmanRecordNotFound">
                                                                {{staticInputLabelMessages.salesperson_search}}
                                                            </template>
                                                            <template v-else>
                                                                {{staticMessages.search_record_not_found}}
                                                            </template>
                                                        </span>
                                                    </v-select>
                                                    <div class="requiredstar">*</div>
                                                    <span class="help is-danger">{{salespersonListError[index]}}</span>
                                                </div>
                                                <div class="col-md-2">
                                                    <button type="button" class="btn btn-danger" title="Remove Salesperson" @click="addRemoveSalesPerson('remove',index, salesperson)">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <!-- Make -->
                            <div class="col-6 forms-block" v-show="unable_fields.make">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">

                                    <v-select 
                                    v-model="makeId"
                                    @change="changedValue"
                                    @input="makeError = ''"
                                    name="make"
                                    id="make"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="makeArr"
                                    :placeholder="staticInputLabelMessages.select_make">
                                </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>

                        </div>
                        
                        <div class="row no-margin">

                             <!-- Decision -->
                            <div v-show="unable_fields.decision_date" class="col-6 forms-block" id="oppDecisionDate">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.decision_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="decisionDate"
                                            :config="decisionDateOptions"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.decision_date_place_holder"
                                            name="decisionDate"
                                            @input="decisionDateError = ''"
                                    >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{decisionDateError}}</span>
                                </div>
                            </div>

                          
                            <!-- Customer -->
                            <!--#### SEARCH BY MODEL INSTEAD OF v-select ####
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select v-model="customerId"
                                                @change="changedValue"
                                                @input="customerError = ''"
                                                @search="onSearchCustomer"
                                                @clear="onClearCustomer"
                                                name="customerId"
                                                id="customerId"
                                                label="text"
                                                :clearable="true"
                                                :searchable="true"
                                                :filterable="false"
                                                :options="customerArr"
                                                :placeholder="staticMessages.customer_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isCustomerRecordNotFound">
                                                    {{staticMessages.contact_search}}
                                                </template>
                                                <template v-else>
                                                    {{staticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{customerError}}</span>
                                    </div>
                                </div>
                            -->
                            <div class="col-6 forms-block" id="oppCustomer">
                                <div class="col-3 paddingleft0">
                                    <!--#### NEED TO UPDATE LABEL ####
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    -->
                                    <div class="form-label">Customer Name/ID :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <div class="opp-input-group">
                                        <div class="opp-input-container">
                                            <input
                                                type="text"
                                                class="opp-form-input"
                                                id="customer"
                                                v-model="customerName"
                                            />
                                            <div
                                                class="opp-search-icon"
                                                @click="searchCustomer"
                                            ></div>
                                            <search-modal
                                                v-bind:isClickSearchCompanyModal="isClickSearchCompanyModal"
                                                v-bind:customerName="customerName"
                                            />
                                        </div>
                                        <input
                                        type="text"
                                        class="opp-form-input"
                                        id="customerId"
                                        v-model="excedeCustomerId"
                                        disabled
                                        />
                                    </div>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerError}}</span>
                                </div>
                            </div>

                                                        
                            <!-- Chance -->
                            <div class="col-6 forms-block" id="oppLikelihood">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.likelihood_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="likelihoodId"
                                            @change="changedValue"
                                            @input="likelihoodError = ''"
                                            name="likelihoodIdcustomPerPage"
                                            id="likelihoodIdcustomPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="likelihoodArr"
                                            :placeholder="staticInputLabelMessages.select_likelihood_placholder_label">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{likelihoodError}}</span>
                                </div>
                            </div>
                            
                            <!--START: Customer Contact Section -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ staticInputLabelMessages.customer_contact_label }}
                                </div>
                                </div>
                                <div class="col-9 paddingleft0">
                                <v-select
                                    v-model="customerContactId"
                                    name="customerContactId"
                                    id="customerContactId"
                                    label="text"
                                    @change="changedValue"
                                    @input="customerContactIdError = ''"
                                    :filterable="false"
                                    :options="customerContactIdArr"
                                    @search="onSearchCustomerContact"
                                    :placeholder="staticMessages.customer_contact_place_holder"
                                >
                                    <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isCustomerContactRecordNotFound">
                                        {{ staticMessages.customer_contact_search }}
                                    </template>
                                    <template v-else>
                                        {{ staticMessages.search_record_not_found }}
                                    </template>
                                    </span>
                                </v-select>
                                <!-- <div class="requiredstar">*</div> -->
                                <span class="help is-danger">{{ customerContactIdError }}</span>
                                </div>
                            </div>

                            <!-- Asset -->
                            <!--#### <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="assetId" 
                                              multiple
                                              name="state"
                                              label="text"
                                              :filterable="false"
                                              :options="assetArr"
                                              @search="onSearchAsset"
                                              :clearable="false"
                                              @input="assetError = ''"
                                              :placeholder="staticMessages.asset_place_holder"
                                              class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssetRecordNotFound">
                                                {{staticMessages.asset_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{assetError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lead}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="leadId"
                                            @change="changedValue"
                                            @input="leadIdError = ''"
                                            @search="onSearchLead"
                                            @clear="onClearLead"
                                            name="leadId"
                                            id="leadId"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="leadIdArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isLeadRecordNotFound">
                                                  {{staticMessages.acquire_from_search}}
                                              </template>
                                              <template v-else>
                                                  {{staticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{leadIdError}}</span>
                                </div>
                            </div> -->
                            <!-- Lead Quality -->
                            <!--#### <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lead_quality_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="leadQuality"
                                            @change="changedValue"
                                            @input="leadQualityError = ''"
                                            name="leadQuality"
                                            id="leadQuality"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="leadQualityArr"
                                            :placeholder="staticMessages.select_lead_quality">
                                    </v-select> -->
                                    <!--<input @input="leadQualityError = ''" id="leadQuality" v-model="leadQuality" class="form-control" type="text"/>-->
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{leadQualityError}}</span>
                                </div>
                            </div> -->

                            <!-- Sale Type -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sale_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="saleType"
                                            @change="changedValue"
                                            @input="saleTypeError = ''"
                                            name="saleType"
                                            id="saleType"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="saleTypeArr"
                                            :placeholder="staticMessages.select_sale_type">
                                    </v-select> -->
                                    <!--<input @input="saleTypeError = ''" id="saleType" v-model="saleType" class="form-control" type="text"/>-->
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{saleTypeError}}</span>
                                </div>
                            </div> -->

                            <!-- Funding Source -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.funding_source_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="fundingSource"
                                            @change="changedValue"
                                            @input="fundingSourceError = ''"
                                            name="fundingSource"
                                            id="fundingSource"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="fundingSourceArr"
                                            :placeholder="staticMessages.select_funding_source">
                                    </v-select> -->
                                    <!--<input @input="fundingSourceError = ''" id="fundingSource" v-model="fundingSource" class="form-control" type="text"/>-->
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fundingSourceError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lender_internal}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="lenderId"
                                            @change="changedValue"
                                            @input="lenderIdError = ''"
                                            @search="onSearchLender"
                                            @clear="onClearLender"
                                            name="lenderId"
                                            id="lenderId"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="lenderIdArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isLenderRecordNotFound">
                                                  {{staticMessages.acquire_from_search}}
                                              </template>
                                              <template v-else>
                                                  {{staticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                    </v-select> -->
                                    <!--<div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lenderIdError}}</span>-->
                                <!-- </div>
                            </div> -->
                            <!-- Funding Method -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.funding_method_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="fundingMethod"
                                            @change="changedValue"
                                            @input="fundingMethodError = ''"
                                            name="fundingMethod"
                                            id="fundingMethod"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="fundingMethodArr"
                                            :placeholder="staticMessages.select_funding_method">
                                    </v-select> -->
                                    <!--<input @input="fundingMethodError = ''" id="fundingMethod" v-model="fundingMethod" class="form-control" type="text"/>-->
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fundingMethodError}}</span>
                                </div>
                            </div> -->
                            <!-- Funding Status -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.funding_status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="fundingStatus"
                                            @change="changedValue"
                                            @input="fundingStatusError = ''"
                                            name="fundingStatus"
                                            id="fundingStatus"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="fundingStatusArr"
                                            :placeholder="staticMessages.select_funding_status">
                                    </v-select> -->
                                    <!--<input @input="fundingStatusError = ''" id="fundingStatus" v-model="fundingStatus" class="form-control" type="text"/>-->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{fundingStatusError}}</span>
                                </div>
                            </div> -->
                            
                            <!-- Down Payment -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.down_payment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="downPaymentError = ''" id="downPayment" v-model="downPayment" class="form-control" type="text"/> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{downPaymentError}}</span>
                                </div>
                            </div> -->

                            <!-- deposit Received -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.deposit_received_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="depositReceivedError = ''" id="depositReceived" v-model="depositReceived" class="form-control" type="text"/> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{depositReceivedError}}</span>
                                </div>
                            </div> -->

                            <!-- deposit Date -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.deposit_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            :placeholder="staticMessages.deposit_date_place_holder"
                                            class="form-control"
                                            autocomplete="off"
                                            v-model="depositDate"
                                            :config="bootstrapOptions"
                                            @input="depositDateError = ''"
                                    >
                                    </date-picker> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{depositDateError}}</span>
                                </div>
                            </div> -->

                            <!-- Funding Rate -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.funding_rate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fundingRateError = ''" id="fundingRate" v-model="fundingRate" class="form-control" type="text"/> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{fundingRateError}}</span>
                                </div>
                            </div> -->

                            <!-- Trade Detail -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trade_detail_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tradeDetailError = ''" id="tradeDetail" v-model="tradeDetail" class="form-control" type="text"/> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{tradeDetailError}}</span>
                                </div>
                            </div> -->

                            <!-- Approx Trade Value -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.approx_trade_value_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="approxTradeValueError = ''" id="approxTradeValue" v-model="approxTradeValue" class="form-control" type="text"/> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{approxTradeValueError}}</span>
                                </div>
                            </div> -->

                            <!-- Warranty Type -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="warrantyTypeId" 
                                        name="state"
                                        label="text" :filterable="false" 
                                        :options="warrantyTypeArr"
                                        @search="onSearchWarrantyType" 
                                        :clearable="false" 
                                        @input="warrantyTypeError = ''"
                                        :placeholder="staticMessages.warranty_place_holder" 
                                        class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isWarrantyRecordNotFound">
                                                {{staticMessages.warranty_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{warrantyTypeError}}</span>
                                </div>
                            </div> -->

                            <!-- Source -->
                            <div class="col-6 forms-block" id="oppSource">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.source_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                    v-model="source"
                                    @change="changedValue"
                                    @input="sourceError = ''"
                                    name="source"
                                    id="source"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="sourceArr"
                                    :placeholder="staticMessages.select_source">
                                </v-select>
                                    <!--<input @input="sourceError = ''" id="source" v-model="source" class="form-control" type="text"/>-->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{sourceError}}</span>
                                </div>
                            </div>

                            <!-- Internal Notes -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.internal_notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="internalNoteError = ''" id="internalNote" v-model="internalNote" class="form-control"></textarea> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{internalNoteError}}</span>
                                </div>
                            </div> -->

                            <!-- Invoice Date -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.invoice_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            :placeholder="staticMessages.invoice_date_place_holder"
                                            class="form-control"
                                            autocomplete="off"
                                            v-model="invoiceDate"
                                            :config="bootstrapOptions"
                                            @input="invoiceDateError = ''"
                                    >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{invoiceDateError}}</span>
                                </div>
                            </div> -->

                            <!-- Invoice Notes -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.invoice_notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="invoiceNoteError = ''" id="invoiceNote" v-model="invoiceNote" class="form-control"></textarea> -->
                                    <!--<div class="requiredstar">*</div>-->
                                    <!-- <span class="help is-danger">{{invoiceNoteError}}</span>
                                </div>
                            </div> -->

                            
                            <!-- Customer Display -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_display_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="customerDisplayId" name="state"
                                              label="text" :filterable="false" :options="customerDisplayArr"
                                              :search="false" :clearable="false" @input="customerDisplayError = ''"
                                              :placeholder="staticMessages.customer_display_place_holder" class="custom-ci-auto-search">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerDisplayError}}</span>
                                </div>
                            </div> -->

                            <!-- Include Salesman -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.include_salesman_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="includeSalesmanId" name="state"
                                              label="text" :filterable="false" :options="includeSalesmanArr"
                                              :search="false" :clearable="false" @input="includeSalesmanError = ''"
                                              :placeholder="staticMessages.include_salesman_place_holder" class="custom-ci-auto-search">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{includeSalesmanError}}</span>
                                </div>
                            </div> -->
                            

                            
                            <!-- Subject -->
                            <div v-show="unable_fields.subject" class="col-6 forms-block" id="oppSubject">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="subjectError = ''"  id="subject" v-model="subject" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{subjectError}}</span>
                                </div>
                            </div>
                            

                            <!-- Budget -->
                            <!-- ToDo: CrmChanges <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.budget_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{budget}}</span>
                                </div>
                            </div> -->

                            
                            <!-- Profit -->
                            <!--ToDo: Removed as per CRM changes form feedback
                                <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.profit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{profit}}</span>
                                </div>
                            </div> -->

                            <!-- Stock/Order -->
                            <!--ToDo: Removed as per CRM changes form feedback
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.stock_order_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="stockOrder"
                                                        :options="stockOrderOptions"
                                                        name="stockOrder">
                                    </b-form-radio-group>
                                </div>
                            </div> -->

                            <!-- Delivered -->
                            <!--ToDo: Au-CRM changes Not in Used
                                <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.delivered_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{delivered}}</span>
                                </div>
                            </div> -->

                            </div>
                            
                            <div class="row no-margin">
                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="status"
                                                        :options="statusOptions"
                                                        @change = "e => {selectDeselectChance(e)}"
                                                        name="status">
                                    </b-form-radio-group>
                                    <!-- <div class="requiredstar">*</div> -->
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>
                            <!-- Sales -->
                            <div class="col-6 forms-block" v-show="status == 'won'">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sales_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input  id="sales" v-model="sales" class="form-control" type="text"/>
                                </div>
                            </div>

                            <!-- Commission GP -->
                            <div class="col-6 forms-block"  v-show="status == 'won'">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.commission_gp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input  id="commissionGP" v-model="commission_gp" class="form-control" type="text"/>
                                </div>
                            </div>

                            <!-- Conclusion -->
                            <!--ToDo: AU-CRM changes, suggested form don't have this
                                So need to confirm with US and if not then need to remove
                                 <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.conclusion_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="conclusion"
                                            @change="changedValue"
                                            @search="onSearchConclusion"
                                            name="conclusion"
                                            id="conclusion"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="conclusionArr"
                                            :placeholder="staticMessages.conclusion_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isConclusionRecordNotFound">
                                                {{staticMessages.conclusion_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div> -->

                            
                            <!-- Lost Cause-->
                            <div class="col-6 forms-block" v-show="status == 'lost'">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lost_cause_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="lost_reason"
                                            @change="changedValue"
                                            @input="chanceError = ''"
                                            name="lostReasonPerPage"
                                            id="lostReasonPerPage"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="lostReasonArr"
                                            :placeholder="staticInputLabelMessages.select_lost_cause_placholder_label">
                                    </v-select>
                                    <span class="help is-danger">{{lostReasonError}}</span>
                                </div>
                            </div>

                            <div class="col-6 forms-block" v-show="status == 'lost'">
                                <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ staticInputLabelMessages.lost_additional_info_label }}
                                </div>
                                </div>
                                <div class="col-9 paddingleft0">
                                <textarea
                                    id="lost_additional_info"
                                    v-model="lost_additional_info"
                                    class="form-control"
                                ></textarea>
                                </div>
                            </div>

                            <!-- Notes -->
                            <div class="col-12 forms-block">
                                <div class="col-12 paddingleft0">
                                <div class="form-label">
                                    {{ staticInputLabelMessages.notes_label }}
                                </div>
                                </div>
                                <div class="col-12 paddingleft0">
                                <textarea
                                    id="notes"
                                    v-model="notes"
                                    class="form-control"
                                ></textarea>
                                </div>
                            </div>

                            <!-- Add Opportunity Attachments  -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.upload_attachments_label}}</label>
                                    <vue-dropzone
                                                ref="opportunityFormDropzoneRef"
                                                id="opportunity_form_dropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-success = "uploadDocument"
                                                @vdropzone-removed-file="removedSelectedFile"
                                                @vdropzone-error="fileError"
                                                @vdropzone-file-added="fileAdd"
                                                @vdropzone-total-upload-progress="progressOfUloadBanner"
                                                :useCustomSlot=true
                                                :refreshFlag="refreshFlag"
                                                :destroyDropzone="false"
                                        >
                                            <div class="dropzone-custom-content">
                                                <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                                <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                            </div>
                                        </vue-dropzone>
                                </div>
                            </div>

                            <div class="col-12 forms-block" v-show="loadDocArr.length > 0">
                                <div class="col-12 forms-block paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.documents_label}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="sliderConfigOptions" ref="sliderConfigOptions">
                                                    <template v-for="(doc, index1) in loadDocArr">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.media_path"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertErrorOpportunity">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addOpportunityBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addOpportunity()">
                            <input id="addAndContinueLocationBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addOpportunity(flag='Save and Continue')">
                            <input id="cancelLocation" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->

                    
                </div>
                
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-if="!isEdit"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- #### REMOVED ON CREATING TAB #### 
    <template v-if="isEdit">
        <div class="clearfix"></div>

        <div class="row clearfix"></div>

        <div id="opportunity-activity-tab" class="">
            <div class=""
                id="opportunity-activity-logs2"
                >
                <asset-crm
                    v-bind:opportunityMasterId="id"
                    v-bind:redirectUrl="redirectUrl"
                ></asset-crm>
                </div>
            
        </div>
    </template>
    -->
    </div>
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import OpportunityTabView from '@/components/OpportunityMaster/OpportunityTabView';
    Vue.component("app-footer", Footer);
    import ListActivity from "@/components/Activity/ListActivity";
    import SearchModal from "./CustomerSearchModel.vue";
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */
    var moment = require("moment-timezone");

    export default {
        name: 'AddUpdateOpportunity',
        components: {
            "app-footer" : Footer,
            datePicker,
            'no-access' : NoAccessPage,
            'opportunity-tab-view': OpportunityTabView,
            "asset-crm": ListActivity,
            "search-modal": SearchModal,
            'vueDropzone': vue2Dropzone,
            swiper,
            swiperSlide,
        },
        props: {
            openAt: {
                type: [String, Number],
                required: false,
                default: 'pageView'
            },
            hasParent: false,
            parentId: null
        },
        mixins: [commonMixins],
        data () {
            return {
                refreshFlag: true,
                production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
                isEdit: false,
                id: this.$route.params.id,
                oppId : "",
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                abbrevation: "",
                websiteName: "",
                address: "",
                city: "",
                cityArr: [],

                /* This form */
                assetId: [],
                assetArr: [],
                isAssetRecordNotFound: false,
                assetError : "",
                warrantyTypeId : "",
                warrantyTypeArr : [],
                isWarrantyRecordNotFound: false,
                warrantyTypeError : "",
                locationId : "",
                locationArr : [],
                locationError : "",
                leadQuality : "",
                leadQualityArr : [],
                leadQualityError : "",
                saleType : "",
                saleTypeArr : [],
                saleTypeError : "",
                fundingSource : '',
                fundingSourceArr : [],
                fundingSourceError : "",
                fundingMethod : "",
                fundingMethodArr : [],
                fundingMethodError : "",
                fundingStatus : "",
                fundingStatusArr : [],
                fundingStatusError : "",
                downPayment : "",
                downPaymentError : "",
                depositReceived : "",
                depositReceivedError : "",
                fundingRate : "",
                fundingRateError : "",
                tradeDetail : "",
                tradeDetailError : "",
                approxTradeValue : "",
                approxTradeValueError : "",
                source : "",
                sourceArr: [],
                sourceError : "",
                internalNote : "",
                internalNoteError : "",
                invoiceNote : "",
                invoiceNoteError : "",
                customerDisplayId : { text : "No", value : "no"},
                customerDisplayArr : [{ text : 'Yes', value : 'yes'}, { text : "No", value : "no"}],
                customerDisplayError : "",
                includeSalesmanId : { text : "No", value : "no"},
                includeSalesmanArr : [{ text : 'Yes', value : 'yes'}, { text : "No", value : "no"}],
                includeSalesmanError : "",

                depositDate : "",
                depositDateError : "",
                invoiceDate : "",
                invoiceDateError : "",
                bootstrapOptions: {
                    /*format: 'MM/DD/YYYY',
                    useCurrent: false,*/
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    allowInputToggle: true,
                    disabledTimeIntervals: false,
                },
                timePicker: {
                    allow : false,
                    format: 'LT',
                    useCurrent: false
                },
                /* End This Form*/
                zipCode: "",
                phone: "",
                sms: "",
                fax: "",
                email: "",
                statusId: {text: 'Active', value: 'active'},
                statusArr: [
                    {
                        text: 'Active',
                        value: 'active'
                    },
                    {
                        text: 'Inactive',
                        value: 'inactive'
                    }
                ],
                locationNote: "",
                cotactName: "",
                salesTax: "",
                laborTax: "",
                partsEmail: "",
                shopEmail: "",
                partStatusId: {text: 'Active', value: 'active'},
                shopStatusId: {text: 'Active', value: 'active'},

                nameError: "",
                abbrevationError: "",
                websiteNameError: "",
                addressError: "",
                stateError: "",
                cityError: "",
                zipCodeError: "",
                phoneError: "",
                smsError: "",
                faxError: "",
                emailError: "",
                locationNoteError: "",
                cotactNameError: "",
                salesTaxError: "",
                laborTaxError: "",
                partsEmailError: "",
                shopEmailError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Opportunity",
                permissionNameArray : ['can_add_opportunity_master'],
                salesmanId : "",
                salesmanArr : [],
                salesmanError : "",
                isSalesmanRecordNotFound: false,
                customerId : "",
                customerArr : [],
                customerError : "",
                isCustomerRecordNotFound : false,
                leadId: "",
                leadIdArr : [],
                leadIdError: "",
                isLeadRecordNotFound: false,
                lenderId: "",
                lenderIdArr : [],
                lenderIdError: "",
                isLenderRecordNotFound: false,
                status : "",
                statusDefaultValue : "pending",
                statusOptions:[
                    {text: 'Lost', value: 'lost'},
                    {text: 'Pending', value: 'pending'},
                    {text: 'Won', value: 'won'},
                ],
                sales : "",
                budget : "",
                chanceId: "",
                chanceArr: [
                    {text: "High", value: "High"},
                    {text: "Medium", value: "Medium"},
                    {text: "Low", value: "Low"} 
                ],
                likelihoodId: "",
                likelihoodArr: [
                    {text: "High", value: "High"},
                    {text: "Medium", value: "Medium"},
                    {text: "Low", value: "Low"} 
                ],
                likelihoodError: "",
                profit : "",
                stockOrder : "",
                stockOrderDefaultValue : "stock",
                stockOrderOptions:[
                    {text: 'Stock', value: 'stock'},
                    {text: 'Order', value: 'order'},
                ],
                delivered : "",
                commission_gp : "",
                conclusion : "",
                conclusionArr : [],
                isConclusionRecordNotFound : false,
                subject : "",
                subjectError: "",
                notes: "",
                oppNumber : "",
                decisionDate : "",
                decisionDateError: "",
                decisionDateOptions: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                dateOpen : moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT),
                dateOpenError: "",
                dateOpenOptions: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                isLocationRecordNotFound: false,
                chanceError: "",
                tempLocId: null,
                redirectUrl: "opportunity/edit/",
                customerName: "",
                isClickSearchCompanyModal: false,
                excedeCustomerId: "",
                lost_reason: "",
                lostReasonArr: [
                    {text: "Availability", value: "Availability"},
                    {text: "Price", value: "Price"},
                    {text: "Finance", value: "Finance"},
                    {text: "Other", value: "Other"}
                ],
                lostReasonError: "",
                lost_additional_info: "",
                customerContactId: "",
                customerContactIdError: "",
                customerContactIdArr: [],
                isCustomerContactRecordNotFound: false,
                unable_fields: {
                    decision_date: (process.env.VUE_APP_PRODUCTION_COUNTRY == 'AU') ? 0 : 1,
                    make: (process.env.VUE_APP_PRODUCTION_COUNTRY == 'AU') ? 1 : 0,
                    subject: (process.env.VUE_APP_PRODUCTION_COUNTRY == 'AU') ? 0 : 1
                },
                makeError: "",
                makeId: "",
                make_master_id_temp: "",
                makeArr: [],
                homeLoactionId: Number, //To select Home Location of LoggedIn User as default on Adding New Opportunity Note: only if is_booking is true for Home Location
                salesPersonList: [],
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".pdf, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png",
                    maxFiles: 50,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    init: function() {
                        this.on("success", function(file, response) {
                            // Handle the success event
                        });
                        this.on("error", function(file, errorMessage, xhr) {
                            // Handle the error event
                            if (xhr) {
                                // If server-side validation failed
                                alert("Server Error: " + errorMessage);
                            } else {
                                // If client-side validation failed
                                if (file.accepted === false) {
                                    // alert("Invalid file type. Please upload files with the following extensions: .pdf, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png");
                                    alert(errorMessage);
                                    this.removeFile(file); // Remove the invalid file from the Dropzone
                                }
                            }
                        });
                    }
                },
                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                loadDocArr: [],
                salespersonListError: [],
                elementPosition: 0,
                attachment: [],
                sliderConfigOptions: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                searchFlag: process.env.VUE_APP_PRODUCTION_COUNTRY === "US" ? "OpportunityForm" : "",
                scrollToFieldName: "",
                searchCustomerModalInstance: null,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            self.redirectUrl = self.redirectUrl + self.$route.params.id;

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            if(self.$route.params.contact_id !== undefined) {
                let customerData = self.$route.params;
                self.customerId = customerData.contact_id;
                self.excedeCustomerId = customerData.customer_id;
                self.customerName = customerData.customer_name;
            }

            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.getLoginIdPermissions();
            self.isEdit = false;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.status = self.statusDefaultValue;
            self.stockOrder = self.stockOrderDefaultValue;
            self.setActiveMenu('opportunity-list');
            EventBus.$emit("menu-selection-changed", "opportunity-list");
            
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                /*self.salesmanId = {"text": userJson.first_name + " " + userJson.last_name, "value": userJson.id};*/
                self.salesmanId = {text: userJson.first_name + " " + userJson.last_name, value: userJson.id};
                self.homeLoactionId = userJson.home_location_id;
            }
            $("#alertErrorOpportunity").hide();
            if (self.$route.name == "OpportunityTab" && typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getOpportunityDetailsById();
                self.buttonNames.save = "Update";
                $("#addAndContinueLocationBtn").hide();
                self.pageTitle = "Edit Opportunity";
                self.isEdit = true;
            }
            self.loadAllData([/*'Location', 'ReferralSource' , 'LeadQuality', 'SaleType', 'FundingSource', 'FundingMethod', 'FundingStatus', 'Contact' */'Conclusion', 'User'], true, self.loadMasterDataCallback, "undefined", '');
            this.$nextTick(() => {
                self.loadAllData(['Location', 'ReferralSource'], true, self.loadMasterLocationReferralDataCallback,"undefined",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                self.searchFlag);

                self.loadAllMasterData(['Make'], self.loadMakeMasterDataCallback, "",{req_for: "OpportunityForm"});
                self.initializeModals();
            });
        },
        methods: {
            initializeModals() {
                self.searchCustomerModalInstance = new bootstrap.Modal(
                document.getElementById("searchCompanyModal"),
                    {
                    backdrop: "static",
                    keyboard: false,
                    }
                );
            },
            openAddOpportunityPopup(contactData) {
                self.customerId = contactData.id ? contactData.id : "";
                self.excedeCustomerId = contactData.excedeId ? contactData.excedeId : "";
                self.customerName = contactData.name ? self.capitalizeFirstLetter(contactData.name) : "";
                
                //GetCustomer Contact as per selected Customer
                self.getCustomerContactList();
                // self.customerId = {text: contactData.name ? self.capitalizeFirstLetter(contactData.name) : "", value: contactData.id ? contactData.id : ""};
                if(contactData.dateOpen){
                    self.dateOpen = contactData.dateOpen;
                }
            },
            buttonBack() {
                if(self.hasParent) { //TO REDIRECT BACK TO CUSTOMER TAB VIEW PAGE WITH CUSTOMER ID IF THE OPPORTUNITY IS VIEW FROM CUSTOMER TAB
                    self.$router.push({ name : 'UpdateContact', params: {id: self.parentId, opportunityTab: true}});
                } else if(self.openAt == 'pageView') {
                    self.$router.push({ name : "ListOpportunity"});
                }else{
                    self.$parent.closeModalPopup();
                    // self.$emit('call-modal-close');
                    self.clearDataOnSaveAndContinue();
                }
            },
            setMakeInEdit(){
                const makeConst = self.makeArr.find((make) => {
                    return make.value == self.make_master_id_temp;
                });

                if(makeConst !== undefined) {
                    self.makeId = makeConst;
                }
            },
            loadMakeMasterDataCallback: function (callbackData) {
                self.makeArr = callbackData.Make;
                self.setMakeInEdit();
            },
            loadMasterDataCallback: function (callbackData) {
                self.isSalesmanRecordNotFound = self.isConclusionRecordNotFound = self.isCustomerRecordNotFound = false;
                self.salesmanArr = callbackData.User;
                if(typeof self.salesmanArr != 'undefined' && self.salesmanArr.length == 0) {
                    self.isSalesmanRecordNotFound = true;
                }
                self.customerArr = callbackData.Contact;
                if(typeof self.customerArr != 'undefined' && self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
                
                /* 
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
                self.sourceArr = callbackData.ReferralSource;
                self.leadQualityArr = callbackData.LeadQuality;
                self.saleTypeArr = callbackData.SaleType;
                self.fundingSourceArr = callbackData.FundingSource;
                self.fundingMethodArr = callbackData.FundingMethod;
                self.fundingStatusArr = callbackData.FundingStatus; */
                self.conclusionArr = callbackData.Conclusion;
                if(self.conclusionArr.length == 0) {
                    self.isConclusionRecordNotFound = true;
                }

                // if(self.tempLocId !== null) {
                //     const locationConst = self.locationArr.filter((location) => {
                //         return location.value == self.tempLocId;
                //     })
                //     self.locationId = locationConst[0];
                // }
            },
            setLocationInEdit(){
                let currentLocationId = self.homeLoactionId;

                if(self.tempLocId !== null) {
                    currentLocationId = self.tempLocId;
                }
                const locationConst = self.locationArr.find((location) => {
                    return location.value == currentLocationId;
                })

                if(locationConst !== undefined) {
                    self.locationId = locationConst;
                }
            },
            loadMasterLocationReferralDataCallback: function (callbackData) {
                
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
                self.sourceArr = callbackData.ReferralSource;

                self.setLocationInEdit();
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchSalesman(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesmanCallback, loading, search);
                }
            },
            loadSalesmanCallback: function (callbackData) {
                self.isSalesmanRecordNotFound = false;
                self.salesmanArr = callbackData.User;
                if(self.salesmanArr.length == 0) {
                    self.isSalesmanRecordNotFound = true;
                }
            },
            onClearSalesman : function() {
                self.salesmanError= "";
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadCustomerCallback, loading, search);
                }
            },
            loadCustomerCallback: function (callbackData) {
                self.isCustomerRecordNotFound = false;
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
            },
            onClearCustomer : function() {
                self.customerError= "";
            },
            onSearchConclusion(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Conclusion'], true, self.loadConclusionCallback, loading, search);
                }
            },
            loadConclusionCallback: function (callbackData) {
                self.isConclusionRecordNotFound = false;
                self.conclusionArr = callbackData.Conclusion;
                if(self.conclusionArr.length == 0) {
                    self.isConclusionRecordNotFound = true;
                }
            },
            clearDataOnSaveAndContinue : function () {
                // self.salesmanId = "";
                /* self.leadId = "";
                self.lenderId = "";
                self.assetId = [];
                self.leadQuality = "";
                self.saleType = "";
                self.fundingSource = "";
                self.fundingMethod = "";
                self.downPayment = "";
                self.depositReceived = "";
                self.fundingRate = "";
                self.tradeDetail = "";
                self.approxTradeValue = ""; */
                self.source = "";
                /* self.internalNote = "";
                self.invoiceNote = "";
                self.customerDisplayId = "";
                self.includeSalesmanId = "";
                self.depositDate = "";
                self.invoiceDate = "";
                self.warrantyTypeId = "";*/
                self.locationId = "";
                self.status = self.statusDefaultValue;
                self.stockOrder = self.stockOrderDefaultValue;
                self.conclusion = "";
                self.subject = "";
                self.notes = "";
                self.oppNumber = "";
                self.chanceId = "";
                self.likelihoodId = "";
                self.dateOpen = moment(new Date()).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT); // Set the default date to today
                self.decisionDate = "";
                let calendarView = '';
                if(self.$route.name != 'undefinde' && self.$route.name == 'Calendar'){
                    calendarView = 'Calendar';
                }
                if(self.openAt == 'pageView' || calendarView == 'Calendar') {
                    self.customerId = "";
                    self.customerName = "";
                    self.isClickSearchCompanyModal = false;
                    self.excedeCustomerId = "";
                    self.customerContactId = "";
                }
                self.lost_reason = "";
                self.lost_additional_info = "";
                self.makeId = "";

                self.lost_reason = "";
                self.lost_additional_info = "";

                self.commission_gp = "";
                self.sales = "";
                self.imagesPaths = [];
                self.imagesType = [];
                self.imagesSize = [];
                self.loadDocArr = [];
                self.$refs.opportunityFormDropzoneRef.removeAllFiles();
                self.refreshFlag = !self.refreshFlag;
                self.salesPersonList = [];
                self.salespersonListError = [];
                self.elementPosition = 0;
                return true;
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getOpportunityDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/opportunity/get', config)
                    .then(response => {
                        if(response.data.content.salesman_id != null){
                            self.salesmanId = {text: self.formatString(response.data.content.firstName), value: response.data.content.salesman_id};
                        }

                        if(Object.keys(response.data.content).includes('opportunitySalesmanMapperList') && response.data.content.opportunitySalesmanMapperList !== null) {
                            self.salesPersonList = response.data.content.opportunitySalesmanMapperList;
                        }
                        if(response.data.content.location_id != null){
                            self.tempLocId = response.data.content.location_id;
                        }
                        if(response.data.content.include_salesman != null){
                            self.source = response.data.content.source;
                            self.source = {text: response.data.content.source_name, value: response.data.content.source};
                        }
                        if(response.data.content.decision_date != null) {
                            self.decisionDate =response.data.content.decision_date;
                        } 

                        if(response.data.content.open_date != null) {
                            self.dateOpen = response.data.content.open_date;
                        }
                        self.notes = response.data.content.notes;
                        self.subject = response.data.content.subject;
                        self.oppNumber = response.data.content.reference_no;
                        self.stockOrder = response.data.content.type;
                        self.status = response.data.content.status;
                        
                        if (response.data.content.likelihood != null) {
                            self.likelihoodId = {text: response.data.content.likelihood, value: response.data.content.likelihood};    
                        }
                        if (response.data.content.lost_reason != null) {
                            self.lost_reason = {text: response.data.content.lost_reason, value: response.data.content.lost_reason};    
                        }
                        if (response.data.content.lost_additional_info != null) {
                            self.lost_additional_info = response.data.content.lost_additional_info;    
                        }
                        

                        if (response.data.content.chance != null) {
                            self.chanceId = {text: response.data.content.chance, value: response.data.content.chance};    
                        }
                        if (response.data.content.conclusion != null) {
                            self.conclusion = {text: response.data.content.conclusion_name, value: response.data.content.conclusion};    
                        }
                        self.customerId = response.data.content.customer_id;
                        self.customerName = response.data.content.customer_name;
                        self.excedeCustomerId = response.data.content.excede_customer_id;


                        self.loadDocArr = response.data.content.media;

                        self.customerContactId = {text: response.data.content.customer_contact_name, value: response.data.content.customer_contact_id};
                        localStorage.setItem('opprtunityTabAddDeal', JSON.stringify({
                            reference_no: response.data.content.reference_no,
                            status: response.data.content.status,
                            id: response.data.content.id
                        }));

                        self.commission_gp = response.data.content.commission_gp;
                        self.sales = response.data.content.sales;
                        self.make_master_id_temp = response.data.content.make_master_id;

                        setTimeout(function() {
                            self.uploadManually();
                            self.setLocationInEdit();
                            self.setMakeInEdit();
                        }, 500);
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                let checked = true;
                self.elementPosition = self.$refs.salesPersonHeight.offsetHeight+209;
                let elementId = "";
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select location";
                    if (checked) {
                        checked = false;
                        self.elementPosition = 0;
                        elementId = "oppTop";
                    }
                }

                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanError = "Please select salesman";
                    if (checked) {
                        checked = false;
                        self.elementPosition = 0;
                        elementId = "oppSalesman"
                    }
                }

                /*FOR DOM EFFECT IN salespersonListError*/
                let tempError = self.salespersonListError;
                self.salespersonListError = null;
                /*End*/
                let elementHeight = self.$refs.salesPersonHeight.offsetHeight;
                let isSubSalesPersonError = false;
                self.salesPersonList.map((salesperson,index) => {
                    if(!Object.keys(salesperson).includes('salesPersonId')) {
                        tempError[index]='Please Select Salesperson-'+(index+1);
                        elementHeight = (index+1)*65;
                        isSubSalesPersonError = true;
                        if(checked) {
                            checked = false;
                            elementId = 'oppSalesman-'+index;
                        }
                    } else {
                        tempError[index]="";
                    }
                })
                self.salespersonListError = tempError;
                if(self.locationError == "" && isSubSalesPersonError) {
                    self.elementPosition = elementHeight;
                }

                if ((self.decisionDate == null || !self.decisionDate) && self.unable_fields.decision_date) {
                    self.decisionDateError = "Please select decision date";
                    if (checked) {
                        checked = false;
                        elementId = "oppDecisionDate"
                    }
                }

                if (self.customerId == null || !self.customerId) {
                    self.customerError = "Please search and select customer";
                    if (checked) {
                        checked = false;
                        elementId = "oppCustomer"
                    }
                }

                if(self.likelihoodId == null || !self.likelihoodId.value) {
                    self.likelihoodError = "Please select likelihood";
                    if (checked) {
                        checked = false;
                        elementId = "oppLikelihood";
                    }
                }

                if ((self.makeId == null || !self.makeId.value) && self.unable_fields.make) {
                    self.makeError = "Please select Make";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.source == null || !self.source.value) {
                    self.sourceError = "Please select source";
                    if (checked) {
                        checked = false;
                        elementId = "oppSource"
                    }
                }

                if(!self.subject && self.unable_fields.subject){
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                        elementId = "oppSubject"
                    }
                }

                /*####NOT REQUIRED AS PER CLIENT MEETING NOV 2,2O23#### 
                if (self.chanceId == null || !self.chanceId.value) {
                    self.chanceError = "Please select chance";
                    if (checked) {
                        checked = false;
                    }
                }
                */

                if(self.openAt == "pageView") {
                    window.scrollTo({
                        top: self.elementPosition,
                        behavior: 'smooth'
                    });
                } else {
                    const element = document.getElementById(elementId);
                    if(element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }

                return checked;
            },
            addOpportunity(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var locationId = "";
                        if (self.locationId == null || !self.locationId.value) {
                            locationId = "";
                        }else{
                            locationId = self.locationId.value;
                        }

                        var makeId = "";
                        if (self.makeId == null || !self.makeId.value) {
                            makeId = "";
                        }else{
                            makeId = self.makeId.value;
                        }
                        var chanceId = "";
                        if (self.chanceId == null || !self.chanceId.value) {
                            chanceId = "";
                        } else {
                            chanceId = self.chanceId.value;
                        }

                        var likelihood = "";
                        if (self.likelihoodId == null || !self.likelihoodId.value) {
                            likelihood = "";
                        } else {
                            likelihood = self.likelihoodId.value;
                        }

                        
                        var conclusionId = "";
                        if (self.conclusion == null || !self.conclusion.value) {
                            conclusionId = "";
                        } else {
                            conclusionId = self.conclusion.value;
                        }
                        let decisionDate = "";
                        if (self.decisionDate != null && typeof self.decisionDate != "undefined" && self.decisionDate != '') {
                            decisionDate = self.apiRequestDateFormat(self.decisionDate);  
                        }

                        let openDate = "";
                        if (self.dateOpen != null && typeof self.dateOpen != "undefined" && self.dateOpen != '') {
                            openDate = self.apiRequestDateFormat(self.dateOpen);                           
                        }

                        let lostReason = "";
                        let lostAdditionalInfo = "";

                        if(self.status == 'lost') {
                            lostReason = (self.lost_reason != "" && self.lost_reason.value) ? self.lost_reason.value : "";
                            lostAdditionalInfo = self.lost_additional_info;
                        }

                        let customerContactId = (self.customerContactId != "" && self.customerContactId.value) ? self.customerContactId.value : "";


                        var data = new FormData();
                        data.append('source', self.source.value);
                        data.append('location_id', locationId);
                        data.append('chance', chanceId);
                        data.append('status' , self.status);
                        data.append('stock_order' , self.stockOrder);
                        data.append('conclusion' , conclusionId);
                        data.append('subject' , self.subject);
                        data.append('notes' , self.notes);
                        data.append('decision_date' , decisionDate);
                        data.append('open_date' , openDate);
                        data.append('customer_id', self.customerId);
                        data.append('customer_contact_id', customerContactId);
                        data.append('likelihood', likelihood);
                        data.append('lost_reason', lostReason);
                        data.append('lost_additional_info', lostAdditionalInfo);
                        data.append('make_master_id', makeId);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('salesPersonList', JSON.stringify(self.salesPersonList));

                        data.append('commission_gp', self.commission_gp);
                        data.append('sales', self.sales);
                        
                        self.imagesPaths.forEach(function(imagePath, index) {
                            data.append('imagePath[]', imagePath);
                        });
                        self.imagesSize.forEach(function(imagePath, index) {
                            data.append('imageSize[]', imagePath);
                        });
                        self.imagesType.forEach(function(imagePath, index) {
                            data.append('imageType[]', imagePath);
                        });


                        let hasEditId = self.$route.name == "OpportunityTab" && typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '';
                        if (hasEditId) {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/opportunity/add/update", data, config)
                            .then(function (response) {
                                if (hasEditId) {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        window.scrollTo({
                                            top:0,
                                            behavior: 'smooth'
                                        })
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search, "", "", "", "", "", "", self.searchFlag);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            selectDeselectChance(statusType) {
                switch (statusType) {
                    case 'lost':
                        self.likelihood = {text: "Low", value: "Low"};
                        break;
                    case 'won':
                        self.likelihood = {text: "High", value: "High"};
                        break;
                    default:
                        self.likelihood = "";
                        break;
                }
            },
            searchCustomer() {
                self.isClickSearchCompanyModal = true;
                self.searchCustomerModalInstance.show();
            },
            populateCustomerData(customer) {
                if (customer != "") {
                    self.customerName = customer.text;
                    self.customerId = customer.value;
                    self.excedeCustomerId = customer.customerId;
                    
                    self.customerError = "";
                    self.getCustomerContactList();
                }
            },
            onSearchCustomerContact(search, loading) {
                if(self.customerId) {
                    if (search.length > 1) {
                        loading(true);
                        self.loadAllData(
                        ["CustomerContact"],
                        true,
                        self.loadCustomerContactCallback,
                        loading,
                        search,
                        self.customerId
                        );
                    }

                }else{
                    self.customerError = "Please select Customer first!";
                }
            },
            getCustomerContactList: function() {
                //Get Customer Contacts as per selected Customer
                if(self.customerId){
                    self.loadAllData(
                        ["CustomerContact"],
                        true,
                        self.loadCustomerContactCallback,
                        "",
                        "",
                        self.customerId
                    );
                }
            },
            loadCustomerContactCallback: function(callbackData) {
                self.isCustomerContactRecordNotFound = false;
                self.customerContactIdArr = callbackData.CustomerContact;
                if (self.customerContactIdArr.length == 0) {
                    self.isCustomerContactRecordNotFound = true;
                }
            },
            addRemoveSalesPerson(type=null,index, salesperson=null) {
                if(type==null) {
                    self.salesPersonList.push({
                        text: '',
                        value: ''
                    })
                    self.salespersonListError.push("");
                } else {
                    const isTempSalesperson = Object.keys(salesperson).includes('text');
                    if(self.$route.params.id !== undefined && Object.keys(self.salesPersonList[index]).includes('salesPersonId') && !isTempSalesperson) {
                        self.$swal({
                            title: "",
                            text: "Are you sure that you want to delete this salesman!",
                            type: "info",
                            confirmButtonText: "OK",
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (result.value) {
                                const spId = self.salesPersonList[index].salesPersonId.value;
                                let config = {
                                    headers: {
                                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                                    },
                                    data: {
                                        salespersonId: spId,
                                        opportunityId: self.$route.params.id,
                                    },
                                };
                                HTTP.delete("/opportunity/salesperson", config)
                                .then(function (response) {
                                    if (response.data.code == "200") {
                                        self.salesPersonList.splice(index, 1);
                                        self.getValidation(index);
                                    }
                                })
                                .catch(function (error) {
                                    self.catchHandler(error, function () {});
                                });
                            } 
                        });
                    } else {
                        self.salesPersonList.splice(index, 1);
                        self.getValidation(index);
                    }
                }
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
                var removeFile = "";
                if (self.$refs.opportunityFormDropzoneRef.dropzone.options.maxFiles) {
                    self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                    }
                }
                self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (self.$refs.opportunityFormDropzoneRef.dropzone.options.maxFiles) {
                    self.$refs.opportunityFormDropzoneRef.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            uploadManually: function (file) {
                var self = this;
                if(self.loadDocArr.length > 0){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        var url = self.loadDocArr[i]['media_path'];
                        var file_name = self.getFileName(self.loadDocArr[i]['media_path']);
                        var file_type = self.loadDocArr[i]['type'];
                        var file_size = self.loadDocArr[i]['size'];
                        self.imagesType.push(file_type);
                        self.imagesSize.push(Number(file_size));
                        var file_data = {size: file_size,fileid: file_name, name: file_name, type: file_type};
                        self.$refs.opportunityFormDropzoneRef.manuallyAddFile(file_data, url);
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

            },
            getFileType: function (file_name) {
                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {
                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];

                self.imagesPaths.push(String(orgPath + "/" + file_name));

                return file_name;
            },
            getValidation: function(index) {
                self.salespersonListError.splice(index,1);
                for(let i=(index); i<self.salespersonListError.length; i++) {
                    const sNo = self.salespersonListError[i].split("-");
                    if(sNo.length == 2) {
                        self.salespersonListError[i] = 'Please Select Salesperson-'+(i+1);
                    }
                }
                return true;
            }
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("close-search-opportunity-customer", function (status) {
        if (typeof self != "undefined") {
            self.isClickSearchCompanyModal = status;
            self.searchCustomerModalInstance.hide();
        }
    });
    EventBus.$on("get-search-opportunity-customer", function (data) {
        if (typeof self != "undefined") {
            self.isClickSearchCompanyModal = false;
            self.searchCustomerModalInstance.hide();
            self.populateCustomerData(data);
        }
    });
</script>

<style scoped>
#opportunity-activity-tab #page-content-wrapper {
    background: white;
    margin: 10px 35px 10px -215px;
}

.opp-input-group {
  display: grid;
  grid-template-columns: 7fr 2fr;
  gap: 0;
}

.opp-input-container {
  position: relative;
  height: 40px;
}

.opp-form-input {
    height: 40px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cecece;
}

.opp-search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 40px;
  flex-shrink: 0;
  background: #ebebeb url("../../../public/static/images/transaction_sheet/search.svg") no-repeat
    center;
  cursor: pointer;
}
</style>