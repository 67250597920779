<template>
    <div class="modal" id="mailHistoryDetail" tabindex="-1" role="dialog">
      <div
        v-if="isClickMailHistoryDetail"
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
            <div class="modal-header">
                <div>
                    {{ modelTitle }}
                </div>
                <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click="closeModal()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card p-2" v-html="mailTemplate"></div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import eventBus from "../../../../event-bus";

  export default {
    name: "MailHistoryModal",
    props: ["isClickMailHistoryDetail","mailDetails","modelTitle"],
    data() {
      return {
        mailTemplate: "",
      };
    },
    methods: {
      closeModal() {
        eventBus.$emit("close-mail-history-detail");
      },
    },
    watch: {
        isClickMailHistoryDetail(status) {
          if(status) {
            this.mailTemplate = this.mailDetails;
          }
        }
    }
  };
  </script>
  