<template>
  <div class="card">
    <div class="card-header">
      <h2 class="mb-0 float-left">
        <button
          class="btn btn-link btn-block text-left"
          type="button"
          data-toggle="collapse"
          data-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Vehicle RO's
        </button>
      </h2>
      <p class="text center">
        <input
          type="checkbox"
          id="hide_ros"
          class="form-check-input"
          v-model="hideInvoiced"
        /><span>Hide Invoiced RO'S</span>
      </p>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
      <div class="card-body">
        <div class="table-responsive">
          <!-- TABLE -->
          <table class="table custom-table jsmartable">
            <tbody v-if="hideInvoiced">
              <template v-if="repairOrderArr && repairOrderArr.length > 0">
                <tr
                  v-for="(repairOrder, index) in repairOrderArr"
                  :key="index"
                  v-if="repairOrder.status_des.toLowerCase() == 'open'"
                >
                  <td align="center">
                    <input
                      type="checkbox"
                      id="ro_checkbox"
                      class="form-check-input"
                      @change="
                        e => {
                          addSubToTotal(e, repairOrder.amt_subtotal);
                        }
                      "
                      v-model="isCostSheetInputChecked"
                    />
                  </td>
                  <td>{{ repairOrder.created_at }}</td>
                  <td>{{ repairOrder.status_des }}</td>
                  <td>
                    <span
                        role="button"
                        title="Click to View"
                        class="text-primary"
                        @click="getDetail(repairOrder)"
                        >{{ repairOrder.sls_id }}</span
                    >
                  </td>
                  <td>{{ repairOrder.status_name }}</td>
                  <td>{{ repairOrder.cus_name }}</td>
                  <td>{{ repairOrder.amt_subtotal }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="7" class="text-alignment-center">
                    {{ allStaticMessages.data_not_available }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template v-if="repairOrderArr && repairOrderArr.length > 0">
                <tr v-for="(repairOrder, index) in repairOrderArr" :key="index">
                  <td align="center">
                    <input
                      type="checkbox"
                      id="ro_checkbox"
                      class="form-check-input"
                      @change="
                        e => {
                          addSubToTotal(e, repairOrder.amt_subtotal);
                        }
                      "
                      v-model="isCostSheetInputChecked"
                    />
                  </td>
                  <td>{{ repairOrder.created_at }}</td>
                  <td>{{ repairOrder.status_des }}</td>
                  <td>
                    <span
                        role="button"
                        title="Click to View"
                        class="text-primary"
                        @click="getDetail(repairOrder)"
                        >{{ repairOrder.sls_id }}</span
                    >
                  </td>
                  <td>{{ repairOrder.status_name }}</td>
                  <td>{{ repairOrder.cus_name }}</td>
                  <td>{{ repairOrder.amt_subtotal }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="7" class="text-alignment-center">
                    {{ allStaticMessages.data_not_available }}
                  </td>
                </tr>
              </template>
            </tbody>
            <thead>
              <tr>
                <th class="" data-breakpoint="xs">Add to Cost</th>
                <th class="" data-breakpoint="xs">Creation Date</th>
                <th class="" data-breakpoint="xs">Status</th>
                <th class="" data-breakpoint="xs">PO#</th>
                <th class="" data-breakpoint="xs">Sales Type</th>
                <th class="" data-breakpoint="xs">Bill Customer</th>
                <th class="" data-breakpoint="xs">Sub Total</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../event-bus";
var thisSelf;

export default {
  name: "VehicleRos",
  props: ["repairOrderArr", "allStaticMessages"],
  data() {
    return {
      hideInvoiced: true,
      isCostSheetInputChecked: false
    };
  },
  beforeMount() {
    thisSelf = this;
  },
  methods: {
    addSubToTotal(e, amount) {
      eventBus.$emit("add-subtract-total", e.target.checked, amount);
    },
    getDetail(param) {
      eventBus.$emit("show-details", param, "ro");
    }
  }
};

eventBus.$on("close-cost-sheet", (status) => {
  Object.assign(thisSelf.$data, thisSelf.$options.data());
});
</script>
