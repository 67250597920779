<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="spaced-container">
                <!-- <div class="col-12 page_title paddingnone">
                    <span>{{pageTitle}}</span>
                    <label v-if="imageNotExistCount > 0" style="color:red;"> {{imageNotExistCount}} selected assets don't have photos and will be excluded</label>
                </div> -->
            </div>
        </div>
        <!--page content-->
        <div class="admin-page-content">
            <div class="col-12 page_title paddingnone">
                <div class="page_title_left">
                    <span>Compare Inventory</span>
                    <!-- <p v-if="InventorySearchData.totalRecords > 0">{{InventorySearchData.totalRecords}} Results found</p> -->
                </div>
            </div>
            <div class="col-12 paddingnone">
                <div class="table-listing white-bg border-blue border-radius-top-right">
                    <table class="table custom-table jsmartable">
                        
                        <tbody>
                            <template v-for="(data, key) in comparedInventoryResult">
                                <template v-if="key == 'Action'">
                                    <tr :key="key">
                                    <template v-if="data.length > 0">
                                        <td>{{ key }}</td>
                                        <template v-for="assetData in data">
                                            <td>
                                                <router-link class="mar-right-0" title='Edit Inventory' v-bind:to="{name: 'AssetTabView', params:{id: assetData}}">
                                                    <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i> Edit
                                                </router-link>
                                                
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                                </template>
                                <template v-else>
                                    <tr :key="key">
                                    <template v-if="data.length > 0">
                                        <td>{{ key }}</td>
                                        <template v-for="assetData in data">
                                            <td>{{ assetData }}</td>
                                        </template>
                                    </template>
                                </tr>
                                </template>
                            </template>
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);

    export default {
        components: {
            VueTagsInput,
        },
        name: 'compareAsset',
        mixins: [commonMixins],
        data() {
            return {
                loginId: 0,
                comparedInventoryResult:[]
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
        },
        mounted: function () {
             $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allLabelMessages;

            var userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson['id'];
            }

            $("#alertError").hide();
            self.assetId = localStorage.getItem('compareAssetIds');
            self.getAssetData();
        },
        methods: {
             getAssetData: function() {
                var splitAssetId = self.assetId.split(":");
                self.isAllSelectedAssetImagesIsExists = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.assetId,
                    }
                };
                HTTP.get('/asset/compare/get', config)
                        .then(response => {
                            self.comparedInventoryResult = response.data.content;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
        },
    }
</script>