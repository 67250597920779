<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <!-- <template v-if="permissionNameArray.includes('can_list_inventory_master')"> -->
            <div class="container-fluid xyz" v-if="showMainList">
                <div class="spaced-container">
                    <template v-if="totalRecordCount >0">
                        <div class="col-12 page_title paddingnone" v-on:click="hideShowList('Inventory')">
                            <div class="page_title_left">
                                <span>Total <b>{{totalRecordCount}}</b> record(s) found for search: <b>{{searchText}}</b></span>
                            </div>
                        </div>
                        <template v-if="permissionNameArray.includes('can_list_inventory_master') && InventorySearchData.data.length > 0">
                            <div class="col-12 page_title paddingnone" v-on:click="hideShowList('Inventory')">
                                <div class="page_title_left">
                                    <span>Inventory</span>
                                    <p v-if="InventorySearchData.totalRecords > 0">{{InventorySearchData.totalRecords}} Results found</p>
                                </div>
                                <!--<router-link class="mr-2 btn btn-primary waves-effect pull-right" title='Add Inventory' v-bind:to="{name: 'AssetTabView'}">
                                    <span>Add Inventory Tab</span>
                                </router-link>-->
                            </div>
                            <div class="col-12 paddingnone" v-show="InventorySearchData.showList">
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <thead>
                                            <tr>
                                                <template v-for="(header, index) in InventorySearchData.headers">
                                                    <th :key="index" scope="col">{{ header.name }}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, dataIndex) in InventorySearchData.data">
                                                <tr :key="dataIndex">
                                                    <td class="text-center">
                                                        <span v-if="data.status == 'Active' || data.status == 'Live'" class="badge badge-primary badge-pill bg-color-71aa3f">
                                                            {{data.status}}
                                                        </span>
                                                        <span v-else-if="data.status == 'Hold'" class="badge badge-primary badge-pill bg-color-0771b4">
                                                            {{data.status}} <template v-if="data.hold_status_id != null && data.hold_status_id != 'null'">({{data.hold_status_name}})</template>
                                                        </span>
                                                        <span v-else-if="data.status == null"  class="">
                                                        </span>
                                                        <span v-else  class="badge badge-primary badge-pill bg-color-c3202b">
                                                            {{data.status}}
                                                        </span>
                                                    </td>
                                                    <td class="text-center">{{ data.unit }}</td>
                                                    <td>{{ data.vin }}</td>
                                                    <td class="text-center">{{ data.model }}</td>
                                                    <td class="text-center">{{ data.cab_style }}</td>
                                                    <td class="text-center">{{ data.cab_type_name }}</td>
                                                    <td class="text-right">{{ data.retail_price }}</td>
                                                    <td class="text-right">{{ data.purchase_age }}</td>
                                                    <!-- <td class="text-center">{{ data.purchase_date }}</td> -->
                                                    <td class="text-center">{{ data.sale_date }}</td>
                                                    <td class="text-left">{{ data.salesman_name }}</td>
                                                    <td class="text-center">{{ data.is_truck }}</td>
                                                    <td class="text-right">{{ data.year }}</td>
                                                    <td class="text-right">{{ data.odometer }}</td>
                                                    <td class="text-center">{{ data.lot_city_lot }}</td>
                                                    <td class="text-center">{{ data.company_name }}</td>
                                                    <td class="text-center">{{ data.engine_make_name }}</td>
                                                    <td class="text-center">{{ data.engine_model }}</td>
                                                    <td class="text-center">{{ data.engine_hp }}</td>
                                                    <!-- <td class="text-center">{{data.trans_type_name}}</td>
                                                    <td class="text-center">{{data.trans_make_name}}</td> -->
                                                    <td class="text-center">{{data.trans_model}}</td>
                                                    <td class="text-left">{{ data.vendor }}</td>
                                                    <td class="text-left">{{data.acquired_by_name}}</td>
                                                    <td class="text-center">{{data.dtr_trade_terms_name}}</td>
                                                    <td class="text-center">{{data.fleet_name}}</td>
                                                    <td class="text-center">{{data.gvw}}</td>
                                                    <!-- <td class="text-center">{{data.trans_speed}}</td> -->
                                                    <!-- <td class="text-left">{{data.acquired_by_name}}</td> -->
                                                    <td class="text-center">{{data.acquire_on_date}}</td>
                                                    <td class="text-left">{{data.customer_name_full}}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                        <template v-if="permissionNameArray.includes('can_list_expense_master') && ExpenseSearchData.data.length > 0">
                            <div class="col-12 page_title paddingnone" v-on:click="hideShowList('Expenses')">
                                <div class="page_title_left">
                                    <span>Expenses</span>
                                    <p v-if="ExpenseSearchData.totalRecords > 0">{{ExpenseSearchData.totalRecords}} Results found</p>
                                </div>
                                <!--<router-link class="mr-2 btn btn-primary waves-effect pull-right" title='Add Inventory' v-bind:to="{name: 'AssetTabView'}">
                                    <span>Add Inventory Tab</span>
                                </router-link>-->
                            </div>
                            <div class="col-12 paddingnone" v-show="ExpenseSearchData.showList">
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <thead>
                                            <tr>
                                                <template v-for="(header, index) in ExpenseSearchData.headers">
                                                    <th :key="index" scope="col">{{ header.name }}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, dataIndex) in ExpenseSearchData.data">
                                                <tr :key="dataIndex">
                                                    <td>{{ data.stock }}</td>
                                                    <td>{{ data.vinNo }}</td>
                                                    <td>{{ data.department }}</td>
                                                    <td>{{ data.vendor }}</td>
                                                    <td>{{ data.excede_pur_id }}</td>
                                                    <td>{{ data.invoice_id }}</td>
                                                    <td>{{ data.expense_name }}</td>
                                                    <td>{{ data.reference_no }}</td>
                                                    <td>{{ data.estimated_cost }}</td>
                                                    <td>{{ data.actual_cost }}</td>
                                                    <td>{{ data.entered }}</td>
                                                    <td>{{ data.paid_date }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                        <template v-if="permissionNameArray.includes('can_list_user') && UserSearchData.data.length > 0">
                            <div class="col-12 page_title paddingnone" v-on:click="hideShowList('User')">
                                <div class="page_title_left">
                                    <span>Users</span>
                                    <p v-if="UserSearchData.totalRecords > 0">{{UserSearchData.totalRecords}} Results found</p>
                                </div>
                                <!--<router-link class="mr-2 btn btn-primary waves-effect pull-right" title='Add Inventory' v-bind:to="{name: 'AssetTabView'}">
                                    <span>Add Inventory Tab</span>
                                </router-link>-->
                            </div>
                            <div class="col-12 paddingnone" v-show="UserSearchData.showList">
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <thead>
                                            <tr>
                                                <template v-for="(header, index) in UserSearchData.headers">
                                                    <th :key="index" scope="col">{{ header.name }}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, dataIndex) in UserSearchData.data">
                                                <tr :key="dataIndex">
                                                    <td>{{ data.emp_id }}</td>
                                                    <td>{{ data.name }}</td>
                                                    <td>{{ data.username }}</td>
                                                    <td>{{ data.email }}</td>
                                                    <td>{{ data.role_name }}</td>
                                                    <td>{{ data.department }}</td>
                                                    <td>{{ data.home_location_name }}</td>
                                                    <td>{{ data.created_at }}</td>
                                                    <td>{{ data.last_login }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                        <template v-if="permissionNameArray.includes('can_list_customer_master') && ContactSearchData.data.length > 0">
                            <div class="col-12 page_title paddingnone" v-on:click="hideShowList('Contact')">
                                <div class="page_title_left">
                                    <span>Customer</span>
                                    <p v-if="ContactSearchData.totalRecords > 0">{{ContactSearchData.totalRecords}} Results found</p>
                                </div>
                                <!--<router-link class="mr-2 btn btn-primary waves-effect pull-right" title='Add Inventory' v-bind:to="{name: 'AssetTabView'}">
                                    <span>Add Inventory Tab</span>
                                </router-link>-->
                            </div>
                            <div class="col-12 paddingnone" v-show="ContactSearchData.showList">
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <thead>
                                            <tr>
                                                <template v-for="(header, index) in ContactSearchData.headers">
                                                    <th :key="index" scope="col">{{ header.name }}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, dataIndex) in ContactSearchData.data">
                                                <tr :key="dataIndex">
                                                    <td>{{ data.contact_group_name }}</td>
                                                    <td>{{ data.code }}</td>
                                                    <td>{{ data.first_name }}</td>
                                                    <td>{{ data.last_name }}</td>
                                                    <td>{{ data.email }}</td>
                                                    <td>{{ data.job_title }}</td>
                                                    <td>{{ data.manage_by_id }}</td>
                                                    <!--<td>{{ data.created_at }}</td>
                                                    <td>{{ data.updated_at }}</td>-->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                        <template v-if="permissionNameArray.includes('can_list_vendor_master') && VendorSearchData.data.length > 0">
                            <div class="col-12 page_title paddingnone" v-on:click="hideShowList('Vendor')">
                                <div class="page_title_left">
                                    <span>Vendor</span>
                                    <p v-if="VendorSearchData.totalRecords > 0">{{VendorSearchData.totalRecords}} Results found</p>
                                </div>
                            </div>
                            <div class="col-12 paddingnone" v-show="VendorSearchData.showList">
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <thead>
                                            <tr>
                                                <template v-for="(header, index) in VendorSearchData.headers">
                                                    <th :key="index" scope="col">{{ header.name }}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, dataIndex) in VendorSearchData.data">
                                                <tr :key="dataIndex">
                                                    <td>{{ data.vendor_id }}</td>
                                                    <td>{{ data.vendor_group_name }}</td>
                                                    <td>{{ data.code }}</td>
                                                    <td>{{ data.first_name }}</td>
                                                    <td>{{ data.last_name }}</td>
                                                    <td>{{ data.email }}</td>
                                                    <td>{{ data.job_title }}</td>
                                                    <!-- <td>{{ data.manage_by_id }}</td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="page_title_left">
                            <span><b>{{totalRecordCount}}</b> record found for search: <b>{{searchText}}</b></span>
                        </div>
                    </template>
                </div>
            </div>
        <!-- </template>
        <template v-else>
            <no-access></no-access>
        </template> -->
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";

    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import { VueContext } from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListAsset',
        mixins: [commonMixins],
        components: {
            'no-access': NoAccessPage,
            VueContext
        },
        data() {
            return {
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [25, 50, 100, 200, 500],
                assetArr: [],
                headers: [],
                filterheaders: [],
                filters: {
                },
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: '',
                selectAll: false,
                permissionNameArray: ['can_list_inventory_master','can_list_expense_master','can_list_user','can_list_customer_master','can_list_vendor_master'],
                actionDisplay: true,
                showMainList: true,
                showExpenseForm: false,
                globalSlug: "",
                assetId: 0,
                // when set to true, the context  menu will close when clicked on
                closeOnClick: true,
                // when set to true, the context  menu will close when the window is scrolled
                closeOnScroll: true,
                // This is how the component is able to find each menu item. Useful if you use non-recommended markup
                itemSelector: ['.custom-item-class'],
                loginId: 0,
                searchText:'',
                InventorySearchData:{
                    headers:[],
                    data:[],
                    totalRecords:0,
                    showList:true
                },
                ExpenseSearchData:{
                    headers:[],
                    data:[],
                    totalRecords:0,
                    showList:true
                },
                UserSearchData:{
                    headers:[],
                    data:[],
                    totalRecords:0,
                    showList:true
                },
                ContactSearchData:{
                    headers:[],
                    data:[],
                    totalRecords:0,
                    showList:true
                },
                VendorSearchData:{
                    headers:[],
                    data:[],
                    totalRecords:0,
                    showList:true
                },
            }
        },
        computed: {
            totalRecordCount() {
                return self.InventorySearchData.totalRecords + self.UserSearchData.totalRecords + self.ExpenseSearchData.totalRecords + self.ContactSearchData.totalRecords + self.VendorSearchData.totalRecords;
            }
        },
        beforeMount() {
            self = this;
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        watch: {
            /*selectedRows: function (selectedRows) {
             console.log(selectedRows.length);
             },
             /*selectAll: function (selectAll) {
             self.clickHeaderCheckBox();
             },*/
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            userObj = localStorage.getItem("user_obj");
            let search = self.$route.query.search;
            // Check if search is defined and its length is greater than 2
            if (search !== undefined && search.length > 2) {
                self.searchText = search;
                self.asyncFunc();
            }

            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson['id'];
            }
        },
        methods: {
            async asyncFunc() {
                // fetch data from a url endpoint
                var config = {
                        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                    };
                var fields = {
                    module: 'Inventory',
                    search: self.searchText,
                };
                self.InventorySearchData.headers=[];
                self.InventorySearchData.data=[];
                self.InventorySearchData.totalRecords=0;
                self.InventorySearchData.showList=true;
                await HTTP.post("/global/search", fields, config)
                            .then(function (data) {
                                if (data.status == 200) {
                                    if(data.data.content.data != 'undefined' && data.data.content.data.length != 'undefined'){
                                        self.InventorySearchData.headers = data.data.content.tableHeaders;
                                        self.InventorySearchData.data = data.data.content.data;
                                        self.InventorySearchData.totalRecords = data.data.content.data.length;
                                    }
                                }
                            })
                            .catch(function (error) {
                                //self.catchHandler(err, function () {});
                            });

                // Expense
                var fields = {
                    module: 'Expense',
                    search: self.searchText,
                };
                self.ExpenseSearchData.headers=[];
                self.ExpenseSearchData.data=[];
                self.ExpenseSearchData.totalRecords=0;
                self.ExpenseSearchData.showList=true;
                await HTTP.post("/global/search", fields, config)
                .then(function (data) {
                    //alert(data.data.content.data.length);
                    if (data.status == 200) {
                        if(data.data.content.data != 'undefined' && data.data.content.data.length != 'undefined'){
                            self.ExpenseSearchData.headers = data.data.content.tableHeaders;
                            self.ExpenseSearchData.data = data.data.content.data;
                            self.ExpenseSearchData.totalRecords = data.data.content.data.length;
                        }
                    }
                })
                .catch(function (error) {
                    //self.catchHandler(err, function () {});
                });

                // User
                var fields = {
                    module: 'User',
                    search: self.searchText,
                };
                self.UserSearchData.headers=[];
                self.UserSearchData.data=[];
                self.UserSearchData.totalRecords=0;
                self.UserSearchData.showList=true;
                await HTTP.post("/global/search", fields, config)
                .then(function (data) {
                    if (data.status == 200) {
                        if(data.data.content.data != 'undefined' && data.data.content.data.length != 'undefined'){
                            self.UserSearchData.headers = data.data.content.tableHeaders;
                            self.UserSearchData.data = data.data.content.data;
                            self.UserSearchData.totalRecords = data.data.content.data.length;
                        }
                    }
                })
                .catch(function (error) {
                    //self.catchHandler(err, function () {});
                });

                // Contact
                var fields = {
                    module: 'Contact',
                    search: self.searchText,
                };
                self.ContactSearchData.headers=[];
                self.ContactSearchData.data=[];
                self.ContactSearchData.totalRecords=0;
                self.ContactSearchData.showList=true;
                await HTTP.post("/global/search", fields, config)
                .then(function (data) {
                    if (data.status == 200) {
                        if(data.data.content.data != 'undefined' && data.data.content.data.length != 'undefined'){
                            self.ContactSearchData.headers = data.data.content.tableHeaders;
                            self.ContactSearchData.data = data.data.content.data;
                            self.ContactSearchData.totalRecords = data.data.content.data.length;
                        }
                    }
                })
                .catch(function (error) {
                    //self.catchHandler(err, function () {});
                });

                // Vendor
                var fields = {
                    module: 'Vendor',
                    search: self.searchText,
                };
                self.VendorSearchData.headers=[];
                self.VendorSearchData.data=[];
                self.VendorSearchData.totalRecords=0;
                self.VendorSearchData.showList=true;
                await HTTP.post("/global/search", fields, config)
                .then(function (data) {
                    if (data.status == 200) {
                        if(data.data.content.data != 'undefined' && data.data.content.data.length != 'undefined'){
                            self.VendorSearchData.headers = data.data.content.tableHeaders;
                            self.VendorSearchData.data = data.data.content.data;
                            self.VendorSearchData.totalRecords = data.data.content.data.length;
                        }
                    }
                })
                .catch(function (error) {
                    //self.catchHandler(err, function () {});
                });
            },
            hideShowList(listName){
                if(listName == 'Inventory'){
                    self.InventorySearchData.showList = !self.InventorySearchData.showList;
                }if(listName == 'Expenses'){
                    self.ExpenseSearchData.showList = !self.ExpenseSearchData.showList;
                }if(listName == 'User'){
                    self.UserSearchData.showList = !self.UserSearchData.showList;
                }if(listName == 'Contact'){
                    self.ContactSearchData.showList = !self.ContactSearchData.showList;
                }if(listName == 'Vendor'){
                    self.VendorSearchData.showList = !self.VendorSearchData.showList;
                }

            }
        },

    }
    EventBus.$on("global-search-changed", function (search) {
        if(search != 'undefined' && search.length > 2 ){
                self.searchText = search;
                self.asyncFunc();
            }
    });

    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            /* if (!self.permissionNameArray.includes('can_add_inventory_master') && !self.permissionNameArray.includes('can_delete_inventory_master') && !self.permissionNameArray.includes('can_view_inventory_master') && !self.permissionNameArray.includes('can_spec_sheet_inventory_master') && !self.permissionNameArray.includes('can_add_expense_master') && !self.permissionNameArray.includes('can_active_deactive_status_inventory_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            } */
        }
    });
</script>
