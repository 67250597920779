<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <!--<th class="">{{staticInputLabelMessages.purchase_price_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('purchasePriceInput')"></i></th>-->
                                <!--<th class="">{{staticInputLabelMessages.floor_plan_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('floorPlanInput')"></i></th>-->
                                <th class="">{{staticInputLabelMessages.min_retail_usd_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('minRetailUsdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.retail_usd_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('reatilUsdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.high_retail_usd_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('highRatailUsdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.whole_sale_usd_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('wholesaleUsdInput')"></i></th>
                                <!--<th class="">{{staticInputLabelMessages.sale_price_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('salePriceInput')"></i></th>-->
                                <th class="">{{staticInputLabelMessages.show_price_online_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('showPriceOnlineIdInput')"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Purchase Price-->
                                <!--<td class="">
                                    <input style="min-width: 135px;" id="purchasePriceInput" @input="purchasePriceErrorMsg[asset.asset_id] = ''" v-model="purchasePriceInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{purchasePriceErrorMsg[asset.asset_id]}}</span>
                                    &lt;!&ndash;{{asset.vin_no}}&ndash;&gt;
                                </td>-->
                                <!--Floor Plan-->
                                <!-- <td class="">
                                    <input id="floorPlanInput" @input="floorPlanErrorMsg[asset.asset_id] = ''" v-model="floorPlanInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{floorPlanErrorMsg[asset.asset_id]}}</span>
                                </td> -->
                                <!--Min Retail USD-->
                                <td class="">
                                    <input style="min-width: 135px;" id="minRetailUsdInput" @input="minRetailUsdErrorMsg[asset.asset_id] = ''" v-model="minRetailUsdInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{minRetailUsdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                                <!--Retail USD-->
                                <td class="">
                                    <input style="min-width: 135px;" id="reatilUsdInput" @input="reatilUsdErrorMsg[asset.asset_id] = ''" v-model="reatilUsdInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{reatilUsdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.classification}}-->
                                </td>
                                <!--High Retail USD-->
                                <td class="">
                                    <input style="min-width: 135px;" id="highRatailUsdInput" @input="highRatailUsdErrorMsg[asset.asset_id] = ''" v-model="highRatailUsdInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{highRatailUsdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                                <!--Whole Sale USD-->
                                <td class="">
                                    <input style="min-width: 135px;" id="wholesaleUsdInput" @input="wholesaleUsdErrorMsg[asset.asset_id] = ''" v-model="wholesaleUsdInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{wholesaleUsdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                                <!--Sale Price-->
                                <!--<td class="">
                                    <input id="salePriceInput" @input="salePriceErrorMsg[asset.asset_id] = ''" v-model="salePriceInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{salePriceErrorMsg[asset.asset_id]}}</span>
                                    &lt;!&ndash;{{asset.vin_no}}&ndash;&gt;
                                </td>-->
                                <td class="">
                                    <v-select style="min-width: 130px;" v-model="showPriceOnlineIdInput[asset.asset_id]"
                                              @input="showPriceOnlineIdErrorMsg[asset.asset_id] = ''"
                                              name="modelIdInput"
                                              id="modelIdInput"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="showPriceOnlineArr"
                                              :placeholder="staticMessages.show_price_online_place_holder"
                                    >
                                    </v-select>
                                    <span class="help is-danger">{{showPriceOnlineIdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.mode_id}} {{asset.showPriceOnlineIdErrorMsg}}-->
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                purchasePriceInput: [],
                purchasePriceErrorMsg: [],
                minRetailUsdInput : [],
                minRetailUsdErrorMsg : [],
                reatilUsdInput : [],
                reatilUsdErrorMsg : [],
                highRatailUsdInput : [],
                highRatailUsdErrorMsg : [],
                wholesaleUsdInput : [],
                wholesaleUsdErrorMsg : [],
                floorPlanInput : [],
                floorPlanErrorMsg : [],
                salePriceInput : [],
                salePriceErrorMsg : [],
                showPriceOnlineIdInput : [],
                showPriceOnlineIdErrorMsg : [],
                showPriceOnlineArr : [{ text : "Yes", value : "yes" }, { text : "No", value : "no" }],

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_add_inventory_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";

                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.minRetailUsdInput[self.asset.content[i].asset_id] = self.asset.content[i].min_retail_usd == null ? 0 : self.asset.content[i].min_retail_usd;
                        self.reatilUsdInput[self.asset.content[i].asset_id] = self.asset.content[i].retail_price == null ? 0 : self.asset.content[i].retail_price;
                        self.highRatailUsdInput[self.asset.content[i].asset_id] = self.asset.content[i].high_retail_usd == null ? 0 : self.asset.content[i].high_retail_usd;
                        self.wholesaleUsdInput[self.asset.content[i].asset_id] = self.asset.content[i].wholeshale_usd == null ? 0 : self.asset.content[i].wholeshale_usd;
                        self.showPriceOnlineIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].show_price_online != null && typeof self.asset.content[i].show_price_online != "undefined" && self.asset.content[i].show_price_online != '') {
                            self.showPriceOnlineIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].show_price_online), value: self.asset.content[i].show_price_online};
                        }

                    }
                });
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'purchasePriceInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.purchasePriceInput[self.asset.content[i].asset_id];
                                    self.purchasePriceInput = [];
                                } 
                                self.purchasePriceInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'floorPlanInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.floorPlanInput[self.asset.content[i].asset_id];
                                    self.floorPlanInput = [];
                                } 
                                self.floorPlanInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'minRetailUsdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.minRetailUsdInput[self.asset.content[i].asset_id];
                                    self.minRetailUsdInput = [];
                                } 
                                self.minRetailUsdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'reatilUsdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.reatilUsdInput[self.asset.content[i].asset_id];
                                    self.reatilUsdInput = [];
                                } 
                                self.reatilUsdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'highRatailUsdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.highRatailUsdInput[self.asset.content[i].asset_id];
                                    self.highRatailUsdInput = [];
                                } 
                                self.highRatailUsdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'wholesaleUsdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.wholesaleUsdInput[self.asset.content[i].asset_id];
                                    self.wholesaleUsdInput = [];
                                } 
                                self.wholesaleUsdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'salePriceInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.salePriceInput[self.asset.content[i].asset_id];
                                    self.salePriceInput = [];
                                } 
                                self.salePriceInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'showPriceOnlineIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.showPriceOnlineIdInput[self.asset.content[i].asset_id];
                                    self.showPriceOnlineIdInput = [];
                                } 
                                self.showPriceOnlineIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            // 'purchase_price' : self.purchasePriceInput[self.asset.content[i].asset_id],
                            // 'floor_plan' : self.floorPlanInput[self.asset.content[i].asset_id].value,
                            'min_retail_usd' : self.minRetailUsdInput[self.asset.content[i].asset_id],
                            'retail_usd' : self.reatilUsdInput[self.asset.content[i].asset_id],
                            'high_retail_usd' : self.highRatailUsdInput[self.asset.content[i].asset_id],
                            'wholesale_usd' : self.wholesaleUsdInput[self.asset.content[i].asset_id],
                            // 'sale_price' : self.salePriceInput[self.asset.content[i].asset_id].value,
                            'show_price_online' : self.showPriceOnlineIdInput[self.asset.content[i].asset_id].value,
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null){
                            /*console.log(self.assetIdInput[i]);*/
                            /*console.log(self.purchasePriceInput[i]);*/
                            /*if (!self.purchasePriceInput[i]) {
                                Vue.set(self.purchasePriceErrorMsg, i, "Please enter purchase price");
                                if (checked) {
                                    checked = false;
                                }
                            } else if (self.purchasePriceInput[i] && !self.numberFormateValidation(self.purchasePriceInput[i])) {
                                Vue.set(self.purchasePriceErrorMsg, i, "Please enter numeric value for purchase price");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*if (!self.minRetailUsdInput[i] && self.minRetailUsdInput[i] !== 0) {
                                Vue.set(self.minRetailUsdErrorMsg, i, "Please enter min retail usd price");
                                if (checked) {
                                    checked = false;
                                }
                            } else if (self.minRetailUsdInput[i] && !self.numberFormateValidation(self.minRetailUsdInput[i])) {
                                Vue.set(self.minRetailUsdErrorMsg, i, "Please enter numeric value for min retail usd price");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*if (!self.reatilUsdInput[i] && self.reatilUsdInput[i] !== 0) {
                                Vue.set(self.reatilUsdErrorMsg, i, "Please enter retail usd");
                                if (checked) {
                                    checked = false;
                                }
                            }  else if (self.reatilUsdInput[i] && !self.numberFormateValidation(self.reatilUsdInput[i])) {
                                Vue.set(self.reatilUsdErrorMsg, i, "Please enter numeric value for retail usd");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*if (!self.highRatailUsdInput[i] && self.highRatailUsdInput[i] !== 0) {
                                Vue.set(self.highRatailUsdErrorMsg, i, "Please enter high retail usd");
                                if (checked) {
                                    checked = false;
                                }
                            } else if (self.highRatailUsdInput[i] && !self.numberFormateValidation(self.highRatailUsdInput[i])) {
                                Vue.set(self.highRatailUsdErrorMsg, i, "Please enter numeric value for high retail usd");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*if (!self.wholesaleUsdInput[i] && self.wholesaleUsdInput[i] !== 0) {
                                Vue.set(self.wholesaleUsdErrorMsg, i, "Please enter whole sale usd");
                                if (checked) {
                                    checked = false;
                                }
                            } else if (self.wholesaleUsdInput[i] && !self.numberFormateValidation(self.wholesaleUsdInput[i])) {
                                Vue.set(self.wholesaleUsdErrorMsg, i, "Please enter numeric value for whole sale usd");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                        }
                    }
                });
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/prices", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 7);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;

                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>