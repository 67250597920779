<template>
  <!--<div class="spaced-container">-->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="
        permissionNameArray.includes('can_add_used_truck_holds_submission_form')
      "
    >
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <template
            v-if="
              permissionNameArray.includes(
                'can_list_used_truck_holds_submission_form'
              )
            "
          >
            <button
              type="submit"
              v-on:click.prevent="buttonBack()"
              class="btn btn-primary waves-effect pull-right"
            >
              Back
            </button>
          </template>
        </div>

        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->

          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <!--Seles Person -->
              <div class="col-md-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.seles_person }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="userId"
                    name="asset"
                    @input="userError = ''"
                    label="text"
                    :filterable="false"
                    :options="userArr"
                    @search="onSearchUser"
                    :clearable="false"
                    :placeholder="staticMessages.sales_person_place_holder"
                    :disabled="true"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isUserRecordNotFound">
                        {{ staticMessages.sales_person_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ userError }}</span>
                </div>
              </div>

              <!-- Customer Name -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="customerNameError = ''"
                    id="otherActivityTypeText"
                    v-model="customerName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ customerNameError }}</span>
                </div>
              </div>

              <div class="col-12 forms-block">
                <button class="btn btn-success" @click="checkInput">
                  <i class="fa fa-plus" aria-hidden="false"></i>
                </button>
                <span>{{ staticInputLabelMessages.click_to_add_more }}</span>
              </div>
              <div class="clearfix"></div>

              <template v-for="(input, index) in inputs">
                <!--VIN No. -->
                <div class="col-1 forms-block" :key='"Delete" + index'>
                  <button class="btn btn-danger" @click="deleteRow(index)">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div class="col-5 forms-block" :key='"VinNo" + index'>
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.vin_no_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      v-model="input.asset"
                      name="asset"
                      @input="input.assetError = ''"
                      label="text"
                      :filterable="false"
                      :options="assetArr"
                      @search="onSearchAsset"
                      :clearable="false"
                      :placeholder="staticMessages.asset_place_holder"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isAssetRecordNotFound">
                          {{ staticMessages.asset_search }}
                        </template>
                        <template v-else>
                          {{ staticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{input.assetError}}</span>
                  </div>
                </div>

                <!--Selling Price -->
                <div class="col-6  forms-block" :key='"SellingPrice" + index'>
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.selling_price_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      @input="input.sellingPriceError = ''"
                      id="sellingPrice"
                      v-model="input.sellingPrice"
                      class="form-control"
                      type="text"
                      maxlength="17"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{input.sellingPriceError}}</span>
                  </div>
                </div>
              </template>

              <!-- Has customer approved finance terms -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{
                      staticInputLabelMessages.has_customer_approved_finance_terms
                    }}
                  </div>
                  <div class="requiredstar">*</div>
                </div>
                <div class="col-9 paddingleft0">
                  <b-form-radio-group
                    @input="customerAppprovedError = ''"
                    v-model="customerAppproved"
                    :options="hasApproveFinanceTermsOptions"
                    name="customerAppproved"
                  >
                  </b-form-radio-group>
                  <span class="help is-danger">{{
                    customerAppprovedError
                  }}</span>
                </div>
              </div>

              <!-- Has customer seen truck/trailer in person -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{
                      staticInputLabelMessages.has_customer_seen_truck_trailer_in_person
                    }}
                  </div>
                  <div class="requiredstar">*</div>
                </div>
                <div class="col-9 paddingleft0">
                  <b-form-radio-group
                    @input="customerSeenError = ''"
                    v-model="customerSeen"
                    :options="hasSeenTruckInPersonOptions"
                    name="customerSeen"
                  >
                  </b-form-radio-group>
                  <span class="help is-danger">{{
                    customerSeenError
                  }}</span>
                </div>
              </div>

              <!--Notes-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.notes_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <textarea
                    @input="noteError = ''"
                    id="notes"
                    v-model="notes"
                    class="form-control"
                    maxlength="50000"
                  ></textarea>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ noteError }}</span>
                </div>
              </div>

              <!-- Referral Source -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.referral_source}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="holdReferralSource" v-model="referral_source" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->
              <div class="col-md-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.referral_source }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="referralSource"
                    name="referralSource"
                    label="text"
                    :options="referralSourceArr"
                    @search="onSearchReferralSource"
                    :filterable="false"
                    :clearable="false"
                    :placeholder="staticMessages.select_referral_source"
                    @input="referralSourceError = ''"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isReferralSourceRecordNotFound">
                        {{ staticMessages.referral_source_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ referralSourceError }}</span>
                </div>
              </div>
              <!-- Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="phoneError = ''"
                    id="phone"
                    v-model="phone"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div v-if="checkIsSalesman" class="requiredstar">*</div> -->
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ phoneError }}</span>
                </div>
              </div>
              <!--Attach approval or deposit check image-->
              <div
                class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block"
              >
                <div class="form-group">
                  <label for="dropzone">{{
                    staticInputLabelMessages.attach_approval_or_deposite_check_image
                  }}</label>
                  <div class="requiredstar">*</div>
                  <vue-dropzone
                    ref="dropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-success="uploadDocument"
                    @vdropzone-removed-file="removedSelectedFile"
                    @vdropzone-error="fileError"
                    @vdropzone-file-added="fileAdd"
                    @vdropzone-total-upload-progress="progressOfUload"
                    :useCustomSlot="true"
                    :destroyDropzone="false"
                  >
                    <div class="dropzone-custom-content">
                      <h4 class="dropzone-custom-title">
                        Drop files here or click to upload.
                      </h4>
                      <div class="subtitle">
                        (Select files from your computer.)
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
                <span class="help is-danger">{{ fileUploadError }}</span>
              </div>
            </div>

            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  class="alert alert-danger"
                  id="alertError"
                  style="display: none;"
                >
                  <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input
                id="addUpdateUsedTruckHoldSubmissionBtn"
                :disabled="disableButtons.save"
                type="submit"
                :value="buttonNames.save"
                class="btn btn-primary"
                v-on:click.prevent="addUpdateUsedTruckHoldSubmission()"
              />
              <input
                v-if="
                  permissionNameArray.includes(
                    'can_list_used_truck_holds_submission_form'
                  )
                "
                id="cancelAsset"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>
          </div>

          <!-- Forms -->
        </div>
      </div>
      <!--page content-->
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!--</div>-->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../.././event-bus";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";

Vue.component("app-footer", Footer);

export default {
  name: "AddUpdateAsset",
  components: {
    datePicker,
    vueDropzone: vue2Dropzone,
    "no-access": NoAccessPage
  },
  mixins: [commonMixins],
  data() {
    return {
      dropzoneOptions: {
        timeout: 3000000,
        url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
        method: "POST",
        paramName: "file",
        autoProcessQueue: true,
        maxFilesize: 25, //MB
        dictFileTooBig:
          "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
        thumbnailWidth: 150, //px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictCancelUpload: "Cancel",
        dictRemoveFile: "Remove",
        acceptedFiles: "",
        maxFiles: 5,
        dictMaxFilesExceeded: "Only 5 files are allowed",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
          // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
          // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
          //'Content-Type': 'application/x-www-form-urlencoded'
        }
      },

      imagesPaths: [],
      imagesType: [],
      imagesSize: [],

      staticMessages: "",
      staticInputLabelMessages: "",
      contentClass: "admin-page-content",
      inputs: [
        {
          asset: "",
          assetError: "",
          sellingPrice: "",
          sellingPriceError: ""
        }
      ],
      tempInputsObject: {
        asset: "",
        assetError: "",
        sellingPrice: "",
        sellingPriceError: ""
      },
      customerAppproved: "",
      customerApprovedDefaultValue: "no",
      customerAppprovedError: "",
      customerSeen: "",
      customerSeenDefaultValue: "no",
      customerSeenError: "",
      notes: "",
      noteError: "",
      fileUploadError: "",

      phone: "",
      phoneError: "",

      asset: "",
      assetArr: [],
      userId: "",
      userArr: [],
      userError: "",
      isAssetRecordNotFound: false,
      isUserRecordNotFound: false,
      sellingPrice: "",

      customerName: "",
      customerNameError: "",

      /* referral_source : "", */
      referralSource: "",
      referralSourceArr: [],
      isReferralSourceRecordNotFound: false,
      referralSourceError : '',

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel"
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false
      },
      maxInputLength: 2,
      userArrayLength: 0,
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Used Truck Holds Submission Form",
      hasSeenTruckInPersonOptions: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" }
      ],
      hasApproveFinanceTermsOptions: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
        { text: "Cash", value: "cash" },
        { text: "OSB", value: "osb" }
      ],
      permissionNameArray: ["can_add_used_truck_holds_submission_form"],
      totalFileSize : 0,
      maxTotalFilesSize : 25
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    inputs: {
      handler(val) {
        for (let i = 0; i < val.length; i++) {
          /*alert(JSON.stringify(val[i].asset.text));
                        alert(JSON.stringify(self.assetArr));*/
          self.assetArr = self.removeByAttr(
            self.assetArr,
            "text",
            val[i].asset.text
          );
        }
      },
      deep: true
    }
  },
  created : function () {
    self = this;
  },
  mounted: function() {
    /* Redirect to login page logic start */
    let userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.selectDefaultUser();
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.customerAppproved = self.customerApprovedDefaultValue;
    self.customerSeen = self.customerSeenDefaultValue;
    self.setActiveMenu("used_truck_holds_submission_form_add");
    EventBus.$emit(
      "menu-selection-changed",
      "used_truck_holds_submission_form_add"
    );

    $("#alertError").hide();
    self.getLoginIdPermissions();
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.contentClass = "spaced-container";
      self.buttonNames.save = "Update";
      $("#addAndContinueAssetBtn").hide();
      self.pageTitle = "Edit Inventory";
    }
    self.loadAllData(
      ["User", "ReferralSource"],
      true,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    /* self.loadAllData(['User'], true, self.loadUserTypeCallback, "undefined", ''); */
  },
  methods: {
    removeAllFiles() {
      let self = this;
      self.$refs.dropzone.removeAllFiles();
    },
    removeByAttr: function(arr, attr, value) {
      let i = arr.length;
      while (i--) {
        if (
          arr[i] &&
          arr[i].hasOwnProperty(attr) &&
          arguments.length > 2 &&
          arr[i][attr] === value
        ) {
          arr.splice(i, 1);
        }
      }
      return arr;
    },
    uploadDocument: function(file, response) {
      let self = this;
      self.disableButtons.save = false;
      self.disableButtons.saveAndContinue = false;
      self.disableButtons.cancel = false;

      self.fileUploadError = "";
      self.imagesPaths.push(response.content.file_path);
      self.imagesType.push(file.type);
      self.imagesSize.push(file.size);
      // this.$refs.dropzone.setOption('maxFiles', null);

      self.totalFileSize = self.totalFileSize + file.size;
      if (self.totalFileSize > (self.maxTotalFilesSize*1024*1024)) {
          self.showSAlert("You can upload upto 1MB file size!", "info", false);
          this.$refs.dropzone.removeFile(file);
      }
    },
    removedSelectedFile: function(file, xhr, error) {
      /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
      // this.$refs.dropzone.setOption('maxFiles', null);
      let self = this;
      self.disableButtons.save = false;
      self.disableButtons.saveAndContinue = false;
      self.disableButtons.cancel = false;

      let removeFile = "";
      if (this.$refs.dropzone.dropzone.options.maxFiles) {
        // this.$refs.dropzone.setOption('maxFiles', null);
      }
      /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
      if ("manuallyAdded" in file) {
        for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
          if (self.imagesPaths[i].indexOf(file.name) != -1) {
            removeFile = self.imagesPaths[i];
          }
        }
        // this.$refs.dropzone.setOption('maxFiles', null);
      } else {
        let filePath = JSON.parse(file.xhr.response);
        removeFile = filePath.content;
        self.removeFileFromServer(removeFile);
        removeFile = removeFile.file_path;
      }
      for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
        if (self.imagesPaths[i] === removeFile) {
          self.totalFileSize = self.totalFileSize - self.imagesSize[i];
          self.imagesPaths.splice(i, 1);
          self.imagesType.splice(i, 1);
          self.imagesSize.splice(i, 1);
          //break;       //<-- Uncomment  if only the first term has to be removed
        }
      }
      // this.$refs.dropzone.setOption('maxFiles', null);
      /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
    },
    removeFileFromServer: function(filePath) {
      let self = this;
      //console.log(filePath);return false;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        data: {
          filePath: filePath
        }
      };
      HTTP.delete("removedocument", config)
        .then(function(response) {
          /*console.log("File removed successfully");*/
        })
        .catch(function(err) {
          //self.catchHandler(err, function () {});
          if (err.response.status == 0) {
            self.error = "Remote server can not be reachable";
          } else {
            //redirect to login page if user not authenticated
            if (err.response.status == 401) {
              //clear previous data
              localStorage.removeItem("user_obj");
              localStorage.removeItem("api_token");
              localStorage.removeItem("profile_image");
              self.$router.push("/login");
              //EventBus.$emit("logged-in", true, "", "");
            }
            self.error = err.response.data.message;
          }
        });
    },
    fileError: function(file, xhr, error) {
      if (xhr.code == 400) {
        /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
        self.showSAlert(xhr.message, "error", false);
        /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
      }
    },
    fileAdd: function(file, xhr, error) {
      self.disableButtons.save = true;
      self.disableButtons.saveAndContinue = true;
      self.disableButtons.cancel = true;

      if (this.$refs.dropzone.dropzone.options.maxFiles) {
        // this.$refs.dropzone.setOption('maxFiles', null);
      }
      /* console.log("File Add"); */
    },
    progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress == 100) {
        self.disableButtons.save = false;
        self.disableButtons.cancel = false;
        self.disableButtons.saveAndContinue = false;
      } else {
        self.disableButtons.save = true;
        self.disableButtons.cancel = true;
        self.disableButtons.saveAndContinue = true;
      }
    },
    selectDefaultUser: function() {
      let userObj = JSON.parse(localStorage.getItem("user_obj"));
      let firstName = "";
      let lastName = "";
      let userId = 0;
      let fullName = "";
      if (userObj != null && userObj != "" && typeof userObj != "undefined") {
        firstName = userObj["first_name"];
        lastName = userObj["last_name"];
        userId = userObj["id"];
        fullName = firstName + " " + lastName;

        self.userId = { text: fullName, value: userId };
      }
    },
    deleteRow(index) {
      this.inputs.splice(index, 1);
    },
    onSearchAsset(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Asset"],
          true,
          self.loadAssetDataCallback,
          loading,
          search,
          "undefined",
          "1,2,4,5"
        );
      }
    },
    loadAssetDataCallback: function(callbackData) {
      self.isAssetRecordNotFound = false;
      self.assetArr = callbackData.Asset;
      if (self.assetArr.length == 0) {
        self.isAssetRecordNotFound = true;
      }
    },
    onSearchUser: function(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadUserTypeCallback,
          loading,
          search
        );
      }
    },
    loadUserTypeCallback: function(callbackData) {
      self.isUserRecordNotFound = false;
      self.userArr = callbackData.User;
      if (self.userArr.lenght == 0) {
        self.isUserRecordNotFound = true;
      }
    },
    loadMasterDataCallback: function(callbackData) {
      self.isUserRecordNotFound = self.isReferralSourceRecordNotFound = false;
      self.userArr = callbackData.User;
      if (self.userArr.lenght == 0) {
        self.isUserRecordNotFound = true;
      }
      self.referralSourceArr = callbackData.ReferralSource;
      if (self.referralSourceArr.lenght == 0) {
        self.isReferralSourceRecordNotFound = true;
      }
    },
    onSearchReferralSource: function(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["ReferralSource"],
          true,
          self.loadReferralSourceCallback,
          loading,
          search
        );
      }
    },
    loadReferralSourceCallback: function(callbackData) {
      self.isReferralSourceRecordNotFound = false;
      self.referralSourceArr = callbackData.ReferralSource;
      if (self.referralSourceArr.lenght == 0) {
        self.isReferralSourceRecordNotFound = true;
      }
    },
    returnData: function() {
      let data = {
        user: self.userId,
        customer_name: self.customerName,
        /* referral_source : self.referral_source, */
        referralSource: self.referralSource,
        inputs: self.inputs,
        imagePath: self.imagesPaths,
        imageSize: self.imagesSize,
        imageType: self.imagesType,
        customer_approved: self.customerAppproved,
        customer_seen: self.customerSeen,
        notes: self.notes,
        phone: self.phone
      };
      return data;
    },

    changedValue: function(value) {
      /*console.log(value);*/
    },
    buttonBack() {
      self.$router.push({ name: "ListUsedTruckHoldsSubmissionForm" });
    },
    clearDataOnSaveAndContinue: function() {
      self.selectDefaultUser();
      self.customerName = "";
      /* self.referral_source = ""; */
      self.referralSource = "";
      self.customerAppproved = self.customerApprovedDefaultValue;
      self.customerSeen = self.customerSeenDefaultValue;
      self.notes = "";
      self.phone = "";

      for (let i = self.inputs.length - 1; i >= 0; i--) {
        self.deleteRow(i);
      }
      self.imagesPaths = [];
      self.imagesType = [];
      self.imagesSize = [];
      // self.removeAllFiles();
    },
    showAlertOnSave: function(alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function() {
          $("#success-alert").slideUp(500);
        });
    },

    checkInput: function() {
      let checked = true;
      for (const element of self.inputs) {
        if (
          element.asset.value === "" ||
          element.asset.value === null ||
          typeof element.asset.value === "undefined"
        ) {
          element.assetError = "Please select VIN No.";
          if (checked) {
            checked = false;
          }
        }
        if (!element.sellingPrice) {
          element.sellingPriceError = "Please enter Selling Price";
          if (checked) {
            checked = false;
          }
        } else if (
          element.sellingPrice &&
          !self.numberFormateValidation(element.sellingPrice)
        ) {
          element.sellingPriceError = "Please enter numeric value for selling price";
          if (checked) {
            checked = false;
          }
        }
      }

      if(checked) {
        self.inputs.push(Vue.util.extend({}, self.tempInputsObject));
      }
      return checked;
    },
    checkForm: function() {
      let checked = true;
      for (const element of self.inputs) {
        if (
          element.asset.value === "" ||
          element.asset.value === null ||
          typeof element.asset.value === "undefined"
        ) {
          element.assetError = "Please select VIN No.";
          if (checked) {
            checked = false;
          }
        }
        if (!element.sellingPrice) {
          element.sellingPriceError = "Please enter Selling Price";
          if (checked) {
            checked = false;
          }
        } else if (
          element.sellingPrice &&
          !self.numberFormateValidation(element.sellingPrice)
        ) {
          element.sellingPriceError = "Please enter numeric value for selling price";
          if (checked) {
            checked = false;
          }
        }
      }
      if (self.userId == null || !self.userId.value) {
        self.userError = "Please select Salesman Name";
        if (checked) {
          checked = false;
        }
      }
      if (!self.customerName) {
        self.customerNameError = "Please enter customer name";
        if (checked) {
          checked = false;
        }
      }
      if (!self.notes) {
        self.noteError = "Please enter note";
        if (checked) {
          checked = false;
        }
      }
      if (self.imagesPaths.length === 0) {
        self.fileUploadError = "Please upload file";
        if (checked) {
          checked = false;
        }
      } else if(self.totalFileSize > (self.maxTotalFilesSize*1024*1024)) {
        self.fileUploadError = "You can upload upto "+self.maxTotalFilesSize+"MB file size!";
        if (checked) {
          checked = false;
        }
      }
      self.phoneError = "";
      if (/* self.checkIsSalesman && */ !self.phone) {
        self.phoneError = "Please enter phone";
        if (checked) {
          checked = false;
        }
      }
      if (self.phone && !self.validContactNumber(self.phone)) {
        self.phoneError = "Please enter valid phone";
        if (checked) {
          checked = false;
        }
      } else if (self.phone && !self.checkContactNoLength(self.phone)) {
        self.phoneError = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      self.customerAppprovedError = "";
      if (!self.customerAppproved) {
        self.customerAppprovedError = "Please select has customer approved finance terms";
        if (checked) {
          checked = false;
        }
      }

      self.customerSeenError = "";
      if (!self.customerSeen) {
        self.customerSeenError = "Please select has customer seen truck/trailer in person";
        if (checked) {
          checked = false;
        }
      }

      self.referralSourceError = "";
      if (!self.referralSource) {
        self.referralSourceError = "Please enter referral source";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    addUpdateUsedTruckHoldSubmission(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          let data = self.returnData();
          if (
            typeof self.$route.params.id != "undefined" &&
            self.$route.params.id != 0 &&
            self.$route.params.id != ""
          ) {
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post(
            "/usedtruckholdssubmissionform/add/update/vtc",
            data,
            config
          )
            .then(function(response) {
              if (
                typeof self.$route.params.id != "undefined" &&
                self.$route.params.id != 0 &&
                self.$route.params.id != ""
              ) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$route.params.id != 0 && self.$route.params.id != "") {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  self.clearDataOnSaveAndContinue();
                  setTimeout(function() {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    if (
                      self.permissionNameArray.includes(
                        "can_list_used_truck_holds_submission_form"
                      )
                    ) {
                      self.buttonBack();
                    }
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  let err = "";
                  for (const element of response.data.content) {
                    err += element + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function(err) {
              if (
                self.$route.params.id != 0 &&
                self.$route.params.id != "" &&
                typeof self.$route.params.id != "undefined"
              ) {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function() {});
            });
        }
      }, 200);
    }
  },
  computed: {
    checkIsSalesman() {
      let userObj = JSON.parse(localStorage.getItem("user_obj"));

      const roles = userObj.roles.map(role => role.name);
      return roles.includes("Salesman");
    }
  }
};
EventBus.$on("login-permissions", function(permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (const element of permissions) {
      self.permissionNameArray.push(element.permission_name);
    }
  }
});
</script>
