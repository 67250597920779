<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_po_request')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    <!--
                      If PO is 'Pending', Then Cancel PO Access Assigned To = Requestor, Manager, Admin & Status Modify Permission User
                    -->
                    <button type="submit" v-if="edit_id != 0 && oldPoCancel == 'No' && oldApprovalStatus == 'pending' && approvalLevel == 0
                        && (loginUsrEmail.includes(createdByUsername)
                        || permissionNameArray.includes('can_approve_reject_po_request')
                        || uniqueManagerIdArr.includes(loginUsrId))" v-on:click.prevent="cancelPO(edit_id)" class="btn btn-primary waves-effect pull-right">Cancel PO</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="salesmanId"
                                        name="salesmanId"
                                        id="salesmanId"
                                        label="text"
                                        @change="changedValue"
                                        @input="salesmanIdError = ''"
                                        :filterable="false"
                                        :options="salesmanIdArr"
                                        @search="onSearchSalesmanUser"
                                        :placeholder="staticMessages.salesman_user_place_holder"
                                        :disabled="true">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesmanNameRecordNotFound">
                                                {{staticMessages.salesman_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesmanIdError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- Sales Manager -->
                            <!-- <div v-show="isAbove500 == 'no'" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sales_manager}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{salesManager}}</span>
                                </div>
                            </div> -->
                            <!-- Vin -->
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vinError = ''" id="vin" v-model="vin" class="form-control" type="text"/>-->
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vinError}}</span> -->
                                <!--</div>
                            </div>-->

                            <!-- Request Number -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.request_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0">
                                        <input @input="requestNoError = ''" id="requestNo" v-model="requestNo" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{requestNoError}}</span>
                                    </template>
                                    <template v-else> -->
                                        <span class="form-control">{{requestNo}}</span>
                                    <!-- </template> -->
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- PO Number (Display on Requestor Side) -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0">
                                        <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{poNoError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{poNo}}</span>
                                    </template>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div> -->

                            <!-- PO Number(Display At Final Stage of Approval)-->
                            <!-- <template v-if="totalAmout <= 4000">
                                <template v-if="ApprovStatus == 'approve' && oldApprovalStatus == 'approve'">
                                  <template v-if="oldPoNo == null || oldPoNo == ''">
                                    <template v-if="defaultPOReqEmailArr.includes(POReqAdminEmailArr)">
                                      <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                          <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                          <div class="requiredstar">*</div>
                                          <span class="help is-danger">{{poNoError}}</span>
                                        </div>
                                      </div>
                                      <div class="col-6 forms-block"></div>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <span class="form-control">{{poNo}}</span>
                                      </div>
                                    </div>
                                    <div class="col-6 forms-block"></div>
                                  </template>
                                </template>-->
                                <!-- <template v-else-if="ApprovStatus == 'approve' && oldApprovalStatus == 'pending'">
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{poNoError}}</span>
                                        </div>
                                    </div>
                                    <div class="col-6 forms-block"></div>
                                </template> -->
                            <!-- </template>
                            <template v-else>
                                <template v-if="uniqueManagerIdArr.length == 1 && uniqueManagerIdArr.includes(createdBy)">
                                    <template v-if="ApprovStatus == 'approve' && oldApprovalStatus == 'approve'">
                                      <template v-if="oldPoNo == null || oldPoNo == ''">
                                        <template v-if="defaultPOReqEmailArr.includes(POReqAdminEmailArr)">
                                          <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                              <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                              <div class="requiredstar">*</div>
                                              <span class="help is-danger">{{poNoError}}</span>
                                            </div>
                                          </div>
                                          <div class="col-6 forms-block"></div>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <div class="col-6 forms-block">
                                          <div class="col-3 paddingleft0">
                                              <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                          </div>
                                          <div class="col-9 paddingleft0">
                                            <span class="form-control">{{poNo}}</span>
                                          </div>
                                        </div>
                                        <div class="col-6 forms-block"></div>
                                      </template>
                                    </template> -->
                                    <!-- <template v-else-if="ApprovStatus == 'approve' && oldApprovalStatus == 'pending'">
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                                <div class="requiredstar">*</div>
                                                <span class="help is-danger">{{poNoError}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block"></div>
                                    </template> -->
                                <!-- </template>
                                <template v-else> -->
                                    <!-- <template v-if="ApprovStatus == 'approve' && approvalLevel == 1">
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                                <div class="requiredstar">*</div>
                                                <span class="help is-danger">{{poNoError}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block"></div>
                                    </template> -->
                                    <!--<template v-if="(ApprovStatus == 'approve' && approvalLevel == 2)">
                                      <template v-if="oldPoNo == null || oldPoNo == ''">
                                        <template v-if="defaultPOReqEmailArr.includes(POReqAdminEmailArr)">
                                          <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                              <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                              <div class="requiredstar">*</div>
                                              <span class="help is-danger">{{poNoError}}</span>
                                            </div>
                                          </div>
                                          <div class="col-6 forms-block"></div>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <div class="col-6 forms-block">
                                          <div class="col-3 paddingleft0">
                                              <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                          </div>
                                          <div class="col-9 paddingleft0">
                                            <span class="form-control">{{poNo}}</span>
                                          </div>
                                        </div>
                                        <div class="col-6 forms-block"></div>
                                      </template>
                                    </template>
                                </template>
                            </template> -->

                            <!-- PO Number(Display After Approval)-->
                            <!-- <template v-if="permissionNameArray.includes('can_add_po_number_to_po_request') && ApprovStatus == 'approve' && oldApprovalStatus == 'approve'">
                              <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0"> -->
                                    <!-- <template v-if="oldPoNo == ''"> -->
                                      <!-- <input @input="poNoError = ''" id="poNo" v-model="poNo" class="form-control" type="text" maxlength="20"/>
                                      <div class="requiredstar">*</div>
                                      <span class="help is-danger">{{poNoError}}</span> -->
                                    <!-- </template>
                                    <template v-else>
                                      <span class="form-control">{{poNo}}</span>
                                    </template> -->
                                <!-- </div>
                              </div>
                              <div class="col-6 forms-block"></div>
                            </template>
                            <template v-else>
                              <template v-if="ApprovStatus == 'approve' && oldApprovalStatus == 'approve' && oldPoNo != ''">
                                <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                    <span class="form-control">{{poNo}}</span>
                                  </div>
                                </div>
                                <div class="col-6 forms-block"></div>
                              </template>
                            </template> -->

                            <!-- Type of Request -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.type_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No'">
                                        <v-select
                                                v-model="typeOfRequestId"
                                                @input="typeOfRequestError=''"
                                                name="typeOfRequestId"
                                                id="typeOfRequestId"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="typeOfRequestArr"
                                                :placeholder="staticMessages.type_of_request_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{typeOfRequestError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{typeOfRequestId.text}}</span>
                                    </template>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- vendor -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No'">
                                        <input @input="vendorError = ''" id="vendor" v-model="vendor" class="form-control" type="text"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{vendorError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{vendor}}</span>
                                    </template>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No'">
                                        <v-select
                                                v-model="locationId"
                                                @input="locationError = ''"
                                                name="locationId"
                                                id="locationId"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="locationArr"
                                                :placeholder="staticMessages.location_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{locationError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{locationId.text}}</span>
                                    </template>
                                </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <!-- To Email -->
                            <div class="col-6 forms-block">
                              <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.po_request_notify_email_label}}</div>
                              </div>
                              <div class="col-9 paddingleft0 po-email-block">
                                <vue-tags-input
                                      v-model="poEmail"
                                      :tags="poEmailTagsArr"
                                      @tags-changed="newTags => poEmailTagsArr = newTags"
                                      placeholder=""
                                      disabled
                                />
                              </div>
                            </div>
                            <div class="col-6 forms-block"></div>

                            <template v-for="(data, vinDataIndex) in all_vin_data">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.vin}}</span>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.description_of_po}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No'">
                                            <input @input="data.vinDescriptionError = ''" id="vinDescription" v-model="data.vin_description" class="form-control" type="text"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{data.vinDescriptionError}}</span>
                                        </template>
                                        <template v-else>
                                            <span style="overflow: auto;" class="form-control">{{data.vin_description}}</span>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!--
                                  1)If PO is 'Pending' and Not Cancelled,
                                    Then Cost Adjustment Access Assigned To = Requestor, Manager, Admin & Status Modify Permission User
                                  2)If PO is 'Approve' and Not Cancelled,
                                    Then Cost Adjustment Access Assigned To = Status Modify Permission User
                                -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.amount_requested}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="(oldApprovalStatus == 'pending' && approvalLevel == 0 && oldPoCancel == 'No' && (loginUsrEmail.includes(createdByUsername) || permissionNameArray.includes('can_approve_reject_po_request') || uniqueManagerIdArr.includes(loginUsrId)))
                                                      || (oldApprovalStatus == 'approve' && permissionNameArray.includes('can_approve_reject_po_request'))">
                                            <input @input="data.amountRequestedError = ''" id="amountRequested" v-model="data.request_amt" class="form-control" type="text" maxlength="17"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{data.amountRequestedError}}</span>
                                        </template>
                                        <template v-else>
                                            <span style="overflow: auto;" class="form-control">{{data.request_amt}}</span>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!-- PO Number(Display After Approval)-->
                                <template v-if="permissionNameArray.includes('can_add_po_number_to_po_request') && ApprovStatus == 'approve' && oldApprovalStatus == 'approve'">
                                  <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="data.poNoError = ''" id="poNo" v-model="data.poNo" class="form-control" type="text" maxlength="20"/>
                                        <span class="help is-danger">{{data.poNoError}}</span>
                                        <div class="requiredstar">*</div>
                                    </div>
                                  </div>
                                  <div class="col-6 forms-block"></div>

                                  <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.po_comment_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <textarea v-model="data.po_comment" name="po_comment" id="po_comment" class="form-control" maxlength="50000"></textarea>
                                    </div>
                                  </div>
                                <div class="col-6 forms-block"></div>
                                </template>
                                <template v-else>
                                  <template v-if="ApprovStatus == 'approve' && oldApprovalStatus == 'approve' && data.poNo != ''">
                                    <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <span class="form-control">{{data.poNo}}</span>
                                      </div>
                                    </div>
                                    <div class="col-6 forms-block"></div>

                                    <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.po_comment_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <textarea v-model="data.po_comment" name="po_comment" id="po_comment" class="form-control" maxlength="50000" disabled></textarea>
                                      </div>
                                    </div>
                                    <div class="col-6 forms-block"></div>
                                  </template>
                                </template>
                            </template>

                            <!--Description-->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_of_po}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0">
                                        <textarea @input="descriptionError = ''" id="descrition" v-model="description" class="form-control" maxlength="50000"></textarea>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{descriptionError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{description}}</span>
                                    </template>
                                </div>
                            </div> -->

                            <!-- Request Amt -->
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.amount_requested}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="requestAmountError = ''" id="requestAmount" v-model="requestAmount" class="form-control" type="text"/> -->
                                    <!-- <div class="requiredstar">*</div> -->
                                    <!--<span class="help is-danger">{{requestAmountError}}</span>
                                </div>
                            </div> -->

                            <!-- Work Complete Status -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.work_complete_status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="workCompleteStatus"
                                                        :options="workCompleteStatusArr"
                                                        name="workCompleteStatus"
                                                        disabled>
                                    </b-form-radio-group>
                                </div>
                            </div> -->

                            <!-- Approve Reject Status -->
                            <template v-if="permissionNameArray.includes('can_approve_reject_po_request') || uniqueManagerIdArr.includes(loginUsrId)">
                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.approval_status_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                    <!-- <template v-if="(oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && !(defaultPOReqAdminEmailArr.includes(createdByUsername)))
                                          || (oldApprovalStatus == 'pending' && (loginUsrEmail.includes(createdByUsername)) && (totalAmout > 4000 && approvalLevel != 1))
                                          || (oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && (approvalLevel == 0) && totalAmout <= 4000 && all_vin_data.length == 1)
                                          || oldApprovalStatus == 'approve'
                                          || oldApprovalStatus == 'reject'"> -->
                                    <!-- <template v-if="(oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername)) || (oldApprovalStatus != 'pending')
                                      || (oldApprovalStatus == 'pending' && (loginUsrEmail.includes(createdByUsername)) && (totalAmout > 4000 && approvalLevel != 1))"> -->
                                    <template v-if="(!permissionNameArray.includes('can_approve_reject_po_request') && approvalLevel >= 1)
                                                || approvalLevel == 2
                                                || (oldApprovalStatus == 'approve' && approvalLevel == 0)
                                                || oldApprovalStatus == 'reject'
                                                || (oldApprovalStatus == 'pending' && oldPoCancel == 'Yes' && approvalLevel == 0)">
                                        <b-form-radio-group v-model="ApprovStatus"
                                                              :options="ApprovDisableStatusArr"
                                                              name="ApprovStatus"
                                                              disabled>
                                          </b-form-radio-group>
                                      </template>
                                      <template v-else>
                                          <b-form-radio-group v-model="ApprovStatus"
                                                              :options="ApprovStatusArr"
                                                              name="ApprovStatus">
                                          </b-form-radio-group>
                                      </template>
                                  </div>
                              </div>
                              <div class="col-6 forms-block"></div>

                              <div v-if="ApprovStatus == 'reject' && oldApprovalStatus !='reject'" class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.reason_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <template v-if="ApprovStatus == 'reject'">
                                          <textarea v-model="reason" name="reason" id="reason" placeholder="Write Reason" class="form-control" maxlength="50000"></textarea>
                                      </template>
                                  </div>
                              </div>

                              <template v-if="oldPoNo == ''">
                                <!-- Approval Comment -->
                                <div  v-if="(totalAmout <= 4000 && ApprovStatus == 'approve' && oldApprovalStatus == 'pending')
                                          || (uniqueManagerIdArr.length == 1 && uniqueManagerIdArr.includes(createdBy))" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.comment_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <textarea v-model="approve_comment" name="approve_comment" id="approve_comment" class="form-control" maxlength="50000"></textarea>
                                    </div>
                                </div>

                                <!-- Manager Comment -->
                                <template v-if="totalAmout > 4000 && !(uniqueManagerIdArr.length == 1 && uniqueManagerIdArr.includes(createdBy)) && ApprovStatus == 'approve' && approvalLevel == 0">
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.first_approval_comment_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <textarea v-model="manager_comment" name="manager_comment" id="manager_comment" class="form-control" maxlength="50000"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-6 forms-block"></div>
                                </template>

                                <!-- Admin Comment -->
                                <template v-if="totalAmout > 4000 && !(uniqueManagerIdArr.length == 1 && uniqueManagerIdArr.includes(createdBy)) && ApprovStatus == 'approve' && approvalLevel == 1">
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.second_approval_comment_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <textarea v-model="admin_comment" name="admin_comment" id="admin_comment" class="form-control" maxlength="50000"></textarea>
                                        </div>
                                    </div>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.approval_status_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <b-form-radio-group v-model="ApprovStatus"
                                                          :options="ApprovDisableStatusArr"
                                                          name="ApprovStatus"
                                                          disabled>
                                      </b-form-radio-group>
                                  </div>
                              </div>
                              <div class="col-6 forms-block"></div>
                            </template>

                            <!-------------------------------------------------------- ------ Approval Milestone Block - Start ----------------------------------------------------------->
                            <div class="col-12 forms-block">
                                <div class="col-12" style="height: 40px;"></div>

                                <div class="col-12" style="margin-bottom: 10px;">
                                    <div class="form-label approval-info-block">
                                        APPROVAL MILESTONE
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_label}}</div>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_perform_by_label}}</div>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_perform_at_label}}</div>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.comment_reason_label}}</div>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.status_label1}}</div>
                                </div>
                            </div>
                            <br>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">Request Initiated</span>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{ created_by }}</span>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{ created_at }}</span>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                  <span class="form-control data-block"> - </span>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                  <span class="badge badge-primary badge-pill bg-color-0771b4 status-block">Initiated</span>
                                </div>
                            </div>

                            <template v-if="ApprovStatus == 'pending' && approvalLevel == 0 && isPoCancel == 'Yes'">
                              <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">PO Cancelled</span>
                                </div>
                              </div>

                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ cancel_by }}</span>
                                  </div>
                              </div>

                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ cancel_at }}</span>
                                  </div>
                              </div>

                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">-</span>
                                  </div>
                              </div>

                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="badge badge-primary badge-pill bg-color-ff3300 status-block">Cancelled</span>
                                  </div>
                              </div>
                            </template>
                            <template v-else>
                              <template v-if="/* totalAmout <= 4000 && */ approvalLevel == 0 && ApprovStatus == 'approve' && oldApprovalStatus == 'approve'">
                                <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">Approved</span>
                                  </div>
                                </div>

                                <div class="col-3 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="form-control data-block">{{ status_change_by }}</span>
                                    </div>
                                </div>

                                <div class="col-2 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="form-control data-block">{{ status_change_at }}</span>
                                    </div>
                                </div>

                                <div class="col-3 forms-block">
                                    <div class="col-12 paddingleft0">
                                      <template v-if="approve_comment != ''">
                                        <span class="form-control data-block">{{approve_comment}}</span>
                                      </template>
                                      <template v-else>
                                        <span class="form-control data-block">-</span>
                                      </template>
                                    </div>
                                </div>

                                <div class="col-2 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">Approved</span>
                                    </div>
                                </div>
                              </template>
                              <template v-else>
                                  <template v-if="(/* totalAmout > 4000 && */ approvalLevel > 0) || status_change_at != '' || oldApprovalStatus == 'reject'">
                                      <template v-if="(/* totalAmout > 4000 &&  */approvalLevel > 0) || status_change_at != ''">
                                          <div class="col-2 forms-block">
                                          <div class="col-12 paddingleft0">
                                              <span class="form-control data-block">Level - 1 Approval</span>
                                          </div>
                                          </div>

                                          <div class="col-3 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="form-control data-block">{{ status_change_by }}</span>
                                              </div>
                                          </div>

                                          <div class="col-2 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="form-control data-block">{{ status_change_at }}</span>
                                              </div>
                                          </div>

                                          <div class="col-3 forms-block">
                                              <div class="col-12 paddingleft0">
                                              <template v-if="manager_comment != ''">
                                                  <span class="form-control data-block">{{manager_comment}}</span>
                                              </template>
                                              <template v-else>
                                                  <span class="form-control data-block">-</span>
                                              </template>
                                              </div>
                                          </div>

                                          <div class="col-2 forms-block">
                                              <div class="col-12 paddingleft0">
                                              <!-- <template v-if="approvalLevel == 2"> -->
                                                  <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">
                                                  Approved
                                                  </span>
                                              <!-- </template>
                                              <template v-else-if="approvalLevel == 1">
                                                  <span class="badge badge-primary badge-pill bg-color-0771b4 status-block">
                                                  Pending
                                                  </span>
                                              </template>
                                              <template v-else>
                                                  <span class="badge badge-primary badge-pill bg-color-c3202b status-block">Rejected</span>
                                              </template> -->
                                              </div>
                                          </div>
                                      </template>

                                      <template v-if="oldApprovalStatus == 'reject'">
                                          <div class="col-2 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="form-control data-block">Rejected</span>
                                              </div>
                                          </div>

                                          <!-- Rejected By -->
                                          <div class="col-3 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="form-control data-block">{{ rejected_by }}</span>
                                              </div>
                                          </div>

                                          <!-- Rejected At -->
                                          <div class="col-2 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="form-control data-block">{{ rejected_at }}</span>
                                              </div>
                                          </div>

                                          <!-- Reason -->
                                          <div class="col-3 forms-block">
                                              <div class="col-12 paddingleft0">
                                              <template v-if="reason != ''">
                                                  <span class="form-control data-block"> {{reason}} </span>
                                              </template>
                                              <template v-else>
                                                  <span class="form-control data-block">-</span>
                                              </template>
                                              </div>
                                          </div>

                                          <!-- Move Request Status -->
                                          <div class="col-2 forms-block">
                                              <div class="col-12 paddingleft0">
                                                  <span class="badge badge-primary badge-pill bg-color-c3202b status-block">Rejected</span>
                                              </div>
                                          </div>
                                      </template>
                                  </template>

                                  <template v-if="/* totalAmout > 4000 && */ approvalLevel == 2">
                                  <div class="col-2 forms-block">
                                      <div class="col-12 paddingleft0">
                                          <span class="form-control data-block">Level - 2 Approval</span>
                                      </div>
                                  </div>

                                  <div class="col-3 forms-block">
                                      <div class="col-12 paddingleft0">
                                          <span class="form-control data-block">{{ second_apvl_status_by }}</span>
                                      </div>
                                  </div>

                                  <div class="col-2 forms-block">
                                      <div class="col-12 paddingleft0">
                                          <span class="form-control data-block">{{ second_apvl_status_change_at }}</span>
                                      </div>
                                  </div>

                                  <div class="col-3 forms-block">
                                      <div class="col-12 paddingleft0">
                                          <template v-if="admin_comment != ''">
                                          <span class="form-control data-block">{{admin_comment}}</span>
                                          </template>
                                          <template v-else>
                                          <span class="form-control data-block">-</span>
                                          </template>
                                      </div>
                                  </div>

                                  <div class="col-2 forms-block">
                                      <div class="col-12 paddingleft0">
                                          <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">Approved</span>
                                      </div>
                                  </div>
                                  </template>
                              </template>

                              <template v-if="approvalLevel != 1 && oldPoNo != ''">
                                <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">PO Created</span>
                                  </div>
                                </div>

                                <div class="col-3 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="form-control data-block">{{ po_created_by }}</span>
                                    </div>
                                </div>

                                <div class="col-2 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="form-control data-block">{{ po_created_at }}</span>
                                    </div>
                                </div>

                                <div class="col-3 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="form-control data-block">-</span>
                                    </div>
                                </div>

                                <div class="col-2 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">PO Created</span>
                                    </div>
                                </div>
                              </template>
                            </template>
                            <!-------------------------------------------------------- ------ Approval Milestone Block - End ------------------------------------------------------------->

                            <template v-if="oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No'">
                                <div v-if="isShowAttachment" class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                    <div class="form-group">
                                        <label for="dropzone">{{staticInputLabelMessages.attach_document}}</label>
                                        <div class="requiredstar">*</div>
                                        <vue-dropzone
                                                ref="dropzone"
                                                id="dropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-success = "uploadDocument"
                                                @vdropzone-removed-file="removedSelectedFile"
                                                @vdropzone-error="fileError"
                                                @vdropzone-file-added="fileAdd"
                                                @vdropzone-total-upload-progress="progressOfUload"
                                                :useCustomSlot=true
                                                :destroyDropzone="false"
                                        >
                                            <div class="dropzone-custom-content">
                                                <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                                <div class="subtitle">(Select files from your computer.)</div>
                                            </div>
                                        </vue-dropzone>
                                    </div>
                                    <span class="help is-danger">{{fileUploadError}}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-12 forms-block" v-show="attachment.length > 0">
                                    <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                                        <div class="col-12 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.attached_document}}</div>
                                        </div>
                                        <div class="col-12 paddingleft0">
                                            <div class="slider">
                                                <template>
                                                    <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                        <template v-for="(doc, index1) in attachment">
                                                            <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else>
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                        </template>
                                                    </swiper>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div v-if="((oldApprovalStatus == 'pending' && loginUsrEmail.includes(createdByUsername) && approvalLevel == 0 && oldPoCancel == 'No')
                              || (permissionNameArray.includes('can_approve_reject_po_request') && oldApprovalStatus == 'pending' && oldPoCancel == 'No')
                              || (uniqueManagerIdArr.includes(loginUsrId) && oldApprovalStatus == 'pending' && approvalLevel != 1 && oldPoCancel == 'No')
                              || (permissionNameArray.includes('can_add_po_number_to_po_request') && oldApprovalStatus == 'approve' /* && oldPoNo == '' */ && oldPoCancel == 'No'))" class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addPORequest()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';

    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import VueTagsInput from '@johmun/vue-tags-input';

    Vue.component("app-footer", Footer);

    export default {
        name: 'UpdatePORequest',
        components: {
            swiper,
            swiperSlide,
            'vueDropzone': vue2Dropzone,
            VueTagsInput,
        },
        mixins: [commonMixins],
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                isAbove500: "",
                // salesManager: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                vin: "",
                vendor: "",
                description: "",
                requestAmount: "",
                attachment: "",
                /* workCompleteStatus:"no",
                workCompleteStatusArr: [
                    {
                        text: "No",
                        value: "no"
                    },
                    {
                        text: "Yes",
                        value: "yes"
                    }
                ], */
                ApprovStatus:"pending",
                ApprovStatusArr: [
                    {
                        text: "Approve",
                        value: "approve"
                    },
                    {
                        text: "Pending",
                        value: "pending"
                    },
                    {
                        text: "Reject",
                        value: "reject"
                    }
                ],
                ApprovDisableStatusArr: [
                    {
                        text: "Approved",
                        value: "approve"
                    },
                    {
                        text: "Pending",
                        value: "pending"
                    },
                    {
                        text: "Rejected",
                        value: "reject"
                    }
                ],
                reason: "",
                // Error
                salesmanIdError: "",
                vinError: "",
                vendorError: "",
                descriptionError: "",
                requestAmountError: "",
                ApprovStatusError: "",
                // workCompleteStatusError: "",
                //
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add PO Request',
                permissionNameArray: ['can_add_po_request'],
                all_vin_data : [],
                vin_descriptions : "",
                totalAmout : "",
                approve_comment : "",
                status_change_by : '',
                status_change_at : '',
                loginUsrEmail : '',
                defaultPOReqAdminEmailArr : [],
                defaultPOReqGenericEmailArr : [],
                oldApprovalStatus : "pending",
                adminSectionShow : false,
                loginUsrId : 1,
                approvalLevel : 0,
                manager_comment : "",
                admin_comment : "",
                managerIdArr : [],
                uniqueManagerIdArr : [],
                createdBy : '',
                createdByUsername : '',
                second_apvl_status_by : '',
                second_apvl_status_change_at : '',
                show1stApprovalBlock : false,
                show2ndApprovalBlock : false,
                requestNo : '',
                requestNoError : '',
                poNo : '',
                poNoError : '',
                typeOfRequestId : '',
                typeOfRequestArr : [
                    /* {
                        text : 'Recon',
                        value : 'recon',
                    },
                    {
                        text : 'External Repairs',
                        value : 'external_repairs',
                    },
                    {
                        text : 'Internal Repairs',
                        value : 'internal_repairs',
                    } */
                ],
                typeOfRequestError : '',
                locationId : '',
                locationArr : [],
                locationError : '',
                isShowAttachment : false,

                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                fileUploadError : "",
                oldPoNo : '',
                created_by : '',
                created_at : '',
                po_created_by : '',
                po_created_at : '',
                rejected_by : '',
                rejected_at : '',
                edit_id : 0,
                cancel_by : '',
                cancel_at : '',
                isPoCancel : 'No',
                oldPoCancel : 'No',
                poEmail : '',
                poEmailTagsArr: [],
                isRedirection: null,
            }
        },
        created() {
            self = this;
            self.getLoginIdPermissions();
        },
        watch : {
            typeOfRequestId : function (val) {
                self.isShowAttachment = false;
                if (val.alias == 'external_repairs' || val.alias == 'internal_repairs' ) {
                    if (self.oldApprovalStatus == 'pending' && self.loginUsrEmail.includes(self.createdByUsername) && self.approvalLevel == 0 && self.oldPoCancel == 'No') {
                        self.isShowAttachment = true;
                    }
                }
            },
            all_vin_data : {
              handler(val){
                if(self.oldApprovalStatus == 'pending' && self.approvalLevel == 0 && self.oldPoCancel == 'No'
                  && (self.loginUsrEmail.includes(self.createdByUsername)
                  || self.permissionNameArray.includes('can_approve_reject_po_request')
                  || self.uniqueManagerIdArr.includes(self.loginUsrId))) {

                  self.totalAmout = 0;
                  for (const element of val) {
                      if(element.request_amt != '' || element.request_amt != 0) {
                          self.totalAmout += parseInt(element.request_amt);
                      }
                  }
                }
              },
              deep: true
            },
        },
        mounted: function () {
            /* self.defaultPOReqAdminEmailArr = process.env.VUE_APP_PO_REQUEST_ADMIN_EMAIL;
            self.defaultPOReqGenericEmailArr = process.env.VUE_APP_GENERIC_PO_REQUEST_EMAIL; */
            self.isRedirection = process.env.VUE_APP_IS_REDIRECTION;

            /* Redirect to login page logic start */
            let userObj = localStorage.getItem("user_obj");
            if (userObj == null) {

                /* Set redirection link specified in the email template to navigate to the edit page  --- Start */
                if (self.isRedirection === 'true') {
                  // Check & set redirection path if available
                  if(typeof window.location.pathname !== 'undefined' && window.location.pathname != 'null' && window.location.pathname != '') {
                    if(window.location.pathname != '/') {
                      localStorage.setItem('redirect_url', window.location.pathname);
                    }
                  }
                }
                /* Set redirection link specified in the email template to navigate to the edit page --- End */

                self.$router.push('/');
            } else if(self.$route.query.token) {
                self.$router.push({name: "UpdatePORequest", params: {id: self.$route.params.id}});
                location.reload();
            }
            /* Redirect to login page logic end */
            // self.loginUsrEmail =  localStorage.getItem('username');

            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('po-request');
            EventBus.$emit("menu-selection-changed", "po-request");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                let userJson = JSON.parse(userObj);
                self.loginUsrId = userJson['id'];
                self.loginUsrEmail= userJson['username'];
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.edit_id = self.$route.params.id;
                self.getPORequestDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit PO Request";
            }
            self.loadAllData(['Location', 'PoTypeOfRequest'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListPORequest'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function(callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
                self.typeOfRequestArr = callbackData.PoTypeOfRequest;
            },
            uploadDocument: function (file, response) {
                let self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPaths.push(response.content.file_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                // this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                // this.$refs.dropzone.setOption('maxFiles', null);
                let self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;

                let removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file) {
                    for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    let filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (let i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                let self = this;
                //console.log(filePath);return false;
                let config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                }
            },
            getPORequestDetailsById: function (id) {
                let config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/porequest/get', config)
                        .then(response => {
                            /* if (response.data.content.uMgrFName != null && response.data.content.uMgrLName != null) {
                                self.salesManager = response.data.content.uMgrFName + " " + response.data.content.uMgrLName;
                            } */
                            if (response.data.content.salesman_id != null) {
                                self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesman_id};
                            }
                            self.isAbove500 = response.data.content.is_above_500;
                            self.vin = response.data.content.vin;
                            self.vendor = response.data.content.vendor;
                            self.description = response.data.content.description;
                            self.requestAmount = response.data.content.request_amt;
                            // self.workCompleteStatus = response.data.content.work_complete_status;
                            if (response.data.content.reason == "null" || response.data.content.reason == null) {
                                self.reason = "";
                            } else {
                                self.reason = response.data.content.reason;
                            }
                            // self.attachment = response.data.content.attachment_path;
                            self.all_vin_data = response.data.content.all_vin_data;
                            self.companyId = response.data.content.company_id;
                            self.vin_descriptions = response.data.content.vin_descriptions;

                            self.totalAmout = response.data.total_request_amt;
                            self.approvalLevel = response.data.content.approval_level;
                            self.createdBy = response.data.content.created_by;
                            self.createdByUsername = response.data.content.created_by_username;
                            self.managerIdArr = response.data.content.manager_ids;

                            const managerIntIdArr = [];
                            for (const element of self.managerIdArr) {
                                managerIntIdArr.push(parseInt(element));
                            }
                            self.uniqueManagerIdArr = managerIntIdArr.filter(self.onlyUniqueIds);

                            /* if(self.totalAmout > 4000 && self.approvalLevel != 0) {

                                if (self.approvalLevel == 1) {
                                    if(managerIntIdArr.includes(self.loginUsrId)) {
                                        // 1st Approval Manager Status
                                        self.showApprovalBlock = true;
                                        self.oldApprovalStatus = self.ApprovStatus = "approve";

                                        if (response.data.content.manager_comment && response.data.content.manager_comment != null && response.data.content.manager_comment != "null") {
                                            self.manager_comment = response.data.content.manager_comment;
                                        }
                                    } else if(self.defaultPOReqAdminEmailArr.includes(self.loginUsrEmail) || self.defaultPOReqGenericEmailArr.includes(self.loginUsrEmail) || (self.oldApprovalStatus == 'pending' && self.loginUsrEmail.includes(self.createdByUsername))) {
                                        // 1st Approval Admin & Generic Usr Status
                                        self.showApprovalBlock = true;
                                        self.oldApprovalStatus =  self.ApprovStatus = "pending";

                                        if (response.data.content.manager_comment && response.data.content.manager_comment != null && response.data.content.manager_comment != "null") {
                                            self.manager_comment = response.data.content.manager_comment;
                                        }
                                    }
                                } else if(self.approvalLevel == 2) {
                                    // 2nd Approval Status
                                    self.showApprovalBlock = true;
                                    self.oldApprovalStatus =  self.ApprovStatus = "approve";

                                    if (response.data.content.manager_comment && response.data.content.manager_comment != null && response.data.content.manager_comment != "null") {
                                        self.manager_comment = response.data.content.manager_comment;
                                    }
                                    if (response.data.content.admin_comment && response.data.content.admin_comment != null && response.data.content.admin_comment != "null") {
                                        self.admin_comment = response.data.content.admin_comment;
                                    }
                                }
                            } else {
                                // Single or More Than 1 VIN Approval Status
                                self.oldApprovalStatus = self.ApprovStatus = response.data.content.is_approved;

                                if (response.data.content.approval_comment && response.data.content.approval_comment != null && response.data.content.approval_comment != "null") {
                                    self.approve_comment = response.data.content.approval_comment;
                                }
                            } */
                            self.oldApprovalStatus = self.ApprovStatus = response.data.content.is_approved;

                            if (response.data.content.status_by && response.data.content.status_by != null && response.data.content.status_by != "null") {
                                self.status_change_by = response.data.content.status_by.text;
                            }

                            if (response.data.content.status_change_at && response.data.content.status_change_at != null && response.data.content.status_change_at != "null") {
                                self.status_change_at = response.data.content.status_change_at;
                            }

                            if (response.data.content.second_apvl_status_by && response.data.content.second_apvl_status_by != null && response.data.content.second_apvl_status_by != "null") {
                                self.second_apvl_status_by = response.data.content.second_apvl_status_by.text;
                            }

                            if (response.data.content.second_apvl_status_change_at && response.data.content.second_apvl_status_change_at != null && response.data.content.second_apvl_status_change_at != "null") {
                                self.second_apvl_status_change_at = response.data.content.second_apvl_status_change_at;
                            }

                            if (response.data.content.approval_comment && response.data.content.approval_comment != null && response.data.content.approval_comment != "null") {
                              self.approve_comment = response.data.content.approval_comment;
                            }

                            if (response.data.content.manager_comment && response.data.content.manager_comment != null && response.data.content.manager_comment != "null") {
                              self.manager_comment = response.data.content.manager_comment;
                            }

                            if (response.data.content.admin_comment && response.data.content.admin_comment != null && response.data.content.admin_comment != "null") {
                              self.admin_comment = response.data.content.admin_comment;
                            }

                            self.created_by = response.data.content.created_by.text;
                            self.created_at = response.data.content.created_at_date;

                            if (response.data.content.po_created_by && response.data.content.po_created_by != null && response.data.content.po_created_by != "null") {
                                self.po_created_by = response.data.content.po_created_by.text;
                            }

                            if (response.data.content.po_created_at && response.data.content.po_created_at != null && response.data.content.po_created_at != "null") {
                                self.po_created_at = response.data.content.po_created_at;
                            }

                            if (response.data.content.rejected_by && response.data.content.rejected_by != null && response.data.content.rejected_by != "null") {
                                self.rejected_by = response.data.content.rejected_by.text;
                            }

                            if (response.data.content.rejected_at && response.data.content.rejected_at != null && response.data.content.rejected_at != "null") {
                                self.rejected_at = response.data.content.rejected_at;
                            }

                            if (response.data.content.id != null) {
                                self.requestNo = response.data.content.id;
                            }
                            if (response.data.content.po_no != null) {
                                self.oldPoNo = self.poNo = response.data.content.po_no;
                            }
                            self.oldPoCancel = self.isPoCancel = response.data.content.is_po_cancel.text;
                            if (response.data.content.type_of_request_id != null) {
                                // self.typeOfRequestId = { text : self.formatString(response.data.content.type_of_request_id), value : response.data.content.type_of_request_id };
                                self.typeOfRequestId = { text : response.data.content.po_type_of_request_name
                                                        , value : response.data.content.po_type_of_request_id
                                                        , alias: response.data.content.po_type_of_request_fmt_name
                                                    };

                                if (response.data.content.attachment_path.length > 0) {
                                    self.attachment = response.data.content.attachment_path;

                                    if((self.typeOfRequestId.text == 'External Repairs' || self.typeOfRequestId.text == 'Internal Repairs') && self.oldApprovalStatus == 'pending' && self.loginUsrEmail.includes(self.createdByUsername) && self.approvalLevel == 0 && self.oldPoCancel == 'No') {
                                        setTimeout(function() {
                                            self.uploadManually();
                                        }, 1000);
                                    }
                                }
                            }
                            if (response.data.content.location_id != null) {
                                self.locationId = response.data.content.location_id;
                            }
                            if (response.data.content.cancel_by && response.data.content.cancel_by != null && response.data.content.cancel_by != "null") {
                                self.cancel_by = response.data.content.cancel_by.text;
                            }
                            if (response.data.content.cancel_at && response.data.content.cancel_at != null && response.data.content.cancel_at != "null") {
                                self.cancel_at = response.data.content.cancel_at;
                            }
                            if (response.data.content.to_email != '' && response.data.content.to_email != null && typeof (response.data.content.to_email) != "undefined") {
                              let email = response.data.content.to_email;
                              email = email.split(",");
                              for (const element of email) {
                                self.poEmailTagsArr.push({"text":element.trim(),"tiClasses":["ti-valid"]});
                              }
                            }
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            onlyUniqueIds : function(value, index, array) {
                return array.indexOf(value) === index;
            },
            uploadManually: function () {
                if(self.attachment.length > 0){
                    for (const element of self.attachment) {
                        if (element['image_from'] == 'image_path') {
                            let url = element['file'];
                            let image_name = self.getFileName(element['file']);
                            let image_type = element['type'];
                            let image_size = element['size'];

                            self.totalsize = self.totalsize + Number(image_size);
                            self.imagesType.push(image_type);
                            self.imagesSize.push(Number(image_size));

                            let image_data = {size: image_size, fileid: image_name, name: image_name, type: image_type};
                            self.$refs.dropzone.manuallyAddFile(image_data, url);
                        }
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
            },
            getFileName: function (file) {
                let src = file.split('/');
                let image_name = src[src.length - 1];
                let orgPath = src[src.length - 2];
                self.imagesPaths.push(String(orgPath + "/" + image_name));
                return image_name;
            },
            checkForm: function () {
                let checked = true;
                // self.requestNoError = '';
                self.poNoError = '';
                self.typeOfRequestError = '';
                self.locationError = '';

                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (!self.vin) {
                 self.vinError = "Please enter Vin";
                 if (checked) {
                 checked = false;
                 }
                 } */
                 if(self.oldApprovalStatus == 'pending' && self.loginUsrEmail.includes(self.createdByUsername) && self.oldPoCancel == 'No') {
                    if (!self.vendor) {
                        self.vendorError = "Please enter Vendor";
                        if (checked) {
                            checked = false;
                        }
                    }
                    /* if (!self.description) {
                        self.descriptionError = "Please enter description";
                        if (checked) {
                            checked = false;
                        }
                    } */

                    /* if (!self.requestNo) {
                        self.requestNoError = "Please enter request number";
                        if (checked) {
                            checked = false;
                        }
                    } */
                    /* if (!self.poNo) {
                        self.poNoError = "Please enter PO number";
                        if (checked) {
                            checked = false;
                        }
                    } */
                    if (self.typeOfRequestId == null || !self.typeOfRequestId.value) {
                        self.typeOfRequestError = "Please select Type of Request";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (self.locationId == null || !self.locationId.value) {
                        self.locationError = "Please select Location";
                        if (checked) {
                            checked = false;
                        }
                    }

                    if (self.isShowAttachment && self.imagesPaths.length === 0)  {
                        self.fileUploadError = "Please upload file";
                        if (checked) {
                            checked = false;
                        }
                    }

                    for (const element of self.all_vin_data) {
                        if (!element.request_amt) {
                            element.amountRequestedError = "Please enter Amount Requested";
                            if (checked) {
                                checked = false;
                            }
                        } else if (element.request_amt && !self.numberFormateValidation(element.request_amt)) {
                            element.amountRequestedError = "Please enter numeric value for Amount Requested";
                            if (checked) {
                                checked = false;
                            }
                        }  else if (element.request_amt && element.request_amt <= 0) {
                            element.amountRequestedError = "Please enter valid Amount Requested";
                            if (error) {
                                error = false;
                            }
                        }

                        if (element.vin_description === "" || element.vin_description === null || typeof element.vin_description === "undefined") {
                            element.vinDescriptionError = "Please enter Description of PO.";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                 } else if((self.oldApprovalStatus == 'pending' && self.approvalLevel == 0 && self.oldPoCancel == 'No' && (self.permissionNameArray.includes('can_approve_reject_po_request') || self.uniqueManagerIdArr.includes(self.loginUsrId)))
                        || (self.oldApprovalStatus == 'approve' && self.permissionNameArray.includes('can_approve_reject_po_request'))) {
                    for (const element of self.all_vin_data) {
                        if (!element.request_amt) {
                            element.amountRequestedError = "Please enter Amount Requested";
                            if (checked) {
                                checked = false;
                            }
                        } else if (element.request_amt && !self.numberFormateValidation(element.request_amt)) {
                            element.amountRequestedError = "Please enter numeric value for Amount Requested";
                            if (checked) {
                                checked = false;
                            }
                        }  else if (element.request_amt && element.request_amt <= 0) {
                            element.amountRequestedError = "Please enter valid Amount Requested";
                            if (checked) {
                              checked = false;
                            }
                        }
                    }
                 }
                 /*if (!self.requestAmount) {
                 self.requestAmountError = "Please enter request Amount";
                 if (checked) {
                 checked = false;
                 }
                 } */
                /*if (self.requestAmount && !self.numberFormateValidation(self.requestAmount)) {
                    self.requestAmountError = "request Amount must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if(self.permissionNameArray.includes('can_add_po_number_to_po_request') && self.ApprovStatus == 'approve' && self.oldApprovalStatus == 'approve') {
                    /* if (!self.poNo) {
                        self.poNoError = "Please enter PO number";
                        if (checked) {
                            checked = false;
                        }
                    } */
                    for (const element of self.all_vin_data) {
                      if (!element.poNo) {
                          element.poNoError = "Please enter PO number";
                          // console.log(element.poNoError);
                          if (checked) {
                              checked = false;
                          }
                      }
                    }
                }
                return checked;
            },
            addPORequest: function () {
                setTimeout(() => {
                    if (self.checkForm()) {
                        self.all_vin_data.map(item => { delete item.amountRequestedError; delete item.vinDescriptionError; delete item.poNoError; return item; });

                        let config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        let data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('vin', self.vin);
                        data.append('vendor', self.vendor);
                        data.append('description', self.description);
                        data.append('request_amount', self.requestAmount);
                        // data.append('work_complete_status', self.workCompleteStatus);
                        data.append('approve_status', self.ApprovStatus);
                        data.append('reason', self.reason);
                        data.append('vin_descriptions', self.vin_descriptions);
                        data.append('approve_comment', self.approve_comment);
                        data.append('manager_comment', self.manager_comment);
                        data.append('admin_comment', self.admin_comment);
                        data.append('all_vin_data', JSON.stringify(self.all_vin_data));
                        data.append('request_no', self.requestNo);
                        data.append('po_no', self.poNo);
                        data.append('type_of_request_id', self.typeOfRequestId.alias);
                        data.append('location_id', self.locationId.value);
                        data.append('imagePath', self.imagesPaths);
                        data.append('imageSize', self.imagesSize);
                        data.append('imageType', self.imagesType);
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/porequest/add/update", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            let err = "";
                                            for (const element of response.data.content) {
                                                err += element + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    /* console.log(err); */
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
            cancelPO: function(id) {
                self.$swal({
                    title: '',
                    html: 'Are you sure that you want to cancel this PO!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.value) {
                        let config = {
                            headers: { Authorization: "Bearer " + localStorage.getItem("api_token") },
                        };
                        let data = new FormData();
                        data.append('id', id);
                        HTTP.post("/porequest/cancel", data, config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                  self.showSAlert(response.data.message, 'success', false, 3);
                                  self.buttonBack();
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (const element of permissions) {
                self.permissionNameArray.push(element.permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (const element of companies) {
                self.userCompany.push(element.company);
            }
        }
    });
</script>
<style scoped>
.approval-info-block {
    text-align: center;
    font-size: 20px;
    background-color: #f5f5f5;
}
.header-block {
  text-align: center;
}
.data-block {
  text-align: left;
}

.status-block {
  display:block;
  margin:auto;
  font-size:85%;
}
.po-email-block .vue-tags-input {
  max-width: initial;
}
</style>
