<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_quotation_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="location"
                                        @change="changedValueLocation"
                                        @input="locationError = ''"
                                        name="location"
                                        id="location"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="locationArr"
                                        :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <!--Sales Person -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="salesPerson"
                                        name="salesPerson"
                                        @input="salesPersonError = ''"
                                        label="text"
                                        :filterable="false"
                                        :options="salesPersonArr"
                                        @search="onSearchSalesPerson"
                                        :clearable="false"
                                        :placeholder="staticMessages.sales_person_place_holder"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesPersonRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesPersonError}}</span>
                                </div>
                            </div>
                            <!-- BILL TO -->
                            <div class="col-12 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label customer-ship-block">BILL TO CUSTOMER</div>
                                </div>
                            </div>
                            <!-- Customer -->
                            <template v-if="!showCustomerNameColumn">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                            v-model="customerId"
                                            @change="changedValue"
                                            @input="customerError = ''"
                                            name="customerId"
                                            id="customerId"
                                            label="text"
                                            @search="onSearchCustomer"
                                            :clearable="false"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="customerArr"
                                            :placeholder="staticMessages.customer_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{customerError}}</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="customerNameError = ''" id="customerName" v-model="customerName" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{customerNameError}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>
                            <!-- contact_no -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="contactError = ''" id="contact" v-model="contact" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{contactError}}</span>
                                </div>
                            </div>
                            <!-- Address -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="addressError = ''" id="address" v-model="address" class="form-control" type="text"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{addressError}}</span>
                                </div>
                            </div>
                            <!-- State -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="state" name="state"
                                              label="text" :filterable="false" :options="stateArr"
                                              @search="onSearchState" :clearable="false" @input="stateError = ''"
                                              :placeholder="staticMessages.state_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateRecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stateError}}</span>
                                </div>
                            </div>
                            <!-- City -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="cityId" name="cityId"
                                        label="text" :filterable="false" :options="cityArr"
                                        @input="cityError = ''"
                                        @search="onSearchCity" :clearable="false"
                                        :placeholder="staticMessages.city_place_holder" class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCityRecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cityError}}</span>
                                </div>
                            </div>
                            <!-- zipCode -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.zip_code_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="zipCodeError = ''" id="zipCode" v-model="zipCode" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{zipCodeError}}</span>
                                </div>
                            </div>
                            <!--Email-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>
                            <!-- Phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phoneError = ''" id="phone" v-model="phone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phoneError}}</span>
                                </div>
                            </div>
                            <!-- SHIP TO -->
                            <div class="col-12 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label customer-ship-block">SHIP TO CUSTOMER</div>
                                </div>
                            </div>
                            <template v-if="!showCustomerNameShipColumn">
                                <!-- Ship Customer -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                            v-model="customerIdShip"
                                            @change="changedValue"
                                            @input="customerErrorShip = ''"
                                            name="customerIdShip"
                                            id="customerIdShip"
                                            label="text"
                                            @search="onSearchCustomerShip"
                                            :clearable="false"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="customerArrShip"
                                            :placeholder="staticMessages.customer_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{customerErrorShip}}</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="customerShipNameError = ''" id="customerShipName" v-model="customerShipName" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{customerShipNameError}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Ship Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameErrorShip = ''" id="nameShip" v-model="nameShip" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship contact_no -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="contactErrorShip = ''" id="contactShip" v-model="contactShip" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{contactErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship Address -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="addressErrorShip = ''" id="addressShip" v-model="addressShip" class="form-control" type="text"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{addressErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship State -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="stateShip" name="statShipe"
                                              label="text" :filterable="false" :options="stateArrShip"
                                              @search="onSearchStateShip" :clearable="false" @input="stateErrorShip = ''"
                                              :placeholder="staticMessages.state_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateShipRecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stateErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship City -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="cityIdShip" name="cityIdShip"
                                        label="text" :filterable="false" :options="cityArrShip"
                                        @input="cityErrorShip = ''"
                                        @search="onSearchCityShip" :clearable="false"
                                        :placeholder="staticMessages.city_place_holder" class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCityShipRecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cityErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship zipCode -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.zip_code_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="zipCodeErrorShip = ''" id="zipCodeShip" v-model="zipCodeShip" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{zipCodeErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailErrorShip = ''" id="emailShip" v-model="emailShip" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailErrorShip}}</span>
                                </div>
                            </div>
                            <!-- Ship Phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phoneErrorShip = ''" id="phoneShip" v-model="phoneShip" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phoneErrorShip}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                &nbsp;                                
                            </div>
                            <!--Vin No.-->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            multiple
                                            v-model="assetId"
                                            name="assetId"
                                            @input="assetIdError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="assetIdArr"
                                            @search="onSearchAsset"
                                            :clearable="false"
                                            :placeholder="staticMessages.asset_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssetRecordNotFound">
                                                {{staticMessages.asset_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{assetIdError}}</span>
                                </div>
                            </div>
                            <!--Description-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="descriptionError = ''" id="description" v-model="description" class="form-control"></textarea>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>
                            <!--GVW-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gvw_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gvwError = ''" id="gvw" v-model="gvw" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gvwError}}</span>
                                    <!-- <span class="form-control">{{gvw}}</span> -->
                                </div>
                            </div>
                            <!-- Amount -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.amount_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="amountError = ''" @blur="calculateGrandTotal('amount')" id="amount" v-model="amount" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="amountError">{{amountError}}</span>
                                </div>
                            </div>
                            <!-- MISC Charge -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.misc_charge_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="miscChargeError = ''" @blur="calculateGrandTotal('miscCharge')" id="miscCharge" v-model="miscCharge" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="miscChargeError">{{miscChargeError}}</span>
                                </div>
                            </div>
                            <!-- Doc Fee -->
                            <template v-if="isAmountEnter">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.doc_fee_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span class="form-control">{{docFee}}</span>
                                    </div>
                                </div>
                                <!-- PREP Fee -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.prep_fee_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span class="form-control">{{prepFee}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Sub Total -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sub_total_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{subTotal}}</span>
                                </div>
                            </div>
                            <!-- Lienholder -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lienholder_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="lienHolderError = ''" id="lienHolder" v-model="lienHolder" class="form-control"></textarea>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{lienHolderError}}</span>
                                </div>
                            </div>
                            <!-- Tax Rate -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tax_rate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="taxRateError = ''" @blur="calculateGrandTotal('taxRate')" id="taxRate" v-model="taxRate" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="taxRateError">{{taxRateError}}</span>
                                </div>
                            </div>
                            <!-- Tax Rate Amount -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tax_rate_amount_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taxRateAmount}}</span>
                                </div>
                            </div>
                            <!-- Out of State -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.out_of_state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="outOfStateError = ''" @blur="calculateGrandTotal('outOfState')" id="outOfState" v-model="outOfState" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="outOfStateError">{{outOfStateError}}</span>
                                </div>
                            </div>
                            <!-- DMV -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dmv_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dmvError = ''" @blur="calculateGrandTotal('dmv')" id="dmv" v-model="dmv" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="dmvError">{{dmvError}}</span>
                                </div>
                            </div>
                            <!-- IRP/INS -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.irp_ins_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="irpINSError = ''" @blur="calculateGrandTotal('irpINS')" id="irpINS" v-model="irpINS" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="irpINSError">{{irpINSError}}</span>
                                </div>
                            </div>
                            <!-- FET -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fet_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fetError = ''" @blur="calculateGrandTotal('fet')" id="fet" v-model="fet" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="fetError">{{fetError}}</span>
                                </div>
                            </div>
                            <!-- Tire Recycling Fee -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_recycling_fee_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tireRecyclingFeeError = ''" @blur="calculateGrandTotal('tireRecyclingFee')" id="tireRecyclingFee" v-model="tireRecyclingFee" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="tireRecyclingFeeError">{{tireRecyclingFeeError}}</span>
                                </div>
                            </div>
                            <!-- Tire Recycling Amount -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_recycling_amount_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{tireRecyclingAmount}}</span>
                                </div>
                            </div>
                            <!--Warranty Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="warrantyType"
                                        @change="changedValueWarrantyType"
                                        @input="warrantyTypeError = ''"
                                        name="warrantyType"
                                        id="warrantyType"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="warrantyTypeArr"
                                        :placeholder="staticMessages.warranty_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{warrantyTypeError}}</span>
                                </div>
                            </div>
                            <!-- Warranty -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.warranty_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="warrantyError = ''" @blur="calculateGrandTotal('warranty')" id="warranty" v-model="warranty" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="warrantyError">{{warrantyError}}</span>
                                </div>
                            </div>
                            <!-- Warranty Discount -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.warranty_discount_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="warrantyDiscountError = ''" @blur="calculateGrandTotal('warrantyDiscount')" id="warrantyDiscount" v-model="warrantyDiscount" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="warrantyDiscountError">{{warrantyDiscountError}}</span>
                                </div>
                            </div>
                            <!-- Down Payment -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.down_payment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="downPaymentError = ''" @blur="calculateGrandTotal('downPayment')" id="downPayment" v-model="downPayment" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger" id="downPaymentError">{{downPaymentError}}</span>
                                </div>
                            </div>
                            <!-- Total -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.total_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{total}}</span>
                                </div>
                            </div>
                        </div>
                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addQuotationBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addQuotation()">
                            <input v-show="showSaveAndContinue" id="addAndContinueQuotationBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addQuotation(flag='Save and Continue')">
                            <input id="cancelQuotation" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateQuotation',
        mixins: [commonMixins],
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                
                location: "",
                locationError: "",
                locationArr: [],
                salesPerson: "",
                salesPersonError: "",
                salesPersonArr: [],
                isSalesPersonRecordNotFound: false,
                customerId: "",
                customerError: "",
                customerArr: [],
                name: "",
                nameError: "",
                contact: "",
                contactError: "",
                address: "",
                addressError: "",
                state: "",
                stateArr: [],
                stateError: "",
                isStateRecordNotFound: false,
                cityId: "",
                cityArr: [],
                cityError: "",
                isCityRecordNotFound: false,
                zipCode: "",
                zipCodeError: "",
                email: "",
                emailError: "",
                phone: "",
                phoneError: "",

                customerIdShip: "",
                customerArrShip: [],
                customerErrorShip: "",
                nameShip: "",
                nameErrorShip: "",
                contactShip: "",
                contactErrorShip: "",
                addressShip: "",
                addressErrorShip: "",
                stateShip: "",
                stateArrShip: [],
                stateErrorShip: "",
                isStateShipRecordNotFound: false,
                cityIdShip: "",
                cityArrShip: [],
                cityErrorShip: "",
                isCityShipRecordNotFound: false,
                zipCodeShip: "",
                zipCodeErrorShip: "",
                emailShip: "",
                emailErrorShip: "",
                phoneShip: "",
                phoneErrorShip: "",

                assetId: [],
                assetIdError: "",
                assetIdArr: [],
                isAssetRecordNotFound: false,
                description: "",
                descriptionError: "",
                gvw: "",
                gvwError: "",
                amount: "",
                amountError: "",
                isAmountEnter: false,  
                docFee: "70.00",
                prepFee: "895.00",
                miscCharge: "",
                miscChargeError: "",
                lienHolder: "",
                lienHolderError: "",
                subTotal: "",
                taxRate: "",
                taxRateError: "",
                taxRateAmount: "0.00",
                outOfState: "",
                outOfStateError: "",
                dmv: "",
                dmvError: "",
                irpINS: "",
                irpINSError: "",
                fet: "",
                fetError: "",
                tireRecyclingFee: "",
                tireRecyclingFeeError: "",
                tireRecyclingAmount: "0.00",
                // tireRecyclePercentage: '',
                tireRecyclePercentage: '75.000',
                warrantyType: "",
                warrantyTypeArr: [],
                warrantyTypeError: "",
                warranty: "",
                warrantyError: "",
                warrantyDiscount: "",
                warrantyDiscountError: "",
                downPayment: "",
                downPaymentError: "",
                total: "0.00",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Quotation",
                permissionNameArray : ['can_add_quotation_master'],
                
                customerName: '',
                customerNameError: '',
                customerSearchLength: 0,
                showCustomerNameColumn: false,
                
                customerShipName: '',
                customerShipNameError: '',
                customerShipSearchLength: 0,
                showCustomerNameShipColumn: false,
                
                dynamicLength: 7,
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            assetId: function(assetId) {
                self.gvw = '';
                if(assetId && assetId.length > 0) {
                    for(var index = 0; index < assetId.length; index++) {
                        var obj = assetId[index];
                        if(obj.gvw != null) {
                            self.gvw += obj.gvw + ",";
                        } else {
                            self.gvw += '' + ",";
                        }
                    }
                    self.gvw = self.gvw.replace(/,\s*$/, "");
                }
            },
            customerId: function(customerId) {
                if (customerId != null && customerId.value) {
                    self.customerError = self.customerNameError = "";
                    self.state = self.cityId = self.name = self.address = self.zipCode = self.email = self.phone = self.contact = '';
                    if(customerId.stateId != null) {
                        self.state = {text: customerId.stateName, value: customerId.stateId};
                        self.stateError = "";
                    }
                    if(customerId.cityId != null) {
                        self.cityId = {text: customerId.cityName, value: customerId.cityId};
                        self.cityError = "";
                    }
                    if(customerId.companyName != null && customerId.companyName != '') {
                        self.name = customerId.companyName;
                        self.nameError = "";
                    }
                    if(customerId.address != null && customerId.address != '') {
                        self.address = customerId.address;
                        self.addressError = "";
                    }
                    if(customerId.zipCode != null && customerId.zipCode != '') {
                        self.zipCode = customerId.zipCode;
                        self.zipCodeError = "";
                    }
                    if(customerId.email != null && customerId.email != '') {
                        self.email = customerId.email;
                        self.emailError = "";
                    }
                    if(customerId.primaryPhone != null && customerId.primaryPhone != '') {
                        self.contact = customerId.primaryPhone;
                        self.contactError = "";
                    }
                    if(customerId.alternatePhone != null && customerId.alternatePhone != '') {
                        self.phone = customerId.alternatePhone;
                        self.phoneError = "";
                    }
                }
                self.checkForm();
            },
            customerIdShip: function(customerIdShip) {
                if (customerIdShip != null && customerIdShip.value) {
                    self.customerErrorShip = self.customerShipNameError = "";
                    self.stateShip = self.cityIdShip = self.nameShip = self.addressShip = self.zipCodeShip = self.emailShip = self.phoneShip = self.contactShip = '';
                    if(customerIdShip.stateId != null) {
                        self.stateShip = {text: customerIdShip.stateName, value: customerIdShip.stateId};
                        self.stateErrorShip = "";
                    }
                    if(customerIdShip.cityId != null) {
                        self.cityIdShip = {text: customerIdShip.cityName, value: customerIdShip.cityId};
                        self.cityErrorShip = "";
                    }
                    if(customerIdShip.companyName != null && customerIdShip.companyName != '') {
                        self.nameShip = customerIdShip.companyName;
                        self.nameErrorShip = "";
                    }
                    if(customerIdShip.address != null && customerIdShip.address != '') {
                        self.addressShip = customerIdShip.address;
                        self.addressErrorShip = "";
                    }
                    if(customerIdShip.zipCode != null && customerIdShip.zipCode != '') {
                        self.zipCodeShip = customerIdShip.zipCode;
                        self.zipCodeErrorShip ="";
                    }
                    if(customerIdShip.email != null && customerIdShip.email != '') {
                        self.emailShip = customerIdShip.email;
                        self.emailErrorShip ="";
                    }
                    if(customerIdShip.primaryPhone != null && customerIdShip.primaryPhone != '') {
                        self.contactShip = customerIdShip.primaryPhone;
                        self.contactErrorShip ="";
                    }
                    if(customerIdShip.alternatePhone != null && customerIdShip.alternatePhone != '') {
                        self.phoneShip = customerIdShip.alternatePhone;
                        self.phoneErrorShip ="";
                    }
                }
                self.checkForm();
            },
            location: function(location) {
                if (location != null && location.value) {
                    self.docFee = '70.00';
                    self.prepFee = '895.00';
                    self.tireRecyclePercentage = '75.000';
                    if(location.docFee != null) {
                        self.docFee = location.docFee.toFixed(2);
                    }
                    if(location.prepFee != null) {
                        self.prepFee = location.prepFee.toFixed(2);
                    }
                    if(location.tireRecyclePercentage != null) {
                        self.tireRecyclePercentage = location.tireRecyclePercentage.toFixed(3);
                    }
                    self.calculateGrandTotal('');
                }
            },
            customerName: function(customerName) {
                // self.showCustomerNameColumn = true;
                if(customerName == '') {
                    self.showCustomerNameColumn = false;
                    self.loadAllData(['Contact'], true, self.loadContactCallback, "undefined", '', '', '', '', 1, '');
                    $("#customerId").focus();
                }
            },
            customerShipName: function(customerShipName) {
                // self.showCustomerNameShipColumn = true;
                if(customerShipName == '') {
                    self.showCustomerNameShipColumn = false; 
                    self.loadAllData(['Contact'], true, self.loadContactShipCallback, "undefined", '', '', '', '', 1, '');
                    $("#customerIdShip").focus();
                }
            }
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;

            self.setActiveMenu('quotation-list');
            EventBus.$emit("menu-selection-changed", "quotation-list");

            self.loadAllData(['Location'], true, self.loadLocationDataCallback, "undefined", '', '', '', '', '', 'yes');
            /* self.loadAllData(['Contact'], true, self.loadContactCallback, "undefined", '', '', '', '', 1, '');
            self.loadAllData(['Contact'], true, self.loadContactShipCallback, "undefined", '', '', '', '', 1, ''); */
            self.loadAllData(['Contact'], true, self.loadMasterDataCallback, "undefined", '', '', '', '', 1, '');
            self.loadAllData(['WarrantyType'], false, self.loadWarrantyTypeCallback, "undefined", '');

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getQuotationDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Quotation";
            }
        },
        methods: {
            loadMasterDataCallback: function(callbackData) {
                self.customerArr = callbackData.Contact;
                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {

                } else {
                    self.showCustomerNameColumn = false;
                }
                if(self.customerArr.length == 0 && self.customerSearchLength >= self.dynamicLength) {
                    self.showCustomerNameColumn = true;
                    $("#customerName").focus();
                }

                self.customerArrShip = callbackData.Contact;
                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {

                } else {
                    self.showCustomerNameShipColumn = false;
                }
                if(self.customerArrShip.length == 0 && self.customerShipSearchLength >= self.dynamicLength) {
                    self.showCustomerNameShipColumn = true;
                    $("#customerShipName").focus();
                }
            },
            calculateGrandTotal: function(blurColumnName) {
                if (self.location == null || !self.location.value) {
                    self.showSAlert('Please Select Location First!', 'info', false);
                    return false;
                }
                if(blurColumnName != '') {
                    $("#" + blurColumnName + "Error").text("");
                    if($("#" + blurColumnName).val() && !self.numberFormateValidation($("#" + blurColumnName).val())) {
                        $("#" + blurColumnName + "Error").text("Only Numeric Value Allow");
                        return false;
                    }
                }
                self.isAmountEnter = false;
                var tempTotal = '0.00';
                if(self.amount && self.numberFormateValidation(self.amount)) {
                    self.isAmountEnter = true;
                    self.subTotal = parseFloat(self.amount) + parseFloat(self.docFee) + parseFloat(self.prepFee);
                }
                if(self.miscCharge && self.numberFormateValidation(self.miscCharge)) {
                    if(self.amount && self.numberFormateValidation(self.amount)) {
                        self.subTotal = parseFloat(self.amount) + parseFloat(self.miscCharge) + parseFloat(self.docFee) + parseFloat(self.prepFee);
                    } else {
                        self.subTotal = parseFloat(self.miscCharge);
                    }
                }
                if(self.taxRate && self.numberFormateValidation(self.taxRate)) {
                    if(self.subTotal) {
                        self.taxRateAmount = parseFloat(self.subTotal) * parseFloat(self.taxRate) / 100;
                        self.taxRateAmount = parseFloat(self.taxRateAmount).toFixed(2);
                    }
                }
                if(self.subTotal) {
                    self.subTotal = parseFloat(self.subTotal).toFixed(2);
                    tempTotal = parseFloat(self.subTotal);
                    if(self.taxRateAmount) {
                        tempTotal = parseFloat(self.subTotal) + parseFloat(self.taxRateAmount);
                    }
                }
                if(self.outOfState && self.numberFormateValidation(self.outOfState)) {
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.outOfState);
                }
                if(self.dmv && self.numberFormateValidation(self.dmv)) {
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.dmv);
                }
                if(self.irpINS && self.numberFormateValidation(self.irpINS)) {
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.irpINS);
                }
                if(self.fet && self.numberFormateValidation(self.fet)) {
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.fet);
                }
                if(self.tireRecyclingFee && self.numberFormateValidation(self.tireRecyclingFee)) {
                    self.tireRecyclingAmount = parseFloat(self.tireRecyclingFee) * self.tireRecyclePercentage / 100;
                    self.tireRecyclingAmount = parseFloat(self.tireRecyclingFee) + parseFloat(self.tireRecyclingAmount);
                    self.tireRecyclingAmount = self.tireRecyclingAmount.toFixed(2);
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.tireRecyclingAmount);
                }
                if(self.warranty && self.numberFormateValidation(self.warranty)) {
                    tempTotal = parseFloat(tempTotal) + parseFloat(self.warranty);
                }
                if(self.warrantyDiscount && self.numberFormateValidation(self.warrantyDiscount)) {
                    tempTotal = parseFloat(tempTotal) - parseFloat(self.warrantyDiscount);
                }
                if(self.downPayment && self.numberFormateValidation(self.downPayment)) {
                    tempTotal = parseFloat(tempTotal) - parseFloat(self.downPayment);
                }
                if(self.total) {
                    self.total = tempTotal;
                    self.total = parseFloat(self.total).toFixed(2);
                }
            },
            buttonBack() {
                self.$router.push({ name: 'ListQuotation'})
            },
            changedValueLocation: function () {
            },
            changedValueWarrantyType: function () {
            },
            changedValue: function () {
            },
            loadLocationDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
            },
            onSearchSalesPerson: function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesPersonCallback, loading, search);
                }
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetIdArr = callbackData.Asset;
                if(self.assetIdArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            loadSalesPersonCallback: function (callbackData) {
                self.isSalesPersonRecordNotFound = false;
                self.salesPersonArr = callbackData.User;
                if (self.salesPersonArr.lenght == 0) {
                    self.isSalesPersonRecordNotFound = true;
                }
            },
            onSearchCustomer(search, loading) {
                self.customerSearchLength = search.length;
                self.customerName = search;
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search, '', '', '', 1, '');
                }
            },
            onSearchCustomerShip(search, loading) {
                self.customerShipSearchLength = search.length;
                self.customerShipName = search;
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactShipCallback, loading, search, '', '', '', 1, '');                    
                }
            },
            loadContactShipCallback: function (callbackData) {
                self.customerArrShip = callbackData.Contact;
                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {

                } else {
                    self.showCustomerNameShipColumn = false;
                }
                if(self.customerArrShip.length == 0 && self.customerShipSearchLength >= self.dynamicLength) {
                    self.showCustomerNameShipColumn = true;
                    $("#customerShipName").focus();
                }
            },
            loadContactCallback: function (callbackData) {
                self.customerArr = callbackData.Contact;
                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {

                } else {
                    self.showCustomerNameColumn = false;
                }
                if(self.customerArr.length == 0 && self.customerSearchLength >= self.dynamicLength) {
                    self.showCustomerNameColumn = true;
                    $("#customerName").focus();
                }
            },
            onSearchState(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            loadStateDataCallback: function (callbackData) {
                self.isStateRecordNotFound = false;
                self.stateArr = callbackData.States;
                if(self.stateArr.length == 0) {
                    self.isStateRecordNotFound = true;
                }
            },
            onSearchStateShip(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateShipDataCallback, loading, search);
                }
            },
            loadStateShipDataCallback: function (callbackData) {
                self.isStateShipRecordNotFound = false;
                self.stateArrShip = callbackData.States;
                if(self.stateArrShip.length == 0) {
                    self.isStateShipRecordNotFound = true;
                }
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select Bill State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            onSearchCityShip(search, loading) {
                if (self.stateShip == null || !self.stateShip.value) {
                    self.showSAlert('Please Select Ship State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityShipDataCallback, loading, search, self.state.value);
                    }
                }
            },
            loadCityDataCallback: function (callbackData) {
                self.isCityRecordNotFound = false;
                self.cityArr = callbackData.Cities;
                if(self.cityArr.length == 0) {
                    self.isCityRecordNotFound = true;
                }
            },
            loadCityShipDataCallback: function (callbackData) {
                self.isCityShipRecordNotFound = false;
                self.cityArrShip = callbackData.Cities;
                if(self.cityArrShip.length == 0) {
                    self.isCityShipRecordNotFound = true;
                }
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArr = callbackData.WarrantyType;
            },
            clearDataOnSaveAndContinue: function () {
                self.location = '';
                self.salesPerson = '';
                self.customerId = '';
                self.customerName = '';
                self.name = '';
                self.contact = '';
                self.address = '';
                self.state = '';
                self.cityId = '';
                self.zipCode = '';
                self.email = '';
                self.phone = '';
                
                self.customerIdShip = '';
                self.customerShipName = '';
                self.nameShip = '';
                self.contactShip = '';
                self.addressShip = '';
                self.stateShip = '';
                self.cityIdShip = '';
                self.zipCodeShip = '';
                self.emailShip = '';
                self.phoneShip = '';

                self.assetId = [];
                self.description = '';
                self.gvw = '';
                self.amount = '';
                self.isAmountEnter = false;
                self.miscCharge = "";
                self.lienHolder = "";
                self.subTotal = "";
                self.taxRate = "";
                self.taxRateAmount = "0.00";
                self.outOfState = "";
                self.dmv = "";
                self.irpINS = "";
                self.fet = "";
                self.tireRecyclingFee = "";
                self.tireRecyclingAmount = "0.00";
                self.warrantyType = "";
                self.warranty = "";
                self.warrantyDiscount = "";
                self.downPayment = "";
                self.total = "0.00";

                self.showCustomerNameColumn = false;
                self.showCustomerNameShipColumn = false;
                self.$nextTick(() => {
                    self.locationError = self.salesPersonError = self.customerError = self.customerNameError = self.nameError = self.contactError = self.addressError = self.stateError = self.cityError = self.zipCodeError = self.emailError = self.phoneError = self.customerErrorShip = self.customerShipNameError = self.nameErrorShip = self.contactErrorShip = self.addressErrorShip = self.stateErrorShip = self.cityErrorShip = self.zipCodeErrorShip = self.emailErrorShip = self.phoneErrorShip = self.assetIdError = self.descriptionError = self.gvwError = self.amountError = self.miscChargeError = self.lienHolderError = self.taxRateError = self.outOfStateError = self.dmvError = self.irpINSError = self.fetError = self.tireRecyclingFeeError = self.warrantyTypeError = self.warrantyError = self.warrantyDiscountError = self.downPaymentError = "";
                });
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getQuotationDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/quotation/get', config)
                    .then(response => {
                        self.location = {text: response.data.content.locationName + " " + response.data.content.dmLocId, value: response.data.content.location_id, docFee: response.data.content.doc_fee, prepFee: response.data.content.prep_fee, tireRecyclePercentage: response.data.content.tireRecyclePercentage, cityName: response.data.content.cityName, stateName: response.data.content.stateName};
                        self.salesPerson = {text: response.data.content.salesFirstName + ' ' + response.data.content.salesLastName, value: response.data.content.salesman_id};
                        self.showCustomerNameColumn = false;
                        if(response.data.content.customer_id == null) {
                            self.showCustomerNameColumn = true;
                            self.customerName = response.data.content.customer_name;
                        } else {
                            self.customerId = {text: response.data.content.contactFirstName + ' ' + response.data.content.contactLastName, value: response.data.content.customer_id, stateId: response.data.content.state_id, stateName: response.data.content.stateName, cityId: response.data.content.city_id, cityName: response.data.content.cityName, companyName: response.data.content.name, address: response.data.content.address, zipCode: response.data.content.zip_code, email: response.data.content.email, primaryPhone: response.data.content.contact, alternatePhone: response.data.content.phone};
                        }
                        self.name = response.data.content.name;
                        self.contact = response.data.content.contact;
                        self.address = response.data.content.address;
                        self.state = {text: response.data.content.stateName, value: response.data.content.state_id};
                        self.cityId = {text: response.data.content.cityName, value: response.data.content.city_id};
                        self.zipCode = response.data.content.zip_code;
                        self.email = response.data.content.email;
                        self.phone = response.data.content.phone;
                        
                        self.showCustomerNameShipColumn = false;
                        if(response.data.content.customer_id_ship == null) {
                            self.showCustomerNameShipColumn = true;
                            self.customerShipName = response.data.content.customer_name_ship;
                        } else {
                            self.customerIdShip = {text: response.data.content.contactFirstNameShip + ' ' + response.data.content.contactLastNameShip, value: response.data.content.customer_id_ship, stateId: response.data.content.state_id_ship, stateName: response.data.content.stateNameShip, cityId: response.data.content.city_id_ship, cityName: response.data.content.cityNameShip, companyName: response.data.content.name_ship, address: response.data.content.address_ship, zipCode: response.data.content.zip_code_ship, email: response.data.content.email_ship, primaryPhone: response.data.content.contact_ship, alternatePhone: response.data.content.phone_ship};
                        }
                        self.nameShip = response.data.content.name_ship;
                        self.contactShip = response.data.content.contact_ship;
                        self.addressShip = response.data.content.address_ship;
                        self.stateShip = {text: response.data.content.stateNameShip, value: response.data.content.state_id_ship};
                        self.cityIdShip = {text: response.data.content.cityNameShip, value: response.data.content.city_id_ship};
                        self.zipCodeShip = response.data.content.zip_code_ship;
                        self.emailShip = response.data.content.email_ship;
                        self.phoneShip = response.data.content.phone_ship;

                        self.assetId = response.data.content.inventory;
                        self.description = response.data.content.description;
                        self.gvw = response.data.content.gvw;
                        self.isAmountEnter = false;
                        if(response.data.content.amount && response.data.content.amount != null) {
                            self.isAmountEnter = true;
                            self.amount = response.data.content.amount.toFixed(2);
                        }
                        if(response.data.content.doc_fee && response.data.content.doc_fee != null) {
                            self.docFee = response.data.content.doc_fee.toFixed(2);
                        }
                        if(response.data.content.prep_fee && response.data.content.prep_fee != null) {
                            self.prepFee = response.data.content.prep_fee.toFixed(2);
                        }
                        if(response.data.content.misc_charge && response.data.content.misc_charge != null) {
                            self.miscCharge = response.data.content.misc_charge.toFixed(2);
                        }
                        self.lienHolder = response.data.content.lienholder;
                        if(response.data.content.sub_total && response.data.content.sub_total != null) {
                            self.subTotal = response.data.content.sub_total.toFixed(2);
                        }
                        if(response.data.content.tax_rate && response.data.content.tax_rate != null) {
                            self.taxRate = response.data.content.tax_rate.toFixed(3);
                        }
                        if(response.data.content.tax_rate_amount && response.data.content.tax_rate_amount != null) {
                            self.taxRateAmount = response.data.content.tax_rate_amount.toFixed(2);
                        }
                        if(response.data.content.out_of_state && response.data.content.out_of_state != null) {
                            self.outOfState = response.data.content.out_of_state.toFixed(2);
                        }
                        if(response.data.content.amount && response.data.content.amount != null) {
                            self.dmv = response.data.content.dmv.toFixed(2);
                        }
                        if(response.data.content.irp_ins && response.data.content.irp_ins != null) {
                            self.irpINS = response.data.content.irp_ins.toFixed(2);
                        }
                        if(response.data.content.fet && response.data.content.fet != null) {
                            self.fet = response.data.content.fet.toFixed(2);
                        }
                        if(response.data.content.tire_recycling_fee && response.data.content.tire_recycling_fee != null) {
                            self.tireRecyclingFee = response.data.content.tire_recycling_fee.toFixed(2);
                        }
                        if(response.data.content.tire_recycling_fee_total && response.data.content.tire_recycling_fee_total != null) {
                            self.tireRecyclingAmount = response.data.content.tire_recycling_fee_total.toFixed(2);
                        }
                        self.warrantyType = {text: response.data.content.warrantyTypeName, value: response.data.content.warranty_type_id};
                        if(response.data.content.warranty && response.data.content.warranty != null) {
                            self.warranty = response.data.content.warranty.toFixed(2);
                        }
                        if(response.data.content.warranty_discount && response.data.content.warranty_discount != null) {
                            self.warrantyDiscount = response.data.content.warranty_discount.toFixed(2);
                        }
                        if(response.data.content.down_payment && response.data.content.down_payment != null) {
                            self.downPayment = response.data.content.down_payment.toFixed(2);
                        }
                        if(response.data.content.total && response.data.content.total != null) {
                            self.total = response.data.content.total.toFixed(2);
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                self.locationError = "";
                if (self.location == null || !self.location.value) {
                    self.locationError = "Please select location";
                    if (checked) {
                        checked = false;
                    }
                }
                self.salesPersonError =  "";
                if (self.salesPerson == null || !self.salesPerson.value) {
                    self.salesPersonError = "Please select sales person";
                    if (checked) {
                        checked = false;
                    }
                }
                self.customerError = "";
                if(!self.showCustomerNameColumn) {
                    if (self.customerId == null || !self.customerId.value) {
                        self.customerError = "Please select customer";
                        if (checked) {
                            checked = false;
                        }
                    }
                } else {
                    self.customerNameError = "";
                    if (!self.customerName) {
                        self.customerNameError = "Please enter Customer Name";
                        if (checked) {
                            checked = false;
                        }
                    } 
                }
                self.nameError = "";
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                self.contactError = "";
                if (!self.contact) {
                    self.contactError = "Please enter contact no";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.contact && !self.validContactNumber(self.contact)) {
                    self.contactError = "Please enter valid contact";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.contact && !self.checkContactNoLength(self.contact)) {
                    self.contactError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.addressError = "";
                if (!self.address) {
                    self.addressError = "Please enter address";
                    if (checked) {
                        checked = false;
                    }
                }
                self.stateError = "";
                if (self.state == null || !self.state.value) {
                    self.stateError = "Please select state";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cityError = "";
                if (self.cityId == null || !self.cityId.value) {
                    self.cityError = "Please select City";
                    if (checked) {
                        checked = false;
                    }
                }
                self.zipCodeError = "";
                if (!self.zipCode) {
                    self.zipCodeError = "Please enter zip Code";
                    if (checked) {
                        checked = false;
                    }
                }
                self.emailError = "";
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                self.phoneError = "";
                if (!self.phone) {
                    self.phoneError = "Please enter phone";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.phone && !self.validContactNumber(self.phone)) {
                    self.phoneError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.phone && !self.checkContactNoLength(self.phone)) {
                    self.phoneError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.customerErrorShip = "";
                if(!self.showCustomerNameShipColumn) {
                    if (self.customerIdShip == null || !self.customerIdShip.value) {
                        self.customerErrorShip = "Please select customer";
                        if (checked) {
                            checked = false;
                        }
                    }
                } else {
                    self.customerShipNameError = "";
                    if (!self.customerShipName) {
                        self.customerShipNameError = "Please enter Customer Name";
                        if (checked) {
                            checked = false;
                        }
                    } 
                }
                self.nameErrorShip = "";
                if (!self.nameShip) {
                    self.nameErrorShip = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                self.contactErrorShip = "";
                if (!self.contactShip) {
                    self.contactErrorShip = "Please enter contact no";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.contactShip && !self.validContactNumber(self.contactShip)) {
                    self.contactErrorShip = "Please enter valid contact";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.contactShip && !self.checkContactNoLength(self.contactShip)) {
                    self.contactErrorShip = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.addressErrorShip = "";
                if (!self.addressShip) {
                    self.addressErrorShip = "Please enter address";
                    if (checked) {
                        checked = false;
                    }
                }
                self.stateErrorShip = "";
                if (self.stateShip == null || !self.stateShip.value) {
                    self.stateErrorShip = "Please select state";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cityErrorShip = "";
                if (self.cityIdShip == null || !self.cityIdShip.value) {
                    self.cityErrorShip = "Please select City";
                    if (checked) {
                        checked = false;
                    }
                }
                self.zipCodeErrorShip = "";
                if (!self.zipCodeShip) {
                    self.zipCodeErrorShip = "Please enter zip Code";
                    if (checked) {
                        checked = false;
                    }
                }
                self.emailErrorShip = "";
                if (!self.emailShip) {
                    self.emailErrorShip = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.emailShip && !self.validEmail(self.emailShip)) {
                    self.emailErrorShip = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                self.phoneErrorShip = "";
                if (!self.phoneShip) {
                    self.phoneErrorShip = "Please enter phone";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.phoneShip && !self.validContactNumber(self.phoneShip)) {
                    self.phoneErrorShip = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.phoneShip && !self.checkContactNoLength(self.phoneShip)) {
                    self.phoneErrorShip = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }

                self.assetIdError = "";
                if (self.assetId.length == 0) {
                    self.assetIdError = "Please select Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                self.gvwError = "";
                if (!self.gvw) {
                    self.gvwError = "Please enter GVW";
                    if (checked) {
                        checked = false;
                    }
                } else {
                    if (self.assetId.length > 0) {
                        var gvwArr = self.gvw.split(',');
                        if(gvwArr.length != self.assetId.length) {
                            self.gvwError = "Please enter GVW for all inventory with comma separated.";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                }
                /* if (!self.description) {
                    self.descriptionError = "Please enter description";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* if (!self.gvw) {
                    self.gvwError = "Please enter GVW";
                    if (checked) {
                        checked = false;
                    }
                } */
                self.amountError = "";
                if(self.amount && !self.numberFormateValidation(self.amount)) {
                    self.amountError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.miscChargeError = "";
                if(self.miscCharge && !self.numberFormateValidation(self.miscCharge)) {
                    self.miscChargeError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.taxRateError = "";
                if(self.taxRate && !self.numberFormateValidation(self.taxRate)) {
                    self.taxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.outOfStateError = "";
                if(self.outOfState && !self.numberFormateValidation(self.outOfState)) {
                    self.outOfStateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.dmvError = "";
                if(self.dmv && !self.numberFormateValidation(self.dmv)) {
                    self.dmvError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.irpINSError = "";
                if(self.irpINS && !self.numberFormateValidation(self.irpINS)) {
                    self.irpINSError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.fetError = "";
                if(self.fet && !self.numberFormateValidation(self.fet)) {
                    self.fetError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.tireRecyclingFeeError = "";
                if(self.tireRecyclingFee && !self.numberFormateValidation(self.tireRecyclingFee)) {
                    self.tireRecyclingFeeError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.warrantyTypeError = "";
                if (self.warrantyType == null || !self.warrantyType.value) {
                    self.warrantyTypeError = "Please select warranty Type";
                    if (checked) {
                        checked = false;
                    }
                }
                self.warrantyError = "";
                if(self.warranty && !self.numberFormateValidation(self.warranty)) {
                    self.warrantyError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.warrantyDiscountError = "";
                if(self.warrantyDiscount && !self.numberFormateValidation(self.warrantyDiscount)) {
                    self.warrantyDiscountError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.downPaymentError = "";
                if(self.downPayment && !self.numberFormateValidation(self.downPayment)) {
                    self.downPaymentError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addQuotation(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var asset_id = '';
                        if( self.assetId && self.assetId.length > 0 ) {
                            for (var i = 0; i < self.assetId.length; i++) {
                                asset_id += (self.assetId[i].value) + ',';
                            }
                            asset_id = asset_id.replace(/,\s*$/, "");
                        }
                        var data = new FormData();
                        data.append('location', self.location.value);
                        data.append('sales_person', self.salesPerson.value);
                        if(!self.showCustomerNameColumn) {
                            data.append('customer_id', self.customerId.value);
                            data.append('customer_name', 'NULL');
                        } else {
                            data.append('customer_id', 'NULL');
                            data.append('customer_name', self.customerName);
                        }
                        data.append('name', self.name);
                        data.append('contact', self.contact);
                        data.append('address', self.address);
                        data.append('state_id', self.state.value);
                        data.append('city_id', self.cityId.value);
                        data.append('zip_code', self.zipCode);
                        data.append('email', self.email);
                        data.append('phone', self.phone);
                        
                        if(!self.showCustomerNameShipColumn) {
                            data.append('customer_id_ship', self.customerIdShip.value);
                            data.append('customer_name_ship', 'NULL');
                        } else {
                            data.append('customer_id_ship', 'NULL');
                            data.append('customer_name_ship', self.customerShipName);
                        }
                        data.append('name_ship', self.nameShip);
                        data.append('contact_ship', self.contactShip);
                        data.append('address_ship', self.addressShip);
                        data.append('state_id_ship', self.stateShip.value);
                        data.append('city_id_ship', self.cityIdShip.value);
                        data.append('zip_code_ship', self.zipCodeShip);
                        data.append('email_ship', self.emailShip);
                        data.append('phone_ship', self.phoneShip);

                        data.append('asset_id', asset_id);
                        data.append('description', self.description);
                        data.append('gvw', self.gvw);
                        data.append('amount', self.amount);
                        data.append('doc_fee', self.amount != '' ? self.docFee : '');
                        data.append('prep_fee', self.amount != '' ? self.prepFee : '');
                        data.append('misc_charge', self.miscCharge);
                        data.append('sub_total', self.subTotal);
                        data.append('lien_holder', self.lienHolder);
                        data.append('tax_rate', self.taxRate);
                        data.append('tax_rate_amount', self.taxRateAmount);
                        data.append('out_of_state', self.outOfState);
                        data.append('dmv', self.dmv);
                        data.append('irp_ins', self.irpINS);
                        data.append('fet', self.fet);
                        data.append('tire_recycling_fee', self.tireRecyclingFee);
                        data.append('tire_recycling_amount', self.tireRecyclingAmount);
                        data.append('warranty_type', self.warrantyType.value);
                        data.append('warranty', self.warranty);
                        data.append('warranty_discount', self.warrantyDiscount);
                        data.append('down_payment', self.downPayment);
                        data.append('total', self.total);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/quotation/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>
<style scoped>
    .customer-ship-block {
        float: left;
        width: 100%;
        margin: 0 0 15px 0;
        padding: 0;
        font-weight: normal;
        font-size: 28px;
        color: #0f467c;
        font-family: 'SanFranciscoDisplay-Bold';
    }
</style>