<template>
  <div class="spaced-container">
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!-- Page Content -->
        <div class="admin-page-content">
          <!-- Forms -->
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <ul class="nav nav-tabs">
                <li
                  class="nav-item"
                  v-if="permissionNameArray.includes('can_add_vendor_master')"
                >
                  <a
                    v-on:click="activetab = 1"
                    v-bind:class="
                      'nav-link ' + [activetab === 1 ? 'active' : '']
                    "
                    >Edit Vendor
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_list_vendor_item_vendor_master'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 2"
                    v-bind:class="{ 'nav-link': true, active: activetab === 2 }"
                    >Vendor Item
                  </a>
                </li>
              </ul>

              <div
                v-if="activetab === 1"
                class="tabcontent"
                id="vendor-activity-logs1"
              >
                <add-update-vendor></add-update-vendor>
              </div>

              <div
                v-if="activetab === 2"
                class="tabcontent"
                id="vendor-activity-logs2"
              >
                <list-vendor-item :vendorId="vendorIteamId"></list-vendor-item>
              </div>
            </div>
          </div>
          <!-- Form -->
        </div>
        <!-- Page content -->
      </div>
      <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
  </div>
</template>

<script>
let self;
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import AddUpdateVendor from "./AddUpdateVendor.vue";
import ListVendorItem from "./ListVendorItem.vue";
import Footer from "@/components/partials/Footer";

export default {
  name: "VendorTabView",
  mixins: [commonMixins],
  components: {
    "add-update-vendor": AddUpdateVendor,
    "list-vendor-item": ListVendorItem,
    "app-footer": Footer,
  },

  data() {
    return {
      activetab: 1,
      pageTitle: "Vendor Tab View",
      permissionNameArray: [
        "can_add_vendor_master",
        "can_list_vendor_item_vendor_master",
      ],
      vendorIteamId: 0,
    };
  },

  beforeMount() {
    self = this;
  },

  mounted() {
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }

    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.vendorIteamId = self.$route.params.id;
    }

    if (
      typeof self.$route.query.activeTab != "undefined" &&
      self.$route.query.activeTab != "" &&
      self.$route.query.activeTab != 0
    ) {
      self.activetab = parseInt(self.$route.query.activeTab);
    }
  },

  methods: {
    buttonBack() {
      self.$router.push({ name: "ListVendor" });
    },
  },

  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newValue) {
        if (!newValue || newValue === self.activetab) {
          return;
        }

        self.activetab = newValue;
      },
    },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
