<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_qr_code_history_list_inventory_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <!-- <div class="page_title_left">
                            <span>{{userName}} (QRCode History)</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="extraFeature btn btn-primary waves-effect pull-right ml-2">Back</button>
                        <a id='refreshList' title='Refresh' class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a> -->
                        <a id='exportQRCodeHistory'
                            title='Export'
                            v-show="permissionNameArray.includes('can_qr_code_history_export_inventory_master')"
                            class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                            v-on:click.prevent="exportUserWiseQRCodeHistory($event)">
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </a>
                    </div>
                    <!-- <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="filterheaders" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                    </div> -->
                    <div class="col-12 paddingnone">
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                    <template v-if="qrCodeHistoryArr && qrCodeHistoryArr.length > 0">
                                        <template  v-for="(qrCodeHistory, index) in qrCodeHistoryArr">
                                            <!-- <tr :key="index">
                                                <td :class="rowClass.vinNo">{{qrCodeHistory.vinNo}}</td>
                                                <td :class="rowClass.stock">{{qrCodeHistory.stock}}</td>
                                                <td :class="rowClass.user_full_name">{{qrCodeHistory.user_full_name}}</td>
                                                <td :class="rowClass.old_area_on_yard">{{qrCodeHistory.old_area_on_yard}}</td>
                                                <td :class="rowClass.old_company_name">{{qrCodeHistory.old_company_name}}</td>
                                                <td :class="rowClass.old_location_name">{{qrCodeHistory.old_location_name}}</td>
                                                <td :class="rowClass.new_area_on_yard">{{qrCodeHistory.new_area_on_yard}}</td>
                                                <td :class="rowClass.new_company_name">{{qrCodeHistory.new_company_name}}</td>
                                                <td :class="rowClass.new_location_name">{{qrCodeHistory.new_location_name}}</td>
                                                <td :class="rowClass.updated_at">{{qrCodeHistory.updated_at}}</td>
                                            </tr> -->
                                            <tr class="mainDetails" :key="index">
                                                <template v-if="qrCodeHistoryArr && qrCodeHistoryArr.length > 0">
                                                    <template v-for="(expenseData, expenseDataIndex) in qrCodeHistoryArr">
                                                        <template v-if="index == expenseDataIndex">
                                                            <template v-for="(data, dataIndex) in expenseData">
                                                                <template v-for="(headerData, headerIndex) in headers">
                                                                    <template v-if="headerData.column_name == dataIndex">
                                                                        <td :class="headerData.column_class" :key="dataIndex"> {{data}} </td>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                            </tr>
                                            <tr class="extraDetails">
                                                <td colspan="2">

                                                </td>
                                                <td :colspan="headers.length - 1" class="text-alignment-left">
                                                    <list-accordian :accordianData="qrCodeHistory" :displayFields="returnAccordianDisplayDataKeys()"></list-accordian>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                        v-model="perPage"
                                        @input="changePerPage"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>
                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
                <!-- Export Modal -->
                <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exportModalLabel">Export QRCode History</h4>
                            </div>
                            <form class="form-inline" id="exportModalForm" enctype="multipart/form-data">
                                <div class="col-md-12 modal-body" id="modal-checkbox">
                                    <template v-if="exportHeaders && exportHeaders.length > 0">
                                        <!-- Select All -->
                                        <div class="col-12 paddingleft0 custom-checkbox-v">
                                            <label class="container">
                                                <input type="checkbox" v-model="isSelectAllExportHeader" @click="selectAllExportHeaders()" class="form-control" id="ExportList">
                                                <strong v-if="isSelectAllExportHeader"><p class="modal-check-lbl">{{staticInputLabelMessages.deselect_all_label}}</p></strong>
                                                <strong v-else><p class="modal-check-lbl">{{staticInputLabelMessages.select_all_label}}</p></strong>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <template v-for="(exportOpp, exportIndex) in exportHeaders">
                                            <div :key="exportIndex" class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v">
                                                <label class="container">
                                                    <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                                                    <input :value="exportOpp" class="form-control" type="checkbox" v-model="exportSelectedHeaders"  @change="updateCheckedExportHeaders()" /> <p class="modal-check-lbl">{{exportOpp['name']}}</p>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                                <div class="row clearfix"></div>
                                <div class="col-md-12 modal-footer">
                                    <button type="button" v-on:click="exportSelectedHeadersData($event);" class="btn general-button btn-success waves-effect">Export</button>
                                    <button type="button" class="btn general-button btn-danger waves-effect" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "updated_at";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListQRCodeHistory',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
            'list-accordian': ListAccordianComponent,
        },
        data () {
            return {
                perPageValue : 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                qrCodeHistoryArr: [],
                headers: [],
                filterheaders: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord : "",
                selectAll: false,
                permissionNameArray : ['can_qr_code_history_list_inventory_master','can_qr_code_history_export_inventory_master'],
                actionDisplay : true,
                accordianIds : [],
                jumpToHistoryPage : 1,
                filterCount : 0,
                userName: '',
                assetId: '',
                exportHeaders: [],
                exportSelectedHeaders: [],
                isSelectAllExportHeader: false,
                exportUserWiseQRCodeHistoryRequest: false
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;

            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            /* var listHistoryData = self.loadFilters('listQRCodeHistory', self);
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page; */

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                self.loadQRCodeHistory(0, self.assetId);
            }
            self.getLoginIdPermissions();
        },
        methods: {
            buttonBack: function() {
                self.$router.push({ name: 'ListUser'});
            },
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadQRCodeHistory(0, self.assetId);
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadQRCodeHistory(0, self.assetId);
                }
            },
            returnAccordianDisplayDataKeys : function() {
                let dataArray = [
                    {
                        field : "id",
                        label : self.allLabelMessages.id_label,
                        alt : ""
                    },
                ];
                return dataArray;
            },
            toggleIcon : function(id) {
                self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadQRCodeHistory(0, self.assetId);
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.qrCodeHistoryArr) {
                            self.selectedRows.push(self.qrCodeHistoryArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.qrCodeHistoryArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                // self.filters = {};
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadQRCodeHistory(0, self.assetId);
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    // self.headers = self.appliedHeaders;
                    self.filterheaders = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            selectAllExportHeaders: function () {
                self.exportSelectedHeaders = [];
                if (!self.isSelectAllExportHeader) {
                    for (let key in self.exportHeaders) {
                        self.exportSelectedHeaders.push(self.exportHeaders[key]);
                    }
                }
                /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
            },
            updateCheckedExportHeaders: function() {
                /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
                console.log("Total export header : " + self.exportHeaders.length); */
                if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
                    self.isSelectAllExportHeader = true;
                } else {
                    self.isSelectAllExportHeader = false;
                }
            },
            exportUserWiseQRCodeHistory: function () {
                $('#exportModal').modal('show');
                /* self.exportUserWiseQRCodeHistoryRequest = true;
                if (self.exportUserWiseQRCodeHistoryRequest == true) {
                    $('#exportQRCodeHistory').prop("disabled", true);
                    self.loadQRCodeHistory(0, self.assetId);
                } */
            },
            exportSelectedHeadersData: function() {
                // if(Object.keys(self.exportSelectedHeaders).length == 0) {
                if(self.exportSelectedHeaders.length == 0) {
                    self.showSAlert("Please Select at least one checkbox", 'info', false);
                    return false;
                }
                /* $('#modal-checkbox input:checkbox').prop('checked', false); */
                self.exportUserWiseQRCodeHistoryRequest = true;
                if (self.exportUserWiseQRCodeHistoryRequest == true) {
                    $('#exportQRCodeHistory').prop("disabled", true);
                    self.loadQRCodeHistory(0, self.assetId);
                    /* self.isSelectAllExportHeader = false; */
                }
                if(self.exportSelectedHeaders != null && self.exportSelectedHeaders !='undefined' && self.exportSelectedHeaders.length > 0){
                    self.storeExportHeaders('exportQRCodeAsset', self.exportSelectedHeaders);
                } else {
                    self.exportSelectedHeaders = [];
                }
            },
            loadQRCodeHistory: function(first, assetId) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadQRCodeHistory();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    assetId: self.assetId,
                    is_export: self.exportUserWiseQRCodeHistoryRequest,
                    export_selected_headers: self.exportSelectedHeaders
                };
                // this.storeFilters('listQRCodeHistory', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/qrcode/history/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        /* self.exportSelectedHeaders = []; */
                        self.exportSelectedHeaders = self.loadExportHeaders('exportQRCodeAsset', self);
                        self.exportSelectedHeaders = (self.exportSelectedHeaders === null || typeof (self.exportSelectedHeaders) === "undefined" || self.exportSelectedHeaders == '') ? [] : self.exportSelectedHeaders;
                        $('#exportModal').modal('hide');
                        $('#exportQRCodeHistory').prop("disabled", false);
                        self.exportUserWiseQRCodeHistoryRequest = false;
                        if (data.status == 200) {
                            if (data.data.message == 'success export') {
                                self.downloadFile(data.data.content.web);
                                // location.href = data.data.content.web;
                            } else { 
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                    // alert(self.totalRecords);
                                    let seconds = 5000;
                                    self.$swal({
                                        type: 'error',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        showConfirmButton: false,
                                        timer: seconds,
                                    });
                                    setTimeout(() => {
                                        self.perPage = 500;
                                        self.loadQRCodeHistory();
                                    }, seconds);
                                    /* self.$swal({
                                        title: '',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        type: 'error',
                                        confirmButtonText: 'OK',
                                        showCancelButton: true,
                                        showLoaderOnConfirm: true
                                    }).then((result) => {
                                        if (result.value) {
                                            self.logout();
                                        } else {
                                            self.perPage = 15;
                                            self.loadQRCodeHistory();
                                        }
                                    }); */
                                }
                                self.qrCodeHistoryArr = data.data.content.data;
                                $("#selectAllCheckBox").prop("checked", false);
                                self.headers = data.data.content.headers;
                                self.exportHeaders = data.data.content.exportheaders;
                                if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
                                    self.isSelectAllExportHeader = true;
                                }
                                self.loadFilterInputs(data.data.content.filterheaders,self.filters);
                                self.filterheaders = data.data.content.filterheaders;

                                self.addColumnClass(self.headers);
                                let testArray = [
                                    {
                                        'text' : 1,
                                        'value' : '2020-02-01'
                                    },
                                    {
                                        'text' : 2,
                                        'value' : '2020-02-02'
                                    },
                                    {
                                        'text' : 3,
                                        'value' : '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                if (data.data.content.per_page !== self.totalRecords) {
                                    self.perPage = parseInt(data.data.content.per_page);
                                    self.jumpToPage = parseInt(data.data.content.current_page);
                                } else {
                                    if(self.perPage == 'All'){
                                        self.jumpToPage = 1;
                                    }
                                    self.perPageValue = self.totalRecords;
                                    self.currentPage = 1;
                                }
                                self.setAlreadyAppliedFilters();
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
                                self.sortFieldDisplay(self.columnName,self.sortType);
                            }
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        $('#exportQRCodeHistory').prop("disabled", false);
                        self.exportUserWiseQRCodeHistoryRequest = false;
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if(self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadQRCodeHistory(0, self.userId);
                }
            },
        },
    }
</script>