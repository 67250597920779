<template>
  <div class="modal" id="calculateFlooring" tabindex="-1" role="dialog" aria-labelledby="calculateFlooringLabel" aria-hidden="true">
    <div v-if="isClickCalFlooring" class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="calculateFlooringLongTitle">Calculate Flooring</h5>
          <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeFlooringModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label for="start-date" class="col-form-label float-right">Start:</label>
                </div>
                <div class="col-md-6">
                  <date-picker
                        v-model="startDate"
                        :config="options"
                        class="form-control"
                        autocomplete="off"
                        placeholder="Select Start Date"
                        name="startDate"
                      />
                </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                  <label for="end-date" class="col-form-label float-right">End:</label>
              </div>
              <div class="col-md-6">
                <date-picker
                    v-model="endDate"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Select End Date"
                    name="endDate"
                  />
              </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <label for="days" class="col-form-label float-right">Days:</label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="days" disabled />
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <label for="rate" class="col-form-label float-right">Rate:</label>
                </div>
                <div class="col-md-6">
                    <money v-bind="rateMask" type="text" class="form-control" v-model="rate" @blur="calculateFlooring()" @keyup.native="event => {incrementDecrementNumber(event, 'rate')}" />
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <label for="flooring" class="col-form-label float-right">Flooring:</label>
                </div>
                <div class="col-md-6">
                    <money v-bind="moneyMask" type="text" class="form-control" v-model="flooring" disabled />
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="calculateFlooring()">Calculate</button>
          <button type="button" class="btn btn-primary" @click="calculateFlooring('save')">Save & Close</button>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
    import {Money} from 'v-money';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import eventBus from '../../event-bus';
    import { commonMixins } from '../../../mixins/commonMixins';

    export default {
      name: 'FlooringCalModal',
      props: ['factoryPrice','isClickCalFlooring'],
      components: {
        Money,
        datePicker
      },
      mixins: [commonMixins],
      data() {
        return {
          startDate: new Date(),
          endDate: new Date(),
          rate: 6.5,
          days: 1,
          flooring: 0,
          options: {
            format: 'MM/DD/YYYY',
            useCurrent: true,
            allowInputToggle: true,
            widgetPositioning: {
              horizontal: 'auto',
              vertical: 'bottom'
            },
            minDate:new Date(),
          },
          moneyMask: {
            thousands: ',',
            prefix: '$ ',
            precision: 2,
          },
          rateMask: {
            decimal: '.',
            suffix: ' %',
            precision: 2,
          },
        }
      },
      mounted() {
        this.startDate= new Date();
        this.endDate= new Date();
        this.rate= 6.5;
        this.days= 1;
        this.flooring= 0;
      },
      methods: {
        calculateFlooring(type=null) {
          if (this.startDate != '' && this.endDate != '') {
            let date_start = new Date(this.startDate);
            let date_end = new Date(this.endDate);

            if (date_start <= date_end) {
                this.days = this.daysBetween(date_start, date_end) + 1;
                // if(this.rate == 0){
                //   this.rate = 6.5; // preset rate
                // }

                if (this.rate != '') {
                  let cost = this.factoryPrice;
                  let rate = this.rate / 100
                  let flooring = ((cost * rate) / 360) * this.days;
                  // var custom9 = GetParentValue("tbCustom9");
                  // flooring = Math.max(0, flooring - custom9); // ARC-49148479
                  this.flooring = Math.round(flooring * 100) / 100;
                }
                else {
                  if (this.rate == 0) {
                      this.flooring = 0;
                  }
                }
            }
            else {
              this.showSAlert('Start Date should be equal to or greater than End Date', 'error', false, 2);
              this.flooring = 0;
            }
          }

          if(type=="save") {
            this.closeFlooringModal();
          }
        },
        daysBetween(date1, date2) {
          // The number of milliseconds in one day
          var ONE_DAY = 1000 * 60 * 60 * 24

          // Convert both dates to milliseconds
          var date1_ms = date1.getTime()
          var date2_ms = date2.getTime()

          // Calculate the difference in milliseconds
          var difference_ms = Math.abs(date1_ms - date2_ms)

          // Convert back to days and return
          return Math.round(difference_ms / ONE_DAY)
        },
        incrementDecrementNumber(event, dataInstance) {
          switch (event.key) {
            case 'ArrowUp':
              this[dataInstance] += 1;
              break;
            case 'ArrowDown':
              this[dataInstance] -= 1;
              break;
          }
        },
        closeFlooringModal() {
          eventBus.$emit("flooring", this.flooring);
        }
      },
    }
  </script>