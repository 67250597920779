<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template>
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>{{allLabelMessages.sales_build_sheet}}</span>
                        </div>
                        <button style="margin-right: 5px;" type="submit" v-on:click.prevent="next" class="btn btn-primary waves-effect pull-right" id="buildUpNext">{{allLabelMessages.next}}</button>
                        <button style="margin-right: 5px;" type="submit" v-on:click.prevent="printBuildUp" class="btn btn-primary waves-effect pull-right" id="printQuotBtn">{{allLabelMessages.print_build_up}}</button>
                        <button style="margin-right: 5px;" type="submit" v-on:click.prevent="updateDeal(true, null)" class="btn btn-primary waves-effect pull-right">{{allLabelMessages.save}}</button>
                        <button style="margin-right: 5px;" type="submit" v-on:click.prevent="back" class="btn btn-primary waves-effect pull-right">{{allLabelMessages.back}}</button>
                    </div>
                </div>
                <div class="admin-page-content">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.s_p}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control">{{buildUpData.sP}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.stock_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="stock" v-model="buildUpData.stock" class="form-control" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="col-md-12 col-sm-12 forms-block">
                                <div class="col-md-2 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.buyer}}</div>
                                </div>
                                <div class="col-md-10 col-sm-12 paddingleft0">
                                    <span class="form-control" style="margin-left: -5px">{{buildUpData.customerCompany}}</span>
                                    <!-- <input id="buyer" v-model="buildUpData.customerCompany" style="margin-left: -5px" class="form-control" type="text" /> -->
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cust}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control">{{buildUpData.cust}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.deal}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control">{{buildUpData.dealNo}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control">{{buildUpData.model}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.delivery_date}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <date-picker v-model="buildUpData.deliveryDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.delivery_date_place_holder" name="deliveryDate">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{allLabelMessages.build_order}}</th>
                                        <th>{{allLabelMessages.body_and_accessories}}</th>
                                        <th>{{allLabelMessages.supplier}}</th>
                                        <th>{{allLabelMessages.order_no}}</th>
                                        <th>{{allLabelMessages.ex_gst_$}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(factoryItem, factoryIndex) in buildUpData.factoryItemsArr">
                                        <td>-</td>
                                        <td>{{factoryItem.factoryOption}}</td>
                                        <td>{{factoryItem.factoryTitle}}</td>
                                        <td>{{factoryItem.factoryOrderNo}}</td>
                                        <td>{{factoryItem.factoryPriceGst}}</td>
                                    </tr>
                                    <tr v-for="(lineItem, lineIndex) in buildUpData.lineItemsArr">
                                        <td>-</td>
                                        <td>{{lineItem.buildUp}}</td>
                                        <td>{{lineItem.supplier}}</td>
                                        <td>{{lineItem.orderNo}}</td>
                                        <td>{{lineItem.priceGST}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    var self;
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    export default {
        name: 'BuildUp',
        components: {
            'no-access': NoAccessPage,
            'app-footer' : Footer,
            datePicker
        },
        mixins: [commonMixins],
        props: ['data'],
        data() {
            return {
                buildUpData: {
                    sP: null,
                    stock: null,
                    buyer: null,
                    cust: null,
                    deal: null,
                    model: null,
                    deliveryDate: null
                },
                options: {
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    }
                }
            }
        },
        watch: {
        },
        beforeMount() {
            self = this;
            let dateFormat = process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT.split(' ')[0];
            self.options = {
                format: dateFormat,
                useCurrent: false,
                allowInputToggle: true,
                widgetPositioning: {
                    horizontal: "auto",
                    vertical: "bottom"
                }
            }
        },
        mounted: function () {
            // console.log('params in buildup ', self.$route.query)
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            } else {
                var userJson = JSON.parse(userObj);
                self.buildUpData.sP = userJson['first_name'] + " " + userJson['last_name'] + ' - ' + userJson['contact_no']
            }
            $('.navbar').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.getDealData()
        },
        methods: {
            next () {
                self.updateDeal(false, 'next')
                // self.$emit('clickOnNext', 'conquest', self.buildUpData)
            },
            printBuildUp () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.post("/AU/print-build-up", self.buildUpData, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            self.downloadFile(response.data.content.web);
                        }
                        if (response.status == 201) {
                            self.showSAlert(response.data.content[0], 'error', false);
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            updateDeal (isShowAlert, action) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = {
                    'stock': self.buildUpData.stock,
                    'deal_no': self.buildUpData.dealNo,
                    'delivery_date': self.buildUpData.deliveryDate.split("/").reverse().join("/"),
                    'type': 'build_up'
                }
                HTTP.post("/AU/update-deal", data, config)
                    .then(function (response) {
                        if (isShowAlert) {
                            self.showSAlert(response.data.message, 'success', false);
                        } else {
                            if (action == 'next') {
                                self.$router.push({ name: 'Conquest', query: { id: self.$route.query.id }})
                            } else if (action == 'back') {
                                self.$router.push("/quotation/?id=" + 0 + "&deal_id=" + self.$route.query.id);
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            back () {
                // self.$emit('clickOnBack', 'quotation', self.buildUpData)
                self.updateDeal(false, 'back')
            },
            getDealData () {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: self.$route.query.id
                    }
                };
                HTTP.get("/AU/deal/getbyid", config)
                    .then(function (response) {
                        self.hideLoader();
                        if (response.status == 200) {
                            // console.log('response in buildup ', response)
                            self.buildUpData = {
                                'sP': self.buildUpData.sP,
                                'stock': response.data.content.stock ? response.data.content.stock : null,
                                'customerCompany': response.data.content.customer_company_name,
                                'buyer': response.data.content.business_name,
                                'cust': response.data.content.customer_name,
                                'dealNo': response.data.content.deal_no, // for reference
                                'model': response.data.content.deal_inventory_data[0].model_display,
                                'deliveryDate': response.data.content.delivery_date ? response.data.content.delivery_date : response.data.content.contract_date,
                                'factoryItemsArr': response.data.content.factory_items,
                                'lineItemsArr': response.data.content.line_items
                            }
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    });
            },
        }
    }
</script>