<template>
  <div>
    <div class="modal" id="vehicleDetailModal" tabindex="-1" role="dialog">
      <div
        v-if="isClickDetail"
        class="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="vehiclePurchaseModalLabel">
              Vehicle Detail
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeVehicleDetailModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Admin</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Stock#</td>
                        <td>Customer</td>
                        <td>Acquisition Date</td>
                        <td>Branch Location</td>
                        <td>YMM</td>
                        <td>Type</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Vehicle</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>YMM</td>
                        <td>VIN</td>
                        <td>Series</td>
                        <td>Body Style</td>
                        <td>Color</td>
                        <td>Interior Color</td>
                        <td>Wheelbase</td>
                        <td>Exhaust</td>
                        <td>Odometer</td>
                        <td>PTO</td>
                        <td>Brake System</td>
                        <td>Front Wheels</td>
                        <td>Rear Wheels</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Sleeper</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Sleeper Type</td>
                        <td>Sleeper</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Engine</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Engine</td>
                        <td>Engine Model</td>
                        <td>HP</td>
                        <td>Engine Serial</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Transmission</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Transmission</td>
                        <td>Transmission Model</td>
                        <td>Transmission SN</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Front Axle</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Front Axle Model</td>
                        <td>Front Axle SN</td>
                        <td>Rear Axle Front Model</td>
                        <td>Rear Axle Front SN</td>
                        <td>Rear Axle Rear Model</td>
                        <td>Rear Axle Rear SN</td>
                        <td>Ratio</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Suspension</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Rear Suspension</td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Tank</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td>Fuel Type</td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                </table>
            </fieldset>

            <fieldset class="form-group border p-3">
                <legend class="w-auto px-2">Pictures</legend>
                <table class="table custom-table jsmartable"> 
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                </table>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import EventBus from '../../event-bus';

    export default {
        name: "VehicleDetail",
        props: ["isClickDetail"],
        data() {
            return {

            };
        },
        methods: {
            closeVehicleDetailModal() {
                EventBus.$emit("close-vehicle-detail");
            }
        }
    }
</script>
