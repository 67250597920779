<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <th class="">{{staticInputLabelMessages.warranty_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('warrantyTypeIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.warranty_sevice_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('warrantyServiceDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.warranty_terms_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('warrantyTermsInput')"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Warranty Type-->
                                <td class="">
                                    <v-select style="min-width: 175px;" v-model="warrantyTypeIdInput[asset.asset_id]"
                                              @input="warrantyTypeIdErrorMsg[asset.asset_id] = ''"
                                              name="warrantyTypeIdInput"
                                              id="warrantyTypeIdInput"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="warrantyTypeArrInput"
                                              :placeholder="staticMessages.warranty_place_holder"
                                    >
                                    </v-select>
                                    <span class="help is-danger">{{warrantyTypeIdErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                                <!--Warranty Service Date-->
                                <td class="">
                                    <date-picker style="min-width: 220px;" 
                                            :wrap="true"
                                            v-model="warrantyServiceDateInput[asset.asset_id]"
                                            :config="options"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.warranty_sevice_date_place_holder"
                                            name="activityDateTime"
                                            @input="changeActivityDateTime()"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{warrantyServiceDateErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                                <!--Warranty Terms-->
                                <td class="">
                                    <input style="min-width: 165px;" id="floorPlanInput" @input="warrantyTermsErrorMsg[asset.asset_id] = ''" v-model="warrantyTermsInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{warrantyTermsErrorMsg[asset.asset_id]}}</span>
                                    <!--{{asset.vin_no}}-->
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                warrantyTypeIdInput: [],
                warrantyTypeArrInput: [],
                warrantyTypeIdErrorMsg: [],

                warrantyServiceDateInput : [],
                warrantyServiceDateErrorMsg : [],

                warrantyTermsInput : [],
                warrantyTermsErrorMsg : [],

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_add_inventory_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";

                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.warrantyTermsInput[self.asset.content[i].asset_id] = self.asset.content[i].warranty_terms;
                        if (self.asset.content[i].warranty_id != null && typeof self.asset.content[i].warranty_id != "undefined" && self.asset.content[i].warranty_id != '') {
                            self.warrantyTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].warranty_name), value: self.asset.content[i].warranty_id};
                        }
                        if (self.asset.content[i].warranty_service_date != null && typeof self.asset.content[i].warranty_service_date != "undefined" && self.asset.content[i].warranty_service_date != '') {
                            self.warrantyServiceDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].warranty_service_date);
                        }

                    }
                });
            self.loadAllData(['WarrantyType'], false, self.loadWarrantyTypeCallback, "undefined", '');
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'warrantyTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.warrantyTypeIdInput[self.asset.content[i].asset_id];
                                    self.warrantyTypeIdInput = [];
                                } 
                                self.warrantyTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'warrantyServiceDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.warrantyServiceDateInput[self.asset.content[i].asset_id];
                                    self.warrantyServiceDateInput = [];
                                } 
                                self.warrantyServiceDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'warrantyTermsInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.warrantyTermsInput[self.asset.content[i].asset_id];
                                    self.warrantyTermsInput = [];
                                } 
                                self.warrantyTermsInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            changeActivityDateTime: function () {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.warrantyServiceDateErrorMsg, i, "");
                    }
                });
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArrInput = callbackData.WarrantyType;
            },
            returnData: function () {
                var data = [];
                var warrantyId = "";
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.warrantyTypeIdInput[self.asset.content[i].asset_id] == null || !self.warrantyTypeIdInput[self.asset.content[i].asset_id].value) {
                            warrantyId = "";
                        } else {
                            warrantyId = self.warrantyTypeIdInput[self.asset.content[i].asset_id].value;
                        }
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'warranty_id' : warrantyId,
                            'warranty_date' : self.warrantyServiceDateInput[self.asset.content[i].asset_id],
                            'warranty_terms' : self.warrantyTermsInput[self.asset.content[i].asset_id],
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null){
                            /*if (typeof(self.warrantyTypeIdInput[i])  == 'undefined' || self.warrantyTypeIdInput[i] == null || self.warrantyTypeIdInput[i].value == '') {
                                Vue.set(self.warrantyTypeIdErrorMsg, i, "Please select warranty");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*if (!self.warrantyServiceDateInput[i]) {
                                Vue.set(self.warrantyServiceDateErrorMsg, i, "Please enter warranty service date");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (!self.warrantyTermsInput[i]) {
                                Vue.set(self.warrantyTermsErrorMsg  , i, "Please enter warranty terms");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                        }
                    }
                });
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/warranty", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 5);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;

                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>