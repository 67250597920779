<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_cso_request'))">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <div class="admin-page-content">
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Sales Person -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="salesperson_id"><b>{{staticInputLabelMessages.salesperson_id}}</b><span class="text-danger">*</span></label>
                                    <v-select
                                        v-model="salespersonId"
                                        name="salespersonId"
                                        @input="salespersonIdError = ''"
                                        label="text"
                                        :filterable="false"
                                        :options="salespersonArr"
                                        @search="onSearchUser"
                                        :clearable="false"
                                        :placeholder="staticMessages.sales_person_place_holder"
                                        :disabled="true"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <span class="is-danger text-danger">{{salespersonIdError}}</span>
                            </div>

                            <!-- Receiving Dealership -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="receiving_dealership"><b>{{staticInputLabelMessages.receiving_dealership}}</b><span class="text-danger">*</span></label>
                                    <v-select v-model="locationId" name="locationId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="locationArr"
                                        @search="onSearchLocation" 
                                        :clearable="true" 
                                        @input="locationError = ''"
                                        :placeholder="allStaticMessages.location_custom_place_holder" 
                                        class="custom-ci-auto-search"
                                        :disabled="isDisabled"
                                        @change="updateLocation"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isLocationRecordNotFound">
                                                {{allStaticMessages.location_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <span class="is-danger text-danger">{{locationError}}</span>
                            </div>

                            <!-- Sales Admin Email -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="sales_manager_email"><b>{{staticInputLabelMessages.sales_manager_email}}</b><span class="text-danger">*</span></label>
                                    <v-select v-model="salesManagerEmail" name="salesManagerEmail"
                                        label="text" 
                                        :filterable="false"
                                        :clearable="true" 
                                        @input="salesManagerEmailError = ''"
                                        :placeholder="allStaticMessages.sm_email_place_holder" 
                                        class="custom-ci-auto-search"
                                        :disabled="isDisabled"
                                        :options="salesManagerEmailArr"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSMEmailRecordNotFound">
                                                {{allStaticMessages.sm_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <span class="is-danger text-danger">{{salesManagerEmailError}}</span>
                                </div>
                            </div>

                            <!-- Customer Id -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="customer_id"><b>{{staticInputLabelMessages.customer_id}}</b>{{staticInputLabelMessages.excede_cusid}}<span class="text-danger">*</span></label>
                                    <input id="customerId" v-model="customerId" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer" @input="customerIdError = null" :disabled="isDisabled"/>
                                </div>
                                <span class="is-danger text-danger">{{customerIdError}}</span>
                            </div>

                            <!-- I confirm this has Sales Admin approval prior to submission -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="confirmSmApprovalSubmission"
                                        v-model="confirmSmApprovalSubmission"
                                        name="confirmSmApprovalSubmission"
                                        :disabled="isDisabled"
                                        @change="updatecheckbox"
                                    >
                                        <b>{{staticInputLabelMessages.i_confirm_this_has_sales_manager_approval_prior_to_submission}}</b>
                                        <span class="text-danger">*</span>
                                    </b-form-checkbox>
                                </div>
                                <span class="is-danger text-danger">{{confirmSmApprovalSubmissionError}}</span>
                            </div>

                            <!-- Confirming that Worksheet has acceptable GP -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="isWorksheetHasAcceptableGp"
                                        v-model="isWorksheetHasAcceptableGp"
                                        name="isWorksheetHasAcceptableGp"
                                        :disabled="isDisabled"
                                    >
                                    <b>{{staticInputLabelMessages.confirming_the_worksheet_has_acceptable_gp}}</b>
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <!-- Worksheet with acceptable -->
                            <div class="col-12 forms-block" v-show="worksheetAttachment.length > 0">
                                <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="">
                                            <b>{{staticInputLabelMessages.worksheet_with_acceptable}}</b>{{staticInputLabelMessages.non_anonymous_question}}
                                            <i class="fa fa-question-circle" id="worksheet_with_acceptable"></i><span class="text-danger">*</span>
                                            <b-tooltip target="worksheet_with_acceptable" triggers="hover">
                                                {{staticInputLabelMessages.worksheet_tooltip}}
                                            </b-tooltip>
                                        </div>
                                    </div>
                                    <div class="col-12 paddingleft0" v-if="isDisabled">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in worksheetAttachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isDisabled">
                                <div class="form-group">
                                    <vue-dropzone
                                        ref="worksheet_dropzone"
                                        id="worksheet_dropzone"
                                        name="worksheet_dropzone"
                                        :options="worksheetDropzoneOptions"
                                        :destroyDropzone="false"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{allLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{allLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{worksheetFileUploadError}}</span>
                            </div>

                            <!-- Signed contract of sale -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="signedContractOfSale"
                                        v-model="signedContractOfSale"
                                        name="signedContractOfSale"
                                        :disabled="isDisabled"
                                    >
                                    <b>{{staticInputLabelMessages.signed_contract_of_sale_customer_po}}</b>
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <!-- Customer PO -->
                            <div class="col-12 forms-block" v-show="signedContractAttachment.length > 0">
                                <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                                    <div class="">
                                        <b>{{staticInputLabelMessages.signed_contract_of_sale_customer_po}}</b>{{staticInputLabelMessages.with_subject_to_availability_clause}} {{staticInputLabelMessages.non_anonymous_question}}
                                        <i class="fa fa-question-circle" id="signed_contract"></i><span class="text-danger">*</span>
                                        <b-tooltip target="signed_contract" triggers="hover">
                                            {{staticInputLabelMessages.worksheet_tooltip}}
                                        </b-tooltip>
                                    </div>
                                    <div class="col-12 paddingleft0" v-if="isDisabled">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in signedContractAttachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isDisabled">
                                <div class="form-group">
                                    <vue-dropzone
                                        ref="signed_contract_dropzone"
                                        id="signed_contract_dropzone"
                                        :options="signedContractDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{signedContractFileUploadError}}</span>
                            </div>

                            <!-- Proof of deposit paid -->
                            <div class="col-12 forms-block" v-show="proofAttachment.length > 0">
                                <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="">
                                            <b>{{staticInputLabelMessages.proof_of_deposit_paid}}</b>{{staticInputLabelMessages.refer_to_inventory_policy}} {{staticInputLabelMessages.non_anonymous_question}}
                                            <i class="fa fa-question-circle" id="proof"></i><span class="text-danger">*</span>
                                            <b-tooltip target="proof" triggers="hover">
                                                {{staticInputLabelMessages.proof_tooltip}}
                                            </b-tooltip>
                                        </div>
                                    </div>
                                    <div class="col-12 paddingleft0" v-if="isDisabled">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in proofAttachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isDisabled">
                                <div class="form-group">
                                    <vue-dropzone
                                        ref="proof_dropzone"
                                        id="proof_dropzone"
                                        :options="proofDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{proofFileUploadError}}</span>
                            </div>

                            <!-- Estimated date of delivery -->
                            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="estimated_date_of_delivery"><b>{{staticInputLabelMessages.estimated_date_of_delivery}}</b><span class="text-danger">*</span></label>
                                    <date-picker v-model="estimatedDateOfDelivery" :config="options" class="form-control" autocomplete="off" :placeholder="staticInputLabelMessages.estimated_date_of_delivery_place_holder" name="estimatedDateOfDelivery" @input="estimatedDateOfDeliveryError = null" :disabled="isDisabled">
                                    </date-picker>
                                </div>
                                <span class="is-danger text-danger">{{estimatedDateOfDeliveryError}}</span>
                            </div> -->

                            <!-- Make and model -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="make_and_model"><b>{{staticInputLabelMessages.make_and_model}}</b>{{staticInputLabelMessages.make_and_model_example}}<span class="text-danger">*</span></label>
                                    <input id="makeAndModel" v-model="makeAndModel" :disabled="isDisabled" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer" @input="makeAndModelError = null"/>
                                </div>
                                <span class="is-danger text-danger">{{makeAndModelError}}</span>
                            </div>

                            <!-- Units volume -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="units_volume"><b>{{staticInputLabelMessages.units_volume}}</b><span class="text-danger">*</span></label>
                                    <input id="unitsVolume" v-model="unitsVolume" :disabled="isDisabled" class="form-control" type="number" :placeholder="staticInputLabelMessages.units_volume_place_holder" @input="inputUnitsVolume" min="0" @keydown="preventNonNumeric($event)"/>
                                </div>
                                <span class="is-danger text-danger">{{unitsVolumeError}}</span>
                            </div>
                            
                            <!-- Request type -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="request_type"><b>{{staticInputLabelMessages.request_type}}</b><span class="text-danger">*</span></label>
                                    <b-form-radio-group v-model="requestType" :disabled="isDisabled" :options="requestTypeOptions" name="requestType" stacked @change="inputUnitsVolume"></b-form-radio-group>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-3 col-4" v-if="requestType == 'both' || requestType == 'vehicle_allocation'">
                                <div class="form-group">
                                    <label for="unit_id"><b>{{staticInputLabelMessages.unit_id}}</b><span class="text-danger">*</span></label>
                                </div>
                                <template v-for="(unitId, index) in unitIdsArr">
                                    <b-input id="unitId" class="mr-1 mb-1" type="text" v-model="unitIdsArr[index].id" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer" @input="unitIdError = null" :disabled="isDisabled"
                                        :class="{ 'border border-warning': dummyUnitIds.find(e => e == unitIdsArr[index].id), 'border border-danger': unitIdsArr[index].status == 'error' }"
                                    />
                                </template><br/>
                                <span class="text-danger">{{unitIdError}}</span><br/>
                                <span class="text-warning">{{ dummyUnitIdError }}</span>
                            </div>

                            <div class="col-lg-7 col-md-7 col-sm-7 col-7" v-if="requestType == 'both' || requestType == 'vehicle_allocation'">
                                <div class="form-group">
                                    <label for="estimated_date_of_delivery"><b>{{staticInputLabelMessages.estimated_date_of_delivery}}</b><span class="text-danger">*</span></label>
                                </div>
                                <template v-for="(unitId, index) in unitIdsArr">
                                    <date-picker v-model="unitIdsArr[index].estimatedDateOfDelivery" class="mb-1 w-50" :config="options" autocomplete="off" :placeholder="staticInputLabelMessages.estimated_date_of_delivery_place_holder" name="estimatedDateOfDelivery" @input="estimatedDateOfDeliveryError = null" :disabled="isDisabled">
                                    </date-picker>
                                </template><br/>
                                <span class="text-danger">{{estimatedDateOfDeliveryError}}</span>
                            </div>

                            <div class="col-12 forms-block" v-show="orderFormAttachment.length > 0" v-if="requestType == 'both' || requestType == 'customer_order'">
                                <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="">
                                            <b>{{staticInputLabelMessages.truck_order_form}}</b>{{staticInputLabelMessages.truck_order_form_example}}
                                            <i class="fa fa-question-circle" id="truck_order_form"></i><span class="text-danger">*</span>
                                            <b-tooltip target="truck_order_form" triggers="hover">
                                                {{staticInputLabelMessages.order_form_tooltip}}
                                            </b-tooltip>
                                        </div>
                                    </div>
                                    <div class="col-12 paddingleft0" v-if="isDisabled">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in orderFormAttachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="!isDisabled" v-show="requestType == 'both' || requestType == 'customer_order'">
                                <div class="form-group">
                                    <vue-dropzone
                                        ref="order_form_dropzone"
                                        id="order_form_dropzone"
                                        :options="orderFormDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{orderFormFileUploadError}}</span>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="notes"><b>{{staticInputLabelMessages.notes}}</b></label>
                                    <textarea id="notes" :disabled="isDisabled" v-model="notes" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-show="statusLogs.length > 0">
                                <div class="form-group">
                                    <label for="status_logs"><b>{{staticInputLabelMessages.status_logs}}</b></label>
                                    <div v-for="log in statusLogs">
                                        <b-badge v-if="log.status" :variant="statusLogVariant(log.status)">{{log.status|capitalize}}:</b-badge>  {{log.action_note}} by {{log.approval_level}} at {{log.created_at|formatDate}}
                                    </div>
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="$route.params.level == 2">
                                <div class="form-group">
                                    <label for="status"><b>{{staticInputLabelMessages.approve_status}}</b></label>
                                    <b-form-radio-group v-model="level2Status"
                                        :options="secondApprovalStatusArr"
                                        name="status"
                                        :disabled="disabledLevel2Status">
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="$route.params.level == 1">
                                <div class="form-group">
                                    <label for="status"><b>{{staticInputLabelMessages.approve_status}}</b></label>
                                    <b-form-radio-group v-model="level1Status"
                                        :options="firstApprovalStatusArr"
                                        name="status"
                                        :disabled="disabledLevel1Status">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Reason -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="$route.params.level == 2 && level2Status == 'rejected'">
                                <div class="form-group">
                                    <label for="reason"><b>{{staticInputLabelMessages.reject_reason}}</b></label>
                                    <b-form-radio-group v-model="rejectReason"
                                        :options="rejectReasonArr"
                                        :disabled="disabledLevel2Status"
                                        name="rejectReason" stacked
                                        @input="reasonError = null">
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="$route.params.level == 1 && level1Status == 'rejected'">
                                <div class="form-group">
                                    <label for="reason"><b>{{staticInputLabelMessages.reject_reason}}</b></label>
                                    <b-form-radio-group v-model="rejectReason"
                                        :options="rejectReasonArr"
                                        :disabled="disabledLevel1Status"
                                        name="rejectReason" stacked
                                        @input="reasonError = null">
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <span class="is-danger text-danger ml-3">{{reasonError}}</span>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo ml-3">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="checkRequest()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <b-modal ref="actionReason" title="Reason for action" hide-footer>
                    <div>
                        <b-form-textarea v-model="actionReason" placeholder="Enter reason for action..." rows="2"></b-form-textarea>
                        <b-button variant="primary" @click="updateCSORequest" class="mt-1 float-right">Save</b-button>
                    </div>
                </b-modal>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';

    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */

    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUdpateCSORequest',
        components: {
            swiper,
            swiperSlide,
            datePicker,
            'vueDropzone': vue2Dropzone
        },
        mixins: [commonMixins],
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                isAbove500: "",
                salesManager: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                vin: "",
                vendor: "",
                description: "",
                requestAmount: "",
                attachment: "",
                workCompleteStatus:"no",
                workCompleteStatusArr: [
                    {
                        text: "No",
                        value: "no"
                    },
                    {
                        text: "Yes",
                        value: "yes"
                    }
                ],     
                level1Status:"pending",
                level2Status:"pending",
                firstApprovalStatusArr: [
                    {
                        text: "Accepted",
                        value: "accepted"
                    },
                    {
                        text: "Pending",
                        value: "pending"
                    },
                    {
                        text: "Rejected",
                        value: "rejected"
                    },
                    {
                        text: "Further information required",
                        value: "further_information_required"
                    }
                ],
                secondApprovalStatusArr: [
                    {
                        text: "Allocated",
                        value: "allocated"
                    },
                    {
                        text: "Pending",
                        value: "pending"
                    },
                    {
                        text: "Rejected",
                        value: "rejected"
                    },
                    {
                        text: "Further information required",
                        value: "further_information_required"
                    }
                ],
                reason: "",
                // Error
                salesmanIdError: "",
                vinError: "",
                vendorError: "",
                descriptionError: "",
                requestAmountError: "",
                ApprovStatusError: "",
                workCompleteStatusError: "",
                // 
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add CSO Request',
                permissionNameArray: ['can_add_cso_request'],
                salespersonId: null,
                salespersonArr: [],
                salespersonIdError: null,
                isUserRecordNotFound : false,
                locationId: "",
                locationError: "",
                customerId: null,
                customerIdError: null,
                worksheetAttachment: "",
                signedContractAttachment: "",
                proofAttachment: "",
                options: {
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    }
                },
                estimatedDateOfDelivery: null,
                estimatedDateOfDeliveryError: null,
                unitsVolume: null,
                unitsVolumeError: null,
                makeAndModel: null,
                makeAndModelError: null,
                requestType: "vehicle_allocation",
                requestTypeOptions: [
                    {
                        text: 'Vehicle Allocation (all required units available within VTC inventory)', 
                        value: 'vehicle_allocation'
                    },
                    {
                        text: 'Customer Order (no units available within VTC inventory, units require new vehicle order)', 
                        value: 'customer_order'
                    },
                    {
                        text: 'Both (some units available within VTC inventory, additional units require new vehicle order)', 
                        value: 'both'
                    }
                ],
                unitId: "",
                unitIdError: null,
                notes: null,
                orderFormAttachment: "",
                locationArr: [],
                isLocationRecordNotFound: false,
                approvalData: null,
                rejectReasonArr: [
                    {
                        text: "Incomplete or insufficient paperwork",
                        value: "incomplete_paperwork"
                    },
                    {
                        text: "Does not meet minimum requirements as per Inventory Policy",
                        value: "does_not_meet_minimum_requirements"
                    },
                    {
                        text: "Internal allocation could not be confirmed at this time, a stock order may be required, and your request is pending",
                        value: "internal_allocation_could_not_be_confirmed"
                    }
                ],
                rejectReason: null,
                disabledLevel1Status: false,
                disabledLevel2Status: false,
                reasonError: null,
                isWorksheetHasAcceptableGp: false,
                signedContractOfSale: false,
                unitIdsArr: [],
                salesManagerEmail: null,
                salesManagerEmailError: null,
                isSMEmailRecordNotFound: false,
                dummyUnitIds: [],
                confirmSmApprovalSubmission: false,
                confirmSmApprovalSubmissionError: "",
                estimatedDateOfDeliveryArr: [],
                actionReason: null,
                isDisabled: true,
                salesManagerEmailArr: [],
                unitIds: {
                    id: 0,
                    status: null,
                    estimatedDateOfDelivery: null
                },
                ccEmailArr: [],
                worksheetDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'worksheet'}
                },
                proofFileUploadError : "",
                proofImagesPaths: [],
                proofImagesType: [],
                proofImagesSize: [],
                filesType: [],
                worksheetFileUploadError : "",
                signedContractDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'signed_contract'}
                },
                proofDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'proof'}
                },
                orderFormDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".pdf,.xls,.xlsx",
                    params: {'type': 'order_form'}
                },
                signedContractFileUploadError: "",
                orderFormFileUploadError: "",
                isDropZoneDisable: false,
                ticketNumber: null,
                parentId: null,
                dummyUnitIdError: null,
                statusLogs: []
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                window.localStorage.setItem("api_token", self.$route.query.token);
                window.localStorage.setItem("username", self.$route.query.username);
                self.autoLoginUser(self.$route.query.username);
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('cso-request');
            self.getLoginIdPermissions();
            EventBus.$emit("menu-selection-changed", "cso-request");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getCSORequestDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit CSO Request";
            }
            self.loadAllData(['Location', 'User'], false, self.loadMasterDataCallback, "undefined", '');
            if (!self.$route.params.level && Object.keys(self.$route.query).length > 0) {
                self.isDisabled = false
                self.options = {
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    minDate: new Date()
                }
            }
            if (!self.$route.params.level && Object.keys(self.$route.query).length == 0) {
                self.disableButtons.save = true
                self.disableButtons.cancel = true
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListCSORequest'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function(callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            getCSORequestDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/csorequest/get', config)
                        .then(response => {
                            if (response.data.content.salesperson_id != null) {
                                self.salespersonId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesperson_id};
                            }
                            self.locationId = {text: response.data.content.receiving_dealership, value: response.data.content.location_id};
                            self.customerId = response.data.content.customer_id;
                            self.worksheetAttachment = response.data.content.worksheet_attachment_path;
                            self.signedContractAttachment = response.data.content.signed_contract_attachment_path;
                            self.proofAttachment = response.data.content.proof_attachment_path;
                            self.estimatedDateOfDeliveryArr = response.data.content.estimated_date_of_delivery ? response.data.content.estimated_date_of_delivery.split(',') : [];
                            self.unitsVolume = response.data.content.units_volume;
                            self.makeAndModel = response.data.content.make_and_model;
                            self.requestType = response.data.content.request_type;
                            self.unitIdsArr = response.data.content.unit_ids_arr;
                            self.notes = response.data.content.notes;
                            self.orderFormAttachment = response.data.content.order_form_attachment_path;
                            self.level1Status = response.data.content.sales_admin;
                            self.level2Status = response.data.content.final_status;
                            self.approvalData = response.data.content.approval_data;
                            self.rejectReason = response.data.content.reason;
                            self.isWorksheetHasAcceptableGp = response.data.content.worksheet_has_acceptable_gp ? true : false;
                            self.signedContractOfSale = response.data.content.signed_contract_of_sale ? true : false;
                            self.salesManagerEmail = response.data.content.sales_manager_email;
                            self.salesManagerEmailArr = response.data.content.sales_manager_email_arr.original.data.toEmails;
                            self.ccEmailArr = response.data.content.sales_manager_email_arr.original.data.ccEmails;
                            self.confirmSmApprovalSubmission = response.data.content.confirm_sm_approval_submission ? true : false;
                            self.parentId = response.data.content.parent_id
                            self.statusLogs = response.data.content.status_logs
                            if (response.data.content.dummy_unit_ids.length > 0) {
                               self.dummyUnitIdError = 'Above unit ids are dummy';
                               self.dummyUnitIds = response.data.content.dummy_unit_ids;
                            }
                            if (self.level1Status != 'pending' || !self.$route.params.level) {
                                self.disabledLevel1Status = true
                            }
                            if (self.level2Status != 'pending' || !self.$route.params.level) {
                                self.disabledLevel2Status = true
                            }
                            if (self.$route.params.level == 1 && self.level1Status != 'pending') {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                            }
                            if (self.$route.params.level == 2 && self.level2Status != 'pending') {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                            }
                            if (response.data.content.worksheet_attachment_path.length > 0 && self.$refs.worksheet_dropzone) {
                                for (let i in response.data.content.worksheet_attachment_path) {
                                    if (response.data.content.worksheet_attachment_path[i].file_type) {
                                        let file_name = self.getFileName(response.data.content.worksheet_attachment_path[i].file_name);
                                        let file_type = response.data.content.worksheet_attachment_path[i].file_type;
                                        let url = response.data.content.worksheet_attachment_path[i].file;
                                        let file_size = response.data.content.worksheet_attachment_path[i].size;
                                        let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.worksheet_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push(response.data.content.worksheet_attachment_path[i].file_name);
                                        self.proofImagesType.push(file_type);
                                        self.proofImagesSize.push(file_size);
                                        self.filesType.push('worksheet');
                                    } else {
                                        let file_name = self.getFileName(response.data.content.worksheet_attachment_path[i].file);
                                        let file_type = self.getFileType(response.data.content.worksheet_attachment_path[i].ext);
                                        let url = response.data.content.worksheet_attachment_path[i].file;
                                        let file_data = {size: 0, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.worksheet_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push('temp_media/'+file_name);
                                        self.filesType.push('worksheet');
                                    }
                                }
                            }
                            if (response.data.content.signed_contract_attachment_path.length > 0 && self.$refs.signed_contract_dropzone) {
                                for (let i in response.data.content.signed_contract_attachment_path) {
                                    if (response.data.content.signed_contract_attachment_path[i].file_type) {
                                        let file_name = self.getFileName(response.data.content.signed_contract_attachment_path[i].file_name);
                                        let file_type = response.data.content.signed_contract_attachment_path[i].file_type;
                                        let url = response.data.content.signed_contract_attachment_path[i].file;
                                        let file_size = response.data.content.signed_contract_attachment_path[i].size;
                                        let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.signed_contract_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push(response.data.content.signed_contract_attachment_path[i].file_name);
                                        self.proofImagesType.push(file_type);
                                        self.proofImagesSize.push(file_size);
                                        self.filesType.push('signed_contract');
                                    } else {
                                        let file_name = self.getFileName(response.data.content.signed_contract_attachment_path[i].file);
                                        let file_type = self.getFileType(response.data.content.signed_contract_attachment_path[i].ext);
                                        let url = response.data.content.signed_contract_attachment_path[i].file;
                                        let file_data = {size: 0, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.signed_contract_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push('temp_media/'+file_name);
                                        self.filesType.push('signed_contract');
                                    }
                                }
                            }
                            if (response.data.content.proof_attachment_path.length > 0 && self.$refs.proof_dropzone) {
                                for (let i in response.data.content.proof_attachment_path) {
                                    if (response.data.content.proof_attachment_path[i].file_type) {
                                        let file_name = self.getFileName(response.data.content.proof_attachment_path[i].file_name);
                                        let file_type = response.data.content.proof_attachment_path[i].file_type;
                                        let url = response.data.content.proof_attachment_path[i].file;
                                        let file_size = response.data.content.proof_attachment_path[i].size;
                                        let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.proof_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push(response.data.content.proof_attachment_path[i].file_name);
                                        self.proofImagesType.push(file_type);
                                        self.proofImagesSize.push(file_size);
                                        self.filesType.push('proof');
                                    } else {
                                        let file_name = self.getFileName(response.data.content.proof_attachment_path[i].file);
                                        let file_type = self.getFileType(response.data.content.proof_attachment_path[i].ext);
                                        let url = response.data.content.proof_attachment_path[i].file;
                                        let file_data = {size: 0, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.proof_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push('temp_media/'+file_name);
                                        self.filesType.push('proof');
                                    }
                                }
                            }
                            if (response.data.content.order_form_attachment_path.length > 0 && self.$refs.order_form_dropzone) {
                                for (let i in response.data.content.order_form_attachment_path) {
                                    if (response.data.content.order_form_attachment_path[i].file_type) {
                                        let file_name = self.getFileName(response.data.content.order_form_attachment_path[i].file_name);
                                        let file_type = response.data.content.order_form_attachment_path[i].file_type;
                                        let url = response.data.content.order_form_attachment_path[i].file;
                                        let file_size = response.data.content.order_form_attachment_path[i].size;
                                        let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.order_form_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push(response.data.content.order_form_attachment_path[i].file_name);
                                        self.proofImagesType.push(file_type);
                                        self.proofImagesSize.push(file_size);
                                        self.filesType.push('order_form');
                                    } else {
                                        let file_name = self.getFileName(response.data.content.order_form_attachment_path[i].file);
                                        let file_type = self.getFileType(response.data.content.order_form_attachment_path[i].ext);
                                        let url = response.data.content.order_form_attachment_path[i].file;
                                        let file_data = {size: 0, fileid: file_name, name: file_name, type: file_type};
                                        self.$refs.order_form_dropzone.manuallyAddFile(file_data, url);
                                        self.proofImagesPaths.push('temp_media/'+file_name);
                                        self.filesType.push('order_form');
                                    }
                                }
                            }
                            self.ticketNumber = response.data.content.ticket_number
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            checkRequest: function () {
                let status
                if (self.$route.params.level == 1) {
                    status = self.level1Status
                } else if (self.$route.params.level == 2) {
                    status = self.level2Status
                }
                if (status == 'pending') {
                    self.$swal.fire(
                        'Wrong status!',
                        'Kindly choose the appropriate status other than Pending.',
                        'warning'
                    );
                } else if (status == 'rejected' && !self.rejectReason) {
                    self.reasonError = 'Kindly choose reject reason.'
                } else {
                    if (self.isDisabled) {
                        self.$refs['actionReason'].show()
                    } else {
                        self.setReasonForAction()
                    }
                }
            },
            setReasonForAction () {
                if (self.requestType == 'vehicle_allocation' || self.requestType == 'both') {
                    var checkvalidation = false
                    setTimeout(() => {
                        if (self.checkForm()) {
                            var config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("api_token")
                                }
                            };
                            var data = {
                                'units_volume': self.unitsVolume,
                                'unit_id': self.unitIdsArr,
                                'id': self.$route.params.id
                            };
                            HTTP.post("cso-request/check-unit-ids", data, config)
                                .then(function (response) {
                                    // console.log('then ', response.data.content)
                                    for (let i = 0; i < self.unitIdsArr.length; i++) {
                                        for (let j = 0; j < response.data.content.all.length; j++) {
                                            if (self.unitIdsArr[i].id == response.data.content.all[j].id) {
                                                self.unitIdsArr[i].status = response.data.content.all[j].status
                                            }
                                        }
                                    }
                                    if (response.data.content.duplicate.length > 0) {
                                        self.unitIdError = "Above unit is already been requested, please use a different id"
                                    } else {
                                        self.unitIdError = "";
                                        self.estimatedDateOfDeliveryError = "";
                                        Object.keys(self.unitIdsArr).forEach(key => {
                                            self.unitIdsArr[key].status = null;
                                        });
                                        checkvalidation = true;
                                    }
                                    if (checkvalidation) {
                                        if (self.$route.params.level) {
                                            self.$refs['actionReason'].show()
                                        } else {
                                            self.addCSORequest()
                                        }
                                    }
                                })
                                .catch(function (err) {
                                });
                        }
                    }, 200);
                } else {
                    setTimeout(() => {
                        if (self.checkForm()) {
                            if (self.$route.params.level) {
                                self.$refs['actionReason'].show()
                            } else {
                                self.addCSORequest()
                            }
                        }
                    }, 200);
                }  
            },
            updateCSORequest () {
                let unitId = self.unitIdsArr.map(function(obj) {
                    return obj.id;
                });
                if (self.$route.params.level) {
                    self.$refs['actionReason'].hide()
                }
                setTimeout(() => {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    var data = new FormData();
                    data.append('id', self.$route.params.id);
                    data.append('salesperson_id', self.salespersonId.value);
                    data.append('location_id', self.locationId.value);
                    data.append('customer_id', self.customerId);
                    data.append('estimated_date_of_delivery', self.estimatedDateOfDeliveryArr);
                    data.append('units_volume', self.unitsVolume);
                    data.append('make_and_model', self.makeAndModel);
                    data.append('request_type', self.requestType);
                    data.append('unit_id', unitId);
                    data.append('notes', self.notes);
                    data.append('sales_manager_email', self.salesManagerEmail);
                    if (self.$route.params.level == 1) {
                        data.append('level1Status', self.level1Status);
                    } else if (self.$route.params.level == 2) {
                        data.append('level2Status', self.level2Status);
                    }
                    data.append('reason', self.rejectReason);
                    data.append('action_note', self.actionReason);
                    self.buttonNames.save = "Updating ...";
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    HTTP.post("/csorequest/add/update", data, config)
                        .then(function (response) {
                            self.buttonNames.save = "Update";
                            if (response.data.status == "success") {
                                self.showSAlert(response.data.message, 'success', false, 3);
                                setTimeout(function () {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (!self.$route.params.status) {
                                        self.buttonBack();
                                    }
                                }, 3000);
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            self.buttonNames.save = "Update";
                            self.disableButtons.save = false;
                            self.disableButtons.cancel = false;
                            /* console.log(err); */
                            self.catchHandler(err, function () {});
                        });
                }, 200);
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadMasterDataCallback, loading, search);
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.salespersonArr = callbackData.User;
                if (self.salespersonArr.lenght == 0) {
                    self.isUserRecordNotFound = true;
                }
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            autoLoginUser: function (username) {
                var self = this;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    'username': username,
                    'access_token': window.localStorage.getItem('api_token')
                };
                HTTP.post("autologinuser", fields)
                    .then(function (data) {
                        if (data.status == 200) {
                            // localStorage.setItem("api_token", data.data.content.result.api_token);
                            // localStorage.setItem("build_version", data.data.content.build);
                            /* Build version checking start*/
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build = data.data.content.build;
                            if (current_build != undefined && current_build == '') {
                                localStorage.setItem("build_version", data.data.content.build);
                            } else if(current_build != latest_build){
                                localStorage.setItem("build_version", data.data.content.build);
                                location.reload(true);
                            }
                            /* Build version checking end*/
                            localStorage.setItem("user_obj", JSON.stringify(data.data.content.user_obj));
                            localStorage.setItem("roleId", JSON.stringify(data.data.content.roles[0].id));
                            self.$router.push({name: "UpdateCSORequest", params: {id: self.$route.params.id, level: self.$route.params.level}});
                            self.getLoginIdPermissions();
                            location.reload();
                        }
                    })
                    .catch(function (error) {
                        self.login_err_msg = error.response.data.message;
                    })
            },
            updateLocation (value) {
                if (Object.keys(value).length > 2) {
                    self.salesManagerEmail = null
                    self.salesManagerEmailArr = []
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                        params: {
                            location_id: self.locationId.value
                        }
                    };
                    HTTP.get('/get-sm-emails', config)
                        .then(response => {
                            self.isSMEmailRecordNotFound = false;
                            self.salesManagerEmailArr = response.data.data.toEmails
                            self.ccEmailArr = response.data.data.ccEmails
                            if(self.salesManagerEmailArr.length == 0) {
                                self.isSMEmailRecordNotFound = true;
                                self.locationError = "There is no available sales admin email. Please select another receiving dealership";
                            }
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
                }
            },
            checkForm: function () {
                var checked = true;
                if (!self.salespersonId) {
                    self.salespersonIdError = "Please enter salesperson id";
                    if (checked) {
                        checked = false;
                    }
                }
                self.locationError = "";
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select receiving dealership";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.customerId) {
                    self.customerIdError = "Please enter customer id";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.customerId.length > 20) {
                    self.customerIdError = "Please enter customer id less than 20.";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.salesManagerEmail) {
                    self.salesManagerEmailError = "Please select CSO sales administration email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('worksheet'))  {
                    self.worksheetFileUploadError = "Please upload file for worksheet with acceptable";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('signed_contract'))  {
                    self.signedContractFileUploadError = "Please upload file for signed contract of sale or customer po";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('proof'))  {
                    self.proofFileUploadError = "Please upload file for proof of deposit paid";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.unitsVolume) {
                    self.unitsVolumeError = "Please enter units volume";
                    if (checked) {
                        checked = false;
                    }
                } else {
                    if (self.unitsVolume < 1 || self.unitsVolume > 100) {
                        self.unitsVolumeError = "Please enter units volume greater than 1 and less than 100.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.makeAndModel) {
                    self.makeAndModelError = "Please enter make and model";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.makeAndModel.length > 50) {
                    self.makeAndModelError = "Please enter make and model less than 50.";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.requestType == 'vehicle_allocation') {
                    if (self.unitIdsArr.length > 0) {
                        for (let i = 0; i < self.unitIdsArr.length; i++) {
                            if (self.unitIdsArr[i].id == '0' || !self.unitIdsArr[i].id) {
                                self.unitIdError = "Please enter unit id greater than 0 for all units.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (self.unitIdsArr[i].id.length > 6) {
                                self.unitIdError = "Please enter unit id less than 6 digits for all units.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (!self.unitIdsArr[i].estimatedDateOfDelivery) {
                                self.estimatedDateOfDeliveryError = "Please select estimated date of delivery for all unit ids.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    } else {
                        self.unitIdError = "Please enter unit id for all units.";
                        self.estimatedDateOfDeliveryError = "Please select estimated date of delivery for all unit ids.";
                        if (checked) {
                            checked = false;
                        }
                    }
                } else if (self.requestType == 'both') {
                    let count = self.unitIdsArr.filter(x => x.id == 0).length;
                    let estimatedDateOfDeliveryCount = self.unitIdsArr.filter(x => !x.estimatedDateOfDelivery).length;
                    if (count == self.unitIdsArr.length) {
                        self.unitIdError = "Please enter at least one unit id greater than 0.";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (estimatedDateOfDeliveryCount == self.unitIdsArr.length) {
                        self.estimatedDateOfDeliveryError = "Please select at least one estimated date of delivery.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (self.requestType == 'customer_order' || self.requestType == 'both') {
                    if (!self.filesType.includes('order_form'))  {
                        self.orderFormFileUploadError = "Please upload file for truck order form";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.confirmSmApprovalSubmission) {
                    self.confirmSmApprovalSubmissionError = "Please confirm";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            inputUnitsVolume () {
                if (!self.unitsVolume) {
                    self.unitsVolumeError = "Please enter units volume";
                    self.unitIdsArr.push(Vue.util.extend({}, self.unitIds));
                } else if (self.unitsVolume < 1 || self.unitsVolume > 100) {
                    self.unitsVolumeError = "Please enter units volume greater than 1 and less than 100.";
                } else {
                    self.unitsVolumeError = "";
                    self.unitIdError = "";
                    self.estimatedDateOfDeliveryError = "";
                    self.unitIdsArr = []
                    for (let i = 0; i < self.unitsVolume; i++) {
                        self.unitIdsArr.push(Vue.util.extend({}, self.unitIds));
                    }
                    self.dummyUnitIdError = "";
                }
            },
            updatecheckbox () {
                if (self.confirmSmApprovalSubmission) {
                    self.confirmSmApprovalSubmissionError = null
                } else {
                    self.confirmSmApprovalSubmissionError = 'Please confirm'
                }
            },
            fileError: function (file, xhr, error) {
                self.isDropZoneDisable = true
                if (xhr.code == 400) {
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            uploadDocument (file, response) {
                self.proofImagesPaths.push(response.content.file_path);
                self.proofImagesType.push(file.type);
                self.proofImagesSize.push(file.size);
                self.filesType.push(response.content.type);
                if (self.filesType.includes('worksheet'))  {
                    self.worksheetFileUploadError = "";
                }
                if (self.filesType.includes('signed_contract'))  {
                    self.signedContractFileUploadError = "";
                }
                if (self.filesType.includes('proof'))  {
                    self.proofFileUploadError = "";
                }
                if (self.filesType.includes('order_form'))  {
                    self.orderFormFileUploadError = "";
                }
            },
            removedSelectedFile (file, xhr, error) {
                self.isDropZoneDisable = false
                var removeFile = "";
                if ("manuallyAdded" in file == true) {
                    for (var i = self.proofImagesPaths.length - 1; i >= 0; i--) {
                        if (self.proofImagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.proofImagesPaths[i];
                        }
                    }
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content.file_path;
                    self.removeFileFromServer(removeFile);
                }
                for (var i = self.proofImagesPaths.length - 1; i >= 0; i--) {
                    if (self.proofImagesPaths[i] === removeFile) {
                        self.proofImagesPaths.splice(i, 1);
                        self.proofImagesType.splice(i, 1);
                        self.proofImagesSize.splice(i, 1);
                        self.filesType.splice(i, 1);
                    }
                }
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            if (err.response.status == 401) {
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileAdd (file, xhr, error) {
                if (this.$refs.proof_dropzone.dropzone.options.maxFiles) {
                }
            },
            progressOfUload (totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                } else {
                }
            },
            getFileName: function (file) {
                let src = file.split('/');
                let file_name = src[src.length - 1];
                return file_name;
            },
            getFileType: function (file_type) {
                return 'image/' + file_type;
            },
            addCSORequest () {
                self.buttonNames.save = "Updating ...";
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;
                let unitId = []
                let estimatedDateOfDelivery = []
                if (self.requestType == 'vehicle_allocation' || self.requestType == 'both') {
                    unitId = self.unitIdsArr.map(value => value.id);
                    estimatedDateOfDelivery = self.unitIdsArr.map(value => value.estimatedDateOfDelivery);
                }
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = {
                    'id': self.$route.params.id,
                    'salesperson_id': self.salespersonId.value,
                    'location_id': self.locationId.value,
                    'customer_id': self.customerId,
                    'estimated_date_of_delivery': estimatedDateOfDelivery,
                    'units_volume': self.unitsVolume,
                    'make_and_model': self.makeAndModel,
                    'request_type': self.requestType,
                    'unit_id': unitId,
                    'notes': self.notes,
                    'proof_image_path': self.proofImagesPaths,
                    'proof_image_size': self.proofImagesSize,
                    'proof_image_type': self.proofImagesType,
                    'files_type': self.filesType,
                    'sales_manager_email': self.salesManagerEmail,
                    'worksheet_has_acceptable_gp': self.isWorksheetHasAcceptableGp,
                    'signed_contract_of_sale': self.signedContractOfSale,
                    'cc_email': self.ccEmailArr,
                    'confirm_sm_approval_submission': self.confirmSmApprovalSubmission,
                    'ticket_number': self.ticketNumber,
                    'parent_id': self.parentId
                };
                HTTP.post("/csorequest/add/update/vtc", data, config)
                    .then(function (response) {
                        self.buttonBack();
                        self.buttonNames.save = "Update";
                        self.disableButtons.save = false;
                        self.disableButtons.cancel = false;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            statusLogVariant (status) {
                if (status == 'rejected') {
                    return 'danger'
                } else if (status == 'further_information_required') {
                    return 'info'
                } else {
                    return 'success'
                }
            },
            preventNonNumeric(event) {
                if (
                    event.key.toLowerCase() === 'e' || event.key === 'E' ||
                    event.key === '-' || event.key === '+'
                ) {
                    event.preventDefault();
                }
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
        }
    });
</script>