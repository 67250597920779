<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="admin-page-content">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">
                        <!-- Div Structure Without Print -->
                        <div class="specification-table">
                            <!--header section-->
                            <div class="SpecTable-Header-section">
                                <div class="specification-table-left">
                                    <template v-if="docs && docs.length > 0">
                                        <template v-if="docs[0][0]['city_name'] != ''">
                                            <h4> {{docs[0][0]['company_full_name']}} - {{docs[0][0]['city_name']}} </h4>
                                        </template>
                                        <template v-else>
                                            <h4> {{docs[0][0]['company_full_name']}} </h4>
                                        </template>
                                        <template v-if="docs[0][0]['city_name'] != '' && docs[0][0]['city_name'] != ''">
                                            <p> {{docs[0][0]['city_name']}}, {{docs[0][0]['state_code']}} </p>
                                        </template>
                                        <template v-if="docs[0][0]['name'] != '' || docs[0][0]['contact_no'] != '' || docs[0][0]['email'] != ''">
                                            <h4><br/>Salesman Contact Details : </h4>
                                            <template v-if="docs[0][0]['name'] != ''">
                                                <p class="name-field"> {{docs[0][0]['name']}} </p>
                                            </template>
                                            <template v-if="docs[0][0]['contact_no'] != ''">
                                                <p class="fone-field"> {{docs[0][0]['contact_no']}} </p>
                                            </template>
                                            <template v-if="docs[0][0]['email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+docs[0][0]['email']"> {{docs[0][0]['email']}} </a>
                                            </template>
                                        </template>
                                        <!-- <template v-if="docs[0][0]['company_full_name'] != '' || docs[0][0]['company_phone'] != '' || docs[0][0]['company_email'] != ''">
                                            <h4><br/>Department Contact Details : </h4>
                                            <template v-if="docs[0][0]['company_full_name'] != ''">
                                                <p class="name-field"> {{docs[0][0]['company_full_name']}} </p>
                                            </template>
                                            <template v-if="docs[0][0]['company_phone'] != ''">
                                                <p class="fone-field"> {{docs[0][0]['company_phone']}} </p>
                                            </template>
                                            <template v-if="docs[0][0]['company_email'] != ''">
                                                <a class="email-field"  :href="'mailto:'+docs[0][0]['company_email']"> {{docs[0][0]['company_email']}} </a>
                                            </template>
                                        </template> -->
                                    </template>
                                </div>

                                <div class="specification-table-right">
                                    <template v-if="docs && docs.length > 0">
                                        <template v-if="docs[0][0]['company_logo'] != ''">
                                            <a href="javascript:void(0)" target="_blank"> <img :src="docs[0][0]['company_logo']" :alt="docs[0][0]['company_logo']" style="width:40%"> </a>
                                        </template>
                                        <template v-else>
                                            <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%"> </a>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%"> </a>
                                    </template>
                                </div>
                            </div>
                            <!--header section-->
                            <!--image section-->
                            <template v-if="docs && docs.length > 0">
                                <div class="btm-img-section">
                                    <template v-for="(doc, index) in docs">
                                        <template v-for="(docInner, indexInner) in docs[index]">
                                            <div class="btm-internal-img">
                                                <a href="javascript:void(0)" v-on:click.prevent="clickIndividualAssetPromoSheet(docInner['asset_id'])">
                                                    <img :src="docInner['media_path_frontend']" alt="Velocity Vehicle Group">
                                                </a>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </template>
                            <!--image section-->
                            <footer class="footer">
                                <div class="vvg-logo">
                                    <img src="/static/images/logo.png" alt="Velocity Vehicle Group">
                                    <p class="copyright-text"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{systemYear}} Velocity Vehicle Group </p>
                                </div>
                            </footer>

                        </div>
                        <!-- Div Structure With Print -->
                        <table class="print-table" border="0" cellpadding="0" cellspacing="0" width="100%" style="font-family:Arial, Helvetica, sans-serif; background-color: #ffffff; border-collapse: collapse;" align="center">
                            <tr>
                                <td align="left" valign="top">
                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                        <!---header section--->
                                        <tr>
                                            <td align="left" valign="top">
                                                <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                        <td align="left" valign="top">
                                                            <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                                <template v-if="docs[0][0]['city_name'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{docs[0][0]['company_full_name']}} - {{docs[0][0]['city_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-else>
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif;"> {{docs[0][0]['company_full_name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="docs[0][0]['city_name'] != '' && docs[0][0]['state_code'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{docs[0][0]['city_name']}}, {{docs[0][0]['state_code']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="docs[0][0]['name'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 18px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px; font-weight:bold;"> Contact {{docs[0][0]['name']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="docs[0][0]['contact_no'] != ''">
                                                                    <tr>
                                                                        <td style="color: #333333; font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> {{docs[0][0]['contact_no']}} </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="docs[0][0]['email'] != ''">
                                                                    <tr>
                                                                        <td style="font-size: 16px; line-height: 25px; font-family:Arial, Helvetica, sans-serif; padding-top: 5px;"> <a style="color:#098de1; text-decoration: none;" :href="'mailto:'+ docs[0][0]['email']"> {{docs[0][0]['email']}} </a> </td>
                                                                    </tr>
                                                                </template>
                                                            </table>
                                                        </td>
                                                        <td align="right" valign="top">
                                                            <a href="javascript:void(0)" target="_blank"> <img src="/static/images/logo-1.png" alt="Central California Truck and Trailer Sales" style="width:40%; height:auto;"> </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="30px" style="border-bottom:1px solid #dddddd;"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" align="left" valign="top" height="30px"> </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <!---header section--->
                                        <!--image section-->
                                        <template v-if="docs && docs.length > 0">
                                            <tr>
                                                <td slign="left" valign="top">
                                                    <table align="center" valign="top" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
                                                        <tbody>
                                                            <template v-for="(doc, index) in docs">
                                                                <tr :key="'tr ' + index">
                                                                    <template v-for="(docInner, indexInner) in docs[index]">
                                                                        <td :key="'indexInner td ' + indexInner" align="left" valign="top" width="32%">
                                                                            <a href="javascript:void(0)" v-on:click.prevent="clickIndividualAssetPromoSheet(docInner['asset_id'])">
                                                                                <img :src="docInner['media_path_frontend']" alt="Velocity Vehicle Group Deal Manager" style="width: 100%;">
                                                                            </a>
                                                                        </td>
                                                                        <template v-if="indexInner + 1 != docs[index].length">
                                                                            <td :key="'indexInner Space td ' + indexInner" align="left" valign="top" width="2%"></td>
                                                                        </template>
                                                                    </template>
                                                                </tr>
                                                                <tr :key="'space tr ' + index">
                                                                    <td colspan="5" align="left" valign="top" width="100%" height="15"></td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                        <!--image section-->
                                        <!--Footer-->
                                        <div class="footer-table-section">
                                            <tr>
                                                <td align="left" valign="top" width="100%" height="20"> </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" width="100%"> <img style="width:170px;" src="/static/images/logo.png" alt="Velocity Vehicle Group"> </td>
                                            </tr>
                                            <tr>
                                                <td align="left" valign="top" width="100%" height="10"> </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top" width="100%" style="font-size: 13px; line-height: 17px; color: #33333; font-family:Arial, Helvetica, sans-serif; font-style: italic;"> <span style="font-size: 10px;">Images may not be of the vehicle associated with the VIN number on this sheet and may a close representation of that vehicle</span> <br/> Copyright © {{systemYear}} Velocity Vehicle Group </td>
                                            </tr>
                                        </div>
                                        <!--Footer-->
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AssetPromoSpecSheet',
        components: {
        },
        mixins: [commonMixins],
        data() {
            return {
                firstName: "",
                lastName: "",
                email: "",
                assetInfo: [],
                docs: [],
                systemYear: "",
                loginId: 0,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            var userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.firstName = userJson['first_name'];
                self.lastName = userJson['last_name'];
                self.email = userJson['email'];
            }
            if (typeof (self.$route.query.uid) != 'undefined' && self.$route.query.uid != "" && self.$route.query.uid != 0) {
                self.loginId = self.$route.query.uid;
                self.getPromoAssetData(self.$route.query.idlist);
            }
        },
        methods: {
            clickIndividualAssetPromoSheet: function(id) {
                window.open("/pub/print/?id=" + id);
            },
            getPromoAssetData: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        firstImageId: id,
                        userId: self.loginId,
                    }
                };
                HTTP.get('/assetprintpromosheetimages/get', config)
                        .then(response => {
                            self.docs = response.data.content;
                            self.systemYear = response.data.SystemYear;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
        },
    }
</script>
