<template>

    <b-collapse :id="'accordion-' + accordianData.id" class="mt-2">
        <b-card>
            <template v-for="(data, index) in displayFields">
                <template v-for="(aData, aIndex) in accordianData">
                    <!--<span v-if="aIndex == data.field"> {{displayFields}}</span>-->

                    <span v-if="(aIndex == data.field && data.field == 'media_thumb') && aData != '' && aData != null" class="mr-4">
                        <img class="inventory-list-img" :src="aData"/>
                    </span>
                    <span v-else-if="aIndex == data.field && aData != '' && aData != null" class="mr-4">
                        <strong>{{data.label}}</strong> {{aData}}
                    </span>
                    <span v-else-if="aIndex == data.field && data.alt != '' && data.alt != null" class="mr-4">
                        <strong>{{data.label}}</strong> {{data.alt}}
                    </span>
                </template>
            </template>

        </b-card>
    </b-collapse>

</template>

<script>
    var self;
    import Vue from "vue";
    import {commonMixins} from '../../mixins/commonMixins';
    import {HTTP} from '../../http-common';
    export default {
        name: 'ListAccordianComponent',
        mixins: [commonMixins],
        props: {
            accordianData : {
                type : Object,
                required : false,
                default : undefined
            },

            displayFields : {
                type : Array,
                required : false,
                default : undefined
            },
        },
        data() {
            return {

            }
        },
        beforeMount() {
            self = this;
        },
        created: function () {
        },
        watch: {

        },
        mounted: function () {
        },
        methods: {

        },
    }
</script>