<template>
    <div class="modal fade" id="set-by-branch-default-form" tabindex="-1" role="dialog" aria-labelledby="set-by-branch-default-form" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-6"><h5 class="modal-title" id="set-by-branch-default-form">{{ pageTitle }}</h5></div>
                    <div class="col-6">
                        <div class="row justify-content-end">
                            <button type="button" class="btn btn-outline-dark mr-1" aria-label="Close" @click="closeModal()">Close</button>
                            <button type="button" :value="buttonNames.save" class="btn btn-primary" @click="submitData()">Save</button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div id="page-content-wrapper" class="admin-content-area">
                        <!-- <template v-if="permissionNameArray.includes('can_add_set_by_branch_defaults')"> -->
                        <template>

                            <!--page content-->
                            <div class="admin-page-content">
                                <!--Forms-->
                                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                                    <div class="row no-margin">
                                        <!-- NOT REQUIRED FOR NOW
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">New Used:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <v-select 
                                                                v-model="vehicleId"
                                                                name="staticValue"
                                                                id="new-used"
                                                                label="text"
                                                                :clearable="true"
                                                                :searchable="true"
                                                                :filterable="false"
                                                                @search="searchData"
                                                                :options="vehicleTypeList"
                                                                placeholder="Select Vechicle Type"
                                                                disabled
                                                                >
                                                        </v-select>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Nrid:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="nrid" v-model="nrid" class="form-control" type="text"/>
                                                    </template>
                                                </div>
                                            </div>
                                        -->
                                        <div class="col-6 forms-block">
                                            <div class="col-7 paddingleft0">
                                                <div class="form-label float-right">Doc Preparation Fee:</div>
                                            </div>
                                            <div class="col-5 paddingleft0">
                                                <template>
                                                    <input id="doc-prep-fee" v-model="doc_prep_fees" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block">
                                            <div class="col-7 paddingleft0">
                                                <div class="form-label float-right">Tire Tax:</div>
                                            </div>
                                            <div class="col-5 paddingleft0">
                                                <template>
                                                    <input id="tire-tax" v-model="tire_tax" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block">
                                            <div class="col-7 paddingleft0">
                                                <div class="form-label float-right">Hold Back Percentage:</div>
                                            </div>
                                            <div class="col-5 paddingleft0">
                                                <template>
                                                    <input id="percentage" v-model="percentage" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>
                                        <!-- <div class="col-6 forms-block">
                                            <div class="col-7 paddingleft0">
                                                <div class="form-label">License/Title:</div>
                                            </div>
                                            <div class="col-5 paddinglfloat-righteft0">
                                                <template>
                                                    <input id="license-title" v-model="license_title" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div> -->
                                        <div class="col-6 forms-block">
                                            <div class="col-7 paddingleft0">
                                                <div class="form-label float-right">No License:</div>
                                            </div>
                                            <div class="col-5 paddingleft0 custom-checkbox-v">
                                                <label class="container">
                                                    <input @change="" id="isAuthorized" v-model="no_license" class="form-control" type="checkbox"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- NOT REQUIRED FOR NOW
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Is NewUsed:</div>
                                                </div>
                                                <div class="col-9 paddingleft0 custom-checkbox-v">
                                                    <label class="container">
                                                        <input @change="" id="isAuthorized" v-model="is_new_used" class="form-control" type="checkbox"/>
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                         -->
                                    </div>
                                </div>
                                <!-- Forms -->
                            </div>
                            <!--page content-->
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../../mixins/commonMixins';
    import EventBus from "../../../event-bus";
    import {HTTP} from '../../../../http-common';
    import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateSetByBranchDefault',
        components: {
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        props: {
            editData: null
        },
        data() {
            return {
               pageTitle: 'Add Branch Defaults',
               vehicleTypeList: [
                {
                    text: 'NEW',
                    value: 'new',
                },
                {
                    text: 'USED',
                    value: 'used',
                },
                {
                    text: 'BOTH',
                    value: 'both',
                }
               ],
               vehicleId: '',
               nrid: 0,
               doc_prep_fees: 0,
               tire_tax: 0,
               percentage: 0,
               no_license: true,
            //    license_title: 0,
               buttonNames: {
                    save: 'Save',
                    update: 'Update',
                },
                is_new_used: false,
                isEdit: false,
                edit_id: 0,
            }
        },
        beforeMount() {
            self = this;
        },
        methods: {
            submitData() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                };
                var data = new FormData();
                data.append('location_id', self.$route.params.id);
                data.append('is_used', self.vehicleId.value);
                data.append('nrid', self.nrid);
                data.append('doc_prepare_fee', self.doc_prep_fees);
                data.append('tire_tax', self.tire_tax);
                data.append('hold_back_percentage', self.percentage);
                data.append('no_license', self.no_license);
                // data.append('license_title', self.license_title);
                data.append('is_new_used', self.is_new_used);
                data.append('type', 'set_by_branch');
                data.append('is_edit', self.isEdit);
                data.append('edit_id', self.edit_id);
                HTTP.post("/add-update/static-data", data, config)
                    .then(response => {
                        if(response.data.code == 200) {
                            self.showSAlert(response.data.message, response.data.status, false, 2);
                            self.clearData();
                            EventBus.$emit("set-by-branch-list-data",response.data.content);
                        }
                    }).catch(error => {
                        if (error.response.status == 422){
                            self.showSAlert(error.response.data.is_used[0], 'error', false, 2);
                        }
                    });
            },
            
            clearData() {
                self.vehicleId= '';
                self.nrid= 0;
                self.doc_prep_fees= 0;
                self.tire_tax= 0;
                self.percentage= 0;
                self.no_license= true;
                // self.license_title= 0;
                self.is_new_used= false;
                self.isEdit= false;
                self.edit_id= 0;
                self.pageTitle= 'Add Branch Defaults';
            },

            searchData(data) {
                self.vehicleTypeList = self.vehicleTypeList.filter((vehicle) => {
                    return vehicle.value.indexOf(data.toLowerCase()) > -1;
                });
            },

            closeModal() {
                self.clearData();
                EventBus.$emit("close-branch-default", false);
            },
        },
        watch: {
            editData(data) {
                if(data.is_used) {
                    self.vehicleTypeList.filter(vehicleType => {
                        if(vehicleType.value == data.is_used) {
                            self.vehicleId = vehicleType;
                            return true;
                        }
                    });
                    self.nrid = data.nrid;
                    self.doc_prep_fees = data.doc_prepare_fee;
                    self.tire_tax = data.tire_tax;
                    self.percentage = data.hold_back_percentage;
                    self.no_license = data.no_license;
                    // self.license_title = data.license_title;
                    self.is_new_used = data.is_new_used;
                    self.isEdit = true;
                    self.edit_id = data.id;
                    self.pageTitle= 'Update Branch Defaults';
                } else {
                    self.clearData();
                }
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_add_set_by_branch_defaults') && !self.permissionNameArray.includes('can_delete_set_by_branch_defaults') && !self.permissionNameArray.includes('can_edit_set_by_branch_defaults')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });
</script>