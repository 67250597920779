<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_user')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.users_lable }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <router-link
              v-show="permissionNameArray.includes('can_add_user')"
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Add User"
              v-bind:to="{ name: 'AddUpdateUser' }"
            >
              <span>Add User</span>
            </router-link>

            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <a
              v-show="permissionNameArray.includes('can_import_user')"
              id="importUser"
              title="Import"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="loadImportModal('import_record')"
            >
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </a>

            <a
              id="exportUser"
              title="Export"
              v-show="permissionNameArray.includes('can_export_user')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="exportUser($event)"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>

            <button
              id="userHeaderList"
              title="User Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="userHeaderList($event)"
            >
              User Header List
            </button>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="permissionNameArray.includes('can_active_inactive_user')"
              type="button"
              v-on:click.prevent="changeActiveInactiveStatus(0, 'Active')"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              Change status of {{ selectedRows.length }} Record(s)
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListUser"
              ref="commonLoaderListUser"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="userArr && userArr.length > 0">
                    <tr v-for="(user, index) in userArr" :key="index">
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="user.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + user.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + user.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions" style="width: 58px">
                          <router-link
                            v-show="
                              permissionNameArray.includes('can_add_user')
                            "
                            class="mar-right-0"
                            title="Edit User"
                            v-bind:to="{
                              // name: 'UpdateUser',//kept this comment to shift view(tab and normal)
                              name: 'UserTabView',
                              params: { id: user.id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_qr_code_history_list_inventory_master'
                              )
                            "
                            class="mar-right-0"
                            title="QRCode History"
                            v-bind:to="{
                              name: 'ListUserWiseQRCodeHistory',
                              params: { id: user.id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-history"
                              style="margin-top: 5px"
                            ></i>
                          </router-link>
                          <template v-if="user.is_active">
                            <a
                              v-show="
                                permissionNameArray.includes(
                                  'can_active_inactive_user'
                                )
                              "
                              href="javascript:void(0)"
                              :title="allLabelMessages.make_inactive"
                              class="mar-right-0"
                              v-on:click.prevent="
                                changeActiveInactiveStatus(user.id, 'Inactive')
                              "
                            >
                              <i
                                class="exceptEdit fa fa-eye color-71aa3f"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </template>
                          <template v-else>
                            <a
                              v-show="
                                permissionNameArray.includes('can_delete_user')
                              "
                              href="javascript:void(0)"
                              title="Make Active"
                              class="mar-right-0"
                              v-on:click.prevent="
                                changeActiveInactiveStatus(user.id, 'Active')
                              "
                            >
                              <i
                                class="exceptEdit fa fa-eye-slash color-c3202b"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </template>
                        </div>
                      </td>
                      <template v-if="userDataArr && userDataArr.length > 0">
                        <template
                          v-for="(userData, userDataIndex) in userDataArr"
                        >
                          <template v-if="index == userDataIndex">
                            <template v-for="(data, dataIndex) in userData">
                              <template
                                v-for="(headerData, headerIndex) in headers"
                              >
                                <template
                                  v-if="headerData.column_name == dataIndex"
                                >
                                  <td
                                    :class="headerData.column_class"
                                    :key="dataIndex"
                                  >
                                    {{ data }}
                                  </td>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                      <!-- <td :class="rowClass.emp_id">{{user.emp_id}}</td>
                                        <td :class="rowClass.name">{{user.name}}</td>
                                        <td :class="rowClass.username">{{user.username}}</td>
                                        <td :class="rowClass.email">{{user.email}}</td>
                                        <td :class="rowClass.role">{{user.role_name}}</td>
                                        <td :class="rowClass.department">{{user.department}}</td>
                                        <td :class="rowClass.home_location_name">{{user.home_location_name}}</td>
                                        <td :class="rowClass.created_at">{{user.created_at}}</td>
                                        <td :class="rowClass.last_login">{{user.last_login}}</td> -->
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepageNew()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>

        <!-- Import Modal -->
        <div
          class="modal fade"
          id="userImportModal"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Import User From Excel</h4>
              </div>
              <form
                class="form-inline"
                id="importCallform"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderUserImportModel"
                  ref="commonLoaderUserImportModel"
                >
                </common-loader>
                <div class="modal-body">
                  {{ allLabelMessages.select_excel_file_to_import }}
                  <input
                    type="file"
                    name="excl_file"
                    accept=".xlsx,.xls"
                    id="uploadUser"
                    ref="files"
                    v-on:change="handleFilesUpload()"
                  />
                  <div class="form-group">
                    <label id="errorMsg" class="error"></label><br />
                  </div>
                  <a
                    id="demouserurl"
                    href="#" @click="getSignedUrlPath('demo/UserImportSheet.xlsx')"
                    class="btn general-button btn-info waves-effect m-r-20"
                  >
                  
                  <!-- <a
                    id="demouserurl"
                    href="demoUrl"
                    target="_blank"
                    class="btn general-button btn-info waves-effect m-r-20"
                  > -->
                    {{ allLabelMessages.download_demo }}
                  </a>

                  <template v-if="userImportRecordsWithErrors.length">
                    <div class="mt-20">
                      <h6>{{ allLabelMessages.import_file_error }}</h6>
                      <template
                        v-for="(row, index) in userImportRecordsWithErrors"
                      >
                        <template v-if="Object.keys(row.errors).length">
                          <div
                            :key="'userImportRecordsWithErrors_' + index"
                            class=""
                          >
                            <table class="">
                              <tr>
                                <td class="bold">Record {{ index + 1 }}:</td>
                                <td>
                                  {{
                                    row.missing_error_msg
                                      ? row.missing_error_msg
                                      : ""
                                  }}
                                  {{
                                    row.duplicate_record
                                      ? row.duplicate_record
                                      : ""
                                  }}
                                  {{ row.other_error ? row.other_error : "" }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </div>
                <div class="modal-footer width-full">
                  <button
                    type="button"
                    @click="importUser"
                    class="btn general-button btn-success waves-effect"
                    id="userImportBtn"
                  >
                    {{ importBtn }}
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    id="userCloseBtn"
                    data-bs-dismiss="modal"
                  >
                    {{ allLabelMessages.close }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Export Modal -->
        <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportModalLabel">Export User</h4>
              </div>
              <form
                class="form-inline"
                id="exportModalForm"
                enctype="multipart/form-data"
              > 
                <common-loader
                  refId="commonLoaderExportUser"
                  ref="commonLoaderExportUser"
                >
                </common-loader>
                <div class="col-md-12 modal-body" id="modal-checkbox">
                  <template v-if="exportHeaders && exportHeaders.length > 0">
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllExportHeader"
                          @click="selectAllExportHeaders()"
                          class="form-control"
                          id="ExportList"
                        />
                        <strong v-if="isSelectAllExportHeader"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p></strong
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(exportOpp, exportIndex) in exportHeaders">
                      <div
                        :key="exportIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="exportOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="exportSelectedHeaders"
                            @change="updateCheckedExportHeaders()"
                          />
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="exportSelectedHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- User Header List Modal -->
        <div
          class="modal fade"
          id="userHeaderListModal"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="userHeaderListModalLabel">
                  Select User Headers
                </h4>
              </div>
              <form
                class="form-inline"
                id="userHeaderListModalForm"
                enctype="multipart/form-data"
              >
                <div
                  class="col-md-12 modal-body"
                  id="modal-checkbox-availabel-age"
                >
                  <template
                    v-if="userHeadersList && userHeadersList.length > 0"
                  >
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllUserHeaderList"
                          @click="selectAllUserHeaders()"
                          class="form-control"
                          id="UserHeadersList"
                        />
                        <strong v-if="isSelectAllUserHeaderList"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p></strong
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(userOpp, userIndex) in userHeadersList">
                      <div
                        :key="userIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="userIndex" class="form-control" type="checkbox" @click="checkedExportAvailableAgeHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="userOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="selectedUserHeadersList"
                            autocomplete="off"
                            @change="updateCheckedUserHeaders()"
                          />
                          <p class="modal-check-lbl">{{ userOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="viewSelectedUserHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Show List Data
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);
Vue.component("no-access", NoAccessPage);

export default {
  name: "ListUsers",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      userArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_user"],
      actionDisplay: true,
      exportUserRequest: false,
      jumpToHistoryPage: 1,
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      staticMessages: "",
      staticInputLabelMessages: "",
      isFilteringUsers: false,
      userHeadersList: [],
      selectedUserHeadersList: [],
      isSelectAllUserHeaderList: false,
      userDataArr: [],
      filterheaders: [],
      userImportRecordsWithErrors: [],
      files: [],
      importBtn: "Import",
      userHeaderListModalInstance: null,
      exportUserInstance: null,
      importUserInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadUser();
            },
            currentPage: function () {
                self.loadUser();
            },*/
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    // var initial = process.env.VUE_APP_ROOT_API;
    // var initial = initial.replace("/v1", "");
    // $("#demouserurl").attr("href", initial + "/demo/UserImportSheet.xlsx");

    self.setActiveMenu("user-list");
    EventBus.$emit("menu-selection-changed", "user-list");
    var listHistoryData = self.loadFilters("listUsers", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;
    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadUser(1);
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.userHeaderListModalInstance = new bootstrap.Modal(
        document.getElementById("userHeaderListModal")
      );

      self.exportUserInstance = new bootstrap.Modal(
        document.getElementById("exportModal")
      );

      self.importUserInstance = new bootstrap.Modal(
        document.getElementById("userImportModal")
      );
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadUser();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadUser();
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
      /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
    },
    updateCheckedExportHeaders: function () {
      /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
                console.log("Total export header : " + self.exportHeaders.length); */
      if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
        self.isSelectAllExportHeader = true;
      } else {
        self.isSelectAllExportHeader = false;
      }
    },
    /* checkedExportHeaders: function (e, index, value) {
                if (e.target.checked) {
                    // Vue.set(self.exportSelectedHeaders, index, value);
                    self.exportSelectedHeaders.push(Vue.util.extend({}, value));
                } else {
                    // Vue.delete(self.exportSelectedHeaders, index);
                    for (var i = 0; i < self.exportSelectedHeaders.length; i++) {
                        if (self.exportSelectedHeaders[i]['name'] == value['name']) {
                            self.exportSelectedHeaders.splice(i, 1);
                        }
                    }
                }
                // console.log(JSON.stringify(self.exportSelectedHeaders));
            }, */
    exportUser: function () {
      self.exportUserInstance.show();
      /* self.exportUserRequest = true;
                if (self.exportUserRequest == true) {
                    $('#exportUser').prop("disabled", true);
                    self.loadUser();
                } */
    },
    exportSelectedHeadersData: function () {
      // if(Object.keys(self.exportSelectedHeaders).length == 0) {
      if (self.exportSelectedHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox input:checkbox').prop('checked', false); */
      self.exportUserRequest = true;
      if (self.exportUserRequest == true) {
        $("#exportUser").prop("disabled", true);
        self.loadUser();
        /* self.isSelectAllExportHeader = false; */
      }
      if (
        self.exportSelectedHeaders != null &&
        self.exportSelectedHeaders != "undefined" &&
        self.exportSelectedHeaders.length > 0
      ) {
        self.storeExportHeaders("exportUsers", self.exportSelectedHeaders);
      } else {
        self.exportSelectedHeaders = [];
      }
    },
    selectAllUserHeaders: function () {
      self.selectedUserHeadersList = [];
      if (!self.isSelectAllUserHeaderList) {
        for (let key in self.userHeadersList) {
          self.selectedUserHeadersList.push(self.userHeadersList[key]);
        }
      }
      /* console.log(JSON.stringify(self.selectedUserHeadersList)); */
    },
    updateCheckedUserHeaders: function () {
      /* console.log("Selected export header : " + self.selectedUserHeadersList.length);
                console.log("Total export header : " + self.userHeadersList.length); */
      if (self.selectedUserHeadersList.length == self.userHeadersList.length) {
        self.isSelectAllUserHeaderList = true;
      } else {
        self.isSelectAllUserHeaderList = false;
      }
    },
    userHeaderList: function () { 
      self.userHeaderListModalInstance.show();
    },
    viewSelectedUserHeadersData: function () {
      // if(Object.keys(self.selectedUserHeadersList).length == 0) {
      if (self.selectedUserHeadersList.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox-availabel-age input:checkbox').prop('checked', false); */
      if (
        self.selectedUserHeadersList != null &&
        self.selectedUserHeadersList != "undefined" &&
        self.selectedUserHeadersList.length > 0
      ) {
        self.storeListHeaders("listUser", self.selectedUserHeadersList);
      } else {
        self.selectedUserHeadersList = [];
      }
      self.loadUser();
      self.userHeaderListModalInstance.hide();
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadUser();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.userArr) {
            self.selectedRows.push(self.userArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.userArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addUser: function () {
      // console.log(self.selectedRows);
      self.$router.push("/add/user");
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadUser();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
        // self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadImportModal() {
      self.files = [];
      self.userImportRecordsWithErrors = [];
      self.resetImportModel();
      self.importUserInstance.show();
    },
    handleFilesUpload() {
      let uploadedFiles = "";
      uploadedFiles = self.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        self.files = [uploadedFiles[i]];
      }
    },
    importUser() {
      if (
        typeof self.files === "undefined" ||
        self.files == "null" ||
        self.files == ""
      ) {
        self.showSAlert(self.allStaticMessages.please_upload_excel_file, "info", false, 3);
      } else {
        var data = new FormData();
        for (let i = 0; i < self.files.length; i++) {
          const file = self.files[0];
          data.append("excl_file", file);
        }

        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            "Content-Type": "multipart/form-data"
          },
        };
        self.importBtn = "Importing ...";
        $("#commonLoaderUserImportModel")
          .find(".loading-container.lodingTable")
          .show();

        HTTP.post("/user/import", data, config)
          .then((response) => {
            $("#commonLoaderUserImportModel")
              .find(".loading-container.lodingTable")
              .hide();
            if (response.data.code == 200) {
              self.importUserInstance.hide();
              self.showSAlert(response.data.message, "success", false);
              self.loadUser();
              self.userImportRecordsWithErrors = [];
            } else {
              self.showSAlert(response.data.message, "error", false);

              self.importBtn = "Import";

              if (
                typeof response.data.status_type != "undefined" &&
                response.data.status_type == "import_record_error"
              ) {
                self.resetImportModel();
                self.userImportRecordsWithErrors = response.data.records;
              }
            }
          })
          .catch(function (err) {
            $("#commonLoaderUserImportModel")
              .find(".loading-container.lodingTable")
              .hide();
            self.catchHandler(err, function () {});
          });
      }
    },
    resetImportModel() {
      self.$refs.files.value = "";
      self.files = [];
      self.importBtn = "Import";
    },
    loadUser: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadUser();
      }
      self.selectedUserHeadersList = self.loadListHeaders("listUser", self);
      self.selectedUserHeadersList =
        self.selectedUserHeadersList === null ||
        typeof self.selectedUserHeadersList === "undefined" ||
        self.selectedUserHeadersList == ""
          ? []
          : self.selectedUserHeadersList;
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportUserRequest,
        export_selected_headers: self.exportSelectedHeaders,
        selected_user_header: self.selectedUserHeadersList,
      };
      this.storeFilters("listUsers", fields, false);
      if (self.exportUserRequest) {
        $("#commonLoaderExportUser")
          .find(".loading-container.lodingTable")
          .show();
      } else {
        $("#commonLoaderListUser")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/user/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (self.exportUserRequest) {
            $("#commonLoaderExportUser")
              .find(".loading-container.lodingTable")
              .hide();
          } else {
            $("#commonLoaderListUser")
              .find(".loading-container.lodingTable")
              .hide();
          }
          /* self.exportSelectedHeaders = []; */
          self.exportSelectedHeaders = self.loadExportHeaders(
            "exportUsers",
            self
          );
          self.exportSelectedHeaders =
            self.exportSelectedHeaders === null ||
            typeof self.exportSelectedHeaders === "undefined" ||
            self.exportSelectedHeaders == ""
              ? []
              : self.exportSelectedHeaders;
          self.exportUserInstance.hide();
          $("#exportUser").prop("disabled", false);
          self.exportUserRequest = false;
          if (data.status == 200) {
            if (data.data.message == "success export") {
              self.downloadFile(data.data.content.web);
              // location.href = data.data.content.web;
            } else {
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                // alert(self.totalRecords);
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadUser();
                }, seconds);
                /* self.$swal({
                                            title: '',
                                            html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                            type: 'error',
                                            confirmButtonText: 'OK',
                                            showCancelButton: true,
                                            showLoaderOnConfirm: true
                                        }).then((result) => {
                                            if (result.value) {
                                                self.logout();
                                            } else {
                                                self.perPage = 15;
                                                self.loadUser();
                                            }
                                        }); */
              }
              self.userArr = data.data.content.data;
              self.userDataArr = data.data.content.userListData;
              $("#selectAllCheckBox").prop("checked", false);
              self.headers = data.data.content.headers;
              self.userHeadersList = data.data.content.userHeadersList;
              if (
                self.selectedUserHeadersList.length ==
                self.userHeadersList.length
              ) {
                self.isSelectAllUserHeaderList = true;
              }
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              self.loadFilterInputs(
                data.data.content.filterheaders,
                self.filters
              );
              self.filterheaders = data.data.content.filterheaders;

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#exportUser").prop("disabled", false);
          self.exportUserRequest = false;
          if (self.exportUserRequest) {
            $("#commonLoaderExportUser")
              .find(".loading-container.lodingTable")
              .hide();
          } else {
            $("#commonLoaderListUser")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadUser();
      }
    },
    changeActiveInactiveStatus: function (id, state) {
      var use = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        use.push(id);
      } else {
        use = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to " + state + " this User!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var fields = {
              id: use,
              flag: state,
            };

            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };

            self.showLoader();
            HTTP.post("/user/activeordeative", fields, config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadUser();
                }
                self.hideLoader();
              })
              .catch(function (error) {
                self.hideLoader();
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }

    if (
      !self.permissionNameArray.includes("can_add_user") &&
      !self.permissionNameArray.includes("can_active_inactive_user") &&
      !self.permissionNameArray.includes("can_delete_user") &&
      !self.permissionNameArray.includes(
        "can_qr_code_history_list_inventory_master"
      )
    ) {
      self.headers.pop();
      self.actionDisplay = false;
    }
  }
});
</script>
