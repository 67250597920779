<template>
  <div class="modal" id="searchModal" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-xl modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header customer-src-modal-row">
          <div>
            <div class="customer-input-search">
              <input type="text" id="customer-form-input" v-model="currentSearch" />
              <div class="customer-search-icon" @click="onSearchCustomer()"></div>
            </div>
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body trns-cust-src-modal-body">
          <div class="card">
            <table class="table custom-table jsmartable">
              <caption style="display:none">Customer List</caption>
              <thead id="trns-cus-src-modal">
                <th>Customer ID</th>
                <th>Customer</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>ZIP</th>
                <th>Contact</th>
                <th>Action</th>
              </thead>
              <tbody v-if="loader">
                <tr>
                  <td colspan="8">
                    <loader />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="customerArr.length > 0">
                <tr v-for="(customer,index) in customerArr" :key="index">
                    <td>{{ customer.customerId }}</td>
                    <td>{{ customer.text }}</td>
                    <td>{{ customer.address }}</td>
                    <td>{{ customer.cityName }}</td>
                    <td>{{ customer.stateCode }}</td>
                    <td>{{ customer.zipCode }}</td>
                    <td>{{ customer.primaryPhone }}</td>
                    <td> <a href="javascript:void(0)" @click="selectCustomer(customer)">Select</a> </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8">
                    <div class="d-flex justify-content-center">
                      <h6>No Data</h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../event-bus";
import { commonMixins } from "@/mixins/commonMixins";
import Loader from "./Loader.vue";
let selfThis;
export default {
  name: "SearchModal",
  props: ["isClickSearchModal", "customerName"],
  components: {
    'loader': Loader,
  },
  mixins: [commonMixins],
  data() {
    return {
      customerArr: [],
      currentSearch: "",
      loader: false,
    };
  },
  mounted() {
    selfThis = this;
  },
  methods: {
    closeModal() {
      selfThis.customerArr = [];
      eventBus.$emit("close-search-customer", false);
    },

    onSearchCustomer() {
      // Check if the search input is not empty before proceeding with search
      if (!this.currentSearch.trim()) {
        this.showSAlert("Please provide a Customer to search.", 'error', false, 4);
        eventBus.$emit("close-search-customer", false);
      }

      this.loader = true;
      let customer = selfThis.currentSearch;
      this.loadAllData(
        ["Contact"],
        true,
        selfThis.loadCustomerCallback,
        "undefined",
        customer
      );
    },

    loadCustomerCallback: function (callbackData) {
      selfThis.customerArr = callbackData.Contact;
      selfThis.loader = false;
    },

    selectCustomer(customer) {
        eventBus.$emit("get-search-customer", customer);
        selfThis.customerArr = [];
    }
  },
  watch: {
    isClickSearchModal(status) {
      if (status) {
        selfThis.currentSearch = selfThis.customerName;
        selfThis.onSearchCustomer();
      }
    },
  },
};
</script>

<style>
.trns-cust-src-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#trns-cus-src-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

.customer-input-search {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  overflow: hidden;
  /* margin-top: 5px; */
}

#customer-form-input {
  height: 32px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #cecece;
}

.customer-search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  flex-shrink: 0;
  background: #ebebeb
    url("../../../../../public/static/images/transaction_sheet/search.svg")
    no-repeat center;
  cursor: pointer;
}

.customer-src-modal-row > div:first-child {
  display: flex;
  align-items: center;
}
  
.customer-src-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
}
</style>
