<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div class="page-name">Edit Customer Contact</div>
                <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right" > Back </button>
            </div>
            <div class="admin-page-content">
                <div class="forms-container add_user_forms_container vtc-dealer-forms edit-customer-contact-form">
                    <customer-contact-form-fields  :refeshFlag="refeshFlag" ref="addUpdateCustomerContactRef"  open_at="editContact" :form_fields="form_fields"></customer-contact-form-fields>
                    <div class="button-demo ml-3">
                        <input type="submit" @click="saveContact" class="btn btn-primary pull-right" value="Update">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomerContactFormFields from './CustomerContactFormFields.vue';
import CustomerContactFields from '../../json/customer_contact_form_fields';
import { saveCustomerContactData, getCustomerContactDataForEdit} from "./customer";
import {commonMixins} from '../../mixins/commonMixins';
import EventBus from ".././event-bus";

var self;
export default {
    name: "CustomerContactEdit",
    components: {
        CustomerContactFormFields
    },
    mixins: [commonMixins],
    data() {
        return {
            refeshFlag: true,
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
            form_fields: JSON.parse(JSON.stringify(CustomerContactFields)),
            customer_id: "",
            contact_id: ""
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {
        self.customer_id = self.$route.params.customer_id;
        self.contact_id = self.$route.params.contact_id;
        self.form_fields.company_name.display = false;
        self.form_fields.abn.display = false;
        
        self.setActiveMenu("contact-list");
        EventBus.$emit("menu-selection-changed", "contact-list");

        self.getCustomerContactData();
    },
    methods: {
        buttonBack() {
            self.$router.push("/customer/edit/"+self.customer_id+"?tab=8");
        },
        getCustomerContactData(){
            const getContactCallbackFunction = (data, flag) => {
                self.form_fields = JSON.parse(JSON.stringify(data.form_fields));
                self.refeshFlag = !self.refeshFlag;
            };
            
            getCustomerContactDataForEdit({contact_id: self.contact_id, customer_id: self.customer_id, form_fields: self.form_fields}, getContactCallbackFunction);
        },
        saveContact() {
            // Define a callback function that accepts parameters
            const saveContactCallbackFunction = (data, flag) => {
                if(flag == "validationError"){
                    self.form_fields = JSON.parse(JSON.stringify(data.form_fields));
                    self.refeshFlag = !self.refeshFlag;
                }else if(flag == "reqResponse"){
                    if(data.content.status == "success") {
                        self.showSAlert(data.content.message, 'success', false);
                        EventBus.$emit('customer-contact-saved', "");
                    }else{
                        self.showSAlert(data.content.message ? data.content.message : "Some error occure, Please try later.", "error", false);
                    }
                }
            };

            let reqData = {
                customer_id: self.customer_id,
                contact_id: self.contact_id,
                form_fields: JSON.parse(JSON.stringify(self.form_fields))
            };
            saveCustomerContactData(reqData, saveContactCallbackFunction);
        }
    }

}
</script>
<style>
.edit-customer-contact-form  .checkbox-label{
    padding-right: 10px;
    text-align: right;
}
</style>
