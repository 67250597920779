<template>
    <div class="container-fluid xyz">
        <div class="spaced-container">
            <div class="col-12 page_title paddingnone">
                <div class="page_title_left">
                    <span>{{allLabelMessages.vehicle_work_sheet_label}}</span>
                </div>
                <button type="submit" v-on:click.prevent="closeWorkSheet()" class="btn btn-primary waves-effect pull-right">Close</button>
                <button style="margin-right: 5px;" type="submit" v-on:click.prevent="saveCloseWorkSheet()" class="btn btn-primary waves-effect pull-right">Save & Close</button>
            </div>
        </div>
        <div class="admin-page-content">
            <div class="forms-container add_user_forms_container vtc-dealer-forms">
                <div class="row no-margin">
                    <!-- Vehicle Information -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Vehicle Information</div>
                        </div>
                    </div>
                    <!-- Asset -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="assetId" 
                                name="asset"
                                label="text"
                                :filterable="false"
                                :options="assetArr"
                                @search="onSearchAsset"
                                :clearable="false"
                                @input="assetError = ''"
                                :placeholder="staticMessages.asset_place_holder"
                                class="custom-ci-auto-search"
                            >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isAssetRecordNotFound">
                                        {{staticMessages.asset_search}}
                                    </template>
                                    <template v-else>
                                        {{staticMessages.search_record_not_found}}
                                    </template>
                                </span>
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{assetError}}</span>
                        </div>
                    </div>
                    <!-- Unit Id -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.unit_id_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="unitId" 
                                name="unitId"
                                label="text"
                                :filterable="false"
                                :options="unitArr"
                                @search="onSearchUnit"
                                :clearable="false"
                                @input="unitError = ''"
                                :placeholder="staticMessages.unit_place_holder"
                                class="custom-ci-auto-search"
                            >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isUnitRecordNotFound">
                                        {{staticMessages.unit_search}}
                                    </template>
                                    <template v-else>
                                        {{staticMessages.search_record_not_found}}
                                    </template>
                                </span>
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{unitError}}</span>
                        </div>
                    </div>
                    <!-- Manufacturer -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.manufacturer_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="manufacturerId" 
                                name="manufacturerId"
                                label="text"
                                :filterable="false"
                                :options="manufacturerArr"
                                @search="onSearchManufacturer"
                                :clearable="false"
                                @input="manufacturerError = ''"
                                :placeholder="staticMessages.manufacturer_place_holder"
                                class="custom-ci-auto-search"
                            >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isManufacturerRecordNotFound">
                                        {{staticMessages.manufacturer_search}}
                                    </template>
                                    <template v-else>
                                        {{staticMessages.search_record_not_found}}
                                    </template>
                                </span>
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{manufacturerError}}</span>
                        </div>
                    </div>
                    <!-- Year -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{yearError}}</span>
                        </div>
                    </div>
                    <!-- Model/OEM -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.model_and_oem_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="modelOEMId" 
                                name="modelOEMId"
                                label="text"
                                :filterable="false"
                                :options="modelOEMArr"
                                @search="onSearchModelOEM"
                                :clearable="false"
                                @input="modelOEMError = ''"
                                :placeholder="staticMessages.model_oem_place_holder"
                                class="custom-ci-auto-search"
                            >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isModelOEMRecordNotFound">
                                        {{staticMessages.model_oem_search}}
                                    </template>
                                    <template v-else>
                                        {{staticMessages.search_record_not_found}}
                                    </template>
                                </span>
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{modelOEMError}}</span>
                        </div>
                    </div>
                    <!-- Color -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.color_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="colorError = ''" id="color" v-model="color" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{colorError}}</span>
                        </div>
                    </div>
                    <!-- Stock Type -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.stock_type_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="stockTypeId"
                                @input="stockTypeError = ''"
                                name="stockTypeId"
                                id="stockTypeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="stockTypeArr"
                                :placeholder="staticMessages.stock_type_place_holder"
                            >
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{stockTypeError}}</span>
                        </div>
                    </div>
                    <!-- Body Type -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.body_type_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="bodyTypeError = ''" id="bodyType" v-model="bodyType" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{bodyTypeError}}</span>
                        </div>
                    </div>
                    <!-- Type -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.asset_type_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="typeId"
                                @input="typeError = ''"
                                name="typeId"
                                id="typeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="typeArr"
                                :placeholder="staticMessages.type_place_holder"
                            >
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{typeError}}</span>
                        </div>
                    </div>
                    <!-- Duty -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.duty_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="duty"
                                @input="dutyError = ''"
                                name="duty"
                                id="duty"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="dutyArr"
                                :placeholder="staticMessages.duty_place_holder"
                            >
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{dutyError}}</span>
                        </div>
                    </div>
                    <!-- Fuel Type -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.fuel_type_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="fuelTypeId"
                                @input="fuelTypeError = ''"
                                name="fuelTypeId"
                                id="fuelTypeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="fuelTypeArr"
                                :placeholder="staticMessages.fuel_type_place_holder"
                            >
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{fuelTypeError}}</span>
                        </div>
                    </div>
                    <!-- Stock/Order -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.stock_order_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <v-select v-model="stockOrder"
                                @input="stockOrderError = ''"
                                name="stockOrder"
                                id="stockOrder"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="stockOrderArr"
                                :placeholder="staticMessages.stock_order_place_holder"
                            >
                            </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{stockOrderError}}</span>
                        </div>
                    </div>
                    <!-- Odometer -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.odometer_label}}</div>
                        </div>
                        <div class="col-md-9 col-sm-12 paddingleft0">
                            <input @input="odometerError = ''" id="odometer" v-model="odometer" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{odometerError}}</span>
                        </div>
                        <div class="col-md-3 col-sm-12 paddingleft0">
                            <b-form-radio-group v-model="paymentType"
                                :options="paymentTypeOption"
                                name="paymentType">
                            </b-form-radio-group>
                        </div>
                    </div>
                    <!-- Scale Weight -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.scale_weight_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="scaleWeightError = ''" id="scaleWeight" v-model="scaleWeight" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{scaleWeightError}}</span>
                        </div>
                    </div>
                    <!-- Vehicle Description -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.vehicle_description_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="vehicleDescriptionError = ''" id="vehicleDescription" v-model="vehicleDescription" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{vehicleDescriptionError}}</span>
                        </div>
                    </div>
                    <!-- # of Batteries -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.of_batteries_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="ofBatteriesError = ''" id="ofBatteries" v-model="ofBatteries" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{ofBatteriesError}}</span>
                        </div>
                    </div>
                    <!-- # of Tires -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.of_tires_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="ofTiresError = ''" id="ofTires" v-model="ofTires" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{ofTiresError}}</span>
                        </div>
                    </div>
                    <!-- # of Axles -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.of_axles_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="ofAxlesError = ''" id="ofAxles" v-model="ofAxles" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{ofAxlesError}}</span>
                        </div>
                    </div>
                    <!-- Private Use -->
                    <div class="col-3 forms-block">
                        <div class="col-9 paddingleft0 custom-checkbox-v">
                            <label class="container">
                                <input id="privateUse" v-model="privateUse" type="checkbox"/>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-3 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.private_use_label}}</div>
                        </div>
                    </div>
                    <!-- Business Use -->
                    <div class="col-3 forms-block">
                        <div class="col-9 paddingleft0 custom-checkbox-v">
                            <label class="container">
                                <input id="businessUse" v-model="businessUse" type="checkbox"/>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-3 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.business_use_label}}</div>
                        </div>
                    </div>
                    <!-- Proposed Delivery Date -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.proposed_delivery_date_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <date-picker
                                v-model="proposedDeliveryDate"
                                :config="options"
                                class="form-control"
                                autocomplete="off"
                                :placeholder="staticMessages.proposed_delivery_date_place_holder"
                                name="proposedDeliveryDate"
                                @input="changeProposedDeliveryDate()"
                            >
                            </date-picker>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{proposedDeliveryDateError}}</span>
                        </div>
                    </div>
                    <!-- Factory Calculation -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Factory Calculation</div>
                        </div>
                    </div>
                    <!-- Fees -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Fees</div>
                        </div>
                    </div>
                    <!-- GVW GCWR -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.gvw_gcwr_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="gvwGCWRError = ''" id="gvwGCWR" v-model="gvwGCWR" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{gvwGCWRError}}</span>
                        </div>
                    </div>
                    <!-- Doc Preparation Fee -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.doc_preparation_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="docPreparationError = ''" id="docPreparation" v-model="docPreparation" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{docPreparationError}}</span>
                        </div>
                    </div>
                    <!-- Customer Supplied Body -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.customer_supplied_body_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="customerSuppliedBodyError = ''" id="customerSuppliedBody" v-model="customerSuppliedBody" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{customerSuppliedBodyError}}</span>
                        </div>
                    </div>
                    <!-- License/Title -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.license_title_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="licenseTitleError = ''" id="licenseTitle" v-model="licenseTitle" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{licenseTitleError}}</span>
                        </div>
                        <!-- No License -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="noLicense" v-model="noLicense" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.no_license_label}}</div>
                            </div>
                        </div>
                        <!-- Custom Fee -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="customFee" v-model="customFee" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.custom_fee_label}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Tire Fee -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.tire_fee_label}}</div>
                        </div>
                        <div class="col-md-4 col-sm-12 paddingleft0">
                            <input class="form-control" type="text" maxlength="190"/>
                        </div>
                        <div class="col-md-8 col-sm-12 paddingleft0">
                            <input @input="tireFeeError = ''" id="tireFee" v-model="tireFee" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{tireFeeError}}</span>
                        </div>
                    </div>
                    <!-- Registration -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.registration_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="registrationError = ''" id="registration" v-model="registration" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{registrationError}}</span>
                        </div>
                    </div>
                    <!-- Flooring -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.flooring_label}}</div>
                        </div>
                        <div class="col-md-6 col-sm-6 paddingleft0">
                            <input @input="flooringError = ''" id="flooring" v-model="flooring" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{flooringError}}</span>
                        </div>
                        <div class="col-md-6 col-sm-6 paddingleft0">
                            <a href="https://www.google.com" target="_blank" style="text-decoration: underline !important;">Flooring Calc</a>
                        </div>
                    </div>
                    <!-- Smog Fee Paid To Seller -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.smog_fee_paid_to_seller_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="smogFeePaidToSellerError = ''" id="smogFeePaidToSeller" v-model="smogFeePaidToSeller" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{smogFeePaidToSellerError}}</span>
                        </div>
                    </div>
                    <!-- Tire Credit -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.tire_credit_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="tireCreditError = ''" id="tireCredit" v-model="tireCredit" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{tireCreditError}}</span>
                        </div>
                    </div>
                    <!-- Smog Certification or Exemption Fee Paid to State -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.smog_certification_or_exemption_fee_paid_to_state_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="smogCertificationOrExemptionFeePaidToStateError = ''" id="smogCertificationOrExemptionFeePaidToState" v-model="smogCertificationOrExemptionFeePaidToState" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{smogCertificationOrExemptionFeePaidToStateError}}</span>
                        </div>
                    </div>
                    <!-- FET Exempt Items -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.fet_exempt_items_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="fetExemptItemsError = ''" id="fetExemptItems" v-model="fetExemptItems" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{fetExemptItemsError}}</span>
                        </div>
                    </div>
                    <!-- DMV Electronic Filling Fee -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.dmv_electronic_filling_fee_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="dmvElectronicFillingFeeError = ''" id="dmvElectronicFillingFee" v-model="dmvElectronicFillingFee" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{dmvElectronicFillingFeeError}}</span>
                        </div>
                    </div>
                    <!-- Fet -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.fet_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="fetError = ''" id="fet" v-model="fet" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{fetError}}</span>
                        </div>
                        <!-- No License -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="fetExempt" v-model="fetExempt" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.fet_exempt_label}}</div>
                            </div>
                        </div>
                        <!-- Custom Fee -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="customFet" v-model="customFet" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.custom_fet_label}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- CCTTS Only - Admin Fee -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.cctts_only_admin_fee_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="ccttsOnlyAdminFeeError = ''" id="ccttsOnlyAdminFee" v-model="ccttsOnlyAdminFee" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{ccttsOnlyAdminFeeError}}</span>
                        </div>
                    </div>
                    <!-- Fees -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Custom Fields</div>
                        </div>
                    </div>
                    <!-- Finance Reserve -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.finance_reserve_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="financeReserveError = ''" id="financeReserve" v-model="financeReserve" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{financeReserveError}}</span>
                        </div>
                    </div>
                    <!-- Factory Price -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.factory_price_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="factoryPriceError = ''" id="factoryPrice" v-model="factoryPrice" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{factoryPriceError}}</span>
                        </div>
                    </div>
                    <!-- Blank Block -->
                    <div class="col-md-6 col-sm-6 forms-block">
                    </div>
                    <!-- Fleet Reserve Cost -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.fleet_reserve_cost_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="fleetReserveCostError = ''" id="fleetReserveCost" v-model="fleetReserveCost" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{fleetReserveCostError}}</span>
                        </div>
                    </div>
                    <!-- Blank Block -->
                    <div class="col-md-6 col-sm-6 forms-block">
                    </div>
                    <!-- Body Cost -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.body_cost_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="bodyCostError = ''" id="bodyCost" v-model="bodyCost" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{bodyCostError}}</span>
                        </div>
                    </div>
                    <!-- ADOT Permit# -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.adot_permit_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="adotPermitError = ''" id="adotPermit" v-model="adotPermit" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{adotPermitError}}</span>
                        </div>
                    </div>
                    <!-- Fleet Reserve Ctrl# -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.fleet_reserve_ctrl_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="fleetReserveCtrlError = ''" id="fleetReserveCtrl" v-model="fleetReserveCtrl" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{fleetReserveCtrlError}}</span>
                        </div>
                    </div>
                    <!-- Hold Date -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.hold_date_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <date-picker
                                v-model="holdDate"
                                :config="options"
                                class="form-control"
                                autocomplete="off"
                                :placeholder="staticMessages.hold_date_place_holder"
                                name="holdDate"
                                @input="changeHoldDate()"
                            >
                            </date-picker>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{holdDateError}}</span>
                        </div>
                    </div>
                    <!-- Factory Inv. Date -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.factory_inv_date_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <date-picker
                                v-model="factoryInvDate"
                                :config="options"
                                class="form-control"
                                autocomplete="off"
                                :placeholder="staticMessages.factory_inv_date_place_holder"
                                name="factoryInvDate"
                                @input="changeFactoryInvDate()"
                            >
                            </date-picker>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{factoryInvDateError}}</span>
                        </div>
                    </div>
                    <!-- Vehicle Cost Calculation -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Vehicle Cost Calculation</div>
                        </div>
                    </div>
                    <!-- Current Inventory Cost From Procede -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.current_inventory_cost_from_procede_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="currentInventoryCostFromProcedeError = ''" id="currentInventoryCostFromProcede" v-model="currentInventoryCostFromProcede" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{currentInventoryCostFromProcedeError}}</span>
                        </div>
                    </div>
                    <!-- Cost Sheet -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">&nbsp;</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <button type="submit" class="btn btn-primary waves-effect">Cost Sheet</button>
                        </div>
                    </div>
                    <!-- Inventory Cost (Last Updated) -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.inventory_cost_last_updated_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="inventoryCostLastUpdatedError = ''" id="inventoryCostLastUpdated" v-model="inventoryCostLastUpdated" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{inventoryCostLastUpdatedError}}</span>
                        </div>
                    </div>
                    <!-- Add-On Lists -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">&nbsp;</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <button type="submit" class="btn btn-primary waves-effect">Add-On Lists</button>
                        </div>
                    </div>
                    <!-- Purchase Order -->
                    <div class="col-md-6 col-sm-6 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">&nbsp;</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <button type="submit" class="btn btn-primary waves-effect">Purchase Order</button>
                        </div>
                    </div>
                    <!-- List -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <!-- TABLE -->
                        <table class="table custom-table jsmartable">
                            <tbody>
                                {{staticInputLabelMessages.no_records_to_display_label}}
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="" data-breakpoint="xs">ITEM NAME</th>
                                    <th class="" data-breakpoint="xs">DESCRIPTION</th>
                                    <th class="" data-breakpoint="xs">TAX</th>
                                    <th class="" data-breakpoint="xs">COST</th>
                                    <th class="" data-breakpoint="xs">PRICE</th>
                                    <th class="" data-breakpoint="xs">ADD TO PRICE</th>
                                    <th class="" data-breakpoint="xs">EXCL FROM NET</th>
                                    <th class="" data-breakpoint="xs">SHOW ON DOC</th>
                                    <th class="" data-breakpoint="xs">DO NOT ADD TO GP</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <!-- Total Internal / Local Installation (Less Out of State Delivery Cost) -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.total_internal_local_installation_less_out_of_state_delivery_cost_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="totalInternalLocalInstallationLessOutOfStateDeliveryCostError = ''" id="totalInternalLocalInstallationLessOutOfStateDeliveryCost" v-model="totalInternalLocalInstallationLessOutOfStateDeliveryCost" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{totalInternalLocalInstallationLessOutOfStateDeliveryCostError}}</span>
                        </div>
                    </div>
                    <!-- Cost of Vehicle and Equipment (Including Flooring Cost) -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.cost_of_vehicle_and_equipment_including_flooring_cost_label}}</div>
                        </div>
                        <div class="col-md-12 col-sm-12 paddingleft0">
                            <input @input="costOfVehicleAndEquipmentIncludingFlooringCostError = ''" id="costOfVehicleAndEquipmentIncludingFlooringCost" v-model="costOfVehicleAndEquipmentIncludingFlooringCost" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{costOfVehicleAndEquipmentIncludingFlooringCostError}}</span>
                        </div>
                    </div>
                    <!-- Holdback Fixed= -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.hold_back_fixed_label}}</div>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="holdBackFixedError = ''" id="holdBackFixed" v-model="holdBackFixed" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{holdBackFixedError}}</span>
                        </div>
                        <!-- Custom Holdback -->
                        <div class="col-md-2 forms-block">
                            <div class="col-md-2 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="fetExempt" v-model="fetExempt" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-md-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.customer_hold_back}}</div>
                            </div>
                        </div>
                        <!-- Percentage= -->
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.percentage_label}}</div>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="percentageError = ''" id="percentage" v-model="percentage" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{percentageError}}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="percentageError1 = ''" id="percentage1" v-model="percentage1" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{percentageError1}}</span>
                        </div>
                    </div>
                    <!-- Total Vehicle Cost -->
                    <div class="col-md-12 forms-block">
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Total Vehicle Cost</div>
                        </div>
                        <div class="col-12 paddingleft0">
                            <div class="book-deal-customer-block">Vehicle Fixed Margin</div>
                        </div>
                    </div>
                    <!-- Gross Profit Dollar amount or Percentage -->
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <div class="form-label">{{staticInputLabelMessages.gross_profit_dollar_amount_or_percentage_label}}</div>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="grossProfitDollarAmountOrPercentageError = ''" id="grossProfitDollarAmountOrPercentage" v-model="grossProfitDollarAmountOrPercentage" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{grossProfitDollarAmountOrPercentageError}}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="grossProfitDollarAmountOrPercentageError1 = ''" id="grossProfitDollarAmountOrPercentage1" v-model="grossProfitDollarAmountOrPercentage1" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{grossProfitDollarAmountOrPercentageError1}}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 paddingleft0">
                            <input @input="grossProfitDollarAmountOrPercentageError2 = ''" id="grossProfitDollarAmountOrPercentage2" v-model="grossProfitDollarAmountOrPercentage2" class="form-control" type="text" maxlength="190"/>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{grossProfitDollarAmountOrPercentageError2}}</span>
                        </div>
                    </div>
                    <div>
                        <!-- Vehicle Price Calculation -->
                        <div class="col-md-12 forms-block">
                            <div class="col-12 paddingleft0">
                                <div class="book-deal-customer-block">Vehicle Price Calculation</div>
                            </div>
                        </div>
                        <!-- Vehicle Sales Price -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.vehicle_sales_price_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.price_fet_included_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <input @input="priceFetIncludedError = ''" id="priceFetIncluded" v-model="priceFetIncluded" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{priceFetIncludedError}}</span>
                            </div>
                            <div class="col-md-2 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="packed" v-model="packed" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-1 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.packed_label}}</div>
                            </div>
                            <div class="col-md-1 col-sm-1 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.base_sales_price_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <input @input="vehicleSalesPriceError = ''" id="vehicleSalesPrice" v-model="vehicleSalesPrice" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{vehicleSalesPriceError}}</span>
                            </div>
                        </div>
                        <!-- PO('s) amount to add to Sales Price -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.po_amount_to_add_to_sales_price_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="poAmountToAddToSalesPriceError = ''" id="poAmountToAddToSalesPrice" v-model="poAmountToAddToSalesPrice" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{poAmountToAddToSalesPriceError}}</span>
                            </div>
                        </div>
                        <!-- Frontend Sales -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.frontend_sales_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="frontendSalesError = ''" id="frontendSales" v-model="frontendSales" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{frontendSalesError}}</span>
                            </div>
                        </div>
                        <!-- Trade Detail -->
                        <div class="col-md-12 forms-block">
                            <div class="col-12 paddingleft0">
                                <div class="book-deal-customer-block">Trade Detail</div>
                            </div>
                        </div>
                        <!-- Trade-In Ammount -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.trade_in_amount_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="tradeInAmountError = ''" id="tradeInAmount" v-model="tradeInAmount" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{tradeInAmountError}}</span>
                            </div>
                        </div>
                        <!-- Backend Sales -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.backend_sales_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="backendSalesError = ''" id="backendSales" v-model="backendSales" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{backendSalesError}}</span>
                            </div>
                        </div>
                        <!-- Dealer Fees -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.dealer_fees_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="dealerFeesError = ''" id="dealerFees" v-model="dealerFees" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{dealerFeesError}}</span>
                            </div>
                        </div>
                        <!-- Total Sales -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.total_sales_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="totalSalesError = ''" id="totalSales" v-model="totalSales" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{totalSalesError}}</span>
                            </div>
                        </div>
                        <!-- Other Charges -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.other_charges_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="otherChargesError = ''" id="otherCharges" v-model="otherCharges" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{otherChargesError}}</span>
                            </div>
                        </div>
                        <!-- Fet -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.fet_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="fetTradeError = ''" id="fetTrade" v-model="fetTrade" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{fetTradeError}}</span>
                            </div>
                        </div>
                        <!-- State Tax Amount -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.state_tax_amount_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.state_tax_rate_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <input @input="sateTaxRateError = ''" id="sateTaxRate" v-model="sateTaxRate" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{sateTaxRateError}}</span>
                            </div>
                            <div class="col-md-3 col-sm-3 paddingleft0">
                                <input @input="sateTaxAmountError = ''" id="sateTaxAmount" v-model="sateTaxAmount" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{sateTaxAmountError}}</span>
                            </div>
                            <div class="col-md-2 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="applySalesTax1" v-model="applySalesTax1" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-1 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.applied_sales_tax_label}}</div>
                            </div>
                        </div>
                        <!-- Warranty Tax Amount -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.warranty_tax_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.custom_tax_rate_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <input @input="customTaxRateError = ''" id="customTaxRate" v-model="customTaxRate" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{customTaxRateError}}</span>
                            </div>
                            <div class="col-md-3 col-sm-3 paddingleft0">
                                <input @input="customTaxAmountError = ''" id="customTaxAmount" v-model="customTaxAmount" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{customTaxAmountError}}</span>
                            </div>
                            <div class="col-md-2 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="applySalesTax2" v-model="applySalesTax2" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-1 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.applied_sales_tax_label}}</div>
                            </div>
                        </div>
                        <!-- Other Tax -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.other_tax_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="otherTaxError = ''" id="otherTax" v-model="otherTax" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{otherTaxError}}</span>
                            </div>
                        </div>
                        <!-- Total Cash Down -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.total_cash_down_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="totalCashDownError = ''" id="totalCashDown" v-model="totalCashDown" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{totalCashDownError}}</span>
                            </div>
                        </div>
                        <!-- Vehicle Net Price -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.vehicle_net_price_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="vehicleNetPriceError = ''" id="vehicleNetPrice" v-model="vehicleNetPrice" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{vehicleNetPriceError}}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!-- Commission Estimate -->
                        <div class="col-md-12 forms-block">
                            <div class="col-12 paddingleft0">
                                <div class="book-deal-customer-block">Commission Estimate</div>
                            </div>
                        </div>
                        <!-- Gross Profit -->
                        <div class="col-md-6 col-sm-6 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.gross_profit_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="grossProfitError = ''" id="grossProfit" v-model="grossProfit" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{grossProfitError}}</span>
                            </div>
                        </div>
                        <!-- (less holdback) -->
                        <div class="col-md-6 col-sm-6 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.less_holdback_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="lessHolbackError = ''" id="lessHolback" v-model="lessHolback" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{lessHolbackError}}</span>
                            </div>
                        </div>
                        <!-- Over Allowance -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.over_allowance_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="overAllowanceError = ''" id="overAllowance" v-model="overAllowance" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{overAllowanceError}}</span>
                            </div>
                        </div>
                        <!-- Commissionable Gross Profit -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commissionable_gross_profit_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.amt_excl_label}}</div>
                            </div>
                            <div class="col-md-3 col-sm-3 paddingleft0">
                                <input @input="amtExclError1 = ''" id="amtExcl1" v-model="amtExcl1" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{amtExclError1}}</span>
                            </div>
                            <div class="col-md-1 col-sm-1 paddingleft0">
                                <input @input="amtExclError2 = ''" id="amtExcl2" v-model="amtExcl2" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{amtExclError2}}</span>
                            </div>
                            <div class="col-md-4 col-sm-4 paddingleft0">
                                <input @input="amtExclError3 = ''" id="amtExcl3" v-model="amtExcl3" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{amtExclError3}}</span>
                            </div>
                        </div>
                        <!-- Commission -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commission_label}}</div>
                            </div>
                            <div class="col-md-2 col-sm-2 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commission_rate_label}}</div>
                            </div>
                            <div class="col-md-3 col-sm-3 paddingleft0">
                                <input @input="commissionRateError1 = ''" id="commissionRate1" v-model="commissionRate1" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{commissionRateError1}}</span>
                            </div>
                            <div class="col-md-1 col-sm-1 paddingleft0">
                                <input @input="commissionRateError2 = ''" id="commissionRate2" v-model="commissionRate2" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{commissionRateError2}}</span>
                            </div>
                            <div class="col-md-4 col-sm-4 paddingleft0">
                                <input @input="commissionRateError3 = ''" id="commissionRate3" v-model="commissionRate3" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{commissionRateError3}}</span>
                            </div>
                        </div>
                        <!-- Additional Commission -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.additional_commission_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="additionalCommissionError = ''" id="additionalCommission" v-model="additionalCommission" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{additionalCommissionError}}</span>
                            </div>
                        </div>
                        <!-- F&I Bonus for Salesperson -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.f_and_i_bonus_for_salesperson_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="fAndIBonusForSalespersonError = ''" id="fAndIBonusForSalesperson" v-model="fAndIBonusForSalesperson" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{fAndIBonusForSalespersonError}}</span>
                            </div>
                        </div>
                        <!-- Flat Commission Only -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.flat_commission_only_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="flatCommissionOnlyError = ''" id="flatCommissionOnly" v-model="flatCommissionOnly" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{flatCommissionOnlyError}}</span>
                            </div>
                        </div>
                        <!-- Total Commission -->
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.total_commission_label}}</div>
                            </div>
                            <div class="col-md-12 col-sm-12 paddingleft0">
                                <input @input="totalCommissionError = ''" id="totalCommission" v-model="totalCommission" class="form-control" type="text" maxlength="190"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{totalCommissionError}}</span>
                            </div>
                        </div>
                        <!-- Split Commission -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="splitCommision" v-model="splitCommision" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.split_commission_label}}</div>
                            </div>
                        </div>
                        <!-- No Commission -->
                        <div class="col-3 forms-block">
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <input id="noCommision" v-model="noCommision" type="checkbox"/>
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.no_commission_label}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'VehicleWorkSheet',
        components: {
            datePicker,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        props: {
            selectedOpportunityId : 0,
            editAssetId: 0,
        },
        data() {
            return {
                opportunityId: 0,
                staticInputLabelMessages: "",
                staticMessages: "",
                assetId: '',
                assetArr: [],
                isAssetRecordNotFound: false,
                assetError: "",
                unitId: '',
                unitArr: [],
                isUnitRecordNotFound: false,
                unitError: "",
                manufacturerId: '',
                manufacturerArr: [],
                isManufacturerRecordNotFound: false,
                manufacturerError: "",
                year: "",
                yearError: "",
                modelOEMId: '',
                modelOEMArr: [],
                isModelOEMRecordNotFound: false,
                modelOEMError: "",
                color: "",
                colorError: "",
                stockTypeId: "",
                stockTypeArr: [],
                stockTypeError: "",
                bodyType: "",
                bodyTypeError: "",
                typeId: "",
                typeArr: [],
                typeError: "",
                duty: "",
                dutyArr: [],
                dutyError: "",
                fuelTypeId: "",
                fuelTypeArr: [],
                fuelTypeError: "",
                stockOrder: "",
                stockOrderArr: [],
                stockOrderError: "",
                odometer: "",
                odometerError: "",
                paymentType:"MI",
                paymentTypeOption:[
                    {text: 'MI', value: 'MI'},
                    {text: 'KM', value: 'KM'},
                ],
                scaleWeight: "",
                scaleWeightError: "",
                vehicleDescription: "",
                vehicleDescriptionError: "",
                ofBatteries: "",
                ofBatteriesError: "",
                ofTires: "",
                ofTiresError: "",
                ofAxles: "",
                ofAxlesError: "",
                privateUse: false,
                businessUse: false,
                proposedDeliveryDate: "",
                proposedDeliveryDateError: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },
                gvwGCWR: "",
                gvwGCWRError: "",
                docPreparation: "",
                docPreparationError: "",
                customerSuppliedBody: "",
                customerSuppliedBodyError: "",
                licenseTitle: "",
                licenseTitleError: "",
                noLicense: false,
                customFee: false,
                tireFee: "",
                tireFeeError: "",
                registration: "",
                registrationError: "",
                flooring: "",
                flooringError: "",
                smogFeePaidToSeller: "",
                smogFeePaidToSellerError: "",
                tireCredit: "",
                tireCreditError: "",
                smogCertificationOrExemptionFeePaidToState: "",
                smogCertificationOrExemptionFeePaidToStateError: "",
                fetExemptItems: "",
                fetExemptItemsError: "",
                dmvElectronicFillingFee: "",
                dmvElectronicFillingFeeError: "",
                fet: "",
                fetError: "",
                fetExempt: false,
                customFet: false,
                ccttsOnlyAdminFee: "",
                ccttsOnlyAdminFeeError: "",
                financeReserve: "",
                financeReserveError: "",
                factoryPrice: "",
                factoryPriceError: "",
                fleetReserveCost: "",
                fleetReserveCostError: "",
                bodyCost: "",
                bodyCostError: "",
                adotPermit: "",
                adotPermitError: "",
                fleetReserveCtrl: "",
                fleetReserveCtrlError: "",
                holdDate: "",
                holdDateError: "",
                factoryInvDate: "",
                factoryInvDateError: "",

                // Vehicle Cost Calculation
                currentInventoryCostFromProcede: "",
                currentInventoryCostFromProcedeError: "",
                inventoryCostLastUpdated: "",
                inventoryCostLastUpdatedError: "",
                totalInternalLocalInstallationLessOutOfStateDeliveryCost: "",
                totalInternalLocalInstallationLessOutOfStateDeliveryCostError: "",
                costOfVehicleAndEquipmentIncludingFlooringCost: "",
                costOfVehicleAndEquipmentIncludingFlooringCostError: "",
                holdBackFixed: "",
                holdBackFixedError: "",
                percentage: "",
                percentageError: "",
                percentage1: "",
                percentageError1: "",
                grossProfitDollarAmountOrPercentage: "",
                grossProfitDollarAmountOrPercentageError: "",

                // Vehicle Price Calculation
                vehicleSalesPrice: "",
                vehicleSalesPriceError: "",
                priceFetIncluded: "",
                priceFetIncludedError: "",
                packed: false,
                poAmountToAddToSalesPrice: "",
                poAmountToAddToSalesPriceError: "",
                frontendSales: "",
                frontendSalesError: "",
                tradeInAmount: "",
                tradeInAmountError: "",
                backendSales: "",
                backendSalesError: "",
                dealerFees: "",
                dealerFeesError: "",
                totalSales: "",
                totalSalesError: "",
                otherCharges: "",
                otherChargesError: "",
                fetTrade: "",
                fetTradeError: "",
                sateTaxRate: "",
                sateTaxRateError: "",
                sateTaxAmount: "",
                sateTaxAmountError: "",
                applySalesTax1: false,
                customTaxRate: "",
                customTaxRateError: "",
                customTaxAmount: "",
                customTaxAmountError: "",
                applySalesTax2: false,
                otherTax: "",
                otherTaxError: "",
                totalCashDown: "",
                totalCashDownError: "",
                vehicleNetPrice: "",
                vehicleNetPriceError: "",
                grossProfitDollarAmountOrPercentage1: "",
                grossProfitDollarAmountOrPercentageError1: "",
                grossProfitDollarAmountOrPercentage2: "",
                grossProfitDollarAmountOrPercentageError2: "",
                
                // Commission Estimate
                grossProfit: "",
                grossProfitError: "",
                lessHolback: "",
                lessHolbackError: "",
                overAllowance: "",
                overAllowanceError: "",
                amtExcl1: "",
                amtExclError1: "",
                amtExcl2: "",
                amtExclError2: "",
                amtExcl3: "",
                amtExclError3: "",
                commissionRate1: "",
                commissionRateError1: "",
                commissionRate2: "",
                commissionRateError2: "",
                commissionRate3: "",
                commissionRateError3: "",
                additionalCommission: "",
                additionalCommissionError: "",
                fAndIBonusForSalesperson: "",
                fAndIBonusForSalespersonError: "",
                flatCommissionOnly: "",
                flatCommissionOnlyError: "",
                totalCommission: "",
                totalCommissionError: "",
                splitCommision: false,
                noCommision: false,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.opportunityId = self.selectedOpportunityId;
            if (typeof(self.editAssetId) != "undefined" && self.editAssetId != null && self.editAssetId != "" && self.editAssetId != 0) {
                self.getAssetDetailsById(self.editAssetId);
            }
        },
        methods: {
            closeWorkSheet: function() {
                // alert();
                EventBus.$emit("work-sheet-callback", 0, 0);
            },
            checkForm: function () {
                var checked = true;
                if (self.assetId == null || !self.assetId.value) {
                    self.assetError = "Please select Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            saveCloseWorkSheet: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        // console.log(self.opportunityId);return false;
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('asset_id', self.assetId.value);
                        data.append('opportunity_id', self.opportunityId);
                        HTTP.post("/vehicle/worksheet/opportunity/inventory/add/update", data, config)
                            .then(function (response) {
                                EventBus.$emit("work-sheet-callback", self.opportunityId, self.assetId.value);
                            })
                            .catch(function (err) {
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            getAssetDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/asset/get', config)
                        .then(response => {
                            self.assetId = {text: response.data.content.asset_data.vin_no, value: id};
                            // Unit Id
                            self.manufacturerId = {text: response.data.content.asset_data.make_name, value: response.data.content.asset_data.make_id};
                            self.year = response.data.content.asset_data.year;
                            self.modelOEMId = {text: response.data.content.asset_data.model_name_full, value: response.data.content.asset_data.mode_id};
                            // Color
                            self.stockTypeId = {text: self.formatString(response.data.content.asset_data.is_stock), value: response.data.content.asset_data.is_stock};
                            // self.bodyType = {text: response.data.content.asset_data.bodyTypeName, value: response.data.content.asset_data.body_type_id};
                            self.bodyType = response.data.content.asset_data.bodyTypeName;
                            self.typeId = {text: response.data.content.asset_data.condition_name, value: response.data.content.asset_data.condition_name};
                            // Duty
                            self.fuelTypeId = {text: response.data.content.asset_data.fuel_type, value: response.data.content.asset_data.fuel_type};
                            self.stockOrder = {text: self.formatString(response.data.content.asset_data.is_stock), value: response.data.content.asset_data.is_stock};
                            self.odometer = response.data.content.asset_data.odometer;
                            // MI / KM
                            // Scale Weight
                            self.vehicleDescription = response.data.content.asset_data.description;
                            // # of Batteries
                            // # of Tires
                            // # of Axles
                            // Private Use / Business Use
                            // Proposed Delivery Date
                            self.gvwGCWR = response.data.content.asset_data.gvw;
                            // Doc Preparation Fee
                            // Customer Supplied Body
                            // License/Title 
                            // No License / Custom Fee
                            // Tire Fee
                            // Registration
                            // Flooring
                            // Smog Fee Paid To Seller
                            // Tire Credit
                            // Smog Certification or Exemption Fee Paid to State
                            // FET Exempt Items
                            // DMV Electronic Filling Fee
                            // FET
                            // CCTTS Only - Admin Fee
                            // FET Exempt / Custom FET
                            // Finance Reserve
                            // Factory Price
                            // Fleet Reserve Cost
                            // Body Cost
                            // ADOT Permit#
                            // Fleet Reserve Ctrl#
                            // Hold Date
                            // Factory Inv. Date
                            // Current Inventory Cost From Procede
                            // Inventory Cost (Last Updated)
                            // Total Internal / Local Installation (Less Out of State Delivery Cost)
                            // Cost of Vehicle and Equipment (Including Flooring Cost)
                            // Holdback Fixed=
                            // Custom Holdback
                            // Percentage=
                            // Gross Profit Dollar amount or Percentage
                            // Price FET Included
                            // Packed
                            // Base Sales Price
                            // PO('s) amount to add to Sales Price
                            // Frontend Sales
                            // Trade-In Amount
                            // Backend Sales
                            // Dealer Fees
                            // Total Sales
                            // Other Charges
                            // FET
                            // State Tax Rate
                            // Apply Sales Tax
                            // Custom Tax Rate
                            // Apply Sales Tax
                            // Other Tax
                            // Total Cash Down
                            // Vehicle Net Price
                            // Gross Profit
                            // (less holdback)
                            // Over Allowance
                            // Amt Excl
                            // Commission Rate
                            // Additional Commission
                            // F&I Bonus for Salesperson
                            // Flat Commission Only
                            // Total Commission
                            // Split Commission / No Commission
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
            changeProposedDeliveryDate: function () {
                self.proposedDeliveryDateError = "";
            },
            changeHoldDate: function () {
                self.holdDateError = "";
            },
            changeFactoryInvDate: function () {
                self.factoryInvDateError = "";
            },
            onSearchUnit(search, loading) {
            },
            onSearchManufacturer(search, loading) {
            },
            onSearchModelOEM(search, loading) {
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
        },
    }
</script>