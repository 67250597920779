<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_update_finance_contract_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <div class="admin-page-content">
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="locationId" name="locationId"
                                        label="text"
                                        :filterable="false"
                                        :options="locationArr"
                                        @search="onSearchLocation"
                                        :clearable="true"
                                        :placeholder="allStaticMessages.location_custom_place_holder"
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isLocationRecordNotFound">
                                                {{allStaticMessages.location_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <!-- <span class="help is-danger">{{nameError}}</span> -->
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.external_contract_number}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="externalContractNumber" v-model="externalContractNumber" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contract_status}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="contractStatus"
                                        :options="contractStatusArr"
                                        name="contractStatus">
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contract_closing_date}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker v-model="contractClosingDate" :config="options" class="form-control" autocomplete="off" :placeholder="staticInputLabelMessages.date_place_holder" name="contractClosingDate">
                                    </date-picker>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_name}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerName" v-model="borrowerName" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contract_start_date}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker v-model="contractStartDate" :config="options" class="form-control" autocomplete="off" :placeholder="staticInputLabelMessages.date_place_holder" name="contractStartDate">
                                    </date-picker>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.last_rental_payment_due_date}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker v-model="lastRentalPaymentDueDate" :config="options" class="form-control" autocomplete="off" :placeholder="staticInputLabelMessages.date_place_holder" name="lastRentalPaymentDueDate">
                                    </date-picker>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.manufacture_year}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="manufactureYear" v-model="manufactureYear" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vehicle_condition}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="vehicleCondition" v-model="vehicleCondition" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asset_make_dsc}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="assetMakeDsc" v-model="assetMakeDsc" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asset_model_dsc}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="assetModelDsc" v-model="assetModelDsc" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vehicle_body_dsc}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="vehicleBodyDsc" v-model="vehicleBodyDsc" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vehicle_registration_number}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="vehicleRegistrationNumber" v-model="vehicleRegistrationNumber" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_number}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="vinNumber" v-model="vinNumber" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.daimler_guaranteed_buy_back}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="daimlerGuaranteedBuyBack" v-model="daimlerGuaranteedBuyBack" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.financed_amount}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="financedAmount" v-model="financedAmount" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.residual_amount}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="residualAmount" v-model="residualAmount" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.km_allowed_msr}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="kmAllowedMsr" v-model="kmAllowedMsr" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contract_term}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="contractTerm" v-model="contractTerm" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interest_rate}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="interestRate" v-model="interestRate" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.installment_amount}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="installmentAmount" v-model="installmentAmount" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fp_group_dsc}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="fpGroupDsc" v-model="fpGroupDsc" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.clicksell_proposal_number}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="clicksellProposalNumber" v-model="clicksellProposalNumber" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_address1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerAddress1" v-model="borrowerAddress1" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_address2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerAddress2" v-model="borrowerAddress2" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_suburb}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerSuburb" v-model="borrowerSuburb" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_state}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerState" v-model="borrowerState" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_postcode}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerPostcode" v-model="borrowerPostcode" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_email}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerEmail" v-model="borrowerEmail" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_mobile}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerMobile" v-model="borrowerMobile" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_phone}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerPhone" v-model="borrowerPhone" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.borrower_ah_phone}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="borrowerAhPhone" v-model="borrowerAhPhone" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_first_name}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorFirstName" v-model="guarantorFirstName" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_surname}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorSurname" v-model="guarantorSurname" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_address1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorAddress1" v-model="guarantorAddress1" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_address2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorAddress2" v-model="guarantorAddress2" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_suburb}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorSuburb" v-model="guarantorSuburb" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_state}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorState" v-model="guarantorState" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_postcode}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorPostcode" v-model="guarantorPostcode" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_email}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorEmail" v-model="guarantorEmail" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_mobile}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorMobile" v-model="guarantorMobile" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_phone}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorPhone" v-model="guarantorPhone" class="form-control" type="text"/>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.guarantor_ah_phone}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="guarantorAhPhone" v-model="guarantorAhPhone" class="form-control" type="text"/>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="updateFinanceContractBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="updateFinanceContract">
                            <input id="cancelFinanceContractBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'UpdateFinanceContract',
        mixins: [commonMixins],
        components: {
            datePicker
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                isEdit: false,
                locationArr: [],
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Edit Finance Contract",
                permissionNameArray : ['can_update_finance_contract_master'],
                isLocationRecordNotFound: false,
                externalContractNumber: null,
                contractStatus: null,
                locationId: null,
                contractClosingDate: null,
                borrowerName: null,
                contractStartDate: null,
                lastRentalPaymentDueDate: null,
                manufactureYear: null,
                vehicleCondition: null,
                assetMakeDsc: null,
                assetModelDsc: null,
                vehicleBodyDsc: null,
                vehicleRegistrationNumber: null,
                vinNumber: null,
                daimlerGuaranteedBuyBack: null,
                financedAmount: null,
                residualAmount: null,
                kmAllowedMsr: null,
                contractTerm: null,
                interestRate: null,
                installmentAmount: null,
                fpGroupDsc: null,
                clicksellProposalNumber: null,
                borrowerAddress1: null,
                borrowerAddress2: null,
                borrowerSuburb: null,
                borrowerState: null,
                borrowerPostcode: null,
                borrowerEmail: null,
                borrowerMobile: null,
                borrowerPhone: null,
                borrowerAhPhone: null,
                guarantorFirstName: null,
                guarantorSurname: null,
                guarantorAddress1: null,
                guarantorAddress2: null,
                guarantorSuburb: null,
                guarantorState: null,
                guarantorPostcode: null,
                guarantorEmail: null,
                guarantorMobile: null,
                guarantorPhone: null,
                guarantorAhPhone: null,
                options: {
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    }
                },
                contractStatusArr: [
                    // {
                    //     text: "Select All",
                    //     value: "all"
                    // },
                    {
                        text: "Current",
                        value: "current"
                    },
                    {
                        text: "Overdue",
                        value: "overdue"
                    },
                    {
                        text: "Collection Officer",
                        value: "collection_officer"
                    },
                    {
                        text: "Repossessed",
                        value: "repossessed"
                    },
                    {
                        text: "Early Payout",
                        value: "early_payout"
                    },
                    {
                        text: "Payout",
                        value: "payout"
                    },
                    {
                        text: "External Agent",
                        value: "external_agent"
                    },
                    {
                        text: "Early Payout - Sale of Asset",
                        value: "early_payout_sale_of_asset"
                    }
                ],
                cusId: null
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('financecontract-list');
            EventBus.$emit("menu-selection-changed", "financecontract-list");
            self.getLoginIdPermissions();
            $("#alertError").hide();
            self.isEdit = false;
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.isEdit = true;
                self.getFinanceContractDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
            }
            self.loadAllData(['Location'], false, self.loadMasterDataCallback, "undefined", '')
        },
        methods: {
            buttonBack() {
                self.$router.push({ name: 'ListFinanceContract'});
            },
            clearDataOnSaveAndContinue : function () {
                console.log('clear data on save')
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getFinanceContractDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id
                    }
                };
                HTTP.get('financecontract/get', config)
                    .then(response => {
                        console.log(response.data.content);
                        self.locationId = {text: response.data.content.dealer, value: response.data.content.location_id};
                        self.externalContractNumber = response.data.content.external_contract_number;
                        self.contractStatus = response.data.content.contract_status;
                        self.contractClosingDate = response.data.content.contract_closing_date;
                        self.borrowerName = response.data.content.borrower_name
                        self.contractStartDate = response.data.content.contract_start_date
                        self.lastRentalPaymentDueDate = response.data.content.last_rental_payment_due_date
                        self.manufactureYear = response.data.content.manufacture_year
                        self.vehicleCondition = response.data.content.vehicle_condition
                        self.assetMakeDsc = response.data.content.asset_make_dsc
                        self.assetModelDsc = response.data.content.asset_model_dsc
                        self.vehicleBodyDsc = response.data.content.vehicle_body_dsc
                        self.vehicleRegistrationNumber = response.data.content.vehicle_registration_number
                        self.vinNumber = response.data.content.asset_id
                        self.daimlerGuaranteedBuyBack = response.data.content.daimler_guaranteed_buy_back
                        self.financedAmount = response.data.content.financed_amount
                        self.residualAmount = response.data.content.residual_amount
                        self.kmAllowedMsr = response.data.content.km_allowed_msr
                        self.contractTerm = response.data.content.contract_term
                        self.interestRate = response.data.content.interest_rate
                        self.installmentAmount = response.data.content.installment_amount
                        self.fpGroupDsc = response.data.content.fp_group_dsc
                        self.clicksellProposalNumber = response.data.content.clicksell_proposal_number
                        self.borrowerAddress1 = response.data.content.borrower_address_1
                        self.borrowerAddress2 = response.data.content.borrower_address_2
                        self.borrowerSuburb = response.data.content.borrower_suburb
                        self.borrowerState = response.data.content.borrower_state
                        self.borrowerPostcode = response.data.content.borrower_postcode
                        self.borrowerEmail = response.data.content.borrower_email
                        self.borrowerMobile = response.data.content.borrower_mobile
                        self.borrowerPhone = response.data.content.borrower_phone
                        self.borrowerAhPhone = response.data.content.borrower_ah_phone
                        self.guarantorFirstName = response.data.content.guarantor_first_name
                        self.guarantorSurname = response.data.content.guarantor_surname
                        self.guarantorAddress1 = response.data.content.guarantor_address_1
                        self.guarantorAddress2 = response.data.content.guarantor_address_2
                        self.guarantorSuburb = response.data.content.guarantor_suburb
                        self.guarantorState = response.data.content.guarantor_state
                        self.guarantorPostcode = response.data.content.guarantor_postcode
                        self.guarantorEmail = response.data.content.guarantor_email
                        self.guarantorMobile = response.data.content.guarantor_mobile
                        self.guarantorPhone = response.data.content.guarantor_phone
                        self.guarantorAhPhone = response.data.content.guarantor_ah_phone
                        self.cusId = response.data.content.cus_id
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            updateFinanceContract (flag) {
                setTimeout(() => {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    var data = new FormData();
                    data.append('location_id', self.locationId.value);
                    data.append('external_contract_number', self.externalContractNumber);
                    data.append('contract_status', self.contractStatus);
                    data.append('contract_closing_date', self.contractClosingDate);
                    data.append('borrower_name', self.borrowerName);
                    data.append('contract_start_date', self.contractStartDate);
                    data.append('last_rental_payment_due_date', self.lastRentalPaymentDueDate);
                    data.append('manufacture_year', self.manufactureYear);
                    data.append('vehicle_condition', self.vehicleCondition);
                    data.append('asset_make_dsc', self.assetMakeDsc);
                    data.append('asset_model_dsc', self.assetModelDsc);
                    data.append('vehicle_body_dsc', self.vehicleBodyDsc);
                    data.append('vehicle_registration_number', self.vehicleRegistrationNumber);
                    data.append('asset_id', self.vinNumber);
                    data.append('daimler_guaranteed_buy_back', self.daimlerGuaranteedBuyBack);
                    data.append('financed_amount', self.financedAmount);
                    data.append('residual_amount', self.residualAmount);
                    data.append('km_allowed_msr', self.kmAllowedMsr);
                    data.append('contract_term', self.contractTerm);
                    data.append('interest_rate', self.interestRate);
                    data.append('installment_amount', self.installmentAmount);
                    data.append('fp_group_dsc', self.fpGroupDsc);
                    data.append('clicksell_proposal_number', self.clicksellProposalNumber);
                    data.append('borrower_address_1', self.borrowerAddress1);
                    data.append('borrower_address_2', self.borrowerAddress2);
                    data.append('borrower_suburb', self.borrowerSuburb);
                    data.append('borrower_state', self.borrowerState);
                    data.append('borrower_postcode', self.borrowerPostcode);
                    data.append('borrower_email', self.borrowerEmail);
                    data.append('borrower_mobile', self.borrowerMobile);
                    data.append('borrower_phone', self.borrowerPhone);
                    data.append('borrower_ah_phone', self.borrowerAhPhone);
                    data.append('guarantor_first_name', self.guarantorFirstName);
                    data.append('guarantor_surname', self.guarantorSurname);
                    data.append('guarantor_address_1', self.guarantorAddress1);
                    data.append('guarantor_address_2', self.guarantorAddress2);
                    data.append('guarantor_suburb', self.guarantorSuburb);
                    data.append('guarantor_state', self.guarantorState);
                    data.append('guarantor_postcode', self.guarantorPostcode);
                    data.append('guarantor_email', self.guarantorEmail);
                    data.append('guarantor_mobile', self.guarantorMobile);
                    data.append('guarantor_phone', self.guarantorPhone);
                    data.append('guarantor_ah_phone', self.guarantorAhPhone);
                    data.append('cus_id', self.cusId);
                    if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                        data.append('id',self.$route.params.id);
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                    } else {
                        if (flag == "Save and Continue") {
                            self.buttonNames.saveAndContinue = "Saving ...";
                        } else {
                            self.buttonNames.save = "Saving ...";
                        }
                        self.disableButtons.save = true;
                        self.disableButtons.saveAndContinue = true;
                        self.disableButtons.cancel = true;
                    }
                    HTTP.post("financecontract/update", data, config)
                        .then(function (response) {
                            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                            }
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message,'success',false,3);
                                if (flag == "Save and Continue") {
                                    self.clearDataOnSaveAndContinue();
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.buttonBack();
                                    }, 3000);
                                }
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    self.clearDataOnSaveAndContinue();
                                } else {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                }
                            }
                            self.catchHandler(err, function () {});
                        });
                }, 200);
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>