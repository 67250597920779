<template>
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_opportunity_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>

          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <template>
              <div class="row no-margin">
                <ul class="nav nav-tabs">
                  <li
                    class="nav-item"
                    v-if="
                      permissionNameArray.includes('can_add_opportunity_master')
                    "
                  >
                    <a
                      v-on:click="activetab = 0"
                      v-bind:class="
                        'nav-link ' + [activetab === 0 ? 'active' : '']
                      "
                      >View Opportunity</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      permissionNameArray.includes('can_add_activity_master')
                    "
                  >
                    <a
                      v-on:click="activetab = 1"
                      v-bind:class="{
                        'nav-link': true,
                        active: activetab === 1,
                      }"
                      >Activity</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      permissionNameArray.includes('can_list_deals') &&
                      productionCountry == 'US'
                    "
                  >
                    <a
                      v-on:click="activetab = 2"
                      v-bind:class="{
                        'nav-link': true,
                        active: activetab === 2,
                      }"
                      >Deals
                      <span class="badge badge-primary">{{
                        totalDeals
                      }}</span></a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      permissionNameArray.includes('can_list_deals') &&
                      productionCountry == 'US'
                    "
                  >
                    <a
                      v-on:click="activetab = 3"
                      v-bind:class="{
                        'nav-link': true,
                        active: activetab === 3,
                      }"
                      >Deals No. Overview
                      <span class="badge badge-primary">{{
                        totalDealNoOverview
                      }}</span></a
                    >
                  </li>
                </ul>
                <div
                  v-if="
                    activetab === 0 &&
                    permissionNameArray.includes('can_add_opportunity_master')
                  "
                  class="tabcontent"
                  id="customer-activity-logs0"
                >
                  <add-update-opportunity
                    :hasParent="hasParent"
                    :parentId="customerId"
                  ></add-update-opportunity>
                </div>
                <div
                  v-if="
                    activetab === 1 &&
                    permissionNameArray.includes('can_add_activity_master')
                  "
                  class="tabcontent"
                  id="customer-activity-logs1"
                >
                  <asset-crm
                    v-bind:opportunityMasterId="opportunityId"
                    v-bind:redirectUrl="redirectUrl"
                    v-bind:activetab="activetab"
                  ></asset-crm>
                </div>
                <div
                  v-if="
                    activetab === 2 &&
                    permissionNameArray.includes('can_list_deals')
                  "
                  class="tabcontent"
                  id="customer-activity-logs2"
                >
                  <list-opportunity-deals></list-opportunity-deals>
                </div>
                <div
                  v-if="
                    activetab === 3 &&
                    permissionNameArray.includes('can_list_deals')
                  "
                  class="tabcontent"
                  id="customer-activity-logs2"
                >
                  <deals-no-overview></deals-no-overview>
                </div>
              </div>
            </template>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
</template>

<script>
let self;
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import AddUpdateOpportunity from "./AddUpdateOpportunity";
import ListActivity from "@/components/Activity/ListActivity";
import ListDeals from "@/components/DealMaster/ListDeals";
import DealsNoOverview from "./DealsNoOverview.vue";

export default {
  props: ["redirectTo"],
  props: {
    oppId: 0,
  },
  name: "OpportunityTab",
  components: {
    "no-access": NoAccessPage,
    "add-update-opportunity": AddUpdateOpportunity,
    "asset-crm": ListActivity,
    "list-opportunity-deals": ListDeals,
    "deals-no-overview": DealsNoOverview,
  },
  mixins: [commonMixins],
  data() {
    return {
      activetab: 0,
      permissionNameArray: [
        "can_view_opportunity_master",
        "can_add_opportunity_master",
        "can_add_activity_master",
      ],
      redirectId: "",
      pageTitle: "Opportunity Tab",
      opportunityId: this.$route.params.id,
      redirectUrl: "opportunity/tab/" + this.$route.params.id,
      totalDeals: 0,
      totalDealNoOverview: 0,
      productionCountry: process.env.VUE_APP_PRODUCTION_COUNTRY,
      hasParent: false,
      customerId: "",
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    self.setActiveMenu("opportunity-list");
    /* Redirect to login page logic end */
    self.getLoginIdPermissions();
    if (self.productionCountry == "US") {
      self.getDealCount();
    }

    if (Object.keys(self.$route.params).includes("type")) {
      self.hasParent = true;
      self.customerId = self.$route.params.customer_id;
    }

    if (
      typeof self.$route.query.tab != "undefined" &&
      self.$route.query.tab != ""
    ) {
      self.activetab = parseInt(this.$route.query.tab);
    }
  },
  methods: {
    buttonBack() {
      localStorage.removeItem("opprtunityTabAddDeal");
      if (self.hasParent) {
        //TO REDIRECT BACK TO CUSTOMER TAB VIEW PAGE WITH CUSTOMER ID IF THE OPPORTUNITY IS VIEW FROM CUSTOMER TAB
        self.$router.push({
          name: self.$route.params.type,
          params: { id: self.$route.params.customer_id, opportunityTab: true },
        });
      } else {
        self.$router.push({ name: "ListOpportunity" });
      }
    },
    getDealCount() {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      let oppId = this.$route.params.id;
      HTTP.get("/opportunity/" + oppId + "/deal-count", config)
        .then(function (data) {
          if (data.data.code == 200) {
            self.totalDeals = data.data.content.total_deals;
            self.totalDealNoOverview = data.data.content.total_deal_no_overview;
          }
        })
        .catch(function (error) {
          self.catchHandler(err, function () {});
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
EventBus.$on("total-deal-count-on-delete", function () {
  if (typeof self != "undefined") {
    self.getDealCount();
  }
});
</script>
