<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_finance_service_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <div class="admin-page-content">
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contract_number}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="financeContractId" name="financeContractId" id="financeContractId" label="text" :filterable="false"
                                        :options="financeContractIdArr" @search="onSearchFinanceContract" @change="updateContractNumber"
                                        :placeholder="staticMessages.select_contract_number" @input="financeContractIdError = ''"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isFinanceContractRecordNotFound">
                                                {{ staticMessages.search_contract_nnumber }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{financeContractIdError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="inventory" v-model="inventory" class="form-control" type="text" maxlength="190" :disabled="true" />
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="customerName" v-model="customerName" class="form-control" type="text" maxlength="190" :disabled="true" />
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.assign_user_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="assignUserId" name="assignUserId" id="assignUserId" label="text" :filterable="false"
                                        :options="assignUserIdArr" @search="onSearchAssignUser" :placeholder="staticMessages.assign_user_place_holder"
                                        @input="assignUserIdError = ''"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssingUserRecordNotFound">
                                                {{ staticMessages.assign_used_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{assignUserIdError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.activity_time_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker v-model="activityDateTime" :config="options" class="form-control" autocomplete="off"
                                        :placeholder="staticMessages.activity_datetime_place_holder" name="activityDateTime"
                                        @input="activityDateTimeError = ''"
                                    >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{activityDateTimeError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.activity_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="type" name="type" id="type" label="text" :clearable="false" :searchable="false"
                                        :filterable="false" :options="typeArr" :placeholder="staticMessages.activity_type_place_holder"
                                        @input="typeError = ''"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{typeError}}</span>
                                </div>
                            </div>
                            <div v-show="isActivityTypeOther" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">
                                        {{ staticInputLabelMessages.other_type_text_label }}
                                    </div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="otherActivityTypeText" v-model="otherActivityTypeText" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{otherActivityTypeTextError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="priority" name="priority" label="text" :filterable="false" :options="priorityArr"
                                        :clearable="false" :placeholder="staticMessages.select_priority"
                                    >
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.direction_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="direction" name="direction" label="text" :filterable="false" :clearable="false"
                                        :options="directionArr" :placeholder="staticMessages.direction_place_holder" @input="directionError = ''"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{directionError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="subject" v-model="subject" class="form-control" type="text" maxlength="190" @input="subjectError = ''"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{subjectError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="status" name="status" id="status" label="text" :clearable="false" :searchable="false"
                                        :filterable="false" :options="statusArr" :placeholder="staticMessages.status_place_holder"
                                        @input="statusError = ''"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span>
                                </div>
                            </div>
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="notes" v-model="notes" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addFinanceServiceBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addFinanceService ()">
                            <input id="cancelFinanceServiceBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    import datePicker from "vue-bootstrap-datetimepicker";

    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'AddUpdateFinanceService',
        mixins: [commonMixins],
        components: {
            datePicker
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                isEdit: false,
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Finance Service",
                permissionNameArray : ['can_add_finance_service_master'],
                status: { value: "in progress", text: "In Progress" },
                statusArr: [
                    {
                        value: "in progress",
                        text: "In Progress"
                    },
                    {
                        value: "completed",
                        text: "Completed"
                    }
                ],
                subject: "",
                direction: { value: "outgoing", text: "Outgoing" },
                directionArr: [
                    {
                        value: "incoming",
                        text: "Incoming"
                    },
                    {
                        value: "outgoing",
                        text: "Outgoing"
                    }
                ],
                priority: { value: "medium", text: "Medium" },
                priorityArr: [
                    {
                        value: "high",
                        text: "High"
                    },
                    {
                        value: "medium",
                        text: "Medium"
                    },
                    {
                        value: "low",
                        text: "Low"
                    }
                ],
                type: { text: "Call", value: "call" },
                typeArr: [
                    {
                        text: "Call",
                        value: "call"
                    },
                    {
                        text: "Email",
                        value: "email"
                    },
                    {
                        text: "Other",
                        value: "other"
                    }
                ],
                activityDateTime: "",
                options: {
                    format: 'MM/DD/YYYY hh:ss a',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: "auto",
                        vertical: "bottom"
                    }
                },
                notes: "",
                assignUserId: "",
                assignUserIdArr: [],
                isAssingUserRecordNotFound: false,
                customerName: "",
                inventory: "",
                financeContractIdArr: [],
                isFinanceContractRecordNotFound: false,
                financeContractId: "",
                financeContractIdError: "",
                assignUserIdError: "",
                activityDateTimeError: "",
                typeError: "",
                directionError: "",
                subjectError: "",
                statusError: "",
                isActivityTypeOther: false,
                otherActivityTypeText: "",
                otherActivityTypeTextError: ""
            }
        },
        watch: {
            type: function(type) {
                self.isActivityTypeOther = false;
                if (type.value == "other") {
                    self.isActivityTypeOther = true;
                }
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('financeservice-list');
            EventBus.$emit("menu-selection-changed", "financeservice-list");
            self.getLoginIdPermissions();
            $("#alertError").hide();
            self.isEdit = false;
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.isEdit = true;
                self.getFinanceServiceDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Finance Service";
            }
            self.loadAllData(['FinanceContract'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            loadMasterDataCallback: function (callbackData) {
                self.isFinanceContractRecordNotFound = false;
                self.financeContractIdArr = callbackData.FinanceContract;
                if (self.financeContractIdArr.lenght == 0) {
                    self.isFinanceContractRecordNotFound = true;
                }
            },
            buttonBack() {
                self.$router.push({ name: 'ListFinanceService'});
            },
            clearDataOnSaveAndContinue : function () {
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getFinanceServiceDetailsById: function () {
                self.isActivityTypeOther = false;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('financeservice/get', config)
                    .then(response => {
                        if (response.data.content.type == "other") {
                            self.isActivityTypeOther = true;
                        }
                        if(response.data.content.finance_contract_id != null && response.data.content.finance_contract_id != '') {
                            self.financeContractId = { value: response.data.content.finance_contract_id, text: response.data.content.external_contract_number };
                        }
                        self.notes = response.data.content.notes;
                        self.customerName = response.data.content.customer_name;
                        self.inventory = response.data.content.inventory;
                        self.type = { text: self.formatString(response.data.content.type), value: response.data.content.type };
                        self.otherActivityTypeText = response.data.content.other_activity_type_text;
                        self.activityDateTime = response.data.content.activity_time;
                        self.priority = { text: self.formatString(response.data.content.priority), value: response.data.content.priority };
                        self.direction = { text: self.formatString(response.data.content.direction), value: response.data.content.direction };
                        if (typeof response.data.content.subject != "undefined" && response.data.content.subject != 0 && response.data.content.subject != "") {
                            self.subject = response.data.content.subject;
                        }
                        self.status = { text: self.formatString(response.data.content.status), value: response.data.content.status };
                        self.assignUserId = { text: response.data.content.assign_user_first_name + " " + response.data.content.assign_user_last_name, value: response.data.content.assign_user_id };
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.financeContractId == null || !self.financeContractId.value) {
                    self.financeContractIdError = "Please select contract number";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.assignUserId == null || !self.assignUserId.value) {
                    self.assignUserIdError = "Please select assign user";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.activityDateTime) {
                    self.activityDateTimeError = "Please select activity Date Time";
                    if (checked) {
                        checked = false;
                    }
                }
                self.otherActivityTypeTextError = "";
                if (self.type.value == "other") {
                    if (!self.otherActivityTypeText) {
                        self.otherActivityTypeTextError = "Please enter Other Activity type";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.otherActivityTypeText && self.otherActivityTypeText.length >= 191) {
                        self.otherActivityTypeTextError = "Please enter Other Activity type up to 190 characters only";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.subject) {
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.subject && self.subject.length >= 191) {
                    self.subjectError = "Please enter subject up to 190 characters only";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.status == null || !self.status.value) {
                    self.statusError = "Please select status";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addFinanceService (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append("finance_contract_id", self.financeContractId.value);
                        data.append('inventory', self.inventory);
                        data.append('customer_name', self.customerName);
                        data.append("assign_user_id", self.assignUserId.value);
                        // data.append("activity_date_time", self.activityDateTime.split("/").reverse().join("/"));
                        data.append("activity_date_time", self.activityDateTime);
                        data.append("notes", self.notes);
                        data.append("direction", self.direction.value);
                        data.append("priority", self.priority.value);
                        data.append("subject", self.subject);
                        data.append("status", self.status.value);
                        data.append("type", self.type.value);
                        data.append("other_activity_type_text", self.otherActivityTypeText);
                        data.append("browser_timezone", localStorage.getItem("browserTimeZone"));
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("financeservice/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            loadAssignUserCallback: function(callbackData) {
                self.isAssingUserRecordNotFound = false;
                self.assignUserIdArr = callbackData.User;
                if (self.assignUserIdArr.length == 0) {
                    self.isAssingUserRecordNotFound = true;
                }
            },
            onSearchAssignUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(["User"], true, self.loadAssignUserCallback, loading, search);
                }
            },
            onSearchFinanceContract () {
            },
            updateContractNumber (data) {
                if (!self.$route.params.id) {
                    self.customerName = data.customer_name
                    self.inventory = data.vin_no
                }
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>