<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template>
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <button v-show="permissionNameArray.includes('can_add_cal_vehicle_prices')" class="btn btn-primary waves-effect pull-right ml-2" @click="showForm()">Add Configuration</button>
                    </div>
                   
                    <div class="col-12 paddingnone">
                        <div class="row no-margin border">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <thead>
                                        <tr>
                                            <!-- <th>Manufacturer</th>
                                                <th>Model</th>
                                                <th>Tax Rates</th> -->
                                            <th>Action</th>
                                            <th>Vehicle Type</th>
                                            <!-- <th>Duty</th>
                                            <th>Hold Back Percentage</th> -->
                                            <th>Hold Back Fixed</th>
                                            <!-- <th>Tier One Rate</th>
                                            <th>Tier Two Rate</th> -->
                                            <th>Tier One Tax Amount</th>
                                            <th>Tier Two Tax Amount</th>
                                            <!-- <th>Fixed Commission</th>
                                            <th>Flat Commission</th>
                                            <th>Commission Gross Profit</th>
                                            <th>Calculated Commission</th>
                                            <th>Commission Next Level</th> -->
                                            <th>DMV Electronic Filling Fee</th>
                                            <th>License/Title</th>
                                            <!-- <th>Custom Pack</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="calVehiclePriceList && calVehiclePriceList.length > 0">
                                            <template v-for="data in calVehiclePriceList">
                                                <tr>
                                                    <!-- <td>{{data.manufacturer_id}}</td>
                                                        <td>{{data.model_id}}</td>
                                                        <td>{{data.tax_id}}</td> -->
                                                    <td>
                                                        <a v-on:click="editVehiclePrice(data)" title='Edit Vehicle' href="javascript:void(0)" class="mar-right-0">
                                                            <i aria-hidden="true" class="fa fa-edit"></i>
                                                        </a>
                                                        <!-- <a v-on:click="delete(vehicle.id)" title='Delete Vehicle' href="javascript:void(0)">
                                                            <i aria-hidden="true" class="fa fa-trash-o text-danger"></i>
                                                        </a> -->
                                                    </td>
                                                    <td>{{capitalize(data.is_used)}}</td>
                                                    <!-- <td>{{capitalize(data.duty)}}</td>
                                                    <td>{{data.hold_back_percentage}}</td> -->
                                                    <td>{{data.hold_back_fixed}}</td>
                                                    <!-- <td>{{data.tier_one_rate}}</td>
                                                    <td>{{data.tier_two_rate}}</td> -->
                                                    <td>{{data.tn_tier_one_tax_amt}}</td>
                                                    <td>{{data.tn_tier_two_tax_amt}}</td>
                                                    <!-- <td>{{data.fixed_commission}}</td>
                                                    <td>{{data.flat_commission}}</td>
                                                    <td>{{data.commission_gross_profit_per}}</td>
                                                    <td>{{data.calculated_commission_per}}</td>
                                                    <td>{{data.commission_next_level}}</td> -->
                                                    <td>{{data.dmv_electronic_filling_fee}}</td>
                                                    <td>{{data.license_title}}</td>
                                                    <!-- <td><input v-model="data.custom_pack" type="checkbox" disabled /></td> -->
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <th colspan="15" class="text-center">No Data</th>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isShowForm">
                    <add-vehicle-price :editData="editData"></add-vehicle-price>
                </div>
            </div>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../../mixins/commonMixins';
    import EventBus from "../../../event-bus";
    import {HTTP} from '../../../../http-common';
    import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
    import AddVehiclePricesModal from "@/components/AssetMaster/NewTransactionSheet/LocationSetDefaultTabs/AddVehiclePricesModal";

    Vue.component("app-footer", Footer);

    export default {
        name: 'ListStaticVehiclePrice',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
            'add-vehicle-price': AddVehiclePricesModal
        },
        data () {
            return {
                calVehiclePriceList: [],
                permissionNameArray: ['can_list_cal_vehicle_prices','can_add_cal_vehicle_prices'],
                isShowForm: false,
                editData: {},
                modalInstance: null,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            self.getData();
            self.initializeModals();
        },
        methods: {
            initializeModals() {
                self.modalInstance = new bootstrap.Modal(
                    document.getElementById("cal-vehicle-price-form"),
                    {
                        backdrop: "static",
                        keyboard: false,
                    }
                );
            },
            getData() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id,
                        type: 'vehicle_price'
                    }
                };
                HTTP.get('/get-location-static-data', config)
                    .then(response => {
                        self.calVehiclePriceList = response.data.content.datas.cal_vehicle_prices;
                        /*##### NOT REQUIRED BECAUSE THESE FIELD ARE DISABLED FOR NOW #####
                            var selectDatas = {
                                manufacturerList: response.data.content.manufacturerList,
                                taxList: response.data.content.taxList,
                                modelList: response.data.content.modelList,
                            }
                            EventBus.$emit("select-field-data", selectDatas);
                        */
                    });
            },
            showForm() {
                self.modalInstance.show();
                self.isShowForm = true;
                self.editData = {};
            },
            capitalize(value) {
                return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            editVehiclePrice(data) {
                self.modalInstance.show();
                self.isShowForm = true;
                self.editData = data;
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof this != "undefined") {
            this.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                this.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("cal-vehicle-price-list-data",function (listData){
        if (typeof self != "undefined"){
            self.calVehiclePriceList = listData;
            self.modalInstance.hide();
        }
    });

    EventBus.$on("close-vehicle-price-default", function (status) {
        if (typeof self != "undefined") {
            self.isShowForm = status;
            self.editData = {};
            self.modalInstance.hide();
        }
    });
    
</script>