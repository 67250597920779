<template>
    <div class="admin-footer" id="main-footer">
        <div class="page-loader-wrapper loading-container" style="display: none;">
            <div class="loading">
                <img src="/static/images/loading.gif">
            </div>
        </div>
        © {{system_year}} Velocity Vehicle Group Deal Manager. All Rights Reserved. <b>{{ tag }}</b>
    </div>
</template>
<script>
    import Vue from 'vue';
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from '../event-bus';
    import moment from 'moment';
    import {HTTP} from '../../http-common';

    var self;
    export default {
        name: 'Footer',
        mixins: [commonMixins],
        components: {

        },
        data() {
            return {
                system_year: '2018',
                tag:'',
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            self = this;

            var date = new Date();
            self.system_year = moment(date).format('YYYY');

            var userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                EventBus.$emit("login-user-info", true, userJson["email"], userJson["first_name"], userJson["last_name"]);
            }
            /* var path = window.location.pathname;
            var pathArr = path.split("/");

            var dashboardExp = /^dashboard$/;
            var workExp = /^workrequest$/;
            var orderApprovalExp = /^orderapproval$/;

            setTimeout(function () {
                $("#menu li").removeClass("active")

                if (dashboardExp.test(pathArr[1])) {
                    $("#dashboardLink").addClass('active');
                }
            },500); */
        },
        methods: {
            getSystemYear() {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };
                HTTP.get("get/systemyear", config)
                    .then(function (data) {

                        if (data.status == 200) {
                            self.system_year = data.data.content.year;
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build =  data.data.content.build;
                            if(current_build != undefined && current_build == ''){
                                localStorage.setItem("build_version", data.data.content.build);
                            }else if(current_build != latest_build){
                                localStorage.setItem("build_version", data.data.content.build);
                                location.reload(true);
                            }
                        }
                    })
                    .catch(function (error) {
                        
                        if (error.response.status == 0) {
                            self.error_msg_login = "Remote server can not be reachable";
                        } else {
                            if (error.response.data.message) {
                                self.error_msg_login = error.response.data.message;
                            }
                        }
                    })
            }
        }
    }
    EventBus.$on("system-year", function(year) {
        if (typeof self != "undefined") {
            self.system_year = year;
        }
    });
    EventBus.$on("build-tag", function(build_tag) {
        if (typeof self != "undefined") {
            self.tag = build_tag;
        }
    });
</script>