<template>
    <div id="page-content-wrapper" class="admin-content-area">
      <template v-if="permissionNameArray.includes('can_list_deals')">
        <div class="container-fluid xyz">
          <div class="spaced-container">
            <div class="col-12 page_title paddingnone">
              <div class="page_title_left">
                <span class="pl-0">Deals</span>
                <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
              </div>
              <router-link
                v-if="
                  $route.name == 'OpportunityTab' &&
                  permissionNameArray.includes('can_add_deals') &&
                  productionCountry == 'US' &&
                  Object.keys(oppAddDeal).length > 0
                "
                class="btn btn-primary waves-effect pull-right ml-2"
                title="Add Deal"
                :to="{
                  name: 'NewTransactionSheet',
                  params: {
                    opportunityId: oppAddDeal.id,
                    referenceNo: oppAddDeal.reference_no,
                    permissionNameArray: permissionNameArray,
                    opportunityStatus: oppAddDeal.status,
                  },
                }"
              >
                <span>Add Deal</span>
              </router-link>
              <a
                id="refreshList"
                title="Refresh"
                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                v-on:click.prevent="refreshList()"
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
              </a>
              <button
                id="dealVehicleHeaderList"
                title="Deal Vehicle Header List"
                class="btn btn-primary waves-effect pull-right ml-2"
                v-on:click.prevent="openReorderHeadersListModal(
                  'Select Deal Vehicle Header List', //Modal Title
                  'list', //Action
                  'listDeal', //Module Name
                  dealVehicleHeadersList //Master Headers List
                )"
              >
                {{ allLabelMessages.deal_vehicle_header_list }}
            </button>
            </div>
            <filter-component
              :key="filterCount"
              :filterCounts="filterCount"
              :parentHeaders="filterHeaders"
              @applyFilterParentComponent="applyFilterParentComponent"
              @resetSorting="resetSorting"
            ></filter-component>
            <div
              class="col-12 paddingnone list-common-actions"
              v-if="selectedRows.length >= 1"
            >
              <span class="color-cc9636"
                >{{ selectedRows.length }} rows selected
                <a class="color-098de1" v-on:click.prevent="clearSelection()"
                  >X</a
                ></span
              >
              <button
                v-show="permissionNameArray.includes('can_delete_deals')"
                type="button"
                v-on:click.prevent="deleteDeal()"
                class="btn btn-primary waves-effect pull-right ml-2"
              >
                Delete {{ selectedRows.length }} Record(s)
              </button>
            </div>
            <div class="col-12 paddingnone">
              <!-- Pagination Start -->
              <common-loader
                refId="commonLoaderListDeals"
                ref="commonLoaderListDeals"
              >
              </common-loader>
              <div class="top_pagination vgt-wrap__footer crm">
                <div class="row">
                  <div class="col-sm-12 col-md-2 PerPage">
                    <v-select
                      title="Select Per Page"
                      v-model="perPage"
                      @input="changePerPage"
                      name="customPerPage"
                      id="customPerPage"
                      label="text"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :options="paginationDropDownArr"
                      placeholder="Select Per Page"
                    >
                    </v-select>
                  </div>
                  <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                    <p class="pageRowCount">
                      {{ startEndRecord }} of {{ totalRecords }}
                    </p>
                  </div>

                  <div class="col-sm-12 col-md-6 Pagination">
                    <b-pagination
                      v-if="totalRecords > 0"
                      align="center"
                      :limit="5"
                      prev-text="‹ Previous"
                      next-text="Next ›"
                      :total-rows="totalRecords"
                      v-model="currentPage"
                      @input="changepage()"
                      :per-page="perPageValue"
                    ></b-pagination>
                  </div>
  
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <p class="pageJumpLabel">Page :</p>
                  </div>
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <input
                      v-model="jumpToPage"
                      type="text"
                      class="form-control form-control-sm"
                      name="jumpToPage"
                      id="jumpToPage"
                      v-on:keyup.enter="keyMonitor()"
                      placeholder="Page"
                    />
                  </div>
                </div>
              </div>
              <!-- Pagination End -->
              <div
                class="table-listing white-bg border-blue border-radius-top-right"
              >
                <table class="table custom-table jsmartable">
                  <tbody>
                    <template v-if="dealArr && dealArr.length > 0">
                      <template v-for="(deal, index) in dealArr">
                        <tr :key="index">
                          <td class="">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                v-model="selectedRows"
                                :value="deal.id"
                                @change="inputCheckbox($event)"
                                class="custom-control-input"
                                :id="'customCheck' + deal.id"
                                name="case[]"
                              />
                              <label
                                class="custom-control-label"
                                :for="'customCheck' + deal.id"
                              ></label>
                            </div>
                          </td>
                          <td v-show="actionDisplay">
                            <div class="actions">
                              <a
                                v-show="
                                  permissionNameArray.includes('can_add_deals')
                                "
                                v-on:click="editDeal(deal.deal_id)"
                                title="Edit Deal "
                                href="javascript:void(0)"
                                class="mar-right-0"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa fa-angle-right forward_icon"
                                ></i>
                              </a>
                              <a
                                v-show="
                                  permissionNameArray.includes('can_delete_deals')
                                "
                                v-on:click="deleteDeal(deal.id)"
                                title="Delete Deal"
                                href="javascript:void(0)"
                                class="mar-right-0"
                              >
                                <i
                                  class="exceptEdit fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </td>
                          <template
                            v-if="dealVehicleDataArr && dealVehicleDataArr.length > 0"
                          >
                            <template
                              v-for="(dealVehicleData, dealDataIndex) in dealVehicleDataArr"
                            >
                              <template v-if="index == dealDataIndex">
                                <template v-for="(data, dataIndex) in dealVehicleData">
                                  <template
                                    v-for="(headerData, headerIndex) in headers"
                                  >
                                    <template
                                      v-if="headerData.column_name == dataIndex"
                                    >
                                      <td
                                        :class="headerData.column_class"
                                        :key="dataIndex"
                                      >
                                        {{ data }}
                                      </td>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </tr>
                      </template>
                    </template>
                    <template v-else-if="oppDealArr && oppDealArr.length > 0">
                      <template v-for="(deal, index) in oppDealArr">
                        <tr :key="index">
                          <td class="">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                v-model="selectedRows"
                                :value="deal.id"
                                @change="inputCheckbox($event)"
                                class="custom-control-input"
                                :id="'customCheck' + deal.id"
                                name="case[]"
                              />
                              <label
                                class="custom-control-label"
                                :for="'customCheck' + deal.id"
                              ></label>
                            </div>
                          </td>
                          <td v-show="actionDisplay">
                            <div class="actions">
                              <a
                                v-show="
                                  permissionNameArray.includes('can_add_deals')
                                "
                                v-on:click="editDeal(deal.deal_id)"
                                title="Edit Deal "
                                href="javascript:void(0)"
                                class="mar-right-0"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa fa-angle-right forward_icon"
                                ></i>
                              </a>
                            </div>
                          </td>
                          <td
                            :class="rowClass.opportunity_reference_no"
                            v-if="$route.name == 'AssetTabView'"
                          >
                            {{ deal.opportunity_reference_no }}
                          </td>
                          <td :class="rowClass.deal_no">{{ deal.deal_no }}</td>
                          <td :class="rowClass.stock_no">{{ deal.stock_no }}</td>
                          <td :class="rowClass.vin">{{ deal.vin }}</td>
                          <td :class="rowClass.year">{{ deal.year }}</td>
                          <td :class="rowClass.make">{{ deal.make }}</td>
                          <td :class="rowClass.model">{{ deal.model }}</td>
                          <td :class="rowClass.deal_step">
                            {{ deal.deal_step }}
                          </td>
                          <td
                            :class="rowClass.delivered"
                            style="text-transform: capitalize"
                          >
                            {{ deal.delivered }}
                          </td>
                          <td :class="rowClass.creation_date">
                            {{ deal.creation_date }}
                          </td>
                          <td
                            :class="rowClass.customer"
                            v-if="$route.name == 'AssetTabView'"
                          >
                            {{ deal.customer }}
                          </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td
                          :colspan="headers.length + 1"
                          class="text-alignment-center"
                        >
                          {{ allStaticMessages.data_not_available }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <thead>
                    <tr>
                      <th class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAll"
                            type="checkbox"
                            class="custom-control-input"
                            id="selectAllCheckBox"
                            name="example1"
                            @change="clickHeaderCheckBox()"
                          />
                          <label
                            class="custom-control-label"
                            for="selectAllCheckBox"
                          ></label>
                        </div>
                      </th>
                      <template v-for="(data, headerIndex) in headers">
                        <th
                          class=""
                          :key="headerIndex"
                          v-if="data.name == 'Action'"
                          data-breakpoint="xs"
                        >
                          {{ data.name }}
                        </th>
                      </template>
                      <template v-for="(data, headerIndex) in headers">
                        <th
                          :class="data.column_full_class + ' column-header'"
                          v-if="data.name != 'Action'"
                          :key="headerIndex"
                          v-on:click.prevent="
                            sortTableData(data.column_name, data.sort)
                          "
                        >
                          {{ data.name }}
                        </th>
                      </template>
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- Pagination Start -->
              <div class="bottom_pagination vgt-wrap__footer crm">
                <div class="row">
                  <div class="col-sm-12 col-md-2 PerPage">
                    <v-select
                      title="Select Per Page"
                      v-model="perPage"
                      @input="changePerPage"
                      name="customPerPage"
                      id="customPerPage"
                      label="text"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :options="paginationDropDownArr"
                      placeholder="Select Per Page"
                    >
                    </v-select>
                  </div>
                  <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                    <p class="pageRowCount">
                      {{ startEndRecord }} of {{ totalRecords }}
                    </p>
                  </div>

                  <div class="col-sm-12 col-md-6 Pagination">
                    <b-pagination
                      v-if="totalRecords > 0"
                      align="center"
                      :limit="5"
                      prev-text="‹ Previous"
                      next-text="Next ›"
                      :total-rows="totalRecords"
                      v-model="currentPage"
                      @input="changepage()"
                      :per-page="perPageValue"
                    ></b-pagination>
                  </div>
  
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <p class="pageJumpLabel">Page :</p>
                  </div>
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <input
                      v-model="jumpToPage"
                      type="text"
                      class="form-control form-control-sm"
                      name="jumpToPage"
                      id="jumpToPage"
                      v-on:keyup.enter="keyMonitor()"
                      placeholder="Page"
                    />
                  </div>
                </div>
              </div>
              <!-- Pagination End -->
            </div>
          </div>
          <!-- List Headers Modal -->
          <reorder-headers-list-modal 
            @call-reload-list="loadDeal"
            ref="reorderHeadersListModalRef"
          >
          </reorder-headers-list-modal>
        </div>
      </template>
      <template v-else>
        <no-access></no-access>
      </template>
      <app-footer></app-footer>
    </div>
  </template>
  
  <script>
  var self;
  var filterColumn = "id";
  var filterType = "desc";
  import Vue from "vue";
  import vSelect from "vue-select";
  import Footer from "@/components/partials/Footer";
  import { commonMixins } from "../../mixins/commonMixins";
  import EventBus from ".././event-bus";
  import { HTTP } from "../../http-common";
  import FilterComponent from "../FilterComponent/FilterComponent";
  import NoAccessPage from "../NoAccessPage/NoAccessPage";
  import CommonLoader from "@/components/partials/CommonLoader.vue";
  import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";
  
  Vue.component("app-footer", Footer);
  Vue.component("v-select", vSelect);
  
  export default {
    name: "ListDeals",
    mixins: [commonMixins],
    components: {
      "filter-component": FilterComponent,
      "no-access": NoAccessPage,
      "common-loader": CommonLoader,
      "reorder-headers-list-modal": ReorderHeadersListModal,
    },
    data() {
      return {
        perPageValue: 25,
        perPage: 25,
        currentPage: 1,
        totalRecords: 0,
        paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
        dealArr: [],
        headers: [],
        filterHeaders: [],
        filters: {},
        appliedHeaders: [],
        jumpToPage: 1,
        columnName: filterColumn,
        sortType: filterType,
        selectedRows: [],
        startEndRecord: "",
        selectAll: false,
        permissionNameArray: ["can_list_deals"],
        actionDisplay: true,
        jumpToHistoryPage: 1,
        filterCount: 0,
        oppDealArr: [],
        oppAddDeal: {},
        dealVehicleDataArr: [],
        dealVehicleHeadersList: [],
        selectedDealVehicleHeadersList: [],
        isSelectAllDealVehicleHeaderList: false,
      };
    },
    beforeMount() {
      self = this;
    },
    mounted: function () {
      /* Redirect to login page logic start */
      var userObj = localStorage.getItem("user_obj");
      if (userObj == null) {
        self.$router.push("/");
      }
      /* Redirect to login page logic end */
      self.setActiveMenu("deals-list");
      EventBus.$emit("menu-selection-changed", "deals-list");
      let listHistoryData = self.loadFilters("listDeal", self);
      self.jumpToHistoryPage = !listHistoryData ? 1 : listHistoryData.page;
      let headersNames = [];
      headersNames["filters"] = self.filters;
      self.listExecutionState = false;
      self.loadDeal(1);
      self.getLoginIdPermissions();
    },
    methods: {
      editDeal: function (id) {
          /**
           * REMOVE VEHICLE WORKSHEET PAGE OF CURRENT EDIT DEAL FROM VehicleWorksheetRefresh localStorage
           */
          let currentVhcRefreshList = JSON.parse(localStorage.getItem('VehicleWorksheetRefresh'));
          if(currentVhcRefreshList && currentVhcRefreshList.length > 0) {
            const vhcRefreshList = currentVhcRefreshList.filter(item => {
              return item.dealId !== id;
            });
            localStorage.setItem('VehicleWorksheetRefresh', JSON.stringify(vhcRefreshList));
          }
          /** END */
          let routeData = self.$router.resolve({
            name: "NewTransactionSheet",
            query: {deal_id: id },
          });
          window.open(routeData.href, "_self").focus();
      },
      resetSorting: function () {
        self.columnName = filterColumn;
        self.sortType = filterType;
        self.changepage();
      },
      changePerPage: function () {
        self.currentPage = 1;
        if (self.perPage !== "All") {
          self.perPageValue = self.perPage;
        }
        self.loadDeal();
      },
      changepage: function () {
        if (!self.listExecutionState) {
          self.loadDeal();
        }
      },
      refreshList: function () {
        self.jumpToPage = 1;
        self.keyMonitor();
        self.loadDeal();
      },
      clearSelection: function () {
        self.selectAll = false;
        self.selectedRows = [];
      },
      keyMonitor: function () {
        var tempNumber = self.jumpToInputPage(
          self.jumpToPage,
          self.totalRecords,
          self.perPage
        );
        self.currentPage = self.jumpToPage = parseInt(tempNumber);
      },
      clickHeaderCheckBox: function () {
        setTimeout(function () {
          self.selectedRows = [];
          if (self.selectAll) {
            for (let i in self.dealArr) {
              self.selectedRows.push(self.dealArr[i].id);
            }
          }
        }, 100);
      },
      inputCheckbox: function (e) {
        setTimeout(function () {
          self.selectAll = false;
          if (self.selectedRows.length == self.dealArr.length) {
            self.selectAll = true;
          }
        }, 100);
      },
      applyFilterParentComponent: function (data) {
        let key;
        let value;
        for (let i = 0; i < data.length; i++) {
          if (data[i]["column_name"] != "null") {
            key = data[i]["column_name"];
            value = data[i]["field"];
            self.filters[key] = value;
          }
        }
        self.appliedHeaders = data;
        self.loadDeal();
      },
      setAlreadyAppliedFilters: function () {
        if (self.appliedHeaders.length > 0) {
          self.filterHeaders = self.appliedHeaders;
        }
      },
      deleteDeal: function (id) {
        let deleteArray = [];
        id =
          id === null || typeof id === "undefined" || id == "" || id == 0
            ? 0
            : id;
        if (id != 0) {
          deleteArray.push(id);
        } else {
          deleteArray = self.selectedRows;
        }
        self
          .$swal({
            title: "",
            text: "Are you sure that you want to delete this Deal!",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            // showCloseButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data: {
                  deleteArr: deleteArray,
                  flag : 'inventory'
                },
              };
              HTTP.delete("/deal/delete", config)
                .then(function (response) {
                  if (response.data.code == "200") {
                    self.loadDeal();
                  }
                })
                .catch(function (error) {
                  console.log("ErrorList!!! 364", error);
                  self.catchHandler(error, function () {});
                });
            }
          });
      },
      loadDeal: function (first=0) {
        self.listExecutionState = true;
        if (
          self.perPage == "All" &&
          self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
        ) {
          self.showSAlert(
            self.allStaticMessages.too_many_data_for_all_list,
            "info",
            false
          );
          self.perPage = 500;
          self.loadDeal();
        }
        self.selectedDealVehicleHeadersList = self.loadListHeaders("listDeal", self);
        self.selectedDealVehicleHeadersList =
          self.selectedDealVehicleHeadersList === null ||
          typeof self.selectedDealVehicleHeadersList === "undefined" ||
          self.selectedDealVehicleHeadersList == ""
            ? []
            : self.selectedDealVehicleHeadersList;
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const fields = {
          filters: self.filters,
          per_page: self.perPage,
          page: self.currentPage,
          sortColumnName: self.columnName,
          sortType: self.sortType,
          selected_deal_vehicle_header: self.selectedDealVehicleHeadersList,
        };
        $("#commonLoaderListDeals").find(".loading-container.lodingTable").show();
        self.filterCount = self.filterCountFunction(self.filters);
        self.storeFilters("listDeal", fields, false);
        HTTP.post("deal/vehicle/list", fields, config)
          .then(function (data) {
            self.listExecutionState = false;
            if (first) {
              self.currentPage = self.jumpToHistoryPage;
            }
            if (data.status == 200) {
              $("#commonLoaderListDeals")
              .find(".loading-container.lodingTable")
              .hide();
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadDeal();
                }, seconds);
              }
              if (
                self.$route.params.id !== undefined &&
                self.$route.params.id !== null
              ) {
                self.oppDealArr = data.data.content.data;
              } else {
                self.dealArr = data.data.content.data;
              }
              $("#selectAllCheckBox").prop("checked", false);
              self.dealVehicleDataArr = data.data.content.dealVehicleListData;
              self.headers = data.data.content.headers;
              self.dealVehicleHeadersList = data.data.content.dealVehicleHeadersList
              if ( 
                self.selectedDealVehicleHeadersList.length ==
                self.dealVehicleHeadersList.length
              ) {
                self.isSelectAllDealVehicleHeaderList = true;
              }
              self.filterHeaders = data.data.content.filterHeaders;
              self.loadFilterInputs(data.data.content.filterHeaders, self.filters);
  
              self.addColumnClass(self.headers);
  
              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
  
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          })
          .catch(function (err) {
            self.listExecutionState = false;
            $("#commonLoaderListDeals")
              .find(".loading-container.lodingTable")
              .hide();
            self.catchHandler(err, function () {});
          });
      },
      sortTableData: function (name, isSortAllowed) {
        if (isSortAllowed == "nosort") {
          if (self.columnName == name) {
            self.sortType = self.sortType == "desc" ? "asc" : "desc";
          } else {
            self.sortType = "asc";
          }
          self.columnName = name;
          self.loadDeal();
        }
      },
    },
  };
  EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
      self.permissionNameArray = [];
      for (let i = 0; i < permissions.length; i++) {
        self.permissionNameArray.push(permissions[i].permission_name);
      }
  
      if (
        !self.permissionNameArray.includes("can_list_deals") &&
        !self.permissionNameArray.includes("can_delete_deals")
      ) {
        self.headers.pop();
        self.actionDisplay = false;
      }
    }
  });
  </script>
  