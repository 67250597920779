<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_service_invoice_customer_master')">
            <template v-if="displayPage==1">
                <div class="container-fluid xyz">
                    <div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                        
                            <div class="page_title_left">
                                <span class="pl-0">Services</span>
                                <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
                            </div>
                            <!-- <a :class="[syncFromExcedeButtonDisabled ? 'disabled ' : '', 'extraFeature btn btn-primary waves-effect pull-right ml-2']"  v-on:click.prevent="syncContactSvsls()">
                                <span>Sync from EXCEDE <br/> <small v-show="syncFromExcedeButtonDisabled">{{hoverTextsyncFromExcedeButton}}</small></span>
                            </a> -->
                            
                        </div>
                        <div class="col-12 paddingnone p-1 pb-2">
                            <div class="col-md-4 paddingnone" >
                                <div class="card">
                                    <div class="card-header bg-primary">Total Invoice Amount: <span class="float-right">{{summary.total_business}}</span></div>
                                </div>

                                <!-- <span>Total Business</span>
                                <span>{{summary.total_business}}</span> -->
                            </div>
                        </div>

                        
                        
                        <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                            
                        <div class="col-12 paddingnone">
                            <common-loader
                                refId="commonLoaderListServiceInvoice"
                                ref="commonLoaderListServiceInvoice"
                            >
                            </common-loader>
                            <!-- Pagination Start -->
                            <div class="top_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                            
                            <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="invoiceArr && invoiceArr.length > 0">
                                        <template v-for="(invoice, index) in invoiceArr">
                                            <tr :key="index">
                                                <!-- <td class="">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" v-model="selectedRows" :value="invoice.sls_id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + invoice.sls_id" name="case[]">
                                                        <label class="custom-control-label" :for="'customCheck' + invoice.sls_id"></label>
                                                    </div>
                                                </td> -->
                                                <td >
                                                    <div class="actions">
                                                        <a href="javascript:void(0)" class="mar-right-0" title='View Service Operations' v-on:click.prevent="getSvslsOps(invoice.vin,invoice.sls_id,invoice.make_name,invoice.model_name,invoice.model_type,invoice.year)">
                                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                                
                                                <td :class="rowClass.name">
                                                    {{invoice.sls_id}}
                                                <a href="javascript:void(0)"> <i class="fa-duotone fa-w color-71aa3f"></i></a>
                                                    <img v-show="invoice.under_warranty" v-b-tooltip.hover :title="staticInputLabelMessages.service_under_warranty_tooltip" class="image-icon" src="/static/images/W.png">
                                                    </td>
                                                <td :class="rowClass.name">
                                                    <span v-if="invoice.status == 'Closed'" class="badge badge-primary badge-pill bg-color-71aa3f">
                                                        {{invoice.status}} 
                                                    </span>
                                                    <span v-else-if="invoice.status == 'Open'" class="badge badge-primary badge-pill bg-color-cc9636">
                                                        {{invoice.status}}
                                                    </span>
                                                </td>
                                                <td :class="rowClass.Vin">{{invoice.vin}}</td>
                                                
                                                <td :class="rowClass.SlsTypId">{{invoice.sls_typ_id}}</td>
                                                <td :class="rowClass.location">{{invoice.location_name}}</td>
                                                <td :class="rowClass.Ref">{{invoice.ref}}</td>
                                                <td :class="rowClass.BillAddress">{{invoice.bill_address}}</td>
                                                <!-- <td :class="rowClass.CusPhone1">{{invoice.CusPhone1}}</td> -->
                                                <!-- <td :class="rowClass.Email">{{invoice.Email}}</td> -->
                                                <td :class="rowClass.DateUpdate">{{invoice.date_update}}</td>
                                            <!--   <td :class="rowClass.ShipPhone1">{{invoice.ShipPhone1}}</td>
                                                <td :class="rowClass.ShipAddress">{{invoice.ShipAddress}}</td> -->
                                                <td class="text-right">{{invoice.amt_total}}</td>
                                            </tr>
                                        </template>

                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <!-- <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th> -->
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action' && data.display" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            
                            <!-- Pagination Start -->
                            <div class="bottom_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                    

                        </div>
                    </div>
                </div>
            </template>
            <template v-if="displayPage==2">
                <list-svsls-ops :sls_id="selectedSlsId" :vin_no="selectedVinNo" :make_name="selectedMakeName"  :model_name="selectedModelName" :model_type="selectedModelType" :model_year="selectedModelYear"></list-svsls-ops>
            </template>
        </template>
    </div>
</template>            
<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";

    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import CommonLoader from "@/components/partials/CommonLoader.vue";
    import ListSvslsOps from './ListSvslsOps.vue';

    export default {
        name: 'ListPtsls',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
            "common-loader": CommonLoader,
            ListSvslsOps
        },
        data () {
            return {
                perPageValue : 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                summary: {
                    total_business: 0
                },
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                invoiceArr: [],
                headers: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord : "",
                selectAll: false,
                permissionNameArray : ['can_list_service_invoice_customer_master'],
                actionDisplay : false,
                accordianIds : [],
                jumpToHistoryPage : 1,
                filterCount : 0,
                syncFromExcedeButtonDisabled: false,
                hoverTextsyncFromExcedeButton: 'Sync From Excede',
                staticInputLabelMessages: '',
                displayPage: 1,
                selectedVinNo: '',
                selectedSlsId: '',
                selectedMakeName: '',
                selectedModelName: '',
                selectedModelType: '',
                selectedModelYear: '',

            }
        },
        props: {
            // permissionNameArray:"",
            customer: {
                type: [String, Number],
                required: false,
                default: undefined
            },
            redirectUrl: {
                type: String,
                required: false,
                default: undefined
            },
            activetab: {
                type: Number,
                required: false,
                default: undefined
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadServiceInvoices();
            },
            currentPage: function () {
                self.loadServiceInvoices();
            },*/
        },
        mounted: function() {
            self.staticInputLabelMessages = self.allLabelMessages;
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('contact-list');
            EventBus.$emit("menu-selection-changed", "contact-list");
            // var listHistoryData = self.loadFilters('listcontactemail', self);
            var listHistoryData = null;
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            self.loadServiceInvoices(1);
            self.getLoginIdPermissions();
            // self.syncContactSvsls(false);
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadServiceInvoices();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadServiceInvoices();
                }
            },
            returnAccordianDisplayDataKeys : function() {
                let dataArray = [
                    'id',
                    "name",
                ];

                return dataArray;
            },
            toggleIcon : function(id) {
                self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
            },
            disableSyncFromExcedeButton : function() {
                self.syncFromExcedeButtonDisabled = true;
                self.hoverTextsyncFromExcedeButton = 'Disable for next 10 mins';

                setTimeout(() => {
                    this.syncFromExcedeButtonDisabled = false;
                    self.hoverTextsyncFromExcedeButton = '';
                }, 11 * 60 * 1000); // 11 minutes
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadServiceInvoices();
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.invoiceArr) {
                            self.selectedRows.push(self.invoiceArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.invoiceArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;

                        if(typeof value == 'object') {
                            self.filters[key] = value.value;
                        }
                    }
                }
                self.appliedHeaders = data;
                self.loadServiceInvoices();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadServiceInvoices: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadServiceInvoices();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    contactId: self.customer
                };
                // this.storeFilters('listcontactemail', fields, false);
                $("#commonLoaderListServiceInvoice").find(".loading-container.lodingTable").show();
                self.filterCount = self.filterCountFunction(self.filters);


                HTTP.post("/contact/getsvsls", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        $("#commonLoaderListServiceInvoice").find(".loading-container.lodingTable").hide();
                        if (data.status == 200) {
                            self.clearSelection();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            self.summary = data.data.summary;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadServiceInvoices();
                                }, seconds);
                                
                            }
                            self.invoiceArr = data.data.content.data;
                            $("#selectAllCheckBox").prop("checked", false);
                            self.headers = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filters);

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]


                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (err) {
                        self.listExecutionState = false;
                        $("#commonLoaderListServiceInvoice").find(".loading-container.lodingTable").hide();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if(self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadServiceInvoices();
                }
            },
            syncContactSvsls : function(showLoader = true) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        'id': self.customer
                    }
                };
                if(showLoader) {
                    self.showLoader();
                }
                HTTP.get('/contact/syncsvsls', config)
                    .then(response => {
                        if(showLoader) {
                            if (response.data.status == "success") {
                                self.jumpToPage = 1;
                                self.keyMonitor();
                                self.loadServiceInvoices();
                                self.showSAlert(response.data.message,'success',false,3);
                                self.disableSyncFromExcedeButton();
                            }
                            self.hideLoader();
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            getSvslsOps(VinNo, SlsId, MakeName, ModelName, ModelType, ModelYear){
                self.displayPage = 2;
                self.selectedSlsId = SlsId;
                self.selectedVinNo = VinNo;

                self.selectedMakeName = MakeName;
                self.selectedModelName = ModelName;
                self.selectedModelType = ModelType;
                self.selectedModelYear = ModelYear;
            },
            BacktoPreviousPage : function(pageId){
                self.displayPage = pageId;
                switch (pageId) {
                        case 1 :
                            // self.loadRepairOrders(1);
                            break;
                        case 2 :
                            // self.loadRepairOrdersOps(1);
                            break;
                        case 3 :
                            // self.loadRepairOrdersItems(1);
                            break;
                    };
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }

            if (!self.permissionNameArray.includes('can_email_customer_master')) {
                self.headers.pop();
                self.actionDisplay = false;
            }

        }
    });


</script>
<style scoped>
.image-icon {
    display: inline-table;
    margin: 0.33em;
    width: 10%;
    height: auto;
}
</style>
