import { render, staticRenderFns } from "./ListPartItems.vue?vue&type=template&id=517e5116&scoped=true&"
import script from "./ListPartItems.vue?vue&type=script&lang=js&"
export * from "./ListPartItems.vue?vue&type=script&lang=js&"
import style0 from "./ListPartItems.vue?vue&type=style&index=0&id=517e5116&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517e5116",
  null
  
)

export default component.exports