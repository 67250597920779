import Vue from 'vue';
import Vuex from 'vuex';

import {HTTP} from '../http-common';
import EventBus from "../components/event-bus";
import {commonMixins} from '../mixins/commonMixins';

Vue.use(Vuex);

export const store = new Vuex.Store({
    mixins: commonMixins,
    state: {
        selectedData: [],
    },
    mutations: {
        changeSelectData(state, arrayData){
            arrayData.sort(function(a, b){return a-b});
            state.selectedData = arrayData;
        },
    },
    actions: {
        
    },
    getters: {
        selectedData: state => state.selectedData,
    }
});