<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="spaced-container">
                <div class="col-12 page_title paddingnone">
                    <span>{{pageTitle}}</span>
                    <label v-if="imageNotExistCount > 0" style="color:red;"> {{imageNotExistCount}} selected assets don't have photos and will be excluded</label>
                </div>
            </div>
        </div>
        <!--page content-->
        <div class="admin-page-content">
            <!--Forms-->
            <template v-if="isAllSelectedAssetImagesIsExists">
                <div :class="alertClass" id="success-alert">
                    <strong> {{alertMessage}} </strong>
                </div>
                <div class="forms-container add_user_forms_container">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Asking Price -->
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asking_price_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="askingPrice" v-model="askingPrice" class="form-control" type="text"/>
                                    <span class="help is-danger">{{askingPriceError}}</span>
                                </div>
                            </div>-->
                            <!-- Contact (CRM) -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_crm_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="contactCRM"
                                          @change="changedValue"
                                          @input="contactCRMError = ''"
                                          @search="onSearchCustomer"
                                          @clear="onClearCustomer"
                                          name="contactCRM"
                                          id="contactCRM"
                                          label="text"
                                          :clearable="true"
                                          :searchable="true"
                                          :filterable="false"
                                          :options="CustomerArr"
                                          :placeholder="staticMessages.acquire_from_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isCustomerRecordNotFound">
                                            {{staticMessages.acquire_from_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                </v-select>
                                    <!--<input @input="contactCRMError = ''" id="contactCRM" v-model="contactCRM" class="form-control" type="text"/>-->
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{contactCRMError}}</span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/> -->
                                    <vue-tags-input
                                        v-model="emailTag"
                                        :tags="emailTagsArr"
                                        :validation="validation"
                                        @tags-changed="newTags => emailTagsArr = newTags"
                                        @before-adding-tag="checkEmailTag"
                                        placeholder=""
                                    />
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailTagError}}</span>
                                </div>
                            </div>
                            <!-- Subject -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="subjectError = ''" id="subject" v-model="subject" class="form-control" type="text" maxlength="255"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{subjectError}}</span>
                                </div>
                            </div>
                            <!-- CC -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cc_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="ccError = ''" id="cc" v-model="cc" class="form-control" type="text"/> -->
                                    <vue-tags-input
                                        v-model="ccTag"
                                        :tags="ccTagsArr"
                                        :validation="validationCC"
                                        @tags-changed="newTags => ccTagsArr = newTags"
                                        @before-adding-tag="checkCCTag"
                                        placeholder=""
                                    />
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{ccTagError}}</span>
                                </div>
                            </div>
                            <!-- Message -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.message_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="messageError = ''" id="message" v-model="message" class="form-control" maxlength="50000"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{messageError}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Buttons-->
                    <div class="row clearfix"></div>
                    <div class="button-demo">
                        <button id="addAssetBtn" type="submit" :disabled="disableButtons.save" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="promoSheetSendEmail()">
                            {{buttonNames.save}}
                        </button>
                        <button class="btn btn-primary" id="addPrintPromoSheetBtn" type="submit" v-on:click.prevent="promoSheetClick()">
                            <i class="fa fa-print" aria-hidden="true"></i> Print Promo Sheet
                        </button>
                        <a id="addPDFPromoSheetBtn" :disabled="disableButtons.pdfButton" :value="buttonNames.pdfButton" class="btn btn-primary" href="javascript:void(0)" v-on:click.prevent="promoSheetPDFClick()">{{buttonNames.pdfButton}}</a>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);

    export default {
        components: {
            VueTagsInput,
        },
        name: 'EmailPromoSheet',
        mixins: [commonMixins],
        data() {
            return {
                loginId: 0,
                staticInputLabelMessages: "",
                staticMessages: "",
                pageTitle: "Email Promo Sheet",
                assetId: 0,
                askingPrice: 0,
                contactCRM: "",
                CustomerArr: [],
                emailTag: '',
                emailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidEmailTag: true,
                subject: "Central California Truck and Trailer Sales | Specification Notification",
                SubjectReset : "Central California Truck and Trailer Sales | Specification Notification",
                message: "",
                ccTag: '',
                ccTagsArr: [],
                validationCC: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidCCTag: true,
                buttonNames: {
                    save: 'Send Promo Sheet',
                    pdfButton: 'Promo Sheet PDF',
                },
                disableButtons: {
                    save: false,
                    pdfButton: false
                },

                //error msg
                askingPriceError: "",
                contactCRMError: "",
                emailTagError: "",
                subjectError: "",
                messageError: "",
                ccTagError: "",

                alertClass: "",
                alertMessage: "",
                imageNotExistCount: 0,
                isAllSelectedAssetImagesIsExists: true,
                isCustomerRecordNotFound : false,
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            contactCRM: function (contactCRM) {
                if (self.contactCRM == null || !self.contactCRM.value) {

                } else {
                    if (self.contactCRM.email != null && self.contactCRM.email != '') {
                        if (self.emailTagsArr.length == 0) {
                            self.emailTagsArr = [self.contactCRM.email];
                        }
                    }
                }
            }
        },
        mounted: function () {
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allLabelMessages;

            var userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson['id'];
            }

            $("#alertError").hide();
            self.assetId = localStorage.getItem('promoSheetIdList');
            self.getPromoSheetAssetData();
        },
        methods: {
            changedValue: function () {
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadCustomerCallback, loading, search);
                }
            },
            loadCustomerCallback: function (callbackData) {
                self.isCustomerRecordNotFound = false;
                self.CustomerArr = callbackData.Contact;
                if (self.CustomerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
            },
            onClearCustomer: function () {
                self.contactCRMError = "";
            },
            checkEmailTag: function(obj) {
                self.emailTagError = "";
                self.isValidEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidEmailTag = false;
                    self.emailTagError = "Please enter valid Email";
                } else obj.addTag();
            },
            checkCCTag: function(obj) {
                self.ccTagError = "";
                self.isValidCCTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidCCTag = false;
                    self.ccTagError = "Please enter valid CC Email";
                } else obj.addTag();
            },
            promoSheetClick: function() {
                if(self.assetId && self.assetId != '' && self.loginId) {
                    window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + self.assetId);
                }
            },
            checkForm: function () {
                var checked = true;
                //self.askingPriceError = "";
                self.contactCRMError = "";
                self.emailError = "";
                self.subjectError = "";
                self.ccError = "";
                self.messageError = "";
                self.isValidEmailTag = true;
                self.isValidCCTag = true;

                /*if (self.askingPrice && !self.numberFormateValidation(self.askingPrice)) {
                    self.askingPriceError = "Asking Price must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.contactCRM == null || !self.contactCRM.value) {
                    self.contactCRMError = "Please enter Contact CRM";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (self.emailTagsArr.length == 0) {
                    self.isValidEmailTag = false;
                    self.emailTagError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (!self.subject) {
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.message) {
                    self.messageError = "Please enter Message";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.cc && !self.validEmail(self.cc)) {
                    self.ccError = "Please enter valid CC Email";
                    if (checked) {
                        checked = false;
                    }
                } */
                return checked;
            },
            promoSheetSendEmail() {
                setTimeout(() => {
                    if (self.isValidEmailTag && self.isValidCCTag && self.checkForm()) {
                        self.buttonNames.save = "Sending Promo Sheet...";
                        self.disableButtons.save = true;
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var contactCRM = "";
                        if (self.contactCRM == null || !self.contactCRM.value) {
                            contactCRM = "";
                        } else {
                            contactCRM = self.contactCRM.value;
                        }
                        var emailData = "";
                        if(self.emailTagsArr && self.emailTagsArr.length > 0) {
                            for(var index = 0;index<self.emailTagsArr.length;index++) {
                                if(!emailData.includes(self.emailTagsArr[index].text)) {
                                    emailData += (self.emailTagsArr[index].text) + ',';
                                }
                            }
                        }
                        emailData = emailData.replace(/,\s*$/, "");
                        var ccData = "";
                        if(self.ccTagsArr && self.ccTagsArr.length > 0) {
                            for(var index = 0;index<self.ccTagsArr.length;index++) {
                                if(!ccData.includes(self.ccTagsArr[index].text)) {
                                    ccData += (self.ccTagsArr[index].text) + ',';
                                }
                            }
                        }
                        ccData = ccData.replace(/,\s*$/, "");
                        // console.log(emailValue); return false;
                        var data = new FormData();
                        data.append('askingPrice', self.askingPrice);
                        data.append('assetId', self.assetId);
                        data.append('contactCRM', contactCRM);
                        data.append('email', emailData);
                        data.append('subject', self.subject);
                        data.append('message', self.message);
                        data.append('cc', ccData);

                        HTTP.post("/assetpromosheet/email", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Send Promo Sheet";
                                    self.disableButtons.save = false;
                                    if (response.data.status == "success") {
                                        self.subject = self.SubjectReset;
                                        self.askingPrice = 0;
                                        self.contactCRM = "";
                                        self.emailTag = "";
                                        self.emailTagsArr = [];
                                        self.message = "";
                                        self.ccTag = "";
                                        self.ccTagsArr = [];
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                    } else {
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Send Promo Sheet";
                                    self.disableButtons.save = false;
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
            promoSheetPDFClick: function() {
                self.buttonNames.pdfButton = "Promo Sheet PDF ...";
                self.disableButtons.pdfButton = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        pdfAssetId: self.assetId,
                    }
                };
                HTTP.get('/assetpdfpromosheet/get', config)
                        .then(response => {
                            self.buttonNames.pdfButton = "Promo Sheet PDF";
                            self.disableButtons.pdfButton = false;
                            window.open(response.data.content);
                        })
                        .catch(function (err) {
                            self.buttonNames.pdfButton = "Promo Sheet PDF";
                            self.disableButtons.pdfButton = false;
                            self.catchHandler(err, function () {});
                        });
            },
            getPromoSheetAssetData: function() {
                var splitAssetId = self.assetId.split(":");
                self.isAllSelectedAssetImagesIsExists = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.assetId,
                    }
                };
                HTTP.get('/assetpromosheetimagecount/get', config)
                        .then(response => {
                            self.imageNotExistCount = response.data.content.count;
                            self.assetId = response.data.content.assetId;
                            self.subject = self.SubjectReset = response.data.content.subject + " | Specification Notification";
                            if(splitAssetId.length == self.imageNotExistCount) {
                                self.isAllSelectedAssetImagesIsExists = false;
                            }
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
        },
    }
</script>