var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-content-area",attrs:{"id":"page-content-wrapper"}},[(_vm.permissionNameArray.includes('can_list_deals_review'))?[_c('div',{staticClass:"container-fluid xyz"},[_c('div',{staticClass:"page-header-top-container"},[_c('div',{staticClass:"page-name"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('button',{staticClass:"btn btn-primary waves-effect pull-right",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.buttonBack()}}},[_vm._v(" Back ")])]),_c('div',{staticClass:"admin-page-content"},[_c('div',{staticClass:"forms-container add_user_forms_container vtc-dealer-forms"},[[_c('div',{staticClass:"row no-margin"},[_c('ul',{staticClass:"nav nav-tabs"},_vm._l((_vm.tabList),function(tab,index){return _c('li',{key:tab.id,staticClass:"nav-item"},[_c('a',{class:{
                          'nav-link': true,
                          active: _vm.activetab === index,
                      },on:{"click":function($event){_vm.activetab = index}}},[_vm._v(_vm._s(tab.name))])])}),0),(
                  _vm.activetab === 1
                )?_c('div',{staticClass:"tabcontent"},[_c('div',{staticClass:"card default-bx-shadow"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 col-md-12 form-group"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',[[_vm._m(2)]],2)])])])])]):_vm._e()])]],2)])])]:[_c('no-access')],_c('app-footer')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 d-flex justify-content-between"},[_c('span',[_c('strong',{staticClass:"bold"},[_vm._v("PO Request Review")])]),_c('span',{staticClass:"position-relative"},[_c('a',{staticClass:"extraFeature btn btn-primary waves-effect pull-right ml-2",attrs:{"title":"Refresh"}},[_c('i',{staticClass:"fa fa-undo",attrs:{"aria-hidden":"true"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',[_vm._v("Action")]),_c('th',[_vm._v("Business Name")]),_c('th',[_vm._v("Reference No")]),_c('th',[_vm._v("Deal No")]),_c('th',[_vm._v("Deal Status")]),_c('th',[_vm._v("Branch")]),_c('th',[_vm._v("Salesperson")]),_c('th',[_vm._v("Created At")]),_c('th',[_vm._v("Updated At")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',[_vm._v("Cory Trashco")]),_c('td',[_vm._v("2024-91")]),_c('td',[_vm._v("DE-231")]),_c('td',[_vm._v("PO Request")]),_c('td',[_vm._v("Neely Coble Company - Nashville")]),_c('td',[_vm._v("Santosh Tamang")]),_c('td',[_vm._v("03/05/2024 03:24AM")]),_c('td',[_vm._v("03/05/2024 04:27AM")])])
}]

export { render, staticRenderFns }