<template>
  <div class="modal" id="searchVinModal" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-lg modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header vin-src-modal-row">
          <div>
            <div class="vin-custom-input-search">
              <input type="text" id="vin-form-input" v-model="vinNo" @blur="isSyncVinAsset = false" />
              <div class="vin-search-icon" @click="onSearchVin(vinNo)"></div>
            </div>
            <button v-if="isSyncVinAsset" type="button" @click="syncVinAssetData()" id="sync-vin-btn">Sync VIN</button>
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body vin-src-modal-body">
          <div class="card">
            <table class="table custom-table jsmartable">
              <caption style="display:none">Vin List</caption>
              <thead id="vin-src-modal">
                <th>Vehicle ID</th>
                <th>Vin No</th>
                <th>Action</th>
              </thead>
              <tbody v-if="loader">
                <tr>
                  <td colspan="3">
                    <loader />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="assetArr.length > 0">
                <tr v-for="(asset, index) in assetArr" :key="index">
                  <td>{{ asset.unit }}</td>
                  <td>{{ asset.text }}</td>
                  <td>
                    <a href="javascript:void(0)" @click="selectVin(asset)"
                      >Select</a
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3">
                    <div class="d-flex justify-content-center">
                      <h6>No Data</h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="page-loader-wrapper loading-container" v-if="syncLoader">
        <div class="loading">
          <img src="/static/images/loading.gif" alt="loader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../event-bus";
import { commonMixins } from "@/mixins/commonMixins";
import { HTTP } from "../../../../http-common";
import Loader from "./Loader.vue";

let selfThis;
export default {
  name: "SearchVinModal",
  props: ["isClickSearchVinModal", "vin", "usedVinList"],
  components: {
    'loader': Loader,
  },
  mixins: [commonMixins],
  data() {
    return {
      vinNo: "",
      assetArr: [],
      isSyncVinAsset: false,
      syncLoader: false,
      loader: false,
    };
  },
  mounted() {
    selfThis = this;
  },
  methods: {
    closeModal() {
      selfThis.isSyncVinAsset = false;
      selfThis.vinNo = "";
      selfThis.assetArr = [];
      eventBus.$emit("close-search-vin", false);
    },

    onSearchVin(vin) {
      selfThis.loader = true;
      selfThis.loadAllMasterData(['Asset'], selfThis.loadAsset, vin, {req_for: "SearchVinModal"});
    },

    loadAsset: function (callbackData) {
      selfThis.assetArr = callbackData.Asset;
      if(selfThis.assetArr.length == 0) {
        selfThis.isSyncVinAsset = true;
      }
      selfThis.loader = false;
    },

    selectVin(vin) {
      if(selfThis.usedVinList.includes(vin.text)) {
        selfThis.showSAlert(
          "VIN has been already used by other vehicle!",
          "info",
          false,
          3
        );
        return true;
      }
      eventBus.$emit("get-search-vin", vin);
      selfThis.vinNo = "";
      selfThis.assetArr = [];
    },

    syncVinAssetData() {
      selfThis.syncLoader = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      let data = new FormData();
      data.append("vin_no", selfThis.vin);
      
      HTTP.post("syncasset", data, config)
      .then((response) => {
        if(response.data.code == 200 && response.data.status == "success") {
          selfThis.syncLoader = false;
          selfThis.showSAlert(
            response.data.message,
            response.data.status,
            false,
            2
          );
          selfThis.isSyncVinAsset = false;
        }
      })
      .catch(function (err) {
        selfThis.isSyncVinAsset = false;
        selfThis.catchHandler(err, function () {});
      });
    }
  },
  watch: {
    isClickSearchVinModal(status) {
      selfThis.isSyncVinAsset = false;
        if(status && selfThis.vin != "") {
            selfThis.vinNo = selfThis.vin;
            selfThis.onSearchVin(selfThis.vin);
        }
    }
  }
};
</script>

<style>
.vin-src-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

.vin-src-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.vin-custom-input-search {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  overflow: hidden;
  /* margin-top: 5px; */
}

.vin-search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  flex-shrink: 0;
  background: #ebebeb
    url("../../../../../public/static/images/transaction_sheet/search.svg")
    no-repeat center;
  cursor: pointer;
}

#sync-vin-btn {
  display: inline-flex;
  height: 30px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  flex-shrink: 0;
  background: linear-gradient(174deg, #076eb0 0%, rgba(7, 110, 176, 0.57) 100%);
  border: none;
  margin-left: 10px;

  /* text */
  color: #fff;
  text-align: center;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1px;
}

.vin-src-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#vin-src-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

#vin-form-input {
  height: 32px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #cecece;
}
</style>
