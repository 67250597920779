<template>
    <div id="page-content-wrapper" class="admin-content-area">
      <template v-if="permissionNameArray.includes('can_notification_manager_user')">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div class="page-name">{{ pageTitle }}</div>
                <button
                    type="submit"
                    v-on:click.prevent="buttonBack()"
                    class="btn btn-primary waves-effect pull-right"
                >
                  {{ allLabelMessages.back }}
                </button>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <!--Forms-->

                <div class="forms-container add_user_forms_container vtc-dealer-forms" >
                    <div class="row no-margin">
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.notification_type_select_label }}
                                </div>
                            </div>

                            <div class="col-9 paddingleft0">
                                <v-select
                                    v-model="selectedNotificationType"
                                    name="notificationTypeId"
                                    id="notificationTypeId"
                                    @input = "notificationTypeIdError = ''"
                                    label="title"
                                    :reduce="opt => opt.record_id"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="notificationTypeOptionArr"
                                    placeholder="Notification Types"
                                >
                                    
                                </v-select>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{ notificationTypeIdError }}</span>
                            </div>


                        </div>

                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.notification_schedule_select_label }}
                                </div>
                            </div>

                            <div class="col-9 paddingleft0">
                                <v-select
                                    multiple
                                    v-model="selectedNotificationSchedules"
                                    name="notificationTimes"
                                    id="notificationTimes"
                                    @input = "selectedNotificationScheduleError = ''"
                                    label="title"
                                    :reduce="opt => opt.record_id"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="notificationTimeScheduleOptionArr"
                                    placeholder="Notification Schedules"
                                >
                                    
                                </v-select>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{ selectedNotificationScheduleError }}</span>
                            </div>


                        </div>

                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.user_label }}
                                </div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select
                                    v-model="selectedUser"
                                    name="assignUserId"
                                    id="assignUserId"
                                    label="text"
                                    @change="changedValue"
                                    @input="selectedUserError = ''"
                                    :filterable="false"
                                    :options="assignUserIdArr"
                                    @search="onSearchAssignUser"
                                    :placeholder="allLabelMessages.select_user_placeholder"
                                >
                                    <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isAssingUserRecordNotFound">
                                        {{ allStaticMessages.assign_used_search }}
                                    </template>
                                    <template v-else>
                                        {{ allStaticMessages.search_record_not_found }}
                                    </template>
                                    </span>
                                </v-select>
                            <div class="requiredstar">*</div>
                            <span class="help is-danger">{{ selectedUserError }}</span>
                            </div>
                        </div>

                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.name_label }}
                                </div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input
                                    @input="nameError = ''"
                                    id="name"
                                    v-model="name"
                                    class="form-control"
                                    type="text"
                                    maxlength="500"
                                />
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{ nameError }}</span>
                            </div>
                        </div>

                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.email_label }}
                                </div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input
                                    @input="toEmailError = ''"
                                    id="subject"
                                    v-model="toEmail"
                                    class="form-control"
                                    type="text"
                                    maxlength="500"
                                />
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{ toEmailError }}</span>
                            </div>
                        </div>


                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.cc_email_label }}
                                </div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <vue-tags-input
                                    v-model="ccEmails"
                                    :tags="ccEmailTagsArr"
                                    :validation="validation"
                                    @tags-changed="newTags => ccEmailTagsArr = newTags"
                                    @before-adding-tag="checkCcEmailTag"
                                    placeholder=""
                                />
                                
                                <!-- <input
                                    @input="ccEmailError = ''"
                                    v-model="ccEmails"
                                    class="form-control"
                                    type="text"
                                    maxlength="500"
                                /> -->
                                <!-- <div class="requiredstar">*</div> -->
                                <span class="help is-danger">{{ ccEmailError }}</span>
                            </div>
                        </div>

                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">
                                    {{ allLabelMessages.bcc_email_label }}
                                </div>
                            </div>
                            <div class="col-9 paddingleft0">

                                <vue-tags-input
                                    v-model="bccEmails"
                                    :tags="bccEmailTagsArr"
                                    :validation="validation"
                                    @tags-changed="newTags => bccEmailTagsArr = newTags"
                                    @before-adding-tag="checkBccEmailTag"
                                    placeholder=""
                                />


                                <!-- <input
                                    @input="bccEmailError = ''"
                                    v-model="bccEmails"
                                    class="form-control"
                                    type="text"
                                    maxlength="500"
                                /> -->
                                <!-- <div class="requiredstar">*</div> -->
                                <span class="help is-danger">{{ bccEmailError }}</span>
                            </div>
                        </div>
                      </div>

                        <div class="clearfix"></div>
                         <!--Buttons-->
                         <div class="row clearfix"></div> 
                         <div class="button-demo">
                            <input
                                id="addNotificationBtn"
                                :disabled="disableButtons.save"
                                type="submit"
                                :value="buttonNames.save"
                                class="btn btn-primary"
                                v-on:click.prevent="addUserNotification()"
                            />

                            <input 
                                id="addAndContinueUserNotificationManagerBtn" 
                                :disabled="disableButtons.saveAndContinue" 
                                type="submit" 
                                :value="buttonNames.saveAndContinue" 
                                class="btn btn-primary" 
                                v-on:click.prevent="addUserNotification(flag='Save and Continue')">

                            <input
                                id="cancelNotificationBtn"
                                :disabled="disableButtons.cancel"
                                type="submit"
                                :value="buttonNames.cancel"
                                class="btn btn-primary"
                                v-on:click.prevent="buttonBack"
                            />
                         </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->

        </div>
      </template>
      <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import { HTTP } from "../../http-common";
    import VueTagsInput from '@johmun/vue-tags-input';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import Footer from "@/components/partials/Footer";

    export default {
        name: 'AddUserNotificationPage',
        mixins: [commonMixins],
        components: {
          VueTagsInput,
          "no-access": NoAccessPage,
          "app-footer": Footer,
        },
        data () {
            return {
                editId: '',
                pageTitle: 'Add User Notification',
                notificationTypeOptionArr: [],
                notificationTimeScheduleOptionArr: [],
                selectedNotificationType: '',
                selectedNotificationSchedules: '',
                selectedNotificationScheduleError: '',
                notificationTypeIdError: '',
                notificationUserId: '',
                notificationUserIdError: '',
                notificationUserName: '',
                notificationUserNameError: '',
                notificationUserToEmail: '',
                notificationUserToEmailError: '',
                notificationUserCcEmail: '',
                notificationUserCcEmailError: '',
                notificationUserBccEmail: '',
                notificationUserBccEmailError: '',
                selectedUser: '',
                selectedUserError: '',
                assignUserIdArr: [],
                isAssingUserRecordNotFound: false,
                toEmailError: '',
                toEmail: '',
                ccEmails: '',
                ccEmailTagsArr: [],
                isValidCcEmailTag :true,
                bccEmails: '',
                bccEmailTagsArr: [],
                isValidBccEmailTag : true,
                ccEmailError: '',
                bccEmailError: '',
                name: '',
                nameError: '',
                buttonNames: {
                    save: "Save",
                    saveAndContinue : 'Save And Continue',
                    cancel: "Cancel",
                },
                disableButtons: {
                    save: false,
                    saveAndContinue : false,
                    cancel: false
                },
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                permissionNameArray : ['can_notification_manager_user'],

            }
        },
        beforeMount() {
            self = this;
        },

        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */

            self.setActiveMenu('notification-manager');
            EventBus.$emit("menu-selection-changed", "notification-manager");

            self.getNotificationTypes();
            self.getNotificationTimeScheduleOptions();
            self.editId = (typeof self.$route.params.id != "undefined" && self.$route.params.id != 0 && self.$route.params.id != "") ? self.$route.params.id : '';
            if(self.editId) {
                self.getNotificationData();
                self.buttonNames.save = 'Update';
                $("#addAndContinueUserNotificationManagerBtn").hide();
                self.pageTitle = "Edit Notification Manager";
            }
        },
        methods: {
            checkBccEmailTag:function(obj) {
                self.bccEmailError = "";
                self.isValidBccEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidBccEmailTag = false;
                    self.bccEmailError = "Please enter valid Email";
                } else obj.addTag();
            }, 
            checkCcEmailTag: function(obj) {
                self.ccEmailError = "";
                self.isValidCcEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidCcEmailTag = false;
                    self.ccEmailError = "Please enter valid Email";
                } else obj.addTag();
            },
            getNotificationData: function() {
                var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    HTTP.get("/notification/"+self.editId, config)
                        .then(response => {

                            if(response.data && response.data.status == "success") {
                                let resultData = response.data.content;

                                self.name = resultData.name;
                                self.toEmail = resultData.to_email;
                                self.selectedNotificationType = self.notificationTypeOptionArr.find(item => item['record_id'] === resultData.notification_type_master_id);
                                
                                self.selectedNotificationSchedules = self.notificationTimeScheduleOptionArr.filter(item => {
                                    return resultData.notification_schedule_ids.includes(item['record_id']);
                                });

                                if(resultData.user_id) {
                                    self.selectedUser = {
                                        email: resultData.to_email,
                                        full_name: '',
                                        text: resultData.name,
                                        value: resultData.user_id
                                    };
                                }

                                if(resultData.cc_emails && resultData.cc_emails != 'NULL') {
                                    var ccEmailArr = resultData.cc_emails.split(",");
                                    for(var index = 0; index < ccEmailArr.length; index++) {
                                        self.ccEmailTagsArr.push({"text": ccEmailArr[index], "tiClasses": ["ti-valid"]});
                                    }
                                }

                                if(resultData.bcc_emails && resultData.bcc_emails != 'NULL') {
                                    var bccEmailArr = resultData.bcc_emails.split(",");
                                    for(var index = 0; index < bccEmailArr.length; index++) {
                                        self.bccEmailTagsArr.push({"text": bccEmailArr[index], "tiClasses": ["ti-valid"]});
                                    }
                                }

                            }
                        })
                        .catch(function(err) {
                             self.catchHandler(err, function() {});
                        });
            },
            selectNotification() {

            },
            changedValue: function(selectedObj) {
                if(selectedObj && selectedObj.email) {
                    self.toEmail = selectedObj.email;
                    self.toEmailError = '';
                }

                if(selectedObj && selectedObj.text) {
                    self.name = selectedObj.text;
                    self.nameError = '';
                }
            },
            buttonBack() {
                if (
                    typeof self.$route.query.redirectUrl != "undefined" &&
                    self.$route.query.redirectUrl != ""
                ) {
                    const url = decodeURIComponent(self.$route.query.redirectUrl);
                    self.$router.push(url);
                } else {
                    self.$router.push("/notification/setup");
                }
            },

            clearDataOnSaveAndContinue() {
                self.selectedNotificationType = "";
                self.selectedNotificationSchedules = "";
                self.selectedUser = "";
                self.name = "";
                self.toEmail = "";
                self.ccEmails = "";
                self.bccEmails = "";
            },

            getNotificationTypes() {
                var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    HTTP.get("/notificationtypemasters", config)
                        .then(response => {
                            
                            if(response.data && response.data.status == "success") {

                                self.notificationTypeOptionArr = (response.data.content && response.data.content.records) ? response.data.content.records : [];
                            }

                        })
                        .catch(function(err) {
                             self.catchHandler(err, function() {});
                        });
            },
            getNotificationTimeScheduleOptions() {
                var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    HTTP.get("/notificationtimescheduleoptions", config)
                        .then(response => {
                            
                            if(response.data && response.data.status == "success") {
                                self.notificationTimeScheduleOptionArr =  (response.data.content && response.data.content.records) ? response.data.content.records : [];
                            }

                        })
                        .catch(function(err) {
                             self.catchHandler(err, function() {});
                        });
            },
            onSearchAssignUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(
                    ["User"],
                    true,
                    self.loadAssignUserCallback,
                    loading,
                    search
                    );
                }
            },
            loadAssignUserCallback: function(callbackData) {
                self.isAssingUserRecordNotFound = false;
                self.assignUserIdArr = callbackData.User;
                if (self.assignUserIdArr.length == 0) {
                    self.isAssingUserRecordNotFound = true;
                }
            },
            checkForm: function() {
                var checked = true;
                self.statusError = "";
                if (self.selectedNotificationType == null || !self.selectedNotificationType.record_id) {
                    self.notificationTypeIdError = "Please select notification type";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.selectedNotificationSchedules == null || !self.selectedNotificationSchedules.length) {
                    self.selectedNotificationScheduleError = "Please select notification schedule";
                    if (checked) {
                        checked = false;
                    }
                }
               
                if (self.selectedUser == null || !self.selectedUser) {
                    self.selectedUserError = "Please select user";
                    if (checked) {
                        checked = false;
                    }
                }

                if (!self.name || self.name == null) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                
                if (self.toEmail == null || !self.toEmail) {
                    self.toEmailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.toEmail && !self.validEmail(self.toEmail)) {
                    self.toEmailError = "Please enter valid email";
                    if (checked) {
                        checked = false;
                    }
                }
                
                return checked;
            },
            addUserNotification(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        

                        var ccEmailData = "";
                        if(self.ccEmailTagsArr && self.ccEmailTagsArr.length > 0) {
                            for(var index = 0;index<self.ccEmailTagsArr.length;index++) {
                                if(!ccEmailData.includes(self.ccEmailTagsArr[index].text)) {
                                    ccEmailData += (self.ccEmailTagsArr[index].text) + ',';
                                }
                            }
                        }
                        ccEmailData = ccEmailData.replace(/,\s*$/, "");

                        var bccEmailData = "";
                        if(self.bccEmailTagsArr && self.bccEmailTagsArr.length > 0) {
                            for(var index = 0;index<self.bccEmailTagsArr.length;index++) {
                                if(!bccEmailData.includes(self.bccEmailTagsArr[index].text)) {
                                    bccEmailData += (self.bccEmailTagsArr[index].text) + ',';
                                }
                            }
                        }
                        bccEmailData = bccEmailData.replace(/,\s*$/, "");

                        let data = {
                            'record_id': self.editId,
                            'notification_type_master_id': (self.selectedNotificationType && self.selectedNotificationType.record_id) ? self.selectedNotificationType.record_id : '',
                            'user_id': (self.selectedUser && self.selectedUser.value) ? self.selectedUser.value : '',
                            'name': self.name,
                            'to_email': self.toEmail,
                            'cc_emails': ccEmailData,
                            'bcc_emails': bccEmailData,
                            'selected_schedules': self.selectedNotificationSchedules
                        };

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.record_id = self.$route.params.id;
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        

                        HTTP.post("/notification/store", data, config)
                            .then(function(response) {
                            if (
                                typeof self.$route.params.id != "undefined" &&
                                self.$route.params.id != 0 &&
                                self.$route.params.id != ""
                            ) {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                if (self.$route.params.id != 0 && self.$route.params.id != "") {
                                self.buttonNames.save = "Update";
                                } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                                }
                            } else {
                                if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Save and Continue";
                                } else {
                                self.buttonNames.save = "Save";
                                }
                            }
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message, "success", false, 3);
                                if (flag == "Save and Continue") {
                                self.clearDataOnSaveAndContinue();
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                } else {
                                setTimeout(function() {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    self.buttonBack();
                                }, 3000);
                                }
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                var err = "";
                                for (var i = 0; i < response.data.content.length; i++) {
                                    err += response.data.content[i] + "<br/>";
                                }
                                self.showSAlert(err, "error", false);
                                }
                            }
                            })
                            .catch(function(err) {
                            if (self.$route.params.id != 0 && self.$route.params.id != "") {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            } else {
                                if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Save and Continue";
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                self.clearDataOnSaveAndContinue();
                                } else {
                                self.buttonNames.save = "Save";
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                }
                            }
                            self.catchHandler(err, function() {});
                            });
                        }
                }, 200);
            },
        },
        
    };

    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });

</script>
<style lang="css">
.vue-tags-input {
    max-width: 100% !important;
}
</style>