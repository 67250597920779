<template>
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_vendor_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
          {{staticInputLabelMessages.back}}
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <!--Vendor Id-->
              <div v-if="isEdit" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.vendor_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    disabled
                    id="venId"
                    v-model="venId"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>

              <!-- Vendor Group -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.vendor_group_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="vendorId"
                    @change="changeVendorGroup"
                    @input="vendorGroupError = ''"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    @search="onSearchVendorGroup"
                    :filterable="false"
                    :options="vendorGroupArr"
                    :placeholder="staticMessages.vendor_group_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isVendorGroupRecordNotFound">
                        {{ staticMessages.vendor_group_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Code-->
              <div v-if="isEdit" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.code_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    disabled
                    @input="codeError = ''"
                    id="code"
                    v-model="code"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ codeError }}</span>
                </div>
              </div>

              <!-- FirstName -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.first_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="firstNameError = ''"
                    id="firstName"
                    v-model="firstName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ firstNameError }}</span>
                </div>
              </div>

              <!-- Middle Name -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.middle_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="middleName"
                    v-model="middleName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- LastName -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.last_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="lastName"
                    v-model="lastName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- Contact Name -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.contact_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="cntName"
                    v-model="cntName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Primary Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.primary_phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="primaryPhoneError = ''"
                    id="primaryPhone"
                    v-model="primaryPhone"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ primaryPhoneError }}</span>
                </div>
              </div>

              <!--Alternate Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.alternate_phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="alternatePhoneError = ''"
                    id="alternatePhone"
                    v-model="alternatePhone"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ alternatePhoneError }}</span>
                </div>
              </div>

              <!-- Fax -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.fax_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="faxError = ''"
                    id="fax"
                    v-model="fax"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <span class="help is-danger">{{ faxError }}</span>
                </div>
              </div>

              <!--Email -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.email_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="emailError = ''"
                    id="email"
                    v-model="email"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <span class="help is-danger">{{ emailError }}</span>
                </div>
              </div>

              <!-- Country -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.country_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="countryId"
                    @change="changeConutry"
                    @input="countryError = ''"
                    name="countryId"
                    id="countryId"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="countryArr"
                    :placeholder="staticMessages.country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ countryError }}</span>
                </div>
              </div>

              <!-- State -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.state_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="stateId"
                    name="state"
                    label="text"
                    :filterable="false"
                    :options="stateArr"
                    @input="stateError = ''"
                    @search="onSearchState"
                    :placeholder="staticMessages.state_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ stateError }}</span>
                </div>
              </div>

              <!-- City -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.city_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="cityId"
                    name="state"
                    label="text"
                    :filterable="false"
                    :options="cityArr"
                    @input="cityError = ''"
                    @search="onSearchCity"
                    :placeholder="staticMessages.city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ cityError }}</span>
                </div>
              </div>

              <!--Address-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.address_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="addressError = ''"
                    id="address"
                    v-model="address"
                    class="form-control"
                    type="text"
                    maxlength="50000"
                  />
                </div>
              </div>

              <!--Zipcode -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.zip_code_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="zipcodeError = ''"
                    id="zipcode"
                    v-model="zipcode"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>


              <!-- Status -->
              <div
                v-show="
                  permissionNameArray.includes(
                    'can_active_inactive_vendor_master'
                  )
                "
                class="col-6 forms-block"
              >
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.status_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <b-form-radio-group
                    v-model="statusId"
                    :options="statusIdOptions"
                    name="statusId"
                  >
                  </b-form-radio-group>
                </div>
              </div>

              <!--Address2-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.cus_address2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="address2"
                    v-model="address2"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--County-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.county }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="county"
                    v-model="county"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Address1-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_address1_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billAddress1"
                    v-model="billAddress1"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Address2-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_address2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billAddress2"
                    v-model="billAddress2"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Country -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_country_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="billCountry"
                    @change="changeBillCountry"
                    name="billCountry"
                    id="billCountry"
                    label="text"
                    :clearable="true"
                    :searchable="false"
                    :filterable="false"
                    :options="billCountryArr"
                    :placeholder="staticMessages.bill_country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill State -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_state_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="billState"
                    name="billState"
                    label="text"
                    :filterable="false"
                    :options="billStateArr"
                    @search="onSearchBillState"
                    :placeholder="staticMessages.bill_state_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill City -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_city_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="billCity"
                    name="billCity"
                    label="text"
                    :filterable="false"
                    :options="billCityArr"
                    @search="onSearchBillCity"
                    :placeholder="staticMessages.bill_city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill County-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_county_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billCounty"
                    v-model="billCounty"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Post-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_post_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billPost"
                    v-model="billPost"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Phone1 -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_phone1_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="billPhone1Error = ''"
                    id="billPhone1"
                    v-model="billPhone1"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ billPhone1Error }}</span>
                </div>
              </div>

              <!--Bill Phone2 -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_phone2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="billPhone2Error = ''"
                    id="billPhone2"
                    v-model="billPhone2"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ billPhone2Error }}</span>
                </div>
              </div>

              <!--Bill Fax -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_fax_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="billFaxError = ''"
                    id="billFax"
                    v-model="billFax"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <span class="help is-danger">{{ billFaxError }}</span>
                </div>
              </div>

              <!-- Bill Contact-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_contact_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billContact"
                    v-model="billContact"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- Bill Vender Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_ven_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billVenId"
                    v-model="billVenId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- Dsb Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.dsb_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="dsbTypId"
                    v-model="dsbTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Mem Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.mem_type_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="memTypId"
                    v-model="memTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- Rec Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.rec_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="recTypId"
                    v-model="recTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Pt Pur Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.pt_pur_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="ptPurTypId"
                    v-model="ptPurTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Sv Pur Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.sv_pur_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="svPurTypId"
                    v-model="svPurTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Vh Pur Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.vh_pur_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="vhPurTypId"
                    v-model="vhPurTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Fl Pur Typ Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.fl_pur_typ_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="flPurTypId"
                    v-model="flPurTypId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Account Number-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.acct_nbr_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="acctNbr"
                    v-model="acctNbr"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Type -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.typ_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="typ"
                    v-model="typ"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Term Id -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.trm_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="trmId"
                    v-model="trmId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Type1099-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.type1099_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="type1099"
                    v-model="type1099"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Tax Id -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.tax_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="taxId"
                    v-model="taxId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Ssn-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.ssn_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="ssn"
                    v-model="ssn"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Core Invoice Reqd-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.core_invoice_reqd_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="coreInvoiceReqd"
                    @change="changeCoreInvoiceReqd"
                    @input="coreInvoiceReqdError = ''"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="true"
                    :searchable="false"
                    :filterable="false"
                    :options="coreInvoiceReqdArr"
                    :placeholder="staticMessages.core_invoice_reqd_place_holder"
                  >
                  </v-select>
                </div>
              </div>

              <!--Employee Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.emp_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="empId"
                    v-model="empId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Excede Date Invoice-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.excede_date_invoice_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <date-picker
                    v-model="excedeDateInvoice"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    :placeholder="
                      staticMessages.excede_date_invoice_place_holder
                    "
                    name="excedeDateInvoice"
                  >
                  </date-picker>
                </div>
              </div>

              <!--Amount Invoice -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.amt_invoice_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="amtInvoiceError = ''"
                    id="amtInvoice"
                    v-model="amtInvoice"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ amtInvoiceError }}</span>
                </div>
              </div>

              <!--Excede Date Payment-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.excede_date_payment_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <date-picker
                    v-model="excedeDatePayment"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    :placeholder="
                      staticMessages.excede_date_payment_place_holder
                    "
                    name="excedeDatePayment"
                  >
                  </date-picker>
                </div>
              </div>

              <!-- Amount Payment -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.amt_payment_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="amtPaymentError = ''"
                    id="amtPayment"
                    v-model="amtPayment"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ amtPaymentError }}</span>
                </div>
              </div>

              <!-- Check Number -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.check_number_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="checkNumber"
                    v-model="checkNumber"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- Not Id-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.not_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="notId"
                    v-model="notId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>
            </div>

            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger" id="alertError">
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input
                id="addVendorBtn"
                :disabled="disableButtons.save"
                type="submit"
                :value="buttonNames.save"
                class="btn btn-primary"
                v-on:click.prevent="addVendor()"
              />
              <input
                id="addAndContinueVendorBtn"
                :disabled="disableButtons.saveAndContinue"
                type="submit"
                :value="buttonNames.saveAndContinue"
                class="btn btn-primary"
                v-on:click.prevent="addVendor((flag = 'Save and Continue'))"
              />
              <input
                id="cancelModel"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
</template>

<script>
let self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import ListVendorItem from "@/components/VendorMaster/ListVendorItem";

Vue.component("app-footer", Footer);

export default {
  name: "AddVendor",
  mixins: [commonMixins],
  components: {
    datePicker,
    "no-access": NoAccessPage,
    "vendor-item": ListVendorItem,
  },
  data() {
    return {
      staticInputLabelMessages: "",
      staticMessages: "",
      isEdit: false,
      id: this.$route.params.id,
      vendorId: "",
      vendorGroupArr: [],
      isVendorGroupRecordNotFound: false,
      vendorGroupError: "",
      code: "",
      codeError: "",
      venId: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      companyName: "",
      companyNameError: "",
      primaryPhone: "",
      primaryPhoneError: "",
      alternatePhone: "",
      alternatePhoneError: "",
      fax: "",
      faxError: "",
      email: "",
      emailError: "",
      countryId: "",
      countryArr: [],
      isCountryRecordNotFound: false,
      countryError: "",
      stateId: "",
      stateArr: [],
      isStateRecordNotFound: false,
      stateError: "",
      cityId: "",
      cityArr: [],
      isCityRecordNotFound: false,
      cityError: "",
      address: "",
      addressError: "",
      zipcode: "",
      zipcodeError: "",
      statusId: "active",
      statusIdOptions: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      statusError: "",
      middleName: "",
      cntName: "",
      address2: "",
      county: "",
      billAddress1: "",
      billAddress2: "",
      billCountry: "",
      billCountryArr: [],
      isBillCountryRecordNotFound: false,
      billState: "",
      billStateArr: [],
      isBillStateRecordNotFound: false,
      billCity: "",
      billCityArr: [],
      isBillCityRecordNotFound: false,
      billCounty: "",
      billPost: "",
      billPhone1: "",
      billPhone1Error: "",
      billPhone2: "",
      billPhone2Error: "",
      billFax: "",
      billFaxError: "",
      billContact: "",
      billVenId: "",
      dsbTypId: "",
      memTypId: "",
      recTypId: "",
      ptPurTypId: "",
      svPurTypId: "",
      vhPurTypId: "",
      flPurTypId: "",
      acctNbr: "",
      typ: "",
      trmId: "",
      type1099: "",
      taxId: "",
      ssn: "",
      coreInvoiceReqd: "",
      coreInvoiceReqdArr: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      coreInvoiceReqdError: "",
      empId: "",
      excedeDateCreate: "",
      excedeDateUpdate: "",
      excedeDateInvoice: "",
      amtInvoice: "",
      amtInvoiceError: "",
      excedeDatePayment: "",
      amtPayment: "",
      amtPaymentError: "",
      checkNumber: "",
      notId: "",
      venMisc1: "",
      venMisc2: "",
      venMisc3: "",
      venMisc4: "",
      venMisc5: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
      },
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Vendor",
      id: 0,
      permissionNameArray: ["can_add_vendor_master"],
    };
  },
  watch: {
    billCountry: function (billCountry) {
      self.loadAllData(
        ["States"],
        true,
        self.loadBillStateDataCallback,
        "undefined",
        "",
        self.billCountry.value
      );
    },
    billState: function (billState) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    },
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;
    self.setActiveMenu("vendor-list");
    EventBus.$emit("menu-selection-changed", "vendor-list");

    $("#alertError").hide();
    self.isEdit = false;
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.id = self.$route.params.id;
      self.isEdit = true;
      self.getVendorDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      $("#addAndContinueVendorBtn").hide();
      self.pageTitle = "Edit Vendor";
    } else {
      self.countryId = self.prodCountryObj();
    }
    self.loadAllData(
      ["Countries"],
      false,
      self.loadCountryCallBack,
      "undefined",
      ""
    );
  },
  watch: {
    countryId: function (countryId) {
      self.loadAllData(
        ["States"],
        true,
        self.loadStateDataCallback,
        "undefined",
        "",
        self.countryId.value
      );
    },
    stateId: function (stateId) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
  },
  methods: {
    buttonBack() {
      self.$router.push({ name: "ListVendor" });
    },
    onSearchVendorGroup(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["VendorGroup"],
          true,
          self.loadVendorGroupCallBack,
          loading,
          search
        );
      }
    },
    loadVendorGroupCallBack: function (callbackData) {
      self.isVendorGroupRecordNotFound = false;
      self.vendorGroupArr = callbackData.VendorGroup;
      if (self.vendorGroupArr.length == 0) {
        self.isVendorGroupRecordNotFound = true;
      }
    },
    loadCountryCallBack: function (callbackData) {
      self.isCountryRecordNotFound = self.isBillCountryRecordNotFound = false;
      self.countryArr = self.billCountryArr = callbackData.Countries;
      if (self.countryArr.length == 0) {
        self.isCountryRecordNotFound = true;
      }
      if (self.billCountryArr.length == 0) {
        self.isBillCountryRecordNotFound = true;
      }
    },
    onSearchCity(search, loading) {
      if (self.stateId == null || !self.stateId.value) {
        self.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadCityDataCallback,
            loading,
            search,
            self.stateId.value
          );
        }
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr) {
        self.isCityRecordNotFound = true;
      }
    },
    onSearchState(search, loading) {
      if (self.countryId == null || !self.countryId.value) {
        self.showSAlert("Please Select Country First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadStateDataCallback,
            loading,
            search,
            self.countryId.value
          );
        }
      }
    },
    loadStateDataCallback: function (callbackData) {
      self.isStateRecordNotFound = false;
      self.stateArr = callbackData.States;
      if (self.stateArr.length == 0) {
        self.isStateRecordNotFound = true;
      }
    },
    changeVendorGroup: function (value) {},
    changeCoreInvoiceReqd: function (value) {
      /*console.log(value);*/
    },
    changeConutry: function (value) {},
    changeStatus: function (value) {},
    changeBillCountry: function (value) {},
    onSearchBillState(search, loading) {
      if (self.billCountry == null || !self.billCountry.value) {
        self.showSAlert("Please Select Bill Country First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadBillStateDataCallback,
            loading,
            search,
            self.billCountry.value
          );
        }
      }
    },
    loadBillStateDataCallback: function (callbackData) {
      self.isBillStateRecordNotFound = false;
      self.billStateArr = callbackData.States;
      if (self.billStateArr.length == 0) {
        self.isBillStateRecordNotFound = true;
      }
    },
    onSearchBillCity(search, loading) {
      if (self.billState == null || !self.billState.value) {
        self.showSAlert("Please Select Bill State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadBillCityDataCallback,
            loading,
            search,
            self.billState.value
          );
        }
      }
    },
    loadBillCityDataCallback: function (callbackData) {
      self.isBillCityRecordNotFound = false;
      self.billCityArr = callbackData.Cities;
      if (self.billCityArr) {
        self.isBillCityRecordNotFound = true;
      }
    },
    clearDataOnSaveAndContinue: function () {
      self.vendorId = "";
      self.vendorGroupArr = [];
      self.isVendorGroupRecordNotFound = false;
      self.code = "";
      self.venId = "";
      self.firstName = "";
      self.lastName = "";
      self.companyName = "";
      self.primaryPhone = "";
      self.alternatePhone = "";
      self.fax = "";
      self.email = "";
      self.countryId = "";
      self.isCountryRecordNotFound = false;
      self.stateId = "";
      self.stateArr = [];
      self.isStateRecordNotFound = false;
      self.cityId = "";
      self.cityArr = [];
      self.isCityRecordNotFound = false;
      self.address = "";
      self.zipcode = "";
      self.statusId = "active";
      self.middleName = "";
      self.cntName = "";
      self.address2 = "";
      self.county = "";
      self.billAddress1 = "";
      self.billAddress2 = "";
      self.billCountry = "";
      self.isBillCountryRecordNotFound = false, 
      self.billState = "";
      self.billStateArr = [];
      self.isBillStateRecordNotFound = false;
      self.billCity = "";
      self.billCityArr = [];
      self.isBillCityRecordNotFound = false;
      self.billCounty = "";
      self.billPost = "";
      self.billPhone1 = "";
      self.billPhone2 = "";
      self.billFax = "";
      self.billContact = "";
      self.billVenId = "";
      self.dsbTypId = "";
      self.memTypId = "";
      self.recTypId = "";
      self.ptPurTypId = "";
      self.svPurTypId = "";
      self.vhPurTypId = "";
      self.flPurTypId = "";
      self.acctNbr = "";
      self.typ = "";
      self.trmId = "";
      self.type1099 = "";
      self.taxId = "";
      self.ssn = "";
      self.coreInvoiceReqd = "";
      self.empId = "";
      self.excedeDateCreate = "";
      self.excedeDateUpdate = "";
      self.excedeDateInvoice = "";
      self.amtInvoice = "";
      self.excedeDatePayment = "";
      self.amtPayment = "";
      self.checkNumber = "";
      self.notId = "";
      self.venMisc1 = "";
      self.venMisc2 = "";
      self.venMisc3 = "";
      self.venMisc4 = "";
      self.venMisc5 = "";
    },
    showAlertOnSave: function (alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function () {
          $("#success-alert").slideUp(500);
        });
    },
    getVendorDetailsById: function (id) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };
      HTTP.get("/get/vendor", config)
        .then((response) => {
          self.venId = response.data.content.ven_id;
          self.vendorId = {
            value: response.data.content.group_id,
            text: response.data.content.vendor_group_name,
          };
          self.code = response.data.content.code;
          self.firstName = response.data.content.first_name;
          if (
            response.data.content.middle_name != null &&
            response.data.content.middle_name != ""
          ) {
            self.middleName = response.data.content.middle_name;
          }
          self.lastName = response.data.content.last_name;
          if (
            response.data.content.contact_name != null &&
            response.data.content.contact_name != ""
          ) {
            self.cntName = response.data.content.contact_name;
          }
          self.companyName = response.data.content.company_name;
          self.primaryPhone = response.data.content.primary_phone;
          self.alternatePhone = response.data.content.alternate_phone;
          self.fax = response.data.content.fax;
          self.email = response.data.content.email;
          self.countryId = {
            value: response.data.content.country_id,
            text: response.data.content.countryName,
          };
          self.stateId = {
            value: response.data.content.state_id,
            text: response.data.content.stateName,
          };
          self.cityId = {
            value: response.data.content.city_id,
            text: response.data.content.cityName,
          };
          self.address = response.data.content.address;
          self.zipcode = response.data.content.zip_code;
          self.statusId = response.data.content.status;
          if (
            response.data.content.address2 != null &&
            response.data.content.address2 != ""
          ) {
            self.address2 = response.data.content.address2;
          }
          if (
            response.data.content.county != null &&
            response.data.content.county != ""
          ) {
            self.county = response.data.content.county;
          }
          if (
            response.data.content.bill_addr1 != null &&
            response.data.content.bill_addr1 != ""
          ) {
            self.billAddress1 = response.data.content.bill_addr1;
          }
          if (
            response.data.content.bill_addr2 != null &&
            response.data.content.bill_addr2 != ""
          ) {
            self.billAddress2 = response.data.content.bill_addr2;
          }
          if (
            response.data.content.bill_country_id != null &&
            response.data.content.bill_country_id != ""
          ) {
            self.billCountry = {
              value: response.data.content.bill_country_id,
              text: response.data.content.countryName,
            };
          }
          if (
            response.data.content.bill_state_id != null &&
            response.data.content.bill_state_id != ""
          ) {
            self.billState = {
              value: response.data.content.bill_state_id,
              text: response.data.content.stateName,
            };
          }
          if (
            response.data.content.bill_city_id != null &&
            response.data.content.bill_city_id != ""
          ) {
            self.billCity = {
              value: response.data.content.bill_city_id,
              text: response.data.content.cityName,
            };
          }
          if (
            response.data.content.bill_county != null &&
            response.data.content.bill_county != ""
          ) {
            self.billCounty = response.data.content.bill_county;
          }
          if (
            response.data.content.bill_post != null &&
            response.data.content.bill_post != ""
          ) {
            self.billPost = response.data.content.bill_post;
          }
          if (
            response.data.content.bill_phone1 != null &&
            response.data.content.bill_phone1 != ""
          ) {
            self.billPhone1 = response.data.content.bill_phone1;
          }
          if (
            response.data.content.bill_phone2 != null &&
            response.data.content.bill_phone2 != ""
          ) {
            self.billPhone2 = response.data.content.bill_phone2;
          }
          if (
            response.data.content.bill_fax != null &&
            response.data.content.bill_fax != ""
          ) {
            self.billFax = response.data.content.bill_fax;
          }
          if (
            response.data.content.bill_contact != null &&
            response.data.content.bill_contact != ""
          ) {
            self.billContact = response.data.content.bill_contact;
          }
          if (
            response.data.content.bill_ven_id != null &&
            response.data.content.bill_ven_id != ""
          ) {
            self.billVenId = response.data.content.bill_ven_id;
          }
          if (
            response.data.content.dsb_typ_id != null &&
            response.data.content.dsb_typ_id != ""
          ) {
            self.dsbTypId = response.data.content.dsb_typ_id;
          }
          if (
            response.data.content.mem_typ_id != null &&
            response.data.content.mem_typ_id != ""
          ) {
            self.memTypId = response.data.content.mem_typ_id;
          }
          if (
            response.data.content.rec_typ_id != null &&
            response.data.content.rec_typ_id != ""
          ) {
            self.recTypId = response.data.content.rec_typ_id;
          }
          if (
            response.data.content.pt_pur_typ_id != null &&
            response.data.content.pt_pur_typ_id != ""
          ) {
            self.ptPurTypId = response.data.content.pt_pur_typ_id;
          }
          if (
            response.data.content.sv_pur_typ_id != null &&
            response.data.content.sv_pur_typ_id != ""
          ) {
            self.svPurTypId = response.data.content.sv_pur_typ_id;
          }
          if (
            response.data.content.vh_pur_typ_id != null &&
            response.data.content.vh_pur_typ_id != ""
          ) {
            self.vhPurTypId = response.data.content.vh_pur_typ_id;
          }
          if (
            response.data.content.fl_pur_typ_id != null &&
            response.data.content.fl_pur_typ_id != ""
          ) {
            self.flPurTypId = response.data.content.fl_pur_typ_id;
          }
          if (
            response.data.content.acct_nbr != null &&
            response.data.content.acct_nbr != ""
          ) {
            self.acctNbr = response.data.content.acct_nbr;
          }
          if (
            response.data.content.typ != null &&
            response.data.content.typ != ""
          ) {
            self.typ = response.data.content.typ;
          }
          if (
            response.data.content.trm_id != null &&
            response.data.content.trm_id != ""
          ) {
            self.trmId = response.data.content.trm_id;
          }
          if (
            response.data.content.type1099 != null &&
            response.data.content.type1099 != ""
          ) {
            self.type1099 = response.data.content.type1099;
          }
          if (
            response.data.content.tax_id != null &&
            response.data.content.tax_id != ""
          ) {
            self.taxId = response.data.content.tax_id;
          }
          if (
            response.data.content.ssn != null &&
            response.data.content.ssn != ""
          ) {
            self.ssn = response.data.content.ssn;
          }
          if (response.data.content.core_invoice_reqd != null) {
            self.coreInvoiceReqd =
              response.data.content.core_invoice_reqd == "1"
                ? { text: "Yes", value: "yes" }
                : { text: "No", value: "no" };
          }
          if (
            response.data.content.emp_id != null &&
            response.data.content.emp_id != ""
          ) {
            self.empId = response.data.content.emp_id;
          }
          if (
            response.data.content.excede_date_created != null &&
            response.data.content.excede_date_created != ""
          ) {
            self.excedeDateCreate = self.datePickerDateFormat(
              response.data.content.excede_date_created
            );
          }
          if (
            response.data.content.excede_date_update != null &&
            response.data.content.excede_date_update != ""
          ) {
            self.excedeDateUpdate = self.datePickerDateFormat(
              response.data.content.excede_date_update
            );
          }
          if (
            response.data.content.excede_date_invoice != null &&
            response.data.content.excede_date_invoice != ""
          ) {
            self.excedeDateInvoice = self.datePickerDateFormat(
              response.data.content.excede_date_invoice
            );
          }
          if (
            response.data.content.amt_invoice != null &&
            response.data.content.amt_invoice != ""
          ) {
            self.amtInvoice = response.data.content.amt_invoice;
          }
          if (
            response.data.content.excede_date_payment != null &&
            response.data.content.excede_date_payment != ""
          ) {
            self.excedeDatePayment = self.datePickerDateFormat(
              response.data.content.excede_date_payment
            );
          }
          if (
            response.data.content.amt_payment != null &&
            response.data.content.amt_payment != ""
          ) {
            self.amtPayment = response.data.content.amt_payment;
          }
          if (
            response.data.content.check_number != null &&
            response.data.content.check_number != ""
          ) {
            self.checkNumber = response.data.content.check_number;
          }
          if (
            response.data.content.not_id != null &&
            response.data.content.not_id != ""
          ) {
            self.notId = response.data.content.not_id;
          }
          if (
            response.data.content.ven_misc1 != null &&
            response.data.content.ven_misc1 != ""
          ) {
            self.venMisc1 = response.data.content.ven_misc1;
          }
          if (
            response.data.content.ven_misc2 != null &&
            response.data.content.ven_misc2 != ""
          ) {
            self.venMisc2 = response.data.content.ven_misc2;
          }
          if (
            response.data.content.ven_misc3 != null &&
            response.data.content.ven_misc3 != ""
          ) {
            self.venMisc3 = response.data.content.ven_misc3;
          }
          if (
            response.data.content.ven_misc4 != null &&
            response.data.content.ven_misc4 != ""
          ) {
            self.venMisc4 = response.data.content.ven_misc4;
          }
          if (
            response.data.content.ven_misc5 != null &&
            response.data.content.ven_misc5 != ""
          ) {
            self.venMisc5 = response.data.content.ven_misc5;
          }
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    checkForm: function () {
      let checked = true;
      if (!self.firstName) {
        self.firstNameError = "Please enter first name";
        if (checked) {
          checked = false;
        }
      }
      if (!self.primaryPhone) {
        self.primaryPhoneError = "Please enter primary phone";
        if (checked) {
          checked = false;
        }
      }
      if (self.email && !self.validEmail(self.email)) {
        self.emailError = "Please enter valid email";
        if (checked) {
          checked = false;
        }
      }
      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }
      if (self.stateId == null || !self.stateId.value) {
        self.stateError = "Please select state";
        if (checked) {
          checked = false;
        }
      }
      if (self.cityId == null || !self.cityId.value) {
        self.cityError = "Please select city";
        if (checked) {
          checked = false;
        }
      }
      if (self.billPhone1 && !self.validContactNumber(self.billPhone1)) {
        self.billPhone1Error = "Please enter valid Bill Phone1";
        if (checked) {
          checked = false;
        }
      }
      if (self.billPhone1 && !self.checkContactNoLength(self.billPhone1)) {
        self.billPhone1Error = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      if (self.billPhone2 && !self.validContactNumber(self.billPhone2)) {
        self.billPhone2Error = "Please enter valid Bill Phone2";
        if (checked) {
          checked = false;
        }
      }
      if (self.billPhone2 && !self.checkContactNoLength(self.billPhone2)) {
        self.billPhone2Error = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      if (self.billFax && !self.validContactNumber(self.billFax)) {
        self.billFaxError = "Please enter valid bill fax";
        if (checked) {
          checked = false;
        }
      }
      self.amtInvoiceError = "";
      if (self.amtInvoice && !self.numberFormateValidation(self.amtInvoice)) {
        self.amtInvoiceError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.amtPaymentError = "";
      if (self.amtPayment && !self.numberFormateValidation(self.amtPayment)) {
        self.amtPaymentError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    addVendor(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };
          let data = new FormData();
          let vendorId = "";
          if (self.vendorId == null || !self.vendorId.value) {
            vendorId = "";
          } else {
            vendorId = self.vendorId.value;
          }
          data.append("group_id", vendorId);
          data.append("first_name", self.firstName);
          data.append("last_name", self.lastName);
          data.append("company_name", self.companyName);
          data.append("primary_phone", self.primaryPhone);
          data.append("alternate_phone", self.alternatePhone);
          data.append("email", self.email);
          data.append("fax", self.fax);
          data.append("country_id", self.countryId.value);
          data.append("state_id", self.stateId.value);
          data.append("city_id", self.cityId.value);
          data.append("address", self.address);
          data.append("zip_code", self.zipcode);
          data.append("status", self.statusId);
          data.append("middle_name", self.middleName);
          data.append("contact_name", self.cntName);
          data.append("address2", self.address2);
          data.append("county", self.county);
          data.append("bill_address1", self.billAddress1);
          data.append("bill_address2", self.billAddress2);
          data.append("bill_country", self.billCountry.value);
          data.append("bill_state", self.billState.value);
          data.append("bill_city", self.billCity.value);
          data.append("bill_county", self.billCounty);
          data.append("bill_post", self.billPost);
          data.append("bill_phone1", self.billPhone1);
          data.append("bill_phone2", self.billPhone2);
          data.append("bill_fax", self.billFax);
          data.append("bill_contact", self.billContact);
          data.append("bill_ven_id", self.billVenId);
          data.append("dsb_typ_id", self.dsbTypId);
          data.append("mem_typ_id", self.memTypId);
          data.append("rec_typ_id", self.recTypId);
          data.append("pt_pur_typ_id", self.ptPurTypId);
          data.append("sv_pur_typ_id", self.svPurTypId);
          data.append("vh_pur_typ_id", self.vhPurTypId);
          data.append("fl_pur_typ_id", self.flPurTypId);
          data.append("acct_nbr", self.acctNbr);
          data.append("typ", self.typ);
          data.append("trm_id", self.trmId);
          data.append("type1099", self.type1099);
          data.append("tax_id", self.taxId);
          data.append("ssn", self.ssn);
          let coreInvoiceReqd = "";
          if (self.coreInvoiceReqd == null || !self.coreInvoiceReqd.value) {
            coreInvoiceReqd = "";
          } else {
            coreInvoiceReqd = self.coreInvoiceReqd.value;
          }
          data.append("core_invoice_reqd", coreInvoiceReqd);
          data.append("emp_id", self.empId);
          data.append("excede_date_create", self.excedeDateCreate);
          data.append("excede_date_update", self.excedeDateUpdate);
          data.append(
            "excede_date_invoice",
            self.apiRequestDateFormat(self.excedeDateInvoice)
          );
          data.append("amt_invoice", self.amtInvoice);
          data.append(
            "excede_date_payment",
            self.apiRequestDateFormat(self.excedeDatePayment)
          );
          data.append("amt_payment", self.amtPayment);
          data.append("check_number", self.checkNumber);
          data.append("not_id", self.notId);
          data.append("ven_misc1", self.venMisc1);
          data.append("ven_misc2", self.venMisc2);
          data.append("ven_misc3", self.venMisc3);
          data.append("ven_misc4", self.venMisc4);
          data.append("ven_misc5", self.venMisc5);
          if (
            typeof self.$route.params.id != "undefined" &&
            self.$route.params.id != 0 &&
            self.$route.params.id != ""
          ) {
            data.append("id", self.$route.params.id);
            data.append("code", self.code);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post("/vendor/add/update", data, config)
            .then(function (response) {
              if (
                typeof self.$route.params.id != "undefined" &&
                self.$route.params.id != 0 &&
                self.$route.params.id != ""
              ) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$route.params.id != 0 && self.$route.params.id != "") {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function () {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  let err = "";
                  for (let i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function (err) {
              if (self.$route.params.id != 0 && self.$route.params.id != "") {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function () {});
            });
        }
      }, 200);
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
