<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_deals')">
            <!--page content-->
            <div class="admin-page-content">
                <!-- List -->
                <div class="col-12 paddingnone">
                    <list-opportunity-deals-tab 
                        v-bind:permissionNameArray=permissionNameArray
                        @updateParentDisplayMethod="updateParentDisplayMethod"
                        v-show="permissionNameArray.includes('can_list_deals')"
                        >
                    </list-opportunity-deals-tab>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import ListOpportunityDealsTab from "@/components/OpportunityMaster/DealsTab/ListOpportunityDealsTab";
    Vue.component("app-footer", Footer);

    export default {
        name: 'OpportunityDealsTab',
        components: {
            "list-opportunity-deals-tab" : ListOpportunityDealsTab,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticInputLabelMessages: "",
            }
        },
        props: {
            permissionNameArray: "",
            opportunity: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('opportunity-list');
            EventBus.$emit("menu-selection-changed", "opportunity-list");
            $("#alertError").hide();
        },
        methods: {
            updateParentDisplayMethod : function (actTab) {
                self.$emit('updateDisplayData',actTab);
            },
        }
    }
</script>