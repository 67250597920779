<template>
  <div class="card">
    <div class="card-header">
      <h2 class="mb-0">
        <button
          class="btn btn-link btn-block text-left"
          type="button"
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          Estimate's
        </button>
      </h2>
    </div>
    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree">
      <div class="card-body">
        <div class="table-responsive">
          <!-- TABLE -->
          <table class="table custom-table jsmartable">
            <tbody>
              <template v-if="estimateArr && estimateArr.length > 0">
                <tr v-for="(estimateArr, index) in estimateArr" :key="index">
                  <td align="center">
                    <input
                      type="checkbox"
                      id="ro_checkbox"
                      class="form-check-input"
                      v-model="isCostSheetInputChecked"
                      @change="e => {addSubToTotal(e,estimateArr.amt_subtotal)}"
                    />
                  </td>
                  <td>
                    {{ estimateArr.created_at }}
                  </td>
                  <td>
                    {{ estimateArr.status_des }}
                  </td>
                  <td>
                    <span
                        role="button"
                        title="Click to View"
                        class="text-primary"
                        @click="getDetail(estimateArr)"
                        >{{ estimateArr.sls_id }}</span
                    >
                  </td>
                  <td>{{ estimateArr.status_name }}</td>
                  <td>{{ estimateArr.cus_name }}</td>
                  <td>
                    {{ estimateArr.amt_subtotal }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="7" class="text-alignment-center">
                    {{ allStaticMessages.data_not_available }}
                  </td>
                </tr>
              </template>
            </tbody>
            <thead>
              <tr>
                <th class="" data-breakpoint="xs">Add to Cost</th>
                <th class="" data-breakpoint="xs">Creation Date</th>
                <th class="" data-breakpoint="xs">Status</th>
                <th class="" data-breakpoint="xs">PO#</th>
                <th class="" data-breakpoint="xs">Sales Type</th>
                <th class="" data-breakpoint="xs">Bill Customer</th>
                <th class="" data-breakpoint="xs">Sub Total</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import eventBus from '../../../event-bus';
  var thisSelfTest;

  export default {
      name: 'Estimates',
      props: ['estimateArr', 'allStaticMessages'],
      data() {
        return {
          isCostSheetInputChecked: false
        };
      },
      beforeMount() {
        thisSelfTest = this;
      },
      methods: {
        addSubToTotal(e, amount) {
          eventBus.$emit("add-subtract-total",e.target.checked,amount);
        },
        getDetail(param) {
          eventBus.$emit("show-details", param, "estimate");
        }
      }
  }

  eventBus.$on("close-cost-sheet", (status) => {
    Object.assign(thisSelfTest.$data, thisSelfTest.$options.data());
  });
</script>