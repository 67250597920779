<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid">
            <div class="admin-page-content contact-dashboard">
                <div class="row mb-5">
                    <div class="col-md-12 page_title">
                        <span>Dashboard</span>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="cards-block default-bx-shadow cust-spend-box bg-purple">
                            <div class="card">
                                <div class="card-body">
                                    <i class="fa fa-file-text-o box-icon"></i>
                                    <div class="bold users-count">200</div>
                                    <div class="sub-title">Total active customers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="cards-block default-bx-shadow cust-spend-box bg-green">
                            <div class="card">
                                <div class="card-body">
                                    <i class="fa fa-file-image-o box-icon"></i>
                                    <div class="bold users-count">200</div>
                                    <div class="sub-title">Total active customers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="cards-block default-bx-shadow cust-spend-box bg-orange">
                            <div class="card">
                                <div class="card-body">
                                    <i class="fa fa-address-card-o box-icon"></i>
                                    <div class="bold users-count">200</div>
                                    <div class="sub-title">Total active customers</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-3 col-md-6 form-group">
                        <date-picker
                            class="form-control"
                            placeholder="Date"
                            autocomplete="off"
                            name="excedeDateInvoice">
                        </date-picker>
                        <i class="fa fa-calendar calendar-icon"></i>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <v-select 
                            name="customPerPage"
                            id="customPerPage"
                            label="text"
                            class=""
                            placeholder="Select">
                        </v-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="chartData"
                                        :options="chartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="truckBuyCountChartData"
                                        :options="truckBuyCountChartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="truckBuyAmountChartData"
                                        :options="truckBuyAmountChartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="truckSalesByTypeChartData"
                                        :options="truckSalesByTypeChartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="avgSpendingOnServiceChartData"
                                        :options="avgSpendingOnServiceChartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="cards-block default-bx-shadow">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4">
                                        <span class="mr-5">
                                            <strong class="bold">Total Sales:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Customers:</strong> 2500
                                        </span>
                                        <span class="mr-5">
                                            <strong class="bold">Total Trucks:</strong> 2500
                                        </span>
                                    </div>
                                    <GChart
                                        :chart-type="'BarChart'"
                                        :type="'BarChart'"
                                        :data="avgSpendingOnPartsChartData"
                                        :options="avgSpendingOnPartsChartOptions"
                                        :width="'100%'"
                                        :height="'400px'"
                                        ></GChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container">
                    <GChart
                        :chart-type="'BarChart'"
                        :type="'BarChart'"
                        :data="chartData"
                        :options="chartOptions"
                        :width="'100%'"
                        :height="'400px'"
                        ></GChart>
                </div>


                <div class="container">
                    <GChart
                        :chart-type="'BarChart'"
                        :type="'BarChart'"
                        :data="truckBuyChartData"
                        :options="truckBuyChartOptions"
                        :width="'100%'"
                        :height="'400px'"
                        ></GChart>
                </div> -->
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</template>
<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    Vue.component("app-footer", Footer);
    // import VueGoogleCharts from 'vue-google-charts';
    // Vue.use(VueGoogleCharts);
    import { GChart } from 'vue-google-charts/legacy';
    import datePicker from 'vue-bootstrap-datetimepicker';

    export default {
        name: 'CustomerDashboard',
        mixins: [commonMixins],
        components: {
            GChart,
            datePicker
        },
        data () {
            return {
                chartData: [
                    ['Credit Limit', 'Customer'],
                    ['$0', 38194],
                    ['$500', 81750],
                    ['$1000', 75521],
                    ['$1500', 22419],
                    ['$2000', 35722]
                ],
                chartOptions: {
                    title: 'Customer Count By Credit Limit',
                    bars: 'vertical' // or 'horizontal'
                },

                truckBuyCountChartData: [
                    ['Trucks', 'Customer'],
                    ['0', 38194],
                    ['1', 81750],
                    ['2', 75521],
                    ['3', 22419],
                    ['4', 35722]
                ],
                truckBuyCountChartOptions: {
                    title: 'Customer Count By Trucks Buy',
                    bars: 'vertical' // or 'horizontal'
                },

                truckBuyAmountChartData: [
                    ['Amount', 'Customer'],
                    ['$0', 38194],
                    ['$1000', 81750],
                    ['$2000', 75521],
                    ['$3000', 22419],
                    ['$4000', 35722]
                ],
                truckBuyAmountChartOptions: {
                    title: 'Customer Count By Avg. Spending On Truck Buy',
                    bars: 'vertical' // or 'horizontal'
                },

                truckSalesByTypeChartData: [
                    ['Type', 'Customer'],
                    ['Fuso', 100],
                    ['MB', 225],
                    ['Freightliners', 115]
                ],
                truckSalesByTypeChartOptions: {
                    title: 'Customer Count They Prefereed To Buy Truck Type',
                    bars: 'vertical' // or 'horizontal'
                },

                avgSpendingOnServiceChartData: [
                    ['Amount', 'Customer'],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                ],
                avgSpendingOnServiceChartOptions: {
                    title: 'Customer Count By Avg. Spending On Service',
                    bars: 'vertical' // or 'horizontal'
                },

                avgSpendingOnPartsChartData: [
                    ['Amount', 'Customer'],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                    ['$1000', 100],
                    ['$2000', 225],
                ],
                avgSpendingOnPartsChartOptions: {
                    title: 'Customer Count By Avg. Spending On Parts',
                    bars: 'vertical' // or 'horizontal'
                },


            }
        },
        beforeMount() {
            self = this;
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        watch: {

        },
        mounted: function() {
            self.setActiveMenu('contact-dashboard');
            EventBus.$emit("menu-selection-changed", "contact-dashboard");
            self.fetchCustomerSummary();
        },
        methods: {
            fetchCustomerSummary : function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                self.showLoader();
                HTTP.get('/contacts/dashboard_data', config)
                    .then(response => {
                        console.info('/contact/dashboard_data response => ', response.data);
                        
                        // if (response.data.status == "success") {
                            // self.jumpToPage = 1;
                            // self.keyMonitor();
                            // self.loadContact();
                            // self.showSAlert(response.data.message,'success',false,3);
                        // }
                        self.hideLoader();
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            }
        }
    
    }
</script>
<style scoped>

</style>
