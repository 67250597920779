<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_invoice_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.invoice }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} {{ allLabelMessages.results_found }}</p>
            </div>
            <router-link
              v-show="permissionNameArray.includes('can_add_invoice_master')"
              class="btn btn-primary waves-effect pull-right ml-2"
              :title="allLabelMessages.add_invoice"
              v-bind:to="{ name: 'AddUpdateInvoice' }"
            >
              <span>{{ allLabelMessages.add_invoice }}</span>
            </router-link>

            <a
              id="refreshList"
              :title="allLabelMessages.refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
          </div>
          <!-- <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component> -->
          <div class="vdc-filters-container">
            <div v-on:click="toggleFilterBlock()" class="vdc-filters-block">
              <span>{{ allLabelMessages.filters }}</span>
              <span
                class="badge badge-primary badge-pill bg-color-098de1"
                v-if="filterCount > 0"
                >{{ filterCount }} {{ allLabelMessages.filter }}<template v-if="filterCount > 1"
                  >s</template
                >
                {{ allLabelMessages.applied }}</span
              >
              <button>
                <img src="/static/images/filter_icon.png" />
              </button>
            </div>
            <div v-show="isOpenFilterBlock" class="vdc-filters-area">
              <!--vin_no Filter-->
              <div class="vdc-filters-area-block">
                <label>{{ allLabelMessages.vin_no_message }}</label>
                <input
                  class="form-control"
                  v-on:keyup.enter="changepage()"
                  v-model="filters.vin_no"
                  :placeholder="allStaticMessages.vin_no_message"
                  id="vin_no"
                  name="vin_no"
                  type="text"
                />
              </div>
              <!-- Company Filter -->
              <div
                v-show="userCompany.length > 1"
                class="vdc-filters-area-block"
              >
                <label>{{ allLabelMessages.company_label_filter }}</label>
                <div class="col-12 paddingleft0">
                  <multiselect
                    v-model="filters.department"
                    :options="filterCompanyOptions"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="allStaticMessages.select_one"
                    label="text"
                    track-by="text"
                    :preselect-first="true"
                    class="form-control"
                    @input="changeDepartmentFilter()"
                  >
                    <!--Dropdown options Custom Slot-->
                    <template slot="option" slot-scope="props">
                      <div
                        v-if="
                          appliedDepartmentFilter.includes(props.option.value)
                        "
                        class="multiple_select_item selected_multiple_item"
                      >
                        <span>{{ props.option.text }}</span>
                      </div>
                      <div v-else class="multiple_select_item">
                        <span>{{ props.option.text }}</span>
                      </div>
                    </template>
                    <!-- Selection Custom Slot -->
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        class="multiselect__single"
                        v-if="values.length > 1 && !isOpen"
                        >{{ values.length }} {{ allLabelMessages.selected }}</span
                      >
                      <span
                        class="multiselect__single"
                        v-else-if="values.length == 1"
                        >{{ values[0].text }}</span
                      >
                      <span
                        class="multiselect__single pull-right"
                        v-on:click="clearDepartmentFilter()"
                        ><i class="fa fa-close" aria-hidden="true"></i>
                      </span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <!-- Location Filter -->
              <template v-if="isDepartmentSelected">
                <div class="vdc-filters-area-block">
                  <label>{{ allLabelMessages.location }}</label>
                  <div class="col-12 paddingleft0">
                    <multiselect
                      v-model="filters.location"
                      :options="filterlocationOptions"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :placeholder="allStaticMessages.select_one"
                      label="text"
                      track-by="text"
                      :preselect-first="true"
                      class="form-control"
                      @input="changeLocationFilter()"
                    >
                      <!--Dropdown options Custom Slot-->
                      <template slot="option" slot-scope="props">
                        <div
                          v-if="
                            appliedLocationFilter.includes(props.option.value)
                          "
                          class="multiple_select_item selected_multiple_item"
                        >
                          <span>{{ props.option.text }}</span>
                        </div>
                        <div v-else class="multiple_select_item">
                          <span>{{ props.option.text }}</span>
                        </div>
                      </template>
                      <!-- Selection Custom Slot -->
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                      >
                        <span
                          class="multiselect__single"
                          v-if="values.length > 1 && !isOpen"
                          >{{ values.length }} {{ allLabelMessages.selected }}</span
                        >
                        <span
                          class="multiselect__single"
                          v-else-if="values.length == 1"
                          >{{ values[0].text }}</span
                        >
                        <span
                          class="multiselect__single pull-right"
                          v-on:click="clearLocationFilter()"
                          ><i class="fa fa-close" aria-hidden="true"></i>
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Location" v-model="filters.location"  name="location"  type="text">-->
                </div>
              </template>
              <!-- type Filter -->
              <div class="vdc-filters-area-block">
                <label>{{ allLabelMessages.type_message }}</label>
                <input
                  class="form-control"
                  v-on:keyup.enter="changepage()"
                  v-model="filters.type"
                  :placeholder="allStaticMessages.type_message"
                  id="type"
                  name="type"
                  type="text"
                />
              </div>
              <!-- deposit Filter -->
              <div class="vdc-filters-area-block">
                <label>{{ allLabelMessages.deposit_message }}</label>
                <input
                  class="form-control"
                  v-on:keyup.enter="changepage()"
                  v-model="filters.deposit"
                  :placeholder="allStaticMessages.deposit_message"
                  id="deposit"
                  name="deposit"
                  type="text"
                />
              </div>
              <!-- deposit_ref_no Filter -->
              <div class="vdc-filters-area-block">
                <label>{{ allLabelMessages.deposit_ref_no_message }}</label>
                <input
                  class="form-control"
                  v-on:keyup.enter="changepage()"
                  v-model="filters.deposit_ref_no"
                  :placeholder="allStaticMessages.deposit_ref_no_message"
                  id="deposit_ref_no"
                  name="deposit_ref_no"
                  type="text"
                />
              </div>
              <div class="clearfix"></div>
              <div class="">
                <button class="btn btn-primary" v-on:click="changepage()">
                  {{ allLabelMessages.apply_filters }}
                </button>
                <button class="btn btn-primary" v-on:click="resetFilters()">
                  {{ allLabelMessages.reset_filters }}
                </button>
                <button class="btn btn-primary" v-on:click="resetSorting()">
                  {{ allLabelMessages.reset_sorting }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} {{ allLabelMessages.rows_selected }}
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="permissionNameArray.includes('can_delete_invoice_master')"
              type="button"
              v-on:click.prevent="deleteInvoice()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              {{ allLabelMessages.delete }} {{ selectedRows.length }} {{ allLabelMessages.records }}
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListInvoice"
              ref="commonLoaderListInvoice"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    :placeholder="allStaticMessages.select_per_page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">{{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    :placeholder="allStaticMessages.page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="invoiceArr && invoiceArr.length > 0">
                    <tr v-for="(invoice, index) in invoiceArr" :key="index">
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="invoice.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + invoice.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + invoice.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions">
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_add_invoice_master'
                              )
                            "
                            class="mar-right-0"
                            :title="allLabelMessages.edit_invoice"
                            v-bind:to="{
                              name: 'UpdateInvoice',
                              params: { id: invoice.id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>
                          <a
                            v-show="
                              permissionNameArray.includes(
                                'can_delete_invoice_master'
                              )
                            "
                            href="javascript:void(0)"
                            :title="allLabelMessages.delete_invoice"
                            class="mar-right-0"
                            v-on:click="deleteInvoice(invoice.id)"
                          >
                            <i
                              class="exceptEdit fa fa-trash-o"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <td :class="rowClass.vinNo">{{ invoice.vinNo }}</td>
                      <td :class="rowClass.department">
                        {{ invoice.department }}
                      </td>
                      <td :class="rowClass.locName">{{ invoice.locName }}</td>
                      <td :class="rowClass.type">{{ invoice.type }}</td>
                      <td :class="rowClass.deposit">{{ invoice.deposit }}</td>
                      <td :class="rowClass.deposit_ref_no">
                        {{ invoice.deposit_ref_no }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    :placeholder="allStaticMessages.select_per_page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">{{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    :placeholder="allStaticMessages.page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import Multiselect from "vue-multiselect";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListInvoice",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    Multiselect,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      invoiceArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_invoice_master"],
      actionDisplay: true,
      jumpToHistoryPage: 1,
      filterCount: 0,
      isOpenFilterBlock: false,
      userCompany: [],
      filterCompanyOptions: [],
      filterlocationOptions: [],
      appliedDepartmentFilter: [],
      appliedLocationFilter: [],
      strDepartmentId: "",
      loginUserLocation: [],
      isDepartmentSelected: false,
      filters: {
        vin_no: "",
        department: "",
        location: "",
        type: "",
        deposit: "",
        deposit_ref_no: "",
      },
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadInvoice();
            },
            currentPage: function () {
                self.loadInvoice();
            }*/
    userCompany: function () {
      self.loadAllData(
        ["Company"],
        false,
        self.loadCompanyDataCallback,
        "undefined",
        "",
        self.userCompany.join(",")
      );
    },
    "filters.department": function () {
      self.isDepartmentSelected = false;
      if (self.filters.department && self.filters.department.length > 0) {
        self.isDepartmentSelected = true;
      }
      var userObj = localStorage.getItem("user_obj");
      /* if user logged in than remove login button and registration button */
      if (userObj != null) {
        var userJson = JSON.parse(userObj);
        var userRole = userJson["roles"][0].id;
        if (
          userRole > 2 &&
          self.loginUserLocation &&
          self.loginUserLocation.length > 0
        ) {
          self.filterlocationOptions = [];
          self.$nextTick(function () {
            self.filters.location = "";
          });
          self.filterlocationOptions = [];
          var temp = [];
          $.each(self.filters.department, function (i) {
            var selectedCompanyId = self.filters.department[i].value;
            $.each(self.loginUserLocation, function (j) {
              var objLoc = self.loginUserLocation[j];
              if (selectedCompanyId == objLoc.companyId) {
                if (!temp.includes(objLoc.value)) {
                  temp.push(objLoc.value);
                  self.filterlocationOptions.push(objLoc);
                }
              }
            });
          });
          return false;
        }
      }
      self.strDepartmentId = "";
      self.appliedDepartmentFilter = [];
      $.each(self.filters.department, function (i) {
        self.appliedDepartmentFilter.push(self.filters.department[i].value);
        self.strDepartmentId += self.filters.department[i].value + ",";
      });
      self.strDepartmentId = self.strDepartmentId.substring(
        0,
        self.strDepartmentId.length - 1
      );
      if (self.strDepartmentId != "") {
        self.loadAllData(
          ["Location"],
          false,
          self.loadCityDataCallback,
          "undefined",
          "",
          self.strDepartmentId
        );
      }
    },
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.setActiveMenu("invoice-list");
    EventBus.$emit("menu-selection-changed", "invoice-list");
    var listHistoryData = self.loadFilters("listInvoice", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadInvoice(1);
    self.getLoginIdPermissions();
  },
  methods: {
    toggleFilterBlock: function () {
      $(window).scrollTop($(window).scrollTop() + 1);
      self.isOpenFilterBlock = !self.isOpenFilterBlock;
    },
    resetFilters: function () {
      self.filters.vin_no = "";
      self.filters.department = "";
      self.filters.location = "";
      self.filters.type = "";
      self.filters.deposit = "";
      self.filters.deposit_ref_no = "";
      self.loadInvoice(1);
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadInvoice();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadInvoice();
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadInvoice();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.invoiceArr) {
            self.selectedRows.push(self.invoiceArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.invoiceArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addInvoice: function () {
      // self.$router.push('/add/invoice');
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadInvoice();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    changeLocationFilter: function () {
      /* self.appliedLocationFilter = [];
                for (let i = 0; i < self.filters.location.length; i++) {
                    self.appliedLocationFilter.push(self.filters.location[i].value);
                } */
    },
    clearLocationFilter: function () {
      self.filters.location = "";
      self.appliedLocationFilter = [];
    },
    clearDepartmentFilter: function () {
      self.filters.department = "";
      self.appliedDepartmentFilter = [];
    },
    changeDepartmentFilter: function () {
      /* self.appliedDepartmentFilter = [];
                for (let i = 0; i < self.filters.department.length; i++) {
                    self.appliedDepartmentFilter.push(self.filters.department[i].value);
                } */
    },
    loadCityDataCallback: function (callbackData) {
      self.filterlocationOptions = callbackData.Location;
    },
    loadCompanyDataCallback: function (callbackData) {
      self.filterCompanyOptions = callbackData.Company;
    },
    loadInvoice: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadInvoice();
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
      };
      this.storeFilters("listInvoice", fields, false);
      $("#commonLoaderListInvoice")
        .find(".loading-container.lodingTable")
        .show();
      self.filterCount = self.filterCountFunction(self.filters);
      if (self.filterCount) {
        self.isOpenFilterBlock = true;
      }
      HTTP.post("/invoice/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListInvoice")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.status == 200) {
            self.clearSelection();
            self.headers = [];
            self.totalRecords = data.data.content.result;
            if (
              self.perPage == "All" &&
              self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
            ) {
              // alert(self.totalRecords);
              let seconds = 5000;
              self.$swal({
                type: "error",
                html: self.allStaticMessages
                  .too_many_data_to_display_browser_can_not_load,
                showConfirmButton: false,
                timer: seconds,
              });
              setTimeout(() => {
                self.perPage = 500;
                self.loadInvoice();
              }, seconds);
              /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPage = 15;
                                        self.loadInvoice();
                                    }
                                }); */
            }
            self.invoiceArr = data.data.content.data;
            self.headers = data.data.content.headers;
            // self.loadFilterInputs(data.data.content.headers,self.filters);
            self.loginUserLocation = data.data.content.logged_user_location;

            self.addColumnClass(self.headers);

            let testArray = [
              {
                text: 1,
                value: "2020-02-01",
              },
              {
                text: 2,
                value: "2020-02-02",
              },
              {
                text: 3,
                value: "2020-02-03",
              },
            ];
            for (let i = 0; i < self.headers.length; i++) {
              if (self.headers[i]["type"] == "drop_down") {
                self.headers[i]["options_array"] = testArray;
              }
            }
            if (data.data.content.per_page !== self.totalRecords) {
              self.perPage = parseInt(data.data.content.per_page);
              self.jumpToPage = parseInt(data.data.content.current_page);
            } else {
              if (self.perPage == "All") {
                self.jumpToPage = 1;
              }
              self.perPageValue = self.totalRecords;
              self.currentPage = 1;
            }
            self.setAlreadyAppliedFilters();
            self.startEndRecord = self.calculateStartRecordEndRecord(
              self.currentPage,
              self.perPage,
              self.totalRecords
            );
            self.sortFieldDisplay(self.columnName, self.sortType);
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListInvoice")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadInvoice();
      }
    },
    deleteInvoice: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Invoice(s)!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteIdArr: deleteArray,
              },
            };
            HTTP.delete("/invoice/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadInvoice();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_invoice_master") &&
      !self.permissionNameArray.includes("can_delete_invoice_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
EventBus.$on("login-company", function (companies) {
  if (typeof self != "undefined") {
    self.userCompany = [];
    for (let i = 0; i < companies.length; i++) {
      self.userCompany.push(companies[i].company);
      // console.log(JSON.stringify(companies[i]));
    }
    if (self.userCompany && self.userCompany.length == 1) {
      setTimeout(function () {
        self.isDepartmentSelected = true;
        self.filters.department = [{ value: self.userCompany[0], text: "" }];
      }, 300);
    }
  }
});
</script>
