<template>

    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_view_inventory_master')">
            <div class="container-fluid xyz">
                <!-- <filter-component :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent"></filter-component> -->
                <div class="spaced-container">
                    <!--<div class="col-12 page_title paddingnone">
                        <span>View Inventory</span>
                    </div>-->
                    <div class="col-md-12 paddingnone">
                        <div class="col-md-6 paddingnone float-left">

                            <!-- Image Details -->
                            <div class="row">
                                <div class="column">
                                    <div class="container">
                                        <img style="width: 100%" :src="mediaPath">
                                        <div v-show="mediaPath != null && mediaPath != ''" class="text-block">
                                            <p>{{imageCount}} Photos</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <span> {{lotLocation}} </span><br>
                                    <span class="color-098de1" style="/*color: #098de1;*/"> {{vinNo}} </span><br>
                                    <span> Available on {{availableDate}} </span><br>
                                    <span class="color-098de1" style="/*color: #098de1;*/;cursor: pointer;" v-on:click="openPrintableTab()"> {{ 'Printable Summary'}} </span><br>
                                </div>
                            </div>

                            <div class="clearfix"><br></div>
                            <template v-if="description != ''">
                                <!-- Description -->
                                <div class="card">
                                    <div class="card-header bg-primary">Description</div>

                                    <div class="card-body">
                                        <div class="table-listing white-bg border-blue border-radius-top-right">
                                            <table class="table custom-table jsmartable">
                                                <tr>
                                                    <td class="text-left">{{description}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"><br></div>
                            </template>

                            <template v-if="privateComment != ''">
                                <!-- Description -->
                                <div class="card">
                                    <div class="card-header bg-primary">Comment</div>

                                    <div class="card-body">
                                        <div class="table-listing white-bg border-blue border-radius-top-right">
                                            <table class="table custom-table jsmartable">
                                                <tr>
                                                    <td class="text-left">{{privateComment}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"><br></div>
                            </template>
                            <!-- Specification | Used -->
                            <div class="card">
                                <div class="card-header bg-primary">Specification | Used</div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="column">
                                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                                <table class="table custom-table jsmartable">
                                                    <tr v-show="odometer != '' && odometer != null"><td>ODOMETER </td><td>{{odometer}}</td></tr>
                                                    <tr v-show="engineMake != '' && engineMake != null"><td>ENGINE MAKE</td><td>{{engineMake}}</td></tr>
                                                    <tr v-show="engineModel != '' && engineModel != null"><td>ENGINE MODEL</td><td>{{engineModel}}</td></tr>
                                                    <tr v-show="fuelType != '' && fuelType != null"><td>FUEL TYPE</td><td>{{fuelType}}</td></tr>
                                                    <tr v-show="fuelTanks != '' && fuelTanks != null"><td>FUEL TANKS</td><td>{{fuelTanks}}</td></tr>
                                                    <tr v-show="transMake != '' && transMake != null"><td>TRANS MAKE</td><td>{{transMake}}</td></tr>
                                                    <tr v-show="transType != '' && transType != null"><td>TRANS TYPE</td><td>{{transType}}</td></tr>
                                                    <tr v-show="transModel != '' && transModel != null"><td>TRANS MODEL</td><td>{{transModel}}</td></tr>
                                                    <tr v-show="axleType != '' && axleType != null"><td>AXLE TYPE</td><td>{{axleType}}</td></tr>
                                                    <tr v-show="suspension != '' && suspension != null"><td>SUSPENSION</td> <td>{{suspension}}</td></tr>
                                                    <tr v-show="fifthWheel != '' && fifthWheel != null"><td>FRONT WHEELS</td><td>{{fifthWheel}}</td></tr>
                                                    <tr v-show="rearWheel != '' && rearWheel != null"><td>REAR WHEELS</td><td>{{rearWheel}}</td></tr>
                                                </table>
                                            </div>

                                        </div>
                                        <div class="column">
                                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                                <table class="table custom-table jsmartable">
                                                    <tr v-show="fifthWheel != '' && fifthWheel != null"><td>5TH WHEEL</td><td>{{fifthWheel}}</td></tr>
                                                    <tr v-show="tireSize != '' && tireSize != null"><td>TIRE SIZE</td><td>{{tireSize}}</td></tr>
                                                    <tr v-show="airCond != '' && airCond != null"><td>AIR COND</td><td>{{airCond}}</td></tr>
                                                    <tr v-show="cabType != '' && cabType != null"><td>CAB TYPE</td><td>{{cabType}}</td></tr>
                                                    <tr v-show="cabStyle != '' && cabStyle != null"><td>CAB STYLE</td><td>{{cabStyle}}</td></tr>
                                                    <tr v-show="interiorColor != '' && interiorColor != null"><td>INTERIOR COLOR</td><td>{{interiorColor}}</td></tr>
                                                    <tr v-show="exteriorColor != '' && exteriorColor != null"><td>EXTERIOR COLOR</td><td>{{exteriorColor}}</td></tr>
                                                    <tr v-show="fairing != '' && fairing != null"><td>FAIRING</td><td>{{fairing}}</td></tr>
                                                    <tr v-show="fairing != '' && fairing != null"><td>VISOR</td><td>{{visor}}</td></tr>
                                                    <tr v-show="bunkType != '' && bunkType != null"><td>BUNK TYPE</td><td>{{bunkType}}</td></tr>
                                                    <tr v-show="bunkHeater != '' && bunkHeater != null"><td>BUNK HEATER</td><td>{{bunkHeater}}</td></tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="clearfix"><br></div>

                            <!-- Price Change Tracker -->
                            <div class="card">
                                <div class="card-header bg-primary">Price Change Tracker</div>

                                <div v-if="priceTrackerArray.length > 0" class="card-body">
                                    <div class="table-listing white-bg border-blue border-radius-top-right">
                                        <table class="table custom-table jsmartable">
                                            <tr>
                                                <th class="text-left">Label </th>
                                                <th class="text-right">Old Amt.</th>
                                                <th class="text-right">Upd. Amt.</th>
                                                <th class="text-center">Upd. At</th>
                                                <th class="text-center">Upd. By</th>
                                            </tr>
                                                <template v-for="(data, index) in priceTrackerArray">
                                                <tr>
                                                    <td class="text-left">{{ data.display_label}}</td>
                                                    <td class="text-right">{{ data.old_amount}}</td>
                                                    <td class="text-right">{{ data.new_amount}}</td>
                                                    <td class="text-center">{{ data.created_at}}</td>
                                                    <td class="text-center">{{ data.user_first_name}} {{data.user_last_name}}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                                <div v-else class="card-body">
                                    <div class="row">
                                        <div class="column" style="width: 100%">
                                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                                <table class="table custom-table jsmartable">
                                                    <tr>
                                                        <td colspan="5">{{staticMessages.data_not_available}}</td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <div class="col-md-6 paddingnone float-left">
                            <div class="card">
                                <div class="card-header bg-primary">Financial Matrix</div>
                                <div class="card-body">
                                    <div class="table-listing white-bg border-blue border-radius-top-right">
                                        <table class="table custom-table jsmartable">

                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>ACQUIRED INVENTORY </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>ACQUIRED FROM </td> <td> </td></tr>-->
                                            <tr v-show="asset.content.asset_data.customer_name != '' && asset.content.asset_data.customer_name != null"><td>BUYER </td> <td> {{asset.content.asset_data.customer_name}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>FLOOR PLAN </td> <td> </td></tr>-->
                                            <tr v-show="availableDate != '' && availableDate != null"><td>AVAILABLE </td> <td> {{availableDate}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>PAID FOR INVENTORY</td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>AGE </td> <td> </td></tr>-->
                                            <tr v-show="minRetailUsd != '' && minRetailUsd != null"><td>MIN RETAIL </td> <td >{{minRetailUsd}} </td></tr>
                                            <tr v-show="askingRetailUsd != '' && askingRetailUsd != null"><td>RETAIL </td> <td > {{askingRetailUsd}} </td></tr>
                                            <tr v-show="wholeshaleUsd != '' && wholeshaleUsd != null"><td>WHOLESHALE </td> <td > {{wholeshaleUsd}} </td></tr>
                                            <tr v-show="asset.content.asset_data.price_paid != '' && asset.content.asset_data.price_paid != null"><td>PURCHASE PRICE </td> <td> {{asset.content.asset_data.price_paid_display}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>INTEREST </td> <td> </td></tr>-->
                                            <tr v-show="asset.content.asset_data.purchase_order != '' && asset.content.asset_data.purchase_order != null"><td>PURCHASE ORDERS </td> <td> {{asset.content.asset_data.purchase_order_display}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>WRITE DOWNS </td> <td> </td></tr>-->
                                            <tr v-show="asset.content.asset_data.running_cost != '' && asset.content.asset_data.running_cost != null"><td>RUNNING COST </td> <td> {{asset.content.asset_data.running_cost_display}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>PROJECTED </td> <td> </td></tr>-->
                                            <tr v-show="asset.content.asset_data.sale_date_display != '' && asset.content.asset_data.sale_date_display != null"><td>SOLD </td> <td> {{asset.content.asset_data.sale_date_display}}</td></tr>
                                            <tr v-show="asset.content.asset_data.price_sold_usd_display != '' && asset.content.asset_data.price_sold_usd_display != null"><td>SOLD TO </td> <td> {{asset.content.asset_data.customer_name}}</td></tr>
                                            <tr v-show="asset.content.asset_data.salesman_first_name != '' && asset.content.asset_data.salesman_first_name != null"><td>SELLER </td> <td> {{asset.content.asset_data.salesman_first_name}} {{asset.content.asset_data.salesman_last_name}}</td></tr>
                                            <tr v-show="asset.content.asset_data.price_sold_usd_display != '' && asset.content.asset_data.price_sold_usd_display != null"><td>SALE PRICE </td> <td> {{asset.content.asset_data.price_sold_usd_display}}</td></tr>
                                            <!--<tr v-show="bunkHeater != '' && bunkHeater != null"><td>RECV'D PAYMENT </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>NET PROFIT </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>STRAIGHT COST </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>BASE PROFIT </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>LOT FEE {{lot}}</td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>ADJUSTED </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>COMMISSION TYPE </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>COMMISSION </td> <td> </td></tr>
                                            <tr v-show="bunkHeater != '' && bunkHeater != null"><td>GORSS PROFIT </td> <td> </td></tr>-->
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div class="clearfix"><br></div>
                            <div v-if="activityTrackArray.length > 0" class="card">
                                <div class="card-header bg-primary">Activity Tracker</div>

                                <div class="card-body">
                                    <div class="table-listing white-bg border-blue border-radius-top-right">
                                        <table class="table custom-table jsmartable">
                                            <tr>
                                                <th class="text-left">Label </th>
                                                <th class="text-right">Old Data</th>
                                                <th class="text-right">New Data</th>
                                                <th class="text-center">Upd. At</th>
                                                <th class="text-center">Upd. By</th>
                                            </tr>
                                            <template v-for="(data, index) in activityTrackArray">
                                                <tr>
                                                    <td class="text-left">{{ data.display_label}}</td>
                                                    <td class="text-right">{{ data.old_value}}</td>
                                                    <td class="text-right">{{ data.new_value}}</td>
                                                    <td class="text-center">{{ data.created_at}}</td>
                                                    <td class="text-center">{{ data.user_name}}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header bg-primary">{{staticLabels.location_change_tracker}}</div>
                                <div v-if="locationTrackerArray.length > 0" class="card-body">
                                    <div class="table-listing white-bg border-blue border-radius-top-right">
                                        <table class="table custom-table jsmartable">
                                            <tr>
                                                <th class="text-left">{{staticLabels.label}}</th>
                                                <th class="text-left">{{staticLabels.event}}</th>
                                                <th class="text-right">{{staticLabels.old_loc}}</th>
                                                <th class="text-right">{{staticLabels.upd_loc}}</th>
                                                <th class="text-center">{{staticLabels.upd_at}}</th>
                                                <th class="text-center">{{staticLabels.upd_by}}</th>
                                            </tr>
                                                <template v-for="(data, index) in locationTrackerArray">
                                                <tr>
                                                    <td class="text-left">{{data.display_label}}</td>
                                                    <td class="text-left">{{data.event | capitalize}}</td>
                                                    <td class="text-right">{{data.old_value}}</td>
                                                    <td class="text-right">{{data.new_value}}</td>
                                                    <td class="text-center">{{data.created_at}}</td>
                                                    <td class="text-center">{{data.user_first_name}} {{data.user_last_name}}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                                <div v-else class="card-body">
                                    <div class="row">
                                        <div class="column" style="width: 100%">
                                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                                <table class="table custom-table jsmartable">
                                                    <tr>
                                                        <td colspan="5">{{staticMessages.data_not_available}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <!--<app-footer></app-footer>-->
    </div>
</template>

<script>
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ViewAsset',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        props: {
            permissionNameVewiAssetArray:"",
            asset : {
                type : Object,
                required : false,
                default : undefined
            }
        },
        data () {
            return {
                staticMessages: "",
                imageCount : "",

                odometer : "",
                description : "",
                engineMake : "",
                engineModel : "",
                fuelType : "",
                fuelTanks : "",
                transMake : "",
                transModel : "",
                transType : "",
                axleType : "",
                suspension : "",
                frontWheel : "",
                rearWheel : "",
                fifthWheel : "",
                tireSize : "",
                airCond : "",
                cabType : "",
                cabStyle : "",
                interiorColor : "",
                exteriorColor : "",
                fairing : "",
                visor : "",
                bunkType : "",
                bunkHeater : "",
                mediaPath : "",
                mediaThumb : "",
                wholeshaleUsd : "",
                minRetailUsd : "",
                askingRetailUsd : "",
                lotLocation : "",
                vinNo : "",
                availableDate : "",
                lot : "",
                priceTrackerArray : [],
                activityTrackArray : [],
                asset_id:"",
                privateComment: "",
                permissionNameArray: ['can_view_inventory_master'],
                staticLabels: "",
                locationTrackerArray: []
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticLabels = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.getData(self.asset);
            self.permissionNameArray = self.permissionNameVewiAssetArray;
            self.asset_id = self.$route.params.id;
            //self.getLoginIdPermissions();
        },
        methods: {
            openPrintableTab : function(){
                let routeData = this.$router.resolve({name: 'AssetPrintableSummary', query: {id: self.asset.content.asset_data.asset_id}});
                window.open(routeData.href, '_blank');

            },
            buttonBack() {
                self.$router.push({name : "ListAsset"});
            },
            getData : function(data) {

                if (data.content['price_track'].length > 0) {
                    self.priceTrackerArray = data.content['price_track'];

                    for (let i = 0; i < self.priceTrackerArray.length; i++) {
                        let displayNameArray = self.priceTrackerArray[i].label.split("_");

                        let displayName = "";

                        for (let j = 0; j < displayNameArray.length; j++) {
                            if (displayName == "") {
                                displayName = displayNameArray[j];
                            } else {
                                displayName = displayName + " " + displayNameArray[j];
                            }
                        }
                        self.priceTrackerArray[i].display_label = self.capitalEachLatter(displayName);
                    }
                }
                if (data.content['activity_track'].length > 0) {
                    self.activityTrackArray = data.content['activity_track'];

                    for (let i = 0; i < self.activityTrackArray.length; i++) {
                        let displayNameArray = self.activityTrackArray[i].data.split("_");

                        let displayName = "";

                        for (let j = 0; j < displayNameArray.length; j++) {
                            if (displayName == "") {
                                displayName = displayNameArray[j];
                            } else {
                                displayName = displayName + " " + displayNameArray[j];
                            }
                        }
                        self.activityTrackArray[i].display_label = self.capitalEachLatter(displayName);
                    }
                }
                if (data.content['location_track_data'].length > 0) {
                    self.locationTrackerArray = data.content['location_track_data'];
                    for (let i = 0; i < self.locationTrackerArray.length; i++) {
                        let displayNameArray = self.locationTrackerArray[i].label.split("_");
                        let displayName = "";
                        for (let j = 0; j < displayNameArray.length; j++) {
                            if (displayName == "") {
                                displayName = displayNameArray[j];
                            } else {
                                displayName = displayName + " " + displayNameArray[j];
                            }
                        }
                        self.locationTrackerArray[i].display_label = self.capitalEachLatter(displayName);
                    }
                }
                self.odometer = data.content['asset_data'].odometer;
                self.description = (data.content['asset_data'].description != null && data.content['asset_data'].description !='')?data.content['asset_data'].description:'';
                self.engineMake = data.content['asset_data'].engine_make;
                self.engineModel = data.content['asset_data'].engine_model;
                self.fuelType = data.content['asset_data'].fuel_type;
                self.fuelTanks = data.content['asset_data'].fuel_tanks;
                self.transMake = data.content['asset_data'].trans_make;
                self.transModel = data.content['asset_data'].trans_model;
                self.transType = data.content['asset_data'].transtype;
                self.axleType = data.content['asset_data'].axle_type;
                self.suspension = data.content['asset_data'].suspension;
                self.frontWheel = data.content['asset_data'].front_wheels;
                self.rearWheel = data.content['asset_data'].rear_wheels;
                self.fifthWheel = data.content['asset_data'].is_fifth_wheel;
                self.tireSize = data.content['asset_data'].tire_size;
                self.airCond = data.content['asset_data'].is_air_conditioning;
                self.cabType = data.content['asset_data'].cab_type;
                self.cabStyle = data.content['asset_data'].cab_style;
                self.interiorColor = data.content['asset_data'].interior_color;
                self.exteriorColor = data.content['asset_data'].exterior_color;
                self.fairing = data.content['asset_data'].fairing;
                self.visor = data.content['asset_data'].visor;
                self.bunkType = data.content['asset_data'].bunk_type;
                self.bunkHeater = data.content['asset_data'].bunk_heater;
                self.privateComment = data.content['asset_data'].private_comment;
                if (data.content['asset_data'].media_path != null && data.content['asset_data'].media_path != '') {
                    self.mediaPath = data.content['asset_data'].media_path;
                } else {
                    self.mediaPath = data.content['asset_data'].media_url;
                }
                if (data.content['asset_data'].media_thumb != null && data.content['asset_data'].media_thumb != '') {
                    self.mediaThumb = data.content['asset_data'].media_thumb;
                } else {
                    self.mediaThumb = data.content['asset_data'].media_url;
                }
                self.wholeshaleUsd = data.content['asset_data'].wholeshale_usd_display;
                self.minRetailUsd =  data.content['asset_data'].min_retail_usd_display;
                self.askingRetailUsd = data.content['asset_data'].asking_retail_usd_display;
                self.lotLocation = data.content['asset_data'].lot_location;
                self.vinNo = data.content['asset_data'].vin_no;
                self.lot = data.content['asset_data'].lot;

                self.imageCount = data.content['media'].length;

                if (data.content['asset_data'].available_date != null && data.content['asset_data'].available_date != '') {
                    self.availableDate = data.content['asset_data'].available_date;
                }
            },
        },
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>
<style>
    * {
        box-sizing: border-box;
    }

    .column {
        float: left;
        width: 50%;
        padding: 5px;
    }

    .row::after {
        clear: both;
        display: table;
    }

    /* Container holding the image and the text */
    .container {
        position: relative;
    }

    /* Bottom right text */
    .text-block {
        position: absolute;
        top: 10px;
        left: 20px;
        background-color: red;
        color: white;
        height: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 12px;
    }
</style>