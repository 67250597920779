<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="permissionNameArray.includes('can_list_finance_contract_master')"
    >
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Finance Contract</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              v-show="
                permissionNameArray.includes(
                  'can_import_finance_contract_master'
                )
              "
              id="importFinanceContract"
              title="Import"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="loadImportModal()"
            >
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </a>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
          </div>
          <div class="col-12 paddingnone">
            <!-- Pagination Start -->
            <common-loader
              refId="commonLoaderListFinanceContract"
              ref="commonLoaderListFinanceContract"
            >
            </common-loader>
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="frameArr && frameArr.length > 0">
                    <template v-for="(frame, index) in frameArr">
                      <tr class="mainDetails">
                        <td>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="selectedRows"
                              :value="frame.id"
                              @change="inputCheckbox($event)"
                              class="custom-control-input"
                              :id="'customCheck' + frame.id"
                              name="case[]"
                            />
                            <label
                              class="custom-control-label"
                              :for="'customCheck' + frame.id"
                            ></label>
                          </div>
                        </td>
                        <td v-show="actionDisplay">
                          <div class="actions">
                            <div class="actions">
                              <router-link
                                v-show="
                                  permissionNameArray.includes(
                                    'can_update_finance_contract_master'
                                  )
                                "
                                class="mar-right-0"
                                title="Edit Finance Contract"
                                v-bind:to="{
                                  name: 'UpdateFinanceContract',
                                  params: { id: frame.id },
                                }"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa fa-angle-right forward_icon"
                                ></i>
                              </router-link>
                            </div>
                          </div>
                        </td>
                        <td :class="rowClass.dealer">{{ frame.dealer }}</td>
                        <td :class="rowClass.external_contract_number">
                          {{ frame.external_contract_number }}
                        </td>
                        <td :class="rowClass.contract_status">
                          {{ frame.contract_status }}
                        </td>
                        <td :class="rowClass.cus_id">{{ frame.cus_id }}</td>
                        <td :class="rowClass.borrower_name">
                          {{ frame.borrower_name }}
                        </td>
                        <td :class="rowClass.contract_start_date">
                          {{ frame.contract_start_date }}
                        </td>
                        <td :class="rowClass.last_rental_payment_due_date">
                          {{ frame.last_rental_payment_due_date }}
                        </td>
                        <!-- <td :class="rowClass.vin_no">{{frame.vin_no}}</td> -->
                        <td :class="rowClass.financed_amount">
                          {{ frame.financed_amount }}
                        </td>
                        <td :class="rowClass.residual_amount">
                          {{ frame.residual_amount }}
                        </td>
                        <td :class="rowClass.borrower_email">
                          {{ frame.borrower_email }}
                        </td>
                        <td :class="rowClass.borrower_phone">
                          {{ frame.borrower_phone }}
                        </td>
                      </tr>
                      <tr class="extraDetails">
                        <td colspan="2"></td>
                        <td
                          :colspan="headers.length - 1"
                          class="text-alignment-left"
                        ></td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
        <!-- Import Modal -->
        <div class="modal fade" id="importModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="importModalLabel">
                  Import Finance Contract From Excel
                </h4>
              </div>
              <form
                class="form-inline"
                id="importFinanceContract"
                enctype="multipart/form-data"
              >
                <div class="modal-body">
                  <div class="col-8 px-0">
                    Select Excel File to Import Finance Contract
                    <input
                      type="file"
                      name="excl_file"
                      accept=".xlsx,.xls"
                      id="upload"
                      ref="files"
                      v-on:change="handleFilesUpload()"
                    />
                    <div class="form-group">
                      <label id="errorMsg" class="error"></label><br />
                    </div>
                  </div>
                  <div class="col-4 pr-0">
                    <a
                      id="demofinancecontracturl"
                      href="#" @click="getSignedUrlPath('demo/FinanceContractSheet.xlsx')"
                      class="btn general-button btn-info waves-effect m-r-20 float-right"
                    >
                      Download Demo
                    </a>
                  </div>
                </div>
                <div class="modal-footer" style="display: block; width: 100%">
                  <button
                    type="button"
                    v-on:click="importFile"
                    class="btn general-button btn-success waves-effect"
                    id="importBtn"
                  >
                    {{ importBtn }}
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                    id="importCloseBtn"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from "../event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListFinanceContract",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      frameArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_finance_contract_master"],
      actionDisplay: true,
      exportPORequest: false,
      accordianIds: [],
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      staticMessages: "",
      staticInputLabelMessages: "",
      files: [],
      importBtn: "Import",
      importFinanceContractInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  props: {
    customer: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    redirectUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    activetab: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  watch: {
    permissionNameArray: function () {
      if (
        !self.permissionNameArray.includes("can_list_finance_contract_master")
      ) {
      }
    },
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    /* Set the left sidebar finance contract menu active only when it is opened from the left sidebar */
    /* Otherwise it is mounted from the customer tab so there is no need to set the finance contract menu as active */
    if (this.$route.params.id == undefined){
      self.setActiveMenu("financecontract-list");
      EventBus.$emit("menu-selection-changed", "financecontract-list");
    }

    var listHistoryData = self.loadFilters("listFinanceContract", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadFinanceContractData(1);
    self.getLoginIdPermissions();
    self.initializeModals()
  },
  methods: {
    initializeModals() {
      self.importFinanceContractInstance = new bootstrap.Modal(
        document.getElementById("importModal")
      );
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    returnAccordianDisplayDataKeys: function () {
      let dataArray = [
        {
          field: "vin",
          label: "Vin",
          alt: "",
        },
        {
          field: "requested_amount",
          label: "Requested Amount",
          alt: "",
        },
      ];

      return dataArray;
    },
    toggleIcon: function (id) {
      self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadFinanceContractData();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadFinanceContractData();
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadFinanceContractData();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.frameArr) {
            self.selectedRows.push(self.frameArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.frameArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadFinanceContractData();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadFinanceContractData: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        return false;
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: false,
        export_selected_headers: false,
        asset_id: typeof self.asset === "undefined" ? 0 : self.asset,
        customer_id: typeof self.customer === "undefined" ? 0 : self.customer,
      };
      this.storeFilters("listFinanceContract", fields, false);
      $("#commonLoaderListFinanceContract")
        .find(".loading-container.lodingTable")
        .show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("financecontract/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListFinanceContract")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.status == 200) {
            self.clearSelection();
            self.headers = [];
            self.totalRecords = data.data.content.result;
            self.frameArr = data.data.content.data;
            $("#selectAllCheckBox").prop("checked", false);
            self.headers = data.data.content.headers;
            self.loadFilterInputs(data.data.content.headers, self.filters);

            self.addColumnClass(self.headers);

            let testArray = [
              {
                text: 1,
                value: "2020-02-01",
              },
              {
                text: 2,
                value: "2020-02-02",
              },
              {
                text: 3,
                value: "2020-02-03",
              },
            ];
            for (let i = 0; i < self.headers.length; i++) {
              if (self.headers[i]["type"] == "drop_down") {
                self.headers[i]["options_array"] = testArray;
              }
            }
            if (data.data.content.per_page !== self.totalRecords) {
              self.perPage = parseInt(data.data.content.per_page);
              self.jumpToPage = parseInt(data.data.content.current_page);
            } else {
              if (self.perPage == "All") {
                self.jumpToPage = 1;
              }
              self.perPageValue = self.totalRecords;
              self.currentPage = 1;
            }
            self.setAlreadyAppliedFilters();
            self.startEndRecord = self.calculateStartRecordEndRecord(
              self.currentPage,
              self.perPage,
              self.totalRecords
            );
            self.sortFieldDisplay(self.columnName, self.sortType);
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListFinanceContract")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadFinanceContractData();
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
    },
    updateCheckedExportHeaders: function () {
      if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
        self.isSelectAllExportHeader = true;
      } else {
        self.isSelectAllExportHeader = false;
      }
    },
    loadImportModal: function () {
      self.files = [];
      self.importFinanceContractInstance.show();
    },
    handleFilesUpload: function () {
      var self = this;
      let uploadedFiles = "";
      uploadedFiles = self.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        self.files = [uploadedFiles[i]];
      }
    },
    importFile: function () {
      if (
        typeof self.files === "undefined" ||
        self.files == "null" ||
        self.files == ""
      ) {
        self.showSAlert("Please upload Excel file.", "info", false);
      } else {
        var data = new FormData();
        let file = self.files[0];
        data.append("excl_file", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            "Content-Type": "multipart/form-data"
          },
        };
        self.importBtn = "Importing ...";
        $("#importBtn").prop("disabled", true);
        $("#importCloseBtn").prop("disabled", true);
        self.showLoader();
        HTTP.post("financecontract/import", data, config)
          .then((response) => {
            self.hideLoader();
            if (response.data.code == 200) {
              self.loadFinanceContractData();
              self.importFinanceContractInstance.hide();
              self.showSAlert(
                response.data.message +
                  "<br>" +
                  response.data.messageSkipRecords,
                "success",
                false
              );
            } else {
              self.showSAlert(response.data.message, "error", false);
            }
          })
          .catch(function (err) {
            self.importBtn = "Import";
            $("#importBtn").prop("disabled", false);
            $("#importCloseBtn").prop("disabled", false);
            self.hideLoader();
            self.catchHandler(err, function () {});
          });
      }
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
