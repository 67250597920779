<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_form_email_manager')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.form_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!--<input class="form-control" :value="formNameId.text" disabled=""/>-->
                                    <v-select title="Select Form Name"
                                        v-model="formNameId"
                                        @change="changedValue"
                                        @input="formNameError = ''"
                                        name="formName"
                                        id="formName"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="formNameArr"
                                        placeholder="Select Form Name">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{formNameError}}</span>
                                </div>
                            </div>

                            <!-- Company -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            multiple
                                            v-model="companyId"
                                            name="state"
                                            label="text"
                                            :filterable="false"
                                            :options="companyArr"
                                            @search="onSearchCompany"
                                            :clearable="false"
                                            @input="companyError = ''"
                                            :placeholder="staticMessages.company_place_holder"
                                            class="custom-ci-auto-search">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isCompanyRecordNotFound">
                                            {{staticMessages.company_place_holder}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyError}}</span>
                                </div>
                            </div>

                            <!-- User Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.user_list_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="userId"
                                              multiple
                                              name="userId"
                                              id="userId"
                                              label="text"
                                              @change="changedValue"
                                              @input="changedValueUser()"
                                              :filterable="false"
                                              :options="userArr"
                                              @search="onSearchSalesmanUser"
                                              :placeholder="staticMessages.user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userNameError}}</span>-->
                                </div>
                            </div>
                            <!--<div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="email" v-model="email" class="form-control" type="text"/>
                                    <span class="help is-danger">{{staticMessages.comma_separated_emails}}</span>
                                </div>
                            </div>-->

                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!--<input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>-->
                                    <vue-tags-input
                                            v-model="email"
                                            :tags="emailTagsArr"
                                            :validation="validation"
                                            @tags-changed="newTags => emailTagsArr = newTags"
                                            @before-adding-tag="checkEmailTag"
                                            placeholder=""
                                    />
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>

                        </div>
                        
                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addFormEmailManager()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUdpateFormEmailManager',
        mixins: [commonMixins],
        components: {
            VueTagsInput,
        },
        data () {
            return {
                email: "",
                emailError : "",
                emailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidEmailTag : true,
                staticInputLabelMessages: "",
                staticMessages: "",
                formNameId: "",
                formNameArr: [],
                userId: "",
                userArr:[],
                isUserRecordNotFound: false,
                // Error
                formNameError: "",
                userNameError: "",
                //
                companyId : "",
                companyArr : [],
                companyError : "",
                isCompanyRecordNotFound: false,
                buttonNames : {
                    save : 'Save',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle: 'Add Form Email Manager',
                permissionNameArray : ['can_add_form_email_manager'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('form-email-manager');
            self.getLoginIdPermissions();
            EventBus.$emit("menu-selection-changed", "form-email-manager");
            $("#alertError").hide();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getFormEmailManagerDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Form Email Manager";
            }
            /* self.loadAllData('Form', false, self.loadFormNameDataCallback);
            self.loadAllData('Company', false, self.loadCompanyDataCallback, "undefined", ''); */
            self.loadAllData(['Form', 'Company'], false, self.loadMasterDataCallback, "undefined", '');
        },
        watch : {
            companyId : function(val) {
                self.changeCompany(val);
            },
            /*emailTagsArr : function(){
                console.log(JSON.stringify(self.emailTagsArr));
            }*/
        },
        methods: {
            checkEmailTag: function(obj) {
                self.emailError = "";
                self.isValidEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter valid Email";
                } else obj.addTag();
            },
            changeCompany : function (id) {
                if (id != null && id != "" && typeof id != "undefined") {
                    let idArray = [];
                    var editId = 0;
                    if (self.$route.params.id != null && self.$route.params.id != "" && typeof (self.$route.params.id) != "undefined") {
                        editId = self.$route.params.id;
                    }

                    for (let i = 0; i < id.length; i++) {
                        idArray.push(id[i].value)
                    }

                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                        params: {
                            edit_id: editId,
                            company: idArray,
                            form_key : self.formNameId.value
                        }
                    };
                    HTTP.get('/formemailmanager/checkformcompanypair', config)
                        .then(response => {
                            if (response.data.status == "Success") {
                                self.disableButtons.save = true;
                                self.$swal({
                                    type : "error",
                                    text: response.data.message,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok',
                                });
                            }else{
                                self.disableButtons.save = false;
                            }
                        })
                        .catch(function (err) {
                            /* console.log(err); */
                            self.catchHandler(err, function () {});
                        });

                }

            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            buttonBack() {
                self.$router.push({ name: 'ListFormEmailManager'});
            },
            loadMasterDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.formNameArr = callbackData.Form;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            /* loadFormNameDataCallback: function (callbackData) {
                self.formNameArr = callbackData;
            }, */
            changedValue: function (value) {
                /*console.log(value);*/
            },
            changedValueUser: function () {
                self.userNameError = "";
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function(callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData;
                if(self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            getFormEmailManagerDetailsById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/formemailmanager/get', config)
                    .then(response => {
                        if (response.data.content.email != '' && response.data.content.email != null && typeof (response.data.content.email) != "undefined") {
                            let email = response.data.content.email;
                            email = email.split(",");
                            for (let i = 0; i < email.length; i++) {
                                self.emailTagsArr.push({"text":email[i].trim(),"tiClasses":["ti-valid"]});
                            }
                        }
                        // self.email = response.data.content.email;
                        self.formNameId = {'text': response.data.content.key, 'value': response.data.content.id};
                        self.userId = [];
                        for (var i = 0; i < response.data.content.users.length; i++) {
                            self.userId.push({'text': response.data.content.users[i].text, 'value': response.data.content.users[i].value});
                        }
                        self.companyId = [];
                        for (var i = 0; i < response.data.content.company.length; i++) {
                            self.companyId.push({'text': response.data.content.company[i].text, 'value': response.data.content.company[i].value});
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.formNameId == null || !self.formNameId.value) {
                    self.formNameError = "Please select Form Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.companyId.length == 0) {
                    self.companyError = "Please select company name";
                    if (checked) {
                        checked = false;
                    }
                }
                /*if (self.emailTagsArr.length == 0) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.isValidEmailTag === false) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.userId.length === 0) {
                    self.userNameError = "Please select User";
                    if (checked) {
                        checked = false;
                    }
                }*/
                return checked;
            },
            addFormEmailManager: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };

                        var user_id = '';
                        for (var i = 0; i < self.userId.length; i++) {
                            user_id += (self.userId[i].value) + ',';
                        }
                        user_id = user_id.replace(/,\s*$/, "");

                        let companyArr = [];
                        for (let i = 0; i < self.companyId.length; i++) {
                            companyArr.push(self.companyId[i]['value']);
                        }
                        var emailData = "";
                        if(self.emailTagsArr && self.emailTagsArr.length > 0) {
                            for(var index = 0;index<self.emailTagsArr.length;index++) {
                                if(!emailData.includes(self.emailTagsArr[index].text)) {
                                    var strLowerCase = self.emailTagsArr[index].text.toLowerCase();
                                    emailData += strLowerCase + ',';
                                }
                            }
                        }
                        emailData = emailData.replace(/,\s*$/, "");
                        var data = new FormData();
                        data.append('email', emailData);
                        data.append('form_key', self.formNameId.value);
                        data.append('users', user_id);
                        data.append('company', companyArr);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Update ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            data.append('id', 0);
                            self.buttonNames.save = "Saving ...";
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/formemailmanager/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                                if (response.data.status == "success") {
                                    self.showSAlert(response.data.message, 'success', false ,3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                            self.buttonNames.save = "Update";
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        } else {
                                            self.buttonNames.save = "Save";
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }
                                    }, 3000);
                                } else {
                                    if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>