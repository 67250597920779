<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_view_case_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">
                        Case Information
                    </div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Subject -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['subject']}}</span>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['description']}}</span>
                                </div>
                            </div>
                            <!-- Resolution -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.resolution_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['resolution']}}</span>
                                </div>
                            </div>
                            <!-- Source -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.source_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{formatString(caseViewObj['source'])}}</span>
                                </div>
                            </div>
                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['statusText']}}</span>
                                </div>
                            </div>
                            <!-- Priority -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{formatString(caseViewObj['priority'])}}</span>
                                </div>
                            </div>
                            <!-- Reported On -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reported_on_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['reportedOn']}}</span>
                                </div>
                            </div>
                            <!-- Assigned Internal User -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.assigned_internal_user_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['firstName'] + " " + caseViewObj['lastName']}}</span>
                                </div>
                            </div>
                            <!-- Customer Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['custName']}}</span>
                                </div>
                            </div>
                            <!-- Dispute Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dispute_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{caseViewObj['dispute_type_text']}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Forms-->
                </div>
                <!--page content-->
                <div class="page-header-top-container">
                    <div class="page-name">
                        Comments
                    </div>
                </div>
                <!--page content-->
                <div v-show="permissionNameArray.includes('can_see_comment_case_master')" class="admin-page-content">
                    <!--Forms-->
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="list-group" v-if="commentsArr.length > 0">
                                    <template v-for="(commentValue , index) in commentsArr">
                                        <div :key="index"> 
                                            <div class="mb-2 list-group-item">
                                                <div :class="'view comment_'+commentValue.id">
                                                    <a v-show="permissionNameArray.includes('can_add_comment_case_master')" class="btn edit-btn-vvg waves-effect pull-right" v-if="commentValue.created_by == loginId" v-on:click.prevent="onDeleteComment(commentValue.id,commentValue.message)"><i class="fa fa fa-trash-o"></i></a>
                                                    <a v-show="permissionNameArray.includes('can_add_comment_case_master')" class="btn edit-btn-vvg waves-effect pull-right" v-if="commentValue.created_by == loginId" v-on:click.prevent="onEditComment(commentValue.message, commentValue.id)"><i class="fa fa-angle-right"></i></a>
                                                    <h4 class="list-group-item-heading">{{commentValue.first_name + " " + commentValue.last_name}}</h4>
                                                    <p class="list-group-item-text">
                                                        <strong>Message : </strong>{{commentValue.message}}<br>
                                                        <span>{{commentValue.updated_at}}</span>
                                                        <span class="pull-right" v-if="commentValue.updated_by_name != ''">Updated by : {{commentValue.updated_by_name}}</span>
                                                    </p>
                                                </div> 
                                                <div style="display: none;" :class="'edit comment_'+commentValue.id"> 
                                                    <label for="comment" id="commenttitle">Edit Comment</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <textarea :name="'comment_'+commentValue.id" :id="'commentData_'+commentValue.id" rows="3"
                                                                class="form-control no-resize"
                                                                placeholder="Please type what you want..."
                                                                @input="commentValue.comment_validation = ''"
                                                            >{{commentValue.message}}
                                                            </textarea>
                                                            <span class="help is-danger">{{commentValue.comment_validation}}</span>
                                                            <!-- <span v-show="errors.has('comment_'+commentValue.id)" class="help is-danger" v-if="errors.has('comment_'+commentValue.id)"><font color="red">Comment Required</font></span> -->
                                                        </div>
                                                        <div class="col-12 mt-2">
                                                            <div class="col-12 paddingleft0">
                                                                <button type="button" v-on:click="addUpdateComments(commentValue.id,commentValue.message, index + 1);" class="btn general-button btn-primary">Update</button>
                                                                <button type="button" v-on:click="onClearEditComment(commentValue.id,commentValue.message);" class="btn general-button btn-danger" data-dismiss="modal">Clear</button>
                                                                <button type="button" v-on:click="onCancelComment(commentValue.id,commentValue.message)" class="btn general-button btn-secondary" data-dismiss="modal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </template>
                                </div>
                                <label v-show="permissionNameArray.includes('can_add_comment_case_master')" class="mt-3" for="comment" id="commenttitle"><strong>Add Comment</strong></label>
                                <div v-show="permissionNameArray.includes('can_add_comment_case_master')" class="row">
                                    <div class="col-sm-12">
                                        <textarea name="comment" id="commentData" rows="3"
                                            class="form-control no-resize"
                                            placeholder="Please type what you want..."
                                            @input="commentError = ''"
                                            v-model="comment">
                                        </textarea>
                                        <span class="help is-danger">{{commentError}}</span>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="col-12 paddingleft0">
                                            <button type="button" v-on:click="addUpdateComments(0, '', 0);" class="btn general-button btn-primary" id='addComment'>Add</button>
                                            <button type="button" v-on:click="onClearComment();" class="btn general-button btn-danger" data-dismiss="modal">Clear</button>  
                                            <button type="button" v-show="editCommentId != ''" v-on:click="onCancelComment();" class="btn general-button btn-danger" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <!--Forms-->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'ViewCaseMaster',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                caseViewObj: [],
                alertClass : '',
                alertMessage : '',
                comment: "",
                commentError: "",
                editCommentId: 0,
                loginId: 0,
                commentsArr: [],
                permissionNameArray : ['can_view_case_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('case-list');
            EventBus.$emit("menu-selection-changed", "case-list");

            $("#alertError").hide();
            self.loginId = 0;
            userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson.id;
            }
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getCaseViewById(self.$route.params.id);
                self.getComments(self.$route.params.id);
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/case/list');
            },
            getCaseViewById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/case/get', config)
                    .then(response => {
                        self.caseViewObj = response.data.content;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            onEditComment(message, id) {
                var self = this;
                $('.view.comment_' + id).hide();
                $('.edit.comment_' + id).show();
                $('#commentData_' + id).val(message);
            },
            onDeleteComment(id, message) {
                $('.comment_' + id).parent().css({"border": "1px solid #FF0000"});
                this.$swal({
                    title: 'Are you sure?',
                    text: "wan't to delete this Comment!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    $('.comment_' + id).parent().removeAttr("style");
                    if (result.value) {
                        self.showSAlert('Comment has been deleted.', 'success', false, 3);
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        };
                        var fields = {
                            id: id
                        };

                        HTTP.post("/case/comment/delete", fields, config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.getComments(self.$route.params.id);
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                })
            },
            onClearComment() {
                var self = this;
                self.comment = '';
                if (self.editId != '') {
                    $('#addComment').text('Update');
                    $('#commenttitle').html('Update Comment');
                } else {
                    $('#addComment').text('Add');
                    $('#commenttitle').html('Add Comment');
                }
                $(".error").html("");
            },
            onClearEditComment(id) {
                $('#commentData_' + id).val('');
            },
            onCancelComment(id, message) {
                var self = this;
                $('#commentData_' + id).val('' + message);
                $('.view.comment_' + id).show();
                $('.edit.comment_' + id).hide();
            },
            addUpdateComments: function(commentId, updateCommentMsg, commentIndex = 0) {
                if(commentIndex != 0) {
                    var strCom = $('#commentData_' + commentId).val();
                    if (!strCom) {
                        self.commentsArr[commentIndex - 1].comment_validation = 'Please Enter Commnet';
                        return false;
                    }
                } else {
                    if (!self.comment) {
                        self.commentError = 'Please Enter Commnet';
                        return false;
                    }
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };
                var fields = {
                    commentId: commentId,
                    caseId: self.$route.params.id,
                    commentData: (updateCommentMsg != '') ? $('#commentData_' + commentId).val() : self.comment
                };

                HTTP.post("/case/comment/add/update", fields, config)
                    .then(function (response) {
                        $('.view.comment_' + commentId).show();
                        $('.edit.comment_' + commentId).hide();
                        self.comment = '';
                        if (response.data.code == '200') {
                            self.getComments(self.$route.params.id);
                        }
                    })
                    .catch(function (error) {
                        $('.view.comment_' + commentId).show();
                        $('.edit.comment_' + commentId).hide();
                        self.catchHandler(error, function () {});
                    });
            },
            getComments: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/case/comment/get', config)
                    .then(response => {
                        self.commentsArr = response.data.content;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>  