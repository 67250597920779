<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_case_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Case</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <router-link v-show="permissionNameArray.includes('can_add_case_master')" class="btn btn-primary waves-effect pull-right ml-2" title='Add Case' v-bind:to="{name: 'AddUpdateCaseMaster'}">
                            <span>Add Case</span>
                        </router-link>
                        <a v-show="permissionNameArray.includes('can_import_case_master')" id='importCase' title='Import' class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="loadImportModal()">
                            <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        </a>
                        <a v-show="permissionNameArray.includes('can_export_case_master')" id='exportCase' title='Export' class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="exportCaseFuntion()">
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </a>
                        <a
                            id='refreshList'
                            title='Refresh'
                            class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                            v-on:click.prevent="loadCaseMaster()">
                                <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                    </div>
                    <filter-component :key="filterCount" :currentPageName="currentPageName" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button v-show="permissionNameArray.includes('can_delete_case_master')" type="button" v-on:click.prevent="deleteCase()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                    <template v-if="caseArr && caseArr.length > 0">
                                        <tr v-for="(caseData, index) in caseArr" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="caseData.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + caseData.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + caseData.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions" style="width: 130px;">
                                                    <template v-if="caseData.created_by_id == loginId">
                                                        <div class="actions">
                                                            <router-link v-show="permissionNameArray.includes('can_add_case_master')" class="mar-right-0" title='Edit Case' v-bind:to="{name: 'UpdateCaseMaster', params:{id: caseData.id}}">
                                                                <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                            </router-link>
                                                        </div>
                                                    </template>
                                                    <router-link v-show="permissionNameArray.includes('can_view_case_master')" class="mar-right-0" title='View Case' v-bind:to="{name: 'ViewCaseMaster', params:{id: caseData.id}}">
                                                        <i class="exceptEdit fa fa-eye" aria-hidden="true"></i>
                                                    </router-link>
                                                    <template v-if="caseData.created_by_id == loginId">
                                                        <a v-show="permissionNameArray.includes('can_delete_case_master')" href="javascript:void(0)" title='Delete Case' class="mar-right-0" v-on:click="deleteCase(caseData.id)">
                                                            <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                        </a>
                                                    </template>
                                                </div>
                                            </td>
                                            <td :class="rowClass.reported_on">{{caseData.reported_on}}</td>
                                            <td :class="rowClass.created_by">{{caseData.created_by}}</td>
                                            <td :class="rowClass.customer_name">{{caseData.customer_name}}</td>
                                            <td :class="rowClass.assigned_internal_user">{{caseData.assigned_internal_user}}</td>
                                            <td :class="rowClass.status">{{caseData.status}}</td>
                                            <td :class="rowClass.priority">{{caseData.priority}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
                <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="defaultModalLabel">Import Cases From Excel</h4>
                            </div>
                            <form class="form-inline" id="importCasesform" enctype="multipart/form-data">
                                <div class="modal-body">
                                    Select Excel File to Import Cases
                                    <input type="file" name="excl_file" accept=".xlsx,.xls" id="upload" ref="files" v-on:change="handleFilesUpload()"/>
                                    <div class="form-group">
                                        <label id="errorMsg" class="error"></label><br>
                                    </div>
                                    <a id ="demourl" href="#" @click="getSignedUrlPath('demo/Cases.xlsx')" class="btn general-button btn-info waves-effect m-r-20">
                                        Download Demo
                                    </a>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" v-on:click="importCases($event);" class="btn general-button btn-success waves-effect" id="caseImportBtn">{{importBtn}}</button>
                                    <button type="button" class="btn general-button btn-danger waves-effect" data-dismiss="modal" id="caseImportClose">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'ListCaseMaster',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
        },
        data () {
            return {
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [25, 50, 100, 200, 500, "All"],
                caseArr: [],
                headers: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord : '',
                selectAll: false,
                permissionNameArray : ['can_list_case_master'],
                actionDisplay : true,
                jumpToHistoryPage : 1,
                filterCount : 0,
                loginId: 0,
                exportCase: false,
                files: [],
                importBtn : 'Import',
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('case-list');
            EventBus.$emit("menu-selection-changed", "case-list");
            self.loginId = 0;
            userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson.id;
            }
            var initial = process.env.VUE_APP_ROOT_API;
            var initial = initial.replace('/v1', '');
            $("#demourl").attr("href", initial + '/demo/Cases.xlsx');
            self.listExecutionState = false;
            self.loadCaseMaster(1);
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadCaseMaster();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadCaseMaster();
                }
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadCaseMaster();
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.caseArr) {
                            self.selectedRows.push(self.caseArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.caseArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadCaseMaster();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadCaseMaster();
                }
            },
            exportCaseFuntion: function () {
                self.exportCase = true;
                if (self.exportCase == true) {
                    $('#exportCase').prop("disabled", true);
                    self.loadCaseMaster();
                }
            },
            deleteCase : function (id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if(id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Case!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data : {
                                deleteArr: deleteArray,
                            }
                        };
                        HTTP.delete("/case/delete", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.loadCaseMaster();
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
            loadCaseMaster: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    is_export: self.exportCase
                };
                this.storeFilters('listCase', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/case/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.resetImportModel();
                        $('#exportCase').prop("disabled", false);
                        self.exportCase = false;
                        if (data.status == 200) {
                            if(first){
                                self.currentPage = self.jumpToHistoryPage;
                            }
                            if (data.data.message == 'success export') {
                                self.downloadFile(data.data.content.web);
                                // location.href = data.data.content.web;
                            } else {
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                self.caseArr = data.data.content.data;
                                $("#selectAllCheckBox").prop("checked", false);
                                self.headers = data.data.content.headers;
                                self.loadFilterInputs(data.data.content.headers,self.filters);

                                self.addColumnClass(self.headers);

                                let testArray = [
                                    {
                                        'text' : 1,
                                        'value' : '2020-02-01'
                                    },
                                    {
                                        'text' : 2,
                                        'value' : '2020-02-02'
                                    },
                                    {
                                        'text' : 3,
                                        'value' : '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                if (data.data.content.per_page !== self.totalRecords) {
                                    self.perPage = parseInt(data.data.content.per_page);
                                    self.jumpToPage = parseInt(data.data.content.current_page);
                                } else {
                                    if(self.perPage == 'All'){
                                        self.jumpToPage = 1;
                                    }
                                    self.perPageValue = self.totalRecords;
                                    self.currentPage = 1;
                                }
                                self.setAlreadyAppliedFilters();
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                self.sortFieldDisplay(self.columnName,self.sortType);
                            }
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.resetImportModel();
                        self.hideLoader();
                        $('#exportCase').prop("disabled", false);
                        self.exportCase = false;
                        self.catchHandler(err, function () {});
                    })
            },
            loadImportModal: function() {
                self.files = [];
                $('#defaultModal').modal('show');
            },
            handleFilesUpload: function () {
                /*
                 Get the uploaded files from the input.
                 */
                var self = this;
                let uploadedFiles = "";
                uploadedFiles = self.$refs.files.files;
                //console.log(uploadedFiles);
                /*
                 Assign the uploaded file to the files array
                 */
                /*this.files.pop();*/
                for (var i = 0; i < uploadedFiles.length; i++) {
                    self.files = [uploadedFiles[i]];
                }
                /*this.getImagePreviews();*/
            },
            importCases: function() {
                if(typeof(self.files) === 'undefined' || self.files == 'null' || self.files == '') {
                    self.showSAlert("Please upload Excel file.", 'info', false);
                } else {
                    var data = new FormData();
                    for (var i = 0; i < self.files.length; i++) {
                        let file = self.files[0];
                        //console.log(file);
                        data.append('excl_file', file);
                    }
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                    };
                    self.importBtn = 'Importing ...';
                    $('#caseImportBtn').prop("disabled", true);
                    $('#caseImportClose').prop("disabled", true);
                    self.showLoader();
                    HTTP.post('/case/import', data, config)
                        .then(response => {
                            self.hideLoader();
                            if(response.data.code == 200) {
                                self.loadCaseMaster();
                                $('#defaultModal').modal('hide');
                                self.showSAlert(response.data.message + '<br>' + response.data.messageSkipRecords, 'success', false);
                            } else {
                                self.showSAlert(response.data.message, 'error', false);
                            }
                        })
                        .catch(function (err) {
                            self.importBtn = 'Import';
                            $('#caseImportBtn').prop("disabled", false);
                            $('#caseImportClose').prop("disabled", false);
                            self.hideLoader();
                            self.catchHandler(err, function () {});
                        });
                }
            },
            resetImportModel : function() {
                const file = document.querySelector('#upload');
                file.value = '';  // Remove File Name 
                self.files = ''; // Remove File List
                self.importBtn = 'Import';
                $('#caseImportBtn').prop("disabled", false);
                $('#caseImportClose').prop("disabled", false);
            }
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_add_case_master') && !self.permissionNameArray.includes('can_view_case_master') && !self.permissionNameArray.includes('can_delete_case_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });
</script>  