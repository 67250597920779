<template>
    <div class="modal" id="tradeDetail" tabindex="-1" role="dialog">
        <div v-if="isClickAddTrade" class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h4 class="modal-title" id="tradeDetailLabel">
                    Trade Detail
                </h4>
                <button type="button" class="close" aria-label="Close" @click="closeTradeDetailModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <table class="table custom-table jsmartable">
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Year:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="year" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Quantity:</span>
                                            <input type="number" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="quantity" hidden />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Make:</span>
                                            <v-select
                                                v-model="make"
                                                name="make"
                                                label="name"
                                                :filterable="false"
                                                :options="makeList"
                                                :clearable="true"
                                                v-on:change="populateRelatedModel(make.id)"
                                                class="custom-ci-auto-search"
                                                style="width: 200px; margin-left: 7rem !important;"
                                            >
                                            </v-select>
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Type:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="type" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Model:</span>
                                            <v-select
                                                v-model="model"
                                                name="model"
                                                label="name"
                                                :filterable="false"
                                                :options="modelList"
                                                :clearable="true"
                                                class="custom-ci-auto-search"
                                                style="width: 200px; margin-left: 7rem !important;"
                                            >
                                            </v-select>
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Stock#:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="stockId" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">VIN:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="vinNo" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Eng No:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="engNo" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Odometer:</span>
                                            <input type="number" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="odometer" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">License No:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="licenseNo" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Trade ACV:</span>
                                            <money v-bind="moneyMask" type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="tradeAcv" @keyup.native="event => {incrementDecrementNumber(event, 'tradeAcv')}" @blur.native="addToTradeInValue" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Lien Name:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="lienName" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Over Allowance:</span>
                                            <money v-bind="moneyMask" type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="overAllowance" @keyup.native="event => {incrementDecrementNumber(event, 'overAllowance')}" @blur.native="addToTradeInValue" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Lien Address:</span>
                                            <input type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="lienAddress" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Trade-in Value:</span>
                                            <money v-bind="moneyMask" type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="tradeInValue" disabled />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Payoff:</span>
                                            <money v-bind="moneyMask" type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="payOff" @keyup.native="event => {incrementDecrementNumber(event, 'payOff')}" />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Trade GST Credit:</span>
                                            <money v-bind="moneyMask" type="text" class="form-control" style="width: 200px; margin-left: 7rem !important;" v-model="tradeGstCredit" disabled />
                                            <!-- <div class="requiredstar">*</div> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="col-md-6">
                                        <input type="checkbox" v-model="safetyInspection"> <span>Safety inspection</span>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" v-model="tiresMinimumTread"> <span>Tires-Minimum tread 50%</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-md-6">
                                        <input type="checkbox" v-model="noBrokenGlass"> <span>No Broken Glass</span>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" v-model="noBodyDamage"> <span>No Body damage</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"> 
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="bold float-left">Trade Note:</span>
                                            <textarea cols="70" rows="4" style="margin-left: 2rem !important;" v-model="tradeNote"></textarea>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="savePurchaseOrder()">Save</button>
                    <button class="btn btn-secondary" @click="closeTradeDetailModal()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import eventBus from "../../event-bus";
import { HTTP } from "../../../http-common";
import {Money} from 'v-money';

export default {
  name: 'TradeDetail',
  components: {
      Money
    },  
  props: ['isClickAddTrade'],
  data() {
    return {
        year: '',
        make: '',
        quantity: 1,
        type: '',
        model: '',
        stockId: '',
        vinNo: '',
        engNo: '',
        odometer: 1,
        licenseNo: '',
        tradeAcv: 0,
        lienName: '',
        overAllowance: 0,
        lienAddress: '',
        tradeInValue: 0,
        payOff: 0,
        tradeGstCredit: 0,
        safetyInspection: false,
        tiresMinimumTread: false,
        noBrokenGlass: false,
        noBodyDamage: false,
        tradeNote: '',
        makeList: [],
        modelList: [],
        moneyMask: {
          thousands: ',',
          prefix: '$ ',
          precision: 2,
        },
    }
  },
  methods: {
    savePurchaseOrder() {
        return true;
    },
    closeTradeDetailModal() {
        eventBus.$emit("close-trade-detail", false);
        return true;
    },
    incrementDecrementNumber(event, dataInstance) {
        switch (event.key) {
          case 'ArrowUp':
            this[dataInstance] += 1;
            break;
          case 'ArrowDown':
            this[dataInstance] -= 1;
            break;
        }
    },
    addToTradeInValue() {
        this.tradeInValue = parseFloat(this.tradeAcv)+parseFloat(this.overAllowance);
    },
    populateRelatedModel(params) {
        if(!!params) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: {
                    make_id: this.make.id
                }
            };

            HTTP.get("/get/trade/make/model", config)
            .then(response => {
                this.modelList = response.data.content;
            });
        } 
    }
  },
  computed: {
    getMake() {
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token")
            }
        };

        HTTP.get("/get/trade/make/model", config)
        .then(response => {
            this.makeList = response.data.content;
        });
    },
  },
  watch: {
    isClickAddTrade(status) {
        if(status == true) {
            this.getMake;
        }
    }
  }     
}
</script>

<!-- <style scoped>
    .requiredstar{
        top: 0;
        left: 320px;
    }
    .help.is-danger {
        padding-left: 97px !important;
        position: relative;
    }
</style> -->