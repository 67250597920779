<template>
    <div>
        <template v-if="permissionNameArray.includes('can_email_customer_master')">
            <div class="col-12 page_title paddingnone">
                <div class="page_title_left">
                    <span>Customer Notification</span>
                    <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                </div>
            </div>
            <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
            <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
            </div>
            <div class="col-12 paddingnone">
                <!-- Pagination Start -->
                <div class="vgt-wrap__footer crm">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 PerPage">
                            <v-select title="Select Per Page"
                                        v-model="perPage"
                                        @input="changePerPage"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                            </v-select>
                        </div>
                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                            <p class="pageRowCount">
                                {{startEndRecord}} of {{totalRecords}}
                            </p>
                        </div>

                        <!-- v-if="totalRecords >= perPage" -->
                        <div class="col-sm-12 col-md-6 Pagination">
                            <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                        </div>

                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <p class="pageJumpLabel">Page : </p>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                        </div>
                    </div>
                </div>
                <!-- Pagination End -->
                <div class="table-listing white-bg border-blue border-radius-top-right">
                    <table class="table custom-table jsmartable">
                        <tbody>
                            <template v-if="contactEmailArr && contactEmailArr.length > 0" v-for="(contEmail, index) in contactEmailArr">
                                <tr :key="index">
                                    <td class="">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="selectedRows" :value="contEmail.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + contEmail.id" name="case[]">
                                            <label class="custom-control-label" :for="'customCheck' + contEmail.id"></label>
                                        </div>
                                    </td>
                                    <td v-show="actionDisplay">
                                        <div class="actions">
                                            <!-- <router-link v-show="permissionNameArray.includes('can_email_customer_master')" class="mar-right-0" title='View Customer Email' v-bind:to="{name: 'ViewContactEmail', params:{id: contEmail.id}, query:{isContactList: 'Yes'}}">
                                                <i aria-hidden="true" class="fa fa-eye"></i>
                                            </router-link> -->
                                            <a href="javascript:void(0)" v-show="permissionNameArray.includes('can_email_customer_master')" class="mar-right-0" title='View Customer Email' v-on:click="redirectContactViewPage(contEmail.id)">
                                                <i aria-hidden="true" class="fa fa-eye"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td :class="rowClass.name">{{contEmail.subject}}</td>
                                    <td :class="rowClass.updated_at">{{contEmail.sent_by}}</td>
                                    <td :class="rowClass.updated_at">{{contEmail.sent_at}}</td>
                                    <td :class="rowClass.updated_at">{{contEmail.is_attachment}}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td :colspan="headers.length + 1" class="text-alignment-center">
                                        {{allStaticMessages.data_not_available}}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <thead>
                            <tr>
                                <th class="">
                                    <div class="custom-control custom-checkbox">
                                        <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                        <label class="custom-control-label" for="selectAllCheckBox"></label>
                                    </div>
                                </th>
                                <template v-for="(data, headerIndex) in headers">
                                    <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                </template>
                                <template v-for="(data, headerIndex) in headers">
                                    <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                </template>
                            </tr>
                        </thead>
                    </table>
                </div>
                <!-- Pagination Start -->
                <div class="vgt-wrap__footer crm">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 PerPage">
                            <v-select title="Select Per Page"
                                        v-model="perPage"
                                        @input="changePerPage"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                            </v-select>
                        </div>
                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                            <p class="pageRowCount">
                                {{startEndRecord}} of {{totalRecords}}
                            </p>
                        </div>

                        <!-- v-if="totalRecords >= perPage" -->
                        <div class="col-sm-12 col-md-6 Pagination">
                            <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                        </div>

                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <p class="pageJumpLabel">Page : </p>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                            <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                        </div>
                    </div>
                </div>
                <!-- Pagination End -->
            </div>
        </template>
        <template v-else>
            &nbsp;
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListContactWiseEmail',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        props: {
            contactId: 0,
        },
        data () {
            return {
                perPageValue : 5,
                perPage: 5,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 20, 40, 100, "All"],
                contactEmailArr: [],
                headers: [],
                filters : {
                },
                filterCount : 0,
                startEndRecord : "",
                jumpToPage: 1,
                selectAll: false,
                selectedRows: [],
                appliedHeaders : [],
                permissionNameArray : ['can_email_customer_master'],
                actionDisplay : true,
                jumpToHistoryPage : 1,
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            if(self.contactId) {
                console.log(self.contactId);
                var listHistoryData = self.loadFilters('listcontactwiseemail', self);
                self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
                self.listExecutionState = false;
                self.loadContactEmailNotification(self.contactId);
            }
        },
        methods: {
            redirectContactViewPage: function(redirectContactId) {
                localStorage.setItem('isContactListPage', self.contactId);
                self.$router.push('/contact/emailview/' + redirectContactId);
            },
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadContactEmailNotification(self.contactId);
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadContactEmailNotification(self.contactId);
                }
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadContactEmailNotification(self.contactId);
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.contactEmailArr) {
                            self.selectedRows.push(self.contactEmailArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.contactEmailArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            loadContactEmailNotification: function(contactId) {
                self.listExecutionState = true;
                var first = 1;
                // console.log("Contact Id: " + contactId);
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    contactId: contactId,
                };
                console.log(fields);
                this.storeFilters('listcontactwiseemail', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/contact/wise/email/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelection();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            self.contactEmailArr = data.data.content.data;
                            $("#selectAllCheckBox").prop("checked", false);
                            self.headers = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filters);

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]

                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        console.log(error);
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if(self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadContactEmailNotification(self.contactId);
                }
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }

            if (!self.permissionNameArray.includes('can_email_customer_master')) {
                self.headers.pop();
                self.actionDisplay = false;
            }

        }
    });
</script>