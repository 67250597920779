<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="spaced-container">
                <div class="col-12 page_title paddingnone">
                    <div class="page_title_left">
                        <span>{{allLabelMessages.conquest_rda_application}}</span>
                    </div>
                    <button style="margin-right: 5px;" type="submit" v-on:click.prevent="close" class="btn btn-primary waves-effect pull-right" id="close">{{allLabelMessages.close}}</button>
                    <button style="margin-right: 5px;" type="submit" v-on:click.prevent="printConquest" class="btn btn-primary waves-effect pull-right" id="printConquestBtn">{{allLabelMessages.print_conquest}}</button>
                    <button style="margin-right: 5px;" type="submit" v-on:click.prevent="updateDeal" class="btn btn-primary waves-effect pull-right">{{allLabelMessages.save}}</button>
                    <button style="margin-right: 5px;" type="submit" v-on:click.prevent="back" class="btn btn-primary waves-effect pull-right">{{allLabelMessages.back}}</button>
                </div>
            </div>
            <div class="admin-page-content">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-2 col-sm-2 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.identifiers}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.vin_no_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="vinNo" v-model="vinNo" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.comm_number}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="commNumber" v-model="commNumber" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.stock_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="stock" v-model="stock" class="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.dealership_details}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.date_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <date-picker v-model="dealerDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.dealer_date_place_holder" name="dealerDate">
                                </date-picker>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.dealer_name}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{dealerName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.salesman_name}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{sP}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.dealer_code}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{dealerCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.customer_details}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.first_name_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerFirstName}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.surname}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerSurName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.company_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerCompany}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.abn}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="abn" v-model="abn" class="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.address_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerAddress}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.postal_code}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerPostCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.suburb}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="suburb" v-model="customerSuburb" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.state_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerState}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.email_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerEmail}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.phone_no_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{customerPhone}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.vehicle_details}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.model_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{model}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.quantity_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="quantity" v-model="quantity" class="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.body_type_filter}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.flat_top">{{allLabelMessages.flat_top}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.fridge_body">{{allLabelMessages.fridge_body}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.tilt_tray">{{allLabelMessages.tilt_tray}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.speciality_body">{{allLabelMessages.speciality_body}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.drop_side_tray">{{allLabelMessages.drop_side_tray}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.tipper">{{allLabelMessages.tipper}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.crane_truck">{{allLabelMessages.crane_truck}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.emergency_services">{{allLabelMessages.emergency_services}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.van_body">{{allLabelMessages.van_body}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.concrete_agitator">{{allLabelMessages.concrete_agitator}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.vacuum_body">{{allLabelMessages.vacuum_body}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.prime_mover">{{allLabelMessages.prime_mover}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.curtainsider">{{allLabelMessages.curtainsider}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.concrete_pump">{{allLabelMessages.concrete_pump}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.refuse_truck">{{allLabelMessages.refuse_truck}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="bodyType.other">{{allLabelMessages.other}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.conquest}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.business_retail">{{allLabelMessages.business_retail}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.govt_state">{{allLabelMessages.govt_state}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.rental">{{allLabelMessages.rental}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.med_sml_fleet">{{allLabelMessages.med_sml_fleet}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.not_for_profit">{{allLabelMessages.not_for_profit}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.demo">{{allLabelMessages.demo}}</b-form-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.govt_local">{{allLabelMessages.govt_local}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.large_fleet">{{allLabelMessages.large_fleet}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.single_operator">{{allLabelMessages.single_operator}}</b-form-checkbox>
                        </div>
                        <div class="col-md-2 col-sm-12 forms-block">
                            <b-form-checkbox v-model="conquest.others">{{allLabelMessages.others}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.conquest}} {{allLabelMessages.amount_label}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{conquestAmount}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.cq_approval}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="cqApproval" v-model="cqApproval" class="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.cq_notes}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <textarea id="cqNotes" v-model="cqNotes" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.bonus_notes}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <textarea id="bonusNotes" v-model="bonusNotes" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.factory_promo}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="factoryPromo" v-model="factoryPromo" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.bonus_amount}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <span class="form-control">{{bonusAmount}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.post_delivery}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.delivery_date}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <date-picker v-model="deliveryDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.delivery_date_place_holder" name="deliveryDate">
                                </date-picker>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.rego_number}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="regoNumber" v-model="regoNumber" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.rego_date}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <date-picker v-model="regoDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.rego_date_place_holder" name="regoDate">
                                </date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-12 col-sm-12 forms-block">
                            <div class="col-4 col-sm-4 paddingleft0">
                                <div class="book-deal-customer-block">{{allLabelMessages.cq_submission}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-4 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.cq_submission_date}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <date-picker v-model="cqSubmissionDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.cq_submission_date_place_holder" name="cqSubmissionDate">
                                </date-picker>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <b-form-checkbox name="cq_entered_in_dfe" id="cq_entered_in_dfe" v-model="cqEnteredInDfe">{{allLabelMessages.cq_entered_in_dfe}}</b-form-checkbox>
                        </div>
                        <div class="col-md-4 col-sm-12 forms-block">
                            <b-form-checkbox name="customer_details_entered_in_dfe" id="customer_details_entered_in_dfe" v-model="customerDetailsEnteredInDfe">{{allLabelMessages.customer_details_entered_in_dfe}}</b-form-checkbox>
                        </div>
                    </div>
                    <div class="row no-margin">
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.rda_approval_number}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <input id="rdaApprovalNumber" v-model="rdaApprovalNumber" class="form-control" type="text" />
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 forms-block">
                            <div class="col-md-4 col-sm-12 paddingleft0">
                                <div class="form-label">{{allLabelMessages.rda_date}}</div>
                            </div>
                            <div class="col-md-8 col-sm-12 paddingleft0">
                                <date-picker v-model="rdaDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.rda_date_place_holder" name="rdaDate">
                                </date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <b>{{allLabelMessages.rda_stamp_goes_here}}</b>
                    </div>
                    <div class="col-12 forms-block" v-show="rdaStamp && rdaStamp.length > 0">
                        <div class="col-12 forms-block  paddingleft0 deposit-check-field">
                            <div class="col-12 paddingleft0">
                                <div class="">
                                    <!-- <b>{{allLabelMessages.rda_stamp_goes_here}}</b> -->
                                </div>
                            </div>
                            <div class="col-12 paddingleft0">
                                <div class="slider">
                                    <template>
                                        <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                            <template v-for="(doc, index1) in rdaStamp">
                                                <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                    </swiper-slide>
                                                </template>
                                                <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                    </swiper-slide>
                                                </template>
                                                <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                    </swiper-slide>
                                                </template>
                                                <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                    </swiper-slide>
                                                </template>
                                                <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                    </swiper-slide>
                                                </template>
                                                <template v-else>
                                                    <swiper-slide>
                                                        <a :href="doc.file" target="_blank"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                    </swiper-slide>
                                                </template>
                                            </template>
                                        </swiper>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <!-- <label for="dropzone"><b>{{allLabelMessages.rda_stamp_goes_here}}</b></label> -->
                            <vue-dropzone
                                ref="rda_stamp_dropzone"
                                id="rda_stamp_dropzone"
                                name="rda_stamp_dropzone"
                                :options="rdaStampDropzoneOptions"
                                :destroyDropzone="false"
                                @vdropzone-success = "uploadDocument"
                                @vdropzone-removed-file="removedSelectedFile"
                                @vdropzone-error="fileError"
                                @vdropzone-file-added="fileAdd"
                                :useCustomSlot=true
                            >
                                <div class="dropzone-custom-content">
                                    <h4 class="dropzone-custom-title">{{allLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                    <div class="subtitle">{{allLabelMessages.select_files_from_your_computer}}</div>
                                </div>
                            </vue-dropzone>
                        </div>
                        <span class="help is-danger">{{rdaStampUploadError}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    export default {
        name: 'Conquest',
        components: {
            datePicker,
            'no-access': NoAccessPage,
            'app-footer' : Footer,
            'vueDropzone': vue2Dropzone,
            swiper,
            swiperSlide
        },
        mixins: [commonMixins],
        props: ['data'],
        data() {
            return {
                vinNo: null,
                commNumber: null,
                stock: null,
                dealerDate: null,
                dealerName: null,
                salesmanName: null,
                dealerCode: null,
                firstName: null,
                surname: null,
                company: null,
                address: null,
                postalCode: null,
                suburb: null,
                state: null,
                email: null,
                phone: null,
                model: null,
                quantity: null,
                sP: null,
                customerFirstName: null,
                customerSurName: null,
                customerCompany: null,
                abn: null,
                customerAddress: null,
                customerPostCode: null,
                customerSuburb: null,
                customerState: null,
                customerEmail: null,
                customerPhone: null,
                conquestAmount: null,
                deliveryDate: null,
                dealNo: null,
                bonusAmount: null,
                cqSubmissionDate: null,
                bodyType: {
                    'flat_top': false,
                    'fridge_body': false,
                    'tilt_tray': false,
                    'speciality_body': false,
                    'drop_side_tray': false,
                    'tipper': false,
                    'crane_truck': false,
                    'emergency_services': false,
                    'van_body': false,
                    'concrete_agitator': false,
                    'vacuum_body': false,
                    'prime_mover': false,
                    'curtainsider': false,
                    'concrete_pump': false,
                    'refuse_truck': false,
                    'other': false
                },
                cqNotes: null,
                bonusNotes: null,
                factoryPromo: null,
                regoNumber: null,
                regoDate: null,
                rdaApprovalNumber: null,
                rdaDate: null,
                rdaStamp: null,
                options: {
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    }
                },
                cqApproval: null,
                cqEnteredInDfe: false,
                customerDetailsEnteredInDfe: false,
                flat_top: false,
                conquest: {
                    'business_retail': false,
                    'govt_state': false,
                    'rental': false,
                    'med_sml_fleet': false,
                    'not_for_profit': false,
                    'demo': false,
                    'govt_local': false,
                    'large_fleet': false,
                    'single_operator': false,
                    'others': false
                },
                rdaStampDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: 'POST',
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    maxFiles: 1, //ToDo : to decide number of files allowed
                    thumbnailWidth: 150, // px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    duplicateCheck: true,
                    acceptedFiles: ".png, .jpg, .jpeg",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    dictFileTooBig: "File is too big ({{filesize}}mb). Max allowed file size is {{maxFilesize}}mb",
                    dictInvalidFileType: "Invalid File Type",
                    dictCancelUpload: "Cancel",
                    dictRemoveFile: "Remove",
                    dictMaxFilesExceeded: "Only 1 file is allowed",
                    dictDefaultMessage: "Drop files here to upload"
                },
                rdaStampType: '',
                rdaStampSize: '',
                rdaStampPath: '',
                rdaStampWidth: 0,
                rdaStampHeight: 0,
                rdaStampUploadError : "",
                dealId: null,
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                }
            }
        },
        beforeMount() {
            self = this;
            let dateFormat = process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT.split(' ')[0];
            self.options = {
                format: dateFormat,
                useCurrent: false,
                allowInputToggle: true,
                widgetPositioning: {
                    horizontal: "auto",
                    vertical: "bottom"
                }
            }
        },
        mounted: function () {
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            } else {
                var userJson = JSON.parse(userObj);
                self.sP = userJson['first_name'] + " " + userJson['last_name'] + ' - ' + userJson['contact_no']
                self.dataAccessType = userJson['access_inventory'];
                if(userJson['access_inventory'] == 'used'){
                    self.conditionId = {text: "Used", value: '2'};
                } else {
                    self.conditionId = {text: "New", value: '1'};
                }
            }
            $('.navbar').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            // console.log('params in conquest ', self.$route.query)
            self.getDealData()
        },
        methods: {
            close () {
                self.$router.push("/quotation/?id=0");
            },
            printConquest () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = {
                    'vin_no': self.vinNo,
                    'comm_number': self.commNumber,
                    'stock': self.stock,
                    'dealer_date': self.dealerDate,
                    'dealer_name': self.dealerName,
                    'salesman_name': self.sP,
                    'dealer_code': self.dealerCode,
                    'first_name': self.customerFirstName,
                    'surname': self.customerSurName,
                    'company': self.customerCompany,
                    'abn': self.abn,
                    'address': self.customerAddress,
                    'postcode': self.customerPostCode,
                    'suburb': self.customerSuburb,
                    'state': self.customerState,
                    'email': self.customerEmail,
                    'phone': self.customerPhone,
                    'model': self.model,
                    'quantity': self.quantity,
                    'conquest_amount': self.conquestAmount,
                    'cq_approval': self.cqApproval,
                    'cq_notes': self.cqNotes,
                    'bonus_notes': self.bonusNotes,
                    'factory_promo': self.factoryPromo,
                    'delivery_date': self.deliveryDate,
                    'rego_number': self.regoNumber,
                    'rego_date': self.regoDate,
                    'cq_submission_date': self.cqSubmissionDate,
                    'rda_approval_number': self.rdaApprovalNumber,
                    'rda_date': self.rdaDate,
                    'cq_entered_in_dfe': self.cqEnteredInDfe,
                    'customer_details_entered_in_dfe': self.customerDetailsEnteredInDfe,
                    'rda_stamp': self.rdaStampPath,
                    'deal_no': self.dealNo,
                    'body_type': self.bodyType,
                    'conquest': self.conquest
                };
                HTTP.post("AU/print-conquest", data, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            self.downloadFile(response.data.content.web);
                        }
                        if (response.status == 201) {
                            self.showSAlert(response.data.content[0], 'error', false);
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            updateDeal () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = {
                    'vin_no': self.vinNo,
                    'comm_number': self.commNumber,
                    'stock': self.stock,
                    'dealer_date': self.dealerDate ? self.dealerDate.split("/").reverse().join("/") : null,
                    'quantity': self.quantity,
                    'cq_approval': self.cqApproval,
                    'cq_notes': self.cqNotes,
                    'bonus_notes': self.bonusNotes,
                    'factory_promo': self.factoryPromo,
                    'rego_number': self.regoNumber,
                    'rego_date': self.regoDate ? self.regoDate.split("/").reverse().join("/") : null,
                    'cq_submission_date': self.cqSubmissionDate ? self.cqSubmissionDate.split("/").reverse().join("/") : null,
                    'rda_approval_number': self.rdaApprovalNumber,
                    'rda_date': self.rdaDate ? self.rdaDate.split("/").reverse().join("/") : null,
                    'cq_entered_in_dfe': self.cqEnteredInDfe,
                    'customer_details_entered_in_dfe': self.customerDetailsEnteredInDfe,
                    'deal_no': self.dealNo,
                    'body_type': self.bodyType,
                    'rda_stamp_path': self.rdaStampPath,
                    'conquest': self.conquest,
                    'abn': self.abn,
                    'suburb': self.customerSuburb,
                    'delivery_date': self.deliveryDate ? self.deliveryDate.split("/").reverse().join("/") : null,
                    'type': 'conquest'
                }
                HTTP.post("AU/update-deal", data, config)
                    .then(function (response) {
                        self.showSAlert(response.data.message, 'success', false);
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            uploadDocument: function (file, response) {
                self.rdaStampPath = response.content.file_path;
                self.rdaStampType = file.type;
                self.rdaStampSize = file.size;
                self.rdaStampWidth = file.width;
                self.rdaStampHeight = file.height;
                self.rdaStampUploadError = '';
            },
            removedSelectedFile(file, xhr, error) {
                var self = this;
                var removeFile = "";
                if(file.accepted || file.manuallyAdded){
                    self.rdaStampPath = '';
                    self.rdaStampHeight = 0;
                    self.rdaStampWidth = 0;
                }
                if ("manuallyAdded" in file == false) {
                    if(file && file.xhr != undefined) {
                        var filePath = JSON.parse(file.xhr.response);
                        removeFile = filePath.content;
                        self.removeFileFromServer(removeFile);
                        removeFile = removeFile.media_path
                    }
                }
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if (xhr.code == 400) {
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
            },
            back () {
                self.$router.push({ name: 'BuildUp', query: { id: self.dealId }})
            },
            getDealData () {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: self.$route.query.id
                    }
                };
                HTTP.get("/AU/deal/getbyid", config)
                    .then(function (response) {
                        self.hideLoader();
                        if (response.status == 200) {
                            // console.log('response in conquest ', response)
                            self.vinNo = response.data.content.vin_no,
                            self.commNumber = response.data.content.comm_no,
                            self.stock = response.data.content.stock,
                            self.dealerDate = response.data.content.dealer_date,
                            self.dealerName = response.data.content.location,
                            self.sP = self.sP,
                            self.dealerCode = response.data.content.locId,
                            self.customerFirstName = response.data.content.customer_first_name,
                            self.customerSurName = response.data.content.customer_last_name,
                            self.customerCompany = response.data.content.customer_company_name,
                            self.abn = response.data.content.abn,
                            self.customerAddress = response.data.content.customer_address,
                            self.customerPostCode = response.data.content.customer_zip_code,
                            self.customerSuburb = response.data.content.cus_address2 ? response.data.content.cus_address2 : response.data.content.cus_address2,
                            self.customerState = response.data.content.customer_state_name,
                            self.customerEmail = response.data.content.customer_email,
                            self.customerPhone = response.data.content.cus_phone,
                            self.model = response.data.content.deal_inventory_data[0].model_display,
                            self.quantity = response.data.content.quantity,
                            self.bodyType = response.data.content.body_type ? JSON.parse(response.data.content.body_type) : self.bodyType,
                            self.conquest = response.data.content.conquest ? JSON.parse(response.data.content.conquest) : self.conquest,
                            self.conquestAmount = response.data.content.extra_conquest,
                            self.cqApproval = response.data.content.cq_approval,
                            self.cqNotes = response.data.content.cq_notes,
                            self.bonusNotes = response.data.content.bonus_notes,
                            self.factoryPromo = response.data.content.factory_promo,
                            self.bonusAmount = response.data.content.bonus,
                            self.deliveryDate = response.data.content.delivery_date,
                            self.regoNumber = response.data.content.rego_number,
                            self.regoDate = response.data.content.rego_date,
                            self.cqSubmissionDate = response.data.content.cq_submission_date,
                            self.cqEnteredInDfe = response.data.content.cq_entered_in_dfe ? true : false,
                            self.customerDetailsEnteredInDfe = response.data.content.customer_details_entered_in_dfe ? true : false,
                            self.rdaApprovalNumber = response.data.content.rda_approval_number,
                            self.rdaDate = response.data.content.rda_date,
                            self.rdaStamp = response.data.content.rda_stamp_data,
                            self.rdaStampPath = response.data.content.rda_stamp,
                            self.buyer = response.data.content.business_name,
                            self.cust = response.data.content.customer_name,
                            self.dealNo = response.data.content.deal_no,
                            self.dealId = self.$route.query.id
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    });
            },
        }
    }
</script>