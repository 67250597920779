<template>
  <div>
    <div class="modal" v-bind:style="{zIndex: parentModalShowHide}" id="defaultModal" tabindex="-1" role="dialog">
      <div v-if="isClickCostSheet" class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="defaultModalLabel">
              Vehicle Cost Sheet
            </h4>
            <button type="button" class="close" aria-label="Close" @click.prevent="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4">
                    <label for="stock" class="float-left">Stock#:</label>
                    <input type="text" v-model="unitId" class="form-control input-sm" style="width: 150px; margin-left: 4rem !important;" />
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-8">
                        <label for="stock" class="float-left">Calculated Total:</label>
                        <money v-bind="moneyMask" type="text" v-model="calculateTotal" class="form-control input-sm" style="width: 150px; margin-left: 8rem !important;" disabled />
                      </div>
                      <div class="col-md-4">
                        <button class="btn btn-primary" v-on:click="loadTotalInVehicleWorksheet()">Load Cost in Deal</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <input type="checkbox" />
                <label for="post-detail">Only Posted Detail</label>
              </div>
            </div>
            <vehicle-ros v-bind:repairOrderArr="repairOrderArr" v-bind:allStaticMessages="allStaticMessages" />
            <vehicle-pos v-bind:assetWiseExpensesArr="assetWiseExpensesArr" v-bind:allStaticMessages="allStaticMessages" />
            <estimates v-bind:estimateArr="estimateArr" v-bind:allStaticMessages="allStaticMessages" />
            <history v-bind:historyArr="historyArr" v-bind:allStaticMessages="allStaticMessages" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="isClickRo">
      <ro-detail></ro-detail>
    </div>
    <div v-show="isClickPo">
      <po-detail></po-detail>
    </div>
    <div v-show="isClickEstimate">
      <estimate-detail></estimate-detail>
    </div>
  </div>
</template>

<script>
var self;
import {commonMixins} from '../../../mixins/commonMixins';
import EventBus from "../../event-bus";
import {HTTP} from '../../../http-common';
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import VehicleRos from "./CostSheet/VehicleRos.vue";
import VehiclePos from "./CostSheet/VehiclePos.vue";
import Estimates from "./CostSheet/Estimates.vue";
import History from "./CostSheet/History.vue";
import {Money} from 'v-money';
import PoDetailModal from './CostSheet/Detail/PoDetailModal.vue';
import RoDetailModal from './CostSheet/Detail/RoDetailModal.vue';
import EstimateDetailModal from './CostSheet/Detail/EstimateDetailModal.vue';

export default {
  name: 'VehicleWorkSheet',
  components: {
    'no-access': NoAccessPage,
    'vehicle-ros': VehicleRos,
    'vehicle-pos': VehiclePos,
    'estimates': Estimates,
    "history": History,
    'po-detail': PoDetailModal,
    'ro-detail': RoDetailModal,
    'estimate-detail': EstimateDetailModal,
    Money
  },
  mixins: [commonMixins],
  props: ['assetId','vin','isClickCostSheet','unitId'],
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      repairOrderArr: [],
      assetWiseExpensesArr: [],
      totalRoAmount: '',
      totalPoAmount: '',
      isShowVehicleCostModal: true,
      estimateArr: [],
      historyArr: [],
      calculateTotal: 0.00,
      moneyMask: {
        thousands: ',',
        prefix: '$ ',
        precision: 2,
      },
      isClickPo: false,
      isClickRo: false,
      isClickEstimate: false,
      parentModalShowHide: 1042,
    }
  },
  beforeMount() {
    self = this;
  },
  methods: {
    sumRoAndPo: function (){
      var total = parseFloat(self.totalPoAmount + self.totalRoAmount).toFixed(2);
      EventBus.$emit("grand-total-cost-sheet",total,self.isShowVehicleCostModal);
    },
    getData: async function () {
      await this.loadRepairOrders;
      await this.loadAssetExpenses;
      // this.sumRoAndPo(); //todo only after confirm, not required for now
    },
    closeModal() {
      EventBus.$emit("close-cost-sheet", false);
    },
    addSubCalulateTotal(status, value) {
      let amount = value.replace('$', '').replace(',', '');
      amount = parseFloat(amount);
      const total = parseFloat(this.calculateTotal);
      if(status) {
        this.calculateTotal = parseFloat(total + amount).toFixed(2);
      } else {
        this.calculateTotal = parseFloat(total - amount).toFixed(2);
      }
      return true;
    },
    showDetails(data,type) {
      // this.showData = data;
      switch (type) {
        case 'ro':
          self.isClickRo = type;
          break;
        case 'po':
          self.isClickPo = type;
          break;
        case 'estimate':
          self.isClickEstimate = type;
          break;
      }
      $("#"+type+"DetailModal").modal({backdrop: 'static', keyboard: false});
    },
    loadTotalInVehicleWorksheet() {
      EventBus.$emit("load-cost-sheet", this.calculateTotal);
    }
  },
  computed: {
    loadRepairOrders: function () {
      self.pageTitle = self.allLabelMessages.repair_orders_label;
      var config = {
        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
      };

      var fields = {
        asset_id: self.assetId,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: "id",
        sortType: "desc",
        vin: self.vin
      };
      // self.showLoader();
      return new Promise(function (resolve,reject){
        HTTP.post("/repairorder/list", fields, config)
          .then(function (data) {
            // self.hideLoader();
            if (data.status == 200) {
              self.repairOrderArr = data.data.content.data.rod;
              self.estimateArr = data.data.content.data.est;
              self.totalRoAmount = data.data.totalRoAmount.ro_total_amount;
              if (self.totalRoAmount == null || typeof self.totalRoAmount === 'undefined'){
                self.totalRoAmount = 0;
              }
              // console.log('ro amount: ',self.totalRoAmount);
              resolve(data);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            console.log('repair error!');
            self.hideLoader();
            self.catchHandler(error, function () {
            });
            reject(error);
          });
      });

    },
    loadAssetExpenses: function () {
      var config = {
        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
      };
      var fields = {
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: "id",
        sortType: "desc",
        asset_id: self.assetId
      };

      return new Promise(function (resolve,reject){
        HTTP.post("/assetwiseexpenses/list", fields, config)
          .then(function (data) {
            if (data.status == 200) {
              self.assetWiseExpensesArr = data.data.content.data;
              self.totalPoAmount = data.data.totalPoAmount.po_total_amount;
              if (self.totalPoAmount == null || typeof self.totalPoAmount === 'undefined'){
                self.totalPoAmount = 0;
              }
              // console.log('po amount: ',self.totalPoAmount);
              resolve(data);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            // console.log('asset error!');
            self.catchHandler(error, function () {
            });
            reject(error);
          });
      });
    },
  },
  watch: {
    isClickCostSheet: function(currentStatus) {
      if(currentStatus) {
        this.calculateTotal = 0.00
        this.getData();
      } else if(!currentStatus) {
        this.closeModal();
      }
    },
  },
}

EventBus.$on("add-subtract-total", (status, value) => {
  if (typeof self != "undefined"){
    self.addSubCalulateTotal(status,value);
  }
});

EventBus.$on("show-details", (value,type) => {
  if (typeof self != "undefined"){
    self.parentModalShowHide = 1040;
    self.showDetails(value,type)
  }
});

EventBus.$on("close-details",function (status, type){
  if (typeof self != "undefined"){
    switch (type) {
      case 'ro':
        self.isClickRo = status;
        break;
      case 'po':
        self.isClickPo = status;
        break;
      case 'estimate':
        self.isClickEstimate = status;
        break;
    }
    self.parentModalShowHide = 1042;
    $("#"+type+"DetailModal").modal("hide");
  }
});

</script>

<style scoped>
  .modal-dialog{
      overflow-y: initial !important
  }
  .modal-body{
      height: 80vh;
      overflow-y: auto;
  }
</style>
