<template>
  <!-- <div class="spaced-container"> -->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_activity_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->

          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
             
              <!-- Contact Group -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ contactGroupId.text }}</span>
                </div>
              </div>
               <!-- Home Department -->
               <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.department_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ departmentId.text }}</span>
                </div>
              </div>

              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.contact_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ customerContactId.text }}</span>
                </div>
              </div>

              <!-- User -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.user_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ userId.text }}</span>
                </div>
              </div>

              <!-- Assign User -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.assign_user_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ assignUserId.text }}</span>
                </div>
              </div>

              <!-- Asset -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.asset_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ assetId.text }}</span>
                </div>
              </div>
              <!-- Activity Date Time -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.activity_time_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ activityDateTime }}</span>
                </div>
              </div>
              <!--type-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.activity_type_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ type.text }}</span>
                </div>
              </div>
              
              <!-- Parent Activity Selection -->
              <div v-show="isActivityTypeFollowUp" class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.parent_activity_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <span class="form-control">{{ parentActivity ? parentActivity.text : "" }}</span>
                  </div>
              </div>

              <!-- Other Type -->
              <div v-show="isActivityTypeOther" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.other_type_text_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ otherActivityTypeText }}</span>
                </div>
              </div>

              <!-- Priority -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.priority_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ priority.text }}</span>
                </div>
              </div>
              <!-- Direction -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.direction_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ direction.text }}</span>
                </div>
              </div>

              <!-- Subject -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.subject_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <span class="form-control">{{ subject }}</span>
                </div>
              </div>

              <!-- Notes -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.notes_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <textarea
                    id="notes"
                    v-model="notes"
                    disabled
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import datePicker from "vue-bootstrap-datetimepicker";

Vue.component("app-footer", Footer);
Vue.component("no-access", NoAccessPage);

export default {
  name: "ViewActivity",
  components: {
    datePicker
  },
  mixins: [commonMixins],
  data() {
    return {
      staticMessages: "",
      staticInputLabelMessages: "",
      moduleData: {
        text: "Inventory",
        value: "inventory"
      },
      moduleDataArr: [
        {
          text: "Inventory",
          value: "inventory"
        },
        {
          text: "Expenses",
          value: "expenses"
        },
        {
          text: "Invoice",
          value: "invoice"
        }
      ],
      contactGroupId: "",
      customerContactId: {text: "", value: ""},
      contactGroupIdArr: [],
      isContactGroupRecordNotFound: false,
      locationId: "",
      locationIdArr: [],
      userId: "",
      userIdArr: [],
      assignUserId: "",
      assignUserIdArr: [],
      isAssingUserRecordNotFound: false,
      type: { text: "Call", value: "call" },
      typeArr: [
        {
          text: "Call",
          value: "call"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Other",
          value: "other"
        }
      ],
      isActivityTypeOther: false,
      otherActivityTypeText: "",
      assetId: "",
      assetIdArr: [],
      isAssetRecordNotFound: false,
      activityDateTime: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom"
        }
        // disabledTimeIntervals: false,
      },
      notes: "",

      moduleDataError: "",
      contactGroupIdError: "",
      locationIdError: "",
      userIdError: "",
      assignUserIdError: "",
      typeError: "",
      assetIdError: "",
      activityDateTimeError: "",

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel"
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false
      },
      alertClass: "",
      alertMessage: "",
      pageTitle: "View Activity",
      permissionNameArray: ["can_list_activity_master"],
      departmentId: "",
      departmentError: "",
      departmentArr: [],
      priority: { value: "medium", text: "Medium" },
      priorityArr: [
        {
          value: "high",
          text: "High"
        },
        {
          value: "medium",
          text: "Medium"
        },
        {
          value: "low",
          text: "Low"
        }
      ],
      priorityError: "",
      direction: { value: "outgoing", text: "Outgoing" },
      directionArr: [
        {
          value: "incoming",
          text: "Incoming"
        },
        {
          value: "outgoing",
          text: "Outgoing"
        }
      ],
      directionError: "",
      subject: "",
      subjectError: "",
      isActivityTypeFollowUp: false,
      parentActivity: "",
    };
  },
  watch: {
    type: function(type) {
      self.isActivityTypeOther = false;
      self.isActivityTypeFollowUp = false;

      if (type.value == "other") {
        self.isActivityTypeOther = true;
      }else if (type.value == "follow_up") {
        self.isActivityTypeFollowUp = true;
      }
    }
  },
  beforeMount() {
    self = this;
  },
  mounted: function() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.setActiveMenu("activity-list");
    EventBus.$emit("menu-selection-changed", "activity-list");

    userObj = localStorage.getItem("user_obj");
    //if user logged in than remove login button and registration button
    if (userObj != null) {
      var userJson = JSON.parse(userObj);
      self.userId = {
        text: userJson.first_name + " " + userJson.last_name,
        value: userJson.id
      };
    }
    self.loadAllData(
      ["Department"],
      false,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    $("#alertError").hide();
    self.getLoginIdPermissions();
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.getActivityDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      $("#addAndContinueActivityBtn").hide();
      self.pageTitle = "View Activity";
    }
    // self.loadAllData(['Location'], true, self.loadLocationCallback, "undefined", "");
  },
  methods: {
    buttonBack() {
      if (
        typeof self.$route.query.redirectUrl != "undefined" &&
        self.$route.query.redirectUrl != ""
      ) {
        const url = decodeURIComponent(self.$route.query.redirectUrl);
        self.$router.push("/" + url + "?tab=" + self.$route.query.tab);
      } else {
        self.$router.push("/activity/list");
      }
    },
    loadMasterDataCallback: function(callbackData) {
      self.departmentArr = callbackData.Department;
      if (self.departmentArr.length == 0) {
        self.isdepartmentRecordNotFound = true;
      }
    },
    changedValue: function(value) {
      /*console.log(value);*/
    },
    changeActivityDateTime: function() {
      self.activityDateTimeError = "";
    },
    onSearchContactGroup(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Contact"],
          true,
          self.loadContactGroupCallback,
          loading,
          search
        );
      }
    },
    loadContactGroupCallback: function(callbackData) {
      self.isContactGroupRecordNotFound = false;
      self.contactGroupIdArr = callbackData.Contact;
      if (self.contactGroupIdArr.length == 0) {
        self.isContactGroupRecordNotFound = true;
      }
    },
    loadLocationCallback: function(callbackData) {
      self.locationIdArr = callbackData;
    },
    onSearchUser(search, loading) {},
    onSearchAssignUser(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadAssignUserCallback,
          loading,
          search
        );
      }
    },
    loadAssignUserCallback: function(callbackData) {
      self.isAssingUserRecordNotFound = false;
      self.assignUserIdArr = callbackData.User;
      if (self.assignUserIdArr.length == 0) {
        self.isAssingUserRecordNotFound = true;
      }
    },
    onSearchAsset(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Asset"],
          true,
          self.loadAssetCallback,
          loading,
          search
        );
      }
    },
    loadAssetCallback: function(callbackData) {
      self.isAssetRecordNotFound = false;
      self.assetIdArr = callbackData.Asset;
      if (self.assetIdArr.length == 0) {
        self.isAssetRecordNotFound = true;
      }
    },
    showAlertOnSave: function(alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function() {
          $("#success-alert").slideUp(500);
        });
    },
    getActivityDetailsById: function(id) {
      self.isActivityTypeOther = false;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id,
          browser_timezone: localStorage.getItem("browserTimeZone"),
        }
      };
      HTTP.get("/activity/get", config)
        .then(response => {

          if (response.data.content.type == "other") {
            self.isActivityTypeOther = true;
          }else if (response.data.content.type == "follow_up") {
              self.isActivityTypeFollowUp = true;
          }

          self.moduleData = {
            text: self.formatString(response.data.content.module),
            value: response.data.content.module
          };
          self.customerContactId = {
            text: response.data.content.customer_contact_name,
            value: response.data.content.customer_contact_id
          };
          self.contactGroupId = {
            text: response.data.content.customer_name,
            value: response.data.content.customer_id
          };
          // self.locationId = {text: response.data.content.location_name, value: response.data.content.location_id};
          self.assignUserId = {
            text: response.data.content.assign_user_first_name,
            value: response.data.content.assgin_user_id
          };
          self.assetId = "";
          if (
            typeof response.data.content.asset_id != "undefined" &&
            response.data.content.asset_id != 0 &&
            response.data.content.asset_id != ""
          ) {
            self.assetId = {
              text: response.data.content.vin_no,
              value: response.data.content.asset_id,
              location_id: response.data.content.location_id,
              companyId: response.data.content.company_id
            };
          }
          self.type = {
            text: self.formatString(response.data.content.type),
            value: response.data.content.type
          };
          self.otherActivityTypeText =
            response.data.content.other_activity_type_text;
          self.activityDateTime = response.data.content.activity_time;
          self.notes = response.data.content.notes;
          self.priority = {
            text: self.formatString(response.data.content.priority),
            value: response.data.content.priority
          };
          self.direction = {
            text: self.formatString(response.data.content.direction),
            value: response.data.content.direction
          };
          if (
            typeof response.data.content.subject != "undefined" &&
            response.data.content.subject != 0 &&
            response.data.content.subject != ""
          ) {
            self.subject = response.data.content.subject;
          }
          if (
            typeof response.data.content.department_id != "undefined" &&
            response.data.content.department_id != 0 &&
            response.data.content.department_id != ""
          ) {
            self.departmentId = {
              text: response.data.content.department_name,
              value: response.data.content.department_id
            };
          }

          self.parentActivity = {
            text: self.formatString(response.data.content.parent_activity_subject),
            value: response.data.content.parent_activity_id
          };
        })
        .catch(function(err) {
          self.catchHandler(err, function() {});
          self.buttonBack();
        });
    }
  }
};
EventBus.$on("login-permissions", function(permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
