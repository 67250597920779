<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_inquiry_notification_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Department Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="companyId"
                                            name="companyId"
                                            id="companyId"
                                            label="text"
                                            :clearable="true"
                                            :filterable="false"
                                            :options="companyArr"
                                            @change="changedDepartmentValue"
                                            @search="onSearchDepartment"
                                            @input="companyError = ''"
                                            :placeholder="staticMessages.company_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{staticMessages.company_place_holder}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{companyError}}</span>
                                </div>
                            </div>
                            <!--Website Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.website_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="websiteId"
                                            name="website"
                                            id="website"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="websiteArr"
                                            @change="changedValue"
                                            @input="websiteError = ''"
                                            placeholder="Select Website">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isWebsiteRecordNotFound">
                                                {{staticMessages.website_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{websiteError}}</span>
                                </div>
                            </div>
                            <!-- Location Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="location"
                                            name="location"
                                            id="location"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="locationArr"
                                            @change="changedValue"
                                            @input="locationError = ''"
                                            :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <!-- User Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.user_list_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            multiple
                                            v-model="userId"
                                            name="userId"
                                            id="userId"
                                            label="text"
                                            @change="changedValue"
                                            @input="changedValueUser()"
                                            :filterable="false"
                                            :options="userArr"
                                            @search="onSearchUser"
                                            :placeholder="staticMessages.user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <vue-tags-input
                                            v-model="email"
                                            :tags="emailTagsArr"
                                            :validation="validation"
                                            @tags-changed="newTags => emailTagsArr = newTags"
                                            @before-adding-tag="checkEmailTag"
                                            placeholder=""
                                    />
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>

                        </div>
                        
                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addInquiryNotificationSettings()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateInquiryNotificationSettings',
        mixins: [commonMixins],
        components: {
            VueTagsInput,
        },
        data () {
            return {
                email: "",
                emailError : "",
                emailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidEmailTag : true,
                staticInputLabelMessages: "",
                staticMessages: "",
                websiteId: "",
                websiteArr: [],
                websiteError: "",
                isWebsiteRecordNotFound: false,

                companyId : "",
                companyArr : [],
                companyError : "",
                isCompanyRecordNotFound: false,

                location: "",
                locationArr: [],
                locationError: "",

                userId: "",
                userArr:[],
                userNameError: "",
                isUserRecordNotFound: false,
                
                buttonNames : {
                    save : 'Save',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle: 'Add Inquiry Notification',
                permissionNameArray : ['can_add_inquiry_notification_master'],
                isEdit : false,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('inquiry-notification-list');
            self.getLoginIdPermissions();
            EventBus.$emit("menu-selection-changed", "inquiry-notification-list");
            $("#alertError").hide();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getInquiryNotificationSettingsDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Inquiry Notification";
                self.isEdit = true;
            }
            self.loadAllData(['Website', 'Company','Location'], false, self.loadMasterDataCallback, "undefined", '');
        },
        watch : {
            /*emailTagsArr : function(){
                console.log(JSON.stringify(self.emailTagsArr));
            }*/
        },
        methods: {
            buttonBack() {
                self.$router.push({ name: 'ListInquiryNotificationSettings'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadMasterDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = self.isWebsiteRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
                self.websiteArr = callbackData.Website;
                if(self.websiteArr.length == 0) {
                    self.isWebsiteRecordNotFound = true;
                }
                self.locationArr = callbackData.Location;
            },
            onSearchDepartment(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadDepartmentDataCallback, loading, search);
                }
            },
            loadDepartmentDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            changedDepartmentValue : function(){
                let company_id = "";
                if (typeof(self.companyId) != "undefined" && self.companyId != null && self.companyId != "" && self.companyId != 0) {
                    company_id = self.companyId.value;
                }
                if (self.isEdit) {
                } else {
                    self.websiteId = "";
                }
                self.isEdit = false;
                self.loadAllData(['Website'], false, self.loadWebsiteDataCallback, "undefined", '', company_id);
            },
            loadWebsiteDataCallback: function (callbackData) {
                self.isWebsiteRecordNotFound = false;
                self.websiteArr = callbackData.Website;
                if(self.websiteArr.length == 0) {
                    self.isWebsiteRecordNotFound = true;
                }
            },
            changedValueUser: function () {
                self.userNameError = "";
            },
            onSearchUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getUserData);
                }
            },
            getUserData: function(callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData;
                if(self.userArr.length == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            checkEmailTag: function(obj) {
                self.emailError = "";
                self.isValidEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter valid Email";
                } else obj.addTag();
            },
            getInquiryNotificationSettingsDetailsById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/inquirynotification/get', config)
                    .then(response => {
                        self.companyId = {'text': response.data.content.data.company_name, 'value': response.data.content.data.company_id};
                        self.$nextTick(function () {
                            self.websiteId = {'text': response.data.content.data.website_name, 'value': response.data.content.data.website_id};
                        });
                        self.location = {'text': response.data.content.data.location_name, 'value': response.data.content.data.location_id};
                        self.userId = [];
                        for (var i = 0; i < response.data.content.user.length; i++) {
                            self.userId.push({'text': response.data.content.user[i].text, 'value': response.data.content.user[i].value});
                        }
                        if (response.data.content.data.email != '' && response.data.content.data.email != null && typeof (response.data.content.data.email) != "undefined") {
                            let email = response.data.content.data.email;
                            email = email.split(",");
                            for (let i = 0; i < email.length; i++) {
                                self.emailTagsArr.push({"text":email[i].trim(),"tiClasses":["ti-valid"]});
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                var checked = true;
                /* if (self.companyId.length == 0) {
                    self.companyError = "Please select Department";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.websiteId == null || !self.websiteId.value) {
                    self.websiteError = "Please select Website";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.location == null || !self.location.value) {
                    self.locationError = "Please select Location";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addInquiryNotificationSettings: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        
                        var user_id = '';
                        for (var i = 0; i < self.userId.length; i++) {
                            user_id += (self.userId[i].value) + ',';
                        }
                        user_id = user_id.replace(/,\s*$/, "");

                        var emailData = "";
                        if(self.emailTagsArr && self.emailTagsArr.length > 0) {
                            for(var index = 0;index<self.emailTagsArr.length;index++) {
                                if(!emailData.includes(self.emailTagsArr[index].text)) {
                                    var strLowerCase = self.emailTagsArr[index].text.toLowerCase();
                                    emailData += strLowerCase + ',';
                                }
                            }
                        }
                        emailData = emailData.replace(/,\s*$/, "");
                        var companyId = "";
                        if (self.companyId == null || !self.companyId.value) {
                            companyId = "";
                        } else {
                            companyId = self.companyId.value;
                        }
                        var websiteId = "";
                        if (self.websiteId == null || !self.websiteId.value) {
                            websiteId = "";
                        } else {
                            websiteId = self.websiteId.value;
                        }
                        var data = new FormData();
                        data.append('company', companyId);
                        data.append('website', websiteId);
                        data.append('location', self.location.value);
                        data.append('user', user_id);
                        data.append('email', emailData);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Update ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            data.append('id', 0);
                            self.buttonNames.save = "Saving ...";
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/inquirynotification/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                                if (response.data.status == "success") {
                                    self.showSAlert(response.data.message, 'success', false ,3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                            self.buttonNames.save = "Update";
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        } else {
                                            self.buttonNames.save = "Save";
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }
                                    }, 3000);
                                } else {
                                    if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>