<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="
        permissionNameArray.includes(
          'can_list_used_truck_moved_submission_form'
        )
      "
    >
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Used Truck Move Submission Form</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <router-link
              v-show="
                permissionNameArray.includes(
                  'can_add_used_truck_moved_submission_form'
                )
              "
              class="btn btn-primary waves-effect pull-right ml-2"
              title="Add Used Truck Move Submission Form"
              v-bind:to="{ name: 'AddUpdateUsedTruckMovedSubmission' }"
            >
              <span>Add Used Truck Move Submission Form</span>
            </router-link>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <button
              type="button"
              id="exportListData"
              title="Export"
              v-show="
                permissionNameArray.includes(
                  'can_export_used_truck_moved_submission_form'
                )
              "
              class="btn btn-primary waves-effect pull-right"
              v-on:click="exportListData($event)"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </button>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <!-- <button type="button" v-on:click.prevent="deleteFrame()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button> -->
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListUsedTruckMovedSubmission"
              ref="commonLoaderListUsedTruckMovedSubmission"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="frameArr && frameArr.length > 0">
                    <template v-for="(frame, index) in frameArr">
                      <tr class="mainDetails">
                        <td class="">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="selectedRows"
                              :value="frame.id"
                              @change="inputCheckbox($event)"
                              class="custom-control-input"
                              :id="'customCheck' + frame.id"
                              name="case[]"
                            />
                            <label
                              class="custom-control-label"
                              :for="'customCheck' + frame.id"
                            ></label>
                          </div>
                        </td>
                        <td v-show="actionDisplay">
                          <div class="actions">
                            <div class="actions">
                              <a
                                :id="'accordion-' + frame.id"
                                v-on:click="toggleIcon(frame.id)"
                                v-b-toggle="'accordion-' + frame.id"
                                href="javascript:void(0)"
                                title="Show Extra details"
                                class="mar-right-0"
                              >
                                <i
                                  v-if="accordianIds.includes(frame.id)"
                                  class="exceptEdit fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  v-else
                                  class="exceptEdit fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <a
                                title="Approved"
                                v-if="frame.completed == 'yes'"
                                href="javascript:void(0)"
                                class="mar-right-0"
                              >
                                <i
                                  class="exceptEdit fa fa-check-circle color-71aa3f"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <router-link
                                v-show="
                                  permissionNameArray.includes(
                                    'can_add_used_truck_moved_submission_form'
                                  )
                                "
                                class="mar-right-0"
                                title="Edit Used Truck Move Submission Form"
                                v-bind:to="{
                                  name: 'UpdateUsedTruckMovedSubmission',
                                  params: { id: frame.id },
                                }"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa fa-angle-right forward_icon"
                                ></i>
                              </router-link>
                            </div>
                          </div>
                        </td>
                        <td :class="rowClass.status">
                          <span
                            v-if="frame.status == 'Approved'"
                            class="badge badge-primary badge-pill bg-color-71aa3f"
                          >
                            {{ frame.status }}
                          </span>
                          <span
                            v-else-if="frame.status == 'In Progress'"
                            class="badge badge-primary badge-pill bg-color-0771b4"
                          >
                            {{ frame.status }}
                          </span>
                          <span
                            v-else-if="frame.status == 'Rejected'"
                            class="badge badge-primary badge-pill bg-color-c3202b"
                          >
                            {{ frame.status }}
                          </span>
                        </td>
                        <td :class="rowClass.salesman_name">
                          {{ frame.salesman_name }}
                        </td>
                        <td :class="rowClass.request_number">
                          {{ frame.request_number }}
                        </td>
                        <!-- <td :class="rowClass.customer_name">{{frame.customer_name}}</td> -->
                        <!--<td :class="rowClass.vin_number">{{frame.vin}}</td>-->
                        <!--<td :class="rowClass.selling_price">{{frame.selling_price}}</td>-->
                        <!-- <td :class="rowClass.department">{{frame.department}}</td> -->
                        <!-- <td :class="rowClass.reason">{{frame.reason}}</td> -->
                        <td :class="rowClass.type_of_request">
                          {{ frame.type_of_request }}
                        </td>
                        <td :class="rowClass.location_of_unit_name">
                          {{ stringTrim(frame.location_of_unit_name, 25) }}
                        </td>
                        <td :class="rowClass.po_number">
                          <template
                            v-if="
                              frame.po_number == '' &&
                              (frame.status == 'In Progress' ||
                                frame.status == 'Approved')
                            "
                          >
                            <span
                              class="badge badge-primary badge-pill bg-color-ff3300"
                              >PO Pending</span
                            >
                          </template>
                          <template v-else>
                            <template v-if="frame.status == 'Rejected'">
                              -
                            </template>
                            <template v-else>
                              <p
                                class="mb-0"
                                v-html="addNewLineInColumn(frame.po_number)"
                              ></p>
                            </template>
                          </template>
                        </td>
                        <td :class="rowClass.send_to_manager">
                          {{ frame.send_to_manager }}
                        </td>
                        <td :class="rowClass.pick_up_date">
                          {{ frame.pick_up_date }}
                        </td>
                        <td :class="rowClass.drop_off_date">
                          {{ frame.drop_off_date }}
                        </td>
                        <td :class="rowClass.eta">{{ frame.eta }}</td>
                        <td :class="rowClass.cost">{{ frame.cost }}</td>
                        <td :class="rowClass.vendor">{{ frame.vendor }}</td>
                        <td :class="rowClass.send_to_manager2">
                          {{ frame.send_to_manager2 }}
                        </td>
                        <td :class="rowClass.updated_by">
                          {{ frame.updated_by }}
                        </td>
                        <td :class="rowClass.updated_at">
                          {{ frame.updated_at }}
                        </td>
                      </tr>
                      <tr class="extraDetails">
                        <td colspan="2"></td>
                        <td
                          :colspan="headers.length - 1"
                          class="text-alignment-left"
                        >
                          <b-collapse
                            :id="'accordion-' + frame.id"
                            class="mt-2"
                          >
                            <b-card>
                              <table>
                                <thead>
                                  <tr>
                                    <template
                                      v-for="(
                                        headerData, headerIndex
                                      ) in returnAccordianDisplayDataKeys()"
                                    >
                                      <!-- <template v-if="headerIndex == 0"> -->
                                      <th class="text-left column-header pl-1">
                                        {{ headerData.label }}
                                      </th>
                                      <!-- </template>
                                                                    <template v-else>
                                                                        <th class="text-right column-header">{{headerData.label}}</th>
                                                                    </template> -->
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <!-- <template v-for="(data, dataIndex) in frame.acordian_data"> -->
                                  <tr>
                                    <!-- <td class=text-left>{{data.vin}}</td>
                                                                    <td class="text-left">{{data.current_location}}</td>
                                                                    <td class="text-left">{{data.move_location}}</td> -->
                                    <td class="text-left pr-3">
                                      <div v-for="vin in frame.vin_data">
                                        {{ vin.vinNosLastSix }}
                                      </div>
                                    </td>
                                    <!-- <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.cabStyleNames}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.transModelNames}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupCntNames}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupPhones}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupEmails}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupAddresses}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupCities}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupStates}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.pickupZips}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destCntNames}}  </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destPhones}}  </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destEmails}}  </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destAddresses}}  </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destCities}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destStates}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.destZips}} </div></td>
                                                                    <td class="text-left pr-3"><div v-for="vin in frame.vin_data"> {{vin.transferInAccount}} </div></td> -->
                                    <td class="text-left pr-3">
                                      <div v-for="vin in frame.vin_data">
                                        {{ vin.sellingPrices }}
                                      </div>
                                    </td>
                                    <td class="text-left pr-3">
                                      <div v-for="vin in frame.vin_data">
                                        {{ vin.poNo }}
                                      </div>
                                    </td>
                                  </tr>
                                  <!-- </template> -->
                                </tbody>
                              </table>
                            </b-card>
                          </b-collapse>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
        <!-- Export Modal -->
        <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportModalLabel">
                  Export Used Truck Move Submission
                </h4>
              </div>
              <form
                class="form-inline"
                id="exportModalForm"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderExportUsedTruckMovedSubmission"
                  ref="commonLoaderExportUsedTruckMovedSubmission"
                >
                </common-loader>
                <div class="col-md-12 modal-body" id="modal-checkbox">
                  <template v-if="exportHeaders && exportHeaders.length > 0">
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllExportHeader"
                          @click="selectAllExportHeaders()"
                          class="form-control"
                          id="ExportList"
                        />
                        <strong v-if="isSelectAllExportHeader"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p></strong
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(exportOpp, exportIndex) in exportHeaders">
                      <div
                        :key="exportIndex"
                        class="col-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="exportOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="exportSelectedHeaders"
                            @change="updateCheckedExportHeaders()"
                          />
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="exportSelectedHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../.././event-bus";
import { HTTP } from "../../../http-common";
import FilterComponent from "../../FilterComponent/FilterComponent";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListUsedTruckMovedSubmissionForm",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      frameArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_used_truck_moved_submission_form"],
      actionDisplay: true,
      exportData: false,
      accordianIds: [],
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      staticMessages: "",
      staticInputLabelMessages: "",
      exportUsedTruckMovedSubmissionFormInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadUsedTruckHoldsSubmissionFormData();
            },
            currentPage: function () {
                self.loadUsedTruckHoldsSubmissionFormData();
            }*/
    permissionNameArray: function () {
      if (
        !self.permissionNameArray.includes(
          "can_list_used_truck_moved_submission_form"
        )
      ) {
        self.$router.push({ name: "AddUpdateUsedTruckMovedSubmission" });
      }
    },
  },
  created: function () {
    self = this;
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    self.setActiveMenu("used_truck_move_submission_form");
    EventBus.$emit("menu-selection-changed", "used_truck_move_submission_form");
    var listHistoryData = self.loadFilters(
      "listUsedTruckMovedSubmission",
      self
    );
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;
    self.getLoginIdPermissions();

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadUsedTruckHoldsSubmissionFormData(1);
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.exportUsedTruckMovedSubmissionFormInstance = new bootstrap.Modal(
        document.getElementById("exportModal")
      );
    },
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    returnAccordianDisplayDataKeys: function () {
      let dataArray = [
        {
          field: "vin",
          label: "Vin",
          alt: "",
        },
        /* {
                        field : "current_location",
                        label : "Current Location",
                        alt : ""
                    },
                    {
                        field : "move_location",
                        label : "Move Location",
                        alt : ""
                    }, */
        /* {
                        field : "cab_style",
                        label : "Cab Style",
                        alt : ""
                    },
                    {
                        field : "trans_model",
                        label : "Trans Model",
                        alt : ""
                    },
                    {
                        field : "pickup_contact_name",
                        label : "Pickup Contact Name",
                        alt : ""
                    },
                    {
                        field : "pickup_phone",
                        label : "Pickup Phone",
                        alt : ""
                    },
                    {
                        field : "pickup_email",
                        label : "Pickup Email",
                        alt : ""
                    },
                    {
                        field : "pickup_address",
                        label : "Pickup Address",
                        alt : ""
                    },
                    {
                        field : "pickup_city",
                        label : "Pickup City",
                        alt : ""
                    },
                    {
                        field : "pickup_state",
                        label : "Pickup State",
                        alt : ""
                    },
                    {
                        field : "pickup_zip",
                        label : "Pickup Zip",
                        alt : ""
                    },
                    {
                        field : "dest_contact_name",
                        label : "Destination Contact Name",
                        alt : ""
                    },
                    {
                        field : "dest_phone",
                        label : "Destination Phone",
                        alt : ""
                    },
                    {
                        field : "dest_email",
                        label : "Destination Email",
                        alt : ""
                    },
                    {
                        field : "dest_address",
                        label : "Destination Address",
                        alt : ""
                    },
                    {
                        field : "dest_city",
                        label : "Destination City",
                        alt : ""
                    },
                    {
                        field : "dest_state",
                        label : "Destination State",
                        alt : ""
                    },
                    {
                        field : "dest_zip",
                        label : "Destination Zip",
                        alt : ""
                    },
                    {
                        field : "transfer_in_account",
                        label : "Transfer in Accounting",
                        alt : ""
                    } */
        {
          field: "selling_price",
          label: "Selling Price",
          alt: "",
        },
        {
          field: "po_number",
          label: "PO Number",
          alt: "",
        },
      ];

      return dataArray;
    },
    toggleIcon: function (id) {
      self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadUsedTruckHoldsSubmissionFormData();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadUsedTruckHoldsSubmissionFormData();
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadUsedTruckHoldsSubmissionFormData();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.frameArr) {
            self.selectedRows.push(self.frameArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.frameArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadUsedTruckHoldsSubmissionFormData();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadUsedTruckHoldsSubmissionFormData: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadUsedTruckHoldsSubmissionFormData();
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportData,
        export_selected_headers: self.exportSelectedHeaders,
      };
      this.storeFilters("listUsedTruckMovedSubmission", fields, false);
      if (self.exportData) {
        $("#commonLoaderExportUsedTruckMovedSubmission")
          .find(".loading-container.lodingTable")
          .show(); 
      } else {
        $("#commonLoaderListUsedTruckMovedSubmission")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/usedtruckmovedsubmissionform/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (self.exportData) {
            $("#commonLoaderExportUsedTruckMovedSubmission")
              .find(".loading-container.lodingTable")
              .hide(); 
          } else {
            $("#commonLoaderListUsedTruckMovedSubmission")
              .find(".loading-container.lodingTable")
              .hide();
          }
          /* self.exportSelectedHeaders = []; */
          self.exportSelectedHeaders = self.loadExportHeaders(
            "exportMoveSubmission",
            self
          );
          self.exportSelectedHeaders =
            self.exportSelectedHeaders === null ||
            typeof self.exportSelectedHeaders === "undefined" ||
            self.exportSelectedHeaders == ""
              ? []
              : self.exportSelectedHeaders;
          self.exportUsedTruckMovedSubmissionFormInstance.hide();
          /*$('#exportListData').text('Export');*/
          $("#exportListData").prop("disabled", false);
          self.exportData = false;
          if (data.status == 200) {
            if (data.data.message == "success export") {
              location.href = data.data.content.web;
            } else {
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                // alert(self.totalRecords);
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadUsedTruckHoldsSubmissionFormData();
                }, seconds);
                /* self.$swal({
                                        title: '',
                                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                        type: 'error',
                                        confirmButtonText: 'OK',
                                        showCancelButton: true,
                                        showLoaderOnConfirm: true
                                    }).then((result) => {
                                        if (result.value) {
                                            self.logout();
                                        } else {
                                            self.perPage = 15;
                                            self.loadUsedTruckHoldsSubmissionFormData();
                                        }
                                    }); */
              }
              self.frameArr = data.data.content.data;
              $("#selectAllCheckBox").prop("checked", false);
              self.headers = data.data.content.headers;
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              self.loadFilterInputs(data.data.content.headers, self.filters);

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          /*$('#exportListData').text('Export');*/
          $("#exportListData").prop("disabled", false);
          self.exportData = false;
          if (self.exportData) {
            $("#commonLoaderExportUsedTruckMovedSubmission")
              .find(".loading-container.lodingTable")
              .hide(); 
          } else {
            $("#commonLoaderListUsedTruckMovedSubmission")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(err, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadUsedTruckHoldsSubmissionFormData();
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
      /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
    },
    updateCheckedExportHeaders: function () {
      /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
                console.log("Total export header : " + self.exportHeaders.length); */
      if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
        self.isSelectAllExportHeader = true;
      } else {
        self.isSelectAllExportHeader = false;
      }
    },
    /* checkedExportHeaders: function (e, index, value) {
                if (e.target.checked) {
                    // Vue.set(self.exportSelectedHeaders, index, value);
                    self.exportSelectedHeaders.push(Vue.util.extend({}, value));
                } else {
                    // Vue.delete(self.exportSelectedHeaders, index);
                    for (var i = 0; i < self.exportSelectedHeaders.length; i++) {
                        if (self.exportSelectedHeaders[i]['name'] == value['name']) {
                            self.exportSelectedHeaders.splice(i, 1);
                        }
                    }
                }
                // console.log(JSON.stringify(self.exportSelectedHeaders));
            }, */
    exportListData: function () {
      self.exportUsedTruckMovedSubmissionFormInstance.show();
      /* self.exportData = true;
                if (self.exportData == true) {
                    // $('#exportListData').text('Exporting...');
                    $('#exportListData').prop("disabled", true);
                    self.loadUsedTruckHoldsSubmissionFormData();
                } */
    },
    exportSelectedHeadersData: function () {
      // if(Object.keys(self.exportSelectedHeaders).length == 0) {
      if (self.exportSelectedHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox input:checkbox').prop('checked', false); */
      self.exportData = true;
      if (self.exportData == true) {
        /*$('#exportListData').text('Exporting...');*/
        $("#exportListData").prop("disabled", true);
        self.loadUsedTruckHoldsSubmissionFormData();
        /* self.isSelectAllExportHeader = false; */
      }
      if (
        self.exportSelectedHeaders != null &&
        self.exportSelectedHeaders != "undefined" &&
        self.exportSelectedHeaders.length > 0
      ) {
        self.storeExportHeaders(
          "exportMoveSubmission",
          self.exportSelectedHeaders
        );
      } else {
        self.exportSelectedHeaders = [];
      }
    },
    stringTrim: function (string, length) {
      length = typeof length !== "undefined" ? length : 20;
      var string1 =
        string.length > length ? string.substring(0, length) + "..." : string;
      return string1;
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes(
        "can_add_used_truck_moved_submission_form"
      )
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>
