<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_repair_order_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>{{pageTitle}}</span>
                        </div>
                        <a v-if="displayPage > 1" href="javascript:void(0)" class="btn btn-primary waves-effect pull-right ml-2" title='Back' v-on:click.prevent="BacktoPreviousPage(displayPage - 1)">
                            Back
                        </a>
                    </div>
                    <template v-if="displayPage == 1">
                        <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                            <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionRepairOrders()">X</a></span>
                        </div>
                        <div class="col-12 paddingnone">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="repairOrderArr && repairOrderArr.length > 0">
                                        <tr v-for="(repairOrder, index) in repairOrderArr" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="repairOrder.id" @change="inputCheckboxRepairOrders($event)" class="custom-control-input" :id="'customCheck' + repairOrder.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + repairOrder.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions">
                                                    <a v-show="permissionNameArray.includes('can_list_repair_order_master')" href="javascript:void(0)" class="mar-right-0" title='View Repair Order Ops' v-on:click.prevent="getRepairOrderOps(repairOrder.id)">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td :class="rowClass.sls_id" >{{repairOrder.sls_id}}</td>
                                            <td :class="rowClass.sls_typ_id" >{{repairOrder.sls_typ_id}}</td>
                                            <td :class="rowClass.unt_id">{{repairOrder.unt_id}}</td>
                                            <!--<td>{{repairOrder.website_name}}</td>-->
                                            <!--<td>{{repairOrder.address}}</td>-->
                                            <td :class="rowClass.vin">{{repairOrder.vin}}</td>
                                            <td :class="rowClass.fleet_unt_id">{{repairOrder.fleet_unt_id}}</td>
                                            <td :class="rowClass.cus_name">{{repairOrder.cus_name}}</td>
                                            <td :class="rowClass.cus_phone">{{repairOrder.cus_phone}}</td>
                                            <td :class="rowClass.email">{{repairOrder.email}}</td>
                                            <td :class="rowClass.created_at">{{repairOrder.created_at}}</td>
                                            <td :class="rowClass.updated_at">{{repairOrder.updated_at}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBoxRepairOrders()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableDataRepairOrders(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- Pagination Start -->
                            <div class="vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPageRepairOrders"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepageRepairOrders()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitorRepairOrders()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                        </div>
                    </template>
                    <template v-if="displayPage == 2">
                        <div class="col-12 paddingnone list-common-actions" v-if="selectedRowsOps.length >= 1">
                            <span class="color-cc9636">{{selectedRowsOps.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionOps()">X</a></span>
                        </div>
                        <div class="col-12 paddingnone">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="repairOrderOpsArr && repairOrderOpsArr.length > 0">
                                        <tr v-for="(repairOrderOps, index) in repairOrderOpsArr" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRowsOps" :value="repairOrderOps.id" @change="inputCheckboxOps($event)" class="custom-control-input" :id="'customCheck' + repairOrderOps.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + repairOrderOps.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplayOps">
                                                <div class="actions">
                                                    <a v-show="permissionNameArray.includes('can_list_repair_order_master')" href="javascript:void(0)" class="mar-right-0" title='List Repair Order Items' v-on:click.prevent="getRepairOrderItms()">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td :class="rowClass.sls_id" >{{repairOrderOps.sls_id}}</td>
                                            <td :class="rowClass.ops_id" >{{repairOrderOps.ops_id}}</td>
                                            <td :class="rowClass.job_id">{{repairOrderOps.job_id}}</td>
                                            <td :class="rowClass.sls_typ_id">{{repairOrderOps.sls_typ_id}}</td>
                                            <td :class="rowClass.failed_prt_id">{{repairOrderOps.failed_prt_id}}</td>
                                            <td :class="rowClass.clm_nbr">{{repairOrderOps.clm_nbr}}</td>
                                            <td :class="rowClass.status">{{repairOrderOps.status_des}}</td>
                                            <td :class="rowClass.amt_clm">{{repairOrderOps.amt_clm}}</td>
                                            <td :class="rowClass.use_job_prices">{{repairOrderOps.use_job_prices}}</td>
                                            <td :class="rowClass.request">{{repairOrderOps.request}}</td>
                                            <td :class="rowClass.amt_parts">{{repairOrderOps.amt_parts}}</td>
                                            <td :class="rowClass.amt_cost_parts">{{repairOrderOps.amt_cost_parts}}</td>
                                            <td :class="rowClass.amt_labor">{{repairOrderOps.amt_labor}}</td>
                                            <td :class="rowClass.amt_cost_labor">{{repairOrderOps.amt_cost_labor}}</td>
                                            <td :class="rowClass.amt_sublet">{{repairOrderOps.amt_sublet}}</td>
                                            <td :class="rowClass.amt_cost_sublet">{{repairOrderOps.amt_cost_sublet}}</td>
                                            <td :class="rowClass.amt_misc">{{repairOrderOps.amt_misc}}</td>
                                            <td :class="rowClass.amt_cost_misc">{{repairOrderOps.amt_cost_misc}}</td>
                                            <td :class="rowClass.amt_tax">{{repairOrderOps.amt_tax}}</td>
                                            <td :class="rowClass.amt_subtotal">{{repairOrderOps.amt_subtotal}}</td>
                                            <td :class="rowClass.created_at">{{repairOrderOps.created_at}}</td>
                                            <td :class="rowClass.updated_at">{{repairOrderOps.updated_at}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headersOps.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAllOps" type="checkbox" class="custom-control-input" id="selectAllOpsCheckBox" name="example1" @change="clickHeaderCheckBoxOps()">
                                                <label class="custom-control-label" for="selectAllOpsCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headersOps">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headersOps">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableDataOps(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- Pagination Start -->
                            <div class="vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 perPageOps">
                                        <v-select title="Select Per Page"
                                                v-model="perPageOps"
                                                @input="changeperPageOps"
                                                name="customperPageOps"
                                                id="customperPageOps"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArrOps"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecordOps}} of {{totalRecordsOps}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecordsOps >= perPageOps" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecordsOps > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsOps" v-model="currentPageOps" @input="changepageOps()" :per-page="perPageValueOps"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPageOps" type="text" class="form-control form-control-sm" name="jumpToPageOps" id="jumpToPageOps" v-on:keyup.enter="keyMonitorOps()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                        </div>
                    </template>
                    <template v-if="displayPage == 3">
                        <div class="col-12 paddingnone list-common-actions" v-if="selectedRowsItm.length >= 1">
                            <span class="color-cc9636">{{selectedRowsItm.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionItm()">X</a></span>
                        </div>
                        <div class="col-12 paddingnone">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="repairOrderItm && repairOrderItm.length > 0">
                                        <tr v-for="(repairItms, index) in repairOrderItm" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRowsItm" :value="repairItms.id" @change="inputCheckboxItm($event)" class="custom-control-input" :id="'customCheck' + repairItms.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + repairItms.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplayItm">
                                                <div class="actions">
                                                    <template v-if="repairItms.itm_typ == '590'">
                                                        <a v-show="permissionNameArray.includes('can_list_repair_order_master')" href="javascript:void(0)" class="mar-right-0" title='View Repair Order EmpLbr' v-on:click.prevent="getRepairOrderLbrs(repairItms.itm_id)">
                                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                        </a>
                                                    </template>
                                                </div>
                                            </td>
                                            <td :class="rowClass.itm_id" >{{repairItms.itm_id}}</td>
                                            <td :class="rowClass.sls_id" >{{repairItms.sls_id}}</td>
                                            <td :class="rowClass.ops_id" >{{repairItms.ops_id}}</td>
                                            <td :class="rowClass.itm_typ">{{repairItms.itm_des}}</td>
                                            <td :class="rowClass.sv_itm">{{repairItms.sv_itm}}</td>
                                            <td :class="rowClass.amt_price">{{repairItms.amt_price}}</td>
                                            <td :class="rowClass.amt_price_suggest">{{repairItms.amt_price_suggest}}</td>
                                            <td :class="rowClass.amt_cost">{{repairItms.amt_cost}}</td>
                                            <td :class="rowClass.qty_ship">{{repairItms.qty_ship}}</td>
                                            <td :class="rowClass.qty_backorder">{{repairItms.qty_backorder}}</td>
                                            <td :class="rowClass.hrs_flat">{{repairItms.hrs_flat}}</td>
                                            <td :class="rowClass.hrs_bill">{{repairItms.hrs_bill}}</td>
                                            <td :class="rowClass.hrs_cost">{{repairItms.hrs_cost}}</td>
                                            <td :class="rowClass.hrs_actual">{{repairItms.hrs_actual}}</td>
                                            <td :class="rowClass.mins_per_segment">{{repairItms.mins_per_segment}}</td>
                                            <td :class="rowClass.use_hrs_flat">{{repairItms.use_hrs_flat}}</td>
                                            <td :class="rowClass.complete">{{repairItms.complete}}</td>
                                            <td :class="rowClass.emp_id_lbr">{{repairItms.emp_id_lbr}}</td>
                                            <td :class="rowClass.tax_id">{{repairItms.tax_id}}</td>
                                            <td :class="rowClass.emp_id">{{repairItms.emp_id}}</td>
                                            <td :class="rowClass.emp_id_spn">{{repairItms.emp_id_spn}}</td>
                                            <td :class="rowClass.created_at">{{repairItms.created_at}}</td>
                                            <td :class="rowClass.updated_at">{{repairItms.updated_at}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headersItm.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAllItm" type="checkbox" class="custom-control-input" id="selectAllItmCheckBox" name="example1" @change="clickHeaderCheckBoxItm()">
                                                <label class="custom-control-label" for="selectAllItmCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headersItm">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headersItm">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- Pagination Start -->
                            <div class="vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPageItm"
                                                @input="changePerPageItm"
                                                name="customperPageItm"
                                                id="customperPageItm"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArrItm"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecordItm}} of {{totalRecordsItm}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecordsItm >= perPageItm" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecordsItm > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsItm" v-model="currentPageItm" @input="changepageItm()" :per-page="perPageValueItm"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPageItm" type="text" class="form-control form-control-sm" name="jumpToPageItm" id="jumpToPageItm" v-on:keyup.enter="keyMonitorItm()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                        </div>
                    </template>
                    <template v-if="displayPage == 4">
                        <div class="col-12 paddingnone list-common-actions" v-if="selectedRowsLbr.length >= 1">
                            <span class="color-cc9636">{{selectedRowsLbr.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionLbr()">X</a></span>
                        </div>
                        <div class="col-12 paddingnone">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="repairOrderEmpLbr && repairOrderEmpLbr.length > 0">
                                        <tr v-for="(repOrdEmpLbr, index) in repairOrderEmpLbr" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRowsLbr" :value="repOrdEmpLbr.id" @change="inputCheckboxLbr($event)" class="custom-control-input" :id="'customCheck' + repOrdEmpLbr.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + repOrdEmpLbr.id"></label>
                                                </div>
                                            </td>
                                            <!-- <td v-show="actionDisplayLbr">
                                                <div class="actions">
                                                </div>
                                            </td> -->
                                            <td :class="rowClass.itm_id" >{{repOrdEmpLbr.itm_id}}</td>
                                            <td :class="rowClass.emp_id" >{{repOrdEmpLbr.emp_id}}</td>
                                            <td :class="rowClass.parent_itm_id" >{{repOrdEmpLbr.parent_itm_id}}</td>
                                            <td :class="rowClass.itm_typ">{{repOrdEmpLbr.itm_typ_des}}</td>
                                            <td :class="rowClass.mins_elapsed">{{repOrdEmpLbr.mins_elapsed}}</td>
                                            <td :class="rowClass.hrs_typ">{{repOrdEmpLbr.hrs_typ_des}}</td>
                                            <td :class="rowClass.amt_cost_hrs">{{repOrdEmpLbr.amt_cost_hrs}}</td>
                                            <td :class="rowClass.update_emp_id">{{repOrdEmpLbr.update_emp_id}}</td>
                                            <td :class="rowClass.date_start">{{repOrdEmpLbr.created_at}}</td>
                                            <td :class="rowClass.date_end">{{repOrdEmpLbr.date_end}}</td>
                                            <td :class="rowClass.updated_at">{{repOrdEmpLbr.updated_at}}</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headersLbr.length" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                    <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAllLbr" type="checkbox" class="custom-control-input" id="selectAllLbrCheckBox" name="example1" @change="clickHeaderCheckBoxLbr()">
                                                <label class="custom-control-label" for="selectAllLbrCheckBox"></label>
                                            </div>
                                        </th>
                                        <!-- <template v-for="(data, headerIndex) in headersLbr">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template> -->
                                        <template v-for="(data, headerIndex) in headersLbr">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableDataLbr(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- Pagination Start -->
                            <div class="vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPageLbr"
                                                @input="changePerPageLbr"
                                                name="customperPageLbr"
                                                id="customperPageLbr"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArrLbr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecordLbr}} of {{totalRecordsLbr}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecordsLbr >= perPageLbr" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecordsLbr > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsLbr" v-model="currentPageLbr" @input="changepageLbr()" :per-page="perPageValueLbr"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPageLbr" type="text" class="form-control form-control-sm" name="jumpToPageLbr" id="jumpToPageLbr" v-on:keyup.enter="keyMonitorLbr()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "created_at";
    var filterType = "desc";

    var filterColumn2 = "created_at";
    var filterType2 = "desc";

    var filterColumn3 = "created_at";
    var filterType3 = "desc";

    var filterColumn4 = "created_at";
    var filterType4 = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListAssetRepairOrder',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                displayPage : 1, /* 1= Repair Orders, 2= Repair Order OPS, 3= Repair Order Items, 4=Repair Order EmpLbr */
                /* Repair Order data Start */
                pageTitle : '',
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderArr: [],
                headers: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: "",
                selectAll: false,
                //permissionNameArray : ['can_list_repair_order_master'],
                actionDisplay : true,
                vinNo : "",
                jumpToHistoryPage : 1,
                records : 2500,
                /* Repair Order data End */
                /* Repair Order OPS data Start */
                perPageValueOps: 25,
                perPageOps: 25,
                currentPageOps: 1,
                totalRecordsOps: 0,
                paginationDropDownArrOps: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderOpsArr: [],
                headersOps: [],
                filtersOps : {
                },
                appliedHeadersOps : [],
                jumpToPageOps: 1,
                columnNameOps: filterColumn2,
                sortTypeOps: filterType2,
                selectedRowsOps: [],
                startEndRecordOps: "",
                selectAllOps: false,
                actionDisplayOps : true,
                jumpToHistoryPageOps : 1,
                /* Repair Order OPS data End */
                /* Repair Order Itms data Start */
                perPageValueItm: 25,
                perPageItm: 25,
                currentPageItm: 1,
                totalRecordsItm: 0,
                paginationDropDownArrItm: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderItm: [],
                headersItm: [],
                filtersItm : {
                },
                appliedHeadersItm : [],
                jumpToPageItm: 1,
                columnNameItm: filterColumn3,
                sortTypeItm: filterType3,
                selectedRowsItm: [],
                startEndRecordItm: "",
                selectAllItm: false,
                actionDisplayItm : true,
                jumpToHistoryPageItm : 1,
                filterCountItm : 0,
                /* Repair Order Itms data End */
                /* Repair Order Labors data Start */
                perPageValueLbr: 25,
                perPageLbr: 25,
                currentPageLbr: 1,
                totalRecordsLbr: 0,
                paginationDropDownArrLbr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderEmpLbr: [],
                headersLbr: [],
                filtersLbr : {
                },
                appliedHeadersLbr : [],
                jumpToPageLbr: 1,
                columnNameLbr: filterColumn4,
                sortTypeLbr: filterType4,
                selectedRowsLbr: [],
                startEndRecordLbr: "",
                selectAllLbr: false,
                actionDisplayLbr : true,
                jumpToHistoryPageLbr : 1,
                filterCountLbr : 0,
                itmIdLbr : '',
                slsIdLbr : '',
                /* Repair Order Labors data End */
                repairOrderId : 0
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch: {
        
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.listExecutionState = false;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            let headersNames = [];
            self.filters.vin = self.asset.content['asset_data'].vin_no;
            self.loadRepairOrders(1);
        },
        methods: {
            BacktoPreviousPage : function(pageId){
                self.displayPage = pageId;
                switch (pageId) {
                        case 1 :
                            self.loadRepairOrders(1);
                            break;
                        case 2 :
                            self.loadRepairOrdersOps(1);
                            break;
                        case 3 :
                            self.loadRepairOrdersItems(1);
                            break;
                    };
            },
            /* Repair Order List Methods Start */
            changePerPageRepairOrders : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadRepairOrders();
            },
            changepageRepairOrders : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrders();
                }
            },
            clearSelectionRepairOrders : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitorRepairOrders: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBoxRepairOrders: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.repairOrderArr) {
                            self.selectedRows.push(self.repairOrderArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxRepairOrders: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.repairOrderArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            loadRepairOrders: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadRepairOrders();
                }
                self.pageTitle = self.allLabelMessages.repair_orders_label;
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    asset_id : self.$route.params.id,
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType
                };
                self.showLoader();
                HTTP.post("/repairorder/list", fields, config)
                // HTTP.post("/assetwiserepairorder/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionRepairOrders();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadRepairOrders();
                                }, seconds);
                                /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPage = 15;
                                        self.loadRepairOrders();
                                    }
                                }); */
                            }
                            self.repairOrderArr = data.data.content.data;
                            self.headers = data.data.content.headers;

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            // self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            },
            sortTableDataRepairOrders: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadRepairOrders();
                }
            },
            /* Repair Order List Methods End */
            /* Repair Order OPS List Methods Start */
            getRepairOrderOps : function (id){
                self.repairOrderId = id;
                self.displayPage = 2;
                self.loadRepairOrdersOps(1);
            },
            loadRepairOrdersOps: function(first) {
                self.listExecutionState = true;
                self.pageTitle = self.allLabelMessages.repair_orders_ops_label;
                if(self.perPageOps == 'All' && self.totalRecordsOps > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    id : self.repairOrderId,
                    filters : self.filtersOps,
                    per_page: self.perPageOps,
                    page: self.currentPageOps,
                    sortColumnName: self.columnNameOps,
                    sortType: self.sortTypeOps
                };
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filtersOps);
                HTTP.post("/repairorderops/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPageOps = self.jumpToHistoryPageOps;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionOps();
                            self.headersOps = [];
                            self.totalRecordsOps = data.data.content.result;
                            self.repairOrderOpsArr = data.data.content.data;
                            self.headersOps = data.data.content.headers;
                            // self.loadFilterInputs(data.data.content.headers,self.filtersOps);

                            self.addColumnClass(self.headersOps);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headersOps.length; i++) {
                                if (self.headersOps[i]['type'] == 'drop_down') {
                                    self.headersOps[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecordsOps) {
                                self.perPageOps = parseInt(data.data.content.per_page);
                                self.jumpToPageOps = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPageOps == 'All'){
                                    self.jumpToPageOps = 1;
                                }
                                self.perPageValueOps = self.totalRecordsOps;
                                self.currentPageOps = 1;
                            }
                            // self.setAlreadyAppliedFilters();
                            self.startEndRecordOps = self.calculateStartRecordEndRecord(self.currentPageOps, self.perPageOps, self.totalRecordsOps);
                            self.sortFieldDisplay(self.columnNameOps,self.sortTypeOps);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            changeperPageOps : function(){
                self.currentPageOps = 1;
                if (self.perPageOps !== "All") {
                    self.perPageValueOps = self.perPageOps;
                }
                self.loadRepairOrdersOps();
            },
            changepageOps : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrdersOps();
                }
            },
            clearSelectionOps : function() {
                self.selectAllOps = false;
                self.selectedRowsOps = [];
            },
            keyMonitorOps: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPageOps, self.totalRecordsOps, self.perPageOps);
                self.currentPageOps = self.jumpToPageOps = parseInt(tempNumber);
            },
            clickHeaderCheckBoxOps: function() {
                setTimeout(function () {
                    self.selectedRowsOps = [];
                    if (self.selectAllOps) {
                        for (let i in self.repairOrderOpsArr) {
                            self.selectedRowsOps.push(self.repairOrderOpsArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxOps: function(e) {
                setTimeout(function () {
                    self.selectAllOps = false;
                    if (self.selectedRowsOps.length == self.repairOrderOpsArr.length) {
                        self.selectAllOps = true;
                    }
                }, 100);
            },
            sortTableDataOps: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnNameOps == name) {
                        self.sortTypeOps = (self.sortTypeOps == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortTypeOps = "asc";
                    }
                    self.columnNameOps = name;
                    self.loadRepairOrdersOps();
                }
            },
            /* Repair Order OPS List Methods End */
            /* Repair Order Itms List Methods Start */
            getRepairOrderItms : function (){
                self.displayPage = 3;
                self.loadRepairOrdersItems(1);
            },
            loadRepairOrdersItems: function(first) {
                self.listExecutionState = true;
                self.pageTitle = self.allLabelMessages.repair_orders_itm_label;
                if(self.perPageItm == 'All' && self.totalRecordsItm > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    id : self.repairOrderId,
                    filters : self.filtersItm,
                    per_page: self.perPageItm,
                    page: self.currentPageItm,
                    sortColumnName: self.columnNameItm,
                    sortType: self.sortTypeItm
                };
                // this.storeFilters('ListRepairOrder', fields, false);
                self.showLoader();
                self.filterCountItm = self.filterCountFunction(self.filtersItm);
                HTTP.post("/repairorderitem/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPageItm = self.jumpToHistoryPageItm;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionItm();
                            self.headersItm = [];
                            self.totalRecordsItm = data.data.content.result;
                            self.repairOrderItm = data.data.content.data;
                            self.headersItm = data.data.content.headers;
                            // self.loadFilterInputs(data.data.content.headers,self.filtersItm);

                            self.addColumnClass(self.headersItm);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headersItm.length; i++) {
                                if (self.headersItm[i]['type'] == 'drop_down') {
                                    self.headersItm[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecordsItm) {
                                self.perPageItm = parseInt(data.data.content.per_page);
                                self.jumpToPageItm = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPageItm == 'All'){
                                    self.jumpToPageItm = 1;
                                }
                                self.perPageValueItm = self.totalRecordsItm;
                                self.currentPageItm = 1;
                            }
                            // self.setAlreadyAppliedFilters();
                            self.startEndRecordItm = self.calculateStartRecordEndRecord(self.currentPageItm, self.perPageItm, self.totalRecordsItm);
                            self.sortFieldDisplay(self.columnNameItm,self.sortTypeItm);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            changePerPageItm : function(){
                self.currentPageItm = 1;
                if (self.perPageItm !== "All") {
                    self.perPageValueItm = self.perPageItm;
                }
                self.loadRepairOrdersItems();
            },
            changepageItm : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrdersItems();
                }
            },
            clearSelectionItm : function() {
                self.selectAllItm = false;
                self.selectedRowsItm = [];
            },
            keyMonitorItm: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPageItm, self.totalRecordsItm, self.perPageItm);
                self.currentPageItm = self.jumpToPageItm = parseInt(tempNumber);
            },
            clickHeaderCheckBoxItm: function() {
                setTimeout(function () {
                    self.selectedRowsItm = [];
                    if (self.selectAllItm) {
                        for (let i in self.repairOrderItm) {
                            self.selectedRowsItm.push(self.repairOrderItm[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxItm: function(e) {
                setTimeout(function () {
                    self.selectAllItm = false;
                    if (self.selectedRowsItm.length == self.repairOrderItm.length) {
                        self.selectAllItm = true;
                    }
                }, 100);
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnNameItm == name) {
                        self.sortTypeItm = (self.sortTypeItm == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortTypeItm = "asc";
                    }
                    self.columnNameItm = name;
                    self.loadRepairOrdersItems();
                }
            },
            /* Repair Order Itms List Methods End */
            /* Repair Order Labor List Methods Start */
            getRepairOrderLbrs : function (itm_id){
                self.itmIdLbr = itm_id;
                self.displayPage = 4;
                self.loadRepairOrdersLabor(1);
            },
            loadRepairOrdersLabor: function(first) {
                self.listExecutionState = true;
                self.pageTitle = self.allLabelMessages.repair_orders_lbr_label;
                if(self.perPageLbr == 'All' && self.totalRecordsLbr > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    id : self.itmIdLbr,
                    filters : self.filtersLbr,
                    per_page: self.perPageLbr,
                    page: self.currentPageLbr,
                    sortColumnName: self.columnNameLbr,
                    sortType: self.sortTypeLbr
                };
                // this.storeFilters('ListRepairOrder', fields, false);
                self.showLoader();
                self.filterCountLbr = self.filterCountFunction(self.filtersLbr);
                HTTP.post("/repairorderlbrs/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPageLbr = self.jumpToHistoryPageLbr;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionLbr();
                            self.headersLbr = [];
                            self.totalRecordsLbr = data.data.content.result;
                            self.repairOrderEmpLbr = data.data.content.data;
                            self.headersLbr = data.data.content.headers;
                            // self.loadFilterInputs(data.data.content.headers,self.filtersLbr);

                            self.addColumnClass(self.headersLbr);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headersLbr.length; i++) {
                                if (self.headersLbr[i]['type'] == 'drop_down') {
                                    self.headersLbr[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecordsLbr) {
                                self.perPageLbr = parseInt(data.data.content.per_page);
                                self.jumpToPageLbr = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPageLbr == 'All'){
                                    self.jumpToPageLbr = 1;
                                }
                                self.perPageValueLbr = self.totalRecordsLbr;
                                self.currentPageLbr = 1;
                            }
                            // self.setAlreadyAppliedFilters();
                            self.startEndRecordLbr = self.calculateStartRecordEndRecord(self.currentPageLbr, self.perPageLbr, self.totalRecordsLbr);
                            self.sortFieldDisplay(self.columnNameLbr,self.sortTypeLbr);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            changePerPageLbr : function(){
                self.currentPageLbr = 1;
                if (self.perPageLbr !== "All") {
                    self.perPageValueLbr = self.perPageLbr;
                }
                self.loadRepairOrdersLabor();
            },
            changepageLbr : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrdersLabor();
                }
            },
            clearSelectionLbr : function() {
                self.selectAllLbr = false;
                self.selectedRowsLbr = [];
            },
            keyMonitorLbr: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPageLbr, self.totalRecordsLbr, self.perPageLbr);
                self.currentPageLbr = self.jumpToPageLbr = parseInt(tempNumber);
            },
            clickHeaderCheckBoxLbr: function() {
                setTimeout(function () {
                    self.selectedRowsLbr = [];
                    if (self.selectAllLbr) {
                        for (let i in self.repairOrderEmpLbr) {
                            self.selectedRowsLbr.push(self.repairOrderEmpLbr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxLbr: function(e) {
                setTimeout(function () {
                    self.selectAllLbr = false;
                    if (self.selectedRowsLbr.length == self.repairOrderEmpLbr.length) {
                        self.selectAllLbr = true;
                    }
                }, 100);
            },
            sortTableDataLbr: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnNameLbr == name) {
                        self.sortTypeLbr = (self.sortTypeLbr == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortTypeLbr = "asc";
                    }
                    self.columnNameLbr = name;
                    self.loadRepairOrdersLabor();
                }
            },
            /* Repair Order Labor List Methods End */
        },
    }
</script>