<template>
  <div class="container-fluid xyz">
    <div class="spaced-container">
      <div class="col-12 page_title paddingnone">
        <div class="page_title_left">
          <span>{{ allLabelMessages.vehicle_work_sheet_label }}</span>
        </div>
        <button type="submit" v-on:click.prevent="closeWorkSheet()" class="btn btn-primary waves-effect pull-right">
          Close
        </button>
        <!-- <button style="margin-right: 5px;" type="submit" v-on:click.prevent="saveCloseWorkSheet()"
                class="btn btn-primary waves-effect pull-right">Save & Close
        </button> -->
        <button style="margin-right: 5px;" type="submit" v-on:click.prevent="saveCloseWorkSheet()"
                class="btn btn-primary waves-effect pull-right">{{ (dealInventoryMapperId !== null || isVehicleEdit) ? 'Update' : 'Save' }}
        </button>
      </div>
    </div>
    <div class="admin-page-content">
      <div class="forms-container add_user_forms_container vtc-dealer-forms">
        <div class="row no-margin">
          <div class="myaccordion" id="accordionVehicleWorksheet">
            <div class="card">
              <div class="card-header d-flex h-100" id="headingOne">
                <h2 class="mb-0 float-left">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseGrossProfit" aria-expanded="true" aria-controls="collapseGrossProfit">
                    <h3>Vehicle Information</h3>
                  </button>
                </h2>
                <span v-if="assetId.text" class="justify-content-center align-self-center text-bold">[{{ assetId.text}}, {{ unitId}}, {{ modelOEMId }}, {{ typeId }}]</span>
              </div>

              <div id="collapseGrossProfit" class="collapse show" aria-labelledby="headingOne">
                <div class="card-body">
                        <!-- Asset -->
                  <div class="col-md-6 mb-1 col-sm-6 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.asset_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="assetId"
                                name="asset"
                                label="text"
                                :filterable="false"
                                :options="assetArr"
                                @search="onSearchAsset"
                                :clearable="false"
                                :placeholder="staticMessages.asset_place_holder"
                                class="custom-ci-auto-search"
                                @input="populateFields"
                      >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssetRecordNotFound">
                                                {{ staticMessages.asset_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ assetError }}</span>
                    </div>
                  </div>
                  <!-- Unit Id -->
                  <div class="col-md-6 mb-1 col-sm-6 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.unit_id_label }}</div>
                    </div>
                    <div class="col-md-7 col-sm-12 paddingleft0">
                      <v-select v-model="unitId"
                                name="unitId"
                                label="text"
                                :filterable="false"
                                :options="unitArr"
                                @search="onSearchUnit"
                                :clearable="false"
                                @input="unitError = ''"
                                :placeholder="staticMessages.unit_place_holder"
                                class="custom-ci-auto-search"
                      >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUnitRecordNotFound">
                                                {{ staticMessages.unit_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ unitError }}</span>
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <button class="btn btn-primary" @click="vehicleDetailModal()">Detail</button>
                    </div>
                  </div>
                  <!-- Manufacturer -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.manufacturer_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="manufacturerId"
                                name="manufacturerId"
                                label="text"
                                :filterable="false"
                                :options="manufacturerArr"
                                @search="onSearchManufacturer"
                                :clearable="false"
                                @input="manufacturerError = ''"
                                :placeholder="staticMessages.manufacturer_place_holder"
                                class="custom-ci-auto-search"
                      >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isManufacturerRecordNotFound">
                                                {{ staticMessages.manufacturer_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ manufacturerError }}</span>
                    </div>
                  </div>
                  <!-- Year -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.year_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ yearError }}</span>
                    </div>
                  </div>
                  <!-- Model/OEM -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.model_and_oem_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="modelOEMId"
                                name="modelOEMId"
                                label="text"
                                :filterable="false"
                                :options="modelOEMArr"
                                @search="onSearchModelOEM"
                                :clearable="false"
                                @input="modelOEMError = ''"
                                :placeholder="staticMessages.model_oem_place_holder"
                                class="custom-ci-auto-search"
                      >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isModelOEMRecordNotFound">
                                                {{ staticMessages.model_oem_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ modelOEMError }}</span>
                    </div>
                  </div>
                  <!-- Color -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.color_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="colorError = ''" id="color" v-model="color" class="form-control" type="text"
                            maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ colorError }}</span>
                    </div>
                  </div>
                  <!-- Stock Type -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.stock_type_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="stockTypeId"
                                @input="stockTypeError = ''"
                                name="stockTypeId"
                                id="stockTypeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="stockTypeArr"
                                :placeholder="staticMessages.stock_type_place_holder"
                      >
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ stockTypeError }}</span>
                    </div>
                  </div>
                  <!-- Body Type -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.body_type_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="bodyTypeError = ''" id="bodyType" v-model="bodyType" class="form-control" type="text"
                            maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ bodyTypeError }}</span>
                    </div>
                  </div>
                  <!-- Type -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.asset_type_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="typeId"
                                @input="typeError = ''"
                                name="typeId"
                                id="typeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="typeArr"
                                :placeholder="staticMessages.type_place_holder"
                      >
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ typeError }}</span>
                    </div>
                  </div>
                  <!-- Duty -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.duty_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="duty"
                                @input="dutyError = ''"
                                name="duty"
                                id="duty"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="dutyArr"
                                :placeholder="staticMessages.duty_place_holder"
                      >
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ dutyError }}</span>
                    </div>
                  </div>
                  <!-- Fuel Type -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.fuel_type_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="fuelTypeId"
                                @input="fuelTypeError = ''"
                                name="fuelTypeId"
                                id="fuelTypeId"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="fuelTypeArr"
                                :placeholder="staticMessages.fuel_type_place_holder"
                      >
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ fuelTypeError }}</span>
                    </div>
                  </div>
                  <!-- Stock/Order -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.stock_order_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <v-select v-model="stockOrder"
                                @input="stockOrderError = ''"
                                name="stockOrder"
                                id="stockOrder"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="stockOrderArr"
                                :placeholder="staticMessages.stock_order_place_holder"
                      >
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ stockOrderError }}</span>
                    </div>
                  </div>
                  <!-- Odometer -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.odometer_label }}</div>
                    </div>
                    <div class="col-md-7 col-sm-12 paddingleft0">
                      <input @input="odometerError = ''" id="odometer" v-model="odometer" class="form-control" type="text"
                            maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ odometerError }}</span>
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <b-form-radio-group v-model="paymentType"
                                          :options="paymentTypeOption"
                                          name="paymentType">
                      </b-form-radio-group>
                    </div>
                  </div>
                  <!-- Scale Weight -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.scale_weight_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="scaleWeightError = ''" id="scaleWeight" v-model="scaleWeight" class="form-control"
                            type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ scaleWeightError }}</span>
                    </div>
                  </div>
                  <!-- Vehicle Description -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.vehicle_description_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="vehicleDescriptionError = ''" id="vehicleDescription" v-model="vehicleDescription"
                            class="form-control" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ vehicleDescriptionError }}</span>
                    </div>
                  </div>
                  <!-- # of Batteries -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.of_batteries_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="ofBatteriesError = ''" id="ofBatteries" v-model="ofBatteries" class="form-control"
                            type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ ofBatteriesError }}</span>
                    </div>
                  </div>
                  <!-- # of Tires -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.of_tires_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="ofTiresError = ''" id="ofTires" v-model="ofTires" class="form-control" type="text"
                            maxlength="190" @keyup="event => {incrementDecrementNumber(event, 'ofTires')}" />
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ ofTiresError }}</span>
                    </div>
                  </div>
                  <!-- # of Axles -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.of_axles_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <input @input="ofAxlesError = ''" id="ofAxles" v-model="ofAxles" class="form-control" type="text"
                            maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ ofAxlesError }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- Private Use -->
                    <div class="col-md-6 forms-block float-right">
                      <div class="col-md-1 custom-checkbox-v">
                        <label class="container">
                          <input id="privateUse" v-model="privateUse" type="checkbox" @change="checkUncheck($event, 'privateUse')"/>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <div class="form-label text-left">{{ staticInputLabelMessages.private_use_label }}</div>
                      </div>
                      <!-- Business Use -->
                      <div class="col-md-1 custom-checkbox-v">
                        <label class="container">
                          <input id="businessUse" v-model="businessUse" type="checkbox" @change="checkUncheck($event, 'businessUse')"/>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-md-4">
                        <div class="form-label text-left">{{ staticInputLabelMessages.business_use_label }}</div>
                      </div>
                      <div class="col-md-2"></div>
                    </div>
                  </div>
                  <!-- Proposed Delivery Date -->
                  <div class="col-md-6 col-sm-6 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.proposed_delivery_date_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <date-picker
                        v-model="proposedDeliveryDate"
                        :config="options"
                        class="form-control"
                        autocomplete="off"
                        :placeholder="staticMessages.proposed_delivery_date_place_holder"
                        name="proposedDeliveryDate"
                        @input="changeProposedDeliveryDate()"
                      >
                      </date-picker>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ proposedDeliveryDateError }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header d-flex h-100" id="headingOne">
                <h2 class="mb-0 float-left">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFees" aria-expanded="true" aria-controls="collapseFees">
                    <h3>Fees</h3>
                  </button>
                </h2>
                <span class="justify-content-center align-self-center text-bold">($ {{ feesTotal.toFixed(2) }})</span>
              </div>

              <div id="collapseFees" class="collapse show" aria-labelledby="headingOne">
                <div class="card-body">
                  <!-- GVW GCWR -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.gvw_gcwr_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <input @input="gvwGCWRError = ''" id="gvwGCWR" v-model="gvwGCWR" class="form-control inputSize" type="text" maxlength="190" @blur="calVehiclePrice()" @keyup="event => {incrementDecrementNumber(event, 'gvwGCWR')}" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ gvwGCWRError }}</span>
                      </div>
                    </div>
                    <!-- Doc Preparation Fee -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.doc_preparation_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money @input="docPreparationError = ''" id="docPreparation" v-model="docPreparation" class="form-control inputAmount" type="text" maxlength="190"  v-bind="moneyMask" @keyup.native="event => {incrementDecrementNumber(event, 'docPreparation')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ docPreparationError }}</span>
                      </div>
                    </div>
                    <!-- Customer Supplied Body -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.customer_supplied_body_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="customerSuppliedBodyError = ''" id="customerSuppliedBody" v-model="customerSuppliedBody" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event,'customerSuppliedBody')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ customerSuppliedBodyError }}</span>
                      </div>
                    </div>
                    <!-- License/Title -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.license_title_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="licenseTitleError = ''" id="licenseTitle" v-model="licenseTitle" class="form-control inputAmount" type="text" maxlength="190" :disabled="!customFee" @keyup.native="event => {incrementDecrementNumber(event, 'licenseTitle')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ licenseTitleError }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 forms-block mb-1">
                      <div class="col-md-4 mb-1"></div>
                      <div class="col-md-4 mb-0 forms-block">
                        <div class="col-md-2"></div>
                        <!-- No License -->
                        <div class="col-md-1 custom-checkbox-v">
                          <label class="container">
                            <input id="noLicense" v-model="noLicense" type="checkbox" @change="checkUncheck($event, 'noLicense')" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-md-4 paddingleft0">
                          <div class="form-label text-left">{{ staticInputLabelMessages.no_license_label }}</div>
                        </div>
                        <!-- Custom Fee -->
                        <div class="col-md-1 paddingleft0 custom-checkbox-v">
                          <label class="container">
                            <input id="customFee" v-model="customFee" type="checkbox" @change="checkUncheck($event, 'customFee')" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-md-4 paddingleft0">
                          <div class="form-label text-left">{{ staticInputLabelMessages.custom_fee_label }}</div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-1"></div>
                    </div>
                    <!-- Tire Fee -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.tire_fee_label }}</div>
                      </div>
                      <div class="col-md-4 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="tireFeeError = ''" id="tireFee" v-model="tireFee" class="form-control inputAmount" type="text" disabled/>
                      </div>
                      <div class="col-md-2 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" id="tireFee" v-model="tireTax" class="form-control inputAmount" type="text" @keyup.native="event => {incrementDecrementNumber(event, 'tireTax')}" />
                      </div>
                    </div>
                    <!-- Registration -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.registration_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="registrationError = ''" id="registration" v-model="registration" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'registration')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ registrationError }}</span>
                      </div>
                    </div>
                    <!-- Flooring -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.flooring_label }}</div>
                      </div>
                      <div class="col-md-4 col-sm-6 paddingleft0">
                        <money v-bind="moneyMask" @input="flooringError = ''" id="flooring" v-model="flooring" class="form-control inputAmount" type="text"
                              maxlength="190" disabled />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ flooringError }}</span>
                      </div>
                      <div class="col-md-2 col-sm-6 paddingleft0">
                          <span style="text-decoration: underline !important; cursor: pointer;" @click="calFlooring()">Flooring Calc</span>
                      </div>
                    </div>
                    <!-- Smog Fee Paid To Seller -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.smog_fee_paid_to_seller_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="smogFeePaidToSellerError = ''" id="smogFeePaidToSeller" v-model="smogFeePaidToSeller" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'smogFeePaidToSeller')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ smogFeePaidToSellerError }}</span>
                      </div>
                    </div>
                    <!-- Tire Credit -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.tire_credit_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="tireCreditError = ''" id="tireCredit" v-model="tireCredit" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'tireCredit')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ tireCreditError }}</span>
                      </div>
                    </div>
                    <!-- Smog Certification or Exemption Fee Paid to State -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">
                          {{ staticInputLabelMessages.smog_certification_or_exemption_fee_paid_to_state_label }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="smogCertificationOrExemptionFeePaidToStateError = ''"
                              id="smogCertificationOrExemptionFeePaidToState"
                              v-model="smogCertificationOrExemptionFeePaidToState" class="form-control inputAmount" type="text"
                              maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'smogCertificationOrExemptionFeePaidToState')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ smogCertificationOrExemptionFeePaidToStateError }}</span>
                      </div>
                    </div>
                    <!-- FET Exempt Items -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.fet_exempt_items_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="fetExemptItemsError = ''" id="fetExemptItems" v-model="fetExemptItems" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'fetExemptItems')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ fetExemptItemsError }}</span>
                      </div>
                    </div>
                    <!-- DMV Electronic Filling Fee -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.dmv_electronic_filling_fee_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="dmvElectronicFillingFeeError = ''" id="dmvElectronicFillingFee" v-model="dmvElectronicFillingFee" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'dmvElectronicFillingFee')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ dmvElectronicFillingFeeError }}</span>
                      </div>
                    </div>
                    <!-- Fet -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.fet_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="fetError = ''" id="fet" v-model="fet" class="form-control inputAmount" type="text" maxlength="190" :disabled="!customFet" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ fetError }}</span>
                      </div>
                    </div>
                    <!-- CCTTS Only - Admin Fee -->
                    <div class="col-md-6 col-sm-6 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.cctts_only_admin_fee_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="ccttsOnlyAdminFeeError = ''" id="ccttsOnlyAdminFee" v-model="ccttsOnlyAdminFee" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'ccttsOnlyAdminFee')}" @blur.native="sumFees()" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ ccttsOnlyAdminFeeError }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 forms-block">
                      <div class="col-md-4"></div>
                      <div class="col-md-4 forms-block">
                        <div class="col-md-2"></div>
                        <!-- No License -->
                        <div class="col-md-1 custom-checkbox-v">
                          <label class="container">
                            <input id="fetExempt" v-model="fetExempt" type="checkbox" @change="checkUncheck($event, 'fetExempt')"/>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-md-4 paddingleft0">
                          <div class="form-label">{{ staticInputLabelMessages.fet_exempt_label }}</div>
                        </div>
                        <!-- Custom Fet -->
                        <div class="col-md-1 paddingleft0 custom-checkbox-v">
                          <label class="container">
                            <input id="customFet" v-model="customFet" type="checkbox" @change="checkUncheck($event, 'customFet')"/>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-md-4 paddingleft0">
                          <div class="form-label">{{ staticInputLabelMessages.custom_fet_label }}</div>
                        </div>
                      </div>
                      <div class="col-md-4"></div>
                    </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseCustomFields" aria-expanded="true" aria-controls="collapseCustomFields">
                    <h3>Custom Fields</h3>
                  </button>
                </h2>
              </div>

              <div id="collapseCustomFields" class="collapse show" aria-labelledby="headingOne">
                <div class="card-body">
                  <!-- Finance Reserve -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.finance_reserve_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="financeReserveError = ''" id="financeReserve" v-model="financeReserve" class="form-control inputAmount" type="text" maxlength="190" @blur.native="calVehiclePrice()" @keyup.native="event => {incrementDecrementNumber(event, 'financeReserve')}" />
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ financeReserveError }}</span>
                    </div>
                  </div>
                  <!-- Factory Price -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.factory_price_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="factoryPriceError = ''" id="factoryPrice" v-model="factoryPrice" class="form-control inputAmount" type="text" maxlength="190" @blur.native="calVehiclePrice()" @keyup.native="event => {incrementDecrementNumber(event, 'factoryPrice')}" />
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ factoryPriceError }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">Deal Adjustment NC:</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" id="tbCustom3" v-model="dealAdjustment" class="form-control inputAmount"
                              type="text" @blur.native="calVehiclePrice()" @keyup.native="event => {incrementDecrementNumber(event, 'dealAdjustment')}" />
                    </div>
                  </div>
                  <!-- Blank Block -->
                  <div class="col-md-12 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0 d-none">
                      <div class="form-label text-right">tbCustom4</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0 d-none">
                      <money v-bind="moneyMask" id="tbCustom4" v-model="custom4" class="form-control inputAmount"
                            type="text" @keyup.native="event => {incrementDecrementNumber(event, 'custom4')}"/>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">IMF/Sales Tax:</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" id="tbCustom3" v-model="imfSalesTax" class="form-control inputAmount"
                              type="text" @keyup.native="event => {incrementDecrementNumber(event, 'imfSalesTax')}" />
                    </div>
                  </div>
                  <!-- Fleet Reserve Cost -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.fleet_reserve_cost_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="fleetReserveCostError = ''" id="fleetReserveCost" v-model="fleetReserveCost" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'fleetReserveCost')}" />
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ fleetReserveCostError }}</span>
                    </div>
                  </div>


                  <!-- Blank Block -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-12 col-sm-12 paddingleft0 d-none"></div>
                  </div>

                  <!-- Body Cost -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.body_cost_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="bodyCostError = ''" id="bodyCost" v-model="bodyCost" class="form-control inputAmount" type="text" maxlength="190" @blur.native="calVehiclePrice()" @keyup.native="event => {incrementDecrementNumber(event, 'bodyCost')}" />
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ bodyCostError }}</span>
                    </div>
                  </div>
                  <!-- ADOT Permit# -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.adot_permit_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <input @input="adotPermitError = ''" id="adotPermit" v-model="adotPermit" class="form-control inputSize" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ adotPermitError }}</span>
                    </div>
                  </div>
                  <!-- Fleet Reserve Ctrl# -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.fleet_reserve_ctrl_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <input @input="fleetReserveCtrlError = ''" id="fleetReserveCtrl" v-model="fleetReserveCtrl"
                            class="form-control inputSize" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ fleetReserveCtrlError }}</span>
                    </div>
                  </div>
                  <!-- Hold Date -->
                  <div class="col-md-6 col-sm-6 mb-4 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.hold_date_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <date-picker
                        v-model="holdDate"
                        :config="options"
                        class="form-control inputSize"
                        autocomplete="off"
                        :placeholder="staticMessages.hold_date_place_holder"
                        name="holdDate"
                        @input="changeHoldDate()"
                      >
                      </date-picker>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ holdDateError }}</span>
                    </div>
                  </div>
                  <!-- Factory Inv. Date -->
                  <div class="col-md-6 col-sm-6 mb-4 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.factory_inv_date_label }}</div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <date-picker
                        v-model="factoryInvDate"
                        :config="options"
                        class="form-control inputSize"
                        autocomplete="off"
                        :placeholder="staticMessages.factory_inv_date_place_holder"
                        name="factoryInvDate"
                        @input="changeFactoryInvDate()"
                      >
                      </date-picker>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ factoryInvDateError }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseVehicleCostCalculation" aria-expanded="false" aria-controls="collapseVehicleCostCalculation">
                    <h3>Vehicle Cost Calculation</h3>
                  </button>
                </h2>
              </div>
              <div id="collapseVehicleCostCalculation" class="collapse show" aria-labelledby="headingThree">
                <div class="card-body">
                  <div class="col-md-6 col-sm-6 mb-1 forms-block" style="border-right: 1px solid">
                    <!-- Current Inventory Cost From Procede -->
                    <div class="row mb-1">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.current_inventory_cost_from_procede_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="currentInventoryCostFromProcedeError = ''" id="currentInventoryCostFromProcede" v-model="currentInventoryCostFromProcede" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'currentInventoryCostFromProcede')}" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ currentInventoryCostFromProcedeError }}</span>
                      </div>
                    </div>
                    <!-- Inventory Cost (Last Updated) -->
                    <div class="row mb-1">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label text-right">{{ staticInputLabelMessages.inventory_cost_last_updated_label }}</div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="moneyMask" @input="inventoryCostLastUpdatedError = ''" id="inventoryCostLastUpdated" v-model="inventoryCostLastUpdated" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'inventoryCostLastUpdated')}" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ inventoryCostLastUpdatedError }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row pl-5">
                    <!-- Cost Sheet -->
                    <div class="col-md-6 col-sm-6 forms-block">
                      <div class="col-md-12 col-sm-12 paddingleft0">
                        <div class="form-label">&nbsp;</div>
                      </div>
                      <div class="col-md-12 col-sm-12 paddingleft0">
                        <button type="submit" @click="vehicleCostSheet()" class="btn btn-primary waves-effect">Cost Sheet
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 forms-block">
                    <div class="col-md-12 col-sm-12 paddingleft0">
                      <div class="form-label">&nbsp;</div>
                    </div>
                    <div class="col-md-12 col-sm-12 paddingleft0">
                      <!-- Add-On Lists -->
                      <button type="submit" @click="addOnModal()" class="btn btn-primary waves-effect">Add-On Lists</button>
                      <!-- Purchase Order -->
                      <button type="submit" @click="purchaseOrderModal()" class="btn btn-primary waves-effect">Purchase Order</button>
                    </div>
                  </div>
                  <!-- List -->
                  <div class="col-md-12 col-sm-12 forms-block">
                    <!-- TABLE -->
                    <table class="table custom-table jsmartable">
                      <tbody v-if="(addOnPoList != undefined) && addOnPoList.length > 0">
                        <tr v-for="(data, index) in addOnPoList" :key="data.id">
                          <td>{{data.asset_expenses_id == null ? 'Adds' : 'PO'}}</td>
                          <td>{{data.description}}</td>
                          <td>{{data.tax}}</td>
                          <td>$ {{data.cost.toFixed(2)}}</td>
                          <td>$ {{data.price.toFixed(2)}}</td>
                          <td><input type="checkbox" :checked="((parseInt(data.is_add_to_total_price) == 1) || data.is_add_to_total_price == true) ? true : false" disabled></td>
                          <td><input type="checkbox" :checked="((parseInt(data.is_fet_exempt) == 1) || data.is_fet_exempt == true) ? true : false" disabled></td>
                          <td><input type="checkbox" :checked="((parseInt(data.is_show_on_documents) || data.is_show_on_documents == true) == 1) ? true : false" disabled></td>
                          <td><input type="checkbox" :checked="((parseInt(data.is_do_not_add_to_gp) == 1) || data.is_do_not_add_to_gp == true) ? true : false" disabled></td>
                          <td>
                            <a v-on:click="editAddOn(data,index)" title='Edit Vehicle' href="javascript:void(0)" class="mar-right-0">
                              <i aria-hidden="true" class="fa fa-edit"></i>
                            </a>
                            <a v-on:click="deleteAddOn(data.id,index,data.asset_expenses_id)" title='Delete AddOn' href="javascript:void(0)">
                              <i aria-hidden="true" class="fa fa-trash-o text-danger"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        {{ staticInputLabelMessages.no_records_to_display_label }}
                      </tbody>
                      <thead>
                      <tr>
                        <th class="" data-breakpoint="xs">ITEM NAME</th>
                        <th class="" data-breakpoint="xs">DESCRIPTION</th>
                        <th class="" data-breakpoint="xs">TAX</th>
                        <th class="" data-breakpoint="xs">COST</th>
                        <th class="" data-breakpoint="xs">PRICE</th>
                        <th class="" data-breakpoint="xs">ADD TO PRICE</th>
                        <th class="" data-breakpoint="xs">EXCL FROM NET</th>
                        <th class="" data-breakpoint="xs">SHOW ON DOC</th>
                        <th class="" data-breakpoint="xs">DO NOT ADD TO GP</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <!-- Total Internal / Local Installation (Less Out of State Delivery Cost) -->
                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.total_internal_local_installation_less_out_of_state_delivery_cost_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="totalInternalLocalInstallationLessOutOfStateDeliveryCostError = ''"
                            id="totalInternalLocalInstallationLessOutOfStateDeliveryCost"
                            v-model="totalInternalLocalInstallationLessOutOfStateDeliveryCost" class="form-control inputAmount inputRight" type="text"
                            maxlength="190" disabled/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ totalInternalLocalInstallationLessOutOfStateDeliveryCostError }}</span>
                    </div>
                  </div>
                  <!-- Cost of Vehicle and Equipment (Including Flooring Cost) -->
                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.cost_of_vehicle_and_equipment_including_flooring_cost_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="costOfVehicleAndEquipmentIncludingFlooringCostError = ''"
                            id="costOfVehicleAndEquipmentIncludingFlooringCost"
                            v-model="costOfVehicleAndEquipmentIncludingFlooringCost" class="form-control inputAmount inputRight" type="text"
                            maxlength="190" disabled/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ costOfVehicleAndEquipmentIncludingFlooringCostError }}</span>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.hold_back_fixed_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @blur.native="fctFixedHoldBack()" id="holdBackFixed" v-model="holdBackFixed" class="form-control inputAmount"
                            type="text" maxlength="190" v-bind:disabled="customHoldBack == false"/>
                      <span class="help is-danger">{{ holdBackFixedError }}</span>
                    </div>
                  </div>

                  <div class="col-md-2 forms-block">
                    <div class="col-md-2 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input id="fetExempt" v-model="customHoldBack" type="checkbox" @change="calVehiclePrice()" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-md-10 paddingleft0">
                      <div class="form-label">{{ staticInputLabelMessages.customer_hold_back }}</div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-12 mb-1 forms-block">
                      <div class="col-md-6 col-sm-12 paddingright0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.percentage_label }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="rateMask" @blur.native="fctHoldBackPercentUpdate()" id="percentage" v-model="percentage" class="form-control" type="text"
                              maxlength="190"/>
                        <span class="help is-danger">{{ percentageError }}</span>
                      </div>
                  </div>

                  <div class="col-md-3 col-sm-12 mb-1 forms-block">

                      <div class="col-md-12 col-sm-2 paddingleft0">
                        <money v-bind="moneyMask" @input="holdBackError = ''" id="holdBack" v-model="holdBack" class="form-control inputAmount inputRight"
                              type="text" disabled/>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ holdBackError }}</span>
                      </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label text-danger">
                        Total Vehicle Cost:
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="totalvehiclecostError = ''"
                            id="totalvehiclecost"
                            v-model="totalvehiclecost" :class="['form-control inputAmount inputRight', totalvehiclecost != 0 ? 'bg-danger text-white' : '']" type="text"
                            maxlength="190" disabled/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ totalvehiclecostError }}</span>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseGrossProfit" aria-expanded="true" aria-controls="collapseGrossProfit">
                          <h3>Vehicle Fixed Margin</h3>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseGrossProfit" class="collapse show" aria-labelledby="headingOne">
                      <div class="card-body">
                        <!-- Gross Profit Dollar amount or Percentage -->
                        <div class="col-md-12 col-sm-12 forms-block">
                          <div class="col-md-3 col-sm-2 paddingleft0">
                            <div class="form-label text-right">{{ staticInputLabelMessages.gross_profit_dollar_amount_or_percentage_label }}=
                            </div>
                          </div>
                          <div class="col-md-2 col-sm-2 paddingleft0">
                            <money v-bind="moneyMask" @input="grossProfitDollarAmountError = ''" id="grossProfitDollarAmount"
                                  v-model="grossProfitDollarAmount" class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'grossProfitDollarAmount')}" />
                            <!-- <div class="requiredstar">*</div> -->
                            <span class="help is-danger">{{ grossProfitDollarAmountError }}</span>
                          </div>
                          <div class="col-md-2 col-sm-2 paddingleft0">
                            <money v-bind="rateMask" @input="grossProfitPercentError = ''" id="grossProfitPercent"
                                  v-model="grossProfitPercent" class="form-control" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'grossProfitPercent')}" />
                            <!-- <div class="requiredstar">*</div> -->
                            <span class="help is-danger">{{ grossProfitPercentError }}</span>
                          </div>
                          <div class="col-md-2 col-sm-2 paddingleft0">
                            <money v-bind="moneyMask" @input="grossProfitSalesPriceError = ''" id="grossProfitSalesPrice"
                                  v-model="grossProfitSalesPrice" class="form-control inputAmount" type="text" maxlength="190" disabled />
                            <!-- <div class="requiredstar">*</div> -->
                            <span class="help is-danger">{{ grossProfitSalesPriceError }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseVehiclePriceCalculation" aria-expanded="true" aria-controls="collapseVehiclePriceCalculation">
                    <h3>Vehicle Price Calculation</h3>
                  </button>
                </h2>
              </div>

              <div id="collapseVehiclePriceCalculation" class="collapse show" aria-labelledby="headingOne">
                <div class="card-body">

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-4 col-sm-12 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0" v-if="packed && typeId.toLowerCase() !== 'used'">
                        <div class="form-label">
                          {{ staticInputLabelMessages.price_fet_included_label }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0" v-if="packed && typeId.toLowerCase() !== 'used'">
                        <money v-bind="moneyMask" @blur.native="calVehiclePrice()" @input="priceFetIncludedError = ''" id="priceFetIncluded" v-model="priceFetIncluded"
                                class="form-control inputAmount" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'priceFetIncluded')}" />
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{ priceFetIncludedError }}</span>
                      </div>
                    </div>

                    <div class="col-md-2 forms-block">
                      <div class="col-md-2 paddingleft0 custom-checkbox-v">
                        <label class="container">
                          <input id="fetExempt" v-model="packed" type="checkbox" :disabled="typeId.toLowerCase() == 'used'" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-md-10 paddingleft0">
                        <div class="form-label">{{ staticInputLabelMessages.packed_label }}</div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12 forms-block">
                      <div class="row">
                        <div class="col-md-3 col-sm-12 paddingright0">
                          <div class="form-label">
                            {{ staticInputLabelMessages.base_sales_price_label }}
                          </div>
                        </div>
                        <div class="col-md-9 col-sm-12 paddingleft0">
                          <money v-bind="moneyMask" @input="vehicleSalesPriceError = ''" id="vehicleSalesPrice" v-model="vehicleSalesPrice" class="form-control inputAmount inputRight" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'vehicleSalesPrice')}" />
                          <span class="help is-danger">{{ vehicleSalesPriceError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.po_amount_to_add_to_sales_price_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="poAmountToAddToSalesPriceError = ''" id="poAmountToAddToSalesPrice"
                        v-model="poAmountToAddToSalesPrice" class="form-control inputAmount inputRight" type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ poAmountToAddToSalesPriceError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.frontend_sales_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="frontendSalesError = ''" id="frontendSales" v-model="frontendSales" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ frontendSalesError }}</span>
                    </div>
                  </div>

                  <div class="card mt-2 mb-2">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTradeDetail" aria-expanded="true" aria-controls="collapseTradeDetail">
                          <h3>Trade Detail</h3>
                        </button>
                      </h2>
                    </div>

                    <div id="collapseTradeDetail" class="collapse" aria-labelledby="headingOne">
                      <div class="card-body">
                        <div class="col-md-12 col-sm-12 text-center mb-2">
                          <button type="submit" @click="addTradeModal()" class="btn btn-primary waves-effect">Add Trade</button>
                        </div>
                        <div class="col-md-12 col-sm-12 forms-block">
                          <table class="table">
                            <thead>
                              <th>YEAR</th>
                              <th>MAKE</th>
                              <th>MODEL</th>
                              <th>GROSS TRADE-IN</th>
                              <th>PAYOFF</th>
                              <th>ACV</th>
                              <th>VIN</th>
                              <th>STOCK#</th>
                              <th>APPRAISAL</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.trade_in_amount_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="tradeInAmountError = ''" id="tradeInAmount" v-model="tradeInAmount" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ tradeInAmountError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.backend_sales_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="backendSalesError = ''" id="backendSales" v-model="backendSales" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled/>
                      <span class="help is-danger">{{ backendSalesError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.dealer_fees_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="dealerFeesError = ''" id="dealerFees" v-model="dealerFees" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ dealerFeesError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.total_sales_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="totalSalesError = ''" id="totalSales" v-model="totalSales" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ totalSalesError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.other_charges_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="otherChargesError = ''" id="otherCharges" v-model="otherCharges" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ otherChargesError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.fet_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="fetTradeError = ''" id="fetTrade" v-model="fetTrade" class="form-control inputAmount inputRight" type="text"
                        maxlength="190" disabled />
                      <span class="help is-danger">{{ fetTradeError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-4 col-sm-12 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.state_tax_rate_label }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="rateMask" @input="stateTaxRateError = ''" id="stateTaxRate" v-model="stateTaxRate" class="form-control" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'stateTaxRate')}" />
                        <span class="help is-danger">{{ stateTaxRateError }}</span>
                      </div>
                    </div>

                    <div class="col-md-2 forms-block">
                      <div class="col-md-2 paddingleft0 custom-checkbox-v">
                        <label class="container">
                          <input id="applySalesTax1" v-model="applySalesTax1" type="checkbox" @change="calVehiclePrice()"/>
                        <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-md-10 paddingleft0">
                        <div class="form-label">{{ staticInputLabelMessages.applied_sales_tax_label }}</div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12 forms-block">
                      <div class="row">
                        <div class="col-md-3 col-sm-12 paddingright0">
                          <div class="form-label">
                            {{ staticInputLabelMessages.state_tax_amount_label }}
                          </div>
                        </div>
                        <div class="col-md-9 col-sm-12 paddingleft0">
                          <money v-bind="moneyMask" @input="stateTaxAmountError = ''" id="stateTaxAmount" v-model="stateTaxAmount" class="form-control inputAmount inputRight" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'stateTaxAmount')}" />
                            <span class="help is-danger">{{ stateTaxAmountError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-4 col-sm-12 forms-block">
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.custom_tax_rate_label }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 paddingleft0">
                        <money v-bind="rateMask" @input="customTaxRateError = ''" id="customTaxRate" v-model="customTaxRate" class="form-control" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'customTaxRate')}" />
                        <span class="help is-danger">{{ customTaxRateError }}</span>
                      </div>
                    </div>

                    <div class="col-md-2 forms-block">
                      <div class="col-md-2 paddingleft0 custom-checkbox-v">
                        <label class="container">
                          <input id="applySalesTax2" v-model="applySalesTax2" type="checkbox" @change="calVehiclePrice()" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-md-10 paddingleft0">
                        <div class="form-label">{{ staticInputLabelMessages.applied_sales_tax_label }}</div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12 forms-block">
                      <div class="row">
                        <div class="col-md-3 col-sm-12 paddingright0">
                          <div class="form-label">
                            {{ staticInputLabelMessages.warranty_tax_label }}
                          </div>
                        </div>
                        <div class="col-md-9 col-sm-12 paddingleft0">
                          <money v-bind="moneyMask" @input="customTaxAmountError = ''" id="customTaxAmount" v-model="customTaxAmount" class="form-control inputAmount inputRight" type="text" maxlength="190" @keyup.native="event => {incrementDecrementNumber(event, 'customTaxAmount')}" />
                          <span class="help is-danger">{{ customTaxAmountError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.other_tax_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="otherTaxError = ''" id="otherTax" v-model="otherTax" class="form-control inputAmount inputRight" type="text"
                        maxlength="190" disabled />
                      <span class="help is-danger">{{ otherTaxError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.total_cash_down_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="totalCashDownError = ''" id="totalCashDown" v-model="totalCashDown" class="form-control inputAmount inputRight"
                        type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ totalCashDownError }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.vehicle_net_price_label }}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="vehicleNetPriceError = ''" id="vehicleNetPrice" v-model="vehicleNetPrice"
                        class="form-control inputAmount inputRight" type="text" maxlength="190" disabled />
                      <span class="help is-danger">{{ vehicleNetPriceError }}</span>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseCommissionEstimate" aria-expanded="true" aria-controls="collapseCommissionEstimate">
                    <h3>Commission Estimate</h3>
                  </button>
                </h2>
              </div>

              <div id="collapseCommissionEstimate" class="collapse" aria-labelledby="headingOne">
                <div class="card-body">
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.gross_profit_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="grossProfitError = ''" id="grossProfit" v-model="grossProfit" class="form-control inputAmount"
                            type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ grossProfitError }}</span>
                    </div>
                  </div>
                  <!-- (less holdback) -->
                  <div class="col-md-6 col-sm-6 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.less_holdback_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="lessHolbackError = ''" id="lessHolback" v-model="lessHolback" class="form-control inputAmount"
                            type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ lessHolbackError }}</span>
                    </div>
                  </div>
                  <!-- Over Allowance -->
                  <div class="col-md-6 col-sm-12 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.over_allowance_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="overAllowanceError = ''" id="overAllowance" v-model="overAllowance" class="form-control inputAmount"
                            type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ overAllowanceError }}</span>
                    </div>
                  </div>
                  <!-- Commissionable Gross Profit -->
                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <!-- <div class="col-md-2 col-sm-2 paddingleft0">
                      <div class="form-label">{{ staticInputLabelMessages.commissionable_gross_profit_label }}</div>
                    </div> -->
                    <div class="col-md-2 col-sm-2 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.amt_excl_label }}</div>
                    </div>
                    <div class="col-md-3 col-sm-3 paddingleft0">
                      <money v-bind="moneyMask" @input="amtExclError1 = ''" id="amtExcl1" v-model="amtExcl1" class="form-control inputAmount" type="text"
                            maxlength="190" placeholder="Amt Excl 1"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ amtExclError1 }}</span>
                    </div>
                    <div class="col-md-3 col-sm-1 paddingleft0">
                      <money v-bind="moneyMask" @input="amtExclError2 = ''" id="amtExcl2" v-model="amtExcl2" class="form-control inputAmount" type="text"
                            maxlength="190" placeholder="Amt Excl 2"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ amtExclError2 }}</span>
                    </div>
                    <div class="col-md-3 col-sm-4 paddingleft0">
                      <money v-bind="moneyMask" @input="amtExclError3 = ''" id="amtExcl3" v-model="amtExcl3" class="form-control inputAmount" type="text"
                            maxlength="190" placeholder="Amt Excl 3"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ amtExclError3 }}</span>
                    </div>
                  </div>
                  <!-- Commission -->
                  <div class="col-md-12 col-sm-12 mb-1 forms-block">
                    <!-- <div class="col-md-2 col-sm-2 paddingleft0">
                      <div class="form-label">{{ staticInputLabelMessages.commission_label }}</div>
                    </div> -->
                    <div class="col-md-2 col-sm-2 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.commission_rate_label }}</div>
                    </div>
                    <div class="col-md-3 col-sm-3 paddingleft0">
                      <money v-bind="moneyMask" @input="commissionRateError1 = ''" id="commissionRate1" v-model="commissionRate1"
                            class="form-control inputAmount" type="text" maxlength="190" placeholder="Commission Rate 1"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ commissionRateError1 }}</span>
                    </div>
                    <div class="col-md-3 col-sm-1 paddingleft0">
                      <money v-bind="moneyMask" @input="commissionRateError2 = ''" id="commissionRate2" v-model="commissionRate2"
                            class="form-control inputAmount" type="text" maxlength="190" placeholder="Commission Rate 2"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ commissionRateError2 }}</span>
                    </div>
                    <div class="col-md-3 col-sm-4 paddingleft0">
                      <money v-bind="moneyMask" @input="commissionRateError3 = ''" id="commissionRate3" v-model="commissionRate3"
                            class="form-control inputAmount" type="text" maxlength="190" placeholder="Commission Rate 3"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ commissionRateError3 }}</span>
                    </div>
                  </div>
                  <!-- Additional Commission -->
                  <div class="col-md-6 col-sm-12 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.additional_commission_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="additionalCommissionError = ''" id="additionalCommission" v-model="additionalCommission"
                            class="form-control inputAmount" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ additionalCommissionError }}</span>
                    </div>
                  </div>
                  <!-- F&I Bonus for Salesperson -->
                  <div class="col-md-6 col-sm-12 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.f_and_i_bonus_for_salesperson_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="fAndIBonusForSalespersonError = ''" id="fAndIBonusForSalesperson"
                            v-model="fAndIBonusForSalesperson" class="form-control inputAmount" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ fAndIBonusForSalespersonError }}</span>
                    </div>
                  </div>
                  <!-- Flat Commission Only -->
                  <div class="col-md-6 col-sm-12 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.flat_commission_only_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="flatCommissionOnlyError = ''" id="flatCommissionOnly" v-model="flatCommissionOnly"
                            class="form-control inputAmount" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ flatCommissionOnlyError }}</span>
                    </div>
                  </div>
                  <!-- Total Commission -->
                  <div class="col-md-6 col-sm-12 mb-1 mb-1 forms-block">
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <div class="form-label text-right">{{ staticInputLabelMessages.total_commission_label }}</div>
                    </div>
                    <div class="col-md-9 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask" @input="totalCommissionError = ''" id="totalCommission" v-model="totalCommission"
                            class="form-control inputAmount" type="text" maxlength="190"/>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ totalCommissionError }}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- Split Commission -->
                    <div class="col-md-4"></div>
                    <div class="col-md-4 forms-block">
                        <div class="col-1 paddingleft0 custom-checkbox-v">
                          <label class="container">
                            <input id="splitCommision" v-model="splitCommision" type="checkbox" @change="checkUncheck($event, 'splitCommision')"/>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-3 paddingleft0">
                          <div class="form-label text-left">{{ staticInputLabelMessages.split_commission_label }}</div>
                        </div>
                      <!-- No Commission -->
                        <div class="col-1 paddingleft0 custom-checkbox-v">
                          <label class="container">
                            <input id="noCommision" v-model="noCommision" type="checkbox" @change="checkUncheck($event, 'noCommision')"/>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-3 paddingleft0">
                          <div class="form-label text-left">{{ staticInputLabelMessages.no_commission_label }}</div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vehicle-cost-modal
      v-bind:assetId="vehicleId"
      v-bind:vin="assetId.text"
      v-bind:isClickCostSheet="isClickCostSheet"
      v-bind:unitId="unitId"
    ></vehicle-cost-modal>

    <add-on-deal v-bind:addOnEditData="addOnEditData" v-bind:editAssetId="editAssetId" v-bind:addOnTempIndex="addOnTempIndex" v-bind:customerCountyId="customerCountyId" v-bind:isClickAddOnList="isClickAddOnList"></add-on-deal>
    
    <cal-flooring v-bind:isClickCalFlooring="isClickCalFlooring" v-bind:factoryPrice="factoryPrice"></cal-flooring>
   
    <vehicle-purchase-order v-bind:isClickPurchaseOrder="isClickPurchaseOrder" v-bind:unitId="unitId" v-bind:vin="assetId.text" v-bind:defaultLocation="locationId" v-bind:assetId="vehicleId" v-bind:editAssetId="editAssetId" v-bind:poEditData="poEditData"></vehicle-purchase-order>

    <trade-detail v-bind:isClickAddTrade="isClickAddTrade"></trade-detail>
    <vehicle-detail-modal v-bind:isClickDetail="isClickDetail"></vehicle-detail-modal>

  </div>
</template>

<script>
import VehicleCostModal from "./VehicleCostModal";
import AddOnDealModal from "./AddOnDealModal";
import FlooringCalModal from "./FlooringCalModal.vue";
import VehiclePurchaseOrder from "./PurchaseOrder/VehiclePurchaseOrder.vue";
import TradeDetailModal from "./TradeDetailModal.vue";
import VehicleDetailModal from "./VehicleDetailModal.vue";

var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import {commonMixins} from '../../../mixins/commonMixins';
import EventBus from "../../event-bus";
import {HTTP} from '../../../http-common';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import {Money} from 'v-money';
import moment from "moment";

Vue.component("app-footer", Footer);

export default {
  name: 'VehicleWorkSheet',
  components: {
    datePicker,
    'no-access': NoAccessPage,
    'vehicle-cost-modal': VehicleCostModal,
    'add-on-deal': AddOnDealModal,
    'cal-flooring': FlooringCalModal,
    'vehicle-purchase-order': VehiclePurchaseOrder,
    'trade-detail': TradeDetailModal,
    'vehicle-detail-modal': VehicleDetailModal,
    Money
  },
  mixins: [commonMixins],
  props: {
    selectedOpportunityId: 0,
    editAssetId: 0,
    dealId: 0,
    vehicleData: '',
    isVehicleEdit: false,
    customerCountyId: '',
    cusStateTaxRate: '',
    warrantyTax: '',
    cusTaxRate: '',
    locationId: '',
    cusSalesTax: true,
    cusStateCode: '',
    salesTaxCounty: '',
  },
  data() {
    return {
      opportunityId: 0,
      staticInputLabelMessages: "",
      staticMessages: "",
      assetId: '',
      assetArr: [],
      isAssetRecordNotFound: false,
      assetError: "",
      unitId: '',
      unitArr: [],
      isUnitRecordNotFound: false,
      unitError: "",
      manufacturerId: '',
      manufacturerArr: [],
      isManufacturerRecordNotFound: false,
      manufacturerError: "",
      year: "",
      yearError: "",
      modelOEMId: '',
      modelOEMArr: [],
      isModelOEMRecordNotFound: false,
      modelOEMError: "",
      color: "",
      colorError: "",
      stockTypeId: "",
      stockTypeArr: [],
      stockTypeError: "",
      bodyType: "",
      bodyTypeError: "",
      typeId: "",
      typeArr: [],
      typeError: "",
      duty: "",
      dutyArr: [],
      dutyError: "",
      fuelTypeId: "",
      fuelTypeArr: [],
      fuelTypeError: "",
      stockOrder: "",
      stockOrderArr: [],
      stockOrderError: "",
      odometer: "",
      odometerError: "",
      paymentType: "MI",
      paymentTypeOption: [
        {text: 'MI', value: 'MI'},
        {text: 'KM', value: 'KM'},
      ],
      scaleWeight: "",
      scaleWeightError: "",
      vehicleDescription: "",
      vehicleDescriptionError: "",
      ofBatteries: "",
      ofBatteriesError: "",
      ofTires: 0,
      ofTiresError: "",
      ofAxles: "",
      ofAxlesError: "",
      privateUse: false,
      businessUse: false,
      proposedDeliveryDate: "",
      proposedDeliveryDateError: "",
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        },
        // disabledTimeIntervals: false,
      },
      gvwGCWR: 0,
      gvwGCWRError: "",
      docPreparation: 0,
      docPreparationError: "",
      customerSuppliedBody: 0,
      customerSuppliedBodyError: "",
      licenseTitle: "",
      licenseTitleError: "",
      noLicense: false,
      customFee: false,
      tireFee: 0,
      tireFeeError: "",
      registration: 0,
      registrationError: "",
      flooring: 0,
      flooringError: "",
      smogFeePaidToSeller: 0,
      smogFeePaidToSellerError: "",
      tireCredit: 0,
      tireCreditError: "",
      smogCertificationOrExemptionFeePaidToState: 0,
      smogCertificationOrExemptionFeePaidToStateError: "",
      fetExemptItems: 0,
      fetExemptItemsError: "",
      dmvElectronicFillingFee: 0,
      dmvElectronicFillingFeeError: "",
      fet: "",
      fetError: "",
      fetExempt: false,
      customFet: false,
      ccttsOnlyAdminFee: 0,
      ccttsOnlyAdminFeeError: "",
      financeReserve: 0,
      financeReserveError: "",
      factoryPrice: 0,
      factoryPriceError: "",
      fleetReserveCost: 0,
      fleetReserveCostError: "",
      bodyCost: 0,
      bodyCostError: "",
      adotPermit: "",
      adotPermitError: "",
      fleetReserveCtrl: "",
      fleetReserveCtrlError: "",
      holdDate: "",
      holdDateError: "",
      factoryInvDate: "",
      factoryInvDateError: "",

      // Vehicle Cost Calculation
      currentInventoryCostFromProcede: 0,
      currentInventoryCostFromProcedeError: "",
      inventoryCostLastUpdated: 0,
      inventoryCostLastUpdatedError: "",
      totalInternalLocalInstallationLessOutOfStateDeliveryCost: "",
      totalInternalLocalInstallationLessOutOfStateDeliveryCostError: "",
      costOfVehicleAndEquipmentIncludingFlooringCost: 0,
      costOfVehicleAndEquipmentIncludingFlooringCostError: "",
      holdBackFixed: 0,
      holdBackFixedError: "",
      percentage: 0,
      percentageError: "",
      percentage1: "",
      percentageError1: "",
      grossProfitDollarAmount: 0,
      grossProfitDollarAmountError: "",
      holdBack: 0,
      holdBackError: "",

      // Vehicle Price Calculation
      vehicleSalesPrice: "",
      vehicleSalesPriceError: "",
      priceFetIncluded: 0,
      priceFetIncludedError: "",
      packed: false,
      poAmountToAddToSalesPrice: "",
      poAmountToAddToSalesPriceError: "",
      frontendSales: "",
      frontendSalesError: "",
      tradeInAmount: "",
      tradeInAmountError: "",
      backendSales: "",
      backendSalesError: "",
      dealerFees: "",
      dealerFeesError: "",
      totalSales: "",
      totalSalesError: "",
      otherCharges: 0,
      otherChargesError: "",
      fetTrade: "",
      fetTradeError: "",
      stateTaxRate: 0,
      stateTaxRateError: "",
      stateTaxAmount: 0,
      stateTaxAmountError: "",
      applySalesTax1: false,
      customTaxRate: 0,
      customTaxRateError: "",
      customTaxAmount: 0,
      customTaxAmountError: "",
      applySalesTax2: false,
      otherTax: 0,
      otherTaxError: "",
      totalCashDown: 0,
      totalCashDownError: "",
      vehicleNetPrice: "",
      vehicleNetPriceError: "",
      grossProfitPercent: 0,
      grossProfitPercentError: "",
      grossProfitSalesPrice: 0,
      grossProfitSalesPriceError: "",

      // Commission Estimate
      grossProfit: 0,
      grossProfitError: "",
      lessHolback: "",
      lessHolbackError: "",
      overAllowance: 0,
      overAllowanceError: "",
      amtExcl1: 0,
      amtExclError1: "",
      amtExcl2: "",
      amtExclError2: "",
      amtExcl3: 0,
      amtExclError3: "",
      commissionRate1: 0,
      commissionRateError1: "",
      commissionRate2: 0,
      commissionRateError2: "",
      commissionRate3: 0,
      commissionRateError3: "",
      additionalCommission: 0,
      additionalCommissionError: "",
      fAndIBonusForSalesperson: 0,
      fAndIBonusForSalespersonError: "",
      flatCommissionOnly: 0,
      flatCommissionOnlyError: "",
      totalCommission: 0,
      totalCommissionError: "",
      splitCommision: false,
      noCommision: false,
      isShowVehicleCostModal: false,
      vehicleId: null,
      dealInventoryMapperId: null,
      modelId: null,
      fuel_type_id: null,
      bodyTypeId: null,
      grandTotalCostSheet: 0,
      sumOfFees: '',
      isClickCostSheet: false,

      extras: 0,
      outofstate: 0,
      poAmount: 0,
      warranty: 0,
      warrantysales: 0,
      warrantycost: 0,
      warrantyGP: 0,
      fetexclude: 0,
      CommissionDeduct: 0,
      flooringexpense: 0,
      flooringadd: 0,
      addonscost: 0,
      addons: 0,
      taxexclude: 0,

      tireTax: 0,
      custom4: 0,
      addOnList: [],
      customHoldBack: false,
      totalvehiclecost: 0,
      totalvehiclecostError: '',
      gfwFee: [],

      tradevalue: 0,
      tradepayoff: 0,
      acv: 0,
      dealAdjustment: 0,
      imfSalesTax: 0,
      isClickAddOnList: false,
      makeId: null,
      businessTax: 0,
      loadCount: 0,
      branchDefaultCal: [],
      vehiclePriceCalList: [],
      moneyMask: {
          // decimal: ',',
          thousands: ',',
          prefix: '$ ',
          // suffix: ' #',
          precision: 2,
        },
      rateMask: {
        decimal: '.',
         suffix: ' %',
         precision: 2,
      },
      saveNotNull: false,
      buttonName: 'Save',
      addOnEditData: {},
      addOnTempIndex: null,
      licenseTitleStatic: 0,
      isClickCalFlooring: false,
      initialCal: true,
      isClickPurchaseOrder: false,
      isClickAddTrade: false,
      feesTotal: 0.00,
      isVinChange: false,
      cashDetailWarranty: 0.00,
      poData: '',
      addOnPoList: [],
      poEditData: {},
      isClickDetail: false
    }
  },
  beforeMount() {
    self = this;
  },
  async mounted() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push('/');
    }

    self.loadAllData(['FuelType'], false, self.loadVehicleInformationFields,'undefined',"");

    window.scrollTo(0,0);
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;
    self.stateTaxRate = self.cusTaxRate;
    self.applySalesTax1 = self.cusSalesTax;
    self.customTaxRate = parseFloat(self.numberFormateValidation(self.warrantyTax) == false ? 0 : self.warrantyTax);
    if(localStorage.getItem('AddOnTemp') != null) {
      localStorage.removeItem('AddOnTemp');
    }

    self.setActiveMenu('transaction-sheet');
    EventBus.$emit("menu-selection-changed", "transaction-sheet");

    self.opportunityId = self.selectedOpportunityId;
    await self.getStaticData();

    if(self.isVehicleEdit) {
      await self.getAssetDataFromId(self.editAssetId);
    } else {
      self.isVinChange = true;
    }
  },
  methods: {
    closeWorkSheet: function () {
      var totalAmount = self.grandTotalCostSheet + self.sumOfFees;
      if(self.saveNotNull == true) {
        EventBus.$emit("work-sheet-callback-after-saving", self.dealInventoryMapperId, self.vehicleId,totalAmount);
      } else {
        EventBus.$emit("work-sheet-callback-after-saving", 0, self.vehicleId,self.grandTotalCostSheet,totalAmount);
      }
    },
    checkForm: function () {
      var checked = true;
      if (self.assetId == null || !self.assetId.value) {
        self.assetError = "Please select Inventory";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    vehicleCostSheet: function () {
      if(self.assetId == '') {
        self.showSAlert('Please Select Inventory', 'info', false, 2);
      } else {
        self.isClickCostSheet = true;
        $("#defaultModal").modal({backdrop: 'static', keyboard: false});
      }
    },
    saveCloseWorkSheet: async function () {
      if (self.checkForm()) {
        self.showLoader();
        var totalAmount = self.grandTotalCostSheet + self.sumOfFees;
        if (self.dealId !== null && self.dealId !== 0 & self.dealId !== undefined){
            await self.saveDealInventory();
            if(localStorage.getItem('AddOnTemp') != null && JSON.parse(localStorage.getItem('AddOnTemp')).length > 0) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token")
                }
              };

              var data = new FormData();
              data.append('data', JSON.stringify(self.addOnList));
              data.append('deal_inventory_id', self.dealInventoryMapperId);
              data.append('add_on_local', true);
              data.append('po_data', self.poData); //To add po when vehicle is not saved

              HTTP.post("store/add-on-deal", data, config)
              .then(function (response) {
                localStorage.removeItem('AddOnTemp');
                return true;
              })
              .catch(function (err) {
                console.log('error=>', err);
                self.catchHandler(err, function () {
                });
                self.hideLoader();
              });
            }
            EventBus.$emit("cash-details-data", self.stateTaxAmount, self.customTaxAmount, self.cashDetailWarranty);
            self.hideLoader();
            self.saveNotNull = true;
            if(self.vehicleData == '') {
              localStorage.setItem('dealInventoryMapperId', self.dealInventoryMapperId);
              localStorage.setItem('vehicleId', self.vehicleId);
              localStorage.setItem('totalAmountFromVehicleWorkSheet', totalAmount);
            }
        }else{
          self.saveNotNull = false;
        }
        self.hideLoader();
      }
    },
    getAssetDetailsById: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id
        }
      };
      HTTP.get('/asset/get', config)
        .then(response => {
          self.assetId = {text: response.data.content.asset_data.vin_no, value: id};
          // Unit Id
          self.manufacturerId = {
            text: response.data.content.asset_data.make_name,
            value: response.data.content.asset_data.make_id
          };
          self.year = response.data.content.asset_data.year;
          self.modelOEMId = {
            text: response.data.content.asset_data.model_name_full,
            value: response.data.content.asset_data.model_id
          };
          // Color
          self.stockTypeId = {
            text: self.formatString(response.data.content.asset_data.is_stock),
            value: response.data.content.asset_data.is_stock
          };
          // self.bodyType = {text: response.data.content.asset_data.bodyTypeName, value: response.data.content.asset_data.body_type_id};
          self.bodyType = response.data.content.asset_data.bodyTypeName;
          self.typeId = {
            text: response.data.content.asset_data.condition_name,
            value: response.data.content.asset_data.condition_name
          };
          // Duty
          self.fuelTypeId = {
            text: response.data.content.asset_data.fuel_type,
            value: response.data.content.asset_data.fuel_type
          };
          self.stockOrder = {
            text: self.formatString(response.data.content.asset_data.is_stock),
            value: response.data.content.asset_data.is_stock
          };
          self.odometer = response.data.content.asset_data.odometer;
          self.vehicleDescription = response.data.content.asset_data.description;
          self.gvwGCWR = response.data.content.asset_data.gvw;
        })
        .catch(function (err) {
          self.catchHandler(err, function () {
          });
        });
    },
    changeProposedDeliveryDate: function () {
      self.proposedDeliveryDateError = "";
    },
    changeHoldDate: function () {
      self.holdDateError = "";
    },
    changeFactoryInvDate: function () {
      self.factoryInvDateError = "";
    },
    onSearchUnit(search, loading) {
    },
    onSearchManufacturer(search, loading) {
    },
    onSearchModelOEM(search, loading) {
    },
    onSearchAsset(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
      }
    },
    loadAssetDataCallback: function (callbackData) {
      self.isAssetRecordNotFound = false;
      self.assetArr = callbackData.Asset;
      if (self.assetArr.length == 0) {
        self.isAssetRecordNotFound = true;
      }
    },
    populateFields() {
      console.log('Populate Inventory');
      if(!(self.assetId.text == '' || self.assetId.text == undefined)) {
        var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        }
        };
        self.showLoader();

        var data = new FormData();
        data.append('vinNo', self.assetId.text);
        HTTP.post("/get-asset-data-with-vinNo", data, config)
          .then(function (response) {
            self.hideLoader();
            if (response.data.status === "success") {
              var assetData = response.data.content.asset;

              self.vehicleId = assetData.id;
              self.unitId = ''
              self.unitId = assetData.unit; // populating the unit Id field

              self.manufacturerId = '';
              if (assetData.make !== null) {
                // self.locationId = assetData.company.id;
                self.makeId = assetData.make.id;
                self.manufacturerId = assetData.make.name;  // populating the manufacturer field
              }

              self.year = '';
              self.year = assetData.year; //populating the year field

              self.modelOEMId = '';
              if (assetData.model_type !== null) {
                self.modelOEMId = assetData.model_type.name;
                self.modelId = assetData.model_type.id;
              }

              self.color = '';
              self.color = assetData.exterior_color; // populating the color field

              self.stockTypeId = '';
              self.stockTypeId = assetData.is_stock; // populating the stock type field

              self.bodyType = '';
              if (assetData.body_type !== null) {
                self.bodyTypeId = assetData.body_type.id;
                self.bodyType = assetData.body_type.name; // populating the body Type field
              }

              self.typeId = '';
              self.typeId = assetData.vehicle_type; // populating the N/U field
              self.fuelTypeId = '';
              if (assetData.fuel_type !== null) {
                self.fuelTypeId = assetData.fuel_type.name; // populating the fuel Type field
                self.fuel_type_id = assetData.fuel_type.id;
              }

              self.odometer = '';
              self.odometer = assetData.odometer; // populating the odometer field

              self.scaleWeight = '';
              self.scaleWeight = assetData.empty_weight; // populating the scaleWeight field

              self.vehicleDescription = '';
              self.vehicleDescription = assetData.description; // populating the vehicle Description field

              // self.ofTires = 0;
              self.ofTires = self.ofTires != 0 ? self.ofTires : (assetData.tireConfig != null ? assetData.tireConfig : 0); // populating the # of Tires field

              self.vehicleSalesPrice = (self.vehicleSalesPrice !== 0) ? self.vehicleSalesPrice : assetData.retail_price;

              // self.branchDefaultCal = response.data.content.setByBranchList;
              self.initialCal = true;
                self.calVehiclePrice();
                self.sumFees();
            }
          })
          .catch(function (err) {
            self.hideLoader();
            self.catchHandler(err, function () {
            });
          });
      }
    },
    
    saveDealInventory: function () {
      try {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          }
        };
        var data = new FormData();
        // foreign keys
        data.append('deal_id', self.dealId);
        data.append('inventory_id', self.vehicleId);
        data.append('makeId', self.makeId),
        data.append('modelId', self.modelId),
        // data.append('use_type', self.privateUse);
        data.append('prop_delivery_date', self.proposedDeliveryDate);

        // Custom Fields
        data.append('gvw', self.gvwGCWR);
        data.append('doc_prepare_fee', self.docPreparation);
        data.append('customer_supply_body', self.customerSuppliedBody);
        data.append('license_title', self.licenseTitle);
        // data.append('license_type', self.customFee);
        data.append('is_no_license', self.noLicense);
        data.append('is_customer_fee', self.customFee);
        data.append('is_fet_exempt', self.fetExempt);
        data.append('is_custom_fet', self.customFet);
        data.append('is_private_use', self.privateUse);
        data.append('is_business_use', self.businessUse);
        data.append('is_split_commission', self.splitCommision);
        data.append('is_no_commission', self.noCommision);

        data.append('tire_fee', self.tireFee);
        data.append('registration', self.registration);
        data.append('flooring', self.flooring);
        data.append('smog_fee_paid_to_seller', self.smogFeePaidToSeller);
        data.append('tire_credit', self.tireCredit);
        data.append('smog_cert_pay_to_state', self.smogCertificationOrExemptionFeePaidToState);
        data.append('fet_examp_items', self.fetExemptItems);
        data.append('fet', self.fet);
        // data.append('fet_type', self.customFet);
        data.append('dmv_electronic_filling_fee', self.dmvElectronicFillingFee);
        data.append('cctts_admin_fee', self.ccttsOnlyAdminFee);
        data.append('finance_reserved', self.financeReserve);
        data.append('factory_price', self.factoryPrice);
        data.append('fleet_reserve_cost', self.fleetReserveCost);
        data.append('body_cost', self.bodyCost);
        data.append('adot_permit', self.adotPermit);
        data.append('fleet_reserve_ctrl', self.fleetReserveCtrl);
        data.append('hold_date', self.holdDate);
        data.append('factory_invo_date', self.factoryInvDate);

        //Vehicle Cost Calculation
        data.append('current_inventory_cost', self.currentInventoryCostFromProcede);
        data.append('inventory_cost_last_updated', self.inventoryCostLastUpdated);
        data.append('local_installation', self.totalInternalLocalInstallationLessOutOfStateDeliveryCost);
        data.append('veh_cost_equip', self.costOfVehicleAndEquipmentIncludingFlooringCost);
        data.append('hold_back_fixed', self.holdBackFixed);
        data.append('custom_hold_back', self.customHoldBack);
        data.append('percentage', self.percentage);
        data.append('gross_profit_amt', self.grossProfitDollarAmount);

        // Total Vehicle Cost, Vehicle Fixed Margin
        // todo: need to figure out which columns to save to
        // Trade Detail
        data.append('trade_amount', self.tradeInAmount);
        data.append('backend_sales', self.backendSales);
        data.append('dealer_fees', self.dealerFees);
        data.append('total_sales', self.totalSales);
        data.append('other_charges', self.otherCharges);
        data.append('veh_fet', self.fetTrade);
        data.append('state_tax_amt', self.stateTaxAmount); // todo: correct spelling?
        data.append('state_tax_rate', self.stateTaxRate); // todo: correct spelling?
        data.append('state_apply_sales_tax', self.applySalesTax1);
        data.append('warrant_apply_sales_tax', self.applySalesTax2);
        data.append('warranty_tax', self.customTaxAmount);
        data.append('custom_tax_rate', self.customTaxRate);

        data.append('other_tax', self.otherTax);
        data.append('total_cash_down', self.totalCashDown);
        data.append('vehicle_net_price', self.vehicleNetPrice);

        // Commission Estimate
        data.append('commission_gross_profit_amt', self.grossProfit); // todo: figure out if self.grossProfit is for gross_profit_amt or commission_gross_profit_amt
        data.append('commission_less_holdback', self.lessHolback);
        data.append('commission_our_allowance', self.overAllowance);

        data.append('commissionable_gross_profit', self.overAllowance);
        data.append('commission_amt_excl', self.amtExcl1);
        data.append('commission_rate', self.commissionRate1);

        data.append('additional_commission', self.additionalCommission);
        data.append('fi_bonus_for_salesperson', self.fAndIBonusForSalesperson);
        data.append('flat_commission_only', self.flatCommissionOnly);
        data.append('total_commission', self.totalCommission);
        // data.append('commission_type', self.splitCommision);
        data.append('grand_total_cost_sheet', self.grandTotalCostSheet);
        data.append('total_vehicle_cost', self.totalvehiclecost);
        data.append('packed', self.packed);
        data.append('base_sales_price', self.vehicleSalesPrice);
        data.append('price_fet_included', self.priceFetIncluded);
        data.append('of_tires', self.ofTires);
        data.append('gross_profit_percent', self.grossProfitPercent);
        data.append('gross_profit_sales_price', self.grossProfitSalesPrice);
        data.append('tire_tax', self.tireTax);
        data.append('frontend_sales', self.frontendSales);

        if(self.isVehicleEdit) {
          data.append('id', self.vehicleDataId);
        } else if(self.dealInventoryMapperId != null) {
          data.append('id', self.dealInventoryMapperId);
        }

        return new Promise(function (resolve, reject) {
          HTTP.post("/add/deal-inventory-mapper", data, config)
            .then(function (response) {
              const dealInventoryMapperResponse = response.data.content;
              self.dealInventoryMapperId = dealInventoryMapperResponse.id;
              self.showSAlert(response.data.message, response.data.status, false, 2);
              resolve(response);
            })
            .catch(function (err) {
              self.catchHandler(err, function () {
              });
              self.hideLoader();
              reject(err);
            });
        });
      } catch (error) {
        console.log('saveDealError=>',error);
      }
    },

    checkUncheck: function (event, type) {
      event.target.checked ? (this[type] = true) : (this[type] = false);
      if(type == 'noLicense' || type == 'fetExempt' || type == 'customFet') {
        self.sumFees();
      }
      self.calVehiclePrice();
    },

    getAssetDataFromId: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        }
      };
      var data = new FormData();
      data.append('id', id);
      data.append('edit', true);
      HTTP.post("get/deal-inventory-mapper-data", data, config)
        .then(function (response) {
          var vehicleData = response.data.content;
          self.assetId = {text: vehicleData.asset.vin_no, value: vehicleData.asset.id};
          // Fees
          self.gvwGCWR = vehicleData.gvw;
          self.docPreparation = vehicleData.doc_prepare_fee;
          self.customerSuppliedBody = parseFloat(vehicleData.customer_supply_body);
          self.licenseTitle = parseFloat(vehicleData.license_title);
          self.tireFee = vehicleData.tire_fee;
          self.flooring = vehicleData.flooring;
          self.registration = vehicleData.registration;
          self.tireCredit = vehicleData.tire_credit;
          self.smogFeePaidToSeller = vehicleData.smog_fee_paid_to_seller;
          self.smogCertificationOrExemptionFeePaidToState = vehicleData.smog_cert_pay_to_state;
          self.dmvElectronicFillingFee = vehicleData.dmv_electronic_filling_fee;
          self.fet = vehicleData.fet;
          self.fetExemptItems = parseFloat(vehicleData.fet_examp_items);
          self.totalvehiclecost = vehicleData.total_vehicle_cost;
          self.noLicense = vehicleData.is_no_license ? true : false;
          self.customFee = vehicleData.is_customer_fee ? true : false;
          self.customFet = vehicleData.is_custom_fet ? true : false;
          self.fetExempt = vehicleData.is_fet_exempt ? true : false;

          // vehicleData.fet_type == 'custom' ? (self.customFet = true, self.fetExempt = false) : (self.customFet = false);

          // Deal Inventory
          self.ccttsOnlyAdminFee = vehicleData.cctts_admin_fee;
          self.vehicleId = vehicleData.inventory_id;
          self.financeReserve = vehicleData.finance_reserved;
          self.factoryPrice = vehicleData.factory_price;
          self.fleetReserveCost = vehicleData.fleet_reserve_cost;
          self.bodyCost = vehicleData.body_cost;
          self.adotPermit = vehicleData.adot_permit;
          self.fleetReserveCtrl = vehicleData.fleet_reserve_ctrl;
          self.holdDate = vehicleData.hold_date;
          self.factoryInvDate = vehicleData.factory_invo_date;
          self.currentInventoryCostFromProcede = vehicleData.current_inventory_cost;
          self.inventoryCostLastUpdated = vehicleData.inventory_cost_last_updated;
          self.totalInternalLocalInstallationLessOutOfStateDeliveryCost = vehicleData.local_installation;
          self.costOfVehicleAndEquipmentIncludingFlooringCost = vehicleData.veh_cost_equip;
          self.holdBackFixed = vehicleData.hold_back_fixed;
          self.percentage = vehicleData.percentage;
          self.tradeInAmount = vehicleData.trade_amount;
          self.backendSales = vehicleData.backend_sales;
          self.dealerFees = vehicleData.dealer_fees;
          self.totalSales = vehicleData.total_sales;
          self.otherCharges = vehicleData.other_charges;
          self.stateTaxAmount = vehicleData.state_tax_amt;
          self.stateTaxRate = vehicleData.state_tax_rate;
          self.applySalesTax1 = vehicleData.state_apply_sales_tax == 'yes' ? true : false;
          self.customTaxAmount = vehicleData.warranty_tax;
          self.customTaxRate = vehicleData.custom_tax_rate;
          self.applySalesTax2 = vehicleData.warrant_apply_sales_tax == 'yes' ? true : false;
          self.otherTax = vehicleData.other_tax;
          self.totalCashDown = vehicleData.total_cash_down;
          self.vehicleNetPrice = vehicleData.vehicle_net_price;
          self.grossProfit = vehicleData.commission_gross_profit_amt;
          self.lessHolback = vehicleData.commission_less_holdback;
          self.overAllowance = vehicleData.commission_our_allowance;
          self.additionalCommission = vehicleData.additional_commission;
          self.fAndIBonusForSalesperson = vehicleData.fi_bonus_for_salesperson;
          self.flatCommissionOnly = vehicleData.flat_commission_only;
          self.totalCommission = vehicleData.total_commission;
          self.vehicleDataId = vehicleData.id;
          self.addOnPoList = vehicleData.add_on_deals;
          self.vehicleSalesPrice = vehicleData.base_sales_price;
          self.ofTires = vehicleData.of_tires;
          self.priceFetIncluded = vehicleData.price_fet;
          self.packed = vehicleData.is_packed;
          self.grossProfitDollarAmount = vehicleData.gross_profit_amt;
          self.grossProfitPercent = vehicleData.gross_profit_percent;
          self.grossProfitSalesPrice = vehicleData.gross_profit_sales_price;
          self.customHoldBack = (vehicleData.custom_hold_back == 'yes') ? true : false;
          self.privateUse = vehicleData.is_private_use;
          self.businessUse = vehicleData.is_business_use;
          self.splitCommision = vehicleData.is_split_commission ? true : false;
          self.noCommision = vehicleData.is_no_commission ? true : false;
          self.tireTax = vehicleData.tire_tax;
          self.frontendSales = vehicleData.frontend_sales;
          self.proposedDeliveryDate = moment(vehicleData.prop_delivery_date).format('MM/DD/YYYY');
        })
        .catch(function (err) {
          console.log('error=>', err);
          self.catchHandler(err, function () {
          });
          self.hideLoader();
        });
    },

    sumFees: function() {
      self.feesTotal = parseFloat(self.docPreparation, 2) + parseFloat(self.customerSuppliedBody, 2) + parseFloat(self.licenseTitle, 2) + parseFloat(self.tireFee, 2) + parseFloat(self.registration, 2) + parseFloat(self.flooring, 2) + parseFloat(self.smogFeePaidToSeller, 2) + parseFloat(self.tireCredit, 2) + parseFloat(self.smogCertificationOrExemptionFeePaidToState, 2) + parseFloat(self.fet, 2) + parseFloat(self.dmvElectronicFillingFee, 2) + parseFloat(self.ccttsOnlyAdminFee, 2); // According to the mapping Arcadium calculation remove parseFloat(self.fetExemptItems, 2)
      return true;
    },

    calVehiclePrice: function() {
      try {
        if(self.isVinChange && self.initialCal) {
          self.SetByBranchDefaults();
        }
        self.calTireFee();
        var salesprice = self.calReverseVehiclePrice();
        var cusState = self.cusStateCode;
        var factoryInvoice = self.inventoryCostLastUpdated;
        // IMP Verify this county condition
        /*On mapping Arcadium County from TransactionSheet Salex Tax field which is manual field is taken to calculate below
          if(self.customerCountyId !== '' && self.customerCountyId != undefined) {
            var County = self.customerCountyId.name.split(/[,*([]+/)[0];
          }
        */

        var County = self.salesTaxCounty;
        
        // var AdditionalCommission = self.additionalCommission;
        var fiBonus = self.fAndIBonusForSalesperson;
        // console.log('county',County,'AdditionalCommission', AdditionalCommission,'fiBonus',fiBonus);

        /* Used by CA for New vehicles,  */
        if(self.factoryInvDate == "" && self.custom4 != 0) {
          self.custom4 = self.factoryPrice;
        }

        if(factoryInvoice == 0 && self.factoryPrice > 0) {
          factoryInvoice = self.factoryPrice;
        }

        self.calAddOnList();

        var tradevalue = 0;
        var tradepayoff = 0;
        var acv = 0;

        // self.calTrade();
        var branch = !(self.locationId == '' || self.locationId == undefined) ? self.locationId.value : null;
        // console.log('branchLocation=>', self.locationId);
        if(branch !== null) {
          if(self.financeReserve > 0)
          {
            // if(branch == "velocity truck center of ventura county")
            // {
            //   self.fAndIBonusForSalesperson = self.financeReserve * 0.1
            // }
            // else if (branch.indexOf("cctts") > -1)
            // {
            //   self.fAndIBonusForSalesperson = 0;
            // }
            // else if (branch == "southern california great dane")
            // {
            //   self.fAndIBonusForSalesperson = 0;
            // }
            // else if (branch.indexOf("vtc") > -1)
            // {
            //   self.fAndIBonusForSalesperson = 0;
            // }
            // else if (branch.indexOf("neely") > -1)
            // {
            //   self.fAndIBonusForSalesperson = self.financeReserve * 0.05;
            // }
            // else
            // {
            //   self.fAndIBonusForSalesperson = self.financeReserve * 0.01
            // }

            if(branch == 115)
            {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.1
            }
            else if ([116,117,118,119,120,121].includes(branch))
            {
              self.fAndIBonusForSalesperson = 0;
            }
            else if (branch == 77)
            {
              self.fAndIBonusForSalesperson = 0;
            }
            else if ([168,191,192,193,194,195,196,197,198].includes(branch))
            {
              self.fAndIBonusForSalesperson = 0;
            }
            else if ([145,146,147,188].includes(branch))
            {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.05;
            }
            else
            {
              self.fAndIBonusForSalesperson = self.financeReserve * 0.01
            }
          }
          else
          {
            self.fAndIBonusForSalesperson = 0
          }



          var newused = self.typeId != null ? self.typeId.toLowerCase() : '';
          // if((!branch.includes('buswest') || !branch.includes('transwest')) && newused == "used"){
          //   self.fAndIBonusForSalesperson = 0
          // }

          if([126,139,127].includes(branch) && newused == "used"){
            self.fAndIBonusForSalesperson = 0
          }

          // self.fAndIBonusForSalesperson = self.fAndIBonusForSalesperson;
          if(branch == 119)
          {
            self.businessTax = self.ccttsOnlyAdminFee;

            // if(!self.businessUse && !self.privateUse) // Either businessUse or privateUse at a time. Cant be both check and uncheck at a time.
            if(!self.businessUse && !self.privateUse) // To do
            {
              // confused in one of the samples we got business tax is calculated on sales only
              //var businessTax = (salesprice + poAmount + warrantysales ) * 0.003
              self.businessTax = (salesprice ) * 0.003;
              //var calculatedBusinessTax = (salesprice + businessTax + docPrepFees) * 0.003;
            }
            else if (self.businessUse)
            {
              self.businessTax = 0;
            }
            self.ccttsOnlyAdminFee = self.businessTax;
          }

          var TNAddedCost = 0;

          if([119,120].includes(branch)) // added kingsport for ARC-49720011 // missing vtc-kingsport branch
          {
            TNAddedCost += self.GetValue(self.docPreparation);
          }

          if(branch == 119)
          {
            TNAddedCost += self.businessTax;
          }

          var frtlRebate = 0;
          var vendorRebate = 0;

          var totalinternal = self.warranty + self.addonscost + TNAddedCost;
          var costvehicle = factoryInvoice + totalinternal + frtlRebate + vendorRebate + self.fleetReserveCost;  //+ flooring

          // tbDeliverAddress holds a flag if the holdback was entered manually
          if(self.customHoldBack == false)
          {
            //JP * 5/3/21 * Add Triad Holdback
            // if([41,42,70,116,117,118,119,120,121,126,127,139,147,168,191,192,193,194,195,196,197,198].includes(branch))
            // {
              for(const data of self.vehiclePriceCalList) {
                if(data.is_used == newused) {
                  self.holdBackFixed = data.hold_back_fixed;
                  self.percentage = data.hold_back_percentage;
                  break;
                };
              }
            // }
          }

          if(self.customHoldBack == true) {
            self.percentage = 0;
          }



          if(self.percentage > 0)
          {
            //factoryInvoice is the current cost of the vehicle, used vehicles
            //factoryprice is custom field 2, new vehicles

            if(self.percentage == 2)
            {
              if(self.factoryPrice > 0)
                self.holdBack = (self.factoryPrice) * (self.percentage/100);

              else if ([116,117,118,119,120,121].includes(branch))
              {
                self.holdBack = (salesprice) * (self.percentage/100);
              }
              else if (branch == 77)
              {
                self.holdBack = (salesprice) * (self.percentage/100);
              }

              else
                self.holdBack = (factoryInvoice + frtlRebate) * (self.percentage/100);

              //self.holdBack = (factoryprice) * (self.percentage/100);
            }
            else if (self.percentage == 3)
            {
              if ([116,117,118,119,120,121].includes(branch))
              {
                self.holdBack = (salesprice) * (self.percentage/100);
              }
              else if (branch == 77)
              {
                self.holdBack = (salesprice) * (self.percentage/100);
              }
              else
              {
                self.holdBack = (factoryInvoice + totalinternal + frtlRebate + self.fleetReserveCost - self.warrantycost) * (self.percentage/100);
              }
            }
            else if (self.percentage == 1.5 && [116,117,118,119,120,121].includes(branch)) // && make.toLowerCase() == "landoll")
            {
              self.holdBack = (salesprice) * (self.percentage/100);
            }
            else if (self.percentage == 1.5 && branch == 77) // && make.toLowerCase() == "landoll")
            {
              self.holdBack = (salesprice) * (self.percentage/100);
            }
            else
            {
              if(newused == "used")
              {
                self.holdBack = (factoryInvoice + totalinternal + frtlRebate + self.fleetReserveCost) * (self.percentage/100);
              }
              else
              {
                self.holdBack = (self.factoryPrice + self.fleetReserveCost) * (self.percentage/100);
              }

              if([126,139].includes(branch))
              {
                self.holdBack = (factoryInvoice + totalinternal + frtlRebate + self.fleetReserveCost) * (self.percentage/100);
              }
            }
          }
          else
          {
            self.holdBack = self.holdBackFixed;
          }

          self.holdBack = self.holdBack.toFixed(2);
          var cost = parseFloat(factoryInvoice) + parseFloat(self.addonscost) + parseFloat(self.fleetReserveCost) + parseFloat(TNAddedCost);
          self.costOfVehicleAndEquipmentIncludingFlooringCost = cost.toFixed(2);

          var totalvehiclecost = parseFloat(self.holdBack) + parseFloat(self.costOfVehicleAndEquipmentIncludingFlooringCost);
          self.totalvehiclecost = totalvehiclecost.toFixed(2);


          var TotalSalesPrice = parseFloat(salesprice) + parseFloat(self.addons) - parseFloat(self.warrantysales);
          self.frontendSales = TotalSalesPrice.toFixed(2);

          var tbPOAddAmount = parseInt(self.addons) - parseInt(self.warrantysales);
          self.poAmountToAddToSalesPrice = tbPOAddAmount.toFixed(2);


          if(newused == "used")
          {
            self.fetExempt = true;
          }

          // FET calculation
          var fet = 0;
          if(!self.fetExempt)
          {
            if(self.packed){
              fet = parseFloat(self.vehicleSalesPrice) * 0.12;
            }
            if(!self.customFet){
              fet = self.GetValue(((parseFloat(salesprice) + parseFloat(self.addons) - parseFloat(self.flooringadd) - parseFloat(self.warrantysales) - parseFloat(self.fetExemptItems) - parseFloat(self.outofstate)) * 0.12) - parseFloat(self.tireCredit));
            } else {
              fet = self.GetValue(self.fet);
            }
          }
          else
          {
            fet = 0;
            self.fetExemptItems = 0;
          }
          self.fet = fet;
          self.fetTrade = fet;

          // CAL calculation
          var cal = 0;
          if(!self.customFee)
          {
            // console.log('cal::1=>',salesprice);
            cal = (salesprice + (self.addons - self.warrantysales - self.flooringadd) + fet + self.customerSuppliedBody) * .02 * 0.325;
            if(self.gfwFee.length > 0) {
              cal += self.gfwFeeCal(self.gvwGCWR);
            }
            if(self.noLicense == false) {
              cal += self.licenseTitleStatic; // Merged all fee changes.
            }

            // Imp added this line to check county undefined if county not selected from transaction sheet page
            if(County!=='') {
              if (County == 'Fresno' || County == 'Kings' || County == 'Madera' || County == 'San Joaquin' || County == 'Tulare' || County == 'Kern') {
                cal += 12
              }
              if (County == 'Kings' || County == 'Los Angeles') {
                cal += 2
              }
              if(County == 'Merced') {
                cal += 24
              }
              if (County == 'Los Angeles') {
                cal += 7
              }
              if (County == 'Tulare') {
                cal += 11
              }
              if (County == 'Kern') {
                cal += 14
              }
              if (County == 'Alameda') {
                cal += 35
              }
              if (County == 'Fresno') {
                cal += 8
              }
              if (County == 'San Bernardino') {
                cal += 12
              }
              if (County == 'Marin' || County == 'Sonoma') {
                cal += 2
              }
              if (County == 'San Diego') {
                cal += 5
              }
              if (County == 'Riverside') {
                cal += 3
              }
              if (County == 'Ventura') {
                cal += 4
              }
              if (County == 'San Luis Obispo') {
                cal += 5
              }
              if (County == 'San Mateo') {
                cal += 15
              }
              if (County == 'Santa Barbara') {
                cal += 7
              }
              if (County == 'Imperial') {
                cal += 7
              }
              if (County == 'Alameda') {
                cal -= 12
              }  // Barbara 12-21-22

              // depending on the county we need to add a new value, the values where updated on 01/01/2011
              if (self.gvwGCWR >= 10000)
              {
                if (newused == "used")
                {
                    cal += 115;
                    cal -= 4; // licence decrease 7/4/2011
                }
                else if(County.indexOf('Orange') > -1 || County.indexOf('Riverside') > -1)
                {
                  cal += 100; // added +5  10-13-20
                }
                else if (County.indexOf('Nevada') > -1)
                {
                    cal += 106;
                          }
                else
                {
                  cal += 94;
                }
              }
              cal = Math.round(cal);
              var gastype = self.fuelTypeId.text;
              if (gastype == "LNG" || gastype == "CNG" || gastype == "Gas" || gastype == "Propane") {
                  cal += 20;
              }
            }
          }
          else
          {
            cal = self.GetValue(self.licenseTitle);
          }

          if(self.noLicense == true)
          {
            cal = 0;
            self.registration = self.licenseTitleStatic;
          }
          cal = Math.round(cal);
          self.licenseTitle = cal;
          var TotalInclFees = salesprice + cal + self.tireFee;

          self.tradeInAmount = parseFloat(self.tradevalue) - parseFloat(self.tradepayoff); //GetValue("tbTradeInAmount");
          self.stateTaxRate = parseFloat(!self.numberFormateValidation(self.cusStateTaxRate) ? 0 : self.cusStateTaxRate);

          var salestaxrate = self.GetValue(self.cusStateTaxRate);
          var countytaxrate = self.GetValue(self.cusTaxRate);
          var citytaxrate = 0;
          // var othertaxrate = self.GetValue(self.warrantyTax);
          var othertaxrate = self.GetValue(self.customTaxRate);
          var other2taxrate = self.GetValue(self.otherTax);
          var downpayment = self.GetValue(self.totalCashDown);

          //If the checkbox is unchecked, then zero the sales tax in case the user hasn't done a Save on the Transactionsheet
          if (!self.applySalesTax1) {
              salestaxrate = 0;
              countytaxrate = 0;
              citytaxrate = 0;
              //SetCheckBoxValue("cbApplyCustom2Tax",0);
              //SetCheckBoxValue("cbApplyCustomTax",0);
          }

          self.backendSales = self.warrantysales;

          if([95,96,117,118,116,121,185,77].includes(branch)) {
            self.docPreparation = self.docPreparation + self.ccttsOnlyAdminFee;
          }

          self.dealerFees = self.docPreparation;

          var totalSales = self.backendSales + self.docPreparation;
          self.totalSales = self.GetValue((parseFloat(self.frontendSales) + parseFloat(self.backendSales) + parseFloat(self.docPreparation) - parseFloat(self.tradeInAmount))).toFixed(2);

          var statetax = 0;
          salesprice = parseFloat(salesprice);
          self.taxexclude = parseFloat(self.taxexclude);
          self.tradevalue = parseFloat(self.tradevalue);
          self.warrantysales = parseFloat(self.warrantysales);
          salestaxrate = parseFloat(salestaxrate);
          var warrantyTax = 0;

          if(branch == 69){
            // ARC-26531121
            statetax = ((salesprice + self.addons -self.taxexclude-self.tradevalue- self.warrantysales)*0.083).toFixed(2); // overwrite tax caluclation for Tolleson, this is just very confusing --- 8.3 is hardcoded, requested by claudia after call on 9-14-2019
            statetax = statetax + 25;
          }
            else if([70,163,124].includes(branch)){
            statetax = ((salesprice + self.addons -self.taxexclude-self.tradevalue- self.warrantysales)* (salestaxrate / 100)).toFixed(2);
            statetax = statetax + (self.warrantysales * warrantyTax) // check is warrantytax is in the table
          }
          else if([145,146,147].includes(branch)){  //vtc - kingsport missing
            var adjustment = self.GetValue(self.dealAdjustment);
            var selectedTax = County;

            if(branch == 146  && selectedTax != "TN.NASHSA~0.07000" ){
              var TaxableAmount = salesprice + self.addons - self.taxexclude - self.tradevalue + adjustment;
              statetax = (TaxableAmount) * (salestaxrate / 100);
            }
            else{

              var TaxableAmount = parseFloat(self.frontendSales) - self.taxexclude - self.tradevalue + adjustment; // - warranty docfee is add in the salesprice by default
              var TNTier1TaxAmount = 0;
              var TNTier2TaxAmount = 0;

              statetax = (TaxableAmount) * (salestaxrate / 100);

              if(selectedTax != "" && selectedTax !== undefined){
                //Set TN Single Article Tax
                var Tier1Rate = 0;
                var Tier2Rate = 0;

                if(self.vehiclePriceCalList.length > 0) {
                  Tier1Rate = 0;
                  Tier2Rate = 0;
                  TNTier1TaxAmount = self.vehiclePriceCalList[0].tn_tier_one_tax_amt;
                  TNTier2TaxAmount = self.vehiclePriceCalList[0].tn_tier_two_tax_amt;
                }

              }

              if(statetax != 0){
                statetax = statetax + (TNTier1TaxAmount + TNTier2TaxAmount);
              }

              //statetax = statetax  + TNwarrantyTax //+ TNbusinessTax Business tax is loaded by in the price
            }
          }
          else{
            // ARC-48349538
            var TaxableAmount = parseFloat(salesprice) + parseFloat(self.addons) + parseFloat(self.docPreparation) - parseFloat(self.taxexclude) - (cusState == "CA" ? 0 : parseFloat(self.tradevalue)) - parseFloat(self.warrantysales);
            // console.log('elseTax',salesprice,self.addons,self.docPreparation,self.taxexclude,cusState,self.tradevalue,self.warrantysales);

            var statetax = self.GetValue(TaxableAmount) * (salestaxrate / 100);
          }

          var othertax = 0;
          if (self.applySalesTax2) {

            othertax = self.GetValue((self.warrantysales) * (othertaxrate / 100));
          }
          else
          {
            othertax = 0;
          }
          self.customTaxAmount = othertax;
          // SetValue("tbCalculatedSalesTax", statetax);
          // SetValue("tbCalculatedCustomTax", othertax);
          // SetValue("tbVehicleCustomTax", othertax);

          if (self.applySalesTax1) {
              self.stateTaxAmount = statetax;
          }
          else {
              self.stateTaxAmount = 0;
              statetax = 0;
              // IMP the commented one to be verified
              // countytax = 0;
              // citytax = 0;
          }

          var OtherFees = parseFloat(self.registration) + parseFloat(self.smogFeePaidToSeller) + parseFloat(self.smogCertificationOrExemptionFeePaidToState) + parseFloat(self.dmvElectronicFillingFee) + parseFloat(self.flooring) + parseFloat(self.tireFee) + parseFloat(self.imfSalesTax); //- businessTax;
          self.otherCharges = OtherFees;

          self.otherTax = cal;
          var netsalesprice = self.frontendSales - self.tradeInAmount + fet + statetax + othertax - parseFloat(downpayment) + parseFloat(self.backendSales) + OtherFees + cal + self.docPreparation + parseFloat(self.businessTax);
          if(branch == 145)
          {
            if(newused == "used"){
              netsalesprice = self.frontendSales - self.tradeInAmount + fet + statetax + othertax - parseFloat(downpayment) + parseFloat(self.backendSales) + OtherFees + cal;
            }
          }


          self.vehicleNetPrice = self.GetValue(netsalesprice).toFixed(2);
          self.grossProfit = self.GetValue((parseFloat(self.frontendSales) + parseFloat(self.warrantysales) - parseFloat(self.totalvehiclecost)- parseFloat(self.CommissionDeduct))).toFixed(2);// - vehiclecostinclfees - outofstate


          // SetValue("tbProfitInclHoldback", grossprofit + holdbackValue);
          // SetValue("tbGrossProfit", grossprofit);

          self.lessHolback = self.GetValue((parseFloat(self.grossProfit) + parseFloat(self.holdBack))).toFixed(2);

          var CalculatedComissionPer = 0;
          var CalculatedComission = 0;
          var ComissionGrossProfitPer = 0;
          var ComissionGrossProfit = 0;
          var ComissionNextLevel = 0;

          var AdditionalCommission = self.GetValue(self.additionalCommission);
          var FIBonus = self.GetValue(self.fAndIBonusForSalesperson);

          self.amtExcl3 = self.GetValue(self.grossprofit) + self.GetValue(self.overAllowance) - self.GetValue(self.flooringexpense) - self.GetValue(self.flooring) + self.GetValue(self.flooringadd) ;  //- flooring this was added again requested by Claudia on 9/25/2018
          if(self.totalvehiclecost != 0)
          {
            //ComissionGrossProfitPer = (grossprofit + overallowance - flooringexpense - flooring + flooringadd)/(TotalSalesPrice-warrantysales-bodycost);
            ComissionGrossProfitPer = (self.GetValue(self.grossprofit) + self.GetValue(self.holdBack) + self.GetValue(self.flooringadd))/(self.GetValue(self.frontendSales)-self.GetValue(self.CommissionDeduct)-self.GetValue(self.bodyCost));


            ComissionGrossProfitPer = !ComissionGrossProfitPer || Math.abs(ComissionGrossProfitPer) == Infinity ? 0 : ComissionGrossProfitPer; // 7-18-19 HB: Divide by zero (NaN) protection; ARC-28465993 HB: Check for Infinity
          }

          if(self.commissionRate1==0 && self.flatCommissionOnly==0)
          {
            self.commissionRate2 = self.vehiclePriceCalList.length>0 ? self.vehiclePriceCalList[0].calculated_commission_per : 0;
            ComissionNextLevel = self.vehiclePriceCalList.length>0 ? self.vehiclePriceCalList[0].commission_next_level : 0;

            // console.log('self.amtExcl3', self.amtExcl3, 'self.commissionRate2', self.commissionRate2);

            self.commissionRate3 = self.amtExcl3 * self.commissionRate2;
            // console.log('1',self.commissionRate3);
            self.totalCommission = self.GetValue(self.commissionRate3 + self.additionalCommission + self.fAndIBonusForSalesperson);
            // console.log('11',self.totalCommission);
          }
          else if (self.commissionRate1!= 0)
          {
            self.commissionRate3 = self.amtExcl3 * (self.commissionRate1/100);
            // console.log('2',self.commissionRate3);
            self.totalCommission = self.commissionRate3 + self.additionalCommission + self.fAndIBonusForSalesperson;
          }

          if (self.flatCommissionOnly != 0)
          {
            self.commissionRate3 = 0;
            self.commissionRate1 = 0;
            self.totalCommission = self.flatCommissionOnly + self.additionalCommission + self.fAndIBonusForSalesperson;
          }

          var extwarranty = 0;
          if(ComissionNextLevel != 0)
          {
            var AddMoneyToLevel = (costvehicle/ComissionNextLevel) - self.poAmountToAddToSalesPrice - extwarranty;
            if(self.CommissionDeduct>0)
            {
              AddMoneyToLevel += self.CommissionDeduct-(self.CommissionDeduct/ComissionNextLevel);
            }
            // if(document.getElementById("lbAddValue"))
            // {
            //   document.getElementById("lbAddValue").innerText = "Add $" + Math.round((AddMoneyToLevel - salesprice)*100)/100 + " to get to the next Commision level (sales price = $" +  Math.round(AddMoneyToLevel*100)/100 + ")";
            // }
          }
          // else
          // {
          //   if(document.getElementById("lbAddValue"))
          //   {
          //     document.getElementById("lbAddValue").innerText = "";
          //   }
          // }


          if (ComissionGrossProfitPer === Infinity) {
            ComissionGrossProfitPer = 0
          }

          // ARC-48739487
          self.commissionRate3 = Math.round(self.commissionRate3 * 10000) / 10000;

          if(self.commissionRate3 < 0 || self.noCommision)
          {
            self.commissionRate3 = 0
            self.totalCommission = 0
            self.flatCommissionOnly = 0
          }

          if(ComissionGrossProfitPer > 101) {

            ComissionGrossProfitPer=0
          }

          self.commissionRate2 = self.commissionRate2 * 100;
          ComissionGrossProfitPer = ComissionGrossProfitPer * 100;
          self.amtExcl3 = ComissionGrossProfit;

          // self.commissionRate3 = CalculatedComission;
          // IMP verify
          // self.totalCommission = CalculatedComission;
          // self.commissionRate2 = CalculatedComissionPer;
          self.amtExcl2 = ComissionGrossProfitPer;
          self.amtExcl1 = self.CommissionDeduct;
          self.totalCommission = self.GetValue(self.totalCommission);
          self.commissionRate3 = self.GetValue(self.commissionRate3);
        }

        if(self.loadCount == 0) {
          self.loadCount++;
        }

        // if(self.splitCommision){
        //   CalculateSplitCommissionPercent();
        // }
        self.isVinChange = true;
        return true;
      } catch (error) {
        console.log('errorCalVehicle=>',error);
      }
    },
    SetByBranchDefaults(data = null) {
      var newused = self.typeId != null ? self.typeId.toLowerCase() : '';
      var branch = !(self.locationId == '' || self.locationId == undefined) ? self.locationId.value : null;

      // const newUsedData = [
      //   140, // "miramar truck center"
      //   41, //los angeles freightliner - whittier
      //   42, //southbay truck center
      //   135, //los angeles freightliner - fontana
      //   54, //selectrucks of los angeles
      //   115, //velocity truck center of ventura county
      //   187, //miramar truck and body equipment
      //   //los angeles truck centers, llc (not in database)
      // ]
      var isBranch = false;
      self.initialCal = false;
      if(self.branchDefaultCal.length > 0 && self.branchDefaultCal[0].nrid === 0) {
        var branchDefaultData = self.branchDefaultCal[0];
        // if(newUsedData.includes(branchDefaultData.location_id) && branchDefaultData.is_new_used) {
            // if(branchDefaultData.is_used == newused) {
              self.docPreparation = branchDefaultData.doc_prepare_fee;
              self.tireTax = branchDefaultData.tire_tax;
              self.noLicense = branchDefaultData.no_license ? true : false;
              self.percentage = branchDefaultData.hold_back_percentage;
              self.licenseTitle = branchDefaultData.license_title;
            // }
        // } else {
        //   console.log('noLicense=>', (branchDefaultData.no_license ? true : false));
        //   self.docPreparation = branchDefaultData.doc_prepare_fee;
        //   self.tireTax = branchDefaultData.tire_tax;
        //   self.noLicense = branchDefaultData.no_license ? true : false;
        //   self.percentage = branchDefaultData.hold_back_percentage;
        //   self.licenseTitle = branchDefaultData.license_title;
        // }
          isBranch = true;
          return true;
        // }
      }


      // self.branchDefaultCal.filter(data => {
      //   if(data.nrid  === 0 && data.location_id == branch) {
      //     if(newUsedData.includes(data.location_id) && data.is_new_used) {
      //       if(data.is_used == newused) {
      //         self.docPreparation = data.doc_prepare_fee;
      //         self.tireTax = data.tire_tax;
      //         self.noLicense = data.no_license;
      //         self.percentage = data.hold_back_percentage;
      //         self.licenseTitle = data.license_title;
      //       }
      //     } else {
      //       self.docPreparation = data.doc_prepare_fee;
      //       self.tireTax = data.tire_tax;
      //       self.noLicense = data.no_license;
      //       self.percentage = data.hold_back_percentage;
      //       self.licenseTitle = data.license_title;
      //     }
      //     isBranch = true;
      //     return true;
      //   }
      // });

      if(!isBranch) {
        self.docPreparation = (newused == 'new') ? 85 : 70;
        self.tireTax = 1.75;
      }

      // if ( tbNRID == 0 && branch !== null) {
      //     switch (branch.toLowerCase()) {
      //     case "freightliner of arizona - tolleson":
      //       self.docPreparation = 175;
      //       self.tireTax = 2;
      //       self.noLicense = true;
      //       break;
      //     case "freightliner of arizona - chandler":
      //       self.docPreparation = 175;
      //       self.tireTax = 2;
      //       self.noLicense = true;
      //       break;
      //     case "freightliner of arizona - flagstaff":
      //       self.docPreparation = 175;
      //       self.tireTax = 2;
      //       self.noLicense = true;
      //       break;
      //     case "freightliner of arizona - tucson":
      //       self.docPreparation = 175;
      //       self.tireTax = 2;
      //       self.noLicense = true;
      //       break;
      //     case "nogales truck and trailer":
      //       self.docPreparation = 175;
      //       self.tireTax = 2;
      //       break;
      //     case "buswest":
      //       self.docPreparation = 70;
      //       self.percentage = 1.5;
      //       self.tireTax = 1.75;
      //       break;
      //     case "buswest north":
      //       self.docPreparation = 70;
      //       self.percentage = 1.5;
      //       self.tireTax = 1.75;
      //       break;
      //     case "transwest truck center":
      //       self.docPreparation = 85;
      //       self.tireTax = 1.75;
      //       break;
      //     case "miramar truck center":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "los angeles freightliner - whittier":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "southbay truck center":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "los angeles freightliner - fontana":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "selectrucks of los angeles":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "velocity truck center of ventura county":
      //       self.docPreparation = (newused == 'new') ? 85 : 70;
      //       self.tireTax = 1.75;
      //       break;
      //     case "los angeles truck centers, llc":
      //       self.docPreparation = (newused == 'new') ? 85.70 : self.docPreparation
      //       self.tireTax = 1.75;
      //       break;
      //     case "miramar truck and body equipment":
      //       self.docPreparation = (newused == 'new') ? 85.70 : self.docPreparation
      //       self.tireTax = 1.75;
      //       break;
      //     case "silver state truck & trailer":
      //       self.docPreparation = 195;
      //       self.tireTax = 1.75;
      //       break;
      //     case "velocity truck centers":
      //       self.docPreparation = 195;
      //       self.tireTax = 1.75;
      //       break;
      //     case "neely coble company - nashville":
      //       self.docPreparation = 0;
      //       self.noLicense = true;
      //       self.licenseTitle = 0;
      //       self.tireTax = 0;
      //       break;
      //     case "neely coble company - madison":
      //       self.docPreparation = 0;
      //       self.noLicense = true;
      //       self.licenseTitle = 0;
      //       self.tireTax = 0;
      //       break;
      //     case "neely coble company - bowling green":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       self.tireTax = 0;
      //             break;
      //     case "nogales truck and trailer":
      //       self.tireTax = 2;
      //       self.tireTax = 2;
      //       break;
      //     case "vtc - greensboro":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;
      //     case "vtc - hickory":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - canton":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - kingman":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - kingsport":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - piedmont":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - greenville (ayden, nc)":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - hope mills":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - raleigh":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - rocky mnt":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc charleston":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc florence":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc warsaw":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc wilmington #2":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc wilmington":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     case "vtc - greenville, sc":
      //       self.docPreparation = 0;
      //       self.noLicense = false;
      //       self.licenseTitle = 0;
      //       break;

      //     default:
      //         self.docPreparation = (newused == 'new') ? 85.70 : self.docPreparation
      //         self.tireTax = 1.75;
      //         break;

      //     }
      // }
      // console.log('datas=>',self.docPreparation,self.tireTax,self.noLicense);
      return true;
    },
    calTireFee() {
      try {
        var NoTires = self.GetValue(self.ofTires);
        var Branch = !(self.locationId == '' || self.locationId == undefined) ? self.locationId.text.toLowerCase() : null;
        var NewUsed = self.typeId != null ? self.typeId.toLowerCase() : '';
        if (NoTires > 0 && Branch !== null) {
          if(NewUsed == "new"){
            if (Branch.indexOf("nashville") > -1 || Branch.indexOf("madison") > -1)
            {
              if(NoTires <= 4)
              {
                self.tireFee = 5;
              }
              else if(NoTires >= 11)
              {
                self.tireFee = 15;
              }
              else
              {
                self.tireFee = 10;
              }
            }
            else
            {
              self.tireFee = parseFloat(NoTires * self.tireTax);
            }
          }
          else
          {
            self.tireFee = parseFloat(NoTires * self.tireTax);
          }
          }
        else
        {
          self.tireFee = 0;
        }
      } catch (error) {
        console.log('error=>',error);
      }
    },
    calAddOnList() {
      //Check for Procede Addons and pull those amounts
      var hasAddons = true;
      var CommissionDeduct = 0;
      var addonscost = 0;
      var warrantysales = 0;
      var warrantycost = 0;
      var flooringadd = 0;
      var addons = 0;
      var fetexclude = 0;
      var outofstate = 0;
      var taxexclude = 0;
      var transactionWarranty = 0;
      // self.tireCredit = 0;

      if(self.addOnPoList.length == 0) {
        hasAddons = false;
      }

      var miscTireCredit = 0;
      if(hasAddons) {
        self.addOnPoList.map(list => {
          var type = (list.asset_expenses_id == null) ? 'Adds' : 'PO';
          var cost = parseFloat(list.cost);
          var sales = parseFloat(list.price);
          var addonType = (list.asset_expenses_id == null) ? 'Adds' : 'PO';

          var AddToSalesPrice = list.is_add_to_total_price;
          var DoNotAddToGP = list.is_do_not_add_to_gp;
          var FETExclude = list.is_fet_exempt;
          var Description = list.description;
          if(addonType.toLowerCase() == "tire credit")
          {
            miscTireCredit += sales;
          }
          if(Description.includes("C-AR") && DoNotAddToGP == 1){
            cost = 0;
          }

          if(DoNotAddToGP == 1)
          {
            CommissionDeduct += sales;
          }

          if(Description.substring(0,2) == 'NA')
          {
            var po = Description.substring(0,15)
            if(po.substring(11,12) != ":" && po.substring(14,15) != "#")
            {
              addonscost += cost;
            }
          }
          else
          {
            if(Description.indexOf("FINANCE INCOME") == -1 && Description.indexOf("COMM ACCRUAL") == -1 && Description.indexOf("RESERVE") == -1 ){//&& type.indexOf("OPEN PO") == -1 removed after call with CL 9-16-2018
              addonscost += cost;
            }
          }

          if (Description.indexOf("W-WFI") > -1 || Description.indexOf("W-WAR") > -1) {

            //if(AddToSalesPrice.getElementsByTagName("input")[0].checked){
                warrantysales += sales;
            //}
                warrantycost += cost;

          }

          if(Description.indexOf("E-FPE") > -1)
          {
            flooringadd += sales;
          }

          if (AddToSalesPrice == 1 && type !== "Service Contract" && type !== "Warranty") { //|| type === "Insurance")
              addons += sales;
          }

          if (FETExclude == 1 && type !== "Service Contract" && type!=="Tire Credit") {
            if(Description.indexOf("E-FPE") == -1 && Description.indexOf("E-DEL") == -1 && Description.indexOf("W-WFI") == -1 && Description.indexOf("W-WAR") == -1)
            {
              fetexclude += sales;
            }
          }

          if(Description.indexOf("E-DEL") > -1){
            outofstate += sales;
          }

                /*if (type === "Service Contract") {
                    warrantysales += sales;
                }

          if (addontype === "Warranty") {
                    warrantysales += sales;
                }*/

          if(list.tax != null) { //this condition is applied because AddOns and PO Item both forms data are stored in same table seperated/flaged by asset_expenses_id
            var TaxExclude = list.tax;
            if (TaxExclude.substring(0, 2) === "EX" && Description.indexOf("W-WFI") === -1 && Description.indexOf("W-WAR") === -1 && AddToSalesPrice == 1) {
                taxexclude += sales;
            }
          }

          // To emit the warranty value in TransactionSheet Cash Details Warranty field
          if(Description == "W-WAR - WARRANTY <ENTER DETAILS> [PRICE & COST]") {
            transactionWarranty += sales;
          }
        });
      }

      self.cashDetailWarranty = transactionWarranty;

      if(miscTireCredit > 0) {
        self.tireCredit = miscTireCredit.toFixed(2);
      }
      self.CommissionDeduct = CommissionDeduct.toFixed(2);
      self.addonscost = addonscost.toFixed(2);
      self.warrantysales = warrantysales.toFixed(2);
      self.warrantycost = warrantycost.toFixed(2);
      self.flooringadd = flooringadd.toFixed(2);
      self.addons = addons.toFixed(2);
      self.fetexclude = fetexclude.toFixed(2);
      self.outofstate = outofstate.toFixed(2);
      self.taxexclude = taxexclude.toFixed(2);
      self.totalInternalLocalInstallationLessOutOfStateDeliveryCost = self.addonscost;
      self.fetExemptItems = self.fetexclude;

      // var tradevalue = 0;
      // var tradepayoff = 0;
      // var acv = 0;


      // IMP trade details
      // testGrid = $find("dgTrade");

      // if (testGrid) {
      //     mastertable = testGrid.get_masterTableView();
      //     var tradeTableRows = mastertable.get_dataItems();

      //     for (var i = 0; i < tradeTableRows.length; i++) {
      //         tradevalue = tradevalue + GetNumericValue(tradeTableRows[i].get_cell("grossvalue").innerHTML);
      //         tradepayoff = tradepayoff + GetNumericValue(tradeTableRows[i].get_cell("payoff").innerHTML);
      //         acv = acv + GetNumericValue(tradeTableRows[i].get_cell("nettrade").innerHTML);
      //     }
      // }

    },
    calTrade() {
      testGrid = $find("dgTrade");

      if (testGrid) {
          mastertable = testGrid.get_masterTableView();
          var tradeTableRows = mastertable.get_dataItems();

          for (var i = 0; i < tradeTableRows.length; i++) {
              tradevalue = tradevalue + GetNumericValue(tradeTableRows[i].get_cell("grossvalue").innerHTML);
              tradepayoff = tradepayoff + GetNumericValue(tradeTableRows[i].get_cell("payoff").innerHTML);
              acv = acv + GetNumericValue(tradeTableRows[i].get_cell("nettrade").innerHTML);
          }
      }

      overallowance = acv - tradevalue;
      SetValue("tbOverAllowance", overallowance);
    },
    calReverseVehiclePrice() {
      if (!self.packed) {
        return self.GetValue(self.vehicleSalesPrice);
      }; // return existing price;
      var NewUsed = self.typeId != null ? self.typeId.toLowerCase() : '';
      var fetCustom = self.customFet;
      var fetExempt = self.fetExempt;

      if (NewUsed == "used") {
      /* do do nothing
          document.getElementById("tbSalesPrice_tbCurrency").value = totalsales;
          formatCurrency("", "tbSalesPrice_tbCurrency");*/
          console.log('do nothing');
      }
      else if (!fetCustom) {
        var totalsales = self.GetValue(self.priceFetIncluded);
        var overallowance = self.GetValue(self.vehicleSalesPrice);
        var tirecredit = self.GetValue(self.tireCredit);
        var poamount = self.GetValue(self.poAmountToAddToSalesPrice);
        var fetexclude = 0; //self.poAmountToAddToSalesPrice
        var factoryInvoice = self.GetValue(self.inventoryCostLastUpdated);
        var holdback = self.GetValue(self.holdBack)
        var credits = 0;
        var internals = 0;
        var hasAddons = (self.addOnPoList.length == 0) ? false : true;
        var deductOverAllowance = 0;
        if (hasAddons) {
          var miscTireCredit = 0;

          self.addOnPoList.map(list => {
            var cost = parseFloat(list.cost);
            var sales = parseFloat(list.price);

            var FETExclude = list.is_fet_exempt;
            var Description = list.description;
            if(FETExclude == 1)
            {
              fetexclude += sales;
            }
            if(cost < 0){
              credits += (cost * -1);
            }
            if(cost > 0 && sales == 0){
              internals += cost;
            }
          });

          // Calculate baseprice
          if (overallowance < 0) {
              deductOverAllowance = overallowance * -1
          }
          var totalcost = factoryInvoice + holdback - credits + internals + poamount;
          var totalcostoverallowance = totalcost + deductOverAllowance;

        }

        var baseprice = (totalsales - (1.12 * poamount) + (1.12 * deductOverAllowance) - (fetexclude + tirecredit)) / 1.12
			  baseprice = Math.round(baseprice * 10000) / 10000; // CDK-133068800 + (0.12 * deductOverAllowance)

        // Todo on confirmed either to use only inside hasaddons
        var baseprice = (totalsales - (1.12 * poamount) + (1.12 * deductOverAllowance) - (fetexclude + tirecredit)) / 1.12;
        // console.log('initialBasePrice=>', baseprice);
        baseprice = self.GetValue(Math.round(baseprice * 10000) / 10000); // CDK-133068800 + (0.12 * deductOverAllowance)
        self.vehicleSalesPrice = baseprice;
        // console.log('basePrice=>', baseprice, self.vehicleSalesPrice);
        return baseprice;
      }

      return self.GetValue(self.vehicleSalesPrice); // return existing price;
    },
    gfwFeeCal(weight)
    {
      var val = 0
      for(var i=0;i<self.gfwFee.length;i++)
      {
        var prev_fee = self.gfwFee[i-1]
        var curr_fee = self.gfwFee[i]
        var weight_range = curr_fee.weight_range.split("-");
        val = curr_fee.cvra_fee
        if(weight < weight_range[0]) {
          return parseFloat(prev_fee.cvra_fee);
        }
      }
      return val;
    },
    fctHoldBackPercentUpdate() {
      if(self.packed == false && !(self.typeId == "" || self.typeId == undefined)) {
        if(self.typeId.toLowerCase() == "new" && self.loadCount == 0)
        {
          alert("Reminder: 20% on Fleet Deals")
        }
        self.holdBackFixed = 0;
      }
      self.calVehiclePrice();
    },
    fctFixedHoldBack() {
      if(!(self.typeId == "" || self.typeId == undefined) && self.typeId.toLowerCase() == "new" && self.loadCount == 0)
      {
        alert("Reminder: 20% on Fleet Deals")
      }
      self.percentage = 0;
      self.calVehiclePrice();
    },
    // CalculateSplitCommissionPercent() {
    //   var CommissionPercent1 = GetValue("tbCommissionPercent1");
    //   var CommissionPercent2 = GetValue("tbCommissionPercent2");
    //   var CommissionPercent3 = GetValue("tbCommissionPercent3");
    //   var TotalCommission = GetValue("tbTotalCommission");
    //   var GrossProfit = GetValue("tbCommissionGrossProfit");
    //   var calcCommission = GetValue("tbCalculatedCommission");
    //   var NewUsed = GetListValue("ddlNU");
    //   var branch = GetListValue("tbBranch");
    //   if (CommissionPercent1 > 0) {
    //     SetValue("tbCommissionAmount1", GrossProfit * (CommissionPercent1 / 100));
    //   }
    //   if (CommissionPercent2 > 0) {
    //     SetValue("tbCommissionAmount2", GrossProfit * (CommissionPercent2 / 100));
    //   }
    //   if (CommissionPercent3 > 0) {
    //     SetValue("tbCommissionAmount3", GrossProfit * (CommissionPercent3 / 100));
    //   }
    // },
    GetValue(value) {
      if (isNaN(value) || value === "" || value === undefined) {
        value = 0;
      }

        // console.log('from=>',value);
      return value;
    },
    addOnModal: function () {
      self.isClickAddOnList = true;
      self.addOnEditData = {};
      $("#addOnModal").modal("show");
    },
    getStaticData: function() {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: self.locationId.value
        }
      };
      HTTP.get("/get-all-static-data", config)
        .then(function (response) {
          self.branchDefaultCal = response.data.content.datas.branch_defaults;
          self.gfwFee = response.data.content.gfwList;
          if(response.data.content.datas.cal_vehicle_prices.length > 0) {
            self.vehiclePriceCalList = response.data.content.datas.cal_vehicle_prices;
            self.dmvElectronicFillingFee = self.vehiclePriceCalList[0].dmv_electronic_filling_fee;
            self.licenseTitleStatic =self.vehiclePriceCalList[0].license_title;
          }
          return true;
        })
        .catch(function (err) {
          console.log('Error on fetching static data', err);
          self.hideLoader();
          self.catchHandler(err, function () {
          });
        });
    },
    deleteAddOn : function (id,index,assetExpensesId) {
      self.$swal({
          title: '',
          text: 'Are you sure that you want to delete this!',
          type: 'info',
          confirmButtonText: 'OK',
          showCancelButton: true,
          showLoaderOnConfirm: true
      }).then((result) => {
          if(assetExpensesId == null) {
            if (result.value) {
              if(localStorage.getItem('AddOnTemp') != null) {
                var AddOnTemp = [];
                AddOnTemp = JSON.parse(localStorage.getItem('AddOnTemp'));
                // console.log('DeletAddOnTemp>>', AddOnTemp.length);
                if(AddOnTemp.length == 0) {
                  localStorage.removeItem('AddOnTemp');
                  self.addOnPoList = [];
                } else {
                  AddOnTemp.splice(index,1).pop();
                  localStorage.setItem('AddOnTemp', JSON.stringify(AddOnTemp));
                  self.addOnPoList = AddOnTemp;
                }

                self.calVehiclePrice();
              } else {
                var config = {
                  headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                  data : {
                      id: id,
                  }
                };
                HTTP.delete("/add-on-deal/delete", config)
                  .then(function (response) {
                      if (response.data.code == '200') {
                        let updateAddOnList = self.addOnPoList.filter((el) => el.id !== id);
                        self.showSAlert(response.data.message, 'success', false, 2);
                        self.addOnPoList = updateAddOnList;
                        self.calVehiclePrice();
                      }
                  })
                  .catch(function (error) {
                    console.log('error=>',error);
                      self.catchHandler(error, function () {});
                  });
              }
            }
          } else {
            var config = {
              headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
              data : {
                  asset_expenses_id: assetExpensesId,
              }
            };
            HTTP.delete("/purchase-order/"+id, config)
            .then(function (response) {
                if (response.data.code == '200') {
                  let updateAddOnList = self.addOnPoList.filter((el) => el.id !== id);
                  self.showSAlert(response.data.message, 'success', false, 2);
                  self.addOnPoList = updateAddOnList;
                  self.calVehiclePrice();
                }
            })
            .catch(function (error) {
              console.log('error=>',error);
                self.catchHandler(error, function () {});
            });
          }
      });
    },
    editAddOn: function(data,index) {
      // console.log('EditAddOnId>>',index, data);
      if(data.asset_expenses_id == null) {
        self.addOnEditData = data;
        self.isClickAddOnList = true;
        self.addOnTempIndex = index;
        $('#addOnModal').modal('show');
      } else {
        console.log('addOnPoList', self.addOnPoList);
        // let tempPoList = self.addOnPoList.filter((po) => po.asset_expenses_id == data.asset_expenses_id);
        // console.log('tempPoList>>>', tempPoList);
        self.poEditData = data;
        self.isClickPurchaseOrder = true;
        $("#vehiclePurchaseModal").modal({backdrop: 'static', keyboard: false});
      }
    },
    calFlooring: function() {
      self.isClickCalFlooring = true;
      $("#calculateFlooring").modal({backdrop: 'static', keyboard: false});
    },
    purchaseOrderModal: function() {
      if(self.locationId == '') {
        self.showSAlert('Please Select Branch from TransactionSheet', 'info', false, 2);
      } else if(self.assetId == '') {
        self.showSAlert('Please Select Inventory', 'info', false, 2);
      } else {
        self.poEditData = {},
        self.isClickPurchaseOrder = true;
        $("#vehiclePurchaseModal").modal({backdrop: 'static', keyboard: false});
      }
    },
    incrementDecrementNumber(event, dataInstance) {
      switch (event.key) {
        case 'ArrowUp':
          this[dataInstance] += 1;
          break;
        case 'ArrowDown':
          this[dataInstance] -= 1;
          break;
      }
    },
    addTradeModal: function() {
        self.isClickAddTrade = true;
        $("#tradeDetail").modal({backdrop: 'static', keyboard: false});
    },
    loadVehicleInformationFields: function(loadVehicleInformationCallback) {
      loadVehicleInformationCallback.FuelType.map(fuel => {
        self.fuelTypeArr.push(fuel);
      })
    },
    vehicleDetailModal: function() {
        self.isClickDetail = true;
        $("#vehicleDetailModal").modal({backdrop: 'static', keyboard: false});
    },
  },
};
// EventBus.$on("grand-total-cost-sheet",function (callbackGrandTotalCostSheet,costSheetClick){
//   if (typeof self != "undefined"){
//     self.grandTotalCostSheet = parseFloat(callbackGrandTotalCostSheet);
//     self.isClickCostSheet = costSheetClick;
//   }
// });

EventBus.$on("add-on-list-data",function (addOnListData){
  if (typeof self != "undefined"){
    self.addOnPoList = addOnListData; //addOnPoList for both addOn and PO datas
    self.addOnList = addOnListData; //addOnList for only addOn used at the time of storing addOn when editAssetId is null
    self.isClickAddOnList = false;
    // console.log('EventCallData>>',self.addOnList, addOnListData);
    if(addOnListData.length > 0) {
      $('#addOnModal').modal('hide')
      self.calVehiclePrice();
    }
  }
});

EventBus.$on("flooring",function (calValue){
  if (typeof self != "undefined"){
    self.flooring = calValue;
    $("#calculateFlooring").modal("hide");
    self.sumFees();
  }
});

EventBus.$on("close-cost-sheet",function (status){
  if (typeof self != "undefined"){
    self.isClickCostSheet = status;
    $("#defaultModal").modal("hide");
  }
});

EventBus.$on("close-purchase-order",function (status){
  if (typeof self != "undefined"){
    self.isClickPurchaseOrder = status;
    self.poEditData = {};
    $("#vehiclePurchaseModal").modal("hide");
  }
});

EventBus.$on("load-cost-sheet", (value) => {
  if (typeof self != "undefined"){
    self.currentInventoryCostFromProcede = value;
    self.inventoryCostLastUpdated = value;
    self.calVehiclePrice();
  }
});

EventBus.$on("close-trade-detail",function (status){
  if (typeof self != "undefined"){
    self.isClickAddTrade = status;
    $("#tradeDetail").modal("hide");
  }
});

// EventBus.$on("purchase-item-list",function (poListData,type){
//   if (typeof self != "undefined"){
//     let itmList;
//     switch (type) {
//       case 'save':
//         itmList = poListData;
//         self.poData = null;
//         itmList.map(item => {
//           self.addOnPoList.push(item);
//         });
//         break;
//       case 'update':
//         self.poEditData = {};
//         self.addOnPoList = poListData;
//         break;
//       default:
//         itmList = poListData.item_list;
//         self.poData = poListData;
//         itmList.map(item => {
//           self.addOnPoList.push(item);
//         });
//         break;
//     }
//     self.isClickPurchaseOrder = false;
//     $("#vehiclePurchaseModal").modal("hide");
//     self.calVehiclePrice();
//   }
// });

EventBus.$on("close-vehicle-detail",function (){
  if (typeof self != "undefined"){
    self.isClickDetail = false;
    $("#vehicleDetailModal").modal("hide");
  }
});

</script>

<style :scope>
  .inputAmount {
    text-align: right;
    width: 200px;
  }
  .inputRight {
    float: right;
  }
  .inputSize {
    width: 200px;
  }
</style>
