<template>
    <div class="row" v-if="form_fields">
        <template v-for="(fieldObj, fieldIndex) in form_fields">

            <div v-if="fieldObj.display && fieldObj.type == 'checkbox'" class="mt-10 col-12 forms-block" :key="pre_id+'_field_'+fieldIndex">
                <div class="col-6 forms-block pl-0">
                    <div class="col-5 paddingleft0">
                        <div class="form-label checkbox-label customer-contact-field-text-align-right pr-10" ><label :for="pre_id + 'field_'+fieldObj.column_name">{{
                            fieldObj.label }}</label>
                        </div>
                    </div>
                    <div class="col-7 paddingleft0 custom-checkbox-v">
                        <label class="container">
                            <input :id="pre_id + 'field_'+fieldObj.column_name" v-model="fieldObj.value" value="true"
                                class="form-control" type="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>    
            </div>
            <div v-else-if="fieldObj.display" class="col-6 forms-block mt-10" :key="pre_id+'_field_'+fieldIndex">
                <div class="col-3 paddingleft0">
                    <div class="form-label">{{ fieldObj.label }}</div>
                </div>
                <div class="col-9 paddingleft0" v-if="fieldObj.type == 'input_text'">
                    <input :id="pre_id + 'field_'+fieldObj.column_name"   @input="onchangeErrMess(fieldIndex)"
                    v-model="fieldObj.value" class="form-control" type="text"  maxlength="190"  />
                    <div class="requiredstar" v-if="fieldObj.mandatory_validation">*</div>
                    <span class="help is-danger" v-show="fieldObj.error">{{ fieldObj.error_msg }}</span>
                </div>
                <div class="col-9 paddingleft0" v-if="fieldObj.type == 'dropdown_modal_search'">
                    <v-select 
                            v-model="fieldObj.value"
                            @change="changeDropDownSearch($event, fieldObj.column_name)"
                            :search-input.sync="search"
                            @search-input="onSearchInput($event, fieldObj.column_name)"
                            @input="onchangeErrMess(fieldIndex)"
                            :name="pre_id+'_'+fieldObj.type+'_'+fieldObj.column_name"
                            :id="pre_id+'_'+fieldObj.type+'_'+fieldObj.column_name"
                            label="text"
                            :clearable="true"
                            :searchable="true"
                            :filterable="true"
                            :options="fieldObj.option_list"
                            :disabled="fieldObj.permission == 'edit' ? false : true"
                            :placeholder="fieldObj.placholder">
                            <span slot="no-options" @click="$refs.select.open = false">
                                <template v-if="!fieldObj.is_record_not_found">
                                    {{fieldObj.placholder}}
                                </template>
                                <template v-else>
                                    {{staticMessages.search_record_not_found}}
                                </template>
                            </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger"  v-show="fieldObj.error">{{fieldObj.error_msg}}</span>
                </div>

            </div>
        </template>
    </div>
</template>
<script>
var self;
import EventBus from "../event-bus";
import { commonMixins } from '../../mixins/commonMixins';
export default {
    name: 'CustomerContactFormFields',
    mixins: [commonMixins],
    props: {
        form_fields: {
            type: [Object],
            required: true,
        },
        pre_id: {
            type: [String],
            required: false,
            default: "customer_contact_"
        },
        open_at: {
            type: [String],
            required: false,
            default: ""
        },
        contact_index: ""
    },
    data() {
        return {
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
            staticInputLabelMessages: "",
            staticMessages: "",
            search: ""

        }
    },
    beforeMount() {
        self = this;
    },
    created: function() {
        self = this;
        self.getLoginIdPermissions();
    },
    mounted: function() {
        /* Redirect to login page logic start */
        var userObj = localStorage.getItem("user_obj");
        if (userObj == null) {
             self.$router.push("/");
        }
        /* Redirect to login page logic end */
        
        self.staticInputLabelMessages = self.allLabelMessages;
        self.staticMessages = self.allStaticMessages;
        self.openCustomerContactPopup();
    },
    watch: {
      
    },
    methods: {
        openCustomerContactPopup(data = "") {
            self.$nextTick(function(){
                self.form_fields.country.value = self.prodCountryObj();

                if(self.form_fields.state.display) {
                    self.loadAllData(["States"],true,self.loadStateDataCallback,"undefined","",self.form_fields.country.value.value);
                }
            });
        },
        changeDropDownSearch(search, columnName) {
            if(columnName == 'state'){
                self.loadAllData(["Cities"],true,self.loadCityDataCallback,"undefined","",self.form_fields.state.value.value);
            }
        },
        onSearchInput(search, columnName) {
            if(columnName == 'state'){
                self.onSearchState(search);
            }else if(columnName == 'city'){
                self.onSearchCity(search);
            }
        },
        onSearchState(search) {
            if (search.length > 2) {
                if (self.form_fields.country.value ==null || self.form_fields.country.value.value == null || !self.form_fields.country.value.value) {
                    self.showSAlert("Please Select Country First!", "info", false);
                } else {
                    loading(true);
                    self.loadAllData(["States"],true,self.loadStateDataCallback,"",search,self.form_fields.country.value.value);
                }
            }
        },
        onSearchCity(search){
            if (search.length > 2) {
                if (self.form_fields.state.value ==null || self.form_fields.state.value.value == null || !self.form_fields.state.value.value) {
                    self.showSAlert("Please Select State First!", "info", false);
                } else {
                    loading(true);
                    self.loadAllData(["Cities"],true,self.loadCityDataCallback,"",search,self.form_fields.state.value.value);
                }
            }
        },
        loadStateDataCallback: function(callbackData) {
            if(callbackData && callbackData.length <= 0) {
                return false;
            }
            self.form_fields.state.is_record_not_found = false;
            self.form_fields.state.option_list = callbackData.States;
            if (self.callbackData.States.length == 0) {
                self.self.form_fields.state.is_record_not_found = true;
            }
        },
        loadCityDataCallback: function(callbackData) {
            if(callbackData && callbackData.length <= 0) {
                return false;
            }
            self.form_fields.city.is_record_not_found = false;
            self.form_fields.city.option_list = callbackData.Cities;
            if (self.callbackData.Cities.length == 0) {
                self.self.form_fields.state.is_record_not_found = true;
            }
        },
        onchangeErrMess(fieldIndex) {
            self.$emit('callErrorMessageHandle',self.contact_index, fieldIndex);
        }
    }
}


</script>

<style scoped>
.add-customer-popup-form .help.is-danger {
    text-transform: none;
  }
</style>

