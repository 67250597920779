<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_vehicle_check_in')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <template v-if="permissionNameArray.includes('can_list_vehicle_check_in')">
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </template>
                </div>

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Seles Person -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="userId"
                                            name="asset"
                                            @input="userError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="userArr"
                                            @search="onSearchUser"
                                            :clearable="false"
                                            :placeholder="staticMessages.sales_person_place_holder"
                                            :disabled="true">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{userError}}</span>
                                </div>
                            </div>

                            <!--Vin No.-->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            multiple
                                            v-model="asset"
                                            name="asset"
                                            @input="assetError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="assetArr"
                                            @search="onSearchAsset"
                                            :clearable="false"
                                            :placeholder="staticMessages.asset_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isAssetRecordNotFound">
                                                {{staticMessages.asset_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{assetError}}</span>
                                </div>
                            </div>

                            <!-- Year -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text"/> -->
                                    <date-picker
                                            id="year"
                                            v-model="year"
                                            :config="yearOptions"
                                            class="form-control"
                                            autocomplete="off"
                                            placeholder="Select Year"
                                            name="year"
                                            @input="yearError = ''"
                                    ></date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{yearError}}</span>
                                </div>
                            </div>

                            <!--Make -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="makeId"
                                            name="make"
                                            :placeholder="staticMessages.make_place_holder"
                                            label="text"
                                            @change="makeValueChanged"
                                            :options="makeArr"
                                            class=""
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>

                            <!--Model-->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="modelId"
                                            name="model"
                                            :placeholder="staticMessages.model_place_holder"
                                            label="text"
                                            @change="modelValueChanged"
                                            :options="modelArr"
                                            class=""
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </div>
                            </div>

                            <!-- Miles or Hours -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.miles_or_hours}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="milesOrHoursError = ''" id="milesOrHours" v-model="milesOrHours" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{milesOrHoursError}}</span>
                                </div>
                            </div>

                            <!-- Runs -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.runs}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="runs"
                                                        :options="runsOptions"
                                                        name="runs">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{runsError}}</span>
                                </div>
                            </div>

                            <!-- Axle Gaskets given to driver -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.axle_gaskets_given_to_driver}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="axleGaskets"
                                                        :options="isAxleGivenOptions"
                                                        name="axleGaskets">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{axleGasketsError}}</span>
                                </div>
                            </div>

                            <!-- Torque down front lug nuts -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.torque_down_front_lug_nuts}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="torqueDown"
                                                        :options="isTorqueDownOptions"
                                                        name="torqueDown">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{torqueDownError}}</span>
                                </div>
                            </div>

                            <!-- Fuel Level -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_level}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="fuelLevel"
                                                        :options="fuelLevelOptions"
                                                        name="fuelLevel">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{fuelLevelError}}</span>
                                </div>
                            </div>

                            <!-- Brake chamber locks removed -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.break_chamber_locks_removed}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="breakChamberLock"
                                                        :options="isBrakeChamberLockRemovedOptions"
                                                        name="breakChamberLock">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{breakChamberLockError}}</span>
                                </div>
                            </div>

                            <!-- Front tires matching? -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_tires_matching}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="frontTireMatching"
                                                        :options="isFrontTiresMatchingOptions"
                                                        name="frontTireMatching">
                                    </b-form-radio-group>
                                    <span class="help is-danger">{{frontTireMatchingError}}</span>
                                </div>
                            </div>

                            <!-- Visual Inspection Interior -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visual_inspection_interior}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="visualInspectionInterior" v-model="visualInspectionInterior" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Visual Inspection Exterior -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visual_inspection_exterior}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="visualInspectionExterior" v-model="visualInspectionExterior" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!--Additional inspection notes below, check for tire wear/condition, damage, excessive wear, etc.-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.additional_inspection}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="additionalInspection" v-model="additionalInspection" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <div class="clearfix"></div>
                            <div class="col-md-2 forms-block">
                            </div>


                            <!--Attach pic of VIN plate-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_pic_of_vin_plate}}</label>
                                    <div class="requiredstar">*</div>
                                    <vue-dropzone
                                            ref="dropzoneVinPlate"
                                            id="dropzoneVinPlate"
                                            :options="dropzoneOptionsVinPlate"
                                            @vdropzone-success = "uploadDocumentVinPlate"
                                            @vdropzone-removed-file="removedSelectedFileVinPlate"
                                            @vdropzone-error="fileErrorVinPlate"
                                            @vdropzone-file-added="fileAddVinPlate"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone="false"
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="help is-danger">{{fileUploadError}}</span>
                            </div>

                            <!--Attach pic(s) of specs sticker(s)-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_pics_of_spec_stickers}}</label>
                                    <vue-dropzone
                                            ref="dropzoneSpecStickers"
                                            id="dropzoneSpecStickers"
                                            :options="dropzoneOptionsSpecStickers"
                                            @vdropzone-success = "uploadDocumentSpecStickers"
                                            @vdropzone-removed-file="removedSelectedFileSpecStickers"
                                            @vdropzone-error="fileErrorSpecStickers"
                                            @vdropzone-file-added="fileAddSpecStickers"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone="false"
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>

                            <!--Attach additional pics of interior/exterior-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attach_additional_pics_of_interior_exterior}}</label>
                                    <vue-dropzone
                                            ref="dropzoneInteriorExterior"
                                            id="dropzoneInteriorExterior"
                                            :options="dropzoneOptionsInteriorExperior"
                                            @vdropzone-success = "uploadDocumentInteriorExperior"
                                            @vdropzone-removed-file="removedSelectedInteriorExperior"
                                            @vdropzone-error="fileErrorInteriorExperior"
                                            @vdropzone-file-added="fileAddInteriorExperior"
                                            @vdropzone-total-upload-progress="progressOfUload"
                                            :useCustomSlot=true
                                            :destroyDropzone="false"
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                            <div class="subtitle">(Select files from your computer.)</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display: none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUpdateVehicleChekInBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateVehicleChekIn()">
                            <input v-if="permissionNameArray.includes('can_list_vehicle_check_in')" id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>


                    <!-- Forms -->
                </div>
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                dropzoneOptionsVinPlate: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                dropzoneOptionsSpecStickers: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                dropzoneOptionsInteriorExperior: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                imagesPathsVinPlate: [],
                imagesTypeVinPlate: [],
                imagesSizeVinPlate: [],

                imagesPathsSpecStickers: [],
                imagesTypeSpecStickers: [],
                imagesSizeSpecStickers: [],

                imagesPathsInteriorExterior: [],
                imagesTypeInteriorExterior: [],
                imagesSizeInteriorExterior: [],

                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",

                fileUploadError : "",


                asset : "",
                assetArr : [],
                assetError : "",
                userId : "",
                userArr : [],
                userError : "",
                isAssetRecordNotFound: false,
                isUserRecordNotFound : false,

                runs : "",
                rundDefaultValue : "yes",
                runsError : "",
                axleGaskets : "",
                axleGasketsDefaultValue : "yes",
                axleGasketsError : "",
                torqueDown : "",
                torqueDownDefaultValue : "yes",
                torqueDownError : "",
                fuelLevel : "",
                fuelLevelDefaultValue : "under1/8",
                fuelLevelError : "",
                breakChamberLock : "",
                breakChamberLockDefaultValue : "yes",
                breakChamberLockError : "",
                frontTireMatching : "",
                frontTireMatchingDefaultValue : "yes",
                frontTireMatchingError : "",
                visualInspectionInterior : "",
                visualInspectionExterior : "",
                additionalInspection : "",


                year : "",
                yearError : "",
                yearOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                makeId : "",
                makeArr : [],
                makeError: "",
                modelId  : "",
                modelArr : [],
                modelError : "",
                milesOrHours : "",
                milesOrHoursError : "",

                firstAdd : false,

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                maxInputLength : 2,
                userArrayLength : 0,
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Vehical Check In",
                isAxleGivenOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                    {text: 'Trailer', value: 'trailer'},
                ],
                runsOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                isTorqueDownOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                    {text: 'Trailer', value: 'trailer'},
                ],
                fuelLevelOptions: [
                    {text: 'Under1/8', value: 'under1/8'},
                    {text: '1/8', value: '1/8'},
                    {text: '1/4', value: '1/4'},
                    {text: '1/2', value: '1/2'},
                    {text: '3/4', value: '3/4'},
                    {text: 'Full', value: 'full'}
                ],
                isBrakeChamberLockRemovedOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                isFrontTiresMatchingOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                permissionNameArray : ['can_add_vehicle_check_in']
            }
        },
        beforeMount() {
            self = this;
        },
        watch : {

        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.runs = self.rundDefaultValue;
            self.axleGaskets = self.axleGasketsDefaultValue;
            self.torqueDown = self.torqueDownDefaultValue;
            self.fuelLevel = self.fuelLevelDefaultValue;
            self.breakChamberLock = self.breakChamberLockDefaultValue;
            self.frontTireMatching = self.frontTireMatchingDefaultValue;

            self.selectDefaultUser();
            self.firstAdd = true;
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.customerAppproved = self.customerApprovedDefaultValue;
            self.customerSeen = self.customerSeenDefaultValue;
            self.setActiveMenu('vehical_checkin_submission_from_add');
            EventBus.$emit("menu-selection-changed", "vehical_checkin_submission_from_add");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.contentClass = "spaced-container";
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
            /* self.loadAllData(['User'], true, self.loadUserTypeCallback, "undefined", '');
            self.loadAllData(['Make'], false, self.loadMakeDataCallback); */
            self.loadAllData(['User', 'Make'], false, self.loadMasterDataCallback, "undefined", '');
        },

        methods: {
            removeAllFiles(flag) {
                var self = this;
                if (flag === "dropzoneVinPlate") {
                    self.$refs.dropzoneVinPlate.removeAllFiles();
                }
                if (flag === "dropzoneSpecStickers") {
                    self.$refs.dropzoneSpecStickers.removeAllFiles();
                }
                if (flag === "dropzoneInteriorExterior") {
                    self.$refs.dropzoneInteriorExterior.removeAllFiles();
                }
            },
            modelValueChanged: function () {
                self.modelError = "";
            },
            loadMasterDataCallback: function(callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.lenght == 0) {
                    self.isUserRecordNotFound = true;
                }
                self.makeArr = callbackData.Make;
            },
            /* loadMakeDataCallback: function (callbackData) {
                self.makeArr = callbackData.Make;
            }, */
            makeValueChanged: function () {
                self.makeError = "";
                let make_id = "";
                self.modelId = "";
                if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0) {
                    make_id = self.makeId.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, 'undefined', '', make_id);
            },
            loadModelDataCallback: function (callbackData) {
                self.modelArr = callbackData.Model;
            },

            /* Drop zone methods for vin plate */
            uploadDocumentVinPlate: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPathsVinPlate.push(response.content.file_path);
                self.imagesTypeVinPlate.push(file.type);
                self.imagesSizeVinPlate.push(file.size);
                // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
            },
            removedSelectedFileVinPlate: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                /* console.log(JSON.stringify(this.$refs.dropzoneVinPlate.dropzone.options)); */
                // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzoneVinPlate.dropzone.options.maxFiles) {
                    // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
                }
                /* if (self.imagesPathsVinPlate.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPathsVinPlate.length - 1; i >= 0; i--) {
                        if (self.imagesPathsVinPlate[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPathsVinPlate[i];
                        }
                    }
                    // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (var i = self.imagesPathsVinPlate.length - 1; i >= 0; i--) {
                    if (self.imagesPathsVinPlate[i] === removeFile) {
                        self.imagesPathsVinPlate.splice(i, 1);
                        self.imagesTypeVinPlate.splice(i, 1);
                        self.imagesSizeVinPlate.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzoneVinPlate.dropzone.options)); */
            },
            fileErrorVinPlate: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
                }
            },
            fileAddVinPlate: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzoneVinPlate.dropzone.options.maxFiles) {
                    // this.$refs.dropzoneVinPlate.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    self.disableButtons.saveAndContinue = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    self.disableButtons.saveAndContinue = true;
                }
            },
            /* End Drop zone methods for vin plate */

            /* Drop zone methods for Spec Stickers */
            uploadDocumentSpecStickers: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPathsSpecStickers.push(response.content.file_path);
                self.imagesTypeSpecStickers.push(file.type);
                self.imagesSizeSpecStickers.push(file.size);
                this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
            },
            removedSelectedFileSpecStickers: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                /* console.log(JSON.stringify(this.$refs.dropzoneSpecStickers.dropzone.options)); */
                this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzoneSpecStickers.dropzone.options.maxFiles) {
                    this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
                }
                /* if (self.imagesPathsSpecStickers.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPathsSpecStickers.length - 1; i >= 0; i--) {
                        if (self.imagesPathsSpecStickers[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPathsSpecStickers[i];
                        }
                    }
                    this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (var i = self.imagesPathsSpecStickers.length - 1; i >= 0; i--) {
                    if (self.imagesPathsSpecStickers[i] === removeFile) {
                        self.imagesPathsSpecStickers.splice(i, 1);
                        self.imagesTypeSpecStickers.splice(i, 1);
                        self.imagesSizeSpecStickers.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzoneSpecStickers.dropzone.options)); */
            },
            fileErrorSpecStickers: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
                }
            },
            fileAddSpecStickers: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzoneSpecStickers.dropzone.options.maxFiles) {
                    this.$refs.dropzoneSpecStickers.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            /* End Drop zone methods for Spec Stickers */

            /* Drop zone methods for Interior Exterior */
            uploadDocumentInteriorExperior: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.fileUploadError = "";
                self.imagesPathsInteriorExterior.push(response.content.file_path);
                self.imagesTypeInteriorExterior.push(file.type);
                self.imagesSizeInteriorExterior.push(file.size);
                this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
            },
            removedSelectedInteriorExperior: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                /* console.log(JSON.stringify(this.$refs.dropzoneInteriorExterior.dropzone.options)); */
                this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzoneInteriorExterior.dropzone.options.maxFiles) {
                    this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
                }
                /* if (self.imagesPathsInteriorExterior.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPathsInteriorExterior.length - 1; i >= 0; i--) {
                        if (self.imagesPathsInteriorExterior[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPathsInteriorExterior[i];
                        }
                    }
                    this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                for (var i = self.imagesPathsInteriorExterior.length - 1; i >= 0; i--) {
                    if (self.imagesPathsInteriorExterior[i] === removeFile) {
                        self.imagesPathsInteriorExterior.splice(i, 1);
                        self.imagesTypeInteriorExterior.splice(i, 1);
                        self.imagesSizeInteriorExterior.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzoneInteriorExterior.dropzone.options)); */
            },
            fileErrorInteriorExperior: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
                }
            },
            fileAddInteriorExperior: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzoneInteriorExterior.dropzone.options.maxFiles) {
                    this.$refs.dropzoneInteriorExterior.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            /* End Drop zone methods for Interior Exterior */




            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            selectDefaultUser : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let userId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    userId = userObj['id'];
                    fullName = firstName + " " + lastName;

                    self.userId = {text : fullName , value : userId};
                }
            },

            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            loadUserTypeCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.userArr = callbackData.User;
                if (self.userArr.lenght == 0) {
                    self.isUserRecordNotFound = true;
                }
            },
            returnData: function () {
                var data = {
                    user : self.userId,
                    vin_no : self.asset,
                    year : self.year,
                    make : self.makeId,
                    model : self.modelId,
                    miles_hour : self.milesOrHours,
                    runs : self.runs,
                    axle_gasket : self.axleGaskets,
                    torque_down : self.torqueDown,
                    fuel_level : self.fuelLevel,
                    break_chamber : self.breakChamberLock,
                    front_tire_matching : self.frontTireMatching,
                    visual_inspection_interior : self.visualInspectionInterior,
                    visual_inspection_exterior : self.visualInspectionExterior,
                    additional_inspection : self.additionalInspection,

                    imagesPathsVinPlate : self.imagesPathsVinPlate,
                    imagesSizeVinPlate : self.imagesSizeVinPlate,
                    imagesTypeVinPlate : self.imagesTypeVinPlate,

                    imagesPathsSpecStickers : self.imagesPathsSpecStickers,
                    imagesSizeSpecStickers : self.imagesSizeSpecStickers,
                    imagesTypeSpecStickers : self.imagesTypeSpecStickers,

                    imagesPathsInteriorExterior : self.imagesPathsInteriorExterior,
                    imagesSizeInteriorExterior : self.imagesSizeInteriorExterior,
                    imagesTypeInteriorExterior : self.imagesTypeInteriorExterior,
                };


                return data;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: 'ListVehicleCheckIn'});
            },
            clearDataOnSaveAndContinue: function () {
                self.year = '';
                self.milesOrHours = '';
                self.visualInspectionInterior = '';
                self.visualInspectionExterior = '';
                self.additionalInspection = '';
                self.selectDefaultUser();
                self.asset = [];
                self.makeId = {text : "", value : ""};
                self.modelId = {text : "", value : ""};
                self.runs = self.rundDefaultValue;
                self.axleGaskets = self.axleGasketsDefaultValue;
                self.torqueDown = self.torqueDownDefaultValue;
                self.fuelLevel = self.fuelLevelDefaultValue;
                self.breakChamberLock = self.breakChamberLockDefaultValue;
                self.frontTireMatching = self.frontTireMatchingDefaultValue;

                self.imagesPathsVinPlate = [];
                self.imagesTypeVinPlate = [];
                self.imagesSizeVinPlate = [];

                self.imagesPathsSpecStickers = [];
                self.imagesTypeSpecStickers = [];
                self.imagesSizeSpecStickers = [];

                self.imagesPathsInteriorExterior = [];
                self.imagesTypeInteriorExterior = [];
                self.imagesSizeInteriorExterior = [];

                /** COMMENTED BY SANTOSH BECAUSE ITS TRIGGERING THE REMOVE API ON EVERY SUCESS AND THROWING ERROR **
                self.removeAllFiles("dropzoneVinPlate");
                self.removeAllFiles("dropzoneSpecStickers");
                self.removeAllFiles("dropzoneInteriorExterior");
                */

            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                //
                if (self.userId == null || !self.userId.value) {
                    self.userError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.asset.length == 0) {
                    self.assetError = "Please enter vin no";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.modelId == null || !self.modelId.value) {
                    self.modelError = "Please select model";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.makeId == null || !self.makeId.value) {
                    self.makeError = "Please select make";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.milesOrHours) {
                    self.milesOrHoursError = "Please enter miles or hours"
                    if (checked) {
                        checked = false;
                    }
                }  else if (self.milesOrHours && !self.numberFormateValidation(self.milesOrHours)) {
                    self.milesOrHoursError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.year) {
                    self.yearError = "Please enter year";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.year && !self.numberFormateValidation(self.year)) {
                    self.yearError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.imagesPathsVinPlate.length === 0)  {
                    self.fileUploadError = "Please upload file of VIN plate";
                    if (checked) {
                        checked = false;
                    }
                }

                return checked;
            },
            addUpdateVehicleChekIn(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/vehiclecheckin/add/update/vtc", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.clearDataOnSaveAndContinue();
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.permissionNameArray.includes('can_list_vehicle_check_in')) {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>

<style>
    .spanClass {
        margin-left: 10px;
    }
</style>