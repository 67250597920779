<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_vendor_company_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>

                            <!--Company Code-->
                            <div v-if="isEdit" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_code_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input disabled @input="companyCodeError = ''" id="companyCode" v-model="companyCode" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyCodeError}}</span>
                                </div>
                            </div>

                            <!--Website URL-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.website_url_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="websiteUrlError = ''" id="websiteUrl" v-model="websiteUrl" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{websiteUrlError}}</span>
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="statusId"
                                              @change="changeStatus"
                                              @input="statusError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="statusArr"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span>
                                </div>
                            </div>

                            <!--Note-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea  @input="noteError = ''" id="note" v-model="note" class="form-control" type="text" maxlength="50000"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{noteError}}</span>
                                </div>
                            </div>

                            <!--Tax Exempt -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tax_exempt_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="taxExemptId"
                                              @change="changeTaxExempt"
                                              @input="taxExemptError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="taxExemptArr"
                                              :placeholder="staticMessages.tax_exempt_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{taxExemptError}}</span>
                                </div>
                            </div>

                            <!--Tax Id-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tax_id_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="taxIdError = ''" id="taxId" v-model="taxId" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{taxIdError}}</span>
                                </div>
                            </div>

                            <!--Payment Terms-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.payment_terms_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="paymentTermsError = ''" id="paymentTerms" v-model="paymentTerms" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{paymentTermsError}}</span>
                                </div>
                            </div>

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addVendorGroupBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addVendorGroup ()">
                            <input v-show="showSaveAndContinue" id="addAndContinueVendorGroupBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addVendorGroup (flag='Save and Continue')">
                            <input id="cancelVendorGroupBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>

                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
        <!-- </div> -->
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'AddUpdateVendorGroup',
        mixins: [commonMixins],
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                isEdit: false,
                name : "",
                nameError : "",
                companyCode : "",
                companyCodeError : "",
                websiteUrl : "",
                websiteUrlError : "",
                taxExemptId : {text : "No", value : 'no'},
                taxExemptArr : [{ text : "Yes", value : 'yes'}, { text : "No", value : 'no'}],
                taxExemptError : "",
                statusId : {text : "Active", value : 'active'},
                statusArr : [{ text : "Active", value : 'active'}, { text : "Inactive", value : 'inactive'}],
                statusError : "",
                taxId : "",
                taxIdError : "",

                note : "",
                noteError : "",
                paymentTerms : "",
                paymentTermsError : "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Vendor Company",
                permissionNameArray : ['can_add_vendor_company_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('vendorgroup-list');
            EventBus.$emit("menu-selection-changed", "vendorgroup-list");
            self.getLoginIdPermissions();
            $("#alertError").hide();
            self.isEdit = false;
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.isEdit = true;
                self.getVendorGroupDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Vendor Company";
            }
        },
        methods: {
            changeTaxExempt : function() {

            },
            changeStatus  : function () {

            },
            buttonBack() {
                self.$router.push({ name: 'ListVendorGroup'});
            },

            clearDataOnSaveAndContinue : function () {
                self.name = '';
                self.companyCode = '';
                self.websiteUrl = '';
                self.taxId = '';
                self.paymentTerms = '';
                self.note = '';
                self.statusId = [];
                self.taxExemptId = [];
            },

            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },

            getVendorGroupDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };

                HTTP.get('/vendorgroup/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.companyCode = response.data.content.company_code;
                        self.websiteUrl = response.data.content.website_url;
                        self.taxId = response.data.content.tax_id;
                        self.paymentTerms = response.data.content.payment_terms;
                        self.note = response.data.content.note;

                        self.statusId = (response.data.content.status == "active") ?
                            { text : "Active", value : 'active'}  :
                            { text : "Inactive", value : 'inactive'} ;

                        self.taxExemptId = (response.data.content.tax_exempt == "yes") ?
                            { text : "Yes", value : 'yes'}  :
                            { text : "No", value : 'no'} ;

                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },

            checkForm: function () {
                var checked = true;

                if (self.websiteUrl && !self.websiteUrlValidation(self.websiteUrl)) {
                    self.websiteUrlError = "Please enter valid url";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (!self.companyCode) {
                    self.companyCodeError = "Please enter company code";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.statusId == null || !self.statusId.value) {
                    self.statusError = "Please select status";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.taxExemptId == null || !self.taxExemptId.value) {
                    self.taxExemptError = "Please select tax exempt";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.taxId) {
                    self.taxIdError = "Please enter tax id";
                    if (checked) {
                        checked = false;
                    }
                }else if (self.taxId && !self.numberFormateValidation(self.taxId)) {
                    self.taxIdError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.paymentTerms) {
                    self.paymentTermsError = "Please enter payment terms";
                    if (checked) {
                        checked = false;
                    }
                }

                return checked;
            },

            addVendorGroup (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('name',self.name);
                        // data.append('company_code',self.companyCode);
                        data.append('website_url',self.websiteUrl);
                        data.append('status',self.statusId.value);
                        data.append('note',self.note);
                        data.append('tax_exempt',self.taxExemptId.value);
                        data.append('tax_id',self.taxId);
                        data.append('payment_terms',self.paymentTerms);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('company_code', self.companyCode);
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/vendorgroup/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>