<template>
    <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- vendor id -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                                </div>
                                <!-- <div class="col-9 paddingleft0">
                                    <input @input="vendorError = ''" id="vendor" v-model="vendor" class="form-control" type="text"/>
                                </div> -->
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="vendor"
                                            @change="changedValue"
                                            @input="vendorError = ''"
                                            @search="onSearchVendor"
                                            @clear="onClearVendor"
                                            name="vendor"
                                            id="vendor"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="vendorArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isVendorRecordNotFound">
                                                {{staticMessages.acquire_from_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<input @input="vendorError = ''" id="vendor" v-model="vendor" class="form-control" type="text"/>-->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vendorError}}</span>
                                </div>
                            </div>
                            <!-- Reference No -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reference_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="referenceNoError = ''" id="referenceNo" v-model="referenceNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{referenceNoError}}</span>
                                </div>
                            </div>
                            <!-- estimated cost -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.estimated_cost_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" @input="estimatedCostError = ''" id="estimatedCost" v-model="estimatedCost" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{estimatedCostError}}</span>
                                </div>
                            </div>
                            <!-- actual cost -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.actual_cost_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" @input="actualCostError = ''" id="actualCost" v-model="actualCost" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{actualCostError}}</span>
                                </div>
                            </div>
                            <!-- Paid Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.paid_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="paidDate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.paid_date_place_holder"
                                            name="paidDate"
                                            @input="changeDatePickerDate()"
                                    >
                                    </date-picker>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{paidDateError}}</span>
                                </div>
                            </div>
                            <!-- expense category -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.expense_category_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="expenseCategory"
                                        @change="changedValue"
                                        @input="expenseCategoryError = ''"
                                        name="expenseCategory"
                                        id="expenseCategory"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="expenseCategoryArr"
                                        :placeholder="staticMessages.expense_category_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{expenseCategoryError}}</span>
                                </div>
                            </div>
                            <!-- Summary -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.summary_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="summaryError = ''" id="summary" v-model="summary" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <!-- <span class="help is-danger">{{actualCostError}}</span> -->
                                </div>
                            </div>
                            <!-- Note -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="notesError = ''" id="notes" v-model="notes" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <!-- <span class="help is-danger">{{actualCostError}}</span> -->
                                </div>
                            </div>
                            <!-- rebill_contact_id -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rebill_contact_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="rebillContactError = ''" id="rebillContact" v-model="rebillContact" class="form-control" type="text"/> -->
                                    <v-select v-model="rebillContact"
                                            @change="changedValue"
                                            @input="rebillContactError = ''"
                                            @search="onSearchRebillContact"
                                            @clear="onClearRebillContact"
                                            name="rebillContact"
                                            id="rebillContact"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="rebillContactrArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isRebillContactRecordNotFound">
                                                  {{staticMessages.acquire_from_search}}
                                              </template>
                                              <template v-else>
                                                  {{staticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                    </v-select>
                                </div>
                            </div>
                            <!-- rebill_paid_date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rebill_paid_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="rebillPaidDate"
                                            :config="options"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.rebill_paid_date_place_holder"
                                            name="rebillPaidDate"
                                    >
                                    </date-picker>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <!-- <span class="help is-danger">{{rebillPaidDateError}}</span> -->
                                </div>
                            </div>
                            <!-- chargeback -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.chargeback_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="chargebackError = ''" id="chargeback" v-model="chargeback" class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{chargebackError}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- {{selectedAssets}} -->
                        <!-- {{assetId}} -->
                        <!-- options with group -->
                        <template v-if="selectedAssets.length > 1">
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.group_expense_options_update}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="updateExpenseGroupStatus"
                                                                    :options="updateGroupExpenseOptionsArr"
                                                                    name="updateExpenseGroupStatus"
                                                                    stacked>
                                                </b-form-radio-group>
                                </div>
                            </div>
                        </template>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <template v-if="selectedAssets.length > 1">
                                <input id="addAssetExpensesBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAssetExpensesInGroup()">
                                <input id="addAndContinueAssetExpensesBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAssetExpensesInGroup(flag='Save and Continue')">
                            </template>
                            <template v-else>
                                <input id="addAssetExpensesBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAssetExpenses()">
                            <input id="addAndContinueAssetExpensesBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAssetExpenses(flag='Save and Continue')">
                            </template>
                            
                            <input id="cancelAssetExpenses" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
            
</template>
<script>
var self;
import {commonMixins} from '../../mixins/commonMixins';
import EventBus from ".././event-bus";
import {HTTP} from '../../http-common';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import NoAccessPage from "../NoAccessPage/NoAccessPage"
export default {
    components: {
            datePicker,
            'no-access' : NoAccessPage,
        },
    props: ['selectedAssets','backButtonClicked','assetId'],
    mixins: [commonMixins],
    data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                asset: "",
                vendor: "",
                vendorArr : [],
                rebillContactrArr:[],
                isVendorRecordNotFound: false,
                isRebillContactRecordNotFound: false,
                referenceNo: "",
                estimatedCost: "",
                actualCost: "",
                paidDate : "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                expenseCategory: "",
                expenseCategoryArr: [],
                summary: "",
                notes: "",
                rebillContact: "",
                rebillPaidDate: "",
                chargeback: "",

                
                vendorError: "",
                referenceNoError: "",
                estimatedCostError: "",
                actualCostError: "",
                paidDateError: "",
                expenseCategoryError: "",
                summaryError: "",
                notesError: "",
                rebillContactError: "",
                rebillPaidDateError: "",
                chargebackError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Inventory Expenses",
                permissionNameArray : ['can_add_expense_master'],
                updateExpenseGroupStatus:"1",
                updateGroupExpenseOptionsArr: [
                    {
                        text: "Split cost & group expenses across multiple assets",
                        value: "1"
                    },
                    {
                        text: "Create expenses w/actual cost for each asset",
                        value: "2"
                    },
                ],
            }
    },
    beforeMount() {
            self = this;
    },
    mounted: function() {
        /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
        self.staticInputLabelMessages = self.allLabelMessages;
        self.staticMessages = self.allStaticMessages;
        self.setActiveMenu('assetexpenses-list');
        EventBus.$emit("menu-selection-changed", "asset-list");
        $("#alertError").hide();
        if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
            self.getAssetExpensesDetailsById(self.$route.params.id);
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetExpensesBtn").hide();
            self.pageTitle = "Edit Inventory Expenses";
        }
        self.getLoginIdPermissions();
        self.loadAllData(['ExpenseCategory'], false, self.loadExpenseCategoryCallback, "undefined", '');
    },
    methods: {
            buttonBack() {
                //self.$router.push('/assetexpenses/list');
                self.backButtonClicked();
            },
            changedValue: function () {
            },
            loadExpenseCategoryCallback: function (callbackData) {
                self.expenseCategoryArr = callbackData.ExpenseCategory;
            },
            changeDatePickerDate : function () {
                self.paidDateError = "";
                self.rebillPaidDateError = "";
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchVendor(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isVendorRecordNotFound = false;
                self.vendorArr = callbackData.Vendor;
                if(self.vendorArr.length == 0) {
                    self.isVendorRecordNotFound = true;
                }
            },
            onClearVendor : function() {
                self.vendorError = "";
            },
            onSearchRebillContact(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadRebillContactFromCallback, loading, search);
                }
            },
            loadRebillContactFromCallback: function (callbackData) {
                self.isRebillContactRecordNotFound = false;
                self.rebillContactrArr = callbackData.Contact;
                if(self.rebillContactrArr.length == 0) {
                    self.isRebillContactRecordNotFound = true;
                }
            },
            onClearRebillContact : function() {
                self.vendorError = "";
                self.rebillContact = {text: 'Select', value: null};
            },
            clearDataOnSaveAndContinue : function () {
                self.vendor = "";
                self.referenceNo = "";
                self.estimatedCost = "";
                self.actualCost = "";
                self.paidDate = "";
                self.expenseCategory = "";
                self.summary = "";
                self.notes = "";
                self.rebillContact = "";
                self.rebillPaidDate = "";
                self.chargeback = "";
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getAssetExpensesDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/assetexpenses/get', config)
                    .then(response => {
                        let paidDate = self.datePickerDateFormat(response.data.content.paid_date);
                        self.asset = {text: self.formatString(response.data.content.vinNo), value: response.data.content.asset_id};
                        self.vendor = response.data.content.vendor_id;
                        self.referenceNo = response.data.content.reference_no;
                        self.estimatedCost = response.data.content.estimated_cost;
                        self.actualCost = response.data.content.actual_cost;
                        self.paidDate = paidDate;
                        if(response.data.content.expense_category_id != null){
                            self.expenseCategory = {value: response.data.content.expense_category_id, text: response.data.content.expenseCategoryName};
                        }
                        self.rebillContact = {text: 'Select Contact', value: null};
                        if(response.data.content.rebill_customer_id != 'null' && response.data.content.rebill_customer_id != null){
                            self.rebillContact = {text: self.formatString(response.data.content.rebill_customer_name), value: response.data.content.rebill_customer_id};
                        }
                        self.summary = response.data.content.summary;
                        self.notes = response.data.content.notes;
                        self.rebillContact = response.data.content.rebill_contact_id;
                        self.rebillPaidDate = response.data.content.rebill_paid_date;
                        self.chargeback = response.data.content.chargeback;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.vendor == null || !self.vendor.value) {
                    self.vendorError = "Please select vendor";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.referenceNo) {
                    self.referenceNoError = "Please enter reference No";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.estimatedCost) {
                    self.estimatedCostError = "Please enter estimated Cost";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.estimatedCost && !self.numberFormateValidation(self.estimatedCost)) {
                    self.estimatedCostError = "Estimated Cost must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.actualCost) {
                    self.actualCostError = "Please enter actual Cost";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.actualCost && !self.numberFormateValidation(self.actualCost)) {
                    self.actualCostError = "Actual Cost must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                // if (!self.paidDate) {
                //     self.paidDateError = "Please select paid Date";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // if (self.expenseCategory == null || !self.expenseCategory.value) {
                //     self.expenseCategoryError = "Please select expense Category";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (self.chargeback && !self.numberFormateValidation(self.chargeback)) {
                    self.chargebackError = "Chargeback must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addAssetExpenses(flag) {
                //alert(flag); return false;
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        if (self.isEdit) {
                            data.append('id',self.isEdit);
                        }
                        var rebillContact = "";
                        if (self.rebillContact == null || !self.rebillContact.value) {
                            rebillContact = "";
                        }else{
                            rebillContact = self.rebillContact.value;
                        }
                        data.append('asset_id', self.assetId);
                        data.append('vendor_id', self.vendor.value);
                        // data.append('vendor_id', 0);
                        data.append('reference_no', self.referenceNo);
                        data.append('estimated_cost', self.estimatedCost);
                        data.append('actual_cost', self.actualCost);
                        data.append('paid_date', self.paidDate);
                        data.append('expense_category_id', self.expenseCategory.value);
                        data.append('summary', self.summary);
                        data.append('notes', self.notes);
                        data.append('rebill_contact_id', rebillContact);
                        //data.append('rebill_contact_id', 0);
                        data.append('rebill_paid_date', self.rebillPaidDate);
                        data.append('chargeback', self.chargeback);

                        self.buttonNames.save = "Submitting ...";
                        self.disableButtons.save = true;
                        HTTP.post("/assetexpenses/add/update", data, config)
                            .then(function (response) {
                                self.buttonNames.save = "Submit";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Submit";
                                self.disableButtons.save = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            addAssetExpensesInGroup(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var rebillContact = "";
                        if (self.rebillContact == null || !self.rebillContact.value) {
                            rebillContact = "";
                        }else{
                            rebillContact = self.rebillContact.value;
                        }
                        var data = new FormData();
                        data.append('asset_id', self.selectedAssets);
                        data.append('vendor_id', self.vendor.value);
                        //data.append('vendor_id', 0);
                        data.append('reference_no', self.referenceNo);
                        data.append('estimated_cost', self.estimatedCost);
                        data.append('actual_cost', self.actualCost);
                        data.append('paid_date', self.paidDate);
                        // data.append('expense_category_id', self.expenseCategory.value);
                        data.append('expense_category_id', self.expenseCategory.value);
                        data.append('summary', self.summary);
                        data.append('notes', self.notes);
                        data.append('rebill_contact_id', rebillContact);
                        //data.append('rebill_contact_id', 0);
                        data.append('rebill_paid_date', self.rebillPaidDate);
                        data.append('chargeback', self.chargeback);
                        data.append('group_option_for_update', self.updateExpenseGroupStatus);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/assetexpensesgroup/add", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.$swal({
                                        type : "success",
                                        html: response.data.alert_message,
                                    });
                                    // self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.up = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }

}
</script>
