<template>
    <div
        class="modal fade"
        id="reorderHeadersListModal"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-sm modal-dialog-scrollable">
        
        <div class="modal-content">
            
            <div class="modal-header">
                <h5 class="modal-title" id="reorderHeadersListModalLabel">
                    {{ modalTitle }}
                </h5>
            </div>
            <div class="modal-body">
                <form
                    id="reorderHeadersListModalForm"
                    enctype="multipart/form-data"
                >
                    <!-- Export Loader Start -->
                    <common-loader
                        v-if="action === 'export'"
                        refId="exportLoader"
                        ref="exportLoader"
                    >   
                    </common-loader>
                    <!-- Export Loader End -->
                    <div id="modal-checkbox-availabel-age">
                    <div class="custom-checkbox-v select-all-item">
                        <label class="container" v-if="action == 'list'">
                            <input
                            type="checkbox"
                            v-model="isSelectAllList"
                            @click="selectAllHeadersList()"
                            class="form-control"
                            id="masterHeadersList"
                        />
                        <strong v-if="isSelectAllList"><span class="inline-block pl-15">{{ allLabelMessages.deselect_all_label }}</span></strong>
                        <strong v-else><span class="inline-block pl-15">{{ allLabelMessages.select_all_label }}</span></strong>
                        <span class="checkmark"></span>
                        </label>
                        <label class="container" v-if="action == 'export'">
                            <input
                            type="checkbox"
                            v-model="isSelectAllExport"
                            @click="selectAllHeadersExport()"
                            class="form-control"
                            id="masterHeadersExport"
                        />
                        <strong v-if="isSelectAllExport"><span class="inline-block pl-15">{{ allLabelMessages.deselect_all_label }}</span></strong>
                        <strong v-else><span class="inline-block pl-15">{{ allLabelMessages.select_all_label }}</span></strong>
                        <span class="checkmark"></span>
                        </label>
                    </div>
                    <template v-if="masterHeadersList && masterHeadersList.length > 0">
                        <vue-draggable-group
                        v-model="masterHeadersList"
                        group="columns"
                        class="draggable-list"
                        >
                        <template v-for="(contactOpp, userIndex) in masterHeadersList">
                            <div class="custom-checkbox-v draggable-item" :key="userIndex">
                                <label class="container">
                                    <input
                                        :value="true"
                                        class="form-control"
                                        type="checkbox"
                                        v-model="masterHeadersList[userIndex].isSelected"
                                        autocomplete="off"
                                        @change="updateSelectedHeadersList()"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                                <span class="inline-block">{{ contactOpp.name }}</span>
                                <i class="fa fa-arrows pull-right" aria-hidden="true"></i>
                            </div>
                        </template>
                        </vue-draggable-group>
                    </template>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button v-if="action == 'list'"
                    type="button"
                    v-on:click="saveAndCloseModal($event)"
                    class="btn general-button btn-success waves-effect"
                >
                    {{ allLabelMessages.show_list_data }}
                </button>
                <button v-else-if="action == 'export'"
                    type="button"
                    id="exportButton"
                    v-on:click="exportAndCloseModal($event)"
                    class="btn general-button btn-success waves-effect"
                >
                    {{ allLabelMessages.export }}
                </button>
                <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-bs-dismiss="modal"
                >
                    {{ allLabelMessages.close }}
                </button>
            </div>

        </div>

        </div>
    </div>
</template>
  
<script>
  let self;
  import { commonMixins } from "../../mixins/commonMixins";
  import VueDraggable from 'vuedraggable';
  import CommonLoader from "@/components/partials/CommonLoader.vue";

  export default {
    name: "ReorderHeadersListModal",
    components: {
        "vue-draggable-group": VueDraggable,
        "common-loader": CommonLoader,
    },
    props: {
    },
    mixins: [commonMixins],
    data() {
      return {
        isSelectAllList: false,
        isSelectAllExport: false,
        modalTitle: "",
        action: "",
        moduleName: "",
        masterHeadersList: [],
        selectedHeadersList: [],
        reorderHeadersListModalInstance: null,

      };
    },
    mounted() {
        self = this;
        self.initializeModals();
    },
    methods: {
        initializeModals() {
            self.reorderHeadersListModalInstance = new bootstrap.Modal(
                document.getElementById("reorderHeadersListModal")
            );
        },    
        openModal(reqData) {
            self = this;
            self.modalTitle = reqData.modalTitle;
            self.action = reqData.action;
            self.moduleName = reqData.moduleName;
            self.masterHeadersList = reqData.masterHeadersList;
            self.reorderHeadersListModalInstance.show();
            self.updateSelectedHeadersList();
        },
        selectAllHeadersList: function () {
            self = this;
            self.selectedHeadersList = [];
            for (let key in self.masterHeadersList) {
                self.masterHeadersList[key].isSelected = false;
            }
            if (!self.isSelectAllList) {
                for (let key in self.masterHeadersList) {
                    self.selectedHeadersList.push(self.masterHeadersList[key]);
                    self.masterHeadersList[key].isSelected = true;
                }
            }
        },
        selectAllHeadersExport: function () {
            self = this;
            self.selectedHeadersList = [];
            for (let key in self.masterHeadersList) {
                self.masterHeadersList[key].isSelected = false;
            }
            if (!self.isSelectAllExport) {
                for (let key in self.masterHeadersList) {
                    self.selectedHeadersList.push(self.masterHeadersList[key]);
                    self.masterHeadersList[key].isSelected = true;
                }
            }
        },
        updateSelectedHeadersList: function () {
            self = this;
            // Reorder selectedHeadersList
            self.selectedHeadersList = [];
            self.masterHeadersList.forEach(header => {
                if(header.isSelected){
                    self.selectedHeadersList.push(header);
                }
            });
            if (
                self.selectedHeadersList.length == self.masterHeadersList.length
            ) {
                if(self.action == 'list'){
                    self.isSelectAllList = true;
                }else if(self.action == 'export'){
                    self.isSelectAllExport = true;
                }
            } else {
                if(self.action == 'list'){
                    self.isSelectAllList = false;
                }else if(self.action == 'export'){
                    self.isSelectAllExport = false;
                }
            }
        },
        saveAndCloseModal: function () {
            self = this;

            self.selectedHeadersList = [];
            self.masterHeadersList.forEach(header => {
                if(header.isSelected){
                    self.selectedHeadersList.push(header);
                }
            });
            if (self.selectedHeadersList.length == 0) {
                self.showSAlert("Please Select at least one checkbox", "info", false);
                return false;
            }
            if (
                self.selectedHeadersList != null &&
                self.selectedHeadersList != "undefined" &&
                self.selectedHeadersList.length > 0
            ) {
                if(self.moduleName != null && self.moduleName != ""){
                    self.storeListHeaders(self.moduleName, self.selectedHeadersList);
                }
            } else {
                self.selectedHeadersList = [];
            }

            self.reorderHeadersListModalInstance.hide();
            
            self.$emit('call-reload-list');
        },
        exportAndCloseModal: function () {
            self = this;

            self.selectedHeadersList = [];
            self.masterHeadersList.forEach(header => {
                if(header.isSelected){
                    self.selectedHeadersList.push(header);
                }
            });
            if (self.selectedHeadersList.length == 0) {
                self.showSAlert("Please Select at least one checkbox", "info", false);
                return false;
            }
            if (
                self.selectedHeadersList != null &&
                self.selectedHeadersList != "undefined" &&
                self.selectedHeadersList.length > 0
            ) {
                if(self.moduleName != null && self.moduleName != "" ){
                    self.storeExportHeaders(self.moduleName, self.selectedHeadersList);
                }
            } else {
                self.selectedHeadersList = [];
            }
            self.$emit('call-is-export');
            self.$emit('call-reload-list');
        },
        exportLoaderShow: function () {
            $("#exportLoader")
                .find(".loading-container.lodingTable")
                .show();
            $("#exportButton").prop("disabled", true);
        },
        exportLoaderHide: function () {
            $("#exportLoader")
                .find(".loading-container.lodingTable")
                .hide();
            $("#exportButton").prop("disabled", false);
        },
        closeModal: function () {
            self.reorderHeadersListModalInstance.hide();
        },
        
    },
    watch: {

    },
  };
  
</script>
  