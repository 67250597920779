<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="permissionNameArray.includes('can_list_branch_location_report')"
    >
      <div class="container-fluid xyz branch-location-report-list-view">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.branch_location_report_label }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
            <a
              id="exportBranchLocationId"
              title="Export"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Export Branch Location Report', //Modal Title
                'export', //Action
                'exportBranchLocationReport', //Module Name 
                exportHeaders //Master Headers List
              )"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>

            <button
              id="branchLocationHeaderList"
              title="Branch Location Report Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Select Branch Location Header List', //Modal Title
                'list', //Action
                'listBranchLocationReport', //Module Name 
                branchLocationHeadersList //Master Headers List
              )"
            >
              {{ allLabelMessages.branch_location_header_list }}
            </button>
          </div>

          <!-- Export file status -->
          <div class="col-12" v-if="batchProcess">
            <div class="alert alert-success">
              Export file is in process. batch no. is {{batchNum}}. <router-link class="color-blue" v-bind:to="{name: 'ListBatch'}" target="_blank" @click.native="batchProcess = 0"> Click Here</router-link> to check file status!
            </div>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent" 
            @resetSorting="resetSorting"
          ></filter-component>

          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListBranchLocationReport"
              ref="commonLoaderListBranchLocationReport"
            ></common-loader>
            <!--Pagination Start-->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!--Pagination End-->

            <div class="table-listing white-bg border-blue border-radius-top-right">
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="branchLocationArr && branchLocationArr.length > 0">
                    <tr v-for="(row, index) in branchLocationArr" :key="index">
                      <template v-for="headerData in headers">
                        <td
                          v-if="headerData.column_name in row"
                          :class="headerData.column_class"
                          :key="headerData.column_name"
                        >
                          {{ row[headerData.column_name] }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <!-- <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template> -->
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
        <!-- Export and List Headers Modal -->
        <reorder-headers-list-modal 
          @call-is-export="exportBranchLocationData = true"
          @call-reload-list="listBranchLocationReport"
          ref="reorderHeadersListModalRef"
        >
        </reorder-headers-list-modal>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
let self;
import Vue from "vue";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";
import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";

export default {
  name: "BranchLocationReport",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
    "app-footer": Footer,
    "reorder-headers-list-modal": ReorderHeadersListModal,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      permissionNameArray: ["can_list_branch_location_report"],
      batchLocationReporDataArr: [],
      headers: [],
      filterheaders: [],
      filters: {},
      appliedHeaders: [],
      exportHeaders: [],
      exportSelectedHeaders: [],
      selectedBranchLocationHeadersList: [],
      exportBranchLocationData: false,
      branchLocationArr: [],
      branchLocationDataArr: [],
      branchLocationHeadersList: [],
      isSelectAllBranchLocationHeaderList: false,
      isSelectAllExportHeader: false,
      startEndRecord: "",
      jumpToPage: 1,
      columnName: 'id',
      sortType: 'asc',
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportAction: '',
      batchProcess: 0,
      batchNum: "",
    };
  },
  beforeMount() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted() {
    self = this;
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("branch-location-report-list");
    EventBus.$emit("menu-selection-changed", "branch-location-report-list");

    const listHistoryData = self.loadFilters("listBranchLocationReport", self);
    self.jumpToHistoryPage = listHistoryData === null || typeof listHistoryData === "undefined" || listHistoryData == "" ? 1 : listHistoryData.page;
    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.listBranchLocationReport(1);
  },
  methods: {
    resetSorting() {
      self.columnName = 'id';
      self.sortType = 'asc';
      self.changepage();
    },
    changePerPage() {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.listBranchLocationReport();
    },
    changepage() {
      if (!self.listExecutionState) {
        self.listBranchLocationReport();
      }
    },
    refreshList() {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.listBranchLocationReport();
    },
    keyMonitor() {
      let tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.listBranchLocationReport();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
      }
    },
    listBranchLocationReport(first) {
      self.listExecutionState = true;
      if (
          self.perPage == "All" &&
          self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
        ) {
          self.showSAlert(
            self.allStaticMessages.too_many_data_for_all_list,
            "info",
            false
          );
          return false;
        }

        first = first === null || typeof first === "undefined" || first == "" ? 0 : first;

        self.exportSelectedHeaders = self.loadExportHeaders("exportBranchLocationReport", self);
        self.exportSelectedHeaders =
          self.exportSelectedHeaders === null ||
          typeof self.exportSelectedHeaders === "undefined" ||
          self.exportSelectedHeaders == ""
            ? []
            : self.exportSelectedHeaders;
        self.selectedBranchLocationHeadersList = self.loadListHeaders("listBranchLocationReport", self);
        self.selectedBranchLocationHeadersList =
          self.selectedBranchLocationHeadersList === null ||
          typeof self.selectedBranchLocationHeadersList === "undefined" ||
          self.selectedBranchLocationHeadersList == ""
            ? []
            : self.selectedBranchLocationHeadersList;

        if (self.exportBranchLocationData == true) {
          self.exportRecords();
        }

        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const fields = {
          filters: self.filters,
          per_page: self.perPage,
          page: self.currentPage,
          sortColumnName: self.columnName,
          sortType: self.sortType,
          is_export: self.exportBranchLocationData,
          export_action: self.exportAction,
          export_selected_headers: self.exportSelectedHeaders,
          selected_branch_location_header: self.selectedBranchLocationHeadersList
        };
        this.storeFilters("listBranchLocationReport", fields, false);
        if (self.exportBranchLocationData) {
          self.$refs.reorderHeadersListModalRef.exportLoaderShow();
        } else {
          $("#commonLoaderListBranchLocationReport").find(".loading-container.lodingTable").show();
        }
        self.filterCount = self.filterCountFunction(self.filters);
        HTTP.post("/report/branch-location", fields, config)
          .then(function (data) {
            self.listExecutionState = false;
            if (first) {
              self.currentPage = self.jumpToHistoryPage;
            }
            if (data.status == 200) {
              if (data.data.message == "success export queue") {
                self.exportAction = '';
                self.exportBranchLocationData = false;
                self.$refs.reorderHeadersListModalRef.exportLoaderHide();
                self.$refs.reorderHeadersListModalRef.closeModal();
                self.listBranchLocationReport();
                self.batchProcess = 1;
                self.batchNum = data.data.content;
              } else if (data.data.message == "success export") {
                self.exportAction = '';
                self.exportBranchLocationData = false;
                self.$refs.reorderHeadersListModalRef.exportLoaderHide();
                self.$refs.reorderHeadersListModalRef.closeModal();
                self.downloadFile(data.data.content.web);
                self.listBranchLocationReport();
              } else {
                $("#commonLoaderListBranchLocationReport").find(".loading-container.lodingTable").hide();
                self.headers = [];
                self.totalRecords = data.data.content.result;
                self.branchLocationArr = data.data.content.data;
                self.headers = data.data.content.headers;
                self.filterheaders = data.data.content.filterheaders;

                self.branchLocationHeadersList = data.data.content.branchLocationHeadersList;
                if (
                  self.selectedBranchLocationHeadersList.length ==
                  self.branchLocationHeadersList.length
                ) {
                  self.isSelectAllBranchLocationHeaderList = true;
                }

                self.exportHeaders = data.data.content.exportheaders;
                if (
                  self.exportSelectedHeaders.length == self.exportHeaders.length
                ) {
                  self.isSelectAllExportHeader = true;
                }

                self.loadFilterInputs(data.data.content.filterheaders, self.filters);

                self.addColumnClass(self.headers);

                if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
              }
            }
            EventBus.$emit("is-open-filter-component", true);
          })
          .catch(function (error) {
            self.listExecutionState = false;
            if (self.exportBranchLocationData) {
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
            } else {
              $("#commonLoaderListBranchLocationReport")
                .find(".loading-container.lodingTable")
                .hide();
            }
            self.catchHandler(error, function () {});
            EventBus.$emit("is-open-filter-component", true);
          });
    },
    sortTableData: function (name, isSortAllowed) {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.listBranchLocationReport();
    }
  }
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>

<style scoped>
  .page_title a.btn {
    background: #007bff;
  }
  .branch-location-report-list-view .vgt-wrap__footer.crm {
    padding: 12px;
  }
  .branch-location-report-list-view .vgt-wrap__footer.crm .pagination {
    margin-bottom: 0;
  }
</style>
