<template>
    <div class="modal fade" id="vehiclePurchaseItemModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Vehicle Purchase Item</h2>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <table class="table custom-table jsmartable">
                <tr>
                  <td id="item-type">Item Type:</td>
                  <td colspan="3">
                    <v-select
                        v-bind="moneyMask"
                        v-model="item"
                        name="item"
                        label="itm_typ_name"
                        :filterable="false"
                        :options="itemList"
                        :clearable="true"
                        class="custom-ci-auto-search"
                    >
                    </v-select>
                  </td>
                </tr>
                <tr>
                  <td id="item-description">Description:</td>
                  <td colspan="3"><textarea id="" cols="60" rows="4" v-model="description"></textarea></td>
                </tr>
                <tr>
                  <td id="item-cost-price">Cost Price:</td>
                  <td colspan="3"><money v-bind="moneyMask" v-model="costPrice" type="text" class="form-control" style="width: 200px" @keyup.native="event => {incrementDecrementNumber(event, 'costPrice')}" /></td>
                </tr>
                <tr>
                  <td id="item-sales-price">Sales Price:</td>
                  <td colspan="3"><money v-bind="moneyMask" v-model="salesPrice" type="text" class="form-control" style="width: 200px" @keyup.native="event => {incrementDecrementNumber(event, 'salesPrice')}" /></td>
                </tr>
                <tr>
                  <td><input type="checkbox" v-model="addToTotalPrice"> <span>Add to Total Price</span></td>
                  <td><input type="checkbox" v-model="showOnDocuments"> <span>Show On Documents</span></td>
                  <td><input type="checkbox" v-model="fetExempt"> <span>FET Exempt</span></td>
                  <td><input type="checkbox" v-model="doNotAddToGp"> <span>Do Not Add To GP</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="saveItem()">Save</button>
            <button class="btn btn-secondary" @click="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import eventBus from '../../../event-bus';
  import {Money} from 'v-money';
  import { HTTP } from '../../../../http-common';

  export default {
    name: "VehiclePurchaseItem",
    components: {
      Money
    },
    props: ['isAddItem','editPOItem'],
    data() {
      return {
        item: '',
        itemList: [],
        costPrice: 0.00,
        salesPrice: 0.00,
        addToTotalPrice: true,
        showOnDocuments: true,
        fetExempt: false,
        doNotAddToGp: false,
        moneyMask: {
          thousands: ',',
          prefix: '$ ',
          precision: 2,
        },
        description: '',
        isEditItem: false,
      };
    },
    methods: {
      closeModal() {
        eventBus.$emit("close-purchase-item", false);
      },
      incrementDecrementNumber(event, dataInstance) {
        switch (event.key) {
          case 'ArrowUp':
            this[dataInstance] += 1;
            break;
          case 'ArrowDown':
            this[dataInstance] -= 1;
            break;
        }
      },
      saveItem() {
        var data = {
          itm_typ_id: this.item.itm_typ_id,
          description: this.description,
          cost: this.costPrice,
          price: this.salesPrice,
          is_add_to_total_price: this.addToTotalPrice,
          is_show_on_documents: this.showOnDocuments,
          is_fet_exempt: this.fetExempt,
          is_do_not_add_to_gp: this.doNotAddToGp
        }
        var isUpdate = false;
        if(this.isEditItem) {
          const id = this.editPOItem.id;
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            },
          };
          HTTP.put("purchase-order-item/"+id, data, config)
          .then( response => {
            data = response.data.content;
            isUpdate = true;
            eventBus.$emit("save-purchase-item", data, isUpdate);
          })
          .catch(function (err) {
            console.log('error=>', err);
          });
        } else {
          eventBus.$emit("save-purchase-item", data, isUpdate);
        }
        
        this.clearData();
      },
      clearData() {
        this.item = '';
        this.costPrice = 0.00;
        this.salesPrice = 0.00;
        this.addToTotalPrice = true;
        this.showOnDocuments = true;
        this.fetExempt = false;
        this.doNotAddToGp = false;
        this.description = '';
        return true;
      },
      getEditItemData() {
        const item = this.itemList.filter(itm => itm.itm_typ_id == this.editPOItem.itm_typ_id);
        this.item = item[0];
        this.costPrice = this.editPOItem.cost;
        this.salesPrice = this.editPOItem.price;
        this.addToTotalPrice = this.editPOItem.is_add_to_total_price;
        this.showOnDocuments = this.editPOItem.is_show_on_documents;
        this.fetExempt = this.editPOItem.is_fet_exempt;
        this.doNotAddToGp = this.editPOItem.is_do_not_add_to_gp;
        this.description = this.editPOItem.description;
      }
    },
    computed: {
      getItems() {
        var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
        };

        HTTP.get("/get/vehicle/purchase/items", config)
        .then(response => {
           this.itemList = response.data.content;
        });
      }
    },
    watch: {
      isAddItem(status) {
        if(status) {
          this.clearData();
          this.getItems;
          if(Object.keys(this.editPOItem).length > 0) {
                this.isEditItem = true;
                setTimeout(() => {
                    this.getEditItemData();
                }, 1000);
            }
        }
      }
    }
  };
</script>
  