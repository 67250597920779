<template>
  <div
    class="modal"
    id="calculateFlooring"
    tabindex="-1"
    role="dialog"
    aria-labelledby="calculateFlooringLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header flooring-modal-row">
          <div id="flooring-modal-header-title">
            Calculate Flooring
          </div>
          <div>
            <button id="flooring-close-btn" data-bs-dismiss="modal" @click="closeFlooringModal()">Close</button>
            <button
                type="button"
                id="flooring-save-btn"
                @click="calculateFlooring('save')"
              ><span>Save & Close</span></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="flooring-row">
            <div class="flooring-column">
              <div class="flooring-fm-group">
                <label for="start">Start</label>
                <date-picker
                  v-model="startDate"
                  :config="options"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Select Start Date"
                  name="startDate"
                  @input="getDays()"
                />
              </div>
              <div>
                <label for="rate">Rate</label>
                <money
                  v-bind="rateMask"
                  type="text"
                  class="flooring-input"
                  v-model="rate"
                  @blur="calculateFlooring()"
                  @keyup.native="
                    (event) => {
                      incrementDecrementNumber(event, 'rate');
                    }
                  "
                />
              </div>
            </div>
          </div>

          <div class="flooring-row">
            <div class="flooring-column">
              <div class="flooring-fm-group">
                <label for="end">End</label>
                <date-picker
                  v-model="endDate"
                  :config="options"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Select End Date"
                  name="endDate"
                  @input="getDays()"
                />
              </div>
              <div>
                <label for="flooring">Flooring</label>
                <money
                  v-bind="moneyMask"
                  type="text"
                  class="flooring-input"
                  v-model="flooring"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="flooring-row">
            <div class="flooring-column">
              <div class="flooring-fm-group">
                <label for="days">Days</label>
                <input
                  type="text"
                  class="flooring-input"
                  v-model="days"
                  disabled
                />
              </div>
              <div class="flooring-fm-group" v-if="isVisibleDDC && typeId.text.toLowerCase() == 'new' && deliveryDelayCredit > 0">
                <label for="delivary-delay-credit">Delivery Delay Credit</label>
                <money
                  v-bind="moneyMask"
                  type="text"
                  class="flooring-input"
                  v-model="ddc"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="flooring-row">
            <div>
              <button
                type="button"
                class="flooring-btn-cal"
                @click="calculateFlooring()"
              >
                <span>Calculate</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import datePicker from "vue-bootstrap-datetimepicker";
import eventBus from "../../../event-bus";
import { commonMixins } from "../../../../mixins/commonMixins";
import moment from "moment";

export default {
  name: "FlooringCalModal",
  props: ["flooringTypeValue", "isClickCalFlooring", "isVisibleDDC", "deliveryDelayCredit", "typeId", "flooringStartDate", "flooringEndDate", "flooringRate", "storedFlooring"],
  components: {
    Money,
    datePicker,
  },
  mixins: [commonMixins],
  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      rate: 6.5,
      days: 1,
      flooring: 0,
      initFlooring: 0,
      ddc: 0,
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: true,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
        // minDate: moment().millisecond(0).second(0).minute(0).hour(0),
      },
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        precision: 2,
      },
      rateMask: {
        decimal: ".",
        suffix: " %",
        precision: 2,
      },
    };
  },
  mounted() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.rate = 6.5;
    this.days = 1;
    this.flooring = 0;
  },
  methods: {
    calculateFlooring(type = null) {
      if (this.startDate != "" && this.endDate != "") {
        let date_start = new Date(this.startDate);
        let date_end = new Date(this.endDate);

        if (date_start <= date_end) {
          this.days = this.daysBetween(date_start, date_end) + 1;

          if (this.rate != "") {
            let cost = this.flooringTypeValue;
            let rate = this.rate / 100;
            let flooring = ((cost * rate) / 360) * this.days;
            flooring = Math.max(0, flooring);
            this.flooring = Math.round(flooring * 100) / 100;
          }
          this.initFlooring = this.flooring;
          if(this.isVisibleDDC && this.ddc != 0 && this.flooring != 0) {
            this.flooring = Math.max(0, this.flooring + this.ddc);
          }
        } else {
          this.showSAlert(
            "Start Date should be equal to or greater than End Date",
            "error",
            false,
            2
          );
          this.flooring = 0;
          this.initFlooring = 0;
        }
      }

      if (type == "save") {
        let flooring = {
          flooring: this.flooring,
          startDate: this.startDate,
          endDate: this.endDate,
          rate: this.rate,
          initFlooring: this.initFlooring
        };
        eventBus.$emit("flooring", flooring);
        this.clearData();
      }
    },
    daysBetween(date1, date2) {
      // The number of milliseconds in one day
      let ONE_DAY = 1000 * 60 * 60 * 24;

      // Convert both dates to milliseconds
      let date1_ms = date1.getTime();
      let date2_ms = date2.getTime();

      // Calculate the difference in milliseconds
      let difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.round(difference_ms / ONE_DAY);
    },
    incrementDecrementNumber(event, dataInstance) {
      switch (event.key) {
        case "ArrowUp":
          this[dataInstance] += 1;
          break;
        case "ArrowDown":
          this[dataInstance] -= 1;
          break;
      }
    },
    closeFlooringModal() {
      eventBus.$emit("flooring", null);
      this.clearData();
    },
    getDays() {
      /**
       * @input is triggering the function on each change; need to refactor
       */
      if(this.startDate > this.endDate) {
        alert('Start Date Is Greater Than End Date!!');
        this.startDate = new Date();
        this.endDate = new Date();
      } else {
        let date_start = new Date(this.startDate);
        let date_end = new Date(this.endDate);
        this.days = this.daysBetween(date_start, date_end) + 1;
      }
      return true;
    },
    clearData() {
      this.startDate = new Date();
      this.endDate = new Date();
      this.rate = 6.5;
      this.days = 1;
      this.flooring = 0;
    }
  },
  watch: {
    isClickCalFlooring:{
      handler(status){
        this.ddc = -this.deliveryDelayCredit;
        this.startDate = this.flooringStartDate;
        this.endDate = this.flooringEndDate;
        this.rate = this.flooringRate;
        this.flooring = this.storedFlooring;
        this.getDays();
        /*#### NOT REQUIRED ####
        if(this.storedFlooring > 0) {
          this.calculateFlooring();
        }
        */
      },
      immediate:false,
    },
  }
};
</script>

<style :scoped>
.flooring-row {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 5px;
}

.flooring-column {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-column-gap: 60px;
}

.flooring-fm-group {
  display: flex;
  flex-direction: column;
}

.flooring-row:last-child > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 20px;
  padding-top: 10px;
}

#flooring-save-btn {
  display: flex;
  height: 36px;
  padding: 6px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #076eb0;
  border: none;
}

#flooring-save-btn > span {
  display: flex;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: #fff;
  text-align: center;
  font-family: SF UI Display, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}

.flooring-btn-cal {
    display: flex;
    height: 36px;
    padding: 6px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #dbe0e7;
}

.flooring-btn-cal > span {
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: #263238;
  text-align: center;
  font-family: SF UI Display, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}

.flooring-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

.flooring-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

#flooring-modal-header-title {
  color: #506b71;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

#flooring-close-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #dbe0e7;
}
</style>
