<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_cso_request')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>CSO Request</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <router-link v-show="permissionNameArray.includes('can_add_cso_request')" class="btn btn-primary waves-effect pull-right ml-2" title='Add CSO Request' v-bind:to="{name: 'AddUpdateCSORequestSubmission'}">
                            <span>Add CSO Request</span>
                        </router-link>
                        <a
                                id='refreshList'
                                title='Refresh'
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>

                        <button v-show="permissionNameArray.includes('can_export_cso_request')" type="button" id='exportCSORequestData' 
                            title='Export' class="btn btn-primary waves-effect pull-right" v-on:click="exportCSORequestData($event)"
                        >
                            {{ staticInputLabelMessages.export }}
                        </button>
                    </div>
                    <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent"  @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button type="button" v-on:click.prevent="deleteCsoRequest()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="frameArr && frameArr.length > 0" >
                                    <template v-for="(frame, index) in frameArr">
                                        <tr :class="'mainDetails'">
                                            <td>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="frame.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + frame.id" name="case[]">
                                                        <label class="custom-control-label" :for="'customCheck' + frame.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions">
                                                    <div class="actions">
                                                        <!-- <a title='Approved' v-if="frame.status == 'Approve'" href="javascript:void(0)" class="mar-right-0"> 
                                                            <i  class="exceptEdit fa fa-check-circle color-71aa3f" aria-hidden="true"></i>
                                                        </a> -->
                                                        <router-link v-show="permissionNameArray.includes('can_add_cso_request')" class="mar-right-0" title='Edit CSO Request' v-bind:to="{name: 'UpdateCSORequest', params:{id: frame.id}}">
                                                            <i aria-hidden="true" :class="'fa fa-angle-right forward_icon'"></i>
                                                        </router-link>
                                                        <a v-show="permissionNameArray.includes('can_delete_cso_request')" href="javascript:void(0)" title='Delete CSO Request' class="mar-right-0" v-on:click="deleteCsoRequest(frame.id)">
                                                            <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td :class="rowClass.salesperson_name">{{frame.salesperson_name}}</td>
                                            <td :class="rowClass.receiving_dealership">{{frame.receiving_dealership | capitalize}}</td>
                                            <td :class="rowClass.ticket_number">{{frame.ticket_number}}</td>
                                            <td :class="rowClass.sales_admin">{{frame.sales_admin | capitalize}}</td>
                                            <td :class="rowClass.final_status">{{frame.final_status | capitalize}}</td>
                                            <td :class="rowClass.customer_id">{{frame.customer_id}}</td>
                                            <td :class="rowClass.units_volume">{{frame.units_volume}}</td>
                                            <td :class="rowClass.make_and_model">{{frame.make_and_model}}</td>
                                            <td :class="rowClass.request_type">{{frame.request_type | capitalize}}</td>
                                            <td :class="rowClass.unit_id" :id="'unitId' + frame.id">{{frame.unit_id | truncate}}</td>
                                            <td :class="rowClass.requested_at">{{frame.requested_at}}</td>
                                            <td :class="rowClass.updated_by">{{frame.updated_by}}</td>
                                            <td :class="rowClass.updated_at">{{frame.updated_at}}</td>
                                        </tr>
                                        <b-popover :target="'unitId' + frame.id" triggers="hover" placement="top" v-if="frame.unit_id">
                                            <pre>{{frame.unit_id.split(',').join('\n')}}</pre>
                                        </b-popover>
                                        <tr class="extraDetails">
                                            <td colspan="2">

                                            </td>
                                            <td :colspan="headers.length - 1" class="text-alignment-left">
                                                <b-collapse :id="'accordion-' + frame.id" class="mt-2">
                                                    <b-card>
                                                        <table>
                                                            <thead>
                                                            <tr>
                                                                <template v-for="(headerData, headerIndex) in returnAccordianDisplayDataKeys()">
                                                                    <template v-if="headerIndex == 0">
                                                                        <th class="text-left column-header">{{headerData.label}}</th>
                                                                    </template>
                                                                    <template v-else>
                                                                        <th class="text-right column-header">{{headerData.label}}</th>
                                                                    </template>
                                                                </template>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <template v-for="(data, dataIndex) in frame.acordian_data">
                                                                <tr>
                                                                    <td class=text-left>{{data.vin}}</td>
                                                                    <td class="text-right">{{data.request_amount}}</td>
                                                                </tr>
                                                            </template>
                                                            </tbody>
                                                        </table>
                                                    </b-card>
                                                </b-collapse>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
                <!-- Export Modal -->
                <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="exportModalLabel">Export CSO Request</h4>
                            </div>
                            <form class="form-inline" id="exportModalForm" enctype="multipart/form-data">
                                <div class="col-md-12 modal-body" id="modal-checkbox">
                                    <template v-if="exportHeaders && exportHeaders.length > 0">
                                        <!-- Select All -->
                                        <div class="col-12 paddingleft0 custom-checkbox-v">
                                            <label class="container">
                                                <input type="checkbox" v-model="isSelectAllExportHeader" @click="selectAllExportHeaders()" class="form-control" id="ExportList">
                                                <strong v-if="isSelectAllExportHeader"><p class="modal-check-lbl">{{staticInputLabelMessages.deselect_all_label}}</p></strong>
                                                <strong v-else><p class="modal-check-lbl">{{staticInputLabelMessages.select_all_label}}</p></strong>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <template v-for="(exportOpp, exportIndex) in exportHeaders">
                                            <div :key="exportIndex" class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v">
                                                <label class="container">
                                                    <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                                                    <input :value="exportOpp" class="form-control" type="checkbox" v-model="exportSelectedHeaders"  @change="updateCheckedExportHeaders()" /> <p class="modal-check-lbl">{{exportOpp['name']}}</p>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                                <div class="row clearfix"></div>
                                <div class="col-md-12 modal-footer">
                                    <button type="button" v-on:click="exportSelectedHeadersData($event);" class="btn general-button btn-success waves-effect">Export</button>
                                    <button type="button" class="btn general-button btn-danger waves-effect" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListCSORequest',
        mixins: [commonMixins],
        components: {
            'filter-component': FilterComponent,
            'no-access': NoAccessPage,
        },
        data() {
            return {
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                frameArr: [],
                headers: [],
                filters: {
                },
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: "",
                selectAll: false,
                permissionNameArray: ['can_list_cso_request'],
                actionDisplay: true,
                exportCSORequest: false,
                accordianIds: [],
                jumpToHistoryPage : 1,
                filterCount : 0,
                exportHeaders: [],
                exportSelectedHeaders: [],
                isSelectAllExportHeader: false,
                staticMessages: "",
                staticInputLabelMessages: ""
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadCsoRequestData();
            },
            currentPage: function () {
                self.loadCsoRequestData();
            }*/
            permissionNameArray : function() {
                if (!self.permissionNameArray.includes('can_list_cso_request')) {
                    // self.$router.push({name: "AddUpdatePORequestSubmission"});
                }
            }
        },
        created: function () {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;

            self.setActiveMenu('cso-request');
            EventBus.$emit("menu-selection-changed", "cso-request");
            var listHistoryData = self.loadFilters('listCSORequest', self);
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            self.loadCsoRequestData(1);
            self.getLoginIdPermissions();
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            returnAccordianDisplayDataKeys : function() {
                let dataArray = [
                    {
                        field : "vin",
                        label : "Vin",
                        alt : ""
                    },
                    {
                        field : "requested_amount",
                        label : "Requested Amount",
                        alt : ""
                    },

                ];

                return dataArray;
            },
            toggleIcon : function(id) {
                self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadCsoRequestData();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadCsoRequestData();
                }
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadCsoRequestData();
            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.frameArr) {
                            self.selectedRows.push(self.frameArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.frameArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            applyFilterParentComponent: function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadCsoRequestData();
            },
            setAlreadyAppliedFilters: function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadCsoRequestData: function (first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters: self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    is_export: self.exportCSORequest,
                    export_selected_headers: self.exportSelectedHeaders
                };
                this.storeFilters('listCSORequest', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);
                HTTP.post("/csorequest/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            if(first){
                                self.currentPage = self.jumpToHistoryPage;
                            }
                            self.hideLoader();
                            /* self.exportSelectedHeaders = []; */
                            self.exportSelectedHeaders = self.loadExportHeaders('exportCSORequest', self);
                            self.exportSelectedHeaders = (self.exportSelectedHeaders === null || typeof (self.exportSelectedHeaders) === "undefined" || self.exportSelectedHeaders == '') ? [] : self.exportSelectedHeaders;
                            $('#exportModal').modal('hide');
                            $('#exportCSORequestData').prop("disabled", false);
                            self.exportCSORequest = false;
                            if (data.status == 200) {
                                if (data.data.message == 'success export') {
                                    self.downloadFile(data.data.content.web);
                                    // location.href = data.data.content.web;
                                } else {
                                    self.clearSelection();
                                    self.headers = [];
                                    self.totalRecords = data.data.content.result;
                                    self.frameArr = data.data.content.data;
                                    $("#selectAllCheckBox").prop("checked", false);
                                    self.headers = data.data.content.headers;
                                    self.exportHeaders = data.data.content.exportheaders;
                                    if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
                                        self.isSelectAllExportHeader = true;
                                    }
                                    self.loadFilterInputs(data.data.content.headers,self.filters);

                                    self.addColumnClass(self.headers);

                                    let testArray = [
                                        {
                                            'text': 1,
                                            'value': '2020-02-01'
                                        },
                                        {
                                            'text': 2,
                                            'value': '2020-02-02'
                                        },
                                        {
                                            'text': 3,
                                            'value': '2020-02-03'
                                        }
                                    ]
                                    for (let i = 0; i < self.headers.length; i++) {
                                        if (self.headers[i]['type'] == 'drop_down') {
                                            self.headers[i]['options_array'] = testArray;
                                        }
                                    }
                                    if (data.data.content.per_page !== self.totalRecords) {
                                        self.perPage = parseInt(data.data.content.per_page);
                                        self.jumpToPage = parseInt(data.data.content.current_page);
                                    } else {
                                        if(self.perPage == 'All'){
                                            self.jumpToPage = 1;
                                        }
                                        self.perPageValue = self.totalRecords;
                                        self.currentPage = 1;
                                    }
                                    self.setAlreadyAppliedFilters();
                                    self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                    self.sortFieldDisplay(self.columnName, self.sortType);
                                }
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            $('#exportCSORequestData').prop("disabled", false);
                            self.exportCSORequest = false;
                            self.hideLoader();
                            self.catchHandler(err, function () {});
                        })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadCsoRequestData();
                }
            },
            selectAllExportHeaders: function () {
                self.exportSelectedHeaders = [];
                if (!self.isSelectAllExportHeader) {
                    for (let key in self.exportHeaders) {
                        self.exportSelectedHeaders.push(self.exportHeaders[key]);
                    }
                }
                /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
            },
            updateCheckedExportHeaders: function() {
                /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
                console.log("Total export header : " + self.exportHeaders.length); */
                if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
                    self.isSelectAllExportHeader = true;
                } else {
                    self.isSelectAllExportHeader = false;
                }
            },
            /* checkedExportHeaders: function (e, index, value) {
                if (e.target.checked) {
                    // Vue.set(self.exportSelectedHeaders, index, value);
                    self.exportSelectedHeaders.push(Vue.util.extend({}, value));
                } else {
                    // Vue.delete(self.exportSelectedHeaders, index);
                    for (var i = 0; i < self.exportSelectedHeaders.length; i++) {
                        if (self.exportSelectedHeaders[i]['name'] == value['name']) {
                            self.exportSelectedHeaders.splice(i, 1);
                        }
                    }
                }
                // console.log(JSON.stringify(self.exportSelectedHeaders));
            }, */
            exportCSORequestData: function () {
                $('#exportModal').modal('show');
            },
            exportSelectedHeadersData: function() {
                // if(Object.keys(self.exportSelectedHeaders).length == 0) {
                if(self.exportSelectedHeaders.length == 0) {
                    self.showSAlert("Please Select at least one checkbox", 'info', false);
                    return false;
                }
                /* $('#modal-checkbox input:checkbox').prop('checked', false); */
                self.exportCSORequest = true;
                if (self.exportCSORequest == true) {
                    $('#exportCSORequestData').prop("disabled", true);
                    self.loadCsoRequestData();
                    /* self.isSelectAllExportHeader = false; */
                }
                if(self.exportSelectedHeaders != null && self.exportSelectedHeaders !='undefined' && self.exportSelectedHeaders.length > 0){
                    self.storeExportHeaders('exportCSORequest', self.exportSelectedHeaders);
                } else {
                    self.exportSelectedHeaders = [];
                }
            },
            deleteCsoRequest: function(id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if(id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this request(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data : {
                                deleteIdArr: deleteArray
                            }
                        };
                        HTTP.delete("csorequest/delete", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.loadCsoRequestData();
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_add_cso_request')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });
</script>