<template>
  <div class="spaced-container">
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <ul class="nav nav-tabs">
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_add_user')
                  "
                >
                  <a
                    v-on:click="activetab = 1"
                    v-bind:class="
                      'nav-link ' + [activetab === 1 ? 'active' : '']
                    "
                    >Edit User
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_list_location_contact_location_master')
                  "
                >
                  <a
                    v-on:click="activetab = 2"
                    v-bind:class="{ 'nav-link': true, active: activetab === 2 }"
                    >Roles and Responsibility
                  </a>
                </li>
              </ul>

              <div
                v-if="activetab === 1"
                class="tabcontent"
                id="location-activity-logs1"
              >
                  <add-update-user ref="userAddUpdateForm" @setPageTitleForEditUser="setPageTitleForEditUser"></add-update-user>
              </div>
              <div
                v-if="activetab === 2"
                class="tabcontent"
                id="location-activity-logs4"
              >
                  <user-roles-for-location></user-roles-for-location>
              </div>
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
      <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
  </div>
</template>
  
<script>
  var self;
  import Vue from "vue";
  import Footer from "@/components/partials/Footer";
  import { commonMixins } from "../../../mixins/commonMixins";
  import EventBus from "../.././event-bus";
  import AddUpdateUser from "@/components/UserManagement/AddUpdateUser";
  import UserRolesForLocation from "@/components/UserManagement/UserMaster/UserRolesForLocation.vue";
  
  Vue.component("app-footer", Footer);
  
  export default {
    name: "UserDataTab",
    components: {
      "add-update-user": AddUpdateUser,
      'user-roles-for-location': UserRolesForLocation
    },
  
    mixins: [commonMixins],
    data() {
      return {
        activetab: 1,
        pageTitle: 'Edit User',
        permissionNameArray: [],
      };
    },
    beforeMount() {
      self = this;
    },
    created: function () {
        self = this;
        self.getLoginIdPermissions();
    },
    mounted: function() {
      /* Redirect to login page logic start */
      var userObj = localStorage.getItem("user_obj");
      if (userObj == null) {
        self.$router.push("/");
      };
    },
    methods: {
      setPageTitleForEditUser(userName) {
        self.pageTitle = 'User : ' + userName ;
      },
      buttonBack() {
        self.$router.push({ name: "ListUser" });
      },
    },
    watch: {
      "$route.query.tab": {
        immediate: true,
        handler(newValue) {
          if (!newValue || newValue === self.activetab) {
            return;
          }
          self.activetab = newValue;
          
        }
      }
    }
  };
  EventBus.$on("login-permissions", function(permissions) {
    if (typeof self != "undefined") {
      self.permissionNameArray = [];
      for (let i = 0; i < permissions.length; i++) {
        self.permissionNameArray.push(permissions[i].permission_name);
      }
    }
  });
</script>
  