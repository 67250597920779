<template>
  <div class="add-teams-popoup-form admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_teams_master')">
      <div
        class="modal fade"
        id="addTeamsFormPopup"
        tabindex="-1"
        role="dolig"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{{ pageTitle }}</h4>
              <button
                type="button"
                v-on:click.prevent="closeModalPopup()"
                class="btn btn-outline-dark mr-1 waves-effect pull-right"
              >
                Close
              </button>
            </div>

            <div class="model-body">
              <common-loader
                refId="commonLoaderAddTeamsFormPopup"
                ref="commonLoaderAddTeamsFormPopup"
              ></common-loader>

              <div class="row no-margin pl-10 pt-20 pb-20">
                <!-- Name -->
                <div class="col-10 forms-block pt-10">
                  <div class="col-5 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.name_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      v-model="name"
                      :id="preId + 'teams_name'"
                      type="text"
                      @input="nameError = ''"
                      :placeholder="allStaticMessages.name_place_holder"
                      class="form-control"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ nameError }}</span>
                  </div>
                </div>

                <div class="col-10 forms-block pt-10">
                  <div class="col-5 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.username_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <!-- User Name -->
                    <v-select
                      v-model="selectedUsers"
                      :id="preId + 'selectedUsers'"
                      multiple
                      name="user"
                      label="text"
                      :filterable="false"
                      :clearable="true"
                      :open-on-clear="true"
                      @input="userNameError = ''"
                      :options="userNameArr"
                      class="custom-ci-auto-search"
                      :deselectFromDropdown="true"
                      @search="onSearchUserName"
                      :placeholder="allStaticMessages.user_place_holder"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isUserRecordNotFound">
                          {{ allStaticMessages.user_search }}
                        </template>
                        <template v-else>
                          {{ allStaticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ userNameError }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
              <div class="ml-3">
                <input
                  type="button"
                  @click="closeModalPopup"
                  class="btn btn-outline-dark mr-1"
                  value="Close"
                  style="margin-right: 12px"
                />
                <input
                  type="submit"
                  @click="saveTeams"
                  class="btn btn-primary"
                  :value="isEditing ? 'Update' : 'Save'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
var self;
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { commonMixins } from "../../mixins/commonMixins";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "AddTeamsFormPopup",
  mixins: [commonMixins],
  components: {
    "common-loader": CommonLoader,
  },
  data() {
    return {
      permissionNameArray: ["can_add_teams_master"],
      preId: "teams_add_update_popup_form_",
      teamsId: "",
      name: "",
      nameError: "",
      selectedUsers: "",
      userNameArr: [],
      userNameError: "",
      isUserRecordNotFound: false,
      isEditing: false,
      pageTitle: "Add Teams",
      modalInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.loadAllData(
      ["User"],
      false,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.modalInstance = new bootstrap.Modal(
       document.getElementById("addTeamsFormPopup")
      );
    },
    openForm(data) {
      self.modalInstance.show();
      if (
        data.add_edit == "edit" &&
        typeof data.record_id != "undefined" &&
        data.record_id
      ) {
        self.teamsId = data.record_id;
        self.getTeamsData(data.record_id);
        self.pageTitle = "Edit Teams";
        self.isEditing = true;
      } else {
        self.pageTitle = "Add Teams";
        self.isEditing = false;
        self.resetFormData();
      }
    },
    getTeamsData(id) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };
      HTTP.get("/teams/get", config)
        .then(function (response) {
          self.name = response.data.content.name;
          self.selectedUsers = response.data.content.selected_users.map(
            (user) => {
              return {
                value: user.id,
                text: user.full_name,
              };
            }
          );
        })
        .catch(function (error) {
          self.catchHandler(error, function () {});
        });
    },
    closeModalPopup() {
      self.resetFormData();
      self.modalInstance.hide();
      this.$emit("call-close-add-teams-popup");
    },
    resetFormData() {
      self.teamsId = "";
      self.name = "";
      self.selectedUsers = "";
      self.nameError = "";
      self.userNameError = "";
    },
    loadMasterDataCallback(callbackData) {
      self.userNameArr = callbackData.User;
      if (self.userNameArr.length == 0) {
        self.isUserRecordNotFound = true;
      }
    },
    onSearchUserName(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadUserDataCallback,
          loading,
          search
        );
      }
    },
    loadUserDataCallback(callbackData) {
      self.isUserRecordNotFound = false;
      self.userNameArr = callbackData.User;
      if (self.userNameArr.length == 0) {
        self.isUserRecordNotFound = true;
      }
    },
    checkForm() {
      var checked = true;
      if (!self.name) {
        self.nameError = "Please enter name";
        if (checked) {
          checked = false;
        }
      }
      if (self.selectedUsers.length == 0) {
        self.userNameError = "Please select user";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    saveTeams() {
      if (self.checkForm()) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        $("#commonLoaderAddTeamsFormPopup")
          .find(".loading-container.lodingTable")
          .show();

        var selectedUserIds = self.selectedUsers.map((user) => user.value);

        var data = {};
        data.id = self.teamsId;
        data.name = self.name;
        data.users_ids = selectedUserIds;

        HTTP.post("/teams/store", data, config)
          .then(function (response) {
            $("#commonLoaderAddTeamsFormPopup")
              .find(".loading-container.lodingTable")
              .hide();
            if (response.data && response.data.status == "success") {
              self.showSAlert(response.data.message, "success", false);
              self.closeModalPopup();
            } else if (
              response.data &&
              response.data.status == "error" &&
              response.data.content.length > 0
            ) {
              var err = "";
              for (var i = 0; i < response.data.content.length; i++) {
                err += response.data.content[i] + "<br/>";
              }
              self.showSAlert(err, "error", false);
            }
          })
          .catch(function (error) {
            $("#commonLoaderAddTeamsFormPopup")
              .find(".loading-container.lodingTable")
              .hide();
            self.catchHandler(error, function () {});
          });
      }
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>

<style>
.add-teams-popoup-form .v-select .selected-tag {
  display: block !important;
}
.add-teams-popoup-form .v-select .vs__selected-options {
  flex-wrap: wrap !important;
}
</style>
