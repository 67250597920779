<template>
  <div>
    <div class="admin-content-area">
        <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">Assets Move Request</div>
                </div>
            
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>

                    <div class="col-md-12 ">
                        <div class="col-md-9 paddingnone float-left pl-0" v-show="req_from">
                            <div class="card">
                                <div class="card-header ">
                                    Requested By
                                </div>

                                <div class="card-body">
                                    <table class="table-listing white-bg border-radius-top-right">
                                        <tr>
                                            <td>Name</td>
                                            <td>{{req_from.full_name ? req_from.full_name : 'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{req_from.email ? req_from.email : 'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact</td>
                                            <td>{{req_from.contact_no ? req_from.contact_no : 'N/A'}}</td>
                                        </tr>
                                        <tr rowspan="4">
                                            <td>Message</td>
                                            <td>{{data.req_message ? data.req_message : 'N/A'}}</td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 paddingnone p-0">
                            <div class="col-md-12 modal-footer border-top-0">
                                
                                <!-- Button to perform the action on the selected records -->
                                <button  v-show="showActionButton()" @click="performAction('Decline')" class="btn btn-primary">Decline</button>
                                <button  v-show="showActionButton()" @click="performAction('Approve')" class="btn btn-primary">Approve</button>
                            </div>
                            <small class="float-left">To move assets to CRLEASE, Select the assets you want to move first, then click on Approve.</small>
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <!-- Table to display the records -->
                                <table class="table custom-table jsmartable">
                                    <thead>
                                        <tr>
                                            <th class="">
                                                <div class="custom-control custom-checkbox">

                                                    <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBoxMoveAsset" name="example1" @change="clickHeaderCheckBox()">
                                                    <label class="custom-control-label" for="selectAllCheckBoxMoveAsset"></label>
                                                </div>
                                            </th>
                                            <th class="column-header">Vin No</th>
                                            <th class="column-header">Stock</th>
                                            <th class="column-header">Condition</th>
                                            <th class="column-header">Company</th>
                                            <th class="column-header">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="data && data.records">
                                            <template v-for="(record, index) in data.records">
                                                <tr class="mainDetails"  :key="'row_asset_move_to_other_company' + index">
                                                    <td>
                                                        <!-- Checkbox to select the record -->
                                                        <div v-if="typeof record.request_status != 'undeined' && (record.request_status == 'Approved' ||record.request_status == 'Declined')" class="custom-control pl-0">
                                                                <span class="badge badge-primary badge-pill bg-color-71aa3f">{{record.request_status}}</span>
                                                        </div>
                                                        <div v-else class="custom-control custom-checkbox">
                                                            <input type="checkbox" v-model="selectedRows" :value="record.vinno" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheckAssetMove' + index" name="case[]">
                                                                <label class="custom-control-label" :for="'customCheckAssetMove' + index"></label>
                                                        </div>
                                                    </td>
                                                    <td>{{ record.vinno }}</td>
                                                    <td>{{ record.stock }}</td>
                                                    <td>{{ record.condition }}</td>
                                                    <td>{{ record.department }}</td>
                                                    <td>{{ record.status }}</td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <app-footer></app-footer>   
  </div>
</template>

<script>
var self;
import Vue from "vue";
import {HTTP} from '../../http-common';
import { commonMixins } from "../../mixins/commonMixins";
import Footer from "@/components/partials/Footer";
Vue.component("app-footer", Footer);
export default {
  mixins: [commonMixins],
  data() {
    return {
      showModal: false, // Whether to show the modal
      selectedRows: [], // The IDs of the selected records
      selectAll: false, // Selected all is check or not
      encryptedString: '',
      alertClass: '',
      alertMessage: '',
    };
  },
  props: {
    data: '', // The records to display in the table
    req_from: ''
  },
  beforeMount() {
        self = this;
  },
  mounted: function () {
            self.encryptedString = this.$route.params.encrypted_string;
    },
  methods: {
    showActionButton(){
        let hideShow = false;
        let totalRecords = 0;
            
        self.data.records.forEach((item, index) => {
            if(item.request_status == 'undeined' || (item.request_status != 'Approved' && item.request_status != 'Declined')){
                hideShow = true;
            }
        });

        return hideShow;
    },
    performAction(flag) {
        if(self.selectedRows.length) {
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
            };
            
            var fields = {
                data: {'records': self.selectedRows, 'action': 'move-inventory-approval-request', 'access_key': self.encryptedString, flag: flag},
            };

            self.showLoader();
            
            HTTP.post("/move-inventory/handle-approval-request", fields, config)
                .then(function (data) {
                    self.hideLoader();
                    if (data.status == 200) {
                        let seconds = 5000;
                        let msg = (flag == 'Approve') ? 'Approved successfully.' : 'Declined successfully.';
                        self.$swal({
                            type: 'success',
                            html: msg,
                            showConfirmButton: false,
                            timer: seconds,
                        });
                        self.selectAll = false;
                        self.selectedRows = [];
                        self.$parent.getHandleAccessDetails();
                    }
                })
                .catch(function (error) {
                    self.hideLoader();
                    // self.catchHandler(error, function () {});
                })

        }else{
            let seconds = 5000;
            self.$swal({
                type: 'error',
                html: 'Please select assets first!',
                showConfirmButton: false,
                timer: seconds,
            });
        }
    },
    clickHeaderCheckBox: function () {
        setTimeout(function () {
            self.selectedRows = [];
            if (self.selectAll) {
                self.data.records.forEach((item, index) => {
                    if(item.request_status == 'undeined' || (item.request_status != 'Approved' && item.request_status != 'Declined')){
                        self.selectedRows.push(item.vinno);
                    }
                });
            }
        }, 100);
    },
    inputCheckbox: function (e) {
        setTimeout(function () {
            self.selectAll = false;
            let selectedRecords = self.selectedRows.length;
            let totalRecords = 0;
            
            self.data.records.forEach((item, index) => {
                if(item.request_status == 'undeined' || (item.request_status != 'Approved' && item.request_status != 'Declined')){
                    totalRecords++;
                }
            });
            if (self.selectedRows.length == totalRecords) {
                self.selectAll = true;
            }
        }, 100);
    },
    showAlertOnSave: function (alertMessage, alertType) {
        if (alertType === "success") {
            self.alertClass = "alert alert-success";
        } else {
            self.alertClass = "alert alert-danger";
        }
        self.alertMessage = alertMessage;
        $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
            $("#success-alert").slideUp(500);
            self.$parent.getHandleAccessDetails();
        });
    },
  },
};
</script>
