<template>
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_deals_review')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>

          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <template>
              <div class="row no-margin">
                <ul class="nav nav-tabs">
                    <li class="nav-item" v-for="(tab,index) in tabList" :key="tab.id">
                        <a
                        v-on:click="activetab = index"
                        v-bind:class="{
                            'nav-link': true,
                            active: activetab === index,
                        }"
                        >{{ tab.name }}</a
                        >
                    </li>
                </ul>
                <!-- <template v-for="(tab,index) in tabList">
                    <div :key="tab.id" v-if="activetab === index && permissionNameArray.includes('can_list_deals_review')" class="tabcontent">
                    
                    </div>
                </template> -->
                <div
                  v-if="
                    activetab === 1
                  "
                  class="tabcontent"
                >
                    <div class="card default-bx-shadow">
                        <div class="card-body">
                            <div class="mb-4 d-flex justify-content-between">
                                <span>
                                    <strong class="bold">PO Request Review</strong>
                                </span>
                                <span class="position-relative">
                                    <a title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2"><i aria-hidden="true" class="fa fa-undo"></i></a>
                                </span>
                            </div>
                            <div class="col-lg-12 col-md-12 form-group">
                                <table class="table">
                                    <thead>
                                        <th>Action</th>
                                        <th>Business Name</th>
                                        <th>Reference No</th>
                                        <th>Deal No</th>
                                        <th>Deal Status</th>
                                        <th>Branch</th>
                                        <th>Salesperson</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </thead>
                                    <tbody>
                                        <template>
                                            <tr>
                                                <!-- <td class="text-center bold" colspan="9">No Review Found</td> -->
                                                <td></td>
                                                <td>Cory Trashco</td>
                                                <td>2024-91</td>
                                                <td>DE-231</td>
                                                <td>PO Request</td>
                                                <td>Neely Coble Company - Nashville</td>
                                                <td>Santosh Tamang</td>
                                                <td>03/05/2024 03:24AM</td>
                                                <td>03/05/2024 04:27AM</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div> 
                </div>
              </div>
            </template>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
</template>


<script>
import { commonMixins } from "@/mixins/commonMixins";
import EventBus from "@/components/event-bus";
import { HTTP } from "@/http-common";

export default {
    name: "DealReview",
    mixins: [commonMixins],
    data() {
        return {
            activetab: 1,
            permissionNameArray: [
                "can_list_deals_review",
            ],
            pageTitle: "Deals Review",
            tabList: [],
        }
    },
    async mounted() {
        await this.getDealLevel();

        if(this.tabList.length > 0) {
            this.getData(this.tabList[0].id);
        }
    },
    methods: {
        async getDealLevel() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                };
                HTTP.get("/deals-level/list", config)
                    .then((response) => {
                        if (response.data.code == 200) {
                            this.tabList = response.data.content;
                            console.log('tabList>>>', this.tabList);
                            resolve();
                        } else {
                            reject('Failed to load data');
                        }
                    })
                    .catch((err) => {
                        this.catchHandler(err, function () {});
                    });
            })
        },
        getData(id) {
            console.log('FIrst Id>>', id);
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
            };
            HTTP.get("/deals/review/"+id, config)
                .then((response) => {
                    if (response.data.code == 200) {
                        
                        console.log('tabList>>>', this.tabList);
                        resolve();
                    } else {
                        reject('Failed to load data');
                    }
                })
                .catch((err) => {
                    this.catchHandler(err, function () {});
                });
        },
        buttonBack() {

        }
    }
}

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
