<template>
<div class="printablemaindiv">
    <div class="print-summary-main-section">
            <div class="gmc-section"> <!--GMC Section-->
                <div class="gmc-left-section">
                    <h3> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </h3>
                    <p> {{ assetInfo['vin_no'] }} </p>
                </div>

                <div class="gmc-right-section">
                    <h3> #VVG </h3>
                    <p> Printed {{ assetInfo['printed_date'] }} </p>
                </div>
            </div> <!--GMC Section-->

            <!--Vendor Section-->
            <div class="vendor-section-main">
                <div class="vendor-left-section">
                    <h6> Vendor </h6>

                    <div class="block-detail"> 
                        <p class="block-label"> Vendor Name </p>
                        <p class="block-value"> Vendor Address </p>
                    </div>

                    <p class="btm-no"> (111)111-1111 </p>
                </div>

                <div class="vendor-right-section">
                    <h6> Internal </h6>

                    <div class="block-detail"> 
                        <p class="block-label"> Purchased By </p>
                        <p class="block-value"> {{ assetInfo['acquired_by_name'] }} </p>
                    </div>

                    <div class="block-detail"> 
                        <p class="block-label"> Managed By </p>
                        <p class="block-value"> {{ assetInfo['managed_by_name'] }} </p>
                    </div>
                </div>
            </div>
            <!--Vendor Section-->

            <!--Financial Summary Section-->
            <div class="financial-summary-section">
                <div class="financial-left-section">
                    <h4 class="table-title-common"> Financial Summary </h4>

                    <div class="financial-table-section">
                        <div class="financial-data">
                            <p class="financial-left-label"> Purchase Price </p>
                            <p class="financial-right-value"> $10,000.00 </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Asking Retail </p>
                            <p class="financial-right-value"> {{ assetInfo['asking_retail_usd'] }} </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Asking Wholesale </p>
                            <p class="financial-right-value"> {{ assetInfo['wholeshale_usd'] }} </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Purchase Orders </p>
                            <p class="financial-right-value"> $395.00 </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Interest </p>
                            <p class="financial-right-value"> $0.00 </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Running Cost </p>
                            <p class="financial-right-value"> {{ assetInfo['expense_amt_total'] }} </p>
                        </div>

                    </div>
                </div>

                <div class="financial-right-section">
                    <h4 class="table-title-common"> Dates </h4>
                    <div class="financial-table-section">
                        <div class="financial-data">
                            <p class="financial-left-label"> Purchase Date </p>
                            <p class="financial-right-value"> {{ assetInfo['acquire_on_date'] }} </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Paid Vendor </p>
                            <p class="financial-right-value"> {{ assetInfo['paid_vendor'] }} </p>
                        </div>

                        <div class="financial-data">
                            <p class="financial-left-label"> Inventory Age </p>
                            <p class="financial-right-value"> {{ assetInfo['inventory_age'] }} </p>
                        </div>
                    </div>

                </div>
            </div>
            <!--Financial Summary Section-->

            <!--Comment Section-->
            <!-- <div class="comment-section-main">
                <h4 class="table-title-common"> Comments </h4>
                <p class="no-data-field"> Dummy account to track utilities by location </p>
            </div> -->
            <!--Comment Section-->

            <!--Price section-->
            <div class="price-section-main desktop-table">
                <h4 class="table-title-common"> Price Changes </h4>

                <div class="price-table" >
                    <template v-if="priceTrackList.length > 0">
                        <template v-for="(track, index) in priceTrackList">
                            <div class="price-section">
                                <p class="price-label"> {{ track.label }} </p>
                                <p class="price-1"> {{ track.old_amount }} </p>
                                <p class="price-1"> {{ track.new_amount }} </p>
                                <p class="date"> {{ track.created_at }} </p>
                                <p class="name-field"> {{ track.user_first_name }} {{ track.user_last_name }} </p>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <p class="no-data-field"> No Data </p>
                    </template>
                </div>
            </div>

            <table class="table-mobile-section">
                <tr>
                    <td> Minimum Retail </td>
                    <td> $1,200 </td>
                    <td> $10,400 </td>
                    <td> 06/25/20 </td>
                    <td> Shivie Mannshahia </td>
                </tr>

                <tr>
                    <td> Asking Retail </td>
                    <td> $1,100 </td>
                    <td> $10,500 </td>
                    <td> 06/25/20 </td>
                    <td> Shivie Mannshahia </td>
                </tr>

                <tr>
                    <td> Minimum Retail </td>
                    <td> $0 </td>
                    <td> $1,200 </td>
                    <td> 06/25/20 </td>
                    <td> Shivie Mannshahia </td>
                </tr>

                <tr>
                    <td> Asking Retail </td>
                    <td> $0 </td>
                    <td> $1,100 </td>
                    <td> 06/25/20 </td>
                    <td> Shivie Mannshahia </td>
                </tr>

                <tr>
                    <td> Purchasease Price </td>
                    <td> $0 </td>
                    <td> $10,000 </td>
                    <td> 06/25/20 </td>
                    <td> Shivie Mannshahia </td>
                </tr>

            </table>

            <!--Price section-->

            <div class="gmc-section"> <!--GMC Section-->
                <div class="gmc-left-section">
                    <h3> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </h3>
                    <p> {{ assetInfo['vin_no'] }} </p>
                </div>

                <div class="gmc-right-section">
                    <h3> #VVG </h3>
                    <p> Printed {{ assetInfo['printed_date'] }} </p>
                </div>
            </div>

            <!--Price section-->
            <div class="price-section-main desktop-table">
                <h4 class="table-title-common"> Price Order Breakdown </h4>

                <div class="price-table">
                    <template v-if="expenseList.length > 0">
                        <div class="price-section">
                            <p class="price-1"> Jet Id </p>
                            <p class="date"> Created Date </p>
                            <p class="price-label"> Vendor Name </p>
                            <p class="name-field"> Reference </p>
                            <p class="price-1"> Actual Cost </p>        
                        </div>
                        <template v-for="(expense, index) in expenseList">
                            <div class="price-section">
                                <p class="price-1"> {{ expense['excede_jet_id'] }} </p>
                                <p class="date"> {{ expense['created_date'] }} </p>
                                <p class="price-label"> {{ expense['excede_bill_vendor_name'] }} </p>
                                <p class="name-field"> {{ expense['excede_receive_ref'] }} </p>
                                <p class="price-1"> {{ expense['actual_cost'] }} </p>        
                            </div>
                        </template>
                        <div class="total-section">
                            <p class="total-label-btm"> Total </p>
                            <p class="total-value"> {{ assetInfo['expense_amt_total'] }} </p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="total-section">
                            <p class="total-label-btm"> Total </p>
                            <p class="total-value"> $0.00 </p>
                        </div>
                    </template>
                </div>

            </div>

            <table class="table-mobile-section">
                <template v-if="expenseList.length > 0">
                    <template v-for="(expense, index) in expenseList">
                        <tr>
                            <td> {{ expense['excede_jet_id'] }} </td>
                            <td> {{ expense['created_date'] }} </td>
                            <td> {{ expense['excede_bill_vendor_name'] }} </td>
                            <td> {{ expense['excede_receive_ref'] }} </td>
                            <td> {{ expense['actual_cost'] }} </td>
                        </tr>
                    </template>
                    <tr>
                        <td colspan="4" style="font-weight: bold;"> Total </td>
                        <td style="font-weight: bold;"> {{ assetInfo['expense_amt_total'] }} </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="4" style="font-weight: bold;"> Total </td>
                        <td style="font-weight: bold;"> $0.00 </td>
                    </tr>
                </template>
            </table>
            <!--Price section-->

            <!-- <div class="comment-section-main">
                <h4 class="table-title-common"> Opportunities </h4>
                <p class="no-data-field"> No sale opportunities found </p>
            </div> -->

            <div class="gmc-section"> <!--GMC Section-->
                <div class="gmc-left-section">
                    <h3> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </h3>
                    <p> {{ assetInfo['vin_no'] }} </p>
                </div>

                <div class="gmc-right-section">
                    <h3> #VVG </h3>
                    <p> Printed {{ assetInfo['printed_date'] }} </p>
                </div>  
            </div>

            <div class="utility-reefer-table">
                <p class="small-text"> 2018 UTILITY REEFER TRAILER </p>

                <div class="utility-btm-table">
                    <div class="utility-table-internal">
                        <p class="utility-label-1"> AXLE TYPE </p>
                        <p class="utility-value-1"> {{ assetInfo['axle_type'] }} </p>
                        <p class="utility-label-2"> FRAME TYPE </p>
                        <p class="utility-value-2"> {{ assetInfo['frame_name'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> SUSPENSION </p>
                        <p class="utility-value-1"> {{ assetInfo['suspension'] }} </p>
                        <p class="utility-label-2"> REAR WHEELS </p>
                        <p class="utility-value-2"> {{ assetInfo['rear_wheels'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> TIRE SIZE </p>
                        <p class="utility-value-1"> {{ assetInfo['tire_size'] }} </p>
                        <p class="utility-label-2"> TIRE CONFIG </p>
                        <p class="utility-value-2"> {{ assetInfo['tire_config'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> REEFER TYPE </p>
                        <p class="utility-value-1"> {{ assetInfo['reefer_type'] }} </p>
                        <p class="utility-label-2"> REEFER MAKE </p>
                        <p class="utility-value-2"> {{ assetInfo['reefer_make_name'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> REEFER DETAIL </p>
                        <p class="utility-value-1"> {{ assetInfo['reefer_detail'] }} </p>
                        <p class="utility-label-2"> REAR DOOR TYPE </p>
                        <p class="utility-value-2"> {{ assetInfo['rear_door_type_name'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> RAMPS </p>
                        <p class="utility-value-1"> {{ assetInfo['ramp'] }} </p>
                        <p class="utility-label-2"> INTERIOR H </p>
                        <p class="utility-value-2"> {{ assetInfo['interior_height'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> EXTERIOR W </p>
                        <p class="utility-value-1"> {{ assetInfo['exterior_width'] }} </p>
                        <p class="utility-label-2"> EXTERIOR L </p>
                        <p class="utility-value-2"> {{ assetInfo['exterior_length'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> FLOOR TYPE </p>
                        <p class="utility-value-1"> {{ assetInfo['floorName'] }} </p>
                        <p class="utility-label-2"> ROOF TYPE </p>
                        <p class="utility-value-2"> {{ assetInfo['roofName'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> BODY TYPE </p>
                        <p class="utility-value-1"> {{ assetInfo['bodyTypeName'] }} </p>
                        <p class="utility-label-2"> EXTERIOR COLOR  </p>
                        <p class="utility-value-2"> {{ assetInfo['exterior_color'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> TRAILER TYPE </p>
                        <p class="utility-value-1"> {{ assetInfo['trailerTypeName'] }} </p>
                        <p class="utility-label-2"> SKIRT  </p>
                        <p class="utility-value-2"> {{ assetInfo['skirt'] }} </p>
                    </div>

                    <div class="utility-table-internal">
                        <p class="utility-label-1"> GVW </p>
                        <p class="utility-value-1 border-mobile-0" style="border-right: 1px solid #dddddd;"> {{ assetInfo['gvwType'] }} </p>
                    </div>
                </div>
            </div>

            <div class="gmc-section"> <!--GMC Section-->
                <div class="gmc-left-section">
                    <h3> {{ assetInfo['year'] }} {{ assetInfo['make_name'] }} {{ assetInfo['model_name'] }} </h3>
                    <p> {{ assetInfo['vin_no'] }} </p>
                </div>

                <div class="gmc-right-section">
                    <h3> #VVG </h3>
                    <p> Printed {{ assetInfo['printed_date'] }} </p>
                </div>    
            </div>

            <div class="btm-img-section">
                <img :src="mediaPath" alt="VVG Dealer Manager">
            </div>

        </div>
</div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AssetPrintableSummary',
        components: {
        },
        mixins: [commonMixins],
        data() {
            return {
                assetInfo: [],
                mediaInfo:[],
                docs: [],
                priceTrackList:[],
                expenseList:[],
                companyInfo: [],
                publicLoginId: 0,
                isShowCompanyOrUserDetails: true,
                mediaPath:""
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.publicLoginId = 0;
            self.isShowCompanyOrUserDetails = true;
            if (typeof (self.$route.query.id) != 'undefined' && self.$route.query.id != "" && self.$route.query.id != 0) {
                if (typeof (self.$route.query.uid) != 'undefined' && self.$route.query.uid != "" && self.$route.query.uid != 0) {
                    self.publicLoginId = self.$route.query.uid;
                }
                if (self.$route.query.key == undefined) {
                    self.isShowCompanyOrUserDetails = false;
                }
                self.getAssetData(self.$route.query.id);
            }
        },
        methods: {
            getAssetData(id) {
                var config = {
                    params: {
                        id: id,
                        publicLoginId: self.publicLoginId,
                    }
                };
                HTTP.get('/asset/summary/get', config)
                    .then(response => {
                        self.assetInfo = response.data.content.asset_data;
                        self.mediaInfo = response.data.content.media;
                        self.mediaPath = response.data.content.mediaPath;
                        self.docs = response.data.content.docs;
                        self.companyInfo = response.data.content.companyInfo;
                        self.priceTrackList = response.data.content.price_track;
                        self.expenseList = response.data.content.expense_data;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        },
    }
</script>