<template>
  <div class="modal" id="countySearchModal" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-lg modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header county-src-modal-row">
          <div class="county-custom-input-search">
            <input
              type="text"
              id="county-form-input"
              v-model="county"
            />
            <div class="county-search-icon" @click="onSearchCounty"></div>
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body county-src-modal-body">
          <div class="card">
            <table class="table custom-table jsmartable">
              <caption style="display:none">County List</caption>
              <thead id="county-src-modal">
                <th>County ID</th>
                <th>County</th>
                <th>Tax Rate</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(county, index) in countyArr" :key="index">
                  <td>{{ county.code }}</td>
                  <td>{{ county.name }}</td>
                  <td>{{ county.rate }}</td>
                  <td>
                    <a href="javascript:void(0)" @click="selectCounty(county)"
                      >Select</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../event-bus";
import { commonMixins } from "@/mixins/commonMixins";
let selfThis;
export default {
  name: "CountySearchModal",
  props: ["isClickCountySearchModal"],
  mixins: [commonMixins],
  data() {
    return {
      county: "",
      countyArr: [],
    };
  },
  mounted() {
    selfThis = this;
  },
  methods: {
    closeModal() {
      this.county = "";
      this.countyArr = [];
      eventBus.$emit("close-search-county", false);
    },

    onSearchCounty() {
      let county = this.county;
      selfThis.loadAllData(
        ["TaxRate"],
        true,
        selfThis.loadCountyCode,
        "undefined",
        county
      );
    },

    loadCountyCode: function (callbackData) {
      selfThis.countyArr = callbackData.TaxRate;
    },

    selectCounty(county) {
      this.county = "";
      this.countyArr = [];
      eventBus.$emit("get-search-county", county);
    },
  },
};
</script>

<style>
.county-src-modal-row {
  display: grid;
  grid-template-columns: 4fr 4fr;
}

.county-src-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

.county-src-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.county-custom-input-search {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  overflow: hidden;
  /* margin-top: 5px; */
}

.county-search-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  flex-shrink: 0;
  background: #ebebeb
    url("../../../../../public/static/images/transaction_sheet/search.svg")
    no-repeat center;
  cursor: pointer;
}

.county-src-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#county-src-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

#county-form-input {
  height: 32px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #cecece;
}
</style>
