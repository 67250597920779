<template>
    <div class="add-contact-popup-form admin-content-area">
        <div class="modal fade bd-example-modal-lg" id="addUpdateActivityFormPopup" tabindex="-1" role="dialog"
                aria-labelledby="myLargeModalLabel" aria-hidden="true">
            
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <add-update-activity ref="addUpdateActivityRef" @call-modal-close="closeModalPopup" openAt="popupView"></add-update-activity>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
var self;
import AddUpdateActivity from "../Activity/AddUpdateActivity.vue";
export default {
    name: "AddUpdateActivityPopup",
    components: {
        AddUpdateActivity
    },
    data() {
        return {
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
            activityModalInstance: null,
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {
        self.initializeModals();
    },
    methods: {
        initializeModals() {
            self.activityModalInstance = new bootstrap.Modal(
                document.getElementById("addUpdateActivityFormPopup")
            );
        },
        openAddActivityPopup(data) {
            self.activityModalInstance.show();
            self.$refs.addUpdateActivityRef.openAddActivityPopup(data);
        },
        closeModalPopup() {
            self.activityModalInstance.hide();
            self.$emit('call-close-add-activity-popup');
        }
    }

}
</script>
