<template>
    <!-- Page Content Wrapper -->
    <div>
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- CopyInventoryInput -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.select_inventory_to_copy_data}}</label>
                                    <v-select ref="copyCelect" style="min-width: 100px;" 
                                              v-model="copyinventoryInput"
                                              @change="changedCopyValue()"
                                              @input="copyinventoryInputErrorMsg = ''"
                                              @search="onSearchAsset"
                                              name="copyinventoryInput"
                                              id="copyinventoryInput"
                                              label="text"
                                              :clearable="false"
                                              :filterable="false"
                                              :options="assetArr"
                                              :placeholder="staticMessages.select_inventory_place_holder">
                                              <span slot="no-options" @click="$refs.copyCelect.open = false">
                                                <template v-if="!isAssetRecordNotFound">
                                                    {{staticMessages.asset_search}}
                                                </template>
                                                <template v-else>
                                                    {{staticMessages.search_record_not_found}}
                                                </template>
                                              </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{copyinventoryInputErrorMsg}}</span>
                                </div>
                            </div>
                            <div v-if="inputs.length < 75" class="col-12 forms-block">
                                <button class="btn btn-success" @click="addRow"> <i class="fa fa-plus" aria-hidden="false"></i> </button>
                                <span>{{staticInputLabelMessages.click_to_add_more}}</span>
                            </div>
                            <div class="clearfix"></div>
                            <template v-for="(input, index) in inputs">
                                <!--VIN No. -->
                                <div class="col-1 forms-block">
                                    <button class="btn btn-danger" @click="deleteRow(index)"><i class="fa fa-times"></i></button>
                                </div>
                                <div class="col-5 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="input.asset"
                                                name="asset"
                                                @input="commonError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="assetArr"
                                                @search="onSearchAsset"
                                                :clearable="false"
                                                :placeholder="staticMessages.asset_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isAssetRecordNotFound">
                                            {{staticMessages.asset_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>
                            </template>
                            <div class="clearfix"></div>
                            <div class="col-md-2 forms-block">
                            </div>
                            <div class="col-md-5  forms-block">
                                <span class="help is-danger">{{commonError}}</span>
                            </div>
                            <div class="clearfix"></div>
                            <div v-if="inputs.length >= 12 && inputs.length < 75" class="col-12 forms-block">
                                <button class="btn btn-success" @click="addRow"> <i class="fa fa-plus" aria-hidden="false"></i> </button>
                                <span>{{staticInputLabelMessages.click_to_add_more}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="copyInventoryData()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                alertClass: '',
                alertMessage: '',
                permissionNameArray: ['can_add_inventory_master'],
                inventoryInput : "",
                inventoryErrorMsg : "",
                assetArr : [],
                isAssetRecordNotFound: false,
                copyinventoryInput : "",
                copyinventoryImageCount : 0,
                copyinventoryInputErrorMsg : "",
                inventoryArrInput : [],
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: 50,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    }
                },
                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                loadDocArr: [],
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                copyFromOtherVin : false,
                firstAdd : false,
                inputs: [],
                commonError : "",
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* this.$refs.select.open = true; */
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            self.firstAdd = true;
            self.addRow();
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.copyFromOtherVin = false;
            self.$nextTick(function () {
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if(i == 0) {
                            self.inventoryInput = {text: self.asset.content[i].vin_no, value: self.asset.content[i].asset_id};
                            self.getAssetDetailsById(self.inventoryInput.value);
                        }
                        var tempObj = {
                            text: self.asset.content[i].vin_no,
                            value: self.asset.content[i].asset_id,
                        }
                        self.inventoryArrInput.push(tempObj);
                    }
                });
            });
        },
        watch : {
            copyinventoryInput : function (data) {
                if (data != null && data.value) {
                    // console.log(JSON.stringify(data));
                    // console.log(JSON.stringify(data.value));
                    self.copyinventoryInputErrorMsg = '';
                    self.copyinventoryImageCount = data.image_count; 
                    // self.getAssetDetailsById(data.value);
                }
            },
        },
        methods: {
            addRow() {
                if (self.firstAdd) {
                    this.inputs.push({
                        asset: '',
                    });
                    self.firstAdd = false;
                } else {
                    if (self.checkInput()) {
                        this.inputs.push({
                            asset: '',
                        });
                    } else {
                        self.commonError = "Please select VIN Number";
                    }
                }
            },
            deleteRow(index) {
                this.inputs.splice(index,1);
                self.commonError = "";
            },
            checkInput: function () {
                var checked = true;
                for (let i = 0; i < self.inputs.length; i++) {
                    if (self.inputs[i].asset.value === "" || self.inputs[i].asset.value === null || typeof self.inputs[i].asset.value === "undefined") {
                        self.commonError = "Please select VIN Number";
                        if (checked) {
                            checked = false;
                        }
                    }
                }

                return checked;
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            changedCopyValue: function(){
                self.copyinventoryInputErrorMsg = '';
            },
            checkForm: function () {
                var checked = true;
                self.copyinventoryInputErrorMsg = "";
                if (self.copyinventoryInput == null || !self.copyinventoryInput.value) {
                    self.copyinventoryInputErrorMsg = "Please select Source Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                self.commonError = "";
                for (let i = 0; i < self.inputs.length; i++) {
                    if (self.inputs[i].asset.value === "" || self.inputs[i].asset.value === null || typeof self.inputs[i].asset.value === "undefined") {
                        self.commonError = "Please select VIN Number";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                return checked;
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            changedValue: function() {
                self.inventoryErrorMsg = '';
            },
            inputInventory: function() {
                self.inventoryErrorMsg = '';
                if (self.inventoryInput != null || self.inventoryInput.value) {
                    self.getAssetDetailsById(self.inventoryInput.value);
                }
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                
                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                this.$refs.dropzone.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400) {
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            removeAllFiles: function () {
                this.$refs.dropzone.removeAllFiles();
            },
            uploadManually: function (file) {
                var self = this;
                if(self.loadDocArr.length > 0){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        if (self.loadDocArr[i]['media_from'] == 'media_url') {
                            var mockFile = {name: "image_" + i + ".jpg", size: self.loadDocArr[i]['size']};
                            let imgUrl = self.loadDocArr[i]['thumb_url'];
                            let imageName = imgUrl.split("https://www.imanpro.net/pub/co/sac/photo/");
                            this.$refs.dropzone.dropzone.options.addedfile.call(this.$refs.dropzone.dropzone, mockFile);
                            this.$refs.dropzone.dropzone.options.thumbnail.call(this.$refs.dropzone.dropzone, mockFile, imgUrl);
                        } else {
                            var url = self.loadDocArr[i]['media_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['media_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];
                            self.imagesType.push(file_type);
                            self.imagesSize.push(Number(file_size));
                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                            self.$refs.dropzone.manuallyAddFile(file_data, url);
                        }
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
            },
            getFileType: function (file_name) {
                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {
                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];
                self.imagesPaths.push(String(orgPath + "/" + file_name));
                return file_name;
            },
            returnData: function() {
                var data = {
                    copyAssetId : self.copyinventoryInput.value,
                    inputs : self.inputs,
                };
                return data;
            },
            copyInventoryData: function() {
                    if (self.checkForm()) {
                        self.$swal({
                        title: '',
                        html: 'Are you sure that you want to copy inventory(s) data? <br/>It will copy all Core and Specification details to selected inventory(s)',
                        type: 'info',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        /* showCloseButton: true, */
                        showLoaderOnConfirm: true
                        }).then((result) => {
                            if (result.value) {
                                var config = {
                                headers: {
                                        Authorization: "Bearer " + localStorage.getItem("api_token")
                                    }
                                };
                                var data = self.returnData();
                                console.log(JSON.stringify(data));
                                self.buttonNames.save = "Saving ...";
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                HTTP.post("/asset/edit/inventory/copy", data, config)
                                    .then(function (response) {
                                        self.copyFromOtherVin = false;
                                        self.buttonNames.save = "Save";
                                        if (response.data.status == "success") {
                                            self.clearForm();
                                            self.getAssetDetailsById(self.inventoryInput.value);
                                            self.disableButtons.save = true;
                                            self.disableButtons.cancel = true;
                                            self.showSAlert(response.data.message, 'success', false, 3);
                                            setTimeout(function () {
                                                self.disableButtons.save = false;
                                                self.disableButtons.cancel = false;
                                            }, 3000);
                                        } else {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            if (response.data.content.length > 0) {
                                                var err = "";
                                                for (var i = 0; i < response.data.content.length; i++) {
                                                    err += response.data.content[i] + "<br/>";
                                                }
                                                self.showSAlert(err, 'error', false);
                                            }
                                        }
                                    })
                                    .catch(function (err) {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        self.catchHandler(err, function () {});
                                    });
                            }
                        });
                    }
            },
            clearForm: function (id) {
                self.copyinventoryInput = "";
                self.inputs = [];
                self.assetArr = [];
                self.firstAdd = true;
                self.addRow();
            },
            getAssetDetailsById: function (id) {
                // self.removeAllFiles();   
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/asset/get', config)
                    .then(response => {
                        self.loadDocArr = [];
                        self.loadDocArr = response.data.content.media;
                        setTimeout(function() {
                            self.uploadManually();
                        }, 500);
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        },
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>