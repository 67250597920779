<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_deals')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="saveDeal()" class="btn btn-primary waves-effect pull-right">Update</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Add To Forecast -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">Add to forecast</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="add_to_forecast" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                            <!-- Deal No -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.deal_no_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control">{{dealNo}}</span>
                                </div>
                            </div>

                            <!-- Department -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="companyId" name="companyId"
                                            label="text" 
                                            :filterable="false" 
                                            :options="companyArr"
                                            @search="onSearchCompany" 
                                            :clearable="true" 
                                            :placeholder="allStaticMessages.company_custom_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{allStaticMessages.company_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Deal Status -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.status_label}}</div>
                                </div>
                                <!-- {{dealStatusIdValue}} -->
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="dealStatusId" name="dealStatusId"
                                            label="text" 
                                            :filterable="false" 
                                            :options="dealStatusArr"
                                            @search="onSearchDealStatus" 
                                            @input="statusError = ''"
                                            :clearable="false"
                                            :searchable="false"
                                            :placeholder="allStaticMessages.status_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isDealStatusRecordNotFound">
                                                {{allStaticMessages.status_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{statusError}}</span>
                                </div>
                            </div>
                           
                            <!-- Contract Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.contract_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="contractNumber" v-model="contractNumber" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Contract Date -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.contract_date_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <date-picker v-model="contractDate" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.contract_date_place_holder" name="contractDate">
                                    </date-picker>
                                </div>
                            </div>

                            <!-- Sales Person -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="salePersonId" name="salePersonId"
                                            label="text" 
                                            :filterable="false" 
                                            :options="salePersonArr"
                                            @search="onSearchSalePerson" 
                                            :clearable="true" 
                                            :placeholder="allStaticMessages.sales_person_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalePersonRecordNotFound">
                                                {{allStaticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Customer -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                            v-model="customerId"
                                            name="customerId"
                                            label="text"
                                            :filterable="false"
                                            :options="customerArr"
                                            @search="onSearchCustomer"
                                            :clearable="true"
                                            id="customerId"
                                            :placeholder="allStaticMessages.customer_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerRecordNotFound">
                                                {{allStaticMessages.customer_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>
                            
                            <!-- Individual -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-2 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="individual" value="true" v-model="individual" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-md-10 col-sm-12 paddingleft0">
                                    <div class="col-md-3 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.individual_label}}</div>
                                    </div>
                                    <div class="col-md-9 col-sm-12 paddingleft0">
                                        <input class="form-control" type="text" id="individualValue" name="individualValue" v-model="individualValue" maxlength="190"/>
                                    </div>
                                </div>
                            </div>

                            <!-- Cus. Address -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusAddress" v-model="cusAddress" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. Address 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusAddress2" v-model="cusAddress2" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Customer City -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="customerCityId" 
                                        name="customerCityId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="customerCityArr"
                                        @search="onSearchCustomerCity"
                                        :clearable="false"
                                        id="customerCityId"
                                        :placeholder="allStaticMessages.city_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerCityRecordNotFound">
                                                {{allStaticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="cusCity2" name="cusCity2" id="cusCity2" type="text" maxlength="190"/>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="cusCity3" name="cusCity3" id="cusCity3" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Alt Cus. City Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.alt_city_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusAltCityName" v-model="cusAltCityName" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Customer Country -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.country_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="customerCountyId" 
                                        name="customerCountyId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="customerCountyArr"
                                        @search="onSearchCustomerCounty"
                                        :clearable="false"
                                        @input="customerCountyError = ''"
                                        id="customerCountyId"
                                        :placeholder="allStaticMessages.country_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerCountyRecordNotFound">
                                                {{allStaticMessages.country_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{customerCountyError}}</span>
                                </div>
                            </div>

                            <!-- Alt Cus. County Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.alt_county_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusAltCountyName" v-model="cusAltCountyName" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Customer Email -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_email_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="customerEmailError = ''" id="customerEmail" v-model="customerEmail" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{customerEmailError}}</span>
                                </div>
                            </div>

                            <!-- Co - Buyer Block Start -->
                            <div class="col-md-12 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="book-deal-customer-block">Co-Buyer</div>
                                </div>
                            </div>

                            <!-- Buyer Phone Number -->
                            <div class="col-md-4 forms-block">
                                <div class="col-md-4 paddingleft0">
                                    <div class="form-label">Co-Buyer</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="buyerNumber" v-model="buyerNumber" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Buyer Name -->
                            <div class="col-md-4 forms-block">
                                <div class="col-md-4 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                            v-model="buyerId"
                                            name="buyerId"
                                            label="text"
                                            :filterable="false"
                                            :options="buyerArr"
                                            @search="onSearchBuyer"
                                            :clearable="true"
                                            id="buyerId"
                                            :placeholder="allStaticMessages.customer_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerRecordNotFound">
                                                {{allStaticMessages.customer_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Buyer Address -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="buyerAddress" v-model="buyerAddress" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Buyer Address 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="buyerAddress2" v-model="buyerAddress2" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Buyer City -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="buyerCityId" 
                                        name="buyerCityId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="buyerCityArr"
                                        @search="onSearchBuyerCity"
                                        :clearable="false"
                                        id="buyerCityId"
                                        :placeholder="allStaticMessages.city_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isBuyerCityRecordNotFound">
                                                {{allStaticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="buyerCity2" name="buyerCity2" id="buyerCity2" type="text" maxlength="190"/>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="buyerCity3" name="buyerCity3" id="buyerCity3" type="text" maxlength="190"/>
                                </div>
                            </div>
                            <!-- Co - Buyer Block End -->

                            
                            <!-- Deliver to Block Start -->
                            <div class="col-md-12 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="book-deal-customer-block">Deliver To</div>
                                </div>
                            </div>

                            <!-- Deliver To Phone Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_phone_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="deliverToPhoneNumberError = ''" id="deliverToPhoneNumber" v-model="deliverToPhoneNumber" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{deliverToPhoneNumberError}}</span>
                                </div>
                            </div>

                            <!-- Deliver To Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                            v-model="deliverToId"
                                            name="deliverToId"
                                            label="text"
                                            :filterable="false"
                                            :options="deliverToArr"
                                            @search="onSearchDeliverTo"
                                            :clearable="true"
                                            id="buyerId"
                                            :placeholder="allStaticMessages.customer_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isDeliverToRecordNotFound">
                                                {{allStaticMessages.customer_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Deliver To Address -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="deliverToAddress" v-model="deliverToAddress" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Deliver To Address 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="deliverToAddress2" v-model="deliverToAddress2" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Deliver To City -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="deliverToCityId" 
                                        name="deliverToCityId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="deliverToCityArr"
                                        @search="onSearchDeliverToCity"
                                        :clearable="false"
                                        id="deliverToCityId"
                                        :placeholder="allStaticMessages.city_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isDeliverToCityRecordNotFound">
                                                {{allStaticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="deliverToCity2" name="deliverToCity2" id="deliverToCity2" type="text" maxlength="190"/>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="deliverToCity3" name="deliverToCity3" id="deliverToCity3" type="text" maxlength="190"/>
                                </div>
                            </div>
                            
                            <!-- Internal Commnets -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.internal_comments_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea id="internalComments" v-model="internalComments" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!-- Commnets -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md- col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.comments_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea id="comments" v-model="comments" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!-- Commnets 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.comments2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea id="comments2" v-model="comments2" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!-- R.O.S Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.ros_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="rosNumber" v-model="rosNumber" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. Phone Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_phone_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusPhoneNumberError = ''" id="cusPhoneNumber" v-model="cusPhoneNumber" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusPhoneNumberError}}</span>
                                </div>
                            </div>

                            <!-- Cus. Fax -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_fax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusFax" v-model="cusFax" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. Cell -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_cell_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusCell" v-model="cusCell" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. SIN -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_sin_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusSIN" v-model="cusSIN" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. Tax Exemption Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.tax_exemption_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusTaxExemptionNumber" v-model="cusTaxExemptionNumber" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Cus. Address Type -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <!-- <div class="col-md-4 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_type_label}}</div>
                                </div> -->
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-form-radio-group v-model="cusAddressType" :options="cusAddressTypeOptions" name="cusAddressType"></b-form-radio-group>
                                </div>
                            </div>

                            <!-- Cus. Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusTaxRateError = ''" id="cusTaxRate" v-model="cusTaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusTaxRateError}}</span>
                                    &nbsp;
                                </div>
                                <div class="col-md-1 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="cusSalesTax" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <!--<div class="requiredstar">*</div>-->
                                    <!--<span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.applied_sales_tax_label}}</div>
                                </div>
                            </div>

                            <!-- Cus. State Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.state_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusStateTaxRateError = ''" id="cusStateTaxRate" v-model="cusStateTaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusStateTaxRateError}}</span>
                                </div>
                            </div>

                            <!-- Cus. County Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCountyTaxRateError = ''" id="cusCountyTaxRate" v-model="cusCountyTaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCountyTaxRateError}}</span>
                                </div>
                            </div>

                            <!-- Cus. City Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCityTaxRateError = ''" id="cusCityTaxRate" v-model="cusCityTaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCityTaxRateError}}</span>
                                </div>
                            </div>

                            <!-- Cus. Other Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.other_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusOtherTaxRateError = ''" id="cusOtherTaxRate" v-model="cusOtherTaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusOtherTaxRateError}}</span>
                                </div>
                            </div>

                            <!-- Cus. Other2 Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.other2_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusOther2TaxRateError = ''" id="cusOther2TaxRate" v-model="cusOther2TaxRate" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusOther2TaxRateError}}</span>
                                </div>
                            </div>

                            <!-- Cus. County Surtax -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_surtax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCountySurtaxError = ''" id="cusCountySurtax" v-model="cusCountySurtax" class="form-control" type="text" maxlength="12"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCountySurtaxError}}</span>
                                </div>
                            </div>

                            <!-- Cus. County Code -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_code_lable}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="cusCountyCode" v-model="cusCountyCode" class="form-control" type="text"/>
                                </div>
                            </div>

                            <!-- showOnBuyersOrder -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.show_on_buyers_order_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="showOnBuyersOrder" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- Deliver to Block End -->
                            
                            <!-- Cash Detail Block Start -->
                            <div class="col-md-12 col-sm-12 forms-block">
                                <div class="col-12 col-sm-12 paddingleft0">
                                    <div class="book-deal-customer-block text">Cash Detail</div>
                                </div>
                            </div>

                            <!-- Sales Price -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.sales_price_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="salesPriceError = ''" id="salesPrice" v-model="salesPrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{salesPriceError}}</span>
                                </div>
                            </div>
                            
                            <!-- Bonus -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.bonus_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="bonus_amount_error = ''" id="bonus_amount" v-model="bonus_amount" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{bonus_amount_error}}</span>
                                </div>
                            </div>
                            
                            <!-- extra_conquest -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.extra_conquest_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="extra_conquest_error = ''" id="extra_conquest" v-model="extra_conquest" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{extra_conquest_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Gross Profit -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.gross_profit_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="gross_profit_error = ''" id="gross_profit" v-model="gross_profit" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{gross_profit_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Stamp Duty Type -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.stamp_duty_type_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select
                                        v-model="stampDutyType"
                                        @change="calculateQuot"
                                        name="stampDutyType"
                                        id="stampDutyType"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="stampDutyTypeArr"
                                        :placeholder="allStaticMessages.stamp_duty_type_place_holder">
                                    </v-select>
                                </div>
                            </div>
                            
                            <!-- Rego -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.rego_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="rego_error = ''" id="rego" v-model="rego" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{rego_error}}</span>
                                </div>
                            </div>
                            
                            <!-- CTP -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.ctp_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="ctp_error = ''" id="ctp" v-model="ctp" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{ctp_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Downpayment Amt -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.down_payment_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="downpaymentAmtError = ''" id="downpaymentAmt" v-model="downpaymentAmt" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{downpaymentAmtError}}</span>
                                </div>
                            </div>
                            
                            <!-- Trade Detail -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.trade_detail_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="trade_detail" v-model="trade_detail" class="form-control dealinputs text-align-left" type="text" maxlength="190"/>
                                </div>
                            </div>
                            
                            <!-- Approx Trade Value -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.trade_value_inc_gst_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="trade_value_inc_gst_error = ''" id="trade_value_inc_gst" v-model="trade_value_inc_gst" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{trade_value_inc_gst_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Over Allowance -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.over_allowance_inc_gst_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="over_allowance_inc_gst_error = ''" id="over_allowance_inc_gst" v-model="over_allowance_inc_gst" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{over_allowance_inc_gst_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Trade Allowance -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.trade_allowance_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="tradeAllownceError = ''" id="tradeAllownce" v-model="tradeAllownce" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{tradeAllownceError}}</span>
                                </div>
                            </div>
                            
                            <!-- Trade Payout -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.trade_payout_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="trade_payout_error = ''" id="trade_payout" v-model="trade_payout" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{trade_payout_error}}</span>
                                </div>
                            </div>
                            
                            <!-- Total Fees -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_fees_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalFeesError = ''" id="totalFees" v-model="totalFees" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{totalFeesError}}</span>
                                </div>
                            </div>
                            
                            <!-- Total Sales Tax -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_sales_tax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalSalesTaxError = ''" id="totalSalesTax" v-model="totalSalesTax" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{totalSalesTaxError}}</span>
                                </div>
                            </div>
                            
                            <!-- Total -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalError = ''" id="total" v-model="total" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{totalError}}</span>
                                </div>
                            </div>
                            
                            <!-- Warranties -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.warranties_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="warrantiesError = ''" id="warranties" v-model="warranties" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{warrantiesError}}</span>
                                </div>
                            </div>
                            
                            <!-- Insurance -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.insurance_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="insuranceError = ''" id="insurance" v-model="insurance" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{insuranceError}}</span>
                                </div>
                            </div>
                            
                            <!-- Total + Warranties -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_warranties_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalWarrantiesError = ''" id="totalWarranties" v-model="totalWarranties" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{totalWarrantiesError}}</span>
                                </div>
                            </div>
                            
                            <!-- Cash Detail Block End -->
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <!-- <input id="saveDealBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="saveDeal()">
                            <input id="addAndContinueDealBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="saveDeal(flag='Save and Continue')">
                            <input id="cancelDeal" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack"> -->
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <!-- <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer> -->
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateOpportunityDeals',
        components: {
            datePicker,
            'no-access' : NoAccessPage,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Deal",
                permissionNameArray : ['can_add_deals'],

                selectedRows: [],
                selectAll: false,
                add_to_forecast : true,
                dealNo: "",
                companyId: "",
                companyArr: [],
                isCompanyRecordNotFound: false,
                dealStatusId: "",
                dealStatusIdValue: "",
                dealStatusArr: [],
                statusError: "",
                isDealStatusRecordNotFound: false,
                contractNumber: "",
                contractDate: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                rosNumber: "",
                salePersonId: "",
                salePersonArr: [],
                isSalePersonRecordNotFound: false,
                cusPhoneNumber: "",
                cusPhoneNumberError: "",
                customerId: "",
                customerArr: [],
                isCustomerRecordNotFound: false,
                individual: false,
                individualValue: "",
                cusFax: "",
                cusFaxError: "",
                cusAddress: "",
                cusCell: "",
                cusAddress2: "",
                cusSIN: "",
                customerCityId: "",
                customerCityArr: [],
                isCustomerCityRecordNotFound: false,
                cusCity2: "",
                cusCity3: "",
                cusTaxExemptionNumber: "",
                cusAltCityName: "",
                cusAddressType: "main address",
                cusAddressTypeOptions: [
                    {text: 'Custom', value: 'custom address'},
                    {text: 'Delivery Address', value: 'delivery address'},
                    {text: 'Main Address', value: 'main address'},
                ],
                customerCountyId: "",
                customerCountyArr: [],
                customerCountyError: "",
                isCustomerCountyRecordNotFound: false,
                cusTaxRate: "",
                cusTaxRateError: "",
                cusSalesTax: true,
                cusStateTaxRate: "",
                cusStateTaxRateError: "",
                cusCountyTaxRate: "",
                cusCountyTaxRateError: "",
                cusAltCountyName: "",
                cusCityTaxRate: "",
                cusCityTaxRateError: "",
                cusOtherTaxRate: "",
                cusOtherTaxRateError: "",
                cusOther2TaxRate: "",
                cusOther2TaxRateError: "",
                customerEmail: "",
                customerEmailError: "",
                cusCountySurtax: "",
                cusCountySurtaxError: "",
                cusCountyCode: "",
                cusCountyCodeError: "",

                // Buyer
                buyerNumber: "",
                buyerId: "",
                buyerArr: [],
                isBuyerRecordNotFound: false,
                buyerAddress: "",
                buyerCell: "",
                buyerCellError: "",
                buyerAddress2: "",
                buyerSIN: "",
                buyerSINError: "",
                buyerCityId: "",
                buyerCityArr: [],
                isBuyerCityRecordNotFound: false,
                buyerCity2: "",
                buyerCity3: "",
            
                // Deliver To
                deliverToPhoneNumber: "",
                deliverToPhoneNumberError: "",
                deliverToId: "",
                deliverToArr: [],
                isDeliverToRecordNotFound: false,
                deliverToIndividual: false,
                deliverToFax: "",
                deliverToFaxError: "",
                deliverToAddress: "",
                deliverToCell: "",
                deliverToCellError: "",
                deliverToAddress2: "",
                deliverToSIN: "",
                deliverToSINError: "",
                deliverToCityId: "",
                deliverToCityArr: [],
                isDeliverToCityRecordNotFound: false,
                deliverToCity2: "",
                deliverToCity3: "",
                deliverToTaxExemptionNumber: "",
                deliverToTaxExemptionNumberError: "",
                deliverToAltCityName: "",
                deliverToAltCityNameError: "",
                deliverToAddressType: "main address",
                deliverToAddressTypeOptions: [
                    {text: 'Custom', value: 'custom address'},
                    {text: 'Delivery Address', value: 'delivery address'},
                    {text: 'Main Address', value: 'main address'},
                ],
                deliverToCountyId: "",
                deliverToCountyArr: [],
                deliverToCountyError: "",
                isDeliverToCountyRecordNotFound: false,
                deliverToTaxRate: "",
                deliverToTaxRateError: "",
                deliverToSalesTax: true,
                deliverToStateTaxRate: "",
                deliverToStateTaxRateError: "",
                deliverToCountyTaxRate: "",
                deliverToCountyTaxRateError: "",
                deliverToAltCountyName: "",
                deliverToAltCountyNameError: "",
                deliverToCityTaxRate: "",
                deliverToCityTaxRateError: "",
                deliverToOtherTaxRate: "",
                deliverToOtherTaxRateError: "",
                deliverToOther2TaxRate: "",
                deliverToOther2TaxRateError: "",
                deliverToEmail: "",
                deliverToEmailError: "",
                deliverToCountySurtax: "",
                deliverToCountySurtaxError: "",
                deliverToCountyCode: "",
                deliverToCountyCodeError: "",

                internalComments: "",
                comments: "",
                showOnBuyersOrder: false,
                comments2: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel',
                    copyVehicle: 'Copy Vehicle',
                    searchStockVehicles: 'Search Stock Vehicles',
                    updateVinOrStock: 'Update Vin#/Stock#',
                    lease: 'Lease',
                    finance: 'Finance',
                    cash: 'Cash',
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false,
                    copyVehicle: false,
                    searchStockVehicles: false,
                    updateVinOrStock: false,
                    lease: false,
                    finance: false,
                    cash: false,
                },
                paymentType: "",
                paymentTypeArr: [],
                paymentTypeError: "",
                downpayment: "",
                downpaymentError: "",
                dueOnDelivery: "",
                dueOnDeliveryError: "",
                // Cash Details
                salesPrice: 0.00,
                salesPriceError: "",
                downpaymentAmt: 0.00,
                downpaymentAmtError: "",
                tradeAllownce: 0.00,
                tradeAllownceError: "",
                totalFees: 0.00,
                totalFeesError: "",
                totalSalesTax: 0.00,
                totalSalesTaxError: "",
                total: 0.00,
                totalError: "",
                warranties: 0.00,
                warrantiesError: "",
                insurance: 0.00,
                insuranceError: "",
                totalWarranties: 0.00,
                totalWarrantiesError: "",

                assetId: 0,
                selectedOpportunityId: 0,
                editAssetId: 0,
                dealId: 0,
                dealData: "",
                conditionId : {text: "New", value: '1'},
                rrp:0,
                gst:0,
                list_price:0,
                dealer_discount:0,
                dealer_net:0,
                conquest:0,
                fituptotal:0,
                pre_delivery:0,
                bonus_amount:0.00,
                bonus_amount_error:"",
                extra_conquest:0.00,
                extra_conquest_error:"",
                sub_total1 : 0.00,
                overallowance : 0.00,
                gross_profit : 0.00,
                gross_profit_error:"",
                gross_profit_percentage : 0.00,
                sub_total_ex_gst : 0.00,
                total_gst : 0.00,
                sub_total2 : 0.00,
                stampDutyPercentage : 0.00,
                stampDutyType : {text: 'Exempt', value: 'exempt'},
                stampDutyTypeArr : [
                    {text: 'New/Demo', value: 'new/demo'},
                    {text: 'Exempt', value: 'exempt'},
                ],
                rego : 0.00,
                rego_error:"",
                ctp : 0.00,
                ctp_error:"",
                stamp_duty : 0.00,
                trade_detail : "",
                trade_value_inc_gst : 0.00,
                trade_value_inc_gst_error:"",
                over_allowance_inc_gst : 0.00,
                over_allowance_inc_gst_error:"",
                trade_payout : 0.00,
                trade_payout_error:"",
            }
        },
        watch: {
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('deals-list');
            EventBus.$emit("menu-selection-changed", "deals-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getDealDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueDealBtn").hide();
                self.pageTitle = "Edit Deal";
            }
            self.loadAllData(['Company', 'DealStatus','User', 'Contact', 'Cities', 'Countries'], false, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push('/deal/list');
            },
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.isCompanyRecordNotFound = self.isDealStatusRecordNotFound = false;
                self.isSalePersonRecordNotFound = self.isCustomerRecordNotFound = self.isCustomerCityRecordNotFound = self.isCustomerCountyRecordNotFound = false;
                self.isBuyerRecordNotFound = self.isBuyerCityRecordNotFound = false;
                self.isDeliverToRecordNotFound = self.isDeliverToCityRecordNotFound = false;

                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
                self.dealStatusArr = callbackData.DealStatus;
                if(self.dealStatusArr.length == 0) {
                    self.isDealStatusRecordNotFound = true;
                }
                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                }
                // Buyer
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
                // Deliver To
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            onSearchDealStatus(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['DealStatus'], true, self.loadStatusDataCallback, loading, search,"","","","","","",self.conditionId.text.toLowerCase());
                }
            },
            loadStatusDataCallback: function (callbackData) {
                self.isDealStatusRecordNotFound = false;
                self.dealStatusArr = callbackData.DealStatus;
                if(self.dealStatusArr.length == 0) {
                    self.isDealStatusRecordNotFound = true;
                }
            },
            onSearchSalePerson(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalePersonCallback, loading, search);
                }
            },
            loadSalePersonCallback: function(callbackData) {
                self.isSalePersonRecordNotFound = false;
                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadCustomerCallback, loading, search);
                }
            },
            loadCustomerCallback: function(callbackData) {
                self.isCustomerRecordNotFound = false;
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
            },
            onSearchCustomerCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadCustomerCityCallback, loading, search);
                }
            },
            loadCustomerCityCallback: function(callbackData) {
                self.isCustomerCityRecordNotFound = false;
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
            },
            onSearchCustomerCounty(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Countries'], true, self.loadCustomerCountyCallback, loading, search);
                }
            },
            loadCustomerCountyCallback: function(callbackData) {
                self.isCustomerCountyRecordNotFound = false;
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                }
            },
            // Buyer
            onSearchBuyer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadBuyerCallback, loading, search);
                }
            },
            loadBuyerCallback: function(callbackData) {
                self.isBuyerRecordNotFound = false;
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
            },
            onSearchBuyerCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadBuyerCityCallback, loading, search);
                }
            },
            loadBuyerCityCallback: function(callbackData) {
                self.isBuyerCityRecordNotFound = false;
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
            },
            // Deliver To
            onSearchDeliverTo(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadDeliverToCallback, loading, search);
                }
            },
            loadDeliverToCallback: function(callbackData) {
                self.isDeliverToRecordNotFound = false;
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
            },
            onSearchDeliverToCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadDeliverToCityCallback, loading, search);
                }
            },
            loadDeliverToCityCallback: function(callbackData) {
                self.isDeliverToCityRecordNotFound = false;
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
            },
            clearDataOnSaveAndContinue : function () {
                // self.selectedOpportunityId = '';
                self.add_to_forecast = true;
                self.dealNo = '';
                self.companyId = '';
                self.dealStatusId = '';
                self.contractNumber = '';
                self.contractDate = '';
                self.rosNumber = '';
                self.salePersonId = '';
                self.cusPhoneNumber = '';
                self.customerId = '';
                self.individual = '';
                self.individualValue = '';
                self.cusFax = '';
                self.cusAddress = '';
                self.cusCell = '';
                self.cusAddress2 = '';
                self.cusSIN = '';
                self.customerCityId = '';
                self.cusCity2 = '';
                self.cusCity3 = '';
                self.cusTaxExemptionNumber = '';
                self.cusAltCityName = '';
                self.cusAddressType = 'main address';
                self.customerCountyId = '';
                self.cusTaxRate = '';
                self.cusSalesTax = true;
                self.cusStateTaxRate = '';
                self.cusAltCountyName = '';
                self.cusCountyTaxRate = '';
                self.cusCityTaxRate = '';
                self.cusOtherTaxRate = '';
                self.cusOther2TaxRate = '';
                self.customerEmail = '';
                self.cusCountySurtax = '';
                self.cusCountyCode = '';
                // CO-Buyer
                self.buyerNumber = '';
                self.buyerId = '';
                self.buyerAddress = '';
                self.buyerAddress2 = '';
                self.buyerCityId = '';
                self.buyerCity2 = '';
                self.buyerCity3 = '';
                // Deliver-To
                self.deliverToPhoneNumber = '';
                self.deliverToId = '';
                self.deliverToAddress = '';
                self.deliverToAddress2 = '';
                self.deliverToCityId = '';
                self.deliverToCity2 = '';
                self.deliverToCity3 = '';
                self.internalComments = '';
                self.comments = '';
                self.comments2 = '';
                self.showOnBuyersOrder = false;
                // Cash Detail
                self.salesPrice = '';
                self.downpaymentAmt = '';
                self.tradeAllownce = '';
                self.totalFees = '';
                self.totalSalesTax = '';
                self.total = '';
                self.warranties = '';
                self.insurance = '';
                self.totalWarranties = '';
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getDealDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id,
                    }
                };
                HTTP.get('/deal/getbyid', config)
                    .then(response => {
                        self.dealData = response.data.content;
                        self.loadDealData();
                    })
                    .catch(function (error) {
                        self.catchHandler(error, function () {});
                    });
            },
            loadDealData: function() {
                /* console.log(JSON.stringify(self.dealData)); */
                self.conditionId = {text: "New", value: '1'};
                self.add_to_forecast = (self.dealData.is_forecast == 'yes') ? true : false;
                self.dealNo = self.dealData.deal_no;
                self.companyId = (self.dealData.department_id != null && self.dealData.department_id != 'undefined' && self.dealData.department_id != 0) ? {text: self.dealData.department, value: self.dealData.department_id} : "";
                self.dealStatusId = (self.dealData.deal_status_id != null && self.dealData.deal_status_id != 'undefined' && self.dealData.deal_status_id != 0) ? {text: self.dealData.deal_status_name, value: self.dealData.deal_status_id} : "";
                self.contractNumber = self.dealData.contract_no;
                self.contractDate = self.dealData.contract_date;
                self.rosNumber = self.dealData.ros_no;
                self.salePersonId = (self.dealData.salesperson_id != null && self.dealData.salesperson_id != 'undefined' && self.dealData.salesperson_id != 0) ? {text: self.dealData.sales_person, value: self.dealData.salesperson_id} : "";
                self.cusPhoneNumber = self.dealData.cus_phone;
                self.customerId = (self.dealData.contact_id != null && self.dealData.contact_id != 'undefined' && self.dealData.contact_id != 0) ? {text: self.dealData.customer_name, value: self.dealData.contact_id} : "";
                self.individual = (self.dealData.is_individual == 'yes') ? true : false;
                self.individualValue = self.dealData.individual;
                self.cusFax = self.dealData.cus_fax;
                self.cusAddress = self.dealData.cus_address;
                self.cusCell = self.dealData.cus_cell;
                self.cusAddress2 = self.dealData.cus_address2;
                self.cusSIN = self.dealData.cus_sin;
                self.customerCityId = (self.dealData.customer_city_id != null && self.dealData.customer_city_id != 'undefined' && self.dealData.customer_city_id != 0) ? {text: self.dealData.customer_city_name, value: self.dealData.customer_city_id} : "";
                self.cusCity2 = self.dealData.city2;
                self.cusCity3 = self.dealData.city3;
                self.cusTaxExemptionNumber = self.dealData.cus_tax_exemption_number;
                self.cusAltCityName = self.dealData.alt_city;
                self.cusAddressType = self.dealData.address_type;
                self.customerCountyId = (self.dealData.customer_country_id != null && self.dealData.customer_country_id != 'undefined' && self.dealData.customer_country_id != 0) ? {text: self.dealData.customer_country_name, value: self.dealData.customer_country_id} : "";
                self.cusTaxRate = self.dealData.tax_rate;
                self.cusSalesTax = (self.dealData.apply_sales_tax == 'yes') ? true : false;
                self.cusStateTaxRate = self.dealData.state_tax_rate;
                self.cusAltCountyName = self.dealData.alt_county;
                self.cusCountyTaxRate = self.dealData.county_tax_rate;
                self.cusCityTaxRate = self.dealData.city_tax_rate;
                self.cusOtherTaxRate = self.dealData.other_tax_rate;
                self.cusOther2TaxRate = self.dealData.other2_tax_rate;
                self.customerEmail = self.dealData.cus_email;
                self.cusCountySurtax = self.dealData.county_surtax;
                self.cusCountyCode = self.dealData.county_code;
                // CO-Buyer
                self.buyerNumber = self.dealData.co_buyer;
                self.buyerId = (self.dealData.co_buyer_contact_id != null && self.dealData.co_buyer_contact_id != 'undefined' && self.dealData.co_buyer_contact_id != 0) ? {text: self.dealData.cobuyer_name, value: self.dealData.co_buyer_contact_id} : "";
                self.buyerAddress = self.dealData.co_buyer_cus_address;
                self.buyerAddress2 = self.dealData.co_buyer_cus_address2;
                self.buyerCityId = (self.dealData.cobuyer_city_id != null && self.dealData.cobuyer_city_id != 'undefined' && self.dealData.cobuyer_city_id != 0) ? {text: self.dealData.cobuyer_city_name, value: self.dealData.cobuyer_city_id} : "";
                self.buyerCity2 = self.dealData.co_buyer_city2;
                self.buyerCity3 = self.dealData.co_buyer_city3;
                // Deliver-To
                self.deliverToPhoneNumber = self.dealData.del_to_phone;
                self.deliverToId = (self.dealData.del_to_contact_id != null && self.dealData.del_to_contact_id != 'undefined' && self.dealData.del_to_contact_id != 0) ? {text: self.dealData.dealer_name, value: self.dealData.del_to_contact_id} : "";
                self.deliverToAddress = self.dealData.del_to_cus_address;
                self.deliverToAddress2 = self.dealData.del_to_cus_address2;
                self.deliverToCityId = (self.dealData.dealer_city_id != null && self.dealData.dealer_city_id != 'undefined' && self.dealData.dealer_city_id != 0) ? {text: self.dealData.dealer_city_name, value: self.dealData.dealer_city_id} : "";
                self.deliverToCity2 = self.dealData.del_to_city2;
                self.deliverToCity3 = self.dealData.del_to_city3;
                self.internalComments = self.dealData.internal_comments;
                self.comments = self.dealData.comments;
                self.comments2 = self.dealData.comments2;
                self.showOnBuyersOrder = (self.dealData.show_on_buyer_order == 'yes') ? true : false;
                // Cash Detail
                self.salesPrice = self.convertToNum(self.dealData.sales_price,2);
                self.downpaymentAmt = self.convertToNum(self.dealData.down_payment,2);
                self.tradeAllownce = self.convertToNum(self.dealData.trade_allowance,2);
                self.totalFees = self.convertToNum(self.dealData.total_fees,2);
                self.totalSalesTax = self.convertToNum(self.dealData.total_sales_tax,2);
                self.total = self.convertToNum(self.dealData.total,2);
                self.warranties = self.convertToNum(self.dealData.warranties,2);
                self.insurance = self.convertToNum(self.dealData.insurance,2);
                self.totalWarranties = self.convertToNum(self.dealData.total_plus_wattanties,2);
                self.bonus_amount = self.convertToNum(self.dealData.bonus,2);
                self.extra_conquest = self.convertToNum(self.dealData.extra_conquest,2);
                self.gross_profit = self.convertToNum(self.dealData.gross_profit,2);
                
                self.stampDutyType  = {text: 'New/Demo', value: 'new/demo'};
                if(self.dealData.stamp_duty_type == 'exempt'){
                    self.stampDutyType  = {text: 'Exempt', value: 'exempt'};
                }
                
                self.rego = self.convertToNum(self.dealData.rego,2);
                self.ctp = self.convertToNum(self.dealData.ctp,2);
                self.trade_detail = self.dealData.trade_details;
                self.trade_value_inc_gst = self.convertToNum(self.dealData.approx_trade_value,2);
                self.over_allowance_inc_gst = self.convertToNum(self.dealData.over_allowance,2);
                self.trade_payout = self.convertToNum(self.dealData.trade_payout,2);
                /* console.log(JSON.stringify(self.dealData.deal_inventory_data)); */
            },
            checkForm: function () {
                var checked = true;

                /* if (self.selectedOpportunityId == 0) {
                    // self.assetError = "Please select Opportunity";
                    self.showSAlert("Please Select Opportunity", 'info', false);
                    if (checked) {
                        checked = false;
                    }
                } */
                self.cusPhoneNumberError = "";
                if(self.cusPhoneNumber && !self.validContactNumber(self.cusPhoneNumber)) {
                    self.cusPhoneNumberError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.cusPhoneNumber && !self.checkContactNoLength(self.cusPhoneNumber)) {
                    self.cusPhoneNumberError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusTaxRateError = "";
                if(self.cusTaxRate && !self.numberFormateValidation(self.cusTaxRate)) {
                    self.cusTaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusStateTaxRateError = "";
                if(self.cusStateTaxRate && !self.numberFormateValidation(self.cusStateTaxRate)) {
                    self.cusStateTaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusCountyTaxRateError = "";
                if(self.cusCountyTaxRate && !self.numberFormateValidation(self.cusCountyTaxRate)) {
                    self.cusCountyTaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusCityTaxRateError = "";
                if(self.cusCityTaxRate && !self.numberFormateValidation(self.cusCityTaxRate)) {
                    self.cusCityTaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusOtherTaxRateError = "";
                if(self.cusOtherTaxRate && !self.numberFormateValidation(self.cusOtherTaxRate)) {
                    self.cusOtherTaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusOther2TaxRateError = "";
                if(self.cusOther2TaxRate && !self.numberFormateValidation(self.cusOther2TaxRate)) {
                    self.cusOther2TaxRateError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.customerEmailError = "";
                if (self.customerEmail && !self.validEmail(self.customerEmail)) {
                    self.customerEmailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusCountySurtaxError = "";
                if(self.cusCountySurtax && !self.numberFormateValidation(self.cusCountySurtax)) {
                    self.cusCountySurtaxError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.deliverToPhoneNumberError = "";
                if(self.deliverToPhoneNumber && !self.validContactNumber(self.deliverToPhoneNumber)) {
                    self.deliverToPhoneNumberError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.deliverToPhoneNumber && !self.checkContactNoLength(self.deliverToPhoneNumber)) {
                    self.deliverToPhoneNumberError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.salesPriceError = "";
                if(self.salesPrice && !self.numberFormateValidation(self.salesPrice)) {
                    self.salesPriceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.downpaymentAmtError = "";
                if(self.downpaymentAmt && !self.numberFormateValidation(self.downpaymentAmt)) {
                    self.downpaymentAmtError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.tradeAllownceError = "";
                if(self.tradeAllownce && !self.numberFormateValidation(self.tradeAllownce)) {
                    self.tradeAllownceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.totalFeesError = "";
                if(self.totalFees && !self.numberFormateValidation(self.totalFees)) {
                    self.totalFeesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.totalSalesTaxError = "";
                if(self.totalSalesTax && !self.numberFormateValidation(self.totalSalesTax)) {
                    self.totalSalesTaxError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.totalError = "";
                if(self.total && !self.numberFormateValidation(self.total)) {
                    self.totalError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.warrantiesError = "";
                if(self.warranties && !self.numberFormateValidation(self.warranties)) {
                    self.warrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.insuranceError = "";
                if(self.insurance && !self.numberFormateValidation(self.insurance)) {
                    self.insuranceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.totalWarrantiesError = "";
                if(self.totalWarranties && !self.numberFormateValidation(self.totalWarranties)) {
                    self.totalWarrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.bonus_amount_error = "";
                if(self.bonus_amount && !self.numberFormateValidation(self.bonus_amount)) {
                    self.bonus_amount_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.extra_conquest_error = "";
                if(self.extra_conquest && !self.numberFormateValidation(self.extra_conquest)) {
                    self.extra_conquest_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.gross_profit_error = "";
                if(self.gross_profit && !self.numberFormateValidation(self.gross_profit)) {
                    self.gross_profit_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.rego_error = "";
                if(self.rego && !self.numberFormateValidation(self.rego)) {
                    self.rego_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.ctp_error = "";
                if(self.ctp && !self.numberFormateValidation(self.ctp)) {
                    self.ctp_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.trade_value_inc_gst_error = "";
                if(self.trade_value_inc_gst && !self.numberFormateValidation(self.trade_value_inc_gst)) {
                    self.trade_value_inc_gst_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.over_allowance_inc_gst_error = "";
                if(self.over_allowance_inc_gst && !self.numberFormateValidation(self.over_allowance_inc_gst)) {
                    self.over_allowance_inc_gst_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.trade_payout_error = "";
                if(self.trade_payout && !self.numberFormateValidation(self.trade_payout)) {
                    self.trade_payout_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            calculateQuot: function(){
                // console.log("calculateQuot");
                self.salesPrice = self.convertToNum((parseFloat(self.salesPrice)),2);
                self.bonus_amount = self.convertToNum((parseFloat(self.bonus_amount)),2);
                self.extra_conquest = self.convertToNum((parseFloat(self.extra_conquest)),2);
                self.gross_profit = self.convertToNum((parseFloat(self.gross_profit)),2);
                self.rego = self.convertToNum((parseFloat(self.rego)),2);
                self.ctp = self.convertToNum((parseFloat(self.ctp)),2);
                self.downpaymentAmt = self.convertToNum((parseFloat(self.downpaymentAmt)),2);
                self.trade_value_inc_gst = self.convertToNum((parseFloat(self.trade_value_inc_gst)),2);
                self.over_allowance_inc_gst = self.convertToNum((parseFloat(self.over_allowance_inc_gst)),2);
                
                self.trade_payout = self.convertToNum((parseFloat(self.trade_payout)),2);
                self.totalFees = self.convertToNum((parseFloat(self.totalFees)),2);
                self.totalSalesTax = self.convertToNum((parseFloat(self.totalSalesTax)),2);
                self.warranties = self.convertToNum((parseFloat(self.warranties)),2);
                self.insurance = self.convertToNum((parseFloat(self.insurance)),2);

                self.total = self.convertToNum((parseFloat(self.sub_total2) + parseFloat(self.stamp_duty) + parseFloat(self.rego) + parseFloat(self.ctp) + parseFloat(self.totalFees) + parseFloat(self.totalSalesTax) + parseFloat(self.insurance) - parseFloat(self.downpaymentAmt)),2);
                self.tradeAllownce = self.convertToNum((parseFloat(self.trade_value_inc_gst) + parseFloat(self.over_allowance_inc_gst)),2);

                self.totalWarranties = self.convertToNum((parseFloat(self.total) + parseFloat(self.warranties)),2);
            },
            saveDeal (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var add_to_forecast = (self.add_to_forecast) ? 'yes' : 'no';
                        var data = new FormData();
                        data.append('add_to_forecast', add_to_forecast);
                        data.append('deal_no', self.dealNo);
                        data.append('company_id', self.companyId.value);
                        data.append('deal_status_id', self.dealStatusId.value);
                        data.append('contract_number', self.contractNumber);
                        data.append('contract_date', self.contractDate);
                        data.append('ros_number', self.rosNumber);
                        data.append('saleperson_id', self.salePersonId.value);
                        data.append('cus_phone_number', self.cusPhoneNumber);
                        data.append('customer_id', self.customerId.value);
                        data.append('individual', self.individual);
                        data.append('individual_value', self.individualValue);
                        data.append('cus_fax', self.cusFax);
                        data.append('cus_address', self.cusAddress);
                        data.append('cus_cell', self.cusCell);
                        data.append('cus_address_2', self.cusAddress2);
                        data.append('cus_sin', self.cusSIN);
                        data.append('cus_city_id', self.customerCityId.value);
                        data.append('cus_city_2', self.cusCity2);
                        data.append('cus_city_3', self.cusCity3);
                        data.append('cus_tax_exemption_number', self.cusTaxExemptionNumber);
                        data.append('cus_alt_city_name', self.cusAltCityName);
                        data.append('cus_address_type', self.cusAddressType);
                        data.append('customer_county_id', self.customerCountyId.value);
                        data.append('cus_tax_rate', self.cusTaxRate);
                        data.append('cus_sales_tax', self.cusSalesTax);
                        data.append('cus_state_tax_rate', self.cusStateTaxRate);
                        data.append('cus_alt_county_name', self.cusAltCountyName);
                        data.append('cus_county_tax_rate', self.cusCountyTaxRate);
                        data.append('cus_city_tax_rate', self.cusCityTaxRate);
                        data.append('cus_other_tax_rate', self.cusOtherTaxRate);
                        data.append('cus_other_2_tax_rate', self.cusOther2TaxRate);
                        data.append('customer_email', self.customerEmail);
                        data.append('cus_county_surtax', self.cusCountySurtax);
                        data.append('cus_county_code', self.cusCountyCode);
                        // CO-Buyer
                        data.append('buyer_number', self.buyerNumber);
                        data.append('buyer_id', self.buyerId.value);
                        data.append('buyer_address', self.buyerAddress);
                        data.append('buyer_address_2', self.buyerAddress2);
                        data.append('buyer_city_id', self.buyerCityId.value);
                        data.append('buyer_city_2', self.buyerCity2);
                        data.append('buyer_city_3', self.buyerCity3);
                        // Deliver-To
                        data.append('deliver_to_phone_number', self.deliverToPhoneNumber);
                        data.append('deliver_to_id', self.deliverToId.value);
                        data.append('deliver_to_address', self.deliverToAddress);
                        data.append('deliver_to_address_2', self.deliverToAddress2);
                        data.append('deliver_to_city_id', self.deliverToCityId.value);
                        data.append('deliver_to_city_2', self.deliverToCity2);
                        data.append('deliver_to_city_3', self.deliverToCity3);
                        data.append('internal_comments', self.internalComments);
                        data.append('comments', self.comments);
                        data.append('comments_2', self.comments2);
                        data.append('show_on_buyers_order', self.showOnBuyersOrder);
                        // Cash Detail
                        data.append('sales_price', self.salesPrice);
                        data.append('downpaymentAmt', self.downpaymentAmt);
                        data.append('trade_allownce', self.tradeAllownce);
                        data.append('total_fees', self.totalFees);
                        data.append('total_sales_tax', self.totalSalesTax);
                        data.append('total', self.total);
                        data.append('warranties', self.warranties);
                        data.append('insurance', self.insurance);
                        data.append('total_warranties', self.totalWarranties);
                        data.append('bonus_amount' , self.bonus_amount);
                        data.append('extra_conquest' , self.extra_conquest);
                        data.append('gross_profit' , self.gross_profit);
                        data.append('stamp_duty_type' , self.stampDutyType.value);
                        data.append('rego' , self.rego);
                        data.append('ctp' , self.ctp);
                        data.append('trade_detail' , self.trade_detail);
                        data.append('trade_value_inc_gst' , self.trade_value_inc_gst);
                        data.append('over_allowance_inc_gst' , self.over_allowance_inc_gst);
                        data.append('trade_payout' , self.trade_payout);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/deal/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>