<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_truck_trailer_search_made_easy')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="salesmanId"
                                        name="salesmanId"
                                        id="salesmanId"
                                        label="text"
                                        @change="changedValue"
                                        @input="salesmanIdError = ''"
                                        :filterable="false"
                                        :options="salesmanIdArr"
                                        @search="onSearchSalesmanUser"
                                        :placeholder="staticMessages.salesman_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesmanNameRecordNotFound">
                                                {{staticMessages.salesman_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesmanIdError}}</span>
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="status"
                                        @change="changedValue"
                                        name="status"
                                        id="status"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="statusArr"
                                        placeholder="Select Status">
                                    </v-select>
                                </div>
                            </div>

                            <!-- Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>

                            <!-- email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>

                            <!-- phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phoneError = ''" id="phone" v-model="phone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phoneError}}</span>
                                </div>
                            </div>

                            <!-- Comment -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.comment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="comment" v-model="comment" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!-- Note -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="note" v-model="note" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addTruckTrailerSearchMadeEasy()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUdpateTruckTrailerSearchMadeEasy',
        mixins: [commonMixins],
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                status: {
                    text: "Working On",
                    value: "working_on"
                },
                statusArr: [
                    {
                        text: "Cancelled",
                        value: "cancelled"
                    },
                    {
                        text: "Sold",
                        value: "sold"
                    },
                    {
                        text: "Working On",
                        value: "working_on"
                    }
                ],
                name: "",
                email: "",
                phone: "",
                comment: "",
                note: "",

                // Error
                salesmanIdError: "",
                nameError: "",
                emailError: "",
                phoneError: "",
                // 
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add Truck and Trailer Search Made Easy',
                permissionNameArray: ['can_add_truck_trailer_search_made_easy'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.getLoginIdPermissions();
            self.setActiveMenu('truck_trailer_search_made_easy');
            EventBus.$emit("menu-selection-changed", "truck_trailer_search_made_easy");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getTruckTrailerSearchMadeEasyDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Truck & Trailer Search Made Easy";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListTruckTrailerSearchMadeEasy'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function (callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            getTruckTrailerSearchMadeEasyDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/trucktrailersearchmadeeasy/get', config)
                        .then(response => {
                            if (response.data.content.salesmanId != null) {
                                self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesmanId};
                            }
                            self.status = {text: self.formatString(response.data.content.status), value: response.data.content.status};
                            self.name = response.data.content.name;
                            self.email = response.data.content.email;
                            self.phone = response.data.content.phone;
                            self.comment = response.data.content.comment;
                            self.note = response.data.content.note;
                            self.companyId = response.data.content.company_id;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            checkForm: function () {
                var checked = true;
                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.phone) {
                    self.phoneError = "Please enter phone";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.phone && !self.validNumeric(self.phone)) {
                 self.phoneError = "Please enter valid phone";
                 if (checked) {
                 checked = false;
                 }
                 } */
                return checked;
            },
            addTruckTrailerSearchMadeEasy: function () {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('status', self.status.value);
                        data.append('name', self.name);
                        data.append('email', self.email);
                        data.append('phone', self.phone);
                        data.append('comment', self.comment);
                        data.append('note', self.note);

                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/trucktrailersearchmadeeasy/add/update", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    /* console.log(err); */
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
        }
    });
</script>