<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_buy_truck_submission_form')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <template v-if="permissionNameArray.includes('can_list_buy_truck_submission_form')">
                        <button id="backBtn" type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </template>
                </div>

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!-- Buyer Name -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.buyer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="buyerName"
                                            name="asset"
                                            label="text"
                                            @input="buyerNameError = ''"
                                            @search="onSearchBuyerName"
                                            :filterable="false"
                                            :options="buyerArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.buyer_name_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isBuyerNameRecordNotFound">
                                                {{staticMessages.buyer_name_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{buyerNameError}}</span>
                                </div>
                            </div>

                            <!--Quantity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.quantity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="quantityError = ''" id="quantity" v-model="quantity" class="form-control" type="text" maxlength="2"/>
                                    <span class="help is-danger">{{quantityError}}</span>
                                </div>
                            </div>

                            <!-- Year -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            id="year"
                                            v-model="year"
                                            :config="yearOptions"
                                            class="form-control"
                                            autocomplete="off"
                                            placeholder="Year"
                                            name="issueDate"
                                            @input="yearError = ''"
                                    >
                                    </date-picker>
                                    <!-- <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text" maxlength="190"/> -->
                                    <span class="help is-danger">{{yearError}}</span>
                                </div>
                            </div>

                            <!-- Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="make" v-model="make" class="form-control" type="text" maxlength="190"/> -->
                                    <v-select 
                                            v-model="make"
                                            @change="changedMakeValue"
                                            name="make"
                                            id="make"
                                            label="text"
                                            :clearable="true"
                                            @search="onSearchMake"
                                            :filterable="false"
                                            :options="makeArr"
                                            :placeholder="staticMessages.make_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isMakeRecordNotFound">
                                                {{staticMessages.make_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Model -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="model" v-model="model" class="form-control" type="text" maxlength="190"/> -->
                                    <v-select 
                                            v-model="model"
                                            name="model"
                                            id="model"
                                            label="text"
                                            @change="changedValue"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="modelArr"
                                            :placeholder="staticMessages.model_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isModelRecordNotFound">
                                                {{staticMessages.model_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Miles -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.miles_or_hours}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="miles" v-model="miles" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Engine Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="engineMake" v-model="engineMake" class="form-control" type="text" maxlength="190"/> -->
                                    <v-select v-model="engineMake"
                                            @change="changedValue"
                                            @search="onSearchEngineMake"
                                            name="engineMake"
                                            id="engineMake"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="engineMakeArr"
                                            :placeholder="staticMessages.engine_make_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isEngineMakeRecordNotFound">
                                                {{staticMessages.engine_make_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- HP -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_hp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="hp" v-model="hp" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Trans Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="transMake" v-model="transMake" class="form-control" type="text" maxlength="190"/> -->
                                    <v-select 
                                            v-model="transMake"
                                            @change="changedTransMakeValue"
                                            name="transMake"
                                            id="transMake"
                                            label="text"
                                            :clearable="true"
                                            @search="onSearchTransMake"
                                            :filterable="false"
                                            :options="transMakeArr"
                                            :placeholder="staticMessages.trans_make_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isTransMakeRecordNotFound">
                                                {{staticMessages.trans_make_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Trans Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="transModel" v-model="transModel" class="form-control" type="text" maxlength="190"/> -->
                                    <v-select 
                                            v-model="transModel"
                                            name="transModel"
                                            id="transModel"
                                            label="text"
                                            @change="changedValue"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="transModelArr"
                                            :placeholder="staticMessages.trans_model_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isTransModelRecordNotFound">
                                                {{staticMessages.trans_model_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                            <!-- Gear Ratio -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gear_ratio_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="gearRatio" v-model="gearRatio" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Wheelbase -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wheelbase_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="wheelBase" v-model="wheelBase" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Color -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.color_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="color" v-model="color" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>

                            <!-- Tires -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tires_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <v-select
                                            v-model="tire"
                                            name="asset"
                                            @input="tireError = ''"
                                            label="text"
                                            :filterable="false"
                                            :options="tireArr"
                                            @search="onSearchTires"
                                            :clearable="false"
                                            :placeholder="staticMessages.tires_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isTireRecordNotFound">
                                                {{staticMessages.tires_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select> -->
                                    <!-- <span class="form-control">{{userId.text}}</span> -->
                                    <input @input="tireError = ''" id="tire" v-model="tire" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tireError}}</span>
                                </div>
                            </div>

                            <!-- Sales Price -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sales_price_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="salesPriceError = ''" id="salesPrice" v-model="salesPrice" class="form-control" type="text" maxlength="10"/>
                                    <span class="help is-danger">{{salesPriceError}}</span>
                                </div>
                            </div>

                            <!-- Buy Price -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.buy_price_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="buyPriceError = ''" id="buyPrice" v-model="buyPrice" class="form-control" type="text" maxlength="10"/>
                                    <span class="help is-danger">{{buyPriceError}}</span>

                                </div>
                            </div>

                            <!-- Recon -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.recon_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input id="recon" v-model="recon" class="form-control" type="text" maxlength="190"/> -->
                                    <input @input="reconError = ''" id="recon" v-model="recon" class="form-control" type="text" maxlength="10"/>
                                    <span class="help is-danger">{{reconError}}</span>
                                </div>
                            </div>

                            <!-- Shipping -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.shipping_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <textarea id="shipping" v-model="shipping" class="form-control" maxlength="50000"></textarea> -->
                                    <input @input="shippingError = ''" id="shipping" v-model="shipping" class="form-control" type="text" maxlength="10"/>
                                    <span class="help is-danger">{{shippingError}}</span>
                                </div>
                            </div>

                            <!--Description-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.additional_description}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="additionalDescription" v-model="additionalDescription" class="form-control" maxlength="50000"></textarea>
                                </div>
                            </div>

                            <!--Attachment-->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.attachment_label}}</label>
                                    <vue-dropzone
                                        ref="attachmentDropzone"
                                        id="attachmentDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-success = "uploadAttachment"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUpload"
                                        :useCustomSlot=true
                                        :destroyDropzone="false"
                                    >
                                    <div class="dropzone-custom-content">
                                        <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                        <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                    </div>
                                    </vue-dropzone>
                                    <!-- <div class="clearfix"></div>
                                    <span class="help is-danger">{{attachmentError}}</span> -->
                                </div>
                            </div>
                        </div>
                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display: none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUpdateBuysTrucksSubmissionBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateBuysTrucksSubmission()">
                            <input v-if="permissionNameArray.includes('can_list_buy_truck_submission_form')" id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>


                    <!-- Forms -->
                </div>
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateBuysTrucksSubmissionForm',
        components: {
            'no-access': NoAccessPage,
            'vueDropzone': vue2Dropzone,
            datePicker,
        },
        mixins: [commonMixins],
        data() {
            return {
                yearOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",

                /* asset : "",
                assetArr : [], */
                buyerArr : [],
                // tireArr : [],

                buyerName : "",
                buyerNameError: "",
                quantity: "",
                quantityError: "",
                year: "",
                yearError: "",
                make : "",
                makeArr: [],
                isMakeRecordNotFound: false,
                model : "",
                modelArr: [],
                isModelRecordNotFound: false,
                miles : "",
                engineMake : "",
                engineMakeArr : [],
                isEngineMakeRecordNotFound : false,
                hp: "",
                transMake : "",
                transMakeArr : [],
                isTransMakeRecordNotFound : false,
                transModel :"",
                transModelArr : [],
                isTransModelRecordNotFound : false,
                gearRatio : "",
                wheelBase : "",
                color: "",
                tire: "",
                tireError : "",
                salesPrice : "",
                salesPriceError : "",
                buyPrice : "",
                buyPriceError: "",
                shipping : '',
                shippingError : "",
                recon : '',
                reconError : "",
                additionalDescription  : '',
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 50, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: "",
                    maxFiles: 20,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                attachmentPaths: [],
                attachmentTypes: [],
                attachmentSizes: [],
                attachmentError : "Sorry, you can upload up to 8.5MB of data only.",
                totalsize: 0,
                totalFlieSize: 8912896,

                isBuyerNameRecordNotFound: false,
                // isTireRecordNotFound : false,
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                pageTitle: "Add Buys - Trucks Submission Form",
                permissionNameArray : ['can_add_buy_truck_submission_form']
            }
        },
        beforeMount() {
            self = this;
        },
        watch : {
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.firstAdd = true;
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('buy_truck_submission_form');
            EventBus.$emit("menu-selection-changed", "buy_truck_submission_form");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.contentClass = "spaced-container";
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Buys - Trucks Submission Form";
            }
            self.loadAllData(['Contact'/* , 'Tire' */, 'Make', 'Model', 'EngineMake', 'TransMake', 'TransModel'], true, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Contact', true, self.loadContactDataCallback, "undefined", '');
            self.loadAllData('Tire', true, self.loadTireDataCallback, "undefined", ''); */
        },
        methods: {
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.isBuyerNameRecordNotFound /* = self.isTireRecordNotFound */ = self.isMakeRecordNotFound = self.isModelRecordNotFound = self.isEngineMakeRecordNotFound = self.isTransMakeRecordNotFound = self.isTransModelRecordNotFound = false;
                self.buyerArr = callbackData.Contact;
                if (self.buyerArr.length == 0) {
                    self.isBuyerNameRecordNotFound = true;
                }
                /* self.tireArr = callbackData.Tire;
                if (self.tireArr.length == 0) {
                    self.isTireRecordNotFound = true;
                } */
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
                self.modelArr = callbackData.Model;
                if(self.modelArr.length == 0) {
                    self.isModelRecordNotFound = true;
                }
                self.engineMakeArr = callbackData.EngineMake;
                if(self.engineMakeArr.length == 0) {
                    self.isEngineMakeRecordNotFound = true;
                }
                self.transMakeArr = callbackData.TransMake;
                if(self.transMakeArr.length == 0) {
                    self.isTransMakeRecordNotFound = true;
                }
                self.transModelArr = callbackData.TransModel;
                if(self.transModelArr.length == 0) {
                    self.isTransModelRecordNotFound = true;
                }
            },
            onSearchBuyerName : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactDataCallback, loading, search);
                }
            },
            /* onSearchTires : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Tire'], true, self.loadTireDataCallback, loading, search);
                }
            }, */
            loadContactDataCallback: function (callbackData) {
                self.isBuyerNameRecordNotFound = false;
                self.buyerArr = callbackData.Contact;
                if (self.buyerArr.length == 0) {
                    self.isBuyerNameRecordNotFound = true;
                }
            },
            /* loadTireDataCallback: function (callbackData) {
                self.isTireRecordNotFound = false;
                self.tireArr = callbackData.Tire;
                if (self.tireArr.length == 0) {
                    self.isTireRecordNotFound = true;
                }
            }, */
            changedMakeValue : function(){
                self.model = "";
                let make_id = "";
                if (typeof(self.make) != "undefined" && self.make != null && self.make != "" && self.make != 0) {
                    make_id = self.make.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', make_id);
            },
            onSearchMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Make'], true, self.loadMakeDataCallback, loading, search);
                }
            },
            loadMakeDataCallback: function (callbackData) {
                self.isMakeRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
            },
            loadModelDataCallback: function (callbackData) {
                self.isModelRecordNotFound = false;
                self.modelArr = callbackData.Model;
                if(self.modelArr.length == 0) {
                    self.isModelRecordNotFound = true;
                }
            },
            onSearchEngineMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['EngineMake'], true, self.loadEngineMakeCallback, loading, search);
                }
            },
            loadEngineMakeCallback: function (callbackData) {
                self.isEngineMakeRecordNotFound = false;
                self.engineMakeArr = callbackData.EngineMake;
                if(self.engineMakeArr.length == 0) {
                    self.isEngineMakeRecordNotFound = true;
                }
            },
            changedTransMakeValue : function(){
                self.transModel = "";
                let trans_make_id = "";
                if (typeof(self.transMake) != "undefined" && self.transMake != null && self.transMake != "" && self.transMake != 0) {
                    trans_make_id = self.transMake.value;
                }
                self.loadAllData(['TransModel'], false, self.loadTransModelDataCallback, "undefined", '', trans_make_id);
            },
            onSearchTransMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['TransMake'], true, self.loadTransMakeDataCallback, loading, search);
                }
            },
            loadTransMakeDataCallback: function (callbackData) {
                self.isTransMakeRecordNotFound = false;
                self.transMakeArr = callbackData.TransMake;
                if(self.transMakeArr.length == 0) {
                    self.isTransMakeRecordNotFound = true;
                }
            },
            loadTransModelDataCallback: function (callbackData) {
                self.isTransModelRecordNotFound = false;
                self.transModelArr = callbackData.TransModel;
                if(self.transModelArr.length == 0) {
                    self.isTransModelRecordNotFound = true;
                }
            },
            uploadAttachment: function (file, response) {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                
                /* console.log("File Add" + file.size);
                console.log("Previous Total : " + self.totalsize);
                console.log("Condition Check Total : " + (self.totalsize + file.size)); */
                /* if ((self.totalsize + file.size) > self.totalFlieSize) {
                    self.showSAlert(self.attachmentError, 'error', false);
                    this.$refs.attachmentDropzone.removeFile(file);
                    return false;
                } else {
                    self.totalsize = self.totalsize + file.size;
                } */
                self.attachmentPaths.push(response.content.file_path);
                self.attachmentTypes.push(file.type);
                self.attachmentSizes.push(file.size);
                /* console.log("Upload attachment "+ file.size); */
                this.$refs.attachmentDropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                
                /* console.log("Remove attachment : " + self.attachmentSizes); */
                /* console.log(JSON.stringify(this.$refs.attachmentDropzone.dropzone.options)); */
                this.$refs.attachmentDropzone.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.attachmentDropzone.dropzone.options.maxFiles) {
                    this.$refs.attachmentDropzone.setOption('maxFiles', null);
                }
                /* if (self.attachmentPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.attachmentPaths.length - 1; i >= 0; i--) {
                        if (self.attachmentPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.attachmentPaths[i];
                        }
                    }
                    this.$refs.attachmentDropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path;
                }
                /* console.log("Before remove total size: " + self.totalsize); */
                for (var i = self.attachmentPaths.length - 1; i >= 0; i--) {
                    if (self.attachmentPaths[i] === removeFile) {
                        self.totalsize = self.totalsize - self.attachmentSizes[i];
                        //self.attachmentError = "";
                        self.attachmentPaths.splice(i, 1);
                        self.attachmentTypes.splice(i, 1);
                        self.attachmentSizes.splice(i, 1);

                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                /* console.log("After remove total size: " + self.totalsize); */
                this.$refs.attachmentDropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.attachmentDropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                /* console.log(filePath);return false; */
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        /* self.catchHandler(err, function () {}); */
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
                    self.showSAlert(xhr.message, 'error', false);
                    /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.attachmentPaths); */
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;

                if (this.$refs.attachmentDropzone.dropzone.options.maxFiles) {
                    this.$refs.attachmentDropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUpload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.cancel = false;
                    self.disableButtons.saveAndContinue = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                    self.disableButtons.saveAndContinue = true;
                }
            },
            returnData: function () {
                var makeId = "";
                if (self.make == null || !self.make.value) {
                    makeId = "";
                }else{
                    makeId = self.make.value;
                }
                var modelId = "";
                if (self.model == null || !self.model.value) {
                    modelId = "";
                }else{
                    modelId = self.model.value;
                }
                var engineMakeId = "";
                if (self.engineMake == null || !self.engineMake.value) {
                    engineMakeId = "";
                }else{
                    engineMakeId = self.engineMake.value;
                }
                var transMakeId = "";
                if (self.transMake == null || !self.transMake.value) {
                    transMakeId = "";
                }else{
                    transMakeId = self.transMake.value;
                }
                var transModelId = "";
                if (self.transModel == null || !self.transModel.value) {
                    transModelId = "";
                }else{
                    transModelId = self.transModel.value;
                }

                var data = {
                    buyerName : self.buyerName,
                    quantity : self.quantity,
                    year : self.year,
                    /* make : self.make,
                    model : self.model, */
                    make : makeId,
                    model : modelId,
                    miles : self.miles,
                    /* engineMake : self.engineMake, */
                    engineMake : engineMakeId,
                    hp : self.hp,
                    /* transMake : self.transMake,
                    transModel : self.transModel, */
                    transMake : transMakeId,
                    transModel : transModelId,
                    gearRatio : self.gearRatio,
                    wheelBase : self.wheelBase,
                    color : self.color,
                    tire : self.tire,
                    salesPrice : self.salesPrice,
                    buyPrice : self.buyPrice,
                    shipping : self.shipping,
                    recon : self.recon,
                    additionalDescription : self.additionalDescription,
                    attachmentPaths : self.attachmentPaths,
                    attachmentTypes : self.attachmentTypes,
                    attachmentSizes : self.attachmentSizes,
                };
                return data;
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListBuyTruckSubmissionForm"});
            },
            clearDataOnSaveAndContinue: function () {
                self.buyerName = "";
                self.quantity = "";
                self.year = "";
                self.make = "";
                self.model = "";
                self.miles = "";
                self.engineMake = "";
                self.hp = "";
                self.transMake = "";
                self.transModel = "";
                self.gearRatio = "";
                self.wheelBase = "";
                self.color = "";
                self.tire = "";
                self.salesPrice = "";
                self.buyPrice = "";
                self.shipping = "";
                self.recon = "";
                self.additionalDescription = "";
                self.attachmentPaths = [];
                self.attachmentTypes = [];
                self.attachmentSizes = [];
                // self.$refs.attachmentDropzone.removeAllFiles(); // It is triggering the removeMedia
            },
            checkForm: function () {
                var checked = true;
                if (self.buyerName == null || !self.buyerName.value) {
                    self.buyerNameError = "Please select Buyer Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.quantity && !self.numberFormateValidation(self.quantity)) {
                    self.quantityError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.year && !self.numberFormateValidation(self.year)) {
                    self.yearError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.tire == null || !self.tire.value) { */
                if (!self.tire) {
                    self.tireError = "Please enter Tire";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.salesPrice && !self.numberFormateValidation(self.salesPrice)) {
                    self.salesPriceError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.buyPrice && !self.numberFormateValidation(self.buyPrice)) {
                    self.buyPriceError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.recon && !self.numberFormateValidation(self.recon)) {
                    self.reconError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.shipping && !self.numberFormateValidation(self.shipping)) {
                    self.shippingError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addUpdateBuysTrucksSubmission(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/buystruckssubmission/add/update/vtc", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        self.clearDataOnSaveAndContinue();
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            if (self.permissionNameArray.includes('can_list_buy_truck_submission_form')) {
                                                self.buttonBack();
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>

