<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template>
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <button v-if="permissionNameArray.includes('add_location_contact_manager')" class="btn btn-primary waves-effect pull-right ml-2" @click="showForm()">Add Location Contact</button>
                    </div>     
                    
                    <div class="col-12 paddingnone">
                        <div class="row no-margin border">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Contact Type</th>
                                            <th>Assigned User</th>
                                            <th>Created BY</th>
                                            <th>Updated By</th>
                                            <th>Created At</th>
                                            <th>Updated At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="permissionNameArray.includes('list_location_contact_manager') && contactManagerList.length > 0">
                                            <tr v-for="data in contactManagerList" :key="data.id">
                                                <td>
                                                    <a v-on:click="deleteContactManager(data.id)" title='Delete Contact Manager' href="javascript:void(0)" class="mar-right-0">
                                                        <i  class="exceptEdit fa fa-trash pr-1" aria-hidden="true"></i>
                                                    </a>
                                                    <a v-on:click="editContactManager(data.id)" title='Edit Contact Manager' href="javascript:void(0)" class="mar-right-0">
                                                        <i aria-hidden="true" class="fa fa-edit"></i>
                                                    </a>
                                                </td>
                                                <td>{{data.contact_type}}</td>
                                                <td>{{data.assigned_user}}</td>
                                                <td>{{data.created_by}}</td>
                                                <td>{{data.updated_by}}</td>
                                                <td>{{data.created_at}}</td>
                                                <td>{{data.updated_at}}</td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <th colspan="7" class="text-center">No Data</th>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isShowForm">
                    <add-location-manager :editData="editData"></add-location-manager>
                </div>
            </div>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from "../event-bus";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import AddLocationManagerModal from "@/components/LocationMaster/AddLocationManagerModal.vue";
    import { HTTP } from '../../http-common';

    Vue.component("app-footer", Footer);

    export default {
        name: 'ListLocationManager',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
            'add-location-manager': AddLocationManagerModal,
        },
        data () {
            return {
                permissionNameArray: ['list_location_contact_manager','add_location_contact_manager'],
                isShowForm: false,
                editData: {},
                contactManagerList: [],
                contactManagerId: null,
                isListAssignedUser: false,
                modalInstance: null,
            }
        },
        mounted() {
            self = this;
            self.getList();
            self.initializeModals();
        },
        methods: {
            initializeModals() {
                self.modalInstance = new bootstrap.Modal(
                    document.getElementById("contact-manager-form"),
                    {
                        backdrop: "static",
                        keyboard: false,
                    }
                );
            },
            showForm() {
                self.modalInstance.show();
                self.isShowForm = true;
            },
            editContactManager(id) {
                self.modalInstance.show();
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                };
                HTTP.get("/location/contacts/"+id, config)
                .then(function (response) {
                    self.editData = response.data.content;
                    self.isShowForm = true;
                })
                .catch(function (err) {
                    self.catchHandler(err, function () {});
                });
            },
            getList() {
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                    params: {
                        location_id: self.$route.params.id,
                    }
                };
                HTTP.get("/location/contacts", config)
                .then(function (response) {
                    self.contactManagerList = response.data.content;
                })
                .catch(function (err) {
                    self.catchHandler(err, function () {});
                });
            },
            deleteContactManager(id) {
                self.$swal({
                    title: '',
                    text: 'Are you sure you want to delete this record!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        const config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        };
                        HTTP.delete("/location/contacts/"+id, config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.getList();
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>',error);
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
        }
    } 

    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });

    EventBus.$on("cal-location-magaer-list-data",function (listData){
        if (typeof self != "undefined"){
            self.calVehiclePriceList = listData;
            self.modalInstance.hide();
        }
    });

    EventBus.$on("close-contact-manager-default", function (status,method) {
        if (typeof self != "undefined") {
            if(method !== null) {
                self.getList();
            } else {
                self.isShowForm = status;
                self.editData = {};
            }
            self.modalInstance.hide();
        }
    });
    
</script>