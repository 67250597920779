<template>
    <div id="page-content-wrapper" class="admin-page-content">
      <div class="col-md-12 error-detail-main forms-container">
        <div class="error-template">
          <h1>404 - Page Not Found</h1>
          <div class="error-content">
            The page you are looking for does not exist.
          </div>
          <button
            class="extraFeature btn btn-primary waves-effect ml-2"
            @click="goToHomePage"
          >
            Go To Home Page
          </button>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
</template>

<script>
var self;
import Footer from "@/components/partials/Footer";

export default {
  name: "PagwNotFound",
  components: {
    "app-footer": Footer,
  },
  data() {
    return {};
  },
  beforeMount() {
    self = this;
  },
  methods: {
    goToHomePage() {
      self.$router.push({ name: "ListAsset" });
    },
  },
};
</script>

