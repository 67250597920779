<template>
    <div class="spaced-container">
        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_print_qr_code_inventory_master')">
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click="buttonBack()" class="btn btn-primary waves-effect pull-right ml-2">Back</button>
                        <button type="submit" v-on:click="printData()" class="extraFeature btn btn-primary waves-effect pull-right ml-2">Generate PDF</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!-- {{assetData}} -->
                        <!--Forms-->
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                            <div class="row no-margin">
                                <template v-if="assetData && assetData.length > 0">
                                    <draggable v-model="assetData" class="drag-view col-12">
                                        <template v-for="(img, index) in assetData">
                                            <div :key="'main-div ' + index" class="col-6 text-alignment-center mb-5">
                                                <div class="cards-block">
                                                    <div class="card">
                                                        <img class="qr-code-display" :src="img.qr_code" :key="'image ' + index">
                                                        <div class="card-body">
                                                            <h4 class="card-title mb-0">
                                                                {{img.vin_no}}&nbsp;
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- <template v-for="indx in assetData.length % 6">
                                            <div :key="'main-div ' + indx" class="col-6 text-alignment-center mb-5">
                                                <div class="cards-block">
                                                    <div class="card">
                                                        <img src="/static/images/300.png" :key="'image ' + indx">
                                                    </div>
                                                </div>
                                            </div>
                                        </template> -->
                                    </draggable>
                                </template>
                                <template v-else>
                                    No Image found!!!
                                </template>
                            </div>
                        </div>
                        <!-- Forms -->
                    </div>
                    <!--page content-->
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <app-footer></app-footer>
        </div>
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import draggable from 'vuedraggable';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'ViewAsset',
        components: {
            draggable,
            'no-access': NoAccessPage,
        },

        mixins: [commonMixins],
        data() {
            return {
                selectedID: 0,
                activetab: 0,
                pageTitle: "Print Inventory QR Code",
                assetData: {
                    content: {

                    },
                },
                permissionNameArray: ['can_print_qr_code_inventory_master'],
                updateActivetab: 1
            }
        },
        computed: {
            dragOptions: function () {
                return {
                    staticMessages: "",
                    staticInputLabelMessages: "",
                    group: 'images',
                    handle: ".drag-handle",
                }
            },
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            self.selectedID = self.$store.state.selectedData;
            console.log(self.selectedID);
            if (self.selectedID.length <= 0) {
                self.buttonBack();
            } else {
                self.setActiveMenu('asset-list');
                EventBus.$emit("menu-selection-changed", "asset-list");
                self.getData();
                self.getLoginIdPermissions();
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            getData: function () {
                /*console.log(self.selectedID);*/
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},

                    params: {
                        id: self.selectedID,
                        is_export: false
                    }
                };
                self.showLoader();
                HTTP.get("/asset/getmultyqr", config)
                        .then(function (data) {
                            self.hideLoader();
                            if (data.status == 200) {
                                self.assetData = data.data.content;
                                /* self.asset = self.assetData; */
                                /*self.pageTitle = "VIN : " + self.assetData.content.vin_no + " | ";
                                 self.pageTitle += self.assetData.content.year + " ";
                                 self.pageTitle += self.assetData.content.make_name + " ";
                                 self.pageTitle += self.assetData.content.model_name;*/
                            }
                            /* self.$nextTick(() => {
                                self.activetab = self.updateActivetab;
                            }); */
                        })
                        .catch(function (error) {
                            self.hideLoader();
                            self.catchHandler(error, function () {});
                        })
            },
            printData: function () {
                /*console.log(self.selectedID);*/
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},

                    params: {
                        id: self.selectedID,
                        is_export: true
                        // data: self.assetData,
                    }
                };
                self.showLoader();
                HTTP.get("/asset/getmultyqr", config)
                //used same api with is_export flag to get data.
                // HTTP.get("/asset/printqrlist", config)
                        .then(function (data) {
                            self.hideLoader();
                            if (data.status == 200) {
                                window.open(data.data.content);
                            }
                            /* self.$nextTick(() => {
                                self.activetab = self.updateActivetab;
                            }); */
                        })
                        .catch(function (error) {
                            self.hideLoader();
                            self.catchHandler(error, function () {});
                        })
            }
        }

    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("update-multi-edit-tab-view-data", function (updateInventoryTab) {
        if (typeof self != "undefined") {
            self.updateActivetab = updateInventoryTab;
            self.getData();
        }
    });
</script>