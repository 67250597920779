<template>
    <div id="page-content-wrapper" class="admin-content-area update-offer-submission">
        <template v-if="(permissionNameArray.includes('can_add_offer_submission_form')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <template v-for="(data, vinDataIndex) in all_vin_data">
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.vin}}</span>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.location || '-'}}</span>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.fleet_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.fleet || '-'}}</span>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.offer_price_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="!isEditAccessAllow">
                                        <span style="overflow: auto;" class="form-control">{{data.offer_price}}</span>
                                      </template>
                                      <template v-else>
                                        <input @input="data.offer_price_error = ''" id="offerPrice" v-model="data.offer_price" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(data)"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{data.offer_price_error}}</span>
                                      </template>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.estimate_of_expenses_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="!isEditAccessAllow">
                                        <span style="overflow: auto;" class="form-control">{{data.expense_charged}}</span>
                                      </template>
                                      <template v-else>
                                        <input @input="data.expense_charged_error = ''" id="estimateOfExpense" v-model="data.expense_charged" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(data)"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{data.expense_charged_error}}</span>
                                      </template>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.estimate_of_expense_types_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0 estimate-expense-type">
                                      <template v-if="!isEditAccessAllow">
                                        <v-select
                                            multiple
                                            disabled
                                            v-model="data.expense_type"
                                            name="estimateOfExpenseType"
                                            label="text"
                                            :filterable="true"
                                            :clearable="true"
                                            :placeholder="staticMessages.data_not_available">
                                        </v-select>
                                      </template>
                                      <template v-else>
                                        <v-select
                                                multiple
                                                v-model="data.expense_type"
                                                name="estimateOfExpenseType"
                                                @input="data.expense_type_error = ''"
                                                label="text"
                                                :filterable="true"
                                                :options="estimateOfExpenseTypeArr"
                                                :clearable="true"
                                                :placeholder="staticMessages.estimate_of_expense_types_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{data.expense_type_error}}</span>
                                      </template>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.retail_price_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="!isEditAccessAllow">
                                        <span style="overflow: auto;" class="form-control">{{data.retail_price}}</span>
                                      </template>
                                      <template v-else>
                                        <input @input="data.retail_price_error = ''" id="retailPrice" v-model="data.retail_price" class="form-control" type="text" maxlength="17"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{data.retail_price_error}}</span>
                                      </template>
                                    </div>
                                </div>
                                <div class="col-4 forms-block current-expense-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.current_expenses_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="!isEditAccessAllow">
                                        <span style="overflow: auto;" class="form-control">{{data.current_expenses}}</span>
                                      </template>
                                      <template v-else>
                                        <input @input="data.current_expenses_error = ''" id="currentExpenses" v-model="data.current_expenses" class="form-control" type="text" maxlength="17" @change="calculateNetFromOffer(data)"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{data.current_expenses_error}}</span>
                                        <a href="javascript:void(0)" class="mar-right-0 expense-setting" @click.stop.prevent="openContextMenu($event, data.asset_id, vinDataIndex)">
                                            <i class="exceptEdit fa fa-cog" aria-hidden="true"></i>
                                        </a>
                                      </template>
                                    </div>
                                    <!-- Vue Context Menu Start -->
                                    <vue-context ref="menu" :close-on-click="closeOnClick"
                                                        :close-on-scroll="closeOnScroll"
                                                        :item-selector="itemSelector"
                                                        class="expense-menu"
                                                        >
                                      <div class="col-12 paddingnone list-common-actions" v-if="data.selectedRows.length >= 1">
                                          <span class="color-cc9636">{{data.selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection(vinDataIndex)">X</a></span>
                                          <button type="button" @click.stop.prevent="submitAssetExpenses($event, vinDataIndex, data)" class="btn btn-primary waves-effect pull-right ml-2 mt-1">Submit</button>
                                      </div>
                                      <div class="table-listing white-bg border-blue border-radius-top-right">
                                          <table class="table custom-table jsmartable" aria-describedby="custom-selectbox-block">
                                              <tbody>
                                              <template v-if="assetWiseExpensesArr && assetWiseExpensesArr.length > 0">
                                                  <tr v-for="(assetexpenses, assetIndex) in assetWiseExpensesArr" :key="assetIndex">
                                                      <td class="">
                                                          <div class="custom-control custom-checkbox">
                                                              <input type="checkbox" v-model="data.selectedRows" :value="assetexpenses.id" @change="inputCheckbox($event, vinDataIndex)" class="custom-control-input" :id="'customCheck' + vinDataIndex + '-' + assetexpenses.id"  name="case[]">
                                                                  <label class="custom-control-label" :for="'customCheck' + vinDataIndex + '-' + assetexpenses.id"></label>
                                                          </div>
                                                      </td>
                                                      <td :class="rowClass.excede_po">{{assetexpenses.excede_po}}</td>
                                                      <td :class="rowClass.expense_category">{{assetexpenses.expense_category}}</td>
                                                      <td :class="rowClass.actual_cost">{{assetexpenses.actual_cost}}</td>
                                                  </tr>
                                              </template>
                                              <template v-else>
                                                  <tr>
                                                      <td :colspan="headers.length + 1" class="text-alignment-center">
                                                          {{allStaticMessages.data_not_available}}
                                                      </td>
                                                  </tr>
                                              </template>
                                              </tbody>
                                              <thead>
                                              <tr>
                                                  <th :id="'selectAllCheckBox-' + data.asset_id">
                                                      <div class="custom-control custom-checkbox">
                                                          <input v-model="data.selectAll" type="checkbox" class="custom-control-input" :id="'selectAllCheckBox' + vinDataIndex" name="example1" @change="clickHeaderCheckBox($event, vinDataIndex)">
                                                          <label class="custom-control-label" :for="'selectAllCheckBox' + vinDataIndex"></label>
                                                      </div>
                                                  </th>
                                                  <template v-for="(data, headerIndex) in headers">
                                                      <th :class="data.column_full_class + ' column-header'" :key="headerIndex" data-breakpoint="xs">{{data.name}}</th>
                                                  </template>
                                              </tr>
                                              </thead>
                                          </table>
                                      </div>
                                      <!-- Pagination Start -->
                                      <div class="vgt-wrap__footer crm p-3 bottom-pagination-section">
                                          <div class="row">
                                              <div class="col-sm-12 col-md-2 PerPage">
                                                <v-select title="Select Per Page"
                                                        v-model="data.perPage"
                                                        @input="changePerPage(vinDataIndex)"
                                                        name="customPerPage"
                                                        id="customPerPage"
                                                        label="text"
                                                        :clearable="false"
                                                        :searchable="false"
                                                        :filterable="false"
                                                        :options="paginationDropDownArr"
                                                        placeholder="Select Per Page">
                                                </v-select>
                                              </div>

                                              <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                                <p class="pageRowCount">
                                                    {{data.startEndRecord}} of {{data.totalRecords}}
                                                </p>
                                              </div>

                                              <div class="col-sm-12 col-md-5 Pagination">
                                                  <b-pagination v-if="data.totalRecords > 0" align="center" :limit="4" prev-text="‹ " next-text="›" :total-rows="data.totalRecords" v-model="data.currentPage" @input="changepage(vinDataIndex)" :per-page="data.perPageValue"></b-pagination>
                                              </div>

                                              <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                                  <p class="pageJumpLabel" style="white-space: nowrap;">Page : </p>
                                              </div>
                                              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
                                                  <input v-model="data.jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor(vinDataIndex)" placeholder="Page">
                                              </div>
                                          </div>
                                      </div>
                                      <!-- Pagination End -->
                                    </vue-context>
                                    <!-- Vue Context Menu End -->
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.net_from_offer_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.vin_net_from_offer}}</span>
                                    </div>
                                </div>
                                <div class="col-4 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.retail_ready_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.retail_ready  || '-'}}</span>
                                    </div>
                                </div>
                                <div class="col-12 forms-block">
                                  <hr/>
                                </div>
                            </template>

                            <div class="card offer-submission-card">
                              <div class="card-header">
                                <h5 id="offerStatusHeader">{{ offerStatusHeader }}</h5>
                                <div id="offerStatusBody" v-if="isCounterOffer && !counterOfferStatus">
                                  <button type="button" title="Approve Counter Offer" class="btn btn-primary mr-2" v-on:click.prevent="changeCounterOfferStatus(staticInputLabelMessages.approve_btn_label)">{{ staticInputLabelMessages.approve_btn_label }}</button>
                                  <button type="button" title="Reject Counter Offer" class="btn btn-danger" v-on:click.prevent="changeCounterOfferStatus(staticInputLabelMessages.reject_btn_label)">{{ staticInputLabelMessages.reject_btn_label }}</button>
                                </div>
                              </div>

                              <div class="card-body">
                                <div class="row">
                                  <!-- Sales Person -->
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.seles_person}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                          <v-select
                                              v-model="salesmanId"
                                              name="salesmanId"
                                              id="salesmanId"
                                              label="text"
                                              @change="changedValue"
                                              @input="salesmanIdError = ''"
                                              :filterable="false"
                                              :options="salesmanIdArr"
                                              @search="onSearchSalesmanUser"
                                              :placeholder="staticMessages.salesman_name_place_holder"
                                              :disabled="true">
                                              <span slot="no-options" @click="$refs.select.open = false">
                                                  <template v-if="!isSalesmanNameRecordNotFound">
                                                      {{staticMessages.salesman_name_search}}
                                                  </template>
                                                  <template v-else>
                                                      {{staticMessages.search_record_not_found}}
                                                  </template>
                                              </span>
                                          </v-select>
                                          <div class="requiredstar">*</div>
                                          <span class="help is-danger">{{salesmanIdError}}</span>
                                      </div>
                                  </div>

                                  <!-- Buyer name -->
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.buyer_name_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                          <template v-if="isCounterOffer">
                                            <span class="form-control">{{ buyer }}</span>
                                          </template>
                                          <template v-else>
                                            <input @input="buyerError = ''" id="buyer" v-model="buyer" class="form-control" type="text"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{buyerError}}</span>
                                          </template>
                                      </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <!-- Is Buyer Financing With Us?  -->
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.is_buyer_financing_with_us_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <template v-if="isCounterOffer">
                                          <b-form-radio-group v-model="isBuyerFinancing"
                                                              :options="isBuyerFinancingOptions"
                                                              name="isBuyerFinancing"
                                                              disabled>
                                          </b-form-radio-group>
                                        </template>
                                        <template v-else>
                                          <b-form-radio-group v-model="isBuyerFinancing"
                                                              :options="isBuyerFinancingOptions"
                                                              name="isBuyerFinancing">
                                          </b-form-radio-group>
                                        </template>
                                      </div>
                                  </div>

                                  <!-- Has Customer Seen Truck? -->
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.has_customer_seen_truck_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <template v-if="isCounterOffer">
                                          <b-form-radio-group v-model="hasCustomerSeenTruck"
                                                              :options="hasCustomerSeenTruckOptions"
                                                              name="hasCustomerSeenTruck"
                                                              disabled>
                                          </b-form-radio-group>
                                        </template>
                                        <template v-else>
                                          <b-form-radio-group v-model="hasCustomerSeenTruck"
                                                              :options="hasCustomerSeenTruckOptions"
                                                              name="hasCustomerSeenTruck">
                                          </b-form-radio-group>
                                        </template>
                                      </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <!-- As/Is ? -->
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.as_is_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                        <template v-if="isCounterOffer">
                                          <b-form-radio-group v-model="asIs"
                                                              :options="asIsOptions"
                                                              name="asIs"
                                                              disabled>
                                          </b-form-radio-group>
                                        </template>
                                        <template v-else>
                                          <b-form-radio-group v-model="asIs"
                                                              :options="asIsOptions"
                                                              name="asIs">
                                          </b-form-radio-group>
                                        </template>
                                      </div>
                                  </div>

                                  <!-- Approve-Reject Status & Counter Offer-->
                                  <div class="col-6 forms-block">
                                      <!-- Approve Status -->
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.approve_label}}</div>
                                      </div>
                                      <div class="col-9">
                                        <div class="row">
                                          <div class="col-3 paddingleft0 mt-1">
                                            <div class="custom-checkbox-v pull-left">
                                              <template v-if="isCounterOffer">
                                                <label class="container">
                                                  <input id="completed" v-model="completed" value="true" class="form-control" type="checkbox" @change="handleCheckbox('completed')" disabled/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                              <template v-else>
                                                <label class="container">
                                                  <input id="completed" v-model="completed" value="true" class="form-control" type="checkbox" @change="handleCheckbox('completed')"/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                            </div>
                                          </div>

                                          <!-- Reject Status -->
                                          <div class="col-4 paddingleft0" style="text-align: left;">
                                            <div class="form-label" style="display: inline-block;float: left;">
                                              {{staticInputLabelMessages.reject_label}}
                                            </div>
                                            <div class="custom-checkbox-v mt-1" style="display: inline;float: left;">
                                              <template v-if="isCounterOffer">
                                                <label class="container">
                                                  <input id="notCompleted" v-model="notCompleted" value="true" class="form-control" type="checkbox" @change="handleCheckbox('notCompleted')" disabled/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                              <template v-else>
                                                <label class="container">
                                                  <input id="notCompleted" v-model="notCompleted" value="true" class="form-control" type="checkbox" @change="handleCheckbox('notCompleted')"/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                            </div>
                                          </div>

                                          <!--
                                            Date : 28th Mar,2024
                                            Reason : Based on request from Andrew Harrell, counter offer status field added where approval or rejection is not applicable.
                                            New Field : Counter Offer, Counter Offer By, Counter Offer Detail, Counter Offer Price
                                          -->
                                          <!-- Counter Offer -->
                                          <div class="col-5 pl-0 pr-0" style="text-align: left;">
                                            <div class="form-label" style="display: inline-block;float: left;">
                                              {{staticInputLabelMessages.counter_offer_label}}
                                            </div>
                                            <div class="custom-checkbox-v mt-1" style="display: inline;float: left;">
                                              <template v-if="isCounterOffer">
                                                <label class="container">
                                                  <input id="counterOffer" v-model="counterOffer" value="true" class="form-control" type="checkbox" @change="handleCheckbox('counterOffer')" disabled/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                              <template v-else>
                                                <label class="container">
                                                  <input id="counterOffer" v-model="counterOffer" value="true" class="form-control" type="checkbox" @change="handleCheckbox('counterOffer')"/>
                                                  <span class="checkmark"></span>
                                                </label>
                                              </template>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>

                                <!-- Approve / Reject / Counter Offer By -->
                                <div v-if="completed || notCompleted || counterOffer" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div v-if="completed" class="form-label">{{staticInputLabelMessages.approve_by_label}}</div>
                                        <div v-if="notCompleted" class="form-label">{{staticInputLabelMessages.reject_by_label}}</div>
                                        <div v-if="counterOffer" class="form-label">{{staticInputLabelMessages.counter_offer_by_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="isCounterOffer">
                                        <v-select
                                                  v-model="completeById"
                                                  name="completeById"
                                                  id="completeById"
                                                  label="text"
                                                  @change="changedValue"
                                                  :filterable="false"
                                                  :options="completeByArr"
                                                  @search="onSearchCompleteBy"
                                                  placeholder="Select User"
                                                  @input="completeByIdError=''"
                                                  disabled>
                                        </v-select>
                                      </template>
                                      <template v-else>
                                        <v-select
                                                  v-model="completeById"
                                                  name="completeById"
                                                  id="completeById"
                                                  label="text"
                                                  @change="changedValue"
                                                  :filterable="false"
                                                  :options="completeByArr"
                                                  @search="onSearchCompleteBy"
                                                  placeholder="Select User"
                                                  @input="completeByIdError=''">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{completeByIdError}}</span>
                                      </template>
                                    </div>
                                </div>

                                <!--Counter Offer Detail-->
                                <div v-if="counterOffer" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.counter_offer_detail_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="isCounterOffer">
                                        <textarea id="counterOfferDetail" v-model="counterOfferDetail" class="form-control" maxlength="50000" disabled></textarea>
                                      </template>
                                      <template v-else>
                                        <textarea id="counterOfferDetail" v-model="counterOfferDetail" class="form-control" maxlength="50000"></textarea>
                                      </template>
                                    </div>
                                </div>

                                <!--Counter Price-->
                                <div v-if="counterOffer" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.counter_offer_price_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="isCounterOffer">
                                        <span class="form-control">{{ counterOfferPrice }}</span>
                                      </template>
                                      <template v-else>
                                        <input type="text" @input="counterOfferPriceError = ''"  id="counterOfferPrice" v-model="counterOfferPrice" class="form-control" maxlength="17"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{counterOfferPriceError}}</span>
                                      </template>
                                    </div>
                                </div>

                                <!--Additional Notes-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.additional_notes}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                      <template v-if="isCounterOffer">
                                        <textarea @input="noteError = ''" id="descrition" v-model="note" class="form-control" maxlength="50000" disabled></textarea>
                                      </template>
                                      <template v-else>
                                        <textarea @input="noteError = ''" id="descrition" v-model="note" class="form-control" maxlength="50000"></textarea>
                                      </template>
                                    </div>
                                </div>

                                <div class="col-12 forms-block" v-show="attachment.length > 0">
                                    <div class="col-12 forms-block paddingleft0 deposit-check-field">
                                        <div class="col-12 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.approval_or_deposit_check_image}}</div>
                                        </div>
                                        <div class="col-12 paddingleft0">
                                            <div class="slider">
                                                <template>
                                                    <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                        <template v-for="(doc, index1) in attachment">
                                                            <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                            <template v-else>
                                                                <swiper-slide>
                                                                    <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                                </swiper-slide>
                                                            </template>
                                                        </template>
                                                    </swiper>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div v-if="!counterOfferStatus" class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="button" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="updateOfferSubmissionFormReq()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="button" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */
    import { VueContext } from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';

    Vue.component("app-footer", Footer);

    export default {
        name: 'UpdateOfferSubmission',
        mixins: [commonMixins],
        components: {
            swiper,
            swiperSlide,
            VueContext,
        },
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                vin: "",
                buyer: "",
                offerPrice: "",
                estimateExpense: "",
                isBuyerFinancing: 'yes',
                isBuyerFinancingOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                hasCustomerSeenTruck: 'yes',
                hasCustomerSeenTruckOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                asIs: 'yes',
                asIsOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                completed: false,
                completeById: "",
                note: "",
                completeByArr: [],
                // Error
                salesmanIdError: "",
                completeByIdError: "",
                vinError: "",
                buyerError: "",
                offerPriceError: "",
                estimateExpenseError: "",
                noteError: "",
                isBuyerFinancingError: "",
                hasCustomerSeenTruckError: "",
                asIsError: "",
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Edit Offer Submission Form',
                permissionNameArray: ['can_add_offer_submission_form'],
                all_vin_data : [],
                attachment: "",
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                notCompleted : false,
                location : "",
                fleet: "",
                vin_net_from_offer : '',
                isRedirection: null,
                counterOffer : false,
                counterOfferDetail : '',
                counterOfferPrice : '',
                counterOfferPriceError : '',

                oldCompleted : false,
                oldNotCompleted : false,
                oldCounterOffer : false,
                oldCompleteById : '',
                oldCounterOfferDetail : '',
                oldCounterOfferPrice : '',
                counterOfferStatus : '',
                isCounterOffer: false,
                retailReady : "",
                isEditAccessAllow: false,
                estimateOfExpenseTypeArr : [],
                closeOnClick: false, // When set to true, the context  menu will close when clicked on
                closeOnScroll: false, // When set to true, the context  menu will close when the window is scrolled
                itemSelector: ['.custom-item-class'], // This is how the component is able to find each menu item. Useful if you use non-recommended markup

                assetId: 0,
                perPageValue: 5,
                perPage: 5,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 20],
                headers:  [
                  {
                      "name": "Excede Po",
                      "type": "text",
                      "field": "",
                      "column_name": "excede_po",
                      "column_class": "text-left",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "excede_po sort text-left"
                  },
                  {
                      "name": "Expense Category",
                      "type": "date",
                      "field": "",
                      "column_name": "expense_category",
                      "column_class": "text-left",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "expense_category sort text-left"
                  },
                  {
                      "name": "Actual Cost",
                      "type": "text",
                      "field": "",
                      "column_name": "actual_cost",
                      "column_class": "text-right",
                      "sort": "sort",
                      "filter_able": "yes",
                      "display": true,
                      "column_full_class": "actual_cost sort text-right"
                  }
                ],
                filters: {
                },
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: "id",
                sortType: "desc",
                selectedRows: [],
                selectedDeleteRows: [],
                startEndRecord: "",
                selectAll: false,
                assetWiseExpensesArr: [],
                selectedExpenses : [],
                totalInvExpenses : [],
                previousSelectedRows: [],
                plusCount : 0,
                additionalData: {
                  offer_price_error: '',
                  expense_charged_error: '',
                  expense_type_error: '',
                  retail_price_error: '',
                  current_expenses_error: '',

                  selectedRows: [],
                  startEndRecord: "",
                  jumpToPage: 1,
                  perPageValue: 5,
                  perPage: 5,
                  currentPage: 1,
                  totalRecords: 0,
                  selectAll: false,
                  selectedDeleteRows: [],
                  previousSelectedRows: []
                }
            }
        },
        beforeMount() {
            self = this;
        },
        computed: {
          /**
           * Function: offerStatusHeader
           * Description:
           * - Computes the status header for an offer based on various conditions.
           * - Determines whether the offer is approved, rejected, a counteroffer, or pending.
           * Returns: The appropriate header label based on the offer status.
           */
          offerStatusHeader() {
            if (self.oldCompleted) {
              return self.staticInputLabelMessages.approved_offer_header_label;
            } else if (self.oldNotCompleted) {
              return self.staticInputLabelMessages.rejected_offer_header_label;
            } else if (self.oldCounterOffer) {
              if (self.counterOfferStatus === 'approve') {
                return self.staticInputLabelMessages.approved_counter_offer_header_label;
              } else if (self.counterOfferStatus === 'reject') {
                return self.staticInputLabelMessages.rejected_counter_offer_header_label;
              } else {
                return self.staticInputLabelMessages.counter_offer_header_label;
              }
            } else {
              return self.staticInputLabelMessages.offer_req_status_header_label;
            }
          },
        },
        watch: {
          oldCounterOffer() {
            if(self.oldCounterOffer) {
              self.isCounterOffer = true; // Based on flag disable the input fields when counter offer request identified
            }
          }
        },
        mounted: function () {
            self.isRedirection = process.env.VUE_APP_IS_REDIRECTION;

            /* Redirect to login page logic start */
            let userObj = localStorage.getItem("user_obj");
            if (userObj == null) {

                /* Set redirection link specified in the email template to navigate to the edit page  --- Start */
                if (self.isRedirection === 'true') {
                  // Check & set redirection path if available
                  if(typeof window.location.pathname !== 'undefined' && window.location.pathname != 'null' && window.location.pathname != '') {
                    if(window.location.pathname != '/') {
                      localStorage.setItem('redirect_url', window.location.pathname);
                    }
                  }
                }
                /* Set redirection link specified in the email template to navigate to the edit page --- End */

                self.$router.push('/');
            } else if(self.$route.query.token) {
                self.$router.push({name: "UpdateOfferSubmission", params: {id: self.$route.params.id}});
                location.reload();
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.getLoginIdPermissions();
            self.setActiveMenu('offer_submission_form');
            EventBus.$emit("menu-selection-changed", "offer_submission_form");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getOfferSubmissionDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
            }
            self.loadAllData(['OfferExpenseType'], true, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            loadMasterDataCallback: function (callbackData) {
                self.estimateOfExpenseTypeArr = callbackData.OfferExpenseType;
            },
            buttonBack() {
                self.$router.push({name: 'ListOfferSubmission'});
            },
            handleCheckbox(selectedCheckbox) {
                // Reset all flags and fields
                self.completed = self.notCompleted = self.counterOffer = false;
                self.completeById = self.counterOfferDetail = self.counterOfferPrice = '';
                self.completeByIdError = self.counterOfferPriceError = '';

                // Update flags and fields based on the selected checkbox
                switch (selectedCheckbox) {
                    case "completed":
                        if ($('#completed').prop('checked')) {
                          self.completed = true;
                          self.notCompleted = self.counterOffer = false;
                          if (self.oldCompleted) {
                              self.completeById = self.oldCompleteById;
                          }
                        }
                        break;
                    case "notCompleted":
                        if ($('#notCompleted').prop('checked')) {
                          self.notCompleted = true;
                          self.completed = self.counterOffer = false;
                          if (self.oldNotCompleted) {
                              self.completeById = self.oldCompleteById;
                          }
                        }
                        break;
                    case "counterOffer":
                        if ($('#counterOffer').prop('checked')) {
                          self.counterOffer = true;
                          self.completed = self.notCompleted = false;
                          if (self.oldCounterOffer) {
                              self.completeById = self.oldCompleteById;
                              self.counterOfferDetail = self.oldCounterOfferDetail;
                              self.counterOfferPrice = self.oldCounterOfferPrice;
                          }
                        }
                        break;
                    default:
                        break;
                }
            },
            loadCompleteByDataCallback: function (callbackData) {
                self.completeByArr = callbackData;
            },
            getCompleteByData: function (callbackData) {
                self.completeByArr = callbackData;
            },
            onSearchCompleteBy(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getCompleteByData);
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUsers(loading, search, this);
                }
            },
            loadSalesmanUsers: function (loading, search, vm) {
                search = (search === null || typeof (search) === "undefined" || search == '') ? '' : search;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        search: search
                    }
                };
                HTTP.get("/salesmanuser/get", config)
                        .then(function (response) {
                            self.salesmanIdArr = response.data.content;
                            if (typeof (loading) != "undefined") {
                                loading(false);
                            }
                        })
                        .catch(function (error) {
                            self.catchHandler(err, function () {});
                        })
            },
            getOfferSubmissionDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/offersubmission/get', config)
                        .then(response => {
                            if (response.data.content.salesman_id != null) {
                               self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesman_id};
                            }
                            self.vin = response.data.content.last_vin_digit;
                            self.buyer = response.data.content.buyer_name;
                            self.offerPrice = response.data.content.offer_price;
                            self.estimateExpense = response.data.content.expense_charged;
                            self.note = response.data.content.note;
                            self.isBuyerFinancing = response.data.content.is_buyer_financing;
                            self.hasCustomerSeenTruck = response.data.content.has_customer_seen_truck;
                            self.vin_net_from_offer = response.data.content.vin_net_from_offer;
                            if (response.data.content.as_is != null) {
                                self.asIs = response.data.content.as_is;
                            }
                            self.oldCompleted = self.completed = (response.data.content.completed == "no") ? false : true;
                            self.oldNotCompleted = self.notCompleted = (response.data.content.not_completed == "no") ? false : true;
                            if (response.data.content.complete_by_id != null) {
                                self.oldCompleteById = self.completeById = {text: response.data.content.userCompleteByFName + ' ' + response.data.content.userCompleteByLName, value: response.data.content.complete_by_id};
                            }
                            self.all_vin_data = response.data.content.all_vin_data;
                            self.all_vin_data = self.all_vin_data.map(obj => ({ ...obj, ...self.additionalData }));

                            self.companyId = response.data.content.company_id;
                            self.attachment = response.data.content.attachment_path;

                            if(response.data.content.location != null) {
                              self.location = response.data.content.location;
                            }

                            if(response.data.content.fleet != null) {
                              self.fleet = response.data.content.fleet;
                            }

                            self.oldCounterOffer = self.counterOffer = (response.data.content.counter_offer == "no") ? false : true;
                            if (response.data.content.counter_offer_detail != null) {
                                self.oldCounterOfferDetail = self.counterOfferDetail = response.data.content.counter_offer_detail;
                            }
                            if (response.data.content.counter_offer_price != null) {
                                self.oldCounterOfferPrice = self.counterOfferPrice = response.data.content.counter_offer_price;
                            }
                            if (response.data.content.counter_offer_status != null) {
                                self.counterOfferStatus = response.data.content.counter_offer_status;
                            }

                            if(response.data.content.retail_ready != null) {
                              self.retailReady = response.data.content.retail_ready;
                            }
                            self.isEditAccessAllow = response.data.isEditAccessAllowed;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            checkForm: function () {
                var checked = true;
                self.counterOfferPriceError = "";

                if(self.isEditAccessAllow) {
                  for (const element of self.all_vin_data) {
                      element.offer_price_error = "";
                      element.expense_charged_error = "";
                      element.retail_price_error = "";
                      element.current_expenses_error = "";
                      element.expense_type_error = "";

                      if (!element.offer_price) {
                          element.offer_price_error = "Please enter offer price";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.offer_price && !self.numberFormateValidation(element.offer_price)) {
                          element.offer_price_error = "Please enter numeric value for offer price";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.offer_price && self.numberFormateValidation(element.offer_price) && element.offer_price <= 0) {
                          element.offer_price_error = "Please enter valid offer price";
                          if (checked) {
                              checked = false;
                          }
                      }
                      if (!element.expense_charged && element.expense_charged != 0) {
                          element.expense_charged_error = "Please enter estimate of expenses";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.expense_charged && !self.numberFormateValidation(element.expense_charged)) {
                          element.expense_charged_error = "Please enter numeric value for estimate of expenses";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.expense_charged && self.numberFormateValidation(element.expense_charged) && element.expense_charged < 0) {
                          element.expense_charged_error = "Please enter valid estimate of expenses";
                          if (checked) {
                              checked = false;
                          }
                      }

                      if (!element.retail_price) {
                          element.retail_price_error = "Please enter retail price";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.retail_price && !self.numberFormateValidation(element.retail_price)) {
                          element.retail_price_error = "Please enter numeric value for retail price";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.retail_price && self.numberFormateValidation(element.retail_price) && element.retail_price <= 0) {
                          element.retail_price_error = "Please enter valid retail price";
                          if (checked) {
                              checked = false;
                          }
                      }

                      if (!element.current_expenses && element.current_expenses != 0) {
                          element.current_expenses_error = "Please select current expenses";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.current_expenses && !self.numberFormateValidation(element.current_expenses)) {
                          element.current_expenses_error = "Please enter numeric value for current expenses";
                          if (checked) {
                              checked = false;
                          }
                      } else if (element.current_expenses && self.numberFormateValidation(element.current_expenses) && element.current_expenses < 0) {
                          element.current_expenses_error = "Please enter valid current expenses";
                          if (checked) {
                              checked = false;
                          }
                      }

                      if (element.expense_type.length == 0) {
                          element.expense_type_error = "Please select estimate of expense types";
                          if (checked) {
                              checked = false;
                          }
                      }  else {
                        /*
                          Description:
                            - Iterate through each expense type to check if an estimate of expenses is provided.
                            - If estimate of expenses is not provided and there are other expense types selected other than "No Expenses",
                            - Then, sets an error message and update the checked flag.
                            - Else If, estimate of expenses is provided and "No Expenses" option is selected,
                            - Then, set an error message and update the checked flag.
                          Parameters:
                            - element: The object containing expense-related information.
                            - checked: A boolean flag indicating whether the form is checked or not.
                        */
                        for (let i = 0; i < element.expense_type.length; i++) {
                          if (!element.expense_charged || element.expense_charged == 0 || element.expense_charged === null || typeof element.expense_charged ==='undefined') {

                            const hasOtherExpenses = element.expense_type.some(expense => expense.text !== 'No Expenses');
                            if(hasOtherExpenses) {
                              element.expense_type_error = "Please enter an estimate of expenses to add other estimate of expense types.";
                              if (checked) {
                                checked = false;
                              }
                            }
                          } else if(element.expense_charged && element.expense_charged > 0) {
                            const hasOtherExpenses = element.expense_type.some(expense => expense.text === 'No Expenses');
                            if(hasOtherExpenses) {
                              element.expense_type_error = "Selected estimate of expenses is non-zero, therefore no expenses option can't be added.";
                              if (checked) {
                                checked = false;
                              }
                            }
                          }
                        }
                      }
                  }
                }

                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.completed || self.notCompleted || self.counterOffer) {
                    if (self.completeById == null || typeof self.completeById === 'undefined'|| !self.completeById.value) {
                        self.completeByIdError = "Please select user";
                        if (checked) {
                            checked = false;
                        }
                    }
                }

                if(self.counterOffer) {
                  if (!self.counterOfferPrice) {
                        self.counterOfferPriceError = "Please enter counter offer price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.counterOfferPrice && !self.numberFormateValidation(self.counterOfferPrice)) {
                        self.counterOfferPriceError = "Please enter numeric value for counter offer price";
                        if (checked) {
                            checked = false;
                        }
                    } else if (self.counterOfferPrice && self.numberFormateValidation(self.counterOfferPrice) && self.counterOfferPrice <= 0) {
                        self.counterOfferPriceError = "Please enter valid counter offer price";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.buyer) {
                    self.buyerError = "Please enter Buyer Name";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            updateOfferSubmissionFormReq: function (counterOfferStatus) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var completeById = '';
                        if (self.completeById != null && typeof self.completeById !== 'undefined' && self.completeById.value) {
                            completeById = self.completeById.value
                        }

                        var counterOfferStatusVal = '';
                        if (counterOfferStatus != null && typeof counterOfferStatus !== 'undefined' && counterOfferStatus) {
                          counterOfferStatusVal = counterOfferStatus.toLowerCase();
                        }

                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('vin', self.vin);
                        data.append('buyer', self.buyer);
                        data.append('offer_price', self.offerPrice);
                        data.append('estimate_expense', self.estimateExpense);
                        data.append('is_buyer_financing', self.isBuyerFinancing);
                        data.append('has_customer_seen_truck', self.hasCustomerSeenTruck);
                        data.append('as_is', self.asIs);
                        data.append('completed', (self.completed) ? 'yes' : 'no');
                        data.append('not_completed', (self.notCompleted) ? 'yes' : 'no');
                        data.append('complete_by_id', completeById);
                        data.append('note', self.note);
                        data.append('location', self.location);
                        data.append('fleet', self.fleet);
                        data.append('vin_net_from_offer', self.vin_net_from_offer);
                        data.append('counter_offer', (self.counterOffer) ? 'yes' : 'no');
                        data.append('counter_offer_detail', self.counterOfferDetail);
                        data.append('counter_offer_price', self.counterOfferPrice);
                        data.append('counter_offer_status', counterOfferStatusVal);
                        data.append('retail_ready', self.retailReady);
                        data.append('all_vin_data', JSON.stringify(self.all_vin_data));
                        data.append('isEditAccessAllow', self.isEditAccessAllow);

                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/offersubmission/update", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
            /**
             * Function to change the status of a counter offer submission.
             * Displays a confirmation dialog before updating the status.
             * @param {string} counterOfferStatus - Status of the counter offer submission.
             */
            changeCounterOfferStatus: function(counterOfferStatus) {
              self.$swal({
                  title: '',
                  text: 'Are you sure that you want to ' + counterOfferStatus + ' this Counter Offer Submission!',
                  type: 'info',
                  confirmButtonText: 'OK',
                  showCancelButton: true,
                  showLoaderOnConfirm: true
              }).then((result) => {
                  if (result.value) {
                      self.updateOfferSubmissionFormReq(counterOfferStatus);
                  }
              });
            },
            /**
             * Opens a context menu based on the provided event, asset ID, and index.
             * If no asset ID is provided, it displays an informational message.
             *
             * @param {Event} e - The event triggering the context menu.
             * @param {string} id - The asset ID.
             * @param {number} index - The index of the context menu to open.
             */
            openContextMenu: function (e, id, index) {
                if(typeof id === 'undefined' || id == 'null' || id == null || id == '') {
                  self.showSAlert('No inventory selected!', 'info');
                } else {
                  self.assetId = id;
                  self.loadAssetExpenses(self.assetId, index, false);

                  if(self.all_vin_data.length > 0) {
                    for(let i=0; i < self.all_vin_data.length; i++) {
                      self.$refs.menu[i].close(e);
                    }
                  }
                  self.$refs.menu[index].open(e);
                }
            },
            loadAssetExpenses: function (assetId, index, isChangePage) {
                self.listExecutionState = true;
                let config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                let fields = {
                    filters: self.filters,
                    per_page: self.all_vin_data[index].perPage,
                    page: self.all_vin_data[index].currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    asset_id: assetId
                };
                HTTP.post("/assetwiseexpenses/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            if (data.status == 200) {
                                // self.clearSelection();
                                self.all_vin_data[index].totalRecords = data.data.content.result;
                                self.assetWiseExpensesArr = data.data.content.data;
                                if(self.assetWiseExpensesArr.length > 0) {

                                    self.totalInvExpenses =  data.data.content.totalInvExpensedata;
                                    // Check if there were no previously selected rows
                                    if(self.all_vin_data[index].previousSelectedRows.length <= 0) {

                                      // If 'selectAll' is true, populate selectedRows and calculate current expenses
                                      if(self.all_vin_data[index].selectAll) {
                                        self.all_vin_data[index].selectedRows = [];
                                        self.all_vin_data[index].current_expenses = 0;

                                        for(const element of self.totalInvExpenses) {
                                          if(element.id != '') {
                                              self.all_vin_data[index].selectedRows.push(element.id);

                                              if(element.actual_cost_wo_fmt != '') {
                                                self.all_vin_data[index].current_expenses += parseFloat(element.actual_cost_wo_fmt); // Calculate Current Expenses
                                              }
                                          }
                                        }

                                        if(self.all_vin_data[index].current_expenses != 0) {
                                          self.all_vin_data[index].current_expenses = Math.round(self.all_vin_data[index].current_expenses *100)/100;
                                        }
                                        self.all_vin_data[index].previousSelectedRows = self.all_vin_data[index].selectedRows;
                                      }
                                    } else {
                                      // Check if all rows are selected or not for specific asset
                                      if(self.all_vin_data[index].selectedRows.length == self.totalInvExpenses.length) {
                                        self.all_vin_data[index].selectAll = true;
                                      } else {
                                        self.all_vin_data[index].selectAll = false;
                                      }

                                      // If not a page change, restore selectedRows from previousSelectedRows
                                      if(!isChangePage) {
                                        self.all_vin_data[index].selectedRows = self.all_vin_data[index].previousSelectedRows;
                                      }
                                    }
                                }

                                self.addColumnClass(self.headers);

                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (const element of self.headers) {
                                    if (element['type'] == 'drop_down') {
                                        element['options_array'] = testArray;
                                    }
                                }
                                self.all_vin_data[index].perPage = parseInt(data.data.content.per_page);
                                self.all_vin_data[index].jumpToPage = parseInt(data.data.content.current_page);
                                // self.setAlreadyAppliedFilters();
                                self.all_vin_data[index].startEndRecord = self.calculateStartRecordEndRecord(self.all_vin_data[index].currentPage, self.all_vin_data[index].perPage, self.all_vin_data[index].totalRecords);
                                self.sortFieldDisplay(self.columnName, self.sortType);
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            self.catchHandler(error, function () {});
                        })
            },
            // Reset pagination for a specific asset and load expenses accordingly
            changePerPage : function(index){
                self.all_vin_data[index].currentPage = 1;
                self.all_vin_data[index].perPageValue = self.all_vin_data[index].perPage;
                self.loadAssetExpenses(self.assetId, index, false);
            },
            changepage : function(index){
                if(!self.listExecutionState){
                  self.loadAssetExpenses(self.assetId, index, true);
                }
            },
            clearSelection: function (index) {
                self.all_vin_data[index].selectAll = false;
                self.all_vin_data[index].selectedRows = [];
            },
            keyMonitor: function (index) {
                let tempNumber = self.jumpToInputPage(self.all_vin_data[index].jumpToPage, self.all_vin_data[index].totalRecords, self.all_vin_data[index].perPage);
                self.all_vin_data[index].currentPage = self.all_vin_data[index].jumpToPage = parseInt(tempNumber);
            },
            // Handle the selection or deselection of rows when a specific asset header checkbox is clicked
            clickHeaderCheckBox: function (e, index) {
                setTimeout(function () {
                    if(e.target.checked) {
                      self.all_vin_data[index].selectAll = true;
                      self.all_vin_data[index].selectedRows = [];

                      for (let i in self.totalInvExpenses) {
                          self.all_vin_data[index].selectedRows.push(self.totalInvExpenses[i].id);
                      }
                    } else {
                      self.all_vin_data[index].selectAll = false;
                      self.all_vin_data[index].selectedRows = [];
                    }
                }, 100);
            },
            inputCheckbox: function (e, index) {
                setTimeout(function () {
                    self.all_vin_data[index].selectAll = true;
                    if(self.all_vin_data[index].selectedRows.length != self.totalInvExpenses.length) {
                      self.all_vin_data[index].selectAll = false;
                    }
                }, 100);
            },
            /**
             * Function to handle asset expense submission which
             * 1) Update selection status
             * 2) Calculate total expenses
             * 3) Close a menu referenced by an index.
             * @param {Event} e - The event triggering the context menu.
             * @param {number} index - The index of the context menu to close.
             */
            submitAssetExpenses: function (e, index, data) {
                if(self.all_vin_data[index].selectedRows.length == self.totalInvExpenses.length) { // 1
                  self.all_vin_data[index].selectAll = true;
                } else {
                  self.all_vin_data[index].selectAll = false;
                }

                self.all_vin_data[index].current_expenses = 0;
                if(self.all_vin_data[index].selectedRows.length > 0) {
                  for (const element of self.all_vin_data[index].selectedRows) {
                    const hasExpObjectWithId = self.totalInvExpenses.find(expenseObj => expenseObj.id === element);
                    if (hasExpObjectWithId) {
                      self.all_vin_data[index].current_expenses += parseFloat(hasExpObjectWithId.actual_cost_wo_fmt); // 2
                      self.all_vin_data[index].current_expenses_error = '';
                    }
                  }

                  if(self.all_vin_data[index].current_expenses != 0) {
                    self.all_vin_data[index].current_expenses = Math.round(self.all_vin_data[index].current_expenses *100)/100;
                  }

                  self.all_vin_data[index].previousSelectedRows = self.all_vin_data[index].selectedRows;
                }

                self.$refs.menu[index].close(e); // 3
                self.calculateNetFromOffer(data);
            },
            calculateNetFromOffer : function(data) {
              /**
               * Date : 14th Mar,2024
               * Reason : Based on request from Drew Harrell, subtracting "Estimate of expense" inside net from offer formula
               * New Formula : Net From Offer = Offer price - Estimate of expense - Current expense
               * Old Formula : Net From Offer = Offer price - Current expense
               */

              data.vin_net_from_offer = self.getValidPrice(data.offer_price)
                              - self.getValidPrice(data.expense_charged)
                              - self.getValidPrice(data.current_expenses)
              data.vin_net_from_offer = self.roundAndFormat(data.vin_net_from_offer);
            },
            /**
            Get the price from the data, handling various edge cases.
            @param {any} data - The data value to be processed.
            @param {number} [defaultValue=0] - The default value to return if the data is invalid.
            @returns {number} - The processed price or the default value.
            */
            getValidPrice: function(data, defaultValue = 0) {

              if (data === null || data === undefined) { // Check for null or undefined
                return defaultValue;
              }

              const price = parseFloat(data); // Convert data to a number
              if (isNaN(price)) { // Check if the result is NaN (Not a Number)
                return defaultValue;
              }

              return price; // Return the valid price
            },
            // Function to round a number to two decimal places and remove trailing '.00'
            roundAndFormat: function(number) {
              var formattedOutput = '';
              if(number) {
                formattedOutput = (Math.round(number*100)/100).toFixed(2).replace(/\.00$/, '');
              }
              return formattedOutput;
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
          }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
        }
    });
</script>
<style>
    .update-offer-submission .estimate-expense-type .v-select .vs__selected-options {
      flex-wrap: wrap !important;
    }
    .offer-submission-card {
      width:100% !important;
    }
    .offer-submission-card #offerStatusHeader {
      float:left;
    }
    .offer-submission-card #offerStatusBody {
      float:right;
    }

    .update-offer-submission .expense-setting{
        position: absolute;
        right: -6px;
        top: 10px;
    }
    .update-offer-submission .current-expense-block .expense-menu{
        position: absolute;
        left:unset !important;
        right: 0;
        top: 40px !important;
        max-height: 400px;
        width: 620px;
    }
    .update-offer-submission .current-expense-block .pagination{
        border: 0;
        position: unset;
        max-height: unset;
        padding: 0;
        box-shadow: unset;
        min-width: unset;
        display: flex;
    }
    .update-offer-submission .current-expense-block .bottom-pagination-section {
      margin: auto !important;
      font-size:15px;
    }
    .update-offer-submission .pagination .page-item span.page-link {
      font-size:15px;
    }

    .update-offer-submission .estimate-expense-type .v-select .vs__selected-options {
      flex-wrap: wrap !important;
    }
</style>
