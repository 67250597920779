<template>
    <div class="modal fade" id="cal-vehicle-price-form" tabindex="-1" role="dialog" aria-labelledby="cal-vehicle-price-form" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-6"><h5 class="modal-title" id="cal-vehicle-price-form">{{ pageTitle }}</h5></div>
                    <div class="col-6">
                       <div class="row justify-content-end">
                            <button type="button" class="btn btn-outline-dark mr-1" aria-label="Close" @click="closeModal()">Close</button>
                            <button type="button" :value="buttonNames.save" class="btn btn-primary" @click="submitData()">Save</button>
                       </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div id="page-content-wrapper" class="admin-content-area">
                        <!-- <template v-if="permissionNameArray.includes('can_add_set_by_branch_defaults')"> -->
                        <template>

                            <!--page content-->
                            <div class="admin-page-content">
                                <!--Forms-->
                                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                                    <div class="row no-margin">
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">New Used:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <v-select 
                                                            v-model="vehicleId"
                                                            @input=""
                                                            name="staticValue"
                                                            id="new-used"
                                                            label="name"
                                                            :clearable="true"
                                                            :searchable="true"
                                                            @search="e => searchData(e,'vehicleTypeList')"
                                                            :filterable="false"
                                                            :options="vehicleTypeList"
                                                            :closeOnSelect="true"
                                                            placeholder="Select Vechicle Type">
                                                        <!-- <span slot="no-options" @click="$refs.select.open = false">
                                                            <template v-if="!isManagedByRecordNotFound">
                                                                {{staticMessages.managed_by_search}}
                                                            </template>
                                                            <template v-else>
                                                                {{staticMessages.search_record_not_found}}
                                                            </template>
                                                        </span> -->
                                                    </v-select>
                                                    <div class="requiredstar">*</div>
                                                    <span class="text-danger">{{ newUsedError }}</span>
                                                </template>
                                            </div>
                                        </div>

                                        <!-- NOT REQUIRED FOR NOW
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Manufacturer:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <v-select 
                                                            v-model="manufacturerId"
                                                            @input=""
                                                            name="manufacturer"
                                                            id="manufacturer"
                                                            label="name"
                                                            :clearable="true"
                                                            :searchable="true"
                                                            @search="e => searchData(e,'manufacturerList')"
                                                            :filterable="false"
                                                            :options="manufacturerList"
                                                            placeholder="Select Manufacturer"
                                                            disabled
                                                            >
                                                        </v-select>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Model:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <v-select 
                                                            v-model="modelId"
                                                            @input=""
                                                            name="model"
                                                            id="model"
                                                            label="name"
                                                            :clearable="true"
                                                            :searchable="true"
                                                            @search="e => searchData(e,'modelList')"
                                                            :filterable="false"
                                                            :options="modelList"
                                                            placeholder="Select Model"
                                                            disabled
                                                            >
                                                        </v-select>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Tax:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <v-select 
                                                            v-model="taxId"
                                                            @input=""
                                                            name="tax"
                                                            id="tax-rate"
                                                            label="name"
                                                            :clearable="true"
                                                            :searchable="true"
                                                            @search="e => searchData(e,'taxList')"
                                                            :filterable="false"
                                                            :options="taxList"
                                                            placeholder="Select Tax"
                                                            disabled
                                                            >
                                                        </v-select>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Duty:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <v-select 
                                                            v-model="duty"
                                                            @input=""
                                                            name="duty"
                                                            id="duty"
                                                            label="name"
                                                            :clearable="true"
                                                            :searchable="true"
                                                            @search="e => searchData(e,'dutyList')"
                                                            :filterable="false"
                                                            :options="dutyList"
                                                            placeholder="Select Duty"
                                                            disabled
                                                            >
                                                        </v-select>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Hold Back Percentage:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="percentage" v-model="hold_back_percentage" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                        -->

                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">Hold Back Fixed:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <input id="hold-back-fixed" v-model="hold_back_fixed" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>

                                        <!-- NOT REQUIRED FOR NOW
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Tier One Rate:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="tier-one-rate" v-model="tier_one_rate" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Tier Two Rate:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="tier-two-rate" v-model="tier_two_rate" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                        -->

                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">Tier One Tax Amount:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <input id="tier-one-tax-amount" v-model="tn_tier_one_tax_amt" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">Tier Two Tax Amount:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <input id="tier-two-tax-amount" v-model="tn_tier_two_tax_amt" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>

                                        <!-- NOT REQUIRED FOR NOW
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Fixed Commission:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="fixed-commission" v-model="fixed_commission" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Flat Commission:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="flat-commission" v-model="flat_commission" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Commission Gross Profit:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="commission-gross-profit" v-model="commission_gross_profit_per" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Calculated Commission:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="calculated-commission" v-model="calculated_commission_per" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-6 forms-block">
                                                <div class="col-3 paddingleft0">
                                                    <div class="form-label">Commission Next Level:</div>
                                                </div>
                                                <div class="col-9 paddingleft0">
                                                    <template>
                                                        <input id="commission-next-level" v-model="commission_next_level" class="form-control" type="text" disabled />
                                                    </template>
                                                </div>
                                            </div>
                                        -->
                                        
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">DMV Electronic Filling Fee:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <input id="dmv-electronic-filling-fee" v-model="dmv_electronic_filling_fee" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">License/Title:</div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <template>
                                                    <input id="license-title" v-model="license_title" class="form-control" type="text"/>
                                                </template>
                                            </div>
                                        </div>

                                        <!-- <div class="col-6 forms-block">
                                            <div class="col-3 paddingleft0">
                                                <div class="form-label">Custom Pack:</div>
                                            </div>
                                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                                <label class="container">
                                                    <input @change="" id="custom-pack" v-model="custom_pack" class="form-control" type="checkbox"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- Forms -->
                            </div>
                            <!--page content-->
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../../mixins/commonMixins';
    import EventBus from "../../../event-bus";
    import {HTTP} from '../../../../http-common';
    import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddStaticValueVechiclePrice',
        components: {
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        props: {
            editData: null
        },
        data() {
            return {
               pageTitle: 'Add Cal Vehicle Price',
               branchList: [],
               vehicleTypeList: [
                {
                    name: 'NEW',
                    value: 'new',
                },
                {
                    name: 'USED',
                    value: 'used',
                },
                {
                    name: 'BOTH',
                    value: 'both',
                }
               ],
               dutyList: [
                {
                    name: 'MED DUTY JAPANESE',
                    value: 'med duty japanese',
                },
                {
                    name: 'LIGHT DUTY',
                    value: 'light duty',
                },
                {
                    name: 'HVY DUTY',
                    value: 'hvy duty',
                },
                {
                    name: 'MED DUTY',
                    value: 'med duty',
                },
                {
                    name: 'WHOLESALE',
                    value: 'wholesale',
                },
                {
                    name: 'BODY BUILDER',
                    value: 'body builder',
                }
               ],
               vehicleId: '',
               manufacturerId: '',
               manufacturerList: [],
               manufacturerListTemp: [],
               modelId: '',
               modelList: [],
               modelListTemp: [],
               taxId: '',
               taxList: [],
               taxListTemp: [],
               duty: '',
               hold_back_percentage: 0,
               hold_back_fixed: 0,
               tier_one_rate: 0,
               tier_two_rate: 0,
               tn_tier_one_tax_amt: 0,
               tn_tier_two_tax_amt: 0,
               fixed_commission: 0,
               flat_commission: 0,
               commission_gross_profit_per: 0,
               calculated_commission_per: 0,
               commission_next_level: 0,
               custom_pack: false,
               isEdit: false,
               edit_id: 0,
               vehicleTypeListTemp: [],
               dutyListTemp: [],
               dmv_electronic_filling_fee: 0,
               license_title: 0,
               buttonNames: {
                    save: 'Save',
                    update: 'Update',
                },
                newUsedError: ''
            }
        },
        beforeMount() {
            self = this;
        },
        methods: {
            submitData() {
                if(self.isValid()) {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                    };
                    var data = new FormData();
                    data.append('location_id', self.$route.params.id);
                    data.append('is_used', self.vehicleId.value);
                    data.append('manufacturer_id', self.manufacturerId.id);
                    data.append('model_id', self.modelId.id);
                    data.append('tax_id', self.taxId.id);
                    data.append('duty', self.duty.value);
                    data.append('hold_back_percentage', self.hold_back_percentage);
                    data.append('hold_back_fixed', self.hold_back_fixed);
                    data.append('tier_one_rate', self.tier_one_rate);
                    data.append('tier_two_rate', self.tier_two_rate);
                    data.append('tn_tier_one_tax_amt', self.tn_tier_one_tax_amt);
                    data.append('tn_tier_two_tax_amt', self.tn_tier_two_tax_amt);
                    data.append('fixed_commission', self.fixed_commission);
                    data.append('flat_commission', self.flat_commission);
                    data.append('commission_gross_profit_per', self.commission_gross_profit_per);
                    data.append('calculated_commission_per', self.calculated_commission_per);
                    data.append('commission_next_level', self.commission_next_level);
                    data.append('custom_pack', self.custom_pack);
                    data.append('dmv_electronic_filling_fee', self.dmv_electronic_filling_fee);
                    data.append('type', 'cal_vehicle_price');
                    data.append('is_edit', self.isEdit);
                    data.append('edit_id', self.edit_id);
                    data.append('license_title', self.license_title);
                    HTTP.post("/add-update/static-data", data, config)
                        .then(response => {
                            if(response.data.code == 200) {
                                self.showSAlert(response.data.message, response.data.status, false, 2);
                                self.clearData();
                                EventBus.$emit("cal-vehicle-price-list-data",response.data.content);
                            }
                        }).catch(error => {
                            if (error.response.status == 422){
                                const errorMessages = Object.entries(error.response.data);
                                for(const err of errorMessages) {
                                    self.showSAlert(err[1][0], 'error', false, 2);
                                    break;
                                }
                            }
                        });
                }
            },
            
            clearData() {
                self.vehicleId = '';
                self.manufacturerId = '';
                self.modelId = '';
                self.taxId = '';
                self.duty = '';
                self.hold_back_percentage = 0;
                self.hold_back_fixed = 0;
                self.tier_one_rate = 0;
                self.tier_two_rate = 0;
                self.tn_tier_one_tax_amt = 0;
                self.tn_tier_two_tax_amt = 0;
                self.fixed_commission = 0;
                self.flat_commission = 0;
                self.commission_gross_profit_per = 0;
                self.calculated_commission_per = 0;
                self.commission_next_level = 0;
                self.custom_pack = false;
                self.isEdit = false;
                self.edit_id = 0;
                self.pageTitle = 'Add Cal Vehicle Price';
                self.license_title = 0;
                self.dmv_electronic_filling_fee = 0;
            },

            searchData(param,type) {
                var tempList = type+'Temp';
                if(param !== '') {
                    self[type] = self[tempList].filter((data) => {
                        return data.name.toLowerCase().indexOf(param.toLowerCase()) > -1;
                    });
                } else {
                    self[type] = self[tempList];
                }
            },

            isValid() {
                var checked = true;
                if (this.vehicleId == '' || this.vehicleId == undefined) {
                    this.newUsedError = "This field is required!";
                    if (checked) {
                        checked = false;
                    }
                } else {
                    this.newUsedError = "";
                }
                return checked;
            },

            closeModal() {
                self.clearData();
                EventBus.$emit("close-vehicle-price-default", false);
            },
        },
        watch: {
            editData(data) {
                if(data.is_used) {
                    self.vehicleTypeList.filter(vehicleType => {
                        if(vehicleType.value == data.is_used) {
                            self.vehicleId = vehicleType;
                            return true;
                        }
                    });
                    self.dutyList.filter(duty => {
                        if(duty.value == data.duty) {
                            self.duty = duty;
                            return true;
                        }
                    });
                    self.manufacturerList.filter(manufacturer => {
                        if(manufacturer.id == data.manufacturer_id) {
                            self.manufacturerId = manufacturer;
                            return true;
                        }
                    });
                    self.modelList.filter(model => {
                        if(model.id == data.model_id) {
                            self.modelId = model;
                            return true;
                        }
                    });
                    self.taxList.filter(tax => {
                        if(tax.id == data.tax_id) {
                            self.taxId = tax;
                            return true;
                        }
                    });
                    self.hold_back_percentage = data.hold_back_percentage;
                    self.hold_back_fixed = data.hold_back_fixed;
                    self.tier_one_rate = data.tier_one_rate;
                    self.tier_two_rate = data.tier_two_rate;
                    self.tn_tier_one_tax_amt = data.tn_tier_one_tax_amt;
                    self.tn_tier_two_tax_amt = data.tn_tier_two_tax_amt;
                    self.fixed_commission = data.fixed_commission;
                    self.flat_commission = data.flat_commission;
                    self.commission_gross_profit_per = data.commission_gross_profit_per;
                    self.calculated_commission_per = data.calculated_commission_per;
                    self.commission_next_level = data.commission_next_level;
                    self.custom_pack = data.custom_pack;
                    self.dmv_electronic_filling_fee = data.dmv_electronic_filling_fee;
                    self.license_title = data.license_title;
                    self.isEdit = true;
                    self.edit_id = data.id;
                    self.pageTitle = 'Update Cal Vehicle Price';
                } else {
                    self.clearData();
                }
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_add_set_by_branch_defaults') && !self.permissionNameArray.includes('can_delete_set_by_branch_defaults') && !self.permissionNameArray.includes('can_edit_set_by_branch_defaults')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });

    /*##### NOT REQUIRED BECAUSE THESE FIELD ARE DISABLED FOR NOW #####
        EventBus.$on("select-field-data",function (listData){
            if (typeof self != "undefined"){
                self.manufacturerList = listData.manufacturerList;
                self.modelList = listData.modelList;
                self.taxList = listData.taxList;

                self.manufacturerListTemp = listData.manufacturerList;
                self.modelListTemp = listData.modelList;
                self.taxListTemp = listData.taxList;
                self.vehicleTypeListTemp = self.vehicleTypeList;
                self.dutyListTemp = self.dutyList;
            }
        });
    */
</script>