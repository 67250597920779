<template>
    <div class="add-contact-popup-form  customer-Contact-popup-form admin-content-area">
        <div class="modal fade bd-example-modal-lg" id="addUpdateCustomerContactPopup" tabindex="-1" role="dialog"
                aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Contact</h4>
                            <button type="submit" v-on:click.prevent="closeModalPopup()" class="btn btn-primary waves-effect pull-right">Close</button>
                        </div>
                        <div class="modal-body">
                            <customer-contact-form-fields :refeshFlag="refeshFlag" ref="addUpdateCustomerContactRef" @call-modal-close="closeModalPopup" open_at="popupView" :form_fields="form_fields"></customer-contact-form-fields>
                        </div>

                        <!-- Modal footer -->
                        <div class="modal-footer">
                            <div class="button-demo ml-3">
                                <input type="submit" @click="saveContact" class="btn btn-primary" value="Save">
                                <input type="submit" @click="closeModalPopup" class="btn btn-primary" value="Close">
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import CustomerContactFormFields from './CustomerContactFormFields.vue';
import CustomerContactFields from '../../json/customer_contact_form_fields';
import { saveCustomerContactData} from "./customer";
import {commonMixins} from '../../mixins/commonMixins';
import EventBus from ".././event-bus";

var self;
export default {
    name: "CustomerContactFormPopup",
    components: {
        CustomerContactFormFields
    },
    mixins: [commonMixins],
    data() {
        return {
            refeshFlag: true,
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
            form_fields: JSON.parse(JSON.stringify(CustomerContactFields)),
            customer_id: ""
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {
    },
    methods: {
        openAddContactPopup(data) {
            $("#addUpdateCustomerContactPopup").modal("show");
            self.form_fields = JSON.parse(JSON.stringify(CustomerContactFields));
            self.customer_id = data.customer_id;
            self.form_fields.company_name.value = data.customer_name;
            if(self.production_country == 'US') {
                self.form_fields.abn.display = false;    
            }
            self.form_fields.abn.value = data.abn_no;
            self.refeshFlag = !self.refeshFlag;
            self.$refs.addUpdateCustomerContactRef.openCustomerContactPopup(data);
        },
        closeModalPopup() {
            $("#addUpdateCustomerContactPopup").modal("hide");
        },
        saveContact() {
            // Define a callback function that accepts parameters
            const saveContactCallbackFunction = (data, flag) => {
                if(flag == "validationError"){
                    self.form_fields = JSON.parse(JSON.stringify(data.form_fields));
                    self.refeshFlag = !self.refeshFlag;
                }else if(flag == "reqResponse"){
                    if(data.content.status == "success") {
                        self.closeModalPopup();
                        self.showSAlert(data.content.message, 'success', false);
                        EventBus.$emit('customer-contact-saved', "");

                        
                    }else{
                        self.showSAlert(data.content.message ? data.content.message : "Some error occure, Please try later.", "error", false);
                    }
                }
            };

            let reqData = {
                customer_id: self.customer_id,
                form_fields: JSON.parse(JSON.stringify(self.form_fields))
            };
            saveCustomerContactData(reqData, saveContactCallbackFunction);
        }
    }

}
</script>
<style>
.customer-Contact-popup-form .checkbox-label{
    padding-right: 10px;
    text-align: right;
}
</style>
