<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_customer_lead_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Contact Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="contactNameError = ''" id="contactName" v-model="contactName" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{contactNameError}}</span>
                                </div>
                            </div>
                            <!-- Title -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.title_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="titleError = ''" id="title" v-model="title" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{titleError}}</span>
                                </div>
                            </div>
                            <!-- Company Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="companyNameError = ''" id="companyName" v-model="companyName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyNameError}}</span>
                                </div>
                            </div>
                            <!-- Primary Phone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.primary_phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="primaryPhoneError = ''" id="primaryPhone" v-model="primaryPhone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{primaryPhoneError}}</span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>
                            <!-- Share Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.share_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="shareType"
                                              @change="changedValue"
                                              name="shareType"
                                              id="shareType"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="shareTypeArr"
                                              :placeholder="staticMessages.share_type_place_holder">
                                    </v-select>
                                    <!--<input @input="shareTypeError = ''" id="shareType" v-model="shareType" class="form-control" type="text"/>-->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{shareTypeError}}</span>
                                </div>
                            </div>
                            <!-- Managed By Id -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.managed_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                            v-model="managedBy"
                                            @change="changedValue"
                                            @input="managedByError = ''"
                                            @search="onSearchManagedBy"
                                            @clear="onClearManagedBy"
                                            name="managedBy"
                                            id="managedBy"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="managedByArr"
                                            :placeholder="staticMessages.managed_by_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isManagedByRecordNotFound">
                                                {{staticMessages.managed_by_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<input @input="managedByError = ''" id="managedBy" v-model="managedBy" class="form-control" type="text"/>-->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{managedByError}}</span>
                                </div>
                            </div>
                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="statusId"
                                              @change="changedValue"
                                              name="status"
                                              id="status"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="statusArr"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>
                             <!-- Notes -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="notesError = ''" id="notes" v-model="notes" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{notesError}}</span>
                                </div>
                            </div>
                           
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addContactLeadBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addContactLead()">
                            <input id="addAndContinueContactLeadBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addContactLead(flag='Save and Continue')">
                            <input id="cancelContactLead" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    Vue.component("app-footer", Footer);
    Vue.component("no-access", NoAccessPage);

    export default {
        name: 'AddUpdateContactLead',
        mixins: [commonMixins],
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                shareType: "",
                shareTypeArr: [
                    {
                        text: 'Private',
                        value: 'Private'
                    },
                    {
                        text: 'Local',
                        value: 'Local'
                    },
                    {
                        text: 'Global',
                        value: 'Global'
                    }
                ],
                contactName: "",
                title: "",
                companyName: "",
                primaryPhone: "",
                email: "",
                notes: "",
                managedBy: "",
                statusId: {text: 'Active', value: 'active'},
                statusArr: [
                    {
                        text: 'Active',
                        value: 'active'
                    },
                    {
                        text: 'Inactive',
                        value: 'inactive'
                    }
                ],

                shareTypeError: "",
                contactNameError: "",
                titleError: "",
                companyNameError: "",
                primaryPhoneError: "",
                emailError: "",
                notesError: "",
                managedByError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Customer Lead",
                permissionNameArray : ['can_add_customer_lead_master'],
                isManagedByRecordNotFound: false,
                managedByArr : [],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('contactlead-list');
            EventBus.$emit("menu-selection-changed", "contactlead-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getContactLeadDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueContactLeadBtn").hide();
                self.pageTitle = "Edit Customer Lead";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/contactlead/list');
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            onSearchManagedBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadManagedByCallback, loading, search);
                }
            },
            loadManagedByCallback: function (callbackData) {
                self.isManagedByRecordNotFound = false;
                self.managedByArr = callbackData.User;
                if(self.managedByArr.length == 0) {
                    self.isManagedByRecordNotFound = true;
                }
            },
            onClearManagedBy : function() {
                self.managedByError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.shareType = "";
                self.contactName = "";
                self.title = "";
                self.companyName = "";
                self.primaryPhone = "";
                self.email = "";
                self.notes = "";
                self.managedBy = "";
                self.statusId = {
                    text: 'Active',
                    value: 'active'
                };
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getContactLeadDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/contactlead/get', config)
                    .then(response => {
                        self.shareType = {text: self.formatString(response.data.content.share_type), value: response.data.content.share_type};
                        self.contactName = response.data.content.contact_name;
                        self.title = response.data.content.title;
                        self.companyName = response.data.content.company_name;
                        self.primaryPhone = response.data.content.primary_phone;
                        self.email = response.data.content.email;
                        self.notes = response.data.content.notes;
                        if(response.data.content.managed_by_id != null && response.data.content.managed_by_id != 0){
                            self.managedBy = {text: self.formatString(response.data.content.manage_first_name + " " + response.data.content.manage_last_name), value: response.data.content.managed_by_id};
                        }
                        self.statusId = {text: self.formatString(response.data.content.status), value: response.data.content.status};
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.shareType == null || !self.shareType.value) {
                    self.shareTypeError = "Please enter share Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.contactName) {
                    self.contactNameError = "Please enter Customer Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.title) {
                    self.titleError = "Please enter title";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.companyName) {
                    self.companyNameError = "Please enter company Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.primaryPhone) {
                    self.primaryPhoneError = "Please enter primary Phone";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.notes) {
                    self.notesError = "Please enter notes";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.managedBy == null || !self.managedBy.value) {
                    self.managedByError = "Please enter managed By";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addContactLead(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('share_type', self.shareType.value);
                        data.append('contactName', self.contactName);
                        data.append('title', self.title);
                        data.append('company_name', self.companyName);
                        data.append('primary_phone', self.primaryPhone);
                        data.append('email', self.email);
                        data.append('notes', self.notes);
                        // data.append('managed_by_id', self.managedBy);
                        data.append('managed_by_id', self.managedBy.value);
                        data.append('status', self.statusId.value);

                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/contactlead/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>