<template>
    <div id="trns-cash">
        <div>
            <label for="salesPrice">Sales Price:</label>
            <money
            v-bind="moneyMask"
            id="salesPrice"
            v-model="salesPrice"
            type="text"
            disabled
            />
        </div>
        <div>
            <label for="downpayment">Downpayment:</label>
            <money
            v-bind="moneyMask"
            @blur.native="calculateQuot"
            id="downpayment"
            v-model="downpaymentAmt"
            type="text"
            />
        </div>
        <div>
            <label for="tradeAllowance">Trade Allowance:</label>
            <money
            v-bind="moneyMask"
            @blur.native="calculateQuot"
            id="tradeAllownce"
            v-model="tradeAllownce"
            type="text"
            />
        </div>
        <div>
            <label for="totalFees">Total Fees:</label>
            <money
            v-bind="moneyMask"
            id="totalFees"
            v-model="totalFees"
            type="text"
            disabled
            />
        </div>
        <div>
            <label for="totalSalesTax">Total Sales Tax:</label>
            <money
            v-bind="moneyMask"
            id="totalSalesTax"
            v-model="totalSalesTax"
            type="text"
            disabled
            />
        </div>
        <div>
            <label for="total">Total:</label>
            <money
            v-bind="moneyMask"
            id="total"
            v-model="total"
            type="text"
            disabled
            />
        </div>
        <div>
            <label for="warranties">Warranties:</label>
            <money
            v-bind="moneyMask"
            id="warranties"
            v-model="warranties"
            type="text"
            disabled
            />
        </div>
        <div>
            <label for="insurance">Insurance:</label>
            <money
            v-bind="moneyMask"
            @blur.native="calculateQuot"
            id="insurance"
            v-model="insurance"
            type="text"
            />
        </div>
        <div>
            <label for="totalPlusWarranties">Total + Warranties:</label>
            <money
            v-bind="moneyMask"
            id="ttlWarrenties"
            v-model="totalPlusWarranties"
            type="text"
            disabled
            />
        </div>
    </div>
</template>

<script>
import { Money } from "v-money";
import NoAccessPage from "../../../NoAccessPage/NoAccessPage";

export default {
  name: "Cash",
  components: {
    "no-access": NoAccessPage,
    Money,
  },
  props: ['salesPriceProp','downpaymentAmtProp','tradeAllownceProp','totalFeesProp','totalSalesTaxProp','insuranceProp','totalPlusWarrantiesProp','warrantiesProp','totalProp'],
  data() {
    return {
        salesPrice: 0.0,
        salesPriceError: "",
        downpaymentAmt: 0.0,
        downpaymentAmtError: "",
        tradeAllownce: 0,
        tradeAllownceError: "",
        totalFees: 0.0,
        totalFeesError: "",
        totalSalesTax: 0.0,
        totalSalesTaxError: "",
        insurance: 0,
        insuranceError: "",
        totalPlusWarranties: 0,
        warranties: 0,
        warrantiesError: "",
        total: 0.0,
        totalError: "",

        moneyMask: {
            thousands: ",",
            prefix: "$ ",
        },
    }
  },
  watch: {
    salesPriceProp(value) {
        this.salesPrice = value;
    },
    downpaymentAmtProp(value) {
        this.downpaymentAmt = value;
    },
    tradeAllownceProp(value) {
        this.tradeAllownce = value;
    },
    totalFeesProp(value) {
        this.totalFees = value;
    },
    totalSalesTaxProp(value) {
        this.totalSalesTax = value;
    },
    insuranceProp(value) {
        this.insurance = value;
    },
    totalPlusWarrantiesProp(value) {
        this.totalPlusWarranties = value;
    },
    warrantiesProp(value) {
        this.warranties = value;
    },
    totalProp(value) {
        this.total = value;
    }
  }
}
</script>

<style :scoped>
    #trns-cash > div > .btn {
    display: flex;
    margin-bottom: 5px; /* Adjust the spacing between each .fm-group if needed */
    }

    #trns-cash > div > label {
    color: #242731;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    /* Give minimum width to the level so that elements on the right start from same line each row */
    min-width: 133px; /* Set a minimum width for each label */
    margin-right: 36px; /* Adjust as needed to create the desired spacing between label and input */
    }

    #trns-cash > div > input {
    width: 173px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid #dbe0e7;
    background: #fff;
    color: var(--light-version-type-dark, #2d2d2d);
    text-align: right;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    }

    /* Center the content */
    #trns-cash {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px; /* Adjust the spacing between each .fm-group if needed */
    }

    #trns-cash > div {
    display: flex;
    }
</style>