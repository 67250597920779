<template>
  <div
    class="modal"
    id="addOnModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addOnModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-addon-lg" role="document">
      <div class="modal-content addon-modal-content">
        <div class="modal-header addon-modal-row">
          <div id="addon-modal-header-title">Add On Deal</div>
          <div>
            <button id="addon-close-btn" @click="closeAddonOnDealModal()">Close</button>
            <button id="addon-save-btn" @click="saveAddOnList()" :disabled="isAddonLoader">{{!isEdit ? 'Save' : 'Update'}}</button>
          </div>
        </div>
        <div class="modal-body">
          <div class="add-on-row">
            <div class="add-on-column">
              <div class="fm-group">
                <label for="select-items">Select Items <span class="addon-required" title="REQUIRED FIELD">*</span></label>
                <v-select
                  v-model="itemName"
                  name="item"
                  id="item"
                  label="item"
                  :options="itemList"
                  @input="showAddOnForm"
                  placeholder="Select Item"
                  class="vhc-select vhc-addon-select-items"
                  :clearable="false"
                ></v-select>
                <span class="addon-required-alert" v-if="itemNameError !== ''">{{ itemNameError }}</span>
              </div>
              <div class="fm-grup" v-if="itemName.panel == 2 || itemName.panel == 3 || showFields">
                <label for="type">Type <span class="addon-required" title="REQUIRED FIELD">*</span></label>
                <v-select
                  v-model="equipment"
                  name="equipment"
                  id="equipment"
                  label="eq_des"
                  :options="equipmentList"
                  @input="equipmentEffect"
                  placeholder="Select Type"
                  class="vhc-select vhc-addon-select-equipment"
                  :clearable="false"
                ></v-select>
                <span class="addon-required-alert" v-if="equipmentError !== ''">{{ equipmentError }}</span>
              </div>
              <div id="inner-column">
                <div>
                  <div class="fm-group">
                    <label for="price">Price</label>
                    <money
                      v-bind="moneyMask"
                      type="text"
                      class="add-on-input"
                      v-model="price"
                      @keyup.native="
                        (event) => {
                          incrementDecrementNumber(event, 'price');
                        }
                      "
                    />
                  </div>
                  <div class="fm-group-checkbox" v-if="itemName.panel == 2 || itemName.panel == 3 || showFields">
                    <input
                      type="checkbox"
                      class="add-on-checkbox"
                      v-model="isNotCharged"
                    />
                    <label for="not-changed">Not Charged</label>
                  </div>
                </div>
                <div v-if="itemName.panel == 2 || itemName.panel == 3 || showFields">
                  <div class="fm-group">
                    <label for="cost">Cost</label>
                    <money
                      v-bind="moneyMask"
                      type="text"
                      class="add-on-input"
                      v-model="cost"
                      @keyup.native="
                        (event) => {
                          incrementDecrementNumber(event, 'cost');
                        }
                      "
                    />
                  </div>
                  <div class="fm-group-checkbox">
                    <input
                      type="checkbox"
                      class="add-on-checkbox"
                      v-model="isNoCost"
                    />
                    <label for="not-changed">No Cost</label>
                  </div>
                </div>
              </div>
              <div class="fm-group">
                <label for="description">Description <span class="addon-required" title="REQUIRED FIELD">*</span></label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  v-model="description"
                  @input="descriptionError=''"
                ></textarea>
                <span class="addon-required-alert" v-if="descriptionError !== ''">{{ descriptionError }}</span>
              </div>
              <div class="fm-group">
                <label for="tax">Tax <span class="addon-required" title="REQUIRED FIELD">*</span></label>
                <v-select
                  v-model="tax"
                  name="name"
                  id="tax"
                  label="text"
                  :options="taxList"
                  placeholder="Select Tax"
                  class="vhc-select"
                ></v-select>
                <span class="addon-required-alert" v-if="taxError !== ''">{{ taxError }}</span>
              </div>
            </div>
          </div>
          <div class="add-on-row">
            <div class="add-on-footer">
              <div>
                <input
                  type="checkbox"
                  class="add-on-checkbox"
                  v-model="isAddToTotalPrice"
                /><label for="add-to-total-price">Add To Total Price</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  class="add-on-checkbox"
                  v-model="isShowOnDocuments"
                /><label for="show-on-documents">Show On Documents</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  class="add-on-checkbox"
                  v-model="isFetExempt"
                /><label for="fet-exempt">FET Exempt</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  class="add-on-checkbox"
                  v-model="doNotAddToGp"
                /><label for="do-not-add-to-gp">Do Not Add To Gp</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-loader refId="commonLoaderAddonItem" ref="commonLoaderAddonItem" />
  </div>
</template>

<script>
import EventBus from "../../../event-bus";
import { HTTP } from "../../../../http-common";
import { Money } from "v-money";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "AddOnDealModal",
  props: [
    "addOnEditData",
    "editAssetId",
    "addOnTempIndex",
    "customerCountyId",
    "isClickAddOnList",
  ],
  components: {
    Money,
    CommonLoader,
  },
  data() {
    return {
      itemList: [],
      equipmentList: [],

      equipment: "",
      itemName: "",
      showForm: false,

      price: 0,
      description: "",
      cost: 0,
      tax: "",
      isNotCharged: false,
      isNoCost: false,
      isAddToTotalPrice: false,
      isShowOnDocuments: false,
      isFetExempt: false,
      doNotAddToGp: false,
      dealInventoryMapperId: 0,
      id: null,
      isEdit: false,
      taxList: [
        {
          text: "EXEMPT (0.00000)",
          value: 0,
        },
      ],
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        precision: 2,
      },
      equipmentError: "",
      descriptionError: "",
      taxError: "",
      notCharged: [
        "C-COM",
        "C-FCOM",
        "C-FETC",
        "C-INC",
        "C-MISC",
        "C-MRSV",
        "C-OPO",
        "C-RBCM",
        "C-RBC",
        "C-RSV",
      ],
      noCost: ["E-ADA", "E-DEL", "E-DELP", "E-FPE", "E-TT"],
      notAddToGP: ["E-DEL", "E-FPE", "I-INS"],
      showFields: true,
      tempIndex: null,
      itemNameError: "",
      isAddonLoader: false,
      addToTotalPrice: ["E-DEL", "E-FPE", "I-INS"],
      showOnDocuments: ["E-DEL", "E-FPE", "I-INS"],
      fetExempt: ["E-DEL", "E-FPE", "I-INS"],
    };
  },
  methods: {
    showAddOnForm(event) {
      this.itemNameError = "";
      this.showForm = true;
      this.equipment = "";
      this.price = 0;
      this.description = event.panel == 2 || event.panel == 3 ? "" : event.item;
      this.cost = 0;
      this.isNotCharged = false;
      this.isNoCost = false;
      this.isAddToTotalPrice = false;
      this.isShowOnDocuments = false;
      this.isFetExempt = false;
      if(event.excede_item_id == 953) {
        this.isAddToTotalPrice = true,
        this.isShowOnDocuments = true,
        this.isFetExempt = true,
        this.doNotAddToGp = true;
      } else {
        this.isAddToTotalPrice = false,
        this.isShowOnDocuments = false,
        this.isFetExempt = false,
        this.doNotAddToGp = false;
      }

      if(event.panel == 2 || event.panel == 3) {
        this.showFields = true;
      } else {
        this.showFields = false;
      }
      this.descriptionError = "";
    },
    saveAddOnList() {
      if (this.checkCustomerForm()) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        let equipmentId = this.getEquipment();
        let data = {
          deal_inventory_id: this.dealInventoryMapperId,
          excede_item_id: this.itemName.id,
          equipment_id: equipmentId,
          price: parseFloat(this.price),
          description:
            !!this.equipment &&
            this.description.indexOf(this.equipment.eq_id) == -1
              ? this.equipment.eq_id + " - " + this.description
              : this.description,
          cost: parseFloat(this.cost),
          tax: this.tax.text,
          is_not_charged: this.isNotCharged,
          is_no_cost: this.isNoCost,
          is_add_to_total_price: this.isAddToTotalPrice,
          is_show_on_documents: this.isShowOnDocuments,
          is_fet_exempt: this.isFetExempt,
          is_do_not_add_to_gp: this.doNotAddToGp,
          id: this.id,
          is_edit: this.isEdit,
          excede_item: this.itemName,
          temp_index: this.tempIndex,
        };
        
        if (this.dealInventoryMapperId == 0 || this.dealInventoryMapperId == null) {
          let AddOnTemp = [];
          if (localStorage.getItem("AddOnTemp") != null) {
            AddOnTemp = JSON.parse(localStorage.getItem("AddOnTemp"));
          }
          if (this.isEdit === true) {
            AddOnTemp[this.tempIndex] = data;
          } else {
            data['temp_index'] = AddOnTemp.length;
            AddOnTemp.push(data);
          }
          localStorage.setItem("AddOnTemp", JSON.stringify(AddOnTemp));
          EventBus.$emit("add-on-data", data, 'temp', this.isEdit);
        } else {
          this.isAddonLoader = true;
          $("#commonLoaderAddonItem").find(".loading-container.lodingTable").show();
          HTTP.post("store/add-on-deal", data, config)
            .then((response) => {
              this.isAddonLoader = false;
              $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
              this.clearData();
              EventBus.$emit("add-on-data", response.data.content, 'store', this.isEdit);
            })
            .catch(function (err) {
              this.isAddonLoader = false;
              $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
            });
        }
        Object.assign(this.$data, this.$options.data());
      }
    },
    checkCustomerForm() {
      let checked = true;
      if (
        (this.itemName.id == 1 || this.itemName.id == 9) &&
        !this.equipment
      ) {
        this.equipmentError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.equipmentError = "";
      }
      if (!this.description) {
        this.descriptionError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.descriptionError = "";
      }
      if (!this.tax) {
        this.taxError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.taxError = "";
      }
      if (!this.itemName) {
        this.itemNameError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.itemNameError = "";
      }
      return checked;
    },
    getEquipment() {
      if(this.equipment == "" || this.equipment == undefined || this.equipment == null) {
        return "";
      }
      return this.equipment.id;
    },
    getExcedeEquipment() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      return new Promise((resolve, reject) => {
        $("#commonLoaderAddonItem").find(".loading-container.lodingTable").show();
        HTTP.get("get-add-on-excede-equipments", config)
          .then((response) => {
            this.equipmentList = response.data.content.equipments;
            this.itemList = response.data.content.excede_items;
            $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
            resolve(response);
          })
          .catch((err) => {
            $("#commonLoaderAddonItem").find(".loading-container.lodingTable").hide();
            this.catchHandler(err, function () {});
            reject(err);
          });
      });
    },
    equipmentEffect(event) {
      this.equipmentError = "";
      this.descriptionError = "";
      this.description = event.eq_des;
      if (this.notCharged.includes(event.eq_id)) {
        this.isNotCharged = true;
        this.isNoCost = false;
      } else {
        this.isNotCharged = false;
      }

      if (this.noCost.includes(event.eq_id)) {
        this.isNoCost = true;
        this.isNotCharged = false;
      } else {
        this.isNoCost = false;
      }

      if (this.addToTotalPrice.includes(event.eq_id)) {
        this.isAddToTotalPrice = true;
      } else {
        this.isAddToTotalPrice = false;
      }

      if (this.showOnDocuments.includes(event.eq_id)) {
        this.isShowOnDocuments = true;
      } else {
        this.isShowOnDocuments = false;
      }

      if (this.notAddToGP.includes(event.eq_id)) {
        this.doNotAddToGp = true;
      } else {
        this.doNotAddToGp = false;
      }

      if (this.fetExempt.includes(event.eq_id)) {
        this.isFetExempt = true;
      } else {
        this.isFetExempt = false;
      }

    },
    edit(addOnData) {
      let itemSelected = this.itemList.filter((currentData) => {
        if (currentData.id == addOnData.excede_item_id) {
          return currentData;
        }
      });

      let taxSelected = this.taxList.filter((currentData) => {
        if (currentData.text == addOnData.tax) {
          return currentData;
        }
      });
      this.itemName = itemSelected[0];
      this.price = addOnData.price;
      this.cost = addOnData.cost;
      this.isNotCharged = addOnData.is_not_charged;
      this.isNoCost = addOnData.is_no_cost;
      this.isAddToTotalPrice = addOnData.is_add_to_total_price;
      this.isShowOnDocuments = addOnData.is_show_on_documents;
      this.isFetExempt = addOnData.is_fet_exempt;
      this.doNotAddToGp = addOnData.is_do_not_add_to_gp;
      this.tax = taxSelected[0];
      this.showFields = false;

      let equipmentSelected = this.equipmentList.filter((currentData) => {
        if (currentData.id == addOnData.equipment_id) {
          return currentData;
        }
      });
      this.equipment = equipmentSelected[0];
      this.description = addOnData.description;
      this.id = addOnData.id;
      this.isEdit = true;
      this.showForm = true;
      this.tempIndex = addOnData.temp_index;
      return true;
    },
    incrementDecrementNumber(event, dataInstance) {
      switch (event.key) {
        case "ArrowUp":
          this[dataInstance] += 1;
          break;
        case "ArrowDown":
          this[dataInstance] -= 1;
          break;
      }
    },
    closeAddonOnDealModal() {
      this.clearData();
      EventBus.$emit("close-add-on-data-modal", false);
    },
    clearData() {
      this.itemList = [];
      this.equipmentList = [];
      this.equipment = "";
      this.itemName = "";
      this.showForm = false;
      this.price = 0;
      this.description = "";
      this.cost = 0;
      this.tax = "";
      this.isNotCharged = false;
      this.isNoCost = false;
      this.isAddToTotalPrice = false;
      this.isShowOnDocuments = false;
      this.isFetExempt = false;
      this.doNotAddToGp = false;
      this.dealInventoryMapperId = 0;
      this.id = null;
      this.isEdit = false;
      this.equipmentError = "";
      this.descriptionError = "";
      this.taxError = "";
      this.showFields = true;
      this.itemNameError = "";
      return true;
    }
  },
  watch: {
    async addOnEditData (addOnData) {
      if(this.isClickAddOnList) {
        if (Object.keys(addOnData).length == 0 && this.isEdit === true) {
          Object.assign(this.$data, this.$options.data());
        }
        await this.getExcedeEquipment();
        this.dealInventoryMapperId = this.editAssetId;
        let countyValue = this.customerCountyId.name.match(/\[(.*?)\]/);
        if (countyValue && this.taxList.length == 1) {
          this.taxList.push({
            text: this.customerCountyId.name,
            value: countyValue[1],
          });
        }

        if (Object.keys(addOnData).length > 0) {
            this.edit(addOnData);
        } else {
          this.tax = {
            text: "EXEMPT (0.00000)",
            value: 0,
          };
        }
      }
    }
  },
};
</script>

<style :scoped>
.addon-modal-content {
  padding: 0 10px;
}

.addon-modal-row {
  align-items: center !important;
}

.addon-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

#addon-modal-header-title {
  color: #506b71;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.addon-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

#addon-close-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #dbe0e7;
}

#addon-save-btn {
  width: 80px;
  height: 36px;
  border-radius: 6px;
  border: none;
  background-color: #076eb0;
  margin-left: auto;

  /* text */
  text-align: center;
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  color: #fff;
}

.add-on-row {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 12px;
}

.add-on-column {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-column-gap: 40px;
  grid-row-gap: 10px;
}

#inner-column {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-column-gap: 10px;
}

.fm-group {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.add-on-input {
  height: 32px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #cecece;
  width: 140px;
  text-align: right;
}

.add-on-checkbox {
  width: 16.457px;
  height: 18px;
  flex-shrink: 0;
}

#description {
  height: 62px;
  border-radius: 2px;
  border: 1px solid #cecece;
  width: 441px;
}

.fm-group-checkbox {
  display: flex;
  gap: 5px;
  align-items: center;
}

.add-on-footer {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
}

.add-on-footer > div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.addon-required {
  color: #ff0000;
  font-weight: bolder;
}

.addon-required-alert {
  color: #ff0000;
  font-weight: lighter;
}

.modal-addon-lg {
  max-width: 857px !important;
}

.vhc-addon-select-items .dropdown-toggle {
  width: 313px !important;
}

.vhc-addon-select-equipment .dropdown-toggle {
  width: 441px !important;
}

/* .addon-modal-content .v-money {
  text-align: right
} */
</style>
