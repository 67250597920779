<template>
  <div class="admin-content-area">
      <div class="modal fade bd-example-modal-lg" id="financeCompanyModal" tabindex="-1" role="dialog"
              aria-labelledby="financeCompanyModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <add-update-finance-group-vue openAt="popupView" v-if="isClickAddFinanceCompany" />
                  </div>
              </div>
      </div>
  </div>
</template>
  
<script>
  import AddUpdateFinanceGroupVue from "../../../FinanceGroupMaster/AddUpdateFinanceGroup.vue";

  export default {
    name: "AddFinanceCompanyModal",
    props: ["isClickAddFinanceCompany"],
    components: {
      'add-update-finance-group-vue': AddUpdateFinanceGroupVue
    },
    data() {
      return {};
    },
  };
</script> 