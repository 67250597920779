<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_view_task_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">
                        Task Information <span class="badge badge-info">{{ taskViewObj['status'] }}</span>
                    </div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Customer Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['custName']}}</span>
                                </div>
                            </div>
                            <!-- Subject -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['subject']}}</span>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['description']}}</span>
                                </div>
                            </div>
                            <!-- Created By -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.created_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['usrCreatedFirstName'] + " " + taskViewObj['usrCreatedLastName']}}</span>
                                </div>
                            </div>
                            <!-- Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['due_date']}}</span>
                                </div>
                            </div>
                            <!-- Priority -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['priority']}}</span>
                                </div>
                            </div>
                            <!-- Assign To -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.assign_to_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{taskViewObj['firstName'] + " " + taskViewObj['lastName']}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Forms-->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'ViewTaskMaster',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                taskViewObj: [],
                alertClass : '',
                alertMessage : '',
                permissionNameArray : ['can_view_task_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('task-list');
            EventBus.$emit("menu-selection-changed", "task-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getTaskViewById(self.$route.params.id);
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/task/list');
            },
            getTaskViewById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/task/get', config)
                    .then(response => {
                        /* self.priority = {value: response.data.content.priority, text: self.formatString(response.data.content.priority)};
                        self.status = {value: response.data.content.status, text: self.formatString(response.data.content.status)};
                        self.customerId = {value: response.data.content.contact_id, text: response.data.content.custName};
                        self.userId = {value: response.data.content.user_id, text: response.data.content.firstName + ' ' + response.data.content.lastName};
                        self.subject = response.data.content.subject;
                        self.dueDate = response.data.content.due_date
                        self.description = response.data.content.description; */
                        self.taskViewObj = response.data.content;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>  