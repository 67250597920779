<template>
    <div class="">
        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_add_make_master')">
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->
                        <div :class="alertClass" id="success-alert">
                            <strong> {{alertMessage}} </strong>
                        </div>
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <span class="help is-danger" v-if="id > 0 && linkCountAsset > 0">
                            Are you sure that you want to update this Make! It is link with <strong> {{linkCountAsset}} </strong> Inventory(s).
                        </span>
                            <div class="row no-margin">
                                <!--Name-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{nameError}}</span>
                                    </div>
                                </div>

                                <!--Type-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                                  v-model="typeId"
                                                  @change="changedValue"
                                                  @input="typeError = ''"
                                                  name="type"
                                                  id="type"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="typeArr"
                                                  :placeholder="staticMessages.type_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{typeError}}</span>
                                    </div>
                                </div>

                                <!--Is Autorized-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label"><label for="isAuthorized">{{staticInputLabelMessages.is_authorized_label}}</label></div>
                                    </div>
                                    <div class="col-9 paddingleft0 custom-checkbox-v">
                                        <label class="container">
                                            <input @change="isAuthorizedError = ''" id="isAuthorized" v-model="isAuthorized" class="form-control" type="checkbox"/>
                                            <span class="checkmark"></span>
                                        </label>
                                        <span class="help is-danger">{{isAuthorizedError}}</span>
                                    </div>
                                </div>
                                
                                <!--Access Type -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.access_type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                                v-model="accessTypeId"
                                                @change="changedValue"
                                                name="accessTypeId"
                                                id="accessTypeId"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="accessTypeArr"
                                                :placeholder="staticMessages.access_type_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!--Available Makes for Opportunities:-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label"><label for="unableForOpportunities">{{staticInputLabelMessages.available_make_for_opportunity_lable}}</label></div>
                                    </div>
                                    <div class="col-9 paddingleft0 custom-checkbox-v">
                                        <label class="container">
                                            <input  id="unableForOpportunities" v-model="unable_for_opportunity" class="form-control" type="checkbox"/>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!--Error Alert-->
                            <div class="row clearfix" >
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="alert alert-danger" id="alertError">
                                        <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                        <strong id="errorMsg" class="error"></strong>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>

                            <!--Buttons-->
                            <div class="row clearfix"></div>
                            <div class="button-demo">
                                <input id="addMakeBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addMake()">
                                <input id="addAndContinueMakeBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addMake(flag='Save and Continue')">
                                <input id="cancelMake" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                            </div>

                        </div>

                    </div>
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <!-- Forms -->
        </div>
        <!--page content-->
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateMake',
        mixins: [commonMixins],
        components: {
            'app-footer': Footer,
        },
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                nameError: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Make",
                id: 0,
                linkCountAsset: 0,

                typeId: "",
                typeArr: [{text: "Truck", value: 'truck'}, {text: 'Trailor', value: "trailer"}],
                typeError: "",
                isAuthorized : false,
                isAuthorizedError : "",
                accessTypeId: {text: "Used", value: "used"},
                accessTypeArr: [{text: "Both", value: "both"}, {text: "New", value: "new"}, {text: "Used", value: "used"}],
                permissionNameArray : ['can_add_make_master'],
                unable_for_opportunity: false
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('make-list');
            EventBus.$emit("menu-selection-changed", "make-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.id = self.$route.params.id;
                self.getMakeDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueMakeBtn").hide();
                self.pageTitle = "Edit Make";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/make/list');
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
                self.typeId =  "";
                self.isAuthorized = false;
                self.unable_for_opportunity = false;
                self.accessTypeId = {
                    text: "Used", 
                    value: "used"
                };
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            getMakeDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/make/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.linkCountAsset = response.data.content.countMake;
                        self.typeId = {text: self.capitalizeFirstLetter(response.data.content.type), value: response.data.content.type};
                        self.isAuthorized = response.data.content.is_authorized == "authorized" ? true : false;
                        self.accessTypeId = {text: self.formatString(response.data.content.is_used), value: response.data.content.is_used};
                        self.unable_for_opportunity = response.data.content.unable_for_opportunity;

                        let media = response.data.media;
                        for (let i = 0; i < media.length; i++) {
                            self.mediaPath.push(response.data.media[i].media_path);

                            if (self.mediaPath[i] != '') {
                                self.isFileUploaded = true;
                                self.mediaSize.push(response.data.media[i].media_size);
                                self.mediaType.push(response.data.media[i].media_type);
                            }
                        }
                        self.uploadManually();
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },

            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.typeId == null || !self.typeId.value) {
                    self.typeError = "Please select type";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addMake(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('name', self.name);
                        data.append('type_id', self.typeId.value);
                        data.append('is_authorized', self.isAuthorized);
                        data.append('access_type', self.accessTypeId.value);
                        data.append('mediaPath', JSON.stringify(self.mediaPath));
                        data.append('mediaType', JSON.stringify(self.mediaType));
                        data.append('mediaSize', JSON.stringify(self.mediaSize));
                        data.append('unable_for_opportunity', self.unable_for_opportunity);
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/make/add/update", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 5000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>