<template xmlns:onfocusout="http://www.w3.org/1999/xhtml" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_show_delete_inventory_list_inventory_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Deleted Inventory List</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <a
                            id='refreshList'
                            title='Refresh'
                            class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                            v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <div class="vdc-filters-container">
                        <div v-on:click="toggleFilterBlock()" class="vdc-filters-block">
                            <span>{{allLabelMessages.filters}}</span>
                            <span class="badge badge-primary badge-pill bg-color-098de1" v-if="filterCount > 0">{{filterCount}} Filter<template v-if="filterCount > 1">s</template> applied</span>
                            <button>
                                <img src="/static/images/filter_icon.png">
                            </button>
                        </div>
                        <div v-show="isOpenFilterBlock" class="vdc-filters-area">
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.sale_date}}</label>
                                <div class="clearfix"></div>
                                <div class="col-12 paddingleft0">
                                    <VueCtkDateTimePicker
                                        v-model="filters.sale_date"
                                        range
                                        :only-date="datePickerOptions.onlyDate"
                                        :only-time="false"
                                        :output-format="datePickerOptions.outputFormat"
                                        :format="datePickerOptions.inputFormat"
                                        :formatted="datePickerOptions.formatted"
                                        :noClearButton="datePickerOptions.noClearButton"
                                        :custom-shortcuts="datePickerOptions.customShortcuts"
                                        :maxDate="datePickerOptions.maxDate"
                                    ></VueCtkDateTimePicker>
                                    <button title="Reset" v-on:click="filters.sale_date = ''; resetFilters('date', 'sale_date')" class="btn btn-primary clearDateFilter">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.vin_no_stock}}</label>
                                <input class="form-control" v-on:keyup.enter="changepage()" placeholder="Vin No, Stock" v-model="filters.vin_no"  name="vin_no"  type="text">
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.salesman_filter}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.salesman"
                                            name="salesman"
                                            label="text"
                                            @search="onSearchSalesman"
                                            :options="filterSalesmanOptions"
                                            :clearable="true"
                                            :placeholder="allLabelMessages.salesman_filter"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.vendor}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.vendor"
                                            name="vendor"
                                            label="text"
                                            @search="onSearchVendor"
                                            :options="filterVendorOptions"
                                            :clearable="true"
                                            :placeholder="allLabelMessages.vendor"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                            </div>
                            <div style="display:none;" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.status}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            multiple
                                            v-model="filters.status"
                                            name="status"
                                            :placeholder="allLabelMessages.status"
                                            label="text"
                                            :options="filterStatusOptions"
                                            class=""
                                            >
                                            <!--<template #selected-option-container="{ option, deselect, multiple, disabled }">
                                                <div class="selected-tag">
                                                    {{ filters.status.length }}
                                                </div>
                                            </template>-->
                                            <!--<template slot="selection" slot-scope="data">
                                                {{ data.item.text }} - {{ data.item.value }}
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }} - {{ data.item.value }}
                                            </template>-->
                                            <!--<template #selected-option="{ text, value}">
                                                <span :title="text">{{ text }}</span>
                                            </template>-->
                                    </v-select>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.status}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect
                                    v-model="filters.status"
                                    :options="filterStatusOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select one"
                                    label="text"
                                    track-by="text"
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeStatusFilter()"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedStatusFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div v-show="isHoldSelected == true" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.hold_status}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect
                                    v-model="filters.hold_status"
                                    :options="filterHoldStatusOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select one"
                                    label="text"
                                    track-by="text"
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeHoldStatusFilter()"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedHodStatusFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearHoldStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.make}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.make"
                                            name="make"
                                            :placeholder="allLabelMessages.make"
                                            label="text"
                                            @change="makeValueChanged"
                                            :options="filterMakeOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.model}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.model"
                                            name="model"
                                            :placeholder="allLabelMessages.model"
                                            label="text"
                                            :options="filterModelOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                            </div>
                            <!--Year Filter-->
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.year}} From</label>
                                <date-picker
                                        id="fromYear"
                                        v-model="filters.year.fromYear"
                                        :config="fromOptions"
                                        class="form-control"
                                        autocomplete="off"
                                        placeholder="From Year"
                                        name="issueDate"
                                        @input="changeFilterFromDate()"
                                >
                                </date-picker>
                            </div>

                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.year}} To</label>
                                <date-picker
                                        id="toYear"
                                        v-model="filters.year.toYear"
                                        :config="toOptions"
                                        class="form-control"
                                        autocomplete="off"
                                        placeholder="To Year"
                                        name="issueDate"
                                        @input="changeFilterToDate()"
                                >
                                </date-picker>
                                <span class="help is-danger">{{yearFilterError}}</span>
                            </div>
                            <div v-show="isHoldSelected == true" class="clearfix"></div>

                            <!--Price Filter-->
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.price_from}}</label>
                                <input class="form-control" @blur="handleBlurFromPrice" v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.price_from" v-model="filters.price.fromPrice" id="fromPrice"  name="price"  type="text">
                            </div>
                            <div v-show="isHoldSelected == false" class="clearfix"></div>

                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.price_to}}</label>
                                <input class="form-control" @blur="handleBlurToPrice"  v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.price_to" v-model="filters.price.toPrice" id="toPrice"  name="price"  type="text">
                                <span class="help is-danger">{{priceFilterError}}</span>
                            </div>
                            <div v-show="userCompany.length > 1" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.company_label_filter}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect
                                    v-model="filters.department"
                                    :options="filterCompanyOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select one"
                                    label="text"
                                    track-by="text"
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeDepartmentFilter()"
                                    >

                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedDepartmentFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length == 1">{{ values[0].text }}</span>
                                            <span class="multiselect__single pull-right" v-on:click="clearDepartmentFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                                <div class="vdc-filters-area-block">
                                    <label>{{allLabelMessages.location}}</label>
                                    <div class="col-12 paddingleft0">

                                        <multiselect
                                                v-model="filters.location"
                                                :options="filterlocationOptions"
                                                :multiple="true"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                :preserve-search="true"
                                                placeholder="Select one"
                                                label="text"
                                                track-by="text"
                                                :preselect-first="true"
                                                class="form-control"
                                                @input="changeLocationFilter()"
                                        >

                                            <!--Dropdown options Custom Slot-->
                                            <template slot="option" slot-scope="props">
                                                <div v-if="appliedLocationFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                    <span>{{ props.option.text }}</span>
                                                </div>
                                                <div v-else class="multiple_select_item">
                                                    <span>{{ props.option.text }}</span>
                                                </div>
                                            </template>
                                            <!-- Selection Custom Slot -->
                                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                                <span class="multiselect__single" v-else-if="values.length == 1">{{ values[0].text }}</span>
                                                <span class="multiselect__single pull-right" v-on:click="clearLocationFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                            </template>
                                        </multiselect>

                                    </div>
                                    <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Location" v-model="filters.location"  name="location"  type="text">-->
                                </div>
                            <template v-if="isDepartmentSelected">
                            </template>
                            <!--<div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.lot}}</label>
                                <input class="form-control" v-on:keyup.enter="changepage()" placeholder="Lot" v-model="filters.lot"  name="lot"  type="text">
                            </div>-->
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.engine_make}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.engine_make"
                                            name="engine_make"
                                            :placeholder="allLabelMessages.engine_make"
                                            label="text"
                                            @change="engineMakeValueChanged"
                                            :options="filterEngineMakeOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Make" v-model="filters.engine_make"  name="engine_make"  type="text">-->
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.engine_model}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                            v-model="filters.engine_model"
                                            name="engine_model"
                                            :placeholder="allLabelMessages.engine_model"
                                            label="text"
                                            :options="filterEngineModelOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div>
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.engine_model"  name="engine_model"  type="text">-->
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.cab_style}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                        v-model="filters.cab_style"
                                        name="cab_style"
                                        :placeholder="allLabelMessages.cab_style"
                                        label="text"
                                        :options="filterCabStyleOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div>
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.cab_style"  name="cab_style"  type="text">-->
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.dtr_trade_terms}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                        v-model="filters.dtr_trade_terms"
                                        name="dtr_trade_terms"
                                        :placeholder="allLabelMessages.dtr_trade_terms"
                                        label="text"
                                        :options="filterDTROptions"
                                        class=""
                                    >
                                    </v-select>
                                </div>
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.dtr_trade_terms"  name="dtr_trade_terms"  type="text">-->
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.fleet}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                        v-model="filters.fleet"
                                        name="fleet"
                                        :placeholder="allLabelMessages.fleet"
                                        label="text"
                                        :options="filterFleetOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div>
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.fleet"  name="fleet"  type="text">-->
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.body_type_filter}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select
                                        v-model="filters.body_type"
                                        name="body_type_filter"
                                        :placeholder="allLabelMessages.body_type_filter"
                                        label="text"
                                        :options="filterbodyTypeOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div>
                            </div>
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.age}}</label>
                                <input class="form-control"  v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.age" v-model="filters.age" id="age_from"  name="age"  type="text">
                            </div> -->

                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.acquire_on_date_filter_label}}</label>
                                <div class="clearfix"></div>
                                <div class="col-12 paddingleft0">
                                    <VueCtkDateTimePicker
                                        v-model="filters.acquire_on_date"
                                        range
                                        :only-date="datePickerOptions.onlyDate"
                                        :only-time="false"
                                        :output-format="datePickerOptions.outputFormat"
                                        :format="datePickerOptions.inputFormat"
                                        :formatted="datePickerOptions.formatted"
                                        :noClearButton="datePickerOptions.noClearButton"
                                        :custom-shortcuts="datePickerOptions.customShortcuts"
                                        :maxDate="datePickerOptions.maxDate"
                                    ></VueCtkDateTimePicker>
                                    <button title="Reset" v-on:click="filters.acquire_on_date = ''; resetFilters('date', 'acquire_on_date')" class="btn btn-primary clearDateFilter">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                        <!--<i class="fa fa-refresh" aria-hidden="true"></i>-->
                                    </button>
                                </div>
                            </div>

                            <div class="vdc-filters-area-block">
                              <label>{{allLabelMessages.customer_name_filter_label}}</label>
                              <div class="col-12 paddingleft0">

                                  <multiselect
                                          v-model="filters.customer_name"
                                          :options="filterCustomerOptions"
                                          :multiple="true"
                                          :close-on-select="true"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          placeholder="Select one"
                                          label="text"
                                          track-by="text"
                                          :preselect-first="true"
                                          class="form-control multiselect_display_input"
                                          @search-change="onSearchCustomer"
                                  >

                                      <!--Dropdown options Custom Slot-->
                                      <template slot="option" slot-scope="props">
                                          <div v-if="appliedCustomerFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                              <span>{{ props.option.text }}</span>
                                          </div>
                                          <div v-else class="multiple_select_item">
                                              <span>{{ props.option.text }}</span>
                                          </div>
                                      </template>
                                      <!-- Selection Custom Slot -->
                                      <template slot="selection" slot-scope="{ values, search, isOpen }">
                                          <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                          <span class="multiselect__single" v-else-if="values.length == 1">{{ values[0].text }}</span>
                                          <span class="multiselect__single pull-right" v-on:click="clearCustomerFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                      </template>
                                  </multiselect>
                              </div>
                            </div>

                            <div class="clearfix"></div>
                            <div class="">
                                <button class="btn btn-primary" v-on:click="changepage()">Apply Filters</button>
                                <button class="btn btn-primary" v-on:click="resetFilters()">Reset Filters</button>
                                <button class="btn btn-primary" v-on:click="resetSorting()">Reset Sorting</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button v-show="permissionNameArray.includes('can_recover_deleted_inventory_inventory_master')" type="button" v-on:click.prevent="deleteAsset()" class="btn btn-primary waves-effect pull-right ml-2" title='Undo Multiple'>
                            <i class="fa fa-undo" aria-hidden="true"></i> Undo ({{selectedRows.length}})
                        </button>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select ref="select" title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="assetArr && assetArr.length > 0">
                                    <template v-for="(asset, index) in assetArr">
                                        <tr class="mainDetails" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="asset.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + asset.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + asset.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions">
                                                    <a v-show="permissionNameArray.includes('can_recover_deleted_inventory_inventory_master')" href="javascript:void(0)" title='Undo Inventory' class="mar-right-0" v-on:click="deleteAsset(asset.id)">
                                                        <i class="exceptEdit fa fa-undo" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td :class="rowClass.status">
                                                <span v-if="asset.status == 'Active' || asset.status == 'Live'" class="badge badge-primary badge-pill bg-color-71aa3f">
                                                    {{asset.status}}
                                                </span>
                                                <span v-else-if="asset.status == 'Hold'" class="badge badge-primary badge-pill bg-color-0771b4">
                                                    {{asset.status}} <template v-if="asset.hold_status_id != null && asset.hold_status_id != 'null'">({{asset.hold_status_name}})</template>
                                                </span>
                                                <span v-else-if="asset.status == 'Pending'" class="badge badge-primary badge-pill bg-color-cc9636">
                                                    {{asset.status}}
                                                </span>
                                                <span v-else-if="asset.status == null"  class="">
                                                </span>
                                                <span v-else  class="badge badge-primary badge-pill bg-color-c3202b">
                                                    {{asset.status}}
                                                </span>
                                            </td>
                                            <td :class="rowClass.stock">{{asset.unit}}</td>
                                            <td :class="rowClass.vin">{{asset.vin}}</td>
                                            <td :class="rowClass.model">{{asset.model}}</td>
                                            <td :class="rowClass.cab_style">{{asset.cab_style}}</td>
                                            <td :class="rowClass.cab_type_name">{{asset.cab_type_name}}</td>
                                            <td :class="rowClass.retail_price">{{asset.retail_price}}</td>
                                            <td :class="rowClass.age">{{asset.purchase_age}}</td>
                                            <td :class="rowClass.sale_date">{{asset.sale_date}}</td>
                                            <td :class="rowClass.salesman">{{asset.salesman_name}}</td>
                                            <td :class="rowClass.type">{{asset.is_truck}}</td>
                                            <td :class="rowClass.year">{{asset.year}}</td>
                                            <td class="text-right">{{asset.odometer}}</td>
                                            <td :class="rowClass.location">{{asset.lot_city_lot}}</td>
                                            <td :class="rowClass.department">{{asset.company_name}}</td>
                                            <td :class="rowClass.engine_make">{{asset.engine_make_name}}</td>
                                            <td :class="rowClass.engine_model">{{asset.engine_model}}</td>
                                            <td :class="rowClass.hp">{{asset.engine_hp}}</td>
                                            <td :class="rowClass.trans_model">{{asset.trans_model}}</td>
                                            <td :class="rowClass.vendor">{{asset.vendor}}</td>
                                            <td :class="rowClass.acquired_by_name">{{asset.acquired_by_name}}</td>
                                            <td :class="rowClass.dtr_trade_terms_name">{{asset.dtr_trade_terms_name}}</td>
                                            <td :class="rowClass.fleet_name">{{asset.fleet_name}}</td>
                                            <td :class="rowClass.gvw">{{asset.gvw}}</td>
                                            <td :class="rowClass.acquire_on_date">{{asset.acquire_on_date}}</td>
                                            <td :class="rowClass.customer_name_full">{{asset.customer_name_full}}</td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" :key="headerIndex" v-if="data.name != 'Action'" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select ref="select" title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "age";
    var filterType = "asc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";

    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import { VueContext } from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';
    import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";
    import {Datetime} from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css';
    import Multiselect from 'vue-multiselect';
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'DeleteAssetList',
        mixins: [commonMixins],
        components: {
            'filter-component': FilterComponent,
            'no-access': NoAccessPage,
            VueContext,
            Multiselect,
            datetime: Datetime,
            'list-accordian': ListAccordianComponent,
            datePicker,
        },
        data() {
            return {
                fromOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                toOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                userCompany : [],
                isHoldSelected: false,
                datePickerOptions: {
                    range: true,
                    onlyDate: true,
                    outputFormat: 'YYYY-MM-DD',
                    inputFormat: 'YYYY-MM-DD',
                    formatted: process.env.VUE_APP_SYSTEM_DISPLAY_DATERANGE_FILTER_FORMAT,/*'YYYY-MM-DD',*/
                    noClearButton: true,
                    maxDate : '',
                },
                perPageValue : 15,
                perPage: 15,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                assetArr: [],
                headers: [],
                filterheaders: [],
                filters: {
                    sale_date : "",
                    purchase_date : "",
                    vin_no:'',
                    status:[],
                    make:'',
                    model:'',
                    year : {
                        fromYear : "",
                        toYear : ""
                    },
                    location:'',
                    salesman:'',
                    price: {
                        fromPrice : "",
                        toPrice : "",
                    },
                    lot:'',
                    engine_make:'',
                    engine_model:'',
                    cab_style:'',
                    department:'',
                    dtr_trade_terms:'',
                    fleet:'',
                    body_type:'',
                },
                yearFilterError : "",
                priceFilterError : "",
                filterStatusOptions:[],
                filterbodyTypeOptions:[],
                filterCompanyOptions:[],
                filterHoldStatusOptions:[],
                filterMakeOptions:[],
                filterModelOptions:[],
                filterEngineMakeOptions:[],
                filterEngineModelOptions:[],
                filterCabStyleOptions:[],
                filterlocationOptions:[],
                filterSalesmanOptions:[],
                filterVendorOptions:[],
                filterFleetOptions:[],
                filterDTROptions:[],
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: '',
                selectAll: false,
                permissionNameArray: ['can_show_delete_inventory_list_inventory_master'],
                actionDisplay: true,
                loginId: 0,
                isOpenFilterBlock:false,
                salesmanIds : '',
                showPromoSheetBtn : true,
                showSpecSheetBtn : true,
                showSpecListBtn : true,
                maxSelectionRecord : 10,
                /* This logicToapplyForMaxRecordSelection variable has two value "alert" or "hide"
                make value "hide" for hide button if value excede from maxSelectionRecord variable value
                make value "alert" for display sweet alert if value excede from maxSelectionRecord variable value */
                logicToapplyForMaxRecordSelection : "alert",
                vendorIds : '',
                jumpToHistoryPage : 1,
                pageLoaded : false,
                filterCount : 0,
                appliedDepartmentFilter : [],
                appliedLocationFilter : [],
                appliedStatusFilter : [],
                appliedHodStatusFilter : [],
                strDepartmentId: '',
                loginUserLocation: [],
                isDepartmentSelected: false,
                isFirstLoad: 0,
                filterCustomerOptions:[],
                appliedCustomerFilter : []
            }
        },
        beforeMount() {
            self = this;
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        watch: {
            selectedRows : function () {
                if (self.logicToapplyForMaxRecordSelection == "hide") {
                    self.maxSelectionAllowed();
                }
            },
            filters: function (selectedRows) {
            },
            salesmanIds: function () {
                self.loadAllData(['User'], false, self.loadSalesmanDataCallback, 'undefined', '', 0,self.salesmanIds);
            },
            salesmanIds: function () {
                self.loadAllData(['Vendor'], false, self.loadVendorDataCallback, 'undefined', '', 0, 1);
            },
            userCompany: function () {
                self.loadAllData(['Company'], false, self.loadCompanyDataCallback,"undefined",'',self.userCompany.join(","));
            },
            "filters.status": function(){
                self.isHoldSelected = false;
                self.appliedStatusFilter = [];
                $.each(self.filters.status, function (i) {
                    self.appliedStatusFilter.push(self.filters.status[i].value);
                    if(self.filters.status[i].value == 2){
                        self.isHoldSelected = true;
                    }
                });
            },
            "filters.department": function(){
                self.isDepartmentSelected = false;
                if(self.isFirstLoad > 0){
                    self.$nextTick(function () {
                        self.filters.location = "";
                    });
                }
                if(self.filters.department && self.filters.department.length > 0) {
                    self.isDepartmentSelected = true;
                }
                var userObj = localStorage.getItem("user_obj");
                // if user logged in than remove login button and registration button
                if (userObj != null) {
                    var userJson = JSON.parse(userObj);
                    var userRole = userJson['roles'][0].id;
                    if(userRole > 2 && self.loginUserLocation && self.loginUserLocation.length > 0) {
                        self.filterlocationOptions = [];
                        var temp = [];
                        if(self.isFirstLoad > 0){
                            self.$nextTick(function () {
                                self.filters.location = "";
                            });
                        }
                        $.each(self.filters.department, function (i) {
                            var selectedCompanyId = self.filters.department[i].value;
                            $.each(self.loginUserLocation, function (j) {
                                var objLoc = self.loginUserLocation[j];
                                if(selectedCompanyId == objLoc.companyId) {
                                    if( !temp.includes(objLoc.value) ) {
                                        temp.push(objLoc.value);
                                        self.filterlocationOptions.push(objLoc);
                                    }
                                }
                            });
                        });
                        return false;
                    }
                    self.strDepartmentId = '';
                    self.appliedDepartmentFilter = [];
                    $.each(self.filters.department, function (i) {
                        self.appliedDepartmentFilter.push(self.filters.department[i].value);
                        self.strDepartmentId += self.filters.department[i].value + ",";
                    });
                    self.strDepartmentId = self.strDepartmentId.substring(0, self.strDepartmentId.length - 1);
                    if(self.strDepartmentId != '') {
                        self.loadAllData(['Location'], false, self.loadCityDataCallback, 'undefined', '', self.strDepartmentId);
                    }
                }
                self.$nextTick(function () {
                    self.isFirstLoad = self.isFirstLoad + 1;
                });
            },
            "filters.location": function(){
                self.appliedLocationFilter = [];
                $.each(self.filters.location, function (i) {
                    self.appliedLocationFilter.push(self.filters.location[i].value);
                });
            },
            "filters.hold_status": function(){
                self.appliedHodStatusFilter = [];
                $.each(self.filters.hold_status, function (i) {
                    self.appliedHodStatusFilter.push(self.filters.hold_status[i].value);
                });
            },
            "filters.customer_name": function(){
                self.appliedCustomerFilter = [];
                $.each(self.filters.customer_name, function (i) {
                    self.appliedCustomerFilter.push(self.filters.customer_name[i].value);
                });
            },
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.filters.status = [
                {
                    "value":1,
                    "text":"Active",
                    "checked":0
                },
                {
                    "value":2,
                    "text":"Hold",
                    "checked":0
                },
                {
                    "value":4,
                    "text":"Pending",
                    "checked":0
                }
            ];
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            var listHistoryData = self.loadFilters('deleteInventoryList', self);
            userObj = localStorage.getItem("user_obj");
            /* if user logged in than remove login button and registration button */
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson['id'];
            }

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.loadAllData(['Status', 'HoldStatus', 'Make', 'EngineMake', 'User', 'CabStyle', 'Fleet', 'BodyType', 'DtrTradeTerms', 'Location', 'Contact'], false, self.loadMasterDataCallback);
            /* self.loadAllData('Status', false, self.loadStatusDataCallback);
            self.loadAllData('HoldStatus', false, self.loadHoldStatusDataCallback);
            self.loadAllData('Make', false, self.loadMakeDataCallback);
            self.loadAllData('EngineMake', false, self.engineMakeDataCallback);
            self.loadAllData('User', false, self.loadSalesmanDataCallback);
            self.loadAllData('CabStyle', false, self.loadCabStyleDataCallback);
            self.loadAllData('Fleet', false, self.loadFleetCallback);
            self.loadAllData('BodyType', false, self.loadBodyTypeCallback);
            self.loadAllData('DtrTradeTerms', false, self.loadDTRTradeTermsCallback);
            self.loadAllData('Location', false, self.loadCityDataCallback); */
            self.makeValueChanged(1);
            self.engineMakeValueChanged(1);
            self.pageLoaded = true;
            self.listExecutionState = false;
            self.loadAsset(1);
            self.$nextTick(() => {
                self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
            });
            self.setInputFilter(document.getElementById("fromYear"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("toYear"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("fromPrice"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("toPrice"), function(value) {
                return /^-?\d*$/.test(value); });
        },
        methods: {
            buttonBack() {
                self.$router.push('/inventory/list');
            },
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changeHoldStatusFilter : function() {
            },
            clearHoldStatusFilter : function () {
                self.filters.hold_status = "";
                self.appliedHodStatusFilter = [];
            },
            changeStatusFilter : function() {
            },
            clearStatusFilter : function () {
                self.filters.status = "";
                self.appliedStatusFilter = [];
            },
            changeLocationFilter : function() {
            },
            clearLocationFilter : function() {
                self.filters.location = "";
                self.appliedLocationFilter = [];
            },
            clearDepartmentFilter : function() {
                self.filters.department = "";
                self.appliedDepartmentFilter = [];
                self.loadAllData(['Location'], false, self.loadCityDataCallback);
            },
            changeDepartmentFilter : function() {
            },
            clearCustomerFilter : function () {
                self.filters.customer_name = "";
                self.appliedCustomerFilter = [];
            },
            setInputFilter : function(textbox, inputFilter) {
                ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
                    textbox.addEventListener(event, function() {
                        if (inputFilter(this.value)) {
                            this.oldValue = this.value;
                            this.oldSelectionStart = this.selectionStart;
                            this.oldSelectionEnd = this.selectionEnd;
                        } else if (this.hasOwnProperty("oldValue")) {
                            this.value = this.oldValue;
                            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                        } else {
                            this.value = "";
                        }
                    });
                });
            },
            handleBlurFromPrice : function() {
                self.priceFilterError = "";
            },
            handleBlurToPrice : function() {
                self.priceFilterError = "";
                if (parseInt(self.filters.price.fromPrice) > parseInt(self.filters.price.toPrice)) {
                    self.filters.price.toPrice = "";
                    self.priceFilterError = "To Price can not be smaller than From Price"
                }
            },
            changeFilterFromDate : function () {
                self.yearFilterError = "";
            },
            changeFilterToDate : function() {
                self.yearFilterError = "";
                if (parseInt(self.filters.year.fromYear) > parseInt(self.filters.year.toYear)) {
                    self.filters.year.toYear = "";
                    self.yearFilterError = "To Year can not be smaller than From Year"
                }
            },
            changePerPage : function() {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.changepage();
            },
            changepage : function() {
                if(!self.listExecutionState){
                    self.loadAsset();
                }
            },
            maxSelectionAllowed : function () {
                if(self.selectedRows.length > self.maxSelectionRecord) {
                    if (self.logicToapplyForMaxRecordSelection == "alert") {
                        self.$swal("","Only " + self.maxSelectionRecord + " Inventory can be selected for this functionality", "error");
                        return false;
                    } else if (self.logicToapplyForMaxRecordSelection == "hide") {
                        self.showPromoSheetBtn = false;
                        self.showSpecSheetBtn = false;
                        self.showSpecListBtn = false;
                        return false;
                    } else {
                        self.$swal("","Only " + self.maxSelectionRecord + " Inventory can be selected for this functionality", "error");
                        return false;
                    }
                } else {
                    self.showPromoSheetBtn = true;
                    self.showSpecSheetBtn = true;
                    self.showSpecListBtn = true;
                }
                return true;
            },
            toggleFilterBlock: function () {
                $(window).scrollTop($(window).scrollTop()+1);
                self.isOpenFilterBlock = !self.isOpenFilterBlock;
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.changepage();
            },
            resetFilters: function (flag, date) {
                if (flag == "date") {
                    if (date == "purchase_date") {
                        self.filters.purchase_date = "";
                    } else if (date == "sale_date") {
                        self.filters.sale_date = "";
                    }
                    self.$nextTick(() => {
                        self.pageLoaded = true;
                        self.changepage();
                    });
                } else {
                    self.filters.status = [{
                            "value":1,
                            "text":"Active",
                            "checked":0
                        },
                        {
                            "value":2,
                            "text":"Hold",
                            "checked":0
                        },
                        {
                            "value":4,
                            "text":"Pending",
                            "checked":0
                        }];
                    self.pageLoaded = false;
                    self.filters.age_from='';
                    self.filters.vin_no='';
                    /* self.filters.status=''; */
                    self.filters.hold_status='';
                    self.filters.make='';
                    self.filters.model='';
                    self.filters.year ={
                        fromYear : "",
                        toYear : ""
                    },
                    self.filters.location='';
                    self.filters.salesman='';
                    self.filters.vendor='';
                    self.filters.price = {
                        fromPrice : "",
                        toPrice : "",
                    };
                    self.filters.lot='';
                    self.filters.engine_make='';
                    self.filters.engine_model='';
                    self.filters.cab_style='';
                    self.filters.sale_date="";
                    self.filters.purchase_date="";
                    self.filters.department="";
                    self.filters.dtr_trade_terms = '';
                    self.filters.fleet = '';
                    self.filters.body_type = '';
                    self.$nextTick(() => {
                        self.pageLoaded = true;
                        self.changepage();
                    });
                    self.filters.customer_name = '';
                }

            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
            },
            /* Context Menu Logic Close */
            compareAssets: function () {
            },
            deleteAsset: function (id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if (id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to recover this Inventory(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    /* showCloseButton: true, */
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data: {
                                deleteArr: deleteArray,
                            }
                        };
                        HTTP.delete("/asset/recover", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.changepage();
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.assetArr) {
                            self.selectedRows.push(self.assetArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.assetArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            setAlreadyAppliedFilters: function () {
                if (self.appliedHeaders.length > 0) {
                    self.filterheaders = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            filterCountFunctionAsset : function (filters) {
                var count = 0 ;
                $.each(filters, function (i) {
                    if (i === "year") {
                        var check = (filters[i]['fromYear'] === null || typeof (filters[i]['fromYear']) === "undefined" || filters[i]['fromYear'] === '') ? "" : filters[i];
                    } else if (i === "price") {
                        var check = (filters[i]['fromPrice'] === null || typeof (filters[i]['fromPrice']) === "undefined" || filters[i]['fromPrice'] === '') ? "" : filters[i];
                    } else {
                        var check = (filters[i] === null || typeof (filters[i]) === "undefined" || filters[i] === '') ? "" : filters[i];
                    }
                    if(check != ""){
                        count ++;
                    }
                });
                return count;
            },
            loadAsset: function (first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadAsset();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                if(self.pageLoaded){
                    var config = {
                        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                    };
                    var fields = {
                        filters: self.filters,
                        per_page: self.perPage,
                        page: self.currentPage,
                        sortColumnName: self.columnName,
                        sortType: self.sortType,
                        deleted_inventory: true
                    };
                    this.storeFilters('deleteInventoryList', fields, false);
                    self.showLoader();
                    self.filterCount = self.filterCountFunctionAsset(self.filters);
                    if(self.filterCount) {
                        self.isOpenFilterBlock = true;
                    }
                    HTTP.post("/asset/list", fields, config)
                            .then(function (data) {
                                self.listExecutionState = false;
                                if(first == 1) {
                                    if(self.jumpToHistoryPage != 1) {
                                        self.currentPage = self.jumpToHistoryPage;
                                    }
                                }
                                self.hideLoader();
                                if (data.status == 200) {
                                    $('.collapse').hide();
                                    self.clearSelection();
                                    self.headers = [];
                                    self.totalRecords = data.data.content.result;
                                    if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                        // alert(self.totalRecords);
                                        let seconds = 5000;
                                        self.$swal({
                                            type: 'error',
                                            html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                            showConfirmButton: false,
                                            timer: seconds,
                                        });
                                        setTimeout(() => {
                                            self.perPage = 500;
                                            self.loadAsset();
                                        }, seconds);
                                        /* self.$swal({
                                            title: '',
                                            html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                            type: 'error',
                                            confirmButtonText: 'OK',
                                            showCancelButton: true,
                                            showLoaderOnConfirm: true
                                        }).then((result) => {
                                            if (result.value) {
                                                self.logout();
                                            } else {
                                                self.perPage = 15;
                                                self.loadAsset();
                                            }
                                        }); */
                                    }
                                    self.assetArr = data.data.content.invdata;
                                    self.headers = data.data.content.headers;
                                    self.filterheaders = data.data.content.filterheaders;
                                    if(self.salesmanIds != data.data.content.salesmen_ids){
                                        self.salesmanIds = data.data.content.salesmen_ids;
                                    }
                                    self.loginUserLocation = data.data.content.logged_user_location;
                                    self.addColumnClass(self.headers);
                                    let testArray = [
                                        {
                                            'text': 1,
                                            'value': '2020-02-01'
                                        },
                                        {
                                            'text': 2,
                                            'value': '2020-02-02'
                                        },
                                        {
                                            'text': 3,
                                            'value': '2020-02-03'
                                        }
                                    ]
                                    for (let i = 0; i < self.headers.length; i++) {
                                        if (self.headers[i]['type'] == 'drop_down') {
                                            self.headers[i]['options_array'] = testArray;
                                        }
                                    }
                                    if (data.data.content.per_page !== self.totalRecords) {
                                        self.perPage = parseInt(data.data.content.per_page);
                                        self.jumpToPage = parseInt(data.data.content.current_page);
                                    } else {
                                        if(self.perPage == 'All') {
                                            self.jumpToPage = 1;
                                        }
                                        self.perPageValue = self.totalRecords;
                                        self.currentPage = 1;
                                    }
                                    self.setAlreadyAppliedFilters();
                                    self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                    self.sortFieldDisplay(self.columnName, self.sortType);
                                }
                            })
                            .catch(function (error) {
                                self.listExecutionState = false;
                                self.hideLoader();
                                self.catchHandler(error, function () {});
                            })

                }
            },
            makeValueChanged: function (firstload) {
                if (typeof(firstload) != "undefined" && firstload != "" && firstload != 0) {
                    firstload = 1;
                }else{
                    firstload = 0;
                }
                let make_id = "";
                if (typeof(self.filters.make) != "undefined" && self.filters.make != null && self.filters.make != "" && self.filters.make != 0) {
                    make_id = self.filters.make.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, 'undefined', '', make_id);
                if(firstload){
                    //self.changepage();
                }
            },
            onSearchSalesman(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['User'], true, self.loadSalesmanDataCallback, loading, search, 0);
                }
            },
            onSearchVendor(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['Vendor'], true, self.loadVendorDataCallback, loading, search, 0, 1);
                }
            },
            onSearchCustomer(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }

                    self.loadAllData(['Contact'], true, self.loadCustomerDataCallback, loading, search);
                }
            },
            loadCityDataCallback: function (callbackData) {
                self.filterlocationOptions = callbackData.Location;
            },
            loadCompanyDataCallback: function (callbackData) {
                self.filterCompanyOptions = callbackData.Company;
            },
            loadSalesmanDataCallback: function (callbackData) {
                self.filterSalesmanOptions = callbackData.User;
            },
            loadVendorDataCallback: function (callbackData) {
                self.filterVendorOptions = callbackData.Vendor;
            },
            loadModelDataCallback: function (callbackData) {
                self.filterModelOptions = callbackData.Model;
            },
            loadCustomerDataCallback: function (callbackData) {
                self.filterCustomerOptions = callbackData.Contact;
            },
            //
            loadMasterDataCallback: function (callbackData) {
                self.filterStatusOptions = callbackData.Status;
                self.filterHoldStatusOptions = callbackData.HoldStatus;
                self.filterMakeOptions = callbackData.Make;
                self.filterEngineMakeOptions = callbackData.EngineMake;
                self.filterSalesmanOptions = callbackData.User;
                self.filterCabStyleOptions = callbackData.CabStyle;
                self.filterFleetOptions = callbackData.Fleet;
                self.filterbodyTypeOptions = callbackData.BodyType;
                self.filterDTROptions = callbackData.DtrTradeTerms;
                self.filterlocationOptions = callbackData.Location;
                self.filterCustomerOptions = callbackData.Contact;
            },
            /* loadStatusDataCallback: function (callbackData) {
                self.filterStatusOptions = callbackData.Status;
            },
            loadHoldStatusDataCallback: function (callbackData) {
                self.filterHoldStatusOptions = callbackData.HoldStatus;
            },
            loadMakeDataCallback: function (callbackData) {
                self.filterMakeOptions = callbackData.Make;
            },
            engineMakeDataCallback: function (callbackData) {
                self.filterEngineMakeOptions = callbackData.EngineMake;
            },
            loadCabStyleDataCallback: function (callbackData) {
                self.filterCabStyleOptions = callbackData.CabStyle;
            },
            loadFleetCallback : function(callbackData) {
                self.filterFleetOptions = callbackData.Fleet;
            },
            loadBodyTypeCallback : function(callbackData) {
                self.filterbodyTypeOptions = callbackData.BodyType;
            },
            loadDTRTradeTermsCallback : function(callbackData) {
                self.filterDTROptions = callbackData.DtrTradeTerms;
            }, */
            engineMakeValueChanged: function (firstload) {
                 if (typeof(firstload) != "undefined" && firstload != "" && firstload != 0) {
                    firstload = 1;
                }else{
                    firstload = 0;
                }
                let make_id = "";
                if (typeof(self.filters.engine_make) != "undefined" && self.filters.engine_make != null && self.filters.engine_make != "" && self.filters.engine_make != 0) {
                    make_id = self.filters.engine_make.value;
                }
                self.loadAllData(['EngineModel'], false, self.engineModelDataCallback, 'undefined', '', make_id);
                if(firstload){
                    //self.changepage();
                }
            },
            engineModelDataCallback: function (callbackData) {
                self.filterEngineModelOptions = callbackData.EngineModel;
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.changepage();
                }
            },
        },
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_recover_deleted_inventory_inventory_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
            if(self.userCompany && self.userCompany.length == 1) {
                setTimeout(function() {
                    self.isDepartmentSelected = true;
                    self.filters.department = [{value: self.userCompany[0], text: ''}];
                }, 300);
            }
        }
    });
</script>
