<template>
  <div class="modal" id="custModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document" v-if="isClickCustomerModal == true">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="custModalLabel">
            Add Customer
          </h4>
          <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <div class="form-group row">
                <label for="contactId" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.contact_group_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="contactId"
                    @input="contactGroupError = ''"
                    name="contactId"
                    id="contactId"
                    label="text"
                    :clearable="false"
                    @search="onSearchContactGroup"
                    :filterable="false"
                    :options="contactGroupArr"
                    :placeholder="staticMessages.contact_group_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isContactGroupRecordNotFound">
                        {{ staticMessages.contact_group_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
                <label for="firstName" class="col-sm-2 pr-0 form-label">{{
                  staticInputLabelMessages.first_name_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="firstName"
                    v-model="firstName"
                    @input="firstNameError = ''"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ firstNameError }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label for="middleName" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.middle_name_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="middleName"
                    v-model="middleName"
                  />
                </div>
                <label for="lastName" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.last_name_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="lastName"
                    v-model="lastName"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="primaryPhone" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.primary_phone_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="primaryPhone"
                    v-model="primaryPhone"
                    @input="primaryPhoneError = ''"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ primaryPhoneError }}</span>
                </div>
                <label for="countryId" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.country_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="countryId"
                    @input="countryError = ''"
                    name="countryId"
                    id="countryId"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="countryArr"
                    :disabled="true"
                    :placeholder="staticMessages.country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ countryError }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label for="stateId" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.state_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="stateId"
                    name="stateId"
                    label="text"
                    :filterable="false"
                    :options="stateArr"
                    @input="stateError = ''"
                    @search="onSearchState"
                    :placeholder="staticMessages.state_place_holder"
                    class="custom-ci-auto-search"
                    @change="populateCity($event)"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ stateError }}</span>
                </div>
                <label for="cityId" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.city_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="cityId"
                    name="state"
                    label="text"
                    :filterable="false"
                    :options="cityArr"
                    @input="cityError = ''"
                    @search="onSearchCity"
                    :placeholder="staticMessages.city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ cityError }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label for="address" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.address_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input class="form-control" id="address" v-model="address" />
                </div>
                <label for="manageBy" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.managed_by_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="manageBy"
                    @input="manageByError = ''"
                    name="manageBy"
                    id="manageBy"
                    label="text"
                    :clearable="false"
                    @search="onSearchManageBy"
                    :filterable="false"
                    :options="manageByArr"
                    :placeholder="staticMessages.managed_by_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isManageByRecordNotFound">
                        {{ staticMessages.managed_by_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ manageByError }}</span>
                </div>
              </div>

              <div class="form-group row">
                <label for="middleName" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.fax_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="middleName"
                    v-model="fax"
                  />
                </div>
                <label for="lastName" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.email_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="lastName"
                    v-model="email"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ emailError }}</span>
                </div>
              </div>

              <div class="form-group row">
                <label for="lastName" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.zip_code_label
                }}</label>
                <div class="col-sm-4 pl-0">
                  <input
                    class="form-control"
                    id="zipcode"
                    v-model="zipcode"
                    @input="zipcodeError = ''"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ zipcodeError }}</span>
                </div>
                
                <!-- County -->
                <label for="manageBy" class="col-sm-2 pr-0">{{
                  staticInputLabelMessages.county
                }}</label>
                <div class="col-sm-4 pl-0">
                  <v-select
                    v-model="customerCountyId"
                    name="customerCountyId"
                    label="name"
                    :filterable="false"
                    :options="countyCodeArr"
                    @search="onSearchCountyCode"
                    :clearable="false"
                    @input="customerCountyError = ''"
                    id="customerCountyId"
                    :placeholder="allStaticMessages.county_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span
                      slot="no-options"
                      @click="$refs.select.open = false"
                    >
                      <template v-if="!isCustomerCountyRecordNotFound">
                        {{ allStaticMessages.county_search }}
                      </template>
                      <template v-else>
                        {{ allStaticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="addCustomer"
            :disabled="isDisabled"
          >
            {{ buttonNames.save }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ buttonNames.cancel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonMixins } from "../../../mixins/commonMixins";
import eventBus from "../../event-bus";
import { HTTP } from "../../../http-common";

export default {
  name: "AddCustomerModal",
  mixins: [commonMixins],
  props: ["isClickCustomerModal","staticInputLabelMessages","staticMessages"],
  data() {
    return {
        contactId: "",
        contactGroupError: "",
        contactGroupArr: [],
        isContactGroupRecordNotFound: false,
        firstName: "",
        middleName: "",
        lastName: "",
        primaryPhone: "",
        address: "",
        manageBy: "",
        manageByArr: [],
        isManageByRecordNotFound: false,
        manageByError: "",
        cityId: "",
        cityArr: [],
        isCityRecordNotFound: false,
        cityError: "",
        countryId: "",
        countryArr: [],
        isCountryRecordNotFound: false,
        countryError: "",
        stateId: "",
        stateArr: [],
        isStateRecordNotFound: false,
        stateError: "",
        firstNameError: "",
        primaryPhoneError: "",
        isDisabled: false,
        buttonNames: {
            save : 'Save',
            cancel : 'Cancel'
        },

        // county part
        customerCountyId: "",
        countyCodeArr: [],
        isCountyCodeNotFound: false,
        customerCountyError: "",
        isCustomerCountyRecordNotFound: false,

        fax: "",
        email: "",
        emailError: "",
        zipcode: "",
        zipcodeError: "",
    };
  },
  methods: {
    onSearchContactGroup(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.loadAllData(
          ["ContactGroup"],
          true,
          this.loadContactGroupCallBack,
          loading,
          search
        );
      }
    },
    loadContactGroupCallBack: function(callbackData) {
      this.isContactGroupRecordNotFound = false;
      this.contactGroupArr = callbackData.ContactGroup;
      if (this.contactGroupArr.length == 0) {
        this.isContactGroupRecordNotFound = true;
      }
    },
    onSearchManageBy(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.loadAllData(
          ["User"],
          true,
          this.loadManageByCallBack,
          loading,
          search
        );
      }
    },
    loadManageByCallBack: function(callbackData) {
      this.isManageByRecordNotFound = false;
      this.manageByArr = callbackData.User;
      if (this.manageByArr) {
        this.isManageByRecordNotFound = true;
      }
    },
    onSearchCity(search, loading) {
      if (this.stateId == null || !this.stateId.value) {
        this.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          this.loadAllData(
            ["Cities"],
            true,
            this.loadCityDataCallback,
            loading,
            search,
            this.stateId.value
          );
        }
      }
    },
    loadCityDataCallback: function(callbackData) {
      this.isCityRecordNotFound = false;
      this.cityArr = callbackData.Cities;
      if (this.cityArr) {
        this.isCityRecordNotFound = true;
      }
    },
    onSearchState(search, loading) {
      if (this.countryId == null || !this.countryId.value) {
        this.showSAlert("Please Select Country First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          this.loadAllData(
            ["States"],
            true,
            this.loadStateDataCallback,
            loading,
            search,
            this.countryId.value
          );
        }
      }
    },
    loadStateDataCallback: function(callbackData) {
      this.isStateRecordNotFound = false;
      this.stateArr = callbackData.States;
      if (this.stateArr.length == 0) {
        this.isStateRecordNotFound = true;
      }
    },
    loadCountryCallBack: function(callbackData) {
      this.isCountryRecordNotFound = this.isBillCountryRecordNotFound = false;
      this.countryArr = this.billCountryArr = callbackData.Countries;
      if (this.countryArr.length == 0) {
        this.isCountryRecordNotFound = true;
      }
      if (this.billCountryArr.length == 0) {
        this.isBillCountryRecordNotFound = true;
      }
    },
    addCustomer () {
      setTimeout(() => {
        if (this.checkCustomerForm()) {
          this.isDisabled = true
          this.buttonNames.save = 'Saving...'
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = new FormData();
          var contactId = "";
          if (this.contactId == null || !this.contactId.value) {
            contactId = "";
          } else {
            contactId = this.contactId.value;
          }
          data.append("group_id", contactId);
          data.append("first_name", this.firstName);
          data.append("middle_name", this.middleName);
          data.append("last_name", this.lastName);
          data.append("primary_phone", this.primaryPhone);
          data.append("country_id", this.countryId.value);
          data.append("state_id", this.stateId.value);
          data.append("city_id", this.cityId.value);
          data.append("address", this.address);
          data.append("manage_by_id", this.manageBy.value);
          data.append("is_prospect", 1);
          if(this.customerCountyId !== "") {
            data.append("county_id", this.customerCountyId.value);
            data.append("county", this.customerCountyId.text);
          }
          data.append("isTransactionSheet", true);
          data.append("fax", this.fax);
          data.append("email", this.email);
          data.append("zip_code", this.zipcode);

          HTTP.post("/contact/add/update", data, config)
            .then((response) => {
              this.isDisabled = false
              this.buttonNames.save = 'Save'
              if (response.data.status == "success") {
                this.showSAlert(response.data.message, "success", false, 3);
                eventBus.$emit("populate-customer-data", response.data.content);
                this.closeModal()
              } else {
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  this.showSAlert(err, "error", false);
                }
              }
            })
            .catch((err) => {
              this.isDisabled = false
              this.buttonNames.save = 'Save'
              this.catchHandler(err, function() {});
            });
        }
      }, 200);
    },
    closeModal () {
      this.contactId = '';
      this.firstName = '';
      this.middleName = '';
      this.lastName = '';
      this.primaryPhone = '';
      this.countryId = '';
      this.stateId = '';
      this.cityId = '';
      this.address = '';
      this.manageBy = '';
      this.fax = '';
      this.email = '';
      this.zipcode = '';
      eventBus.$emit("close-add-customer", false);
    },
    checkCustomerForm: function() {
      var checked = true;
      if (!this.firstName) {
        this.firstNameError = "Please enter first name";
        if (checked) {
          checked = false;
        }
      }
      if (!this.primaryPhone) {
        this.primaryPhoneError = "Please enter primary phone";
        if (checked) {
          checked = false;
        }
      } else if (this.primaryPhone && !this.validContactNumber(this.primaryPhone)) {
        this.primaryPhoneError = "Please enter valid primary phone";
        if (checked) {
          checked = false;
        }
      } else if (this.primaryPhone && !this.checkContactNoLength(this.primaryPhone)) {
        this.primaryPhoneError = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      if (this.countryId == null || !this.countryId.value) {
        this.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }
      if (this.stateId == null || !this.stateId.value) {
        this.stateError = "Please select state";
        if (checked) {
          checked = false;
        }
      }
      if (this.cityId == null || !this.cityId.value) {
        this.cityError = "Please select city";
        if (checked) {
          checked = false;
        }
      }
      if (this.manageBy == null || !this.manageBy.value) {
        this.manageByError = "Please enter managed by";
        if (checked) {
          checked = false;
        }
      }

      if (!this.zipcode) {
        this.zipcodeError = "Please enter zipcode";
        if (checked) {
          checked = false;
        }
      }
      if (!this.email || this.email && !this.validEmail(this.email)) {
        this.emailError = "Please enter valid email";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    loadContact() {
      this.loadAllData(['ContactGroup'], false, this.loadContactGroupCallBack, "undefined", '');
      this.countryId = this.prodCountryObj();
      this.loadAllData(["States"],true,this.loadStateDataCallback,"undefined","",this.countryId.value);
    },
    populateCity(state) {
      this.loadAllData(
        ["Cities"],
        true,
        this.loadCityDataCallback,
        "undefined",
        "",
        state.value
      );
    },
    //Search For county
    onSearchCountyCode(search, loading){
      if (search.length > 1){
        loading(true);
        this.loadAllData(
          ["TaxRate"],
          true,
          this.loadCountyCode,
          loading,
          search
        );
      }
    },
    loadCountyCode : function(callbackData){
      this.isCountyCodeNotFound = false;
      var taxRates = callbackData.TaxRate;
      this.countyCodeArr = [];
      taxRates.forEach((taxRate) => {
        this.countyCodeArr.push({
          name: taxRate.name,
          value: taxRate.id,
          text: taxRate.code,
        });
      });
      if(this.countyCodeArr.length === 0){
        this.isCountyCodeNotFound = true;
      }
    },
  },
  watch: {
    isClickCustomerModal(modalStatus) {
      console.log('ModalStatus>>>', modalStatus);
      if(modalStatus) {
        this.loadContact();
      } else {
        return false;
      }
    }
  }
};
</script>
