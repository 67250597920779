<template>
    <div class="vhc-dtl-custom-row">
        <div class="vhc-admin-section">
            <div class="vhc-dtl-accordian">
            <div class="vhc-dtl">
                <div class="vhc-dtl-header">
                <div class="vhc-dtl-column">{{detailList.title}}</div>
                </div>
                <div id="vhc-dtl-admin">
                <div class="vhc-dtl-body">
                    <div class="vhc-dtl-custom-row">
                      <div class="vhc-fm-group" v-for="(detail,index) in detailList.data" :key="index">
                          <label>{{ index }}:</label>
                          <span> {{ detail }}</span>
                      </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  
  export default {
    name: "VhcDtlAdmin",
    props: ["detailList"],
    data() {
      return {
        
      };
    },
    methods: {
      
    },
  };
  
  </script>
  
  <style scoped>
  /* Accordion */
  .vhc-dtl-accordian > .vhc-dtl > .vhc-dtl-header {
    display: grid;
    grid-template-columns: 3fr 4fr;
    /* grid-gap: 4px; */
  
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px 2px 0px 0px;
    border-top: 1px solid #076eb0;
    border-right: 1px solid #076eb0;
    border-left: 1px solid #076eb0;
    background: #f1f1f1;
  }
  
  .vhc-dtl-accordian
    > .vhc-dtl
    > .vhc-dtl-header
    > .vhc-dtl-column:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
  }
  
  .vhc-dtl-accordian
    > .vhc-dtl
    > .vhc-dtl-header
    > .vhc-dtl-column:first-child {
    display: flex;
    width: 228.355px;
    color: #1a203d;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.25px;
    align-items: center;
    padding-left: 24.38px;
  }
  
  .vhc-dtl-accordian
    > .vhc-dtl
    > .vhc-dtl-header
    > .vhc-dtl-column:last-child
    > img {
    width: 15px;
    height: 12.059px;
    flex-shrink: 0;
  }
  
  .vhc-dtl-accordian > .vhc-dtl > div:last-child {
    /* background: #076eb0; */
    width: 100%;
    height: 100%;
    /* height: 327px; */
    flex-shrink: 0;
    border-radius: 0px 0px 2px 2px;
    border-right: 1px solid #076eb0;
    border-bottom: 1px solid #076eb0;
    border-left: 1px solid #076eb0;
    background: #fff;
  }
  
  /* Accordian Body Section */
  .vhc-dtl-accordian > .vhc-dtl > div:last-child > .vhc-dtl-body {
    display: grid;
    grid-template-rows: 1fr auto;
    /* width: 100%; */
  
    padding-left: 28px;
    padding-right: 28px;
  }
  .vhc-dtl-accordian
    > .vhc-dtl
    > div:last-child
    > .vhc-dtl-body
    > .vhc-dtl-custom-row {
    margin-top: 10px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 45px;
  }
  .vhc-fm-group {
    display: flex;
    column-gap: 5px;
  }
  .vhc-dtl-custom-row > .vhc-fm-group > label {
    font-weight: bolder;
  }
  </style>
  