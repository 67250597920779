<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_front_tire_model_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>
                            <!--Front Tire Make-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_tire_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Front Tire Make"
                                        v-model="frontTireMakeId"
                                        @change="changedValue"
                                        @input="frontTireMakeError = ''"
                                        name="frontTireMake"
                                        id="frontTireMake"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="frontTireMakeArr"
                                        :placeholder="staticMessages.front_tire_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{frontTireMakeError}}</span>
                                </div>
                            </div>
                            <!--Access Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.access_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                        v-model="accessTypeId"
                                        @change="changedValue"
                                        name="accessTypeId"
                                        id="accessTypeId"
                                        label="text"
                                        @input="accessTypeError = ''"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="accessTypeArr"
                                        :placeholder="staticMessages.access_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{accessTypeError}}</span>
                                </div>
                            </div>

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addFrontTireModelBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addFrontTireModel()">
                            <input id="addAndContinueFrontTireModelBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addFrontTireModel(flag='Save and Continue')">
                            <input id="cancelFrontTireModel" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import {commonMixins} from '../../mixins/commonMixins';
import EventBus from ".././event-bus";
import {HTTP} from '../../http-common';
import NoAccessPage from "../NoAccessPage/NoAccessPage"

export default {
    name: 'AddUpdateFrontTireModel',
    mixins: [commonMixins],
    components: {
        'app-footer': Footer,
        'no-access' : NoAccessPage,
    },
    data() {
        return {
            staticInputLabelMessages: "",
            staticMessages: "",
            name: "",
            nameError: "",
            accessTypeId: {text: "Used", value: "used"},
            accessTypeArr: [{text: "Both", value: "both"}, {text: "New", value: "new"}, {text: "Used", value: "used"}],
            accessTypeError : "",
            buttonNames: {
                save: 'Save',
                saveAndContinue: 'Save And Continue',
                cancel: 'Cancel'
            },
            disableButtons: {
                save: false,
                saveAndContinue: false,
                cancel: false
            },
            alertClass: '',
            alertMessage: '',
            pageTitle: "Add Front Tire Model",
            id: 0,
            linkCountAsset: 0,
            frontTireMakeId: "",
            frontTireMakeArr: [],
            frontTireMakeError: "",
            permissionNameArray : ['can_add_front_tire_model_master'],
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {
        /* Redirect to login page logic start */
        var userObj = localStorage.getItem("user_obj");
        if (userObj == null) {
            self.$router.push('/');
        }
        /* Redirect to login page logic end */
        self.staticInputLabelMessages = self.allLabelMessages;
        self.staticMessages = self.allStaticMessages;
        self.setActiveMenu('fronttiremodel-list');
        EventBus.$emit("menu-selection-changed", "fronttiremodel-list");

        $("#alertError").hide();
        self.getLoginIdPermissions();
        if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
            self.id = self.$route.params.id;
            self.getFrontTireModelDetailsById(self.$route.params.id);
            self.buttonNames.save = "Update";
            $("#addAndContinueFrontTireModelBtn").hide();
            self.pageTitle = "Edit Front Tire Model";
        }
        self.loadAllData(['FrontTireMake'], false, self.loadFrontTireMakeDataCallback);
    },
    methods: {
        loadFrontTireMakeDataCallback: function (callbackData) {
            self.frontTireMakeArr = callbackData.FrontTireMake;
        },
        buttonBack() {
            self.$router.push({ name: 'ListFrontTireModel'});
        },
        changedValue: function (value) {
            /*console.log(value);*/
        },
        clearDataOnSaveAndContinue: function () {
            self.name = '';
            self.frontTireMakeId = '';
            self.accessTypeId = {text: "Used", value: "used"};
        },
        showAlertOnSave: function (alertMessage, alertType) {
            if (alertType === "success") {
                self.alertClass = "alert alert-success";
            } else {
                self.alertClass = "alert alert-danger";
            }
            self.alertMessage = alertMessage;
            $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                $("#success-alert").slideUp(500);
            });
        },
        getFrontTireModelDetailsById: function (id) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: {
                    id: id
                }
            };
            HTTP.get('/fronttiremodel/get', config)
                .then(response => {
                    self.name = response.data.content.name;
                    self.frontTireMakeId = {text: response.data.content.front_tire_make_name, value: response.data.content.front_tire_make_id};
                    self.accessTypeId = {text: self.formatString(response.data.content.is_used), value: response.data.content.is_used};
                })
                .catch(function (err) {
                    /* console.log(err); */
                    self.catchHandler(err, function () {});
                });
        },
        checkForm: function () {
            var checked = true;
            if (!self.name) {
                self.nameError = "Please enter name";
                if (checked) {
                    checked = false;
                }
            }
            if (self.frontTireMakeId == null || !self.frontTireMakeId.value) {
                self.frontTireMakeError = "Please select front tire make";
                if (checked) {
                    checked = false;
                }
            }
            if (self.accessTypeId == null || !self.accessTypeId.value) {
                self.accessTypeError = "Please select access type";
                if (checked) {
                    checked = false;
                }
            }
            return checked;
        },
        addFrontTireModel(flag) {
            setTimeout(() => {
                if (self.checkForm()) {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    var data = new FormData();
                    data.append('name', self.name);
                    data.append('front_tire_make_id', self.frontTireMakeId.value);
                    data.append('access_type', self.accessTypeId.value);
                    if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                        data.append('id', self.$route.params.id);
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                    } else {
                        if (flag == "Save and Continue") {
                            self.buttonNames.saveAndContinue = "Saving ...";
                        } else {
                            self.buttonNames.save = "Saving ...";
                        }
                        self.disableButtons.save = true;
                        self.disableButtons.saveAndContinue = true;
                        self.disableButtons.cancel = true;
                    }
                    HTTP.post("/fronttiremodel/add/update", data, config)
                        .then(function (response) {
                            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                            }
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message, 'success', false ,3);
                                if (flag == "Save and Continue") {
                                    self.clearDataOnSaveAndContinue();
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.buttonBack();
                                    }, 3000);
                                }
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    self.clearDataOnSaveAndContinue();
                                } else {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                }
                            }
                            self.catchHandler(err, function () {});
                        });
                }
            }, 200);
        },
    }
}
EventBus.$on("login-permissions", function(permissions) {
    if (typeof self != "undefined") {
        self.permissionNameArray = [];
        for (let i = 0; i < permissions.length; i++) {
            self.permissionNameArray.push(permissions[i].permission_name)
        }
    }
});
</script>