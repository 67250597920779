<template>
    <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name width-100-pct-imp" >VIN : {{vin_no}} | {{model_year}} {{make_name}} {{model_type}} {{model_name}} | Order: {{sls_id}}</div>
                    </div>
                    <div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                        
                            <div class="page_title_left">
                                <span class="pl-0">Service Operations</span>
                                <p v-if="totalRecords > 0">{{totalRecords}} Results Found</p>
                            </div>
                            <button
                                type="submit"
                                v-on:click.prevent="buttonBack()"
                                class="btn btn-primary waves-effect pull-right"
                            >
                                Back
                            </button>
                            <!-- <a :class="[syncFromExcedeButtonDisabled ? 'disabled ' : '', 'extraFeature btn btn-primary waves-effect pull-right ml-2']"  v-on:click.prevent="syncContactPtsls()">
                                <span>Sync from EXCEDE <br/> <small v-show="syncFromExcedeButtonDisabled">{{hoverTextsyncFromExcedeButton}}</small></span>
                            </a> -->
                        </div>

                        <div class="col-12 paddingnone p-1 pb-2">
                            <div class="col-md-6 paddingnone" >
                                <!-- <a  href="javascript:void(0)" class="btn btn-primary waves-effect pull-left ml-0"  v-on:click.prevent="buttonBack()">
                                            VIN : {{vin_no}}
                                </a> 
                                        
                                <a  href="javascript:void(0)" class="btn btn-primary waves-effect pull-left ml-2" v-on:click.prevent="buttonBack()">
                                    Service-{{sls_id}}
                                </a>  -->
                                    
                                    <div class="card hide">
                                        <div class="card-header bg-primary">
                                            <a  href="javascript:void(0)" class="btn btn-primary waves-effect pull-right ml-2" title='Back' v-on:click.prevent="buttonBack()">
                                                Vehical-{{vin_no}}
                                            </a> 
                                            
                                            <a  href="javascript:void(0)" class="btn btn-primary waves-effect pull-right ml-2" title='Back' v-on:click.prevent="buttonBack()">
                                                Service-{{sls_id}}
                                            </a> 
                                        </div>
                                    </div>
                                <!-- <div class="card">
                                    <div class="card-header bg-primary">Total Service Amount: <span class="float-right">{{summary.total_business}}</span></div>
                                </div> -->

                                <!-- <span>Total Business</span>
                                <span>{{summary.total_business}}</span> -->
                            </div>
                        </div>
                        
                        <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                            
                        <div class="col-12 paddingnone">
                            <!-- Pagination Start -->
                            <div class="top_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                            
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="invoiceArr && invoiceArr.length > 0">
                                        <template v-for="(invoice, index) in invoiceArr">
                                            <tr :key="index">
                                                <td>
                                                    <div class="actions">
                                                        <div class="actions">
                                                            <a @click="toggleSubTable(index)"   href="javascript:void(0)" title='Show Services details' class="mar-right-0">
                                                                <i v-if="subTableVisible && selectedRowIndex !== null && selectedRowIndex === index" class="exceptEdit fa fa-minus" aria-hidden="true"></i>
                                                                <i v-else class="exceptEdit fa fa-plus" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td >{{invoice.sls_id}}</td>
                                                <!-- <td >{{invoice.ops_id}}</td> -->
                                                <!-- <td >{{invoice.job_id}}</td> -->
                                                <td>{{invoice.job_id}}-{{invoice.job_dec}}</td>
                                                <td >{{invoice.sls_typ_id}}</td>
                                                <td>{{invoice.cause}}</td>
                                                <td class="" >
                                                    <div style="width: 100px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" :title="invoice.Correction"> {{invoice.Correction}}</div>
                                                </td>
                                                <td >{{invoice.failed_prt_id}}</td>
                                                <td >{{invoice.clm_nbr}}</td>
                                                <td >{{invoice.status}}</td>
                                                <td class="text-right" >{{invoice.amt_clm}}</td>
                                                <td class="text-right">{{invoice.use_job_prices}}</td>
                                                <td >{{invoice.request}}</td>
                                                
                                                <td class="text-right" >{{invoice.amt_parts}}</td>
                                                <!-- <td >{{invoice.AmtCostParts}}</td> -->
                                                <td class="text-right" >{{invoice.amt_labor}}</td>
                                                <!-- <td >{{invoice.AmtCostLabor}}</td> -->
                                                <td class="text-right" >{{invoice.amt_sublet}}</td>
                                                <!-- <td >{{invoice.AmtCostSublet}}</td> -->
                                                <td >{{invoice.amt_misc}}</td>
                                                <!-- <td >{{invoice.AmtCostMisc}}</td> -->
                                                <td class="text-right" >{{invoice.amt_tax}}</td>
                                                <td class="text-right" >{{invoice.amt_subtotal}}</td>
                                                
                                                
                                                <!-- <td >{{invoice.DateExpire}}</td>
                                                <td >{{invoice.DateCreate}}</td>
                                                <td >{{invoice.DateUpdate}}</td>
                                                <td >{{invoice.Complete}}</td>
                                                <td >{{invoice.NotId}}</td> -->

                                            
                                            </tr>

                                            
                                            <tr :key="'service_items_tr_'.index" v-show="selectedRowIndex !== null && selectedRowIndex === index">
                                                <td colspan="21" class="pt-0 pb-0 pl-0 pr-0">
                                                    <table class="table border">
                                                        <tbody>
                                                            <tr>
                                                                <th>ItmId</th>
                                                                <th>ItmTyp</th>
                                                                <th>SvItm</th>
                                                                <th>Des</th>
                                                                <th>ClaId</th>
                                                                <th>Reason</th>
                                                                <th>AmtPrice</th>
                                                                <th class="text-right">AmtCost</th>
                                                                <th class="text-right">AmtRebate</th>
                                                                <th class="text-right">QtyShip</th>
                                                                <th class="text-right">QtyBackorder</th>
                                                                <th class="text-right">HrsFlat</th>

                                                                <th class="text-right">HrsBill</th>
                                                                <!-- <th class="text-right">HrsCost</th> -->
                                                                <th class="text-right">HrsActual</th>
                                                                <th class="text-right">MinsPerSegment</th>
                                                                <!-- <th class="text-right">UseHrsFlat</th> -->
                                                                
                                                                <th class="text-right">Complete</th>
                                                                <th class="text-right">DatePayPeriod</th>
                                                                <th class="text-right">EmpIdLbr</th>
                                                                <th class="text-right">TaxId</th>
                                                                <th class="text-right">EmpId</th>
                                                                <th class="text-right">EmpIdSpn</th>
                                                            </tr>
                                                            <tr v-for="(subRow, subIndex) in invoice.svslsitms" :key="'service_items_'.subIndex">
                                                                <td>{{subRow.itm_id}}</td>
                                                                <td>{{subRow.itm_typ}}</td>
                                                                <td>{{subRow.sv_itm}}</td>
                                                                <td>{{subRow.des}}</td>
                                                                
                                                                <td>{{subRow.cla_id}}</td>
                                                                <td>{{subRow.reason}}</td>
                                                                <td class="text-right">{{subRow.amt_price}}</td>
                                                                <td class="text-right">{{subRow.amt_cost}}</td>
                                                                <td class="text-right">{{subRow.amt_rebate}}</td>
                                                                <td class="text-right">{{subRow.qty_ship}}</td>
                                                                <td class="text-right">{{subRow.qty_backorder}}</td>
                                                                <td class="text-right">{{subRow.hrs_flat}}</td>
                                                                <td class="text-right">{{subRow.hrs_bill}}</td>

                                                                <!-- <td class="text-right">{{subRow.hrs_cost}}</td> -->
                                                                <td class="text-right">{{subRow.hrs_actual ? subRow.hrs_actual.toFixed(2) : ''}}</td>
                                                                <td class="text-right">{{subRow.complete}}</td>
                                                                <td class="text-right">{{subRow.mins_per_segment}}</td>
                                                                <td class="text-right">{{subRow.date_pay_period_formatted}}</td>
                                                                <td class="text-right">{{subRow.emp_id_lbr}}</td>
                                                                <td class="text-right">{{subRow.tax_id}}</td>
                                                                <td class="text-right">{{subRow.emp_id}}</td>
                                                                <td class="text-right">{{subRow.emp_id_spn}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                            </tr>
                                        </template>
                                        

                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>

                                    <thead>
                                    <tr>
                                        <!-- <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th> -->
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            
                            <!-- Pagination Start -->
                            <div class="bottom_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                    

                        </div>
                    </div>
                </div>
</template>
<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";

    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";

    export default {
        name: 'ListSvslsOps',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                perPageValue : 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                summary: {
                    total_business: 0
                },
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                invoiceArr: [],
                headers: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord : "",
                selectAll: false,
                permissionNameArray : ['can_list_part_invoce_customer_master'],
                actionDisplay : false,
                accordianIds : [],
                jumpToHistoryPage : 1,
                filterCount : 0,
                syncFromExcedeButtonDisabled: false,
                hoverTextsyncFromExcedeButton: '',
                subTableVisible: false,
                selectedRowIndex: null,
                currentIndex: null
            }
        },
        props: {
            sls_id: {
                type: [String, Number],
                required: true,
                default: ''
            },
            vin_no: {
                type: [String, Number],
                required: true,
                default: ''
            },
            make_name: {
                type: [String, Number],
                required: true,
                default: ''
            },
            model_name: {
                type: [String, Number],
                required: true,
                default: ''
            },
            model_type: {
                type: [String, Number],
                required: true,
                default: ''
            },
            model_year: {
                type: [String, Number],
                required: true,
                default: ''
            },
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadServiceOperations();
            },
            currentPage: function () {
                self.loadServiceOperations();
            },*/
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
           
            
            var listHistoryData = null;
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            self.loadServiceOperations(1);
            self.getLoginIdPermissions();
        },
        methods: {
            toggleSubTable(index) {
                this.subTableVisible = !this.subTableVisible;
                this.selectedRowIndex = this.selectedRowIndex === index ? null : index;
                this.currentIndex = index;
            },
            buttonBack() {
                this.$parent.BacktoPreviousPage(1);
                // self.$router.push({ name: "UpdateContact", params: { id: self.$route.params.id}});
            },
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadServiceOperations();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadServiceOperations();
                }
            },
            returnAccordianDisplayDataKeys : function() {
                let dataArray = [
                    'id',
                    "name",
                ];

                return dataArray;
            },
            toggleIcon : function(id) {
                self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
            },
           
            disableSyncFromExcedeButton : function() {
                this.syncFromExcedeButtonDisabled = true;
                self.hoverTextsyncFromExcedeButton = 'Disable for next 10 mins';
                setTimeout(() => {
                    this.syncFromExcedeButtonDisabled = false;
                    self.hoverTextsyncFromExcedeButton = '';
                }, 11 * 60 * 1000); // 11 minutes
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadServiceOperations();
            },
            clearSelection : function() {
                self.selectAll = false;
                self.selectedRows = [];
            },
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.invoiceArr) {
                            self.selectedRows.push(self.invoiceArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.invoiceArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadServiceOperations();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadServiceOperations: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadServiceOperations();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };

                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    contact_id: self.$route.params.id,
                    sls_id: self.sls_id,
                };
                
                // this.storeFilters('listcontactemail', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);


                HTTP.post("/contact/getsvslsops", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelection();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            self.summary = data.data.summary;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadServiceOperations();
                                }, seconds);
                                
                            }
                            self.invoiceArr = data.data.content.data;
                            $("#selectAllCheckBox").prop("checked", false);
                            self.headers = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filters);

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]


                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (err) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if(self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadServiceOperations();
                }
            },
            
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }

            if (!self.permissionNameArray.includes('can_email_customer_master')) {
                self.headers.pop();
                self.actionDisplay = false;
            }

        }
    });


</script>
<style scoped>
.width-100-pct-imp{
    width: 100% !important;
}
</style>