var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-content-area",attrs:{"id":"page-content-wrapper"}},[(_vm.permissionNameArray.includes('can_add_opportunity_master'))?[_c('div',{staticClass:"container-fluid xyz"},[_c('div',{staticClass:"page-header-top-container"},[_c('div',{staticClass:"page-name"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('button',{staticClass:"btn btn-primary waves-effect pull-right",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.buttonBack()}}},[_vm._v(" Back ")])]),_c('div',{staticClass:"admin-page-content"},[_c('div',{staticClass:"forms-container add_user_forms_container vtc-dealer-forms"},[[_c('div',{staticClass:"row no-margin"},[_c('ul',{staticClass:"nav nav-tabs"},[(
                    _vm.permissionNameArray.includes('can_add_opportunity_master')
                  )?_c('li',{staticClass:"nav-item"},[_c('a',{class:'nav-link ' + [_vm.activetab === 0 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 0}}},[_vm._v("View Opportunity")])]):_vm._e(),(
                    _vm.permissionNameArray.includes('can_add_activity_master')
                  )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                      'nav-link': true,
                      active: _vm.activetab === 1,
                    },on:{"click":function($event){_vm.activetab = 1}}},[_vm._v("Activity")])]):_vm._e(),(
                    _vm.permissionNameArray.includes('can_list_deals') &&
                    _vm.productionCountry == 'US'
                  )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                      'nav-link': true,
                      active: _vm.activetab === 2,
                    },on:{"click":function($event){_vm.activetab = 2}}},[_vm._v("Deals "),_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(_vm.totalDeals))])])]):_vm._e(),(
                    _vm.permissionNameArray.includes('can_list_deals') &&
                    _vm.productionCountry == 'US'
                  )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                      'nav-link': true,
                      active: _vm.activetab === 3,
                    },on:{"click":function($event){_vm.activetab = 3}}},[_vm._v("Deals No. Overview "),_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(_vm.totalDealNoOverview))])])]):_vm._e()]),(
                  _vm.activetab === 0 &&
                  _vm.permissionNameArray.includes('can_add_opportunity_master')
                )?_c('div',{staticClass:"tabcontent",attrs:{"id":"customer-activity-logs0"}},[_c('add-update-opportunity',{attrs:{"hasParent":_vm.hasParent,"parentId":_vm.customerId}})],1):_vm._e(),(
                  _vm.activetab === 1 &&
                  _vm.permissionNameArray.includes('can_add_activity_master')
                )?_c('div',{staticClass:"tabcontent",attrs:{"id":"customer-activity-logs1"}},[_c('asset-crm',{attrs:{"opportunityMasterId":_vm.opportunityId,"redirectUrl":_vm.redirectUrl,"activetab":_vm.activetab}})],1):_vm._e(),(
                  _vm.activetab === 2 &&
                  _vm.permissionNameArray.includes('can_list_deals')
                )?_c('div',{staticClass:"tabcontent",attrs:{"id":"customer-activity-logs2"}},[_c('list-opportunity-deals')],1):_vm._e(),(
                  _vm.activetab === 3 &&
                  _vm.permissionNameArray.includes('can_list_deals')
                )?_c('div',{staticClass:"tabcontent",attrs:{"id":"customer-activity-logs2"}},[_c('deals-no-overview')],1):_vm._e()])]],2)])])]:[_c('no-access')],_c('app-footer')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }