<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_apu_make_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Apu Make</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              v-show="permissionNameArray.includes('can_add_apu_make_master')"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="addApuMake()"
            >
              <span>Add Apu Make</span>
            </a>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="
                permissionNameArray.includes('can_delete_apu_make_master')
              "
              type="button"
              v-on:click.prevent="deleteApuMake()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              Delete {{ selectedRows.length }} Record(s)
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListApuMake"
              ref="commonLoaderListApuMake"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="apuMakeArr && apuMakeArr.length > 0">
                    <template v-for="(apumake, index) in apuMakeArr">
                      <tr :key="index">
                        <td class="">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="selectedRows"
                              :value="apumake.id"
                              @change="inputCheckbox($event)"
                              class="custom-control-input"
                              :id="'customCheck' + apumake.id"
                              name="case[]"
                            />
                            <label
                              class="custom-control-label"
                              :for="'customCheck' + apumake.id"
                            ></label>
                          </div>
                        </td>
                        <td v-show="actionDisplay">
                          <div class="actions">
                            <router-link
                              v-show="
                                permissionNameArray.includes(
                                  'can_add_apu_make_master'
                                )
                              "
                              class="mar-right-0"
                              title="Edit Apu Make"
                              v-bind:to="{
                                name: 'UpdateApuMake',
                                params: { id: apumake.id },
                              }"
                            >
                              <i
                                aria-hidden="true"
                                class="fa fa-angle-right forward_icon"
                              ></i>
                            </router-link>

                            <a
                              v-show="
                                permissionNameArray.includes(
                                  'can_delete_apu_make_master'
                                )
                              "
                              href="javascript:void(0)"
                              title="Delete Apu Make"
                              class="mar-right-0"
                              v-on:click="deleteApuMake(apumake.id)"
                            >
                              <i
                                class="exceptEdit fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </td>
                        <td :class="rowClass.name">{{ apumake.name }}</td>
                        <td :class="rowClass.access_type">
                          {{ apumake.access_type }}
                        </td>
                        <td :class="rowClass.updated_at">
                          {{ apumake.updated_at }}
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListApuMake",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "list-accordian": ListAccordianComponent,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      apuMakeArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_apu_make_master"],
      actionDisplay: true,
      accordianIds: [],
      jumpToHistoryPage: 1,
      filterCount: 0,
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    /*selectAll: function (selectAll) {
                self.clickHeaderCheckBox();
            },*/
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadApuMake();
            },
            currentPage: function () {
                self.loadApuMake();
            },*/
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.setActiveMenu("apumake-list");
    EventBus.$emit("menu-selection-changed", "apumake-list");
    var listHistoryData = self.loadFilters("listApuMake", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadApuMake(1);
    self.getLoginIdPermissions();
  },
  methods: {
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadApuMake();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadApuMake();
      }
    },
    returnAccordianDisplayDataKeys: function () {
      let dataArray = ["id", "name"];

      return dataArray;
    },
    toggleIcon: function (id) {
      self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadApuMake();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    deleteApuMake: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Apu Make!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteArr: deleteArray,
              },
            };

            HTTP.delete("/apumake/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.showSAlert(response.data.message, "success", false, 3);
                  self.loadApuMake();
                } else if(response.data.code == "201") {
                  self.$swal({
                    title: "",
                    text: response.data.message,
                    type: "info",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                    showLoaderOnConfirm: false
                  });
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.apuMakeArr) {
            self.selectedRows.push(self.apuMakeArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.apuMakeArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addApuMake: function () {
      self.$router.push({ name: "AddApuMake" });
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadApuMake();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadApuMake: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        return false;
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
      };
      this.storeFilters("listApuMake", fields, false);
      $("#commonLoaderListApuMake")
        .find(".loading-container.lodingTable")
        .show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/apumake/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListApuMake")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.status == 200) {
            self.clearSelection();
            self.headers = [];
            self.totalRecords = data.data.content.result;
            self.apuMakeArr = data.data.content.data;
            $("#selectAllCheckBox").prop("checked", false);
            self.headers = data.data.content.headers;
            self.loadFilterInputs(data.data.content.headers, self.filters);

            self.addColumnClass(self.headers);

            let testArray = [
              {
                text: 1,
                value: "2020-02-01",
              },
              {
                text: 2,
                value: "2020-02-02",
              },
              {
                text: 3,
                value: "2020-02-03",
              },
            ];

            for (let i = 0; i < self.headers.length; i++) {
              if (self.headers[i]["type"] == "drop_down") {
                self.headers[i]["options_array"] = testArray;
              }
            }
            if (data.data.content.per_page !== self.totalRecords) {
              self.perPage = parseInt(data.data.content.per_page);
              self.jumpToPage = parseInt(data.data.content.current_page);
            } else {
              if (self.perPage == "All") {
                self.jumpToPage = 1;
              }
              self.perPageValue = self.totalRecords;
              self.currentPage = 1;
            }
            self.setAlreadyAppliedFilters();
            self.startEndRecord = self.calculateStartRecordEndRecord(
              self.currentPage,
              self.perPage,
              self.totalRecords
            );
            self.sortFieldDisplay(self.columnName, self.sortType);
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListApuMake")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(err, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadApuMake();
      }
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }

    if (
      !self.permissionNameArray.includes("can_add_apu_make_master") ||
      !self.permissionNameArray.includes("can_delete_apu_make_master")
    ) {
      self.headers.pop();
      self.actionDisplay = false;
    }
  }
});
</script>
