<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_teams_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Teams</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              v-show="permissionNameArray.includes('can_add_teams_master')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="addTeams()"
            >
              <span>Add Teams</span>
            </a>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <a
              v-show="permissionNameArray.includes('can_import_teams_master')"
              id="importTeams"
              title="Import Teams"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="loadImportModal('import_record')"
            >
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </a>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="permissionNameArray.includes('can_delete_teams_master')"
              type="button"
              v-on:click.prevent="deleteTeams()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              Delete {{ selectedRows.length }} Record(s)
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListTeams"
              ref="commonLoaderListTeams"
            ></common-loader>
            <!--Pagination Start-->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!--Pagination End-->

            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="teamsArr && teamsArr.length > 0">
                    <tr v-for="(teams, index) in teamsArr" :key="index">
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="teams.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + teams.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + teams.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions" style="width: 66px">
                          <a
                            v-show="
                              permissionNameArray.includes(
                                'can_add_teams_master'
                              )
                            "
                            href="javascript:void(0)"
                            class="mar-right-0"
                            title="Edit Teams"
                            v-bind:to="{
                              name: 'TeamsTab',
                              params: {
                                id: teams.id,
                              },
                            }"
                            @click="editTeams(teams.id)"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </a>

                          <a
                            v-show="
                              permissionNameArray.includes(
                                'can_delete_teams_master'
                              )
                            "
                            href="javascript:void(0)"
                            title="Delete Teams"
                            class="mar-right-0"
                            @click="deleteTeams(teams.id)"
                          >
                            <i
                              class="exceptEdit fa fa-trash-o"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <td :class="rowClass.name">
                        {{ teams.name }}
                      </td>

                      <td
                        :class="rowClass.user_names"
                        style="white-space: break-spaces"
                      >
                        <div>
                          <div v-if="Array.isArray(teams.users)">
                            <!-- Render user names based on showAllUserNames for this row -->
                            <div>
                              <div
                                v-for="(user, userIndex) in teams.users"
                                :key="userIndex"
                                class="user-box"
                                v-show="
                                  showAllUserNames[index] || userIndex < 5
                                "
                              >
                                <div class="user-name-container">
                                  {{ user.full_name }}
                                  <a
                                    v-show="
                                      permissionNameArray.includes(
                                        'can_delete_teams_master'
                                      )
                                    "
                                    href="javascript:void(0)"
                                    title="Remove User"
                                    @click="deleteUserName(teams.id, user.id)"
                                  >
                                    <i class="fa fa-times remove-icon"></i>
                                  </a>
                                </div>
                              </div>
                              <!-- Add link to toggle showAllUserNames for this row -->
                              <div v-if="teams.users.length > 5" class="link">
                                <a
                                  href="javascript:void(0)"
                                  @click="toggleShowAllUserNames(index)"
                                  class="extraFeature ml-2"
                                >
                                  {{
                                    showAllUserNames[index]
                                      ? "Read Less..."
                                      : "Read More..."
                                  }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>

                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>

                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>

            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  ></v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>

        <!-- Import Modal -->

        <div
          class="modal fade"
          id="teamsImportModal"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">
                  {{ allLabelMessages.import_teams_from_excel }}
                </h4>
              </div>
              <form
                class="form-inline"
                id="importCallform"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderTeamsImportModel"
                  ref="commonLoaderTeamsImportModel"
                >
                </common-loader>
                <div class="modal-body">
                  {{ allLabelMessages.select_excel_file_to_import }}
                  <input
                    type="file"
                    name="excl_file"
                    accept=".xlsx,.xls"
                    id="uploadTeams"
                    ref="files"
                    v-on:change="handleFilesUpload()"
                  />
                  <div class="form-group">
                    <label id="errorMsg" class="error"></label><br />
                  </div>
                  <a
                    id="demoteamsurl"
                    href="demoUrl"
                    target="_blank"
                    class="btn general-button btn-info waves-effect m-r-20"
                  >
                    {{ allLabelMessages.download_demo }}
                  </a>

                  <template v-if="teamsImportRecordsWithUserNotExist.length">
                    <div class="mt-20">
                      <h6>{{ allLabelMessages.import_file_error }}</h6>
                      <div>
                        <p>
                         {{ allStaticMessages.record_not_exist }}
                        </p>
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Username</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                user, index
                              ) in teamsImportRecordsWithUserNotExist"
                              :key="index"
                            >
                              <td>{{ user.name }}</td>
                              <td>{{ user.username }}</td>
                            </tr>
                            <button
                              type="button"
                              v-if="teamsImportRecordsWithUserNotExist.length"
                              @click="continueImport"
                              class="btn general-button btn-warning waves-effect"
                              id="continueBtn"
                            >
                              Continue
                            </button>
                            <h6>{{allStaticMessages.add_existing_user_in_teams_list}}</h6>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="modal-footer width-full">
                  <button
                    type="button"
                    @click="importTeams"
                    class="btn general-button btn-success waves-effect"
                    id="teamsImportBtn"
                  >
                    {{ importBtn }}
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-dismiss="modal"
                    @click="closeModal"
                    id="teamsCloseBtn"
                  >
                    {{ allLabelMessages.close }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <add-teams-form-popup
          ref="addTeamsFormPopup"
          @call-close-add-teams-popup="callCloseAddTeamsPopup"
        ></add-teams-form-popup>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import { commonMixins } from "../../mixins/commonMixins";
import AddTeamsFormPopup from "./AddTeamsFormPopup.vue";
import FilterComponent from "../FilterComponent/FilterComponent";
import EventBus from ".././event-bus";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";
import { HTTP } from "@/http-common";

export default {
  name: "ListTeams",
  mixins: [commonMixins],
  components: {
    "add-teams-form-popup": AddTeamsFormPopup,
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
    "app-footer": Footer,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      teamsArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      actionDisplay: true,
      jumpToHistoryPage: 1,
      filterCount: 0,
      showAllUserNames: {},
      permissionNameArray: ["can_list_teams_master"],
      teamsImportRecordsWithUserNotExist: [],
      files: [],
      importBtn: "Import",
      forceImport: 0,
      importTeamsInstance: null,
    };
  },
  beforeMount() {
    self = this;
  },
  created() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    var initial = process.env.VUE_APP_ROOT_API;
    var initial = initial.replace("/v1", "");
    $("#demoteamsurl").attr("href", initial + "/demo/TeamsImportSheet.xlsx");

    self.setActiveMenu("teams-list");
    EventBus.$emit("menu-selection-changed", "teams-list");
    var listHistoryData = self.loadFilters("listTeams", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadTeams("onPageLoad");
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.importTeamsInstance = new bootstrap.Modal(
      document.getElementById("teamsImportModal")
    );
    },
    toggleShowAllUserNames(index) {
      self.$set(self.showAllUserNames, index, !self.showAllUserNames[index]);
    },

    resetSorting() {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage() {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadTeams();
    },
    changepage() {
      if (!self.listExecutionState) {
        self.loadTeams();
      }
    },
    refreshList() {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadTeams();
    },
    clearSelection() {
      self.selectAll = false;
      self.selectedRows = [];
    },
    keyMonitor() {
      self.tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox() {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.teamsArr) {
            self.selectedRows.push(self.teamsArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox() {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.teamsArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addTeams() {
      let data = {
        add_edit: "add",
        record_id: "",
      };
      self.$refs.addTeamsFormPopup.openForm(data);
    },
    editTeams(id) {
      let data = {
        add_edit: "edit",
        record_id: id,
      };
      self.$refs.addTeamsFormPopup.openForm(data);
    },
    callCloseAddTeamsPopup() {
      //Reload the list
      self.loadTeams();
    },
    applyFilterParentComponent(data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadTeams();
    },
    setAlreadyAppliedFilters() {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    loadImportModal() {
      self.files = self.teamsImportRecordsWithUserNotExist = [];
      self.resetTeamsImportModal();
      self.forceImport = 0; 
      self.importTeamsInstance.show();
    },

    handleFilesUpload() {
      let uploadedFiles = self.$refs.files.files;
        for (let i = 0; i < uploadedFiles.length; i++) {
          self.files.push(uploadedFiles[i]); 
        }
    },

    importTeams() {
      if (!self.files.length) {
        self.showSAlert(
          self.allStaticMessages.please_upload_excel_file,
          "info",
          false,
          3
        );
      } else {
        var data = new FormData();
        for (let i = 0; i < self.files.length; i++) {
          const file = self.files[i];
          data.append("excl_file", file);
        }

        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            "Content-Type": "multipart/form-data",
          },
        };

        self.importBtn = "Importing ...";
        $("#commonLoaderTeamsImportModel")
          .find(".loading-container.lodingTable")
          .show();

        HTTP.post("/teams/import", data, {
          ...config,
          params: { forceImport: self.forceImport },
        })
          .then((response) => {
            $("#commonLoaderTeamsImportModel")
              .find(".loading-container.lodingTable")
              .hide();

            if (response.data.code == 201) {
              self.teamsImportRecordsWithUserNotExist =
                response.data.non_exist_users;
                self.importTeamsInstance.show();
              self.importBtn = "Import";
            } else if (response.data.code == 200) {
              self.importTeamsInstance.hide();
              self.showSAlert(response.data.message, "success", false);
              self.loadTeams();
              self.teamsImportRecordsWithUserNotExist = [];
            } else {
              self.showSAlert(response.data.message, "error", false);
              self.importBtn = "Import";
            }
          })
          .catch(function (err) {
            $("#commonLoaderTeamsImportModel")
              .find(".loading-container.lodingTable")
              .hide();
            self.catchHandler(err, function () {});
          });
      }
    },

    continueImport() {
      self.forceImport = 1;
      self.importTeams();
    },

    closeModal() {
      self.importTeamsInstance.hide();
      self.teamsImportRecordsWithUserNotExist = [];
      self.forceImport = 0;
    },
    resetTeamsImportModal() {
      self.$refs.files.value = "";
      self.files = [];
      self.importBtn = "Import";
    },
    loadTeams(flag = "") {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadTeams();
      }
      flag =
        flag === null || typeof flag === "undefined" || flag == "" ? "" : flag;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
      };
      this.storeFilters("listTeams", fields, false);
      $("#commonLoaderListTeams").find(".loading-container.lodingTable").show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/teams/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (flag == "onPageLoad") {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListTeams")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.status == 200) {
            self.clearSelection();
            self.headers = [];
            self.totalRecords = data.data.content.result;
            if (
              self.perPage == "All" &&
              self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
            ) {
              let seconds = 5000;
              self.$swal({
                type: "error",
                html: self.allStaticMessages
                  .too_many_data_to_display_browser_can_not_load,
                showConfirmButton: false,
                timer: seconds,
              });
              setTimeout(() => {
                self.perPage = 500;
                self.loadTeams();
              }, seconds);
            }
            self.teamsArr = data.data.content.data;
            self.headers = data.data.content.headers;
            self.loadFilterInputs(data.data.content.headers, self.filters);

            self.addColumnClass(self.headers);

            if (data.data.content.per_page !== self.totalRecords) {
              self.perPage = parseInt(data.data.content.per_page);
              self.jumpToPage = parseInt(data.data.content.current_page);
            } else {
              if (self.perPage == "All") {
                self.jumpToPage = 1;
              }
              self.perPageValue = self.totalRecords;
              self.currentPage = 1;
            }
            self.setAlreadyAppliedFilters();
            self.startEndRecord = self.calculateStartRecordEndRecord(
              self.currentPage,
              self.perPage,
              self.totalRecords
            );
            self.sortFieldDisplay(self.columnName, self.sortType);
          }
        })
        .catch(function (err) {
          self.listExecutionState = false;
          $("#commonLoaderListTeams")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(err, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadTeams();
      }
    },
    deleteTeams(id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Team(s)!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteIdArr: deleteArray,
              },
            };
            HTTP.delete("/teams/delete", config)
              .then(function (response) {
                if (response.data.code == 200) {
                  self.showSAlert(response.data.message, "success", false, 3);
                  self.loadTeams();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },

    deleteUserName(id, userId) {
      self
        .$swal({
          text: "Are you sure that you want to remove this user(s)?",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                id: id,
                teamUserId: userId,
              },
            };
            HTTP.delete("/teams/username/delete", config)
              .then(function (response) {
                if (response.data.code == 200) {
                  self.showSAlert(response.data.message, "success", false, 3);

                  const teamIndex = self.teamsArr.findIndex(
                    (team) => team.id === id
                  );
                  if (teamIndex !== -1) {
                    const userIndex = self.teamsArr[teamIndex].users.findIndex(
                      (user) => user.id === userId
                    );
                    if (userIndex !== -1) {
                      self.teamsArr[teamIndex].users.splice(userIndex, 1);
                      self.loadTeams();
                    }
                  }
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_teams_master") &&
      !self.permissionNameArray.includes("can_delete_teams_master")
    ) {
      setTimeout(function () {
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>

<style>
.user-box {
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.user-name-container {
  display: flex;
  align-items: center;
}

.remove-icon {
  margin-left: 5px;
  color: black;
}

.link {
  display: contents;
}
</style>
