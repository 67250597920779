<template>
    <div class="">

        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_add_model_master')">
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->
                        <div :class="alertClass" id="success-alert">
                            <strong> {{alertMessage}} </strong>
                        </div>
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <span class="help is-danger" v-if="id > 0 && linkCountAsset > 0">
                            Are you sure that you want to update this Model! It is link with <strong> {{linkCountAsset}} </strong> Inventory(s).
                        </span>
                            <div class="row no-margin">
                                <!--Name-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{nameError}}</span>
                                    </div>
                                </div>
                                <!--Make-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select title="Select Make"
                                                  v-model="makeId"
                                                  @change="changedValue"
                                                  @search="onSearchMake"
                                                  @input="makeError = ''"
                                                  name="make"
                                                  id="make"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="true"
                                                  :filterable="false"
                                                  :options="makeArr"
                                                  :placeholder="staticMessages.make_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isMakeRecordNotFound">
                                                    {{staticMessages.make_search}}
                                                </template>
                                                <template v-else>
                                                    {{staticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{makeError}}</span>
                                    </div>
                                </div>
                                <!--Type-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select title="Select Type"
                                                  v-model="typeId"
                                                  @change="changedValue"
                                                  @input="typeError = ''"
                                                  name="type"
                                                  id="type"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="typeArr"
                                                  :placeholder="staticMessages.type_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{typeError}}</span>
                                    </div>
                                </div>
                                <!--Access Type -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.access_type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                                v-model="accessTypeId"
                                                @change="changedValue"
                                                name="accessTypeId"
                                                id="accessTypeId"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="accessTypeArr"
                                                :placeholder="staticMessages.access_type_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Application Type -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.application_type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="applicationTyp" v-model="applicationTyp" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <!-- Engine Output -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.engine_output_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="engineOp" v-model="engineOp" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <!-- Emission Standard -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.emission_standard_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="emissionStd" v-model="emissionStd" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <!--Cab Type -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.cab_type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="cabTypeId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabTypeArr"
                                                :placeholder="staticMessages.cab_type_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!--Cab Style -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.cab_style_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="cabStyleId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabStyleArr"
                                                :placeholder="staticMessages.cab_style_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Suspension -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.suspension_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="suspensionId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="suspensionArr"
                                                :placeholder="staticMessages.suspension_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Front Wheel -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.front_wheels_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="frontWheelId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="frontWheelArr"
                                                :placeholder="staticMessages.front_wheels_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Rear Wheel -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.rear_wheels_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="rearWheelId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="rearWheelArr"
                                                :placeholder="staticMessages.rear_wheels_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Front Tyre -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.front_tyre_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="frontTyreId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="frontTyreArr"
                                                :placeholder="staticMessages.front_tyre_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Rear Tyre -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.rear_tyre_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                                v-model="rearTyreId"
                                                @change="changedValue"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="rearTyreArr"
                                                :placeholder="staticMessages.rear_tyre_place_holder">
                                        </v-select>
                                    </div>
                                </div>

                                <!-- Wheelbase -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.wheelbase_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="wheelBase" v-model="wheelBase" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <!-- Paint -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.paint_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="paint" v-model="paint" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <!-- Fuel Capacity -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.fuel_capacity_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input id="fuelCapacity" v-model="fuelCapacity" class="form-control" type="text" maxlength="190"/>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <!-- Commented upload pdf file after discussed with Roshani and Satyen -->
                                <!-- <div v-show="multyfile_allow == 1" id="AddNewBtn" class="row col-12 forms-block">
                                    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                        <button type="button" v-on:click="addNewDocument" class="btn btn-success">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div class="mt-1 paddingleft0 col-lg-11 col-md-11 col-sm-12 col-xs-12">
                                        {{staticInputLabelMessages.would_you_like_add_more_documents_label}}
                                    </div>
                                </div> -->
                                <!-- <template v-for="(docObj, index) in documentObjects">
                                    <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 forms-block" :key="index">
                                        <div v-show="multyfile_allow == 1" class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                            <button type="button" v-on:click="removeDocument(index)" class="btn btn-danger">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </div>

                                        <div :id="'input_doc_'+index" class="col-5">
                                            <div class="paddingleft0 col-3">
                                                <div class="form-label" for="title">{{staticInputLabelMessages.file_label}} </div>
                                            </div>
                                            <div class="col-9 paddingleft0">
                                                <input type="button" id="id" value="Upload File"  v-on:click="clickonFileButton(index)" />
                                                <span class="backend input_file_name ">{{documentObjects[index].doc_name}}</span>
                                                <input
                                                        accept="application/pdf"
                                                        style="display: none;" type="file"
                                                        v-on:change="handleFilesUpload(index)"
                                                        :id="'upload_'+index"
                                                        name="documentObjects[][doc]" >
                                            </div>
                                        </div>
                                    </div>
                                </template> -->

                                <!-- Upload Image-->
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                    <div class="form-group">
                                        <div class="form-label">{{staticInputLabelMessages.upload_image_label}}</div>
                                        <vue-dropzone
                                                ref="dropzone"
                                                id="dropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-success="uploadDocument"
                                                @vdropzone-removed-file="removedSelectedFile"
                                                @vdropzone-error="fileError"
                                                @vdropzone-file-added="fileAdd"
                                                @vdropzone-total-upload-progress="progressOfUloadBanner"
                                                :useCustomSlot=true
                                                :destroyDropzone="false"
                                        >
                                            <div class="dropzone-custom-content">
                                                <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                                <div class="subtitle">(Select files from your computer.)</div>
                                            </div>
                                        </vue-dropzone>
                                    </div>
                                </div>

                                <!--Error Alert-->
                                <div class="row clearfix" >
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="alert alert-danger" id="alertError">
                                            <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                            <strong id="errorMsg" class="error"></strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                <!--Buttons-->
                                <div class="row clearfix"></div>
                                <div class="button-demo">
                                    <input id="addModelBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addModel()">
                                    <input id="addAndContinueModelBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addModel(flag='Save and Continue')">
                                    <input id="cancelModel" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <!--page content-->
        </div>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        name: 'AddUpdateModel',
        mixins: [commonMixins],
        components: {
            'app-footer': Footer,
            'no-access' : NoAccessPage,
            'vueDropzone': vue2Dropzone,
        },
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                nameError: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Model",
                id: 0,
                linkCountAsset: 0,
                makeId: "",
                makeArr: [],
                isMakeRecordNotFound: false,
                makeError: "",
                typeId: "",
                typeArr: [
                    {text: 'Canter', value: 'Canter'},
                    {text: 'Fighter', value: 'Fighter'},
                    {text: 'Rosa', value: 'Rosa'},
                    {text: 'Shogun', value: 'Shogun'},
                    {text: 'Trailor', value: 'Trailor'},
                    {text: 'Truck', value: 'Truck'}
                ],
                typeError: "",
                accessTypeId: {text: "Used", value: "used"},
                accessTypeArr: [{text: "Both", value: "both"}, {text: "New", value: "new"}, {text: "Used", value: "used"}],
                /* Make multyfile_allow = 1 for allow multiple upload */
                // multyfile_allow : 0,
                // documentObjects: [
                //     {
                //         doc_title: '',
                //         doc: '',
                //         doc_name: '',
                //     }
                // ],
                applicationTyp : "",
                engineOp : "",
                emissionStd : "",

                cabTypeId : "",
                cabTypeArr : [],
                cabStyleId : "",
                cabStyleArr : [],

                wheelBase : "",

                suspensionId : "",
                suspensionArr : [],
                frontWheelId : "",
                frontWheelArr : [],
                rearWheelId : "",
                rearWheelArr : [],
                frontTyreId : "",
                frontTyreArr : [],
                rearTyreId : "",
                rearTyreArr : [],

                paint : "",
                fuelCapacity : "",
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".png, .jpg, .jpeg",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    dictMaxFilesExceeded: "Only 1 file is allowed",
                },
                imagePath: '',
                imageType: '',
                imageSize: '',

                permissionNameArray : ['can_add_model_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            // documentObjects: {
            //     handler(object) {
            //         //console.log(object.length);
            //         if (self.multyfile_allow == "1"  && object.length >= 2) {
            //             $("#AddNewBtn").hide();
            //         } else if(self.multyfile_allow == "1"  && object.length < 2){
            //             $("#AddNewBtn").show();
            //         }
            //     },
            //     deep: true
            // },
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('model-list');
            EventBus.$emit("menu-selection-changed", "model-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.id = self.$route.params.id;
                self.getModelDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueModelBtn").hide();
                self.pageTitle = "Edit Model";
            }
            self.loadAllData(['Make'], false, self.loadMakeDataCallback, '', '', '', '', '', '', '', '', self.accessTypeId.value);
            self.loadAllData(['CabType', 'CabStyle','Suspension', 'FrontWheels', 'RearWheels', 'FrontTireMake', 'RearTireMake'], false, self.loadMasterDataCallback,  '', '', '', '', '', '', '', '', self.accessTypeId.value);
        },
        methods: {
            loadMasterDataCallback: function(callbackData) {
                self.cabTypeArr = callbackData.CabType;
                self.cabStyleArr = callbackData.CabStyle;
                self.suspensionArr = callbackData.Suspension;
                self.frontWheelArr = callbackData.FrontWheels;
                self.rearWheelArr = callbackData.RearWheels;
                self.frontTyreArr = callbackData.FrontTireMake;
                self.rearTyreArr = callbackData.RearTireMake;
            },
            // addNewDocument: function () {
            //     self.documentObjects.push(Vue.util.extend({}, self.documentObject))
            // },
            onSearchMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Make'], true, self.loadMakeDataCallback, loading, search, '', '', '', '', '', '', self.accessTypeId.value);
                }
            },
            // removeDocument: function (index) {
            //     Vue.delete(self.documentObjects, index);
            //     $.each(self.documentObjects, function (i) {
            //         self.checkIsURL(i);
            //     });
            //     //console.log((self.documentObjects));
            // },
            clickonFileButton: function (index) {
                $("#upload_" + index).click();
            },
            // handleFilesUpload: function (index) {
            //     let uploadedFiles = "";
            //     self.$nextTick(function () {
            //         setTimeout(function () {
            //             uploadedFiles = $("#upload_" + index).prop('files')[0];
            //             uploadedFiles = (uploadedFiles === null || typeof uploadedFiles === "undefined" || uploadedFiles == '') ? '' : uploadedFiles;
            //             if (uploadedFiles != '') {
            //                 self.documentObjects[index]['doc'] = uploadedFiles;
            //                 self.documentObjects[index]['doc_name'] = uploadedFiles.name;
            //             }
            //         }, 200);
            //     });
            //     /*this.getImagePreviews();*/
            // },
            loadMakeDataCallback: function (callbackData) {
                self.isMakeRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
            },
            buttonBack() {
                self.$router.push('/model/list');
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
                self.makeId = '';
                self.typeId = "";
                self.accessTypeId = {
                    text: "Used", 
                    value: "used"
                };
                // self.documentObjects= [
                //     {
                //         doc_title: '',
                //         doc: '',
                //         doc_name: '',
                //     }
                // ];
                self.applicationTyp = "";
                self.engineOp = "";
                self.emissionStd = "";
                self.wheelBase = "";
                self.paint = "";
                self.fuelCapacity = "";
                self.imagePath = "";
                self.imageType = "";
                self.imageSize = "";
                self.cabTypeId = "";
                self.cabStyleId = "";
                self.suspensionId = "";
                self.frontWheelId = "";
                self.rearWheelId = "";
                self.frontTyreId = "";
                self.rearTyreId = "";
                // self.$refs.dropzone.removeAllFiles();
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                self.imagePath = response.content.file_path;
                self.imageType = file.type;
                self.imageSize = file.size;

                // self.$refs.dropzone.setOption('maxFiles', null);
                /* console.log("Upload Document"); */
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                var removeFile = "";
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;

                /* console.log("removedSelectedFile"); */
                if ("manuallyAdded" in file == false) {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path;
                }
                self.imagePath = '';
                self.imageType = '';
                self.imageSize = '';
            },
            removeFileFromServer: function (filePath) {
                /* console.log("removeFileFromServer"); */
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                /* console.log("File Error"); */
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$refs.dropzone.dropzone.options.maxFiles) {
                    // self.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            getModelDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/model/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.linkCountAsset = response.data.content.countModel;
                        self.makeId = {text: response.data.content.makeName, value: response.data.content.makeId};
                        self.typeId = {text: self.capitalizeFirstLetter(response.data.content.type), value: response.data.content.type};
                        self.accessTypeId = {text: self.formatString(response.data.content.is_used), value: response.data.content.is_used};
                        // if (response.data.media.length > 0) {
                        //     self.documentObjects = response.data.media;
                        // } else {
                        //     self.documentObjects = [{doc_title: '', is_url: 0, doc: '', doc_name: '', url: ''}];
                        // }
                        self.applicationTyp = response.data.content.application_type;
                        self.engineOp = response.data.content.engine_output;
                        self.emissionStd = response.data.content.emission_standard;
                        if(response.data.content.cabTypeId != null) {
                            self.cabTypeId = {text: response.data.content.cabTypeName, value: response.data.content.cabTypeId};
                        }
                        if(response.data.content.cabStyleId != null) {
                            self.cabStyleId = {text: response.data.content.cabStyleName, value: response.data.content.cabStyleId};
                        }
                        self.wheelBase = response.data.content.wheelbase;
                        if(response.data.content.suspensionId != null) {
                            self.suspensionId = {text: response.data.content.suspensionName, value: response.data.content.suspensionId};
                        }
                        if(response.data.content.frontWheelId != null) {
                            self.frontWheelId = {text: response.data.content.frontWheelName, value: response.data.content.frontWheelId};
                        }
                        if(response.data.content.rearWheelId != null) {
                            self.rearWheelId = {text: response.data.content.rearWheelName, value: response.data.content.rearWheelId};
                        }
                        if(response.data.content.frontTireId != null) {
                            self.frontTyreId = {text: response.data.content.frontTireName, value: response.data.content.frontTireId};
                        }
                        if(response.data.content.rearTireId != null) {
                            self.rearTyreId = {text: response.data.content.rearTireName, value: response.data.content.rearTireId};
                        }
                        self.paint = response.data.content.paint;
                        self.fuelCapacity = response.data.content.fuel_capacity;
                        self.imagePath = response.data.content.image_path;
                        if (self.imagePath != '') {
                            self.imageSize = response.data.content.image_size;
                            self.uploadManually();
                        }
                    })
                    .catch(function (err) {
                        /* console.log(err); */
                        self.catchHandler(err, function () {});
                    });
            },
            uploadManually: function () {
                if(self.imagePath != '') {
                    var file_name = self.getFileName(self.imagePath);
                    let file_type = self.getFileType(file_name);
                    let file_size = self.imageSize;
                    let url = self.imagePath;
                    /* console.log("File Name : " + file_name + " , File Type : " + file_type + " , URL : " + url + " , File Size : " + file_size); */
                    let file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                    self.$refs.dropzone.manuallyAddFile(file_data, url);
                }
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
            },
            getFileName: function (file) {
                var src = file.split('/');
                var file_name = src[src.length - 1];
                return file_name;
            },
            getFileType: function (file_name) {
                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.makeId == null || !self.makeId.value) {
                    self.makeError = "Please select make";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.typeId == null || !self.typeId.value) {
                    self.typeError = "Please select type";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addModel(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token"),
                                // "Content-Type": "multipart/form-data"
                            }
                        };
                        var cabTypeId = "";
                        if (self.cabTypeId == null || !self.cabTypeId.value) {
                            cabTypeId = "";
                        }else{
                            cabTypeId = self.cabTypeId.value;
                        }
                        var cabStyleId = "";
                        if (self.cabStyleId == null || !self.cabStyleId.value) {
                            cabStyleId = "";
                        }else{
                            cabStyleId = self.cabStyleId.value;
                        }
                        var suspensionId = "";
                        if (self.suspensionId == null || !self.suspensionId.value) {
                            suspensionId = "";
                        }else{
                            suspensionId = self.suspensionId.value;
                        }
                        var frontWheelId = "";
                        if (self.frontWheelId == null || !self.frontWheelId.value) {
                            frontWheelId = "";
                        }else{
                            frontWheelId = self.frontWheelId.value;
                        }
                        var rearWheelId = "";
                        if (self.rearWheelId == null || !self.rearWheelId.value) {
                            rearWheelId = "";
                        }else{
                            rearWheelId = self.rearWheelId.value;
                        }
                        var frontTyreId = "";
                        if (self.frontTyreId == null || !self.frontTyreId.value) {
                            frontTyreId = "";
                        }else{
                            frontTyreId = self.frontTyreId.value;
                        }
                        var rearTyreId = "";
                        if (self.rearTyreId == null || !self.rearTyreId.value) {
                            rearTyreId = "";
                        }else{
                            rearTyreId = self.rearTyreId.value;
                        }
                        var data = new FormData();
                        data.append('name', self.name);
                        data.append('make_id', self.makeId.value);
                        data.append('type_id', self.typeId.value);
                        data.append('access_type', self.accessTypeId.value);
                        // for (var i = 0; i < self.documentObjects.length; i++) {
                        //     data.append('doc_title' + [i], self.documentObjects[i].doc_title);
                        //     data.append('doc' + [i], self.documentObjects[i].doc);
                        //     data.append('doc_name' + [i], self.documentObjects[i].doc_name);
                        // }
                        data.append('application_type', self.applicationTyp);
                        data.append('engine_output', self.engineOp);
                        data.append('emission_standard', self.emissionStd);
                        data.append('cab_type', cabTypeId);
                        data.append('cab_style', cabStyleId);
                        data.append('wheelbase', self.wheelBase);
                        data.append('suspension', suspensionId);
                        data.append('front_wheel_id', frontWheelId);
                        data.append('rear_wheel_id', rearWheelId);
                        data.append('front_tyre_id', frontTyreId);
                        data.append('rear_tyre_id', rearTyreId);
                        data.append('paint', self.paint);
                        data.append('fuel_capacity', self.fuelCapacity);
                        data.append('image_path', self.imagePath);
                        data.append('image_size', self.imageSize);
                        data.append('image_type', self.imageType);
                        // data.append('document_count', self.documentObjects.length);
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/model/add/update", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>