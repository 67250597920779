<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_vehicle_check_in')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="salesmanId"
                                        name="salesmanId"
                                        id="salesmanId"
                                        label="text"
                                        @change="changedValue"
                                        @input="salesmanIdError = ''"
                                        :filterable="false"
                                        :options="salesmanIdArr"
                                        @search="onSearchSalesmanUser"
                                        :placeholder="staticMessages.salesman_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesmanNameRecordNotFound">
                                                {{staticMessages.salesman_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesmanIdError}}</span>
                                </div>
                            </div>

                            <!-- Vin -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span style="overflow: auto;height:1%" class="form-control">{{vin}}</span>
                                    <!-- <textarea v-model="vin" class="form-control" style="min-height : 38px;" disabled></textarea> -->
                                    <!--<input @input="vinError = ''" id="vin" v-model="vin" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vinError}}</span>-->
                                </div>
                            </div>

                            <!-- Year -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text"/> -->
                                    <date-picker
                                            id="year"
                                            v-model="year"
                                            :config="yearOptions"
                                            class="form-control"
                                            autocomplete="off"
                                            placeholder="Select Year"
                                            name="year"
                                            @input="yearError = ''"
                                    ></date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{yearError}}</span>
                                </div>
                            </div>

                            <!-- Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="makeError = ''" id="make" v-model="make" class="form-control" type="text"/> -->
                                    <v-select
                                            v-model="make"
                                            name="make"
                                            :placeholder="staticMessages.make_place_holder"
                                            label="text"
                                            @change="makeValueChanged"
                                            :options="makeArr"
                                            class=""
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>

                            <!-- Model -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- <input @input="modelError = ''" id="model" v-model="model" class="form-control" type="text"/> -->
                                    <v-select
                                            v-model="model"
                                            name="model"
                                            :placeholder="staticMessages.model_place_holder"
                                            label="text"
                                            @change="modelValueChanged"
                                            :options="modelArr"
                                            class=""
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </div>
                            </div>

                            <!-- Runs -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.runs_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="runs"
                                                        :options="runsOptions"
                                                        name="runs">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Axle Gaskets given to driver -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.axle_gaskets_given_to_driver_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="isAxleGiven"
                                                        :options="isAxleGivenOptions"
                                                        name="isAxleGiven">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Torque down front lug nuts -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.torque_down_front_lug_nuts_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="isTorqueDown"
                                                        :options="isTorqueDownOptions"
                                                        name="isTorqueDown">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Fuel level -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_level_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="fuelLevel"
                                                        :options="fuelLevelOptions"
                                                        name="fuelLevel">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Brake chamber locks removed -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.brake_chamber_locks_removed_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="isBrakeChamberLockRemoved"
                                                        :options="isBrakeChamberLockRemovedOptions"
                                                        name="isBrakeChamberLockRemoved">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Visual Inspection Exterior -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visual_inspection_exterior_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="visualInspectionExterior" v-model="visualInspectionExterior" class="form-control" type="text" maxlength="190"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelLevelError}}</span> -->
                                </div>
                            </div>

                            <!-- Front tires matching? -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_tires_matching_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="isFrontTiresMatching"
                                                        :options="isFrontTiresMatchingOptions"
                                                        name="isFrontTiresMatching">
                                    </b-form-radio-group>
                                </div>
                            </div>

                            <!-- Visual Inspection Interior -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visual_inspection_interior_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="visualInspectionInterior" v-model="visualInspectionInterior" class="form-control" type="text" maxlength="190"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelLevelError}}</span> -->
                                </div>
                            </div>

                            <!--Additional note-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.additional_note_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea id="note" v-model="note" class="form-control" maxlength="50000"></textarea>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>

                            <!-- Completed -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.approved_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" v-model="completed" value="true" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>

                            <!-- Complete By -->
                            <div v-if="completed" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.approved_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="completeById"
                                              name="completeById"
                                              id="completeById"
                                              label="text"
                                              @change="changedValue"
                                              :filterable="false"
                                              :options="completeByArr"
                                              @search="onSearchCompleteBy"
                                              :placeholder="staticMessages.complete_by_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompleteByRecordNotFound">
                                                {{staticMessages.complete_by_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{completeByIdError}}</span>
                                </div>
                            </div>

                            <!-- Created At -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.created_at_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input disabled id="createdAt" v-model="createdAt"  class="form-control" type="text"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                            </div>
                            <template v-if="attached_pin_plate.length > 0">
                                <div class="col-12 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.attached_pic_of_vin_plate}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="swiperAttachedPinPlate" ref="swiperAttachedPinPlate">
                                                    <template v-for="(doc, index1) in attached_pin_plate">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>    
                            </template>
                            
                            <template v-if="attached_specs_sticker.length > 0">
                                <div class="col-12 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.attached_pic_of_sticker}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="attachedPicOfSticker" ref="attachedPicOfSticker">
                                                    <template v-for="(doc, index1) in attached_specs_sticker">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div> 
                            </template>
                            
                            <template v-if="attached_interior_exterior.length > 0">
                                <div class="col-12 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.attached_additional_pics_of_interior_exterior}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="attachedAdditionalPicsOfInteriorExterior" ref="attachedAdditionalPicsOfInteriorExterior">
                                                    <template v-for="(doc, index1) in attached_interior_exterior">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>    
                                    </div>    
                                </div>
                            </template>
                            
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addVehicleCheckIn()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    
    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUdpateVehicleCheckIn',
        components: {
            swiper,
            swiperSlide,
            datePicker,
        },
        mixins: [commonMixins],
        data() {
            return {
                userCompany : [1],
                companyId : 1,
                swiperAttachedPinPlate: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                attachedPicOfSticker: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                attachedAdditionalPicsOfInteriorExterior: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                attached_pin_plate: "",
                attached_specs_sticker: "",
                attached_interior_exterior: "",
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                vin: "",
                year: "",
                yearOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                make: "",
                makeArr : [],
                model: "",
                modelArr : [],
                isAxleGiven: "yes",
                isAxleGivenOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                    {text: 'Trailer', value: 'trailer'},
                ],
                runs: "yes",
                runsOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                isTorqueDown: "yes",
                isTorqueDownOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                    {text: 'Trailer', value: 'trailer'},
                ],
                fuelLevel: "",
                fuelLevelOptions: [
                    {text: 'Under1/8', value: 'under1/8'},
                    {text: '1/8', value: '1/8'},
                    {text: '1/4', value: '1/4'},
                    {text: '1/2', value: '1/2'},
                    {text: '3/4', value: '3/4'},
                    {text: 'Full', value: 'full'}
                ],
                isBrakeChamberLockRemoved: "yes",
                isBrakeChamberLockRemovedOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                visualInspectionExterior: "",
                isFrontTiresMatching: "yes",
                isFrontTiresMatchingOptions: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                visualInspectionInterior: "",
                note: "",
                completed: false,
                completeById: "",
                completeByArr: [],
                isCompleteByRecordNotFound: false,
                createdAt: "",

                // Error
                salesmanIdError: "",
                completeByIdError: "",
                vinError: "",
                yearError: "",
                makeError: "",
                modelError: "",
                fuelLevelError: "",
                // 
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add Vehicle Check In',
                permissionNameArray: ['can_add_vehicle_check_in'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('vehicle_check_in');
            self.getLoginIdPermissions();
            EventBus.$emit("menu-selection-changed", "vehicle_check_in");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getVehicleCheckInDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Vehicle Check In";
            }
            //self.loadAllData(['User'], false, self.loadCompleteByDataCallback);
            self.loadAllData(['Make'], false, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListVehicleCheckIn'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadMasterDataCallback: function(callbackData) {
                self.makeArr = callbackData.Make;
            },
            loadCompleteByDataCallback: function (callbackData) {
                self.completeByArr = callbackData.User;
            },
            onSearchCompleteBy(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getCompleteByData);
                }
            },
            getCompleteByData: function (callbackData) {
                self.isCompleteByRecordNotFound = false;
                self.completeByArr = callbackData;
                if(self.completeByArr.length == 0) {
                    self.isCompleteByRecordNotFound = true;
                }
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function (callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            makeValueChanged: function () {
                let make_id = "";
                if (typeof(self.make) != "undefined" && self.make != null && self.make != "" && self.make != 0) {
                    make_id = self.make.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, 'undefined', '', make_id);
            },
            loadModelDataCallback: function (callbackData) {
                self.modelArr = callbackData.Model;
            },
            modelValueChanged: function () {
                self.modelError = "";
            },
            getVehicleCheckInDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/vehiclecheckin/get', config)
                        .then(response => {
                            if (response.data.content.salesman_id != null) {
                                self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesman_id};
                            }
                            self.vin = response.data.content.vin;
                            self.year = response.data.content.year;
                            if (response.data.content.make != null) {
                                self.make = {text: response.data.content.makeName, value: response.data.content.make};
                            }
                            if (response.data.content.model != null) {
                                self.model = {text: response.data.content.modelName, value: response.data.content.model};
                            }
                            /* self.make = response.data.content.make;
                            self.model = response.data.content.model; */
                            self.runs = response.data.content.runs;
                            self.isAxleGiven = response.data.content.isAxleGiven;
                            self.isTorqueDown = response.data.content.isTorqueDown;
                            self.fuelLevel = response.data.content.fuelLevel;
                            self.isBrakeChamberLockRemoved = response.data.content.isBrakeChamberLockRemoved;
                            self.visualInspectionExterior = response.data.content.visualInspectionExterior;
                            self.isFrontTiresMatching = response.data.content.isFrontTiresMatching;
                            self.visualInspectionInterior = response.data.content.visualInspectionInterior;
                            self.note = response.data.content.note;
                            self.completed = (response.data.content.completed == "no") ? false : true;
                            if (response.data.content.completeById != null) {
                                self.completeById = {text: response.data.content.userCompleteByFName + ' ' + response.data.content.userCompleteByLName, value: response.data.content.completeById};
                            }
                            self.createdAt = response.data.content.createdAt;
                            self.attached_pin_plate = response.data.content.attached_pin_plate;
                            self.attached_specs_sticker = response.data.content.attached_specs_sticker;
                            self.attached_interior_exterior = response.data.content.attached_interior_exterior;
                            self.companyId = response.data.content.company_id;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                            self.buttonBack();
                        });
            },
            checkForm: function () {
                var checked = true;
                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.completed) {
                    if (self.completeById == null || !self.completeById.value) {
                        self.completeByIdError = "Please select user";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.vin) {
                    self.vinError = "Please enter Vin";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.year) {
                    self.yearError = "Please enter year";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.make == null || !self.make.value) {
                    self.makeError = "Please enter make";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.model == null || !self.model.value) {
                    self.modelError = "Please enter Model";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.fuelLevel) {
                    self.fuelLevelError = "Please enter Fuel Level";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addVehicleCheckIn: function () {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('vin', self.vin);
                        data.append('year', self.year);
                        data.append('make', self.make.value);
                        data.append('model', self.model.value);
                        data.append('is_axle_given', self.isAxleGiven);
                        data.append('runs', self.runs);
                        data.append('is_torque_down', self.isTorqueDown);
                        data.append('fuel_level', self.fuelLevel);
                        data.append('is_brake_chamber_lock_removed', self.isBrakeChamberLockRemoved);
                        data.append('visual_inspection_exterior', self.visualInspectionExterior);
                        data.append('is_front_tires_matching', self.isFrontTiresMatching);
                        data.append('visual_inspection_interior', self.visualInspectionInterior);
                        data.append('note', self.note);
                        data.append('completed', (self.completed) ? 'yes' : 'no');
                        data.append('complete_by_id', self.completeById.value);
                        data.append('created_at', self.createdAt);

                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/vehiclecheckin/add/update", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    /* console.log(err); */
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
            }
        }
    });
</script>