<template>
    <div class="modal fade" id="estimateDetailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <table class="table custom-table jsmartable">
                <tr>
                  <td id="cusId">{{ showDetail.cus_id }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td id="cusName">{{ showDetail.cus_name }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td id="billAdd1">{{ showDetail.bill_addr1 }}</td>
                  <td id="slsId">{{ showDetail.sls_id }}</td>
                </tr>
                <tr>
                  <td id="billAdd2">{{ showDetail.bill_addr2 }}</td>
                  <td id="dateCreated">{{ showDetail.created_at }}</td>
                </tr>
                <tr>
                  <td id="billCounty">{{ showDetail.bill_county }}</td>
                  <td id="statusDes">{{ showDetail.status_des }}</td>
                </tr>
                <tr>
                  <td>
                    <span id="billCity">{{ showDetail.bill_city }}</span>
                    <span id="billState">{{ showDetail.bill_state }}</span>
                    <span id="billPost">{{ showDetail.bill_post }}</span>
                  </td>
                  <td id="amtSubTotal">{{ showDetail.amt_subtotal }}</td>
                </tr>
                <tr>
                  <td id="des" colspan="2">{{ showDetail.status_name }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                      <table class="table">
                          <tr>
                              <td><span class="bold">ID</span></td>
                              <td><span class="bold">CONDITION</span></td>
                              <td><span class="bold">SUB TOTAL</span></td>
                              <td><span class="bold">OPSID</span></td>
                              <td><span class="bold">CAUSE</span></td>
                          </tr>
                          <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import eventBus from "../../../../event-bus";
  var estimateDetail;
  export default {
    name: "EstimateDetailModal",
    data() {
      return {
        showDetail: {}
      };
    },
    mounted() {
      estimateDetail = this;
    },
    methods: {
      closeModal() {
        eventBus.$emit("close-details", false, "estimate");
      },
      getRoDetail(data) {
        estimateDetail.showDetail = data;
      }
    }
  };
  
  eventBus.$on("show-details", value => {
    estimateDetail.getRoDetail(value);
  });
  </script>
  