<template>
    <div id="app">
        <template v-if="$route.path.indexOf('/ppa') == 0">
            <router-view></router-view>
        </template>
        <template v-else>
            <app-header v-if="showHeaderAndSidebar"></app-header>
            <div :id="$route.name != 'PageNotFound' ? 'wrapper' : ''" class="app-sidebar-right">
                <app-sidebar v-if="showHeaderAndSidebar"></app-sidebar>
                <router-view></router-view>
            </div>
        </template>
    </div>
</template>

<script>
    import "@babel/polyfill";
    import Header from '@/components/partials/Header';
    import Footer from '@/components/partials/Footer';
    import Sidebar from '@/components/partials/Sidebar';
    export default {
        components: {
            'app-header': Header,
            'app-footer': Footer,
            'app-sidebar': Sidebar,
        },
        name: 'App',
        data: () => ({
                showHeader: true,
                isRedirection: null,
            }),
        beforeMount: function () {
           
        },
        mounted: function () {
        },

        methods: {
            
        },
        computed: {
            showHeaderAndSidebar() {
                const excludedRoutes = [
                    'Login',
                    'PageNotFound'
                ];
                const excludedPaths = [
                    '/forgotpassword',
                    '/resetpassword',
                    '/pub/print',
                    '/pub/view',
                    '/pub/multi-print',
                    '/compare/inventory',
                    '/pub/print/promo',
                    '/email/promo/sheet',
                    '/quotation',
                    '/build-up',
                    '/conquest',
                    '/document/view'
                ];

                if (excludedRoutes.includes(this.$route.name)) {
                    return false;
                }

                return !excludedPaths.some(path => this.$route.path.indexOf(path) !== -1);
            }
        }
    }
</script>

<style></style>
