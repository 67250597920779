<template>
    <div class="spaced-container">
        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_add_role')">
                <div class="container-fluid xyz role-section">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->

                        <div :class="alertClass" id="success-alert">
                            <strong> {{alertMessage}} </strong>
                        </div>
                        <div class="forms-container add_user_forms_container vtc-dealer-forms role-form">
                            <div class="row no-margin">

                                <!--Name-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{nameError}}</span>
                                    </div>
                                </div>

                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.search}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input v-on:keyup.enter="searchPermission()" id="searchModule" v-model="searchModule" class="form-control" type="text" :placeholder="allStaticMessages.search_module" />
                                    </div>
                                </div>

                                <div class="col-12 forms-block">
                                    <span class="help is-danger">{{permissionError}}</span>
                                </div>

                                <div class="col-12 forms-block">
                                    <div class="col-12 paddingleft0 custom-checkbox-v">
                                        <label class="container">
                                            <input @change="inputCheckUncheckAll($event)" v-model="checkUncheckAll" class="checkbox" type="checkbox" id="checkUncheckAllPermission" name="checkUncheckAllPermission">
                                            <span class="checkmark"></span>
                                            <span class="pl-2">{{checkUncheckAllPermissionName}}</span>
                                        </label>
                                        <!--<span class='unclickable' onclick='return false;'>
                                            <label for="checkUncheckAllPermission"> </label><br>
                                        </span>-->
                                    </div>
                                </div>

                                <template v-for="(permission, index) in permissionsArray">
                                    <div :class="'col-12 allPermissions ' + permission[0].module_name">
                                        <div class="col-12 paddingleft0 custom-checkbox-v forms-block">
                                            <label class="roleLabel container">
                                                <input v-model="permissionModel" class="checkbox" type="checkbox" id="permissions" name="permissions" :value='index'>
                                                <span class="checkmark"></span>
                                                <span class="pl-2"><strong>{{index}}</strong></span>
                                            </label>
                                            <br/>
                                        </div>
                                        <div class="row col-12">
                                            <template v-for="(permissionName, index) in permission">
                                                <div :class="'col-4 forms-block custom-checkbox-v ' + permissionName.class">
                                                    <label class="container role-label">
                                                        <input v-model="modelPermissions" class="checkbox" type="checkbox" id="permissions" name="permissions" :value='permissionName.id'>
                                                        <span class="checkmark"></span>
                                                        <span class="pl-2">{{permissionName['display_name']}}</span>
                                                    </label>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <!--Error Alert-->
                            <div class="row clearfix" >
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="alert alert-danger" id="alertError">
                                        <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                        <strong id="errorMsg" class="error"></strong>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>

                            <!--Buttons-->
                            <div class="row clearfix"></div>
                            <div class="button-demo">
                                <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addRolePermissions ()">
                                <input v-show="showSaveAndContinue" id="addAndContinueBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addRolePermissions (flag='Save and Continue')">
                                <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                            </div>

                        </div>
                        <!-- Forms -->
                    </div>
                    <!--page content-->
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <app-footer></app-footer>
        </div>
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateRole',
        mixins: [commonMixins],
        data () {
            return {
                staticInputLabelMessages: "",
                searchModule : "",
                name : "",
                nameError : "",
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                permissionsArray : [],
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Role",
                permissionModel : [],
                modelPermissions : [],
                checkUncheckAllPermissionName : "Check All",
                checkUncheckAll : "",
                permissionError : "",
                firstLoad : false,
                permissionNameArray: ['can_add_role'],
            }
        },
        watch : {
            searchModule : function(value) {
                self.searchPermission();
            },
            checkUncheckAll : function(value) {
                let keys = Object.keys(self.permissionsArray);

                /* If the value is true then we reinitialize the permission model */
                if (value) {
                    self.permissionModel = [];
                    self.checkUncheckAllPermissionName = "Uncheck All";
                    /* We add again all the module in permissionModel and module's permission automaticaly
                    * added in modelPermission */
                    for (let j = 0; j < keys.length; j++) {
                        let putkey = keys[j];
                        for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                            let moduleName = self.permissionsArray[putkey][k]['display_module_name'];
                            if (!self.permissionModel.includes(moduleName)) {
                                self.permissionModel.push(moduleName);
                            }
                        }
                    }
                } else {
                    /* If the value is false then we need to check if any permission is check if all is uncheck
                    * then only we make permissionModel and modelPermission array blank*/
                    if (self.modelPermissions.length <= 0) {
                        self.permissionModel = [];
                        self.modelPermissions = [];
                    }
                    self.checkUncheckAllPermissionName = "Check All";
                }

            },
            permissionModel : function (newValue, oldValue) {
                let arrayDiff = self.arrayDiff(newValue, oldValue)[0];
                let keys = Object.keys(self.permissionsArray);

                /* If there is change in permissionModel and new and old values are not same then new value add in permissionModel */
                if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                    let newArrayLength = newValue.length;
                    let oldArrayLength = oldValue.length;
                    /* If newArrayLength is greater that means value is added and push it into permissionModel*/
                    if (newArrayLength > oldArrayLength) {
                        for (let i = 0; i < newValue.length; i++) {
                            for (let j = 0; j < keys.length; j++) {
                                if (newValue[i] == keys[j]) {
                                    let putkey = keys[j];
                                    for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                                        if (!self.modelPermissions.includes(self.permissionsArray[putkey][k]['id'])) {
                                            self.modelPermissions.push(self.permissionsArray[putkey][k]['id']);
                                        }
                                    }
                                }
                            }
                        }
                        /* Else value is removed and remove it from permissionModel*/
                    } else {
                        let value;
                        /* Check newArrayLength if it is greater than 0 then let newValue length  else old vale length (for loop)
                           * This is mainly for if all the checkbox is unchecked then we get new value 0 and that is length is 0 for loop
                         */
                        if (newArrayLength > 0) {
                            value = newValue;
                        } else {
                            value = oldValue;
                        }

                        for (let i = 0; i < value.length; i++) {
                            for (let j = 0; j < keys.length; j++) {
                                let putkey = keys[j];
                                if (arrayDiff == putkey) {
                                    for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                                        if (self.modelPermissions.includes(self.permissionsArray[putkey][k]['id']) && !self.permissionModel.includes(arrayDiff)) {
                                            let index = self.modelPermissions.indexOf(self.permissionsArray[putkey][k]['id'])
                                            if (index >= 0) {
                                                self.modelPermissions.splice( index, 1 );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* After above process finished we again check if all module is into the permissionModel or not
                * if it is then flag value remains true otherwise it becomes false*/
                let flag = true;
                for (let j = 0; j < keys.length; j++) {
                    let putkey = keys[j];
                    for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                        let moduleName = self.permissionsArray[putkey][k]['display_module_name'];

                        if (self.permissionModel.includes(moduleName)) {

                        } else {
                            flag = false;
                        }
                    }
                }

                /* If flag is true then we checked 'Check All' checkbox otherwise it is unchecked*/
                if (flag) {
                    self.checkUncheckAll = true;
                } else {
                    self.checkUncheckAll = false;
                }
                // console.log(JSON.stringify(self.permissionModel));
            },

            modelPermissions : function (newValue, oldValue) {
                // alert(newValue);
                // alert(oldValue);
                self.$nextTick(() => {
                    setTimeout(function() {


                        let arrayDiff = self.arrayDiff(newValue, oldValue)[0];
                        let keys = Object.keys(self.permissionsArray);

                        let newArrayLength = newValue.length;
                        let oldArrayLength = oldValue.length;

                        // if (!self.firstLoad) {
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            // Apply same logic as below add into permissionModel while update role display already assigned permission to the role
                            // self.permissionModel = [];
                            for (let j = 0; j < keys.length; j++) {
                                let putkey = keys[j];
                                for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                                    if (newArrayLength > 0) {
                                        for (let y = 0; y < newValue.length; y++) {
                                            if (newValue[y] == self.permissionsArray[putkey][k]['id']) {
                                                let moduleName = self.permissionsArray[putkey][k]['display_module_name'];

                                                let flag = true;

                                                for (let l = 0; l < self.permissionsArray[putkey].length; l++) {

                                                    if (self.modelPermissions.includes(self.permissionsArray[putkey][l]['id'])) {
                                                    } else {
                                                        flag = false;
                                                    }
                                                }

                                                if (flag) {
                                                    if (!self.permissionModel.includes(moduleName)) {
                                                        self.permissionModel.push(moduleName);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // self.firstLoad = true;
                        // }

                        /* If there is change in modelPermissions and new and old values are not same then new value add in modelPermissions */
                        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                            for (let j = 0; j < keys.length; j++) {
                                let putkey = keys[j];
                                for (let k = 0; k < self.permissionsArray[putkey].length; k++) {

                                    /* If newArrayLength is greater that means value is added and push it into modelPermissions*/
                                    if (newArrayLength > oldArrayLength) {

                                        /* Here arrayDiff gives the added value and we match it with the all permission id */
                                        if (arrayDiff == self.permissionsArray[putkey][k]['id']) {

                                            /* if the id is match then we let the module name */
                                            let moduleName = self.permissionsArray[putkey][k]['display_module_name'];

                                            let flag = true;
                                            /* Here we check the modelPermissions with main permission array */
                                            /*  The flag value initialy true and check if all permissions of that module is already
                                            * added in modelPermissions or not if any one is missing then flag value becomes false
                                            * otherwise it will remian true*/

                                            for (let l = 0; l < self.permissionsArray[putkey].length; l++) {
                                                if (self.modelPermissions.includes(self.permissionsArray[putkey][l]['id'])) {

                                                } else {
                                                    flag = false;
                                                }
                                            }

                                            /* if flag value is remains true that means all the permission od that module is already added
                                            * and add that module added into permisionModel */
                                            if (flag) {
                                                self.permissionModel.push(moduleName);
                                            }
                                        }
                                        /* Else value is removed and remove it from modelPermissions*/
                                    } else {
                                        for (let j = 0; j < keys.length; j++) {
                                            let putkey = keys[j];
                                            for (let k = 0; k < self.permissionsArray[putkey].length; k++) {
                                                /* Here we get arrayDiff value as removed value and we remove it from permissionModel*/
                                                if (arrayDiff == self.permissionsArray[putkey][k]['id']) {
                                                    if (self.permissionModel.includes(self.permissionsArray[putkey][k]['display_module_name'])) {
                                                        let index = self.permissionModel.indexOf(self.permissionsArray[putkey][k]['display_module_name'])
                                                        if (index >= 0) {
                                                            self.permissionModel.splice( index, 1 );
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }

                                }
                            }

                        }
                    }, 500);
                });
                // console.log(JSON.stringify(self.modelPermissions));
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('role-list');
            EventBus.$emit("menu-selection-changed", "role-list");
            self.staticInputLabelMessages = self.allLabelMessages;
            $("#alertError").hide();
            self.getAllPermissions();

            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getRoleDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Role";
            }

            self.getLoginIdPermissions();
        },
        methods: {
            /* jQuery Code Start */
            searchPermission : function () {
                if(self.searchModule.length > 0){
                    $('.allPermissions').removeClass('show');
                    $('.allPermissions').addClass('hide');
                    self.$nextTick(() => {
                        $.each(self.permissionsArray, function (i) {
                            if(self.permissionsArray[i][0].display_module_name.toLowerCase().indexOf(self.searchModule.toLowerCase()) != -1){
                                /* console.log(self.permissionsArray[i][0].display_module_name);
                                console.log(self.permissionsArray[i][0].module_name); */
                                $('.' + self.permissionsArray[i][0].module_name).removeClass('hide');
                                $('.' + self.permissionsArray[i][0].module_name).addClass('show');
                            }
                        });
                    });
                }else{
                    $('.allPermissions').addClass('show');
                    $('.allPermissions').removeClass('hide');
                }
                /* console.log(self.searchModule); */
            },
            inputCheckUncheckAll : function (e) {
                let flag = e.target.checked;
                if (!flag) {
                    self.permissionModel = [];
                    self.modelPermissions = [];
                }

            },
            /* jQuery Code End */
            arrayDiff : function (a1, a2) {
                var a = [], diff = [];
                for (var i = 0; i < a1.length; i++) {
                    a[a1[i]] = true;
                }
                for (var i = 0; i < a2.length; i++) {
                    if (a[a2[i]]) {
                        delete a[a2[i]];
                    } else {
                        a[a2[i]] = true;
                    }
                }
                for (var k in a) {
                    diff.push(k);
                }

                return diff;
            },
            buttonBack() {
                self.$router.push({ name: 'ListRole'});
            },

            clearDataOnSaveAndContinue : function () {
                self.name = '';
                self.permissionModel = [];
                self.modelPermissions = [];
            },

            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },

            getRoleDetailsById : function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        role_id : self.$route.params.id,
                    }
                };

                HTTP.get('/role/get', config).
                then(response => {
                    self.name = response.data.role.name;

                    if (response.data.permissions.length > 0) {
                        setTimeout(function () {
                            for (let i = 0; i < response.data.permissions.length; i++) {
                                self.modelPermissions.push(response.data.permissions[i].id);
                            }
                        }, 200);
                    }
                })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },

            getAllPermissions: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },

                };

                HTTP.get('/permission/get', config).
                then(response => {
                    self.$nextTick(() => {
                        self.permissionsArray = response.data.content;
                    });
                })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },

            checkForm: function () {
                var checked = true;

                if (!self.name) {
                    self.nameError = "Please enter role name";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.name && self.modelPermissions.length <= 0) {
                    self.permissionError = "Please select atleast one permission";
                    if (checked) {
                        checked = false;
                    }
                }

                return checked;
            },

            addRolePermissions (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        let permissionArray = JSON.stringify(self.modelPermissions);
                        var data = new FormData();
                        data.append('name',self.name);
                        data.append('permissions',permissionArray);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id',self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/role/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i];
                                        }
                                        self.showSAlert(err,'success',false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (err.response.status == 401) {
                                    self.showSAlert(err.response.data.message,'error',false);
                                    self.buttonBack();
                                }
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.showSAlert(err.response.data.message,'success',false);
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.showSAlert(err.response.data.message,'success',false);
                                    }

                                    if (err.response.status == 0) {
                                        self.errorMsg = "Remote server can not be reachable";
                                    } else {
                                        //redirect to login page if user not authenticated
                                        if (err.response.status == 401) {
                                            //clear previous data
                                            localStorage.removeItem("user_obj");
                                            localStorage.removeItem("api_token");
                                            localStorage.removeItem("profile_image");
                                            self.$router.push("/login");
                                        }
                                    }
                                }
                            });
                    }
                }, 200);
            },
        }
    }

    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>

<style type='text/css'>
    a span.unclickable  { text-decoration: none; }
    a span.unclickable:hover { cursor: default; }
    .role-section .role-label {
      display: inline-block !important;
    }
    .role-section .custom-checkbox-v input,
    .role-section .custom-checkbox-v .checkmark {
      top: 0;
    }
    .role-section .custom-checkbox-v label {
      /* display: unset !important; */
      margin-left: 8px;
      position: relative;
    }
    .role-section .custom-checkbox-v .pl-2 {
      float: none !important;
    }
</style>
