<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <th class="">{{staticInputLabelMessages.managed_by_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('managedByIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.acquire_by_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('acquireByIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.acquire_from_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('acquireFromIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sold_by_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('soldByInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sold_to_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('soldToInput')"></i></th>
                                <!--<th class="">{{staticInputLabelMessages.floor_plan_vendor}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('floorPlanVendorIdInput')"></i></th>-->
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Managed By-->
                                <td class="">
                                    <v-select style="min-width: 120px;"
                                            v-model="managedByIdInput[asset.asset_id]"
                                            @change="changedValue('managed_by')"
                                            @input="managedByErrorMsg[asset.asset_id] = ''"
                                            @search="onSearchManagedBy"
                                            name="customPerPage"
                                            id="customPerPag"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="managedByArrInput"
                                            :placeholder="staticMessages.managed_by_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isManagedByRecordNotFound">
                                            {{staticMessages.managed_by_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{managedByErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Acquired By-->
                                <td class="">
                                    <v-select style="min-width: 120px;" v-model="acquireByIdInput[asset.asset_id]"
                                              @change="changedValue('acquire_by')"
                                              @input="acquireByErrorMsg[asset.asset_id] = ''"
                                              @search="onSearchAcquireBy"
                                              name="customPerPage"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="acquireByArrInput"
                                              :placeholder="staticMessages.acquire_by_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isAcquireByRecordNotFound">
                                            {{staticMessages.acquire_by_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{acquireByErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Acquired From-->
                                <td class="">
                                    <v-select style="min-width: 120px;" v-model="acquireFromIdInput[asset.asset_id]"
                                              @change="changedValue('acquire_form ' + asset.asset_id)"
                                              @search="onSearchAcquireFrom"
                                              name="customPerPage"
                                              id="customPerPag"
                                              @input="acquireFromErrorMsg[asset.asset_id] = ''"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="acquireFromArrInput"
                                              :placeholder="staticMessages.acquire_from_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isAcquireFromRecordNotFound">
                                            {{staticMessages.acquire_from_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{acquireFromErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sold By-->
                                <td class="">
                                    <v-select style="min-width: 120px;" v-model="soldByInput[asset.asset_id]"
                                              @change="changedValue('sold_by ' + asset.asset_id)"
                                              @search="onSearchSoldBy"
                                              @input="soldByErrorMsg[asset.asset_id] = ''"
                                              name="customPerPage"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="soldByArrInput"
                                              :placeholder="staticMessages.managed_by_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isSoldByRecordNotFound">
                                            {{staticMessages.managed_by_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{soldByErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sold To-->
                                <td class="">
                                    <v-select style="min-width: 135px;" v-model="soldToInput[asset.asset_id]"
                                              @change="changedValue('sold_to ' + asset.asset_id)"
                                              @search="onSearchSoldTo"
                                              @input="soldToErrorMsg[asset.asset_id] = ''"
                                              name="customPerPage"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="soldToArrInput"
                                              :placeholder="staticMessages.acquire_from_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isSoldToRecordNotFound">
                                            {{staticMessages.acquire_from_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{soldToErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Floor Plan Vendor-->
                                <!--<td class="">
                                    <v-select style="min-width: 195px;" v-model="floorPlanVendorIdInput[asset.asset_id]"
                                              @change="changedValue('floor_plan_vendor ' + asset.asset_id)"
                                              @search="onSearchFloorPlanVendor"
                                              name="customPerPage"
                                              @input="floorPlanVendorErrorMsg[asset.asset_id] = ''"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="floorPlanVendorArrInput"
                                              :placeholder="staticMessages.select_floor_plan_vendor">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isFloorPlanVendorRecordNotFound">
                                            {{staticMessages.floor_plan_vendor_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    &lt;!&ndash;<div class="requiredstar">*</div>&ndash;&gt;
                                    <span class="help is-danger">{{floorPlanVendorErrorMsg[asset.asset_id]}}</span>
                                </td>-->

                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                managedByIdInput : [],
                managedByErrorMsg : [],
                managedByArrInput : [],
                isManagedByRecordNotFound : false,

                acquireByIdInput : [],
                isAcquireByRecordNotFound : false,
                acquireByArrInput : [],
                acquireByErrorMsg : [],

                acquireFromIdInput : [],
                acquireFromErrorMsg : [],
                acquireFromArrInput : [],
                isAcquireFromRecordNotFound : false,

                soldByInput : [],
                soldByArrInput : [],
                soldByErrorMsg : [],
                isSoldByRecordNotFound : false,

                soldToInput : [],
                soldToArrInput : [],
                isSoldToRecordNotFound : false,
                soldToErrorMsg : [],

                floorPlanVendorErrorMsg : [],
                floorPlanVendorIdInput : [],
                floorPlanVendorArrInput : [],
                isFloorPlanVendorRecordNotFound : false,

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_add_inventory_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        if (self.asset.content[i].managed_by_id != null && typeof self.asset.content[i].managed_by_id != "undefined" && self.asset.content[i].managed_by_id != '') {
                            self.managedByIdInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].managed_by_name, value: self.asset.content[i].managed_by_id};
                        }
                        if (self.asset.content[i].acquire_by_id != null && typeof self.asset.content[i].acquire_by_id != "undefined" && self.asset.content[i].acquire_by_id != '') {
                            self.acquireByIdInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].acquire_by_name, value: self.asset.content[i].acquire_by_id};
                        }
                        if (self.asset.content[i].acquire_from_id != null && typeof self.asset.content[i].acquire_from_id != "undefined" && self.asset.content[i].acquire_from_id != '') {
                            self.acquireFromIdInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].acquire_from_name, value: self.asset.content[i].acquire_from_id};
                        }
                        if (self.asset.content[i].sold_by_id != null && typeof self.asset.content[i].sold_by_id != "undefined" && self.asset.content[i].sold_by_id != '') {
                            self.soldByInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].sold_by_name, value: self.asset.content[i].sold_by_id};
                        }
                        if (self.asset.content[i].sold_to_id != null && typeof self.asset.content[i].sold_to_id != "undefined" && self.asset.content[i].sold_to_id != '') {
                            self.soldToInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].sold_to_name, value: self.asset.content[i].sold_to_id};
                        }

                    }
                });
            /* self.loadAllData('WarrantyType', false, self.loadWarrantyTypeCallback, "undefined", ''); */

            self.loadAllData(['User', 'Contact', 'Vendor'], true, self.loadMasterDataCallback, "undefined", '');
            // self.loadAllData('User', true, self.loadMountUsersOption, "undefined", '');
            /* self.loadAllData('User', true, self.loadManagedByCallback, "undefined", '');
            self.loadAllData('User', true, self.loadAcquireByCallback, "undefined", '');
            self.loadAllData('User', true, self.loadSoldByCallback, "undefined", ''); */

            // self.loadAllData('Contact', true, self.loadMountContactOption, "undefined", '');
            /* self.loadAllData('Contact', true, self.loadAcquireFromCallback, "undefined", '');
            self.loadAllData('Contact', true, self.loadSoldToCallback, "undefined", ''); */
            
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'managedByIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.managedByIdInput[self.asset.content[i].asset_id];
                                    self.managedByIdInput = [];
                                } 
                                self.managedByIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'acquireByIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.acquireByIdInput[self.asset.content[i].asset_id];
                                    self.acquireByIdInput = [];
                                } 
                                self.acquireByIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'acquireFromIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.acquireFromIdInput[self.asset.content[i].asset_id];
                                    self.acquireFromIdInput = [];
                                } 
                                self.acquireFromIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'soldByInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.soldByInput[self.asset.content[i].asset_id];
                                    self.soldByInput = [];
                                } 
                                self.soldByInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'soldToInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.soldToInput[self.asset.content[i].asset_id];
                                    self.soldToInput = [];
                                } 
                                self.soldToInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'floorPlanVendorIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.floorPlanVendorIdInput[self.asset.content[i].asset_id];
                                    self.floorPlanVendorIdInput = [];
                                } 
                                self.floorPlanVendorIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            onSearchFloorPlanVendor : function () {

            },
            onSearchSoldTo : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadSoldToCallback, loading, search);
                }
            },
            loadSoldToCallback : function (callbackData) {
                self.isSoldToRecordNotFound = false;
                self.soldToArrInput = callbackData.Contact;
                if(self.soldToArrInput.length == 0) {
                    self.isSoldToRecordNotFound = true;
                }
            },
            onSearchSoldBy : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSoldByCallback, loading, search);
                }
            },
            loadSoldByCallback : function (callbackData) {
                self.isSoldByRecordNotFound = false;
                self.soldByArrInput = callbackData.User;
                if(self.soldByArrInput.length == 0) {
                    self.isSoldByRecordNotFound = true;
                }
            },
            onSearchAcquireFrom(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isAcquireFromRecordNotFound = false;
                self.acquireFromArrInput = callbackData.Vendor;
                if(self.acquireFromArrInput.length == 0) {
                    self.isAcquireFromRecordNotFound = true;
                }
            },
            onSearchAcquireBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadAcquireByCallback, loading, search);
                }
            },
            loadAcquireByCallback: function (callbackData) {
                self.isAcquireByRecordNotFound = false;
                self.acquireByArrInput = callbackData.User;
                if(self.acquireByArrInput.length == 0) {
                    self.isAcquireByRecordNotFound = true;
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isAcquireByRecordNotFound = self.isManagedByRecordNotFound = self.isSoldByRecordNotFound = self.isAcquireFromRecordNotFound = self.isSoldToRecordNotFound = false;
                self.managedByArrInput = self.acquireByArrInput = self.soldByArrInput = callbackData.User;
                if(self.managedByArrInput.length == 0) {
                    self.isManagedByRecordNotFound = self.isAcquireByRecordNotFound = self.isSoldByRecordNotFound = true;
                }
                self.acquireFromArrInput = callbackData.Vendor;
                self.soldToArrInput = callbackData.Contact;
                if(self.acquireFromArrInput.length == 0) {
                    self.isAcquireFromRecordNotFound = self.isSoldToRecordNotFound = true;
                }
            },
            /* loadMountUsersOption: function (callbackData) {
                self.isAcquireByRecordNotFound = self.isManagedByRecordNotFound = self.isSoldByRecordNotFound = false;
                self.managedByArrInput = self.acquireByArrInput = self.soldByArrInput = callbackData;
                if(self.managedByArrInput.length == 0) {
                    self.isManagedByRecordNotFound = self.isAcquireByRecordNotFound = self.isSoldByRecordNotFound = true;
                }
            },
            loadMountContactOption: function (callbackData) {
                self.isAcquireFromRecordNotFound = self.isSoldToRecordNotFound = false;
                self.acquireFromArrInput = self.soldToArrInput = callbackData;
                if(self.acquireFromArrInput.length == 0) {
                    self.isAcquireFromRecordNotFound = self.isSoldToRecordNotFound = true;
                }
            }, */
            onSearchManagedBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadManagedByCallback, loading, search);
                }
            },
            loadManagedByCallback: function (callbackData) {
                self.isManagedByRecordNotFound = false;
                self.managedByArrInput = callbackData.User;
                if(self.managedByArrInput.length == 0) {
                    self.isManagedByRecordNotFound = true;
                }
            },
            changeActivityDateTime: function () {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.warrantyServiceDateErrorMsg, i, "");
                    }
                });
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArrInput = callbackData;
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'managed_by_id' : self.managedByIdInput[self.asset.content[i].asset_id],
                            'acquire_by_id' : self.acquireByIdInput[self.asset.content[i].asset_id],
                            'acquire_from_id' : self.acquireFromIdInput[self.asset.content[i].asset_id],
                            'sold_by_id' : self.soldByInput[self.asset.content[i].asset_id],
                            'sold_to_id' : self.soldToInput[self.asset.content[i].asset_id],
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },


            changedValue: function (value) {
                let stringValue = value;
                stringValue = stringValue.split(" ");
                let flag = stringValue[0];
                let id = stringValue[1];
                $.each(self.assetIdInput, function (i) {
                    switch (value) {
                        case "managed_by" :
                            if (i == id) {
                                Vue.set(self.managedByErrorMsg, i, "");
                            }
                            break;
                        case "acquire_by" :
                            if (i == id) {
                                Vue.set(self.acquireByErrorMsg, i, "");
                            }
                            break;
                        case "acquire_form" :
                            if (i == id) {
                                Vue.set(self.acquireFromErrorMsg, i, "");
                            }
                            break;
                        case "sold_by" :
                            if (i == id) {
                                Vue.set(self.soldByErrorMsg, i, "");
                            }
                            break;
                        case "sold_to" :
                            if (i == id) {
                                Vue.set(self.soldByErrorMsg, i, "");
                            }
                            break;
                        case "floor_plan_vendor" :
                            if (i == id) {
                                Vue.set(self.floorPlanVendorErrorMsg, i, "");
                            }
                            break;
                    };
                });
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                /* $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null){
                            if (typeof(self.acquireFromIdInput[i])  == 'undefined' || self.acquireFromIdInput[i] == null || self.acquireFromIdInput[i].value == '') {
                                Vue.set(self.acquireFromErrorMsg, i, "Please select acquire from");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (typeof(self.soldByInput[i])  == 'undefined' || self.soldByInput[i] == null || self.soldByInput[i].value == '') {
                                Vue.set(self.soldByErrorMsg, i, "Please select sold by");
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                }); */
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/people", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 8);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;

                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>