<template>
    <div class="loginbg" style="display: none;" id="login-main-hr">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>This is Test Environment !</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="static/images/login-logo.png" alt="Velocity Vehicle Group">
                        </div>
                        <form>
                            <div class="form-group">
                                <!-- <span class="help is-danger">Note :- <br/>Please, Follow the steps of registration in HR Portal if you have not follow the registration steps.</span> -->
                                <label v-if="isEmail == 'undefined'" for="exampleInputEmail1">Enter Dayforce ID</label>
                                <label v-else-if="isEmail == true" for="exampleInputEmail1">Email</label>
                                <label v-else for="exampleInputEmail1">Dayforce ID</label>
                                <input v-model="username" type="text" name="username" class="form-control" id="username_login" placeholder="Enter Dayforce ID">
                                <span class="help is-danger">{{username_error_msg}}</span>
                            </div>

                            <!-- <div class="form-check not-registered"> -->
                            <!-- <input type="checkbox">
                            <span class="checkbox-placeholder"></span>
                            Remember me -->
                            <!-- <a :href="not_registered_url" target="_blank">Not Registered?</a> -->
                            <!-- </div> -->
                            <div class="help is-danger">{{error_msg_login}}</div>
                            <div>
                                <button :disabled="disableButtons.loginButton" id="loginbtn" type="submit" class="btn btn-primary" v-on:click.prevent="submitRequest(this)">{{buttonNames.loginButton}}</button>
                                <!-- <a style="float: left;" :href="not_registered_url" target="_blank">Not Registered?</a> 
                                <a :href="forgot_pass_url" target="_blank">Forgot password?</a> --> 
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Vue from 'vue';
    import {HTTP} from '../../http-common';
    import EventBus from '.././event-bus';
    import {commonMixins} from '../../mixins/commonMixins';
    var self;

    export default {
        name: 'Login',
        mixins: [commonMixins],
        data() {
            return {
                isEmail: "undefined",
                username: "",
                password: "",
                not_registered_url: "",
                username_error_msg: "",
                password_error_msg: "",
                forgot_pass_url: "",
                buttonNames: {
                    loginButton: 'Submit',
                },
                disableButtons: {
                    loginButton: false,
                },
                error_msg_login: "",
            }
        },
        watch: {
            username: function (username) {
                if (username == '') {
                    self.isEmail = 'undefined';
                } else {
                    self.isEmail = self.validEmail(username);
                }
            },
        },
        created: function () {
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            HTTP.get('/buildversion', {})
                .then(response => {
                    /* Build version checking start*/
                    let current_build = window.localStorage.getItem("build_version");
                    let latest_build = response.data.content.build;
                    if(current_build == null || current_build == 'null') {
                        localStorage.setItem("build_version", response.data.content.build);
                    } else if (current_build != undefined && current_build == '') {
                        localStorage.setItem("build_version", response.data.content.build);
                    } else if(current_build != latest_build){
                        localStorage.setItem("build_version", response.data.content.build);
                        location.reload(true);
                    }
                    /* Build version checking end*/
                    
                })
                .catch(function (err) {
                    self.catchHandler(err, function () {});
                });
            self.$nextTick(() => {
                self.checkLocalStorage();
            });
        },
        methods: {
            submitRequest: function () {
                var error = false;
                self.username_error_msg = '';
                self.password_error_msg = '';

                if (!self.username || self.username == null || self.username == "") {
                    self.username_error_msg = "The Dayforce ID field is required.";
                    error = true;
                }

                if (!error) {
                    self.buttonNames.loginButton = "Submitting...";
                    self.disableButtons.loginButton = true;
                    /*$('#loginbtn').text("Login...");
                     $("#loginbtn").attr("disabled", true);*/

                    var fields = {
                        email: self.username,
                    };

                    HTTP.post("submitforgotlink", fields)
                            .then(function (data) {
                                self.buttonNames.loginButton = "Submit";
                                self.disableButtons.loginButton = false;
                                /*$('#loginbtn').text("Login");
                                 $("#loginbtn").attr("disabled", false);*/

                                if (data.status == 200) {
                                    self.error_msg_login = data.data.message;
                                    // window.location.href = "/inventory/list";
                                }
                                if (data.status == 201) {
                                    self.error_msg_login = data.data.content;
                                }
                            })
                            .catch(function (error) {
                                self.error_msg_login = "";
                                self.buttonNames.loginButton = "Submit";
                                self.disableButtons.loginButton = false;
                                /*$('#loginbtn').text("Login");
                                 $("#loginbtn").attr("disabled", false);*/
                                if (error.response.status == 0) {
                                    self.error_msg_login = "Remote server can not be reachable";
                                } else {
                                    if (error.response.data.message) {
                                        self.error_msg_login = error.response.data.message;
                                    }
                                }
                            })
                }
            },
            checkLocalStorage: function () {
                if (localStorage.getItem('user_obj')) {
                    var userObj = localStorage.getItem('user_obj');
                    if (userObj != null && userObj !== "") {
                        self.$router.push('/inventory/list');
                    } else {
                        self.$router.push('/');
                    }
                } else {
                    self.forgot_pass_url = process.env.VUE_APP_HR_URL + "?identify=" + window.location.href;
                    self.not_registered_url = process.env.VUE_APP_NOT_REGISTERED_HR_URL + "?identify=" + window.location.href;
                    /* $('#main-header').hide();
                     $('#sidebar-wrapper').hide();
                     $("#wrapper").addClass("login-div-full");
                     $(".loginbg").show(); */

                    $('.navbar').hide();
                    // $('.admin-footer').hide();
                    $('#sidebar-wrapper').hide();
                    if (window.location.pathname == "/forgotpassword") {
                        $(".app-sidebar-right").css("padding-left", "0px");
                        $(".app-sidebar-right").css("margin-top", "0px");
                        $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
                        $(".loginbg").show();
                    }
                }
            },
        }
    }
</script>