<template>
    <div
      :class="{ 'modal fade show': isSaveTemplate }"
      id="savePrintDocTemplate"
      tabindex="-1"
      role="dialog"
    >
      <div v-if="isSaveTemplate" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div id="print-doc-template-modal-header-title">
              {{ methodType }} Vehicle Template Modal
            </div>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-md-2">
                    <label for="name">Name:</label>
                </div>
                <div class="col-md-10">
                    <div id="save-print-doc-template-name">
                        <input type="text" @input="nameError=''" v-model="name">
                        <span>{{ nameError }}</span>
                    </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="po-ro-save-button" @click="saveUpdateTemplate">{{ methodType }}</button>
            <button type="button" class="po-ro-close-button" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  import eventBus from "../../../event-bus";
  import { HTTP } from "../../../../http-common";
  
  export default {
    name: "SavePrintDocTemplateModal",
    props: ["templateCheckedList","isSaveTemplate","currentTemplate","templateMethod"],
    data() {
      return {
        name: "",
        nameError: "",
        isUpdate: false,
        methodType: "Save",
      };
    },
    computed: {
      backdropClass() {
        return this.isSaveTemplate ? "custom-backdrop-class" : "";
      },
    },
    methods: {
      closeModal() {
        this.name = "";
        eventBus.$emit("close-print-doc-save-template", false);
      },
      saveUpdateTemplate() {
        if(this.isValidForm()) {
            let data = {
                name: this.name,
                data: this.templateCheckedList,
                id: (this.templateMethod=="Update" && this.isUpdate) ? this.currentTemplate.id : null
            };
          
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
            };
            HTTP.post("deal/printdoc/template/store", data, config)
                .then((response) => {
                    if(response.data.status=="success") {
                        this.name = "";
                        eventBus.$emit("close-print-doc-save-template", false, response.data.content);
                    }
                })
                .catch(function (err) {
                    console.log("error=>", err);
                });
        }
      },
  
      isValidForm() {
        if (!this.name) {
          this.nameError = "This field is required!";
          return false;
        }

        this.nameError = '';
        return true;
      },
    },
    watch: {
      isSaveTemplate(status) {
        if(status) {
          this.methodType = this.templateMethod;
          this.$nextTick(() => {
            const backdrop = document.querySelector('.modal-backdrop.custom-backdrop-class');
            if (backdrop) {
              document.body.classList.remove('modal-open');
              backdrop.remove();
            }
            if(this.currentTemplate !== null && this.templateMethod=="Update") {
              this.isUpdate = true;
              this.name = this.currentTemplate.name;
            }
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
    #print-doc-template-modal-header-title {
      color: #506b71;
      font-family: Verdana, Arial, Helvetica, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    #save-print-doc-template-name > input {
      width: 100%;
    }

    #save-print-doc-template-name {
      color: #ff0000;
      font-weight: lighter;
    }
  </style>
  