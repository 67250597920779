<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_calls_and_emails')">  
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <template v-if="isEditable == 1">
                        <!--page content-->
                        <div class="admin-page-content">
                            <!--Forms-->

                            <div :class="alertClass" id="success-alert">
                                <strong> {{alertMessage}} </strong>
                            </div>
                            <div class="forms-container add_user_forms_container vtc-dealer-forms">
                                <div class="row no-margin">
                                    <!--Customer -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select 
                                                v-model="customerId"
                                                @change="changedValue"
                                                @input="customerError = ''"
                                                name="customerId"
                                                id="customerId"
                                                label="text"
                                                @search="onSearchCustomer"
                                                :clearable="false"
                                                :searchable="true"
                                                :filterable="false"
                                                :options="customerArr"
                                                :placeholder="staticMessages.customer_place_holder">
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{customerError}}</span>
                                        </div>
                                    </div>
                                    <!-- Asset -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                v-model="assetId"
                                                name="assetId"
                                                id="assetId"
                                                label="text"
                                                @change="changedValue"
                                                @input="assetIdError = ''"
                                                :filterable="false"
                                                :options="assetIdArr"
                                                @search="onSearchAsset"
                                                :placeholder="staticMessages.asset_place_holder">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isAssetRecordNotFound">
                                                        {{staticMessages.asset_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{staticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                        </div>
                                    </div>
                                    <!-- Priority -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                v-model="priority"
                                                name="priority"
                                                @input="priorityError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="priorityArr"
                                                :clearable="false"
                                                :placeholder="staticMessages.select_priority">
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{priorityError}}</span>
                                        </div>
                                    </div>
                                    <!-- Direction -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.direction_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                v-model="direction"
                                                name="direction"
                                                @input="directionError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="directionArr"
                                                :clearable="false"
                                                :placeholder="staticMessages.direction_place_holder">
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{priorityError}}</span>
                                        </div>
                                    </div>
                                    <!-- Type -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.type_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                v-model="type"
                                                name="type"
                                                @input="typeError = ''"
                                                label="text"
                                                :filterable="false"
                                                :options="typeArr"
                                                :clearable="false"
                                                :placeholder="staticMessages.activity_type_place_holder">
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{typeError}}</span>
                                        </div>
                                    </div>
                                    <!-- Subject -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <input @input="subjectError = ''" id="subject" v-model="subject" class="form-control" type="text"/>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{subjectError}}</span>
                                        </div>
                                    </div>
                                    <!-- Call DateTime -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.call_date_time_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <date-picker
                                                v-model="callDate"
                                                :config="options"
                                                class="form-control"
                                                :placeholder="staticMessages.call_date_time_place_holder"
                                                name="callDate"
                                                @input="changeCallDateTime()"
                                                >
                                            </date-picker>
                                            <div class="requiredstar">*</div>
                                            <span class="help is-danger">{{callDateError}}</span>
                                        </div>
                                    </div>
                                    <!-- Notes -->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">{{staticInputLabelMessages.additional_comments_label}}</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <textarea id="notes" v-model="notes" class="form-control" type="text"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <!--Error Alert-->
                                <div class="row clearfix" >
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="alert alert-danger" id="alertError">
                                            <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                            <strong id="errorMsg" class="error"></strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                <!--Buttons-->
                                <div class="row clearfix"></div>
                                <div class="button-demo">
                                    <input id="addCallEmailBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addCallEmail()">
                                    <input id="addAndContinueCallEmailBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addCallEmail(flag='Save and Continue')">
                                    <input id="cancelCallEmail" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                                </div>
                            </div>
                            <!-- Forms -->
                        </div>
                        <!--page content-->
                     </template>
                     <template v-else>
                        <no-access></no-access>
                    </template>
                </div>
        </template>
        <template v-else>
                <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateActivity',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                customerId: "",
                customerArr: [],
                assetId: "",
                assetIdArr: [],
                isAssetRecordNotFound: false,
                priority: {value: "medium", text: "Medium"},
                priorityArr: [
                    {
                        value: "high",
                        text: "High"
                    },
                    {
                        value: "medium",
                        text: "Medium"
                    },
                    {
                        value: "low",
                        text: "Low"
                    }
                ],
                direction: {value: "outgoing", text: "Outgoing"},
                directionArr: [
                    {
                        value: "incoming",
                        text: "Incoming"
                    },
                    {
                        value: "outgoing",
                        text: "Outgoing"
                    }
                ],
                type: {value: "call", text: "Call"},
                typeArr: [
                    {
                        value: "call",
                        text: "Call"
                    },
                    {
                        value: "email",
                        text: "E-Mail"
                    }
                ],
                subject: "",
                callDate: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },
                notes: "",
                isEditable : 1,
                customerError: "",
                assetIdError: "",
                priorityError: "",
                directionError: "",
                typeError: "",
                subjectError: "",
                callDateError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Calls/Emails",
                permissionNameArray : ['can_add_calls_and_emails'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('calls-email-list');
            EventBus.$emit("menu-selection-changed", "calls-email-list");
            self.loadAllData(['Contact'], true, self.loadContactCallback, "undefined", '', 0, 0, 1);

            userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.userId = {text: userJson.first_name + " " + userJson.last_name, value: userJson.id};
            }

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getCallEmailDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueCallEmailBtn").hide();
                self.pageTitle = "Edit Calls/Emails";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/call/email/list');
            },
            loadContactCallback: function (callbackData) {
                self.customerArr = callbackData.Contact;
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search);
                }
            },
            onSearchAsset(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetCallback, loading, search);
                }
            },
            loadAssetCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetIdArr = callbackData.Asset;
                if(self.assetIdArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            changeCallDateTime: function () {
                self.callDateError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.customerId = "";
                self.assetId = "";
                self.priority = {value: "medium", text: "Medium"};
                self.direction = {value: "outgoing", text: "Outgoing"};
                self.type = {value: "call", text: "Call"};
                self.subject = "";
                self.callDate = "";
                self.notes = "";
            },
            getCallEmailDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/call/email/get', config)
                    .then(response => {
                        self.customerId = {text: response.data.content.custName, value: response.data.content.contact_id};
                        if(response.data.content.asset_id != null) {
                            self.assetId = {text: response.data.content.vinNo, value: response.data.content.asset_id};
                        }
                        self.priority = {text: self.formatString(response.data.content.priority), value: response.data.content.priority};
                        self.direction = {text: self.formatString(response.data.content.direction), value: response.data.content.direction};
                        self.type = {text: self.formatString(response.data.content.call_type), value: response.data.content.call_type};
                        if(response.data.content.call_type == 'email') {
                            self.type = {text: 'E-Mail', value: response.data.content.call_type};
                        }
                        self.subject = response.data.content.subject;
                        self.callDate = response.data.content.call_date_time;
                        self.notes = response.data.content.notes;
                        self.isEditable = response.data.content.is_editable;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.customerId == null || !self.customerId.value) {
                    self.customerError = "Please select Customer";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.subject) {
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.callDate) {
                    self.callDateError = "Please Select Call Date & Time";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addCallEmail (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var assetId = "";
                        if (self.assetId == null || !self.assetId.value) {
                            assetId = "NULL";
                        } else {
                            assetId = self.assetId.value;
                        }
                        var data = new FormData();
                        data.append('customer_id', self.customerId.value);
                        data.append('asset_id', assetId);
                        data.append('priority', self.priority.value);
                        data.append('direction', self.direction.value);
                        data.append('type', self.type.value);
                        data.append('subject', self.subject);
                        data.append('call_date_time', self.callDate);
                        data.append('notes', self.notes);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/call/email/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>