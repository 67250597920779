<template>
  <div class="card">
    <div class="card-header">
      <h2 class="mb-0 float-left">
        <button
          class="btn btn-link btn-block text-left"
          type="button"
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          Vehicle PO's
        </button>
      </h2>
      <p class="text center">
        <input
          type="checkbox"
          id="hide_pos"
          class="form-check-input"
          v-model="hideStocked"
        /><span>Hide Stocked PO'S</span>
      </p>
    </div>
    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo">
      <div class="card-body">
        <div class="table-responsive">
          <!-- TABLE -->
          <table class="table custom-table jsmartable">
            <tbody v-if="hideStocked">
              <template
                v-if="assetWiseExpensesArr && assetWiseExpensesArr.length > 0"
              >
                <tr
                  v-for="(assetexpenses, index) in assetWiseExpensesArr"
                  :key="index"
                  v-if="assetexpenses.status == 711"
                >
                  <td align="center">
                    <input
                      type="checkbox"
                      id="po_checkbox"
                      class="form-check-input"
                      @change="e => {addSubToTotal(e, assetexpenses.actual_cost)}"
                      v-bind:checked="isCostSheetInputChecked"
                    />
                  </td>
                  <td>
                    <span role="button" title="Click to View" class="text-primary" @click="getDetail(assetexpenses)">{{ assetexpenses.excede_po }}</span>
                  </td>
                  <td>{{ assetexpenses.entered }}</td>
                  <td>
                    {{ assetexpenses.expense_category }}
                  </td>
                  <!-- Start Status Condition -->
                  <td v-if="assetexpenses.status == 715">Stocked</td>
                  <td v-else-if="assetexpenses.status == 711">Open</td>
                  <td v-else></td>
                  <!-- End Status -->
                  <td>{{ assetexpenses.vendor }}</td>
                  <td>{{ assetexpenses.summary }}</td>
                  <td>
                    {{ assetexpenses.actual_cost }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8" class="text-alignment-center">
                    {{ allStaticMessages.data_not_available }}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template
                v-if="assetWiseExpensesArr && assetWiseExpensesArr.length > 0"
              >
                <tr
                  v-for="(assetexpenses, index) in assetWiseExpensesArr"
                  :key="index"
                >
                  <td align="center">
                    <input
                      type="checkbox"
                      id="po_checkbox"
                      class="form-check-input"
                      v-bind:checked="isCostSheetInputChecked"
                      @change="e => {addSubToTotal(e,assetexpenses.actual_cost)}"
                    />
                  </td>
                  <td>
                    <span role="button" title="Click to View" class="text-primary" @click="getDetail(assetexpenses)">{{ assetexpenses.excede_po }}</span>
                  </td>
                  <td>{{ assetexpenses.entered }}</td>
                  <td>
                    {{ assetexpenses.expense_category }}
                  </td>
                  <!-- Start Status Condition -->
                  <td v-if="assetexpenses.status == 715">Stocked</td>
                  <td v-else-if="assetexpenses.status == 711">Open</td>
                  <td v-else></td>
                  <!-- End Status -->
                  <td>{{ assetexpenses.vendor }}</td>
                  <td>{{ assetexpenses.summary }}</td>
                  <td>
                    {{ assetexpenses.actual_cost }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8" class="text-alignment-center">
                    {{ allStaticMessages.data_not_available }}
                  </td>
                </tr>
              </template>
            </tbody>
            <thead>
              <tr>
                <th class="" data-breakpoint="xs">Add to Cost</th>
                <th class="" data-breakpoint="xs">PO</th>
                <th class="" data-breakpoint="xs">Creation Date</th>
                <th class="" data-breakpoint="xs">PO Type</th>
                <th class="" data-breakpoint="xs">Status</th>
                <th class="" data-breakpoint="xs">Vendor</th>
                <th class="" data-breakpoint="xs">Description</th>
                <th class="" data-breakpoint="xs">Total</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import eventBus from '../../../event-bus';
  var thisSelf;

  export default {
    name: 'VehiclePos',
    props: ['assetWiseExpensesArr', 'allStaticMessages'],
    data() {
        return {
            hideStocked: true,
            isCostSheetInputChecked: false
        }
    },
    beforeMount() {
      thisSelf = this;
    },
    methods: {
      addSubToTotal(e, amount) {
        eventBus.$emit("add-subtract-total",e.target.checked,amount);
      },
      getDetail(param) {
        eventBus.$emit("show-details",param, 'po');
      }
    },
  }

  eventBus.$on("close-cost-sheet", (status) => {
    Object.assign(thisSelf.$data, thisSelf.$options.data());
  });
</script>
