<template xmlns:onfocusout="http://www.w3.org/1999/xhtml" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_deal_inventory_mapper')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Linked Inventory</span>
                        </div>
                        <a
                                id='refreshList'
                                title='Refresh'
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button v-show="permissionNameArray.includes('can_delete_deal_inventory_mapper')" type="button" v-on:click.prevent="deleteLinkedInventory()" class="btn btn-primary waves-effect pull-right ml-2" title='Delete Multiple'><i class="fa fa-trash-o" aria-hidden="true"></i> Delete {{selectedRows.length}} Record(s)</button>
                    </div>
                    <div class="col-12 paddingnone">
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="opportunityAssetArr && opportunityAssetArr.length > 0">
                                    <template v-for="(asset, index) in opportunityAssetArr">
                                        <tr :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="asset.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + asset.id" name="case[]">
                                                        <label class="custom-control-label" :for="'customCheck' + asset.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions">
                                                    <a v-show="permissionNameArray.includes('can_delete_deal_inventory_mapper')" href="javascript:void(0)" title='Delete Linked Inventory' class="mar-right-0" v-on:click="deleteLinkedInventory(asset.id)">
                                                        <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td :class="rowClass.deal_no">{{asset.deal_no}}</td>
                                            <td :class="rowClass.unit">{{asset.unit}}</td>
                                            <td :class="rowClass.vin_no">{{asset.vin_no}}</td>
                                            <td :class="rowClass.make">{{asset.make}}</td>
                                            <td :class="rowClass.model">{{asset.model}}</td>
                                            <td :class="rowClass.use_type">{{asset.use_type}}</td>
                                            <td :class="rowClass.hold_date">{{asset.hold_date}}</td>
                                            <td :class="rowClass.factory_invo_date">{{asset.factory_invo_date}}</td>
                                            <td :class="rowClass.updated_at">{{asset.updated_at}}</td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="'header_' + headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" :key="'header_' + headerIndex" v-if="data.name != 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select ref="select" title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListOpportunityAssetTab',
        mixins: [commonMixins],
        components: {
            'no-access': NoAccessPage,
        },
        data() {
            return {
                oppId: 0,
                perPageValue : 5,
                perPage: 5,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                opportunityAssetArr: [],
                headers: [],
                jumpToPage: 1,
                selectedRows: [],
                startEndRecord: '',
                selectAll: false,
                actionDisplay: true,
                jumpToHistoryPage : 1,
                staticMessages: "",
                staticInputLabelMessages: "",
                columnName: filterColumn,
                sortType: filterType,
            }
        },
        props: {
            permissionNameArray: "",
            opportunity: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        beforeMount() {
            self = this;
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        watch: {
        },
        mounted: function () {
             /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('opportunity-list');
            EventBus.$emit("menu-selection-changed", "opportunity-list");

            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.oppId = self.$route.params.id;
                self.listExecutionState = false;
                self.loadOpportunityWiseAsset(self.oppId);
            }
        },
        methods: {
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.changepage();
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.changepage();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadOpportunityWiseAsset();
                }
            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
            },
            deleteLinkedInventory: function (id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if (id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Linked Inventory(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    /* showCloseButton: true, */
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data: {
                                deleteInvArr: deleteArray,
                            }
                        };
                        HTTP.delete("/opportunitywiseasset/delete", config)
                                .then(function (response) {
                                    if (response.data.code == '200') {
                                        self.changepage();
                                    }
                                })
                                .catch(function (error) {
                                    self.catchHandler(error, function () {});
                                });
                    }
                });
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.opportunityAssetArr) {
                            self.selectedRows.push(self.opportunityAssetArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.opportunityAssetArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadOpportunityWiseAsset: function (first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    opportunity_id: self.oppId
                };
                HTTP.post("/opportunitywiseasset/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            if (data.status == 200) {
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                self.opportunityAssetArr = data.data.content.data;
                                self.headers = data.data.content.headers;
                               
                                self.addColumnClass(self.headers);

                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                self.sortFieldDisplay(self.columnName,self.sortType);
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            self.catchHandler(error, function () {});
                        })
                    
            },
        },
    }
</script>