<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_repair_order_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Repair Orders Items</span>
                            <p v-if="totalRecordsItm > 0">{{totalRecordsItm}} Results found</p>
                        </div>
                        <router-link class="btn btn-primary waves-effect pull-right ml-2" title='Back' v-bind:to="{name: 'ListRepairOrderOps', params:{sls_id: svslsidItm}}">
                            <span>Back</span>
                        </router-link>
                        
                    </div>
                    <filter-component :key="filterCountItm" :filterCounts="filterCountItm" :parentHeaders="headersItm" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSortingItm"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRowsItm.length >= 1">
                        <span class="color-cc9636">{{selectedRowsItm.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionItm()">X</a></span>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPageItm"
                                              @input="changePerPageItm"
                                              name="customperPageItm"
                                              id="customperPageItm"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArrItm"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecordItm}} of {{totalRecordsItm}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecordsItm >= perPageItm" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsItm" v-model="currentPageItm" @input="changepageItm()" :per-page="perPageValueItm"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPageItm" type="text" class="form-control form-control-sm" name="jumpToPageItm" id="jumpToPageItm" v-on:keyup.enter="keyMonitorItm()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="repairOrderItm && repairOrderItm.length > 0">
                                    <tr v-for="(repairItms, index) in repairOrderItm" :key="index">
                                        <td class="">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="selectedRowsItm" :value="repairItms.id" @change="inputCheckboxItm($event)" class="custom-control-input" :id="'customCheck' + repairItms.id" name="case[]">
                                                <label class="custom-control-label" :for="'customCheck' + repairItms.id"></label>
                                            </div>
                                        </td>
                                        <td v-show="actionDisplayItm">
                                            <div class="actions">
                                                <template v-if="repairItms.itm_typ == '590'">
                                                    <router-link v-show="permissionNameArray.includes('can_list_repair_order_master')" class="mar-right-0" title='View Repair Order EmpLbr' v-bind:to="{name: 'ListRepairOrderEmpLbr', params:{id: repairItms.itm_id,sls_id : svslsidItm}}">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </router-link>
                                                </template>
                                            </div>
                                        </td>
                                        <td :class="rowClass.itm_id" >{{repairItms.itm_id}}</td>
                                        <td :class="rowClass.sls_id" >{{repairItms.sls_id}}</td>
                                        <td :class="rowClass.ops_id" >{{repairItms.ops_id}}</td>
                                        <td :class="rowClass.itm_typ">{{repairItms.itm_des}}</td>
                                        <td :class="rowClass.sv_itm">{{repairItms.sv_itm}}</td>
                                        <td :class="rowClass.amt_price">{{repairItms.amt_price}}</td>
                                        <td :class="rowClass.amt_price_suggest">{{repairItms.amt_price_suggest}}</td>
                                        <td :class="rowClass.amt_cost">{{repairItms.amt_cost}}</td>
                                        <td :class="rowClass.qty_ship">{{repairItms.qty_ship}}</td>
                                        <td :class="rowClass.qty_backorder">{{repairItms.qty_backorder}}</td>
                                        <td :class="rowClass.hrs_flat">{{repairItms.hrs_flat}}</td>
                                        <td :class="rowClass.hrs_bill">{{repairItms.hrs_bill}}</td>
                                        <td :class="rowClass.hrs_cost">{{repairItms.hrs_cost}}</td>
                                        <td :class="rowClass.hrs_actual">{{repairItms.hrs_actual}}</td>
                                        <td :class="rowClass.mins_per_segment">{{repairItms.mins_per_segment}}</td>
                                        <td :class="rowClass.use_hrs_flat">{{repairItms.use_hrs_flat}}</td>
                                        <td :class="rowClass.complete">{{repairItms.complete}}</td>
                                        <td :class="rowClass.emp_id_lbr">{{repairItms.emp_id_lbr}}</td>
                                        <td :class="rowClass.tax_id">{{repairItms.tax_id}}</td>
                                        <td :class="rowClass.emp_id">{{repairItms.emp_id}}</td>
                                        <td :class="rowClass.emp_id_spn">{{repairItms.emp_id_spn}}</td>
                                        <td :class="rowClass.created_at">{{repairItms.created_at}}</td>
                                        <td :class="rowClass.updated_at">{{repairItms.updated_at}}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headersItm.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                <tr>
                                    <th class="">
                                        <div class="custom-control custom-checkbox">
                                            <input v-model="selectAllItm" type="checkbox" class="custom-control-input" id="selectAllItmCheckBox" name="example1" @change="clickHeaderCheckBoxItm()">
                                            <label class="custom-control-label" for="selectAllItmCheckBox"></label>
                                        </div>
                                    </th>
                                    <template v-for="(data, headerIndex) in headersItm">
                                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                    </template>
                                    <template v-for="(data, headerIndex) in headersItm">
                                        <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                    </template>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPageItm"
                                              @input="changePerPageItm"
                                              name="customperPageItm"
                                              id="customperPageItm"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArrItm"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecordItm}} of {{totalRecordsItm}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecordsItm >= perPageItm" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsItm" v-model="currentPageItm" @input="changepageItm()" :per-page="perPageValueItm"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPageItm" type="text" class="form-control form-control-sm" name="jumpToPageItm" id="jumpToPageItm" v-on:keyup.enter="keyMonitorItm()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "created_at";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListRepairOrder',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                perPageValueItm: 25,
                perPageItm: 25,
                currentPageItm: 1,
                totalRecordsItm: 0,
                paginationDropDownArrItm: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderItm: [],
                headersItm: [],
                filtersItm : {
                },
                appliedHeadersItm : [],
                jumpToPageItm: 1,
                columnNameItm: filterColumn,
                sortTypeItm: filterType,
                selectedRowsItm: [],
                startEndRecordItm: "",
                selectAllItm: false,
                permissionNameArray : ['can_list_repair_order_master'],
                actionDisplayItm : true,
                jumpToHistoryPageItm : 1,
                filterCountItm : 0,
                svslsidItm : '',
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAllItm: function (selectAllItm) {
                self.clickHeaderCheckBoxItm();
            },*/
            /*perPageItm: function () {
                self.currentPageItm = 1;
                if (self.perPageItm !== "All") {
                    self.perPageValueItm = self.perPageItm;
                }
                self.loadRepairOrdersItems();
            },
            currentPageItm: function () {
                self.loadRepairOrdersItems();
            }*/
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('repairorder-list');
            EventBus.$emit("menu-selection-changed", "repairorder-list");
            var listHistoryData = self.loadFilters('ListRepairOrderItms', self);
            self.jumpToHistoryPageItm = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
            if (typeof(self.$route.params.sls_id)  != 'undefined' && self.$route.params.sls_id != 0 && self.$route.params.sls_id != '') {
                self.svslsidItm = self.$route.params.sls_id;
                /* alert(self.svslsidItm); */
            }
            let headersNames = [];
            headersNames['filters'] = self.filtersItm;
            self.listExecutionState = false;
            self.loadRepairOrdersItems(1);
        },
        methods: {
            resetSortingItm: function () {
                self.columnNameItm = filterColumn;
                self.sortTypeItm = filterType;
                self.changepageItm();
            },
            changePerPageItm : function(){
                self.currentPageItm = 1;
                if (self.perPageItm !== "All") {
                    self.perPageValueItm = self.perPageItm;
                }
                self.loadRepairOrdersItems();
            },
            changepageItm : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrdersItems();
                }
            },
            clearSelectionItm : function() {
                self.selectAllItm = false;
                self.selectedRowsItm = [];
            },
            keyMonitorItm: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPageItm, self.totalRecordsItm, self.perPageItm);
                self.currentPageItm = self.jumpToPageItm = parseInt(tempNumber);
            },
            clickHeaderCheckBoxItm: function() {
                setTimeout(function () {
                    self.selectedRowsItm = [];
                    if (self.selectAllItm) {
                        for (let i in self.repairOrderItm) {
                            self.selectedRowsItm.push(self.repairOrderItm[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxItm: function(e) {
                setTimeout(function () {
                    self.selectAllItm = false;
                    if (self.selectedRowsItm.length == self.repairOrderItm.length) {
                        self.selectAllItm = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filtersItm[key] = value;
                    }
                }
                self.appliedHeadersItm = data;
                self.loadRepairOrdersItems();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeadersItm.length > 0) {
                    self.headersItm = self.appliedHeaders;
                }
            },
            loadRepairOrdersItems: function(first) {
                self.listExecutionState = true;
                if(self.perPageItm == 'All' && self.totalRecordsItm > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    id : self.svslsidItm,
                    filters : self.filtersItm,
                    per_page: self.perPageItm,
                    page: self.currentPageItm,
                    sortColumnName: self.columnNameItm,
                    sortType: self.sortTypeItm
                };
                this.storeFilters('ListRepairOrderItms', fields, false);
                self.showLoader();
                self.filterCountItm = self.filterCountFunction(self.filtersItm);
                HTTP.post("/repairorderitem/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPageItm = self.jumpToHistoryPageItm;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionItm();
                            self.headersItm = [];
                            self.totalRecordsItm = data.data.content.result;
                            self.repairOrderItm = data.data.content.data;
                            self.headersItm = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filtersItm);

                            self.addColumnClass(self.headersItm);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headersItm.length; i++) {
                                if (self.headersItm[i]['type'] == 'drop_down') {
                                    self.headersItm[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecordsItm) {
                                self.perPageItm = parseInt(data.data.content.per_page);
                                self.jumpToPageItm = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPageItm == 'All'){
                                    self.jumpToPageItm = 1;
                                }
                                self.perPageValueItm = self.totalRecordsItm;
                                self.currentPageItm = 1;
                            }
                            self.startEndRecordItm = self.calculateStartRecordEndRecord(self.currentPageItm, self.perPageItm, self.totalRecordsItm);
                            self.sortFieldDisplay(self.columnNameItm,self.sortTypeItm);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnNameItm == name) {
                        self.sortTypeItm = (self.sortTypeItm == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortTypeItm = "asc";
                    }
                    self.columnNameItm = name;
                    self.loadRepairOrdersItems();
                }
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            /* if (!self.permissionNameArray.includes('can_add_location_master') && !self.permissionNameArray.includes('can_delete_location_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplayItm = false;
                }, 50);
            } */
        }
    });
</script>