<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_repair_order_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>Repair Orders Ops</span>
                            <p v-if="totalRecordsOps > 0">{{totalRecordsOps}} Results found</p>
                        </div>
                        <router-link class="btn btn-primary waves-effect pull-right ml-2" title='Back' v-bind:to="{name: 'ListRepairOrder'}">
                            <span>Back</span>
                        </router-link>
                        
                    </div>
                    <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headersOps" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRowsOps.length >= 1">
                        <span class="color-cc9636">{{selectedRowsOps.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelectionOps()">X</a></span>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 perPageOps">
                                    <v-select title="Select Per Page"
                                              v-model="perPageOps"
                                              @input="changeperPageOps"
                                              name="customperPageOps"
                                              id="customperPageOps"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArrOps"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecordOps}} of {{totalRecordsOps}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecordsOps >= perPageOps" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsOps" v-model="currentPageOps" @input="changepageOps()" :per-page="perPageValueOps"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPageOps" type="text" class="form-control form-control-sm" name="jumpToPageOps" id="jumpToPageOps" v-on:keyup.enter="keyMonitorOps()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="repairOrderOpsArr && repairOrderOpsArr.length > 0">
                                    <tr v-for="(repairOrderOps, index) in repairOrderOpsArr" :key="index">
                                        <td class="">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="selectedRowsOps" :value="repairOrderOps.id" @change="inputCheckboxOps($event)" class="custom-control-input" :id="'customCheck' + repairOrderOps.id" name="case[]">
                                                <label class="custom-control-label" :for="'customCheck' + repairOrderOps.id"></label>
                                            </div>
                                        </td>
                                        <td v-show="actionDisplayOps">
                                            <div class="actions">
                                                <router-link v-show="permissionNameArray.includes('can_list_repair_order_master')" class="mar-right-0" title='List Repair Order Items' v-bind:to="{name: 'ListRepairOrderItems', params:{sls_id: svslsid}}">
                                                    <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                </router-link>
                                            </div>
                                        </td>
                                        <td :class="rowClass.sls_id" >{{repairOrderOps.sls_id}}</td>
                                        <td :class="rowClass.ops_id" >{{repairOrderOps.ops_id}}</td>
                                        <td :class="rowClass.job_id">{{repairOrderOps.job_id}}</td>
                                        <td :class="rowClass.sls_typ_id">{{repairOrderOps.sls_typ_id}}</td>
                                        <td :class="rowClass.failed_prt_id">{{repairOrderOps.failed_prt_id}}</td>
                                        <td :class="rowClass.clm_nbr">{{repairOrderOps.clm_nbr}}</td>
                                        <td :class="rowClass.status">{{repairOrderOps.status_des}}</td>
                                        <td :class="rowClass.amt_clm">{{repairOrderOps.amt_clm}}</td>
                                        <td :class="rowClass.use_job_prices">{{repairOrderOps.use_job_prices}}</td>
                                        <td :class="rowClass.request">{{repairOrderOps.request}}</td>
                                        <td :class="rowClass.amt_parts">{{repairOrderOps.amt_parts}}</td>
                                        <td :class="rowClass.amt_cost_parts">{{repairOrderOps.amt_cost_parts}}</td>
                                        <td :class="rowClass.amt_labor">{{repairOrderOps.amt_labor}}</td>
                                        <td :class="rowClass.amt_cost_labor">{{repairOrderOps.amt_cost_labor}}</td>
                                        <td :class="rowClass.amt_sublet">{{repairOrderOps.amt_sublet}}</td>
                                        <td :class="rowClass.amt_cost_sublet">{{repairOrderOps.amt_cost_sublet}}</td>
                                        <td :class="rowClass.amt_misc">{{repairOrderOps.amt_misc}}</td>
                                        <td :class="rowClass.amt_cost_misc">{{repairOrderOps.amt_cost_misc}}</td>
                                        <td :class="rowClass.amt_tax">{{repairOrderOps.amt_tax}}</td>
                                        <td :class="rowClass.amt_subtotal">{{repairOrderOps.amt_subtotal}}</td>
                                        <td :class="rowClass.created_at">{{repairOrderOps.created_at}}</td>
                                        <td :class="rowClass.updated_at">{{repairOrderOps.updated_at}}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headersOps.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                <tr>
                                    <th class="">
                                        <div class="custom-control custom-checkbox">
                                            <input v-model="selectAllOps" type="checkbox" class="custom-control-input" id="selectAllOpsCheckBox" name="example1" @change="clickHeaderCheckBoxOps()">
                                            <label class="custom-control-label" for="selectAllOpsCheckBox"></label>
                                        </div>
                                    </th>
                                    <template v-for="(data, headerIndex) in headersOps">
                                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                    </template>
                                    <template v-for="(data, headerIndex) in headersOps">
                                        <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableDataOps(data.column_name, data.sort)">{{data.name}}</th>
                                    </template>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 perPageOps">
                                    <v-select title="Select Per Page"
                                              v-model="perPageOps"
                                              @input="changeperPageOps"
                                              name="customperPageOps"
                                              id="customperPageOps"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArrOps"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecordOps}} of {{totalRecordsOps}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecordsOps >= perPageOps" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecordsOps" v-model="currentPageOps" @input="changepageOps()" :per-page="perPageValueOps"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPageOps" type="text" class="form-control form-control-sm" name="jumpToPageOps" id="jumpToPageOps" v-on:keyup.enter="keyMonitorOps()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "created_at";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'ListRepairOrder',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                perPageValueOps: 25,
                perPageOps: 25,
                currentPageOps: 1,
                totalRecordsOps: 0,
                paginationDropDownArrOps: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                repairOrderOpsArr: [],
                headersOps: [],
                filtersOps : {
                },
                appliedHeadersOps : [],
                jumpToPageOps: 1,
                columnNameOps: filterColumn,
                sortTypeOps: filterType,
                selectedRowsOps: [],
                startEndRecordOps: "",
                selectAllOps: false,
                permissionNameArray : ['can_list_repair_order_master'],
                actionDisplayOps : true,
                jumpToHistoryPageOps : 1,
                filterCount : 0,
                svslsid : '',
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAllOps: function (selectAllOps) {
                self.clickHeaderCheckBoxOps();
            },*/
            /*perPageOps: function () {
                self.currentPageOps = 1;
                if (self.perPageOps !== "All") {
                    self.perPageValueOps = self.perPageOps;
                }
                self.loadRepairOrdersOps();
            },
            currentPageOps: function () {
                self.loadRepairOrdersOps();
            }*/
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('repairorder-list');
            EventBus.$emit("menu-selection-changed", "repairorder-list");
            var listHistoryData = self.loadFilters('ListRepairOrderOps', self);
            self.jumpToHistoryPageOps = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
            if (typeof(self.$route.params.sls_id)  != 'undefined' && self.$route.params.sls_id != 0 && self.$route.params.sls_id != '') {
                self.svslsid = self.$route.params.sls_id;
                /* alert(self.svslsid); */
            }
            let headersNames = [];
            headersNames['filters'] = self.filtersOps;
            self.listExecutionState = false;
            self.loadRepairOrdersOps(1);
        },
        methods: {
            resetSorting: function () {
                self.columnNameOps = filterColumn;
                self.sortTypeOps = filterType;
                self.changepageOps();
            },
            changeperPageOps : function(){
                self.currentPageOps = 1;
                if (self.perPageOps !== "All") {
                    self.perPageValueOps = self.perPageOps;
                }
                self.loadRepairOrdersOps();
            },
            changepageOps : function(){
                if(!self.listExecutionState){
                    self.loadRepairOrdersOps();
                }
            },
            clearSelectionOps : function() {
                self.selectAllOps = false;
                self.selectedRowsOps = [];
            },
            keyMonitorOps: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPageOps, self.totalRecordsOps, self.perPageOps);
                self.currentPageOps = self.jumpToPageOps = parseInt(tempNumber);
            },
            clickHeaderCheckBoxOps: function() {
                setTimeout(function () {
                    self.selectedRowsOps = [];
                    if (self.selectAllOps) {
                        for (let i in self.repairOrderOpsArr) {
                            self.selectedRowsOps.push(self.repairOrderOpsArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckboxOps: function(e) {
                setTimeout(function () {
                    self.selectAllOps = false;
                    if (self.selectedRowsOps.length == self.repairOrderOpsArr.length) {
                        self.selectAllOps = true;
                    }
                }, 100);
            },
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filtersOps[key] = value;
                    }
                }
                self.appliedHeadersOps = data;
                self.loadRepairOrdersOps();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeadersOps.length > 0) {
                    self.headersOps = self.appliedHeadersOps;
                }
            },
            loadRepairOrdersOps: function(first) {
                self.listExecutionState = true;
                if(self.perPageOps == 'All' && self.totalRecordsOps > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    id : self.svslsid,
                    filters : self.filtersOps,
                    per_page: self.perPageOps,
                    page: self.currentPageOps,
                    sortColumnName: self.columnNameOps,
                    sortType: self.sortTypeOps
                };
                this.storeFilters('ListRepairOrderOps', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filtersOps);
                HTTP.post("/repairorderops/list", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPageOps = self.jumpToHistoryPageOps;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelectionOps();
                            self.headersOps = [];
                            self.totalRecordsOps = data.data.content.result;
                            self.repairOrderOpsArr = data.data.content.data;
                            self.headersOps = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filtersOps);

                            self.addColumnClass(self.headersOps);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.headersOps.length; i++) {
                                if (self.headersOps[i]['type'] == 'drop_down') {
                                    self.headersOps[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecordsOps) {
                                self.perPageOps = parseInt(data.data.content.per_page);
                                self.jumpToPageOps = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPageOps == 'All'){
                                    self.jumpToPageOps = 1;
                                }
                                self.perPageValueOps = self.totalRecordsOps;
                                self.currentPageOps = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecordOps = self.calculateStartRecordEndRecord(self.currentPageOps, self.perPageOps, self.totalRecordsOps);
                            self.sortFieldDisplay(self.columnNameOps,self.sortTypeOps);
                        }
                    })
                    .catch(function (error) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableDataOps: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnNameOps == name) {
                        self.sortTypeOps = (self.sortTypeOps == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortTypeOps = "asc";
                    }
                    self.columnNameOps = name;
                    self.loadRepairOrdersOps();
                }
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            /* if (!self.permissionNameArray.includes('can_add_location_master') && !self.permissionNameArray.includes('can_delete_location_master')) {
                setTimeout(function () {
                    self.headersOps.pop();
                    self.actionDisplayOps = false;
                }, 50);
            } */
        }
    });
</script>