<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_private_file_list_inventory_master') || permissionNameArray.includes('can_public_file_list_inventory_master') || permissionNameArray.includes('can_add_private_file_inventory_master') || permissionNameArray.includes('can_add_public_file_inventory_master')">
            <div class="container-fluid xyz">
                    <div class="spaced-container">
                        <div class="col-md-12 paddingnone">
                            <div v-show="permissionNameArray.includes('can_add_private_file_inventory_master')" class="col-md-6 paddingnone float-left">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.upload_private_file_label}}</label>
                                    <vue-dropzone
                                        ref="privateDocDropzone"
                                        id="privateDocDropzone"
                                        :options="privateDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUloadBanner"
                                        :useCustomSlot=true
                                        >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                            <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <!-- Private Table Listing End -->
                            </div>
                            <div v-show="permissionNameArray.includes('can_add_public_file_inventory_master')" class="col-md-6 paddingnone float-left">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.upload_public_file_label}}</label>
                                    <vue-dropzone
                                        ref="publicDocDropzone"
                                        id="publicDocDropzone"
                                        :options="publicDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUloadBanner"
                                        :useCustomSlot=true
                                        >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                            <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>
                            <span class="help is-danger">{{fileUploadError}}</span>
                            <div class="clearfix"></div>
                            <div class="row clearfix"></div>
                            <div v-show="permissionNameArray.includes('can_add_private_file_inventory_master') || permissionNameArray.includes('can_add_public_file_inventory_master')" class="col-md-12">
                                <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addFileDocuments()">
                                <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary ml-10" v-on:click.prevent="buttonBack">
                            </div>
                            <div class="clearfix mb-3"></div>
                            <div v-show="permissionNameArray.includes('can_private_file_list_inventory_master')" class="col-md-6 paddingnone float-left">
                                <!--Private Table Listing Start-->
                                <label for="dropzone">{{staticInputLabelMessages.private_file_label}}</label>
                                <template v-if="assetWiseFilePrivateArr && assetWiseFilePrivateArr.length > 1">
                                    <!-- <a 
                                        id="downloadPrivateFile" 
                                        :href="privateFileURL"
                                        class="btn btn-primary waves-effect pull-right ml-2" 
                                        title='Download Private File' 
                                        download="download"> -->
                                    <a href="#" id="downloadPrivateFile" @click="getSignedUrlPath(privateFileURL)" title='Download Private Files' class="btn btn-primary waves-effect pull-right ml-2">
                                        <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>  Download All
                                    </a>
                                </template>
                                <div class="clearfix mb-3"></div>
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <tbody>
                                            <template v-if="assetWiseFilePrivateArr && assetWiseFilePrivateArr.length > 0">
                                                <tr v-for="(file, index) in assetWiseFilePrivateArr" :key="index">
                                                    <template v-if="file.is_private == true">
                                                        <td>
                                                            <div class="actions">
                                                                <a v-show="permissionNameArray.includes('can_private_file_delete_inventory_master')" href="javascript:void(0)" title='Delete File' class="mar-right-0" v-on:click="deleteFile(file.file_id)">
                                                                    <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                                </a>
                                                                <!-- <a :href="file.file_path" title='Download File' class="mar-right-0" target="_blank" download> -->
                                                                <a href="#" :id="'id' + index" @click="getSignedUrlPath(file.file_path)" title='Download File' class="mar-right-0">
                                                                    <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td :class="rowClass.name">{{file.name}}</td>
                                                        <td :class="rowClass.updated_at">{{file.updated_at}}</td>
                                                        <td :class="rowClass.updated_by">{{file.updated_by}}</td>
                                                    </template>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <td :colspan="privateHeaders.length + 1" class="text-alignment-center">
                                                        {{allStaticMessages.data_not_available}}
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <template v-for="(data, headerIndex) in privateHeaders">
                                                <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                            </template>
                                            <template v-for="(data, headerIndex) in privateHeaders">
                                                <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortPrivateTableData(data.column_name, data.sort)">{{data.name}}</th>
                                            </template>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                                <!-- Pagination Start -->
                                <div class="vgt-wrap__footer crm">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-2 PerPage">
                                            <v-select title="Select Per Page"
                                                    v-model="perPrivatePage"
                                                    @input="changePerPrivatePage"
                                                    name="customPerPage"
                                                    id="customPerPage"
                                                    label="text"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    :filterable="false"
                                                    :options="paginationDropDownArr"
                                                    placeholder="Select Per Page">
                                            </v-select>
                                        </div>
                                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                            <p class="pageRowCount">
                                                {{privateStartEndRecord}} of {{totalPrivateRecords}}
                                            </p>
                                        </div>

                                        <!-- v-if="totalPrivateRecords >= perPage" -->
                                        <div class="col-sm-12 col-md-6 Pagination">
                                            <b-pagination v-if="totalPrivateRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalPrivateRecords" v-model="currentPrivatePage" @input="changePrivatePage()" :per-page="perPagePrivateValue"></b-pagination>
                                        </div>

                                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                            <p class="pageJumpLabel">Page : </p>
                                        </div>
                                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                            <input v-model="jumpToPrivatePage" type="text" class="form-control form-control-sm" name="jumpToPrivatePage" id="jumpToPrivatePage" v-on:keyup.enter="privateKeyMonitor()" placeholder="Page">
                                        </div>
                                    </div>
                                </div>
                                <!-- Pagination End -->
                                <!-- Private Table Listing End -->
                            </div>
                            <div v-show="permissionNameArray.includes('can_public_file_list_inventory_master')" class="col-md-6 paddingnone float-left">
                                <!-- Public Table Listing Start-->
                                <label for="dropzone">{{staticInputLabelMessages.public_file_label}}</label>
                                <template v-if="assetWiseFilePublicArr && assetWiseFilePublicArr.length > 1">
                                    <!-- <a 
                                        id="downloadPublicFile" 
                                        :href="publicFileURL"
                                        class="btn btn-primary waves-effect pull-right ml-2" 
                                        title='Download Public File' 
                                        download="download"> -->
                                    <a href="#" id="downloadPublicFile" @click="getSignedUrlPath(publicFileURL)" title='Download Public Files' class="btn btn-primary waves-effect pull-right ml-2">
                                        <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>  Download All
                                    </a>
                                </template>
                                <div class="clearfix mb-3"></div>
                                <div class="table-listing white-bg border-blue border-radius-top-right">
                                    <table class="table custom-table jsmartable">
                                        <tbody>
                                        <template v-if="assetWiseFilePublicArr && assetWiseFilePublicArr.length > 0">
                                            <tr v-for="(file, index) in assetWiseFilePublicArr" :key="index">
                                                <template v-if="file.is_private == false">
                                                <td>
                                                    <div class="actions">
                                                        <a v-show="permissionNameArray.includes('can_public_file_delete_inventory_master')" href="javascript:void(0)" title='Delete File' class="mar-right-0" v-on:click="deleteFile(file.file_id)">
                                                            <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                        </a>
                                                        <!-- <a :href="file.file_path" title='Download File' class="mar-right-0" target="_blank" download> -->
                                                        <a href="#" :id="'id' + index" @click="getSignedUrlPath(file.file_path)" title='Download File' class="mar-right-0">
                                                            <i class="exceptEdit fa fa-cloud-download" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td :class="rowClass.name">{{file.name}}</td>
                                                <td :class="rowClass.updated_at">{{file.updated_at}}</td>
                                                <td :class="rowClass.updated_by">{{file.updated_by}}</td>
                                                </template>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td :colspan="privateHeaders.length + 1" class="text-alignment-center">
                                                    {{allStaticMessages.data_not_available}}
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <template v-for="(data, headerIndex) in privateHeaders">
                                                <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                            </template>
                                            <template v-for="(data, headerIndex) in privateHeaders">
                                                <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortPublicTableData(data.column_name, data.sort)">{{data.name}}</th>
                                            </template>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                                <!-- Pagination Start -->
                                <div class="bottom_pagination vgt-wrap__footer crm">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-2 PerPage">
                                            <v-select title="Select Per Page"
                                                    v-model="perPublicPage"
                                                    @input="changePerPublicPage"
                                                    name="customPerPage"
                                                    id="customPerPage"
                                                    label="text"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    :filterable="false"
                                                    :options="paginationDropDownArr"
                                                    placeholder="Select Per Page">
                                            </v-select>
                                        </div>
                                        <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                            <p class="pageRowCount">
                                                {{publicStartEndRecord}} of {{totalPublicRecords}}
                                            </p>
                                        </div>

                                        <!-- v-if="totalPublicRecords >= perPage" -->
                                        <div class="col-sm-12 col-md-6 Pagination">
                                            <b-pagination v-if="totalPublicRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalPublicRecords" v-model="currentPublicPage" @input="changePublicPage()" :per-page="perPagePublicValue"></b-pagination>
                                        </div>

                                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                            <p class="pageJumpLabel">Page : </p>
                                        </div>
                                        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                            <input v-model="jumpToPublicPage" type="text" class="form-control form-control-sm" name="jumpToPublicPage" id="jumpToPublicPage" v-on:keyup.enter="publicKeyMonitor()" placeholder="Page">
                                        </div>
                                    </div>
                                </div>
                                <!-- Pagination End -->
                                <!-- Public Table Listing End -->
                            </div>
                        </div>
                    </div>    
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterPrivateColumn = "id";
    var filterPrivateType = "desc";
    var filterPublicColumn = "id";
    var filterPublicType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import vue2Dropzone from 'vue2-dropzone';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'FileAsset',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
            'vueDropzone': vue2Dropzone,
        },
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                headers: [],
                columnPrivateName: filterPrivateColumn,
                sortPrivateType: filterPrivateType,
                columnPublicName: filterPublicColumn,
                sortPublicType: filterPublicType,
                privateHeaders: [],
                publicHeaders: [],
                assetId: 0,
                jumpToPrivatePage : 1,
                jumpToPublicPage : 1,
                perPagePrivateValue : 15,
                perPagePublicValue : 15,
                perPrivatePage:15,
                perPublicPage:15,
                currentPrivatePage:1,
                currentPublicPage:1,
                totalPrivateRecords:0,
                totalPublicRecords:0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                startEndRecord: "",
                privateStartEndRecord: "",
                publicStartEndRecord: "",
                assetWiseFilePrivateArr : [],
                privateFileURL : "",
                assetWiseFilePublicArr:[],
                publicFileURL : "",
                loadDocArr: [],
                filesPaths: [],
                filesType: [],
                filesSize: [],
                filesIsPrivate: [],
                fileUploadError : "",
                privateDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 15, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".doc, .docx, .xls, .pdf , .xlsx , .html, .jpg, .jpeg, .png",
                    maxFiles: 10,
                    params: {'is_private': true},
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                publicDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 15, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".doc, .docx, .xls, .pdf , .xlsx , .html, .jpg, .jpeg, .png",
                    maxFiles: 10,
                    params: {'is_private': false},
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
            }
        },
        beforeMount() {
            self = this;
        },
        props: ['permissionNameArray', 'asset', 'getData'],
        /* props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        }, */
        watch: {
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.listExecutionState = false;
            self.getInventoryFiles();
            /* self.loadFile(); */
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                //self.loadFile(self.assetId);
                self.loadPrivateFile(self.assetId);
                self.loadPublicFile(self.assetId);
            }
        },
        methods: {
            changePerPrivatePage : function(){
                self.currentPrivatePage = 1;
                if (self.perPrivatePage !== "All") {
                    self.perPagePrivateValue = self.perPrivatePage;
                }
                self.loadPrivateFile(self.assetId);
            },
            changePerPublicPage : function(){
                self.currentPublicPage = 1;
                if (self.perPublicPage !== "All") {
                    self.perPagePublicValue = self.perPublicPage;
                }
                self.loadPublicFile(self.assetId);
            },
            changePrivatePage : function(){
                self.loadPrivateFile(self.assetId);
            },
            changePublicPage : function(){
                self.loadPublicFile(self.assetId);
            },
            privateKeyMonitor:function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPrivatePage, self.totalPrivateRecords, self.perPrivatePage);
                self.currentPrivatePage = self.jumpToPrivatePage = parseInt(tempNumber);
            },
            sortPrivateTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnPrivateName == name) {
                        self.sortPrivateType = (self.sortPrivateType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortPrivateType = "asc";
                    }
                    self.columnPrivateName = name;
                    self.loadPrivateFile(self.assetId);
                }
            },
            sortPublicTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnPublicName == name) {
                        self.sortPublicType = (self.sortPublicType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortPublicType = "asc";
                    }
                    self.columnPublicName = name;
                    self.loadPublicFile(self.assetId);
                }
            },
            publicKeyMonitor:function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPublicPage, self.totalPublicRecords, self.perPublicPage);
                self.currentPublicPage = self.jumpToPublicPage = parseInt(tempNumber);
            },
            buttonBack: function() {
                self.$router.push({name: "ListAsset"});
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
                
                self.fileUploadError = "";
                self.filesPaths.push(response.content.file_path);
                self.filesType.push(file.type);
                self.filesSize.push(file.size);
                self.filesIsPrivate.push(response.content.is_private);
                this.$refs.privateDocDropzone.setOption('maxFiles', null);
                this.$refs.publicDocDropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              
                this.$refs.privateDocDropzone.setOption('maxFiles', null);
                this.$refs.publicDocDropzone.setOption('maxFiles', null);
                var removeFile = "";
                
                if (this.$refs.privateDocDropzone.dropzone.options.maxFiles || this.$refs.publicDocDropzone.dropzone.options.maxFiles ) {
                    this.$refs.privateDocDropzone.setOption('maxFiles', null);
                    this.$refs.publicDocDropzone.setOption('maxFiles', null);
                }
                if ("manuallyAdded" in file == true) {
                    for (var i = self.filesPaths.length - 1; i >= 0; i--) {
                        if (self.filesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.filesPaths[i];
                        }
                    }
                    this.$refs.privateDocDropzone.setOption('maxFiles', null);
                    this.$refs.publicDocDropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.file_path
                }
                
                for (var i = self.filesPaths.length - 1; i >= 0; i--) {
                    if (self.filesPaths[i] === removeFile) {
                        self.filesPaths.splice(i, 1);
                        self.filesType.splice(i, 1);
                        self.filesSize.splice(i, 1);
                        self.filesIsPrivate.splice(i, 1);
                    }
                }
                this.$refs.privateDocDropzone.setOption('maxFiles', null);
                this.$refs.publicDocDropzone.setOption('maxFiles', null);
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removedocument', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                // localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;
                if (this.$refs.privateDocDropzone.dropzone.options.maxFiles || this.$refs.publicDocDropzone.dropzone.options.maxFiles ) {
                    this.$refs.privateDocDropzone.setOption('maxFiles', null);
                    this.$refs.publicDocDropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            getInventoryFiles: function() {
                self.listExecutionState = true;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/asset/file/get', config)
                    .then(response => {
                        self.listExecutionState = false;
                        if (response.data.code == 200) {
                            self.loadDocArr = response.data.content;
                            setTimeout(function() {
                                self.uploadManually();
                            }, 500);
                        }
                    })
                    .catch(function (err) {
                        self.listExecutionState = false;
                        self.catchHandler(err, function () {});
                    });
                
            },
            checkForm: function() {
                var checked = true;
                if (self.filesPaths.length === 0)  {
                    self.fileUploadError = "Please upload file";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            loadPrivateFile: function(assetId) {
                if(self.perPrivatePage == 'All' && self.totalPrivateRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPrivatePage = 500;
                    self.loadPrivateFile();
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    per_page: self.perPrivatePage,
                    page: self.currentPrivatePage,
                    sortColumnName: self.columnPrivateName,
                    sortType: self.sortPrivateType,
                    assetId : assetId
                };
                self.showLoader();
                HTTP.post("/asset/private/file/list", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.privateHeaders = [];
                            self.totalPrivateRecords = data.data.content.result;
                            if(self.perPrivatePage == 'All' && self.totalPrivateRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalPrivateRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPrivatePage = 500;
                                    self.loadPrivateFile();
                                }, seconds);
                                /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPrivatePage = 15;
                                        self.loadPrivateFile();
                                    }
                                }); */
                            }
                            self.assetWiseFilePrivateArr = data.data.content.data;
                            self.privateFileURL = data.data.content.link;
                            self.privateHeaders = data.data.content.headers;
            
                            self.addColumnClass(self.privateHeaders);
                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.privateHeaders.length; i++) {
                                if (self.privateHeaders[i]['type'] == 'drop_down') {
                                    self.privateHeaders[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalPrivateRecords) {
                                self.perPrivatePage = parseInt(data.data.content.per_page);
                                self.jumpToPrivatePage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPrivatePage == 'All'){
                                    self.jumpToPrivatePage = 1;
                                }
                                self.perPagePrivateValue = self.totalPrivateRecords;
                                self.currentPrivatePage = 1;
                            }
                            self.perPrivatePage = parseInt(data.data.content.per_page);
                            self.jumpToPrivatePage = parseInt(data.data.content.current_page);
                            self.privateStartEndRecord = self.calculateStartRecordEndRecord(self.currentPrivatePage, self.perPrivatePage, self.totalPrivateRecords);
                            self.sortFieldDisplay(self.columnPrivateName,self.sortPrivateType);
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            loadPublicFile: function(assetId) {
                if(self.perPublicPage == 'All' && self.totalPublicRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPublicPage = 500;
                    self.loadPublicFile();
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    per_page: self.perPublicPage,
                    page: self.currentPublicPage,
                    sortColumnName: self.columnPublicName,
                    sortType: self.sortPublicType,
                    assetId : assetId
                };
                self.showLoader();
                HTTP.post("/asset/public/file/list", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.privateHeaders = [];
                            self.totalPublicRecords = data.data.content.result;
                            if(self.perPublicPage == 'All' && self.totalPublicRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                // alert(self.totalPublicRecords);
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPublicPage = 500;
                                    self.loadPublicFile();
                                }, seconds);
                                /* self.$swal({
                                    title: '',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    type: 'error',
                                    confirmButtonText: 'OK',
                                    showCancelButton: true,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    if (result.value) {
                                        self.logout();
                                    } else {
                                        self.perPublicPage = 15;
                                        self.loadPublicFile();
                                    }
                                }); */
                            }
                            self.assetWiseFilePublicArr = data.data.content.data;
                            self.publicFileURL = data.data.content.link;
                            self.privateHeaders = data.data.content.headers;
            
                            self.addColumnClass(self.privateHeaders);
                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]
                            for (let i = 0; i < self.privateHeaders.length; i++) {
                                if (self.privateHeaders[i]['type'] == 'drop_down') {
                                    self.privateHeaders[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalPublicRecords) {
                                self.perPublicPage = parseInt(data.data.content.per_page);
                                self.jumpToPublicPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPublicPage == 'All'){
                                    self.jumpToPublicPage = 1;
                                }
                                self.perPagePublicValue = self.totalPublicRecords;
                                self.currentPublicPage = 1;
                            }
                            self.perPublicPage = parseInt(data.data.content.per_page);
                            self.jumpToPublicPage = parseInt(data.data.content.current_page);
                            self.publicStartEndRecord = self.calculateStartRecordEndRecord(self.currentPublicPage, self.perPublicPage, self.totalPublicRecords);
                            self.sortFieldDisplay(self.columnPublicName,self.sortPublicType);
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            deleteFile: function (deleteAssetWiseId) {
                let deleteArray = [];
                deleteAssetWiseId = (deleteAssetWiseId === null || typeof (deleteAssetWiseId) === "undefined" || deleteAssetWiseId == '' || deleteAssetWiseId == 0) ? 0 : deleteAssetWiseId;
                if (deleteAssetWiseId != 0) {
                    deleteArray.push(deleteAssetWiseId);
                }
                if (deleteArray.length >= 1) {
                    self.$swal({
                        title: '',
                        text: 'Are you sure that you want to delete this File(s)!',
                        type: 'info',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        // showCloseButton: true,
                        showLoaderOnConfirm: true,
                    }).then((result) => {
                        if (result.value) {
                            var config = {
                                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                                data: {
                                    deleteIdArr: deleteArray,
                                }
                            };
                            HTTP.delete("/asset/file/delete", config)
                                    .then(function (response) {
                                        if (response.data.code == '200') {
                                            //self.$emit('updateParentDisplayMethod',5);
                                            self.reloadPageData();
                                            //location.reload();
                                            //self.loadFile(self.assetId);
                                        }
                                    })
                                    .catch(function (error) {
                                        self.catchHandler(error, function () {});
                                    });
                        }
                    });
                }
            },
            returnData: function() {
                var data = {
                    filePath: self.filesPaths,
                    fileSize: self.filesSize,
                    fileType: self.filesType,
                    isPrivate: self.filesIsPrivate,
                    id: self.$route.params.id,
                };
                return data;
            },
            addFileDocuments: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Saving ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/file/add/update", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Save";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);

                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        //self.buttonBack();
                                        self.reloadPageData();
                                    }, 3000);
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Save";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            uploadManually: function () {
                if(self.loadDocArr.length > 0){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        self.filesIsPrivate.push(self.loadDocArr[i]['is_private']);
                        if (self.loadDocArr[i]['file_from'] == 'file_path' && self.loadDocArr[i]['is_private'] == 1) {
                            var url = self.loadDocArr[i]['file_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['file_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];

                            self.filesType.push(file_type);
                            self.filesSize.push(Number(file_size));
                            
                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                            self.$refs.privateDocDropzone.manuallyAddFile(file_data, url);  
                        }
                        if (self.loadDocArr[i]['file_from'] == 'file_path' && self.loadDocArr[i]['is_private'] == 0) {
                            var url = self.loadDocArr[i]['file_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['file_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];

                            self.filesType.push(file_type);
                            self.filesSize.push(Number(file_size));

                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type}
                            self.$refs.publicDocDropzone.manuallyAddFile(file_data, url);
                        }
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
            },
            getFileName: function (file) {
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];
                self.filesPaths.push(String(orgPath + "/" + file_name));
                return file_name;
            },
            reloadPageData : function (){
                self.removeAllPrivateFiles();
                self.removeAllPublicFiles();
                self.getInventoryFiles();
                self.loadPrivateFile(self.assetId);
                self.loadPublicFile(self.assetId);
            },
            removeAllPrivateFiles: function () {
                this.$refs.privateDocDropzone.removeAllFiles();
            },
            removeAllPublicFiles: function () {
                this.$refs.publicDocDropzone.removeAllFiles();
            },
        },
    }
</script>