<template>
  <div class="add-state-popup-form admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_states')">
      <div
        class="modal fade"
        id="addStateFormPopup"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{{ pageTitle }}</h4>
              <button
                type="button"
                v-on:click.prevent="closeModalPopup()"
                class="btn btn-outline-primary waves-effect pull-right"
              >
                Close
              </button>
            </div>

            <div class="model-body">
              <common-loader
                refId="commonLoaderAddStateFormPopup"
                ref="commonLoaderAddStateFormPopup"
              >
              </common-loader>
              <div class="row no-margin pl-10 pt-20 pb-20">
                <!-- Country -->
                <div class="col-10 forms-block pt-10">
                  <div class="col-5 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.country_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      v-model="countryId"
                      @change="changeCountry"
                      @input="countryError = ''"
                      name="countryId"
                      :id="preId+'country_id'"
                      label="text"
                      :options="countryArr"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :disabled="true"
                      :placeholder="allStaticMessages.country_place_holder"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isCountryRecordNotFound">
                          {{ allStaticMessages.country_search }}
                        </template>
                        <template v-else>
                          {{ allStaticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is_danger">{{ countryError }}</span>
                  </div>
                </div>

                <!-- State -->
                <div class="col-10 forms-block pt-10">
                  <div class="col-5 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.state_name_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      v-model="stateName"
                      :id="preId+'state_name'"
                      type="text"
                      @input="stateError = ''"
                      :placeholder="
                        allStaticMessages.state_placeholder_input_field
                      "
                      class="form-control"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ stateError }}</span>
                  </div>
                </div>

                <!-- Code -->
                <div class="col-10 forms-block pt-10">
                  <div class="col-14 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.state_code_label }}
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                      :id="preId+'state_code'"
                        v-model="code"
                        class="form-control"
                        type="text"
                        :placeholder="allStaticMessages.state_code_placeholder"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal Footer -->
              <div class="modal-footer">
                <div class="ml-3">
                  <input
                    type="button"
                    @click="closeModalPopup"
                    class="btn btn-outline-primary"
                    value="Close"
                    style="margin-right: 12px;"
                  />
                  <input
                    type="submit"
                    @click="saveState"
                    class="btn btn-primary"
                    :value="isEditing ? 'Update' : 'Save'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
var self;
import EventBus from ".././event-bus";
import { HTTP } from "@/http-common";
import { commonMixins } from "@/mixins/commonMixins";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "AddStateFormPopup",
  mixins: [commonMixins],
  components: {
    "common-loader": CommonLoader,
  },

  data() {
    return {
      productionCountry: process.env.VUE_APP_PRODUCTION_COUNTRY,
      preId: "state_add_update_form_",
      permissionNameArray: ["can_add_states"],
      stateId: "",
      countryId: "",
      countryArr: [],
      countryError: "",
      isCountryRecordNotFound: false,
      stateName: "",
      stateError: "",
      code: "",
      isEditing: false,
      pageTitle: "Add State",
      modalInstance: null,
    };
  },

  beforeMount() {
    self = this;
  },

  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },

  mounted() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    if (self.productionCountry == "AU") {
      self.countryId = { text: self.allLabelMessages.AU_country_label, value: 13 };
    } else {
      self.countryId = { text: self.allLabelMessages.US_country_label, value: 231 };
    }
    self.initializeModals();
  },

  methods: {
    initializeModals() {
      self.modalInstance = new bootstrap.Modal(
       document.getElementById("addStateFormPopup")
      );
    },
    openForm(data) {
      self.modalInstance.show();

      if (
        data.add_edit == "edit" &&
        typeof data.record_id != "undefined" &&
        data.record_id
      ) {
        self.stateId = data.record_id;
        self.getStateData(data.record_id);
        self.pageTitle = "Edit State";
        self.isEditing = true;
      } else {
        self.pageTitle = "Add State";
        self.isEditing = false;
        self.resetFormData();
      }
    },
    getStateData(id) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };

      HTTP.get("/state/get", config)
        .then(function (response) {
          self.code = response.data.content.code;
          self.countryId = {
            text: response.data.content.countryName,
            value: response.data.content.country_id,
          };
          self.stateName = response.data.content.stateName;
        })
        .catch(function (error) {
          self.catchHandler(error, function () {});
        });
    },
    changeCountry(value) {},
    checkForm() {
      let checked = true;
      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }

      if (
        self.stateName.trim() === "" ||
        self.stateName === null ||
        !self.stateName.trim()
      ) {
        self.stateError = "Please enter state";
        if (checked) {
          checked = false;
        }
      }

      return checked;
    },
    closeModalPopup() {
      self.resetFormData();
      self.modalInstance.hide();
      this.$emit("call-close-add-state-popup");
    },
    resetFormData() {
      self.isCountryRecordNotFound = false;
      self.countryError = "";
      self.stateName = "";
      self.stateError = "";
      self.code = "";
      self.stateId = "";
    },
    saveState() {
      if (self.checkForm()) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        $("#commonLoaderAddStateFormPopup")
          .find(".loading-container.lodingTable")
          .show();

        var data = {};
        data.id = self.stateId;
        data.code = self.code;
        data.state_name = self.stateName;
        data.country_id =
          self.countryId && self.countryId.value ? self.countryId.value : "";

        HTTP.post("/state/store", data, config)
          .then(function (response) {
            $("#commonLoaderAddStateFormPopup")
              .find(".loading-container.lodingTable")
              .hide();
            if (response.data && response.data.status == "success") {
              self.showSAlert(response.data.message, "success", false);
              self.closeModalPopup();
            }else if (response.data  && response.data.status == "error" && response.data.content.length > 0) {
              var err = "";
              for (var i = 0; i < response.data.content.length; i++) {
                  err += response.data.content[i] + "<br/>";
              }
              self.showSAlert(err, 'error', false);
            }
          })
          .catch(function (error) {
            $("#commonLoaderAddStateFormPopup")
              .find(".loading-container.lodingTable")
              .hide();
            self.catchHandler(error, function () {});
          });
      }
    },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>

<style></style>
