<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_commission_inventory_master')">
            <div class="container-fluid xyz">
                <template v-if="isAddFrom">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>

                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                </template>
                <template v-else>
                    <!--<div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                            <span>{{pageTitle}}</span>
                        </div>
                    </div>-->
                </template>
            </div>

            <!--page content-->
            <div class="admin-page-content">
                <!--Forms-->

                <div v-show="nocommissionAddedWarning" class="notification-block">
                    <b-alert variant="danger" show dismissible>
                        <i class="fa fa-info-circle" aria-hidden="true"></i> No commission added
                    </b-alert>
                </div>

                <div class="forms-container add_user_forms_container">
                    <div class="row no-margin">

                        <div class=" forms-block">
                            <button class="btn btn-primary" @click="addRow"> Add Commission</button>
                        </div>
                        <div class="clearfix"></div>

                        <template class="" v-for="(input, index) in inputs">
                            <!--User -->
                            <div class="row col-12 forms-block">
                                
                                <div class="col-md-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.user_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                                  v-model="input.userId"
                                                  @change="changedValue"
                                                  @input="commonError = ''"
                                                  name="customPerPage"
                                                  id="customPerPage"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="userArr"
                                                  :placeholder="staticMessages.user_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>

                                <!--Commission Type -->
                                <div class="col-md-5  forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.commission_type_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select 
                                                  v-model="input.commissionTypeId"
                                                  @change="changedValue"
                                                  @input="commonError = ''"
                                                  name="customPerPage"
                                                  id="customPerPage"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="commissionTypeArr"
                                                  :placeholder="staticMessages.commission_type_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>
                                
                                <div class="col-md-1 forms-block">
                                    <button class="btn btn-danger" @click="deleteRow(index)"><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                            
                            <div class="row col-12 forms-block">
                                <!--Amount -->
                                <div class="col-md-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">Amount :</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="commonError = ''" id="amount" v-model="input.amount" class="form-control" type="text"/>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="clearfix"></div>
                        </template>

                        <span class="help is-danger">{{commonError}}</span>

                    </div>

                    <!--Error Alert-->
                    <div class="row clearfix" >
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="alert alert-danger" id="alertError" style="display: none;">
                                <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                <strong id="errorMsg" class="error"></strong>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <!--Buttons-->
                    <div class="row clearfix"></div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <!--<input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">-->
                    </div>
                </div>


                <!-- Forms -->
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-show="isAddFrom"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",
                inputs: [],


                commonError : "",
                commissionTypeId : "",
                commissionTypeArr : [],
                nocommissionAddedWarning : false,

                userId : "",
                userArr : [],
                amount : "",

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                maxInputLength : 0,
                commissionArrayLength : 0,
                userArrayLength : 0,
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                isAddFrom: true,
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch : {
            commissionTypeArr : function() {
                self.commissionArrayLength = self.commissionTypeArr.length
            },
            userArr : function() {
                self.userArrayLength = self.userArr.length;
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            //self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.isAddFrom = false;
                self.contentClass = "spaced-container";
                self.getAssetCommissionDetailsById();
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
            self.loadAllData(['CommissionType', 'User'], true, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('CommissionType', true, self.loadCommissionTypeCallback, "undefined", '');
            self.loadAllData('User', true, self.loadUserTypeCallback, "undefined", ''); */
        },
        methods: {
            addRow() {
                if (self.userArrayLength > self.commissionArrayLength) {
                    self.maxInputLength = self.userArrayLength;
                } else if (self.commissionArrayLength > self.userArrayLength) {
                    self.maxInputLength = self.commissionArrayLength;
                }

                if (self.maxInputLength > self.inputs.length) {
                    this.inputs.push({
                        userId: '',
                        commissionTypeId: '',
                        amount : "",
                    })
                } else {
                    self.commonError = "You can not add more commissions";
                }

            },
            deleteRow(index) {
                this.inputs.splice(index,1);
                self.commonError = "";
            },
            returnData: function () {
                var data = {
                    user_commission_ids : self.inputs,
                }
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.id = self.$route.params.id;
                }

                return data;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadMasterDataCallback: function() {
                self.commissionTypeArr = callbackData.CommissionType;
                self.userArr = callbackData.User;
            },
            /* loadCommissionTypeCallback: function (callbackData) {
                self.commissionTypeArr = callbackData.CommissionType;
            },
            loadUserTypeCallback: function (callbackData) {
                self.userArr = callbackData.User;
            }, */
            buttonBack() {
                // self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            getAssetCommissionDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },

                    params : {
                        id :self.$route.params.id,
                    }
                };
                HTTP.get('/asset/get/commission', config)
                    .then(response => {
                        if (response.data.code == 200) {
                            self.nocommissionAddedWarning = false;
                            for (let i = 0; i < response.data.content.length; i++) {
                                this.inputs.push({
                                    userId: {"text":response.data.content[i]['user_name'],"value":response.data.content[i]['user_id']},
                                    commissionTypeId: {"text":response.data.content[i]['commission_name'],"value":response.data.content[i]['commission_id']},
                                    amount: response.data.content[i].amount,
                                })
                            }
                        } else {
                            self.nocommissionAddedWarning = true;
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },

            checkForm: function () {
                var checked = true;
                for (let i = 0; i < self.inputs.length; i++) {
                    if (!self.inputs[i]['userId'] || !self.inputs[i]['commissionTypeId'] || !self.inputs[i]['amount']) {
                        self.commonError = "Please fill all the details";
                        if (checked) {
                            checked = false;
                            return;
                        }
                    }
                }
                for (let i = 0; i < self.inputs.length; i++) {
                    for (let j = i + 1; j < self.inputs.length; j++) {
                        if (self.inputs[i]['userId']['value'] === self.inputs[j]['userId']['value'] && self.inputs[i]['commissionTypeId']['value'] === self.inputs[j]['commissionTypeId']['value']) {
                            self.commonError = "You can not add same commission for same user";
                            if (checked) {
                                checked = false;
                                return;
                            }
                        }
                    }
                }

                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/asset/add/update/commission", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.nocommissionAddedWarning = false;

                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
</script>