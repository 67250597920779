<template>
  <div
    class="modal fade"
    id="contact-manager-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="location-manager-form"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="col-6">
            <h5 class="modal-title" id="location-manager-form">
              {{ pageTitle }}
            </h5>
          </div>
          <div class="col-6">
            <div class="row justify-content-end">
              <button
                type="button"
                class="btn btn-outline-dark mr-1"
                aria-label="Close"
                @click="closeModal()"
              >
                Close
              </button>
              <button
                type="button"
                :value="buttonNames.save"
                class="btn btn-primary"
                @click="submitData()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
              <!--page content-->
              <div class="location-manager">
                <!--Forms-->
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                   <div class="row no-margin">
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.manager_type_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <template>
                      <v-select
                        v-model="contact"
                        @input="contactError=''"
                        name="staticValue"
                        label="text"
                        :clearable="false"
                        :options="contactTypeList"
                        :closeOnSelect="true"
                        class="add_user_forms_container"
                        :placeholder="staticMessages.manager_type_place_holder"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isManagerTypeRecordNotFound">
                            {{ staticMessages.managed_by_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="text-danger">{{ contactError }}</span>
                    </template>
                  </div>
                </div>

                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.assign_user_label }}
                      <!-- User : -->
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      :multiple="!isEditForm"
                      v-model="userId"
                      name="asset"
                      label="text"
                      @input="userError = ''"
                      :options="userArr"
                      @search="onSearchUser"
                      :clearable="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :placeholder="staticMessages.assign_user_place_holder"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isUserRecordNotFound">
                          {{ staticMessages.assign_used_search }}
                        </template>
                        <template v-else>
                          {{ staticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ userError }}</span>
                  </div>
                </div>
                </div>
                </div>
                <!-- Forms -->
              </div>
              <!--page content-->
            </div>
      </div>
    </div>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from "../event-bus";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import { HTTP } from '../../http-common';

Vue.component("app-footer", Footer);

export default {
  name: "AddStaticValueLocationManager",
  components: {
    "no-access": NoAccessPage,
  },
  mixins: [commonMixins],
  props: ["editData"],
  data() {
    return {
      staticInputLabelMessages: "",
      staticMessages: "",
      pageTitle: "Add Location Contact",
      userArr: [],
      contactTypeList: [],
      userId: "",
      contact: "",
      userError: "",
      contactError: "",
      isUserRecordNotFound: false,
      isManagerTypeRecordNotFound: false,
      buttonNames: {
        save: "Save",
        update: "Update",
      },
      isEditForm: false,
      showConatactManagerLoader: false,
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.loadAllData(
      ["User"],
      true,
      self.loadUserTypeCallback,
      "undefined",
      ""
    );
    self.loadAllMasterData(['DealStatus'], self.loadDealStatusCallback, "", {req_for: "LocationContactType"});
  },
  methods: {
    loadUserTypeCallback: function (callbackData) {
      self.isUserRecordNotFound = false;
      self.userArr = callbackData.User;
      if (self.userArr.length == 0) {
        self.isUserRecordNotFound = true;
      }
    },
    onSearchUser(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadUserTypeCallback,
          loading,
          search
        );
      }
    },
    loadDealStatusCallback: function (callbackData) {
      self.contactTypeList = callbackData.DealStatus;
    },
    submitData() {
      if(self.checkForm()) {
        self.showConatactManagerLoader = true;
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const data = {
          contact_type: self.contact,
          user_list: self.userId,
          location_id: self.$route.params.id,
        };
        
        if(self.isEditForm) {
          HTTP.put("/location/contacts/"+self.editData.id, data, config)
          .then(function (response) {
            self.showConatactManagerLoader = false;
            if (response.data.code == '200') {
              self.showSAlert(
                response.data.message,
                response.data.status,
                false,
                2
              );
              self.closeModal('update');
            }
          })
          .catch(function (err) {
            console.log("Update Location Contact Error>>>", err);
            self.catchHandler(err, function () {});
          });
        } else {
          HTTP.post("/location/contacts", data, config)
          .then(function (response) {
            self.showConatactManagerLoader = false;
            if (response.data.code == '200') {
              self.showSAlert(
                response.data.message,
                response.data.status,
                false,
                2
              );
              self.closeModal('save');
            }
          })
          .catch(function (err) {
            console.log("Save Location Contact Error>>>", err);
            self.catchHandler(err, function () {});
          });
        }
      }
    },
    checkForm: function () {
      var checked = true;
      if (self.userId == null || self.userId.length==0) {
        self.userError = "Please select user";
        if (checked) {
          checked = false;
        }
      }
      if (self.contact == null || !self.contact.value) {
        self.contactError = "Please select contact";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    clearData() {
        self.userId = '';
        self.contact = '';
        self.userError = '';
        self.contactError = '';
        self.isEditForm = false;
        self.showConatactManagerLoader = false;
    },
    closeModal(type=null) {
      self.clearData();
      EventBus.$emit("close-contact-manager-default", false, type);
    },
  },
  watch: {
    editData(data) {
      if(Object.keys(data).includes('assigned_user')) {
        self.isEditForm = true;
        self.$nextTick(() => {
          self.userId = JSON.parse(data.assigned_user);
        });
        self.contact = JSON.parse(data.contact_type);
      }
    }
  }
};
</script>

<style>
 .button {
    padding-right: 30px;
    padding-bottom: 20px;
 }
 .box {
  height: 240px;
 }
</style>
