<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="spaced-container">
                <div class="col-12 page_title paddingnone">
                    <div class="page_title_left">
                        <span>{{pageTitle}}</span>
                        
                    </div>
                    <template v-if="zipFile != ''">
                        <a href="#" @click="getSignedUrlPath(zipFile)" class="btn btn-primary waves-effect pull-right">Download All</a>
                    </template>
                </div>
            </div>
            <!-- page content -->
            <div class="admin-page-content">
                <!-- Forms -->
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">
                        <div class="col-md-12 forms-block">
                            <template v-if="documentArr && documentArr.length > 0">
                                <swiper class="" :options="swiperOptionAttachments" ref="swiperAttachment">
                                    <template v-for="(doc, index) in documentArr">
                                        <template v-if="doc.doc_extension.toLowerCase() == 'gif' || doc.doc_extension.toLowerCase() == 'jpg' || doc.doc_extension.toLowerCase() == 'jpeg' || doc.doc_extension.toLowerCase() == 'png'">
                                            <swiper-slide :key="'image ' + index">
                                                <img style="width: 100%; height: 100px; object-fit: cover;" :src="doc.media_path" @click="openGallery(documentArr, index)">
                                            </swiper-slide>
                                        </template>
                                        <template v-else-if="doc.doc_extension.toLowerCase() == 'doc' || doc.doc_extension.toLowerCase() == 'docx'">
                                            <swiper-slide :key="'doc ' + index">
                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img src="/static/images/doc-icon.png" class="link-icon"></a>
                                            </swiper-slide>
                                        </template>
                                        <template v-else-if="doc.doc_extension.toLowerCase() == 'xls' || doc.doc_extension.toLowerCase() == 'xlsx'">
                                            <swiper-slide :key="'xls ' + index">
                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img src="/static/images/xl-icon.png" class="link-icon"></a>
                                            </swiper-slide>
                                        </template>
                                        <template v-else-if="doc.doc_extension.toLowerCase() == 'ppt' || doc.doc_extension.toLowerCase() == 'pptx'">
                                            <swiper-slide :key="'ppt ' + index">
                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img src="/static/images/ppt-icon.png" class="link-icon"></a>
                                            </swiper-slide>
                                        </template>
                                        <template v-else-if="doc.doc_extension.toLowerCase() == 'pdf'">
                                            <swiper-slide :key="'pdf ' + index">
                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img src="/static/images/pdf-icon.png" class="link-icon"></a>
                                            </swiper-slide>
                                        </template>
                                        <template v-else>
                                            <swiper-slide :key="'other ' + index">
                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img src="/static/images/link-icon.png" class="link-icon"></a>
                                            </swiper-slide>
                                        </template>
                                    </template>
                                </swiper>
                            </template>
                            <template v-else>
                                No Document(s) found!!
                            </template>
                        </div>
                    </div>
                    <b-modal size="lg" class="gallery_modal" id="modal1" @cancel="onCancel('main')" ref="myModalRef" hide-footer :hide-header=true>
                        <img class="w-100 mb-3" :src="viewImage">
                        <div class="clearfix"></div>
                        <div class="image-arrows customIconModelFooter clearfix">
                            <button type="button" class="ml-2 btn btn-primary pull-right themebutton" v-on:click="changeImage('next')"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                            <button type="button" class="ml-2 btn btn-primary pull-right themebutton" v-on:click="changeImage('previous')"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                            <button class="ml-2 btn btn-primary pull-right" type="button" v-on:click="getSignedUrlPath(viewImageName)"><i class="fa fa-download" aria-hidden="true"></i></button>
                        </div>
                    </b-modal>
                </div>
                <!-- Forms -->
            </div>
            <!-- page content -->
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var exten = ['gif', 'jpg', 'jpeg', 'png'];
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    Vue.component("app-footer", Footer);
    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */

    export default {
        name: 'ViewDocument',
        components: {
            'no-access': NoAccessPage,
            swiper,
            swiperSlide,
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "",
                zipFile: "",
                documentArr: [],
                lightboxImages: [],
                lightboxImagesName: [],
                viewImage: '',
                viewImageName: '',
                index: 0,
                swiperOptionAttachments: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getDocuments();
            }
        },
        methods: {
            getDocuments: function() {
                var config = {
                    params: {
                        id: self.$route.params.id,
                        modelname: self.$route.params.modelname,
                    }
                };
                HTTP.get('/document/view', config)
                    .then(response => {
                        if(response.data.code == 200) {
                            self.documentArr = response.data.content.documents;
                            self.pageTitle = response.data.content.title;
                            self.zipFile = response.data.content.zipfile;
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            openGallery: function (documents, index) {
                var self = this;
                var arr = [];
                var file_name_arr = [];
                var new_index = 0;
                $.each(documents, function (i) {
                    if (jQuery.inArray(documents[i].doc_extension.toLowerCase(), exten) !== -1) {
                        if (i == index) {
                            index = new_index;
                        }
                        arr.push(documents[i].media_path);
                        file_name_arr.push(documents[i].file_name);
                        new_index = new_index + 1;
                    }
                });
                self.loadImagesInLightBox(arr, index, file_name_arr);
            },
            onCancel: function (type) {
            },
            changeImage: function (move) {
                var self = this;
                if (move == 'next') {
                    self.index = self.index + 1;
                    if (self.lightboxImages.length <= self.index) {
                        self.index = 0;
                    }
                } else {
                    self.index = self.index - 1;
                    if (self.index < 0) {
                        self.index = (self.lightboxImages.length - 1);
                    }
                }
                self.viewImage = self.lightboxImages[self.index];
                self.viewImageName = self.lightboxImagesName[self.index];
            },
            /* set images to gallery view */
            loadImagesInLightBox: function (arr, index, file_name_arr) {
                var self = this;
                self.lightboxImages = arr;
                self.lightboxImagesName = file_name_arr;
                self.index = index;
                self.viewImage = self.lightboxImages[self.index];
                self.viewImageName = self.lightboxImagesName[self.index];
                self.$refs.myModalRef.show();
            },
        },
    }
</script>
