<template>
    <div class="">

        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_add_apu_model_master')">
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->
                        <div :class="alertClass" id="success-alert">
                            <strong> {{alertMessage}} </strong>
                        </div>
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <span class="help is-danger" v-if="id > 0 && linkCountAsset > 0">
                            Are you sure that you want to update this Apu Model! It is link with <strong> {{linkCountAsset}} </strong> Inventory(s).
                        </span>
                            <div class="row no-margin">
                                <!--Name-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{nameError}}</span>
                                    </div>
                                </div>
                                <!--Apu Make-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.apu_make_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select title="Select Apu Make"
                                            v-model="apuMakeId"
                                            @change="changedValue"
                                            @input="apuMakeError = ''"
                                            name="apuMake"
                                            id="apuMake"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="apuMakeArr"
                                            :placeholder="staticMessages.apu_make_place_holder">
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{apuMakeError}}</span>
                                    </div>
                                </div>
                                <!--Error Alert-->
                                <div class="row clearfix" >
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="alert alert-danger" id="alertError">
                                            <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                            <strong id="errorMsg" class="error"></strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                <!--Buttons-->
                                <div class="row clearfix"></div>
                                <div class="button-demo">
                                    <input id="addApuModelBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addApuModel()">
                                    <input id="addAndContinueApuModelBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addApuModel(flag='Save and Continue')">
                                    <input id="cancelApuModel" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <!--page content-->
        </div>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    export default {
        name: 'AddUpdateApuModel',
        mixins: [commonMixins],
        components: {
            'app-footer': Footer,
            'no-access' : NoAccessPage,
        },
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                nameError: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Apu Model",
                id: 0,
                linkCountAsset: 0,
                apuMakeId: "",
                apuMakeArr: [],
                apuMakeError: "",
                permissionNameArray : ['can_add_apu_model_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('apumodel-list');
            EventBus.$emit("menu-selection-changed", "apumodel-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.id = self.$route.params.id;
                self.getApuModelDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueApuModelBtn").hide();
                self.pageTitle = "Edit Apu Model";
            }
            self.loadAllData(['ApuMake'], false, self.loadApuMakeDataCallback);
        },
        methods: {
            loadApuMakeDataCallback: function (callbackData) {
                self.apuMakeArr = callbackData.ApuMake;
            },
            buttonBack() {
                self.$router.push('/apu/model/list');
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
                self.apuMakeId = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            getApuModelDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/apu/model/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.linkCountAsset = response.data.content.countApuModel;
                        self.apuMakeId = {text: response.data.content.apuMakeName, value: response.data.content.apuMakeId};
                    })
                    .catch(function (err) {
                        /* console.log(err); */
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.apuMakeId == null || !self.apuMakeId.value) {
                    self.apuMakeError = "Please select apu make";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addApuModel(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        data.append('name', self.name);
                        data.append('apu_make_id', self.apuMakeId.value);
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/apu/model/add/update", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>