<template xmlns:onfocusout="http://www.w3.org/1999/xhtml" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_dtna_ordered_and_canceled_inventory_master')">
            <div class="container-fluid xyz" v-if="showMainList">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>DTNA Ordered and Cancelled Inventory</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} Results found</p>
                        </div>
                        <!-- <router-link v-show="permissionNameArray.includes('can_add_inventory_master')" class="btn btn-primary waves-effect pull-right ml-2" title='Add Inventory' v-bind:to="{name: 'AddAsset'}">
                            <span>Add Inventory</span>
                        </router-link> -->
                        <!--<router-link class="mr-2 btn btn-primary waves-effect pull-right" title='Add Inventory' v-bind:to="{name: 'AssetTabView'}">
                            <span>Add Inventory Tab</span>
                        </router-link>-->
                        <a
                                id='refreshList'
                                title='Refresh'
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                        <!-- <a
                                id='exportAssetExpense'
                                title='Export'
                                v-show="permissionNameArray.includes('can_export_inventory_master')"
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="exportAssetExpense($event)">
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </a> -->
                        <!-- <button
                                id='exportAsset'
                                title='Export'
                                v-show="permissionNameArray.includes('can_export_available_date_inventory_master')"
                                class="extraFeature btn btn-success waves-effect pull-right ml-2"
                                v-on:click.prevent="exportAssetAvailableData($event)">
                            <i class="fa fa-cloud-download" aria-hidden="true"> Available Age Export</i>
                        </button> -->
                        <!-- <router-link v-show="permissionNameArray.includes('can_show_delete_inventory_list_inventory_master')" class="btn btn-primary waves-effect pull-right ml-2" title='Delete Inventory List' v-bind:to="{name: 'DeleteAssetList'}">
                            <span>Delete Inventory List</span>
                        </router-link> -->
                    </div>
                    <div class="vdc-filters-container">
                        <div v-on:click="toggleFilterBlock()" class="vdc-filters-block">
                            <span>{{allLabelMessages.filters}}</span> 
                            <span class="badge badge-primary badge-pill bg-color-098de1" v-if="filterCount > 0">{{filterCount}} Filter<template v-if="filterCount > 1">s</template> applied</span>
                            <button>
                                <img src="/static/images/filter_icon.png">
                            </button>
                        </div>
                        <div v-show="isOpenFilterBlock" class="vdc-filters-area">
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.sale_date}}</label>
                                <div class="clearfix"></div>
                                <div class="col-12 paddingleft0">
                                    <VueCtkDateTimePicker 
                                        v-model="filters.sale_date"
                                        range
                                        :only-date="datePickerOptions.onlyDate"
                                        :only-time="false"
                                        :output-format="datePickerOptions.outputFormat"
                                        :format="datePickerOptions.inputFormat"
                                        :formatted="datePickerOptions.formatted"
                                        :noClearButton="datePickerOptions.noClearButton"
                                        :custom-shortcuts="datePickerOptions.customShortcuts"
                                        :maxDate="datePickerOptions.maxDate"
                                    ></VueCtkDateTimePicker>
                                    <button title="Reset" v-on:click="filters.sale_date = ''; resetFilters('date', 'sale_date')" class="btn btn-primary clearDateFilter">
                                        <i class="fa fa-times" aria-hidden="true"></i> -->
                                        <!--<i class="fa fa-refresh" aria-hidden="true"></i>-->
                                    <!-- </button>
                                </div>
                            </div> -->

                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.purchase_date_label_filter}}</label>
                                <div class="clearfix"></div>
                                <div class="col-12 paddingleft0">
                                    <VueCtkDateTimePicker
                                            v-model="filters.purchase_date"
                                            range
                                            :only-date="datePickerOptions.onlyDate"
                                            :only-time="false"
                                            :output-format="datePickerOptions.outputFormat"
                                            :format="datePickerOptions.inputFormat"
                                            :formatted="datePickerOptions.formatted"
                                            :noClearButton="datePickerOptions.noClearButton"
                                            :custom-shortcuts="datePickerOptions.customShortcuts"
                                            :maxDate="datePickerOptions.maxDate"
                                    ></VueCtkDateTimePicker>
                                    <button title="Reset" v-on:click="filters.purchase_date = ''; resetFilters('date', 'purchase_date')" class="btn btn-primary clearDateFilter">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> -->

                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.vin_no_stock}}</label>
                                <input class="form-control" v-on:keyup.enter="changepage()" placeholder="Vin No, Stock" v-model="filters.vin_no"  name="vin_no"  type="text">
                            </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.salesman_filter}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.salesman" 
                                            name="salesman"
                                            label="text"
                                            @search="onSearchSalesman"
                                            :options="filterSalesmanOptions"
                                            :clearable="true" 
                                            :placeholder="allLabelMessages.salesman_filter"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.salesman" 
                                    :options="filterSalesmanOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @search-change="onSearchSalesman"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedSalesmanFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearSalesmanFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.vendor}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.vendor" 
                                            name="vendor"
                                            label="text"
                                            @search="onSearchVendor"
                                            :options="filterVendorOptions"
                                            :clearable="true" 
                                            :placeholder="allLabelMessages.vendor"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.vendor" 
                                    :options="filterVendorOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @search-change="onSearchVendor"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedVendorFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearVendorFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div> -->
                            <div style="display:none;" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.status}}</label>
                                <div class="col-12 paddingleft0">
                                    <v-select 
                                            multiple
                                            v-model="filters.status" 
                                            name="status"
                                            :placeholder="allLabelMessages.status"
                                            label="text"
                                            :options="filterStatusOptions"
                                            class=""
                                            >
                                            <!--<template #selected-option-container="{ option, deselect, multiple, disabled }">
                                                <div class="selected-tag">
                                                    {{ filters.status.length }}
                                                </div>
                                            </template>-->
                                            <!--<template slot="selection" slot-scope="data">
                                                {{ data.item.text }} - {{ data.item.value }}
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }} - {{ data.item.value }}
                                            </template>-->
                                            <!--<template #selected-option="{ text, value}">
                                                <span :title="text">{{ text }}</span>
                                            </template>-->
                                    </v-select>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.status}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.status" 
                                    :options="filterStatusOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeStatusFilter()"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedStatusFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div v-show="isHoldSelected == true" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.hold_status}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect
                                    v-model="filters.hold_status" 
                                    :options="filterHoldStatusOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeHoldStatusFilter()"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedHodStatusFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearHoldStatusFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.make}}</label>
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.make" 
                                            name="make"
                                            :placeholder="allLabelMessages.make"
                                            label="text"
                                            @change="makeValueChanged"
                                            :options="filterMakeOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <div class="col-12 paddingleft0">
                                    <multiselect 
                                    @input="makeValueChanged"
                                    v-model="filters.make" 
                                    :options="filterMakeOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedMakeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearMakeFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.model}}</label>
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.model" 
                                            name="model"
                                            :placeholder="allLabelMessages.model"
                                            label="text"
                                            :options="filterModelOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.model" 
                                    :options="filterModelOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    >
                                        <!--Dropdown options Custom Slot-->
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedModelFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <!-- Selection Custom Slot -->
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <span class="multiselect__single pull-right" v-on:click="clearModelFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <!--Year Filter-->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.year}} From</label>
                                <date-picker
                                        id="fromYear"
                                        v-model="filters.year.fromYear"
                                        :config="fromOptions"
                                        class="form-control"
                                        autocomplete="off"
                                        placeholder="From Year"
                                        name="issueDate"
                                        @input="changeFilterFromDate()"
                                >
                                </date-picker>
                            </div> -->

                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.year}} To</label>
                                <date-picker
                                        id="toYear"
                                        v-model="filters.year.toYear"
                                        :config="toOptions"
                                        class="form-control"
                                        autocomplete="off"
                                        placeholder="To Year"
                                        name="issueDate"
                                        @input="changeFilterToDate()"
                                >
                                </date-picker>
                                <span class="help is-danger">{{yearFilterError}}</span>
                            </div> -->

                            <!--Price Filter-->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.price_from}}</label>
                                <input class="form-control" @blur="handleBlurFromPrice" v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.price_from" v-model="filters.price.fromPrice" id="fromPrice"  name="price"  type="text" autocomplete="off">
                            </div> -->
                            <!-- <div v-show="isHoldSelected == false" class="clearfix"></div> -->

                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.price_to}}</label>
                                <input class="form-control" @blur="handleBlurToPrice"  v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.price_to" v-model="filters.price.toPrice" id="toPrice"  name="price"  type="text" autocomplete="off">
                                <span class="help is-danger">{{priceFilterError}}</span>
                            </div>
                            <div v-show="userCompany.length > 1" class="vdc-filters-area-block">
                                <label>{{allLabelMessages.company_label_filter}}</label>
                                <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.department" 
                                    :options="filterCompanyOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @input="changeDepartmentFilter()"
                                    > -->

                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedDepartmentFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length == 1">{{ values[0].text }}</span>
                                            <span class="multiselect__single pull-right" v-on:click="clearDepartmentFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div> -->
                             <!-- <div v-show="isHoldSelected == true" class="clearfix"></div>
                                <div class="vdc-filters-area-block">
                                    <label>{{allLabelMessages.location}}</label>
                                    <div class="col-12 paddingleft0">

                                        <multiselect
                                                v-model="filters.location"
                                                :options="filterlocationOptions"
                                                :multiple="true"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                :preserve-search="true"
                                                placeholder="Select one"
                                                label="text"
                                                track-by="text"
                                                :preselect-first="true"
                                                class="form-control"
                                                @input="changeLocationFilter()"
                                        > -->

                                            <!--Dropdown options Custom Slot-->
                                            <!-- <template slot="option" slot-scope="props">
                                                <div v-if="appliedLocationFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                    <span>{{ props.option.text }}</span>
                                                </div>
                                                <div v-else class="multiple_select_item">
                                                    <span>{{ props.option.text }}</span>
                                                </div>
                                            </template> -->
                                            <!-- Selection Custom Slot -->
                                            <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                                <span class="multiselect__single" v-else-if="values.length == 1">{{ values[0].text }}</span>
                                                <span class="multiselect__single pull-right" v-on:click="clearLocationFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                            </template>
                                        </multiselect>

                                    </div> -->
                                    <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Location" v-model="filters.location"  name="location"  type="text">-->
                                <!-- </div> -->
                            <!-- <template v-if="isDepartmentSelected">
                            </template> -->
                            <!--<div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.lot}}</label>
                                <input class="form-control" v-on:keyup.enter="changepage()" placeholder="Lot" v-model="filters.lot"  name="lot"  type="text">
                            </div>-->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.engine_make}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.engine_make" 
                                            name="engine_make"
                                            :placeholder="allLabelMessages.engine_make"
                                            label="text"
                                            @change="engineMakeValueChanged"
                                            :options="filterEngineMakeOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    @input="engineMakeValueChanged"
                                    v-model="filters.engine_make" 
                                    :options="filterEngineMakeOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedEngineMakeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearEngineMakeFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div> -->
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Make" v-model="filters.engine_make"  name="engine_make"  type="text">-->
                            <!-- </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.engine_model}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.engine_model" 
                                            name="engine_model"
                                            :placeholder="allLabelMessages.engine_model"
                                            label="text"
                                            :options="filterEngineModelOptions"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.engine_model" 
                                    :options="filterEngineModelOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedEngineModelFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearEngineModelFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div> -->
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.engine_model"  name="engine_model"  type="text">-->
                            <!-- </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.cab_style}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                        v-model="filters.cab_style" 
                                        name="cab_style"
                                        :placeholder="allLabelMessages.cab_style"
                                        label="text"
                                        :options="filterCabStyleOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.cab_style" 
                                    :options="filterCabStyleOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedCabStyleFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearCabStyleFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div> -->
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.cab_style"  name="cab_style"  type="text">-->
                            <!-- </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.dtr_trade_terms}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                        v-model="filters.dtr_trade_terms" 
                                        name="dtr_trade_terms"
                                        :placeholder="allLabelMessages.dtr_trade_terms"
                                        label="text"
                                        :options="filterDTROptions"
                                        class=""
                                    >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.dtr_trade_terms" 
                                    :options="filterDTROptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedDTRFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearDTRFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div> -->
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.dtr_trade_terms"  name="dtr_trade_terms"  type="text">-->
                            <!-- </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.fleet}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                        v-model="filters.fleet" 
                                        name="fleet"
                                        :placeholder="allLabelMessages.fleet"
                                        label="text"
                                        :options="filterFleetOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.fleet" 
                                    :options="filterFleetOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedFleetFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearFleetFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div> -->
                                <!--<input class="form-control" v-on:keyup.enter="changepage()" placeholder="Engine Model" v-model="filters.fleet"  name="fleet"  type="text">-->
                            <!-- </div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.body_type_filter}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                        v-model="filters.body_type" 
                                        name="body_type_filter"
                                        :placeholder="allLabelMessages.body_type_filter"
                                        label="text"
                                        :options="filterbodyTypeOptions"
                                        class=""
                                    >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.body_type" 
                                    :options="filterbodyTypeOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedBodyTypeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearBodyTypeFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.trans_model_lbl}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.trans_model" 
                                            name="trans_model"
                                            label="text"
                                            @search="onSearchtransModel"
                                            :options="filterTransModelOptions"
                                            :clearable="true" 
                                            :placeholder="allLabelMessages.trans_model_lbl"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.trans_model" 
                                    :options="filterTransModelOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    @search="onSearchtransModel"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedTransModelFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearTransModelFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.axle_type_label_filter}}</label> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <v-select 
                                            v-model="filters.axle_type" 
                                            name="axle_type"
                                            label="text"
                                            @search="onSearchAxleType"
                                            :options="filterAxleTypeOptions"
                                            :clearable="true" 
                                            :placeholder="allLabelMessages.axle_type_label_filter"
                                            class=""
                                            >
                                    </v-select>
                                </div> -->
                                <!-- <div class="col-12 paddingleft0">
                                    <multiselect 
                                    v-model="filters.axle_type" 
                                    :options="filterAxleTypeOptions" 
                                    :multiple="true" 
                                    :close-on-select="true" 
                                    :clear-on-select="false" 
                                    :preserve-search="true" 
                                    placeholder="Select one" 
                                    label="text" 
                                    track-by="text" 
                                    :preselect-first="true"
                                    class="form-control"
                                    > -->
                                        <!--Dropdown options Custom Slot-->
                                        <!-- <template slot="option" slot-scope="props">
                                            <div v-if="appliedAxleTypeFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template> -->
                                        <!-- Selection Custom Slot -->
                                        <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span> -->
                                            <!--<span class="multiselect__single" v-else></span>-->
                                            <!-- <span class="multiselect__single pull-right" v-on:click="clearAxleTypeFilter()"><i class="fa fa-close" aria-hidden="true"></i> </span>
                                        </template>
                                    </multiselect>
                                </div>
                            </div> -->
                            <!--Price Filter-->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.odometer_from_label}}</label>
                                <input class="form-control" @blur="handleBlurFromOdometer" v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.odometer_from_label" v-model="filters.odometer.fromOdometer" id="fromOdometer"  name="fromOdometer"  type="text" autocomplete="off">
                            </div>
                            <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.odometer_to_label}}</label>
                                <input class="form-control" @blur="handleBlurToOdometer"  v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.odometer_to_label" v-model="filters.odometer.toOdometer" id="toOdometer"  name="toOdometer"  type="text" autocomplete="off">
                                <span class="help is-danger">{{odometerFilterError}}</span>
                            </div>
                            <div v-show="isHoldSelected == true" class="clearfix"></div> -->
                            <!-- <div v-show="isHoldSelected == false" class="clearfix"></div> -->
                            <!-- <div class="vdc-filters-area-block">
                                <label>{{allLabelMessages.age}}</label>
                                <input class="form-control"  v-on:keyup.enter="changepage()" :placeholder="allLabelMessages.age" v-model="filters.age" id="age_from"  name="age"  type="text">
                            </div> -->
                            <div class="clearfix"></div>
                            <div class="">
                                <button class="btn btn-primary" v-on:click="changepage()">Apply Filters</button>
                                <button class="btn btn-primary" v-on:click="resetFilters()">Reset Filters</button>    
                                <button class="btn btn-primary" v-on:click="resetSorting()">Reset Sorting</button>    
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button v-show="permissionNameArray.includes('can_compare_inventory_master')" type="button" v-on:click.prevent="compareAssets()" class="btn btn-primary waves-effect pull-right ml-2">Compare ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_spec_sheet_inventory_master') && showSpecListBtn" type="button" v-on:click.prevent="specListSelectedEmail()" class="btn btn-primary waves-effect pull-right ml-2"><i class="fa fa-envelope-o" aria-hidden="true"></i> Spec List ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_spec_sheet_inventory_master') && showSpecSheetBtn" type="button" v-on:click.prevent="specSheetSelectedEmail()" class="btn btn-primary waves-effect pull-right ml-2"><i class="fa fa-envelope-o" aria-hidden="true"></i> Spec Sheet ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_spec_sheet_inventory_master') && showPromoSheetBtn" type="button" v-on:click.prevent="promoSheetSelectedEmail()" class="btn btn-primary waves-effect pull-right ml-2"><i class="fa fa-envelope-o" aria-hidden="true"></i> Promo Sheet ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_multi-print_inventory_master')" type="button" v-on:click.prevent="printSelected()" class="btn btn-primary waves-effect pull-right ml-2" title='Print Multiple'><i class="fa fa-print" aria-hidden="true"></i> Multi-Print ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_add_inventory_master')" type="button" v-on:click.prevent="editSelected()" class="btn btn-primary waves-effect pull-right ml-2" title='Edit Multiple'><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Multi-Edit ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_delete_inventory_master')" type="button" v-on:click.prevent="deleteAsset()" class="btn btn-primary waves-effect pull-right ml-2" title='Delete Multiple'><i class="fa fa-trash-o" aria-hidden="true"></i> Delete ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_active_deactive_status_inventory_master')" type="button" v-on:click.prevent="activeInactiveAsset(0, 'Active')" class="btn btn-primary waves-effect pull-right ml-2" title='Activate Selected' ><i class="fa fa-eye" aria-hidden="true"></i> Activate ({{selectedRows.length}})<!-- Record(s)--></button>
                        <button v-show="permissionNameArray.includes('can_active_deactive_status_inventory_master')" type="button" v-on:click.prevent="activeInactiveAsset(0, 'Deactive')" class="btn btn-primary waves-effect pull-right ml-2" title='Deactivate Selected'><i class="fa fa-eye-slash" aria-hidden="true"></i> Deactivate ({{selectedRows.length}})<!-- Record(s)--></button>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select ref="select" title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <div class="table-listing white-bg border-blue border-radius-top-right">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="assetArr && assetArr.length > 0">
                                    <template v-for="(asset, index) in assetArr">
                                        <tr class="mainDetails" :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="asset.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + asset.id" name="case[]">
                                                        <label class="custom-control-label" :for="'customCheck' + asset.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions" style="width: 60px;">
                                                    <!-- <a :id="'accordion-' + asset.id" v-on:click="toggleIcon(asset.id)" v-b-toggle="'accordion-' + asset.id"  href="javascript:void(0)" title='Show Extra details' class="mar-right-0">
                                                        <i v-if="accordianIds.includes(asset.id)" class="exceptEdit fa fa-minus" aria-hidden="true"></i>
                                                        <i v-else class="exceptEdit fa fa-plus" aria-hidden="true"></i>
                                                    </a>
                                                    <router-link v-show="permissionNameArray.includes('can_view_inventory_master')" class="mar-right-0" title='Edit Inventory' v-bind:to="{name: 'AssetTabView', params:{id: asset.id}}">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </router-link>
                                                    <a href="javascript:void(0)" class="mar-right-0" @click.stop.prevent="openContextMenu($event, asset.id, asset.slug)">
                                                        <i class="exceptEdit fa fa-cog" aria-hidden="true"></i>
                                                    </a>
                                                    <a v-show="permissionNameArray.includes('can_delete_inventory_master')" href="javascript:void(0)" title='Delete Inventory' class="mar-right-0" v-on:click="deleteAsset(asset.id)">
                                                        <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                    <template v-if="asset.is_active">
                                                        <a v-show="permissionNameArray.includes('can_active_deactive_status_inventory_master')" href="javascript:void(0)" title='Make Deactive Inventory' class="mar-right-0" v-on:click="activeInactiveAsset(asset.id, 'Deactive')">
                                                            <i class="exceptEdit fa fa-eye-slash" aria-hidden="true"></i>
                                                        </a>
                                                    </template>
                                                    <template v-else>
                                                        <a v-show="permissionNameArray.includes('can_active_deactive_status_inventory_master')" href="javascript:void(0)" title='Make Active Inventory' class="mar-right-0" v-on:click="activeInactiveAsset(asset.id, 'Active')">
                                                            <i class="exceptEdit fa fa-eye" aria-hidden="true"></i>
                                                        </a>
                                                    </template> -->
                                                    <!--<router-link v-show="permissionNameArray.includes('can_view_inventory_master')" class="mar-right-0" title='View Inventory' v-bind:to="{name: 'ViewAsset', params:{id: asset.id}}">
                                                        <i class="exceptEdit fa fa-eye" aria-hidden="true"></i>
                                                    </router-link>-->
                                                    <!-- <a title='Image(s) available' v-if="asset.image_count > 0" href="javascript:void(0)" class="mar-right-0">
                                                        <i  class="exceptEdit fa fa-file-image-o" aria-hidden="true"></i>
                                                    </a>-->
                                                    <router-link v-show="permissionNameArray.includes('can_view_inventory_master')" class="mar-right-0" title='Edit Inventory' v-bind:to="{name: 'AssetTabView', params:{id: asset.id, redirectSlug: '/inventory/list/onorder'}}">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </router-link>
                                                </div>
                                            </td>
                                            <!-- <td :class="rowClass.photo">
                                                <img v-if="asset.media_thumb != ''" :src="asset.media_thumb" style="height: 40px;"/>
                                            </td> -->
                                            <td :class="rowClass.branch_ordered">{{asset.branch_ordered}}</td>
                                            <td :class="rowClass.serial_no">{{asset.serial_no}}</td>
                                            <td :class="rowClass.order_status">
                                                <!--<i v-if="asset.status == 'Live'" class="color-71aa3f fa fa-square" aria-hidden="true"></i>
                                                <i v-else class="color-9c1a22 fa fa-square" aria-hidden="true"></i>-->
                                                <span v-if="asset.status == 'Active' || asset.status == 'Live'" class="badge badge-primary badge-pill bg-color-71aa3f">
                                                    {{asset.status}}
                                                </span>
                                                <span v-else-if="asset.status == 'On-Order'" class="badge badge-primary badge-pill bg-color-0771b4">
                                                    {{asset.status}} <template v-if="asset.hold_status_id != null && asset.hold_status_id != 'null'">({{asset.hold_status_name}})</template>
                                                </span>
                                                <span v-else-if="asset.status == 'Pending'" class="badge badge-primary badge-pill bg-color-cc9636">
                                                    {{asset.status}}
                                                </span>
                                                <span v-else-if="asset.status == null"  class="">
                                                </span>
                                                <span v-else  class="badge badge-primary badge-pill bg-color-c3202b">
                                                    {{asset.status}}
                                                </span>
                                            </td>
                                            <td :class="rowClass.year">{{asset.year}}</td>
                                            <td :class="rowClass.make">{{asset.make}}</td>
                                            <td :class="rowClass.model">{{asset.model}}</td>
                                            <td :class="rowClass.status_date">{{asset.status_date}}</td>
                                            <td :class="rowClass.insert_date">{{asset.insert_date}}</td>
                                            <td :class="rowClass.insert_error">{{asset.insert_error}}</td>
                                            <td :class="rowClass.insert_dms_error">{{asset.insert_dms_error}}</td>
                                            <td :class="rowClass.update_date">{{asset.update_date}}</td>
                                            <td :class="rowClass.update_error">{{asset.update_error}}</td>
                                            <td :class="rowClass.update_dms_error">{{asset.update_dms_error}}</td>
                                            <!-- <td :class="rowClass.age">{{asset.purchase_age}}</td> -->
                                            <!-- <td :class="rowClass.purchase_date">{{asset.purchase_date}}</td> -->
                                            <!-- <td :class="rowClass.sale_date">{{asset.sale_date}}</td>
                                            <td :class="rowClass.salesman">{{asset.salesman_name}}</td>
                                            <td :class="rowClass.vendor">{{asset.vendor}}</td> -->
                                            <!-- <td :class="rowClass.type">{{asset.is_truck}}</td>
                                            <td :class="rowClass.year">{{asset.year}}</td>
                                            <td class="text-right">{{asset.odometer}}</td>
                                            <td :class="rowClass.location">{{asset.lot_city_lot}}</td> -->
                                            <!-- <td :class="rowClass.price">{{asset.rprice}}</td> -->
                                            <!-- <td :class="rowClass.department">{{asset.company_name}}</td> -->
                                            <!--<td class="text-right">{{asset.rprice | numeral('$ 0,0[.]00')}}</td>-->
                                            <!--<td :class="rowClass.lot">{{asset.lot}}</td>-->
                                            <!-- <td :class="rowClass.engine_make">{{asset.engine_make_name}}</td>
                                            <td :class="rowClass.engine_model">{{asset.engine_model}}</td>
                                            <td :class="rowClass.hp">{{asset.engine_hp}}</td> -->
                                            <!--<td :class="rowClass.asking_retail_usd">{{asset.asking_retail_usd}}</td>
                                            <td :class="rowClass.min_retail_usd">{{asset.min_retail_usd}}</td>
                                            <td :class="rowClass.high_retail_usd">{{asset.high_retail_usd}}</td>
                                            <td :class="rowClass.wholeshale_usd">{{asset.wholeshale_usd}}</td>-->
                                            <!--<td :class="rowClass.quantity">{{asset.quantity}}</td>-->
                                            <!-- <td :class="rowClass.trans_type">{{asset.trans_type_name}}</td>
                                            <td :class="rowClass.trans_make">{{asset.trans_make_name}}</td> -->
                                            <!-- <td :class="rowClass.trans_model">{{asset.trans_model}}</td> -->
                                            <!-- <td :class="rowClass.dtr_trade_terms_name">{{asset.dtr_trade_terms_name}}</td>
                                            <td :class="rowClass.fleet_name">{{asset.fleet_name}}</td> -->
                                            <!-- <td :class="rowClass.trans_speed">{{asset.trans_speed}}</td> -->
                                        </tr>

                                        <tr class="extraDetails" :key="asset.id" :id="asset.id">
                                            <td colspan="2">

                                            </td>
                                            <td :colspan="headers.length - 1" class="text-alignment-left">
                                                <list-accordian :accordianData="asset" :displayFields="returnAccordianDisplayDataKeys()"></list-accordian>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{allStaticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBox"></label>
                                            </div>
                                        </th>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" :key="headerIndex" v-if="data.name != 'Action'" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select ref="select" title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <!-- v-if="totalRecords >= perPage" -->
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                        <!-- Vue Context Menu Start -->
                        <vue-context ref="menu" :close-on-click="closeOnClick"
                                     :close-on-scroll="closeOnScroll"
                                     :item-selector="itemSelector"
                                     >
                            <li>
                                <a v-show="permissionNameArray.includes('can_spec_sheet_inventory_master')" href="javascript:void(0)" title='Print Spec (Personal)' @click="openSpecSheetLink('personal')">
                                    <i class="fa fa-print" aria-hidden="true"></i> Print Spec (Personal)
                                </a>
                            </li>
                            <li >
                                <a v-show="permissionNameArray.includes('can_spec_sheet_inventory_master')" href="javascript:void(0)" title='Print Spec (Generic)' @click="openSpecSheetLink('Generic')">
                                    <i class="fa fa-print" aria-hidden="true"></i> Print Spec (Generic)
                                </a>
                            </li>
                            <li >
                                <a v-show="permissionNameArray.includes('can_email_inventory_master')" href="javascript:void(0)" title='Email Specs' v-on:click="moveToSpecificAssetTab(4)">
                                    <i class="fa fa-envelope-open" aria-hidden="true"></i> Email Specs
                                </a>
                            </li>
                            <li >
                                <a v-show="permissionNameArray.includes('can_add_expense_master')" href="javascript:void(0)" title='Add Asset Expense' v-on:click="addExpense()">
                                    <i class="fa fa-usd color-71aa3f" aria-hidden="true"></i> Add Expense
                                </a>
                            </li>
                            <li >
                                <a v-show="permissionNameArray.includes('can_close_inventory_master')" href="javascript:void(0)" title='Close/Hold Asset' v-on:click="moveToSpecificAssetTab(6)">
                                    <i class="fa fa-window-close-o color-c3202b" aria-hidden="true"></i> Close/Hold Asset
                                </a>
                            </li>
                            <li >
                                <a v-show="permissionNameArray.includes('can_close_inventory_master')" href="javascript:void(0)" title='Opportunity' v-on:click="moveToSpecificAssetTab(8)">
                                    <i class="fa fa-plus-square color-71aa3f" aria-hidden="true"></i> Opportunity
                                </a>
                            </li>
                        </vue-context>
                        <!-- Vue Context Menu End -->
                    </div>
                </div>
            </div>
            <template v-if="showExpenseForm">
                <add-asset-expense-group v-bind:selectedAssets=selectedRows v-bind:assetId=assetId :backButtonClicked="clickedBackFromChild"></add-asset-expense-group>
            </template>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    var filterColumn = "age";
    var filterType = "asc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import AssetExpenseGroup from "@/components/AssetMaster/AssetExpenseGroup";

    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import { VueContext } from 'vue-context';
    import 'vue-context/dist/css/vue-context.css';
    import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";
    import {Datetime} from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css';
    import Multiselect from 'vue-multiselect';
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListAsset',
        mixins: [commonMixins],
        components: {
            'filter-component': FilterComponent,
            'no-access': NoAccessPage,
            'add-asset-expense-group': AssetExpenseGroup,
            VueContext,
            Multiselect,
            datetime: Datetime,
            'list-accordian': ListAccordianComponent,
            datePicker,
        },
        data() {
            return {
                fromOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                toOptions: {
                    format: 'YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                userCompany : [],
                isHoldSelected: false,
                datePickerOptions: {
                    range: true,
                    onlyDate: true,
                    outputFormat: 'YYYY-MM-DD',
                    inputFormat: 'YYYY-MM-DD',
                    formatted: process.env.VUE_APP_SYSTEM_DISPLAY_DATERANGE_FILTER_FORMAT,/*'YYYY-MM-DD',*/
                    noClearButton: true,
                    maxDate : '',
                    /*  Getting error when using the for date picker options */
                    /*  'no-shortcuts': false, */
                    /*customShortcuts : [
                        { label: 'Yesterday', value: '-day', isSelected: false },
                        { label: 'This Week', value: 'week', isSelected: false },
                        { label: 'Last Week', value: '-week', isSelected: false },
                        { key: 'last7Days', label: 'Last 7 days', value: 7 },
                        { key: 'last30Days', label: 'Last 30 days', value: 30 },
                        { label: 'This Month', value: 'month', isSelected: false },
                        { label: 'Last Month', value: '-month', isSelected: false },
                        { key: 'thisYear', label: 'This year', value: 'year' },
                        { key: 'lastYear', label: 'Last year', value: '-year' }
                    ]*/
                },
                perPageValue : 15,
                perPage: 15,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                assetArr: [],
                headers: [],
                filterheaders: [],
                filters: {
                    // sale_date : "",
                    purchase_date : "",
                    vin_no:'',
                    status:[],
                    make:'',
                    model:'',
                    year : {
                        fromYear : "",
                        toYear : ""
                    },
                    location:'',
                    // salesman:'',
                    price: {
                        fromPrice : "",
                        toPrice : "",
                    },
                    odometer: {
                        fromOdometer : "",
                        toOdometer : "",
                    },
                    lot:'',
                    engine_make:'',
                    engine_model:'',
                    cab_style:'',
                    department:'',
                    dtr_trade_terms:'',
                    fleet:'',
                    body_type:'',
                    trans_model : '',
                    axle_type : '',
                },
                yearFilterError : "",
                priceFilterError : "",
                odometerFilterError : "",
                filterStatusOptions:[],
                filterbodyTypeOptions:[],
                filterCompanyOptions:[],
                filterHoldStatusOptions:[],
                filterMakeOptions:[],
                filterModelOptions:[],
                filterEngineMakeOptions:[],
                filterEngineModelOptions:[],
                filterCabStyleOptions:[],
                filterlocationOptions:[],
                filterSalesmanOptions:[],
                filterTransModelOptions:[],
                filterAxleTypeOptions:[],
                filterVendorOptions:[],
                filterFleetOptions:[],
                filterDTROptions:[],
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: '',
                selectAll: false,
                permissionNameArray: ['can_dtna_ordered_and_canceled_inventory_master'],
                actionDisplay: true,
                showMainList: true,
                showExpenseForm: false,
                globalSlug: "",
                assetId: 0,
                /* when set to true, the context  menu will close when clicked on */
                closeOnClick: true,
                /* when set to true, the context  menu will close when the window is scrolled */
                closeOnScroll: true,
                /* This is how the component is able to find each menu item. Useful if you use non-recommended markup */
                itemSelector: ['.custom-item-class'],
                loginId: 0,
                renderFilterComponent:true,
                isOpenFilterBlock:false,
                exportAsset: false,
                exportAssetAvailableDateData: false,
                accordianIds : [],
                locationIds : '',
                salesmanIds : '',
                showPromoSheetBtn : true,
                showSpecSheetBtn : true,
                showSpecListBtn : true,

                
                maxSelectionRecord : 10,
                /*  This logicToapplyForMaxRecordSelection variable has two value "alert" or "hide"
                    make value "hide" for hide button if value excede from maxSelectionRecord variable value
                    make value "alert" for display sweet alert if value excede from maxSelectionRecord variable value */
                logicToapplyForMaxRecordSelection : "alert",
                vendorIds : '',
                jumpToHistoryPage : 1,
                pageLoaded : false,
                filterCount : 0,
                appliedDepartmentFilter : [],
                appliedLocationFilter : [],
                appliedStatusFilter : [],
                appliedSalesmanFilter : [],
                appliedVendorFilter : [],
                appliedHodStatusFilter : [],
                appliedAxleTypeFilter : [],
                appliedTransModelFilter : [],
                appliedBodyTypeFilter : [],
                appliedFleetFilter : [],
                appliedDTRFilter : [],
                appliedCabStyleFilter : [],
                appliedEngineModelFilter : [],
                appliedEngineMakeFilter : [],
                appliedModelFilter : [],
                appliedMakeFilter : [],
                strDepartmentId: '',
                loginUserLocation: [],
                isDepartmentSelected: false,
                isFirstLoad: 0,
            }
        },
        beforeMount() {
            self = this;
            let appliedFilters = JSON.parse(localStorage.getItem("filters"));
            if (appliedFilters != null && appliedFilters != "" && typeof appliedFilters != "undefined") {
                if (appliedFilters['OnOrderInventoryList'].filters.odometer != null && appliedFilters['OnOrderInventoryList'].filters.odometer != "" && typeof appliedFilters['OnOrderInventoryList'].filters.odometer != "undefined") {
                    
                } else {
                    var fltr = appliedFilters['OnOrderInventoryList'].filters;
                    fltr.odometer = {"fromOdometer" : "", "toOdometer" : ""};
                    var fields = {
                        filters: fltr,
                        per_page: appliedFilters['OnOrderInventoryList'].per_page,
                        page: appliedFilters['OnOrderInventoryList'].page,
                        sortColumnName: appliedFilters['OnOrderInventoryList'].sortColumnName,
                        sortType: appliedFilters['OnOrderInventoryList'].sortType,
                        is_export: appliedFilters['OnOrderInventoryList'].is_export,
                        is_export_available_date_data: appliedFilters['OnOrderInventoryList'].is_export_available_date_data
                    };
                    this.storeFilters('OnOrderInventoryList', fields, false);
                }
            }
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        watch: {
            selectedRows : function () {
                if (self.logicToapplyForMaxRecordSelection == "hide") {
                    self.maxSelectionAllowed();
                }
            },
            /*selectedRows: function (selectedRows) {
             console.log(selectedRows.length);
             },
             /*selectAll: function (selectAll) {
             self.clickHeaderCheckBox();
             },*/
             filters: function (selectedRows) {
                /* console.log('selectedRows');
                console.log(selectedRows); */
             },
            /*perPage: function () {
                let appliedFilters = JSON.parse(localStorage.getItem("filters"));
                if (appliedFilters != null && appliedFilters != "" && typeof appliedFilters != "undefined") {
                    let currentPage = appliedFilters['OnOrderInventoryList'].page;
                    self.currentPage = currentPage;
                } else {
                    self.currentPage = 1;
                }
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.changepage();
            },*/
            /*currentPage: function () {
                self.changepage();
            },*/
            /* locationIds: function () {
                self.loadAllData(['Location'], false, self.loadCityDataCallback, 'undefined', '', 0, self.locationIds);
            }, */
            salesmanIds: function () {
                self.loadAllData(['User'], false, self.loadSalesmanDataCallback, 'undefined', '', 0,self.salesmanIds);
            },
            salesmanIds: function () {
                self.loadAllData(['Vendor'], false, self.loadVendorDataCallback, 'undefined', '', 0, 1);
            },
            userCompany: function () {
                self.loadAllData(['Company'], false, self.loadCompanyDataCallback,"undefined",'',self.userCompany.join(","));
            },
            "filters.salesman": function(){
                self.appliedSalesmanFilter = [];
                $.each(self.filters.salesman, function (i) {
                    self.appliedSalesmanFilter.push(self.filters.salesman[i].value);
                });
            },
            "filters.vendor": function(){
                self.appliedVendorFilter = [];
                $.each(self.filters.vendor, function (i) {
                    self.appliedVendorFilter.push(self.filters.vendor[i].value);
                });
            },
            "filters.status": function(){
                self.isHoldSelected = false;
                self.appliedStatusFilter = [];
                $.each(self.filters.status, function (i) {
                    self.appliedStatusFilter.push(self.filters.status[i].value);
                    if(self.filters.status[i].value == 2){
                        self.isHoldSelected = true;
                    }
                });
            },
            "filters.axle_type": function(){
                self.appliedAxleTypeFilter = [];
                $.each(self.filters.axle_type, function (i) {
                    self.appliedAxleTypeFilter.push(self.filters.axle_type[i].value);
                });
            },
            "filters.trans_model": function(){
                self.appliedTransModelFilter = [];
                $.each(self.filters.trans_model, function (i) {
                    self.appliedTransModelFilter.push(self.filters.trans_model[i].value);
                });
            },
            "filters.body_type": function(){
                self.appliedBodyTypeFilter = [];
                $.each(self.filters.body_type, function (i) {
                    self.appliedBodyTypeFilter.push(self.filters.body_type[i].value);
                });
            },
            "filters.fleet": function(){
                self.appliedFleetFilter = [];
                $.each(self.filters.fleet, function (i) {
                    self.appliedFleetFilter.push(self.filters.fleet[i].value);
                });
            },
            "filters.dtr_trade_terms": function(){
                self.appliedDTRFilter = [];
                $.each(self.filters.dtr_trade_terms, function (i) {
                    self.appliedDTRFilter.push(self.filters.dtr_trade_terms[i].value);
                });
            },
            "filters.cab_style": function(){
                self.appliedCabStyleFilter = [];
                $.each(self.filters.cab_style, function (i) {
                    self.appliedCabStyleFilter.push(self.filters.cab_style[i].value);
                });
            },
            "filters.engine_model": function(){
                self.appliedEngineModelFilter = [];
                $.each(self.filters.engine_model, function (i) {
                    self.appliedEngineModelFilter.push(self.filters.engine_model[i].value);
                });
            },
            "filters.engine_make": function(){
                self.appliedEngineMakeFilter = [];
                $.each(self.filters.engine_make, function (i) {
                    self.appliedEngineMakeFilter.push(self.filters.engine_make[i].value);
                });
            },
            "filters.model": function(){
                self.appliedModelFilter = [];
                $.each(self.filters.model, function (i) {
                    self.appliedModelFilter.push(self.filters.model[i].value);
                });
            },
            "filters.make": function(){
                self.appliedMakeFilter = [];
                $.each(self.filters.make, function (i) {
                    self.appliedMakeFilter.push(self.filters.make[i].value);
                });
            },
            "filters.department": function(){
                self.isDepartmentSelected = false;
                if(self.isFirstLoad > 0){
                    self.$nextTick(function () {
                        self.filters.location = "";
                    });
                }
                if(self.filters.department && self.filters.department.length > 0) {
                    self.isDepartmentSelected = true;
                }
                var userObj = localStorage.getItem("user_obj");
                // if user logged in than remove login button and registration button
                if (userObj != null) {
                    var userJson = JSON.parse(userObj);
                    var userRole = userJson['roles'][0].id;
                    if(userRole > 2 && self.loginUserLocation && self.loginUserLocation.length > 0) {
                        self.filterlocationOptions = [];
                        var temp = [];
                        if(self.isFirstLoad > 0){
                            self.$nextTick(function () {
                                self.filters.location = "";
                            });
                        }
                        $.each(self.filters.department, function (i) {
                            var selectedCompanyId = self.filters.department[i].value;
                            $.each(self.loginUserLocation, function (j) {
                                var objLoc = self.loginUserLocation[j];
                                if(selectedCompanyId == objLoc.companyId) {
                                    if( !temp.includes(objLoc.value) ) {
                                        temp.push(objLoc.value);
                                        self.filterlocationOptions.push(objLoc);
                                    }
                                }
                            });
                        });
                        return false;
                    }
                    
                    self.strDepartmentId = '';
                    self.appliedDepartmentFilter = [];
                    $.each(self.filters.department, function (i) {
                        self.appliedDepartmentFilter.push(self.filters.department[i].value);
                        self.strDepartmentId += self.filters.department[i].value + ",";
                    });
                    self.strDepartmentId = self.strDepartmentId.substring(0, self.strDepartmentId.length - 1);
                    if(self.strDepartmentId != '') {
                        self.loadAllData(['Location'], false, self.loadCityDataCallback, 'undefined', '', self.strDepartmentId);
                    }
                }
                self.$nextTick(function () {
                    self.isFirstLoad = self.isFirstLoad + 1;
                });
            },
            "filters.location": function(){
                self.appliedLocationFilter = [];
                $.each(self.filters.location, function (i) {
                    self.appliedLocationFilter.push(self.filters.location[i].value);
                });
            },
            "filters.hold_status": function(){
                self.appliedHodStatusFilter = [];
                $.each(self.filters.hold_status, function (i) {
                    self.appliedHodStatusFilter.push(self.filters.hold_status[i].value);
                });
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            /* this.$refs.select.open = true; */
            self.filters.status = [{
                            "value":5,
                            "text":"On-Order",
                            "checked":0
                        },{
                            "value":6,
                            "text":"Cancelled",
                            "checked":0
                        }];
            self.setActiveMenu('dtna-ordered-and-cancelled');
            EventBus.$emit("menu-selection-changed", "dtna-ordered-and-cancelled");
            var listHistoryData = self.loadFilters('OnOrderInventoryList', self);
            
            /* if user logged in than remove login button and registration button */
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginId = userJson['id'];
            }

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.loadAllData(['Status'], false, self.loadStatusDataCallback, '', '', '5,6');
            self.loadAllData(['Make'], false, self.loadMakeDataCallback);
            /* self.loadAllData(['Status', 'HoldStatus', 'Make', 'EngineMake', 'TransModel', 'AxleType', 'CabStyle'
                , 'Fleet', 'BodyType', 'DtrTradeTerms', 'User', 'Location'
            ], false, self.loadMasterDataCallback); */
            /* self.loadAllData('Status', false, self.loadStatusDataCallback);
            self.loadAllData('HoldStatus', false, self.loadHoldStatusDataCallback);
            self.loadAllData('Make', false, self.loadMakeDataCallback);
            self.loadAllData('EngineMake', false, self.engineMakeDataCallback);
            self.loadAllData('TransModel', false, self.loadTransModelDataCallback);
            self.loadAllData('AxleType', false, self.loadAxleTypeDataCallback);
            self.loadAllData('CabStyle', false, self.loadCabStyleDataCallback);
            self.loadAllData('Fleet', false, self.loadFleetCallback);
            self.loadAllData('BodyType', false, self.loadBodyTypeCallback);
            self.loadAllData('DtrTradeTerms', false, self.loadDTRTradeTermsCallback);
            
            self.loadAllData('User', false, self.loadSalesmanDataCallback);
            self.loadAllData('Location', false, self.loadCityDataCallback); */
            self.makeValueChanged(1);
            self.engineMakeValueChanged(1);
            self.pageLoaded = true;
            self.listExecutionState = false;
            self.loadOnOrderAsset(1);
            self.$nextTick(() => {
                self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
            });
            self.setInputFilter(document.getElementById("fromYear"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("toYear"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("fromPrice"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("toPrice"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("fromOdometer"), function(value) {
                return /^-?\d*$/.test(value); });
            self.setInputFilter(document.getElementById("toOdometer"), function(value) {
                return /^-?\d*$/.test(value); });
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changeHoldStatusFilter : function() {
                /* self.appliedHodStatusFilter = [];
                for (let i = 0; i < self.filters.hold_status.length; i++) {
                    self.appliedHodStatusFilter.push(self.filters.hold_status[i].value);
                } */
            },
            clearHoldStatusFilter : function () {
                self.filters.hold_status = "";
                self.appliedHodStatusFilter = [];
            },
            changeStatusFilter : function() {
                /*self.appliedStatusFilter = [];
                for (let i = 0; i < self.filters.status.length; i++) {
                    self.appliedStatusFilter.push(self.filters.status[i].value);
                }*/
            },
            changeSalesmanFilter : function() {
                /*self.appliedStatusFilter = [];
                for (let i = 0; i < self.filters.status.length; i++) {
                    self.appliedStatusFilter.push(self.filters.status[i].value);
                }*/
            },
            clearSalesmanFilter : function () {
                self.filters.salesman = "";
                self.appliedSalesmanFilter = [];
            },
            clearVendorFilter : function () {
                self.filters.vendor = "";
                self.appliedVendorFilter = [];
            },
            clearStatusFilter : function () {
                self.filters.status = "";
                self.appliedStatusFilter = [];
            },
            clearAxleTypeFilter : function () {
                self.filters.axle_type = "";
                self.appliedAxleTypeFilter = [];
            },
            clearTransModelFilter : function () {
                self.filters.trans_model = "";
                self.appliedTransModelFilter = [];
            },
            clearBodyTypeFilter : function () {
                self.filters.body_type = "";
                self.appliedBodyTypeFilter = [];
            },
            clearFleetFilter : function () {
                self.filters.fleet = "";
                self.appliedFleetFilter = [];
            },
            clearDTRFilter : function () {
                self.filters.dtr_trade_terms = "";
                self.appliedDTRFilter = [];
            },
            clearCabStyleFilter : function () {
                self.filters.cab_style = "";
                self.appliedCabStyleFilter = [];
            },
            clearEngineModelFilter : function () {
                self.filters.engine_model = "";
                self.appliedEngineModelFilter = [];
            },
            clearEngineMakeFilter : function () {
                self.filters.engine_make = "";
                self.appliedEngineMakeFilter = [];
                self.engineMakeValueChanged(1);
            },
            clearModelFilter : function () {
                self.filters.model = "";
                self.appliedModelFilter = [];
            },
            clearMakeFilter : function () {
                self.filters.make = "";
                self.appliedMakeFilter = [];
                self.makeValueChanged(1);
            },
            changeLocationFilter : function() {
                /* self.appliedLocationFilter = [];
                for (let i = 0; i < self.filters.location.length; i++) {
                    self.appliedLocationFilter.push(self.filters.location[i].value);
                } */
            },
            clearLocationFilter : function() {
                self.filters.location = "";
                self.appliedLocationFilter = [];
            },
            clearDepartmentFilter : function() {
                self.filters.department = "";
                self.appliedDepartmentFilter = [];
                self.loadAllData(['Location'], false, self.loadCityDataCallback);
            },
            changeDepartmentFilter : function() {
                /* self.appliedDepartmentFilter = [];
                for (let i = 0; i < self.filters.department.length; i++) {
                    self.appliedDepartmentFilter.push(self.filters.department[i].value);
                } */
            },
            setInputFilter : function(textbox, inputFilter) {
                ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
                    textbox.addEventListener(event, function() {
                        if (inputFilter(this.value)) {
                            this.oldValue = this.value;
                            this.oldSelectionStart = this.selectionStart;
                            this.oldSelectionEnd = this.selectionEnd;
                        } else if (this.hasOwnProperty("oldValue")) {
                            this.value = this.oldValue;
                            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                        } else {
                            this.value = "";
                        }
                    });
                });
            },
            handleBlurFromPrice : function() {
                self.priceFilterError = "";
            },
            handleBlurToPrice : function() {
                self.priceFilterError = "";
                if (parseInt(self.filters.price.fromPrice) > parseInt(self.filters.price.toPrice)) {
                    self.filters.price.toPrice = "";
                    self.priceFilterError = "To Price can not be smaller than From Price"
                }
            },
            handleBlurFromOdometer : function() {
                self.odometerFilterError = "";
            },
            handleBlurToOdometer : function() {
                self.odometerFilterError = "";
                if (parseInt(self.filters.odometer.fromOdometer) > parseInt(self.filters.odometer.toOdometer)) {
                    self.filters.odometer.toOdometer = "";
                    self.odometerFilterError = "Miles/Hrs To can not be smaller than Miles/Hrs From"
                }
            },
            changeFilterFromDate : function () {
                self.yearFilterError = "";
            },
            changeFilterToDate : function() {
                self.yearFilterError = "";
                if (parseInt(self.filters.year.fromYear) > parseInt(self.filters.year.toYear)) {
                    self.filters.year.toYear = "";
                    self.yearFilterError = "To Year can not be smaller than From Year"
                }
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.changepage();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadOnOrderAsset();
                }
            },
            maxSelectionAllowed : function () {
                if(self.selectedRows.length > self.maxSelectionRecord) {
                    if (self.logicToapplyForMaxRecordSelection == "alert") {
                        self.$swal("","Only " + self.maxSelectionRecord + " Inventory can be selected for this functionality", "error");
                        return false;
                    } else if (self.logicToapplyForMaxRecordSelection == "hide") {
                        self.showPromoSheetBtn = false;
                        self.showSpecSheetBtn = false;
                        self.showSpecListBtn = false;
                        return false;
                    } else {
                        self.$swal("","Only " + self.maxSelectionRecord + " Inventory can be selected for this functionality", "error");
                        return false;
                    }
                } else {
                    self.showPromoSheetBtn = true;
                    self.showSpecSheetBtn = true;
                    self.showSpecListBtn = true;
                }
                return true;
            },
            toggleFilterBlock: function () {
                $(window).scrollTop($(window).scrollTop()+1);
                self.isOpenFilterBlock = !self.isOpenFilterBlock;
            },
            moveToSpecificAssetTab : function(flag) {
                if (typeof flag !== "undefined" && flag != "") {
                    self.$router.push({name: 'AssetTabView', params: { id: self.assetId, redirectTo : flag}});
                } else {
                    self.$router.push({name: 'AssetTabView', params: { id: self.assetId, redirectTo : 1}});
                }
            },
            exportAssetExpense: function () {
                self.exportAsset = true;
                if (self.exportAsset == true) {
                    $('#exportAssetExpense').prop("disabled", true);
                    self.changepage();
                }
            },
            returnAccordianDisplayDataKeys : function() {
                let dataArray = [
                    {
                        field : "media_thumb",
                        label : self.allLabelMessages.inventory_image_label,
                        alt : ""
                    },
                    /* {
                        field : "vin",
                        label : "Vin",
                        alt : ""
                    }, */
                    /* {
                        field : "unit",
                        label : "Unit",
                        alt : ""
                    }, */
                    {
                        field : "private_comment",
                        label : self.allLabelMessages.comment_label,
                        alt : "Comment Not Provided"
                    },
                    {
                        field : "description",
                        label : self.allLabelMessages.description_label,
                        alt : "Description Missing"
                    },
                    {
                        field : "rear_ratio",
                        label : self.allLabelMessages.rear_ratio_label,
                        alt : ""
                    },
                    {
                        field : "wheelbase",
                        label : self.allLabelMessages.wheelbase_label,
                        alt : ""
                    },
                    {
                        field : "tire_size",
                        label : self.allLabelMessages.tire_size_label,
                        alt : ""
                    },
                    {
                        field : "apu_make_name",
                        label : self.allLabelMessages.apu_make_label,
                        alt : ""
                    },
                    {
                        field : "lot_location",
                        label : self.allLabelMessages.lot_location_label,
                        alt : ""
                    },
                    {
                        field : "purchase_date",
                        label : self.allLabelMessages.purchase_date_label,
                        alt : ""
                    },
                    {
                        field : "exterior_length",
                        label : self.allLabelMessages.exterior_lenght_label,
                        alt : ""
                    },
                    {
                        field : "exterior_width",
                        label : self.allLabelMessages.exterior_width_label,
                        alt : ""
                    },
                    {
                        field : "exterior_height",
                        label : self.allLabelMessages.exterior_height_label,
                        alt : ""
                    },
                    {
                        field : "axle_type_name",
                        label : self.allLabelMessages.axle_type_label,
                        alt : ""
                    },
                    {
                        field : "suspension_name",
                        label : self.allLabelMessages.suspension_label,
                        alt : ""
                    },
                    {
                        field : "reefer_make_name",
                        label : self.allLabelMessages.reefer_make_label,
                        alt : ""
                    },
                    {
                        field : "reefer_serial",
                        label : self.allLabelMessages.reefer_serial_label,
                        alt : ""
                    },
                    {
                        field : "lift_gate",
                        label : self.allLabelMessages.lift_gate_label,
                        alt : ""
                    },
                    {
                        field : "trans_type_name",
                        label : self.allLabelMessages.trans_type_label,
                        alt : ""
                    },
                    {
                        field : "trans_make_name",
                        label : self.allLabelMessages.trans_make_label,
                        alt : ""
                    },
                    {
                        field : "trans_model",
                        label : self.allLabelMessages.trans_model_label,
                        alt : ""
                    },
                    {
                        field : "trans_speed",
                        label : self.allLabelMessages.trans_speed_label,
                        alt : ""
                    },
                    {
                        field : "body_type_name",
                        label : self.allLabelMessages.body_type_label,
                        alt : ""
                    },
                ];

                return dataArray;
            },
            toggleIcon : function(id) {
                self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.changepage();
            },
            clickedBackFromChild() {
                /* var self = this; */
                self.showMainList = true;
                self.showExpenseForm = false;
                self.$nextTick(() => {
                    self.resetFilters();
                });
            },
            resetFilters: function (flag, date) {
                if (flag == "date") {
                    if (date == "purchase_date") {
                        self.filters.purchase_date = "";
                    } else if (date == "sale_date") {
                        self.filters.sale_date = "";
                    }
                    self.$nextTick(() => {
                        self.pageLoaded = true;
                        self.changepage();
                    });
                } else {
                    self.filters.status = [{
                            "value":5,
                            "text":"On-Order",
                            "checked":0
                        },{
                            "value":6,
                            "text":"Cancelled",
                            "checked":0
                        }];
                    self.pageLoaded = false;
                    self.filters.age_from='';
                    self.filters.vin_no='';
                    /* self.filters.status=''; */
                    self.filters.hold_status='';
                    self.filters.make='';
                    self.filters.model='';
                    self.filters.year ={
                        fromYear : "",
                        toYear : ""
                    },
                    self.filters.location='';
                    self.filters.salesman='';
                    self.filters.vendor='';
                    self.filters.price = {
                        fromPrice : "",
                        toPrice : "",
                    };
                    self.filters.odometer = {
                        fromOdometer : "",
                        toOdometer : "",
                    };
                    self.filters.lot='';
                    self.filters.engine_make='';
                    self.filters.engine_model='';
                    self.filters.cab_style='';
                    self.filters.sale_date="";
                    self.filters.purchase_date="";
                    self.filters.department="";
                    self.filters.dtr_trade_terms = '';
                    self.filters.fleet = '';
                    self.filters.body_type = '';
                    self.$nextTick(() => {
                        self.pageLoaded = true;
                        self.changepage();
                    });
                    self.filters.trans_model = '';
                    self.filters.axle_type = '';
                }

            },
            addExpense: function () {
                /* let selectedAssets= self.selectedAssets;
                alert(self.assetId);
                if (self.selectedRows.length > 1) { */
                    self.showMainList = false;
                    self.showExpenseForm = true;
                /* } else {
                    this.showSAlert('Select more than one asset', 'error', function () {});
                } */
            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
            },
            editSelected: function () {
                self.$store.commit('changeSelectData', self.selectedRows);
                self.$router.push({name: "MultyAssetTabView"});
            },
            printSelected: function () {
                var selectedInventory = "";
                $.each(self.selectedRows, function (i) {
                    selectedInventory += self.selectedRows[i];
                    if((self.selectedRows.length - 1) != i){
                        selectedInventory += ":";
                    }
                });
                window.open("/pub/multi-print/?id=" + selectedInventory + "&uid=" + self.loginId);
            },
            /* Context Menu Logic Start */
            openContextMenu: function (e, id, slug) {
                self.globalSlug = slug;
                self.assetId = id;
                self.$refs.menu.open(e);
            },
            openSpecSheetLink: function (flag) {
                if (self.loginId > 0) {
                    if (flag == 'personal') {
                        /* https://www.imanpro.net/pub/print/?co=sac&id=13407&uid=1004&key=b5241b4630506e90ecf9d060c28b92c3 */
                        window.open("/pub/print/?id=" + self.assetId + "&uid=" + self.loginId + "&key=" + self.globalSlug);
                    } else {
                        /* https://www.imanpro.net/pub/print/?co=sac&id=13407&key=b5241b4630506e90ecf9d060c28b92c3 */
                        window.open("/pub/print/?id=" + self.assetId + "&key=" + self.globalSlug);
                    }
                }
            },
            /* Context Menu Logic Close */
            compareAssets: function () {
                var strIdList = "";
                if(self.selectedRows.length < 2){
                    self.$swal("","Select at least 2 Inventory to compare", "error");
                    return false;
                }
                if(self.selectedRows.length > 5){
                    self.$swal("","Only 5 Inventory can be compare at one time", "error");
                    return false;
                }
                for (var index = 0; index < self.selectedRows.length; index++) {
                    strIdList += self.selectedRows[index] + ":";
                }
                strIdList = strIdList.replace(/:\s*$/, "");
                if (self.loginId > 0) {
                    localStorage.removeItem('compareAssetIds');
                    self.changepage();
                    localStorage.setItem('compareAssetIds', strIdList);
                    /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
                    window.open("/compare/inventory");
                }
            },
            specListSelectedEmail : function () {
                if (self.maxSelectionAllowed()){
                    var strIdList = "";
                    for (var index = 0; index < self.selectedRows.length; index++) {
                        strIdList += self.selectedRows[index] + ":";
                    }
                    strIdList = strIdList.replace(/:\s*$/, "");
                    if (self.loginId > 0) {
                        localStorage.removeItem('promoSheetIdList');
                        self.changepage();
                        localStorage.setItem('promoSheetIdList', strIdList);
                        /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
                        let routeData = self.$router.resolve({name: 'EmailSpecSheetTabView', query: {redirectTo: 3}});
                        window.open(routeData.href, '_blank');
                        /* window.open("/email/spec"); */
                    }
                }
            },
            specSheetSelectedEmail : function () {
                if (self.maxSelectionAllowed()){
                    var strIdList = "";
                    for (var index = 0; index < self.selectedRows.length; index++) {
                        strIdList += self.selectedRows[index] + ":";
                    }
                    strIdList = strIdList.replace(/:\s*$/, "");
                    if (self.loginId > 0) {
                        localStorage.removeItem('promoSheetIdList');
                        self.changepage();
                        localStorage.setItem('promoSheetIdList', strIdList);
                        /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
                        let routeData = self.$router.resolve({name: 'EmailSpecSheetTabView', query: {redirectTo: 2}});
                        window.open(routeData.href, '_blank');
                        /* window.open("/email/spec"); */
                    }
                }
            },
            promoSheetSelectedEmail: function () {
                if (self.maxSelectionAllowed()) {
                    var strIdList = "";
                    for (var index = 0; index < self.selectedRows.length; index++) {
                        strIdList += self.selectedRows[index] + ":";
                    }
                    strIdList = strIdList.replace(/:\s*$/, "");
                    if (self.loginId > 0) {
                        localStorage.removeItem('promoSheetIdList');
                        self.changepage();
                        localStorage.setItem('promoSheetIdList', strIdList);
                        /* window.open("/pub/print/promo/?uid=" + self.loginId + "&idlist=" + strIdList); */
                        let routeData = self.$router.resolve({name: 'EmailSpecSheetTabView', query: {redirectTo: 1}});
                        window.open(routeData.href, '_blank');
                        /* window.open("/email/spec"); */
                    }
                }
            },
            activeInactiveAsset: function (id, state) {
                let statusArr = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if (id != 0) {
                    statusArr.push(id);
                } else {
                    statusArr = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to ' + state + ' this Inventory(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    /* showCloseButton: true, */
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        };
                        var fields = {
                            id: statusArr,
                            state: state,
                        };

                        HTTP.post("/asset/activeordeative", fields, config)
                                .then(function (response) {
                                    if (response.data.code == '200') {
                                        self.changepage();
                                    }
                                })
                                .catch(function (error) {
                                    self.catchHandler(error, function () {});
                                });
                    }
                });
            },
            deleteAsset: function (id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if (id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Inventory(s)!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    /* showCloseButton: true, */
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data: {
                                deleteArr: deleteArray,
                            }
                        };

                        HTTP.delete("/asset/delete", config)
                                .then(function (response) {
                                    if (response.data.code == '200') {
                                        self.changepage();
                                    }
                                })
                                .catch(function (error) {
                                    self.catchHandler(error, function () {});
                                });
                    }
                });
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.assetArr) {
                            self.selectedRows.push(self.assetArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.assetArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            addAsset: function () {
                /* self.$router.push('/add/inventory'); */
            },
            applyFilterParentComponent: function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        if(data[i]['type'] == "drop_down" && data[i]['value'] != null){
                            key = data[i]['column_name'];
                            value = data[i]['value']['value'];
                        }else{
                            key = data[i]['column_name'];
                            value = data[i]['field'];
                        }
                        
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data; 
                
                self.changepage();
                /* self.renderFilterComponent = !self.renderFilterComponent; */
            },
            setAlreadyAppliedFilters: function () {
                if (self.appliedHeaders.length > 0) {
                    self.filterheaders = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            filterCountFunctionAsset : function (filters) {
                var count = 0 ;
                $.each(filters, function (i) {

                    if (i === "year") {
                        var check = (filters[i]['fromYear'] === null || typeof (filters[i]['fromYear']) === "undefined" || filters[i]['fromYear'] === '') ? "" : filters[i];
                    } else if (i === "price") {
                        var check = (filters[i]['fromPrice'] === null || typeof (filters[i]['fromPrice']) === "undefined" || filters[i]['fromPrice'] === '') ? "" : filters[i];
                    } else if (i === "odometer") {
                        var check = (filters[i]['fromOdometer'] === null || typeof (filters[i]['fromOdometer']) === "undefined" || filters[i]['fromOdometer'] === '') ? "" : filters[i];
                    } else if (i === "status" || i === "make" || i === "model" || i === "location" || i === "salesman" || i === "lot" || i === "engine_make" || i === "engine_model" || i === "cab_style" || i === "department" || i === "dtr_trade_terms" || i === "fleet" || i === "body_type" || i === "trans_model" || i === "axle_type" || i === "acquired_by") {
                        var check = (filters[i].length <= 0) ? "" : filters[i].length;
                    } else {
                        var check = (filters[i] === null || typeof (filters[i]) === "undefined" || filters[i] === '') ? "" : filters[i];
                    }

                    if(check != ""){
                        count ++;
                    }
                });
                return count;
            },
            exportAssetAvailableData: function() {
                self.exportAssetAvailableDateData = true;
                if (self.exportAssetAvailableDateData == true) {
                    $('#exportAsset').prop("disabled", true);
                    self.loadOnOrderAsset();
                }
            },
            loadOnOrderAsset: function (first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadOnOrderAsset();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                if(self.pageLoaded){
                    var config = {
                        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                    };
                    var fields = {
                        filters: self.filters,
                        per_page: self.perPage,
                        page: self.currentPage,
                        sortColumnName: self.columnName,
                        sortType: self.sortType,
                        is_export: self.exportAsset,
                        is_export_available_date_data: self.exportAssetAvailableDateData
                    };
                    this.storeFilters('OnOrderInventoryList', fields, false);
                    self.showLoader();
                    self.filterCount = self.filterCountFunctionAsset(self.filters);
                    if(self.filterCount){
                        self.isOpenFilterBlock = true;
                    }
                    HTTP.post("/asset/list/onorder", fields, config)
                            .then(function (data) {
                                self.listExecutionState = false;
                                if(first == 1){
                                    if(self.jumpToHistoryPage != 1){
                                        self.currentPage = self.jumpToHistoryPage;
                                    }
                                }
                                self.hideLoader();
                                $('#exportAssetExpense').prop("disabled", false);
                                $('#exportAsset').prop("disabled", false);
                                self.exportAsset = false;
                                self.exportAssetAvailableDateData = false;
                                if (data.status == 200) {
                                    if (data.data.message == 'success export') {
                                        self.downloadFile(data.data.content.web);
                                        //location.href = data.data.content.web;
                                    } else {
                                        self.accordianIds = [];
                                        $('.collapse').hide();
                                        self.clearSelection();
                                        self.headers = [];
                                        self.totalRecords = data.data.content.result;
                                        if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                            // alert(self.totalRecords);
                                            let seconds = 5000;
                                            self.$swal({
                                                type: 'error',
                                                html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                                showConfirmButton: false,
                                                timer: seconds,
                                            });
                                            setTimeout(() => {
                                                self.perPage = 500;
                                                self.loadOnOrderAsset();
                                            }, seconds);
                                            /* self.$swal({
                                                title: '',
                                                html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                                type: 'error',
                                                confirmButtonText: 'OK',
                                                showCancelButton: true,
                                                showLoaderOnConfirm: true
                                            }).then((result) => {
                                                if (result.value) {
                                                    self.logout();
                                                } else {
                                                    self.perPage = 15;
                                                    self.loadOnOrderAsset();
                                                }
                                            }); */
                                        }
                                        self.assetArr = data.data.content.data;
                                        self.headers = data.data.content.headers;
                                        self.filterheaders = data.data.content.filterheaders;
                                        if(self.salesmanIds != data.data.content.salesmen_ids){
                                            self.salesmanIds = data.data.content.salesmen_ids;
                                        }
                                        self.loginUserLocation = data.data.content.logged_user_location;

                                        self.addColumnClass(self.headers);

                                        let testArray = [
                                            {
                                                'text': 1,
                                                'value': '2020-02-01'
                                            },
                                            {
                                                'text': 2,
                                                'value': '2020-02-02'
                                            },
                                            {
                                                'text': 3,
                                                'value': '2020-02-03'
                                            }
                                        ]
                                        for (let i = 0; i < self.headers.length; i++) {
                                            if (self.headers[i]['type'] == 'drop_down') {
                                                self.headers[i]['options_array'] = testArray;
                                            }
                                        }
                                        if (data.data.content.per_page !== self.totalRecords) {
                                            self.perPage = parseInt(data.data.content.per_page);
                                            self.jumpToPage = parseInt(data.data.content.current_page);
                                        } else {
                                            if(self.perPage == 'All'){
                                                self.jumpToPage = 1;
                                            }
                                            self.perPageValue = self.totalRecords;
                                            self.currentPage = 1;
                                        }
                                        self.setAlreadyAppliedFilters();
                                        self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                        self.sortFieldDisplay(self.columnName, self.sortType);
                                    }
                                    self.setAlreadyAppliedFilters();
                                    /* EventBus.$emit("refresh-filter-list",self.filterheaders); */
                                    self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                    self.sortFieldDisplay(self.columnName, self.sortType);
                                }

                            })
                            .catch(function (error) {
                                self.listExecutionState = false;
                                $('#exportAssetExpense').prop("disabled", false);
                                $('#exportAsset').prop("disabled", false);
                                self.exportAsset = false;
                                self.exportAssetAvailableDateData = false;
                                self.hideLoader();
                                self.catchHandler(error, function () {});
                            })
                    // HTTP.post("/asset/list", fields, config)
                    //         .then(function (data) {
                    //             if(first == 1){
                    //                 if(self.jumpToHistoryPage != 1){
                    //                     self.currentPage = self.jumpToHistoryPage;
                    //                 }
                    //             }
                    //             self.hideLoader();
                    //             $('#exportAssetExpense').prop("disabled", false);
                    //             $('#exportAsset').prop("disabled", false);
                    //             self.exportAsset = false;
                    //             self.exportAssetAvailableDateData = false;
                    //             if (data.status == 200) {
                    //                 if (data.data.message == 'success export') {
                    //                     self.downloadFile(data.data.content.web);
                    //                     //location.href = data.data.content.web;
                    //                 } else {
                    //                     self.accordianIds = [];
                    //                     $('.collapse').hide();
                    //                     self.clearSelection();
                    //                     self.headers = [];
                    //                     self.totalRecords = data.data.content.result;
                    //                     self.assetArr = data.data.content.data;
                    //                     self.headers = data.data.content.headers;
                    //                     self.filterheaders = data.data.content.filterheaders;
                    //                     if(self.salesmanIds != data.data.content.salesmen_ids){
                    //                         self.salesmanIds = data.data.content.salesmen_ids;
                    //                     }
                    //                     self.loginUserLocation = data.data.content.logged_user_location;

                    //                     self.addColumnClass(self.headers);

                    //                     let testArray = [
                    //                         {
                    //                             'text': 1,
                    //                             'value': '2020-02-01'
                    //                         },
                    //                         {
                    //                             'text': 2,
                    //                             'value': '2020-02-02'
                    //                         },
                    //                         {
                    //                             'text': 3,
                    //                             'value': '2020-02-03'
                    //                         }
                    //                     ]
                    //                     for (let i = 0; i < self.headers.length; i++) {
                    //                         if (self.headers[i]['type'] == 'drop_down') {
                    //                             self.headers[i]['options_array'] = testArray;
                    //                         }
                    //                     }
                    //                     if (data.data.content.per_page !== self.totalRecords) {
                    //                         self.perPage = parseInt(data.data.content.per_page);
                    //                         self.jumpToPage = parseInt(data.data.content.current_page);
                    //                     } else {
                    //                         if(self.perPage == 'All'){
                    //                             self.jumpToPage = 1;
                    //                         }
                    //                         self.perPageValue = self.totalRecords;
                    //                         self.currentPage = 1;
                    //                     }
                    //                     self.setAlreadyAppliedFilters();
                    //                     self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                    //                     self.sortFieldDisplay(self.columnName, self.sortType);
                    //                 }
                    //                 self.setAlreadyAppliedFilters();
                    //                 /* EventBus.$emit("refresh-filter-list",self.filterheaders); */
                    //                 self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                    //                 self.sortFieldDisplay(self.columnName, self.sortType);
                    //             }

                    //         })
                    //         .catch(function (error) {
                    //             $('#exportAssetExpense').prop("disabled", false);
                    //             $('#exportAsset').prop("disabled", false);
                    //             self.exportAsset = false;
                    //             self.exportAssetAvailableDateData = false;
                    //             self.hideLoader();
                    //             self.catchHandler(error, function () {});
                    //         })
                    
                }
            },
            makeValueChanged: function (firstload) {
                if (typeof(firstload) != "undefined" && firstload != "" && firstload != 0) {
                    firstload = 1;
                }else{
                    firstload = 0;
                }
                self.clearModelFilter();
                let make_id = [];
                if (typeof(self.filters.make) != "undefined" && self.filters.make != null && self.filters.make != "" && self.filters.make != 0) {
                    $.each(self.filters.make, function (i) {
                        make_id.push(self.filters.make[i].value);
                    });
                    //make_id = self.filters.make.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, 'undefined', '', make_id.join());
                if(firstload){
                    //self.changepage();
                }
            },
            onSearchSalesman(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['User'], true, self.loadSalesmanDataCallback, loading, search, 0);
                }                
            },
            onSearchtransModel(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['TransModel'], true, self.loadTransModelDataCallback, loading, search, 0);
                }                
            },
            onSearchAxleType(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['AxleType'], true, self.loadAxleTypeDataCallback, loading, search, 0);
                }                
            },
            onSearchVendor(search, loading) {
                if (search.length >= 0) {
                    if (loading === null || typeof loading === "undefined" || loading == '') {
                    } else {
                        loading(true);
                    }
                    self.loadAllData(['Vendor'], true, self.loadVendorDataCallback, loading, search, 0, 1);
                }                
            },
            loadMasterDataCallback: function (callbackData) {
                self.filterStatusOptions = callbackData.Status;
                self.filterHoldStatusOptions = callbackData.HoldStatus;
                self.filterMakeOptions = callbackData.Make;
                self.filterEngineMakeOptions = callbackData.EngineMake;
                self.filterTransModelOptions = callbackData.TransModel;
                self.filterAxleTypeOptions = callbackData.AxleType;
                self.filterCabStyleOptions = callbackData.CabStyle;
                self.filterFleetOptions = callbackData.Fleet;
                self.filterbodyTypeOptions = callbackData.BodyType;
                self.filterDTROptions = callbackData.DtrTradeTerms;
                self.filterSalesmanOptions = callbackData.User;
                self.filterlocationOptions = callbackData.Location;
            },
            loadCityDataCallback: function (callbackData) {
                self.filterlocationOptions = callbackData.Location;
            },
            loadCompanyDataCallback: function (callbackData) {
                self.filterCompanyOptions = callbackData.Company;
            },
            loadStatusDataCallback: function (callbackData) {
                self.filterStatusOptions = callbackData.Status;
            },
            /* loadHoldStatusDataCallback: function (callbackData) {
                self.filterHoldStatusOptions = callbackData.HoldStatus;
            }, */
            loadMakeDataCallback: function (callbackData) {
                self.filterMakeOptions = callbackData.Make;
            },
            loadModelDataCallback: function (callbackData) {
                self.filterModelOptions = callbackData.Model;
            },
            loadSalesmanDataCallback: function (callbackData) {
                self.filterSalesmanOptions = callbackData.User;
            },
            loadTransModelDataCallback: function (callbackData) {
                self.filterTransModelOptions = callbackData.TransModel;
            },
            loadAxleTypeDataCallback: function (callbackData) {
                self.filterAxleTypeOptions = callbackData.AxleType;
            },            
            loadVendorDataCallback: function (callbackData) {
                self.filterVendorOptions = callbackData.Vendor;
            },
            engineMakeValueChanged: function (firstload) {
                if (typeof(firstload) != "undefined" && firstload != "" && firstload != 0) {
                    firstload = 1;
                }else{
                    firstload = 0;
                }
                self.clearEngineModelFilter();
                let make_id = [];
                if (typeof(self.filters.engine_make) != "undefined" && self.filters.engine_make != null && self.filters.engine_make != "" && self.filters.engine_make != 0) {
                    $.each(self.filters.engine_make, function (i) {
                        make_id.push(self.filters.engine_make[i].value);
                    });
                    /* make_id = self.filters.engine_make.value; */
                }
                /* console.log(make_id.join());return false; */
                self.loadAllData(['EngineModel'], false, self.engineModelDataCallback, 'undefined', '', make_id.join());
                if(firstload){
                    //self.changepage();
                }
            },
            /* engineMakeDataCallback: function (callbackData) {
                self.filterEngineMakeOptions = callbackData.EngineMake;
            }, */
            engineModelDataCallback: function (callbackData) {
                self.filterEngineModelOptions = callbackData.EngineModel;
            },
            /* loadCabStyleDataCallback: function (callbackData) {
                self.filterCabStyleOptions = callbackData.CabStyle;
            },
            loadFleetCallback : function(callbackData) {
                self.filterFleetOptions = callbackData.Fleet;
            },
            loadBodyTypeCallback : function(callbackData) {
                self.filterbodyTypeOptions = callbackData.BodyType;
            },
            loadDTRTradeTermsCallback : function(callbackData) {
                self.filterDTROptions = callbackData.DtrTradeTerms;
            }, */
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if (self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.changepage();
                }
            },
        },
    }

    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
            if (!self.permissionNameArray.includes('can_add_inventory_master') && !self.permissionNameArray.includes('can_delete_inventory_master') && !self.permissionNameArray.includes('can_view_inventory_master') && !self.permissionNameArray.includes('can_spec_sheet_inventory_master') && !self.permissionNameArray.includes('can_add_expense_master') && !self.permissionNameArray.includes('can_active_deactive_status_inventory_master') && !self.permissionNameArray.includes('can_compare_inventory_master') && !self.permissionNameArray.includes('can_multi-print_inventory_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (let i = 0; i < companies.length; i++) {
                self.userCompany.push(companies[i].company);
                // console.log(JSON.stringify(companies[i]));
            }
            if(self.userCompany && self.userCompany.length == 1) {
                setTimeout(function() {
                    self.isDepartmentSelected = true;
                    self.filters.department = [{value: self.userCompany[0], text: ''}];
                }, 300);
            }
        }
    });
</script>