<template>
    <div class="spaced-container">
        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <template v-if="permissionNameArray.includes('can_email_inventory_master') || permissionNameArray.includes('can_spec_sheet_inventory_master')">
                <div class="container-fluid xyz">
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->
                        <div class="forms-container add_user_forms_container vtc-dealer-forms custom-class">
                            <div class="row no-margin">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item"  v-if="permissionNameArray.includes('can_email_inventory_master') || permissionNameArray.includes('can_spec_sheet_inventory_master')">
                                        <a v-on:click="activetab=1" v-bind:class="'nav-link ' + [ activetab === 1 ? 'active' : '' ]">{{staticInputLabelMessages.promo_sheet}}</a>
                                    </li>
                                    <li class="nav-item"  v-if="permissionNameArray.includes('can_email_inventory_master') || permissionNameArray.includes('can_spec_sheet_inventory_master')">
                                        <a v-on:click="activetab=2" v-bind:class="'nav-link ' + [ activetab === 2 ? 'active' : '' ]">{{staticInputLabelMessages.spec_sheets}}</a>
                                    </li>
                                    <li class="nav-item"  v-if="permissionNameArray.includes('can_email_inventory_master') || permissionNameArray.includes('can_spec_sheet_inventory_master')">
                                        <a v-on:click="activetab=3" v-bind:class="'nav-link ' + [ activetab === 3 ? 'active' : '' ]">{{staticInputLabelMessages.spec_list}}</a>
                                    </li>

                                </ul>

                                <div v-if="activetab === 1" class="tabcontent" id="customer-activity-logs3" >
                                    <email-promo-sheet
                                    ></email-promo-sheet>
                                </div>
                                <div v-if="activetab === 2" class="tabcontent" id="customer-activity-logs1" >
                                    <email-spec-sheet
                                    ></email-spec-sheet>
                                </div>
                                <div v-if="activetab === 3" class="tabcontent" id="customer-activity-logs2" >
                                    <email-spec-list
                                    ></email-spec-list>
                                </div>
                            </div>
                        </div>
                        <!-- Forms -->
                    </div>
                    <!--page content-->
                </div>
            </template>
            <template v-else>
                <no-access></no-access>
            </template>
            <app-footer></app-footer>
        </div>
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';

    import EmailSpecSheet from "@/components/AssetMaster/SpecSheet/EmailSpecSheet";
    import EmailSpecList from "@/components/AssetMaster/SpecSheet/EmailSpecList";
    import EmailPromoSheet from "@/components/AssetMaster/SpecSheet/EmailPromoSheet";
    Vue.component("app-footer", Footer);

    export default {
        props: ['redirectTo'],
        name: 'ViewAsset',
        components: {
            "email-promo-sheet" : EmailPromoSheet,
            "email-spec-list" : EmailSpecList,
            "email-spec-sheet" : EmailSpecSheet,
            VueTagsInput,
        },

        mixins: [commonMixins],
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                activetab: 1,
                pageTitle: "Inventory",
                assetData: {
                    content: {

                    },
                    media:[]
                },
                permissionNameArray: ['can_email_inventory_master','can_spec_sheet_inventory_master'],
                redirectId : '',
                listCRMData : [],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            var userObj = localStorage.getItem("user_obj");
            //if user logged in than remove login button and registration button
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.firstName = userJson['first_name'];
                self.lastName = userJson['last_name'];
                self.email = userJson['email'];
            }
            if (typeof (self.$route.query.uid) != 'undefined' && self.$route.query.uid != "" && self.$route.query.uid != 0) {
                self.getPromoAssetData(self.$route.query.idlist);
            }
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.getLoginIdPermissions();
            self.redirectTospecificTab();
        },
        updated: function () {
            self.$nextTick(function () {
                self.removeQueryParamsFromUrl();
            })
        },
        methods: {
            removeQueryParamsFromUrl : function () {
                let query = Object.assign({}, self.$route.query);
                delete query.redirectTo;
                self.$router.replace({ query })
            },

            redirectTospecificTab : function () {
                if (self.$route.query.redirectTo != null && self.$route.query.redirectTo !== '' && typeof self.$route.query.redirectTo != "undefined") {
                    self.$nextTick(function () {
                        self.activetab = parseInt(self.$route.query.redirectTo);
                    });
                } else {
                    self.activetab = 1;
                }
            },
        }

    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>