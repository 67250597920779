<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template>
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <button v-show="permissionNameArray.includes('can_add_set_by_branch_defaults')" class="btn btn-primary waves-effect pull-right ml-2" @click="showForm()">Add Configuration</button>
                    </div>
                   
                    <div class="col-12 paddingnone">
                        <div class="row no-margin border">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <thead>
                                        <tr>
                                            <!-- <th>Branch</th>
                                            <th>Location Id</th> -->
                                            <th>Action</th>
                                            <!-- <th>Vehicle Type</th> -->
                                            <!-- <th>Nrid</th> -->
                                            <th>Doc Preparation Fee</th>
                                            <th>Tire Tax</th>
                                            <th>Hold Back Percentage</th>
                                            <!-- <th>License/Title</th> -->
                                            <th>No License</th>
                                            <!-- <th>Is NewUsed</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="setByBranchList && setByBranchList.length > 0">
                                            <template v-for="data in setByBranchList">
                                                <tr>
                                                    <!-- <td>{{data.branch ? data.branch.name : ''}}</td>
                                                    <td>{{data.branch ? data.branch.id : ''}}</td> -->
                                                    <td>
                                                        <a v-on:click="editSetByBranchDefault(data)" title='Edit Vehicle' href="javascript:void(0)" class="mar-right-0">
                                                            <i aria-hidden="true" class="fa fa-edit"></i>
                                                        </a>
                                                        <!-- <a v-on:click="delete(vehicle.id)" title='Delete Vehicle' href="javascript:void(0)">
                                                            <i aria-hidden="true" class="fa fa-trash-o text-danger"></i>
                                                        </a> -->
                                                    </td>
                                                    <!-- <td>{{data.is_used.toUpperCase()}}</td> -->
                                                    <!-- <td>{{data.nrid}}</td> -->
                                                    <td>{{data.doc_prepare_fee}}</td>
                                                    <td>{{data.tire_tax}}</td>
                                                    <td>{{data.hold_back_percentage}}</td>
                                                    <!-- <td>{{data.license_title}}</td> -->
                                                    <td><input v-model="data.no_license" type="checkbox" disabled /></td>
                                                    <!-- <td><input v-model="data.is_new_used" type="checkbox" disabled /></td> -->
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <th colspan="5" class="text-center">No Data</th>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isShowForm">
                    <add-branch-default :editData="editData"></add-branch-default>
                </div>
            </div>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../../mixins/commonMixins';
    import EventBus from "../../../event-bus";
    import {HTTP} from '../../../../http-common';
    import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
    import AddBranchDefaultsModal from "@/components/AssetMaster/NewTransactionSheet/LocationSetDefaultTabs/AddBranchDefaultsModal";

    Vue.component("app-footer", Footer);

    export default {
        name: 'ListStaticSetByBranchDefault',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
            'add-branch-default': AddBranchDefaultsModal
        },
        data () {
            return {
                setByBranchList: [],
                permissionNameArray: ['can_list_set_by_branch_defaults','can_add_set_by_branch_defaults'],
                isShowForm: false,
                editData: {},
                modalInstance: null,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            self.getData();
            self.initializeModals();
        },
        methods: {
            initializeModals() {
                self.modalInstance = new bootstrap.Modal(
                    document.getElementById("set-by-branch-default-form"),
                    {
                        backdrop: "static",
                        keyboard: false,
                    }
                );
            },
            getData() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id,
                        type: 'set_by_branch'
                    }
                };
                HTTP.get('/get-location-static-data', config)
                    .then(response => {
                        self.setByBranchList = response.data.content.branch_defaults;
                    });
            },
            showForm() {
                self.modalInstance.show();
                self.isShowForm = true;
                self.editData = {};
            },
            editSetByBranchDefault(data) {
                self.modalInstance.show();
                self.isShowForm = true;
                self.editData = data;
            }
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof this != "undefined") {
            this.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                this.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("set-by-branch-list-data",function (listData){
        if (typeof self != "undefined"){
            self.setByBranchList = listData;
            self.modalInstance.hide();
        }
    });
    EventBus.$on("close-branch-default", function (status) {
        if (typeof self != "undefined") {
            self.isShowForm = status;
            self.editData = {};
            self.modalInstance.hide();
        }
    });
    
</script>