<template>
  <!-- <div class="spaced-container"> -->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_tax_rates')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div class="forms-container add_user_forms_container vtc-dealer-forms">
            <div class="row no-margin">
              <!-- Name -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">{{ staticInputLabelMessages.name_label }}</div>
                </div>
                <div class="col-9 paddingleft0">
                  <input @input="nameError = ''" id="Name" v-model="name" class="form-control" type="text" maxlength="190"/>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ nameError }}</span>
                </div>
              </div>

              <!-- Code -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">{{ staticInputLabelMessages.code_label }}</div>
                </div>
                <div class="col-9 paddingleft0">
                  <input @input="codeError = ''" id="Code" v-model="code" class="form-control" type="text" maxlength="190"/>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ codeError }}</span>
                </div>
              </div>

              <!-- Rate -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">{{ staticInputLabelMessages.tax_rate_label }}</div>
                </div>
                <div class="col-9 paddingleft0">
                  <input @input="rateError = ''" id="Rate" v-model="rate" class="form-control" type="number"/>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ rateError }}</span>
                </div>
              </div>

            </div>

            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger" id="alertError">
                  <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input id="addModelBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save"
                     class="btn btn-primary" v-on:click.prevent="addModel()">
              <input id="addAndContinueModelBtn" :disabled="disableButtons.saveAndContinue" type="submit"
                     :value="buttonNames.saveAndContinue" class="btn btn-primary"
                     v-on:click.prevent="addModel(flag='Save and Continue')">
              <input id="cancelModel" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel"
                     class="btn btn-primary" v-on:click.prevent="buttonBack()">
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import {commonMixins} from '../../mixins/commonMixins';
import EventBus from ".././event-bus";
import {HTTP} from '../../http-common';
import {Datetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import NoAccessPage from "../NoAccessPage/NoAccessPage"
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.component("app-footer", Footer);

export default {
  name: 'AddUpdateTaxRate',
  mixins: [commonMixins],
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      staticInputLabelMessages: "",
      staticMessages: "",
      isEdit: false,
      id: 0,
      name: "",
      code: "",
      rate: "",
      nameError: "",
      codeError: "",
      rateError: "",

      buttonNames: {
        save: 'Save',
        saveAndContinue: 'Save And Continue',
        cancel: 'Cancel'
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false
      },
      alertClass: '',
      alertMessage: '',
      pageTitle: "Add Tax Rate",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        },
      },
      permissionNameArray: ['can_add_tax_rates'],
    }
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push('/');
    }
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;

    $("#alertError").hide();
    self.isEdit = false;
    // this is for updating
    if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
      self.id = self.$route.params.id;
      self.isEdit = true;
      self.getModelDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      $("#addAndContinueModelBtn").hide();
      self.pageTitle = "Edit Tax Rate";
    }
  },
  methods: {
    buttonBack() {
      self.$router.push({name: "ViewTaxRates"});
    },
    clearDataOnSaveAndContinue: function () {
      self.name = "";
      self.nameError = "";
      self.rate = "";
      self.rateError = "";
      self.code = "";
      self.codeError = "";
    },
    showAlertOnSave: function (alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
        $("#success-alert").slideUp(500);
      });
    },
    // this is for updating
    getModelDetailsById: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id
        }
      }
      HTTP.get('/get/tax-rate', config)
        .then(response => {
          self.name = response.data.content.name;
          self.code = response.data.content.code;
          self.rate = response.data.content.rate;
        })
        .catch(function (err) {
          self.catchHandler(err, function () {
          });
        });
    },
    // this is for validating I think
    checkForm: function () {
      var checked = true;
      if (!self.name) {
        self.nameError = "Please enter Name";
        if (checked) {
          checked = false;
        }
      }
      if (!self.code) {
        self.codeError = "Please enter Code";
        if (checked) {
          checked = false;
        }
      }
      if (!self.rate) {
        self.rateError = "Please enter Rate";
        if (checked) {
          checked = false;
        }
      } else {
        // Convert the rate to a number to check its value
        let rateNumber = Number(self.rate);
        // Check if rate is a number and within the valid range of decimal(8,5)
        if (
          isNaN(rateNumber) || 
          rateNumber <= 0.00001 || 
          rateNumber >= 999.99999
        ) {
          self.rateError = 
            "Rate must be a valid number between 0.00001 to 999.99999";
          if (checked) {
            checked = false;
          }
        } else {
        // Regular expression to validate decimal(8,5)
         const ratePattern = /^\d{1,3}(\.\d{1,5})?$/;
         if (!ratePattern.test(self.rate)) {
           self.rateError = 
            "Rate must be a valid decimal number with up to 8 total digits and up to 5 digits after the decimal point";
           if (checked) {
             checked = false;
           }
         }
       }
      }
      return checked;
    },
    // this is for adding to the database.
    addModel(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = new FormData();
          data.append('name', self.name);
          data.append('rate', self.rate);
          data.append('code', self.code);

          if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id !== 0 && self.$route.params.id !== '') {
            data.append('id', self.$route.params.id);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            data.append('id',self.id);
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post("/tax-rate/add-update", data, config)
            .then(function (response) {
              if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, 'success', false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function () {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, 'error', false);
                }
              }
            })
            .catch(function (err) {
              if (self.$route.params.id != 0 && self.$route.params.id != '') {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function () {
              });
            });
        }
      }, 200);
    },
  }
}
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name)
    }
  }
});
</script>
