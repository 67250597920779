<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_drag_expense_type_location_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <div class="admin-page-content">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="cards-container">
                                <template v-if="expenseList && expenseList.length > 0">
                                    <draggable v-model="expenseList" v-on:change="changePosition()" class="drag-view">
                                        <template v-for="(expense, index) in expenseList">
                                            <div :key="'main-div ' + index" class="col-6 text-alignment-center mb-5">
                                                <div class="cards-block">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h4 class="card-title mb-0">
                                                                {{expense.expenseTypeId['text']}} ({{expense.typeFees}})
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </draggable>
                                </template>
                                <template v-else>
                                    No Expense found!!!
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import $ from 'jquery';
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import draggable from 'vuedraggable';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'DraggableExpenseType',
        mixins: [commonMixins],
        components: {
            draggable,
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                expenseList: [],
                pageTitle: "Location Exoennse",
                permissionNameArray: ['can_drag_expense_type_location_master'],
            }
        },
        computed: {
            dragOptions: function () {
                return {
                    staticMessages: "",
                    staticInputLabelMessages: "",
                    group: 'images',
                    handle: ".drag-handle",
                }
            },
        },
        beforeMount() {
            self = this;
        },
        watch: {
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('location-list');
            EventBus.$emit("menu-selection-changed", "location-list");
            self.getLocationExpense();
        },
        methods: {
            buttonBack() {
                self.$router.push('/location/list');
            },
            getLocationExpense: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id,
                        identify: 'draggble_view'
                    }
                };
                self.showLoader();
                HTTP.get('/location/get/form', config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.pageTitle = data.data.content.name + ' (Expense Type)';
                            self.expenseList = data.data.content.expense_type_fee;
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            },
            changePosition: function() {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    expenseList : self.expenseList,
                    location_id : self.$route.params.id,
                }
                HTTP.post("/location/expense/position/change", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        self.getLocationExpense();
                        self.$emit('updateDisplayData',10);
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>