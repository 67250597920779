<template>
    <div class="notification-sidebar">
        <div id="noticication-header">
            <i class="fa fa-times" id="close-notification-sidebar" @click="$emit('hide-sidebar', false)"></i>
            <h2>Notifications</h2>
        </div>
        <common-loader refId="commonLoaderNotificationSidebar" ref="commonLoaderNotificationSidebar"></common-loader>

        <template v-if="!loading">
          <template v-if="notificationList.length > 0">
            <ul id="notification-list">
              <li v-for="(data,index) in notificationList" :key="index">
                <div class="notifier-logo">{{ data.short_name_logo.toUpperCase() }}</div>
                <div class="notification-content">
                    <div>
                        <span class="notifier-name">
                            {{data.created_by}}
                        </span>
                        {{ data.message }}
                        <span v-if="Object.keys(data).includes('deal_id')">
                            <a href="javascript:void(0)" @click="redirectToDeal(data.deal_id)">
                                {{ data.deal_no }}
                            </a>
                        </span>
                        <template v-if="data.notification == 'AssetExpenseNotification' && data.is_description_available == 1">
                          <div v-html="data.description"></div>
                        </template>
                    </div>
                    <div>{{ data.created_date }}</div>
                </div>
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="no-notification-section">
              <img src="/static/images/no-notification.svg" alt="Velocity Vehicle Group">
              <div class="no-notification-info-block">
                <p>{{ allStaticMessages.no_new_notification_msg }}</p>
                <span>{{  allStaticMessages.no_notification_received_msg }}</span>
              </div>
            </div>
          </template>
        </template>
    </div>
</template>

<script>
import { HTTP } from '../../http-common';
import {commonMixins} from '../../mixins/commonMixins';
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
    mixins: [commonMixins],
    components: {
      "common-loader": CommonLoader,
    },
    props: ['showNotificationSidebar'],
    data() {
        return {
            notificationList: [],
            loading: false
        }
    },
    methods: {
        getNotificationList() {
            const config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
            };

            HTTP.get("/notifications", config)
            .then((response) => {
                this.notificationList = response.data.content;
                this.$emit('count', 0);
            })
            .catch(function (err) {
                console.log('ERROR!!', err);
            })
            .finally(() => {
              this.loading = false;
              $("#commonLoaderNotificationSidebar").find(".loading-container.lodingTable").hide();
            });
        },
        redirectToDeal(id) {
            const routeData = this.$router.resolve({
                name: "NewTransactionSheet",
                query: {deal_id: id },
            });
            window.open(routeData.href, "_self").focus();
        }
    },
    watch: {
        showNotificationSidebar:{
            handler(status){
                if(status) {
                  this.loading = true;
                  setTimeout(() => {
                    $("#commonLoaderNotificationSidebar").find(".loading-container.lodingTable").show();
                  }, 50);
                  this.getNotificationList();
                }
            },
            immediate:true,
        },
    }
}
</script>

<style :scoped>
    #noticication-header {
        padding: 10px;
    }

    #close-notification-sidebar {
        position:absolute;
        right:20px;
        top:20px;
        cursor:pointer;
        padding:3px;
        padding-left:8px;
        padding-right:8px;
        border-radius:100%;
        margin-top: 30px;
        color: #2471A3;
        font-size: 20px;
    }

    #close-notification-sidebar:hover{
        color:rgb(240, 229, 229);
        background-color:#2471A3;
    }

    .notification-sidebar{
        position:fixed;
        width:440px;
        right:-500px;
        box-shadow:0px 0px 10px 3px #9d9999;
        background-color:rgba(255, 255, 255, 1);
        height:100%;
        top:0px;
        z-index: 1000;
        right: 0;
    }

    .notification-sidebar h2{
        color: #2471A3;
        font-family: 'SanFranciscoDisplayMedium';
        font-size: 26px;
        font-style: normal;
        line-height: 24px;
        margin-top: 40px;
        padding-left: 12px;
        border-bottom: 4px solid #2471A3;
        padding-bottom: 10px;
    }

    #notification-list {
        list-style-type: none;
        margin: 0;
        padding: 0 0 30px;
        max-height: 90%;
        overflow-y: auto;
    }

    #notification-list li {
        border-bottom: 1px solid #e8e8e8;
        padding: 10px;
        display: flex;
        gap: 15px;
    }

    #notification-list li:last-child {
        border-bottom: none;
    }

    #notification-list li.seen {
        background-color: #f0f0f0;
    }

    .notification-content > div:first-child {
        flex: 1;
        margin-right: 20px;
    }

    .notification-content {
        display: inline-flex;
        flex-direction: column;
        gap: 5px;
    }

    .notifier-logo {
        min-width: 50px; /* Set a minimum width */
        max-width: 50px; /* Set a maximum width based on your design */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 100%;
        background: #ffbb44;
        color: #fff;
        justify-content: center;
        line-height: 50px;
        text-align: center;
        min-height: 50px;
        max-height: 50px;
        font-family: 'SanFranciscoDisplayBold';
        font-size: 20px;
    }

    .notifier-name {
        font-family: 'SanFranciscoDisplayBold';
    }

    .notification-description {
      border: 1px solid #DEE2E6;
      padding: 10px;
      font-size: 15px;
      border-radius: 5px;
    }
    .notification-description .notificaiton-offer-data-section {
      display: flex;
      justify-content: space-between;
    }
    .notification-description .notificaiton-offer-data-section a {
      text-decoration: none;
    }
    .notification-description .notificaiton-offer-data-section a i{
      padding-right: 10px;
      cursor: pointer;
      color: #D68910;
    }
    .no-notification-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80%;
    }
    .no-notification-section img {
      width: 50%;
      margin-bottom: 20px;
      display: block;
    }
    .no-notification-section .no-notification-info-block {
      text-align: center;
    }
    .no-notification-section .no-notification-info-block p {
      font-size: 22px;
      font-family: 'SanFranciscoDisplayMedium';
      margin-bottom: 0;
    }
    .no-notification-section .no-notification-info-block span {
      display: block;
      padding-top: 20px;
      color: #8d8a8ad4;
      font-size: 16px;
      font-family: 'SanFranciscoDisplayMedium';
    }
    .notification-description .notificaiton-offer-data-section a i:hover {
      color:#2471A3;
    }
</style>
