<template>
    <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name width-100-pct-imp" >Order: {{sls_id}}</div>
                    </div>
                    <div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                        
                            <div class="page_title_left">
                                <span class="pl-0">Parts Items</span>
                                <p v-if="totalRecords > 0">{{totalRecords}} Results Found</p>
                            </div>
                            <button
                                type="submit"
                                v-on:click.prevent="buttonBack()"
                                class="btn btn-primary waves-effect pull-right"
                            >
                                Back
                            </button>
                           
                        </div>

                        <div class="col-12 paddingnone p-1 pb-2">
                            <div class="col-md-6 paddingnone" >
                                
                            </div>
                        </div>
                        
                        <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                            
                        <div class="col-12 paddingnone">
                            <!-- Pagination Start -->
                            <div class="top_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                            
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <tbody>
                                    <template v-if="invoiceArr && invoiceArr.length > 0">
                                        <template v-for="(invoice, index) in invoiceArr">
                                            <tr :key="index">
                                                <!-- <td >{{invoice.sls_id}}</td> -->
                                                <td class="">{{invoice.itm_id}}</td>
                                                <td class="">{{invoice.itm_typ}}</td>
                                                <td class="">{{invoice.des}}</td>

                                                <td class="">{{invoice.reason}}</td>
                                                <td class="text-right">{{invoice.amt_price}}</td>
                                                <td class="text-right">{{invoice.amt_rebate}}</td>
                                                
                                                <td class="text-right">{{invoice.qty_ship}}</td>
                                                <td class="text-right">{{invoice.qty_backorder}}</td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{allStaticMessages.data_not_available}}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>

                                    <thead>
                                    <tr>
                                       
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                        </template>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                        </template>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            
                            <!-- Pagination Start -->
                            <div class="bottom_pagination vgt-wrap__footer crm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                                v-model="perPage"
                                                @input="changePerPage"
                                                name="customPerPage"
                                                id="customPerPage"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="paginationDropDownArr"
                                                placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">
                                            {{startEndRecord}} of {{totalRecords}}
                                        </p>
                                    </div>

                                    <!-- v-if="totalRecords >= perPage" -->
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                    </div>

                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination End -->
                    

                        </div>
                    </div>
                </div>
</template>
<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";

    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    

    export default {
        name: 'ListPtslsItm',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                perPageValue : 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                summary: {
                    total_business: 0
                },
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                invoiceArr: [],
                headers: [],
                filters : {
                },
                appliedHeaders : [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord : "",
                selectAll: false,
                permissionNameArray : ['can_list_part_invoce_customer_master'],
                actionDisplay : false,
                accordianIds : [],
                jumpToHistoryPage : 1,
                filterCount : 0,
            }
        },
        props: {
            sls_id: {
                type: [String, Number],
                required: true,
                default: ''
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
           
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
           
            
            var listHistoryData = null;
            self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

            let headersNames = [];
            headersNames['filters'] = self.filters;
            self.listExecutionState = false;
            self.loadPartItems(1);
            self.getLoginIdPermissions();
        },
        methods: {
            buttonBack() {
                this.$parent.BacktoPreviousPage(1);
                // self.$router.push({ name: "UpdateContact", params: { id: self.$route.params.id}});
            },
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadPartItems();
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadPartItems();
                }
            },
            refreshList : function() {
                self.jumpToPage = 1;
                self.keyMonitor();
                self.loadPartItems();
            },
            
            keyMonitor: function() {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            
            applyFilterParentComponent : function (data) {
                let key;
                let value;
                for (let i = 0; i < data.length; i++) {
                    if (data[i]['column_name'] != "null") {
                        key = data[i]['column_name'];
                        value = data[i]['field'];
                        self.filters[key] = value;
                    }
                }
                self.appliedHeaders = data;
                self.loadPartItems();
            },
            setAlreadyAppliedFilters : function () {
                if (self.appliedHeaders.length > 0) {
                    self.headers = self.appliedHeaders;
                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadPartItems: function(first) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    self.perPage = 500;
                    self.loadPartItems();
                }
                first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };

                var fields = {
                    filters : self.filters,
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    contact_id: self.$route.params.id,
                    sls_id: self.sls_id,
                };
                
                // this.storeFilters('listcontactemail', fields, false);
                self.showLoader();
                self.filterCount = self.filterCountFunction(self.filters);


                HTTP.post("/contact/getptslsitm", fields, config)
                    .then(function (data) {
                        self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            self.summary = data.data.summary;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadPartItems();
                                }, seconds);
                                
                            }
                            self.invoiceArr = data.data.content.data;
                            
                            self.headers = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filters);

                            self.addColumnClass(self.headers);

                            
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
                    })
                    .catch(function (err) {
                        self.listExecutionState = false;
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            sortTableData: function(name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                    if(self.columnName == name) {
                        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                    } else {
                        self.sortType = "asc";
                    }
                    self.columnName = name;
                    self.loadPartItems();
                }
            },
            
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }

            if (!self.permissionNameArray.includes('can_email_customer_master')) {
                self.headers.pop();
                self.actionDisplay = false;
            }

        }
    });


</script>
<style scoped>
.width-100-pct-imp{
    width: 100% !important;
}
</style>