<template>
  <div
    class="modal fade"
    id="user-contact-manager-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="location-manager-form"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="location-manager-form">
            {{ pageTitle }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="location-manager">
            <!--Forms-->
            <div class="forms-container add_user_forms_container vtc-dealer-forms">
                <div class="">
            <div class="forms-block">
              <div class="" style="text-align: left;">
                <div class="form-label">
                  {{ staticInputLabelMessages.manager_type_label }}
                  <div class="requiredstar_new">*</div>
                </div>
              </div>
              <div class="">
                <v-select
                  :disabled=disableContactType
                  v-model="contact"
                  @input="contactError=''"
                  name="staticValue"
                  label="text"
                  :clearable="false"
                  :options="contactTypeList"
                  :closeOnSelect="true"
                  :placeholder="staticMessages.manager_type_place_holder"
                >
                  <span
                    slot="no-options"
                    @click="$refs.select.open = false"
                  >
                    <template v-if="!isManagerTypeRecordNotFound">
                      {{ staticMessages.managed_by_search }}
                    </template>
                    <template v-else>
                      {{ staticMessages.search_record_not_found }}
                    </template>
                  </span>
                </v-select>
                <span class="text-danger">{{ contactError }}</span>
              </div>
            </div>

            <div class="forms-block">
              <div class="" style="text-align: left;">
                <div class="form-label">
                  {{ staticInputLabelMessages.location_label }}
                  <div class="requiredstar_new">*</div>
                  <!-- Location : -->
                </div>
              </div>
              <div class="">
                <v-select
                  :multiple=true
                  v-model="locationIds"
                  name="location"
                  label="text"
                  @input="locationError = ''"
                  :options="locationArr"
                  :open-on-clear="true"
                  :placeholder="staticMessages.location_place_holder"
                >
                  <span
                    slot="no-options"
                    @click="$refs.select.open = false"
                  >
                    <template v-if="!isLocationRecordNotFound">
                      {{ staticMessages.location_search }}
                    </template>
                    <template v-else>
                      {{ staticMessages.search_record_not_found }}
                    </template>
                  </span>
                </v-select>
                <span class="help is-danger">{{ locationError }}</span>
              </div>
            </div>
            </div>
            </div>
            <!-- Forms -->
          </div>
        </div>
            <div class="button text-right">
              <button
                type="button"
                class="btn btn-outline-dark mr-1"
                aria-label="Close"
                @click="closeModal()"
              >
                Close
              </button>
              <button
                type="button"
                :value="buttonNames.save"
                class="btn btn-primary"
                @click="submitData()"
              >
                Save
              </button>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../../event-bus";
import { HTTP } from '../../../http-common';

Vue.component("app-footer", Footer);

export default {
  name: "AddUserRolesForLocationModal",
  components: {
  },
  mixins: [commonMixins],
  props: ["userData"],
  data() {
    return {
      staticInputLabelMessages: "",
      staticMessages: "",
      pageTitle: "Add Roles and Responsibility",
      disableContactType:false,
      locationArr: [],
      contactTypeList: [],
      editpageId:0,
      locationIds: "",
      contact: "",
      locationError: "",
      contactError: "",
      isLocationRecordNotFound: false,
      isManagerTypeRecordNotFound: false,
      buttonNames: {
        save: "Save",
        update: "Update",
      },
      showConatactManagerLoader: false,
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.loadAllData(
      ["Location"],
      true,
      self.loadLocationTypeCallback,
      "undefined",
      ""
    );
    self.loadAllMasterData(['DealStatus'], self.loadDealStatusCallback, "", {req_for: "LocationContactType"});
  },
  methods: {
    loadLocationTypeCallback: function (callbackData) {
      self.isLocationRecordNotFound = false;
      self.locationArr = callbackData.Location;
      if (self.locationArr.length == 0) {
        self.isLocationRecordNotFound = true;
      }
    },
    loadDealStatusCallback: function (callbackData) {
      self.contactTypeList = callbackData.DealStatus;
    },
    submitData() {
      if(self.checkForm()) {
        self.showConatactManagerLoader = true;
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const data = {
          contact_type: self.contact,
          location_list: self.locationIds,
          user_id: self.$route.params.id,
          id: self.editpageId
        };

        HTTP.post("/user/location/contacts", data, config)
          .then(function (response) {
            self.showConatactManagerLoader = false;
            if (response.data.code == '200') {
              self.showSAlert(
                response.data.message,
                response.data.status,
                false,
                2
              );
              self.closeModal('update');
            }
          })
          .catch(function (err) {
            console.log("Update Location Contact Error>>>", err);
            self.catchHandler(err, function () {});
          });
      }
    },
    checkForm: function () {
      var checked = true;
      if (self.locationIds == null || self.locationIds.length==0) {
        self.locationError = "Please select location";
        if (checked) {
          checked = false;
        }
      }
      if (self.contact == null || !self.contact.value) {
        self.contactError = "Please select contact";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    clearData() {
        self.locationIds = '';
        self.contact = '';
        self.locationError = '';
        self.contactError = '';
        self.showConatactManagerLoader = false;
    },
    closeModal(type=null) {
      self.clearData();
      EventBus.$emit("close-user-contact-manager-default", false, type);
    },
  },
  watch: {
    userData(data){
      if(data != null){
        self.disableContactType = true;
        self.editpageId = data.loc_cont_ids;
        self.contact = data.contact_type;
        self.locationIds = data.location;
      }else{
        self.disableContactType = false;
        self.editpageId = 0;
        self.contact = "";
        self.locationIds ="";
      }
    }
  }
};
</script>

<style scoped>
 .button {
    padding-right: 30px;
    padding-bottom: 20px;
 }
 .vtc-dealer-forms .v-select .selected-tag {
  white-space: normal;
 }
 .vtc-dealer-forms .v-select .vs__selected-options {
    flex-wrap: wrap !important;
}
.vtc-dealer-forms .v-select .dropdown-menu {
    max-height: 200px !important;
}
</style>
