<template>
    <div id="main-header">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>This is Test Environment !</p>
                </div>
            </div>
        </div>
        <nav class="navbar navbar-default no-margin app-sidebar-nav">
            <div class="navbar-header fixed-brand">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" id="menu-toggle">
                    <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
                </button>
                <a class="navbar-brand" href="javascript:void(0)"><img src="/static/images/logo.jpg" alt="Velocity Vehicle Group"></a>
            </div>
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li class="active">
                        <button class="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2"> 
                            <img src="/static/images/menubar.png" alt="Menu" class="menubar">
                        </button>
                    </li>
                </ul>
            </div>
            <div id="wrap">
                <div id="global-search">
                    <input name="global_search" id="global_search" type="text" v-model="searchText" autocomplete="off" placeholder="Search">
                    <div id="global-search-icon" v-on:click.prevent="applyGlobalSearch()"></div>
                </div>
                <!-- <div class="notify" title="Notification">
                    <i :class="notIcon" @mouseenter="notIcon='fa fa-bell'" @mouseleave="notIcon='fa fa-bell-o'" @click="showNotificationSidebar=true"></i>
                    <sup class="badge rounded-pill badge-danger" v-if="notificationCount>0">{{ notificationCount }}</sup>
                </div> -->
            </div>
            <div class="user-info">
                <div class="user-info-container">
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary dropdown-toggle" id="dropdownMenuButton" @click="toggleLogout" aria-expanded="false">
                            <div class="dropdown-user">
                                <div class="username-letter">{{iconLetters}}</div>
                                <div class="dropdown-user-right">
                                    <div class="display-username" :title="firstName + ' ' + lastName">{{firstName}} {{lastName}}</div>
                                <div class="usermailid" :title="username">{{username}}</div>
                            </div>
                        </div>
                    </button>
                    <ul class="dropdown-menu" v-if="showLogout" aria-labelledby="dropdownMenuButton">
                        <li>
                            <a class="dropdown-item" href="javascript:void(0)" @click.prevent="logout()">Logout</a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </nav>
        <notification-sidebar v-if="showNotificationSidebar" @hide-sidebar="showNotificationSidebar=$event" @count="notificationCount=$event" :showNotificationSidebar="showNotificationSidebar" />
    </div>
</template>

<script>
    import EventBus from '.././event-bus';
    import {HTTP} from '../../http-common';
    import {commonMixins} from '../../mixins/commonMixins';
    import NotificationSidebar from '../NotificationMaster/NotificationSidebar';
    var self;
    export default {
        name: 'Header',
        components: {
            "notification-sidebar": NotificationSidebar
        },
        mixins: [commonMixins],
        data() {
            return {
                username: '',
                firstName: '',
                lastName: '',
                iconLetters: '',
                searchText:'',
                isRedirection: null,
                notIcon: 'fa fa-bell-o',
                showNotificationSidebar: false,
                notificationCount: 0,
                showLogout: false,
            }
        },

        beforeMount: function () {
            self = this;
            self.isRedirection = process.env.VUE_APP_IS_REDIRECTION;
        },
        mounted: function () {
            self = this;
            let search = (typeof self.$route.query != 'undefined' && typeof self.$route.query.search != 'undefined') ? self.$route.query.search : "";
            self.searchText = search;
            self.getNotificationCount();
        },
        watch: {
            // enable search only on click search button instead on change
            // searchText(newValue) {
            //     if (typeof newValue != 'undefined' && newValue.length > 4) {
            //         self.applyGlobalSearch();
            //     }
            // }
        },
        methods: {
            toggleLogout() {
                self.showLogout = !self.showLogout;
            },
            logout: function () {

                let current_build = window.localStorage.getItem("build_version");

                const config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };

                HTTP.get("/logout", config)
                .then((response) => {
                    
                    localStorage.clear();
                    localStorage.setItem("build_version", current_build);
                    if (self.isRedirection === 'true') {
                        window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/logout?redirect_url=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
                    } else {
                        self.$router.push("/");
                    }
                })
                .catch(function (err) {
                    console.log('ERROR!!', err);
                });

                
            },
            applyGlobalSearch(){
                let currentRouteName = self.$route.name;
                if(typeof self.searchText != 'undefined' && self.searchText.length >= 4){
                    if(currentRouteName != 'GlobalSearch' ){
                        self.$router.push({ path: '/global/search', query: { search: self.searchText } });
                    }else{
                        EventBus.$emit("global-search-changed", self.searchText);
                    }
                } else {
                    self.$swal("","Please search more than 4 characters", "error");
                }
                
            },
            getNotificationCount() {
                const config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                };

                HTTP.get("/notifications/count", config)
                .then((response) => {
                    this.notificationCount = response.data.content;
                })
                .catch(function (err) {
                    console.log('ERROR!!', err);
                });
            }
        },
    }
    EventBus.$on('login-user-info', function (isLogin, user_name, first_name, last_name) {
        if(typeof (self) != "undefined"){
            self.username = user_name;
            if(first_name != null || last_name != null) {
                self.firstName = first_name;
                self.lastName = last_name;
            } else {
                self.firstName = "NA";
                self.lastName = "";
            }
            //First Two Characters in Upper Case of Username in iconLetters
            self.iconLetters = self.username.substring(0,2).toUpperCase();
        }
    });
</script>