<template>
    <div
        class="modal"
        id="changeCustomerModal"
        tabindex="-1"
        role="dialog"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content" v-if="!isResponseSuccess">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Move Opportunity Deal</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click.prevent="closeMoveOpportunityDealModal('close')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mv-opp-deal-modal-body">
              <div class="row mv-op-deal-select">
                <div class="col-md-3">
                    <label class="form-label pt-2 float-right">Move To:</label>
                </div>
                <div class="col-md-9 pl-0">
                  <div>
                    <v-select
                      v-model="customer"
                      name="customerId"
                      label="text"
                      :filterable="false"
                      :options="customerList"
                      :clearable="false"
                      @search="onSearchCustomer"
                      @input="customerError=''"
                      :placeholder="mvPlaceholder"
                      class="custom-ci-auto-search mv-opp-deal-select"
                      id="mv-op-de-cus"
                    >
                    </v-select>
                    <div class="text-danger font-weight-bold">*</div>
                  </div>
                  <span class="help is-danger pl-1">{{customerError}}</span>
                </div>
              </div>
              <div class="row mv-op-deal-select">
                <div class="col-md-3">
                    <label class="form-label pt-2 float-right">Description:</label>
                </div>
                <div class="col-md-9 pl-0">
                  <div>
                    <textarea v-model="description" class="mv-opp-deal-textarea"></textarea>
                  </div>
                </div>
              </div>
              <div class="pt-2 table-responsive">
                <table class="table custom-table jsmartable">
                  <caption class="d-none">Move Opportunity Deal</caption>
                  <thead>
                    <th></th>
                    <th>Deal</th>
                    <th>Opportunity Ref-No</th>
                    <th>Customer</th>
                    <th>Subject</th>
                    <th>Decision Date</th>
                  </thead>
                  <tbody v-if="loader">
                    <tr>
                      <td colspan="6">
                        <loader />
                      </td>
                    </tr>
                  </tbody>
                  <template v-else-if="opportunityDealList.length > 0">
                    <tbody v-for="(data, index) in opportunityDealList" :key="index">
                      <tr v-for="(deal) in data.deals" :key="deal.id">
                        <td><input type="checkbox" :checked="isCheckboxChecked" @change="listSelectedDeal($event,deal.id,index)"></td>
                        <td style="vertical-align: middle;">{{ deal.deal_mapper.deal_no }}</td>
                        <td style="vertical-align: middle;">{{ data.reference_no }}</td>
                        <td style="vertical-align: middle;">{{ data.get_customer.name }}</td>
                        <td style="vertical-align: middle;">{{ data.subject }}</td>
                        <td style="vertical-align: middle;">{{ data.decision_date ? data.decision_date.split(" ")[0] : '' }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <div class="d-flex justify-content-center">
                          <h6>No Data</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary waves-effect" @click="moveOpportuityDeal()">Move Deal</button>
              <button type="button" class="btn btn-secondary waves-effect" @click="closeMoveOpportunityDealModal('close')">Close</button>
            </div>
          </div>
          <div class="modal-content" v-else>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Moved Opportunity Deal Successfully</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click.prevent="closeMoveOpportunityDealModal('api')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mv-opp-deal-modal-body table-responsive">
              <table class="table custom-table jsmartable">
                  <caption class="d-none">Moved Opportunity Deal Successfully</caption>
                  <thead>
                    <th>Deal</th>
                    <th>Opportunity</th>
                    <th>Is New Opportunity</th>
                    <th>Current Customer</th>
                    <th>Previous Customer</th>
                  </thead>
                  <tbody v-for="(data, index) in responseDataList" :key="index">
                    <tr v-for="(deal,key) in data.deals" :key="key">
                      <td style="vertical-align: middle;">{{ deal.deal_no}}</td>
                      <td style="vertical-align: middle;">{{ data.reference_no }}</td>
                      <td style="vertical-align: middle;">{{ data.is_new_opportunity }}</td>
                      <td style="vertical-align: middle;">{{ data.customer }}</td>
                      <td style="vertical-align: middle;">{{ data.previous_customer }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary waves-effect" @click="closeMoveOpportunityDealModal('api')">Close</button>
            </div>
          </div>
          <div class="page-loader-wrapper loading-container" v-if="showMovOpdlLoader">
            <div class="loading">
              <img src="/static/images/loading.gif" alt="loader" />
            </div>
          </div>
        </div>
    </div>
</template>
  
  <script>
  import EventBus from "../../event-bus";
  import {HTTP} from '../../../http-common';
  import { commonMixins } from "../../../mixins/commonMixins";
  import Loader from "../../AssetMaster/NewTransactionSheet/Modal/Loader.vue";

  export default {
    name: "ChangeCustomer",
    components: {'loader': Loader},
    props: ["isClickMoveDeal","selectedRows","moveIndividual","oppId"],
    mixins: [commonMixins],
    data() {
      return {
        isShowModal: false,
        opportunityDealList: [],
        customerList: [],
        customer: "",
        mvPlaceholder: 'Search and Select Customer',
        selectedOpportunityDeal: [],
        customerError: '',
        isResponseSuccess: false,
        responseDataList: [],
        isCheckboxChecked: false,
        loader: false,
        showMovOpdlLoader: false,
        description: ""
      };
    },
    methods: {
      closeMoveOpportunityDealModal(params) {
        this.customerError = '';
        this.isResponseSuccess = false;
        this.selectedOpportunityDeal = [];
        this.customerList = [];
        this.customer = "";
        this.isCheckboxChecked = false;
        this.isShowModal = false;
        this.opportunityDealList = [];
        EventBus.$emit("close-move-oppportunity-deal-modal", false, params);
      },
      getSelectedOpportunityWithDeals() {
        let selectedOpp = this.selectedRows;
        if(this.moveIndividual) {
            selectedOpp = [this.oppId];
        }
        var config = {
          headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
          params: {opportunityList: selectedOpp}
        };
        HTTP.get("/move/opportunity-deal", config)
          .then((response) => {
            if (response.data.code == '200') {
              this.loader = false;
              this.opportunityDealList = response.data.content.opportunityDealsList;
              // this.customerList = response.data.content.customerList;
            }
          })
          .catch((error) => {
            this.loader = false;
            this.catchHandler(error, function () {
            });
          });
      },
      listSelectedDeal(event,dealId, oppIndex) {
        let oppDealListSelect = {};
        const currentData = this.opportunityDealList[oppIndex];
        const filteredData = this.selectedOpportunityDeal.find((list) => {
          return list.oppId == currentData.id;
        });
        if(event.target.checked) {
          if(filteredData !== undefined) {
            filteredData.dealIdList.push(dealId);
          } else {
              oppDealListSelect = {
                dealIdList: [dealId],
                oppId: currentData.id,
              }
            this.selectedOpportunityDeal.push(oppDealListSelect)
          }
        } else {
          filteredData.dealIdList = filteredData.dealIdList.filter(data => data !== dealId);
          if(filteredData.dealIdList.length == 0) {
            const data = this.selectedOpportunityDeal.filter((data) => data.oppId !== filteredData.oppId)
            this.selectedOpportunityDeal = data;
          }
        }
      },
      moveOpportuityDeal() {
        if(this.isValid()) {
          this.$swal({
            title: "",
            text: "Are you sure that you want to move the selected opportunity with deals to the customer '"+this.customer.text+"'",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              this.showMovOpdlLoader = true;
              let config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
              };
            
              let data = new FormData();
              data.append("selectedOpportunityList", JSON.stringify(this.selectedOpportunityDeal));
              data.append('selectedCustomerId',this.customer.value );
              data.append('moveType', 'deals');
              data.append('description', this.description);

              HTTP.post("/move/opportunity-deal", data, config)
                .then((response) => {
                  this.showMovOpdlLoader = false;
                  if (response.data.code == '200') {
                    this.showSAlert(
                      response.data.message,
                      response.data.status,
                      false,
                      2
                    );
                    this.isResponseSuccess = true;
                    this.responseDataList = response.data.content;
                  } else if (response.data.code == '201') {
                    this.$swal({
                    title: "",
                    text: response.data.message,
                    type: "info",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                    showLoaderOnConfirm: false
                  });
                  }
                })
                .catch((error) => {
                  this.showMovOpdlLoader = false;
                  this.catchHandler(error, function () {
                  });
                });
            }
          });
        }
      },
      onSearchCustomer(search, loading) {
        if (search.length > 2) {
            loading(true);
            this.loadAllData(['Contact'], true, this.loadCustomerCallback, loading, search);
        }
      },
      loadCustomerCallback: function(callbackData) {
          this.customerList = callbackData.Contact;
      },
      isValid() {
        let valid = true;
        if (Object.keys(this.customer).length == 0 || this.customer == null || this.customer == undefined) {
          this.customerError = "Please Select The Customer !!";
          if (valid) {
              valid = false;
          }
        } else {
            this.customerError = '';
        }

        if (valid && this.selectedOpportunityDeal.length == 0) {
          this.showSAlert(
            "Please Select At Least One Deal !!",
            "info",
            false,
            2
          );
          valid = false;
        }
        return valid;
      }
    },
    watch: {
      isClickMoveDeal(status) {
        if(status) {
          this.isShowModal = true;
          this.loader = true;
          this.getSelectedOpportunityWithDeals();
        }
      }
    },
  };
  
  </script>
  
  <style>
    .mv-opp-deal-modal-body {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    #changeCustomerModal .mv-opp-deal-select {
      width: 100%;
    }

    .mv-opp-deal-modal-body > div:last-child > table > tbody > tr:last-child > td {
      border-bottom: 2px solid black !important;
    }
  </style>
  