<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_view_calls_and_emails')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">
                        Call/Email Information <span class="badge badge-info">{{ formatString(callEmailViewObj['direction']) }}</span>
                    </div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Customer Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['custName']}}</span>
                                </div>
                            </div>
                            <!-- Created By -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.created_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['usrCreatedFirstName'] + " " + callEmailViewObj['usrCreatedLastName']}}</span>
                                </div>
                            </div>
                            <!-- Asset -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['vinNo']}}</span>
                                </div>
                            </div>
                            <!-- Priority -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.priority_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{formatString(callEmailViewObj['priority'])}}</span>
                                </div>
                            </div>
                            <!-- Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{formatString(callEmailViewObj['call_type'])}}</span>
                                </div>
                            </div>
                            <!-- Subject -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['subject']}}</span>
                                </div>
                            </div>
                            <!-- Call Date Time -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.call_date_time_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['call_date_time']}}</span>
                                </div>
                            </div>
                            <!-- Notes -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.additional_comments_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['notes']}}</span>
                                </div>
                            </div>
                            <!-- Updated By -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.last_updated_by_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['usrUpdatedFirstName'] + " " + callEmailViewObj['usrUpdatedLastName']}}</span>
                                </div>
                            </div>
                            <!-- Email ID -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{callEmailViewObj['emails']}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Forms-->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'ViewTaskMaster',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data () {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                callEmailViewObj: [],
                alertClass : '',
                alertMessage : '',
                permissionNameArray : ['can_view_calls_and_emails'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('calls-email-list');
            EventBus.$emit("menu-selection-changed", "calls-email-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getCallEmailViewById(self.$route.params.id);
            }
        },
        methods: {
            buttonBack() {
                self.$router.push('/call/email/list');
            },
            getCallEmailViewById: function(id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/call/email/get', config)
                    .then(response => {
                        self.callEmailViewObj = response.data.content;
                        if(self.callEmailViewObj['call_type'] == 'email') {
                            self.callEmailViewObj['call_type'] = 'E-Mail';
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>  