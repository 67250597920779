import { render, staticRenderFns } from "./ViewCallsAndEmails.vue?vue&type=template&id=9d2effb2&"
import script from "./ViewCallsAndEmails.vue?vue&type=script&lang=js&"
export * from "./ViewCallsAndEmails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports