<template>
    <div>
        <template v-if="permissionNameArray.includes('can_add_expense_master')">
            <template v-if="group_content.length > 0">
                <div class="row no-margin">
                This is a Grouped expense with a total of ${{group_content[0].actual_cost}}
                </div>
                <div class="row clearfix" >&nbsp;</div>
            </template>
            <template v-if="asset_in_same_group.length >0">
                <div v-on:click="toggleAssetListBlock()" style="cursor: pointer;">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                    <span>Expand Inventory List</span>
                </div>
                <div class="row clearfix" >&nbsp;</div>

                <ul v-show="isOpenListBlock">
                    <li v-for="asset in asset_in_same_group" :key="asset.id">
                        {{asset.vinNo}} | {{asset.lot_location}}
                    </li>
                </ul>
                <div class="row clearfix" >&nbsp;</div>
            </template>
            <div class="row no-margin">
                <!-- vendor id -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="vendor"
                                @change="changedValue"
                                @input="vendorError = ''"
                                @search="onSearchVendor"
                                @clear="onClearVendor"
                                name="vendor"
                                id="vendor"
                                label="text"
                                :clearable="true"
                                :searchable="true"
                                :filterable="false"
                                :options="vendorArr"
                                :placeholder="staticMessages.acquire_from_place_holder">
                              <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isVendorRecordNotFound">
                                      {{staticMessages.acquire_from_search}}
                                  </template>
                                  <template v-else>
                                      {{staticMessages.search_record_not_found}}
                                  </template>
                              </span>
                        </v-select>
                        <!--<input @input="vendorError = ''" id="vendor" v-model="vendor" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{vendorError}}</span>
                    </div>
                </div>
                <!-- Reference No -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.reference_no_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="referenceNoError = ''" id="referenceNo" v-model="referenceNo" class="form-control" type="text"/>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{referenceNoError}}</span>
                    </div>
                </div>
                <!-- estimated cost -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.estimated_cost_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" @input="estimatedCostError = ''" id="estimatedCost" v-model="estimatedCost" class="form-control" type="text"/>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{estimatedCostError}}</span>
                    </div>
                </div>
                <!-- actual cost -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.actual_cost_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" @input="actualCostError = ''" id="actualCost" v-model="actualCost" class="form-control" type="text"/>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{actualCostError}}</span>
                    </div>
                </div>
                <!-- Paid Date -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.paid_date_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <date-picker
                                v-model="paidDate"
                                :config="options"
                                class="form-control"
                                autocomplete="off"
                                :placeholder="staticMessages.paid_date_place_holder"
                                name="paidDate"
                                @input="changeDatePickerDate()"
                        >
                        </date-picker>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{paidDateError}}</span>
                    </div>
                </div>
                <!-- expense category -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.expense_category_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select 
                            v-model="expenseCategory"
                            @change="changedValue"
                            @input="expenseCategoryError = ''"
                            @search="onSearchExpenseCategory"
                            @clear="onClearExpenseCategory"
                            name="expenseCategory"
                            id="expenseCategory"
                            label="text"
                            :clearable="true"
                            :searchable="true"
                            :filterable="false"
                            :options="expenseCategoryArr"
                            :placeholder="staticMessages.expense_category_place_holder">
                            <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isExpenseCategoryRecordNotFound">
                                      {{staticMessages.expense_category_search}}
                                  </template>
                                  <template v-else>
                                      {{staticMessages.search_record_not_found}}
                                  </template>
                              </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{expenseCategoryError}}</span>
                    </div>
                </div>
                <!-- Summary -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.summary_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="summaryError = ''" id="summary" v-model="summary" class="form-control" type="text"/>
                        <!-- <div class="requiredstar">*</div> -->
                        <!-- <span class="help is-danger">{{actualCostError}}</span> -->
                    </div>
                </div>
                <!-- Note -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <textarea @input="notesError = ''" id="notes" v-model="notes" class="form-control" type="text"/>
                        <!-- <div class="requiredstar">*</div> -->
                        <!-- <span class="help is-danger">{{actualCostError}}</span> -->
                    </div>
                </div>
                <!-- rebill_contact_id -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.rebill_contact_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <!-- <input @input="rebillContactError = ''" id="rebillContact" v-model="rebillContact" class="form-control" type="text"/> -->
                            <v-select v-model="rebillContact"
                                            @change="changedValue"
                                            @input="rebillContactError = ''"
                                            @search="onSearchRebillContact"
                                            @clear="onClearRebillContact"
                                            name="rebillContact"
                                            id="rebillContact"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="rebillContactrArr"
                                            :placeholder="staticMessages.acquire_from_place_holder">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isRebillContactRecordNotFound">
                                                  {{staticMessages.acquire_from_search}}
                                              </template>
                                              <template v-else>
                                                  {{staticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                    </v-select>
                        <!-- <div class="requiredstar">*</div> -->
                        <!-- <span class="help is-danger">{{rebillContactError}}</span> -->
                    </div>
                </div>
                <!-- rebill_paid_date -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.rebill_paid_date_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <date-picker
                                v-model="rebillPaidDate"
                                :config="options"
                                autocomplete="off"
                                class="form-control"
                                :placeholder="staticMessages.rebill_paid_date_place_holder"
                                name="rebillPaidDate"
                        >
                        </date-picker>
                        <!-- <div class="requiredstar">*</div> -->
                        <!-- <span class="help is-danger">{{rebillPaidDateError}}</span> -->
                    </div>
                </div>
                <!-- chargeback -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.chargeback_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="chargebackError = ''" id="chargeback" v-model="chargeback" class="form-control" type="text"/>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{chargebackError}}</span>
                    </div>
                </div>
                <!-- options with group -->
                <template v-if="group_content.length > 0">
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.group_expense_options_update}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <b-form-radio-group v-model="updateExpenseGroupStatus"
                                                        :options="updateGroupExpenseOptionsArr"
                                                        name="updateExpenseGroupStatus"
                                                        stacked>
                                    </b-form-radio-group>
                    </div>
                </div>
                </template>
            </div>
            <!--Error Alert-->
            <div class="row clearfix" >
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="alert alert-danger" id="alertError">
                        <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                        <strong id="errorMsg" class="error"></strong>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
                <template v-if="group_content.length > 0">
                    <input id="addAssetExpensesBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAssetExpensesGroup()">
                </template>
                <template v-else>
                    <input id="addAssetExpensesBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAssetExpenses()">
                </template>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        props: ['isEdit', 'permissionNameArray'],
        name: 'AddUpdateAssetExpenseTab',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
            datePicker,
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                asset: "",
                asset_id: "",
                vendor: "",
                vendorArr : [],
                rebillContactrArr:[],
                isVendorRecordNotFound: false,
                isExpenseCategoryRecordNotFound: false,
                isRebillContactRecordNotFound: false,
                referenceNo: "",
                estimatedCost: "",
                actualCost: "",
                paidDate: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                expenseCategory: "",
                expenseCategoryArr: [],
                summary: "",
                notes: "",
                rebillContact: "",
                rebillPaidDate: "",
                chargeback: "",

                buttonNames : {
                    save : 'Submit',
                },
                disableButtons : {
                    save : false,
                },
                vendorError: "",
                rebillContactError: "",
                referenceNoError: "",
                estimatedCostError: "",
                actualCostError: "",
                paidDateError: "",
                expenseCategoryError: "",
                summaryError: "",
                notesError: "",
                rebillPaidDateError: "",
                chargebackError: "",
                updateExpenseGroupStatus:"1",
                updateGroupExpenseOptionsArr: [
                    {
                        text: "Update the cost of this expense only",
                        value: "1"
                    },
                    {
                        text: "Update the cost of All expense in this group",
                        value: "2"
                    },
                    {
                        text: "Remove this expense from this group,DO NOT DISTRIBUTE cost to other expenses ",
                        value: "3"
                    },
                    {
                        text: "Remove this expense from this group,DISTRIBUTE cost to other expenses in group",
                        value: "4"
                    }
                ],
                group_content:[],
                asset_in_same_group:[],
                isOpenListBlock:false,

            }
        },
        beforeMount() {
            self = this;
        },
        watch: {

        },
        created : function () {
            self = this;
            //self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            $("#alertError").hide();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.asset = self.$route.params.id;
                if (self.isEdit) {
                    self.getAssetExpensesDetailsById(self.isEdit);
                }
            }
            // self.loadAllData(['ExpenseCategory'], false, self.loadMasterDataCallback, "undefined", '');
            // self.loadAllData(['ExpenseCategory'], false, self.loadExpenseCategoryCallback, "undefined", '');
        },
        methods: {
            toggleAssetListBlock: function () {
                self.isOpenListBlock = !self.isOpenListBlock;
            },
            changeDatePickerDate : function () {
                self.paidDateError = "";
                self.rebillPaidDateError = "";
            },
            changedValue: function () {
            },
            onSearchVendor(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isVendorRecordNotFound = false;
                self.vendorArr = callbackData.Vendor;
                if(self.vendorArr.length == 0) {
                    self.isVendorRecordNotFound = true;
                }
            },
            onClearVendor : function() {
                self.vendorError = "";
            },
            onSearchExpenseCategory(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['ExpenseCategory'], true, self.loadExpenseCategoryFromCallback, loading, search);
                }
            },
            loadExpenseCategoryFromCallback: function (callbackData) {
                self.isExpenseCategoryRecordNotFound = false;
                self.expenseCategoryArr = callbackData.ExpenseCategory;
                if(self.expenseCategoryArr.length == 0) {
                    self.isExpenseCategoryRecordNotFound = true;
                }
            },
            onClearExpenseCategory : function() {
                self.expenseCategoryError = "";
            },
            onSearchRebillContact(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadRebillContactFromCallback, loading, search);
                }
            },
            loadRebillContactFromCallback: function (callbackData) {
                self.isRebillContactRecordNotFound = false;
                self.rebillContactrArr = callbackData.Contact;
                if(self.rebillContactrArr.length == 0) {
                    self.isRebillContactRecordNotFound = true;
                }
            },
            onClearRebillContact : function() {
                self.rebillContact = {text: 'Select', value: null};
            },
            loadMasterDataCallback: function (callbackData) {
                self.expenseCategoryArr = callbackData.ExpenseCategory;
            },
            
            getAssetExpensesDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/assetexpenses/get', config)
                    .then(response => {
                        let paidDate = '';
                        if(response.data.content.paid_date != null){
                            paidDate = self.datePickerDateFormat(response.data.content.paid_date);
                        }
                        self.asset = response.data.content.asset_id;
                        if(response.data.content.vendor_id != '0' && response.data.content.vendor_id != 0){
                            self.vendor = {text: self.formatString(response.data.content.customer_name), value: response.data.content.vendor_id};
                        }
                        self.referenceNo = response.data.content.reference_no;
                        self.estimatedCost = response.data.content.estimated_cost;
                        self.actualCost = response.data.content.actual_cost;
                        self.paidDate = paidDate;
                        if(response.data.content.expense_category_id != null){
                            self.expenseCategory = {value: response.data.content.expense_category_id, text: response.data.content.expenseCategoryName};
                        }
                        self.summary = response.data.content.summary;
                        self.notes = response.data.content.notes;
                        self.rebillContact = {text: 'Select Contact', value: null};
                        if(response.data.content.rebill_customer_id != 'null' && response.data.content.rebill_customer_id != null){
                            self.rebillContact = {text: self.formatString(response.data.content.rebill_customer_name), value: response.data.content.rebill_customer_id};
                        }
                        self.rebillPaidDate = "";
                        if(response.data.content.rebill_paid_date != null  && response.data.content.rebill_paid_date != ''){
                            self.rebillPaidDate = self.datePickerDateFormat(response.data.content.rebill_paid_date);
                        }
                        if(response.data.content.chargeback != null){
                            self.chargeback = response.data.content.chargeback;
                        }
                        self.group_content = response.data.group_content;
                        self.asset_in_same_group = response.data.asset_in_same_group_content;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (self.vendor == null || !self.vendor.value) {
                    self.vendorError = "Please select vendor";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.referenceNo) {
                    self.referenceNoError = "Please enter reference No";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.estimatedCost) {
                    self.estimatedCostError = "Please enter estimated Cost";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.estimatedCost && !self.numberFormateValidation(self.estimatedCost)) {
                    self.estimatedCostError = "Estimated Cost must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.actualCost) {
                    self.actualCostError = "Please enter actual Cost";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.actualCost && !self.numberFormateValidation(self.actualCost)) {
                    self.actualCostError = "Actual Cost must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                // if (!self.paidDate) {
                //     self.paidDateError = "Please select paid Date";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (self.expenseCategory == null || !self.expenseCategory.value) {
                    self.expenseCategoryError = "Please select expense Category";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.chargeback && !self.numberFormateValidation(self.chargeback)) {
                    self.chargebackError = "Chargeback must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                } */
                return checked;
            },
            addAssetExpensesGroup(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        if (self.isEdit) {
                            data.append('id',self.isEdit);
                        }
                        var rebillContact = "";
                        if (self.rebillContact == null || !self.rebillContact.value) {
                            rebillContact = "";
                        }else{
                            rebillContact = self.rebillContact.value;
                        }
                        data.append('asset_id', self.asset_id);
                        data.append('vendor_id', self.vendor.value);
                        data.append('reference_no', self.referenceNo);
                        data.append('estimated_cost', self.estimatedCost);
                        data.append('actual_cost', self.actualCost);
                        data.append('paid_date', self.apiRequestDateFormat(self.paidDate));
                        data.append('expense_category_id', self.expenseCategory.value);
                        data.append('summary', self.summary);
                        data.append('notes', self.notes);
                        data.append('rebill_contact_id', rebillContact);
                        data.append('rebill_paid_date', self.apiRequestDateFormat(self.rebillPaidDate));
                        data.append('chargeback', self.chargeback);
                        data.append('group_option_for_update', self.updateExpenseGroupStatus);
                        data.append('uniq_id', self.group_content[0].uniq_id);

                        self.buttonNames.save = "Submitting ...";
                        self.disableButtons.save = true;
                        HTTP.post("/assetexpensesgroup/update", data, config)
                            .then(function (response) {
                                self.buttonNames.save = "Submit";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.$parent.chagneComponent(0);
                                    }, 3000); 
                                } else {
                                    self.disableButtons.save = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Submit";
                                self.disableButtons.save = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            addAssetExpenses(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = new FormData();
                        if (self.isEdit) {
                            data.append('id',self.isEdit);
                        }
                        var rebillContact = "";
                        if (self.rebillContact == null || !self.rebillContact.value) {
                            rebillContact = "";
                        }else{
                            rebillContact = self.rebillContact.value;
                        }
                        data.append('asset_id', self.asset);
                        data.append('vendor_id', self.vendor.value);
                        data.append('reference_no', self.referenceNo);
                        data.append('estimated_cost', self.estimatedCost);
                        data.append('actual_cost', self.actualCost);
                        data.append('paid_date', self.apiRequestDateFormat(self.paidDate));
                        data.append('expense_category_id', self.expenseCategory.value);
                        data.append('summary', self.summary);
                        data.append('notes', self.notes);
                        data.append('rebill_contact_id', rebillContact);
                        data.append('rebill_paid_date', self.apiRequestDateFormat(self.rebillPaidDate));
                        data.append('chargeback', self.chargeback);

                        self.buttonNames.save = "Submitting ...";
                        self.disableButtons.save = true;
                        HTTP.post("/assetexpenses/add/update", data, config)
                            .then(function (response) {
                                self.buttonNames.save = "Submit";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.$parent.chagneComponent(0);
                                    }, 3000); 
                                } else {
                                    self.disableButtons.save = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Submit";
                                self.disableButtons.save = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        },
    }
</script>