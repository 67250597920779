<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_tag_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span>{{staticInputLabelMessages.tags_header}}</span>
                            <p v-if="totalRecords > 0">{{totalRecords}} {{staticInputLabelMessages.results_found}}</p>
                        </div>
                        <a v-show="test" class="btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="addTag()">
                            <span>{{staticInputLabelMessages.add_tag}}</span>
                        </a>

                        <a
                                id='refreshList'
                                title='Refresh'
                                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                                v-on:click.prevent="refreshList()">
                            <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                    </div>
                    <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>
                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        <span class="color-cc9636">{{selectedRows.length}} {{staticInputLabelMessages.rows_selected}} <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                        <button v-show="permissionNameArray.includes('can_delete_tag_master')" type="button" v-on:click.prevent="deleteTag(0)" class="btn btn-primary waves-effect pull-right ml-2">{{deleteRecord}} {{selectedRows.length}} {{ records }}</button>
                    </div>
                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">{{staticInputLabelMessages.page}}</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->

                        <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                            <table class="table custom-table jsmartable">
                                <tbody>
                                <template v-if="tagList && tagList.length > 0">
                                    <template  v-for="(tag, index) in tagList">
                                        <tr :key="index">
                                            <td class="">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" v-model="selectedRows" :value="tag.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + tag.id" name="case[]">
                                                    <label class="custom-control-label" :for="'customCheck' + tag.id"></label>
                                                </div>
                                            </td>
                                            <td v-show="actionDisplay">
                                                <div class="actions">
                                                    <router-link class="mar-right-0" title='Edit Tag' v-bind:to="{name: 'UpdateTag', params:{id: tag.id}}">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon" v-if="permissionNameArray.includes('can_edit_tag_master')"></i>
                                                    </router-link>
                                                    <a title='Delete Tag' href="javascript:void(0)" class="mar-right-0" @click="deleteTag(tag.id)" v-if="permissionNameArray.includes('can_delete_tag_master')">
                                                        <i  class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td :class="rowClass.tag_label">{{tag.tag_label}}</td>
                                            <td :class="rowClass.tag_value">{{tag.tag_value}}</td>
                                            <td :class="rowClass.model_name">{{tag.model_name}}</td>
                                            <td :class="rowClass.status">{{tag.status ? "Active" : "In-Active"}}</td>
                                            <td :class="rowClass.created_at">{{tag.created_at}}</td>
                                            <td :class="rowClass.updated_at">{{tag.updated_at}}</td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td :colspan="headers.length + 1" class="text-alignment-center">
                                            {{staticMessages.data_not_available}}
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <div class="custom-control custom-checkbox">
                                            <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="headerCheckbox" @change="clickHeaderCheckBox()">
                                            <label class="custom-control-label" for="selectAllCheckBox"></label>
                                        </div>
                                    </th>
                                    <template v-for="(data, headerIndex) in headers">
                                        <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                                    </template>
                                    <template v-for="(data, headerIndex) in headers">
                                        <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                                    </template>
                                </tr>
                                </thead>
                            </table>
                        </div>

                        <!-- Pagination Start -->
                        <div class="bottom_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                              v-model="perPage"
                                              @input="changePerPage"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="paginationDropDownArr"
                                              placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{startEndRecord}} of {{totalRecords}}
                                    </p>
                                </div>

                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">{{staticInputLabelMessages.page}}</p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>
  
  <script>
  let self;
  let filterColumn = "id";
  let filterType = "desc";
  import Vue from "vue";
  import vSelect from "vue-select";
  import Footer from "@/components/partials/Footer";
  import { commonMixins } from "../../mixins/commonMixins";
  import EventBus from ".././event-bus";
  import { HTTP } from "../../http-common";
  import FilterComponent from "../FilterComponent/FilterComponent";
  import NoAccessPage from "../NoAccessPage/NoAccessPage";
  
  Vue.component("app-footer", Footer);
  Vue.component("v-select", vSelect);
  
  export default {
    name: "ListTags",
    mixins: [commonMixins],
    components: {
      "filter-component": FilterComponent,
      "no-access": NoAccessPage,
    },
    data() {
      return {
        perPageValue: 25,
        perPage: 25,
        currentPage: 1,
        totalRecords: 0,
        paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
        tagList: [],
        headers: [],
        filters: {},
        filterheaders: [],
        appliedHeaders: [],
        jumpToPage: 1,
        columnName: filterColumn,
        sortType: filterType,
        selectedRows: [],
        startEndRecord: "",
        selectAll: false,
        permissionNameArray: ["can_list_tag_master","can_add_tag_master","can_delete_tag_master"],
        actionDisplay: true,
        jumpToHistoryPage: 1,
        filterCount: 0,
        tagHeadersList: [],
        isSelectAllTagHeaderList: false,
        test: true,
        staticMessages: "",
        staticInputLabelMessages: "",
        deleteRecord: "Delete",
        records: "Record(s)"
      };
    },
    mounted: function () {
        self = this;
        /* Redirect to login page logic start */
        const userObj = localStorage.getItem("user_obj");
        if (userObj == null) {
            self.$router.push("/");
        }
        /* Redirect to login page logic end */

        self.staticMessages = self.allStaticMessages;
        self.staticInputLabelMessages = self.allLabelMessages;

        self.setActiveMenu('tag-list');
        EventBus.$emit("menu-selection-changed", "tag-list");

        const listHistoryData = self.loadFilters("listTag", self);
        self.jumpToHistoryPage = !listHistoryData ? 1 : listHistoryData.page;
  
        self.listExecutionState = false;
        self.loadTags(1);
        self.getLoginIdPermissions();
    },
    methods: {
        resetSorting: function () {
            self.columnName = filterColumn;
            self.sortType = filterType;
            self.changepage();
        },
        changePerPage : function(){
            self.currentPage = 1;
            if (self.perPage !== "All") {
                self.perPageValue = self.perPage;
            }
            self.loadTags();
        },
        changepage : function(){
            if(!self.listExecutionState){
                self.loadTags();
            }
        },
        applyFilterParentComponent: function (data) {
            let key;
            let value;
            for (let i = 0; i < data.length; i++) {
                if (data[i]["column_name"] != "null") {
                    key = data[i]["column_name"];
                    value = data[i]["field"];
                    self.filters[key] = value;
                }
            }
            self.appliedHeaders = data;
            self.loadTags();
        },
        
        setAlreadyAppliedFilters: function () {
            if (self.appliedHeaders.length > 0) {
            self.headers = self.appliedHeaders;
            }
        },

        refreshList : function() {
            self.jumpToPage = 1;
            self.keyMonitor();
            self.loadTags();
        },

        keyMonitor: function() {
            const tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
            self.currentPage = self.jumpToPage = parseInt(tempNumber);
        },

        addTag: function() {
            self.$router.push({name : "AddTag"});
        },

        deleteTag : function (id) {
            self.$swal({
                title: '',
                text: 'Are you sure that you want to delete this Tag(s)?',
                type: 'info',
                confirmButtonText: 'OK',
                showCancelButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    let deleteArray = [];
                    if(id > 0) {
                        deleteArray.push(id);
                    } else {
                        deleteArray = self.selectedRows;
                    }
                    let config = {
                        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        data : {
                            tagIds: deleteArray,
                        }
                    };
                    HTTP.delete("/tags", config)
                        .then(function (response) {
                            if (response.data.code == '200') {
                                self.showSAlert(
                                    response.data.message,
                                    response.data.status,
                                    false,
                                    2
                                );
                                self.loadTags();
                            }
                        })
                        .catch(function (error) {
                            self.catchHandler(error, function () {});
                        });
                }
            });
        },
      
        loadTags: function (first=0) {
            self.listExecutionState = true;
            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                self.showSAlert(self.staticMessages.too_many_data_for_all_list, 'info', false);
                self.perPage = 500;
                self.loadTags();
            }
            const fields = {
                filters : self.filters,
                per_page: self.perPage,
                page: self.currentPage,
                sortColumnName: self.columnName,
                sortType: self.sortType
            };
            self.storeFilters('listTag', fields, false);
            const config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
            };
            self.showLoader();
            self.filterCount = self.filterCountFunction(self.filters);

            HTTP.post("/tags/list", fields, config)
            .then(function (data) {
                self.listExecutionState = false;
                        if(first){
                            self.currentPage = self.jumpToHistoryPage;
                        }
                        self.hideLoader();
                        if (data.status == 200) {
                            self.clearSelection();
                            self.headers = [];
                            self.totalRecords = data.data.content.result;
                            if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                                let seconds = 5000;
                                self.$swal({
                                    type: 'error',
                                    html: self.staticMessages.too_many_data_to_display_browser_can_not_load,
                                    showConfirmButton: false,
                                    timer: seconds,
                                });
                                setTimeout(() => {
                                    self.perPage = 500;
                                    self.loadTags();
                                }, seconds);
                            }
                            self.tagList = data.data.content.data;
                            localStorage.setItem('TagValueList', self.tagList.map(tagModel => tagModel.tag_value));
                            $("#selectAllCheckBox").prop("checked", false);
                            self.headers = data.data.content.headers;
                            self.loadFilterInputs(data.data.content.headers,self.filters);

                            self.addColumnClass(self.headers);

                            let testArray = [
                                {
                                    'text' : 1,
                                    'value' : '2020-02-01'
                                },
                                {
                                    'text' : 2,
                                    'value' : '2020-02-02'
                                },
                                {
                                    'text' : 3,
                                    'value' : '2020-02-03'
                                }
                            ]


                            for (let i = 0; i < self.headers.length; i++) {
                                if (self.headers[i]['type'] == 'drop_down') {
                                    self.headers[i]['options_array'] = testArray;
                                }
                            }
                            if (data.data.content.per_page !== self.totalRecords) {
                                self.perPage = parseInt(data.data.content.per_page);
                                self.jumpToPage = parseInt(data.data.content.current_page);
                            } else {
                                if(self.perPage == 'All'){
                                    self.jumpToPage = 1;
                                }
                                self.perPageValue = self.totalRecords;
                                self.currentPage = 1;
                            }
                            self.setAlreadyAppliedFilters();
                            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
                            self.sortFieldDisplay(self.columnName,self.sortType);
                        }
            })
            .catch(function (error) {
                self.listExecutionState = false;
                self.hideLoader();
                self.catchHandler(err, function () {});
            });
        },

        sortTableData: function (name, isSortAllowed) {
                if (isSortAllowed == "nosort") {
                if (self.columnName == name) {
                    self.sortType = self.sortType == "desc" ? "asc" : "desc";
                } else {
                    self.sortType = "asc";
                }
                self.columnName = name;
                self.loadTags();
            }
        },
        
        clickHeaderCheckBox: function() {
            setTimeout(function () {
                self.selectedRows = [];
                if (self.selectAll) {
                    for (let i in self.tagList) {
                        self.selectedRows.push(self.tagList[i].id);
                    }
                }
            }, 100);
        },
            
        inputCheckbox: function(e) {
            setTimeout(function () {
                self.selectAll = false;
                if (self.selectedRows.length == self.tagList.length) {
                    self.selectAll = true;
                }
            }, 100);
        },

        clearSelection : function() {
            self.selectAll = false;
            self.selectedRows = [];
        },
    },
  };
  EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
      self.permissionNameArray = [];
      for (let i = 0; i < permissions.length; i++) {
        self.permissionNameArray.push(permissions[i].permission_name);
      }
  
      if (
        !self.permissionNameArray.includes("can_list_tag_master") &&
        !self.permissionNameArray.includes("can_delete_tag_master")
      ) {
        self.headers.pop();
        self.actionDisplay = false;
      }
    }
  });
  </script>
  