<template>
  <div>
    <div
      id="page-content-wrapper"
      class="admin-content-area"
      v-if="permissionNameArray.includes('can_add_deals')"
    >
      <div class="custom-container" v-if="!isShowVehicleWorkSheet" ref="trnsIndex">
        <!-- Start Top -->
        <div id="top">
          <!-- First Row -->
          <div class="custom-row">
            <!-- Left Column -->
            <div class="custom-column">
              <h4 class="top-head-title">
                {{ allLabelMessages.transaction_sheet_title }}
              </h4>
              <span class="sub-title">Deal on {{ dealLevel }} level.</span>
            </div>
            <!-- Right Column -->
            <div class="custom-column" id="button-top">
              <button class="close-button" @click="closeDeal()">Close</button>
              <button class="save-close-button" @click="saveAndCloseDeal()">
                <span>{{ dealMapperId > 0 ? "Update" : "Save" }}</span>
              </button>
            </div>
          </div>
          <!-- Second Row -->
          <!-- Note here the use of HTML COMMENT between buttons to remove the white space -->
          <div class="custom-row">
            <div class="custom-column">
              <!--#### NOT A FUNCTIONAL SO NOT REQUIRED FOR NOW  ####
              <button class="menu-btn trs-btn-disabled">
                <i class="btn-icon"></i>
                Copy Transaction
              </button>
              -->
              <button 
              :type="dealId !== '' && permissionNameArray.includes('can_delete_deals') ? 'button' : ''" 
              :class="dealId !== '' && permissionNameArray.includes('can_delete_deals') ? 'menu-btn' : 'menu-btn trs-btn-disabled'" 
              @click="deleteDeal()">
                <i class="btn-icon"></i>
                {{ buttonNames.deleteTransaction }}
              </button>
              <button
                :type="dealId !== '' ? 'button' : ''" 
                :class="dealId !== '' ? 'menu-btn' : 'menu-btn trs-btn-disabled'"
                :disabled="!dealId"
                @click="mailHistory()">
                <i class="btn-icon"></i>
                {{ buttonNames.mailHistory }}
              </button>
              <button
                v-if="dealStatusSlug==='sales'"
                :type="dealId !== '' ? 'button' : ''" 
                :class="dealId !== '' ? 'menu-btn' : 'menu-btn trs-btn-disabled'"
                :disabled="!dealId"
                @click="createDealLevelRequest('lost')">
                <i class="btn-icon"></i>
                {{ buttonNames.lost }}
              </button>
              <button
                v-if="dealStatusSlug==='sales' || dealStatusSlug==='po-request' || dealStatusSlug==='lost'"
                :type="dealId !== '' ? 'button' : ''" 
                :class="dealId !== '' ? 'menu-btn' : 'menu-btn trs-btn-disabled'"
                :disabled="!dealId"
                @click="createDealLevelRequest('doc-request')">
                <i class="btn-icon"></i>
                {{ buttonNames.docRequest }}
              </button>
              <button
                v-if="dealStatusSlug==='doc-request' || dealStatusSlug==='manager'"
                type="button" 
                class="menu-btn"
                @click="createDealLevelRequest('accounting')">
                <i class="btn-icon"></i>
                {{ buttonNames.accounting }}
              </button>
              <button
                v-if="dealStatusSlug==='doc-request'"
                type="button"
                class="menu-btn"
                @click="createDealLevelRequest('manager')">
                <i class="btn-icon"></i>
                {{ buttonNames.manager }}
              </button>
              <button
                v-if="dealStatusSlug==='sales'"
                :type="dealId !== '' ? 'button' : ''" 
                :class="dealId !== '' ? 'menu-btn' : 'menu-btn trs-btn-disabled'"
                :disabled="!dealId"
                @click="createDealLevelRequest('po-request')">
                <i class="btn-icon"></i>
                {{ buttonNames.roPoRequest }}
              </button>
              <button
                v-if="dealStatusSlug==='po-request' || dealStatusSlug==='doc-request' || dealStatusSlug==='manager'"
                type="button" 
                class="menu-btn"
                @click="createDealLevelRequest('sales')">
                <i class="btn-icon"></i>
                {{ buttonNames.returnToSales }}
              </button>
              <button
                v-if="dealStatusSlug==='manager' || dealStatusSlug==='accounting'"
                type="button"
                class="menu-btn"
                @click="createDealLevelRequest('doc-request')">
                <i class="btn-icon"></i>
                {{ buttonNames.returnToDoc }}
              </button>
              <button class="menu-btn" @click="exportToPdf()">
                <i class="btn-icon"></i>
                {{ buttonNames.print }}
              </button>
            </div>
          </div>
        </div>
        <!-- End Top -->
        <hr />
        <!-- Start Main Content -->
        <div class="main">
          <div class="row-1">
            <!-- Checkbox with label -->
            <div class="checkbox-container-group">
              <input type="checkbox" id="trsCheckBox" v-model="addToForecast" />
              <label for="addToForecast" class="checkbox-label">Add to Forecast</label>
            </div>

            <!-- Select field with label -->
            <div class="select-container">
              <label for="branch" class="select-label">Branch <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
              <v-select
                v-model="locationId"
                name="locationId"
                label="text"
                :filterable="true"
                :options="locationArr"
                :clearable="false"
                :placeholder="allStaticMessages.company_custom_place_holder"
                @input="addWarrantyTax()"
                class="custom-ci-auto-search"
                id="branch"
              >
              </v-select>
              <span class="trns-required-alert" v-if="locationError !== ''">{{ locationError }}</span>
            </div>

            <!-- Label with input box -->
            <div class="deal-no-container" style="margin-left: auto">
              <label for="dealNo" class="deal-no-label">Deal No:</label>
              <div style="display: flex; flex-direction: column">
                <input
                  type="text"
                  id="dealNo1"
                  class="deal-no-input"
                  v-model="dealNo"
                  readonly
                />
                <input
                  type="text"
                  id="dealNo2"
                  class="deal-no-input"
                  v-model="oppNo"
                  readonly
                />
              </div>
            </div>
          </div>

          <div id="main-department">
            <div class="custom-row">
              <h2 class="section-header">Department:</h2>
            </div>

            <div class="custom-row">
              <div class="column-row">
                <div class="custom-column">
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="contact-number">Contract Number</label>
                      <input
                        type="text"
                        class="form-input"
                        v-model="contractNumber"
                        maxlength="190"
                      />
                    </div>
                    <div class="fm-group">
                      <label for="inovice-date">Invoice Date</label>
                      <date-picker
                        v-model="invoiceDate"
                        :config="options"
                        class="form-control"
                        autocomplete="off"
                        :placeholder="
                          allStaticMessages.contract_date_place_holder
                        "
                        name="invoiceDate"
                        id="invoice-date"
                      />
                    </div>
                  </div>
                  <div class="column-column">
                    <div class="fm-group" id="sls-fm-group">
                      <label for="sales-person">Sales Person</label>
                      <v-select
                        v-model="salePersonId"
                        name="salePersonId"
                        label="text"
                        :filterable="true"
                        :options="salePersonArr"
                        @search:focus="onSearchSalePerson"
                        :clearable="true"
                        :placeholder="
                          allStaticMessages.sales_person_place_holder
                        "
                        class="custom-ci-auto-search"
                        id="sales-person"
                      />
                    </div>
                  </div>
                </div>

                <div class="custom-column">
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="customer">Customer</label>
                      <div class="custom-input-group">
                        <div class="input-container">
                          <input
                            type="text"
                            class="form-input"
                            id="customer"
                            v-model="customerName"
                          />
                          <div
                            class="search-icon"
                            @click="searchCustomer('customerName')"
                          ></div>
                          <customer-search-modal
                            v-bind:isClickSearchModal="isClickSearchModal"
                            v-bind:customerName="searchCustomerNameId"
                          />
                        </div>
                        <input
                          type="text"
                          class="form-input"
                          id="customerId"
                          v-model="excedeCustomerId"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="address">Address</label>
                      <input type="text" class="form-input" v-model="address" maxlength="5000" />
                    </div>
                  </div>
                </div>

                <div class="custom-column">
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="city">City</label>
                      <div class="custom-input-group">
                        <div class="input-container">
                          <input
                            type="text"
                            class="form-input"
                            id="city"
                            v-model="city"
                          />
                          <div>
                            <input
                              type="text"
                              class="state-code"
                              id="stateCode"
                              v-model="stateCode"
                              @input="clearErrorAndValidate"
                              maxlength="2"
                              :class="{ 'border-red': stateCodeError }"
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-input"
                          id="stateId"
                          v-model="zipCode"
                        />
                      </div>
                      <span class="trns-required-alert">{{ stateCodeError }}</span>
                    </div>
                  </div>
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="county">County</label>
                      <div>
                        <div class="input-container-county">
                          <v-select
                            @input="preFillTaxInfo()"
                            v-model="county"
                            name="county"
                            label="name"
                            :options="countyArr"
                            :searchable="false"
                            id="county"
                            :placeholder="allStaticMessages.county_place_holder"
                          />
                          <div
                            class="search-icon-select"
                            @click="searchCounty"
                          ></div>
                        </div>
                      </div>
                      <county-search-modal
                        v-bind:isClickCountySearchModal="
                          isClickCountySearchModal
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="custom-column">
                  <div class="column-column">
                    <div class="fm-group">
                      <label for="city">Customer Email</label>
                      <input
                        type="text"
                        class="form-input"
                        placeholder="example@mail.com"
                        v-model="email"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div class="custom-accordian">
                    <div class="custom-card">
                      <div
                        class="custom-card-header"
                        @click="expandCollapse('expandCollapse1', 1)"
                      >
                        <div class="custom-column">Co-Buyer</div>
                        <div class="custom-column">
                          <img
                            src="/static/images/transaction_sheet/Vector.svg"
                            alt="Vector"
                            :class="expandCollapseImg1"
                          />
                        </div>
                      </div>
                      <div id="co-buyer">
                        <div class="custom-card-body" :class="expandCollapse1">
                          <div class="custom-row">
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="coBuyer">Co-Buyer</label>
                                <div class="custom-input-group">
                                  <div class="input-container">
                                    <input
                                      type="text"
                                      class="form-input"
                                      id="customer"
                                      v-model="coBuyer"
                                    />
                                    <div class="search-icon" @click="searchCustomer('coBuyer')"></div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-input"
                                    id="customerId"
                                    v-model="coBuyerExcedeCusId"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="coBuyerAddress">Address</label>
                                <input
                                  type="text"
                                  class="form-input"
                                  id="coBuyerAddress"
                                  v-model="coBuyerAddress"
                                />
                              </div>
                            </div>
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="coBuyerAddressTwo">Address 2</label>
                                <input
                                  type="text"
                                  class="form-input"
                                  id="address2"
                                  v-model="coBuyerAddressTwo"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="custom-row">
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="coBuyerCity">City</label>
                                <div class="custom-input-group">
                                  <div class="input-container">
                                    <input
                                      type="text"
                                      class="form-input"
                                      id="city"
                                      v-model="coBuyerCity"
                                    />
                                    <div class="state-code">{{ coBuyerStateCode }}</div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-input"
                                    id="stateId"
                                    v-model="coBuyerZipCode"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="custom-column">
                              <div class="fm-group">
                                <div class="fm-group">
                                  <label for="co-buyer-phone"
                                    >Co-Buyer Phone</label
                                  >
                                  <input
                                    type="text"
                                    class="form-input"
                                    id="co-buyer-phone"
                                    v-model="coBuyerPhone"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="custom-column card-body-checkbox">
                              <div>
                                <input type="checkbox" v-model="isDBA" />
                                <label for="dba">DBA</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="custom-accordian">
                    <div class="custom-card">
                      <div
                        class="custom-card-header"
                        @click="expandCollapse('expandCollapse2', 2)"
                      >
                        <div class="custom-column">Deliver To</div>
                        <div class="custom-column">
                          <img
                            src="/static/images/transaction_sheet/Vector.svg"
                            alt="Vector"
                            :class="expandCollapseImg2"
                          />
                        </div>
                      </div>
                      <div id="co-buyer">
                        <div class="custom-card-body" :class="expandCollapse2">
                          <div class="custom-row">
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="shipToName">Ship To Name</label>
                                <div class="custom-input-group">
                                  <div class="input-container">
                                    <input
                                      type="text"
                                      class="form-input"
                                      id="customer"
                                      v-model="deliverToName"
                                    />
                                    <div class="search-icon" @click="searchCustomer('deliverToName')"></div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-input"
                                    id="customerId"
                                    v-model="deliverToExcedeCusId"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="deliverToAddress">Address</label>
                                <input
                                  type="text"
                                  class="form-input"
                                  id="deliverToAddress"
                                  v-model="deliverToAddress"
                                />
                              </div>
                            </div>
                            <div class="custom-column">
                              <div class="fm-group">
                                <label for="deliverToCity">City</label>
                                <div class="custom-input-group">
                                  <div class="input-container">
                                    <input
                                      type="text"
                                      class="form-input"
                                      id="city"
                                      v-model="deliverToCity"
                                    />
                                    <div class="state-code">{{ deliverToStateCode }}</div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-input"
                                    id="stateId"
                                    v-model="deliverToZipCode"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="fm-group">
                    <label for="inernal-comments">Internal Comments</label>
                    <textarea
                      name="internal-comments"
                      id="internal-comments"
                      v-model="internalComments"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="column-row-2 transaction-form">
                <div class="column-2">
                  <div class="fm-group">
                    <label for="invoice-id">Invoice #</label>
                    <input type="text" class="form-input" id="invoice-id" v-model="invoiceNo" />
                  </div>
                </div>
                <div class="column-2">
                  <div class="fm-group">
                    <label for="cus-phone">Cus Phone</label>
                    <input
                      type="text"
                      class="form-input"
                      id="cus-phone"
                      placeholder="+1-202-555-0163"
                      v-model="primaryPhone"
                    />
                  </div>
                </div>
                <div class="column-2">
                  <div class="fm-group">
                    <label for="cus-fax">Cus Fax</label>
                    <input
                      type="text"
                      class="form-input"
                      id="cus-fax"
                      v-model="cusFax"
                    />
                  </div>
                  <div class="fm-group">
                    <label for="cus-sell">Cus Cell</label>
                    <input
                      type="text"
                      class="form-input"
                      id="cus-sell"
                      v-model="cusCell"
                    />
                  </div>
                </div>
                
                <div class="column-2">
                  <div class="fm-group">
                    <h2 class="section-header">Sales Tax:</h2>
                    <!--#### NOT A FUNCTIONAL SO NOT REQUIRED FOR NOW  ####
                    <label for="district-county">District / County</label>
                    <input
                      type="text"
                      class="form-input"
                      id="district-county"
                      v-model="districtOrCounty"
                    />
                    -->
                  </div>
                </div>
                <div class="column-2">
                  <div class="fm-group">
                    <label for="tax-rate">Tax Rate</label>
                    <money
                      v-bind="rateMask"
                      type="text"
                      class="form-input"
                      id="tax-rate"
                      v-model="taxRate"
                      disabled
                    />
                  </div>
                  <div class="fm-group fm-group-checkbox">
                    <input type="checkbox" checked v-model="applySalesTax" />
                    <label for="apply-sales-tax">Apply Sales Tax</label>
                  </div>
                </div>
                <div class="column-2">
                  <div class="fm-group">
                    <label for="state-tax-rate">State Tax Rate</label>
                    <money
                      v-bind="rateMask"
                      type="text"
                      class="form-input"
                      id="state-tax-rate"
                      v-model="stateTaxRate"
                    />
                  </div>
                  <div class="fm-group">
                    <label for="warranty-tax">Warranty Tax</label>
                    <money
                      v-bind="warrantyRateMask"
                      type="text"
                      class="form-input"
                      id="warranty-tax"
                      v-model="warrantyTax"
                    />
                  </div>
                </div>
                <!--#### NOT A FUNCTIONAL SO NOT REQUIRED FOR NOW  ####
                <div class="column-2">
                  <div class="fm-group">
                    <label for="county">County</label>
                    <input
                      type="text"
                      class="form-input"
                      v-model="countyName"
                    />
                  </div>
                </div>
                -->
              </div>
            </div>

            <div class="custom-row comment-section">
              <div class="custom-column">
                <div class="fm-group">
                  <label for="booking-comments">Booking Comments</label>
                  <textarea
                    name="booking-comments"
                    id="booking-comments"
                    v-model="bookingComments"
                  ></textarea>
                </div>
              </div>
              <div class="custom-column">
                <div class="fm-group">
                  <label for="comments">Comments</label>
                  <textarea
                    name="comments"
                    id="comments"
                    v-model="comments"
                  ></textarea>
                </div>
              </div>
              <div class="custom-column">
                <div class="fm-group">
                  <input type="checkbox" v-model="showOnBuyersOrder" />
                  <label for="show-on-buyers-order">Show On Buyers Order</label>
                </div>
              </div>
            </div>
          </div>

          <div id="main-vhc-info">
            <div class="custom-row">
              <div class="column-row">
                <div class="custom-column">
                  <h2 id="vhc-title">Vehicle Information</h2>
                </div>
                <!--#### NOT REQUIRED FOR NOW ####
                  <div class="custom-column">
                    <div class="fm-group">
                      <label for="select-group">Select Group</label>
                      <v-select
                        v-model="selectGroup"
                        disabled
                        label="Disabled"
                        :filterable="false"
                        :clearable="true"
                        id="select-group"
                        placeholder="Select Group"
                        class="custom-ci-auto-search trns-select"
                      />
                    </div>
                  </div> 
                -->
              </div>
            </div>

            <div class="custom-row">
              <div class="column-row">
                <div class="custom-column">
                  <div class="vhc-btn-group">
                    <button
                      :type="
                        permissionNameArray.includes(
                          'can_add_deal_inventory_mapper'
                        )
                          ? 'button'
                          : ''
                      "
                      :class="
                        permissionNameArray.includes(
                          'can_add_deal_inventory_mapper'
                        )
                          ? 'vhc-btn'
                          : 'vhc-btn trs-btn-disabled'
                      "
                      @click="vehicleWorkSheet()"
                    >
                      Add Vehicle
                    </button>
                    <!--#### NOT A FUNCTIONAL SO NOT REQUIRED FOR NOW  ####
                    <button class="vhc-btn trs-btn-disabled">
                      Load Template
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Copy Vehicle
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Search Stock Vehicle
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Update Vin#/Stock#
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Create Group
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Update Selected Vehicles
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Move Selected Vehicles
                    </button>
                    <button class="vhc-btn trs-btn-disabled">
                      Mass Update Story
                    </button>
                    ####-->
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-row">
              <div class="column-row">
                <div class="custom-column"></div>
                <div class="custom-column">
                  <span id="vhc-count"
                    >Vehicle Count: <span>{{ assetArr.length }}</span></span
                  >
                </div>
              </div>
            </div>

            <div class="custom-row">
              <table class="custom-trs-table">
                <caption>Vehicle Info</caption>
                <thead>
                  <th>
                    <input
                      type="checkbox"
                      id="trsCheckBox"
                      class="trs-btn-disabled"
                      disabled
                    />
                  </th>
                  <th>NU</th>
                  <th>VIN</th>
                  <th>STOCK NO</th>
                  <th>MODEL</th>
                  <th>SALE PRICE</th>
                  <th>Fees</th>
                  <th>GP</th>
                  <th>DELIVERY DATE</th>
                  <th>DELIVERED</th>
                  <th>DETAIL</th>
                </thead>
                <tbody v-if="assetArr && assetArr.length > 0">
                  <tr v-for="(vehicle, index) in assetArr" :key="index">
                    <td>
                      <input
                        type="checkbox"
                        id="trsCheckBox"
                        class="trs-btn-disabled"
                        disabled
                      />
                    </td>
                    <td>{{ vehicle.new_used }}</td>
                    <td>
                      <a
                        @click="vehicleWorkSheet('edit', vehicle.id, index)"
                        title="Edit Vin Vehicle"
                        href="javascript:void(0)"
                      >
                        {{ vehicle.vin }}</a
                      >
                    </td>
                    <td>{{ vehicle.unit }}</td>
                    <td v-if="vehicle.model">
                      {{ vehicle.model }}
                    </td>
                    <td>$ {{ vehicle.base_sales_price.toFixed(2) }}</td>
                    <td>$ {{ vehicle.fees }}</td>
                    <td></td>
                    <td>{{ vehicle.prop_delivery_date != null ? vehicle.prop_delivery_date.split(' ')[0] : '-'}}</td>
                    <td>
                      <input
                        type="checkbox"
                        id="trsCheckBox"
                        class="trs-btn-disabled"
                        :checked="vehicle.is_delivered=='yes' ? true : false"
                        disabled
                      />
                    </td>
                    <td>
                      <i class="vhc-tbl-dtl"
                        @click="showVhcDetail(vehicle.vin)" title="Vehicle Detail"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <vehicle-detail v-bind:isClickVehicleDetail="isClickVehicleDetail" v-bind:vinNo="vinNo"></vehicle-detail>
          </div>

          <div id="trade-detail">
            <div class="custom-accordian">
              <div class="custom-card">
                <div
                  class="custom-card-header"
                  @click="expandCollapse('expandCollapse3', 3)"
                >
                  <div class="custom-column">Trade Detail</div>
                  <div class="custom-column">
                    <img
                      src="/static/images/transaction_sheet/Vector.svg"
                      alt="Vector"
                      :class="expandCollapseImg3"
                    />
                  </div>
                </div>
                <div>
                  <div class="custom-card-body" :class="expandCollapse3">
                    <div class="custom-row">
                      <table class="custom-trs-table">
                        <caption>Trade Details</caption>
                        <thead>
                          <th>Year</th>
                          <th>MAKE</th>
                          <th>MODEL</th>
                          <th>GROSS TRADE-IN</th>
                          <th>PAYOFF</th>
                          <th>ACV</th>
                          <th>VIN</th>
                          <th>STOCK#</th>
                          <th>SALES STOCK#</th>
                          <th>APPRAISAL</th>
                        </thead>
                        <tbody v-if="tradeDetailList.length > 0">
                          <tr v-for="(tradeDetail, index) in tradeDetailList" :key="index">
                            <td>{{ tradeDetail.year }}</td>
                            <td>{{ tradeDetail.make }}</td>
                            <td>{{ tradeDetail.model }}</td>
                            <td>{{ tradeDetail.gross_trade_in }}</td>
                            <td>{{ tradeDetail.payoff }}</td>
                            <td>{{ tradeDetail.acv }}</td>
                            <td>{{ tradeDetail.vin }}</td>
                            <td>{{ tradeDetail.stock_id }}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="trns-tab-section">
            <div class="custom-row">
              <div class="custom-column transaction-form">
                <div id="trns-order">
                  <div class="fm-group">
                    <label for="orderType">Order Type:</label>
                    <v-select
                      v-model="orderType"
                      name="orderType"
                      label="text"
                      :filterable="false"
                      :options="orderTypeArr"
                      :clearable="true"
                      class="custom-ci-auto-search tab-select"
                    />
                  </div>
                  <div class="fm-group">
                    <label for="totalDeposit">Total Deposit:</label>
                    <money
                      v-bind="moneyMask"
                      id="downpayment"
                      v-model="downPaymentOnOrder"
                      type="text"
                      @blur.native="addDownPayment()"
                    />
                    <v-select
                      v-model="downpaymentPaymentType"
                      name="selectDownPayment"
                      id="selectDownPayment"
                      label="text"
                      :clearable="true"
                      :searchable="false"
                      :filterable="false"
                      :options="paymentTypeArr"
                      class="custom-ci-auto-search tab-select"
                    />
                    <input
                      id=""
                      type="text"
                      maxlength="190"
                      v-model="downPaymentInfo"
                    />
                  </div>
                  <div class="fm-group">
                    <label for="cashBack">Cash Back:</label>
                    <money
                      v-bind="moneyMask"
                      id="cashBack"
                      v-model="cashBack"
                      type="text"
                      disabled
                    />
                    <v-select
                      v-model="cashBackPaymentType"
                      name="selectCashBack"
                      id="selectCashBack"
                      label="text"
                      :clearable="true"
                      :searchable="false"
                      :filterable="false"
                      :options="paymentTypeArr"
                      class="custom-ci-auto-search tab-select"
                    />
                    <input
                      id="cashBackInfo"
                      type="text"
                      v-model="cashBackInfo"
                    />
                  </div>
                  <div class="fm-group">
                    <label for="dueOnDelivery">Due On Delivery:</label>
                    <money
                      v-bind="moneyMask"
                      id="dueOnDelivery"
                      v-model="dueOnDelivery"
                      type="text"
                      @blur.native="addDueOnDelivery()"
                    />
                    <v-select
                      v-model="dueOnDeliveryPaymentType"
                      name="selectDueOnDelivery"
                      id="selectDueOnDelivery"
                      label="text"
                      :clearable="true"
                      :searchable="false"
                      :filterable="false"
                      :options="paymentTypeArr"
                      class="custom-ci-auto-search tab-select"
                    />
                    <input
                      id="duePaymentInfo"
                      type="text"
                      v-model="duePaymentInfo"
                    />
                  </div>
                  <div id="trns-price">
                    <label for="orderType">Payment Type:</label>
                    <div>
                      <div>
                        <input
                          type="radio"
                          v-model="paymentCat"
                          value="lease"
                          @input="checkUncheck('lease')"
                          class="trns-payment-type-radio"
                          id="trns-lease"
                        />
                        <label for="trns-lease">Lease</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="paymentCat"
                          value="finance"
                          @input="checkUncheck('finance')"
                          class="trns-payment-type-radio"
                          id="trns-finance"
                        />
                        <label for="trns-finance">Finance</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="paymentCat"
                          value="cash"
                          @input="checkUncheck('cash')"
                          class="trns-payment-type-radio"
                          id="trns-cash"
                        />
                        <label for="trns-cash">Cash</label>
                      </div>
                    </div>
                </div>
                  <!--#### Lease and Finance  ####-->
                  <finance
                    v-show="paymentCat == 'finance'"
                    v-bind:financeCompanyList="financeCompanyArr"
                    v-bind:financeData="financeData"
                    v-bind:isEdit="isEdit"
                    v-bind:triggerPayment="triggerPayment"
                    v-bind:paymentCat="paymentCat"
                    v-bind:permissionNameArray="permissionNameArray"
                  />
                  <lease
                    v-show="paymentCat == 'lease'"
                    v-bind:financeCompanyList="financeCompanyArr"
                    v-bind:leaseData="leaseData"
                    v-bind:isEdit="isEdit"
                    v-bind:triggerPayment="triggerPayment"
                    v-bind:paymentCat="paymentCat"
                    v-bind:permissionNameArray="permissionNameArray"
                  />
                  <div id="lien-holder" v-if="isLienHolderCheckboxVisible">
                    <input type="checkbox" id="trsCheckBox" name="lien-holder-check" value="yes" @change="checkLienHolder($event)" :checked="isLienHolder=='yes'">
                    <label for="lien-holder-title">Lienholder (Yes/No)</label>
                  </div>
                </div>
              </div>

              <div class="custom-column">
                <div id="trns-cash">
                  <div>
                    <label for="salesPrice">Sales Price:</label>
                    <money
                      v-bind="moneyMask"
                      id="salesPrice"
                      v-model="salesPrice"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="tradeAllowance">Trade Allowance:</label>
                    <money
                      v-bind="moneyMask"
                      @blur.native="calculateQuot"
                      id="tradeAllownce"
                      v-model="tradeAllownce"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="totalFees">Total Fees:</label>
                    <money
                      v-bind="moneyMask"
                      id="totalFees"
                      v-model="totalFees"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="adminFees">Admin Fee:</label>
                    <money
                      v-bind="moneyMask"
                      id="adminFees"
                      v-model="adminFee"
                      type="text"
                      @blur.native="calculateQuot"
                    />
                  </div>
                  <div>
                    <label for="totalSalesTax">Total Sales Tax:</label>
                    <money
                      v-bind="moneyMask"
                      id="totalSalesTax"
                      v-model="totalSalesTax"
                      type="text"
                      @blur.native="calculateQuot"
                    />
                  </div>
                  <div>
                    <label for="total">Total:</label>
                    <money
                      v-bind="moneyMask"
                      id="total"
                      v-model="total"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="warranties">Warranties:</label>
                    <money
                      v-bind="moneyMask"
                      id="warranties"
                      v-model="warranties"
                      type="text"
                      @blur.native="calculateQuot"
                    />
                  </div>
                  <div>
                    <label for="insurance">Insurance:</label>
                    <money
                      v-bind="moneyMask"
                      @blur.native="calculateQuot"
                      id="insurance"
                      v-model="insurance"
                      type="text"
                    />
                  </div>
                  <div>
                    <label for="totalPlusWarranties">Total + Warranties:</label>
                    <money
                      v-bind="moneyMask"
                      id="ttlWarrenties"
                      v-model="totalPlusWarranties"
                      type="text"
                      disabled
                    />
                  </div>

                  <div>
                    <label for="downpayment">Total Deposit:</label>
                    <money
                      v-bind="moneyMask"
                      @blur.native="calculateQuot"
                      id="downpayment"
                      v-model="downpaymentAmt"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="ttlCashBack">Cash Back:</label>
                    <money
                      v-bind="moneyMask"
                      id="ttlCashBack"
                      v-model="cashBack"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="ttlDueOnDelivery">Due On Delivery:</label>
                    <money
                      v-bind="moneyMask"
                      id="ttlDueOnDelivery"
                      v-model="dueOnDelivery"
                      type="text"
                      disabled
                    />
                  </div>
                  <div>
                    <label for="dueonpaymentAmt">Remaining Due:</label>
                    <money
                      v-bind="moneyMask"
                      id="ttlDueonpaymentAmt"
                      v-model="dueonpaymentAmt"
                      type="text"
                      disabled
                    />
                  </div>
                  
                </div>
              </div>
            </div>
            <hr v-if="isLienHolder=='yes' && isLienHolderCheckboxVisible" />
            <div class="lien-holder-section" v-if="isLienHolder=='yes' && isLienHolderCheckboxVisible">
              <div class="fm-group">
                  <label for="lienholder">Lienholder Name <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
                  <input
                  type="text"
                  class="form-input"
                  v-model="lineHolderName"
                  @input="lineHolderNameError=''"
                  />
                  <span class="trns-required-alert" v-if="lineHolderNameError !== ''">{{ lineHolderNameError }}</span>
              </div>

              <div class="fm-group">
                  <label for="address">Lienholder Address <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
                  <input
                  type="text"
                  class="form-input"
                  v-model="lineHolderAddress"
                  @input="lineHolderAddressError=''"
                  />
                  <span class="trns-required-alert" v-if="lineHolderAddressError !== ''">{{ lineHolderAddressError }}</span>
              </div>

              <div class="fm-group">
                  <label for="finance-city">Lienholder City <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
                  <input
                  type="text"
                  class="form-input"
                  v-model="lineHolderCity"
                  @input="lineHolderCityError=''"
                  />
                  <span class="trns-required-alert" v-if="lineHolderCityError !== ''">{{ lineHolderCityError }}</span>
              </div>

              <div class="fm-group">
                  <label for="state-zip">Lienholder State <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                  v-model="lineHolderState"
                  name="state"
                  label="text"
                  :filterable="false"
                  :options="lineHolderStateArr"
                  @search="onSearchState"
                  :clearable="false"
                  @input="lineHolderStateError = ''"
                  placeholder="Search & Select State"
                  class="custom-ci-auto-search"
                  id="select-finance-state"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isStateRecordNotFound">
                        Search State...
                      </template>
                      <template v-else>
                        Sorry, no matching record found!
                      </template>
                    </span>
                  </v-select>
                  <span class="trns-required-alert" v-if="lineHolderStateError !== ''">{{ lineHolderStateError }}</span>
              </div>

              <div class="fm-group">
                  <label for="zip">Lienholder Zip <span class="finance-company-required" title="REQUIRED FIELD">*</span></label>
                  <input
                  type="text"
                  class="form-input"
                  v-model="lineHolderZip"
                  @input="lineHolderZipError = ''"
                  />
                  <span class="trns-required-alert" v-if="lineHolderZipError !== ''">{{ lineHolderZipError }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vehicle-work-sheet
        v-else
        v-bind:editAssetId="editAssetId"
        v-bind:dealId="dealId"
        v-bind:vehicleData="vehicleData"
        v-bind:isVehicleEdit="isVehicleEdit"
        v-bind:county="county"
        v-bind:cusStateCode="stateCode"
        v-bind:salesTaxCounty="countyName"
        v-bind:locationId="locationId"
        v-bind:cusStateTaxRate="stateTaxRate"
        v-bind:cusTaxRate="taxRate"
        v-bind:cusApplySalesTax="applySalesTax"
        v-bind:warrantyTax="warrantyTax"
        v-bind:dealNo="dealNo"
        v-bind:permissionNameArray="permissionNameArray"
        v-bind:vehicleType="vehicleType"
        v-bind:dealStatusSlug="dealStatusSlug"
        v-bind:customer="customerName"
        v-bind:salesperson="salePersonId.text"
        v-bind:usedVinList="usedVinList"
        v-bind:usedUnitList="usedUnitList"
      ></vehicle-work-sheet>
      <print-doc-option-modal 
        v-if="showPrintDocPopup"
        ref="printDocPopupRef">
      </print-doc-option-modal>
      <finance-company-modal
        v-bind:isClickAddFinanceCompany="isClickAddFinanceCompany"
      ></finance-company-modal>
      <mail-modal :isClickMail="isClickDealLevelRequest" :id="dealId" :mailDescription="dlrDescription" :locationId="locationId" :dealStatusSlug="requestedDealStatusSlug" :trnsMail="true" :vehicleCount="assetArr.length"></mail-modal>
      <mail-history :isClickMailHistory="isClickMailHistory" :dealNo="dealNo" :dealId="dealId" :labelMessage="allLabelMessages"></mail-history>
      <div class="page-loader-wrapper loading-container" v-if="showTrnsLoader">
        <div class="loading">
          <img src="/static/images/loading.gif" alt="loader" />
        </div>
      </div>
      <app-footer></app-footer>
    </div>
    <div v-else>
      <no-access></no-access>
    </div>
  </div>
</template>

<script>
let self;
import Vue from "vue";
import { commonMixins } from "../../../mixins/commonMixins";
import EventBus from "../../event-bus";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import moment from "moment";
import { Money } from "v-money";
import CustomerSearchModal from "./Modal/CustomerSearchModal.vue";
import CountySearchModal from "./Modal/CountySearchModal.vue";
import vSelect from "vue-select";
import PrintDocOptionModal from "./PrintDocOption";
import Cash from "./Payment/Cash";
import Finance from "./Payment/Finance";
import Lease from "./Payment/Lease";
import FinanceCompanyModal from "./Modal/AddFinanceCompanyModal";
import VehicleDetailModal from "./Modal/VehicleDetailModal.vue";
import Footer from "@/components/partials/Footer";
import MailModal from "./Modal/Mail/MailModal.vue";
import MailHistory from "./Modal/Mail/MailHistoryModal.vue";
import VwsUI from "./VwsUi.vue";

Vue.component("v-select", vSelect);
Vue.component("app-footer", Footer);

export default {
  name: "TransactionSheet",
  components: {
    datePicker,
    "no-access": NoAccessPage,
    tinymce: tinymce,
    Money,
    "customer-search-modal": CustomerSearchModal,
    "county-search-modal": CountySearchModal,
    "vehicle-work-sheet": VwsUI,
    "print-doc-option-modal": PrintDocOptionModal,
    cash: Cash,
    finance: Finance,
    lease: Lease,
    "finance-company-modal": FinanceCompanyModal,
    "vehicle-detail": VehicleDetailModal,
    "mail-modal": MailModal,
    "mail-history": MailHistory,
  },
  mixins: [commonMixins],
  data() {
    return {
      opportunityId: this.$route.params.opportunityId,
      dealType: "cash",
      selectedRows: [],
      selectAll: false,
      addToForecast: false,
      dealNo: "",
      dealNoError: "",
      oppNo: "",
      oppNoError: "",
      invoiceNo: "",
      invoiceNoError: "",
      dealStatusArr: [],
      statusError: "",
      isDealStatusRecordNotFound: false,
      invoiceDate: "",
      invoiceDateError: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
        copyVehicle: "Copy Vehicle",
        searchStockVehicles: "Search Stock Vehicles",
        updateVinOrStock: "Update Vin#/Stock#",
        lease: "Lease",
        finance: "Finance",
        cash: "Cash",
        roPoRequest: "Request RO/PO",
        docRequest: "Doc Request",
        accounting: "Accounting",
        manager: "Manager",
        returnToSales: "Return To Sales",
        returnToDoc: "Return To Doc Req",
        lost: "Lost",
        print: "Print",
        mailHistory: "Mail History",
        deleteTransaction: "Delete Transaction",
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
        copyVehicle: false,
        searchStockVehicles: false,
        updateVinOrStock: false,
        lease: false,
        finance: false,
        cash: false,
      },

      // Required
      locationArr: [],
      locationId: "",
      locationError: "",
      moneyMask: {
        // decimal: ',',
        thousands: ",",
        prefix: "$ ",
        // suffix: ' #',
        // precision: 0,
      },
      rateMask: {
        suffix: " %",
        precision: 2,
      },
      warrantyRateMask: {
        suffix: " %",
        precision: 2,
      },
      print_document_flag: false,
      isClickCustomerModal: false,
      flagCustomerPopulate: 0,
      currentCustomerData: false,
      warrantyFromVehicleWorksheet: 0,
      isMount: true,

      /*##### Latest Design #####*/
      customerName: "",
      isClickSearchModal: false,

      customerId: "",
      address: "",
      primaryPhone: "",
      city: "",
      stateCode: "",
      zipCode: "",
      email: "",

      county: "",
      countyArr: [],
      isClickCountySearchModal: false,

      taxRate: "",
      stateTaxRate: "",
      warrantyTax: 9.25,
      tempTaxRate: "",
      taxRateArr: [],

      // Vehicle Info
      isShowVehicleWorkSheet: false,
      editAssetId: "",
      isVehicleEdit: false,

      dealId: "",
      vehicleData: "",
      countyName: "",
      applySalesTax: true,

      // Cash
      tab: "cash",
      cashId: 0,
      salesPrice: 0.0,
      salesPriceError: "",
      downpaymentAmt: 0.0,
      downpaymentAmtError: "",
      dueonpaymentAmt: 0.0,
      tradeAllownce: 0,
      tradeAllownceError: "",
      totalFees: 0.0,
      totalFeesError: "",
      totalSalesTax: 0.0,
      totalSalesTaxError: "",
      insurance: 0,
      insuranceError: "",
      totalPlusWarranties: 0,
      oldTab: "",
      oldTabId: 0,
      salePersonId: "",
      salePersonArr: [],
      salePersonError: "",
      isSalePersonRecordNotFound: false,

      orderType: "",
      orderTypeArr: [],
      orderTypeError: "",

      downPaymentOnOrder: 0,
      downpaymentPaymentType: "",
      downPaymentInfo: "",

      cashBackInfo: "",
      cashBack: 0,
      cashBackError: "",
      cashBackPaymentType: "",
      cashBackDesc: "",

      dueOnDelivery: 0,
      dueOnDeliveryError: "",
      dueOnDeliveryPaymentType: "",
      dueOnDeliveryDesc: "",
      duePaymentInfo: "",

      dealMapperId: 0,
      contractNumber: "",
      contractNumberError: "",
      cusFax: "",
      cusFaxError: "",
      cusCell: "",
      cusCellError: "",
      districtOrCounty: "",
      internalComments: "",
      internalCommentsError: "",
      comments: "",
      commentsError: "",
      bookingComments: "",
      bookingCommentsError: "",
      showOnBuyersOrder: false,
      isEdit: false,
      assetArr: [],

      paymentType: "",
      paymentTypeArr: [],
      total: 0.0,
      totalError: "",
      warranties: 0,
      warrantiesError: "",

      // Return back
      tempRetailPrice: 0,
      // MOUNT
      dataAccessType: "used",
      conditionId: { text: "New", value: "1" },
      firstAdd: false,

      // Accordian
      expandCollapse1: "vhc-collapse",
      expandCollapseImg1: "rotate-accordian-collapse",
      expandCollapse2: "vhc-collapse",
      expandCollapseImg2: "rotate-accordian-collapse",
      expandCollapse3: "vhc-collapse",
      expandCollapseImg3: "rotate-accordian-collapse",

      // TabSection Update
      paymentCat: "finance",
      isClickAddFinanceCompany: false,
      selectGroup: "",
      deliverTo: "",
      financeCompanyArr: [],
      showTrnsLoader: false,
      isEventBus: false,

      // PAYMENT SECTION EMITTED DATA
      financeData: {},
      leaseData: {},
      triggerPayment: false,

      // PERMISSION
      permissionNameArray: ["can_add_deals"],
      // TRADE DETAILS
      tradeDetailList: [],
      isCountyEdit: true,
      excedeCustomerId: "",

      // LIEN-HOLDER
      lineHolderName: "",
      lineHolderNameError: "",
      lineHolderAddress: "",
      lineHolderAddressError: "",
      lineHolderCity: "",
      lineHolderCityError: "",
      lineHolderState: "",
      lineHolderStateError: "",
      lineHolderZip: "",
      lineHolderZipError: "",
      lineHolderStateArr: [],
      isLienHolder: "no",
      lienHolderId: null,
      isLienHolderCheckboxVisible: false,

      // CO-BUYER
      coBuyer: "",
      coBuyerCusId: "",
      coBuyerExcedeCusId: "",
      coBuyerAddress: "",
      coBuyerAddressTwo: "",
      coBuyerCity: "",
      coBuyerStateCode: "",
      coBuyerZipCode: "",
      coBuyerPhone: "",
      isDBA: 0,

      // DELIVERY TO
      deliverToName: "",
      deliverToCusId: "",
      deliverToExcedeCusId: "",
      deliverToAddress: "",
      deliverToCity: "",
      deliverToStateCode: "",
      deliverToZipCode: "",

      searchCustomerNameId: "",
      customerSearchType: "",
      isClickVehicleDetail: false,
      vinNo: "",

      // ROPO
      isClickDealLevelRequest: false,
      dealLevel: "Sales",
      vehicleType: "",
      dlrDescription: {},
      requestedDealStatusSlug: 'sales',
      dealStatusSlug: 'sales',

      isStateRecordNotFound: false,
      adminFee: 0,

      // Mail History
      isClickMailHistory: false,
      usedVinList: "",
      usedUnitList: "",

      showPrintDocPopup: false,
      vehicleDetailModalInstance: null,
      addFinanceCompanyInstance: null,
      customerSearchModalInstance: null,
      countySearchModalInstance:  null,
      mailHistoryModalInstance: null,
      mailModalInstance: null,
      stateCodeError: '',
    };
  },
  created: function () {
      self = this;
      self.getLoginIdPermissions();
  },
  beforeMount() {
    self = this;
    self.showTrnsLoader = true;
    if (
      self.opportunityId !== null &&
      self.opportunityId !== 0 &&
      typeof self.opportunityId !== "undefined"
    ) {
      let opportunityData = this.$route.params;
      self.permissionNameArray = opportunityData.permissionNameArray;
      self.oppNo = opportunityData.referenceNo;
      localStorage.setItem("opportunityId", self.opportunityId);
      localStorage.setItem("referenceNo", self.oppNo);
      if(opportunityData.opportunityStatus.toLowerCase() === "won") {
        self.addToForecast = true;
      }
      localStorage.setItem("addToForecast", self.addToForecast);
    } else if (localStorage.getItem("opportunityId") !== null) {
      self.opportunityId = localStorage.getItem("opportunityId");
      self.dealId = localStorage.getItem("dealId");
      self.oppNo = localStorage.getItem("referenceNo");
      self.addToForecast = localStorage.getItem("addToForecast");
    }
  },
  mounted() {
    let isEventBus = this.isEventBus;

    let localDealInventoryMapperId = localStorage.getItem(
      "dealInventoryMapperId"
    );
    let localVehicleId = localStorage.getItem("vehicleId");

    if (
      !isEventBus &&
      localDealInventoryMapperId !== null &&
      !self.$route.query.deal_id
    ) {
      self.dealInventoryMapperId = localDealInventoryMapperId;
      self.assetId = localVehicleId;
      self.isVehicleEdit = false;
    }

    /* Redirect to login page logic start */
    let userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    } else {
      let userJson = JSON.parse(userObj);
      self.salePersonId = {
        text: userJson["first_name"] + " " + userJson["last_name"],
        value: userJson["id"],
      };
      self.dataAccessType = userJson["access_inventory"];
      if (userJson["access_inventory"] == "used") {
        self.conditionId = { text: "Used", value: "2" };
      } else {
        self.conditionId = { text: "New", value: "1" };
      }
    }

    self.setActiveMenu("deals-list");
    EventBus.$emit("menu-selection-changed", "deals-list");
    self.firstAdd = true;

    if (
      typeof self.$route.query.deal_id != "undefined" &&
      self.$route.query.deal_id != "" &&
      self.$route.query.deal_id != 0
    ) {
      self.dealId = self.$route.query.deal_id;
    }

    if (self.$route.query.deal_id > 0) {
      self.editTransactionSheet(self.$route.query.deal_id);
    }

    if (self.tab == "cash") {
      self.calculateQuot();
    }

    setTimeout(function() {
      self.loadAllData(
        [
          // "Company",
          // "DealStatus",
          "User",
          // "Contact",
          // "Cities",
          // "Countries",
          "FinanceGroup",
          "PaymentType",
          // "States",
          "Location",
          "OrderTypes",
        ],
        false,
        self.loadMasterDataCallback,
        "undefined",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "DealBooking"
      );
    }, 800);

    setTimeout(function() {
      self.loadCounty();
    }, 1600);

    self.$nextTick(() => {
      if (self.isEdit === false) {
        self.onSearchDefaultCustomer();
      }
    });

    /**
     * 
     * REDIRECT TO VEHICLE WORKSHEET PAGE ON HARD REFRESH
     * Fetch the Open Vehicle Worksheet Page List
     * Find the current open Deal in the list
     * If found then pass the status, vehicleId and vehicleIndex
     * Else Ignore
     * 
     */
    let vehicleWorksheetRefresh = [];
    vehicleWorksheetRefresh = JSON.parse(localStorage.getItem('VehicleWorksheetRefresh'));
    if(vehicleWorksheetRefresh && vehicleWorksheetRefresh.length > 0) {
      const vehicle = vehicleWorksheetRefresh.find(item => {
        return item.dealId === self.dealId;
      });
      if(vehicle) {
        setTimeout(() => {
          self.vehicleWorkSheet(vehicle.status, vehicle.vehicleId, vehicle.vehicleIndex);
        }, 1500);
      } else {
        self.showTrnsLoader = false;
      }
    } else {
      self.showTrnsLoader = false;
    }
    self.initializeModals();
  },
  methods: {
    initializeModals() { 
      self.vehicleDetailModalInstance = new bootstrap.Modal(
        document.getElementById("vehicleDetailModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
      );
      self.addFinanceCompanyInstance = new bootstrap.Modal(
        document.getElementById("financeCompanyModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
      );
      self.customerSearchModalInstance = new bootstrap.Modal(
        document.getElementById("searchModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
      );
      self.countySearchModalInstance = new bootstrap.Modal(
        document.getElementById("countySearchModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
      );
      self.mailHistoryModalInstance = new bootstrap.Modal(
          document.getElementById("mailHistory"),
            {
              backdrop: "static",
              keyboard: false,
            }
      );
      self.mailModalInstance = new bootstrap.Modal(
          document.getElementById("mailModal"),
            {
              backdrop: "static",
              keyboard: false,
            }
      );
    },

    /*##### Department and Address Section With Auto Populate Customer and County #####*/

    loadMasterDataCallback: function (callbackData) {
      if (callbackData && callbackData.length <= 0) {
        return false;
      }

      self.locationArr = callbackData.Location;
      if (self.locationArr.length == 0) {
        self.isLocationRecordNotFound = true;
      } 
      /* #### DEFAULT LOCATION IS BASED ON OPPORTUNITY LOCATION (CLIENT MEETING 12/28/2023) ####
      else if (self.isEdit === false) {
        // This else condition is applied to select the default branch "Los Angeles Freightliner - Whittier" on mount 

        self.locationArr.filter((location) => {
          if (location.excedeLocId === "210") {
            self.locationId = {
              text: location.text,
              value: location.value,
              excedeLocId: location.excedeLocId
            };
          }
        });
      }
      */

      self.salePersonArr = callbackData.User;
      if (self.salePersonArr.length == 0) {
        self.isSalePersonRecordNotFound = true;
      }

      self.paymentTypeArr = callbackData.PaymentType;

      /*#### DIRECTLY TAKEN AT THE TIME OF EDIT API CALL ####
        self.locationArr.filter((location) => {
          if (location.value === self.locationId) {
            console.log("locationInside1>>", self.locationId);
            self.locationId = {
              text: location.text,
              value: location.value,
            };
            console.log("locationInside2>>", self.locationId);
            self.showTrnsLoader = false;
          }
        });

        if (self.isEdit) {
          self.salePersonArr.filter((sale) => {
            if (sale.value === self.salePersonId) {
              self.salePersonId = {
                text: sale.text,
                value: sale.value,
              };
              self.salePersonError = "";
            }
          });
        }
      */

      self.financeCompanyArr = callbackData.FinanceGroup;
      self.orderTypeArr = callbackData.OrderTypes;
    },    
    addWarrantyTax() {
      self.locationError = "";
      if (!self.isMount && self.locationId.stateId == 3969) {
        // console.log(
        //   "Should take either countryId or code instead of stateId",
        //   self.locationId
        // );
        self.warrantyTax = 9.25;
      } else {
        self.isMount = false;
        self.warrantyTax = 0;
      }
    },

    searchCustomer(dataInstance) {
      self.isClickSearchModal = true;
      self.searchCustomerNameId = this[dataInstance];
      self.customerSearchType = dataInstance;
      self.customerSearchModalInstance.show();
    },
    populateCustomerData(customer) {
      if (customer != "") {
        switch (self.customerSearchType) {
          case 'coBuyer':
            self.coBuyer = customer.text;
            self.coBuyerCusId = customer.value;
            self.coBuyerExcedeCusId = customer.customerId;
            self.coBuyerAddress = customer.address;
            self.coBuyerCity = customer.cityName;
            self.coBuyerStateCode = customer.stateCode;
            self.coBuyerZipCode = customer.zipCode;
            self.coBuyerPhone = customer.primaryPhone;
            break;
          case 'deliverToName':
            self.deliverToName = customer.text;
            self.deliverToCusId = customer.value;
            self.deliverToExcedeCusId = customer.customerId;
            self.deliverToAddress = customer.address;
            self.deliverToCity = customer.cityName;
            self.deliverToStateCode = customer.stateCode;
            self.deliverToZipCode = customer.zipCode;
            break;
          default:
            self.customerName = customer.text;
            self.customerId = customer.value;
            self.excedeCustomerId = customer.customerId;
            self.address = customer.address;
            self.primaryPhone = customer.primaryPhone;
            self.city = customer.cityName;
            self.stateCode = customer.stateCode;
            self.zipCode = customer.zipCode;
            self.email = customer.email;
            self.cusCell = customer.alternate_phone;
            self.cusFax = customer.fax;
            break;
        }
      }
    },

    loadCounty() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      HTTP.get("/load-county", config)
        .then(function (data) {
          self.countyArr = data.data.content;
        })
        .catch(function (error) {
          console.log("error=>" + error);
        });
    },

    searchCounty() {
      self.isClickCountySearchModal = true;
      self.countySearchModalInstance.show();
    },

    preFillTaxInfo() {
      if (
        self.isCountyEdit === true &&
        typeof self.county === "object" &&
        self.county.text
      ) {
        const taxRate = parseFloat(self.county.rate) * 100;
        self.taxRate = taxRate;
        self.tempTaxRate =
          self.tempTaxRate != 0 ? self.tempTaxRate : self.county.rate;
        self.stateTaxRate = self.county.rate ? taxRate : 0;
      } else {
        self.isCountyEdit = false;
        self.taxRate = self.taxRate == null ? 0 : self.taxRate;
        self.tempTaxRate = self.taxRate;
      }

      return true;
    },

    populateCountyData(county) {
      self.countyArr.push({
        name: county.name,
        value: county.id,
        text: county.code,
        rate: county.rate,
      });

      self.county = {
        name: county.name,
        value: county.id,
        text: county.code,
        rate: county.rate,
      };

      self.preFillTaxInfo();
    },

    onSearchSalePerson(search, loading) {
      if (search !== undefined && search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadSalePersonCallback,
          loading,
          search
        );
      }
    },
    loadSalePersonCallback: function (callbackData) {
      self.isSalePersonRecordNotFound = false;
      self.salePersonArr = callbackData.User;
      if (self.salePersonArr.length == 0) {
        self.isSalePersonRecordNotFound = true;
      }
    },
    /*##### End Department and Address Section #####*/

    /*##### Vehicle Information Section #####*/
    vehicleWorkSheet: async function (type = null, id = null, assetIndex=null) {
      if (self.permissionNameArray.includes("can_add_deal_inventory_mapper")) {
        self.showTrnsLoader = true;
        self.editAssetId = id;

        /**
         * 
         * REDIRECT TO VEHICLE WORKSHEET PAGE ON HARD REFRESH
         * Find the current vehicle in the localStorage
         * If not found add the current vehicle into the VehicleWorksheetRefresh
         * Else use same list
         * 
         */
        if(type !== null) {
          let currentVhcRefreshList = [];
          currentVhcRefreshList = JSON.parse(localStorage.getItem('VehicleWorksheetRefresh'));
          let vhcRefreshList = [];
          if(currentVhcRefreshList) {
            const vehicleExist = currentVhcRefreshList.find(item => {
              return item.vehicleId === id;
            });
            if(!vehicleExist) {
              currentVhcRefreshList.push({vehicleId: id, status: type, vehicleIndex: assetIndex, dealId: self.dealId, permissionNameArray: self.permissionNameArray});
              vhcRefreshList = currentVhcRefreshList;
            } else {
              vhcRefreshList = currentVhcRefreshList;
            }
          } else {
            vhcRefreshList.push({vehicleId: id, status: type, vehicleIndex: assetIndex, dealId: self.dealId, permissionNameArray: self.permissionNameArray});
          }
          localStorage.setItem('VehicleWorksheetRefresh', JSON.stringify(vhcRefreshList));
        }
        /** END */

        if (type == null && self.dealMapperId == 0) {
          self.showTrnsLoader = true;
          await self.saveAndCloseDeal();
          self.showVehicleWorkSheet(type);
        } else {
          if(self.isValidForm('vehicle')) {
            self.showVehicleWorkSheet(type,assetIndex);
          } else {
            self.scrollToTop();
            self.showTrnsLoader = false;
          }
        }
      } else {
        self.showTrnsLoader = false;
      }
    },

    showVehicleWorkSheet(type,assetIndex=null) {
      if(self.dealId !== '') {
        self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
        if (type == "edit") {
          self.isVehicleEdit = true;
        }
      }
      self.showTrnsLoader = false;
      if(self.assetArr.length > 0) {
        let usedVinList = self.assetArr.map(asset => asset.vin);
        let usedUnitList = self.assetArr.map(asset => asset.unit);
        if(type==="edit" && assetIndex != null) {
          const vin = self.assetArr[assetIndex].vin;
          const unit = self.assetArr[assetIndex].unit;
          usedVinList = usedVinList.filter(usedVin => usedVin !== vin );
          usedUnitList = usedUnitList.filter(usedStock => usedStock !== unit && usedStock !== null);
        }
        self.usedVinList = usedVinList;
        self.usedUnitList = usedUnitList;

      }
      
      if((self.assetArr.length === 0) || (self.assetArr.length === 1 && type == "edit")) {
        self.vehicleType = "";
        return true;
      }
      self.vehicleType = self.assetArr[0].new_used;
    },
    /*##### End Vehicle Information Section #####*/

    /*##### SAVE TRANSACTION-SHEET */
    saveAndCloseDeal: function () {
      return new Promise((resolve, reject) => {
        if(self.isValidForm()) {
            self.showTrnsLoader = true;
            setTimeout(() => {
              self.saveDeal()
              .then(() => resolve())
              .catch((error) => reject(error));
            }, 200);
        } else {
          if(self.isLienHolder=="yes" && self.locationError=="") {
            self.scrollToBottom();
          } else {
            self.scrollToTop();
          }
          self.showTrnsLoader = false;
        }
      })
    },

    saveDeal() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        let data = self.returnFormDataForSave();
      
        HTTP.post("/save/deal-data", data, config)
          .then(function (response) {
            if (response.data.code == 200) {
              self.executeResponse(response)
              .then(() => resolve())
              .catch((error) => reject(error));
            }
          })
          .catch(function (err) {
            console.log("Save TransactionSheet Error>>>", err);
            self.catchHandler(err, function () {});
            self.showTrnsLoader = false;
            reject(err);
          });
      });
    },

    returnFormDataForSave: function () {
      /*#### Removed after reintroducing the "CASH" option ####
      let cashData = {};
      if (self.tab == "cash") {
        cashData = {
          id: self.cashId == 0 ? 0 : self.cashId,
          sales_price: parseFloat(self.salesPrice).toFixed(2),
          down_payment: parseFloat(self.downpaymentAmt).toFixed(2),
          trade_allowance_inc_gst: parseFloat(self.tradeAllownce).toFixed(2),
          total_fees: parseFloat(self.totalFees).toFixed(2),
          total_sales_tax: parseFloat(self.totalSalesTax).toFixed(2),
          insurance: parseFloat(self.insurance).toFixed(2),
          total_plus_warranties: parseFloat(self.totalPlusWarranties).toFixed(
            2
          ),
        };
      }
      */

      let formData = {
        /*##### Lease & Finance Tab #####
          lease_data: self.tab == 'lease' ? self.leaseData : [],
          finance_data: self.tab == 'finance' ? self.financeData : [],
        */
       /*#### Removed after reintroducing the "CASH" option ####
        cash_data: self.tab == "cash" ? cashData : [],
        old_tab: self.oldTab,
        old_tab_id: self.oldTabId,
        */
        dealsData: {
          location_id: self.checkNull('locationId'),
          salesperson_id: self.checkNull('salePersonId'),
          county_id: self.checkNull('county'),
          contact_id: self.checkNull('customerId'),
          co_buyer_contact_id: self.coBuyerCusId,
          del_to_contact_id: self.deliverToCusId,
          
          opportunity_id: self.opportunityId,
          order_type_id: self.checkNull('orderType'),

          down_payment_on_order: parseFloat(self.downPaymentOnOrder).toFixed(
            2
          ),
          down_payment_on_order_id: self.checkNull('downpaymentPaymentType'),
          down_payment_info: self.downPaymentInfo,

          cash_back: parseInt(self.cashBack).toFixed(2),
          cash_back_type_id: self.cashBackPaymentType
            ? self.cashBackPaymentType.value
            : null,
          cash_back_info: self.cashBackInfo,

          due_payment: parseInt(self.dueOnDelivery).toFixed(2),
          due_payment_id: self.checkNull('dueOnDeliveryPaymentType'),
          due_payment_info: self.duePaymentInfo,

          tax_rate: parseFloat(self.taxRate),
          state_tax_rate: parseFloat(self.stateTaxRate),
          warranty_tax_rate: parseFloat(self.warrantyTax),

          order_payment_method: self.paymentCat,
        },

        dealsMapperData: {
          deal_no: self.dealNo,
          invoice_number: self.invoiceNo,
          id: self.dealMapperId,
          contract_number: self.contractNumber,
          contract_date: self.invoiceDate,
          cus_phone: self.primaryPhone,
          cus_fax: self.cusFax,
          cus_cell: self.cusCell,
          cus_address: self.address,
          city_name: self.city,
          zip_code: self.zipCode,
          state_code: self.stateCode,
          customer_email: self.email,
          district_or_county: self.districtOrCounty,
          internal_comments: self.internalComments,
          comments: self.comments,
          comments2: self.bookingComments,
          is_forecast: self.addToForecast ? "yes" : "no",
          county: self.countyName,
          county_code: (self.county == '' || self.county == null || self.county == undefined) ? null : self.county.name,
          apply_sales_tax: self.applySalesTax ? "yes" : "no",
          show_on_buyer_order: self.showOnBuyersOrder ? "yes" : "no",
          is_lien_holder: self.isLienHolder,
          co_buyer: self.coBuyer,
          co_buyer_cus_address: self.coBuyerAddress,
          co_buyer_cus_address2: self.coBuyerAddressTwo,
          co_buyer_city2: self.coBuyerCity,
          del_to_cus_address: self.deliverToAddress,
          del_to_city2: self.deliverToCity,
        },

        usDealsMapperData: {
          co_buyer_state_code: self.coBuyerStateCode,
          co_buyer_zip_code: self.coBuyerZipCode,
          co_buyer_phone: self.coBuyerPhone,
          co_buyer_phone: self.coBuyerPhone,
          co_buyer_excede_cus_id: self.coBuyerExcedeCusId,
          is_dba: self.isDBA,
          del_to_name: self.deliverToName,
          del_to_state_code: self.deliverToStateCode,
          del_to_zip_code: self.deliverToZipCode,
          del_to_excede_cus_id: self.deliverToExcedeCusId,
        },

        payment_data: self.getPyamentData(),
        lien_holder_data: self.isLienHolder=="yes" ? {
          id: self.lienHolderId,
          line_holder_name: self.lineHolderName,
          line_holder_address: self.lineHolderAddress,
          line_holder_city: self.lineHolderCity,
          line_holder_zip: self.lineHolderZip,
          line_holder_state_id: self.lineHolderState.value,
        } : {},

        vehiclePriceCalculationData: {
          id: self.cashId == 0 ? 0 : self.cashId,
          sales_price: parseFloat(self.salesPrice).toFixed(2),
          down_payment: parseFloat(self.downpaymentAmt).toFixed(2),
          trade_allowance_inc_gst: parseFloat(self.tradeAllownce).toFixed(2),
          total_fees: parseFloat(self.totalFees).toFixed(2),
          total_sales_tax: parseFloat(self.totalSalesTax).toFixed(2),
          insurance: parseFloat(self.insurance).toFixed(2),
          total_plus_warranties: parseFloat(self.totalPlusWarranties).toFixed(
            2
          ),
        }
        /*#### Removed after reintroducing the "CASH" option ####
        payment_data: self.paymentCat == "finance" ? self.financeData : self.leaseData,
        */
      };
      if (
        self.dealId !== null &&
        self.dealId !== 0 &&
        typeof self.dealId !== "undefined"
      ) {
        formData.id = self.isEdit ? parseInt(self.dealId) : self.dealId;
      }
      return formData;
    },

    executeResponse(response) {
      return new Promise((resolve, reject) => {
        if (!self.print_document_flag) {
          self.showSAlert(
            self.dealMapperId == 0
              ? "Deal Created Successfully!"
              : "Deal Updated Successfully!",
            response.data.status,
            false,
            2
          );
        }
        const deal_id = response.data.content.id;
        if(self.dealId === "") {
          self.$router.push({ path: self.$route.path, query: { deal_id } });
        }
        self.dealId = deal_id;
        localStorage.setItem("dealId", response.data.content.id);
        self.dealMapperId = response.data.content.dealsmapper_id;
        self.dealNo = response.data.content.deal_no;
        self.invoiceNo = response.data.content.invoice_number;
        
        /*#### Removed after reintroducing the "CASH" option ####
        self.cashId = response.data.content.cash_id; 
        */

        if (response.data.content.payment_method_id !== null) {
          switch (self.paymentCat) {
            case "finance":
              self.financeData["id"] = response.data.content.payment_method_id;
              break;
            case "lease":
              self.leaseData["id"] = response.data.content.payment_method_id;
              break;
            default:
              self.cashId = response.data.content.payment_method_id;  //Added after reintroducing the "CASH" option.
              break;
          }
          self.triggerPayment = !self.triggerPayment;
        }

        if (response.data.content.lien_holder_id !== null) {
          self.lienHolderId = response.data.content.lien_holder_id;
        }

        self.showTrnsLoader = false;
        if (self.print_document_flag) {
          let data = [];
          data.deal_id = self.dealId;
          data.vehicles = [];
          self.assetArr.forEach(function (vehicle, index) {
            let vehicle_data = [];
            vehicle_data["name"] = "";
            if (vehicle.asset) {
              if (vehicle.asset.model_type) {
                vehicle_data["name"] =
                  vehicle.asset.unit +
                  " - " +
                  vehicle.asset.model_type.name;
              } else {
                vehicle_data["name"] = vehicle.asset.unit;
              }
            } else {
              vehicle_data["name"] = vehicle.vin;
            }
            vehicle_data["deal_inventory_mapper_id"] = vehicle.id;
            data.vehicles.push(vehicle_data);
          });
          self.print_document_flag = false;
          EventBus.$emit("print-doc-options-popup-open", data);
        }

        resolve();
      });
    },
    /*##### END #####*/

    closeDeal() {
      localStorage.removeItem("dealId");
      localStorage.removeItem("opportunityId");
      localStorage.removeItem("dealInventoryMapperId");
      localStorage.removeItem("vehicleId");
      localStorage.removeItem("referenceNo");
      localStorage.removeItem("addToForecast");
      self.dealMapperId > 0
        ? self.$router.push("/deal/vehicle/list")
        : self.$router.push("/opportunity/list");
    },

    /*##### RETURN FROM VEHICLE-WORKSHEET #####*/
    calculateSalesTaxWarranties(taxAmount, warrantyAmount, warranties) {
      self.totalSalesTax += parseFloat(taxAmount) + parseFloat(warrantyAmount);
      self.warranties = warranties;
      self.warrantyFromVehicleWorksheet = warrantyAmount;
    },

    getAssetDataFromId: function () {
      if (
        self.assetId !== null &&
        self.assetId !== undefined &&
        self.assetId !== 0
      ) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
          params: {
            'edit': false,
          }
        };
        HTTP.get("deal/"+self.dealId+"/inventory/mapper", config)
          .then(function (response) {
            self.salesPrice = response.data.totalSalesPrice;
            self.tempRetailPrice = response.data.totalSalesPrice;
            self.totalFees = response.data.calculation.total_fees;
            self.totalSalesTax = response.data.calculation.total_sales_tax;
            self.warranties = response.data.calculation.warranties;
            self.warrantyFromVehicleWorksheet =
            response.data.calculation.warranty_from_vehicle_worksheet;
            self.assetArr = response.data.content;
            if (self.assetArr.length > 0) {
              self.assetArrHasValue = true;
            }
            /*#### The salesman must manually enter this information and amount should be at the deal level and not comprised of individual VIN line items (Client:2023/11/30) ####
            self.downpaymentAmt = response.data.calculation.downpayment;
            self.downPaymentOnOrder = response.data.calculation.downpayment;
            */
            self.tradeDetailList =
              response.data.tradeDetailList == null
                ? []
                : response.data.tradeDetailList;
            if (self.tradeDetailList.length > 0) {
              self.expandCollapse3 = "vhc-expand";
              self.expandCollapseImg3 = "rotate-accordian-expand";
            } else {
              self.expandCollapse3 = "vhc-collapse";
              self.expandCollapseImg3 = "rotate-accordian-collapse";
            }
            self.tradeAllownce = response.data.calculation.trade_allowance;
            self.calculateQuot();
          })
          .catch(function (error) {
            self.showTrnsLoader = false;
            self.catchHandler(error, function () {});
          });
      }
    },

    calculateQuot() {
      self.salesPrice = self.convertToNum(parseFloat(self.salesPrice), 2);
      self.gross_profit = self.convertToNum(parseFloat(self.gross_profit), 2);
      self.downpaymentAmt = self.convertToNum(
        parseFloat(
          !self.numberFormateValidation(self.downpaymentAmt)
            ? 0
            : self.downpaymentAmt
        ),
        2
      );

      self.totalFees = self.convertToNum(
        parseFloat(
          !self.numberFormateValidation(self.totalFees) ? 0 : self.totalFees
        ),
        2
      );
      self.insurance = self.convertToNum(
        parseFloat(
          !self.numberFormateValidation(self.insurance) ? 0 : self.insurance
        ),
        2
      );
      self.tradeAllownce = self.convertToNum(
        parseFloat(
          !self.numberFormateValidation(self.tradeAllownce)
            ? 0
            : self.tradeAllownce
        ),
        2
      );
      const sumPrice = parseFloat(self.salesPrice) + parseFloat(self.totalSalesTax) + parseFloat(self.totalFees) + parseFloat(self.adminFee);
      const paidPrice = parseFloat(self.tradeAllownce);
      const totalLeft = parseFloat(sumPrice) - parseFloat(paidPrice);
      self.total = self.convertToNum(totalLeft,2);
      self.totalPlusWarranties = self.convertToNum(
        parseFloat(self.total) +
          parseFloat(self.warranties) +
          parseFloat(self.insurance),
        2
      );

      self.dueonpaymentAmt = self.convertToNum(
          parseFloat(self.totalPlusWarranties) -
          parseFloat(self.cashBack) -
          parseFloat(self.dueOnDelivery) -
          parseFloat(self.downpaymentAmt),
        2
      );

    },
    /*##### END #####*/

    /*##### EDIT TRANSACTION-SHEET */
    editTransactionSheet: function (dealId) {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: dealId,
        },
      };

      HTTP.get("/deal/edit", config)
        .then(function (data) {
          if (data.status == 200) {
            let deals = data.data.content;
            self.salePersonId = deals.sales_person;
            self.locationId = deals.branch;
            self.county = deals.deal_mapper !== null ? {name: deals.deal_mapper.county_code,value: deals.county_id,} : "";
            self.opportunityId = deals.opportunity_id;
            self.oppNo = deals.opportunity.opp_no;
            self.customerId = deals.contact_id;
            self.customerName = deals.customer_detail.text;
            self.excedeCustomerId = deals.customer_detail.customerId;

            self.flagCustomerPopulate = 2;
            self.orderType = deals.order_type;
            self.downPaymentOnOrder = deals.down_payment_on_order;
            self.downpaymentPaymentType = deals.deposit_payment;
            self.downPaymentInfo = deals.down_payment_info;
            self.cashBack = deals.cash_back;
            self.cashBackPaymentType = deals.cash_payment;
            self.cashBackInfo = deals.cash_back_info;
            self.dueOnDelivery = deals.due_payment;
            self.dueOnDeliveryPaymentType = deals.due_on_delivery_payment;
            self.duePaymentInfo = deals.due_payment_info;
            self.taxRate = deals.tax_rate;
            self.stateTaxRate = parseFloat(deals.state_tax_rate);
            self.warrantyTax = parseFloat(deals.warranty_tax);

            if (deals.deal_mapper) {
              let dealMapper = deals.deal_mapper;
              self.dealNo = dealMapper.deal_no;
              self.invoiceNo = dealMapper.invoice_number;
              self.dealMapperId = dealMapper.id;
              self.contractNumber = dealMapper.contract_no;
              self.invoiceDate = moment(
                String(dealMapper.contract_date)
              ).format("MM/DD/YYYY");
              self.primaryPhone = dealMapper.cus_phone;
              self.cusFax = dealMapper.cus_fax;
              self.cusCell = dealMapper.cus_cell;
              self.address = dealMapper.cus_address;
              self.city = dealMapper.city_name;
              self.stateCode = dealMapper.state_code;
              self.zipCode = dealMapper.zip_code;
              self.email = dealMapper.customer_email;
              self.districtOrCounty = dealMapper.district_or_county;

              self.internalComments = dealMapper.internal_comments;
              self.comments = dealMapper.comments;
              self.bookingComments = dealMapper.comments2;
              self.addToForecast = self.returnBoolean(dealMapper.is_forecast);
              self.countyName = dealMapper.county;
              self.applySalesTax = self.returnBoolean(dealMapper.apply_sales_tax);
              self.showOnBuyersOrder = self.returnBoolean(dealMapper.show_on_buyer_order);
              self.isLienHolder = dealMapper.is_lien_holder;
              self.coBuyer = dealMapper.co_buyer;
              self.coBuyerAddress = dealMapper.co_buyer_cus_address;
              self.coBuyerAddressTwo = dealMapper.co_buyer_cus_address2;
              self.coBuyerCity = dealMapper.co_buyer_city2;
              self.deliverToAddress = dealMapper.del_to_cus_address;
              self.deliverToCity = dealMapper.del_to_city2;

              if(dealMapper.is_lien_holder == "yes") {
                self.getLienHolderData(dealMapper.id);
              }
            }

            if(deals.us_deal_mapper) {
              let usDealMapper = deals.us_deal_mapper;
              self.coBuyerStateCode = usDealMapper.co_buyer_state_code;
              self.coBuyerZipCode = usDealMapper.co_buyer_zip_code; 
              self.coBuyerPhone = usDealMapper.co_buyer_phone; 
              self.coBuyerExcedeCusId = usDealMapper.co_buyer_excede_cus_id;
              self.isDBA = usDealMapper.is_dba; 
              self.deliverToName = usDealMapper.del_to_name; 
              self.deliverToStateCode = usDealMapper.del_to_state_code; 
              self.deliverToZipCode = usDealMapper.del_to_zip_code; 
              self.deliverToExcedeCusId = usDealMapper.del_to_excede_cus_id;
            }

            if(self.coBuyer !== null) {
              self.expandCollapse('expandCollapse1', 1);
            }

            if(self.deliverToName) {
              self.expandCollapse('expandCollapse2', 2);
            }

            self.isEdit = true;
            self.isCountyEdit = true;
            if (deals.deal_inventory) {
              self.isEditVehicle = true;
              /*##### THIS CONDITION TO BE REFACTOR LATER #####*/
              self.assetId = deals.deal_inventory.id
                ? deals.deal_inventory.id
                : 0;
              /*##### #####*/
              self.getAssetDataFromId();
            }
            self.vehicleData = deals.deal_inventory;
            self.paymentCat = deals.order_payment_method;
            if(deals.order_payment_method!="cash") {
              self.isLienHolderCheckboxVisible = true;
            }
            self.dealStatusSlug = deals.deal_status.slug;
            self.changeDealLevel(deals.deal_status.slug);
            self.getPaymentOrderMethodData(dealId, deals.order_payment_method);
          }
        })
        .catch(function (error) {
          self.showTrnsLoader = false;
          self.catchHandler(error, function () {});
        });
    },

    getPaymentOrderMethodData(id, pType) {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          dealId: id,
          paymentMethod: self.tab,
          paymentCat: pType,
        },
      };

      HTTP.get("/deal/order-payment-method", config)
        .then(function (data) {
          if (data.status == 200) {
            let cashData = data.data.content.cash;
            let financeData = data.data.content.finance_data;
            let leaseData = data.data.content.lease_data;

            /*##### CASH DATA #####*/
            if(cashData != null) {
              self.cashId = cashData.id;
              self.cashData = cashData;
              self.salesPrice =
                parseFloat(cashData.sales_price) > 0
                  ? parseFloat(cashData.sales_price)
                  : self.salesPrice; /* the condition is applied in the case when vehicle is added but deal is not updated, refactor salesPrice on watch */
              self.downpaymentAmt = parseFloat(cashData.down_payment);
              self.tradeAllownce = parseFloat(cashData.trade_allowance_inc_gst);
              self.totalFees =
                parseFloat(cashData.total_fees) > 0
                  ? parseFloat(cashData.total_fees)
                  : self.totalFees; /* the condition is applied in the case when vehicle is added but deal is not updated */
              self.totalSalesTax = parseFloat(cashData.total_sales_tax);
              self.insurance = parseFloat(cashData.insurance);
              self.totalPlusWarranties = parseFloat(
                cashData.total_plus_warranties
              );

              self.calculateQuot();
            }
            /*##### END CASH DATA #####*/

            /*##### FINANCE DATA #####*/
            self.financeData = financeData != null ? financeData : {};
            /*##### END FINANCE DATA #####*/

            /*##### LEASE DATA #####*/
            self.leaseData = leaseData != null ? leaseData : {};
            /*##### END LEASE DATA #####*/

            /*##### OLD CODE #####
              if (paymentData !== null) {
                self.oldTab = self.tab;
                self.oldTabId = paymentData.id;
                if (self.tab == "lease") {
                  self.leaseData = paymentData;
                } else if (self.tab == "finance") {
                  self.financeData = paymentData;
                } else {
                  self.cashId = paymentData.id;
                  self.cashData = paymentData;
                  self.salesPrice =
                    parseInt(paymentData.sales_price) > 0
                      ? parseInt(paymentData.sales_price)
                      : self.salesPrice; // the condition is applied in the case when vehicle is added but deal is not updated, refactor salesPrice on watch
                  self.downpaymentAmt = parseInt(paymentData.down_payment);
                  self.tradeAllownce = parseInt(
                    paymentData.trade_allowance_inc_gst
                  );
                  self.totalFees =
                    parseInt(paymentData.total_fees) > 0
                      ? parseInt(paymentData.total_fees)
                      : self.totalFees; // the condition is applied in the case when vehicle is added but deal is not updated
                  self.totalSalesTax = parseInt(paymentData.total_sales_tax);
                  self.insurance = parseInt(paymentData.insurance);
                  self.totalPlusWarranties = parseInt(
                    paymentData.total_plus_warranties
                  );
                }
              }
            */
          }
        })
        .catch(function (error) {
          self.showTrnsLoader = false;
          self.catchHandler(error, function () {});
        });
    },

    getLienHolderData(dealMapperId) {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      HTTP.get("/lien-holder/"+dealMapperId, config)
        .then(function (data) {
          if (data.status == 200 && data.data.content != null) {
            const getData = data.data.content;
            self.lienHolderId = getData.id;
            self.lineHolderName = getData.name;
            self.lineHolderAddress = getData.address;
            self.lineHolderCity = getData.city;
            self.lineHolderZip = getData.zip_code;
            self.lineHolderState = getData.state;
          }
          return true;
        })
        .catch(function (error) {
          self.showTrnsLoader = false;
          self.catchHandler(error, function () {});
        });
    },
  
    /*##### END #####*/

    /*##### POPULATE DEFAULT CUSTOMER DATA ON MOUNT #####*/
    onSearchDefaultCustomer() {
      const opportunityId = self.opportunityId;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: opportunityId,
        },
      };
      HTTP.get("/get/default/customer", config)
        .then(function (response) {
          const data = response.data.content;
          self.populateCustomerDefaultData(data);
        })
        .catch(function (err) {
          console.log("Default Customer", err);
          self.showTrnsLoader = false;
        });
    },

    populateCustomerDefaultData(data) {
      // Note: refactor the flagCustomerPopulate with better option
      // console.log('currentCustomerData', self.currentCustomerData, self.flagCustomerPopulate, data);
      if (self.flagCustomerPopulate == 0 && data !== null) {
        if(data.is_booking_deal && data.locId !== null) {
          self.locationId = {
            excedeLocId: data.excedeLocId,
            locid: data.locId,
            text: data.locName,
            value: data.locValue,
          }
        }
        if (self.currentCustomerData === false) {
          self.customerId = data; //instead pass only text and value as an object
          if (data.county_id !== null) {
            self.county = {
              name: data.county_name,
              value: data.county_id,
              text: data.county_code,
              rate: data.county_rate,
            };
            self.preFillTaxInfo();

            self.countyArr[data.county_code] = data.county_rate;
          } else {
            self.county = {
              name: "EXEMPT",
              value: 1104,
              text: "EX",
            };
            self.countyArr["EX"] = 0;
          }

          self.salePersonId = {
            text: data.salesPersonName,
            value: data.salesPersonId,
          };

          self.populateCustomerData(data);
        } else {
          self.currentCustomerData = false;
        }
      } else {
        self.flagCustomerPopulate -= 1;
      }
      return true;
    },
    /*##### END #####*/

    /*##### PRINT #####*/
    exportToPdf() {
      self.showPrintDocPopup = true;
      //as per discussion need to call save(update) before print documents.
      self.print_document_flag = true;
      self.saveAndCloseDeal();
      /*#### COMMENTED #####
        $("#printQuotBtn").attr("disabled", true);

        // let data = self.returnFormDataForSave();

        var config = {
            headers: {
                Authorization:"Bearer " + localStorage.getItem("api_token")
            },
            params : {
                // data:data,
                deal_id: self.dealId,
                // saleperson_id: self.salePersonId.value,
                // customer_id: self.customerId.value,
            }
        };
        HTTP.get("/export_pdf", config)
            .then(function(response) {
                if (response.status == "200") {
                    if(response.data.code == "200"){
                        // window.open(process.env.ROOT_API + '/s3/' + response.data.content);
                        window.open(response.data.content);
                    }else{
                        console.log('err', response.data.message);
                    }
                }
                $("#printQuotBtn").attr("disabled", false);
            })
            .catch(function(err) {
                console.log('err', err)
                if (err.response.status == 0) {
                    self.error = "Remote server can not be reachable";
                } else {
                    self.error = err.response.data.message;
                }
                $("#printQuotBtn").attr("disabled", false);
            });
      */
    },
    /*##### END #####*/

    /*##### ACCORDION #####*/
    expandCollapse: function (accordianPosition, positionNumber) {
      this[accordianPosition] =
        this[accordianPosition] == "vhc-collapse"
          ? "vhc-expand"
          : "vhc-collapse";

      let expandCollapseImg = "expandCollapseImg" + positionNumber;
      this[expandCollapseImg] =
        this[accordianPosition] == "vhc-collapse"
          ? "rotate-accordian-collapse"
          : "rotate-accordian-expand";
      return true;
    },

    /*##### PRICE SECTION CHECKUNCHECK INSTEAD TAB #####*/
    checkUncheck: function (type) {
      self.paymentCat = type;
      if(type==="cash") {
        self.isLienHolderCheckboxVisible = false;
      } else {
        self.isLienHolderCheckboxVisible = true;
      }
      self.scrollToBottom();
    },

    addFinanceCompany() {
      self.isClickAddFinanceCompany = true;
      self.addFinanceCompanyInstance.show();
    },

    deleteDeal: function () {
      if (
        self.dealId != "" &&
        self.permissionNameArray.includes("can_delete_deals")
      ) {
        let deleteArray = [];
        deleteArray.push(self.dealId);
        self
          .$swal({
            title: "",
            text: "Are you sure that you want to delete this Deal!",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            // showCloseButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data: {
                  deleteArr: deleteArray,
                },
              };
              HTTP.delete("/deal/delete", config)
                .then(function (response) {
                  if (response.data.code == "200") {
                    self.closeDeal();
                  }
                })
                .catch(function (error) {
                  console.log("ErrorList!!! 364", error);
                  self.catchHandler(error, function () {});
                });
            }
          });
      }
    },

    returnBoolean(value) {
      let status = false;
      if(value == "yes") {
        status = true;
      }
      return status; 
    },

    checkNull(dataInstance) {
      let data = this[dataInstance];
      if(data == '' || data == null || data == undefined) {
        data = null;
      }

      if(data != null) {
        if(dataInstance == 'customerId') {
          data = this[dataInstance];
        } else {
          data = this[dataInstance].value;
        }
      }
      return data;
    },

    addDownPayment() {
      self.downpaymentAmt = self.downPaymentOnOrder;
      self.calculateQuot();
    },

    addDueOnDelivery() {
      self.calculateQuot();
    },

    getPyamentData() {
      switch (self.paymentCat) {
        case "finance":
          return self.financeData;
        case "lease":
          return self.leaseData;
        default:
          return false;
      }
    },

    onSearchState(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.loadAllData(
          ["States"],
          true,
          this.loadStateDataCallback,
          loading,
          search
        );
      }
    },

    loadStateDataCallback: function (callbackData) {
      this.isStateRecordNotFound = false;
      this.lineHolderStateArr = callbackData.States;
      if(this.lineHolderStateArr.length == 0) {
          this.isStateRecordNotFound = true;
      }
    },

    checkLienHolder(event) {

      if(event.target.checked) {
        this.isLienHolder = "yes";
      } else {
        this.isLienHolder = "no";
      }

      if(this.isLienHolder == "yes") {
        this.lineHolderName ="";
        this.lineHolderNameError ="";
        this.lineHolderAddress ="";
        this.lineHolderAddressError ="";
        this.lineHolderCity ="";
        this.lineHolderCityError ="";
        this.lineHolderState ="";
        this.lineHolderStateError ="";
        this.lineHolderZip ="";
        this.lineHolderZipError ="";
        this.lineHolderStateArr =[];
        if(self.dealId !== '') {
          self.getLienHolderData(self.dealMapperId);
        }
      }

      self.scrollToBottom();
    },
    clearErrorAndValidate() {
      // Clear the error when typing
      self.stateCodeError = '';

        // Automatically convert input to uppercase and prevent numerical or special characters
        self.stateCode = self.stateCode.replace(/[^a-zA-Z]/g, '').toUpperCase();

      // Validate input after clearing error
      self.validateStateCode();
    },

    validateStateCode() {
      if (self.stateCode.length === 1) {
        self.stateCodeError = "Please enter two alphabetic characters in this field."; // Error for 1 character
      } else {
        self.stateCodeError = ''; // Clear the error if valid
      }
    },

    isValidForm(type=null) {
      if (self.stateCodeError) {
      // Prevent form submission if there is an error
       return;
      }

      let valid = true;

      if (self.locationId == "" || self.locationId == null || self.locationId == undefined) {
        self.locationError = "This field is required!";
        if (valid) {
            valid = false;
        }
      } else {
          self.locationError = '';
      }

      if(type==null) {
        if(this.isLienHolder == "yes") {
          if (self.lineHolderName == "") {
              self.lineHolderNameError = "This field is required!";
              if (valid) {
                  valid = false;
              }
          } else {
              self.lineHolderNameError = '';
          }

          if (self.lineHolderAddress == "") {
              self.lineHolderAddressError = "This field is required!";
              if (valid) {
                  valid = false;
              }
          } else {
              self.lineHolderAddressError = '';
          }

          if (self.lineHolderCity == "") {
              self.lineHolderCityError = "This field is required!";
              if (valid) {
                  valid = false;
              }
          } else {
              self.lineHolderCityError = '';
          }

          if (self.lineHolderZip == "") {
              self.lineHolderZipError = "This field is required!";
              if (valid) {
                  valid = false;
              }
          } else {
              self.lineHolderZipError = '';
          }

          if (self.lineHolderState == "" || self.lineHolderState == null || self.lineHolderState == undefined) {
              self.lineHolderStateError = "This field is required!";
              if (valid) {
                  valid = false;
              }
          } else {
              self.lineHolderStateError = '';
          }
        }
      }

      return valid;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        window.scrollTo(
          {
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth"
          }
        );
        return false;
      });
    },

    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo(
          {
            top: 0,
            left: 0,
            behavior: "smooth"
          }
        );
        return false;
      });
    },

    showVhcDetail(vin) {
      self.isClickVehicleDetail = true;
      self.vinNo = vin;
      self.vehicleDetailModalInstance.show();
    },

    createDealLevelRequest(requestedDealStatusSlug) {
      self.isClickDealLevelRequest = true;
      self.requestedDealStatusSlug = requestedDealStatusSlug;
      let vehicleData = '';
      self.assetArr.forEach((asset) => {
        vehicleData += `
          <p><strong>Vehicle ${asset.vin}:</strong></p>
          <ul>
            <li>Stock#: ${asset.unit}</li>
            <li>Year: ${asset.year}</li>
            <li>Make: ${asset.manufacturer}</li>
            <li>Model: ${asset.model}</li>
            <li>Fees: ${asset.fees}</li>
            <li>Sales Price: ${asset.base_sales_price}</li>
          </ul>
          <p>
        `;
      });

      self.dlrDescription = `
          <p><strong>Buyer: </strong>${self.customerName},<br /> <strong>Deal No: </strong>${self.dealNo},<br /> <strong>Sales Person: </strong>${self.salePersonId.text}</p>
          ${vehicleData}
          `;
      self.mailModalInstance.show();
    },

    changeDealLevel(dealLevel) {
      switch(dealLevel) {
        case 'po-request':
          self.dealLevel = self.allLabelMessages.dl_po_request;
          break;
        case 'doc-request':
          self.dealLevel = self.allLabelMessages.dl_doc_request;
          break;
        case 'manager':
          self.dealLevel = self.allLabelMessages.dl_manager;
          break;
        case 'accounting':
          self.dealLevel = self.allLabelMessages.dl_accounting;
          break;
        case 'lost':
          self.dealLevel = self.allLabelMessages.dl_lost;
          break;
        default:
          self.dealLevel = self.allLabelMessages.dl_sales;
          break;
      }
    },

    mailHistory() {
      self.isClickMailHistory = true;
      self.mailHistoryModalInstance.show();
    },

    /**
    * REMOVE CURRENTLY CLOSED VEHICLE WORKSHEET PAGE FROM VehicleWorksheetRefresh localStorage
    */
    removeClosedVehicleRefreshList() {
      let currentVhcRefreshList = JSON.parse(localStorage.getItem('VehicleWorksheetRefresh'));
      if(currentVhcRefreshList && currentVhcRefreshList.length > 0) {
        const vhcRefreshList = currentVhcRefreshList.filter(item => {
          return item.vehicleId !== self.editAssetId;
        });
        localStorage.setItem('VehicleWorksheetRefresh', JSON.stringify(vhcRefreshList));
      }
    }
  },
};

EventBus.$on("close-search-customer", function (status) {
  if (typeof self != "undefined") {
    self.isClickSearchModal = status;
    self.customerSearchModalInstance.hide();
  }
});

EventBus.$on("get-search-customer", function (data) {
  if (typeof self != "undefined") {
    self.isClickSearchModal = false;
    self.customerSearchModalInstance.hide();
    self.populateCustomerData(data);
  }
});

EventBus.$on("close-search-county", function (status) {
  if (typeof self != "undefined") {
    self.isClickCountySearchModal = status;
    self.countySearchModalInstance.hide();
  }
});

EventBus.$on("get-search-county", function (data) {
  if (typeof self != "undefined") {
    self.isClickCountySearchModal = false;
    self.countySearchModalInstance.hide();
    self.populateCountyData(data);
  }
});

EventBus.$on(
  "cash-details-data",
  function (taxAmount, warrantyAmount, warranties) {
    if (typeof self != "undefined") {
      self.calculateSalesTaxWarranties(taxAmount, warrantyAmount, warranties);
    }
  }
);

EventBus.$on("delete-vheicle-worksheet", function (data, vheicleId) {
  self.isVehicleEdit = false;
  self.removeClosedVehicleRefreshList();
  self.salesPrice = data.totalSalesPrice;
  self.tempRetailPrice = data.totalSalesPrice;
  self.totalFees = data.calculation.total_fees;
  self.totalSalesTax = data.calculation.total_sales_tax;
  self.warranties = data.calculation.warranties;
  let updateAssetArr = self.assetArr.filter((el) => el.id !== vheicleId);
  self.showSAlert(data.message, "success", false, 2);
  self.assetArr = updateAssetArr;
  self.calculateQuot();
  self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
});

EventBus.$on("add-financial-company", () => {
  if (typeof self != "undefined") {
    self.addFinanceCompany();
  }
});

EventBus.$on("close-add-financial-company", function (status) {
  if (typeof self != "undefined") {
    self.isClickAddFinanceCompany = status;
    self.addFinanceCompanyInstance.hide();
  }
});

EventBus.$on(
  "trns-callback-after-saving",
  function (callbackDealInventoryMapperId, callbackAssetId) {
    self.removeClosedVehicleRefreshList();
    self.vehicleType = "";
    self.isVehicleEdit = false;
    self.triggerPayment = !self.triggerPayment;
    try {
      self.dealInventoryMapperId = callbackDealInventoryMapperId;
      self.assetId = callbackAssetId;
      self.isEventBus = true;
      self.currentCustomerData = true;
      self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
      self.getAssetDataFromId();
    } catch (e) {
      console.log("CloseSaveError>>", e);
    }
  }
);

EventBus.$on("emit-finance-data", function (data) {
  if (typeof self != "undefined") {
    self.financeData = data;
  }
});

EventBus.$on("emit-lease-data", function (data) {
  if (typeof self != "undefined") {
    self.leaseData = data;
  }
});

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];

    for(let permission of permissions) {
      self.permissionNameArray.push(permission.permission_name);
    }
  }
});

EventBus.$on("close-vehicle-detail", function (status) {
  if (typeof self != "undefined") {
    self.vinNo = "";
    self.isClickVehicleDetail = status;
    self.vehicleDetailModalInstance.hide();
  }
});
EventBus.$on("close-mail-modal", function (isMailSend) {
  if (typeof self != "undefined") {
    self.isClickDealLevelRequest = false;
    self.mailModalInstance.hide();
    if(isMailSend) {
      self.dealStatusSlug = self.requestedDealStatusSlug;
      self.changeDealLevel(self.requestedDealStatusSlug);
    }
  }
});
EventBus.$on("close-mail-history", function () {
  if (typeof self != "undefined") {
    self.isClickMailHistory = false;
    self.mailHistoryModalInstance.hide();
  }
});
</script>

<style
  lang="css"
  scoped
  src="../../../../public/static/css/transactionSheet.css"
></style>

<style>
.select-container #branch .dropdown-toggle,
.input-container-county #county .dropdown-toggle,
#sls-fm-group #sales-person .dropdown-toggle,
#select-group .dropdown-toggle {
  height: 32px !important;
  background: #eff5ff !important;
}

#invoice-date {
  height: 32px;
}

.tab-select .dropdown-toggle {
  height: 32px !important;
  background: #ededed !important;
}

#deliver-to .dropdown-toggle {
  border: 1px solid #076eb0 !important;
  background: #ededed !important;
}

.trns-select.disabled .dropdown-toggle,
.trns-select.disabled .dropdown-toggle .clear,
.trns-select.disabled .dropdown-toggle input,
.trns-select.disabled .open-indicator,
.trns-select.disabled .selected-tag .close {
  background-color: unset !important;
}
</style>
