<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_specification_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        disabled
                                        v-model="makeId"
                                        name="makeId"
                                        :placeholder="staticMessages.make_place_holder"
                                        label="text"
                                        @change="changedMakeValue"
                                        :options="makeArr"
                                        :clearable="false"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>
                            <!--Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="modelId"
                                        name="modelId"
                                        :placeholder="staticMessages.model_place_holder"
                                        label="text"
                                        @change="modelValueChanged"
                                        :options="modelArr"
                                        :clearable="false"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </div>
                            </div>
                            <!-- Model1 -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="model1Error = ''" id="model1" v-model="model1" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{model1Error}}</span>
                                </div>
                            </div>
                            <!-- Spec Sheet Template -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.spec_sheet_template}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="specSheetTemplateError = ''" id="specSheetTemplate" v-model="specSheetTemplate" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{specSheetTemplateError}}</span>
                                </div>
                            </div>
                            <!-- Model Description -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_description}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea rows="3" @input="modelDescriptionError = ''" id="modelDescription" v-model="modelDescription" class="form-control" type="text" maxlength="65534"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelDescriptionError}}</span>
                                </div>
                            </div>
                            <!-- GVM -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gvm}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gvmError = ''" id="gvm" v-model="gvm" class="form-control" type="text" maxlength="190"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gvmError}}</span> -->
                                </div>
                            </div>
                            <!-- GCM -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gcm}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gcmError = ''" id="gcm" v-model="gcm" class="form-control" type="text" maxlength="190"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gcmError}}</span> -->
                                </div>
                            </div>
                            <!-- Power -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.power}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="powerError = ''" id="power" v-model="power" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{powerError}}</span>
                                </div>
                            </div>
                            <!-- Torque -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.torque}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="torqueError = ''" id="torque" v-model="torque" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{torqueError}}</span>
                                </div>
                            </div>
                            <!-- Transmission -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.transmission}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="transmissionError = ''" id="transmission" v-model="transmission" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{transmissionError}}</span>
                                </div>
                            </div>
                            <!-- Wheelbase -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wheelbase}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="wheelbaseError = ''" id="wheelbase" v-model="wheelbase" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{wheelbaseError}}</span>
                                </div>
                            </div>
                            <!-- Engine -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="engineError = ''" id="engine" v-model="engine" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineError}}</span>
                                </div>
                            </div>
                            <!-- Cab type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_type}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="cabTypeError = ''" id="cabType" v-model="cabType" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cabTypeError}}</span>
                                </div>
                            </div>
                            <!-- Front Suspension -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_suspension}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="frontSuspensionError = ''" id="frontSuspension" v-model="frontSuspension" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{frontSuspensionError}}</span>
                                </div>
                            </div>
                            <!-- Rear Suspension -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_suspension}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rearSuspensionError = ''" id="rearSuspension" v-model="rearSuspension" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearSuspensionError}}</span>
                                </div>
                            </div>
                            <!-- Fuel Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_type}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fuelTypeError = ''" id="fuelType" v-model="fuelType" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelTypeError}}</span>
                                </div>
                            </div>
                            <!-- Fuel Tank -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_tank}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fuelTankError = ''" id="fuelTank" v-model="fuelTank" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelTankError}}</span>
                                </div>
                            </div>
                            <!-- Emission Level -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.emission_level}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emissionLevelError = ''" id="emissionLevel" v-model="emissionLevel" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emissionLevelError}}</span>
                                </div>
                            </div>
                            <!-- Steering -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.steering}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="steeringError = ''" id="steering" v-model="steering" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{steeringError}}</span>
                                </div>
                            </div>
                            <!-- Battery -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.battery}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="batteryError = ''" id="battery" v-model="battery" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{batteryError}}</span>
                                </div>
                            </div>
                            <!-- Brakes -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.brakes}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="brakesError = ''" id="brakes" v-model="brakes" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{brakesError}}</span>
                                </div>
                            </div>
                            <!-- Active Safety Features -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.active_safety_features}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="activeSafetyfeaturesError = ''" id="activeSafetyfeatures" v-model="activeSafetyfeatures" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{activeSafetyfeaturesError}}</span>
                                </div>
                            </div>
                            <!-- ATM Towing Capacity -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.atm_towing_capacity}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="atmTowingCapacityError = ''" id="atmTowingCapacity" v-model="atmTowingCapacity" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{atmTowingCapacityError}}</span>
                                </div>
                            </div>
                            <!-- Licence Required -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.licence_required}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="licenceRequiredError = ''" id="licenceRequired" v-model="licenceRequired" class="form-control" type="text" maxlength="190"/>
                                    <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{licenceRequiredError}}</span> -->
                                </div>
                            </div>
                            <!-- Factory Warranty -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.factory_warranty}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="factoryWarrantyError = ''" id="factoryWarranty" v-model="factoryWarranty" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{factoryWarrantyError}}</span>
                                </div>
                            </div>
                            <!-- Max People -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.max_people}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="maxPeopleError = ''" id="maxPeople" v-model="maxPeople" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{maxPeopleError}}</span>
                                </div>
                            </div>
                            <!-- Emission Control -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.emission_control}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emissionControlError = ''" id="emissionControl" v-model="emissionControl" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emissionControlError}}</span>
                                </div>
                            </div>
                            <!-- Max Loading Front -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.max_loading_front}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="maxLoadingFrontError = ''" id="maxLoadingFront" v-model="maxLoadingFront" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{maxLoadingFrontError}}</span>
                                </div>
                            </div>
                            <!-- Max Loading Rear -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.max_loading_rear}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="maxLoadingRearError = ''" id="maxLoadingRear" v-model="maxLoadingRear" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{maxLoadingRearError}}</span>
                                </div>
                            </div>
                            <!-- Mass Estimated -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.mass_estimated}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="massEstimatedError = ''" id="massEstimated" v-model="massEstimated" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{massEstimatedError}}</span>
                                </div>
                            </div>
                            <!-- Tyres and Wheels -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tyres_and_wheels}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tyresAndWheelsError = ''" id="tyresAndWheels" v-model="tyresAndWheels" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tyresAndWheelsError}}</span>
                                </div>
                            </div>
                            <!-- Airbags -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.airbags}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="airbagsError = ''" id="airbags" v-model="airbags" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{airbagsError}}</span>
                                </div>
                            </div>
                            <!-- Drive Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drive_type}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="driveTypeError = ''" id="driveType" v-model="driveType" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{driveTypeError}}</span>
                                </div>
                            </div>
                            <!-- Mirrors -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.mirrors}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="mirrorsError = ''" id="mirrors" v-model="mirrors" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{mirrorsError}}</span>
                                </div>
                            </div>
                            <!-- Multimedia unit -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.multimedia_unit}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="multimediaUnitError = ''" id="multimediaUnit" v-model="multimediaUnit" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{multimediaUnitError}}</span>
                                </div>
                            </div>
                            <!-- Features -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.features}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="featuresError = ''" id="features" v-model="features" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{featuresError}}</span>
                                </div>
                            </div>
                            <!-- Service Intervals -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.service_intervals}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="serviceIntervalsError = ''" id="serviceIntervals" v-model="serviceIntervals" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{serviceIntervalsError}}</span>
                                </div>
                            </div>
                        </div>
                        <!--Error Alert-->
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addSpecificationBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateSpecification()">
                            <input v-show="showSaveAndContinue" id="addAndContinueSpecificationBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addUpdateSpecification(flag='Save and Continue')">
                            <input id="cancelSpecification" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateSpecification',
        mixins: [commonMixins],
        components: {
            datePicker,
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",

                makeId: "",
                makeArr: [
                ],
                makeError: "",
                modelId: "",
                modelArr: [],
                modelError: "",
                specSheetTemplate: "",
                specSheetTemplateError: "",
                modelDescription: "",
                modelDescriptionError: "",
                gvm: "",
                gvmError: "",
                gcm: "",
                gcmError: "",
                power: "",
                powerError: "",
                torque: "",
                torqueError: "",
                transmission: "",
                transmissionError: "",
                wheelbase: "",
                wheelbaseError: "",
                engine: "",
                engineError: "",
                cabType: "",
                cabTypeError: "",
                frontSuspension: "",
                frontSuspensionError: "",
                rearSuspension: "",
                rearSuspensionError: "",
                fuelType: "",
                fuelTypeError: "",
                fuelTank: "",
                fuelTankError: "",
                emissionLevel: "",
                emissionLevelError: "",
                steering: "",
                steeringError: "",
                battery: "",
                batteryError: "",
                brakes: "",
                brakesError: "",
                activeSafetyfeatures: "",
                activeSafetyfeaturesError: "",
                atmTowingCapacity: "",
                atmTowingCapacityError: "",
                licenceRequired: "",
                licenceRequiredError: "",
                factoryWarranty: "",
                factoryWarrantyError: "",
                maxPeople: "",
                maxPeopleError: "",
                emissionControl: "",
                emissionControlError: "",
                maxLoadingFront: "",
                maxLoadingFrontError: "",
                maxLoadingRear: "",
                maxLoadingRearError: "",
                massEstimated: "",
                massEstimatedError: "",
                tyresAndWheels: "",
                tyresAndWheelsError: "",
                airbags: "",
                airbagsError: "",
                driveType: "",
                driveTypeError: "",
                mirrors: "",
                mirrorsError: "",
                multimediaUnit: "",
                multimediaUnitError: "",
                features: "",
                featuresError: "",
                serviceIntervals: "",
                serviceIntervalsError: "",
                model1Error: "",
                model1: "",
                make: "Fuso",
                makeError: "",
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Specification",
                permissionNameArray : ['can_add_specification_master']
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('specification-list');
            EventBus.$emit("menu-selection-changed", "specification-list");

            self.loadAllData(['Make'], false, self.loadMasterDataCallback, "undefined", '', '', '', '', '', '', '', 'new');

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getSpecificationDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Specification";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({ name: 'ListSpecification'})
            },
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.makeArr = callbackData.Make;
                // this is only for Fuso make temporary as currently we have only Fuso Make
                for (let i = 0; i < callbackData.Make.length; i++) {
                    if (callbackData.Make[i].text.match("Fuso")) {
                        self.makeId = {text: callbackData.Make[i].text, value: callbackData.Make[i].value};
                    }
                }
            },
            changedMakeValue: function() {
                self.makeError = "";
                self.modelId = "";
                let make_id = "";
                if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0) {
                    make_id = self.makeId.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', make_id, '', '', '', '', '', 'new');
            },
            loadModelDataCallback: function (callbackData) {
                self.modelArr = callbackData.Model;
            },
            modelValueChanged: function () {
                self.modelError = "";
            },
            changeApplyFromDateTime: function () {
                self.applyFromDateTimeError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.makeId = "";
                self.modelId = "";
                self.applyFromDateTime = "";
                self.orderType = "";
                self.option = "";
                self.description = "";
                self.rrp = "";
                self.gst = "";
                self.listPrice = "";
                self.dealerDiscount = "";
                self.dealerNet = "";
                self.conquest = "";
                self.fitUpTotal = "";
                self.preDelivery = "";
                self.cylinderType = {text: '4 Cylinder', value: '4 Cylinder'};
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getSpecificationDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };

                HTTP.get('/specification/get', config)
                    .then(response => {
                        console.log('response', response.data.content)
                        self.makeId = {text: response.data.content.make.name, value: response.data.content.make_id};
                        setTimeout(function() {
                            self.modelId = {text: response.data.content.model.name, value: response.data.content.model_id};
                        }, 50);
                        self.model1 = response.data.content.model1;
                        self.specSheetTemplate = response.data.content.spec_sheet_template;
                        self.modelDescription = response.data.content.model_description;
                        self.gvm = response.data.content.gvm;
                        self.gcm = response.data.content.gcm;
                        self.power = response.data.content.power;
                        self.torque = response.data.content.torque;
                        self.transmission = response.data.content.transmission;
                        self.wheelbase = response.data.content.wheelbase;
                        self.engine = response.data.content.engine;
                        self.cabType = response.data.content.cab_type;
                        self.frontSuspension = response.data.content.front_suspension;
                        self.rearSuspension = response.data.content.rear_suspension;
                        self.fuelType = response.data.content.fuel_type;
                        self.fuelTank = response.data.content.fuel_tank;
                        self.emissionLevel = response.data.content.emission_level;
                        self.steering = response.data.content.steering;
                        self.battery = response.data.content.battery;
                        self.brakes = response.data.content.brakes;
                        self.activeSafetyfeatures = response.data.content.active_safety_features;
                        self.atmTowingCapacity = response.data.content.atm_towing_capacity;
                        self.licenceRequired = response.data.content.licence_required;
                        self.factoryWarranty = response.data.content.factory_warranty;
                        self.maxPeople = response.data.content.max_people;
                        self.emissionControl = response.data.content.emission_control;
                        self.maxLoadingFront = response.data.content.max_loading_front;
                        self.maxLoadingRear = response.data.content.max_loading_rear;
                        self.massEstimated = response.data.content.mass_estimated;
                        self.tyresAndWheels = response.data.content.tyres_and_wheels;
                        self.airbags = response.data.content.airbags;
                        self.driveType = response.data.content.drive_type;
                        self.mirrors = response.data.content.mirrors;
                        self.multimediaUnit = response.data.content.multimedia_unit;
                        self.features = response.data.content.features;
                        self.serviceIntervals = response.data.content.service_intervals;
                    })
                    .catch(function (err) {
                        console.log(err);
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                // self.makeError = "";
                // self.modelError = "";
                self.model1Error = "";
                self.specSheetTemplateError = "";
                self.modelDescriptionError = "";
                self.gvmError = "";
                self.gcmError = "";
                self.powerError = "";
                self.torqueError = "";
                self.transmissionError = "";
                self.wheelbaseError = "";
                self.engineError = "";
                self.cabTypeError = "";
                self.frontSuspensionError = "";
                self.rearSuspensionError = "";
                self.fuelTypeError = "";
                self.fuelTankError = "";
                self.emissionLevelError = "";
                self.steeringError = "";
                self.batteryError = "";
                self.brakesError = "";
                self.activeSafetyfeaturesError = "";
                self.licenceRequiredError = "";
                self.factoryWarrantyError = "";
                self.maxPeopleError = "";
                self.emissionControlError = "";
                self.maxLoadingFrontError = "";
                self.maxLoadingRearError = "";
                self.massEstimatedError = "";
                self.tyresAndWheelsError = "";
                self.airbagsError = "";
                self.driveTypeError = "";
                self.mirrorsError = "";
                self.multimediaUnitError = "";
                self.featuresError = "";
                self.serviceIntervalsError = "";
                // if (self.makeId == null || !self.makeId.value) {
                //     self.makeError = "Please select make";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // if (self.modelId == null || !self.modelId.value) {
                //     self.modelError = "Please select model";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (!self.model1) {
                    self.model1Error = "Please enter Model1";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.specSheetTemplate) {
                    self.specSheetTemplateError = "Please enter Spec Sheet Template";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.modelDescription) {
                    self.modelDescriptionError = "Please enter Model Description";
                    if (checked) {
                        checked = false;
                    }
                }
                // if (!self.gvm) {
                //     self.gvmError = "Please enter GVM";
                //     if (checked) {
                //         checked = false;
                //     }
                // } else if(self.gvm && !self.numberFormateValidation(self.gvm)) {
                //     self.gvmError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // if (!self.gcm) {
                //     self.gcmError = "Please enter GCM";
                //     if (checked) {
                //         checked = false;
                //     }
                // } else if(self.gcm && !self.numberFormateValidation(self.gcm)) {
                //     self.gcmError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (!self.power) {
                    self.powerError = "Please enter Power";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.torque) {
                    self.torqueError = "Please enter Torque";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.transmission) {
                    self.transmissionError = "Please enter Transmission";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.wheelbase) {
                    self.wheelbaseError = "Please enter Wheelbase";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.wheelbase && !self.numberFormateValidation(self.wheelbase)) {
                    self.wheelbaseError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.engine) {
                    self.engineError = "Please enter Engine";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.cabType) {
                    self.cabTypeError = "Please enter Cab Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.frontSuspension) {
                    self.frontSuspensionError = "Please enter Front Suspension";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.rearSuspension) {
                    self.rearSuspensionError = "Please enter Rear Suspension";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.fuelType) {
                    self.fuelTypeError = "Please enter Fuel Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.fuelTank) {
                    self.fuelTankError = "Please enter Fuel Tank";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.fuelTank && !self.numberFormateValidation(self.fuelTank)) {
                    self.fuelTankError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.emissionLevel) {
                    self.emissionLevelError = "Please enter Emission Level";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.steering) {
                    self.steeringError = "Please enter Steering";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.battery) {
                    self.batteryError = "Please enter Battery";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.brakes) {
                    self.brakesError = "Please enter Brakes";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.activeSafetyfeatures) {
                    self.activeSafetyfeaturesError = "Please enter Active Safety Features";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.atmTowingCapacity) {
                    self.atmTowingCapacityError = "Please enter ATM (Towing Capacity)";
                    if (checked) {
                        checked = false;
                    }
                }
                // if (!self.licenceRequired) {
                //     self.licenceRequiredError = "Please enter Licence";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (!self.factoryWarranty) {
                    self.factoryWarrantyError = "Please enter Factory Warranty";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.maxPeople) {
                    self.maxPeopleError = "Please enter Max People";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.maxPeople && !self.numberFormateValidation(self.maxPeople)) {
                    self.maxPeopleError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.emissionControl) {
                    self.emissionControlError = "Please enter Emission Control";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.maxLoadingFront) {
                    self.maxLoadingFrontError = "Please enter Max Loading Front";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.maxLoadingFront && !self.numberFormateValidation(self.maxLoadingFront)) {
                    self.maxLoadingFrontError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.maxLoadingRear) {
                    self.maxLoadingRearError = "Please enter Max Loading Rear";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.maxLoadingRear && !self.numberFormateValidation(self.maxLoadingRear)) {
                    self.maxLoadingRearError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.massEstimated) {
                    self.massEstimatedError = "Please enter Mass Estimated";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.massEstimated && !self.numberFormateValidation(self.massEstimated)) {
                    self.massEstimatedError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.tyresAndWheels) {
                    self.tyresAndWheelsError = "Please enter Tyres and Wheels";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.airbags) {
                    self.airbagsError = "Please enter Airbags - Supplementary Restraint System";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.driveType) {
                    self.driveTypeError = "Please enter Drive Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.mirrors) {
                    self.mirrorsError = "Please enter Mirrors";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.multimediaUnit) {
                    self.multimediaUnitError = "Please enter Multimedia Unit";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.features) {
                    self.featuresError = "Please enter Features";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.serviceIntervals) {
                    self.serviceIntervalsError = "Please enter Service Intervals";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addUpdateSpecification: function(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        self.disableButtons.save = true;
                        self.disableButtons.saveAndContinue = true;
                        self.disableButtons.cancel = true;
                        var data = new FormData();
                        data.append('make_id', self.makeId.value);
                        data.append('model_id', self.modelId.value);
                        data.append('model1', self.model1);
                        data.append('spec_sheet_template', self.specSheetTemplate);
                        data.append('model_description', self.modelDescription);
                        data.append('gvm', self.gvm);
                        data.append('gcm', self.gcm);
                        data.append('power', self.power);
                        data.append('torque', self.torque);
                        data.append('transmission', self.transmission);
                        data.append('wheelbase', self.wheelbase);
                        data.append('engine', self.engine);
                        data.append('cab_type', self.cabType);
                        data.append('front_suspension', self.frontSuspension);
                        data.append('rear_suspension', self.rearSuspension);
                        data.append('fuel_type', self.fuelType);
                        data.append('fuel_tank', self.fuelTank);
                        data.append('emission_level', self.emissionLevel);
                        data.append('steering', self.steering);
                        data.append('battery', self.battery);
                        data.append('brakes', self.brakes);
                        data.append('active_safety_features', self.activeSafetyfeatures);
                        data.append('atm_towing_capacity', self.atmTowingCapacity);
                        data.append('licence_required', self.licenceRequired);
                        data.append('factory_warranty', self.factoryWarranty);
                        data.append('max_people', self.maxPeople);
                        data.append('emission_control', self.emissionControl);
                        data.append('max_loading_front', self.maxLoadingFront);
                        data.append('max_loading_rear', self.maxLoadingRear);
                        data.append('mass_estimated', self.massEstimated);
                        data.append('tyres_and_wheels', self.tyresAndWheels);
                        data.append('airbags', self.airbags);
                        data.append('drive_type', self.driveType);
                        data.append('mirrors', self.mirrors);
                        data.append('multimedia_unit', self.multimediaUnit);
                        data.append('features', self.features);
                        data.append('service_intervals', self.serviceIntervals);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                        }

                        HTTP.post("/specification/add/update", data, config)
                            .then(function (response) {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }

                                if (response.data.status == "success") {
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        setTimeout(function () {
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>