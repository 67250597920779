<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_360_image_inventory_master')">
            <div class="container-fluid xyz">
                <div class="admin-page-content">
                    <div class="cards-container">
                        <div class="form-group">
                            <label for="dropzone">{{staticInputLabelMessages.upload_360_image_label}}</label>
                            <vue-dropzone
                                ref="dropzone"
                                id="dropzone"
                                :options="dropzoneOptions"
                                @vdropzone-success = "uploadDocument"
                                @vdropzone-removed-file="removedSelectedFile"
                                @vdropzone-error="fileError"
                                @vdropzone-file-added="fileAdd"
                                @vdropzone-total-upload-progress="progressOfUloadBanner"
                                :useCustomSlot=true
                                :destroyDropzone="false"
                                >
                                <div class="dropzone-custom-content">
                                    <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                    <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                </div>
                            </vue-dropzone>
                        </div>
                        <span class="help is-danger">{{fileUploadError}}</span>
                        <div class="clearfix"></div>
                        <template v-if="loadDocArr.length > 0">
                            <a :href="loadDocArr[0].paneroma_url + slug + '?imgid=' + loadDocArr[0].id" target="_blank">Click here to view 360 images</a>
                            <br/><br/>
                        </template>
                        <div class="row clearfix"></div>
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="add360Images()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary ml-10" v-on:click.prevent="buttonBack">
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import $ from 'jquery';
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import vue2Dropzone from 'vue2-dropzone';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'Image360View',
        mixins: [commonMixins],
        components: {
            'no-access' : NoAccessPage,
            'vueDropzone': vue2Dropzone,
        },
        data () {
            return {
                slug : "",
                staticMessages: "",
                staticInputLabelMessages: "",

                loadDocArr: [],
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                fileUploadError : "",
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: 50,
                    params: {'is_360_image': true},
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch: {
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.getData(self.asset);
            self.getInventory360Images();
        },
        methods: {
            getData : function(data) {
                self.slug = self.odometer = data.content['asset_data'].slug;
            },
            buttonBack: function() {
                self.$router.push({name: "ListAsset"});
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
                
                self.fileUploadError = "";
                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                this.$refs.dropzone.setOption('maxFiles', null);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
                
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                this.$refs.dropzone.setOption('maxFiles', null);
                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                if(xhr.code == 400){
                    self.showSAlert(xhr.message, 'error', false);
                    self.$refs.dropzone.removeFile(file);
                }
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;

                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                }
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                } else {
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
            },
            getInventory360Images: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/360/images/get', config)
                    .then(response => {
                        if (response.data.code == 200) {
                            self.loadDocArr = response.data.content;
                            setTimeout(function() {
                                self.uploadManually();
                            }, 500);
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
                
            },
            checkForm: function() {
                var checked = true;
                /* if (self.imagesPaths.length === 0)  {
                    self.fileUploadError = "Please upload file";
                    if (checked) {
                        checked = false;
                    }
                } */
                return checked;
            },
            returnData: function() {
                var data = {
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType,
                    id: self.$route.params.id,
                };
                return data;
            },
            add360Images: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Saving ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/360/image/add/update", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Save";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);

                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                            self.$emit('updateDisplayData',11);
                                            self.$refs.dropzone.removeAllFiles();
                                            self.getData(self.asset);
                                            self.getInventory360Images();
                                        } else {
                                            self.buttonBack();
                                        }
                                    }, 3000);
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Save";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            uploadManually: function () {
                if(self.loadDocArr.length > 0){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        if (self.loadDocArr[i]['media_from'] == 'media_url') {
                            var mockFile = {name: "image_" + i + ".jpg", size: self.loadDocArr[i]['size']};
                            let imgUrl = self.loadDocArr[i]['thumb_url'];
                            let imageName = imgUrl.split("https://www.imanpro.net/pub/co/sac/photo/");
                            this.$refs.dropzone.dropzone.options.addedfile.call(this.$refs.dropzone.dropzone, mockFile);
                            this.$refs.dropzone.dropzone.options.thumbnail.call(this.$refs.dropzone.dropzone, mockFile, imgUrl);
                        } else {
                            var url = self.loadDocArr[i]['media_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['media_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];

                            self.imagesType.push(file_type);
                            self.imagesSize.push(Number(file_size));

                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                            self.$refs.dropzone.manuallyAddFile(file_data, url);
                        }
                    }
                }
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
            },
            getFileName: function (file) {
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];
                self.imagesPaths.push(String(orgPath + "/" + file_name));
                return file_name;
            },
        },
    }
</script>