<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_document_group_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            
                            <!-- Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>
                        
                            <!-- Select Document -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.select_document_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        v-model="documentId" 
                                        :options="documentArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        :placeholder="staticMessages.select_document_place_holder"
                                        label="text" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        class="form-control"
                                        @input="documentError = ''"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedDocumentSelection.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{documentError}}</span>
                                </div>
                            </div>
                        </div>
                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addDocumentGroupBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addDocumentGroup()">
                            <input id="addAndContinueDocumentGroupBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addDocumentGroup(flag='Save and Continue')">
                            <input id="cancelDocument" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import tinymce from 'vue-tinymce-editor';
    import Multiselect from 'vue-multiselect';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage);
    Vue.component('tinymce', tinymce)
    
    export default {
        name: 'AddUpdateDocumentGroup',
        mixins: [commonMixins],
        components: { 
            Multiselect
        },
        data() {
            return {
                isadd: true,
                staticInputLabelMessages: "",
                staticMessages: "",
                name : "",
                nameError : "",

                documentId : "",
                documentArr : [],
                appliedDocumentSelection : [],
                documentError : "",

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Document Groups",
                permissionNameArray: ['can_add_document_group_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            documentId: function(){
                self.appliedDocumentSelection = [];
                $.each(self.documentId, function (i) {
                    self.appliedDocumentSelection.push(self.documentId[i].value);
                });
            },
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('document-group-list');
            EventBus.$emit("menu-selection-changed", "document-group-list");

            $("#alertError").hide();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getDocumentGroupDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.isadd = false;
                $("#addAndContinueDocumentGroupBtn").hide();
                self.pageTitle = "Edit Document Groups";
                self.isadd = false;
            }
            self.loadAllData(['Document'], false, self.loadMasterDataCallback, "undefined", '');
        },
        methods: {
            buttonBack() {
                self.$router.push('/documentgroup/list');
            },
            loadMasterDataCallback: function(callbackData) {
                self.documentArr = callbackData.Document;
            },
            clearDataOnSaveAndContinue: function () {
                self.name = "";
                self.documentId = "";
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            getDocumentGroupDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id
                    }
                };
                HTTP.get('/documentgroup/get', config)
                    .then(response => {
                        self.name = response.data.content.getDocGrpData.name;

                        self.documentId = [];
                        if(response.data.content.documentArr && response.data.content.documentArr.length > 0) {
                            for (var i = 0; i < response.data.content.documentArr.length; i++) {
                                self.documentId.push(response.data.content.documentArr[i]);
                            }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.documentId.length == 0) {
                    self.documentError = "Please select document";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addDocumentGroup(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };

                        var document_id = '';
                        if( self.documentId && self.documentId.length > 0 ) {
                            for (var i = 0; i < self.documentId.length; i++) {
                                document_id += (self.documentId[i].value) + ',';
                            }
                            document_id = document_id.replace(/,\s*$/, "");
                        }

                        var data = new FormData();
                        data.append('name', self.name);
                        data.append('document_id', document_id);
                        
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/documentgroup/add/update", data, config)
                            .then(function (response) {
                                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>