<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_price_book_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="makeId"
                                        name="makeId"
                                        :placeholder="staticMessages.make_place_holder"
                                        label="text"
                                        @change="changedMakeValue"
                                        :options="makeArr"
                                        :clearable="false"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>
                            <!--Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="modelId"
                                        name="modelId"
                                        :placeholder="staticMessages.model_place_holder"
                                        label="text"
                                        @change="modelValueChanged"
                                        :options="modelArr"
                                        :clearable="false"
                                    >
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </div>
                            </div>
                            <!-- Series -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.series}}:</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="series" v-model="series" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div>
                            <!-- File name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.filename}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fileNameError = ''" id="fileName" v-model="fileName" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fileNameError}}</span>
                                </div>
                            </div>
                            <!-- Apply from Date Time -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apply_from_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="applyFromDateTime"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.apply_from_datetime_place_holder"
                                        name="applyFromDateTime"
                                        @input="changeApplyFromDateTime()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{applyFromDateTimeError}}</span>
                                </div>
                            </div>
                            <!-- Order Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.order_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="orderTypeError = ''" id="orderType" v-model="orderType" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{orderTypeError}}</span>
                                </div>
                            </div>
                            <!-- Option -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.option_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="optionError = ''" id="option" v-model="option" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{optionError}}</span>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea rows="3" @input="descriptionError = ''" id="description" v-model="description" class="form-control" type="text" maxlength="65534"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span>
                                </div>
                            </div>
                            <!-- RRP -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rrp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rrpError = ''" id="rrp" v-model="rrp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rrpError}}</span>
                                </div>
                            </div>
                            <!-- GST -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gst_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gstError = ''" id="gst" v-model="gst" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gstError}}</span>
                                </div>
                            </div>
                            <!-- List Price -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.list_price_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="listPriceError = ''" id="listPrice" v-model="listPrice" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{listPriceError}}</span>
                                </div>
                            </div>
                            <!-- Dealer Discount -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_discount_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerDiscountError = ''" id="dealerDiscount" v-model="dealerDiscount" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerDiscountError}}</span>
                                </div>
                            </div>
                            <!-- Dealer Net -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_net_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerNetError = ''" id="dealerNet" v-model="dealerNet" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerNetError}}</span>
                                </div>
                            </div>
                            <!-- Conquest -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.conquest_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="conquestError = ''" id="conquest" v-model="conquest" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{conquestError}}</span>
                                </div>
                            </div>
                            <!-- Fitup Total -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fitup_total_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fitUpTotalError = ''" id="fitUpTotal" v-model="fitUpTotal" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fitUpTotalError}}</span>
                                </div>
                            </div>
                            <!-- Pre Delivery -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.pre_delivery_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="preDeliveryError = ''" id="preDelivery" v-model="preDelivery" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{preDeliveryError}}</span>
                                </div>
                            </div>
                            <!-- Pre Delivery -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cylinder_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="cylinderType"
                                            name="cylinderType"
                                            id="cylinderType"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="cylinderTypeArr"
                                            :placeholder="allStaticMessages.stamp_duty_type_place_holder">
                                        </v-select>
                                </div>
                            </div>
                            <!-- LLP -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.llp}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="llpError = ''" id="llp" v-model="llp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{llpError}}</span>
                                </div>
                            </div> -->
                            <!-- Exempt from Net Revenue Increase -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exempt_from_net_revenue_increase}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="exemptFromNetRevenueIncrease"
                                        name="exemptFromNetRevenueIncrease"
                                        id="exemptFromNetRevenueIncrease"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="exemptFromNetRevenueIncreaseArr"
                                        :placeholder="allStaticMessages.stamp_duty_type_place_holder">
                                    </v-select>
                                </div>
                            </div> -->
                            <!-- Dealer disc 1 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_disc_1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerDisc1Error = ''" id="dealerDisc1" v-model="dealerDisc1" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerDisc1Error}}</span>
                                </div>
                            </div> -->
                            <!-- Dealer disc 2 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_disc_2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerDisc2Error = ''" id="dealerDisc2" v-model="dealerDisc2" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerDisc2Error}}</span>
                                </div>
                            </div> -->
                            <!-- Dealer disc 3 -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_disc_3}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerDisc3Error = ''" id="dealerDisc3" v-model="dealerDisc3" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerDisc3Error}}</span>
                                </div>
                            </div> -->
                            <!-- Net Revenue Increase -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.net_revenue_increase}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="netRevenueIncreaseError = ''" id="netRevenueIncrease" v-model="netRevenueIncrease" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{netRevenueIncreaseError}}</span>
                                </div>
                            </div> -->
                            <!-- Total Dealer Discount -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.total_dealer_discount}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="totalDealerDiscountError = ''" id="totalDealerDiscount" v-model="totalDealerDiscount" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{totalDealerDiscountError}}</span>
                                </div>
                            </div> -->
                            <!-- In % of LLP -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.in_percentage_of_llp}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="inPercentageOfLlpError = ''" id="inPercentageOfLlp" v-model="inPercentageOfLlp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{inPercentageOfLlpError}}</span>
                                </div>
                            </div> -->
                            <!-- Dealer Net Price -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dealer_net_price}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dealerNetPriceError = ''" id="dealerNetPrice" v-model="dealerNetPrice" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dealerNetPriceError}}</span>
                                </div>
                            </div> -->
                            <!-- Discount Percentage Price Sheet Original -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.discount_percentage_price_sheet_original}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="discountPercentagePriceSheetOriginalError = ''" id="discountPercentagePriceSheetOriginal" v-model="discountPercentagePriceSheetOriginal" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{discountPercentagePriceSheetOriginalError}}</span>
                                </div>
                            </div> -->
                            <!-- Discount Percentage SAP -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.discount_percentage_sap}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="discountPercentageSapError = ''" id="discountPercentageSap" v-model="discountPercentageSap" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{discountPercentageSapError}}</span>
                                </div>
                            </div> -->
                            <!-- Discount Percentage Matt's Fields -->
                            <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.discount_percentage_matts_file}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="discountPercentageMattsFileError = ''" id="discountPercentageMattsFile" v-model="discountPercentageMattsFile" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{discountPercentageMattsFileError}}</span>
                                </div>
                            </div> -->
                        </div>
                        <!--Error Alert-->
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addPriceBookBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdatePriceBook()">
                            <input v-show="showSaveAndContinue" id="addAndContinuePriceBookBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addUpdatePriceBook(flag='Save and Continue')">
                            <input id="cancelPriceBook" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdatePriceBook',
        mixins: [commonMixins],
        components: {
            datePicker,
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",

                makeId: "",
                makeArr: [],
                makeError: "",
                modelId: "",
                modelArr: [],
                modelError: "",
                applyFromDateTime: "",
                applyFromDateTimeError: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },
                orderType: "",
                orderTypeError: "",
                option: "",
                optionError: "",
                description: "",
                descriptionError: "",
                rrp: "",
                rrpError: "",
                gst: "",
                gstError: "",
                listPrice: "",
                listPriceError: "",
                dealerDiscount: "",
                dealerDiscountError: "",
                dealerNet: "",
                dealerNetError: "",
                conquest: "",
                conquestError: "",
                fitUpTotal: "",
                fitUpTotalError: "",
                preDelivery: "",
                preDeliveryError: "",
                llp: "",
                llpError: "",
                exemptFromNetRevenueIncrease: {text: 'No', value: 0},
                dealerDisc1: "",
                dealerDisc1Error: "",
                dealerDisc2: "",
                dealerDisc2Error: "",
                dealerDisc3: "",
                dealerDisc3Error: "",
                netRevenueIncrease: "",
                netRevenueIncreaseError: "",
                totalDealerDiscount: "",
                totalDealerDiscountError: "",
                inPercentageOfLlp: "",
                inPercentageOfLlpError: "",
                dealerNetPrice: "",
                dealerNetPriceError: "",
                discountPercentagePriceSheetOriginal: "",
                discountPercentagePriceSheetOriginalError: "",
                discountPercentageSap: "",
                discountPercentageSapError: "",
                discountPercentageMattsFile: "",
                discountPercentageMattsFileError: "",
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                cylinderType : {text: '4 Cylinder', value: '4 Cylinder'},
                cylinderTypeArr : [
                    {text: '4 Cylinder', value: '4 Cylinder'},
                    {text: '6 Cylinder', value: '6 Cylinder'},
                ],
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Price Book",
                permissionNameArray : ['can_add_price_book_master'],
                exemptFromNetRevenueIncreaseArr: [
                    {text: 'Yes', value: 1},
                    {text: 'No', value: 0}
                ],
                fileName: "",
                fileNameError: "",
                series: null
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('price-book-list');
            EventBus.$emit("menu-selection-changed", "price-book-list");

            self.loadAllData(['Make'], false, self.loadMasterDataCallback, "undefined", '', '', '', '', '', '', '', 'new');

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getPriceBookDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Price Book";
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({ name: 'ListPriceBook'})
            },
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.makeArr = callbackData.Make;
            },
            changedMakeValue: function() {
                self.makeError = "";
                self.modelId = "";
                let make_id = "";
                if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0) {
                    make_id = self.makeId.value;
                }
                self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', make_id, '', '', '', '', '', 'new');
            },
            loadModelDataCallback: function (callbackData) {
                self.modelArr = callbackData.Model;
            },
            modelValueChanged: function () {
                self.modelError = "";
            },
            changeApplyFromDateTime: function () {
                self.applyFromDateTimeError = "";
            },
            clearDataOnSaveAndContinue : function () {
                self.series = null;
                self.fileName = "";
                self.makeId = "";
                self.modelId = "";
                self.applyFromDateTime = "";
                self.orderType = "";
                self.option = "";
                self.description = "";
                self.rrp = "";
                self.gst = "";
                self.listPrice = "";
                self.dealerDiscount = "";
                self.dealerNet = "";
                self.conquest = "";
                self.fitUpTotal = "";
                self.preDelivery = "";
                self.cylinderType = {text: '4 Cylinder', value: '4 Cylinder'};
                self.llp = "";
                self.exemptFromNetRevenueIncrease = {text: 'No', value: 0};
                self.dealerDisc1 = "";
                self.dealerDisc2 = "";
                self.dealerDisc3 = "";
                self.netRevenueIncrease = "";
                self.totalDealerDiscount = "";
                self.inPercentageOfLlp = "";
                self.dealerNetPrice = "";
                self.discountPercentagePriceSheetOriginal = "";
                self.discountPercentageSap = "";
                self.discountPercentageMattsFile = "";
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            getPriceBookDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };

                HTTP.get('/price/book/get', config)
                    .then(response => {
                        self.makeId = {text: response.data.content.make_name, value: response.data.content.make_id};
                        setTimeout(function() {
                            self.modelId = {text: response.data.content.model_name, value: response.data.content.model_id};
                        }, 50);
                        self.series = response.data.content.type;
                        self.fileName = response.data.content.file_name;
                        self.applyFromDateTime = response.data.content.apply_from;
                        self.orderType = response.data.content.order_type;
                        self.option = response.data.content.option;
                        self.description = response.data.content.description;
                        self.rrp = response.data.content.rrp.toFixed(2);
                        self.gst = response.data.content.gst.toFixed(2);
                        self.listPrice = response.data.content.list_price.toFixed(2);
                        self.dealerDiscount = response.data.content.dealer_discount.toFixed(2);
                        self.dealerNet = response.data.content.dealer_net.toFixed(2);
                        self.conquest = response.data.content.conquest.toFixed(2);
                        self.fitUpTotal = response.data.content.fituptotal.toFixed(2);
                        self.preDelivery = response.data.content.pre_delivery.toFixed(2);
                        self.cylinderType = {text: response.data.content.cylinder, value: response.data.content.cylinder}
                        self.llp = response.data.content.llp.toFixed(2);
                        if (response.data.content.exempt_from_net_revenue_increase) {
                            self.exemptFromNetRevenueIncrease = {text: 'Yes', value: 'yes'}
                        } else {
                            self.exemptFromNetRevenueIncrease = {text: 'No', value: 'no'}
                        }
                        self.dealerDisc1 = response.data.content.dealer_disc_1;
                        self.dealerDisc2 = response.data.content.dealer_disc_2.toFixed(2);
                        self.dealerDisc3 = response.data.content.dealer_disc_3.toFixed(2);
                        self.netRevenueIncrease = response.data.content.net_revenue_increase.toFixed(2);
                        self.totalDealerDiscount = response.data.content.total_dealer_discount.toFixed(2);
                        self.inPercentageOfLlp = response.data.content.in_percentage_of_llp.toFixed(2);
                        self.dealerNetPrice = response.data.content.dealer_net_price.toFixed(2);
                        self.discountPercentagePriceSheetOriginal = response.data.content.discount_percentage_price_sheet_original.toFixed(2);
                        self.discountPercentageSap = response.data.content.discount_percentage_sap.toFixed(2);
                        self.discountPercentageMattsFile = response.data.content.discount_percentage_matts_file.toFixed(2);
                    })
                    .catch(function (err) {
                        console.log(err);
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                self.fileNameError = "";
                self.makeError = "";
                self.modelError = "";
                self.applyFromDateTimeError = "";
                self.orderTypeError = "";
                self.optionError = "";
                self.descriptionError = "";
                self.rrpError = "";
                self.gstError = "";
                self.listPriceError = "";
                self.dealerDiscountError = "";
                self.dealerNetError = "";
                self.conquestError = "";
                self.fitUpTotalError = "";
                self.preDeliveryError = "";
                self.llpError = "";
                self.dealerDisc1Error = "";
                self.dealerDisc2Error = "";
                self.dealerDisc3Error = "";
                self.netRevenueIncreaseError = "";
                self.totalDealerDiscountError = "";
                self.inPercentageOfLlpError = "";
                self.dealerNetPriceError = "";
                self.discountPercentagePriceSheetOriginalError = "";
                self.discountPercentageSapError = "";
                self.discountPercentageMattsFileError = "";

                if (self.makeId == null || !self.makeId.value) {
                    self.makeError = "Please select make";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.modelId == null || !self.modelId.value) {
                    self.modelError = "Please select model";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.applyFromDateTime) {
                    self.applyFromDateTimeError = "Please select apply from Date Time";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.fileName) {
                    self.fileNameError = "Please enter File name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.orderType) {
                    self.orderTypeError = "Please enter order Type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.option) {
                    self.optionError = "Please enter option";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.description) {
                    self.descriptionError = "Please enter description";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.rrp) {
                    self.rrpError = "Please enter RRP";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.rrp && !self.numberFormateValidation(self.rrp)) {
                    self.rrpError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.gst) {
                    self.gstError = "Please enter GST";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.gst && !self.numberFormateValidation(self.gst)) {
                    self.gstError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.listPrice) {
                    self.listPriceError = "Please enter List Price";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.listPrice && !self.numberFormateValidation(self.listPrice)) {
                    self.listPriceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerDiscount) {
                    self.dealerDiscountError = "Please enter dealer Discount";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerDiscount && !self.numberFormateValidation(self.dealerDiscount)) {
                    self.dealerDiscountError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerNet) {
                    self.dealerNetError = "Please enter dealer net";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerNet && !self.numberFormateValidation(self.dealerNet)) {
                    self.dealerNetError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.conquest) {
                    self.conquestError = "Please enter conquest";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.conquest && !self.numberFormateValidation(self.conquest)) {
                    self.conquestError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.fitUpTotal) {
                    self.fitUpTotalError = "Please enter Fitup Total";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.fitUpTotal && !self.numberFormateValidation(self.fitUpTotal)) {
                    self.fitUpTotalError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.preDelivery) {
                    self.preDeliveryError = "Please enter pre Delivery";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.preDelivery && !self.numberFormateValidation(self.preDelivery)) {
                    self.preDeliveryError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.llp) {
                    self.llpError = "Please enter LLP";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.llp && !self.numberFormateValidation(self.llp)) {
                    self.llpError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerDisc1) {
                    self.dealerDisc1Error = "Please enter Dealer disc 1";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerDisc1 && !self.numberFormateValidation(self.dealerDisc1)) {
                    self.dealerDisc1Error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerDisc2) {
                    self.dealerDisc2Error = "Please enter Dealer disc 2";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerDisc2 && !self.numberFormateValidation(self.dealerDisc2)) {
                    self.dealerDisc2Error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerDisc3) {
                    self.dealerDisc3Error = "Please enter Dealer disc 3";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerDisc3 && !self.numberFormateValidation(self.dealerDisc3)) {
                    self.dealerDisc3Error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.netRevenueIncrease) {
                    self.netRevenueIncreaseError = "Please enter Net Revenue Increase";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.netRevenueIncrease && !self.numberFormateValidation(self.netRevenueIncrease)) {
                    self.netRevenueIncreaseError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.totalDealerDiscount) {
                    self.totalDealerDiscountError = "Please enter Total Dealer Discount";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.totalDealerDiscount && !self.numberFormateValidation(self.totalDealerDiscount)) {
                    self.totalDealerDiscountError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.inPercentageOfLlp) {
                    self.inPercentageOfLlpError = "Please enter In % of LLP";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.inPercentageOfLlp && !self.numberFormateValidation(self.inPercentageOfLlp)) {
                    self.inPercentageOfLlpError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dealerNetPrice) {
                    self.dealerNetPriceError = "Please enter Dealer Net Price";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.dealerNetPrice && !self.numberFormateValidation(self.dealerNetPrice)) {
                    self.dealerNetPriceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.discountPercentagePriceSheetOriginal) {
                    self.discountPercentagePriceSheetOriginalError = "Please enter Discount % Price Sheet Original";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.discountPercentagePriceSheetOriginal && !self.numberFormateValidation(self.discountPercentagePriceSheetOriginal)) {
                    self.discountPercentagePriceSheetOriginalError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.discountPercentageSap) {
                    self.discountPercentageSapError = "Please enter Discount % SAP";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.discountPercentageSap && !self.numberFormateValidation(self.discountPercentageSap)) {
                    self.discountPercentageSapError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.discountPercentageMattsFile) {
                    self.discountPercentageMattsFileError = "Please enter Discount % Matt's Fields";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.discountPercentageMattsFile && !self.numberFormateValidation(self.discountPercentageMattsFile)) {
                    self.discountPercentageMattsFileError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addUpdatePriceBook: function(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        self.disableButtons.save = true;
                        self.disableButtons.saveAndContinue = true;
                        self.disableButtons.cancel = true;
                        var data = new FormData();
                        data.append('make_id', self.makeId.value);
                        data.append('model_id', self.modelId.value);
                        data.append('apply_from', self.applyFromDateTime);
                        data.append('type', self.series);
                        data.append('file_name', self.fileName);
                        data.append('order_type', self.orderType);
                        data.append('option', self.option);
                        data.append('description', self.description);
                        data.append('rrp', self.rrp);
                        data.append('gst', self.gst);
                        data.append('list_price', self.listPrice);
                        data.append('dealer_discount', self.dealerDiscount);
                        data.append('dealer_net', self.dealerNet);
                        data.append('conquest', self.conquest);
                        data.append('fituptotal', self.fitUpTotal);
                        data.append('pre_delivery', self.preDelivery);
                        data.append('cylinder', self.cylinderType.value);
                        data.append('llp', self.llp);
                        data.append('exempt_from_net_revenue_increase', self.exemptFromNetRevenueIncrease.value);
                        data.append('dealer_disc_1', self.dealerDisc1);
                        data.append('dealer_disc_2', self.dealerDisc2);
                        data.append('dealer_disc_3', self.dealerDisc3);
                        data.append('net_revenue_increase', self.netRevenueIncrease);
                        data.append('total_dealer_discount', self.totalDealerDiscount);
                        data.append('in_percentage_of_llp', self.inPercentageOfLlp);
                        data.append('dealer_net_price', self.dealerNetPrice);
                        data.append('discount_percentage_price_sheet_original', self.discountPercentagePriceSheetOriginal);
                        data.append('discount_percentage_sap', self.discountPercentageSap);
                        data.append('discount_percentage_matts_file', self.discountPercentageMattsFile);
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                        }

                        HTTP.post("/price/book/add/update", data, config)
                            .then(function (response) {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }

                                if (response.data.status == "success") {
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        setTimeout(function () {
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>