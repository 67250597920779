<template>
  <div>
    <div
      class="modal"
      id="mailHistory"
      tabindex="-1"
      role="dialog"
      v-bind:style="{ zIndex: parentModalShowHide }"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <div>{{ labelMessage.mail_history }} ({{ curDealNo }})</div>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mail-history-modal-body">
            <div class="card">
              <table class="table custom-table jsmartable">
                <caption style="display: none">
                    {{ labelMessage.mail_history }}
                </caption>
                <thead id="mail-history-modal">
                  <th>{{ labelMessage.mail_history_date }}</th>
                  <th>{{ labelMessage.mail_history_from }}</th>
                  <th>{{ labelMessage.mail_history_to }}</th>
                  <th>{{ labelMessage.mail_history_subject }}</th>
                  <th>{{ labelMessage.mail_history_deal_status }}</th>
                  <th>Action</th>
                </thead>
                <tbody v-if="loader">
                  <tr>
                    <td colspan="6">
                      <loader />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template v-if="mailHistoryList.length > 0">
                    <tr
                        v-for="mailHistory in mailHistoryList"
                        :key="mailHistory.id"
                    >
                        <td>{{ mailHistory.mail_date }}</td>
                        <td>{{ mailHistory.mail_from }}</td>
                        <td>{{ mailHistory.mail_to }}</td>
                        <td class="mhtData">{{ mailHistory.mail_subject }}</td>
                        <td>{{ mailHistory.deal_status }}</td>
                        <td>
                        <a href="javascript:void(0)" @click="viewMailDetail(mailHistory.mail_details)" class="pl-3" title="View Mail Details"><i
                            aria-hidden="true"
                            class="fa fa-eye text-primary"
                        ></i></a>
                        </td>
                    </tr>
                  </template>
                <tr v-else>
                    <td colspan="6" class="text-center">
                        {{ labelMessage.no_records_to_display_label }}
                    </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mail-history-detail :isClickMailHistoryDetail="isClickMailHistoryDetail" :mailDetails="mailDetails" :modelTitle="labelMessage.mail_history_detail" />
  </div>
</template>

<script>
import eventBus from "../../../../event-bus";
import { HTTP } from "../../../../../http-common";
import Loader from "../Loader.vue";
import mailHistoryDetail from "./MailHistoryDetailModal.vue";

let selfThis;
export default {
  name: "MailHistoryModal",
  props: ["isClickMailHistory", "dealNo", "dealId", "labelMessage"],
  components: {
    loader: Loader,
    "mail-history-detail": mailHistoryDetail,
  },
  data() {
    return {
      loader: false,
      curDealNo: "",
      mailHistoryList: [],
      isClickMailHistoryDetail: false,
      parentModalShowHide: 1042,
      mailDetails: "",
    };
  },
  beforeMount() {
    selfThis = this;
  },
  methods: {
    closeModal() {
      eventBus.$emit("close-mail-history", false);
      this.parentModalShowHide = 1042;
    },
    getMailHistoryLog() {
      this.loader = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      HTTP.get("/mail/history/" + this.dealId, config)
        .then((response) => {
          if (response.data.status === "success") {
            this.mailHistoryList = response.data.content;
          }
        })
        .catch(function (err) {
          console.log("ERROR!!!", err);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    viewMailDetail(mailDetails) {
      this.parentModalShowHide = 1040;
      this.mailDetails = mailDetails;
      this.isClickMailHistoryDetail = true;
      $("#mailHistoryDetail").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
  },
  watch: {
    isClickMailHistory(status) {
      if (status) {
        this.curDealNo = this.dealNo;
        this.getMailHistoryLog();
      }
    },
  },
};

eventBus.$on("close-mail-history-detail", function () {
  if (typeof selfThis != "undefined") {
    selfThis.parentModalShowHide = 1042;
    selfThis.isClickMailHistoryDetail = false;
    $("#mailHistoryDetail").modal("hide");
  }
});
</script>

<style scoped>
.mail-history-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#mail-history-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

.table.custom-table td {
  vertical-align: middle;
  white-space: unset;
}

.table.custom-table td:nth-child(3){
  max-width: 209px;
}
</style>
