<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_branch_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Name-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div>

                            <!--Abbreviation-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.abbreviation}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="abbreviationError = ''" id="abbreviation" v-model="abbreviation" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{abbreviationError}}</span>
                                </div>
                            </div>

                            <!--Address1-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.addr1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="address1Error = ''" id="address1" v-model="address1" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{address1Error}}</span>
                                </div>
                            </div>

                            <!--Address2-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.addr2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="address2Error = ''" id="address2" v-model="address2" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{address2Error}}</span>
                                </div>
                            </div>

                            <!--Postal Code-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.postal_code}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="postalCodeError = ''" id="postalCode" v-model="postalCode" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{postalCodeError}}</span>
                                </div>
                            </div>

                            <!--County-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.county}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="countyError = ''" id="county" v-model="county" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{countyError}}</span>
                                </div>
                            </div>

                            <!-- Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="locationId"
                                              name="state"
                                              label="text"
                                              :filterable="false" :options="locationArr"
                                              :search="false" :clearable="false" @input="locationError = ''"
                                              :placeholder="staticMessages.location_place_holder" class="custom-ci-auto-search">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>

                            <!--Phone1-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone1}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phone1Error = ''" id="phone1" v-model="phone1" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{phone1Error}}</span>
                                </div>
                            </div>

                            <!--Phone2-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.phone2}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="phone2Error = ''" id="phone2" v-model="phone2" class="form-control" type="text"/>
                                    <span class="help is-danger">{{phone2Error}}</span>
                                </div>
                            </div>

                            <!--Fax-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fax}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="faxError = ''" id="fax" v-model="fax" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{faxError}}</span>
                                </div>
                            </div>

                            <!--Email-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>

                            <!-- Branch Status -->
                            <div v-show="permissionNameArray.includes('can_active_inactive_branch_master')" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.branch_status}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Role"
                                              v-model="branchStatusId"
                                              @change="changedValue"
                                              @input="branchStatusError = ''"
                                              name="roleId"
                                              id="roleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="branchStatusArr"
                                              :placeholder="staticMessages.branch_status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{branchStatusError}}</span>
                                </div>
                            </div>

                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUpdateBranch ()">
                            <input v-show="showSaveAndContinue" id="addAndContinueBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addUpdateBranch (flag='Save and Continue')">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>

                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"

    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage)

    export default {
        name: 'AddUpdateBrakeType',
        mixins: [commonMixins],
        data () {
            return {

                /* new vars */
                staticInputLabelMessages: "",
                staticMessages: "",
                name : "",
                nameError : "",

                abbreviation : "",
                abbreviationError : "",

                address1 : "",
                address1Error : "",

                address2 : "",
                address2Error : "",

                postalCode : "",
                postalCodeError : "",

                county : "",
                countyError : "",

                locationId : "",
                locationArr : [],
                locationError : "",

                phone1 : "",
                phone1Error : "",

                phone2 : "",
                phone2Error : "",

                email : "",
                emailError : "",

                fax : "",
                faxError : "",

                branchStatusId : "Active",
                branchStatusError : "",
                branchStatusArr : [
                    {
                        text : "Active",
                        value : 'active'
                    },
                    {
                        text : "Inactive",
                        value : 'inactive'
                    }
                ],
                /* end */
                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                showSaveAndContinue : true,
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Branch",
                permissionNameArray : ['can_add_branch_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('braketype-list');
            EventBus.$emit("menu-selection-changed", "braketype-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            self.loadAllData(['Location'], true, self.loadLocationCallback, "undefined", '');
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getBranchDetailsById();
                self.buttonNames.save = "Update";
                self.showSaveAndContinue = false;
                self.pageTitle = "Edit Branch";
            }
        },
        methods: {
            changedValue : function () {

            },
            loadLocationCallback : function (callbackData) {
                self.locationArr = callbackData.Location;
            },
            buttonBack() {
                self.$router.push({ name: 'ListBranch'});
            },

            clearDataOnSaveAndContinue : function () {
                self.name = '';
                self.abbreviation = '';
                self.address1 = '';
                self.address2 = '';
                self.postalCode = '';
                self.county = '';
                self.locationId = '';
                self.phone1 = '';
                self.phone2 = '';
                self.fax = '';
                self.email = '';
                self.branchStatusId = {
                    text : "Inactive",
                    value : 'inactive'
                };
            },

            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },

            getBranchDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };

                HTTP.get('/branch/get', config)
                    .then(response => {
                        self.name = response.data.content.name;
                        self.abbreviation = response.data.content.abbreviation;
                        self.address1 = response.data.content.addr1;
                        self.address2 = response.data.content.addr2;
                        self.postalCode = response.data.content.postal_code;
                        self.county = response.data.content.county;
                        self.phone1 = response.data.content.phone1;
                        self.phone2 = response.data.content.phone2;
                        self.fax = response.data.content.fax;
                        self.email = response.data.content.email;
                        self.locationId = {text : response.data.content.location_name, value : response.data.content.location_id};
                        self.branchStatusId = response.data.content.inactive == "yes" ?
                            {text: "Active", value: "active"} :  {text: "Inactive", value: "inactive"}
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },

            checkForm: function () {
                var checked = true;

                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.abbreviation) {
                    self.abbreviationError = "Please enter abbreviation";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.address1) {
                    self.address1Error = "Please enter address1";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.address2) {
                    self.address2Error = "Please enter address2";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.postalCode) {
                    self.postalCodeError = "Please enter postal code";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.postalCode && !self.numberFormateValidation(self.postalCode)) {
                    self.postalCodeError = "Please enter numeric value";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.county) {
                    self.countyError = "Please enter county";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select location";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.phone1) {
                    self.phone1Error = "Please enter phone1";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }else if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }

                return checked;
            },

            returnData: function () {
                var data = {
                    name : self.name,
                    abbreviation : self.abbreviation,
                    address1 : self.address1,
                    address2 : self.address2,
                    postal_code : self.postalCode,
                    county : self.county,
                    location_id : self.locationId.value,
                    phone1 : self.phone1,
                    phone2 : self.phone2,
                    email : self.email,
                    fax : self.fax,
                    branch_status : self.branchStatusId.value,
                }
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.id = self.$route.params.id;
                }

                return data;
            },

            addUpdateBranch (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/add/update/branch", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>