<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_list_customer_master')">
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <div class="page_title_left">
                            <span class="pl-0">Contacts</span>
                            <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
                        </div>
                        <a v-show="permissionNameArray.includes('can_add_customer_master')"
                            class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                            v-on:click.prevent="openAddContactPopup()">
                            <span>Add Contact</span>
                        </a>
                    </div>

                    <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                        
                    </div>

                    <div class="col-12 paddingnone">
                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page" v-model="perPage" @input="changePerPage"
                                        name="customPerPage" id="customPerPage" label="text" :clearable="false"
                                        :searchable="false" :filterable="false" :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{ startEndRecord }} of {{ totalRecords }}
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous"
                                        next-text="Next ›" :total-rows="totalRecords" v-model="currentPage"
                                        @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm"
                                        name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()"
                                        placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->

                        <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                            <table class="table custom-table jsmartable" >
                                <tbody>
                                    <template v-if="contactArr && contactArr.length > 0">
                                        <tr v-for="(contact, index) in contactArr" :key="index">
                                            <td :key="index+'__action'">
                                                <div class="actions" style="width: 120px;">
                                                    <router-link
                                                        v-show="permissionNameArray.includes('can_add_customer_master')"
                                                        class="mar-right-0" title='Edit Customer Contact'
                                                        v-bind:to="{ name: 'UpdateCustomerContact', params: { customer_id: customer_id, contact_id: contact.id } }">
                                                        <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                    </router-link>
                                                    <a href="javascript:void(0)" title="Delete" class="mar-right-0" @click="deleteContact(contact.id)"><i aria-hidden="true" class="exceptEdit fa fa-trash-o"></i></a>
                                                </div>
                                            </td>

                                            <template v-for="(headerData, headerIndex) in headers">
                                                <template v-if="(headerData.name != 'Action' && headerData.column_name) && (typeof headerData.display == 'undefined' || headerData.display == true)">
                                                    <td class="td-hover-handle" :key="index+'_'+headerIndex">{{ contact[headerData.column_name] }}</td>
                                                </template>
                                            </template>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                                {{ allStaticMessages.data_not_available }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <thead>
                                    <tr>
                                        <template v-for="(data, headerIndex) in headers">
                                            <th class="" :key="headerIndex" v-if="data.name == 'Action'"
                                                data-breakpoint="xs">{{ data.name }}</th>
                                        </template>
                                        
                                        <template v-for="(data, headerIndex) in headers">
                                            <th :class="data.column_full_class + ' column-header'"
                                                v-if="data.name != 'Action' && (typeof data.display == 'undefined' || data.display == true)"
                                                :key="headerIndex"
                                                v-on:click.prevent="sortTableData(data.column_name, data.sort)">
                                                {{ data.name }}</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>


                        <!-- Pagination Start -->
                        <div class="top_pagination vgt-wrap__footer crm">
                            <div class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page" v-model="perPage" @input="changePerPage"
                                        name="customPerPage" id="customPerPage" label="text" :clearable="false"
                                        :searchable="false" :filterable="false" :options="paginationDropDownArr"
                                        placeholder="Select Per Page">
                                    </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">
                                        {{ startEndRecord }} of {{ totalRecords }}
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous"
                                        next-text="Next ›" :total-rows="totalRecords" v-model="currentPage"
                                        @input="changepage()" :per-page="perPageValue"></b-pagination>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm"
                                        name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()"
                                        placeholder="Page">
                                </div>
                            </div>
                        </div>
                        <!-- Pagination End -->
                    </div>
                </div>


                <customer-contact-form-popup ref="customerContactFormPopup"></customer-contact-form-popup>
            </div>
        </template>
        
    </div>    
</template>

<script>
var self;
import EventBus from ".././event-bus";
import { HTTP } from '../../http-common';

import { commonMixins } from '../../mixins/commonMixins';
import CustomerContactFormPopup from './CustomerContactFormPopup.vue';
import {deleteCustomerContact } from './customer.js';
export default {
    name: 'CustomerContactList',
    mixins: [commonMixins],
    components: {
        CustomerContactFormPopup
    },
    props: {
        customer_id: {
            type: [String, Number],
            required: false,
            default: null
        },
        customer_name: {
            type: [String, Number],
            required: false,
            default: null
        },
        abn_no: {
            type: [String, Number],
            required: false,
            default: null
        },
    },
    
    data() {
        return {
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
            staticInputLabelMessages: "",
            staticMessages: "",
            permissionNameArray: ['can_list_customer_master'],
            actionDisplay: true,
            selectedRows: [],
            totalRecords: 0,
            perPageAllRecords: 10,
            perPageValue: 25,
            perPage: 25,
            currentPage: 1,
            totalRecords: 0,
            paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
            contactArr: [],
            headers: [],
            filters: {
            },
            startEndRecord: "",
            jumpToPage: 1,

        }
    },
    beforeMount() {
        self = this;
    },
    created: function () {
        self = this;
        self.getLoginIdPermissions();
    },
    watch: {

    },
    mounted: function () {
        /* Redirect to login page logic start */
        var userObj = localStorage.getItem("user_obj");
        if (userObj == null) {
            self.$router.push('/');
        }
        /* Redirect to login page logic end */

        self.staticMessages = self.allStaticMessages;
        self.staticInputLabelMessages = self.allLabelMessages;

        self.loadContact();

        EventBus.$on('customer-contact-saved', function (data) {
        self.loadContact();
        });

    },
    methods: {
        openAddContactPopup: function () {
            let data = {
                customer_id: self.customer_id,
                customer_name: self.customer_name,
                abn_no: self.abn_no
            };
            
            self.$refs.customerContactFormPopup.openAddContactPopup(data);
        },
        changePerPage: function () {
            self.currentPage = 1;
            if (self.perPage !== "All") {
                self.perPageValue = self.perPage;
            }
            self.loadContact();
        },
        sortTableData: function (name, isSortAllowed) {
            if (isSortAllowed == "nosort") {
                if (self.columnName == name) {
                    self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
                } else {
                    self.sortType = "asc";
                }
                self.columnName = name;
                self.loadContact();
            }
        },
        changepage: function () {
            self.loadContact();
        },
        keyMonitor: function () {
            var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
            self.currentPage = self.jumpToPage = parseInt(tempNumber);
        },
        loadContact: function (first = null) {
            if (self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
                self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                self.perPage = 500;
                self.loadContact();
            }

            first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
            

            var fields = {
                customer_id: self.customer_id,
                filters: self.filters,
                per_page: self.perPage,
                page: self.currentPage,
                sortColumnName: self.columnName,
                sortType: self.sortType,
                per_page_all_record: self.perPageAllRecords,
            };
            var config = {
                headers: { Authorization: "Bearer " + localStorage.getItem("api_token") },
                params: fields
            };

            HTTP.get("/customer/contact", config)
            .then(function (data) {
                if (first) {
                    self.currentPage = self.jumpToHistoryPage;
                }

                self.headers = [];
                self.totalRecords = data.data.content.total_record;
                if (self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
                    let seconds = 5000;
                    self.$swal({
                        type: 'error',
                        html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                        showConfirmButton: false,
                        timer: seconds,
                    });
                    setTimeout(() => {
                        self.perPage = 500;
                        self.loadContact();
                    }, seconds);
                }

                self.contactArr = data.data.content.contactListData;
                self.headers = data.data.content.headers;



            }).catch(function (error) {
                self.hideLoader();
                self.catchHandler(error, function () { });
            });
        },
        deleteContact: function(id) {
            const deleteContactCallbackFunction = (data, flag) => {
                if (data.code == '200') {
                    self.showSAlert(data.message, 'success', false);
                    self.loadContact();
                }else{
                    self.showSAlert(data.message, 'error', false);
                }
            };

            self.$swal({
                    title: '',
                    text: 'Are you sure you want to delete this Customer Contact?',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    /* showCloseButton: true, */
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        deleteCustomerContact(id, deleteContactCallbackFunction);
                    }
                }).catch(function (error) {

                });
        }
    }
}

EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
        self.permissionNameArray = [];
        for (let i = 0; i < permissions.length; i++) {
            self.permissionNameArray.push(permissions[i].permission_name)
        }
        if (!self.permissionNameArray.includes('can_add_customer_master') && !self.permissionNameArray.includes('can_delete_customer_master') && !self.permissionNameArray.includes('can_active_inactive_customer_master')) {
            setTimeout(function () {
                self.headers.pop();
                self.actionDisplay = false;
            }, 50);
        }
    }
});
</script>