import { HTTP } from "../../http-common";

export function saveCustomerContactData(formData, callback) {
    // Form check 
    let checkedData = checkCustomerContactForm(formData.form_fields);
    if (checkedData.is_valid) {
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token")
            }
        };

        //Request Data
        let data = customerContactReqData(formData.form_fields);
        data.customer_id = formData.customer_id;
        data.contact_id = formData.contact_id ? formData.contact_id : "";

        HTTP.post("/customer/contact/store", data, config)
        .then(function (response) {
            // Call the callback function with parameters
            if (typeof callback === 'function') {
                callback(response.data, 'reqResponse');
            }
        })
        .catch(function (err) {

        });
    } else {
        // Call the callback function with parameters
        if (typeof callback === 'function') {
            callback(checkedData, 'validationError');
        }
    }
}


export function customerContactReqData(formFields) {
    let data = {};
    if(formFields) {
        $.each(formFields, function(index, obj){
            if(obj.type == "dropdown_modal_search") {
                data[obj.column_name] = obj.value.text;
                data[obj.column_name+'_id'] = obj.value.value;
            }else {
                data[obj.column_name] = obj.value;
            }
        });
    }
    return data;
}

export function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function checkCustomerContactForm(formData) {
    let isValid = true;
    let phoneRegex = /^(\+\d{10,15}|\d{10,15})$/;
    if(formData) {
        $.each(formData, function(key, obj){
            if(obj.display && obj.mandatory_validation && ((obj.type == "input_text" && !obj.value) || (obj.type == "dropdown_modal_search" && !obj.value.value))) {
                isValid = false;
                obj.error = true;
            }else if(typeof obj.other_validation != "undefined" && obj.other_validation != "") {
                if(obj.other_validation == 'email' && !validEmail(obj.value)) {
                    isValid = false;
                    obj.error = true;
                    obj.error_msg = "Please enter valid email";
                }else if(obj.other_validation == 'phone_number' && !phoneRegex.test(obj.value)) {
                    isValid = false;
                    obj.error = true;
                    obj.error_msg = "Invalid phone number. Please enter a valid number.";
                }else{
                    obj.error = false;
                }
            }else{
                obj.error = false;
            }
        });
    }
    return {"is_valid": isValid,form_fields: formData};
}



export function deleteCustomerContact(contactId, callback){
    let config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
        }
    };
    HTTP.delete("/customer/contact/delete/"+contactId, config)
    .then(function (response) {
        // Call the callback function with parameters
        if (typeof callback === 'function') {
            callback(response.data, 'reqResponse');
        }
    })
    .catch(function (err) {

    });
}

export function getCustomerContactDataForEdit(data, callback){
    var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {contact_id: data.contact_id, customer_id: data.customer_id}
      };

      HTTP.get("/customer/contact/edit/"+data.contact_id, config)
        .then(response => {
            if(response.data && response.data.status == "success") {
                let formFields = JSON.parse(JSON.stringify(data.form_fields));
                if(response.data.content && response.data.content.contact){
                    let contact = response.data.content.contact;
                    if(contact.name) {
                        formFields.name.value = contact.name;
                    }
                    if(contact.email_work) {
                        formFields.email.value = contact.email_work;
                    }
                    if(contact.phone_work) {
                        formFields.phone_number.value = contact.phone_work;
                    }
                    if(contact.typ) {
                        formFields.job_title.value = contact.typ;
                    }
                    if(contact.department) {
                        formFields.department.value = contact.department;
                    }

                    if(contact.city) {
                        formFields.city.value.text = contact.city;
                    }
                    if(contact.city_id) {
                        formFields.city.value.value = contact.city_id;
                    }

                    if(contact.state) {
                        formFields.state.value.text = contact.state;
                    }
                    if(contact.state_id) {
                        formFields.state.value.value = contact.state_id;
                    }
                    if(contact.is_primary) {
                        formFields.is_primary.value = true;
                    }
                }

                // Call the callback function with parameters
                let callBackData = {
                    form_fields: formFields
                };
                if (typeof callback === 'function') {
                    callback(callBackData, 'reqResponse');
                }
            }
            
        })
        .catch(function (err) {

        });
}
  