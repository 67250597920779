<template>
    <div class="spaced-container">
        <!-- Page Content Wrapper -->
        <div id="page-content-wrapper" class="admin-content-area">
            <!-- <template v-if="permissionNameArray.includes('can_add_inventory_master')"> -->
                <div class="container-fluid xyz">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>
                        <button type="submit" v-on:click="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                    <!--page content-->
                    <div class="admin-page-content">
                        <!--Forms-->
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                            <div class="row no-margin">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=1" v-bind:class="'nav-link ' + [ activetab === 1 ? 'active' : '' ]">Core</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_specification_inventory_master')">
                                        <a v-on:click="activetab=2" v-bind:class="'nav-link ' + [ activetab === 2 ? 'active' : '' ]">Spec</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=3" v-bind:class="'nav-link ' + [ activetab === 3 ? 'active' : '' ]">Text</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_close_inventory_master')">
                                        <a v-on:click="activetab=4" v-bind:class="'nav-link ' + [ activetab === 4 ? 'active' : '' ]">Close</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=5" v-bind:class="'nav-link ' + [ activetab === 5 ? 'active' : '' ]">Warranty</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=6" v-bind:class="'nav-link ' + [ activetab === 6 ? 'active' : '' ]">Dates</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=7" v-bind:class="'nav-link ' + [ activetab === 7 ? 'active' : '' ]">Prices</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=8" v-bind:class="'nav-link ' + [ activetab === 8 ? 'active' : '' ]">People</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=9" v-bind:class="'nav-link ' + [ activetab === 9 ? 'active' : '' ]">Inventory Image</a>
                                    </li>
                                    <li class="nav-item" v-if="permissionNameArray.includes('can_add_inventory_master')">
                                        <a v-on:click="activetab=10" v-bind:class="'nav-link ' + [ activetab === 10 ? 'active' : '' ]">Copy Inventory</a>
                                    </li>
                                </ul>

                                <div v-if="activetab === 1" class="tabcontent" id="customer-activity-logs1">
                                    <edit-asset :asset="assetData" v-bind:key="activetab"></edit-asset>
                                </div>
                                <div v-if="activetab === 2" class="tabcontent" id="customer-activity-logs2">
                                    <edit-spec2 :asset="assetData" v-bind:key="activetab"></edit-spec2>
                                </div>
                                <div v-if="activetab === 3" class="tabcontent" id="customer-activity-logs3">
                                    <edit-text :asset="assetData" v-bind:key="activetab"></edit-text>
                                </div>
                                <div v-if="activetab === 4" class="tabcontent" id="customer-activity-logs4">
                                    <edit-close :asset="assetData" v-bind:key="activetab"></edit-close>
                                </div>
                                <div v-if="activetab === 5" class="tabcontent" id="customer-activity-logs5">
                                    <edit-warranty :asset="assetData" v-bind:key="activetab"></edit-warranty>
                                </div>
                                <div v-if="activetab === 6" class="tabcontent" id="customer-activity-logs6">
                                    <edit-date :asset="assetData" v-bind:key="activetab"></edit-date>
                                </div>
                                <div v-if="activetab === 7" class="tabcontent" id="customer-activity-logs7">
                                    <edit-prices :asset="assetData" v-bind:key="activetab"></edit-prices>
                                </div>
                                <div v-if="activetab === 8" class="tabcontent" id="customer-activity-logs8">
                                    <edit-people :asset="assetData" v-bind:key="activetab"></edit-people>
                                </div>
                                <div v-if="activetab === 9" class="tabcontent" id="customer-activity-logs9">
                                    <edit-inventory-image :asset="assetData" v-bind:key="activetab"></edit-inventory-image>
                                </div>
                                <div v-if="activetab === 10" class="tabcontent" id="customer-activity-logs10">
                                    <copy-inventory :asset="assetData" v-bind:key="activetab"></copy-inventory>
                                </div>
                            </div>
                        </div>
                        <!-- Forms -->
                    </div>
                    <!--page content-->
                </div>
            <!-- </template>
            <template v-else>
                <no-access></no-access>
            </template> -->
            <app-footer></app-footer>
        </div>
        <!-- Page Content Wrapper -->
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';

    import EditAsset from "@/components/AssetMaster/MultipleAction/EditAsset";
    import EditPrices from "@/components/AssetMaster/MultipleAction/EditPrices";
    import EditWarranty from "@/components/AssetMaster/MultipleAction/EditWarranty";
    import EditText from "@/components/AssetMaster/MultipleAction/EditText";
    import EditDate from "@/components/AssetMaster/MultipleAction/EditDate";
    import EditPeople from "@/components/AssetMaster/MultipleAction/EditPeople";
    import EditSpec2 from "@/components/AssetMaster/MultipleAction/EditSpec2";
    import EditClose from "@/components/AssetMaster/MultipleAction/EditClose";
    import EditInventoryImage from "@/components/AssetMaster/MultipleAction/EditInventoryImage";
    import CopyInventory from "@/components/AssetMaster/MultipleAction/CopyInventory";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'ViewAsset',
        components: {
            "edit-asset": EditAsset,
            "edit-prices": EditPrices,
            "edit-warranty": EditWarranty,
            "edit-text": EditText,
            "edit-date": EditDate,
            "edit-people": EditPeople,
            "edit-spec2": EditSpec2,
            "edit-close": EditClose,
            "edit-inventory-image": EditInventoryImage,
            "copy-inventory": CopyInventory,
            'no-access': NoAccessPage,
        },

        mixins: [commonMixins],
        data() {
            return {
                selectedID: 0,
                activetab: 0,
                pageTitle: "Edit Multiple Inventory",
                assetData: {
                    content: {

                    },
                },
                permissionNameArray: ['can_add_inventory_master','can_specification_inventory_master','can_close_inventory_master'],
                updateActivetab: 1
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.selectedID = self.$store.state.selectedData;
            if (self.selectedID.length <= 0) {
                self.buttonBack();
            } else {
                self.setActiveMenu('asset-list');
                EventBus.$emit("menu-selection-changed", "asset-list");
                self.getData();
                self.getLoginIdPermissions();
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            getData: function () {
                /*console.log(self.selectedID);*/
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},

                    params: {
                        id: self.selectedID,
                    }
                };
                self.showLoader();
                HTTP.get("/asset/getmulty", config)
                        .then(function (data) {
                            self.hideLoader();
                            if (data.status == 200) {
                                self.assetData = data.data;
                                self.asset = self.assetData;
                                /*self.pageTitle = "VIN : " + self.assetData.content.vin_no + " | ";
                                 self.pageTitle += self.assetData.content.year + " ";
                                 self.pageTitle += self.assetData.content.make_name + " ";
                                 self.pageTitle += self.assetData.content.model_name;*/
                            }
                            self.$nextTick(() => {
                                self.activetab = self.updateActivetab;
                            });
                        })
                        .catch(function (error) {
                            self.hideLoader();
                            self.catchHandler(error, function () {});
                        })
            }
        }

    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
    EventBus.$on("update-multi-edit-tab-view-data", function (updateInventoryTab) {
        if (typeof self != "undefined") {
            self.updateActivetab = updateInventoryTab;
            self.getData();
        }
    });
</script>