<template>
    <div>
        <div class="modal" v-bind:style="{zIndex: parentModalShowHide}" id="vehiclePurchaseModal" tabindex="-1" role="dialog">
            <div v-if="isClickPurchaseOrder" class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h4 class="modal-title" id="vehiclePurchaseModalLabel">
                        Vehicle Purchase Order
                    </h4>
                    <button type="button" class="close" aria-label="Close" @click="closePurchaseOrderModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <div class="card">
                            <table class="table custom-table jsmartable">
                                <tr>
                                    <td colspan="3"><span class="bold float-left">Excede PO#:</span> <input type="text" class="form-control input-sm" style="width: 200px; margin-left: 6rem !important;"  /></td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span class="bold float-left">Branch:</span>
                                                <v-select
                                                    v-model="location"
                                                    name="locationId"
                                                    label="text"
                                                    :filterable="false"
                                                    :options="locationArr"
                                                    @search="(search, loading) => onSearchData(search, loading, 'Location')"
                                                    :clearable="true"
                                                    @input=""
                                                    class="custom-ci-auto-search"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </v-select>
                                                <div class="requiredstar">*</div>
                                                <span :class="branchError=='' ? 'hide' : 'help is-danger'">{{branchError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span class="bold float-left">Vendor ID:</span> 
                                                <v-select
                                                    v-model="vendor"
                                                    name="vendorId"
                                                    label="vendor_id"
                                                    :filterable="false"
                                                    :options="vendorArr"
                                                    @search="(search, loading) => onSearchData(search, loading, 'Vendor')"
                                                    v-on:change="populateVendorRelated"
                                                    :clearable="true"
                                                    class="custom-ci-auto-search"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </v-select>
                                                <div class="requiredstar">*</div>
                                                <span :class="vendorError=='' ? 'hide' : 'help is-danger'">{{vendorError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <span class="bold float-left">Vendor Name:</span> 
                                        <input type="text" v-model="vendorName" class="form-control input-sm" style="width: 200px; margin-left: 6rem !important;" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Ship Code:</span>
                                                <v-select
                                                    v-model="ship"
                                                    name="shipId"
                                                    label="des"
                                                    :filterable="false"
                                                    :options="shipArr"
                                                    @search="(search, loading) => onSearchData(search, loading, 'Ship')"
                                                    :clearable="true"
                                                    class="custom-ci-auto-search"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </v-select>
                                                <div class="requiredstar">*</div>
                                                <span :class="shipError=='' ? 'hide' : 'help is-danger'">{{shipError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Terms:</span>
                                                <v-select
                                                    v-model="term"
                                                    name="termId"
                                                    label="des"
                                                    :filterable="false"
                                                    :options="termArr"
                                                    @search="(search, loading) => onSearchData(search, loading, 'Term')"
                                                    :clearable="true"
                                                    class="custom-ci-auto-search"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </v-select>
                                                <div class="requiredstar">*</div>
                                                <span :class="termError=='' ? 'hide' : 'help is-danger'">{{termError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Type:</span>
                                                <v-select
                                                    v-model="vechicleType"
                                                    name="type"
                                                    label="des_pur_typ"
                                                    :options="typeArr"
                                                    @search="(search, loading) => onSearchData(search, loading, 'VehiclePurchaseOrderType')"
                                                    :clearable="true"
                                                    class="custom-ci-auto-search"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </v-select>
                                                <div class="requiredstar">*</div>
                                                <span :class="vechicleTypeError=='' ? 'hide' : 'help is-danger'">{{vechicleTypeError}}</span> 
                                            </div>
                                        </div>       
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Stock#:</span>
                                                <input v-model="unitId" type="text" class="form-control input-sm" style="width: 200px; margin-left: 6rem !important;" />
                                                <div class="requiredstar">*</div>
                                                <span :class="stockError=='' ? 'hide' : 'help is-danger'">{{stockError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">VIN:</span>
                                                <input v-model="vin" type="text" class="form-control input-sm" style="width: 200px; margin-left: 6rem !important;" />
                                                <div class="requiredstar">*</div>
                                                <span :class="vinError=='' ? 'hide' : 'help is-danger'">{{vinError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="bold float-left">Description:</span>
                                        <input v-model="description" type="text" class="form-control input-sm" style="width: 200px; margin-left: 6rem !important;" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Order Date:</span>
                                                <date-picker
                                                    v-model="orderDate"
                                                    :config="options"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    placeholder="Select Order Date"
                                                    name="orderDate"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </date-picker>
                                                <span class="requiredstar">*</span>
                                                <span :class="orderDateError=='' ? 'hide' : 'help is-danger'">{{orderDateError}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="bold float-left">Expected Date:</span>
                                                <date-picker
                                                    v-model="expectedDate"
                                                    :config="options"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    placeholder="Select Expected Date"
                                                    name="expectedDate"
                                                    style="width: 200px; margin-left: 6rem !important;"
                                                >
                                                </date-picker>
                                                <div class="requiredstar">*</div>
                                                <span :class="expectedDateError=='' ? 'hide' : 'help is-danger'">{{expectedDateError}}</span> 
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="text-center mt-4 pt-4">
                                        <input type="checkbox" v-model="isExcludePo" /><span>Exclude PO from Taxable Amount</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        <button class="btn btn-primary" @click="addItemModal()">Add Item</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <table class="table">
                                            <tr>
                                                <td>DESCRIPTION</td>
                                                <td>COST</td>
                                                <td>PRICE</td>
                                                <td>ADD TO PRICE</td>
                                                <td>EXCL FROM FET</td>
                                                <td>SHOW ON DEC</td>
                                                <td>DO NOT ADD TO GP</td>
                                            </tr>
                                            <tr v-for="(item,index) in itemList" :key="item.index">
                                                <td>{{ item.description }}</td>
                                                <td>{{ item.cost }}</td>
                                                <td>{{ item.price }}</td>
                                                <td><input type="checkbox" v-model="item.is_add_to_total_price" disabled></td>
                                                <td><input type="checkbox" v-model="item.is_fet_exempt" disabled></td>
                                                <td><input type="checkbox" v-model="item.is_show_on_documents" disabled></td>
                                                <td><input type="checkbox" v-model="item.is_do_not_add_to_gp" disabled></td>
                                                <td>
                                                    <a v-on:click="editItems(item,index)" title='Edit Items' href="javascript:void(0)" class="mar-right-0">
                                                    <i aria-hidden="true" class="fa fa-edit"></i>
                                                    </a>
                                                    <!-- <a title='Delete Items' href="javascript:void(0)">
                                                    <i aria-hidden="true" class="fa fa-trash-o text-danger"></i>
                                                    </a> -->
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="savePurchaseOrder()" :disabled="itemList.length > 0 ? false : true">{{!isEditPO ? 'Save' : 'Update'}}</button>
                        <button class="btn btn-secondary" @click="closePurchaseOrderModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="isAddItem">
            <vehicle-purchase-item v-bind:isAddItem="isAddItem" v-bind:editPOItem="editPOItem"></vehicle-purchase-item>
        </div>
    </div>
</template>
  
<script>
import EventBus from "../../../event-bus";
import {HTTP} from '../../../../http-common';
import { commonMixins } from "../../../../mixins/commonMixins";
import datePicker from 'vue-bootstrap-datetimepicker';
import AddItemModal from "./AddItemModal.vue";
import moment from "moment";

var thisPO;
export default {
  name: 'VehiclePurchaseOrder',
  props: ['isClickPurchaseOrder','unitId','vin','defaultLocation','assetId','editAssetId','poEditData'],
  mixins: [commonMixins],
  components: {
    datePicker,
    'vehicle-purchase-item': AddItemModal
  },
  data() {
    return {
        location: '',
        locationId: Number,
        locationArr: [],
        ship: '',
        shipId: Number,
        shipArr: [],
        term: '',
        termId: Number,
        termArr: [],
        vendor: '',
        vendorId: Number,
        vendorArr: [],
        fieldName: 'initial',
        vendorName: '',
        vechicleType: '',
        typeArr: [],
        description: 'Vehicle Purchase',
        orderDate: '',
        expectedDate: '',
        options: {
            format: 'MM/DD/YYYY',
            useCurrent: false,
            allowInputToggle: true,
            widgetPositioning: {
            horizontal: 'auto',
            vertical: 'bottom'
            },
        },
        isAddItem: false,
        parentModalShowHide: 1042,
        branchError: '',
        vendorError: '',
        shipError: '',
        termError: '',
        vechicleTypeError: '',
        stockError: '',
        vinError: '',
        orderDateError: '',
        expectedDateError: '',
        isExcludePo: false,
        itemList: [],
        populateVendor: true,
        editPOItem: [],
        isEditPO: false
    }
  },
  beforeMount() {
    thisPO = this;
  },
  methods: {
    closePurchaseOrderModal() {
        this.clearData();
        EventBus.$emit("close-purchase-order", false);
    },
    loadDataCallback: function (callbackData) {
        if (callbackData && callbackData.length <= 0) {
            return false;
        }
        switch (this.fieldName) {
            case 'Location':
                this.locationArr = callbackData.Location;
                break;
            case 'Ship':
                this.shipArr = callbackData.Ship;
                break;
            case 'Term':
                this.termArr = callbackData.Term;
                break;
            case 'Vendor':
                this.vendorArr = callbackData.Vendor;
                break;
            case 'VehiclePurchaseOrderType':
                this.typeArr = callbackData.VehiclePurchaseOrderType;
                break;
            default:
                this.locationArr = callbackData.Location;
                this.shipArr = callbackData.Ship;
                this.termArr = callbackData.Term;
                this.vendorArr = callbackData.Vendor;
                this.typeArr = callbackData.VehiclePurchaseOrderType;
                break;
        }
    },
    onSearchData(search, loading, type) {
        this.fieldName = type;
        if (search.length > 2) {
            loading(true);
            this.loadAllData(
                [type],
                true,
                this.loadDataCallback,
                loading,
                search
            );
        }
    },
    populateVendorRelated() {
        if(this.populateVendor && this.vendor != '') {
            this.vendorId = this.vendor.vendor_id;
            this.vendorName = this.vendor.text;
            console.log('vendorPopulate>>>', this.populateVendor, this.vendor);
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: {
                    vendor_id: this.vendorId
                }
            };

            HTTP.get("vendor/term", config)
                .then((response) => {
                const data = response.data.content;
                this.term = {
                    trm_id: data.trm_id,
                    des: data.des
                }
                this.vechicleType = {
                    pur_typ_id: data.pur_typ_id,
                    des_pur_typ: data.des_pur_typ
                }
                return true;
            })
            .catch(function (err) {
                console.log('error=>', err);
            });
        }
    },
    clearData() {
        this.location = this.defaultLocation;
        this.locationId= Number;
        this.ship= '';
        this.shipId= Number;
        this.term= '';
        this.termId= Number;
        this.vendor= '';
        this.vendorId= Number;
        this.fieldName= 'initial';
        this.vendorName= '';
        this.vechicleType= '';
        this.description= 'Vehicle Purchase';
        this.orderDate= '';
        this.expectedDate= '';
        return true;
    },
    addItemModal: function() {
        this.parentModalShowHide = 1040;
        this.isAddItem = true;
        $("#vehiclePurchaseItemModal").modal({backdrop: 'static', keyboard: false});
    },
    savePurchaseOrder() {
        if (this.checkPurchaseOrderForm()) {
            var config = {
                headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token")
                }
            };
            var data = {
                branch_id: this.location.value,
                vendor_id: this.vendor.vendor_id,
                vendor_name: this.vendorName,
                shp_code: this.ship.shp_id,
                terms: this.term.trm_id,
                vehicle_type: this.vechicleType.pur_typ_id,
                unit: this.unitId,
                vin_no: this.vin,
                summary: this.description,
                order_date: this.orderDate,
                expected_date: this.expectedDate,
                is_exclude_po: this.isExcludePo,
                asset_id: this.assetId,
                deal_inventory_id: this.editAssetId,
                item_list: !this.isEditPO ? this.itemList : ''
            };

            if(this.editAssetId != null || this.editAssetId !== 'undefined') {
                if(!this.isEditPO) {
                    HTTP.post('store/purchase/order',data,config)
                    .then(response => {
                        EventBus.$emit("purchase-item-list", response.data.content, 'save');
                    });
                } else {
                    HTTP.put("purchase-order/"+this.poEditData.asset_expenses_id, data, config)
                    .then(response => {
                        EventBus.$emit("purchase-item-list", response.data.content, 'update');
                    });
                }
            } else {
                EventBus.$emit("purchase-item-list", data, 'temp');
            }
        }
    },
    checkPurchaseOrderForm() {
        var checked = true;
        if (this.location == '') {
            this.branchError = "Please Select Branch from the list!";
            if (checked) {
                checked = false;
            }
        } else {
            this.branchError = '';
        }

        if (this.vendor == '') {
            this.vendorError = "Please Select Vendor from the list!";
            if (checked) {
                checked = false;
            }
        } else {
            this.vendorError = '';
        }

        if (this.ship == '') {
            this.shipError = "Please Select Ship from the list!";
            if (checked) {
                checked = false;
            }
        } else {
            this.shipError = '';
        }

        if (this.term == '') {
            this.termError = "Please Select Term from the list!";
            if (checked) {
                checked = false;
            }
        } else {
            this.termError = '';
        }

        if (this.vechicleType == '') {
            this.vechicleTypeError = "Please Select Type from the list!";
            if (checked) {
                checked = false;
            }
        } else {
            this.vechicleTypeError = '';
        }

        if (this.unitId == '') {
            this.stockError = "Stock id is empty!";
            if (checked) {
                checked = false;
            }
        } else {
            this.stockError = '';
        }

        if (this.vin == '') {
            this.vinError = "Vin is empty!";
            if (checked) {
                checked = false;
            }
        } else {
            this.vinError = '';
        }

        if (this.orderDate == '') {
            this.orderDateError = "Please select order date!";
            if (checked) {
                checked = false;
            }
        } else {
            this.orderDateError = '';
        }

        if (this.expectedDate == '') {
            this.expectedDateError = "Please select expected date!";
            if (checked) {
                checked = false;
            }
        } else {
            this.expectedDateError = '';
        }

        if(this.orderDate > this.expectedDate) {
            this.showSAlert('Order Date should be less than the Expected Date', 'info', false, 2);
        }
        return checked;
    },
    addItemObject(items) {
        this.itemList.push(items);
        console.log('afterItemList>>', this.itemList);
    },
    getEditData() {
        const id = this.poEditData.asset_expenses_id;
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          },
        };
        HTTP.get("purchase-order/"+id+"/edit", config)
        .then( response => {
          this.populateResponseData(response.data.content);
          setTimeout(() => {
            this.populateVendor = true;
          }, 500);
          return true;
        })
        .catch(function (err) {
          console.log('error=>', err);
        });
    },
    populateResponseData(data) {
        this.vendor = data.vendor_id;
        this.description = data.summary;
        this.vendorName = data.vendor_name;
        this.orderDate = moment(data.excede_order_date).format('MM/DD/YYYY');
        this.expectedDate = moment(data.excede_expect_date).format('MM/DD/YYYY');
        this.isExcludePo = data.is_exclude_po;
        const ship = this.shipArr.filter(shp => shp.shp_id == data.excede_shp_id);
        this.ship = ship[0];
        const vendor = this.vendorArr.filter(vendor => vendor.vendor_id == data.vendor_id);
        this.vendor = vendor[0];
        this.vendorName = data.vendor_name;
        const term = this.termArr.filter(term => term.trm_id == data.excede_trm_id);
        this.term = term[0];
        const vehicleType = this.typeArr.filter(vhc => vhc.pur_typ_id == data.excede_pur_typ_id);
        this.vechicleType = vehicleType[0];
        this.itemList = data.po_items;
        return true;
        /*Auto populated as per selected vin and unit
            this.vin = data.vin;
            this.unitId = data.excede_vunit_id;
        */
    },
    editItems(data, index) {
        this.parentModalShowHide = 1040;
        this.isAddItem = true;
        this.editPOItem = data;
        localStorage.setItem('po_item_index', index);
        $("#vehiclePurchaseItemModal").modal({backdrop: 'static', keyboard: false});
    },
    updateItemObject(item) {
        const itemIndex = localStorage.getItem('po_item_index');
        this.itemList[itemIndex] = item;
        localStorage.removeItem('po_item_index');
    },
    // deleteItem : function (id,index,assetExpensesId) {
    //   self.$swal({
    //       title: '',
    //       text: 'Are you sure that you want to delete this item!',
    //       type: 'info',
    //       confirmButtonText: 'OK',
    //       showCancelButton: true,
    //       showLoaderOnConfirm: true
    //   }).then((result) => {
    //     var config = {
    //         headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
    //         data : {
    //             asset_expenses_id: assetExpensesId,
    //         }
    //     };
    //     HTTP.delete("/purchase-order/"+id, config)
    //     .then(function (response) {
    //         if (response.data.code == '200') {
    //             let updateAddOnList = self.addOnPoList.filter((el) => el.id !== id);
    //             self.showSAlert(response.data.message, 'success', false, 2);
    //             self.addOnPoList = updateAddOnList;
    //             self.calVehiclePrice();
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log('error=>',error);
    //         self.catchHandler(error, function () {});
    //     });
    //   });
    // },
  },
  computed: {
    loadPurchaseOrderData() {
        console.log('defaultLocation>>>', this.defaultLocation);
        this.location = this.defaultLocation;
        this.loadAllData(
            [
             "Location",
             "Ship",
             "Term",
             "Vendor",
             'VehiclePurchaseOrderType'
            ],
            false,
            this.loadDataCallback,
            "undefined",
            ""
        );
    },
  },
  watch: {
    isClickPurchaseOrder(status) {
        if(status == true) {
            this.clearData();
            this.loadPurchaseOrderData;
            if(Object.keys(this.poEditData).length > 0) {
                this.populateVendor = false;
                this.isEditPO = true;
                setTimeout(() => {
                    this.getEditData();
                }, 1000);
            }
        }
    }
  }
}

// EventBus.$on("close-purchase-item",function (status){
//     thisPO.parentModalShowHide = 1042;
//     thisPO.isAddItem = false;
//     $("#vehiclePurchaseItemModal").modal("hide");
// });

// EventBus.$on("save-purchase-item",function (data,isUpdate){
//     thisPO.parentModalShowHide = 1042;
//     thisPO.isAddItem = false;
//     $("#vehiclePurchaseItemModal").modal("hide");
//     if(!isUpdate) {
//         thisPO.addItemObject(data);
//     } else {
//         thisPO.editPOItem = [];
//         thisPO.updateItemObject(data);
//     }
// });
</script>

<style scoped>
    .requiredstar{
        top: 0;
        left: 320px;
    }
    .help.is-danger {
        padding-left: 97px !important;
        position: relative;
    }
</style>