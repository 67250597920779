<template>
    <!-- Page Content Wrapper -->
    <!-- <template v-if="permissionNameArray.includes('can_view_opportunity_master')"> -->
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <button type="submit" v-on:click="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <!--Forms-->
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">

                        <ul class="nav nav-tabs">
                            <li class="nav-item"  v-if="permissionNameArray.includes('can_list_deals') || permissionNameArray.includes('can_view_opportunity_master')">
                                <a v-on:click="activetab=1" v-bind:class="'nav-link ' + [ activetab === 1 ? 'active' : '' ]">{{staticInputLabelMessages.deals}}</a>
                            </li>
                            <li class="nav-item"  v-if="permissionNameArray.includes('can_list_deal_inventory_mapper') || permissionNameArray.includes('can_view_opportunity_master')">
                                <a v-on:click="activetab=2" v-bind:class="'nav-link ' + [ activetab === 2 ? 'active' : '' ]">{{staticInputLabelMessages.linked_inventory}}</a>
                            </li>
                        </ul>

                        <div v-if="activetab === 1" class="tabcontent" id="customer-activity-logs1" >
                            <opportunity-deals-tab
                                v-bind:permissionNameArray=permissionNameArray 
                                v-bind:opportunity=opportunityData
                                v-bind:key="activetab"
                                @updateDisplayData="updateDisplayData"
                                :getAllOpportunityDataBasedOnId="getDataChild">
                            </opportunity-deals-tab>
                        </div>

                        <div v-if="activetab === 2" class="tabcontent" id="customer-activity-logs2" >
                            <opportunity-asset-tab
                                v-bind:permissionNameArray=permissionNameArray 
                                v-bind:opportunity=opportunityData
                                v-bind:key="activetab"
                                @updateDisplayData="updateDisplayData"
                                :getAllOpportunityDataBasedOnId="getDataChild">
                            </opportunity-asset-tab>
                        </div>
                    </div>
                </div>
                <!-- Forms -->
            </div>
            <!--page content-->
        </div>
   <!--  </template>
    <template v-else>
        <no-access></no-access>
    </template> -->
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import OpportunityDealsTab from '@/components/OpportunityMaster/DealsTab/OpportunityDealsTab';
    import OpportunityAssetTab from '@/components/OpportunityMaster/LinkedInventoryTab/OpportunityAssetTab';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";

    export default {
        props: ['redirectTo'],
        props: {
            oppId: 0,
        },
        name: 'OpportunityTabView',
        components: {
            'opportunity-deals-tab': OpportunityDealsTab,
            'opportunity-asset-tab': OpportunityAssetTab,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                activetab: 0,
                opportunityData: {
                    content: {

                    },
                },
                permissionNameArray: ['can_view_opportunity_master'],
                redirectId : '',
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('opportunity-list');
            EventBus.$emit("menu-selection-changed", "opportunity-list");
            self.redirectToTabFunction();
            self.getLoginIdPermissions();
        },
        methods: {
            updateDisplayData : function (actTab) {
                self.getAllOpportunityDataBasedOnId(actTab);
            },
            redirectToTabFunction: function() {
                if (typeof self.redirectTo !== "undefined" && self.redirectTo !== "") {
                    self.redirectId = self.redirectTo;
                }else{
                    self.redirectId = 1;
                }
                self.getAllOpportunityDataBasedOnId(self.redirectId);
            },
            buttonBack() {
                self.$router.push({name: "ListOpportunity"});
            },
            getDataChild(){
                var self = this;
                self.getAllOpportunityDataBasedOnId(1);
            },
            getAllOpportunityDataBasedOnId: function (actTab) {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},

                    params: {
                        id: self.$route.params.id,
                    }
                };
                self.showLoader();
                HTTP.get("/opportunity/get", config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.opportunityData = data.data;
                            self.opportunity = self.opportunityData;
                        }
                        self.$nextTick(() => {
                            self.activetab = actTab;
                        });
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                        self.buttonBack();
                    })
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>