<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_notification_manager_user')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.users_notifications }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} {{ allLabelMessages.results_found }}</p>
            </div>

            <router-link
              v-show="permissionNameArray.includes('can_notification_manager_user')"
              class="btn btn-primary waves-effect pull-right ml-2"
              :title="allLabelMessages.add_users_notifications"
              :to="{ name: 'AddUserNotification' }"
            >
              <span>{{ allLabelMessages.add_users_notifications }}</span>
            </router-link>
            
            <a
              id="refreshList"
              :title="allLabelMessages.refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>

          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListNotificationManager"
              ref="commonLoaderListNotificationManager"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    ref="select"
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">{{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="NotificationManagerArr && NotificationManagerArr.length > 0">
                    <tr v-for="(row, index) in NotificationManagerArr" :key="index">
                        <td v-show="actionDisplay">
                        <div class="actions">
                          <router-link
                          v-show="
                              permissionNameArray.includes(
                                'can_notification_manager_user'
                              )
                            "
                            class="mar-right-0" 
                            :title="allLabelMessages.edit_notification"
                            v-bind:to="{
                              name: 'UpdateUserNotification',
                              params: { id: row.record_id },
                            }"
                          >
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>
                        </div>
                        </td>
                        <td :class="rowClass.notification">{{ row.notification }}</td>
                        <td :class="rowClass.user_name">{{ row.user_name }}</td>
                        <td :class="rowClass.to_email">{{ row.to_email }}</td>
                        <td :class="rowClass.cc_emails">
                          <p
                            v-html="addNewLineInEmailColumn(row.cc_emails)"
                          ></p>
                        </td>
                        <td :class="rowClass.bcc_emails">
                          <p
                            v-html="addNewLineInEmailColumn(row.bcc_emails)"
                          ></p>
                        </td>
                        <td :class="rowClass.notification_schedules">
                          <template v-if="row.notification_schedules">
                            <template
                              v-for="(
                                schedule, scheduleIndex
                              ) in row.notification_schedules"
                            >
                              <span
                                :key="
                                  'row_user_notification_schedule_' +
                                  index +
                                  '_' +
                                  scheduleIndex
                                "
                                class="custom-tag"
                              >
                                {{ schedule.name }}
                              </span>
                            </template>
                          </template>
                        </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>

            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">{{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
      </div>
  </template>
  <template v-else>
      <no-access></no-access>
    </template>
  <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import Footer from "@/components/partials/Footer";

Vue.component("app-footer", Footer);

export default {
  name: "NotificationManager",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      NotificationManagerArr: [],
      headers: [],
      appliedHeaders: [],
      filters: {},
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      jumpToPage: 1,
      perPageValue: 25,
      perPage: 25,
      totalRecords: 0,
      startEndRecord: "",
      selectAll: false,
      jumpToHistoryPage: 1,
      columnName: filterColumn, 
      sortType: filterType,
      filterCount: 0,
      permissionNameArray: ["can_notification_manager_user"],
      actionDisplay : true,
    };
  },
  beforeMount() {
    self = this;
  },

  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("notification-manager");
    EventBus.$emit("menu-selection-changed", "notification-manager");
    var listHistoryData = self.loadFilters("listNotification", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.getLoginIdPermissions();
    self.loadListData();
  },
  methods: {
    // Change per page value
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadListData();
    },

    // Change page
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadListData();
      }
    },
    // Reset sorting
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    //Refresh List
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadAssetExpenses();
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    // Sort table data
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadListData();
      }
    },
    applyFilterParentComponent: function (data) {
      console.log('data filter :>> ', data);
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadListData();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },
    // Load list data
    loadListData: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadListData();
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
      };
      self.storeFilters("listNotification", fields, false);
      $("#commonLoaderListNotificationManager")
        .find(".loading-container.lodingTable")
        .show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/notification/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListNotificationManager")
            .find(".loading-container.lodingTable")
            .hide();
          if (data.data.code == 200) {
            self.headers = [];
            self.totalRecords = data.data.content.result;

            if (
              self.perPage == "All" &&
              self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
            ) {
              let seconds = 5000;
              self.$swal({
                type: "error",
                html: self.allStaticMessages
                  .too_many_data_to_display_browser_can_not_load,
                showConfirmButton: false,
                timer: seconds,
              });
              setTimeout(() => {
                self.perPage = 500;
                self.loadListData();
              }, seconds);
            }
          
            self.NotificationManagerArr = data.data.content.data;
            self.headers = data.data.content.headers;
            self.loadFilterInputs(data.data.content.headers, self.filters);

            self.addColumnClass(self.headers);

            if (data.data.content.per_page !== self.totalRecords) {
              self.perPage = parseInt(data.data.content.per_page);
              self.jumpToPage = parseInt(data.data.content.current_page);
            } else {
              if (self.perPage == "All") {
                self.jumpToPage = 1;
              }
              self.perPageValue = self.totalRecords;
              self.currentPage = 1;
            }
            self.setAlreadyAppliedFilters();
            self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
            self.sortFieldDisplay(self.columnName, self.sortType);
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListNotificationManager")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(error, function () {});
        });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }

    if (
      !self.permissionNameArray.includes("can_notification_manager_user")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>
<style scoped>
.custom-tag {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  border-radius: 50px;
  font-family: Arial, sans-serif;
  margin-left: 2px;
}

.view-more {
  text-align: center;
  width: 100%;
  margin-top: 16px;
  display: none; /* Initially hidden */
}

#view-more-btn {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
</style>
