<template>
    <!-- Page Content Wrapper -->
    <div class="page-content-wrapper admin-content-area">
        <template v-if="permissionNameArray.includes('can_upload_guide_documents')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                            
                        <div class="row no-margin">

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="dropzone">{{staticInputLabelMessages.upload_guide_label}}</label>
                                    <vue-dropzone
                                                ref="utReferenceGuideDropzoneRef"
                                                id="ut_ref_guide_dropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-success = "uploadDocument"
                                                @vdropzone-removed-file="removedSelectedFile"
                                                @vdropzone-error="fileError"
                                                @vdropzone-file-added="fileAdd"
                                                @vdropzone-total-upload-progress="progressOfUloadBanner"
                                                :useCustomSlot=true
                                                :refreshFlag="refreshFlag"
                                                :destroyDropzone="false"
                                        >
                                            <div class="dropzone-custom-content">
                                                <h4 class="dropzone-custom-title">{{staticMessages.drop_file_here_drop_zone_msg}}</h4>
                                                <div class="subtitle">({{staticMessages.select_files_from_computer}})</div>
                                            </div>
                                        </vue-dropzone>
                                </div>
                            </div>

                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div>
                            <input id="saveGuideBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="saveDoc()">
                        </div>
                    </div>
                    <!-- Forms -->

                    
                </div>
                
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-if="!isEdit"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';

    export default {
        name: 'UploadGuideDocument',
        components: {
            "app-footer" : Footer,
            'no-access' : NoAccessPage,
            'vueDropzone': vue2Dropzone,
        },
        props: {
            hasParent: false,
            parentId: null
        },
        mixins: [commonMixins],
        data () {
            return {
                refreshFlag: true,
                isEdit: false,
                id: this.$route.params.id,
                staticInputLabelMessages: "",
                staticMessages: "",

                buttonNames : {
                    save : 'Save',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Upload Guide Document",
                permissionNameArray : ['can_upload_guide_documents'],
                
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                    acceptedFiles: ".pdf",
                    params: {'doc_for': 'ut_reference_guide'}
                },
                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                attachment: [],
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function() {

            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.getLoginIdPermissions();
            self.isEdit = false;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('upload-guide-document');
            EventBus.$emit("menu-selection-changed", "upload-guide-document");

        },
        methods: {
            clearDataOnSave : function () {
                self.imagesPaths = [];
                self.imagesType = [];
                self.imagesSize = [];
                self.$refs.utReferenceGuideDropzoneRef.removeAllFiles();
                self.refreshFlag = !self.refreshFlag;
                return true;
            },
            showAlertOnSave : function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function(){
                    $("#success-alert").slideUp(500);
                });
            },
            saveDoc() {
                setTimeout(() => {
                    
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };

                    var data = new FormData();  
                    var filesCount = 0;                  
                    self.imagesPaths.forEach(function(imagePath, index) {
                        data.append('imagePath[]', imagePath);
                        filesCount++;
                    });
                    self.imagesSize.forEach(function(imagePath, index) {
                        data.append('imageSize[]', imagePath);
                    });
                    self.imagesType.forEach(function(imagePath, index) {
                        data.append('imageType[]', imagePath);
                    });

                    if(filesCount > 0)
                    {
                        HTTP.post("/upload-guide-document", data, config)
                        .then(function (response) {
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message,'success',false ,3);
                                self.clearDataOnSave();
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                        
                        });

                    }else{
                        self.showSAlert('Please upload file','warning',false ,3);
                        self.disableButtons.save = false;
                        self.disableButtons.cancel = false;
                    }
                    
                }, 200);
            },
            uploadDocument: function (file, response) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;

                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
            },
            removedSelectedFile: function (file, xhr, error) {
                var self = this;
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;

                var removeFile = "";
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                    removeFile = removeFile.media_path
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                    }
                }
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                self.showSAlert(xhr, 'error', false);
                self.$refs.utReferenceGuideDropzoneRef.removeFile(file);     
            },
            fileAdd: function (file, xhr, error) {
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;
            },
            progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
                self.disableButtons.save = true;
                self.disableButtons.cancel = true;
            },
            getFileType: function (file_name) {
                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {
                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];

                self.imagesPaths.push(String(orgPath + "/" + file_name));

                return file_name;
            },
        },
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>