<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <th class="">{{staticInputLabelMessages.acquire_on_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('acquireOnDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.purchase_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('paidDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.available_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('availableDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.title_in_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('titleInIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.title_out_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('titleOutDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sale_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('saleDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.order_no_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('orderNoInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.order_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('orderDateInput')"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Acquire on Date-->
                                <td class="">
                                    <date-picker style="min-width: 110px;"
                                            v-model="acquireOnDateInput[asset.asset_id]"
                                            :config="options"
                                            autocomplete="off"
                                            :wrap="true"
                                            class="form-control"
                                            :placeholder="staticMessages.acquire_on_date_place_holder"
                                            name="acquireOnDateInput"
                                            @input="changeAcquireOnDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{acquireOndateErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Paid Date-->
                                <td class="">
                                    <date-picker style="min-width: 116px;"
                                            v-model="paidDateInput[asset.asset_id]"
                                            :config="options"
                                            autocomplete="off"
                                            :wrap="true"
                                            class="form-control"
                                            :placeholder="staticMessages.paid_date_place_holder"
                                            name="paidDateInput"
                                            @input="changePaidDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{paidDateErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Available Date-->
                                <td class="">
                                    <date-picker style="min-width: 110px;"
                                            v-model="availableDateInput[asset.asset_id]"
                                            :config="options"
                                            autocomplete="off"
                                            :wrap="true"
                                            class="form-control"
                                            :placeholder="staticMessages.available_date_place_holder"
                                            name="availableDateInput"
                                            @input="changeAvailableDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{availableDateErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Title In-->
                                <td class="">
                                    <v-select style="min-width: 140px;" v-model="titleInIdInput[asset.asset_id]"
                                              @input="titleInIdErrorMsg[asset.asset_id] = ''"
                                              name="titleInIdInput"
                                              id="titleInIdInput"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="titleInIdArrput"
                                              :placeholder="staticMessages.is_title_in_place_holder"
                                    >
                                    </v-select>
                                    <span class="help is-danger">{{titleInIdErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Title Out Date-->
                                <td class="">
                                    <date-picker style="min-width: 110px;"
                                            v-model="titleOutDateInput[asset.asset_id]"
                                            :config="options"
                                            :wrap="true"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.title_out_date_place_holder"
                                            name="titleOutDateInput"
                                            @input="changeTitleOutDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{titleOutDateErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Sale Date-->
                                <td class="">
                                    <date-picker style="min-width: 110px;"
                                            v-model="saleDateInput[asset.asset_id]"
                                            :config="options"
                                            :wrap="true"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.sale_date_place_holder"
                                            name="saleDateInput"
                                            @input="changeSaleDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{saleDateErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Order No-->
                                <td class="">
                                    <input style="min-width: 110px;" id="notes" v-model="orderNoInput[asset.asset_id]" class="form-control" type="text"></input>
                                    <span class="help is-danger">{{orderNoErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Order Date-->
                                <td class="">
                                    <date-picker style="min-width: 110px;"
                                            v-model="orderDateInput[asset.asset_id]"
                                            :config="options"
                                            :wrap="true"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.order_date_place_holder"
                                            name="activityDateTime"
                                            @input="changeOrderDate(asset.asset_id)"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{orderDateErrorMsg[asset.order_date_place_holder]}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                acquireOnDateInput : [],
                acquireOndateErrorMsg : [],
                paidDateInput : [],
                paidDateErrorMsg : [],
                availableDateErrorMsg :[],
                titleInIdInput : [],
                titleInIdErrorMsg :[],
                titleInIdArrput : [{ text : "Yes", value : "yes"}, { text : "No", value : "no"}],
                titleOutDateInput : [],
                titleOutDateErrorMsg : [],
                saleDateInput : [],
                saleDateErrorMsg : [],
                orderNoInput : [],
                orderNoErrorMsg : [],
                orderDateInput : [],
                orderDateErrorMsg : [],
                availableDateInput : [],
                availableDateErrorMsg : [],

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_add_inventory_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";
            /* self.loadAllData(['WarrantyType'], false, self.loadWarrantyTypeCallback, "undefined", ''); */

                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.orderNoInput[self.asset.content[i].asset_id] = self.asset.content[i].order_no;
                        if (self.asset.content[i].acquire_on_date != null && typeof self.asset.content[i].acquire_on_date != "undefined" && self.asset.content[i].acquire_on_date != '') {
                            self.acquireOnDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].acquire_on_date);
                        }
                        if (self.asset.content[i].purchase_date != null && typeof self.asset.content[i].purchase_date != "undefined" && self.asset.content[i].purchase_date != '') {
                            self.paidDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].purchase_date);
                        }
                        if (self.asset.content[i].available_date != null && typeof self.asset.content[i].available_date != "undefined" && self.asset.content[i].available_date != '') {
                            self.availableDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].available_date);
                        }
                        if (self.asset.content[i].title_in != null && typeof self.asset.content[i].title_in != "undefined" && self.asset.content[i].title_in != '') {
                            self.titleInIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].title_in), value: self.asset.content[i].title_in};
                        } else {
                            self.titleInIdInput[self.asset.content[i].asset_id] = {text: "", value: ""};
                        }
                        if (self.asset.content[i].title_out_date != null && typeof self.asset.content[i].title_out_date != "undefined" && self.asset.content[i].title_out_date != '') {
                            self.titleOutDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].title_out_date);
                        }
                        if (self.asset.content[i].sale_date != null && typeof self.asset.content[i].sale_date != "undefined" && self.asset.content[i].sale_date != '') {
                            self.saleDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].sale_date);
                        }
                        if (self.asset.content[i].order_date != null && typeof self.asset.content[i].order_date != "undefined" && self.asset.content[i].order_date != '') {
                            self.orderDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].order_date);
                        }
                    }
                });
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'acquireOnDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.acquireOnDateInput[self.asset.content[i].asset_id];
                                    self.acquireOnDateInput = [];
                                } 
                                self.acquireOnDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'paidDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.paidDateInput[self.asset.content[i].asset_id];
                                    self.paidDateInput = [];
                                } 
                                self.paidDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'availableDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.availableDateInput[self.asset.content[i].asset_id];
                                    self.availableDateInput = [];
                                } 
                                self.availableDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'titleInIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.titleInIdInput[self.asset.content[i].asset_id];
                                    self.titleInIdInput = [];
                                } 
                                self.titleInIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'titleOutDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.titleOutDateInput[self.asset.content[i].asset_id];
                                    self.titleOutDateInput = [];
                                } 
                                self.titleOutDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'saleDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.saleDateInput[self.asset.content[i].asset_id];
                                    self.saleDateInput = [];
                                } 
                                self.saleDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'orderNoInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.orderNoInput[self.asset.content[i].asset_id];
                                    self.orderNoInput = [];
                                } 
                                self.orderNoInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'orderDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.orderDateInput[self.asset.content[i].asset_id];
                                    self.orderDateInput = [];
                                } 
                                self.orderDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            changeOrderDate : function (assetId) {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.orderDateErrorMsg, assetId, "");
                    }
                });
            },
            changeSaleDate : function (assetId) {
                $.each(self.assetIdInput, function () {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.saleDateErrorMsg, assetId, "");
                    }
                });
            },
            changeTitleOutDate : function (assetId) {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.titleOutDateErrorMsg, assetId, "");
                    }
                });
            },
            changeAvailableDate : function (assetId) {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.availableDateErrorMsg, assetId, "");
                    }
                });
            },
            changePaidDate : function (assetId) {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.paidDateErrorMsg, assetId, "");
                    }
                });
            },
            changeAcquireOnDate: function (assetId) {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[assetId] != null) {
                        Vue.set(self.acquireOndateErrorMsg, assetId, "");
                    }
                });
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArrInput = callbackData.WarrantyType;
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'acquire_on_date' : self.returnDateOrNull(self.acquireOnDateInput[self.asset.content[i].asset_id]),
                            'paid_date' : self.returnDateOrNull(self.paidDateInput[self.asset.content[i].asset_id]),
                            'availabel_date' : self.returnDateOrNull(self.availableDateInput[self.asset.content[i].asset_id]),
                            'title_in' : self.titleInIdInput[self.asset.content[i].asset_id].value,
                            'title_out_date' : self.returnDateOrNull(self.titleOutDateInput[self.asset.content[i].asset_id]),
                            'sale_date' : self.returnDateOrNull(self.saleDateInput[self.asset.content[i].asset_id]),
                            'order_no' : self.orderNoInput[self.asset.content[i].asset_id],
                            'order_date' : self.returnDateOrNull(self.orderDateInput[self.asset.content[i].asset_id]),
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },

            returnDateOrNull : function (date) {
                if (date != '' && date != null && typeof date != "undefined") {
                    return date;
                } else {
                     return "";
                }
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null){
                            if (!self.acquireOnDateInput[i]) {
                                Vue.set(self.acquireOndateErrorMsg, i, "Please enter acquire on date");
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                });
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/date", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 6);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;

                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>