<template>
  <div :id="refId" class="common-loader">
    <div
      style="display: none"
      :id="refId"
      class="page-loader-wrapper loading-container lodingTable"
    >
      <div class="loading">
        <img src="/static/images/loading.gif" />
      </div>
    </div>
  </div>
</template>

<script>
var self;
export default {
  name: "CommonLoader",
  props: {
    refId: "",
  },
  beforeMount: function () {
    self = this;
  },

  methods: {
  },
};
</script>

<style scoped>
.common-loader .lodingTable {
  position: absolute;
  display: block;
  z-index: 9;
}
</style>
