<template>
  <!-- <div class="spaced-container"> -->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="
        permissionNameArray.includes('can_add_area_on_yard_location_master')
      "
    >
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <!--Name-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="nameError = ''"
                    :id="preId+'name'"
                    v-model="name"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ nameError }}</span>
                </div>
              </div>
            </div>
            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger" id="alertError">
                  <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input
                id="addAreaOnYardBtn"
                :disabled="disableButtons.save"
                type="submit"
                :value="buttonNames.save"
                class="btn btn-primary"
                v-on:click.prevent="addAreaOnYard()"
              />
              <input
                v-show="showSaveAndContinue"
                id="addAndContinueAreaOnYardBtn"
                :disabled="disableButtons.saveAndContinue"
                type="submit"
                :value="buttonNames.saveAndContinue"
                class="btn btn-primary"
                v-on:click.prevent="addAreaOnYard((flag = 'Save and Continue'))"
              />
              <input
                id="cancelAreaOnYard"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import NoAccessPage from "../NoAccessPage/NoAccessPage";

Vue.component("app-footer", Footer);
Vue.component("no-access", NoAccessPage);

export default {
  name: "AddUpdateAreaOnYard",
  mixins: [commonMixins],
  props: {
    locid: 0,
  },
  data() {
    return {
      preId: "add_update_area_on_yard_",
      name: "",
      nameError: "",
      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
      },
      showSaveAndContinue: true,
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Area On Yard",
      permissionNameArray: ["can_add_area_on_yard_location_master"],
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.setActiveMenu("location-list");
    EventBus.$emit("menu-selection-changed", "location-list");

    $("#alertError").hide();
    self.getLoginIdPermissions();
    if (
      typeof self.$route.params.aoyid != "undefined" &&
      self.$route.params.aoyid != 0 &&
      self.$route.params.aoyid != ""
    ) {
      self.getAreaOnYardById();
      self.buttonNames.save = "Update";
      self.showSaveAndContinue = false;
      self.pageTitle = "Edit Area On Yard";
    }
  },
  methods: {
    buttonBack() {
      self.$router.push(
        "/location/detail/" + self.$route.params.id + "?activeTab=" + 5
      );
    },
    clearDataOnSaveAndContinue: function () {
      self.name = "";
    },
    showAlertOnSave: function (alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function () {
          $("#success-alert").slideUp(500);
        });
    },
    getAreaOnYardById: function () {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: self.$route.params.aoyid,
        },
      };
      HTTP.get("/areaonyard/get", config)
        .then((response) => {
          self.name = response.data.content.name;
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    checkForm: function () {
      var checked = true;
      if (!self.name) {
        self.nameError = "Please enter name";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    addAreaOnYard(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };
          var data = new FormData();
          data.append("location_id", self.$route.params.id);
          data.append("name", self.name);
          if (
            typeof self.$route.params.aoyid != "undefined" &&
            self.$route.params.aoyid != 0 &&
            self.$route.params.aoyid != ""
          ) {
            data.append("id", self.$route.params.aoyid);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }

          HTTP.post("/areaonyard/add/update", data, config)
            .then(function (response) {
              if (
                typeof self.$route.params.aoyid != "undefined" &&
                self.$route.params.aoyid != 0 &&
                self.$route.params.aoyid != ""
              ) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (
                  self.$route.params.aoyid != 0 &&
                  self.$route.params.aoyid != ""
                ) {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function () {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function (err) {
              if (
                self.$route.params.aoyid != 0 &&
                self.$route.params.aoyid != ""
              ) {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function () {});
            });
        }
      }, 200);
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
