<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_email_inventory_master')">
            <!--<div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <span>{{pageTitle}}</span>
                    </div>
                </div>
            </div>-->
            <!--page content-->
            <div class="admin-page-content">
                <span>
                    <template v-if="unitNo != ''">
                        Stock #{{unitNo}} | 
                    </template>
                    <span>{{year}} {{makeName}} {{modelName}}</span>
                </span>
                <!--Forms-->
                <div :class="alertClass" id="success-alert">
                    <strong> {{alertMessage}} </strong>
                </div>
                <div class="forms-container add_user_forms_container">
                    <!-- <div class="forms-container add_user_forms_container vtc-dealer-forms"> -->
                    <div class="row no-margin">
                        <!-- Asking Price -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.asking_price_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input id="askingPrice" v-model="askingPrice" class="form-control" type="text"/>
                                <span class="help is-danger">{{askingPriceError}}</span>
                            </div>
                        </div>
                        <!-- Contact (CRM) -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.contact_crm_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select v-model="contactCRM"
                                          @change="changedValue"
                                          @input="contactCRMError = ''"
                                          @search="onSearchCustomer"
                                          @clear="onClearCustomer"
                                          name="vendor"
                                          id="vendor"
                                          label="text"
                                          :clearable="true"
                                          :searchable="true"
                                          :filterable="false"
                                          :options="CustomerArr"
                                          :placeholder="staticMessages.acquire_from_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isCustomerRecordNotFound">
                                            {{staticMessages.acquire_from_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                </v-select>
                                <!--<input @input="contactCRMError = ''" id="contactCRM" v-model="contactCRM" class="form-control" type="text"/>-->
                                <!--<div class="requiredstar">*</div>-->
                                <span class="help is-danger">{{contactCRMError}}</span>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <!--<input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>-->
                                <vue-tags-input
                                    v-model="email"
                                    :tags="emailTagsArr"
                                    :validation="validation"
                                    @tags-changed="newTags => emailTagsArr = newTags"
                                    @before-adding-tag="checkEmailTag"
                                    placeholder=""
                                />
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{emailError}}</span>
                            </div>
                        </div>
                        <!-- Subject -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.subject_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="subjectError = ''" id="subject" v-model="subject" class="form-control" type="text"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{subjectError}}</span>
                            </div>
                        </div>
                        <!-- Message -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.message_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <textarea id="message" v-model="message" class="form-control"></textarea>
                            </div>
                        </div>
                        <!-- CC -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.cc_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <!--<input @input="ccError = ''" id="cc" v-model="cc" class="form-control" type="text"/>-->
                                <vue-tags-input
                                    v-model="cc"
                                    :tags="ccTagsArr"
                                    :validation="validationCC"
                                    @tags-changed="newTags => ccTagsArr = newTags"
                                    @before-adding-tag="checkCCTag"
                                    placeholder=""
                                />
                                <!-- <div class="requiredstar">*</div> -->
                                <span class="help is-danger">{{ccError}}</span>
                            </div>
                        </div>
                        <!-- Option -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.option_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="container">
                                    <!-- class="form-control" -->
                                    <input id="option" class="checkbox" v-model="option" type="checkbox"/>
                                    <span class="checkmark"></span>
                                    <span class="pl-3">{{staticInputLabelMessages.send_annonymous_spec_sheet_no_company_details_or_logo_will_be_included_label}}</span>
                                </label>
                            </div>
                            <div class="col-3 paddingleft0">
                                <!-- <div class="form-label">{{staticInputLabelMessages.option_label}}</div> -->
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <!-- Send Specification in Email Body -->
                                <label class="container">
                                    <input id="specInEmailBody" class="checkbox" v-model="specInEmailBody" type="checkbox"/>
                                    <span class="checkmark"></span>
                                    <span class="pl-3">{{staticInputLabelMessages.send_specification_in_email_body_label}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!--Error Alert-->
                    <!-- <div class="row clearfix" >
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="alert alert-danger" id="alertError">
                                <strong id="errorMsg" class="error"></strong>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div> -->
                    <!--Buttons-->
                    <div class="row clearfix"></div>
                    <div class="button-demo">
                        <input id="addAssetBtn" type="submit" :disabled="disableButtons.save" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="emailSendSpecs()">
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);
    export default {
        name: 'EmailAsset',
        components: {
            VueTagsInput,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                pageTitle: "Email Inventory",
                assetId: 0,
                unitNo: "",
                year: "",
                makeName: "",
                modelName: "",

                askingPrice: 0,
                contactCRM: "",
                CustomerArr: [],
                isCustomerRecordNotFound: false,
                email: "",
                emailTagsArr: [],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidEmailTag : true,
                subject: "Central California Truck and Trailer Sales | Specification Notification",
                message: "",
                cc: "",
                ccTagsArr : [],
                validationCC: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                isValidCCTag : true,
                option: 0,
                specInEmailBody: false,
                buttonNames: {
                    save: 'Send Specs',
                },
                disableButtons: {
                    save: false
                },

                //error msg
                askingPriceError: "",
                contactCRMError: "",
                emailError: "",
                subjectError: "",
                ccError: "",

                alertClass: "",
                alertMessage: "",
            }
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch: {
            contactCRM: function (contactCRM) {
                if (self.contactCRM == null || !self.contactCRM.value) {

                } else {
                    if (self.contactCRM.email != null && self.contactCRM.email != '') {
                        if (self.emailTagsArr.length == 0) {
                             self.emailTagsArr = [self.contactCRM.email];
                        }
                    }
                }
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            self.unitNo = self.asset.content.asset_data.unit;
            self.year = self.asset.content.asset_data.year;
            self.makeName = self.asset.content.asset_data.make_name;
            self.modelName = self.asset.content.asset_data.model_name;
            self.askingPrice = self.asset.content.asset_data.retail_price;
            self.subject = self.asset.content.asset_data.subject + " | Specification Notification";
            EventBus.$emit("menu-selection-changed", "asset-list");
            $("#alertError").hide();
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                /*self.getAssetData(self.assetId);*/
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            checkEmailTag: function(obj) {
                self.emailError = "";
                self.isValidEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter valid Email";
                } else obj.addTag();
            },
            checkCCTag: function(obj) {
                self.ccError = "";
                self.isValidCCTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidCCTag = false;
                    self.ccError = "Please enter valid CC Email";
                } else obj.addTag();
            },
            changedValue: function () {
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadCustomerCallback, loading, search);
                }
            },
            loadCustomerCallback: function (callbackData) {
                self.isCustomerRecordNotFound = false;
                self.CustomerArr = callbackData.Contact;
                if (self.CustomerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
            },
            onClearCustomer: function () {
                self.contactCRMError = "";
            },
            checkForm: function () {
                var checked = true;
                self.askingPriceError = "";
                self.contactCRMError = "";
                self.emailError = "";
                self.subjectError = "";
                self.ccError = "";
                self.isValidEmailTag = true;

                if (self.askingPrice && !self.numberFormateValidation(self.askingPrice)) {
                    self.askingPriceError = "Asking Price must be Numeric Value";
                    if (checked) {
                        checked = false;
                    }
                }
                /*if (self.contactCRM == null || !self.contactCRM.value) {
                    self.contactCRMError = "Please enter Contact CRM";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (self.emailTagsArr.length == 0) {
                    self.isValidEmailTag = false;
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.subject) {
                    self.subjectError = "Please enter subject";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.cc && !self.validEmail(self.cc)) {
                    self.ccError = "Please enter valid CC Email";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            emailSendSpecs() {
                setTimeout(() => {
                    if (self.isValidEmailTag && self.isValidCCTag && self.checkForm()) {
                        self.buttonNames.save = "Sending Specs...";
                        self.disableButtons.save = true;
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var contactCRM = "";
                        if (self.contactCRM == null || !self.contactCRM.value) {
                            contactCRM = "";
                        } else {
                            contactCRM = self.contactCRM.value;
                        }
                        var emailData = "";
                        if(self.emailTagsArr && self.emailTagsArr.length > 0) {
                            for(var index = 0;index<self.emailTagsArr.length;index++) {
                                if(!emailData.includes(self.emailTagsArr[index].text)) {
                                    emailData += (self.emailTagsArr[index].text) + ',';
                                }
                            }
                        }
                        emailData = emailData.replace(/,\s*$/, "");
                        var ccData = "";
                        if(self.ccTagsArr && self.ccTagsArr.length > 0) {
                            for(var index = 0;index<self.ccTagsArr.length;index++) {
                                if(!ccData.includes(self.ccTagsArr[index].text)) {
                                    ccData += (self.ccTagsArr[index].text) + ',';
                                }
                            }
                        }
                        ccData = ccData.replace(/,\s*$/, "");
                        var data = new FormData();
                        data.append('askingPrice', self.askingPrice);
                        data.append('contactCRM', contactCRM);
                        data.append('email', emailData);
                        data.append('subject', self.subject);
                        data.append('message', self.message);
                        data.append('cc', ccData);
                        data.append('option', self.option);
                        data.append('specInEmailBody', self.specInEmailBody);
                        data.append('assetId', self.assetId);

                        HTTP.post("/asset/email", data, config)
                                .then(function (response) {
                                    self.buttonNames.save = "Send Specs";
                                    self.disableButtons.save = false;
                                    if (response.data.status == "success") {
                                        self.subject = self.asset.content.asset_data.subject + " | Specification Notification";
                                        self.askingPrice = 0;
                                        self.contactCRM = "";
                                        self.email = "";
                                        self.message = "";
                                        self.cc = "";
                                        self.option = false;
                                        self.specInEmailBody = false;
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        /*setTimeout(function () {
                                         self.buttonBack();
                                         }, 3000);*/
                                    } else {
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Send Specs";
                                    self.disableButtons.save = false;
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);
            },
            getAssetData() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.assetId,
                    }
                };
                HTTP.get('/asset/get', config)
                        .then(response => {
                            self.unitNo = response.data.content.asset_data.unit;
                            self.year = response.data.content.asset_data.year;
                            self.makeName = response.data.content.asset_data.make_name;
                            self.modelName = response.data.content.asset_data.model_name;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
        },
    }
</script>