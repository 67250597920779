<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_f&i_master')">
      <div class="container-fluid xyz" >
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
                <span>F&I</span>
            </div>

            <a v-show="permissionNameArray.includes('can_import_f&i_master')" id='importFandiRecords' title='Import' class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="openImportModal('import_record')">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </a>

          </div>
          <div class="vdc-filters-container">

          </div>

          <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers" @applyFilterParentComponent="applyFilterParentComponent" @resetSorting="resetSorting"></filter-component>

          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListFandI"
              ref="commonLoaderListFandI"
            >
            </common-loader>
              <!-- Pagination Start -->
              <div class="top_pagination vgt-wrap__footer crm">
                  <div class="row">
                      <div class="col-sm-12 col-md-2 PerPage">
                          <v-select ref="select" title="Select Per Page"
                                  v-model="perPage"
                                  @input="changePerPage"
                                  name="customPerPage"
                                  id="customPerPage"
                                  label="text"
                                  :clearable="false"
                                  :searchable="false"
                                  :filterable="false"
                                  :options="paginationDropDownArr"
                                  placeholder="Select Per Page">
                          </v-select>
                      </div>
                      <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                          <p class="pageRowCount">
                              {{startEndRecord}} of {{totalRecords}}
                          </p>
                      </div>

                      <!-- v-if="totalRecords >= perPage" -->
                      <div class="col-sm-12 col-md-6 Pagination">
                          <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                      </div>

                      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                          <p class="pageJumpLabel">Page : </p>
                      </div>
                      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                          <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                      </div>
                  </div>
              </div>
              <!-- Pagination End -->
              <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                <table class="table custom-table jsmartable">
                  <tbody>
                    <template v-if="records && records.length > 0">
                      <template v-for="(row, index) in records">
                        <tr class="mainDetails" :key="'row_fandi_' + index">
                          <td :class="rowClass.vin_no">{{row.dealer_name}}</td>
                          <td :class="rowClass.unit_no">{{row.ext_cont_no}}</td>

                          
                          <td :class="rowClass.vin_no">{{row.contact_status}}</td>

                          <td :class="rowClass.unit_no">{{row.date_contact_start}}</td>

                          <td :class="rowClass.vin_no">{{row.date_contact_end}}</td>
                          <td :class="rowClass.unit_no">{{row.borrower_name}}</td>
                          <td :class="rowClass.vin_no">{{row.date_last_payment_due}}</td>
                          <td :class="rowClass.unit_no">{{row.man_year}}</td>
                          <td :class="rowClass.vin_no">{{row.new_used}}</td>
                          <td :class="rowClass.unit_no">{{row.make_name}}</td>
                          <td :class="rowClass.vin_no">{{row.model_name}}</td>


                          <td :class="rowClass.unit_no">{{row.body_desc}}</td>
                          <td :class="rowClass.vin_no">{{row.rego}}</td>
                          <td :class="rowClass.unit_no">{{row.vin_no}}</td>
                          <td :class="rowClass.vin_no">{{row.buy_back}}</td>
                          <td :class="rowClass.unit_no">{{row.fin_amount}}</td>
                          <td :class="rowClass.vin_no">{{row.res_amount}}</td>
                          <td :class="rowClass.unit_no">{{row.km_allow_msr}}</td>
                          <td :class="rowClass.vin_no">{{row.cont_term}}</td>
                          <td class="text-right">{{row.int_rate}}</td>
                          <td class="text-right">{{row.inst_amt}}</td>


                          <td :class="rowClass.unit_no">{{row.fp_group_dsc}}</td>
                          <td :class="rowClass.unit_no">{{row.clicksell_proposal_nbr}}</td>
                          <td :class="rowClass.unit_no">{{row.borrower_address_one}}</td>
                          <td :class="rowClass.unit_no">{{row.borrower_address_two}}</td>
                          <td :class="rowClass.unit_no">{{row.suburb}}</td>
                          <td :class="rowClass.unit_no">{{row.state}}</td>
                          <td :class="rowClass.unit_no">{{row.borrower_email}}</td>


                          <td :class="rowClass.unit_no">{{row.mobile}}</td>
                          <td :class="rowClass.unit_no">{{row.borrower_phone}}</td>
                          <td :class="rowClass.unit_no">{{row.guart_first_name}}</td>
                          <td :class="rowClass.unit_no">{{row.guar_surname}}</td>
                          <td :class="rowClass.unit_no">{{row.guarantor_address_one}}</td>
                          <td :class="rowClass.unit_no">{{row.do_not_call}}</td>
                          <td :class="rowClass.unit_no">{{row.date_of_last_interation}}</td>

                          <td :class="rowClass.unit_no">{{row.what_happned_customer_wants_to}}</td>
                          <td :class="rowClass.unit_no">{{row.commentsnotes_what_happned_with_last_interaction}}</td>
                          <td :class="rowClass.unit_no">{{row.whats_going_to_happen_next}}</td>
                          <td :class="rowClass.unit_no">{{row.next_follow_up_date}}</td>
                          <td :class="rowClass.unit_no">{{row.follow_up_notes_comments}}</td>

                          <td :class="rowClass.unit_no">{{row.next_follow_up}}</td>
                          <td :class="rowClass.unit_no">{{row.outcome}}</td>
                          <td :class="rowClass.unit_no">{{row.future_follow_up_date}}</td>

                        </tr>
                      </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="headers.length + 1" class="text-alignment-center">
                                {{allStaticMessages.data_not_available}}
                            </td>
                        </tr>
                    </template>

                  </tbody>
                  <thead>
                    <tr>
                        <template v-for="(data, headerIndex) in headers">
                            <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                        </template>
                        <template v-for="(data, headerIndex) in headers">
                            <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                        </template>
                    </tr>
                  </thead>
                </table>
              </div>

              <!-- Pagination Start -->
              <div class="bottom_pagination vgt-wrap__footer crm">
                  <div class="row">
                      <div class="col-sm-12 col-md-2 PerPage">
                          <v-select title="Select Per Page"
                                  v-model="perPage"
                                  @input="changePerPage"
                                  name="customPerPage"
                                  id="customPerPage"
                                  label="text"
                                  :clearable="false"
                                  :searchable="false"
                                  :filterable="false"
                                  :options="paginationDropDownArr"
                                  placeholder="Select Per Page">
                          </v-select>
                      </div>
                      <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                          <p class="pageRowCount">
                              {{startEndRecord}} of {{totalRecords}}
                          </p>
                      </div>

                      <!-- v-if="totalRecords >= perPage" -->
                      <div class="col-sm-12 col-md-6 Pagination">
                          <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                      </div>

                      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                          <p class="pageJumpLabel">Page : </p>
                      </div>
                      <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                          <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                      </div>
                  </div>
              </div>
              <!-- Pagination End -->


          </div>
        </div>



        <!-- Import Modal -->
        <div class="modal fade" id="fandiImportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" >Import F&I From Excel</h4>
                  </div>
                  <form class="form-inline" id="importCallform" enctype="multipart/form-data">
                    <common-loader
                      refId="commonLoaderFandIImportModel"
                      ref="commonLoaderFandIImportModel"
                    >
                    </common-loader>
                      <div class="modal-body">
                          Select Excel File to Import
                          <input type="file" name="excl_file" accept=".xlsx,.xls" id="uploadFandi" ref="files" v-on:change="handleFilesUpload()"/>
                          <div class="form-group">
                              <label id="errorMsg" class="error"></label><br>
                          </div>
                          <a  id ="demoDownloadFandiImportUrl" href="#" @click="getSignedUrlPath('demo/FandiSheet.xlsx')" class="btn general-button btn-info waves-effect m-r-20">
                              Download Demo
                          </a>
                          


                          <template v-if="fandiImportRecordsWithErrors.length">
                                  <div class="mt-20">
                                      <h6>The file may contain errors. Check the file for any errors, such as missing fields or incorrect data.</h6>
                                      <template v-for="(row, index) in fandiImportRecordsWithErrors">
                                          <template v-if="Object.keys(row.errors).length">
                                              <div :key="'fandiImportRecordsWithErrors_'+index" class="">
                                                  <table class="">
                                                      <tr>
                                                          <td class="bold">Record {{index+1}}:</td>
                                                          <td>
                                                              {{row.missing_error_msg ? row.missing_error_msg : ''}}
                                                              {{row.duplicate_record ? row.duplicate_record : ''}}
                                                              {{row.other_error ? row.other_error : ''}}
                                                              <!-- <tr :key="'inventoryImportRecordsWithErrors_'+index+'_'+errorKey" v-for="(error, errorKey) in row.errors">
                                                                  <td>{{error}}</td>
                                                              </tr> -->
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </div>
                                          </template>
                                      </template>
                                  </div>
                                  
                          </template>
                      </div>
                      <div class="modal-footer width-full">
                          <button type="button" v-on:click="importFandi($event);" class="btn general-button btn-success waves-effect" id="fandiImportBtn">Import</button>
                          <button type="button" class="btn general-button btn-danger waves-effect" data-bs-dismiss="modal" id="fandiImportClose" >Close</button>
                      </div>
                  </form>
              </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access-page></no-access-page>
    </template>
    <app-footer></app-footer>
    </div>
  
</template>
<script>
import {commonMixins} from '../../mixins/commonMixins';
import {HTTP} from '../../http-common';
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from '../NoAccessPage/NoAccessPage.vue';
import EventBus from ".././event-bus";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";


var self;
export default {
  name: 'ListFandi',
  mixins: [commonMixins],
  components: {
    "filter-component" : FilterComponent,
    "common-loader": CommonLoader,
    NoAccessPage,
    "app-footer": Footer
  },
  data() {
    return {
      // Initial values for data properties
      currentPage: 1,
      itemsPerPage: 10,
      records: [],
      headers: [],
      appliedHeaders : [],
      filters : {},
      fandiImportRecordsWithErrors: [],
      files: [],
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      jumpToPage: 1,
      perPageValue : 25,
      perPage: 25,
      totalRecords: 0,
      startEndRecord : "",
      selectAll: false,
      jumpToHistoryPage : 1,
      columnName: "id", // Set default filter column
      sortType: "desc", // Set default filter type
      filterCount : 0,
      permissionNameArray: ['can_list_f&i_master'],
    };
  },
beforeMount() {
  self = this;
},

created: function () {
  self = this;
  self.getLoginIdPermissions();
},

methods: {
  // Apply filter to the parent component
  applyFilterParentComponent: function (data) {
    let key;
    let value;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['column_name'] != "null") {
        key = data[i]['column_name'];
        value = data[i]['field'];
        self.filters[key] = value;
      }
    }
    self.appliedHeaders = data;
    self.loadListData();
  },

  // Reset sorting
  resetSorting: function () {
    self.columnName = filterColumn;
    self.sortType = filterType;
    self.changepage();
  },

  // Sort table data
  sortTableData: function (name, isSortAllowed) {
    if (isSortAllowed == "nosort") {
      if (self.columnName == name) {
        self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
      } else {
        self.sortType = "asc";
      }
      self.columnName = name;
      self.loadListData();
    }
  },

  // Import Fandi
  importFandi: function () {
    if (typeof (self.files) === 'undefined' || self.files == 'null' || self.files == '') {
      self.showSAlert("Please upload Excel file.", 'info', false);
    } else {
      var data = new FormData();
      for (var i = 0; i < self.files.length; i++) {
        let file = self.files[0];
        data.append('excl_file', file);
      }

      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
          "Content-Type": "multipart/form-data"
        },
      };
      self.importBtn = 'Importing ...';
      $('#fandiImportBtn').prop("disabled", true);
      $('#fandiImportClose').prop("disabled", true);
      $("#commonLoaderFandIImportModel")
          .find(".loading-container.lodingTable")
          .show();
      HTTP.post('/fandi/import', data, config)
        .then(response => {
          $("#commonLoaderFandIImportModel")
          .find(".loading-container.lodingTable")
          .hide();
          if (response.data.code === 200) {
            $('#fandiImportModal').modal('hide');
            self.showSAlert(response.data.message + '<br>' + response.data.messageSkipRecords, 'success', false);
            self.loadListData();
            self.fandiImportRecordsWithErrors = [];
          } else {
            self.showSAlert(response.data.message, 'error', false);

            self.importBtn = 'Import';
            $('#fandiImportBtn').prop("disabled", false);
            $('#fandiImportClose').prop("disabled", false);

            if (typeof response.data.status_type != 'undefined' && response.data.status_type == 'import_record_error') {
              self.resetImportModel();
              self.fandiImportRecordsWithErrors = response.data.records;
            }
          }
        })
        .catch(function (err) {
          $('#fandiImportBtn').prop("disabled", false);
          $('#fandiImportClose').prop("disabled", false);
          $("#commonLoaderFandIImportModel")
          .find(".loading-container.lodingTable")
          .hide();
          self.catchHandler(err, function () {});
        });
    }
  },

  // Open import modal
  openImportModal: function () {
    self.files = [];
    self.fandiImportRecordsWithErrors = [];
    self.resetImportModel();
    $('#fandiImportModal').modal('show');
  },

  // Reset import modal
  resetImportModel: function () {
    const file = document.querySelector('#uploadFandi');
    file.value = '';  // Remove File Name 
    self.files = []; // Remove File List
    self.importBtn = 'Import';
    $('#fandiImportBtn').prop("disabled", false);
    $('#fandiImportClose').prop("disabled", false);
  },

  // Load list data
  loadListData: function (first) {
    self.listExecutionState = true;
    if (self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
      self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
      self.perPage = 500;
      self.loadListData();
    }
    first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
    var config = {
      headers: { Authorization: "Bearer " + localStorage.getItem("api_token") }
    };

    var fields = {
      filters: self.filters,
      per_page: self.perPage,
      page: self.currentPage,
      sortColumnName: self.columnName,
      sortType: self.sortType,
    };

    $("#commonLoaderListFandI")
        .find(".loading-container.lodingTable")
        .show();
    HTTP.post("/fandi/list", fields, config)
      .then(function (response) {
        self.listExecutionState = false;
        if (first) {
          self.currentPage = self.jumpToHistoryPage;
        }
        $("#commonLoaderListFandI")
            .find(".loading-container.lodingTable")
            .hide();
        if (response.data.code == 200) {
          self.headers = [];
          self.totalRecords = response.data.content.result;

          if (self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
            let seconds = 5000;
            self.$swal({
              type: 'error',
              html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
              showConfirmButton: false,
              timer: seconds,
            });
            setTimeout(() => {
              self.perPage = 500;
              self.loadListData();
            }, seconds);
          }

          self.records = response.data.content.data;
          self.headers = response.data.content.headers;

          if (response.data.content.per_page !== self.totalRecords) {
            self.perPage = parseInt(response.data.content.per_page);
            self.jumpToPage = parseInt(response.data.content.current_page);
          } else {
            if (self.perPage == 'All') {
              self.jumpToPage = 1;
            }
            self.perPageValue = self.totalRecords;
            self.currentPage = 1;
          }
          self.setAlreadyAppliedFilters();
          self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords)
          self.sortFieldDisplay(self.columnName, self.sortType);
        }
      })
      .catch(function (error) {
        self.listExecutionState = false;
        $("#commonLoaderListFandI")
            .find(".loading-container.lodingTable")
            .hide();
        self.catchHandler(error, function () {});
      })
  },

  // Set already applied filters
  setAlreadyAppliedFilters: function () {
    if (self.appliedHeaders.length > 0) {
      self.headers = self.appliedHeaders;
    }
  },

  loadMore: function () {
    this.currentPage++;
    this.fetchData();
  },

  // Handle file upload
  handleFilesUpload: function () {
    var self = this;
    let uploadedFiles = "";
    uploadedFiles = self.$refs.files.files;
    for (var i = 0; i < uploadedFiles.length; i++) {
      self.files = [uploadedFiles[i]];
    }
  },

  // Change per page value
  changePerPage: function () {
    self.currentPage = 1;
    if (self.perPage !== "All") {
      self.perPageValue = self.perPage;
    }
    self.loadListData();
  },

  // Change page
  changepage: function () {
    if (!self.listExecutionState) {
      self.loadListData();
    }
  },
},
  mounted() {
    self.staticInputLabelMessages = self.allLabelMessages;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
        self.$router.push('/');
    }
    /* Redirect to login page logic end */

    self.setActiveMenu('fandi-list');
    EventBus.$emit("menu-selection-changed", "fandi-list");

    var listHistoryData = null;
    self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;

    self.loadListData(1);
  },
};

EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
        self.permissionNameArray = [];
        for (let i = 0; i < permissions.length; i++) {
            self.permissionNameArray.push(permissions[i].permission_name)
        }
        if (!self.permissionNameArray.includes('can_list_f&i_master')) {
            setTimeout(function () {
                self.actionDisplay = false;
            }, 50);
        }
    }
});
</script>