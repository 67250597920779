<template>
  <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="defaultModalLabel">
            Vehicle Worksheet
          </h4>
        </div>
        <div class="modal-body">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Vehicle Information
                  </button>
                </h2>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div
                    class="forms-container add_user_forms_container vtc-dealer-forms"
                  >
                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            VIN
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Quantity
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Manufacturer
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Unit ID
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Model / OEM
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Year
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Stock Type
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Body Type
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            N/U
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Duty
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Fuel Type
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Stock / Order
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Odometer
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                        <div class="col-md-2 col-sm-12 paddingleft0">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              MI
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              KM
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Scale Weight
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Vehicle Description
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            # of Batteries
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            # of Tires
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            # of Axles
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Private Use
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            checked
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Business Use
                          </label>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Proposed Delvery Date
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                        <div class="col-md-2 col-sm-12 paddingleft0">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Delivered
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Fees
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                class="collapse show"
                aria-labelledby="headingTwo"
              >
                <div class="card-body">
                  <div
                    class="forms-container add_user_forms_container vtc-dealer-forms"
                  >
                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            GVW / GCWR
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Docs Preparation Fees
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Customer Supplied Body
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            License / Title
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                        <div class="col-md-2 col-sm-12 paddingleft0">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              No License
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Custom Fee
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Tire Fee
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Registration
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Flooring
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Smog Fee Paid to Seller
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Tire Credit
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Smog Certification or Exemption Fee Paid to State
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            FET Excempt Items
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            DMV Electronic Filing Fee
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            FET
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                        <div class="col-md-2 col-sm-12 paddingleft0">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              FET Example
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Custom FET
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            CETTS Only - Admin Fee
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Custom Fields
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                class="collapse show"
                aria-labelledby="headingThree"
              >
                <div class="card-body">
                  <div
                    class="forms-container add_user_forms_container vtc-dealer-forms"
                  >
                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Finance Reserve
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Factory Price
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Deal Adjustment NC
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            IMF / Sales Tax
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Fleet Reserve Cost
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Delivery Delay Credit
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Body Cost
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            ADOT Permit#
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Fleet Reserve Control#
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Hold Date
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Factory Inv. Date
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Vehicle Cost Calculation
                  </button>
                </h2>
              </div>
              <div
                id="collapseFour"
                class="collapse show"
                aria-labelledby="headingFour"
              >
                <div class="card-body">
                  <div
                    class="forms-container add_user_forms_container vtc-dealer-forms"
                  >
                    <div class="row no-margin">
                      <div class="col-md-8 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Current Inventorv Cost From Procede:
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-12 forms-block"></div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Inventory Cost (Last Updated)
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-12 forms-block text-center">
                        <button
                          id="assetHeaderList"
                          title="Inventory Header List"
                          class="btn btn-primary waves-effect ml-2"
                        >
                          Add-On Lists
                        </button>
                        <button
                          id="exportAsset"
                          title="Export"
                          class="btn btn-primary waves-effect ml-2"
                        >
                          Purchase Order
                        </button>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-12 m-auto pb-3">
                        <div
                          class="table-listing white-bg border-blue border-radius-top-right"
                        >
                          <table class="table custom-table jsmartable">
                            <thead>
                              <tr>
                                <th>ITEM NAME</th>
                                <th>DESCRIPTION</th>
                                <th>TAX</th>
                                <th>COST</th>
                                <th>PRICE</th>
                                <th>ADD TO PRICE</th>
                                <th>EXCL FROM FET</th>
                                <th>SHOW ON DOC</th>
                                <th>DONOT ADD TO GP</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainDetails">
                                <td>No records to display</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-8 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Total Cost of Addons Above
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-12 col-sm-12 forms-block">
                        <div class="col-md-8 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Cost Sub-Total before Pack (Including Flooring)
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row no-margin">
                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-4 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Holdback Fixed
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                        <div class="col-md-2 col-sm-12 paddingleft0">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Custom Holdback
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 forms-block">
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <div class="form-label">
                            Percentage =
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 paddingleft0">
                          <input
                            @input="cusPhoneNumberError = ''"
                            id="cusPhoneNumber"
                            v-model="cusPhoneNumber"
                            class="form-control"
                            type="text"
                            maxlength="190"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import { commonMixins } from "../../../mixins/commonMixins";

export default {
  name: "AssetSpecSheet",
  components: {},
  mixins: [commonMixins],
  data() {
    return {
      cusPhoneNumber: "",
      cusPhoneNumberError: ""
    };
  },
  watch: {},
  beforeMount() {
    self = this;
  },
  mounted: function() {},
  methods: {}
};
</script>
