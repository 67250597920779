<template>
    <div class="admin-page-content">
        <div class="col-md-12 error-detail-main forms-container">
            <div class="error-template">
                <h1>Oops!</h1>
                <h2>403</h2>
                <div class="error-details">Sorry!, You don't have permission to access this page.</div>
            </div>
        </div>
    </div>
</template>

<script>
    var self;

    export default {
        name: 'NoAccessPage',
        components: {
        },
        data () {
            return {

            }
        },
        beforeMount() {
            self = this;
        },

        mounted: function() {

        },
        methods: {

        },
    }

</script>