<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="(permissionNameArray.includes('can_add_used_truck_moved_submission_form')) && userCompany.includes(companyId)">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!--Form Name-->
                            <div class="col-6 forms-block">
                                <!--Salesman Name-->
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.salesman_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="salesmanId"
                                            name="salesmanId"
                                            id="salesmanId"
                                            label="text"
                                            @change="changedValue"
                                            @input="salesmanIdError = ''"
                                            :filterable="false"
                                            :options="salesmanIdArr"
                                            @search="onSearchSalesmanUser"
                                            :disabled="true"
                                            :placeholder="staticMessages.salesman_user_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalesmanNameRecordNotFound">
                                                {{staticMessages.salesman_user_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{salesmanIdError}}</span>
                                </div>
                            </div>

                            <!-- Type of Request -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.type_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="typeOfRequestId"
                                            name="typeOfRequestId"
                                            label="text"
                                            :filterable="false"
                                            :options="typeOfRequestIdArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.type_of_request_place_holder"
                                            :disabled="true">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!-- Request Number -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.request_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control">{{requestNo}}</span>
                                </div>
                            </div>

                            <!-- Location of Unit -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_of_unit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                  <v-select
                                          v-model="locationOfUnit"
                                          name="locationOfUnit"
                                          id="locationOfUnit"
                                          label="text"
                                          :clearable="false"
                                          :searchable="false"
                                          :filterable="false"
                                          :options="locationOfUnitArr"
                                          :placeholder="staticMessages.location_of_unit_place_holder"
                                          :disabled="true">
                                  </v-select>
                                  <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!--Send To Manager -->
                            <div class="col-md-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.send_to_manager1_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                            v-model="salesManagerId"
                                            name="asset"
                                            label="text"
                                            :filterable="false"
                                            :options="salesManagerArr"
                                            :clearable="false"
                                            :placeholder="staticMessages.send_to_manager1_search"
                                            :disabled="true">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!-- Is unit ready for pick up -->
                            <div class="col-6 forms-block">
                              <div class="col-3 paddingleft0">
                                <div class="form-label"> {{staticInputLabelMessages.is_unit_ready_for_pickup_label}} </div>
                                <div class="requiredstar">*</div>
                              </div>
                              <div class="col-9 paddingleft0">
                                <b-form-radio-group
                                    v-model="isUnitReadyForPickup"
                                    :options="isUnitReadyForPickupOptions"
                                    name="isUnitReadyForPickup"
                                    :disabled="true"
                                  >
                                </b-form-radio-group>
                              </div>
                            </div>

                            <!-- Description of Request -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_of_request_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea style="overflow: auto;" v-model="descriptionOfReq" name="descriptionOfReq" id="descriptionOfReq" class="form-control" maxlength="50000" disabled></textarea>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!-- Pickup Date -->
                            <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.pickup_date_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <date-picker
                                                v-model="pickUpDate"
                                                :config="options"
                                                autocomplete="off"
                                                class="form-control"
                                                :placeholder="staticMessages.pickup_date_place_holder"
                                                name="pickUpDate"
                                                @dp-change="changePickupDate()"
                                        >
                                        </date-picker>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>

                            <!-- Pickup Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.pickup_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control" style="overflow: auto;">{{pickupLocation}}</span>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!-- Drop off Date -->
                            <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.drop_off_date_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <date-picker
                                                v-model="dropOffDate"
                                                :config="options"
                                                autocomplete="off"
                                                class="form-control"
                                                :placeholder="staticMessages.drop_off_date_place_holder"
                                                name="dropOffDate"
                                        >
                                        </date-picker>
                                        <div class="requiredstar">*</div>
                                    </div>
                                </div>

                            <!-- Drop off Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_off_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <span class="form-control" style="overflow: auto;">{{dropOffLocation}}</span>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>

                            <!-- Moved Submission Request Notify Email -->
                            <div class="col-6 forms-block">
                              <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.moved_submission_request_notify_email_label}}</div>
                              </div>
                              <div class="col-9 paddingleft0 moved-email-block">
                                <vue-tags-input
                                      v-model="movedSubmissionNotifyEmail"
                                      :tags="movedSubmissionNotifyEmailTagsArr"
                                      @tags-changed="newTags => movedSubmissionNotifyEmailTagsArr = newTags"
                                      placeholder=""
                                      disabled
                                />
                              </div>
                            </div>

                            <template v-for="(data, vinDataIndex) in allVINJsonData">
                                <!--VIN no-->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.vinNos}}</span>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!-- Selling Price -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.selling_price_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <span style="overflow: auto;" class="form-control">{{data.sellingPrices}}</span>
                                    </div>
                                </div>
                                <div class="col-6 forms-block"></div>

                                <!-- PO Number -->
                                <template v-if="permissionNameArray.includes('can_add_po_no_to_used_truck_moved_submission_form') && statusId.value == 'approve' && oldStatus.value == 'approve'">
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                          <input @input="data.poNoError = ''" id="poNo" v-model="data.poNo" class="form-control" type="text" maxlength="20"/>
                                          <div class="requiredstar">*</div>
                                          <span class="help is-danger">{{data.poNoError}}</span>
                                      </div>
                                  </div>
                                  <div class="col-6 forms-block"></div>
                                </template>
                                <template v-else-if="statusId.value == 'approve' && oldStatus.value == 'approve' && data.poNo != '' && data.poNo != null">
                                  <div class="col-6 forms-block">
                                      <div class="col-3 paddingleft0">
                                          <div class="form-label">{{staticInputLabelMessages.po_number_label}}</div>
                                      </div>
                                      <div class="col-9 paddingleft0">
                                          <span style="overflow: auto;" class="form-control">{{data.poNo}}</span>
                                          <div class="requiredstar">*</div>
                                      </div>
                                  </div>
                                  <div class="col-6 forms-block"></div>
                                </template>
                            </template>

                            <!-- Dispatch Details -->
                            <template v-if="permissionNameArray.includes('can_add_dispatch_details_to_used_truck_moved_submission_form') && oldStatus.value == 'in progress' && (statusId.value == 'in progress' || statusId.value == 'approve') && approvalLevel == 1  && oldETA == ''">
                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.eta_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <date-picker
                                          v-model="eta"
                                          :config="etaDateOptions"
                                          autocomplete="off"
                                          class="form-control"
                                          :placeholder="staticMessages.eta_place_holder"
                                          name="eta"
                                          @input="etaError=''"
                                        >
                                      </date-picker>
                                      <div class="requiredstar">*</div>
                                      <span class="help is-danger">{{etaError}}</span>
                                  </div>
                              </div>

                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.cost_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <input @input="costError = ''" id="cost" v-model="cost" class="form-control" type="text" maxlength="17"/>
                                      <div class="requiredstar">*</div>
                                      <span class="help is-danger">{{costError}}</span>
                                  </div>
                              </div>

                              <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vendorError = ''" id="vendor" v-model="vendor" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vendorError}}</span>
                                </div>
                              </div>

                              <div class="col-md-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.send_to_manager2_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <v-select
                                              v-model="salesManagerId2"
                                              name="salesManagerId2"
                                              @input="salesManagerError2 = ''"
                                              label="text"
                                              :filterable="false"
                                              :options="salesManagerArr2"
                                              @search="onSearchSalesManager2"
                                              :clearable="false"
                                              :placeholder="staticMessages.send_to_manager2_search">
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              <template v-if="!isSalesManager2RecordNotFound">
                                                  {{staticMessages.send_to_manager2_search}}
                                              </template>
                                              <template v-else>
                                                  {{staticMessages.search_record_not_found}}
                                              </template>
                                          </span>
                                      </v-select>
                                      <div class="requiredstar">*</div>
                                      <span class="help is-danger">{{salesManagerError2}}</span>
                                  </div>
                              </div>
                            </template>
                            <template v-else-if="approvalLevel >= 1 && oldETA != ''">
                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.eta_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <date-picker
                                          v-model="eta"
                                          :config="options"
                                          autocomplete="off"
                                          class="form-control"
                                          :placeholder="staticMessages.eta_place_holder"
                                          name="eta"
                                          @input="etaError=''"
                                        >
                                      </date-picker>
                                      <div class="requiredstar">*</div>
                                  </div>
                              </div>

                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.cost_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <span class="form-control">{{cost}}</span>
                                  </div>
                              </div>
                              <div class="col-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.vendor_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <span class="form-control">{{vendor}}</span>
                                  </div>
                              </div>
                              <div class="col-md-6 forms-block">
                                  <div class="col-3 paddingleft0">
                                      <div class="form-label">{{staticInputLabelMessages.send_to_manager2_label}}</div>
                                  </div>
                                  <div class="col-9 paddingleft0">
                                      <v-select
                                              v-model="salesManagerId2"
                                              name="salesManagerId2"
                                              label="text"
                                              :filterable="false"
                                              :options="salesManagerArr2"
                                              :clearable="false"
                                              :placeholder="staticMessages.send_to_manager2_search"
                                              :disabled="true">
                                      </v-select>
                                      <div class="requiredstar">*</div>
                                  </div>
                              </div>
                            </template>

                            <!-- Status -->
                            <template v-if="permissionNameArray.includes('can_change_status_used_truck_moved_submission_form') || isManager || isManager2">
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="(approvalLevel == 0 && oldStatus.value == 'in progress') || (approvalLevel == 1 && oldETA != '')">
                                          <v-select
                                                  v-model="statusId"
                                                  @change="changedValue"
                                                  name="statusId"
                                                  id="statusId"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="StatusTypeArr"
                                                  :placeholder="staticMessages.status_place_holder">
                                          </v-select>
                                          <div class="requiredstar">*</div>
                                          <span class="help is-danger">{{statusIdError}}</span>
                                        </template>
                                        <template v-else>
                                          <v-select
                                                  v-model="statusId"
                                                  @change="changedValue"
                                                  name="statusId"
                                                  id="statusId"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="disableStatusTypeArr"
                                                  :placeholder="staticMessages.status_place_holder"
                                                  :disabled="true">
                                          </v-select>
                                        </template>
                                    </div>
                                </div>

                                <!-- Status Comment -->
                                <div v-if="statusId.value != 'in progress' && oldStatus.value == 'in progress' && approvalLevel == 0" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <template v-if="statusId.value == 'cancel'">
                                            <div class="form-label">{{staticInputLabelMessages.reason}}</div>
                                        </template>
                                        <template v-else>
                                            <div class="form-label">{{staticInputLabelMessages.first_approval_comment_label}}</div>
                                        </template>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="(isManager || isManager2 || loginUsrRole <= 2 || permissionNameArray.includes('can_change_status_used_truck_moved_submission_form')) && oldStatus.value == 'in progress'">
                                          <textarea id="notes" v-model="statusComment" class="form-control" type="text" maxlength="50000"></textarea>
                                        </template>
                                        <template v-else>
                                          <textarea id="notes" v-model="statusComment" class="form-control" type="text" maxlength="50000" disabled></textarea>
                                        </template>
                                    </div>
                                </div>

                                <div v-if="statusId.value != 'in progress' && oldStatus.value == 'in progress' && approvalLevel == 1" class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <template v-if="statusId.value == 'cancel'">
                                            <div class="form-label">{{staticInputLabelMessages.reason}}</div>
                                        </template>
                                        <template v-else>
                                            <div class="form-label">{{staticInputLabelMessages.second_approval_comment_label}}</div>
                                        </template>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <template v-if="(isManager || isManager2 || loginUsrRole <= 2 || permissionNameArray.includes('can_change_status_used_truck_moved_submission_form')) && oldStatus.value == 'in progress'">
                                          <textarea id="statusComment2" v-model="statusComment2" class="form-control" type="text" maxlength="50000"></textarea>
                                        </template>
                                        <template v-else>
                                          <textarea id="statusComment2" v-model="statusComment2" class="form-control" type="text" maxlength="50000" disabled></textarea>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <v-select
                                            v-model="statusId"
                                            @change="changedValue"
                                            name="statusId"
                                            id="statusId"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="disableStatusTypeArr"
                                            :placeholder="staticMessages.status_place_holder"
                                            :disabled="true">
                                          </v-select>
                                    </div>
                                </div>
                            </template>

                            <div class="col-12 forms-block">
                              <div class="col-12" style="height: 40px;"></div>

                              <div class="col-12" style="margin-bottom: 10px;">
                                  <div class="form-label approval-info-block">
                                      APPROVAL MILESTONE
                                  </div>
                              </div>
                            </div>
                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_label}}</div>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_perform_by_label}}</div>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.action_perform_at_label}}</div>
                                </div>
                            </div>

                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.comment_reason_label}}</div>
                                </div>
                            </div>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <div class="form-label header-block">{{staticInputLabelMessages.status_label1}}</div>
                                </div>
                            </div>
                            <br>

                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">Request Initiated</span>
                                </div>
                            </div>

                            <!-- Move Request Created By -->
                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{ createdBy }}</span>
                                </div>
                            </div>

                            <!-- Move Request At -->
                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{ createdAt }}</span>
                                </div>
                            </div>

                            <!-- Move Request Comment -->
                            <div class="col-3 forms-block">
                                <div class="col-12 paddingleft0">
                                  <span class="form-control data-block"> - </span>
                                </div>
                            </div>

                            <!-- Move Request Status -->
                            <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                  <span class="badge badge-primary badge-pill bg-color-0771b4 status-block">Initiated</span>
                                </div>
                            </div>

                            <template v-if="approvalLevel == 0 && oldStatus.text == 'Rejected'">
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">Rejected</span>
                                  </div>
                              </div>

                              <!-- Rejected By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeBy }}</span>
                                  </div>
                              </div>

                              <!-- Rejected At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeAt }}</span>
                                  </div>
                              </div>

                              <!-- Reason -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{statusComment || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="badge badge-primary badge-pill bg-color-c3202b status-block">Rejected</span>
                                  </div>
                              </div>
                            </template>

                            <template v-if="(approvalLevel != 0 && oldStatus.text == 'In Progress') || (approvalLevel == 1 && oldStatus.text == 'Rejected') || approvalLevel == 2">
                              <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">Level - 1 Approval</span>
                                </div>
                              </div>

                              <!-- Approved By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeBy }}</span>
                                  </div>
                              </div>

                              <!-- Approved At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeAt }}</span>
                                  </div>
                              </div>

                              <!-- Comment -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{statusComment  || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">Approved</span>
                                  </div>
                              </div>
                            </template>

                            <template v-if="(approvalLevel == 1 && oldStatus.text == 'In Progress') || (approvalLevel == 1 && oldStatus.text == 'Rejected') || approvalLevel == 2">
                              <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">Dispatch - Set up a move</span>
                                </div>
                              </div>

                              <!-- Dispatched By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ dispatchedBy || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Dispatched At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ dispatchedAt || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Comment -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">-</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span v-if="approvalLevel == 1 && oldStatus.text == 'In Progress' &&  oldETA == ''" class="badge badge-primary badge-pill bg-color-0771b4 status-block">Processing</span>
                                      <span v-if="(approvalLevel == 1 && oldStatus.text == 'Rejected') || (approvalLevel == 1 && oldStatus.text == 'In Progress' &&  oldETA != '') || approvalLevel == 2" class="badge badge-primary badge-pill bg-color-71aa3f status-block">Processed</span>
                                  </div>
                              </div>
                            </template>

                            <template v-if="approvalLevel == 1 && oldStatus.text == 'Rejected'">
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">Rejected</span>
                                  </div>
                              </div>

                              <!-- Rejected By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeBy2 }}</span>
                                  </div>
                              </div>

                              <!-- Rejected At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeAt2 }}</span>
                                  </div>
                              </div>

                              <!-- Reason -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{statusComment2 || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="badge badge-primary badge-pill bg-color-c3202b status-block">Rejected</span>
                                  </div>
                              </div>
                            </template>

                            <template v-if="approvalLevel == 2 && oldStatus.text == 'Approved'">
                              <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">Level - 2 Approval</span>
                                </div>
                              </div>

                              <!-- Approved By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeBy2 }}</span>
                                  </div>
                              </div>

                              <!-- Approved At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ statusChangeAt2 }}</span>
                                  </div>
                              </div>

                              <!-- Comment -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">{{statusComment2  || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="badge badge-primary badge-pill bg-color-71aa3f status-block">Approved</span>
                                  </div>
                              </div>
                            </template>

                            <template v-if="approvalLevel == 2 && oldStatus.text == 'Approved'">
                              <div class="col-2 forms-block">
                                <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">PO Created</span>
                                </div>
                              </div>

                              <!-- PO Created By -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ poCreatedBy || '-' }}</span>
                                  </div>
                              </div>

                              <!-- PO Created At -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span class="form-control data-block">{{ poCreatedAt || '-' }}</span>
                                  </div>
                              </div>

                              <!-- Comment -->
                              <div class="col-3 forms-block">
                                  <div class="col-12 paddingleft0">
                                    <span class="form-control data-block">-</span>
                                  </div>
                              </div>

                              <!-- Move Request Status -->
                              <div class="col-2 forms-block">
                                  <div class="col-12 paddingleft0">
                                      <span v-if="!poCreatedBy" class="badge badge-primary badge-pill bg-color-0771b4 status-block">PO Pending</span>
                                      <span v-if="poCreatedBy" class="badge badge-primary badge-pill bg-color-71aa3f status-block">PO Created</span>
                                  </div>
                              </div>
                            </template>

                            <div class="col-12 forms-block" v-show="attachment.length > 0">
                                <div class="col-12 forms-block paddingleft0 deposit-check-field">
                                    <div class="col-12 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.attach_approval_or_deposite_check_image}}</div>
                                    </div>
                                    <div class="col-12 paddingleft0">
                                        <div class="slider">
                                            <template>
                                                <swiper class="" :options="ApprovalOrDepositCheck" ref="ApprovalOrDepositCheck">
                                                    <template v-for="(doc, index1) in attachment">
                                                        <template v-if="doc.ext.toLowerCase() == 'jpeg' || doc.ext.toLowerCase() == 'jpg' || doc.ext.toLowerCase() == 'png' || doc.ext.toLowerCase() == 'gif'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" :src="doc.file"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'doc' || doc.ext.toLowerCase() == 'docx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/doc-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'xls' || doc.ext.toLowerCase() == 'xlsx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/xl-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'ppt' || doc.ext.toLowerCase() == 'pptx'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/ppt-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else-if="doc.ext.toLowerCase() == 'pdf'">
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/pdf-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                        <template v-else>
                                                            <swiper-slide>
                                                                <a href="#" @click="getSignedUrlPath(doc.file_name)"><img style="width: 52px;height: 70px;" src="/static/images/icons/link-icon.png" class="link-icon"></a>
                                                            </swiper-slide>
                                                        </template>
                                                    </template>
                                                </swiper>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError" style="display : none;">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div v-if="((isManager || isManager2 || loginUsrRole <= 2 || permissionNameArray.includes('can_change_status_used_truck_moved_submission_form')) && ((approvalLevel == 0 && oldStatus.value == 'in progress') || (approvalLevel == 1 && oldETA != '')))
                                  || (permissionNameArray.includes('can_add_po_no_to_used_truck_moved_submission_form') && statusId.value == 'approve' && oldStatus.value == 'approve')
                                  || (permissionNameArray.includes('can_add_dispatch_details_to_used_truck_moved_submission_form') && oldStatus.value == 'in progress' && (statusId.value == 'in progress' || statusId.value == 'approve')  && approvalLevel == 1 && oldETA == '')" class="button-demo">
                            <input id="addBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUsedTruckMovedSubmissionForm()">
                            <input id="cancelBtn" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    /* Swiper for submenu tebs start */
    import 'swiper/dist/css/swiper.css';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    /* Swiper for submenu tebs end */
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.component("app-footer", Footer);

    export default {
        name: 'UpdateUsedTruckMovedSubmission',
        components: {
            swiper,
            swiperSlide,
            datePicker,
            VueTagsInput,
        },
        mixins: [commonMixins],
        data() {
            return {
                statusId:{text: "In Progress", value: "in progress"},
                statusIdError:"",
                statusComment:"",
                statusCommentError:"",
                StatusTypeArr: [{text: "Approve", value: "approve"}, {text: "Reject", value: "cancel"}, {text: "In Progress", value: "in progress"}],
                disableStatusTypeArr: [{text: "Approved", value: "approve"}, {text: "Rejected", value: "cancel"}, {text: "In Progress", value: "in progress"}],
                userCompany : [1],
                companyId : 1,
                ApprovalOrDepositCheck: {
                    slidesPerView: 14.5,
                    spaceBetween: 8,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1500: {
                            slidesPerView: 12.5
                        },
                        1400: {
                            slidesPerView: 11.5
                        },
                        1300: {
                            slidesPerView: 10.4
                        },
                        1200: {
                            slidesPerView: 8.5
                        },
                        1000: {
                            slidesPerView: 7.5
                        },
                        667: {
                            slidesPerView: 5.5
                        },
                        480: {
                            slidesPerView: 3.5
                        },
                        360: {
                            slidesPerView: 3.4
                        }
                    }
                },
                attachment: "",
                staticInputLabelMessages: "",
                staticMessages: "",
                salesmanId: "",
                salesmanIdArr: [],
                isSalesmanNameRecordNotFound: false,
                /* vin: "", */
                currentLocation : "",
                moveLocation : "",
                sellingPrice: "",
                /* customerName: "", */
                hasSeenTruckInPerson: "yes",
                hasSeenTruckInPersonOptions:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                completed: false,
                note: "",
                completeById: "",
                completeByArr: [],
                isCompleteByRecordNotFound: false,
                createdAt: "",

                reason : "",
                reasonOptions:[
                    {text: 'Sold', value: 'sold'},
                    {text: 'Docs', value: 'docs'},
                    {text: 'Inventory Stock', value: 'inventory stock'},
                    {text: 'Shop', value: 'shop'},
                    {text: 'OSD', value: 'osd'},
                    {text: 'New Purchase', value: 'new purchase'},
                ],

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    disable : true
                    // disabledTimeIntervals: false,
                },
                estimateDeliveryDate : "",
                estimateDateDeliveryDateError : "",

                isTruckReadyForDelivery : "",
                isTruckReadyForDeliveryOptions:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],

                // Error
                salesmanIdError: "",
                completeByIdError: "",
                /* vinError: "", */
                sellingPriceError: "",
                /* customerNameError: "", */
                //
                buttonNames: {
                    save: 'Save',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: 'Add Used Truck Move Submission Form',
                permissionNameArray: ['can_add_used_truck_moved_submission_form'],
                all_vin_data : "",
                allVINJsonData : [],
                destCntNames : "",

                typeOfRequestId : "",
                typeOfRequestIdArr : [
                  {text: 'OSD', value: 'osd'},
                  {text: 'Store to Store', value: 'store_to_store'},
                  {text: 'Vendor to Store', value: 'vendor_to_store'}
                ],
                requestNo : '',
                locationOfUnit : "",
                locationOfUnitArr : [],
                salesManagerId : "",
                salesManagerArr : [],
                isUnitReadyForPickup : "",
                isUnitReadyForPickupOptions : [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                descriptionOfReq : "",
                pickUpDate : "",
                pickupLocation : "",
                dropOffDate : "",
                dropOffLocation : "",
                isManager : false,
                loginUsrEml : '',
                oldStatus : { text: "In Progress", value: "in progress" },
                statusChangeBy : '',
                statusChangeAt : '',
                defaultPOReqEmailArr : [],
                poNo : '',
                poNoError : '',
                oldPoNo : '',
                poCreatedBy : '',
                poCreatedAt : '',
                eta : '',
                etaError : '',
                oldETA : '',
                defaultDispatcherEmailArr : [],
                etaDateOptions : {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                    minDate: new Date(),
                },
                dispatchedBy : '',
                dispatchedAt : '',
                createdBy : '',
                loginUsrRole : 1,
                approvalLevel : 0,
                statusComment2: "",
                cost : '',
                costError : '',
                vendor : '',
                vendorError : '',
                salesManagerId2 : '',
                salesManagerError2 : '',
                salesManagerArr2 : [],
                isSalesManager2RecordNotFound : false,
                statusChangeBy2 : '',
                statusChangeAt2 : '',
                isManager2 : false,
                movedSubmissionNotifyEmail : '',
                movedSubmissionNotifyEmailTagsArr: [],
                isRedirection: null,
            }
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            self.isRedirection = process.env.VUE_APP_IS_REDIRECTION;

            /* Redirect to login page logic start */
            let userObj = localStorage.getItem("user_obj");
            if (userObj == null) {

                /* Set redirection link specified in the email template to navigate to the edit page  --- Start */
                if (self.isRedirection === 'true') {
                  // Check & set redirection path if available
                  if(typeof window.location.pathname !== 'undefined' && window.location.pathname != 'null' && window.location.pathname != '') {
                    if(window.location.pathname != '/') {
                      localStorage.setItem('redirect_url', window.location.pathname);
                    }
                  }
                }
                /* Set redirection link specified in the email template to navigate to the edit page --- End */

                self.$router.push('/');
            } else if(self.$route.query.token) {
                self.$router.push({name: "UpdateUsedTruckMovedSubmission", params: {id: self.$route.params.id}});
                location.reload();
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.getLoginIdPermissions();
            self.setActiveMenu('used_truck_move_submission_form');
            EventBus.$emit("menu-selection-changed", "used_truck_move_submission_form");
            $("#alertError").hide();
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                let userJson = JSON.parse(userObj);
                self.loginUsrEml = userJson['username'];
                self.loginUsrRole = localStorage.getItem("roleId");
                if (typeof (userJson.company_id) == 'undefined' && userJson.company_id == 0 && userJson.company_id == '') {
                    self.logout();
                }
            }
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getUsedTruckHoldsSubmissionFormDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                self.pageTitle = "Edit Used Truck Move Submission Form";
            }
            self.loadAllData(['User'], true, self.loadMasterDataCallback, "undefined", '');
        },
        watch: {
            statusId: function (statusId) {
              if (typeof (self.$route.params.id) === 'undefined' && self.$route.params.id == 0 && self.$route.params.id == '') {
                if (statusId === null || typeof statusId.value === "undefined" || statusId.value === null || statusId.value == '') {
                  } else {
                      self.statusCommentError="";
                      self.statusComment="";
                  }
              }
            }
        },
        methods: {
            buttonBack() {
                self.$router.push({name: 'ListUsedTruckMovedSubmission'});
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            changePickupDate : function(e) {
              self.etaDateOptions.minDate = self.pickUpDate;
            },
            loadCompleteByDataCallback: function(callbackData) {
                self.completeByArr = callbackData.User;
            },
            onSearchCompleteBy(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getCompleteByData);
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isSalesManager2RecordNotFound = false;
                self.salesManagerArr2 = callbackData.User;
                if (self.salesManagerArr2.length == 0) {
                  self.isSalesManager2RecordNotFound = true;
                }
            },
            onSearchSalesManager2 : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesManager2TypeCallback, loading, search);
                }
            },
            loadSalesManager2TypeCallback: function (callbackData) {
                self.isSalesManager2RecordNotFound = false;
                self.salesManagerArr2 = callbackData.User;
                if (self.salesManagerArr2.length == 0) {
                    self.isSalesManager2RecordNotFound = true;
                }
            },
            getCompleteByData: function (callbackData) {
                self.isCompleteByRecordNotFound = false;
                self.completeByArr = callbackData;
                if(self.completeByArr.length == 0) {
                    self.isCompleteByRecordNotFound = true;
                }
            },
            onSearchSalesmanUser(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadSalesmanUser(loading, search, this, self.getSalesmanData);
                }
            },
            getSalesmanData: function(callbackData) {
                self.isSalesmanNameRecordNotFound = false;
                self.salesmanIdArr = callbackData;
                if(self.salesmanIdArr.length == 0) {
                    self.isSalesmanNameRecordNotFound = true;
                }
            },
            getUsedTruckHoldsSubmissionFormDetailsById: function (id) {
                let config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/usedtruckmovesubmissionform/get', config)
                    .then(response => {
                        if (response.data.content.salesmanId != null) {
                            self.salesmanId = {text: response.data.content.uSalesFName + " " + response.data.content.uSalesLName, value: response.data.content.salesmanId};
                        }
                        
                        self.typeOfRequestId = response.data.content.type_of_request;
                        self.requestNo = response.data.content.id;
                        self.locationOfUnit = response.data.content.location_of_unit_name;
                        self.salesManagerId = response.data.content.send_to_manager_id;
                        self.isUnitReadyForPickup = response.data.content.is_unit_ready_for_pick_up;
                        self.descriptionOfReq = response.data.content.description_of_request;
                        self.pickUpDate = response.data.content.pick_up_at;
                        self.pickupLocation = response.data.content.current_location;
                        self.dropOffDate = response.data.content.drop_off_at;
                        self.dropOffLocation = response.data.content.move_location;

                        if (response.data.content.to_email != '' && response.data.content.to_email != null && typeof (response.data.content.to_email) != "undefined") {
                          let email = response.data.content.to_email;
                          email = email.split(",");
                          for (const element of email) {
                            self.movedSubmissionNotifyEmailTagsArr.push({"text":element.trim(),"tiClasses":["ti-valid"]});
                          }
                        }

                        self.attachment = response.data.content.attachment_path;
                        self.all_vin_data = response.data.content.all_vin_data;
                        self.allVINJsonData = response.data.content.all_vin_json_data;
                        
                        self.companyId = response.data.content.company_id;
                        self.statusComment = "";
                        if (response.data.content.status_reason != null && response.data.content.status_reason != 'null' && typeof response.data.content.status_reason != "undefined" && response.data.content.status_reason != '') {
                            self.statusComment = response.data.content.status_reason;
                        }
                        self.oldStatus = self.statusId = {text: response.data.content.display_status, value: response.data.content.status};

                        if (response.data.content.send_to_manager_id.email != null) {
                          if(self.loginUsrEml == response.data.content.send_to_manager_id.email && response.data.content.approval_level == 0 && self.oldStatus.text == 'In Progress') {
                            self.isManager = true;
                          }
                        }

                        if (response.data.content.status_change_by != null) {
                          self.statusChangeBy = response.data.content.status_change_by;
                        }
                        if (response.data.content.status_change_at != null) {
                          self.statusChangeAt = response.data.content.status_change_at;
                        }
                        if (response.data.content.po_no != null) {
                          self.oldPoNo = self.poNo = response.data.content.po_no;
                        }
                        if (response.data.content.po_requested_by != null) {
                          self.poCreatedBy = response.data.content.po_requested_by;
                        }
                        if (response.data.content.po_requested_at != null) {
                          self.poCreatedAt = response.data.content.po_requested_at;
                        }
                        if (response.data.content.eta != null) {
                          self.oldETA = self.eta = response.data.content.eta;
                        }
                        if (response.data.content.dispatched_by != null) {
                          self.dispatchedBy = response.data.content.dispatched_by;
                        }
                        if (response.data.content.dispatched_at != null) {
                          self.dispatchedAt = response.data.content.dispatched_at;
                        }
                        if (response.data.content.created_by != null) {
                          self.createdBy = response.data.content.created_by;
                        }
                        if (response.data.content.createdAt != null) {
                          self.createdAt = response.data.content.createdAt;
                        }
                        self.etaDateOptions.minDate = self.pickUpDate;
                        self.approvalLevel = response.data.content.approval_level;

                        if (response.data.content.status_change_by2 != null) {
                          self.statusChangeBy2 = response.data.content.status_change_by2;
                        }
                        if (response.data.content.status_change_at2 != null) {
                          self.statusChangeAt2 = response.data.content.status_change_at2;
                        }
                        if (response.data.content.status_reason2 != null && response.data.content.status_reason2 != 'null' && typeof response.data.content.status_reason2 != "undefined" && response.data.content.status_reason2 != '') {
                            self.statusComment2 = response.data.content.status_reason2;
                        }
                        if (response.data.content.cost != null) {
                          self.cost = response.data.content.cost;
                        }
                        if (response.data.content.vendor != null) {
                          self.vendor = response.data.content.vendor;
                        }
                        if (response.data.content.send_to_manager_id2 != null) {
                          self.salesManagerId2 = response.data.content.send_to_manager_id2;
                        }
                        if (response.data.content.send_to_manager_id2.email != null) {
                          if(self.loginUsrEml == response.data.content.send_to_manager_id2.email && response.data.content.approval_level == 1 && self.oldStatus.text == 'In Progress') {
                            self.isManager2 = true;
                          }
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                let checked = true;
                self.statusCommentError = "";
                self.statusIdError = "";

                if (self.statusId == null || !self.statusId.value) {
                    self.statusIdError = "Please select status";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanIdError = "Please select Salesman Name";
                    if (checked) {
                        checked = false;
                    }
                }
                if(self.permissionNameArray.includes('can_add_po_no_to_used_truck_moved_submission_form') && self.statusId.value == 'approve' && self.oldStatus.value == 'approve'/*  && self.oldPoNo == '' */) {
                  for (const element of self.allVINJsonData) {
                    if (!element.poNo) {
                        element.poNoError = "Please enter PO Number";
                        if (checked) {
                            checked = false;
                        }
                    } else if (element.poNo && (element.poNo == 0 || element.poNo.toString().toLowerCase() == 'null' || element.poNo.toString().toLowerCase() == 'undefined')) {
                        element.poNoError = "Please enter valid PO Number";
                        if (checked) {
                            checked = false;
                        }
                    }
                  }
                }

                if(self.permissionNameArray.includes('can_add_dispatch_details_to_used_truck_moved_submission_form') && self.oldStatus.value == 'in progress' && (self.statusId.value == 'in progress' || self.statusId.value == 'approve') && self.approvalLevel == 1 && self.oldETA == '') {
                  self.etaError = "";
                  self.costError = "";
                  self.vendorError = "";
                  self.salesManagerError2 = "";

                  if (!self.eta) {
                    self.etaError = "Please select ETA";
                    if (checked) {
                        checked = false;
                    }
                  }

                  if (!self.cost) {
                    self.costError = "Please enter cost";
                    if (checked) {
                        checked = false;
                    }
                  } else if (self.cost && !self.numberFormateValidation(self.cost)) {
                    self.costError = "Please enter numeric value for Cost";
                    if (checked) {
                        checked = false;
                    }
                  } else if (self.cost && self.cost <= 0) {
                    self.costError = "Please enter valid Cost";
                    if (checked) {
                      checked = false;
                    }
                  }

                  if (!self.vendor) {
                    self.vendorError = "Please enter Vendor";
                    if (checked) {
                        checked = false;
                    }
                  }

                  if (!self.salesManagerId2) {
                    self.salesManagerError2 = "Please select Send to Manager2";
                    if (checked) {
                        checked = false;
                    }
                  }
                }
                return checked;
            },
            addUsedTruckMovedSubmissionForm: function () {
                setTimeout(() => {
                    if (self.checkForm()) {
                        let eta_val = '';
                        let cost_val = '';
                        let vendor_val = '';
                        let send_to_manager_val = '';
                        if(self.permissionNameArray.includes('can_add_dispatch_details_to_used_truck_moved_submission_form') && self.oldStatus.value == 'in progress' && (self.statusId.value == 'in progress' || self.statusId.value == 'approve')  && self.approvalLevel == 1 && self.oldETA == '') {
                          eta_val = self.eta;
                          cost_val = self.cost;
                          vendor_val = self.vendor;
                          send_to_manager_val = self.salesManagerId2.value;
                        }

                        self.allVINJsonData.map(item => { delete item.poNoError; return item; });

                        let config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        let data = new FormData();
                        data.append('id', self.$route.params.id);
                        data.append('status', self.statusId.value);
                        data.append('status_comment', self.statusComment);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('all_vin_json_data',JSON.stringify(self.allVINJsonData));
                        data.append('created_at', self.createdAt);
                        data.append('eta', eta_val);
                        data.append('approval_level', self.approvalLevel);
                        data.append('status_comment2', self.statusComment2);
                        data.append('cost', cost_val);
                        data.append('vendor', vendor_val);
                        data.append('send_to_manager2', send_to_manager_val);

                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/usedtruckmovesubmissionform/add/update", data, config)
                            .then(function (response) {
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        self.buttonBack();
                                    }, 3000);
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        let err = "";
                                        for (const element of response.data.content) {
                                            err += element + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (const element of permissions) {
                self.permissionNameArray.push(element.permission_name)
            }
        }
    });
    EventBus.$on("login-company", function (companies) {
        if (typeof self != "undefined") {
            self.userCompany = [];
            for (const element of companies) {
                self.userCompany.push(element.company);
            }
        }
    });
</script>
<style scoped>
.approval-info-block {
    text-align: center;
    font-size: 20px;
    background-color: #f5f5f5;
}
.header-block {
  text-align: center;
}
.data-block {
  text-align: left;
}

.status-block {
  display:block;
  margin:auto;
  font-size:85%;
}

.moved-email-block .vue-tags-input {
  max-width: initial;
}
</style>
