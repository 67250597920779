<template>
    <!--<div class="spaced-container">-->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_close_inventory_master')">
            <div class="container-fluid xyz">
                <template v-if="isAddFrom">
                    <div class="page-header-top-container">
                        <div class="page-name">{{pageTitle}}</div>

                        <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    </div>
                </template>
                <template v-else>
                    <!--<div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                            <span>{{pageTitle}}</span>
                        </div>
                    </div>-->
                </template>
            </div>

            <!--page content-->
            <div class="admin-page-content">
                <!--Forms-->

                <div :class="alertClass" id="success-alert">
                    <strong> {{alertMessage}} </strong>
                </div>

                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="row no-margin">

                        <!--Close Type -->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.close_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="closeTypeId"
                                    @change="changedValue"
                                    @input="closeTypeError = ''"
                                    name="closeTypeId"
                                    id="closeTypeId"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="closeTypeArr"
                                    :placeholder="staticMessages.close_type_place_holder">
                                </v-select>
                                <!--<div v-show="isCloseTypeActive" class="requiredstar">*</div>-->
                                <span class="help is-danger">{{closeTypeError}}</span>
                            </div>
                        </div>

                        <!--Hold Status -->
                        <div v-if="closeFormType == 2" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.hold_status_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="HoldStatusId"
                                    @change="changedValue"
                                    @input="HoldStatusError = ''"
                                    name="HoldStatusId"
                                    id="HoldStatusId"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="HoldStatusArr"
                                    :placeholder="staticMessages.hold_status_place_holder">
                                </v-select>
                                <div v-show="isCloseTypeActive" class="requiredstar">*</div>
                                <span class="help is-danger">{{HoldStatusError}}</span>
                            </div>
                        </div>

                        <!--Sale Type -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.sale_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="saleTypeId"
                                    @change="changedValue"
                                    @input="saleTypeError = ''"
                                    name="saleTypeId"
                                    id="saleTypeId"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="saleTypeArr"
                                    :placeholder="staticMessages.sale_type_place_holder">
                                </v-select>
                                <div v-show="isCloseTypeActive" class="requiredstar">*</div>
                                <span class="help is-danger">{{saleTypeError}}</span>
                            </div>
                        </div>

                        <!--Salesman -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.salesman}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="salesmanId"
                                    @change="changedValue"
                                    @input="salesmanError = ''"
                                    name="salesmanId"
                                    id="salesmanId"
                                    label="text"
                                    @search="onSearchSalesman"
                                    :clearable="false"
                                    :searchable="true"
                                    :filterable="false"
                                    :options="salesmanArr"
                                    :placeholder="staticMessages.user_place_holder">
                                </v-select>
                                <div v-show="isCloseTypeActive" class="requiredstar">*</div>
                                <span class="help is-danger">{{salesmanError}}</span>
                            </div>
                        </div>
                        <!--Customer -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.customer_name_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="customerId"
                                    @change="changedValue"
                                    @input="customerError = ''"
                                    name="customerId"
                                    id="customerId"
                                    label="text"
                                    @search="onSearchCustomer"
                                    :clearable="false"
                                    :searchable="true"
                                    :filterable="false"
                                    :options="customerArr"
                                    :placeholder="staticMessages.customer_place_holder">
                                </v-select>
                                <div v-show="closeFormType == 3" class="requiredstar">*</div>
                                <span class="help is-danger">{{customerError}}</span>
                            </div>
                        </div>
                        <div v-if="closeFormType == 2" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.hold_customer_name_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input id="holdCustomerName" v-model="holdCustomerName" class="form-control" type="text" maxlength="190"/>
                            </div>
                        </div>
                        <!-- Payment Type -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.payment_type}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <b-form-radio-group v-model="paymentType"
                                                    :options="paymentTypeOption"
                                                    name="paymentType">
                                </b-form-radio-group>
                            </div>
                        </div>

                        <!-- Customer Contact No -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.customer_phone_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="customerContactNumberError = ''" id="customerContactNumber" v-model="customerContactNumber" class="form-control" type="text"/>
                                <span class="help is-danger">{{customerContactNumberError}}</span>
                            </div>
                        </div>

                        <!-- Has seen truck -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.has_customer_seen_the_unit}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <b-form-radio-group v-model="hasCustomerSeenUnit"
                                                    :options="hasCustomerSeenUnitOption"
                                                    name="hasCustomerSeenUnit">
                                </b-form-radio-group>
                            </div>
                        </div>
                        
                        <!--Price Soled USD-->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.sales_price_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <div v-show="closeFormType == 3" class="requiredstar">*</div>
                                <input @input="priceSoldUsdError = ''" id="priceSoldUsd" v-model="priceSoldUsd" class="form-control" type="text" maxlength="17"/>
                                <span class="help is-danger">{{priceSoldUsdError}}</span>
                            </div>
                        </div>

                        <!-- Sale Date -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.sale_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <date-picker
                                    v-model="saleDate"
                                    :config="options"
                                    class="form-control"
                                    autocomplete="off"
                                    :placeholder="staticMessages.sale_date_place_holder"
                                    name="saleDate"
                                    @input="changeDate('saledate')"
                                    >
                                </date-picker>
                                <div v-show="isCloseTypeActive" class="requiredstar">*</div>
                                <span class="help is-danger">{{saleDateError}}</span>
                            </div>
                        </div>

                        <!-- Payment Date -->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.payment_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <date-picker
                                    v-model="paymentDate"
                                    :config="options"
                                    class="form-control"
                                    autocomplete="off"
                                    :placeholder="staticMessages.payment_date_place_holder"
                                    name="paymentDate"
                                    @input="changeDate('paymentdate')"
                                    >
                                </date-picker>
                            </div>
                        </div>

                        <!--Referral Source -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.referral_source}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select
                                    v-model="referralSourceId"
                                    @change="changedValue"
                                    @input="referralSourceError = ''"
                                    name="referralSourceId"
                                    id="referralSourceId"
                                    label="text"
                                    :clearable="true"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="referralSourceArr"
                                    :placeholder="staticMessages.select_referral_source">
                                </v-select>
                                <!--<div v-show="isCloseTypeActive" class="requiredstar">*</div>-->
                                <span class="help is-danger">{{referralSourceError}}</span>
                            </div>
                        </div>

                        <!--  Title Out -->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.title_out_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <date-picker
                                    v-model="titleOutDate"
                                    :config="options"
                                    class="form-control"
                                    autocomplete="off"
                                    :placeholder="staticMessages.title_out_date_place_holder"
                                    name="titleOutDate"
                                    @input="changeDate('titleOutDate')"
                                    >
                                </date-picker>
                            </div>
                        </div>

                        <!--Commission Type -->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commission_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select 
                                    v-model="commissionTypeId"
                                    @change="changedValue"
                                    @input="commissionTypeError = ''"
                                    name="commissionTypeId"
                                    id="commissionTypeId"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="commissionTypeArr"
                                    :placeholder="staticMessages.commission_type_place_holder">
                                </v-select>
                                <!-- <div v-show="isCloseTypeActive" class="requiredstar">*</div> -->
                                <span class="help is-danger">{{commissionTypeError}}</span>
                            </div>
                        </div>

                        <!--Total Commission Rate-->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.total_commission_rate_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <!-- <div v-show="isCloseTypeActive" class="requiredstar">*</div> -->
                                <input @input="totalCommissionRateError = ''" id="totalCommissionRate" v-model="totalCommissionRate" class="form-control" type="text"/>
                                <span class="help is-danger">{{totalCommissionRateError}}</span>
                            </div>
                        </div>

                        <!--Lot Fee-->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.lot_fee_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="lotFeeError = ''" id="lotFee" v-model="lotFee" class="form-control" type="text"/>
                                <span class="help is-danger">{{lotFeeError}}</span>
                            </div>
                        </div>

                        <!--Interest Rate-->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.interest_rate_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="interestRateError = ''" id="interestRate" v-model="interestRate" class="form-control" type="text"/>
                                <span class="help is-danger">{{interestRateError}}</span>
                            </div>
                        </div>

                        <!--Closing Fee-->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.closing_fee_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="closingFeeError = ''" id="closingFee" v-model="closingFee" class="form-control" type="text"/>
                                <span class="help is-danger">{{closingFeeError}}</span>
                            </div>
                        </div>

                        <!--Wararnty Type -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <v-select
                                    v-model="warrantyTypeId"
                                    @change="changedValue"
                                    @input="warrantyTypeError = ''"
                                    name="warrantyTypeId"
                                    id="warrantyTypeId"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="warrantyTypeArr"
                                    :placeholder="staticMessages.warranty_place_holder">
                                </v-select>
                                <span class="help is-danger">{{warrantyTypeError}}</span>
                            </div>
                        </div>

                        <!-- Warranty Sevice Date -->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.warranty_sevice_date_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <date-picker
                                    v-model="warrantyServiceDate"
                                    :config="options"
                                    class="form-control"
                                    autocomplete="off"
                                    :placeholder="staticMessages.warranty_sevice_date_place_holder"
                                    name="warrantyServiceDate"
                                    @input="changeDate('warrantyServiceDate')"
                                    >
                                </date-picker>
                            </div>
                        </div>

                        <!--Warranty Terms-->
                        <div class="col-6 forms-block" v-show="isCloseTypeActive">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.warranty_terms_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="warrantyTermsError = ''" id="warrantyTerms" v-model="warrantyTerms" class="form-control" type="text"/>
                                <span class="help is-danger">{{warrantyTermsError}}</span>
                            </div>
                        </div>
                        <!-- Commission -->
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commission}}</div>
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="roleLabel container">
                                    <input v-model="commissionSalesman" class="checkbox" type="checkbox" id="commissionSalesman" name="commissionSalesman">
                                    <span class="checkmark"></span>
                                    <span class="pl-2"><strong>{{staticInputLabelMessages.add_salesman}}</strong></span>
                                </label>
                                <br/>
                            </div>
                        </div>
                        <div v-if="closeFormType == 3" class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.commission}}</div>
                            </div>
                            <div class="col-9 paddingleft0 custom-checkbox-v">
                                <label class="roleLabel container">
                                    <input v-model="commissionManagedBy" class="checkbox" type="checkbox" id="commissionManagedBy" name="commissionManagedBy">
                                    <span class="checkmark"></span>
                                    <span class="pl-2"><strong>{{staticInputLabelMessages.add_managed_by}}</strong></span>
                                </label>
                                <br/>
                            </div>
                        </div>
                        <!--Description-->
                        <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.notes_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <textarea id="note" v-model="note" class="form-control"></textarea>
                            </div>
                        </div>

                    </div>

                    <!--Error Alert-->
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="alert alert-danger" id="alertError" style="display: none;">
                                <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                <strong id="errorMsg" class="error"></strong>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <!--Buttons-->
                    <div class="row clearfix"></div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <!--<input id="addAndContinueAssetBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAsset(flag='Save and Continue')">-->
                        <!--<input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">-->
                    </div>
                </div>


                <!-- Forms -->
            </div>
            <!--page content-->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer v-show="isAddFrom"></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!--</div>-->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AssetCloseTab',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                assetId : 0,
                closeFormType: 0,
                staticMessages: "",
                note:"",
                staticInputLabelMessages: "",
                contentClass: "admin-page-content",
                saleTypeId: "",
                saleTypeArr: [],
                saleTypeError: "",
                closeTypeId: "",
                closeTypeArr: [],
                HoldStatusId:"",
                HoldStatusArr: [],
                closeTypeError: "",
                HoldStatusError:"",
                customerId: "",
                customerContactNumber : "",
                customerContactNumberError : "",
                holdCustomerName : "",
                customerArr: [],
                customerError: "",
                salesmanId: "",
                salesmanArr: [],
                salesmanError: "",
                commissionSalesman: "",
                commissionManagedBy: "",
                priceSoldUsd: "",
                priceSoldUsdError: "",
                saleDate: "",
                saleDateError: "",
                paymentDate: "",
                referralSourceId: "",
                referralSourceArr: [],
                referralSourceError: "",
                titleOutDate: "",
                titleOutDateError: "",
                commissionTypeId: "",
                commissionTypeArr: [],
                commissionTypeError: "",
                totalCommissionRate: "",
                totalCommissionRateError: "",
                lotFee: "",
                lotFeeError: "",
                interestRate: 0,
                interestRateError: "",
                closingFee: "",
                closingFeeError: "",
                warrantyTypeId: "",
                warrantyTypeArr: [],
                warrantyTypeError: "",
                warrantyServiceDate: "",
                warrantyTerms: "",
                warrantyTermsError: "",
                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    //maxDate: new Date((new Date()).valueOf() + 1000*3600*24),
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",
                hasCustomerSeenUnit: "yes",
                hasCustomerSeenUnitOption:[
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'},
                ],
                paymentType:"cash",
                paymentTypeOption:[
                    {text: 'Cash', value: 'cash'},
                    {text: 'Our Financing', value: 'our financing'},
                ],
                isAddFrom: true,
                isCloseTypeActive : false,
            }
        },
        watch: {
            closeTypeId: function (val) {
                if (val.value === 1 || val.value === 4) {
                    self.closeTypeIsActive();
                } else {
                    self.isCloseTypeActive = true;
                    if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                        self.getAssetDetailsById(self.asset, 'fromWatcher');
                    }
                }
                self.closeFormType = 0;
                if (self.closeTypeId == null || !self.closeTypeId.value) {
                    self.closeFormType = 0;
                } else {
                    /*if (self.closeTypeId.text.toLowerCase() == 'sold') {
                        self.closeFormType = 1;
                    }*/
                    self.closeFormType=self.closeTypeId.value;
                }
            },
        },
        beforeMount() {
            self = this;
        },
        props: ['permissionNameArray', 'asset', 'getData'],
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            self.loadAllData([/* 'Make', 'Model', 'Condition',  */'SaleType', 'WarrantyType', 'HoldStatus','Contact', 'User', 'Status', 'ReferralSource', 'CommissionType'], false, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Make', false, self.loadMakeDataCallback, "undefined", '');
            self.loadAllData('Model', false, self.loadModelDataCallback, "undefined", '');
            self.loadAllData('Condition', false, self.loadConditionCallback, "undefined", '');
            self.loadAllData('SaleType', false, self.loadSaleTypeCallback, "undefined", '');
            self.loadAllData('WarrantyType', false, self.loadWarrantyTypeCallback, "undefined", '');
            self.loadAllData('HoldStatus', false, self.loadHoldStatusCallback, "undefined", ''); */
            /* self.loadAllData(['Contact', 'User', 'Status', 'ReferralSource', 'CommissionType'], false, self.loadMasterDetailsCallback, "undefined", ''); */
            /* self.loadAllData('Contact', true, self.loadContactCallback, "undefined", '');
            self.loadAllData('User', true, self.loadSalesmanCallback, "undefined", '');
            self.loadAllData('Status', true, self.loadCloseTypeCallback, "undefined", '');
            self.loadAllData('ReferralSource', true, self.loadReferralSourceCallback, "undefined", '');
            self.loadAllData('CommissionType', true, self.loadCommissionTypeCallback, "undefined", ''); */
            //self.loadAllData('Status', false, self.loadStatusCallback, "undefined", '');
            // self.loadAllData('Company', false, self.loadCompanyCallback, "undefined", '');
            $("#alertError").hide();
            //self.getLoginIdPermissions();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.isAddFrom = false;
                self.contentClass = "spaced-container";
                self.getAssetDetailsById(self.asset, 'fromMounted');
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
        },
        methods: {
            closeTypeIsActive : function () {
                self.isCloseTypeActive = false;
                self.saleTypeId = "";
                self.salesmanId = "";
                self.customerId = "";
                self.customerContactNumber = "";
                self.holdCustomerName = "";
                self.paymentType = "cash";
                self.hasCustomerSeenUnit = "yes";
                self.priceSoldUsd = "";
                self.saleDate = "";
                self.referralSourceId = "";
                self.interestRate = "";
                self.warrantyTypeId = "";
                self.warrantyServiceDate = "";
                self.warrantyTerms = "";
                self.makeAllErrorBlank();
            },
            makeAllErrorBlank : function () {
                self.closeTypeError = "";
                self.saleTypeError = "";
                self.customerError = "";
                self.customerContactNumberError = "";
                self.saleDateError = "";
                self.priceSoldUsdError = "";
                self.saleDateError = "";
                self.salesmanError = "";
                self.HoldStatusError = "";
                self.commissionTypeError = "";
                self.totalCommissionRateError = "";
                self.interestRateError = "";
            },
            updateCRM : function () {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var closeTypeId = "";
                if (self.closeTypeId == null || !self.closeTypeId.value) {
                    closeTypeId = "";
                } else {
                    closeTypeId = self.closeTypeId.value;
                }

                var salesmanId = "";
                if (self.salesmanId == null || !self.salesmanId.value) {
                    salesmanId = "";
                } else {
                    salesmanId = self.salesmanId.value;
                }
                let assetId = self.assetId;
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    assetId = self.$route.params.id
                }
                var fields = {
                    salesman_id: salesmanId,
                    close_type_id: closeTypeId,
                    asset_id : assetId,
                }
                self.showLoader();
                HTTP.post("/asset/updatecrm", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {

                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(err, function () {});
                    })
            },
            changeDate: function (flag) {
                if (flag == "paymentdate") {

                } else if (flag == "saledate") {
                    self.saleDateError = "";
                } else if (flag == "titleOutDate") {

                } else if (flag == "warrantyServiceDate") {

                }
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadMasterDataCallback: function(callbackData) {
                self.saleTypeArr = callbackData.SaleType;
                self.warrantyTypeArr = callbackData.WarrantyType;
                self.HoldStatusArr = callbackData.HoldStatus;
                self.customerArr = callbackData.Contact;
                self.salesmanArr = callbackData.User;
                self.closeTypeArr = callbackData.Status;
                self.referralSourceArr = callbackData.ReferralSource;
                self.commissionTypeArr = callbackData.CommissionType;
            },
            /* loadMasterDetailsCallback: function(callbackData) {
                self.customerArr = callbackData.Contact;
                self.salesmanArr = callbackData.User;
                self.closeTypeArr = callbackData.Status;
                self.referralSourceArr = callbackData.ReferralSource;
                self.commissionTypeArr = callbackData.CommissionType;
            },*/
            /* loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArr = callbackData.SaleType;
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArr = callbackData.WarrantyType;
            },
            loadHoldStatusCallback: function (callbackData) {
                self.HoldStatusArr = callbackData.HoldStatus;
            }, */
            loadCompanyCallback: function (callbackData) {
                // self.companyArr = callbackData;
            },
            /* loadCloseTypeCallback: function (callbackData) {
                self.closeTypeArr = callbackData.Status;
            },
            loadReferralSourceCallback: function (callbackData) {
                self.referralSourceArr = callbackData.ReferralSource;
            },
            loadCommissionTypeCallback: function (callbackData) {
                self.commissionTypeArr = callbackData.CommissionType;
            },*/
            loadContactCallback: function (callbackData) {
                self.customerArr = callbackData.Contact;
            },
            loadSalesmanCallback: function (callbackData) {
                self.salesmanArr = callbackData.User;
            }, 
            changeAvailableDate: function () {
                self.availableDateError = "";
            },
            changeTitleOutDate: function () {
                self.titleOutDateError = "";
            },
            changeAvailableDateDate: function () {
                self.availableDateError = "";
            },
            changeTitleIn: function () {

            },
            changeAcuireDate: function () {
                self.acquireOnDateError = "";
            },
            chageOrderDate: function () {
                self.orderDateError = "";
            },
            changedValueLocation: function () {

            },
            changeIsTruck: function () {

            },
            loadStateDataCallback: function (callbackData) {
                // self.stateArr = callbackData;
            },
            loadCityDataCallback: function (callbackData) {
                // self.cityArr = callbackData;
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search);
                }
            },
            onSearchSalesman(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesmanCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                // self.locationArr = callbackData;
            },
            loadCompanyDataCallback: function (callbackData) {
                // self.companyArr = callbackData;
            },
            buttonBack() {
                // self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            getAssetDetailsById: function (data, flag) {
                self.assetId = data.content['asset_data'].asset_id;
                self.saleTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].sale_type_name), value: data.content['asset_data'].sale_type_id};
                self.warrantyTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].warranty_type_name), value: data.content['asset_data'].warranty_type_id};
                
                if(flag != 'fromWatcher'){
                    self.closeTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].status_name), value: data.content['asset_data'].status_id};
                }
                
                if(data.content['asset_data'].hold_status_id != null && data.content['asset_data'].hold_status_id != 'null' && data.content['asset_data'].hold_status_id != ''){
                    self.HoldStatusId = {text: data.content['asset_data'].hold_status_name, value: data.content['asset_data'].hold_status_id};
                }
                if(data.content['asset_data'].customer_id != null && data.content['asset_data'].customer_id != 'null' && data.content['asset_data'].customer_id != ''){
                    self.customerId = {text: self.capitalizeFirstLetter(data.content['asset_data'].customer_name_full), value: data.content['asset_data'].customer_id};
                }
                if(data.content['asset_data'].hold_customer_name != null && data.content['asset_data'].hold_customer_name != 'null' && data.content['asset_data'].hold_customer_name != ''){
                    self.holdCustomerName = data.content['asset_data'].hold_customer_name;
                }
                if(data.content['asset_data'].customer_contact != null && data.content['asset_data'].customer_contact != 'null' && data.content['asset_data'].customer_contact != ''){
                    console.log(data.content['asset_data'].customer_contact);
                    self.customerContactNumber = data.content['asset_data'].customer_contact;
                }
                self.commissionTypeId = {text: self.capitalizeFirstLetter(data.content['asset_data'].commission_type_name), value: data.content['asset_data'].commission_type_id};
                if(data.content['asset_data'].payment_type != null && data.content['asset_data'].payment_type != 'null' && data.content['asset_data'].payment_type != ''){
                    self.paymentType = data.content['asset_data'].payment_type;
                }
                if(data.content['asset_data'].customer_seen != null && data.content['asset_data'].customer_seen != 'null' && data.content['asset_data'].customer_seen != ''){
                    self.hasCustomerSeenUnit = data.content['asset_data'].customer_seen;
                }
                self.priceSoldUsd = data.content['asset_data'].price_sold_usd;
                self.totalCommissionRate = data.content['asset_data'].total_commission_rate;
                self.lotFee = data.content['asset_data'].lot_fee;
                self.interestRate = data.content['asset_data'].interest_rate;
                self.closingFee = data.content['asset_data'].closing_fee;
                self.warrantyTerms = data.content['asset_data'].warranty_terms;
                if (data.content['asset_data'].referral_source != null && data.content['asset_data'].referral_source != '') {
                    self.referralSourceId = {text: data.content['asset_data'].referral_source, value: data.content['asset_data'].referral_id};
                }


                if (data.content['asset_data'].salesman_id != null && data.content['asset_data'].salesman_id != '') {
                    self.salesmanId = {text: data.content['asset_data'].salesman_first_name + " " + data.content['asset_data'].salesman_last_name, value: data.content['asset_data'].salesman_id};
                }

                self.commissionManagedBy = data.content['asset_data'].commission_managedby;
                self.commissionSalesman = data.content['asset_data'].commission_salesman;
                if (data.content['asset_data'].sale_date != null && data.content['asset_data'].sale_date != 'null' && data.content['asset_data'].sale_date != '') {
                    self.saleDate = self.datePickerDateFormat(data.content['asset_data'].sale_date);
                }
                if (data.content['asset_data'].payment_date != null && data.content['asset_data'].payment_date != 'null' && data.content['asset_data'].payment_date != '') {
                    self.paymentDate = self.datePickerDateFormat(data.content['asset_data'].payment_date);
                }
                if (data.content['asset_data'].close_title_out_date != null && data.content['asset_data'].close_title_out_date != 'null' && data.content['asset_data'].close_title_out_date != '') {
                    self.titleOutDate = self.datePickerDateFormat(data.content['asset_data'].close_title_out_date);
                }
                if (data.content['asset_data'].warranty_service_date != null && data.content['asset_data'].warranty_service_date != 'null' && data.content['asset_data'].warranty_service_date != '') {
                    self.warrantyServiceDate = self.datePickerDateFormat(data.content['asset_data'].warranty_service_date);
                }
                self.note = "";
                if (data.content['asset_data'].asset_note != null && data.content['asset_data'].asset_note != 'null' && data.content['asset_data'].asset_note != '') {
                    self.note = data.content['asset_data'].asset_note;
                }


                /*if (data.content['asset_data'].warranty_service_date != null && data.content['asset_data'].warranty_service_date != 'null' && data.content['asset_data'].warranty_service_date != '') {
                 let orderDate = data.content['asset_data'].warranty_service_date.split(" ")[0];
                 let year = orderDate.split('-')[0];
                 let month = orderDate.split('-')[1];
                 let date = orderDate.split('-')[2];
                 orderDate = month + "/" + date + "/" + year;
                 self.warrantyServiceDate = orderDate;
                 }*/
            },

            checkForm: function () {
                var checked = true;
                if (self.isCloseTypeActive === true) {
                    /*if (self.closeTypeId == null || !self.closeTypeId.value) {
                        self.closeTypeError = "Please select close type";
                        if (checked) {
                            checked = false;
                        }
                    }*/
                    if (self.saleTypeId == null || !self.saleTypeId.value) {
                        self.saleTypeError = "Please select sale type";
                        if (checked) {
                            checked = false;
                        }
                    }
                    /*if (self.customerId == null || !self.customerId.value) {
                        self.customerError = "Please select customer";
                        if (checked) {
                            checked = false;
                        }
                    }*/
                    if (!self.saleDate) {
                        self.saleDateError = "Please enter sale date";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if(self.closeTypeId.value == 3){
                        if (self.priceSoldUsd != 0) {
                            if (!self.priceSoldUsd) {
                                self.priceSoldUsdError = "Please enter price sold";
                                if (checked) {
                                    checked = false;
                                }
                            } else if (!self.numberFormateValidation(self.priceSoldUsd)) {
                                self.priceSoldUsdError = "Please enter numeric value";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                    /* if (!self.commissionTypeId) {
                        self.saleDateError = "Please select sale date";
                        if (checked) {
                            checked = false;
                        }
                    } */
                    if (self.salesmanId == null || !self.salesmanId.value) {
                        self.salesmanError = "Please select salesman";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (self.closeFormType == 2) {
                        if (self.HoldStatusId == null || !self.HoldStatusId.value) {
                            self.HoldStatusError = "Please select hold status";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                    if (self.closeFormType == 3) {
                        /* if (self.commissionTypeId == null || !self.commissionTypeId.value) {
                            self.commissionTypeError = "Please select commission type";
                            if (checked) {
                                checked = false;
                            }
                        } */
                        if (self.totalCommissionRate != 0) {
                            /* if (!self.totalCommissionRate) {
                                self.totalCommissionRateError = "Please enter total commission rate";
                                if (checked) {
                                    checked = false;
                                }
                            } else */ if (!self.numberFormateValidation(self.totalCommissionRate)) {
                                self.totalCommissionRateError = "Please enter numeric value";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                        if (self.customerId == null || !self.customerId.value) {
                            self.customerError = "Please select customer";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                    if (self.interestRate && !self.numberFormateValidation(self.interestRate)) {
                        self.interestRateError = "Please enter numeric value";
                        if (checked) {
                            checked = false;
                        }
                    }
                } else {
                    checked = true;
                }
                if(self.customerContactNumber && !self.validContactNumber(self.customerContactNumber)) {
                    self.customerContactNumberError = "Please enter valid contact no";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.customerContactNumber && !self.checkContactNoLength(self.customerContactNumber)) {
                    self.customerContactNumberError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                if(self.closeTypeId.value != 3){
                    if (self.priceSoldUsd != 0) {
                        if (self.priceSoldUsd && !self.numberFormateValidation(self.priceSoldUsd)) {
                            self.priceSoldUsdError = "Please enter numeric value";
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                }
                return checked;
            },
            returnData: function () {
                let saleDate = "";
                let paymentDate = "";
                let titleOutDate = "";
                let warrantyServiceDate = "";
                if (self.saleDate != null && typeof self.saleDate != "undefined" && self.saleDate != '') {
                    saleDate = self.apiRequestDateFormat(self.saleDate);
                }
                if (self.paymentDate != null && typeof self.paymentDate != "undefined" && self.paymentDate != '') {
                    paymentDate = self.apiRequestDateFormat(self.paymentDate);
                }
                if (self.titleOutDate != null && typeof self.titleOutDate != "undefined" && self.titleOutDate != '') {
                    titleOutDate = self.apiRequestDateFormat(self.titleOutDate);
                }
                if (self.warrantyServiceDate != null && typeof self.warrantyServiceDate != "undefined" && self.warrantyServiceDate != '') {
                    warrantyServiceDate = self.apiRequestDateFormat(self.warrantyServiceDate);
                }

                if (paymentDate === "") {
                    paymentDate = null;
                }
                if (titleOutDate === "") {
                    titleOutDate = null;
                }

                if (self.interestRate === "") {
                    self.interestRate = null;
                }
                if (warrantyServiceDate === "") {
                    warrantyServiceDate = null;
                }
                var closeTypeId = "";
                if (self.closeTypeId == null || !self.closeTypeId.value) {
                    closeTypeId = "";
                } else {
                    closeTypeId = self.closeTypeId.value;
                }
                var HoldStatusId = "";
                if (self.HoldStatusId == null || !self.HoldStatusId.value) {
                    HoldStatusId = "";
                } else {
                    HoldStatusId = self.HoldStatusId.value;
                }
                var referralSourceId = "";
                if (self.referralSourceId == null || !self.referralSourceId.value) {
                    referralSourceId = "";
                } else {
                    referralSourceId = self.referralSourceId.value;
                }
                var salesmanId = "";
                if (self.salesmanId == null || !self.salesmanId.value) {
                    salesmanId = "";
                } else {
                    salesmanId = self.salesmanId.value;
                }
                var customerId = "";
                if (self.customerId == null || !self.customerId.value) {
                    customerId = "";
                } else {
                    customerId = self.customerId.value;
                }
                var commissionTypeId = "";
                if (self.commissionTypeId == null || !self.commissionTypeId.value) {
                    commissionTypeId = "";
                } else {
                    commissionTypeId = self.commissionTypeId.value;
                }
                var data = {
                    note: self.note,
                    commission_managed_by: self.commissionManagedBy,
                    commission_salesman: self.commissionSalesman,
                    salesman_id: salesmanId,
                    referral_source: referralSourceId,
                    close_type_id: closeTypeId,
                    hold_status_id: HoldStatusId,
                    sale_type_id: self.saleTypeId.value,
                    customer_id: customerId,
                    customer_contact_number : self.customerContactNumber,
                    hold_customer_name : self.holdCustomerName,
                    price_sold_usd: self.priceSoldUsd,
                    payment_type: self.paymentType,
                    customer_seen: self.hasCustomerSeenUnit,
                    sale_date: saleDate,
                    payment_date: paymentDate,
                    title_out_date: titleOutDate,
                    commission_type_id: commissionTypeId,
                    total_commission_rate: self.totalCommissionRate,
                    lot_fee: self.lotFee,
                    interest_rate: self.interestRate,
                    closing_fee: self.closingFee,
                    warranty_type_id: self.warrantyTypeId.value,
                    warranty_service_date: warrantyServiceDate,
                    warranty_terms: self.warrantyTerms,
                }
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.id = self.$route.params.id;
                }

                return data;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }
                        HTTP.post("/asset/add/update/close", data, config)
                                .then(function (response) {
                                    if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.disableButtons.save = true;
                                        self.disableButtons.saveAndContinue = true;
                                        self.disableButtons.cancel = true;
                                        if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                            self.buttonNames.save = "Update";
                                        } else {
                                            if (flag == "Save and Continue") {
                                                self.buttonNames.saveAndContinue = "Save and Continue";
                                            } else {
                                                self.buttonNames.save = "Save";
                                            }
                                        }
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                    if (response.data.status == "success") {
                                        self.updateCRM();
                                        self.disableButtons.save = true;
                                        self.disableButtons.saveAndContinue = true;
                                        self.disableButtons.cancel = true;
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        if (flag == "Save and Continue") {
                                            self.clearDataOnSaveAndContinue();
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                        } else {
                                            setTimeout(function () {
                                                self.disableButtons.save = false;
                                                self.disableButtons.saveAndContinue = false;
                                                self.disableButtons.cancel = false;
                                                self.buttonBack();
                                            }, 3000);
                                        }
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                    self.getData(6);
                                })
                                .catch(function (err) {
                                    /* console.log(err); */
                                    if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                        self.buttonNames.save = "Update";
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.clearDataOnSaveAndContinue();
                                        } else {
                                            self.buttonNames.save = "Save";
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                        }
                                    }
                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);

            },
        }
    }
</script>