<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_specification_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <th class="">{{staticInputLabelMessages.engine_make_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('engineMakeInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.engine_model_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('engineModelIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.trans_make_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('transMakeInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.trans_model_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('transModelIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.rear_ratio_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('rearRatioInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.wheelbase_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('wheelBaseInput')"></i></th>
                                <!-- <th class="">{{staticInputLabelMessages.engine_serial_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('engineSerialInput')"></i></th> -->
                                <th class="">{{staticInputLabelMessages.odometer_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('odometerInput')"></i></th>
                                <!-- <th class="">{{staticInputLabelMessages.apu_serials_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('apuSerialInput')"></i></th> -->
                                <!-- <th class="">{{staticInputLabelMessages.apu_hours_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('apuHourInput')"></i></th> -->
                                <th class="">{{staticInputLabelMessages.reefer_serial_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('reeferSerialInput')"></i></th>
                                <!-- <th class="">{{staticInputLabelMessages.reefer_hours_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('reeferHoursInput')"></i></th> -->
                                <th class="">{{staticInputLabelMessages.engine_hp_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('hpInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.cab_style_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('cabStyleIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.cab_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('cabTypeIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.dtr_trade_terms_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('dtrTradeTermsIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.gvw_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('gvwInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.trans_speed_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('transSpeedIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.bunk_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('bunkTypeInput')"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Engine Make-->
                                <td class="">
                                    <v-select style="min-width: 190px;" v-model="engineMakeInput[asset.asset_id]"
                                        @input="enginesMakeChangeInventory(asset.asset_id, engineMakeInput[asset.asset_id].value, index)"
                                        name="engineMakeInput"
                                        id="engineMakeInput"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="engineMakeArr"
                                        :placeholder="staticMessages.select_engine_make"
                                        >
                                    </v-select>
                                </td>
                                <!--Engine Model-->
                                <td class="">
                                    <v-select style="min-width: 165px;" 
                                        :key="'AssetId ' + engineModelIdInput[asset.asset_id]"
                                        v-model="engineModelIdInput[asset.asset_id]"
                                        name="engineModelIdInput"
                                        id="engineModelIdInput"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="multiEditEngineModelArr[asset.asset_id]"
                                        :placeholder="staticMessages.engine_model_place_holder"
                                        >
                                    </v-select>
                                </td>
                                <!--Trans Make-->
                                <td class="">
                                    <v-select style="min-width: 190px;" v-model="transMakeInput[asset.asset_id]"
                                        @input="transMakeChangeInventory(asset.asset_id, transMakeInput[asset.asset_id].value, index)"
                                        name="transMakeInput"
                                        id="transMakeInput"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="transMakeArr"
                                        :placeholder="staticMessages.trans_make_place_holder"
                                        >
                                    </v-select>
                                </td>
                                <!--Trans Model-->
                                <td class="">
                                    <v-select style="min-width: 165px;" 
                                        :key="'AssetId ' + transModelIdInput[asset.asset_id]"
                                        v-model="transModelIdInput[asset.asset_id]"
                                        name="transModelIdInput"
                                        id="transModelIdInput"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="multiEditTransModelArr[asset.asset_id]"
                                        :placeholder="staticMessages.trans_model_place_holder"
                                        >
                                    </v-select>
                                </td>
                                <!--Ratio Ratio-->
                                <td class="">
                                    <input style="min-width: 160px;" id="rearRatioInput" v-model="rearRatioInput[asset.asset_id]" class="form-control" type="text"/>
                                </td>
                                <!--WheelBase-->
                                <td class="">
                                    <input style="min-width: 160px;" id="wheelBaseInput" v-model="wheelBaseInput[asset.asset_id]" class="form-control" type="text"/>
                                </td>
                                <!--Engine Serial-->
                                <!-- <td class="">
                                    <input style="min-width: 160px;" id="engineSerialInput" @input="engineSerialErrorMsg[asset.asset_id] = ''" v-model="engineSerialInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{engineSerialErrorMsg[asset.asset_id]}}</span>
                                </td> -->
                                <!--Odometer-->
                                <td class="">
                                    <input style="min-width: 160px;" id="odometerInput" @input="odometerErrorMsg[asset.asset_id] = ''" v-model="odometerInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{odometerErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--APU Serials-->
                                <!-- <td class="">
                                    <input style="min-width: 160px;" id="apuSerialInput" @input="apuSerialErrorMsg[asset.asset_id] = ''" v-model="apuSerialInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{apuSerialErrorMsg[asset.asset_id]}}</span>
                                </td> -->
                                <!--APU Hours-->
                                <!-- <td class="">
                                    <input style="min-width: 160px;" id="apuHourInput" @input="apuHoursErrorMsg[asset.asset_id] = ''" v-model="apuHourInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{apuHoursErrorMsg[asset.asset_id]}}</span>
                                </td> -->
                                <!--Reefer Serial-->
                                <td class="">
                                    <input style="min-width: 160px;" id="reeferSerialInput" @input="reeferSerialErrorMsg[asset.asset_id] = ''" v-model="reeferSerialInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{reeferSerialErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!--Reefer Hours-->
                                <!-- <td class="">
                                    <input style="min-width: 160px;" id="reeferHoursInput" @input="reeferErrorMsg[asset.asset_id] = ''" v-model="reeferHoursInput[asset.asset_id]" class="form-control" type="text"/>
                                    <span class="help is-danger">{{reeferErrorMsg[asset.asset_id]}}</span>
                                </td> -->
                                <td class="">
                                    <input style="min-width: 160px;" id="hpInput" v-model="hpInput[asset.asset_id]" @input="hpInputErrorMsg[asset.asset_id] = ''" class="form-control" type="text" maxlength="190"/>
                                    <span class="help is-danger">{{hpInputErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="cabStyleIdInput[asset.asset_id]"
                                                @input="cabStyleIdInputError[asset.asset_id] = ''"
                                                name="cabStyleIdInput"
                                                id="cabStyleIdInput"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabStyleArr"
                                                :placeholder="staticMessages.cab_style_place_holder"
                                                >
                                    </v-select>
                                    <span class="help is-danger">{{cabStyleIdInputError[asset.asset_id]}}</span>
                                </td>
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="cabTypeIdInput[asset.asset_id]"
                                                @input="cabTypeIdInputError[asset.asset_id] = ''"
                                                name="cabTypeIdInput"
                                                id="cabTypeIdInput"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="cabTypeArr"
                                                :placeholder="staticMessages.cab_type_place_holder"
                                                >
                                    </v-select>
                                    <span class="help is-danger">{{cabTypeIdInputError[asset.asset_id]}}</span>
                                </td>

                                <!--DTR Trade Terms-->
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="dtrTradeTermsIdInput[asset.asset_id]"
                                                @input="dtrTradeTermsIdInputError[asset.asset_id] = ''"
                                                name="dtrTradeTermsIdInput"
                                                id="dtrTradeTermsIdInput"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="dtrTradeTermsArr"
                                                :placeholder="staticMessages.dtr_trade_terms_place_holder"
                                                >
                                    </v-select>
                                    <span class="help is-danger">{{dtrTradeTermsIdInputError[asset.asset_id]}}</span>
                                </td>

                                <!--GVW-->
                                <td class="">
                                    <input style="min-width: 160px;" id="gvwInput"  @input="gvwErrorMsg[asset.asset_id] = ''" v-model="gvwInput[asset.asset_id]" class="form-control" type="text" maxlength="190"/>
                                    <span class="help is-danger">{{gvwErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Trans Speed-->
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="transSpeedIdInput[asset.asset_id]"
                                                @input="transSpeedIdInputError[asset.asset_id] = ''"
                                                name="transSpeedIdInput"
                                                id="transSpeedIdInput"
                                                label="text"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="transSpeedArr"
                                                :placeholder="staticMessages.trans_speed_place_holder"
                                                >
                                    </v-select>
                                    <span class="help is-danger">{{transSpeedIdInputError[asset.asset_id]}}</span>
                                </td>

                                <!--Bunk Type-->
                                <td class="">
                                    <input style="min-width: 160px;" id="bunkTypeInput" v-model="bunkTypeInput[asset.asset_id]" class="form-control" type="text" maxlength="190"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                engineSerialInput : [],
                engineSerialErrorMsg : [],
                odometerInput : [],
                odometerErrorMsg : [],
                apuSerialInput : [],
                apuSerialErrorMsg : [],
                apuHourInput : [],
                
                wheelBaseInput: [],
                rearRatioInput: [],
                transMakeInput: [],
                transMakeArr: [],
                transModelIdInput: [],
                multiEditTransModelArr: [],
                transModelArr: [],
                engineMakeInput: [],
                engineMakeArr: [],
                engineModelIdInput: [],
                multiEditEngineModelArr: [],
                engineModelArr: [],

                apuHoursErrorMsg : [],
                reeferSerialInput : [],
                reeferSerialErrorMsg : [],
                reeferHoursInput : [],
                reeferErrorMsg : [],
                hpInput : [],
                hpInputErrorMsg : [],

                cabStyleIdInput: [],
                cabStyleIdInputError: [],
                cabStyleArr: [],

                cabTypeIdInput: [],
                cabTypeIdInputError: [],
                cabTypeArr: [],

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_specification_inventory_master'],

                dtrTradeTermsIdInput: [],
                dtrTradeTermsIdInputError: [],
                dtrTradeTermsArr: [],

                gvwInput : [],
                gvwErrorMsg : [],

                transSpeedIdInput: [],
                transSpeedIdInputError: [],
                transSpeedArr: [],

                bunkTypeInput : []
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.rearRatioInput[self.asset.content[i].asset_id] = self.asset.content[i].rear_ratio;
                        self.wheelBaseInput[self.asset.content[i].asset_id] = self.asset.content[i].wheelbase;
                        self.engineSerialInput[self.asset.content[i].asset_id] = self.asset.content[i].engine_serial;
                        self.odometerInput[self.asset.content[i].asset_id] = self.asset.content[i].odometer;
                        self.apuSerialInput[self.asset.content[i].asset_id] = self.asset.content[i].apu_serial;
                        self.apuHourInput[self.asset.content[i].asset_id] = self.asset.content[i].apu_hours;
                        self.reeferSerialInput[self.asset.content[i].asset_id] = self.asset.content[i].reefer_serial;
                        self.reeferHoursInput[self.asset.content[i].asset_id] = self.asset.content[i].reefer_hours;

                        self.transMakeInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].transMakeId != null && typeof self.asset.content[i].transMakeId != "undefined" && self.asset.content[i].transMakeId != '') {
                            self.transMakeInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].transMakeName), value: self.asset.content[i].transMakeId};
                        }
                        self.transModelIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].transModelId != null && typeof self.asset.content[i].transModelId != "undefined" && self.asset.content[i].transModelId != '') {
                            self.transModelIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].transModelName), value: self.asset.content[i].transModelId};
                        }
                        
                        self.engineMakeInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].engMakeId != null && typeof self.asset.content[i].engMakeId != "undefined" && self.asset.content[i].engMakeId != '') {
                            self.engineMakeInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].engMakeName), value: self.asset.content[i].engMakeId};
                        }
                        self.engineModelIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].engModelId != null && typeof self.asset.content[i].engModelId != "undefined" && self.asset.content[i].engModelId != '') {
                            self.engineModelIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].engModelName), value: self.asset.content[i].engModelId};
                        }
                        self.hpInput[self.asset.content[i].asset_id] = self.asset.content[i].engine_hp;

                        self.cabStyleIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].cabStyleId != null && typeof self.asset.content[i].cabStyleId != "undefined" && self.asset.content[i].cabStyleId != '') {
                            self.cabStyleIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].cabStyleName), value: self.asset.content[i].cabStyleId};
                        }
                        self.cabTypeIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].cabTypeId != null && typeof self.asset.content[i].cabTypeId != "undefined" && self.asset.content[i].cabTypeId != '') {
                            self.cabTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].cabTypeName), value: self.asset.content[i].cabTypeId};
                        }

                        self.dtrTradeTermsIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].dtr_trade_terms_id != null && typeof self.asset.content[i].dtr_trade_terms_id != "undefined" && self.asset.content[i].dtr_trade_terms_id != '') {
                            self.dtrTradeTermsIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].dtr_trade_terms_name), value: self.asset.content[i].dtr_trade_terms_id};
                        }

                        self.gvwInput[self.asset.content[i].asset_id] = self.asset.content[i].gvw;

                        self.transSpeedIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].trans_speed_id != null && typeof self.asset.content[i].trans_speed_id != "undefined" && self.asset.content[i].trans_speed_id != '') {
                            self.transSpeedIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].trans_speed_name), value: self.asset.content[i].trans_speed_id};
                        }

                        self.bunkTypeInput[self.asset.content[i].asset_id] = self.asset.content[i].bunk_type;

                    }
                });
            /* self.loadAllData('WarrantyType', false, self.loadWarrantyTypeCallback, "undefined", ''); */
            self.loadAllData(['TransMake', 'EngineMake', 'CabStyle', 'CabType', 'DtrTradeTerms', 'TransSpeed'], false, self.loadMasterDataCallback, "undefined", '');
            self.loadAllData(['TransModel', 'EngineModel'], false, self.loadMasterDetailsCallback, "undefined", '', 0, '', 1);
            /* self.loadAllData('TransMake', false, self.loadTransMakeDataCallback, "undefined", '');
            self.loadAllData('TransModel', false, self.loadTransModelDataCallback, "undefined", '', 0, '', 1);
            self.loadAllData('EngineMake', false, self.loadEngineMakeDataCallback, "undefined", '');
            self.loadAllData('EngineModel', false, self.loadEngineModelDataCallback, "undefined", '', 0, '', 1); */
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'engineMakeInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.engineMakeInput[self.asset.content[i].asset_id];
                                    self.engineMakeInput = [];
                                } 
                                self.engineMakeInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'engineModelIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.engineModelIdInput[self.asset.content[i].asset_id];
                                    self.engineModelIdInput = [];
                                } 
                                self.engineModelIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'transMakeInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.transMakeInput[self.asset.content[i].asset_id];
                                    self.transMakeInput = [];
                                } 
                                self.transMakeInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'transModelIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.transModelIdInput[self.asset.content[i].asset_id];
                                    self.transModelIdInput = [];
                                } 
                                self.transModelIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'rearRatioInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.rearRatioInput[self.asset.content[i].asset_id];
                                    self.rearRatioInput = [];
                                } 
                                self.rearRatioInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'wheelBaseInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.wheelBaseInput[self.asset.content[i].asset_id];
                                    self.wheelBaseInput = [];
                                } 
                                self.wheelBaseInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'engineSerialInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.engineSerialInput[self.asset.content[i].asset_id];
                                    self.engineSerialInput = [];
                                } 
                                self.engineSerialInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'odometerInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.odometerInput[self.asset.content[i].asset_id];
                                    self.odometerInput = [];
                                } 
                                self.odometerInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'apuSerialInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.apuSerialInput[self.asset.content[i].asset_id];
                                    self.apuSerialInput = [];
                                } 
                                self.apuSerialInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'apuHourInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.apuHourInput[self.asset.content[i].asset_id];
                                    self.apuHourInput = [];
                                } 
                                self.apuHourInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'reeferSerialInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.reeferSerialInput[self.asset.content[i].asset_id];
                                    self.reeferSerialInput = [];
                                } 
                                self.reeferSerialInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'reeferHoursInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.reeferHoursInput[self.asset.content[i].asset_id];
                                    self.reeferHoursInput = [];
                                } 
                                self.reeferHoursInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'hpInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.hpInput[self.asset.content[i].asset_id];
                                    self.hpInput = [];
                                } 
                                self.hpInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'cabStyleIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.cabStyleIdInput[self.asset.content[i].asset_id];
                                    self.cabStyleIdInput = [];
                                } 
                                self.cabStyleIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'cabTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.cabTypeIdInput[self.asset.content[i].asset_id];
                                    self.cabTypeIdInput = [];
                                } 
                                self.cabTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'dtrTradeTermsIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.dtrTradeTermsIdInput[self.asset.content[i].asset_id];
                                    self.dtrTradeTermsIdInput = [];
                                }
                                self.dtrTradeTermsIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'gvwInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.gvwInput[self.asset.content[i].asset_id];
                                    self.gvwInput = [];
                                }
                                self.gvwInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'transSpeedIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.transSpeedIdInput[self.asset.content[i].asset_id];
                                    self.transSpeedIdInput = [];
                                }
                                self.transSpeedIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'bunkTypeInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.bunkTypeInput[self.asset.content[i].asset_id];
                                    self.bunkTypeInput = [];
                                }
                                self.bunkTypeInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.transMakeArr = callbackData.TransMake;
                self.engineMakeArr = callbackData.EngineMake;
                self.cabStyleArr = callbackData.CabStyle;
                self.cabTypeArr = callbackData.CabType;
                self.dtrTradeTermsArr = callbackData.DtrTradeTerms;
                self.transSpeedArr = callbackData.TransSpeed;
            },
            loadMasterDetailsCallback: function (callbackData) {
                self.transModelArr = callbackData.TransModel;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].transModelId != null && typeof self.asset.content[i].transModelId != "undefined" && self.asset.content[i].transModelId != '') {
                            self.multiEditTransModelArr[self.asset.content[i].asset_id] = [];
                            $.each(self.transModelArr, function (j) {
                                if(self.asset.content[i].transMakeId == self.transModelArr[j].transMakeId) {
                                    self.multiEditTransModelArr[self.asset.content[i].asset_id].push(self.transModelArr[j]);
                                }
                                // self.multiEditTransModelArr[self.asset.content[i].asset_id] = self.transModelArr;
                            });
                        }
                    }
                });

                self.engineModelArr = callbackData.EngineModel;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].engModelId != null && typeof self.asset.content[i].engModelId != "undefined" && self.asset.content[i].engModelId != '') {
                            self.multiEditEngineModelArr[self.asset.content[i].asset_id] = [];
                            $.each(self.engineModelArr, function (j) {
                                if(self.asset.content[i].engineMakeId == self.engineModelArr[j].engineMakeId) {
                                    self.multiEditEngineModelArr[self.asset.content[i].asset_id].push(self.engineModelArr[j]);
                                }
                                // self.multiEditEngineModelArr[self.asset.content[i].asset_id] = self.engineModelArr;
                            });
                        }
                    }
                });
            },
            /* loadTransMakeDataCallback: function (callbackData) {
                self.transMakeArr = callbackData;
            }, */
            transMakeChangeInventory: function(inventoryId, transMakeId, assetIndex) {
                self.transModelIdInput[inventoryId] = {};
                self.multiEditTransModelArr[inventoryId] = [];
                // self.loadAllData('TransModel', false, self.loadTransModelDataCallback, "undefined", '', transMakeId);
                var tempModelId = self.asset.content[assetIndex].transModelId;
                self.$nextTick(function () {
                    // setTimeout(function() { //UN-COMMENT WHILE USING API BASED LOGIC
                        if(self.transModelArr && self.transModelArr.length > 0) {
                            for (let i in self.transModelArr) {
                                var obj = self.transModelArr[i];
                                if(transMakeId == obj.transMakeId) {
                                    self.multiEditTransModelArr[inventoryId].push(obj);
                                }
                                /* if(obj.value == tempModelId) {
                                    // self.transModelIdInput[inventoryId] = {text: self.capitalizeFirstLetter(self.asset.content[assetIndex].transModelName), value: self.asset.content[assetIndex].transModelId};
                                    // return false;
                                } */
                            }
                        }
                    // }, 500);
                });
            },
            /* loadTransModelDataCallback: function (callbackData) {
                self.transModelArr = callbackData.TransModel;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].transModelId != null && typeof self.asset.content[i].transModelId != "undefined" && self.asset.content[i].transModelId != '') {
                            self.multiEditTransModelArr[self.asset.content[i].asset_id] = [];
                            $.each(self.transModelArr, function (j) {
                                if(self.asset.content[i].transMakeId == self.transModelArr[j].transMakeId) {
                                    self.multiEditTransModelArr[self.asset.content[i].asset_id].push(self.transModelArr[j]);
                                }
                                // self.multiEditTransModelArr[self.asset.content[i].asset_id] = self.transModelArr;
                            });
                        }
                    }
                });
            }, */
            /* loadEngineMakeDataCallback: function (callbackData) {
                self.engineMakeArr = callbackData.EngineMake;
            }, */
            enginesMakeChangeInventory: function(inventoryId, engineMakeId, assetIndex) {
                self.engineModelIdInput[inventoryId] = {};
                self.multiEditEngineModelArr[inventoryId] = [];
                // self.loadAllData('EngineModel', false, self.loadEngineModelDataCallback, "undefined", '', engineMakeId);
                var tempModelId = self.asset.content[assetIndex].engModelId;
                self.$nextTick(function () {
                    // setTimeout(function() { //UN-COMMENT WHILE USING API BASED LOGIC
                        if(self.engineModelArr && self.engineModelArr.length > 0) {
                            for (let i in self.engineModelArr) {
                                var obj = self.engineModelArr[i];
                                if(engineMakeId == obj.engineMakeId) {
                                    self.multiEditEngineModelArr[inventoryId].push(obj);
                                }
                                /* if(obj.value == tempModelId) {
                                    // self.engineModelIdInput[inventoryId] = {text: self.capitalizeFirstLetter(self.asset.content[assetIndex].engModelName), value: self.asset.content[assetIndex].engModelId};
                                    // return false;
                                } */
                            }
                        }
                    // }, 500);
                });
            },
            /* loadEngineModelDataCallback: function(callbackData) {
                self.engineModelArr = callbackData.EngineModel;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].engModelId != null && typeof self.asset.content[i].engModelId != "undefined" && self.asset.content[i].engModelId != '') {
                            self.multiEditEngineModelArr[self.asset.content[i].asset_id] = [];
                            $.each(self.engineModelArr, function (j) {
                                if(self.asset.content[i].engineMakeId == self.engineModelArr[j].engineMakeId) {
                                    self.multiEditEngineModelArr[self.asset.content[i].asset_id].push(self.engineModelArr[j]);
                                }
                                // self.multiEditEngineModelArr[self.asset.content[i].asset_id] = self.engineModelArr;
                            });
                        }
                    }
                });
            }, */
            changeActivityDateTime: function () {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.warrantyServiceDateErrorMsg, i, "");
                    }
                });
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArrInput = callbackData;
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'engine_serial' : self.engineSerialInput[self.asset.content[i].asset_id],
                            'odometer' : self.odometerInput[self.asset.content[i].asset_id],
                            'apu_serial' : self.apuSerialInput[self.asset.content[i].asset_id],
                            'apu_hour' : self.apuHourInput[self.asset.content[i].asset_id],
                            'reefer_serial' : self.reeferSerialInput[self.asset.content[i].asset_id],
                            'reefer_hour' : self.reeferHoursInput[self.asset.content[i].asset_id],
                            'engine_hp' : self.hpInput[self.asset.content[i].asset_id],
                            'engine_make' : self.engineMakeInput[self.asset.content[i].asset_id].value,
                            'engine_model' : self.engineModelIdInput[self.asset.content[i].asset_id].value,
                            'trans_make' : self.transMakeInput[self.asset.content[i].asset_id].value,
                            'trans_model' : self.transModelIdInput[self.asset.content[i].asset_id].value,
                            'rear_ratio' : self.rearRatioInput[self.asset.content[i].asset_id],
                            'wheelbase' : self.wheelBaseInput[self.asset.content[i].asset_id],
                            'cab_style' : self.cabStyleIdInput[self.asset.content[i].asset_id].value,
                            'cab_type' : self.cabTypeIdInput[self.asset.content[i].asset_id].value,
                            'dtr_trade_terms' : self.dtrTradeTermsIdInput[self.asset.content[i].asset_id].value,
                            'gvw' : self.gvwInput[self.asset.content[i].asset_id],
                            'trans_speed' : (self.transSpeedIdInput[self.asset.content[i].asset_id]) ? self.transSpeedIdInput[self.asset.content[i].asset_id].value : '',
                            'bunk_type' : self.bunkTypeInput[self.asset.content[i].asset_id],
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },

            changedValue: function (value) {
                /*console.log(value);*/
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null) {
                            if (self.apuHourInput[i] && !self.numberFormateValidation(self.apuHourInput[i])) {
                                Vue.set(self.apuHoursErrorMsg, i, "Please enter numeric value");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            /* if (self.reeferSerialInput[i] && !self.numberFormateValidation(self.reeferSerialInput[i])) {
                                Vue.set(self.reeferErrorMsg, i, "Please enter numeric value");
                                if (checked) {
                                    checked = false;
                                }
                            } */
                            if (self.hpInput[i] && !self.numberFormateValidation(self.hpInput[i])) {
                                Vue.set(self.hpInputErrorMsg, i, "Please enter numeric value");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (self.odometerInput[i] && !self.numberFormateValidation(self.odometerInput[i])) {
                                Vue.set(self.odometerErrorMsg, i, "Please enter numeric value");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (self.gvwInput[i] && !self.numberFormateValidation(self.gvwInput[i])) {
                                Vue.set(self.gvwErrorMsg, i, "Please enter numeric value");
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                });
                /*$.each(self.assetIdInput, function (i) {
                    if(self.assetIdInput[i] != null){
                        if (typeof(self.warrantyTypeIdInput[i])  == 'undefined' || self.warrantyTypeIdInput[i] == null || self.warrantyTypeIdInput[i].value == '') {
                            Vue.set(self.warrantyTypeIdErrorMsg, i, "Please select warranty");
                            if (checked) {
                                checked = false;
                            }
                        }
                        if (!self.warrantyServiceDateInput[i]) {
                            Vue.set(self.warrantyServiceDateErrorMsg, i, "Please enter warranty service date");
                            if (checked) {
                                checked = false;
                            }
                        }
                        if (!self.warrantyTermsInput[i]) {
                            Vue.set(self.warrantyTermsErrorMsg  , i, "Please enter warranty terms");
                            if (checked) {
                                checked = false;
                            }
                        }
                    }
                });*/
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/spec2", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 2);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;

                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>