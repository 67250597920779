<template>
    <div class="modal fade" id="estimateDetailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="poDetailModalLabel">
              Estimate Details
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card" style="border: 3px solid black">
              <table class="table custom-table jsmartable">
                <tr>
                  <td id="cusId"><span class="bold">Customer ID:</span> {{ showDetail.cus_id }}</td>
                  <td id="billAdd1"><span class="bold">Bill Address1:</span> {{ showDetail.bill_addr1 }}</td>
                  <td id="billCity"><span class="bold">Bill City:</span> {{ showDetail.bill_city }}</td>
                </tr>
                <tr>
                  <td id="cusName"><span class="bold">Customer Name:</span> {{ showDetail.cus_name }}</td>
                  <td id="billAdd2"><span class="bold">Bill Address2:</span> {{ showDetail.bill_addr2 }}</td>
                  <td id="billState"><span class="bold">Bill State:</span> {{ showDetail.bill_state }}</td>
                </tr>
                <tr>
                  <td id="dateCreated"><span class="bold">Created At:</span> {{ showDetail.created_at }}</td>
                  <td id="billCounty"><span class="bold">Bill Country:</span> {{ showDetail.bill_county }}</td>
                  <td id="billPost"><span class="bold">Bill Post:</span> {{ showDetail.bill_post }}</td>
                </tr>
                <tr style="border-top: 3px solid black">
                  <td id="statusDes"><span class="bold">Status Description:</span></td>
                  <td id="slsId"><span class="bold">SLS:</span></td>
                  <td id="des" colspan="2"><span class="bold">Status Name:</span></td>
                </tr>
                <tr style="border-bottom: 3px solid black">
                  <td>{{ showDetail.status_des }}</td>
                  <td>{{ showDetail.sls_id }}</td>
                  <td>{{ showDetail.status_name }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td id="amtSubTotal"><span class="bold">Amount Subtotal:</span> {{ showDetail.amt_subtotal }}</td>
                </tr>
                <!-- <tr>
                  <td colspan="2">
                      <table class="table">
                          <tr>
                              <td><span class="bold">ID</span></td>
                              <td><span class="bold">CONDITION</span></td>
                              <td><span class="bold">SUB TOTAL</span></td>
                              <td><span class="bold">OPSID</span></td>
                              <td><span class="bold">CAUSE</span></td>
                          </tr>
                          <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      </table>
                  </td>
                </tr> -->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import eventBus from "../../../../../event-bus";
  var estimateDetail;
  export default {
    name: "EstimateDetailModal",
    data() {
      return {
        showDetail: {},
        modalInstance: null,
      };
    },
    mounted() {
      estimateDetail = this;
      estimateDetail.initializeModals();
    },
    methods: {
      initializeModals() {
        estimateDetail.modalInstance = new bootstrap.Modal(
          document.getElementById("estimateDetailModal"), {
          backdrop: 'static',
          keyboard: false,
        });
      },
      closeModal() {
        estimateDetail.modalInstance.hide();
        eventBus.$emit("close-details", false, "estimate");
      },
      getEstimateDetail(data) {
        estimateDetail.modalInstance.show();
        estimateDetail.showDetail = data;
      }
    }
  };
  
  eventBus.$on("show-details", (value, type) => {
  if (type === 'estimate') {
    estimateDetail.getEstimateDetail(value);
  }  
  });
  </script>
  