<template>
    <div class="card">
      <div class="card-header">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="true"
            aria-controls="collapseFour"
          >
            History
          </button>
        </h2>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingThree">
        <div class="card-body">
          <div class="table-responsive">
            <!-- TABLE -->
            <table class="table custom-table jsmartable">
              <tbody>
                <template v-if="historyArr && historyArr.length > 0">
                  <tr v-for="(historyArr, index) in historyArr" :key="index">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="6" class="text-alignment-center">
                      {{ allStaticMessages.data_not_available }}
                    </td>
                  </tr>
                </template>
              </tbody>
              <thead>
                <tr>
                  <th class="" data-breakpoint="xs">Date Update</th>
                  <th class="" data-breakpoint="xs">Type</th>
                  <th class="" data-breakpoint="xs">Reference</th>
                  <th class="" data-breakpoint="xs">Name</th>
                  <th class="" data-breakpoint="xs">Price</th>
                  <th class="" data-breakpoint="xs">Cost</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
      export default {
          name: 'History',
          props: ['historyArr','allStaticMessages'],
      }
  </script>