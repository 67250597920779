<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <!-- Opportunity ID : {{selectedOpportunityId}} -->
        <template v-if="isShowVehicleWorkSheet">
            <vehicle-work-sheet :selectedOpportunityId="selectedOpportunityId" :editAssetId="editAssetId"></vehicle-work-sheet>
        </template>
        <template v-else>
            <template id="quotation" v-if="type == 'quotation'">
                <div class="container-fluid xyz">
                    <div class="spaced-container">
                        <div class="col-12 page_title paddingnone">
                            <div class="page_title_left">
                                <span>{{allLabelMessages.transaction_sheet_title}}</span>
                            </div>
                            <button style="margin-right: 5px;" type="submit" v-on:click.prevent="saveBookDeal()" class="btn btn-primary waves-effect pull-right">Save</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 8" type="submit" v-on:click.prevent="checkPrintQuotation()" class="btn btn-primary waves-effect pull-right" id="printQuotBtn">Print Quotation</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 2 || dealStatusIdValue == 4" type="submit" v-on:click.prevent="saveBookDeal(0,1)" class="btn btn-primary waves-effect pull-right" id="accounting">Accounting</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 1 || dealStatusIdValue == 3 || dealStatusIdValue == 4 || dealStatusIdValue == 5 || dealStatusIdValue == 7" type="submit" v-on:click.prevent="saveBookDeal(0,2)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Document Request</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 7" type="submit" v-on:click.prevent="saveBookDeal(0,3)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Lost</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 2" type="submit" v-on:click.prevent="saveBookDeal(0,4)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Manager</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 7" type="submit" v-on:click.prevent="saveBookDeal(0,5)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Request Ro/PO</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue >= 1" type="submit" v-on:click.prevent="saveBookDeal(0,6)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Push to EXCEDE</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 2 || dealStatusIdValue == 4 || dealStatusIdValue == 5 || dealStatusIdValue == 8" type="submit" v-on:click.prevent="saveBookDeal(0,7)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Sales</button>
                            <button style="margin-right: 5px;" v-if="dealStatusIdValue == 7" type="submit" v-on:click.prevent="saveBookDeal(0,8)" class="btn btn-primary waves-effect pull-right" id="documentRequest">Quatation</button>
                            <button style="margin-right: 5px;" v-if="dealNo" type="submit" v-on:click.prevent="next('buildUp', null)" class="btn btn-primary waves-effect pull-right" id="quotationNext">Next</button>
                        </div>
                    </div>
                </div>
                <div class="admin-page-content">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">Add to forecast</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="add_to_forecast" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- Invoice Number -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue == 2">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.invoice_no_label}}</div>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <span class="form-control" >{{invoiceNo}}</span>
                                    <span class="help is-danger">{{invoiceNoError}}</span>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <button type="submit" v-on:click.prevent="saveBookDeal()" class="btn btn-primary waves-effect pull-right">Generate Invoice#</button>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.deal_no_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control" :class="{ 'disabled': isEditDeal }">{{dealNo}}</span>
                                    <span class="help is-danger">{{dealNoError}}</span>
                                </div>
                            </div>
                            <!-- Excede ID#  -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.excede_id_hash_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="excedeIdHashError = ''" id="excedeIdHash" :disabled="isEditDeal" v-model="excedeIdHash" class="form-control" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{excedeIdHashError}}</span>
                                </div>
                            </div>
                            <!-- Invoice Date -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.invoice_date_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <date-picker @input="invoiceDateError = ''" v-model="invoiceDate" :disabled="isEditDeal" :config="options" class="form-control" autocomplete="off" :placeholder="allStaticMessages.invoice_date_place_holder" name="invoiceDate">
                                    <span class="help is-danger">{{invoiceDateError}}</span>
                                    </date-picker>
                                </div>
                            </div>
                            <!-- Invoice#  -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.invoice_hash_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="invoiceHashError = ''" id="invoiceHash" :disabled="isEditDeal" v-model="invoiceHash" class="form-control" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{invoiceHashError}}</span>
                                </div>
                            </div>
                            <!-- Department -->
                            <!-- <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="companyId" name="companyId"
                                            label="text" 
                                            :filterable="false" 
                                            :options="companyArr"
                                            @search="onSearchCompany"
                                            @change="updateCompany"
                                            :clearable="true" 
                                            @input="companyError = ''"
                                            :placeholder="allStaticMessages.company_custom_place_holder" 
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{allStaticMessages.company_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyError}}</span>
                                </div>
                            </div> -->
                            <!-- Location -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="locationId" name="locationId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="locationArr"
                                        @search="onSearchLocation" 
                                        :clearable="true" 
                                        :disabled="isEditDeal"
                                        @input="locationError = ''"
                                        :placeholder="allStaticMessages.location_custom_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isLocationRecordNotFound">
                                                {{allStaticMessages.location_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <!-- Status -->
                            <!-- <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.status_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select v-model="dealStatusId" name="dealStatusId"
                                                label="text" 
                                                :filterable="false" 
                                                :options="dealStatusArr"
                                                @search="onSearchDealStatus" 
                                                @input="statusError = ''"
                                                :clearable="false"
                                                :searchable="false"
                                                :placeholder="allStaticMessages.status_place_holder" 
                                                class="custom-ci-auto-search">
                                        <v-select 
                                                v-model="pricebookId"
                                                name="pricebookId"
                                                @change="changedPricebook"
                                                @input="updatePricebook"
                                                id="pricebookId"
                                                label="text"
                                                :clearable="true"
                                                :filterable="false"
                                                :options="pricebookArr"
                                                :disabled="isEditDeal"
                                                :placeholder="allStaticMessages.pricebook_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isDealStatusRecordNotFound">
                                                    {{allStaticMessages.status_custom_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <span class="help is-danger">{{statusError}}</span>
                                    </div>
                                </div>
                            </template>-->
                            <!-- Select Inventory Type Used/New -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dataAccessType =='both'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.condition_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="conditionId" name="conditionId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="conditionArr"
                                        :clearable="false"
                                        :disabled="isEditDeal"
                                        :searchable="false"
                                        :placeholder="allStaticMessages.condition_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isDealStatusRecordNotFound">
                                                {{allStaticMessages.condition_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.condition_search}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>
                            <template v-if="dealStatusIdValue == 8">
                                <!-- Select Make -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.make_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select 
                                                v-model="makeId"
                                                @input="makeError = ''"
                                                name="make"
                                                id="make"
                                                label="text"
                                                :clearable="true"
                                                @search="onSearchMake"
                                                :filterable="false"
                                                :options="makeArr"
                                                disabled
                                                :placeholder="allStaticMessages.make_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isMakeRecordNotFound">
                                                    {{allStaticMessages.make_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{makeError}}</span>
                                    </div>
                                </div>
                                <!-- Select Series -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.series}}:</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="series"
                                            name="series"
                                            id="series"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            @change="changedSeries"
                                            :options="seriesArr"
                                            :disabled="isEditDeal"
                                            @input="updatedSeries"
                                        >
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{seriesError}}</span>
                                    </div>
                                </div>
                                <!-- Select Model -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.model_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select 
                                                v-model="modelId"
                                                @change="changedValue"
                                                @input="updateModel"
                                                name="modelId"
                                                id="modelId"
                                                label="text"
                                                :clearable="true"
                                                :filterable="false"
                                                :options="modelArr"
                                                :disabled="isEditDeal"
                                                @search="onSearchModel"
                                                :placeholder="allStaticMessages.model_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isModelRecordNotFound">
                                                    {{allStaticMessages.model_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{modelError}}</span>
                                    </div>
                                </div>
                                <!-- Select Pricebook -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.pricebook}}:</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select 
                                                v-model="pricebookId"
                                                name="pricebookId"
                                                @change="changedPricebook"
                                                @input="updatePricebook"
                                                id="pricebookId"
                                                label="text"
                                                :clearable="true"
                                                :filterable="false"
                                                :options="pricebookArr"
                                                :disabled="isEditDeal"
                                                :placeholder="allStaticMessages.pricebook_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isPricebookRecordNotFound">
                                                    {{allStaticMessages.pricebook_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{pricebookError}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Contract Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.contract_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="contractNumberError = ''" :disabled="isEditDeal" id="contractNumber" v-model="contractNumber" class="form-control" type="text" maxlength="190"/>
                                    <span class="help is-danger">{{contractNumberError}}</span>
                                </div>
                            </div>
                            <!-- Contract Date -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.contract_date_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <date-picker v-model="contractDate" :config="options" :disabled="isEditDeal" class="form-control" autocomplete="off" :placeholder="allStaticMessages.contract_date_place_holder" name="contractDate">
                                    <span class="help is-danger">{{contractDateError}}</span>
                                    </date-picker>
                                </div>
                            </div>
                            <!-- Sales Person -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.seles_person}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="salePersonId" name="salePersonId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="salePersonArr"
                                        @search="onSearchSalePerson" 
                                        :clearable="true"
                                        :disabled="isEditDeal"
                                        @input="salePersonError = ''"
                                        :placeholder="allStaticMessages.sales_person_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSalePersonRecordNotFound">
                                                {{allStaticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <span class="help is-danger">{{salePersonError}}</span>
                                </div>
                            </div>
                            <!-- Business Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.business_name}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="businessName"
                                        name="businessName"
                                        label="text"
                                        :filterable="false"
                                        :options="businessNameArr"
                                        @search="onSearchBusiness"
                                        :clearable="true"
                                        @input="updateBusiness"
                                        id="businessName"
                                        :placeholder="allStaticMessages.select_business"
                                        class="custom-ci-auto-search"
                                        :disabled="isEditDeal"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isBusinessRecordNotFound">
                                                {{allStaticMessages.search_business}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{businessNameError}}</span>
                                </div>
                            </div>
                            <!-- Customer -->
                            <!-- <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                            v-model="customerId"
                                            name="customerId"
                                            label="text"
                                            :filterable="false"
                                            :options="customerArr"
                                            @search="onSearchCustomer"
                                            :clearable="true"
                                            @input="customerError = ''"
                                            id="customerId"
                                            :placeholder="allStaticMessages.customer_place_holder"
                                            class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerRecordNotFound">
                                                {{allStaticMessages.customer_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerError}}</span>
                                </div>
                            </div> -->
                            <!-- Individual -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <!-- <div class="col-md-2 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="individual" value="true" v-model="individual" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div> -->
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <!-- <v-select 
                                        v-model="customerId"
                                        name="customerId"
                                        label="text"
                                        :filterable="false"
                                        :options="customerArr"
                                        @search="onSearchCustomer"
                                        :clearable="true"
                                        @input="updateCustomer"
                                        id="customerId"
                                        :placeholder="allStaticMessages.customer_place_holder"
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isDealStatusRecordNotFound">
                                                {{allStaticMessages.condition_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.condition_search}}
                                            </template>
                                        </span>
                                    </v-select> -->
                                    <input @input="customerError = ''" :disabled="isEditDeal" id="customerId" v-model="customerId" class="form-control" type="text" maxlength="190"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{customerError}}</span>
                                </div>
                                <!-- </div> -->
                            </div>
                            <!-- Cus. Address -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusAddressError = ''" :disabled="isEditDeal" id="cusAddress" v-model="cusAddress" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusAddressError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Address 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusAddress2Error = ''" :disabled="isEditDeal" id="cusAddress2" v-model="cusAddress2" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusAddress2Error}}</span>
                                </div>
                            </div>
                            <!-- Customer County -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="customerCountyId" 
                                        name="customerCountyId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="customerCountyArr"
                                        @search="onSearchCustomerCounty"
                                        :clearable="false"
                                        @input="updateCountry"
                                        id="customerCountyId"
                                        :placeholder="allStaticMessages.county_place_holder" 
                                        class="custom-ci-auto-search"
                                        :disabled="true"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerCountyRecordNotFound">
                                                {{allStaticMessages.county_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{customerCountyError}}</span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select v-model="customerStateId" name="customerStateId"
                                        label="text" :filterable="false" :options="stateArr"
                                        @search="onSearchState" :clearable="false" id="customerStateId"
                                        @input="updateState"
                                        :placeholder="allStaticMessages.state_place_holder" class="custom-ci-auto-search"
                                        :disabled="isEditDeal"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateRecordNotFound">
                                                {{allStaticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!-- <input class="form-control" v-model="cusCity3" name="cusCity3" id="cusCity3" type="text" maxlength="190"/> -->
                                </div>
                            </div>
                            <!-- Customer City -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select 
                                        v-model="customerCityId" 
                                        name="customerCityId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="customerCityArr"
                                        @search="onSearchCustomerCity"
                                        :clearable="false"
                                        @input="updateCity"
                                        id="customerCityId"
                                        :placeholder="allStaticMessages.city_place_holder" 
                                        class="custom-ci-auto-search"
                                        :disabled="isEditDeal"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCustomerCityRecordNotFound">
                                                {{allStaticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{customerCityError}}</span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.zip_code_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input class="form-control" v-model="cusCity2" name="cusCity2" id="cusCity2" type="text" maxlength="190" :disabled="isEditDeal"/>
                                </div>
                            </div>
                            <!-- Alt Cus. City Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.alt_city_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusAltCityNameError = ''" id="cusAltCityName" :disabled="isEditDeal" v-model="cusAltCityName" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusAltCityNameError}}</span>
                                </div>
                            </div>
                            <!-- Alt Cus. County Name -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.alt_county_name_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusAltCountyNameError = ''" :disabled="isEditDeal" id="cusAltCountyName" v-model="cusAltCountyName" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusAltCountyNameError}}</span>
                                </div>
                            </div>
                            <!-- Customer Email -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.customer_email_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="customerEmailError = ''" :disabled="isEditDeal" id="customerEmail" v-model="customerEmail" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{customerEmailError}}</span>
                                </div>
                            </div>
                            <!-- Elite Logo -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.elite_logo_on_pdf}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="uploadEliteLogoOnPdf" :disabled="isEditDeal" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <template v-if="dealStatusIdValue != 8">
                                <!-- Buyer Block Start -->
                                <div class="col-md-12 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <div class="book-deal-customer-block" v-b-toggle="'co-buyer-all-data'">Co-Buyer</div>
                                    </div>
                                </div>
                                <b-collapse id="co-buyer-all-data">
                                    <!-- Buyer Phone Number -->
                                    <div class="col-md-4 forms-block">
                                        <div class="col-md-4 paddingleft0">
                                            <div class="form-label">Co-Buyer</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="buyerNumberError = ''" id="buyerNumber" v-model="buyerNumber" class="form-control" type="text" maxlength="190"/>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{buyerNumberError}}</span>
                                        </div>
                                    </div>
                                    <!-- Buyer Name -->
                                    <div class="col-md-4 forms-block">
                                        <div class="col-md-4 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <v-select 
                                                    v-model="buyerId"
                                                    name="buyerId"
                                                    label="text"
                                                    :filterable="false"
                                                    :options="buyerArr"
                                                    @search="onSearchBuyer"
                                                    :clearable="true"
                                                    @input="buyerError = ''"
                                                    id="buyerId"
                                                    :placeholder="allStaticMessages.customer_place_holder"
                                                    class="custom-ci-auto-search">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isCustomerRecordNotFound">
                                                        {{allStaticMessages.customer_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{allStaticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{buyerError}}</span>
                                        </div>
                                    </div>
                                    <!-- Buyer Address -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.address_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="buyerAddressError = ''" id="buyerAddress" v-model="buyerAddress" class="form-control" type="text" maxlength="190"/>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{buyerAddressError}}</span>
                                        </div>
                                    </div>
                                    <!-- Buyer Address 2 -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="buyerAddress2Error = ''" id="buyerAddress2" v-model="buyerAddress2" class="form-control" type="text" maxlength="190"/>
                                            <span class="help is-danger">{{buyerAddress2Error}}</span>
                                        </div>
                                    </div>
                                    <!-- Buyer City -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.city_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <v-select 
                                                v-model="buyerCityId" 
                                                name="buyerCityId"
                                                label="text" 
                                                :filterable="false" 
                                                :options="buyerCityArr"
                                                @search="onSearchBuyerCity"
                                                :clearable="false"
                                                @input="buyerCityError = ''"
                                                id="buyerCityId"
                                                :placeholder="allStaticMessages.city_place_holder" 
                                                class="custom-ci-auto-search"
                                            >
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isBuyerCityRecordNotFound">
                                                        {{allStaticMessages.city_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{allStaticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{buyerCityError}}</span>
                                        </div>
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <input class="form-control" v-model="buyerCity2" name="buyerCity2" id="buyerCity2" type="text" maxlength="190"/>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input class="form-control" v-model="buyerCity3" name="buyerCity3" id="buyerCity3" type="text" maxlength="190"/>
                                        </div>
                                    </div>
                                </b-collapse>
                            </template>
                            <!-- Buyer Block End -->
                            <template v-if="dealStatusIdValue != 8">
                                <!-- Deliver to Block Start -->
                                <div class="col-md-12 forms-block">
                                    <div class="col-12 paddingleft0">
                                        <div class="book-deal-customer-block" v-b-toggle="'deliver-to-all-data'">Deliver To</div>
                                    </div>
                                </div>
                                <b-collapse id="deliver-to-all-data">
                                    <!-- Deliver To Phone Number -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.cus_phone_number_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="deliverToPhoneNumberError = ''" id="deliverToPhoneNumber" v-model="deliverToPhoneNumber" class="form-control" type="text" maxlength="190"/>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{deliverToPhoneNumberError}}</span>
                                        </div>
                                    </div>
                                    <!-- Deliver To Name -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.customer_name_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <v-select 
                                                    v-model="deliverToId"
                                                    name="deliverToId"
                                                    label="text"
                                                    :filterable="false"
                                                    :options="deliverToArr"
                                                    @search="onSearchDeliverTo"
                                                    :clearable="true"
                                                    @input="deliverToError = ''"
                                                    id="buyerId"
                                                    :placeholder="allStaticMessages.customer_place_holder"
                                                    class="custom-ci-auto-search">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isDeliverToRecordNotFound">
                                                        {{allStaticMessages.customer_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{allStaticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{deliverToError}}</span>
                                        </div>
                                    </div>
                                    <!-- Deliver To Address -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.address_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="deliverToAddressError = ''" id="deliverToAddress" v-model="deliverToAddress" class="form-control" type="text" maxlength="190"/>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{deliverToAddressError}}</span>
                                        </div>
                                    </div>
                                    <!-- Deliver To Address 2 -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.cus_address2_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input @input="deliverToAddress2Error = ''" id="deliverToAddress2" v-model="deliverToAddress2" class="form-control" type="text" maxlength="190"/>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{deliverToAddress2Error}}</span>
                                        </div>
                                    </div>
                                    <!-- Deliver To City -->
                                    <div class="col-md-4 col-sm-12 forms-block">
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <div class="form-label">{{allLabelMessages.city_label}}</div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <v-select 
                                                v-model="deliverToCityId" 
                                                name="deliverToCityId"
                                                label="text" 
                                                :filterable="false" 
                                                :options="deliverToCityArr"
                                                @search="onSearchDeliverToCity"
                                                :clearable="false"
                                                @input="deliverToCityError = ''"
                                                id="deliverToCityId"
                                                :placeholder="allStaticMessages.city_place_holder" 
                                                class="custom-ci-auto-search"
                                            >
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    <template v-if="!isDeliverToCityRecordNotFound">
                                                        {{allStaticMessages.city_search}}
                                                    </template>
                                                    <template v-else>
                                                        {{allStaticMessages.search_record_not_found}}
                                                    </template>
                                                </span>
                                            </v-select>
                                            <!--<div class="requiredstar">*</div>-->
                                            <span class="help is-danger">{{deliverToCityError}}</span>
                                        </div>
                                        <div class="col-md-4 col-sm-12 paddingleft0">
                                            <input class="form-control" v-model="deliverToCity2" name="deliverToCity2" id="deliverToCity2" type="text" maxlength="190"/>
                                        </div>
                                        <div class="col-md-8 col-sm-12 paddingleft0">
                                            <input class="form-control" v-model="deliverToCity3" name="deliverToCity3" id="deliverToCity3" type="text" maxlength="190"/>
                                        </div>
                                    </div>
                                </b-collapse>
                            </template>
                            <!-- Internal Commnets -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.internal_comments_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea @input="internalCommentsError = ''" id="internalComments" v-model="internalComments" class="form-control"></textarea>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{internalCommentsError}}</span>
                                </div>
                            </div>
                            <!-- Commnets -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.comments_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea @input="commentsError = ''" id="comments" v-model="comments" class="form-control"></textarea>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{commentsError}}</span>
                                </div>
                            </div>
                            <!-- Commnets 2 -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.comments2_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <textarea @input="comments2Error = ''" id="comments2" v-model="comments2" class="form-control"></textarea>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{comments2Error}}</span>
                                </div>
                            </div>
                            <!-- R.O.S Number -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.ros_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="rosNumberError = ''" id="rosNumber" v-model="rosNumber" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{rosNumberError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Phone Number -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_phone_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusPhoneNumberError = ''" :disabled="isEditDeal" id="cusPhoneNumber" v-model="cusPhoneNumber" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusPhoneNumberError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Fax -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_fax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusFaxError = ''" id="cusFax" v-model="cusFax" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusFaxError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Cell -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_cell_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCellError = ''" id="cusCell" :disabled="isEditDeal" v-model="cusCell" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCellError}}</span>
                                </div>
                            </div>
                            <!-- Cus. SIN -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.cus_sin_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusSINError = ''" id="cusSIN" v-model="cusSIN" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusSINError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Tax Exemption Number -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.tax_exemption_number_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusTaxExemptionNumberError = ''" id="cusTaxExemptionNumber" v-model="cusTaxExemptionNumber" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusTaxExemptionNumberError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Address Type -->
                            <div class="col-md-4 col-sm-12 forms-block">
                                <!-- <div class="col-md-4 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.address_type_label}}</div>
                                </div> -->
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-form-radio-group v-model="cusAddressType" :disabled="isEditDeal" :options="cusAddressTypeOptions" name="cusAddressType"></b-form-radio-group>
                                </div>
                            </div>
                            <!-- Cus. Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusTaxRateError = ''" id="cusTaxRate" v-model="cusTaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusTaxRateError}}</span>
                                    &nbsp;
                                </div>
                                <div class="col-md-1 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="cusSalesTax" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <!--<div class="requiredstar">*</div>-->
                                    <!--<span class="help is-danger">{{descriptionError}}</span> -->
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.applied_sales_tax_label}}</div>
                                </div>
                            </div>
                            <!-- Cus. State Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.state_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusStateTaxRateError = ''" id="cusStateTaxRate" v-model="cusStateTaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusStateTaxRateError}}</span>
                                </div>
                            </div>
                            <!-- Cus. County Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCountyTaxRateError = ''" id="cusCountyTaxRate" v-model="cusCountyTaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCountyTaxRateError}}</span>
                                </div>
                            </div>
                            <!-- Cus. City Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.city_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCityTaxRateError = ''" id="cusCityTaxRate" v-model="cusCityTaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCityTaxRateError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Other Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.other_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusOtherTaxRateError = ''" id="cusOtherTaxRate" v-model="cusOtherTaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusOtherTaxRateError}}</span>
                                </div>
                            </div>
                            <!-- Cus. Other2 Tax Rate -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.other2_tax_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusOther2TaxRateError = ''" id="cusOther2TaxRate" v-model="cusOther2TaxRate" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusOther2TaxRateError}}</span>
                                </div>
                            </div>
                            <!-- Cus. County Surtax -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_surtax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCountySurtaxError = ''" id="cusCountySurtax" v-model="cusCountySurtax" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCountySurtaxError}}</span>
                                </div>
                            </div>
                            <!-- Cus. County Code -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.county_code_lable}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="cusCountyCodeError = ''" id="cusCountyCode" v-model="cusCountyCode" class="form-control" type="text" maxlength="190"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{cusCountyCodeError}}</span>
                                </div>
                            </div>
                            <!-- showOnBuyersOrder -->
                            <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label">{{allLabelMessages.show_on_buyers_order_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0 custom-checkbox-v">
                                    <label class="container">
                                        <input id="yes" value="true" v-model="showOnBuyersOrder" class="form-control" type="checkbox"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <template v-if="dealStatusIdValue != 8">
                                <!-- Vehicle Information Block Start -->
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block">Vehicle Information</div>
                                    </div>
                                </div>
                                <!-- Copy Vehicle -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <!-- <div class="form-label">{{allLabelMessages.comments2_label}}</div> -->
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.copyVehicle" type="submit" :value="buttonNames.copyVehicle" class="btn btn-primary">
                                    </div>
                                </div>
                                <!-- Search Stock Vehicles -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <!-- <div class="form-label">{{allLabelMessages.comments2_label}}</div> -->
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.searchStockVehicles" type="submit" :value="buttonNames.searchStockVehicles" class="btn btn-primary" v-on:click="vehicleWorkSheet()">
                                    </div>
                                </div>
                                <!-- Update Vin Or Stock -->
                                <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <!-- <div class="form-label">{{allLabelMessages.comments2_label}}</div> -->
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.updateVinOrStock" type="submit" :value="buttonNames.updateVinOrStock" class="btn btn-primary">
                                    </div>
                                </div>
                                <!-- Asset List -->
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <!-- TABLE -->
                                    <table class="table custom-table jsmartable">
                                        <tbody>
                                            <template v-if="opportunityInventoryArr && opportunityInventoryArr.length > 0">
                                                <template  v-for="(assetBookDealData, index) in opportunityInventoryArr">
                                                    <tr :key="index">
                                                        <td class="">
                                                            <template v-if="assetId != assetBookDealData.value">
                                                                <a href="javascript:void(0)" class="mar-right-0" title='Edit' v-on:click="editOpportunityInventory(assetBookDealData.value)">
                                                                    <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                                </a>
                                                                <a href="javascript:void(0)" class="mar-right-0" title='delete' v-on:click="deleteOpportunityInventory(assetBookDealData.value)">
                                                                    <i aria-hidden="true" class="fa fa-trash-o forward_icon"></i>
                                                                </a>
                                                            </template>
                                                        </td>
                                                        <td class="text-center">{{assetBookDealData.vehicle_type}}</td>
                                                        <td class="text-center">{{assetBookDealData.vin_no}}</td>
                                                        <td class="text-center">{{assetBookDealData.unit}}</td>
                                                        <td class="text-center">{{assetBookDealData.model_name}}</td>
                                                        <td class="text-right">
                                                            <template v-if="assetBookDealData.retail_price > 0">
                                                                {{assetBookDealData.retail_price_display}}
                                                            </template>
                                                            <template v-else>
                                                                0.00
                                                                <!--{{assetBookDealData.retail_price_display}}-->
                                                            </template>
                                                        </td>
                                                        <td class="text-center">
                                                            <input type="checkbox" />
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.data_not_available}}
                                            </template>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th class="">
                                                    <div class="actions">
                                                        Action
                                                    </div>
                                                </th>
                                                <th class="text-center" data-breakpoint="xs">N / U</th>
                                                <th class="text-center" data-breakpoint="xs">VIN</th>
                                                <th class="text-center" data-breakpoint="xs">StocK No</th>
                                                <th class="text-center" data-breakpoint="xs">Model</th>
                                                <th class="text-right" data-breakpoint="xs">Sale Price</th>
                                                <!-- <th class="" data-breakpoint="xs">Delivery Date</th> -->
                                                <th class="text-center" data-breakpoint="xs">Delivered</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <!-- Vehicle Information Block End -->
                            </template>
                            <template v-if="dealStatusIdValue == 8">
                                <div class="col-md-4 col-sm-12 forms-block">
                                    
                                </div>
                                <!-- <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.specification_pdf_url_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="specificationUrlError = ''" id="specificationUrl" v-model="specificationUrl" class="form-control" type="text" maxlength="5000"/>
                                        <span class="help is-danger">{{specificationUrlError}}</span>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.specification_pages_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="specPagesError = ''" id="specPages" v-model="specPages" class="form-control" type="text" maxlength="190"/>
                                        <span class="help is-danger">{{specPagesError}}</span>
                                    </div>
                                </div> -->
                                <div class="clearfix"></div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block">{{allLabelMessages.service_plan_label}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-md-12 col-sm-12 paddingleft0">
                                        <tinymce id="servicePlan" v-model="servicePlan"></tinymce>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block">{{allLabelMessages.vehicle_warranty_label}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-md-12 col-sm-12 paddingleft0">
                                        <tinymce id="vehicleWarranty" v-model="vehicleWarranty"></tinymce>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block">{{allLabelMessages.roadside_assistance_label}}</div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-md-12 col-sm-12 paddingleft0">
                                        <tinymce id="roadsideAssistance" v-model="roadsideAssistance"></tinymce>
                                    </div>
                                </div>
                            </template>
                            <template v-if="dealStatusIdValue == 8">
                                <!-- Factory Items Block Start -->
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-2 col-sm-2 paddingleft0">
                                        <div class="book-deal-customer-block">{{allLabelMessages.factory_options}}</div>
                                        <button type="button" v-on:click="addNewFactoryRow" class="btn btn-success" :disabled="factoryItemsArr.length == 5">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                        <span class="is-danger ml-3 help" v-if="factoryItemsArr.length == 5">{{allStaticMessages.you_can_not_add_more_factory_option}}</span>
                                    </div>
                                </div>
                                <template v-for="(factoryItems, factoryItemsIndex) in factoryItemsArr">
                                    <div :key="'removeFactory' + factoryItemsIndex" class="col-md-1 forms-block mr-0">
                                        <button class="btn btn-danger" @click="removeFactory(factoryItemsIndex)"><i class="fa fa-times"></i></button>
                                    </div>
                                    <!-- Factory Option -->
                                    <div :key="'Option' + factoryItemsIndex" class="col-md-4 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-5 col-sm-12 paddingleft0">
                                                <div class="form-label pt-0">{{allLabelMessages.factory_options_and_chassis_price}}</div>
                                            </div>
                                            <div class="col-md-7 col-sm-12 paddingleft0">
                                                <input @change="calculateQuot()" @input="factoryItems.factoryOptionError = ''" id="factoryOption" v-model="factoryItems.factoryOption" class="form-control" type="text"/>
                                                <!-- <div class="requiredstar">*</div> -->
                                                <span class="help is-danger">{{factoryItems.factoryOptionError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Factory Price GST -->
                                    <div :key="'Factory Price GST' + factoryItemsIndex" class="col-md-3 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 paddingleft0">
                                                <div class="form-label pt-0">{{allLabelMessages.price_gst_label}}</div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 paddingleft0">
                                                <input @change="calculateQuot()" @input="factoryItems.factoryPriceGstError = ''" id="factoryPriceGst" v-model="factoryItems.factoryPriceGst" class="form-control" type="number"/>
                                                <!-- <div class="requiredstar">*</div> -->
                                                <span class="help is-danger">{{factoryItems.factoryPriceGstError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Factory Order No -->
                                    <div :key="'Factory Order No' + factoryItemsIndex" class="col-md-3 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 paddingleft0">
                                                <div class="form-label pt-0">{{allLabelMessages.order_no}}:</div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 paddingleft0">
                                                <input @change="calculateQuot()" @input="factoryItems.factoryOrderNoError = ''" id="factoryOrderNo" v-model="factoryItems.factoryOrderNo" class="form-control" type="text"/>
                                                <span class="help is-danger">{{factoryItems.factoryOrderNoError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Factory Price Inc -->
                                    <div :key="'Factory Price Inc' + factoryItemsIndex" class="col-md-1 col-sm-12 forms-block">
                                        <b-form-checkbox :id="'factoryPriceInc'+factoryItemsIndex" v-model="factoryItems.factoryPriceInc" name="factoryPriceInc"
                                            value="true" @change="calculateQuot()"
                                        >
                                            Included
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <!-- Factory Items Block End -->
                            </template>
                            <template v-if="dealStatusIdValue == 8">
                                <!-- Line Items Block Start -->
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-2 col-sm-2 paddingleft0">
                                        <div class="book-deal-customer-block">Build Up Items</div>
                                        <button type="button" v-on:click="addNewRow()" class="btn btn-success">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <template v-for="(lineItems, lineItemsIndex) in lineItemsArr">
                                    <div :key="'removeSupplier' + lineItemsIndex" class="col-md-1 forms-block">
                                        <button class="btn btn-danger" @click="removeSupplier(lineItemsIndex)"><i class="fa fa-times"></i></button>
                                    </div>
                                    <!-- Supplier -->
                                    <div :key="'Supplier' + lineItemsIndex" class="col-md-3 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 paddingleft0">
                                                <div class="form-label">{{allLabelMessages.supplier_label}}</div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 paddingleft0">
                                                <input @input="lineItems.supplierError = ''" id="supplier" v-model="lineItems.supplier" class="form-control" type="text"/>
                                                <span class="help is-danger">{{lineItems.supplierError}}</span>
                                            </div>
                                            <!-- <div class="col-md-8 col-sm-12 paddingleft0">
                                                <v-select
                                                    v-model="lineItems.supplierId"
                                                    name="supplierId"
                                                    @input="lineItems.supplierIdError = ''"
                                                    label="text"
                                                    :filterable="false"
                                                    :options="supplierArr"
                                                    @search="onSearchSupplier"
                                                    :clearable="false"
                                                    :placeholder="allStaticMessages.supplier_place_holder">
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        <template v-if="!isSupplierRecordNotFound">
                                                            {{allStaticMessages.supplier_search}}
                                                        </template>
                                                        <template v-else>
                                                            {{allStaticMessages.search_record_not_found}}
                                                        </template>
                                                    </span>
                                                </v-select>
                                                <span class="help is-danger">{{lineItems.supplierIdError}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- Build UP -->
                                    <div :key="'Build UP' + lineItemsIndex" class="col-md-3 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-5 col-sm-12 paddingleft0">
                                                <div class="form-label">{{allLabelMessages.build_up_label}}</div>
                                            </div>
                                            <div class="col-md-7 col-sm-12 paddingleft0">
                                                <input @input="lineItems.buildUpError = ''" id="offerPrice" v-model="lineItems.buildUp" class="form-control" type="text"/>
                                                <!-- <div class="requiredstar">*</div> -->
                                                <span class="help is-danger">{{lineItems.buildUpError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Price GST -->
                                    <div :key="'Price GST' + lineItemsIndex" class="col-md-2 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 paddingleft0">
                                                <div class="form-label pt-0">{{allLabelMessages.price_gst_label}}</div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 paddingleft0">
                                                <input @change="calculateQuot()" @input="lineItems.priceGSTError = ''" id="estimateOfExpense" v-model="lineItems.priceGST" class="form-control" type="number"/>
                                                <!-- <div class="requiredstar">*</div> -->
                                                <span class="help is-danger">{{lineItems.priceGSTError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Order No -->
                                    <div :key="'Order No' + lineItemsIndex" class="col-md-2 col-sm-12 forms-block">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 paddingleft0">
                                                <div class="form-label pt-0">{{allLabelMessages.order_no}}:</div>
                                            </div>
                                            <div class="col-md-8 col-sm-12 paddingleft0">
                                                <input @change="calculateQuot()" @input="lineItems.orderNoError = ''" id="orderNo" v-model="lineItems.orderNo" class="form-control" type="text"/>
                                                <span class="help is-danger">{{lineItems.orderNoError}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Price Inc -->
                                    <div :key="'Price Inc' + lineItemsIndex" class="col-md-1 col-sm-12 forms-block">
                                        <b-form-checkbox :id="'priceInc'+lineItemsIndex" v-model="lineItems.priceInc" name="priceInc" value="true"
                                            @change="calculateQuot()"
                                        >
                                            Included
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <!-- Line Items Block End -->
                            </template>
                            <template v-if="dealStatusIdValue != 8">
                                <!-- Trade Detail Block Start -->
                                <!--<div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block" >Trade Detail</div>
                                    </div>
                                </div>-->
                                <!-- Trade Detail Block End -->
                                <!-- Lease -->
                                <!-- <div class="col-md-4 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.lease" type="submit" :value="buttonNames.lease" class="btn btn-primary">
                                    </div>
                                </div> -->
                                <!-- Finance -->
                                <div class="col-md-6 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.finance" type="submit" :value="buttonNames.finance" class="btn btn-primary" v-on:click.prevent="changeDealType('finance')">
                                    </div>
                                </div>
                                <!-- Cash -->
                                <div class="col-md-6 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input id="addAssetBtn" :disabled="disableButtons.cash" type="submit" :value="buttonNames.cash" class="btn btn-primary"  v-on:click.prevent="changeDealType('cash')">
                                    </div>
                                </div>
                                <!-- Payment Type -->
                                <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue != 3">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.payment_type}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="paymentType"
                                            @input="paymentTypeError = ''"
                                            name="paymentType"
                                            id="paymentType"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="paymentTypeArr"
                                            :placeholder="allStaticMessages.payment_type_place_holder">
                                        </v-select>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{paymentTypeError}}</span>
                                    </div>
                                </div>
                                <!-- Order Type -->
                                <div class="col-md-4 col-sm-12 forms-block" v-if="dealStatusIdValue == 3">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.order_type_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="orderType"
                                            @input="orderTypeError = ''"
                                            name="orderType"
                                            id="orderType"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="orderTypeArr"
                                            :placeholder="allStaticMessages.order_type_place_holder">
                                        </v-select>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{orderTypeError}}</span>
                                    </div>
                                </div>
                                <!-- Downpayment On Order -->
                                <div class="col-md-9 col-sm-12 forms-block">
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.downpayment_on_order_label}}</div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input @input="downpaymentError = ''" id="downpayment" v-model="downpaymentAmt" class="form-control" type="text" maxlength="190"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{downpaymentError}}</span>
                                    </div>
                                    <div class="col-md-3 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="downpaymentPaymentType"
                                            name="selectDownPayment"
                                            id="selectDownPayment"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="paymentTypeArr"
                                            :placeholder="allStaticMessages.payment_type_place_holder">
                                        </v-select>
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input id="" class="form-control" type="text" maxlength="190" v-model="downpaymentDesc" />
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                </div>
                                <!-- Cash Back -->
                                <div class="col-md-9 col-sm-12 forms-block">
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.cash_back_label}}</div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input @input="cashBackError = ''" id="cashBack" v-model="cashBack" class="form-control" type="text" maxlength="190"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{cashBackError}}</span>
                                    </div>
                                    <div class="col-md-3 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="cashBackPaymentType"
                                            name="selectCashBack"
                                            id="selectCashBack"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="paymentTypeArr"
                                            :placeholder="allStaticMessages.payment_type_place_holder">
                                        </v-select>
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input id="" class="form-control" type="text" maxlength="190" v-model="cashBackDesc"/>
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                </div>
                                <!-- Due On Delivery -->
                                <div class="col-md-9 col-sm-12 forms-block">
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.due_on_delivery_label}}</div>
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input @input="dueOnDeliveryError = ''" id="dueOnDelivery" v-model="dueOnDelivery" class="form-control" type="text" maxlength="190"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{dueOnDeliveryError}}</span>
                                    </div>
                                    <div class="col-md-3 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="dueOnDeliveryPaymentType"
                                            name="selectDueOnDelivery"
                                            id="selectDueOnDelivery"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="paymentTypeArr"
                                            :placeholder="allStaticMessages.payment_type_place_holder">
                                        </v-select>
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                    <div class="col-md-2 col-sm-12 paddingleft0">
                                        <input id="" class="form-control" type="text" maxlength="190"  v-model="dueOnDeliveryDesc"/>
                                        <!--<div class="requiredstar">*</div>-->
                                    </div>
                                </div>
                            </template>
                            <!-- Floorplan interest calculator (months) Block Start -->
                            <!-- <template v-if="dealStatusIdValue != 3">
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block text">
                                            {{allLabelMessages.floorplan_interest_calculator_months}}
                                            <i class="fa fa-question-circle" id="floorplan_interest_calculator_months"></i>
                                        </div>
                                        <b-tooltip target="floorplan_interest_calculator_months" triggers="hover">
                                            1 month = 30 days<br/>
                                            2 months = 60 days<br/>
                                            3 months = 90 days<br/>
                                            4 months = 120 days<br/>
                                            5 months = 150 days<br/>
                                            6 months = 180 days<br/>
                                            7 months = 210 days<br/>
                                            8 months = 240 days<br/>
                                            9 months = 270 days<br/>
                                            10 months = 300 days<br/>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </template>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.interest_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group append="%">
                                        <b-form-input v-model="interest_rate" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.sub_total_ex_gst_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control dealinputs bg-color-bebebe">{{sub_total_ex_gst}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.stock_type}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select
                                        v-model="months_stock_type"
                                        name="monthsStockType"
                                        id="monthsStockType"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        @change="calculateInterestForMonths"
                                        :options="stockTypeArr">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.expected_age_months_at_time_of_delivery}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input id="expected_age_months_at_time_of_delivery" v-model="expected_age_months_at_time_of_delivery" class="form-control dealinputs" type="number" maxlength="17" @change="calculateInterestForMonths"/>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.customer_paying_within_interest_free_period}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select
                                        v-model="months_customer_paying_within_interest_free_period"
                                        @change="calculateInterestForMonths"
                                        name="monthsCustomerPayingWithinInterestFreePeriod"
                                        id="monthsCustomerPayingWithinInterestFreePeriod"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="customerPayingPeriodArr">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.days_of_interest_charged}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control dealinputs bg-color-bebebe">{{months_of_interest_charged}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.interest_charged}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control dealinputs bg-color-bebebe">{{months_interest_charged}}</span>
                                </div>
                            </div> -->
                            <!-- Floorplan interest calculator (months) Block End -->
                            <!-- Floorplan interest calculator (days) Block Start -->
                            <template v-if="dealStatusIdValue != 3">
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block text">
                                            {{allLabelMessages.floorplan_interest_calculator_days}}
                                            <i class="fa fa-question-circle" id="floorplan_interest_calculator_days"></i>
                                        </div>
                                        <b-tooltip target="floorplan_interest_calculator_days" triggers="hover">
                                            1 month = 30 days<br/>
                                            2 months = 60 days<br/>
                                            3 months = 90 days<br/>
                                            4 months = 120 days<br/>
                                            5 months = 150 days<br/>
                                            6 months = 180 days<br/>
                                            7 months = 210 days<br/>
                                            8 months = 240 days<br/>
                                            9 months = 270 days<br/>
                                            10 months = 300 days<br/>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </template>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.interest_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group append="%">
                                        <b-form-input v-model="interest_rate" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.dealer_net_invoice_price}}
                                        <i class="fa fa-question-circle" id="floorplan_interest_calculated_on_this_amount"></i>
                                    </div>
                                    <b-tooltip target="floorplan_interest_calculated_on_this_amount" triggers="hover">
                                        <span><small>{{allLabelMessages.floorplan_interest_calculated_on_this_amount}}</small></span>
                                    </b-tooltip>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group prepend="$">
                                        <b-form-input disabled :value="dealer_net_invoice_price|formatPrice"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.stock_type}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select
                                        v-model="days_stock_type"
                                        name="daysStockType"
                                        id="daysStockType"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        @change="calculateQuot"
                                        :options="stockTypeArr">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.days_of_interest_charged}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group>
                                        <b-form-input disabled v-model="days_of_interest_charged"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.expected_age_days_at_time_of_delivery}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group>
                                        <b-form-input id="expected_age_days_at_time_of_delivery" type="number" v-model="expected_age_days_at_time_of_delivery" @change="calculateQuot"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.interest_charged}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group prepend="$">
                                        <b-form-input disabled :value="days_interest_charged|formatPrice"></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.customer_paying_within_interest_free_period}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <v-select
                                        v-model="days_customer_paying_within_interest_free_period"
                                        @change="calculateQuot"
                                        name="daysCustomerPayingWithinInterestFreePeriod"
                                        id="daysCustomerPayingWithinInterestFreePeriod"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="customerPayingPeriodArr">
                                    </v-select>
                                </div>
                            </div>
                            <!-- Floorplan interest calculator (days) Block End -->
                            <!-- Cash Detail Block Start -->
                            <template v-if="dealStatusIdValue != 3">
                                <div class="col-md-12 col-sm-12 forms-block mt-3">
                                    <div class="col-12 col-sm-12 paddingleft0">
                                        <div class="book-deal-customer-block text" v-if="dealType == 'cash'">
                                            {{ allLabelMessages.cost_worksheet }}
                                            <span v-if="modelId || model_description" class="small">({{ modelId.text }}{{ model_description }})</span>
                                        </div>
                                        <div class="book-deal-customer-block text" v-else>Financial Details</div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="dealStatusIdValue == 3">
                                <div class="col-md-12 col-sm-12 forms-block">
                                    <div class="col-4 col-sm-4 paddingleft0">
                                        <div class="book-deal-customer-block text">Description</div>
                                    </div>
                                    <div class="col-4 col-sm-4 paddingleft0">
                                        <div class="book-deal-customer-block text">Price</div>
                                    </div>
                                    <div class="col-4 col-sm-4 paddingleft0">
                                        <div class="book-deal-customer-block text">Cost</div>
                                    </div>
                                </div>
                                <!-- physical Damage -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.physical_damage_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="physicalDamagePriceError = ''" id="physicalDamagePrice" v-model="physicalDamagePrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{physicalDamagePriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="physicalDamageCostError = ''" id="physicalDamageCost" v-model="physicalDamageCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{physicalDamageCostError}}</span>
                                    </div>
                                </div>
                                <!-- Non-Trucking Liability -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.non_trucking_liability_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="nonTruckingLiabilityPriceError = ''" id="nonTruckingLiabilityPrice" v-model="nonTruckingLiabilityPrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{nonTruckingLiabilityPriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="nonTruckingLiabilityCostError = ''" id="nonTruckingLiabilityCost" v-model="nonTruckingLiabilityCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{nonTruckingLiabilityCostError}}</span>
                                    </div>
                                </div>
                                <!-- Gap Insurance -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.gap_insurance_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="gapInsurancePriceError = ''" id="gapInsurancePrice" v-model="gapInsurancePrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{gapInsurancePriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="gapInsuranceCostError = ''" id="gapInsuranceCost" v-model="gapInsuranceCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{gapInsuranceCostError}}</span>
                                    </div>
                                </div>
                                <!-- Disability -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.disability_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="disabilityPriceError = ''" id="disabilityPrice" v-model="disabilityPrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{disabilityPriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="disabilityCostError = ''" id="disabilityCost" v-model="disabilityCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{disabilityCostError}}</span>
                                    </div>
                                </div>
                                <!-- Credit Life -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.credit_life_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="creditLifePriceError = ''" id="creditLifePrice" v-model="creditLifePrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{creditLifePriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="creditLifeCostError = ''" id="creditLifeCost" v-model="creditLifeCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{creditLifeCostError}}</span>
                                    </div>
                                </div>
                                <!-- CVRA {Commercial Vehicle Registration Act (California)} -->
                                <div class="col-md-12 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.cvra_label}}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="cvraPriceError = ''" id="cvraPrice" v-model="cvraPrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{cvraPriceError}}</span>
                                    </div>
                                    <div class="col-md-4 col-sm-4 paddingleft0">
                                        <input @change="calculateQuot()" @input="cvraCostError = ''" id="cvraCost" v-model="cvraCost" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <!--<div class="requiredstar">*</div>-->
                                        <span class="help is-danger">{{cvraCostError}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Annuale Percentage Rate -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.annuale_percentage_rate_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="annualePerRateError = ''" id="annualePerRate" v-model="annualePerRate" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{annualePerRateError}}</span>
                                </div>
                            </div>
                            <!-- Dealer Start -->
                            <div class="col-md-12 forms-block" v-show="dealType == 'finance'">
                                <div class="col-12 paddingleft0">
                                    <div class="book-deal-customer-block" v-b-toggle="'dealer-col-all-data'">Dealer</div>
                                </div>
                            </div>
                            <b-collapse id="dealer-col-all-data">
                                <div class="col-md-6 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.dealer_rate_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="dealerRateError = ''" id="dealerRate" v-model="dealerRate" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{dealerRateError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label">{{allLabelMessages.finance_income_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeIncomeError = ''" id="financeIncome" v-model="financeIncome" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{financeIncomeError}}</span>
                                    </div>
                                </div>
                            </b-collapse>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.term_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="financeTermError = ''" id="financeTerm" v-model="financeTerm" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{financeTermError}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.days_to_first_payment_label}}</div>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <input @input="daysToFirstPaymentError = ''" id="daysToFirstPayment" v-model="daysToFirstPayment" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{daysToFirstPaymentError}}</span>
                                </div>
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <date-picker @input="daysToFirstPaymentDateError = ''" v-model="daysToFirstPaymentDate" :config="options" class="form-control dealinputs" autocomplete="off" :placeholder="allStaticMessages.invoice_date_place_holder" name="daysToFirstPaymentDate">
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{daysToFirstPaymentDateError}}</span>
                                    </date-picker>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.payment_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="financePaymentError = ''" id="financePayment" v-model="financePayment" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{financePaymentError}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.sales_subtotal_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @input="salesSubtotalError = ''" id="salesSubtotal" v-model="salesSubtotal" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{salesSubtotalError}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col">
                                        <b-card :header="allLabelMessages.vehicle_pricing" header-tag="h5" border-variant="dark" header-border-variant="dark">
                                            <!-- Sales Price -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue != 3 && dealType != 'finance'">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.sales_price_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="salesPrice|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="salesPriceError = ''" id="salesPrice" v-model="salesPrice" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{salesPriceError}}</span>
                                                </div>
                                            </div>
                                            <!-- Dealer Net -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.dealer_net_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="dealer_net|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Factory Options -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.factory_options}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="factory_options_total|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Extra Conquest -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.extra_conquest_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="extra_conquest|formatPrice" v-model="extra_conquest" @change="calculateQuot()" @input="extra_conquest_error = ''" id="extra_conquest"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="extra_conquest_error = ''" id="extra_conquest" v-model="extra_conquest" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{extra_conquest_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Bonus -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.bonus_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="bonus_amount|formatPrice" v-model="bonus_amount" @change="calculateQuot()" @input="bonus_amount_error = ''" id="bonus_amount"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="bonus_amount_error = ''" id="bonus_amount" v-model="bonus_amount" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{bonus_amount_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Dealer Cost -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.dealer_cost}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="dealer_cost|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- PD Load -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.pd_load}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="pre_delivery|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Delivery Charges -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.delivery_charges}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="delivery_charges|formatPrice" v-model="delivery_charges" @change="calculateQuot()" id="delivery_charges"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="bonus_amount_error = ''" id="bonus_amount" v-model="bonus_amount" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                </div>
                                            </div>
                                            <!-- Gross Profit -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.gross_profit_label}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="gross_profit|formatPrice" v-model="gross_profit" @change="calculateQuot()" @input="gross_profit_error = ''" id="gross_profit"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="gross_profit_error = ''" id="gross_profit" v-model="gross_profit" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{gross_profit_error}}</span>
                                                </div>
                                                <!-- GP% -->
                                                <div class="col-md-2 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.gross_profit_percentage_label}}</div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 paddingleft0">
                                                    <b-input-group append="%">
                                                        <b-form-input :value="gross_profit_percentage" disabled :class="{ 'bg-danger border-danger text-white': gross_profit_percentage < 8 }"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Cab Chassis -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.cab_chassis_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="cab_chassis|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Build Up Total -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.build_up_total_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="build_up_total|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Sub total Ex GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.sub_total_ex_gst_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="sub_total_ex_gst|formatPrice" disabled class="bg-secondary border-secondary text-white"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.gst_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="total_gst|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- Sub-Total Incl. GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.sub_total_incl_gst}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="sub_total_incl_gst|formatPrice" disabled class="bg-secondary border-secondary text-white"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </b-card>
                                    </div>
                                    <div class="col">
                                        <b-card :header="allLabelMessages.government_charges_on_road_costs" header-tag="h5" class="mb-1" border-variant="dark" header-border-variant="dark">
                                            <!-- Stamp Duty Type -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.stamp_duty_type_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <v-select
                                                        v-model="stampDutyType"
                                                        @input="updateStampDutyType"
                                                        name="stampDutyType"
                                                        id="stampDutyType"
                                                        label="text"
                                                        :clearable="false"
                                                        :searchable="false"
                                                        :filterable="false"
                                                        :options="stampDutyTypeArr"
                                                        :placeholder="allStaticMessages.stamp_duty_type_place_holder">
                                                    </v-select>
                                                </div>
                                            </div>
                                            <!-- Stamp Duty -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.stamp_duty_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="stamp_duty|formatPrice" @change="calculateQuot()" v-model="stamp_duty" @input="stamp_duty_error = ''" id="stamp_duty"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="stamp_duty_error = ''" id="stamp_duty" v-model="stamp_duty" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{stamp_duty_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Registration -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.registration}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="registration|formatPrice" v-model="registration" @change="calculateQuot()" @input="registration_error = ''" id="registration"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="registration_error = ''" id="registration" v-model="registration" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{registration_error}}</span>
                                                </div>
                                            </div>
                                            <!-- CTP Insurance -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0" style="white-space:nowrap;">
                                                    <b-form-select v-model="ctp_label" :options="ctp_label_options" class="mr-1"></b-form-select>:
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="ctp|formatPrice" v-model="ctp" @change="calculateQuot()" @input="ctp_error = ''" id="ctp"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="ctp_error = ''" id="ctp" v-model="ctp" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{ctp_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Total On Road Price -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.total_on_road_price}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="total_on_road_price|formatPrice" disabled class="bg-secondary border-secondary text-white"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </b-card>
                                        <b-card :header="allLabelMessages.trade_in" header-tag="h5" class="mb-1" border-variant="dark" header-border-variant="dark">
                                            <!-- Trade Detail -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.trade_detail_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-form-input v-model="trade_detail" @change="calculateQuot()" id="trade_detail"></b-form-input>
                                                    <!-- <input @change="calculateQuot()" id="trade_detail" v-model="trade_detail" class="form-control dealinputs text-align-left" type="text" maxlength="190"/> -->
                                                </div>
                                            </div>
                                            <!-- Trade Value inc GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.trade_value_inc_gst_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="trade_value_inc_gst|formatPrice" v-model="trade_value_inc_gst" @change="calculateQuot()" @input="trade_value_inc_gst_error = ''" id="trade_value_inc_gst"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="trade_value_inc_gst_error = ''" id="trade_value_inc_gst" v-model="trade_value_inc_gst" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{trade_value_inc_gst_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Over allowance inc GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.over_allowance_inc_gst_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="over_allowance_inc_gst|formatPrice" v-model="over_allowance_inc_gst" @change="calculateQuot()" @input="over_allowance_inc_gst_error = ''" id="over_allowance_inc_gst"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="over_allowance_inc_gst_error = ''" id="over_allowance_inc_gst" v-model="over_allowance_inc_gst" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{over_allowance_inc_gst_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Trade Allowance inc GST -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.trade_allowance_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="tradeAllownce|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="tradeAllownceError = ''" id="tradeAllownce" v-model="tradeAllownce" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{tradeAllownceError}}</span>
                                                </div>
                                            </div>
                                            <!-- Finance Payout -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.finance_payout}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="trade_payout|formatPrice" v-model="trade_payout" @change="calculateQuot()" @input="trade_payout_error = ''" id="trade_payout"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="trade_payout_error = ''" id="trade_payout" v-model="trade_payout" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{trade_payout_error}}</span>
                                                </div>
                                            </div>
                                            <!-- Trade Equity -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.trade_equity_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="trade_equity|formatPrice" disabled></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </b-card>
                                        <b-card border-variant="dark" header-border-variant="dark" header-tag="header">
                                            <template #header>
                                                <h5>{{ allLabelMessages.deposit_label }}</h5>
                                                <h6 class="text-muted" style="font-size: 15px">{{ allLabelMessages.nominated_deposit_amount_by_customer_to_secure_stock_a_deposit_of_xx_is_required }}</h6>
                                            </template>
                                            <!-- Deposit -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue != 3">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.deposit_label}}:</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="downpaymentAmt|formatPrice" v-model="downpaymentAmt" @change="calculateQuot()" @input="downpaymentAmtError = ''" id="downpaymentAmt"></b-form-input>
                                                    </b-input-group>
                                                    <!-- <input @change="calculateQuot()" @input="downpaymentAmtError = ''" id="downpaymentAmt" v-model="downpaymentAmt" class="form-control dealinputs" type="text" maxlength="17"/> -->
                                                    <span class="help is-danger">{{downpaymentAmtError}}</span>
                                                </div>
                                            </div>
                                            <!-- Change Over Amount -->
                                            <div class="forms-block min-margin" v-if="dealStatusIdValue == 8">
                                                <div class="col-md-4 col-sm-12 paddingleft0">
                                                    <div class="form-label deallabel">{{allLabelMessages.change_over_label}}</div>
                                                </div>
                                                <div class="col-md-8 col-sm-12 paddingleft0">
                                                    <b-input-group prepend="$">
                                                        <b-form-input :value="change_over|formatPrice" disabled class="bg-secondary border-secondary text-white"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.overallowance_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group prepend="$">
                                        <b-form-input :value="overallowance|formatPrice" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            
                            <!-- <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.std_conquest_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control dealinputs bg-color-bebebe">{{conquest}}</span>
                                </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_cq_approved_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <span class="form-control dealinputs bg-color-bebebe">{{total_cq_approved}}</span>
                                </div>
                            </div> -->

                            <!-- <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue == 8">
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue == 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.rego_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="rego_error = ''" id="rego" v-model="rego" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{rego_error}}</span>
                                </div>
                            </div> -->

                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.sub_total_ex_gst_plus_calculated_interest}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group prepend="$">
                                        <b-form-input :value="sub_total_ex_gst_plus_calculated_interest|formatPrice" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 8 && dealStatusIdValue != 3">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.cylinder_type_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group>
                                        <b-form-input :value="cylinders" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>

                            <!-- Total Fees -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel" v-if="dealType == 'finance'">{{allLabelMessages.fees_subtotal_label}}</div>
                                    <div class="form-label deallabel" v-else>{{allLabelMessages.total_fees_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalFeesError = ''" id="totalFees" v-model="totalFees" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{totalFeesError}}</span>
                                </div>
                            </div>
                            
                            <!-- Total Sales Tax -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="(dealStatusIdValue != 3 && dealStatusIdValue != 8) || dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_sales_tax_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalSalesTaxError = ''" id="totalSalesTax" v-model="totalSalesTax" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{totalSalesTaxError}}</span>
                                </div>
                            </div>

                            <!-- Total Financed -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_financed_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalFinancedError = ''" id="totalFinanced" v-model="totalFinanced" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{totalFinancedError}}</span>
                                </div>
                            </div>

                            <!-- Finance Charge -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.finance_charge_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="financeChargeError = ''" id="financeCharge" v-model="financeCharge" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{financeChargeError}}</span>
                                </div>
                            </div>
                                
                            <!-- Total -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel" v-if="dealType == 'finance'">{{allLabelMessages.total_of_payments_label}}</div>
                                    <div class="form-label deallabel" v-else>{{allLabelMessages.total_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalError = ''" id="total" v-model="total" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{totalError}}</span>
                                </div>
                            </div>
                                
                            <!-- Warranties -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 3 && dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel" v-if="dealType == 'finance'">{{allLabelMessages.warranty_not_taxable_label}}</div>
                                    <div class="form-label deallabel" v-else>{{allLabelMessages.warranties_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="warrantiesError = ''" id="warranties" v-model="warranties" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{warrantiesError}}</span>
                                </div>
                            </div>
                                
                            <!-- Insurance -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="(dealStatusIdValue != 3 && dealStatusIdValue != 8) || dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.insurance_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="insuranceError = ''" id="insurance" v-model="insurance" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{insuranceError}}</span>
                                </div>
                            </div>
                                
                            <!-- Total + Warranties -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="((dealStatusIdValue != 3 && dealStatusIdValue != 8) && dealType != 'finance')">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.total_warranties_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="totalWarrantiesError = ''" id="totalWarranties" v-model="totalWarranties" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{totalWarrantiesError}}</span>
                                </div>
                            </div>

                            <!-- Finance Charge -->
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealType == 'finance'">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.deferred_price_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <input @change="calculateQuot()" @input="deferredPriceError = ''" id="deferredPrice" v-model="deferredPrice" class="form-control dealinputs" type="text" maxlength="17"/>
                                    <span class="help is-danger">{{deferredPriceError}}</span>
                                </div>
                            </div>
                                
                            <div class="col-md-6 col-sm-12 forms-block min-margin" v-if="dealStatusIdValue != 8">
                                <div class="col-md-4 col-sm-12 paddingleft0">
                                    <div class="form-label deallabel">{{allLabelMessages.less_trade_equity_label}}</div>
                                </div>
                                <div class="col-md-8 col-sm-12 paddingleft0">
                                    <b-input-group prepend="$">
                                        <b-form-input :value="less_trade_equity|formatPrice" disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>

                            <template v-if="dealType == 'finance'">
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.select_finance_company_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="selectFinanceCompany"
                                            @change="onChangeFinanceCompany"
                                            @search="onSearchFinanceCompany" 
                                            name="selectFinanceCompany"
                                            id="selectFinanceCompany"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="selectFinanceCompanyArr"
                                            :placeholder="allStaticMessages.finance_group_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isFinanceCompanyRecordNotFound">
                                                    {{allStaticMessages.finance_group_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.finance_company_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeCompanyError = ''" id="financeCompany" v-model="financeCompany" class="form-control" type="text" maxlength="190"/>
                                        <span class="help is-danger">{{financeCompanyError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.address_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeCompanyAddressError = ''" id="financeCompanyAddress" v-model="financeCompanyAddress" class="form-control" type="text" maxlength="190"/>
                                        <span class="help is-danger">{{financeCompanyAddressError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.city_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeCompanyCityError = ''" id="financeCompanyCity" v-model="financeCompanyCity" class="form-control" type="text"/>
                                        <span class="help is-danger">{{financeCompanyCityError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.state_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="financeCompanyState"
                                            @search="onSearchFinanceCompanyState" 
                                            @change="calculateQuot"
                                            name="financeCompanyState"
                                            id="financeCompanyState"
                                            label="text"
                                            :clearable="true"
                                            :searchable="true"
                                            :filterable="false"
                                            :options="financeCompanyStateArr"
                                            :placeholder="allStaticMessages.state_place_holder">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                <template v-if="!isFinanceCompanyStateRecordNotFound">
                                                    {{allStaticMessages.state_search}}
                                                </template>
                                                <template v-else>
                                                    {{allStaticMessages.search_record_not_found}}
                                                </template>
                                            </span>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.zip_code_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeCompanyZipError = ''" id="financeCompanyZip" v-model="financeCompanyZip" class="form-control" type="text"/>
                                        <span class="help is-danger">{{financeCompanyZipError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.comment_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @input="financeCompanyCommentError = ''" id="financeCompanyComment" v-model="financeCompanyComment" class="form-control" type="text"/>
                                        <span class="help is-danger">{{financeCompanyCommentError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.send_docs_to_diff_address_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @change="calculateQuot()" @input="financeCompanyAltError = ''" id="financeCompanyAlt" v-model="financeCompanyAlt" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{financeCompanyAltError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.address_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @change="calculateQuot()" @input="financeCompanyAltAddressError = ''" id="financeCompanyAltAddress" v-model="financeCompanyAltAddress" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{financeCompanyAltAddressError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.city_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @change="calculateQuot()" @input="financeCompanyAltCityError = ''" id="financeCompanyAltCity" v-model="financeCompanyAltCity" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{financeCompanyAltCityError}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.state_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <v-select
                                            v-model="financeCompanyAltState"
                                            @change="calculateQuot"
                                            name="financeCompanyAltState"
                                            id="financeCompanyAltState"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="financeCompanyAltStateArr"
                                            :placeholder="allStaticMessages.stamp_duty_type_place_holder">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 forms-block min-margin">
                                    <div class="col-md-4 col-sm-12 paddingleft0">
                                        <div class="form-label deallabel">{{allLabelMessages.zip_code_label}}</div>
                                    </div>
                                    <div class="col-md-8 col-sm-12 paddingleft0">
                                        <input @change="calculateQuot()" @input="financeCompanyAltZipError = ''" id="financeCompanyAltZip" v-model="financeCompanyAltZip" class="form-control dealinputs" type="text" maxlength="17"/>
                                        <span class="help is-danger">{{financeCompanyAltZipError}}</span>
                                    </div>
                                </div>
                            </template>
                            <!-- Cash Detail Block End -->
                            <div class="clearfix"></div>
                            <div class="ml-2">
                                <!-- <input id="bookDeal" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addModel()"> -->
                                <!-- <input id="addAndContinueModelBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addModel(flag='Save and Continue')"> -->
                                <!-- <input id="cancelDeal" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack"> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Deals Modal -->
                <div class="modal fade" id="dealModal" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="dealModalLabel">Select Deal Or Create New</h4>
                                <a href="javascript:void(0)" class="btn btn-primary waves-effect pull-right ml-2" title='Add Deal' v-on:click.prevent="saveBookDeal()">
                                    <i class="fa fa-plus"></i>
                                </a>
                            </div>
                            <form class="form-inline" id="dealModalForm" enctype="multipart/form-data">
                                <div class="col-md-12 modal-body" id="modal-checkbox">
                                    <!-- Content -->
                                    <template v-for="(dealData, indexOpp) in assetInfo">
                                        <div :key="indexOpp" class="col-md-12 col-sm-12 paddingleft0 custom-checkbox-v">
                                            <label class="container">
                                                <!-- {{dealData}} -->
                                                <input :value="dealData" class="form-control" type="checkbox" @change="getdeal(dealData.id)" /> 
                                                <p class="modal-check-lbl">{{dealData['deal_no']}}</p>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- Opportunity Modal -->
                <div class="modal fade" id="opportunityModal" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="opportunityModalLabel">Choose Opportunity</h4>
                                <a href="javascript:void(0)" class="btn btn-primary waves-effect pull-right ml-2" title='Add Opportunity' v-on:click.prevent="addOpportunity()">
                                    <i class="fa fa-plus"></i>
                                </a>
                            </div>
                            <form class="form-inline" id="opportunityModalForm" enctype="multipart/form-data">
                                <div class="col-md-12 modal-body" id="modal-checkbox">
                                    <!-- Content -->
                                    <template v-for="(opportunity, indexOpp) in opportunityInfo">
                                        <div :key="indexOpp" class="col-md-12 col-sm-12 paddingleft0 custom-checkbox-v">
                                            <label class="container">
                                                {{opportunityInfo}}
                                                <input :value="opportunity" class="form-control" type="checkbox" @change="getOpportunityInventory(opportunity['id'])" /> 
                                                <p class="modal-check-lbl">{{opportunity['reference_no']}}</p>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </template>
            <template id="buildUp" v-else-if="type == 'buildUp'">
                <build-up @clickOnNext="next" :data="quotationData" @clickOnBack="back"></build-up>
            </template>
            <template id="conquest" v-else-if="type == 'conquest'">
                <conquest @clickOnNext="next" :data="quotationData" @clickOnBack="back"></conquest>
            </template>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import VehicleWorkSheet from "@/components/AssetMaster/BookDeal/VehicleWorkSheet";
    import tinymce from 'vue-tinymce-editor';
    import { BCollapse } from 'bootstrap-vue';
    import BuildUp from '@/components/AssetMaster/BookDeal/BuildUp';
    import Conquest from '@/components/AssetMaster/BookDeal/Conquest';
    // Vue.component("app-footer", Footer);

    export default {
        name: 'AssetSpecSheet',
        components: {
            datePicker,
            'no-access': NoAccessPage,
            'vehicle-work-sheet': VehicleWorkSheet,
            'tinymce': tinymce,
            'app-footer' : Footer,
            'b-collapse' : BCollapse,
            'build-up': BuildUp,
            'conquest': Conquest
        },
        mixins: [commonMixins],
        data() {
            return {
                dealType : 'cash',
                selectedRows: [],
                selectAll: false,
                add_to_forecast : true,
                dealNo: "",
                dealNoError: "",
                invoiceNo: "",
                invoiceNoError: "",
                invoiceHash: "",
                invoiceHashError: "",
                excedeIdHash: "",
                excedeIdHashError: "",
                companyId: "",
                companyArr: [],
                companyError: "",
                isCompanyRecordNotFound: false,
                dealStatusId: {"value":8, "text":"Quotation"},
                dealStatusIdValue: 8,
                dealStatusArr: [],
                statusError: "",
                isDealStatusRecordNotFound: false,
                contractNumber: "",
                contractNumberError: "",
                contractDate: "",
                contractDateError: "",
                invoiceDate: "",
                invoiceDateError: "",
                options: {
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                rosNumber: "",
                rosNumberError: "",
                salePersonId: "",
                salePersonArr: [],
                salePersonError: "",
                isSalePersonRecordNotFound: false,
                cusPhoneNumber: "",
                cusPhoneNumberError: "",
                customerId: "",
                customerArr: [],
                customerError: "",
                isCustomerRecordNotFound: false,
                individual: false,
                individualValue: "",
                cusFax: "",
                cusFaxError: "",
                cusAddress: "",
                cusAddressError: "",
                cusCell: "",
                cusCellError: "",
                cusAddress2: "",
                cusAddress2Error: "",
                cusSIN: "",
                cusSINError: "",
                customerCityId: "",
                customerCityArr: [],
                customerCityError: "",
                isCustomerCityRecordNotFound: false,
                cusCity2: "",
                customerStateId: "",
                cusTaxExemptionNumber: "",
                cusTaxExemptionNumberError: "",
                cusAltCityName: "",
                cusAltCityNameError: "",
                cusAddressType: "main address",
                cusAddressTypeOptions: [
                    {text: 'Custom', value: 'custom address'},
                    {text: 'Delivery Address', value: 'delivery address'},
                    {text: 'Main Address', value: 'main address'},
                ],
                customerCountyId: "",
                customerCountyArr: [],
                customerCountyError: "",
                isCustomerCountyRecordNotFound: false,
                cusTaxRate: "",
                cusTaxRateError: "",
                cusSalesTax: true,
                cusStateTaxRate: "",
                cusStateTaxRateError: "",
                cusCountyTaxRate: "",
                cusCountyTaxRateError: "",
                cusAltCountyName: "",
                cusAltCountyNameError: "",
                cusCityTaxRate: "",
                cusCityTaxRateError: "",
                cusOtherTaxRate: "",
                cusOtherTaxRateError: "",
                cusOther2TaxRate: "",
                cusOther2TaxRateError: "",
                customerEmail: "",
                customerEmailError: "",
                cusCountySurtax: "",
                cusCountySurtaxError: "",
                cusCountyCode: "",
                cusCountyCodeError: "",
                // Buyer
                buyerPhoneNumber: "",
                buyerPhoneNumberError: "",
                buyerNumber: "",
                buyerNumberError: "",
                buyerId: "",
                buyerArr: [],
                buyerError: "",
                isBuyerRecordNotFound: false,
                buyerIndividual: false,
                buyerFax: "",
                buyerFaxError: "",
                buyerAddress: "",
                buyerAddressError: "",
                buyerCell: "",
                buyerCellError: "",
                buyerAddress2: "",
                buyerAddress2Error: "",
                buyerSIN: "",
                buyerSINError: "",
                buyerCityId: "",
                buyerCityArr: [],
                buyerCityError: "",
                isBuyerCityRecordNotFound: false,
                buyerCity2: "",
                buyerCity3: "",
                buyerTaxExemptionNumber: "",
                buyerTaxExemptionNumberError: "",
                buyerAltCityName: "",
                buyerAltCityNameError: "",
                buyerAddressType: "main address",
                buyerAddressTypeOptions: [
                    {text: 'Custom', value: 'custom address'},
                    {text: 'Delivery Address', value: 'delivery address'},
                    {text: 'Main Address', value: 'main address'},
                ],
                buyerCountyId: "",
                buyerCountyArr: [],
                buyerCountyError: "",
                isBuyerCountyRecordNotFound: false,
                buyerTaxRate: "",
                buyerTaxRateError: "",
                buyerSalesTax: true,
                buyerStateTaxRate: "",
                buyerStateTaxRateError: "",
                buyerCountyTaxRate: "",
                buyerCountyTaxRateError: "",
                buyerAltCountyName: "",
                buyerAltCountyNameError: "",
                buyerCityTaxRate: "",
                buyerCityTaxRateError: "",
                buyerOtherTaxRate: "",
                buyerOtherTaxRateError: "",
                buyerOther2TaxRate: "",
                buyerOther2TaxRateError: "",
                buyerEmail: "",
                buyerEmailError: "",
                buyerCountySurtax: "",
                buyerCountySurtaxError: "",
                buyerCountyCode: "",
                buyerCountyCodeError: "",
                // Deliver To
                deliverToPhoneNumber: "",
                deliverToPhoneNumberError: "",
                deliverToId: "",
                deliverToArr: [],
                deliverToError: "",
                isDeliverToRecordNotFound: false,
                deliverToIndividual: false,
                deliverToFax: "",
                deliverToFaxError: "",
                deliverToAddress: "",
                deliverToAddressError: "",
                deliverToCell: "",
                deliverToCellError: "",
                deliverToAddress2: "",
                deliverToAddress2Error: "",
                deliverToSIN: "",
                deliverToSINError: "",
                deliverToCityId: "",
                deliverToCityArr: [],
                deliverToCityError: "",
                isDeliverToCityRecordNotFound: false,
                deliverToCity2: "",
                deliverToCity3: "",
                deliverToTaxExemptionNumber: "",
                deliverToTaxExemptionNumberError: "",
                deliverToAltCityName: "",
                deliverToAltCityNameError: "",
                deliverToAddressType: "main address",
                deliverToAddressTypeOptions: [
                    {text: 'Custom', value: 'custom address'},
                    {text: 'Delivery Address', value: 'delivery address'},
                    {text: 'Main Address', value: 'main address'},
                ],
                deliverToCountyId: "",
                deliverToCountyArr: [],
                deliverToCountyError: "",
                isDeliverToCountyRecordNotFound: false,
                deliverToTaxRate: "",
                deliverToTaxRateError: "",
                deliverToSalesTax: true,
                deliverToStateTaxRate: "",
                deliverToStateTaxRateError: "",
                deliverToCountyTaxRate: "",
                deliverToCountyTaxRateError: "",
                deliverToAltCountyName: "",
                deliverToAltCountyNameError: "",
                deliverToCityTaxRate: "",
                deliverToCityTaxRateError: "",
                deliverToOtherTaxRate: "",
                deliverToOtherTaxRateError: "",
                deliverToOther2TaxRate: "",
                deliverToOther2TaxRateError: "",
                deliverToEmail: "",
                deliverToEmailError: "",
                deliverToCountySurtax: "",
                deliverToCountySurtaxError: "",
                deliverToCountyCode: "",
                deliverToCountyCodeError: "",

                internalComments: "",
                internalCommentsError: "",
                comments: "",
                commentsError: "",
                showOnBuyersOrder: false,
                comments2: "",
                comments2Error: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel',
                    copyVehicle: 'Copy Vehicle',
                    searchStockVehicles: 'Search Stock Vehicles',
                    updateVinOrStock: 'Update Vin#/Stock#',
                    lease: 'Lease',
                    finance: 'Finance',
                    cash: 'Cash',
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false,
                    copyVehicle: false,
                    searchStockVehicles: false,
                    updateVinOrStock: false,
                    lease: false,
                    finance: false,
                    cash: false,
                },
                paymentType: "",
                paymentTypeArr: [],
                paymentTypeError: "",
                orderType: "",
                orderTypeArr: [],
                orderTypeError: "",
                downpayment: "",
                downpaymentError: "",
                downpaymentPaymentType: "",
                downpaymentDesc : "",
                dueOnDelivery: "",
                dueOnDeliveryError: "",
                dueOnDeliveryPaymentType : "",
                dueOnDeliveryDesc : "",
                cashBack: "",
                cashBackError: "",
                cashBackPaymentType : "",
                cashBackDesc : "",
                // Cash Details
                salesPrice: "",
                salesPriceError: "",
                annualePerRate: 0,
                annualePerRateError: "",
                financeTerm: 0,
                financeTermError: "",
                daysToFirstPayment: 0,
                daysToFirstPaymentError: "",
                daysToFirstPaymentDate: "",
                daysToFirstPaymentDateError: "",
                financeIncome: 0,
                financeIncomeError: "",
                financePayment: 0,
                financePaymentError: "",
                salesSubtotal: 0,
                salesSubtotalError: "",
                feesSubtotal: 0,
                feesSubtotalError: "",
                warrantyNotTaxable: 0,
                warrantyNotTaxableError: "",
                dealerRate: 0,
                dealerRateError: "",
                physicalDamagePrice: 0.00,
                physicalDamagePriceError: "",
                physicalDamageCost: 0.00,
                physicalDamageCostError: "",
                nonTruckingLiabilityPrice: 0.00,
                nonTruckingLiabilityPriceError: "",
                nonTruckingLiabilityCost: 0.00,
                nonTruckingLiabilityCostError: "",
                gapInsurancePrice: 0.00,
                gapInsurancePriceError: "",
                gapInsuranceCost: 0.00,
                gapInsuranceCostError: "",
                disabilityPrice: 0.00,
                disabilityPriceError: "",
                disabilityCost: 0.00,
                disabilityCostError: "",
                creditLifePrice: 0.00,
                creditLifePriceError: "",
                creditLifeCost: 0.00,
                creditLifeCostError: "",
                cvraPrice: 0.00,
                cvraPriceError: "",
                cvraCost: 0.00,
                cvraCostError: "",
                downpaymentAmt: 0.00,
                downpaymentAmtError: "",
                tradeAllownce: "",
                tradeAllownceError: "",
                totalFees: 0.00,
                totalFeesError: "",
                totalSalesTax: 0.00,
                totalSalesTaxError: "",
                totalFinanced: 0.00,
                totalFinancedError: "",
                financeCharge: 0.00,
                financeChargeError: "",
                financeCompany: "",
                financeCompanyError: "",
                financeCompanyAddress: "",
                financeCompanyAddressError: "",
                financeCompanyCity: "",
                financeCompanyCityError: "",
                financeCompanyZip: "",
                financeCompanyZipError: "",
                financeCompanyComment: "",
                financeCompanyCommentError: "",
                financeCompanyAlt: "",
                financeCompanyAltError: "",
                financeCompanyAltAddress: "",
                financeCompanyAltAddressError: "",
                financeCompanyAltCity: "",
                financeCompanyAltCityError: "",
                financeCompanyAltZip: "",
                financeCompanyAltZipError: "",
                financeCompanyAltComment: "",
                financeCompanyAltCommentError: "",
                deferredPrice: 0.00,
                deferredPriceError: "",
                totalOfPayments: 0.00,
                totalOfPaymentsError: "",
                total: "",
                totalError: "",
                warranties: "",
                warrantiesError: "",
                insurance: "",
                insuranceError: "",
                totalWarranties: "",
                totalWarrantiesError: "",

                assetInfo: [],
                opportunityInfo : [],
                opportunityInventoryArr: [],
                assetId: 0,
                selectedOpportunityId: 0,
                editAssetId: 0,
                isShowVehicleWorkSheet: false,
                dealId: 0,
                dealData: "",
                makeArr : [],
                makeId : {text: "Fuso", value: '1'},
                isMakeRecordNotFound: false,
                modelArr : [],
                modelId : 0,
                isModelRecordNotFound: false,
                conditionArr : [],
                dataAccessType : "used",
                conditionId : {text: "New", value: '1'},
                rrp:0,
                gst:0,
                list_price:0,
                dealer_discount:0,
                dealer_net:0,
                conquest:0,
                fituptotal:0,
                pre_delivery:0,
                bonus_amount:0.00,
                bonus_amount_error:"",
                extra_conquest:0.00,
                extra_conquest_error:"",
                total_cq_approved : 0.00,
                sub_total1 : 0.00,
                overallowance : 0.00,
                gross_profit : 0.00,
                gross_profit_error:"",
                gross_profit_percentage : 0.00,
                cab_chassis : 0.00,
                build_up_total : 0.00,
                sub_total_ex_gst : 0.00,
                total_gst : 0.00,
                sub_total_incl_gst : 0.00,
                stampDutyPercentage : 0.00,
                cylinders : "",
                stampDutyType : {text: 'Exempt', value: 'exempt'},
                stampDutyTypeArr : [
                    {text: 'New/Demo', value: 'new/demo'},
                    {text: 'Exempt', value: 'exempt'},
                ],
                selectFinanceCompany : "",
                selectFinanceCompanyArr : [],
                isFinanceCompanyRecordNotFound : false,
                financeCompanyState : "",
                financeCompanyStateArr : [],
                isFinanceCompanyStateRecordNotFound : false,
                financeCompanyAltState : "",
                financeCompanyAltStateArr : [],
                rego : 0.00,
                rego_error:"",
                ctp : 0.00,
                ctp_error:"",
                stamp_duty : 0.00,
                stamp_duty_error: "",
                registration : 0.00,
                registration_error:"",
                trade_detail : null,
                trade_value_inc_gst : 0.00,
                trade_value_inc_gst_error:"",
                over_allowance_inc_gst : 0.00,
                over_allowance_inc_gst_error:"",
                trade_payout : 0.00,
                trade_payout_error:"",
                trade_equity : 0.00,
                change_over : 0.00,
                isSupplierRecordNotFound: false,
                lineItemsArr: [],
                supplierArr: [],
                lineItemsObject: {
                    supplier: '',
                    supplierError: '',
                    buildUp: '',
                    buildUpError: '',
                    priceGST: 0.00,
                    priceGSTError: '',
                    priceInc: true,
                    orderNo: ''
                },
                plusCountSupplier: 49,
                firstAdd: false,
                servicePlan: "",
                vehicleWarranty: "",
                roadsideAssistance : "",
                specificationUrl : "",
                specificationUrlError : "",
                specPages : "",
                specPagesError : "",
                isAddFactory: false,
                factoryItemsArr: [],
                factoryItemsObject: {
                    factoryOption: '',
                    factoryOptionError: '',
                    factoryPriceGst: 0.00,
                    factoryPriceGstError: '',
                    factoryPriceInc: true,
                    factoryOrderNo: ''
                },
                plusCountFactory: 5,
                factory_options_total : 0.00,
                locationArr: [],
                isLocationRecordNotFound: false,
                locationId: "",
                locationError: "",
                makeError: "",
                modelError: "",
                stateArr: [],
                isStateRecordNotFound: false,
                pricebookArr: [],
                isPricebookRecordNotFound: false,
                pricebookId: "",
                pricebookError: "",
                businessName: "",
                businessNameError: "",
                isChangedPricebook: true,
                uploadEliteLogoOnPdf: true,
                stateCountryId: null,
                type: 'quotation',
                quotationData: null,
                salesPersonContactNo: null,
                isNextButton: false,
                dealNumber: null,
                isQuotationUpdate: false,
                businessNameArr: [],
                isBusinessRecordNotFound: false,
                formData: null,
                isEditDeal: false,
                interest_rate: 7,
                days_stock_type: {text: 'In Stock (45 days interest-free)', value: 'in_stock'},
                expected_age_days_at_time_of_delivery: 0,
                days_customer_paying_within_interest_free_period: {text: 'No', value: 'no'},
                days_of_interest_charged: 0,
                days_interest_charged: 0.00,
                stockTypeArr: [
                    {text: 'In Stock (45 days interest-free)', value: 'in_stock'},
                    {text: 'Ordered Stock (90 days interest-free)', value: 'ordered_stock'}
                ],
                customerPayingPeriodArr: [
                    {text: 'Yes', value: 'yes'},
                    {text: 'No', value: 'no'}
                ],
                months_stock_type: {text: 'In Stock (45 days interest-free)', value: 'in_stock'},
                expected_age_months_at_time_of_delivery: 0,
                months_customer_paying_within_interest_free_period: {text: 'No', value: 'no'},
                months_of_interest_charged: 0,
                months_interest_charged: 0.00,
                sub_total_ex_gst_plus_calculated_interest: 0,
                stock: null,
                delivery_date: null,
                vin_no: null,
                comm_no: null,
                dealer_date: null,
                quantity: null,
                cq_approval: null,
                cq_notes: null,
                bonus_notes: null,
                factory_promo: null,
                rego_number: null,
                rego_date: null,
                cq_submission_date: null,
                rda_approval_number: null,
                cq_entered_in_dfe: false,
                customer_details_entered_in_dfe: false,
                rda_date: null,
                bodyType: {
                    'flat_top': false,
                    'fridge_body': false,
                    'tilt_tray': false,
                    'speciality_body': false,
                    'drop_side_tray': false,
                    'tipper': false,
                    'crane_truck': false,
                    'emergency_services': false,
                    'van_body': false,
                    'concrete_agitator': false,
                    'vacuum_body': false,
                    'prime_mover': false,
                    'curtainsider': false,
                    'concrete_pump': false,
                    'refuse_truck': false,
                    'other': false
                },
                conquest_obj: {
                    'business_retail': false,
                    'govt_state': false,
                    'rental': false,
                    'med_sml_fleet': false,
                    'not_for_profit': false,
                    'demo': false,
                    'govt_local': false,
                    'large_fleet': false,
                    'single_operator': false,
                    'others': false
                },
                rda_stamp: null,
                cus_address2: null,
                abn: null,
                less_trade_equity: 0.00,
                total_on_road_price: 0.00,
                dealer_cost: 0.00,
                delivery_charges: 0.00,
                ctp_label: 'CTP Insurance',
                ctp_label_options: [
                    {value: 'CTP Insurance', html: '<b>CTP Insurance</b>'},
                    {value: 'Green Slip', html: '<b>Green Slip</b>'},
                    {value: 'TAC Charge', html: '<b>TAC Charge</b>'}
                ],
                dealer_net_invoice_price: 0.00,
                model_description: null,
                seriesArr: [
                    {text: 'Canter', value: 'Canter'},
                    {text: 'Fighter', value: 'Fighter'},
                    {text: 'Rosa', value: 'Rosa'},
                    {text: 'Shogun', value: 'Shogun'},
                    {text: 'Truck', value: 'Truck'}
                ],
                series: null,
                seriesError: null
            }
        },
        watch: {
            dealStatusId: function (dealStatusId) {
                // if (typeof (dealStatusId) != 'undefined' && dealStatusId.value != "" && dealStatusId.value != 0) {
                self.dealStatusIdValue = dealStatusId.value;
                // }
                if(self.dealStatusIdValue != 8) {
                    for (let i = (self.lineItemsArr.length - 1); i >= 0; i--) {
                        self.removeSupplier(i);
                    }
                    self.plusCountSupplier = 49;
                    if(self.lineItemsArr.length <= 0){
                        self.addNewRow();
                    }
                }
            },
            selectFinanceCompany : function(selectFinanceCompany) {
                self.loadAllData(['FinanceGroup'], false, self.loadFinanceCompanyDataCallback, "undefined", '');
            },
            financeCompanyState : function(financeCompanyState) {
                self.loadAllData(['States'], false, self.loadFinanceCompanyStateDataCallback, "undefined", '');
            }
        },
        beforeMount() {
            self = this;
            if (self.$route.query.deal_id) {
                self.isEditDeal = true
            }
            let dateFormat = process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT.split(' ')[0];
            self.options = {
                format: dateFormat,
                useCurrent: false,
                allowInputToggle: true,
                widgetPositioning: {
                    horizontal: "auto",
                    vertical: "bottom"
                }
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            } else {
                var userJson = JSON.parse(userObj);
                self.salePersonId = {text: userJson['first_name'] + " " + userJson['last_name'], value: userJson['id']};
                self.salesPersonContactNo = userJson['contact_no'];
                self.dataAccessType = userJson['access_inventory'];
                if(userJson['access_inventory'] == 'used'){
                    self.conditionId = {text: "Used", value: '2'};
                } else {
                    self.conditionId = {text: "New", value: '1'};
                }
            }
            /* Redirect to login page logic end */
            $('.navbar').hide();
            // $('.admin-footer').hide();
            $('#sidebar-wrapper').hide();
            $(".app-sidebar-right").css("padding-left", "0px");
            $(".app-sidebar-right").css("margin-top", "0px");
            $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
            self.firstAdd = true;
            self.isAddFactory = true;
            // self.addNewRow();
            if (typeof (self.$route.query.id) != 'undefined' && self.$route.query.id != "" && self.$route.query.id != 0) {
                self.getAvailableDeals(self.$route.query.id);
                // self.getAvailableOpportunity(self.$route.query.id);
                // self.getAssetData(self.$route.query.id);
                self.assetId = self.$route.query.id;
            } else {
                self.customerCountyId = self.prodCountryObj();
                self.loadAllData(['States'], true, self.loadStateDataCallback, "undefined", "", self.customerCountyId.value);
            }
            if (typeof (self.$route.query.deal_id) != 'undefined' && self.$route.query.deal_id != "" && self.$route.query.deal_id != 0) {
                self.dealId = self.$route.query.deal_id;
                self.getAvailableDeals(0);
            }
            self.loadAllData(['Location', 'DealStatus', 'User', 'Contact', 'FinanceGroup', 'PaymentType'], false, self.loadMasterDataCallback, "undefined", '');
            self.$nextTick(() => {
                self.loadAllData(['Condition'], false, self.loadTypeMasterDataCallback, "undefined", '', '', '', '', '', '', '', self.dataAccessType);
            });
            /* self.loadAllData('Company', false, self.loadCompanyDataCallback, "undefined", '');
            self.loadAllData('DealStatus', false, self.loadStatusDataCallback, "undefined", ''); */
            /* self.loadAllData(['User', 'Contact', 'Cities', 'Countries'], true, self.loadMasterDetailsCallback, "undefined", ''); */
            /* self.loadAllData('User', true, self.loadSalePersonCallback, "undefined", '');
            self.loadAllData('Contact', true, self.loadCustomerCallback, 'undefined', '');
            self.loadAllData('Cities', true, self.loadCustomerCityCallback, 'undefined', '');
            self.loadAllData('Countries', true, self.loadCustomerCountyCallback, 'undefined', '');
            // Buyer
            self.loadAllData('Contact', true, self.loadBuyerCallback, 'undefined', '');
            self.loadAllData('Cities', true, self.loadBuyerCityCallback, 'undefined', '');
            self.loadAllData('Countries', true, self.loadBuyerCountyCallback, 'undefined', '');
            // Deliver To
            self.loadAllData('Contact', true, self.loadDeliverToCallback, 'undefined', '');
            self.loadAllData('Cities', true, self.loadDeliverToCityCallback, 'undefined', '');
            self.loadAllData('Countries', true, self.loadDeliverToCountyCallback, 'undefined', ''); */
            /* var callCount = 0;
            if(intervalId != null && intervalId !='undefined' && intervalId != 0){
                clearInterval(intervalId);
                callCount = 0;
            }
            var intervalId = window.setInterval(function(){
                callCount++;
                console.log("call method : " + callCount);
                self.saveBookDeal(1);
                if(callCount == 10){
                    clearInterval(intervalId);
                }
            }, 10000); */
            var businessName = document.getElementById('businessName');
            businessName.onkeydown = function() {
                var key = event.keyCode || event.charCode;
                if(key == 8) {
                    self.businessName = null
                    self.updateBusiness()
                }
            };
        },
        methods: {
            changeDealType: function(type){
                self.dealType = type;
            },
            loadTypeMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                self.makeArr = callbackData.Make;
                // self.modelArr = callbackData.Model;
                self.conditionArr = callbackData.Condition;
            },
            loadStateDataCallback: function (callbackData) {
                self.isStateRecordNotFound = false;
                self.stateArr = callbackData.States;
                if (self.stateArr.length == 0) {
                    self.isStateRecordNotFound = true;
                } else {
                    self.customerCityId = null
                // } else {
                //     self.customerStateId = {text: self.stateArr[0].text, value: self.stateArr[0].value}
                //     if (self.customerStateId) {
                //         self.loadAllData(['Countries'], true, self.loadCustomerCountyCallback, 'undefined', '', self.stateArr[0].country_id,'','','','','',self.conditionId.text.toLowerCase());
                //     }
                //     self.stateCountryId = self.stateArr[0].country_id
                }
            },
            addNewFactoryRow () {
                if (self.addNewFactory()) {
                    if (self.isAddFactory) {
                        self.factoryItemsArr.push(Vue.util.extend({}, self.factoryItemsObject));
                        self.isAddFactory = false;
                    } else {
                        self.plusCountFactory = self.plusCountFactory - 1;
                        self.factoryItemsArr.push(Vue.util.extend({}, self.factoryItemsObject));
                    }
                }
            },
            addNewFactory () {
                var validationError = true;
                for (let index = 0 ; index < self.factoryItemsArr.length; index++) {
                    var factoryItemObject = self.factoryItemsArr[index];
                    factoryItemObject.factoryOptionError = '';
                    factoryItemObject.factoryPriceGstError = '';
                    if (!factoryItemObject.factoryOption) {
                        factoryItemObject.factoryOptionError = "Please enter Factory Option & Chassis price";
                        if (validationError) {
                            validationError = false;
                        }
                    }
                    if (!factoryItemObject.factoryPriceGst) {
                        factoryItemObject.factoryPriceGstError = "Please enter price ex. GST";
                        if (validationError) {
                            validationError = false;
                        }
                    } else if(factoryItemObject.factoryPriceGst && !self.numberFormateValidation(factoryItemObject.factoryPriceGst)) {
                        factoryItemObject.factoryPriceGstError = "Only Numeric Value Allow";
                        if (validationError) {
                            validationError = false;
                        }
                    }
                }
                return validationError;
            },
            removeFactory (index) {
                if(self.plusCountFactory <= 5 ) {
                    self.plusCountFactory = self.plusCountFactory + 1;
                    self.factoryItemsArr.splice(index, 1);
                }
                self.calculateQuot();
            },
            addNewRow: function() {
                // console.log("First Add : " + self.firstAdd);
                if (self.addNewSupplier()) {
                    if (self.firstAdd) {
                        self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
                        self.firstAdd = false;
                    } else {
                        self.plusCountSupplier = self.plusCountSupplier - 1;
                        self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
                    }
                }
            },
            addNewSupplier: function() {
                var validationError = true;
                for (let index = 0 ; index < self.lineItemsArr.length; index++) {
                    var lineItemObject = self.lineItemsArr[index];
                    lineItemObject.supplierError = '';
                    lineItemObject.buildUpError = '';
                    lineItemObject.priceGSTError = '';
                    // if(self.dealStatusIdValue == 8){
                        if (!lineItemObject.supplier) {
                            lineItemObject.supplierError = "Please select supplier";
                            if (validationError) {
                                validationError = false;
                            }
                        }
                        if (!lineItemObject.buildUp) {
                            lineItemObject.buildUpError = "Please select build up";
                            if (validationError) {
                                validationError = false;
                            }
                        }
                        if (!lineItemObject.priceGST) {
                            lineItemObject.priceGSTError = "Please select price ex. GST";
                            if (validationError) {
                                validationError = false;
                            }
                        } else if(lineItemObject.priceGST && !self.numberFormateValidation(lineItemObject.priceGST)) {
                            lineItemObject.priceGSTError = "Only Numeric Value Allow";
                            if (validationError) {
                                validationError = false;
                            }
                        }
                    // }
                }
                return validationError;
            },
            removeSupplier: function(index) {
                if(self.plusCountSupplier <= 49 ) {
                    self.plusCountSupplier = self.plusCountSupplier + 1;
                    self.lineItemsArr.splice(index, 1);
                }
                self.calculateQuot();
            },
            onSearchSupplier(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadSupplierDataCallback, loading, search);
                }
            },
            loadSupplierDataCallback: function(callbackData) {
                self.isSupplierRecordNotFound = false;
                self.supplierArr = callbackData.Vendor;
                if(self.supplierArr.length == 0) {
                    self.isSupplierRecordNotFound = true;
                }
            },
            vehicleWorkSheet: function() {
                self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
            },
            editOpportunityInventory: function(editOptionAssetId) {
                self.editAssetId = editOptionAssetId;
                self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
            },
            deleteOpportunityInventory: function(deleteAssetId) {
                /* console.log(deleteAssetId);
                console.log(self.selectedOpportunityId);return false; */
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Opportunity Inventory!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data : {
                                asset_id: deleteAssetId,
                                opportunity_id: self.selectedOpportunityId
                            }
                        };
                        HTTP.delete("/opportunity/inventory/delete", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.getOpportunityInventory(self.selectedOpportunityId);
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
            addOpportunity: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = new FormData();
                data.append('asset_id', self.assetId);
                data.append('deal_id', self.dealId);
                HTTP.post("/deal/opportunity/add", data, config)
                    .then(function (response) {
                        // $('#opportunityModal').modal('hide');
                        // Display Opportunity Inventory Data Function
                        self.getOpportunityInventory(response.data.content.id);
                        self.getDealData();
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            getOpportunityInventory: function(opportunityId) {
                self.selectedOpportunityId = opportunityId;
                $('#opportunityModal').modal('hide');
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: opportunityId,
                    }
                };
                self.showLoader();
                HTTP.get("/opportunity/get", config)
                    .then(function (data) {
                        /* console.log(JSON.stringify(data.data.content));
                        self.opportunityInfo = [];
                        self.opportunityInfo = data.data.content.inventory_data; */
                        self.opportunityInventoryArr = data.data.content.inventory_data;
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    });
            },
            getdeal: function(dealId) {
                self.dealId = dealId;
                self.assetInfo = [];
                /* console.log(self.dealId); */
                $('#dealModal').modal('hide');
                /* return false; */
                self.$nextTick(() => {
                    self.getDealData();
                });
            },
            getAvailableDeals: function (id) {
                // var idArr = [];
                // idArr.push(id);
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: id,
                        deal_id: self.dealId,
                    }
                };
                self.showLoader();
                HTTP.get("/asset/get_asset_deals_by_ids", config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            /* self.assetData = data.data;
                            self.asset = self.assetData; */
                            self.assetInfo = data.data.content;
                            // console.log(JSON.stringify(self.assetInfo));
                            if(self.assetInfo && self.assetInfo.length > 0) {
                                if(self.assetInfo.length >= 1) {
                                    $('#dealModal').modal('show');
                                } else {
                                    self.dealId = self.assetInfo[0].id;
                                    self.dealNo = self.assetInfo[0].deal_no;
                                    self.getAvailableOpportunity(self.dealId);
                                    self.$nextTick(() => {
                                        self.getDealData();
                                    });
                                    // Display Opportunity Inventory Data Function
                                    // self.getOpportunityInventory(self.assetInfo[0].id);
                                    // Check 
                                }
                            } else {
                                self.saveBookDeal();
                                self.$nextTick(() => {
                                    // self.addOpportunity();
                                });
                            }
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    });
            },
            /**
             * get Deal data
             */
            getDealData: function () {
                // self.addNewRow();
                /* console.log(self.dealId);
                return false; */
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: self.dealId,
                    }
                };
                self.showLoader();
                HTTP.get("/AU/deal/getbyid", config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            /* self.assetData = data.data;
                            self.asset = self.assetData; */
                            // self.isNextButton = true
                            self.isChangedPricebook = false
                            self.dealData = data.data.content;
                            self.opportunityInventoryArr = data.data.content.deal_inventory_data;
                            self.loadDealData();
                            if(data.data.content.line_items.length > 0) {
                                self.lineItemsArr = [];
                                self.lineItemsArr = data.data.content.line_items;
                                // console.log(data.data.content.line_items.length);
                                self.plusCountSupplier -= data.data.content.line_items.length;
                                // self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
                            }
                            if(data.data.content.factory_items.length > 0) {
                                self.factoryItemsArr = [];
                                self.factoryItemsArr = data.data.content.factory_items;
                            }
                            if (data.data.content && data.data.content.opportunity_id) {
                                self.selectedOpportunityId = data.data.content.opportunity_id;
                                self.getOpportunityInventory(self.selectedOpportunityId);
                            }
                            self.formData = JSON.parse(JSON.stringify(self.returnDealFormData()))
                            /* if(data.data.content.opportunity_id != null && data.data.content.opportunity_id != 'undefined' && data.data.content.opportunity_id != 0){
                                self.opportunityInfo.push({
                                    id: data.data.content.opportunity_id, 
                                    reference_no:  data.data.content.opportunity_reference_no
                                });
                                self.$nextTick(() => {
                                    $('#opportunityModal').modal('show');
                                });
                            } else {
                                self.addOpportunity();
                            } */
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        /* console.log(error); */
                        self.catchHandler(error, function () {});
                    });
            },
            /**
             * Checck Opportunity available for tthe deal
             * @param string $id comma separated deal ids 
             */
            getAvailableOpportunity: function (id) {
                /* console.log(id);
                return false; */
                // var idArr = [];
                // idArr.push(id);
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {
                        id: id,
                    }
                };
                self.showLoader();
                HTTP.get("/asset/get_deal_opportunity_by_id", config)
                    .then(function (data) {
                        self.hideLoader();
                        /* console.log(JSON.stringify(data.data));
                        return false; */
                        if (data.status == 200) {
                            /* self.assetData = data.data;
                            self.asset = self.assetData; */
                            self.opportunityInfo = data.data.content;
                            if(self.opportunityInfo && self.opportunityInfo.length > 0) {
                                if(self.opportunityInfo.length >= 1) {
                                    $('#opportunityModal').modal('show');
                                } else {
                                    // Display Opportunity Inventory Data Function
                                    self.getOpportunityInventory(self.opportunityInfo[0].opportunity_id);
                                }
                            } else {
                                // Create Opportunity
                                self.addOpportunity();
                                // self.saveBookDeal();
                                self.$nextTick(() => {
                                });
                            }
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    });
            },
            loadMasterDataCallback: function(callbackData) {
                if(callbackData && callbackData.length <= 0) {
                    return false;
                }
                // self.isCompanyRecordNotFound = false;
                // self.companyArr = callbackData.Company;
                // if(self.companyArr.length == 0) {
                //     self.isCompanyRecordNotFound = true;
                // }
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }

                self.isDealStatusRecordNotFound = self.isFinanceCompanyRecordNotFound = self.isFinanceCompanyStateRecordNotFound = false;
                self.isPaymentTypeRecordNotFound = self.isDownPaymentRecordNotFound = self.isCashBackRecordNotFound = self.isDueOnDeliveryRecordNotFound = false;
                
                self.dealStatusArr = callbackData.DealStatus;
                if(self.dealStatusArr.length == 0) {
                    self.isDealStatusRecordNotFound = true;
                }
                self.isSalePersonRecordNotFound = self.isCustomerRecordNotFound = self.isCustomerCityRecordNotFound = self.isCustomerCountyRecordNotFound = false;
                self.isBuyerRecordNotFound = self.isBuyerCityRecordNotFound = self.isBuyerCountyRecordNotFound = false;
                self.isDeliverToRecordNotFound = self.isDeliverToCityRecordNotFound = self.isDeliverToCountyRecordNotFound = false;
                self.isBusinessRecordNotFound = false;

                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
                self.businessNameArr = callbackData.Contact;
                if(self.businessNameArr.length == 0) {
                    self.isBusinessRecordNotFound = true;
                }
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                }
                // Buyer
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
                self.buyerCountyArr = callbackData.Countries;
                if(self.buyerCountyArr.length == 0) {
                    self.isBuyerCountyRecordNotFound = true;
                }
                // Deliver To
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
                self.deliverToCountyArr = callbackData.Countries;
                if(self.deliverToCountyArr.length == 0) {
                    self.isDeliverToCountyRecordNotFound = true;
                }
                // Finance Company
                self.selectFinanceCompanyArr = callbackData.FinanceGroup;
                if(self.selectFinanceCompanyArr.length == 0) {
                    self.isFinanceCompanyRecordNotFound = true;
                }
                self.financeCompanyStateArr = callbackData.States;
                if(self.financeCompanyStateArr.length == 0) {
                    self.isFinanceCompanyStateRecordNotFound = true;
                }
                // Payment Type
                self.paymentTypeArr = callbackData.PaymentType;
            },
            /* loadMasterDetailsCallback: function(callbackData) {
                self.isSalePersonRecordNotFound = self.isCustomerRecordNotFound = self.isCustomerCityRecordNotFound = self.isCustomerCountyRecordNotFound = false;
                self.isBuyerRecordNotFound = self.isBuyerCityRecordNotFound = self.isBuyerCountyRecordNotFound = false;
                self.isDeliverToRecordNotFound = self.isDeliverToCityRecordNotFound = self.isDeliverToCountyRecordNotFound = false;

                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                }
                // Buyer
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
                self.buyerCountyArr = callbackData.Countries;
                if(self.buyerCountyArr.length == 0) {
                    self.isBuyerCountyRecordNotFound = true;
                }
                // Deliver To
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
                self.deliverToCountyArr = callbackData.Countries;
                if(self.deliverToCountyArr.length == 0) {
                    self.isDeliverToCountyRecordNotFound = true;
                }
            }, */
            updateCompany () {
                let company_id = "";
                if (typeof(self.companyId) != "undefined" && self.companyId != null && self.companyId != "" && self.companyId != 0) {
                    company_id = self.companyId.value;
                    self.loadAllData(['Location'], false, self.loadLocationDataCallback, "undefined", '', company_id,'','','','','',self.conditionId.text.toLowerCase());
                }
            },
            onSearchCompany(search, loading) {
                if (search.length > 2 && self.companyId) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            onSearchMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Make'], true, self.loadMakeDataCallback, loading, search,'','','','','','',self.conditionId.text.toLowerCase());
                }
            },
            loadMakeDataCallback: function (callbackData) {
                self.isMakeRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
            },
            changedSeries : function(){
                if (!self.isEditDeal) {
                    let make_id = "";
                    if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0 && self.series) {
                        make_id = self.makeId.value;
                        let params = {
                            make_id: make_id,
                            type: self.series.value
                        }
                        self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', params,'','','','','',self.conditionId.text.toLowerCase());
                    }
                }
            },
            onSearchModel(search, loading) {
                if (search.length > 2 && self.makeId) {
                    loading(true);
                    let make_id = "";
                    let params = null;
                    if (typeof(self.makeId) != "undefined" && self.makeId != null && self.makeId != "" && self.makeId != 0 && self.series) {
                        make_id = self.makeId.value;
                        params = {
                            make_id: make_id,
                            type: self.series.value
                        }
                    }
                    self.loadAllData(['Model'], true, self.loadModelDataCallback, loading, search, params,'','','','','',self.conditionId.text.toLowerCase());
                }
            },
            loadModelDataCallback: function (callbackData) {
                self.isModelRecordNotFound = false;
                self.modelArr = callbackData.Model;
                if(self.modelArr.length == 0) {
                    self.isModelRecordNotFound = true;
                }
            },
            loadPricebookDataCallback (callbackData) {
                self.isPricebookRecordNotFound = false;
                self.pricebookArr = callbackData.Pricebook;
                if(self.pricebookArr.length == 0) {
                    self.isPricebookRecordNotFound = true;
                }
            },
            clearDataOnModelChange () {
                self.salesPrice = "";
                self.bonus_amount = 0.00;
                self.extra_conquest = 0.00;
                self.overallowance = 0.00;
                self.gross_profit = 0.00;
                self.gross_profit_percentage = 0.00;
                self.cab_chassis =0.00;
                self.build_up_total=0.00;
                self.sub_total_ex_gst=0.00;
                self.total_gst=0.00;
                self.sub_total_incl_gst=0.00;
                self.sub_total1 = 0.00;
                self.ctp = 0.00;
                self.stamp_duty=0.00;
                self.downpaymentAmt=0.00;
                self.registration=0.00;
                self.trade_detail=null;
                self.trade_value_inc_gst=0.00;
                self.over_allowance_inc_gst=0.00;
                self.tradeAllownce="";
                self.trade_payout=0.00;
                self.trade_equity=0.00;
                self.total="";
                self.change_over=0.00;
                self.gst = 0;
                self.list_price = 0;
                self.dealer_discount = 0;
                self.dealer_net = 0;
                self.fituptotal = 0;
                self.pre_delivery = 0;
                self.stampDutyPercentage = 0.00;
                self.cylinders = "";
                self.less_trade_equity = 0.00;
                self.delivery_charges = 0.00;
                self.total_on_road_price = 0.00;
                self.dealer_net_invoice_price = 0.00;
                self.dealer_cost = 0.00;
                self.resetDaysInterest()
                self.resetMonthsInterest()
            },
            changedValue: function (data) {
                if (self.isChangedPricebook) {
                    self.pricebookId = null
                }
                if (self.modelId) {
                    self.loadAllData(['Pricebook'], true, self.loadPricebookDataCallback, false, '', self.modelId.value,'','','','','',self.conditionId.text.toLowerCase());
                }
                self.getModelPriceBookData();
            },
            changedPricebook (value) {
                if (!self.isEditDeal) {
                    if (value && value.description) {
                        self.model_description = ' - '+value.description.trim()
                    } else {
                        self.model_description = null
                    }
                }
                self.getModelPriceBookData()
            },
            getModelPriceBookData(value) {
                if (self.isChangedPricebook) {
                    self.clearDataOnModelChange();
                }
                // let contract_date
                // if (self.contractDate) {
                //     contract_date = self.contractDate
                // } else {
                //     let today = new Date();
                //     let dd = String(today.getDate()).padStart(2, '0');
                //     let mm = String(today.getMonth() + 1).padStart(2, '0');
                //     let yyyy = today.getFullYear();

                //     contract_date = mm + '/' + dd + '/' + yyyy;
                // }
                let modelid = self.modelId.value
                if(modelid && self.makeId && self.pricebookId){
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                        params : {
                            make_id : self.makeId.value
                            ,model_id : modelid,
                            // contract_date: contract_date,
                            pricebook_id: self.pricebookId.value,
                            file_name: self.pricebookId.text
                        }
                    };

                    HTTP.get('/price/book/makemodelget', config)
                        .then(response => {
                            // console.log(JSON.stringify(response.data));
                            // console.log(response.data.content.list_price);
                            // if(self.salesPrice < response.data.content.list_price){
                            self.salesPrice = self.convertToNum(response.data.content.list_price,2);
                            if (self.salesPrice) {
                                self.salesPriceError = ""
                            }
                            // }
                            self.rrp=self.convertToNum(response.data.content.rrp,2);
                            self.gst=self.convertToNum(response.data.content.gst,2);
                            self.list_price=self.convertToNum(response.data.content.list_price,2);
                            self.dealer_discount=self.convertToNum(response.data.content.dealer_discount,2);
                            self.dealer_net=self.convertToNum(response.data.content.dealer_net,2);
                            // self.conquest=self.convertToNum(response.data.content.conquest,2);
                            self.fituptotal=self.convertToNum(response.data.content.fituptotal,2);
                            self.pre_delivery=self.convertToNum(response.data.content.pre_delivery,2);
                            self.stampDutyPercentage = 3;
                            self.cylinders = response.data.content.cylinder;
                            if(response.data.content.cylinder == "6 Cylinder"){
                                self.stampDutyPercentage = 3.5;
                            }
                            // console.log(self.stampDutyPercentage);
                            self.calculateQuot();
                            // self.calculateInterestForDays();
                            // self.calculateInterestForMonths();
                        })
                        .catch(function (err) {
                            self.showSAlert(err.response.data.message, 'error', false);
                            // self.catchHandler(err, function () {});
                        });
                }
            },
            onSearchDealStatus(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['DealStatus'], true, self.loadStatusDataCallback, loading, search,"","","","","","",self.conditionId.text.toLowerCase());
                }
            },
            loadStatusDataCallback: function (callbackData) {
                self.isDealStatusRecordNotFound = false;
                self.dealStatusArr = callbackData.DealStatus;
                if(self.dealStatusArr.length == 0) {
                    self.isDealStatusRecordNotFound = true;
                }
            },
            onSearchSalePerson(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalePersonCallback, loading, search);
                }
            },
            loadSalePersonCallback: function(callbackData) {
                self.isSalePersonRecordNotFound = false;
                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
            },
            onSearchCustomer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadCustomerCallback, loading, search);
                }
            },
            loadCustomerCallback: function(callbackData) {
                self.isCustomerRecordNotFound = false;
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
            },
            onSearchCustomerCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadCustomerCityCallback, loading, search, self.customerStateId.value, '','','','','',self.conditionId.text.toLowerCase());
                }
            },
            updateCity () {
                if (self.customerCityId) {
                    // self.loadAllData(['States'], true, self.loadStateDataCallback, 'undefined', self.customerCityId.state_id, '','','','','','',self.conditionId.text.toLowerCase());
                }
            },
            loadCustomerCityCallback: function(callbackData) {
                self.isCustomerCityRecordNotFound = false;
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
            },
            onSearchCustomerCounty(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Countries'], true, self.loadCustomerCountyCallback, loading, search);
                }
            },
            loadCustomerCountyCallback: function(callbackData) {
                self.isCustomerCountyRecordNotFound = false;
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                } else {
                    self.customerStateId = null
                    self.customerCityId = null
                // } else {
                //     self.customerCountyArr.forEach(function (item, index) {
                //         if (item.value == self.stateCountryId) {
                //             self.customerCountyId = {text: item.text, value: item.value}
                //         }
                //     });
                }
            },
            // Buyer
            onSearchBuyer(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadBuyerCallback, loading, search);
                }
            },
            loadBuyerCallback: function(callbackData) {
                self.isBuyerRecordNotFound = false;
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
            },
            onSearchBuyerCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadBuyerCityCallback, loading, search);
                }
            },
            loadBuyerCityCallback: function(callbackData) {
                self.isBuyerCityRecordNotFound = false;
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
            },
            onSearchBuyerCounty(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Countries'], true, self.loadBuyerCountyCallback, loading, search);
                }
            },
            loadBuyerCountyCallback: function(callbackData) {
                self.isBuyerCountyRecordNotFound = false;
                self.buyerCountyArr = callbackData.Countries;
                if(self.buyerCountyArr.length == 0) {
                    self.isBuyerCountyRecordNotFound = true;
                }
            },
            // Deliver To
            onSearchDeliverTo(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadDeliverToCallback, loading, search);
                }
            },
            loadDeliverToCallback: function(callbackData) {
                self.isDeliverToRecordNotFound = false;
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
            },
            onSearchDeliverToCity(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Cities'], true, self.loadDeliverToCityCallback, loading, search);
                }
            },
            loadDeliverToCityCallback: function(callbackData) {
                self.isDeliverToCityRecordNotFound = false;
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
            },
            onSearchDeliverToCounty(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Countries'], true, self.loadDeliverToCountyCallback, loading, search);
                }
            },
            loadDeliverToCountyCallback: function(callbackData) {
                self.isDeliverToCountyRecordNotFound = false;
                self.deliverToCountyArr = callbackData.Countries;
                if(self.deliverToCountyArr.length == 0) {
                    self.isDeliverToCountyRecordNotFound = true;
                }
            },
            onChangeFinanceCompany : function(value) {
                if(self.selectFinanceCompany != null && self.selectFinanceCompany.value) {
                    var config = {
                        headers: {Authorization:"Bearer " + localStorage.getItem("api_token")},
                        params: {
                            id: self.selectFinanceCompany.value,
                        }
                    };
                    HTTP.get("/financegroup/get", config)
                        .then(function (response) {
                            self.financeCompany = response.data.content.name;
                            self.financeCompanyAddress = response.data.content.address;
                            if(response.data.content.state_id != null && response.data.content.state_id != '') {
                                self.financeCompanyState = { value : response.data.content.state_id,text :  response.data.content.state_name };
                            }
                            if(response.data.content.city_name != null && response.data.content.city_name != '') {
                                self.financeCompanyCity = response.data.content.city_name;
                            }
                            self.financeCompanyZip = response.data.content.zip_code;
                            self.financeCompanyComment = response.data.content.comment;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
                } else {
                    self.financeCompany = "";
                    self.financeCompanyAddress = "";
                    self.financeCompanyCity = "";
                    self.financeCompanyState = "";
                    self.financeCompanyZip = "";
                    self.financeCompanyComment = "";
                }
            },
            onSearchFinanceCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['FinanceGroup'], true, self.loadFinanceCompanyDataCallback, loading, search);
                }
            },
            loadFinanceCompanyDataCallback: function (callbackData) {
                self.isFinanceCompanyRecordNotFound = false;
                self.selectFinanceCompanyArr = callbackData.FinanceGroup;
                if(self.selectFinanceCompanyArr.length == 0) {
                    self.isFinanceCompanyRecordNotFound = true;
                }
            },
            onSearchFinanceCompanyState(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadFinanceCompanyStateDataCallback, loading, search);
                }
            },
            loadFinanceCompanyStateDataCallback: function (callbackData) {
                self.isFinanceCompanyStateRecordNotFound = false;
                self.financeCompanyStateArr = callbackData.States;
                if(self.financeCompanyStateArr.length == 0) {
                    self.isFinanceCompanyStateRecordNotFound = true;
                }
            },
            clickHeaderCheckBox: function() {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.opportunityInfo) {
                            self.selectedRows.push(self.opportunityInfo[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function(e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.opportunityInfo.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            getAssetData(id) {
                var idArr = [];
                idArr.push(id);
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},

                    params: {
                        id: idArr,
                    }
                };
                self.showLoader();
                HTTP.get("/asset/getmulty", config)
                        .then(function (data) {
                            self.hideLoader();
                            if (data.status == 200) {
                                /* self.assetData = data.data;
                                self.asset = self.assetData; */
                                self.assetInfo = data.data.content;
                            }
                        })
                        .catch(function (error) {
                            self.hideLoader();
                            self.catchHandler(error, function () {});
                        })
            },
            checkForm: function () {
                var checked = true;

                /* if (self.selectedOpportunityId == 0) {
                    // self.assetError = "Please select Opportunity";
                    self.showSAlert("Please Select Opportunity", 'info', false);
                    if (checked) {
                        checked = false;
                    }
                } */
                // self.specificationUrlError = "";
                // if (self.specificationUrl && !self.websiteUrlValidation(self.specificationUrl)) {
                //     self.specificationUrlError = "Please enter valid url";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.specPagesError = "";
                // /* console.log("self.specPages-"+self.specPages + "-Over") */
                // if (self.specPages  && self.specPages != '') {
                //     if(!self.validPageNumberInput(self.specPages)){
                //         self.specPagesError = "Please enter valid pages string";
                //         if (checked) {
                //             checked = false;
                //         }
                //     }
                //     for (var i = 0; i < self.specPages.length; i++) {
                //         if(i%2){
                //             if(self.specPages[i] != "-" && self.specPages[i] != ","){
                //                 self.specPagesError ='Please use pattern like "1,3,5-7" for "1,3,5,6,7" and do not use space';
                //                 /* console.log(self.specPages[i] + " != , or - "); */
                //                 if (checked) {
                //                     checked = false;
                //                 }
                //             }
                //         } else {
                //             if(self.specPages[i] <= "0"){
                //                 self.specPagesError ='Please enter valid page number';
                //                 if (checked) {
                //                     checked = false;
                //                 }
                //             }
                //         }
                //     }
                // }
                // self.companyError = "";
                // if (self.companyId == null || !self.companyId.value) {
                //     self.companyError = "Please select Department";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // if (self.locationId && self.companyId == null) {
                //     self.locationError = "Please select Department first.";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                self.locationError = "";
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select Location";
                    if (checked) {
                        checked = false;
                    }
                }
                self.makeError = "";
                if (self.makeId == null || !self.makeId.value) {
                    self.makeError = "Please select Make";
                    if (checked) {
                        checked = false;
                    }
                }
                self.seriesError = "";
                if (self.series == null || !self.series.value) {
                    self.seriesError = "Please select Series";
                    if (checked) {
                        checked = false;
                    }
                }
                self.modelError = "";
                if (self.modelId == null || !self.modelId.value) {
                    self.modelError = "Please select Model";
                    if (checked) {
                        checked = false;
                    }
                }
                self.pricebookError = "";
                if (self.pricebookId == null || !self.pricebookId.value) {
                    self.pricebookError = "Please select Pricebook";
                    if (checked) {
                        checked = false;
                    }
                }
                self.customerError = "";
                if (!self.customerId) {
                    self.customerError = "Please enter customer name";
                    if (checked) {
                        checked = false;
                    }
                }
                self.businessNameError = "";
                if (self.businessName == null || !self.businessName.value) {
                    self.businessNameError = "Please select Business name";
                    if (checked) {
                        checked = false;
                    }
                }
                self.salePersonError = "";
                if (self.salePersonId == null || !self.salePersonId.value) {
                    self.salePersonError = "Please select Sales Person";
                    if (checked) {
                        checked = false;
                    }
                }
                self.cusPhoneNumberError = "";
                if(self.cusPhoneNumber && !self.validContactNumber(self.cusPhoneNumber)) {
                    self.cusPhoneNumberError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.cusPhoneNumber && !self.checkContactNoLength(self.cusPhoneNumber)) {
                    self.cusPhoneNumberError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                // self.cusTaxRateError = "";
                // if(self.cusTaxRate && !self.numberFormateValidation(self.cusTaxRate)) {
                //     self.cusTaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.cusStateTaxRateError = "";
                // if(self.cusStateTaxRate && !self.numberFormateValidation(self.cusStateTaxRate)) {
                //     self.cusStateTaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.cusCountyTaxRateError = "";
                // if(self.cusCountyTaxRate && !self.numberFormateValidation(self.cusCountyTaxRate)) {
                //     self.cusCountyTaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.cusCityTaxRateError = "";
                // if(self.cusCityTaxRate && !self.numberFormateValidation(self.cusCityTaxRate)) {
                //     self.cusCityTaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.cusOtherTaxRateError = "";
                // if(self.cusOtherTaxRate && !self.numberFormateValidation(self.cusOtherTaxRate)) {
                //     self.cusOtherTaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                // self.cusOther2TaxRateError = "";
                // if(self.cusOther2TaxRate && !self.numberFormateValidation(self.cusOther2TaxRate)) {
                //     self.cusOther2TaxRateError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                self.customerEmailError = "";
                if (self.customerEmail && !self.validEmail(self.customerEmail)) {
                    self.customerEmailError = "Please enter valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                // self.cusCountySurtaxError = "";
                // if(self.cusCountySurtax && !self.numberFormateValidation(self.cusCountySurtax)) {
                //     self.cusCountySurtaxError = "Only Numeric Value Allow";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                self.deliverToPhoneNumberError = "";
                if(self.deliverToPhoneNumber && !self.validContactNumber(self.deliverToPhoneNumber)) {
                    self.deliverToPhoneNumberError = "Please enter valid phone";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.deliverToPhoneNumber && !self.checkContactNoLength(self.deliverToPhoneNumber)) {
                    self.deliverToPhoneNumberError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                self.salesPriceError = "";
                if(self.salesPrice && !self.numberFormateValidation(self.salesPrice)) {
                    self.salesPriceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.downpaymentAmtError = "";
                if(self.downpaymentAmt && !self.numberFormateValidation(self.downpaymentAmt)) {
                    self.downpaymentAmtError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.tradeAllownceError = "";
                if(self.tradeAllownce && !self.numberFormateValidation(self.tradeAllownce)) {
                    self.tradeAllownceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                /* self.totalFeesError = "";
                if(self.totalFees && !self.numberFormateValidation(self.totalFees)) {
                    self.totalFeesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* self.totalSalesTaxError = "";
                if(self.totalSalesTax && !self.numberFormateValidation(self.totalSalesTax)) {
                    self.totalSalesTaxError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
                self.totalError = "";
                if(self.total && !self.numberFormateValidation(self.total)) {
                    self.totalError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                /* self.warrantiesError = "";
                if(self.warranties && !self.numberFormateValidation(self.warranties)) {
                    self.warrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* self.insuranceError = "";
                if(self.insurance && !self.numberFormateValidation(self.insurance)) {
                    self.insuranceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
                /* self.totalWarrantiesError = "";
                if(self.totalWarranties && !self.numberFormateValidation(self.totalWarranties)) {
                    self.totalWarrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
                self.bonus_amount_error = "";
                if(self.bonus_amount && !self.numberFormateValidation(self.bonus_amount)) {
                    self.bonus_amount_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.extra_conquest_error = "";
                if(self.extra_conquest && !self.numberFormateValidation(self.extra_conquest)) {
                    self.extra_conquest_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.gross_profit_error = "";
                if(self.gross_profit && !self.numberFormateValidation(self.gross_profit)) {
                    self.gross_profit_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.rego_error = "";
                if(self.rego && !self.numberFormateValidation(self.rego)) {
                    self.rego_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.ctp_error = "";
                if(self.ctp && !self.numberFormateValidation(self.ctp)) {
                    self.ctp_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.stamp_duty_error = "";
                if(self.stamp_duty && !self.numberFormateValidation(self.stamp_duty)) {
                    self.stamp_duty_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.trade_value_inc_gst_error = "";
                if(self.trade_value_inc_gst && !self.numberFormateValidation(self.trade_value_inc_gst)) {
                    self.trade_value_inc_gst_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.registration_error = "";
                if(self.registration && !self.numberFormateValidation(self.registration)) {
                    self.registration_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.over_allowance_inc_gst_error = "";
                if(self.over_allowance_inc_gst && !self.numberFormateValidation(self.over_allowance_inc_gst)) {
                    self.over_allowance_inc_gst_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                self.trade_payout_error = "";
                if(self.trade_payout && !self.numberFormateValidation(self.trade_payout)) {
                    self.trade_payout_error = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
                if(self.dealStatusId.value == 8){
                    if(self.lineItemsArr && self.lineItemsArr.length > 0) {
                        for(var index = 0; index < self.lineItemsArr.length; index++) {
                            var lineItemObject = self.lineItemsArr[index];
                            lineItemObject.supplierError = '';
                            lineItemObject.buildUpError = '';
                            lineItemObject.priceGSTError = '';
                            if (!lineItemObject.supplier) {
                                lineItemObject.supplierError = "Please select supplier";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (!lineItemObject.buildUp) {
                                lineItemObject.buildUpError = "Please select build up";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (!lineItemObject.priceGST) {
                                lineItemObject.priceGSTError = "Please select price ex. GST";
                                if (checked) {
                                    checked = false;
                                }
                            } else if(lineItemObject.priceGST && !self.numberFormateValidation(lineItemObject.priceGST)) {
                                lineItemObject.priceGSTError = "Only Numeric Value Allow";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                }
                return checked;
            },
            openBookDealLink: function (flag) {
                self.$router.push("/quotation/?id=" + self.$route.query.id + "&deal_id=" + self.dealId);
            },
            returnDealFormData : function(){
                var data = {
                    'inventory_id' : self.assetId,
                    'make_id' : self.makeId.value,
                    'model_id' : self.modelId.value,
                    'opportunity_id': self.selectedOpportunityId,
                    'add_to_forecast': self.add_to_forecast,
                    'deal_no': self.dealNo,
                    'company_id': self.companyId.value,
                    'deal_status_id': self.dealStatusId.value,
                    'contract_number': self.contractNumber,
                    'contract_date': self.contractDate.split("/").reverse().join("/"),
                    'ros_number': self.rosNumber,
                    'saleperson_id': self.salePersonId.value,
                    'cus_phone_number': self.cusPhoneNumber,
                    'customer_name': self.customerId,
                    'individual': self.individual,
                    'individual_value': self.individualValue,
                    'cus_fax': self.cusFax,
                    'cus_address': self.cusAddress,
                    'cus_cell': self.cusCell,
                    'cus_address_2': self.cusAddress2,
                    'cus_sin': self.cusSIN,
                    'cus_city_id': self.customerCityId.value,
                    'cus_city_2': self.cusCity2,
                    'cus_state_id': self.customerStateId.value,
                    'cus_tax_exemption_number': self.cusTaxExemptionNumber,
                    'cus_alt_city_name': self.cusAltCityName,
                    'cus_address_type': self.cusAddressType,
                    'customer_county_id': self.customerCountyId.value,
                    'cus_tax_rate': self.cusTaxRate,
                    'cus_sales_tax': self.cusSalesTax,
                    'cus_state_tax_rate': self.cusStateTaxRate,
                    'cus_alt_county_name': self.cusAltCountyName,
                    'cus_county_tax_rate': self.cusCountyTaxRate,
                    'cus_city_tax_rate': self.cusCityTaxRate,
                    'cus_other_tax_rate': self.cusOtherTaxRate,
                    'cus_other_2_tax_rate': self.cusOther2TaxRate,
                    'customer_email': self.customerEmail,
                    'cus_county_surtax': self.cusCountySurtax,
                    'cus_county_code': self.cusCountyCode,
                    // CO-Buyer
                    'buyer_number': self.buyerNumber,
                    'buyer_id': self.buyerId.value,
                    'buyer_address': self.buyerAddress,
                    'buyer_address_2': self.buyerAddress2,
                    'buyer_city_id': self.buyerCityId.value,
                    'buyer_city_2': self.buyerCity2,
                    'buyer_city_3': self.buyerCity3,
                    // Deliver-To
                    'deliver_to_phone_number': self.deliverToPhoneNumber,
                    'deliver_to_id': self.deliverToId.value,
                    'deliver_to_address': self.deliverToAddress,
                    'deliver_to_address_2': self.deliverToAddress2,
                    'deliver_to_city_id': self.deliverToCityId.value,
                    'deliver_to_city_2': self.deliverToCity2,
                    'deliver_to_city_3': self.deliverToCity3,
                    'internal_comments': self.internalComments,
                    'comments': self.comments,
                    'comments2': self.comments2,
                    'show_on_buyers_order': self.showOnBuyersOrder,
                    // Cash Detail
                    'sales_price': self.salesPrice,
                    'downpaymentAmt': self.downpaymentAmt,
                    'trade_allownce': self.tradeAllownce,
                    'total_fees': self.totalFees,
                    'total_sales_tax': self.totalSalesTax,
                    'total': self.total,
                    'warranties': self.warranties,
                    'insurance': self.insurance,
                    'total_warranties': self.totalWarranties,
                    'line_items': self.lineItemsArr,
                    'location_id': self.locationId.value,
                    'bonus_amount' : self.bonus_amount,
                    'extra_conquest' : self.extra_conquest,
                    'gross_profit' : self.gross_profit,
                    'stamp_duty_type' : self.stampDutyType.value,
                    'rego' : self.rego,
                    'ctp' : self.ctp,
                    'stamp_duty': self.stamp_duty,
                    'trade_detail' : self.trade_detail,
                    'registration' : self.registration,
                    'trade_value_inc_gst' : self.trade_value_inc_gst,
                    'over_allowance_inc_gst' : self.over_allowance_inc_gst,
                    'trade_payout' : self.trade_payout,
                    'service_plan' : self.servicePlan,
                    'vehicle_warranty' : self.vehicleWarranty,
                    'roadside_assistance' : self.roadsideAssistance,
                    'specification_url' : self.specificationUrl,
                    'spec_pages' : self.specPages,
                    'factory_items': self.factoryItemsArr,
                    'pricebook_id': self.pricebookId.value,
                    //NEW FIELDS STARTED
                    'excede_id_hash' : self.excedeIdHash,
                    'invoice_date' : self.invoiceDate,
                    'invoice_hash' : self.invoiceHash,
                    'payment_type' : self.paymentType,
                    'order_type' : self.orderType,
                    'downpayment_payment_type' : self.downpaymentPaymentType,
                    'downpayment_desc' : self.downpaymentDesc,
                    'cash_back' : self.cashBack,
                    'cash_back_payment_type' : self.cashBackPaymentType,
                    'cash_back_desc' : self.cashBackDesc,
                    'due_on_delivery' : self.dueOnDelivery,
                    'due_on_delivery_payment_type' : self.dueOnDeliveryPaymentType,
                    'due_on_delivery_desc' : self.dueOnDeliveryDesc,
                    'physical_damage_price' : self.physicalDamagePrice,
                    'physical_damage_cost' : self.physicalDamageCost,
                    'non_trucking_liability_price' : self.nonTruckingLiabilityPrice,
                    'non_trucking_liability_cost' : self.nonTruckingLiabilityCost,
                    'gap_insurance_price' : self.gapInsurancePrice,
                    'gap_insurance_cost' : self.gapInsuranceCost,
                    'disability_price' : self.disabilityPrice,
                    'disability_cost' : self.disabilityCost,
                    'credit_life_price' : self.creditLifePrice,
                    'credit_life_cost' : self.creditLifeCost,
                    'cvra_price' : self.cvraPrice,
                    'cvra_cost' : self.cvraCost,
                    'annuale_per_rate' : self.annualePerRate,
                    'dealer_rate' : self.dealerRate,
                    'finance_income' : self.financeIncome,
                    'finance_term' : self.financeTerm,
                    'days_to_first_payment' : self.daysToFirstPayment,
                    'days_to_first_payment_date' : self.daysToFirstPaymentDate,
                    'finance_payment' : self.financePayment,
                    'sales_subtotal' : self.salesSubtotal,
                    'total_financed' : self.totalFinanced,
                    'finance_charge' : self.financeCharge,
                    'deferred_price' : self.deferredPrice,
                    'select_finance_company' : self.selectFinanceCompany,
                    'finance_company' : self.financeCompany,
                    'finance_company_address' : self.financeCompanyAddress,
                    'finance_company_city' : self.financeCompanyCity,
                    'finance_company_state' : self.financeCompanyState,
                    'finance_company_zip' : self.financeCompanyZip,
                    'finance_company_comment' : self.financeCompanyComment,
                    'finance_ccompany_alt' : self.financeCompanyAlt,
                    'finance_company_alt_address' : self.financeCompanyAltAddress,
                    'finance_company_alt_city' : self.financeCompanyAltCity,
                    'finance_company_alt_state' : self.financeCompanyAltState,
                    'finance_company_alt_zip' : self.financeCompanyAltZip,
                    'business_name': self.businessName.text,
                    'business_id': self.businessName.value,
                    'is_form_changed': false,
                    'stock_type': self.days_stock_type.value,
                    'expected_days': self.expected_age_days_at_time_of_delivery,
                    'is_customer_paying_interest': self.days_customer_paying_within_interest_free_period.value,
                    'stock': self.stock,
                    'delivery_date': self.delivery_date ? self.delivery_date.split("/").reverse().join("/") : null,
                    'vin_no': self.vin_no,
                    'comm_no': self.comm_no,
                    'dealer_date': self.dealer_date ? self.dealer_date.split("/").reverse().join("/") : null,
                    'quantity': self.quantity,
                    'cq_approval': self.cq_approval,
                    'cq_notes': self.cq_notes,
                    'bonus_notes': self.bonus_notes,
                    'factory_promo': self.factory_promo,
                    'rego_number': self.rego_number,
                    'rego_date': self.rego_date ? self.rego_date.split("/").reverse().join("/") : null,
                    'cq_submission_date': self.cq_submission_date ? self.cq_submission_date.split("/").reverse().join("/") : null,
                    'rda_approval_number': self.rda_approval_number,
                    'cq_entered_in_dfe': self.cq_entered_in_dfe,
                    'customer_details_entered_in_dfe': self.customer_details_entered_in_dfe,
                    'rda_date': self.rda_date ? self.rda_date.split("/").reverse().join("/") : null,
                    'body_type': self.body_type,
                    'conquest_obj': self.conquest_obj,
                    'rda_stamp': self.rda_stamp,
                    'cus_address2': self.cus_address2,
                    'abn': self.abn,
                    'delivery_charges': self.delivery_charges,
                    'ctp_label': self.ctp_label,
                    'type': self.series && self.series.value
                };
                return data;
            },
            changeLocation () {
                // if (self.companyId == null || !self.companyId.value) {
                //     self.locationError = "Please select Department first";
                // } else {
                //     self.locationError = "";
                // }
            },
            updateModel () {
                if (self.series == null || !self.series.value) {
                    self.seriesError = "Please select Series first";
                } else {
                    self.seriesError = "";
                }
            },
            updatePricebook () {
                if (self.modelId == null || !self.modelId.value) {
                    self.pricebookError = "Please select Model first";
                } else {
                    self.pricebookError = "";
                }
            },
            saveBookDeal: function(is_auto, status) {
                // console.log(self.formData)
                // console.log(JSON.parse(JSON.stringify(self.returnDealFormData())))
                // console.log(Object.keys(self.formData).length)
                // let obj = JSON.parse(JSON.stringify(self.returnDealFormData()))
                // console.log(Object.keys(obj).length)
                // return true;
                is_auto = (is_auto != null && is_auto !='undefined' && is_auto != 0)? 1 : 0;
                var stat ="";
                setTimeout(() => {
                    // console.log("saveBookDeal");
                    if (self.checkForm()) {
                        stat = self.dealStatusId.value = (status != null && status !='undefined' && status != 0)? status : self.dealStatusId.value;
                        // console.log("FORMCHECK DONE saveBookDeal");
                        // return false;
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnDealFormData();
                        let checkingData = data
                        Object.keys(checkingData).map(k => checkingData[k] = typeof checkingData[k] == 'string' ? checkingData[k].trim() : checkingData[k]);
                        if (self.formData) {
                            Object.keys(self.formData).map(k => self.formData[k] = typeof self.formData[k] == 'string' ? self.formData[k].trim() : self.formData[k]);
                        }
                        
                        if (JSON.stringify(checkingData) === JSON.stringify(self.formData)) {
                            self.showSAlert('There is no change to update.', 'error', false);
                        } else {
                            if (self.isEditDeal) {
                                data.is_form_changed = true
                            }
                            HTTP.post("/AU/deal/add/update", data, config)
                                .then(function (response) {
                                    if(stat != null && stat !='undefined' && stat != 0){
                                        self.getdeal(self.dealId);
                                    }
                                    if(is_auto == 0){
                                        self.showSAlert(response.data.message, 'success', false);
                                        if (self.dealId != response.data.content.id) {
                                            self.dealId = response.data.content.id; 
                                            self.openBookDealLink();
                                        }
                                        self.getDealData();
                                        $('#dealModal').modal('hide');
                                        if(response.data.content.opportunity_id != null && response.data.content.opportunity_id != 'undefined' && response.data.content.opportunity_id != 0){
            
                                        } else {
                                            self.addOpportunity();
                                        }
                                    }
                                    // self.isNextButton = true
                                // }
                                // self.clearDataOnSaveAndContinue();
                                // self.getAvailableDeals(self.$route.query.id);
                            })
                            .catch(function (err) {
                                // console.log(err);
                                self.catchHandler(err, function () {});
                            });
                        }
                    }
                }, 200);
            },
            checkPrintQuotation: function() {
                setTimeout(() => {
                    if (self.checkForm()) {
                        $("#printQuotBtn").html("Preparing PDF...");
                        $("#printQuotBtn").prop('disabled', true);
                        var data = {
                            'inventory_id' : self.assetId,
                            'make_id' : self.makeId.value,
                            'model_id' : self.modelId.value,
                            'opportunity_id': self.selectedOpportunityId,
                            'add_to_forecast': self.add_to_forecast,
                            'deal_no': self.dealNo,
                            'company_id': self.companyId.value,
                            'deal_status_id': self.dealStatusId.value,
                            'contract_number': self.contractNumber,
                            'contract_date': self.contractDate.split("/").reverse().join("/"),
                            'ros_number': self.rosNumber,
                            'saleperson_id': self.salePersonId.value,
                            'cus_phone_number': self.cusPhoneNumber,
                            'customer_name': self.customerId,
                            'individual': self.individual,
                            'individual_value': self.individualValue,
                            'cus_fax': self.cusFax,
                            'cus_address': self.cusAddress,
                            'cus_cell': self.cusCell,
                            'cus_address_2': self.cusAddress2,
                            'cus_sin': self.cusSIN,
                            'cus_city_id': self.customerCityId.value,
                            'cus_city_2': self.cusCity2,
                            'cus_state_id': self.customerStateId.value,
                            'cus_tax_exemption_number': self.cusTaxExemptionNumber,
                            'cus_alt_city_name': self.cusAltCityName,
                            'cus_address_type': self.cusAddressType,
                            'customer_county_id': self.customerCountyId.value,
                            'cus_tax_rate': self.cusTaxRate,
                            'cus_sales_tax': self.cusSalesTax,
                            'cus_state_tax_rate': self.cusStateTaxRate,
                            'cus_alt_county_name': self.cusAltCountyName,
                            'cus_county_tax_rate': self.cusCountyTaxRate,
                            'cus_city_tax_rate': self.cusCityTaxRate,
                            'cus_other_tax_rate': self.cusOtherTaxRate,
                            'cus_other_2_tax_rate': self.cusOther2TaxRate,
                            'customer_email': self.customerEmail,
                            'cus_county_surtax': self.cusCountySurtax,
                            'cus_county_code': self.cusCountyCode,
                            /* Cash Detail */
                            'sales_price': self.salesPrice,
                            'downpaymentAmt': self.downpaymentAmt,
                            'trade_allownce': self.tradeAllownce,
                            'total_fees': self.totalFees,
                            'total_sales_tax': self.totalSalesTax,
                            'total': self.total,
                            'warranties': self.warranties,
                            'insurance': self.insurance,
                            'total_warranties': self.totalWarranties,
                            'line_items': self.lineItemsArr,
                            'bonus_amount' : self.bonus_amount,
                            'extra_conquest' : self.extra_conquest,
                            'gross_profit' : self.gross_profit,
                            'stamp_duty_type' : self.stampDutyType.value,
                            'rego' : self.rego,
                            'ctp' : self.ctp,
                            'trade_detail' : self.trade_detail,
                            'registration' : self.registration,
                            'trade_value_inc_gst' : self.trade_value_inc_gst,
                            'over_allowance_inc_gst' : self.over_allowance_inc_gst,
                            'trade_payout' : self.trade_payout,
                            'dealer_net' : self.dealer_net,
                            'conquest' : self.conquest,
                            'total_cq_approved' : self.total_cq_approved,
                            'sub_total1' : self.sub_total1,
                            'overallowance' : self.overallowance,
                            'pre_delivery' : self.pre_delivery,
                            'gross_profit_percentage' : self.gross_profit_percentage,
                            'cab_chassis' : self.cab_chassis,
                            'build_up_total' : self.build_up_total,
                            'sub_total_ex_gst' : self.sub_total_ex_gst,
                            'total_gst' : self.total_gst,
                            'sub_total_incl_gst' : self.sub_total_incl_gst,
                            'cylinders' : self.cylinders,
                            'stamp_duty' : self.stamp_duty,
                            'trade_equity' : self.trade_equity,
                            'change_over' : self.change_over,
                            'service_plan' : self.servicePlan,
                            'vehicle_warranty' : self.vehicleWarranty,
                            'roadside_assistance' : self.roadsideAssistance,
                            'specification_url' : self.specificationUrl,
                            'spec_pages' : self.specPages,
                            'factory_items': self.factoryItemsArr,
                            'location_id': self.locationId.value,
                            'factory_options_total': self.factory_options_total,
                            'build_up_total': self.build_up_total,
                            'pricebook_id': self.pricebookId.value,
                            'business_id': self.businessName.value,
                            'internal_comments': self.internalComments,
                            'comments': self.comments,
                            'comments2': self.comments2,
                            'upload_elite_logo_on_pdf': self.uploadEliteLogoOnPdf,
                            'business_name': self.businessName.text,
                            'stock_type': self.days_stock_type.value,
                            'expected_days': self.expected_age_days_at_time_of_delivery,
                            'is_customer_paying_interest': self.days_customer_paying_within_interest_free_period.value,
                            'sub_total_ex_gst_plus_calculated_interest': self.sub_total_ex_gst_plus_calculated_interest,
                            'interest_charged': self.days_interest_charged,
                            'is_save_and_print': true,
                            'delivery_charges': self.delivery_charges,
                            'ctp_label': self.ctp_label,
                            'total_on_road_price': self.total_on_road_price,
                            'type': self.series.value,
                            'pricebook_name': self.pricebookId.text
                        };
                        // if (self.isEditDeal) {
                        //     let checkingData = JSON.parse(JSON.stringify(self.returnDealFormData()))
                        //     Object.keys(checkingData).map(k => checkingData[k] = typeof checkingData[k] == 'string' ? checkingData[k].trim() : checkingData[k]);
                        //     if (self.formData) {
                        //         Object.keys(self.formData).map(k => self.formData[k] = typeof self.formData[k] == 'string' ? self.formData[k].trim() : self.formData[k]);
                        //     }
                        //     if (JSON.stringify(checkingData) === JSON.stringify(self.formData)) {
                        //         data.is_save_and_print = false
                        //         self.printQuotation(data)
                        //     } else {
                        //         this.$bvModal.msgBoxConfirm('Please confirm that you want to print or save & print', {
                        //             title: 'Modification Detected!',
                        //             size: 'sm',
                        //             buttonSize: 'sm',
                        //             okTitle: 'Save & Print',
                        //             cancelTitle: 'Print Only',
                        //             footerClass: 'p-2',
                        //             hideHeaderClose: false,
                        //             centered: true
                        //         })
                        //         .then(value => {
                        //             if (value != null) {
                        //                 data.is_save_and_print = value
                        //                 data.is_form_changed = value
                        //                 self.printQuotation(data)
                        //             }
                        //         })
                        //         .catch(err => {
                        //             // An error occurred
                        //         })
                        //     }
                        // } else {
                            self.printQuotation(data)
                        // }
                    }
                }, 200);
            },
            printQuotation (data) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.post("/AU/deal/print/quotation", data, config)
                    .then(function (response) {
                        self.dealNo = response.data.content.deal_no
                        self.dealId = response.data.content.deal_id
                        $("#printQuotBtn").html("Print Quotation");
                        $("#printQuotBtn").prop('disabled', false);
                        self.dealNumber = response.data.deal_no
                        self.dealId = response.data.deal_id
                        if (response.status == 200) {
                            self.downloadFile(response.data.content.web);
                        }
                        if (response.status == 201) {
                            $("#printQuotBtn").html("Print Quotation");
                            $("#printQuotBtn").prop('disabled', false);
                            self.showSAlert(response.data.content[0], 'error', false);
                        }
                        self.getDealData()
                    })
                    .catch(function (err) {
                        $("#printQuotBtn").html("Print Quotation");
                        $("#printQuotBtn").prop('disabled', false);
                        self.catchHandler(err, function () {});
                    });
            },
            clearDataOnSaveAndContinue: function() {
                // self.selectedOpportunityId = '';
                self.add_to_forecast = true;
                self.conditionId = {text: "New", value: '1'};
                self.makeId = 0;
                self.modelId = 0;
                self.series = null;
                self.dealNo = '';
                self.companyId = '';
                self.dealStatusId = '';
                self.contractNumber = '';
                self.contractDate = '';
                self.rosNumber = '';
                self.salePersonId = '';
                self.cusPhoneNumber = '';
                self.customerId = '';
                self.individual = '';
                self.individualValue = '';
                self.cusFax = '';
                self.cusAddress = '';
                self.cusCell = '';
                self.cusAddress2 = '';
                self.cusSIN = '';
                self.customerCityId = '';
                self.cusCity2 = '';
                self.customerStateId = '';
                self.cusTaxExemptionNumber = '';
                self.cusAltCityName = '';
                self.cusAddressType = 'main address';
                self.customerCountyId = '';
                self.cusTaxRate = '';
                self.cusSalesTax = true;
                self.cusStateTaxRate = '';
                self.cusAltCountyName = '';
                self.cusCountyTaxRate = '';
                self.cusCityTaxRate = '';
                self.cusOtherTaxRate = '';
                self.cusOther2TaxRate = '';
                self.customerEmail = '';
                self.cusCountySurtax = '';
                self.cusCountyCode = '';
                // CO-Buyer
                self.buyerNumber = '';
                self.buyerId = '';
                self.buyerAddress = '';
                self.buyerAddress2 = '';
                self.buyerCityId = '';
                self.buyerCity2 = '';
                self.buyerCity3 = '';
                // Deliver-To
                self.deliverToPhoneNumber = '';
                self.deliverToId = '';
                self.deliverToAddress = '';
                self.deliverToAddress2 = '';
                self.deliverToCityId = '';
                self.deliverToCity2 = '';
                self.deliverToCity3 = '';
                self.internalComments = '';
                self.comments = '';
                self.comments2 = '';
                self.showOnBuyersOrder = false;
                // Cash Detail
                self.salesPrice = '';
                self.downpaymentAmt = 0.00;
                self.tradeAllownce = '';
                self.totalFees = '';
                self.totalSalesTax = '';
                self.total = '';
                self.warranties = '';
                self.insurance = '';
                self.totalWarranties = '';
                for (let i = (self.lineItemsArr.length - 1); i >= 0; i--) {
                    self.removeSupplier(i);
                }
                self.plusCountSupplier = 49;
                self.servicePlan = '';
                self.vehicleWarranty = '';
                self.roadsideAssistance = '';
                self.specificationUrl = '';
                self.specPages = '';
                self.pricebookId = '';
                // NEW FIELDS STARTED
                self.excedeIdHash = '';
                self.invoiceDate = '';
                self.invoiceHash = '';
                self.paymentType = '';
                self.orderType = '';
                self.downpaymentPaymentType = '';
                self.downpaymentDesc = '';
                self.cashBack = '';
                self.cashBackPaymentType = '';
                self.cashBackDesc = '';
                self.dueOnDelivery = '';
                self.dueOnDeliveryPaymentType = '';
                self.dueOnDeliveryDesc = '';
                self.physicalDamagePrice = 0.00;
                self.physicalDamageCost = 0.00;
                self.nonTruckingLiabilityPrice = 0.00;
                self.nonTruckingLiabilityCost = 0.00;
                self.gapInsurancePrice = 0.00;
                self.gapInsuranceCost = 0.00;
                self.disabilityPrice = 0.00;
                self.disabilityCost = 0.00;
                self.creditLifePrice = 0.00;
                self.creditLifeCost = 0.00;
                self.cvraPrice = 0.00;
                self.cvraCost = 0.00;
                self.annualePerRate = 0.00;
                self.dealerRate = 0.00;
                self.financeIncome = 0.00;
                self.financeTerm = '';
                self.daysToFirstPayment = 0.00;
                self.daysToFirstPaymentDate = '';
                self.financePayment = 0.00;
                self.salesSubtotal = 0.00;
                self.totalFinanced = 0.00;
                self.financeCharge = 0.00;
                self.deferredPrice = 0.00;
                self.selectFinanceCompany = '';
                self.financeCompany = '';
                self.financeCompanyAddress = '';
                self.financeCompanyCity = '';
                self.financeCompanyState = '';
                self.financeCompanyZip = '';
                self.financeCompanyComment = '';
                self.financeCompanyAlt = '';
                self.financeCompanyAltAddress = '';
                self.financeCompanyAltCity = '';
                self.financeCompanyAltState = '';
                self.financeCompanyAltZip = '';
                self.addNewRow();
            },
            loadDealData: function() {
                // console.log(JSON.stringify(self.dealData));
                self.conditionId = {text: "New", value: '1'};
                self.add_to_forecast = (self.dealData.is_forecast == 'yes') ? true : false;
                self.dealNo = self.dealData.deal_no;
                self.dealNumber = self.dealData.deal_no;
                self.companyId = (self.dealData.department_id != null && self.dealData.department_id != 'undefined' && self.dealData.department_id != 0) ? {text: self.dealData.department, value: self.dealData.department_id} : "";
                self.dealStatusId = (self.dealData.deal_status_id != null && self.dealData.deal_status_id != 'undefined' && self.dealData.deal_status_id != 0) ? {text: self.dealData.deal_status_name, value: self.dealData.deal_status_id} : "";
                self.contractNumber = self.dealData.contract_no;
                self.contractDate = self.dealData.contract_date;
                self.rosNumber = self.dealData.ros_no;
                self.salePersonId = (self.dealData.salesperson_id != null && self.dealData.salesperson_id != 'undefined' && self.dealData.salesperson_id != 0) ? {text: self.dealData.sales_person, value: self.dealData.salesperson_id} : "";
                self.cusPhoneNumber = self.dealData.cus_phone;
                self.businessName = (self.dealData.contact_id != null && self.dealData.contact_id != 'undefined' && self.dealData.contact_id != 0) ? {text: self.dealData.business_name, value: self.dealData.contact_id} : "";
                self.individual = (self.dealData.is_individual == 'yes') ? true : false;
                self.individualValue = self.dealData.individual;
                self.cusFax = self.dealData.cus_fax;
                self.cusAddress = self.dealData.cus_address;
                self.cusCell = self.dealData.cus_cell;
                self.cusAddress2 = self.dealData.cus_address2;
                self.cusSIN = self.dealData.cus_sin;
                self.customerCityId = (self.dealData.customer_city_id != null && self.dealData.customer_city_id != 'undefined' && self.dealData.customer_city_id != 0) ? {text: self.dealData.customer_city_name, value: self.dealData.customer_city_id} : "";
                self.cusCity2 = self.dealData.city2;
                self.customerStateId = (self.dealData.customer_state_id != null && self.dealData.customer_state_id != 'undefined' && self.dealData.customer_state_id != 0) ? {text: self.dealData.customer_state_name, value: self.dealData.customer_state_id} : "";
                self.cusTaxExemptionNumber = self.dealData.cus_tax_exemption_number;
                self.cusAltCityName = self.dealData.alt_city;
                self.cusAddressType = self.dealData.address_type;
                self.customerCountyId = (self.dealData.customer_country_id != null && self.dealData.customer_country_id != 'undefined' && self.dealData.customer_country_id != 0) ? {text: self.dealData.customer_country_name, value: self.dealData.customer_country_id} : "";
                self.cusTaxRate = self.dealData.tax_rate;
                self.cusSalesTax = (self.dealData.apply_sales_tax == 'yes') ? true : false;
                self.cusStateTaxRate = self.dealData.state_tax_rate;
                self.cusAltCountyName = self.dealData.alt_county;
                self.cusCountyTaxRate = self.dealData.county_tax_rate;
                self.cusCityTaxRate = self.dealData.city_tax_rate;
                self.cusOtherTaxRate = self.dealData.other_tax_rate;
                self.cusOther2TaxRate = self.dealData.other2_tax_rate;
                self.customerEmail = self.dealData.cus_email;
                self.cusCountySurtax = self.dealData.county_surtax;
                self.cusCountyCode = self.dealData.county_code;
                // CO-Buyer
                self.buyerNumber = self.dealData.co_buyer;
                self.buyerId = (self.dealData.co_buyer_contact_id != null && self.dealData.co_buyer_contact_id != 'undefined' && self.dealData.co_buyer_contact_id != 0) ? {text: self.dealData.cobuyer_name, value: self.dealData.co_buyer_contact_id} : "";
                self.buyerAddress = self.dealData.co_buyer_cus_address;
                self.buyerAddress2 = self.dealData.co_buyer_cus_address2;
                self.buyerCityId = (self.dealData.cobuyer_city_id != null && self.dealData.cobuyer_city_id != 'undefined' && self.dealData.cobuyer_city_id != 0) ? {text: self.dealData.cobuyer_city_name, value: self.dealData.cobuyer_city_id} : "";
                self.buyerCity2 = self.dealData.co_buyer_city2;
                self.buyerCity3 = self.dealData.co_buyer_city3;
                // Deliver-To
                self.deliverToPhoneNumber = self.dealData.del_to_phone;
                self.deliverToId = (self.dealData.del_to_contact_id != null && self.dealData.del_to_contact_id != 'undefined' && self.dealData.del_to_contact_id != 0) ? {text: self.dealData.dealer_name, value: self.dealData.del_to_contact_id} : "";
                self.deliverToAddress = self.dealData.del_to_cus_address;
                self.deliverToAddress2 = self.dealData.del_to_cus_address2;
                self.deliverToCityId = (self.dealData.dealer_city_id != null && self.dealData.dealer_city_id != 'undefined' && self.dealData.dealer_city_id != 0) ? {text: self.dealData.dealer_city_name, value: self.dealData.dealer_city_id} : "";
                self.deliverToCity2 = self.dealData.del_to_city2;
                self.deliverToCity3 = self.dealData.del_to_city3;
                self.internalComments = self.dealData.internal_comments;
                self.comments = self.dealData.comments;
                self.comments2 = self.dealData.comments2;
                self.showOnBuyersOrder = (self.dealData.show_on_buyer_order == 'yes') ? true : false;
                // Cash Detail
                self.salesPrice = self.convertToNum(self.dealData.sales_price,2);
                self.downpaymentAmt = self.convertToNum(self.dealData.down_payment,2);
                self.tradeAllownce = self.convertToNum(self.dealData.trade_allowance,2);
                self.totalFees = self.convertToNum(self.dealData.total_fees,2);
                self.totalSalesTax = self.convertToNum(self.dealData.total_sales_tax,2);
                self.total = self.convertToNum(self.dealData.total,2);
                self.warranties = self.convertToNum(self.dealData.warranties,2);
                self.insurance = self.convertToNum(self.dealData.insurance,2);
                self.totalWarranties = self.convertToNum(self.dealData.total_plus_wattanties,2);
                self.bonus_amount = self.convertToNum(self.dealData.bonus,2);
                self.extra_conquest = self.convertToNum(self.dealData.extra_conquest,2);
                self.gross_profit = self.convertToNum(self.dealData.gross_profit,2);
                self.locationId = (self.dealData.location_id != null && self.dealData.location_id != 'undefined' && self.dealData.location_id != 0) ? {text: self.dealData.location, value: self.dealData.location_id} : "";
                self.pricebookId = (self.dealData.deal_pricebook_id != null && self.dealData.deal_pricebook_id != 'undefined' && self.dealData.deal_pricebook_id != 0) ? {text: self.dealData.deal_pricebook_name, value: self.dealData.deal_pricebook_id} : "";
                
                self.stampDutyType  = {text: 'New/Demo', value: 'new/demo'};
                if(self.dealData.stamp_duty_type == 'exempt'){
                    self.stampDutyType  = {text: 'Exempt', value: 'exempt'};
                }
                
                self.rego = self.convertToNum(self.dealData.rego,2);
                self.ctp = self.convertToNum(self.dealData.ctp,2);
                self.stamp_duty = self.convertToNum(self.dealData.stamp_duty,2);
                self.trade_detail = self.dealData.trade_details;
                self.registration = self.convertToNum(self.dealData.registration,2);
                self.trade_value_inc_gst = self.convertToNum(self.dealData.approx_trade_value,2);
                self.over_allowance_inc_gst = self.convertToNum(self.dealData.over_allowance,2);
                self.trade_payout = self.convertToNum(self.dealData.trade_payout,2);
                self.model_description = ' - '+self.dealData.deal_pricebook_description.trim()
                /* console.log(JSON.stringify(self.dealData.deal_inventory_data)); */
                if(self.dealData.deal_inventory_data.length == 1){
                    self.makeId = {text: self.dealData.deal_inventory_data[0].make, value: self.dealData.deal_inventory_data[0].make_id};
                    self.modelId = {text: self.dealData.deal_inventory_data[0].model_display, value: self.dealData.deal_inventory_data[0].model_id};
                    self.servicePlan = self.dealData.deal_inventory_data[0].service_plan;
                    self.vehicleWarranty = self.dealData.deal_inventory_data[0].vehicle_warranty;
                    self.roadsideAssistance = self.dealData.deal_inventory_data[0].roadside_assistance;
                    self.specificationUrl = self.dealData.deal_inventory_data[0].specification_pdf_url;
                    self.specPages = self.dealData.deal_inventory_data[0].specification_pdf_pages;
                } else {
                    self.makeId = 0;
                    self.modelId = 0;
                    self.servicePlan = "";
                    self.vehicleWarranty = "";
                    self.roadsideAssistance = "";
                    self.specificationUrl = "";
                    self.specPages = "";
                }
                self.excedeIdHash = self.dealData.excede_id;
                self.invoiceDate = self.dealData.invoice_date;
                self.invoiceHash = self.dealData.invoice_number;
                self.paymentType = (self.dealData.payment_type_id != null && self.dealData.payment_type_id != 'undefined' && self.dealData.payment_type_id != 0) ? {text: self.dealData.payment_type_name, value: self.dealData.payment_type_id} : "";
                self.orderType = (self.dealData.order_type_id != null && self.dealData.order_type_id != 'undefined' && self.dealData.order_type_id != 0) ? {text: self.dealData.order_type_name, value: self.dealData.order_type_id} : "";
                self.downpaymentPaymentType = (self.dealData.down_payment_type_id != null && self.dealData.down_payment_type_id != 'undefined' && self.dealData.down_payment_type_id != 0) ? {text: self.dealData.down_payment_type_name, value: self.dealData.down_payment_type_id} : "";
                self.downpaymentDesc = self.dealData.downpayment_desc;
                self.cashBack = self.dealData.cash_back;
                self.cashBackPaymentType = (self.dealData.cash_back_type_id != null && self.dealData.cash_back_type_id != 'undefined' && self.dealData.cash_back_type_id != 0) ? {text: self.dealData.cashback_payment_type_name, value: self.dealData.cash_back_type_id} : "";
                self.cashBackDesc = self.dealData.cash_back_desc;
                self.dueOnDelivery = self.dealData.due_payment;
                self.dueOnDeliveryPaymentType = (self.dealData.due_payment_id != null && self.dealData.due_payment_id != 'undefined' && self.dealData.due_payment_id != 0) ? {text: self.dealData.due_payment_type_name, value: self.dealData.due_payment_id} : "";
                self.dueOnDeliveryDesc = self.dealData.due_on_delivery_desc;
                self.physicalDamagePrice = self.dealData.physical_damage_price;
                self.physicalDamageCost = self.dealData.physical_damage_cost;
                self.nonTruckingLiabilityPrice = self.dealData.non_trucking_liability_price;
                self.nonTruckingLiabilityCost = self.dealData.non_trucking_liability_cost;
                self.gapInsurancePrice = self.dealData.gap_insurance_price;
                self.gapInsuranceCost = self.dealData.gap_insurance_cost;
                self.disabilityPrice = self.dealData.disability_price;
                self.disabilityCost = self.dealData.disability_cost;
                self.creditLifePrice = self.dealData.credit_life_price;
                self.creditLifeCost = self.dealData.credit_life_cost;
                self.cvraPrice = self.dealData.cvra_price;
                self.cvraCost = self.dealData.cvra_cost;
                self.annualePerRate = self.dealData.finance_annuale_per_rate;
                self.dealerRate = self.dealData.dealer_rate;
                self.financeIncome = self.dealData.dealer_finance_income;
                self.financeTerm = self.dealData.dealer_term;
                self.daysToFirstPayment = self.dealData.finance_days_to_first_payment;
                self.daysToFirstPaymentDate = self.dealData.finance_first_payment_date;
                self.financePayment = self.dealData.finance_payment;
                self.salesSubtotal = self.dealData.finance_sales_subtotal;
                self.totalFinanced = self.dealData.finance_total;
                self.financeCharge = self.dealData.finance_charge;
                self.deferredPrice = self.dealData.finance_deferred_price;
                self.selectFinanceCompany = self.dealData.finance_company;
                self.financeCompany = self.dealData.finance_company_name;
                self.financeCompanyAddress = self.dealData.finance_company_address;
                self.financeCompanyCity = self.dealData.finance_company_city;
                self.financeCompanyState = (self.dealData.finance_company_state != null && self.dealData.finance_company_state != 'undefined' && self.dealData.finance_company_state != 0) ? {text: self.dealData.finance_company_state_name, value: self.dealData.finance_company_state} : "";
                self.financeCompanyZip = self.dealData.finance_company_zip;
                self.financeCompanyComment = self.dealData.finance_company_comment;
                self.financeCompanyAlt = self.dealData.finance_company_alt_name;
                self.financeCompanyAltAddress = self.dealData.finance_company_alt_address;
                self.financeCompanyAltState = (self.dealData.finance_company_alt_state != null && self.dealData.finance_company_alt_state != 'undefined' && self.dealData.finance_company_alt_state != 0) ? {text: self.dealData.finance_company_alt_state_name, value: self.dealData.finance_company_alt_state} : "";
                self.financeCompanyAltCity = self.dealData.finance_company_alt_city;
                self.financeCompanyAltZip = self.dealData.finance_company_alt_zip;
                // self.businessName = self.dealData.business_name;
                self.customerId = self.dealData.customer_name;
                self.days_stock_type = {text: 'In Stock (45 days interest-free)', value: 'in_stock'};
                if(self.dealData.stock_type == 'ordered_stock'){
                    self.days_stock_type = {text: 'Ordered Stock (90 days interest-free)', value: 'ordered_stock'};
                }
                self.expected_age_days_at_time_of_delivery = self.dealData.expected_days;
                self.days_customer_paying_within_interest_free_period = {text: 'No', value: 'no'};
                if(self.dealData.is_customer_paying_interest == 'yes'){
                    self.days_customer_paying_within_interest_free_period = {text: 'Yes', value: 'yes'};
                }
                self.calculateInterestForDays();
                self.stock = self.dealData.stock;
                self.delivery_date = self.dealData.delivery_date ? self.dealData.delivery_date : self.dealData.contract_date;
                self.vin_no = self.dealData.vin_no;
                self.comm_no = self.dealData.comm_no;
                self.dealer_date = self.dealData.dealer_date;
                self.quantity = self.dealData.quantity;
                self.cq_approval = self.dealData.cq_approval;
                self.cq_notes = self.dealData.cq_notes;
                self.bonus_notes = self.dealData.bonus_notes;
                self.factory_promo = self.dealData.factory_promo;
                self.rego_number = self.dealData.rego_number;
                self.rego_date = self.dealData.rego_date;
                self.cq_submission_date = self.dealData.cq_submission_date;
                self.rda_approval_number = self.dealData.rda_approval_number;
                self.cq_entered_in_dfe = self.dealData.cq_entered_in_dfe ? true : false;
                self.customer_details_entered_in_dfe = self.dealData.customer_details_entered_in_dfe ? true : false;
                self.rda_date = self.dealData.rda_date;
                self.body_type = self.dealData.body_type ? JSON.parse(self.dealData.body_type) : self.body_type;
                self.conquest_obj = self.dealData.conquest ? JSON.parse(self.dealData.conquest) : self.conquest_obj;
                self.rda_stamp = self.dealData.rda_stamp;
                self.cus_address2 = self.dealData.suburb;
                self.abn = self.dealData.abn;
                self.delivery_charges = self.convertToNum(self.dealData.delivery_charges,2);
                self.ctp_label = self.dealData.ctp_label
                self.series = {text: self.dealData.type, value: self.dealData.type}
            },
            calculateQuot: function(){
                self.salesPrice = self.convertToNum(self.salesPrice,2);
                self.bonus_amount = self.convertToNum(self.bonus_amount,2);
                self.extra_conquest = self.convertToNum(self.extra_conquest,2);
                self.gross_profit = self.convertToNum(self.gross_profit,2);
                self.ctp = self.convertToNum(self.ctp,2);
                self.downpaymentAmt = self.convertToNum(self.downpaymentAmt,2);
                self.registration = self.convertToNum(self.registration,2);
                self.trade_value_inc_gst = self.convertToNum(self.trade_value_inc_gst,2);
                self.over_allowance_inc_gst = self.convertToNum(self.over_allowance_inc_gst,2);
                self.delivery_charges = self.convertToNum(self.delivery_charges,2);
                
                self.trade_payout = self.convertToNum(self.trade_payout,2);
                self.overallowance = self.convertToNum((self.over_allowance_inc_gst / 1.1),2);

                var totalOfAllFactoryItems = 0;
                for(var index = 0; index < self.factoryItemsArr.length; index++) {
                    var factoryItemObject = self.factoryItemsArr[index];
                    if (factoryItemObject.factoryPriceGst) {
                        totalOfAllFactoryItems = parseFloat(totalOfAllFactoryItems) + parseFloat(factoryItemObject.factoryPriceGst);
                    }
                    factoryItemObject.factoryPriceGst = self.convertToNum(factoryItemObject.factoryPriceGst,2); 
                }
                self.factory_options_total = self.convertToNum(totalOfAllFactoryItems,2);
                self.dealer_net_invoice_price = self.convertToNum(parseFloat(self.dealer_net) + parseFloat(self.factory_options_total), 2);
                self.sub_total1 = self.convertToNum((parseFloat(self.dealer_net) - (parseFloat(self.bonus_amount) + parseFloat(self.extra_conquest) + parseFloat(self.conquest) + parseFloat(self.factory_options_total))),2);
                self.dealer_cost = self.convertToNum(parseFloat(self.dealer_net) + parseFloat(self.factory_options_total) - parseFloat(self.extra_conquest) - parseFloat(self.bonus_amount),2);
                if (self.days_customer_paying_within_interest_free_period.value == 'no') {
                    if (self.days_stock_type.value == 'ordered_stock') {
                        if (self.expected_age_days_at_time_of_delivery > 90) {
                            self.days_of_interest_charged = self.expected_age_days_at_time_of_delivery - 90
                        } else {
                            self.resetDaysInterest()
                        }
                    } else if (self.days_stock_type.value == 'in_stock') {
                        if (self.expected_age_days_at_time_of_delivery > 45) {
                            self.days_of_interest_charged = self.expected_age_days_at_time_of_delivery - 45
                        } else {
                            self.resetDaysInterest()
                        }
                    }
                    self.days_interest_charged = self.convertToNum((parseFloat((self.dealer_net_invoice_price) * (self.interest_rate/100) * (self.days_of_interest_charged/365))),2);
                } else {
                    self.resetDaysInterest()
                }
                self.cab_chassis = self.convertToNum((parseFloat(self.dealer_cost) + parseFloat(self.pre_delivery) + parseFloat(self.delivery_charges) + parseFloat(self.days_interest_charged) + parseFloat(self.gross_profit)),2);
                if (self.over_allowance_inc_gst != 0.00) {
                    self.cab_chassis_including_overallowance = self.convertToNum(parseFloat(self.cab_chassis) + (self.over_allowance_inc_gst/1.1), 2);
                } else {
                    self.cab_chassis_including_overallowance = self.cab_chassis;
                }
                var totalOfAllItems = 0;
                for(var index = 0; index < self.lineItemsArr.length; index++) {
                    var lineItemObject = self.lineItemsArr[index];
                    if (lineItemObject.priceGST) {
                        totalOfAllItems = parseFloat(totalOfAllItems) + parseFloat(lineItemObject.priceGST);
                    }
                    lineItemObject.priceGST = self.convertToNum(lineItemObject.priceGST,2);
                }
                self.build_up_total = self.convertToNum(totalOfAllItems,2);
                self.sub_total_ex_gst = self.convertToNum((parseFloat(self.cab_chassis_including_overallowance) + parseFloat(self.build_up_total)),2);
                self.sub_total_ex_gst_plus_calculated_interest = self.convertToNum((parseFloat(self.days_interest_charged) + parseFloat(self.sub_total_ex_gst)),2);
                self.total_gst = self.convertToNum((parseFloat(self.sub_total_ex_gst) * (10/100)),2);
                self.sub_total_incl_gst = self.convertToNum((parseFloat(self.sub_total_ex_gst) + parseFloat(self.total_gst)),2);
                if (self.gross_profit != 0.00 && self.cab_chassis != 0.00) {
                    self.gross_profit_percentage = self.convertToNum(((parseFloat(self.gross_profit) / parseFloat(self.sub_total_ex_gst)) * 100),2);
                }
                self.stamp_duty = self.convertToNum((parseFloat(self.stamp_duty)),2);
                self.total_on_road_price = self.convertToNum(parseFloat(self.sub_total_incl_gst) + parseFloat(self.stamp_duty) + parseFloat(self.registration) + parseFloat(self.ctp), 2);
                self.total = self.convertToNum((parseFloat(self.sub_total_incl_gst) + parseFloat(self.stamp_duty) + parseFloat(self.ctp) + parseFloat(self.registration)/*  + parseFloat(self.totalFees) *//*  + parseFloat(self.totalSalesTax) *//*  + parseFloat(self.insurance) */ - parseFloat(self.downpaymentAmt)),2);
                self.tradeAllownce = self.convertToNum((parseFloat(self.trade_value_inc_gst) + parseFloat(self.over_allowance_inc_gst)),2);
                self.trade_equity = self.convertToNum((parseFloat(self.tradeAllownce) - parseFloat(self.trade_payout)),2);
                self.change_over = self.convertToNum((parseFloat(self.total) - parseFloat(self.trade_equity)),2);
                self.less_trade_equity = self.convertToNum((parseFloat(self.total_on_road_price) - parseFloat(self.trade_equity)),2);
            },
            onSearchState(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search, self.customerCountyId.value, '','','','','',self.conditionId.text.toLowerCase());
                }
            },
            updateStampDutyType() {
                // self.stamp_duty = 0.00;
                // if(self.stampDutyType.value != "exempt"){
                //     self.stamp_duty = self.convertToNum(((parseFloat(self.sub_total_incl_gst) * (parseFloat(self.stampDutyPercentage) / 100)) + parseFloat(self.stamp_duty)),2);
                // }
                // self.calculateQuot()
            },
            updateBusiness () {
                self.businessNameError = ''
                // self.customerError = ''
                if (self.businessName) {
                    self.cusAddress = self.businessName.address
                    self.cusAddress2 = self.businessName.address2
                    self.customerCityId = {text: self.businessName.cityName, value: self.businessName.cityId}
                    self.customerStateId = {text: self.businessName.stateName, value: self.businessName.stateId}
                    self.customerCountyId = {text: self.businessName.countryName, value: self.businessName.countryId}
                    self.cusCity2 = self.businessName.zipCode
                    self.cusPhoneNumber = self.businessName.primaryPhone
                    self.cusCell = self.businessName.alternatePhone
                    self.customerEmail = self.businessName.email
                } else {
                    self.cusAddress = null
                    self.cusAddress2 = null
                    self.customerCityId = null
                    self.customerStateId = null
                    self.customerCountyId = null
                    self.cusCity2 = null
                    self.cusPhoneNumber = null
                    self.cusCell = null
                    self.customerEmail = null
                }
            },
            updateCountry () {
                self.customerCountyError = ""
                self.loadAllData(['States'], false, self.loadStateDataCallback, 'undefined', '', self.customerCountyId.value, '','','','','',self.conditionId.text.toLowerCase());
            },
            updateState () {
                self.loadAllData(['Cities'], false, self.loadCustomerCityCallback, 'undefined', '', self.customerStateId.value, '','','','','',self.conditionId.text.toLowerCase());
            },
            next (type, value) {
                if (self.checkForm()) {
                    self.type = type
                    // console.log('next', self.dealId)
                    if (type == 'conquest') {
                        self.$router.push({ name: 'Conquest', query: { id: self.dealId }})
                        self.quotationData = {
                            'sP': self.salePersonId.text + ' - ' + self.salesPersonContactNo,
                            'buyer': self.businessName,
                            'cust': self.customerId.text,
                            'model': self.modelId.text,
                            'factoryItemsArr': self.factoryItemsArr,
                            'lineItemsArr': self.lineItemsArr,
                            'customerFirstName': self.customerId.first_name,
                            'customerSurName': self.customerId.last_name,
                            'customerCompany': value.customerCompany ? value.customerCompany : self.customerId.companyName,
                            'abn': value.abn,
                            'customerAddress': self.customerId.address,
                            'customerPostCode': self.customerId.zipCode,
                            'customerSuburb': value.customerSuburb ? value.customerSuburb : self.cusAddress2,
                            'customerState': self.customerId.stateName,
                            'customerEmail': self.customerId.email,
                            'customerPhone': self.customerId.primaryPhone,
                            'dealerName': self.locationId.text,
                            'dealerCode': self.locationId.locId,
                            'conquestAmount': self.extra_conquest,
                            'bonusAmount': self.bonus_amount,
                            'stock': value.stock ? value.stock : null,
                            'dealNo': self.dealNumber, // for reference
                            'deliveryDate': value.deliveryDate ? value.deliveryDate.split("/").reverse().join("/") : self.contractDate.split("/").reverse().join("/")
                        }
                    } else {
                        self.$router.push({ name: 'BuildUp', query: { id: self.dealId }})
                        // self.quotationData = {
                        //     'sP': self.salePersonId.text + ' - ' + self.salesPersonContactNo,
                        //     'buyer': self.businessName,
                        //     'cust': self.customerId.text,
                        //     'model': self.modelId.text,
                        //     'factoryItemsArr': self.factoryItemsArr,
                        //     'lineItemsArr': self.lineItemsArr,
                        //     'customerFirstName': self.customerId.first_name,
                        //     'customerSurName': self.customerId.last_name,
                        //     'customerCompany': self.customerId.companyName,
                        //     'abn': '',
                        //     'customerAddress': self.customerId.address,
                        //     'customerPostCode': self.customerId.zipCode,
                        //     'customerSuburb': self.cusAddress2 ? self.cusAddress2 : self.customerId.address2,
                        //     'customerState': self.customerId.stateName,
                        //     'customerEmail': self.customerId.email,
                        //     'customerPhone': self.customerId.primaryPhone,
                        //     'dealerName': self.locationId.text,
                        //     'dealerCode': self.locationId.locId,
                        //     'conquestAmount': self.extra_conquest,
                        //     'bonusAmount': self.bonus_amount,
                        //     'stock': value ? value : null,
                        //     'dealNo': self.dealNumber, // for reference
                        //     'deliveryDate': self.contractDate,
                        //     'dealId': self.dealId
                        // }
                    }
                    if (self.isQuotationUpdate) {
                        self.saveBookDeal()
                    }
                    self.isQuotationUpdate = false
                }
            },
            back (type, value) {
                // console.log('back type ', type)
                // console.log('back value ', value)
                self.type = type
                if (self.type == 'quotation') {
                    self.isQuotationUpdate = true
                }
                // console.log('back deal id ', value.dealId)
                self.dealId = value.dealId
                self.getDealData()
            },
            onSearchBusiness(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadContactCallback, loading, search);
                }
            },
            loadContactCallback: function(callbackData) {
                self.isBusinessRecordNotFound = false;
                self.businessNameArr = callbackData.Contact;
                if(self.businessNameArr.length == 0) {
                    self.isBusinessRecordNotFound = true;
                }
            },
            calculateInterestForDays () {
                if (self.days_customer_paying_within_interest_free_period.value == 'no') {
                    if (self.days_stock_type.value == 'ordered_stock') {
                        if (self.expected_age_days_at_time_of_delivery > 90) {
                            self.days_of_interest_charged = self.expected_age_days_at_time_of_delivery - 90
                        } else {
                            self.resetDaysInterest()
                        }
                    } else if (self.days_stock_type.value == 'in_stock') {
                        if (self.expected_age_days_at_time_of_delivery > 45) {
                            self.days_of_interest_charged = self.expected_age_days_at_time_of_delivery - 45
                        } else {
                            self.resetDaysInterest()
                        }
                    }
                    self.days_interest_charged = self.convertToNum((parseFloat((self.dealer_net_invoice_price) * (self.interest_rate/100) * (self.days_of_interest_charged/365))),2);
                } else {
                    self.resetDaysInterest()
                }
                self.sub_total_ex_gst_plus_calculated_interest = self.convertToNum((parseFloat(self.days_interest_charged) + parseFloat(self.sub_total_ex_gst)),2);
            },
            resetDaysInterest () {
                self.days_of_interest_charged = 0
                self.days_interest_charged = 0.00
            },
            calculateInterestForMonths () {
                if (self.months_customer_paying_within_interest_free_period.value == 'no') {
                    if (self.months_stock_type.value == 'ordered_stock') {
                        if ((self.expected_age_months_at_time_of_delivery * 30) > 90) {
                            self.months_of_interest_charged = (self.expected_age_months_at_time_of_delivery * 30) - 90
                        } else {
                            self.resetMonthsInterest()
                        }
                    } else if (self.months_stock_type.value == 'in_stock') {
                        if ((self.expected_age_months_at_time_of_delivery * 30) > 45) {
                            self.months_of_interest_charged = (self.expected_age_months_at_time_of_delivery * 30) - 45
                        } else {
                            self.resetMonthsInterest()
                        }
                    }
                    self.months_interest_charged = self.convertToNum((parseFloat(self.sub_total_ex_gst * (self.interest_rate/100) * (self.months_of_interest_charged/365))),2);
                } else {
                    self.resetMonthsInterest()
                }
            },
            resetMonthsInterest () {
                self.months_of_interest_charged = 0
                self.months_interest_charged = 0.00
            },
            formatPrice (value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            updatedSeries () {
                self.modelId = 0
                self.seriesError = null
            }
        }
    }
    EventBus.$on("work-sheet-callback", function (callbackOpportunityId, callbackAssetId) {
        if (typeof self != "undefined") {
            self.vehicleWorkSheet();
            if(callbackOpportunityId != 0 && callbackAssetId != 0) {
                self.getOpportunityInventory(callbackOpportunityId);
                // console.log('Callback Opportunity Id ' + callbackOpportunityId);
                // console.log('Callback Asset Id ' + callbackAssetId);
            }
        }
    });
</script>
<style>
.disabled {
    background-color: #e9ecef;
}
</style>