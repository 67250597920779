<template>
  <!-- Page Content Wrapper -->
  <div :id="openAt == 'pageView' ? 'page-content-wrapper' : 'popup-content-wrapper'" class="admin-content-area">
    <template
      v-if="
        permissionNameArray.includes('can_add_activity_master') &&
          userId.value == createdBy
      "
    >
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}
          </div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            {{openAt == 'pageView' ? 'Back' : 'Close'}}
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->

          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >

            <div class="row no-margin">
              
              <div class="col-6 forms-block">
                  <div class="col-5  text-right pr-imp-25">
                      <div class="form-label"><label for="is_followup_activity">{{
                          staticInputLabelMessages.is_followup_activity_label }}</label>
                      </div>
                  </div>
                  <div class="col-6 paddingleft0 custom-checkbox-v">
                      <label class="container">
                          <input id="is_followup_activity" v-model="is_followup_activity" value="true"
                              class="form-control" type="checkbox" />
                          <span class="checkmark"></span>
                      </label>
                  </div>
              </div>
               <!-- Parent Activity Selection -->
               <div v-show="is_followup_activity" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.parent_activity_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                      <v-select
                        v-model="parentActivity"
                        name="parentActivityId"
                        id="parentActivityId"
                        label="text"
                        @change="parentActivityChangedValue"
                        @input="parentActivityError = ''"
                        :filterable="false"
                        :options="parentActivityArr"
                        @search="onSearchActivity"
                        :placeholder="staticInputLabelMessages.activity_search_placeholder"
                      >
                        <span slot="no-options" @click="$refs.select.open = false">
                          <template v-if="!isParentActivityRecordNotFound">
                            {{ staticMessages.activity_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                </div>
              </div>
            </div>

            <div class="row no-margin">
              <!-- Home Department -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.department_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Department"
                                              v-model="departmentId"
                                              @change="changedValue"
                                              @input="departmentError = ''"
                                              name="departmentId"
                                              id="departmentId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="departmentArr"
                                              :placeholder="staticMessages.department_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{departmentError}}</span>
                                </div>
                            </div> -->
              <!--Module-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.module_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="moduleData"
                                        @change="changedValue"
                                        @input="moduleDataError = ''"
                                        name="moduleData"
                                        id="moduleData"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="moduleDataArr"
                                        :placeholder="staticMessages.module_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{moduleDataError}}</span>
                                </div>
                            </div> -->

              <!-- Customer -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="contactGroupId"
                    name="customer_id"
                    id="customer_id"
                    label="text"
                    @change="changedCustomerValue"
                    @input="contactGroupIdError = ''"
                    :filterable="false"
                    :options="contactGroupIdArr"
                    @search="onSearchContactGroup"
                    :placeholder="staticMessages.contact_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isContactGroupRecordNotFound">
                        {{ staticMessages.contact_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ contactGroupIdError }}</span>
                </div>
              </div>


              <!-- User -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.user_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    disabled
                    v-model="userId"
                    name="userId"
                    id="userId"
                    label="text"
                    @change="changedValue"
                    @input="userIdError = ''"
                    :filterable="false"
                    :options="userIdArr"
                    @search="onSearchUser"
                    :placeholder="staticMessages.user_place_holder"
                  >
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ userIdError }}</span>
                </div>
              </div>

              <!-- Customer ABN No -->
              <div v-show="production_country == 'AU'" class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{ staticInputLabelMessages.customer_abn_label }}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                      <input disabled @input="anbNoError = ''" id="abn_no" v-model="abn_no"
                          class="form-control" type="text" maxlength="50" />
                  </div>
              </div>
              

              <!-- Location -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                        v-model="locationId"
                                        @change="changedValue"
                                        @input="locationIdError = ''"
                                        name="locationId"
                                        id="locationId"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="locationIdArr"
                                        :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationIdError}}</span>
                                </div>
                            </div> -->

              

              <!-- Assign User -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.assign_user_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="assignUserId"
                    name="assignUserId"
                    id="assignUserId"
                    label="text"
                    @change="changedValue"
                    @input="assignUserIdError = ''"
                    :filterable="false"
                    :options="assignUserIdArr"
                    @search="onSearchAssignUser"
                    :placeholder="staticMessages.assign_user_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isAssingUserRecordNotFound">
                        {{ staticMessages.assign_used_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <!-- <div class="requiredstar">*</div> -->
                  <span class="help is-danger">{{ assignUserIdError }}</span>
                </div>
              </div>

              <!-- Customer Contact -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.contact_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="customerContactId"
                    name="customerContactId"
                    id="customerContactId"
                    label="text"
                    @change="changedValue"
                    @input="customerContactIdError = ''"
                    :filterable="false"
                    :options="customerContactIdArr"
                    @search="onSearchCustomerContact"
                    :placeholder="staticMessages.customer_contact_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCustomerContactRecordNotFound">
                        {{ staticMessages.customer_contact_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <span class="help is-danger">{{ customerContactIdError }}</span>
                </div>
              </div>
              
              <!-- Asset -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.asset_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="assetId"
                    name="assetId"
                    id="assetId"
                    label="text"
                    @change="changedValue"
                    @input="assetIdError = ''"
                    :filterable="false"
                    :options="assetIdArr"
                    @search="onSearchAsset"
                    :placeholder="staticMessages.asset_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isAssetRecordNotFound">
                        {{ staticMessages.asset_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <!-- <div class="requiredstar">*</div> -->
                  <span class="help is-danger">{{ assetIdError }}</span>
                </div>
              </div>
              
              <!-- Activity Date Time -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.activity_time_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <date-picker
                    v-model="activityDateTime"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    :placeholder="staticMessages.activity_datetime_place_holder"
                    name="activityDateTime"
                    @input="changeActivityDateTime()"
                  >
                  </date-picker>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{
                    activityDateTimeError
                  }}</span>
                </div>
              </div>

                <!--Opportinuity -->
                <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                      <div class="form-label">
                          {{ staticInputLabelMessages.select_opportunity_label }}
                      </div>
                  </div>
                  <div class="col-9 paddingleft0">
                      <v-select
                          v-model="selectedOpportunity"
                          name="OpportunityId"
                          id="OpportunityId"
                          label="text"
                          @change="changedOpportunityValue"
                          @input="selectedOpportunityError = ''"
                          :filterable="false"
                          :options="opportunityIdArr"
                          @search="onSearchOpportunity"
                          :placeholder="staticMessages.select_opportunity_placeholder"
                      >
                          <span slot="no-options" @click="$refs.select.open = false">
                          <template v-if="!isOpportunityRecordNotFound">
                              {{ staticMessages.select_opportunity_search }}
                          </template>
                          <template v-else>
                              {{ staticMessages.search_record_not_found }}
                          </template>
                          </span>
                      </v-select>
                      <!-- <div class="requiredstar">*</div> -->
                      <span class="help is-danger">{{ selectedOpportunityError }}</span>
                  </div>
              </div>

              <!--type-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.activity_type_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="type"
                    @change="changedValue"
                    @input="typeError = ''"
                    name="type"
                    id="type"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="typeArr"
                    :placeholder="staticMessages.activity_type_place_holder"
                  >
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ typeError }}</span>
                </div>
              </div>


              <!-- Other Type -->
              <div v-show="isActivityTypeOther" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.other_type_text_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="otherActivityTypeText"
                    v-model="otherActivityTypeText"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{
                    otherActivityTypeTextError
                  }}</span>
                </div>
              </div>

             

              <!-- Priority -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.priority_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="priority"
                    name="priority"
                    @input="priorityError = ''"
                    label="text"
                    :filterable="false"
                    :options="priorityArr"
                    :clearable="false"
                    :placeholder="staticMessages.select_priority"
                  >
                  </v-select>
                  <!-- <div class="requiredstar">*</div> -->
                  <span class="help is-danger">{{ priorityError }}</span>
                </div>
              </div>
              <!-- Direction -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.direction_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="direction"
                    name="direction"
                    @input="directionError = ''"
                    label="text"
                    :filterable="false"
                    :options="directionArr"
                    :clearable="false"
                    :placeholder="staticMessages.direction_place_holder"
                  >
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ priorityError }}</span>
                </div>
              </div>

              <!-- Subject -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.subject_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="subjectError = ''"
                    id="subject"
                    v-model="subject"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ subjectError }}</span>
                </div>
              </div>

              <!--Status-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.status_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                        v-model="status"
                        @change="changedValue"
                        @input="statusError = ''"
                        name="status"
                        id="status"
                        label="text"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        :options="statusArr"
                        :placeholder="staticMessages.status_place_holder"
                  >
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ statusError }}</span>
                </div>
              </div>

              <!-- Notes -->
              <div class="col-12 forms-block">
                <div class="col-12 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.notes_label }}
                  </div>
                </div>
                <div class="col-12 paddingleft0">
                  <textarea
                    id="notes"
                    v-model="notes"
                    class="form-control"
                  ></textarea>
                </div>
              </div>



            </div>

            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  class="alert alert-danger"
                  id="alertError"
                  style="display :none;"
                >
                  <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo ml-3">
              <!-- <input
                id="addActivityComment"
                type="submit"
                :value="buttonNames.addComment"
                class="btn btn-primary"
                v-on:click.prevent="openAddCommentPopup()"
              /> -->
              
              <input
                id="addActivityBtn"
                :disabled="disableButtons.save"
                type="submit"
                :value="buttonNames.save"
                class="btn btn-primary"
                v-on:click.prevent="addActivity()"
              />
              <input
                v-show="showSaveAndContinue"
                id="addAndContinueActivityBtn"
                :disabled="disableButtons.saveAndContinue"
                type="submit"
                :value="buttonNames.saveAndContinue"
                class="btn btn-primary"
                v-on:click.prevent="addActivity((flag = 'Save and Continue'))"
              />
              <input
                id="cancelActivity"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>

            <div class="row clearfix "></div>
            <div class="clearfix"></div>
            <!-- START: Comments Section -->
            
            <div v-show="editId" class="row no-margin pt-40">
              <div class="page-header-top-container">
                <div class="page-name">Comments</div>
              </div>
            </div>
            <div  v-show="editId" class="row no-margin mt-20">
              <div class="col-12 forms-block">
                <div class="col-12 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.comment_label }}
                  </div>
                </div>
                <div class="col-12 paddingleft0">
                  <textarea
                    id="activitycomment"
                    v-model="newComment"
                    class="form-control"
                  ></textarea>
                </div>


                <!--Buttons-->
              <div class="row clearfix"></div>
                  <div class="button-demo mt-0">
                  <input
                    id="addActivityComment"
                    type="submit"
                    :value="buttonNames.addComment"
                    class="btn btn-primary"
                    v-on:click.prevent="addComment()"
                  />
                </div>
              </div>


              <template v-if="comments && comments.length > 0">
                  <div class="col-md-12 sidebar-notes-details mt-0">
                      <template v-for="(comment, index) in comments">
                          <div class="each-comment-section " :key="index" :id="'each-comment-section-'+comment['record_id']">
                              <div class="mb-2 user-details">
                                  <div class="username-letter text-center">{{comment['imgString']}}</div>
                                  <div class="comment-details" style="display: inline-block; margin-left: 10px;">
                                      <p class="mb-0 user-name" style="color: #098de1; font-family: 'SanFranciscoDisplayMedium';">
                                          {{comment['name']}}
                                      </p>
                                      <p class="mb-0 comment-date" style="font-size: 14px; color: #8b8b8b;">
                                          {{comment['date']}}
                                      </p>
                                  </div>
                              </div>
                              <p class="mb-20 text-right text-nowrap addeditactions">
                                <input
                                        id="addActivityComment"
                                        type="submit"
                                        :value="buttonNames.lavelUpdate"
                                        class="btn btn-primary comment-edit-section hide"
                                        v-on:click.prevent="updateComment()"
                                      />
                                <button type="submit" id="editCommentBtn" v-if="comment.permission_edit" title="Edit Comment" class="btn edit-btn-vvg waves-effect comment-view-section" v-on:click.prevent="enableEditCommentSection(comment)"><i aria-hidden="true" class="fa fa-pencil" style="font-size: 16px;"></i></button>
                                <button type="submit" id="deleteCommentBtn" v-if="comment.permission_delete" title="Delete Comment" class="btn edit-btn-vvg waves-effect" v-on:click.prevent="deleteComment(comment)"><i aria-hidden="true" class="fa fa-trash-o" style="font-size: 16px;"></i></button>
                                </p>
                              <div class="mb-20 commentsec">
                                <p class="comment-view-section">{{comment['comment']}}</p>
                                <div class="comment-edit-section hide">
                                      <textarea
                                        id="editactivitycomment"
                                        class="width-full"
                                        v-model="editComment"
                                      ></textarea>
                                </div>
                              </div>
                          </div>
                      </template>
                  </div>
              </template>

              
            </div>

          </div>
          <!-- Forms -->

          
        </div>
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from 'moment';

Vue.component("app-footer", Footer);
Vue.component("no-access", NoAccessPage);

export default {
  name: "AddUpdateActivity",
  components: {
    datePicker
  },
  props: {
    openAt: {
      type: [String, Number],
      required: false,
      default: 'pageView'
    },
  },
  mixins: [commonMixins],
  data() {
    return {
      production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
      editId: '',
      staticMessages: "",
      staticInputLabelMessages: "",
      moduleData: {
        text: "Inventory",
        value: "inventory"
      },
      moduleDataArr: [
        {
          text: "Inventory",
          value: "inventory"
        },
        {
          text: "Expenses",
          value: "expenses"
        },
        {
          text: "Invoice",
          value: "invoice"
        }
      ],
      contactGroupId: "",
      contactGroupIdArr: [],
      isContactGroupRecordNotFound: false,
      locationId: "",
      locationIdArr: [],
      createdBy: "",
      userId: "",
      userIdArr: [],
      assignUserId: "",
      assignUserIdArr: [],
      isAssingUserRecordNotFound: false,
      type: { text: "Call", value: "call" },
      typeArr: [
        {
          text: "Call",
          value: "call"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Personal visit",
          value: "personal_visit"
        },
        {
          text: "Incident",
          value: "incident"
        },
        {
          text: "Other",
          value: "other"
        }
      ],
      isActivityTypeOther: false,
      otherActivityTypeText: "",
      otherActivityTypeTextError: "",
      assetId: "",
      assetIdArr: [],
      isAssetRecordNotFound: false,
      activityDateTime: moment().format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT),
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom"
        }
        // disabledTimeIntervals: false,
      },
      notes: "",

      moduleDataError: "",
      customerContactId: "",
      customerContactIdError: "",
      customerContactIdArr: [],
      isCustomerContactRecordNotFound: false,
      contactGroupIdError: "",
      locationIdError: "",
      userIdError: "",
      assignUserIdError: "",
      typeError: "",
      assetIdError: "",
      activityDateTimeError: "",

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
        addComment: "Add Comment",
        lavelUpdate: "Update"
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false
      },
      showSaveAndContinue: true,
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Activity",
      permissionNameArray: ["can_add_activity_master"],
      departmentId: "",
      departmentError: "",
      departmentArr: [],
      priority: { value: "medium", text: "Medium" },
      priorityArr: [
        {
          value: "high",
          text: "High"
        },
        {
          value: "medium",
          text: "Medium"
        },
        {
          value: "low",
          text: "Low"
        }
      ],
      priorityError: "",
      direction: { value: "outgoing", text: "Outgoing" },
      directionArr: [
        {
          value: "incoming",
          text: "Incoming"
        },
        {
          value: "outgoing",
          text: "Outgoing"
        }
      ],
      directionError: "",
      subject: "",
      subjectError: "",
      status : { value: "in progress", text: "In Progress" },
      statusArr : [
        {
          value: "in progress",
          text: "In Progress"
        },
        {
          value: "completed",
          text: "Completed"
        }
      ],
      statusError : "",
      newComment: "",
      comments: [],
      editComment: '',
      editCommentId: '',
      selectedOpportunity: '',
      isOpportunityRecordNotFound: '',
      selectedOpportunityError: '',
      opportunityIdArr: [],
      changedOpportunityValue: '',
      selectedOpportunityError: '',
      parentActivityError: "",
      parentActivity: "",
      parentActivityArr: [],
      isParentActivityRecordNotFound: false,
      anbNoError: "",
      abn_no: "",
      openFor: "",
      is_followup_activity: false
    };
  },
  watch: {
    type: function(type) {
      self.isActivityTypeOther = false;

      if (type.value == "other") {
        self.isActivityTypeOther = true;
      }
    }
  },
  beforeMount() {
    self = this;
  },
  mounted: function() {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    
    /* Set the left sidebar activity menu active only when it is opened from the left sidebar */
    /* Otherwise it is mounted from the customer tab so there is no need to set the activity menu as active */
    if (this.$route.params.id == undefined){
      self.setActiveMenu("activity-list");
      EventBus.$emit("menu-selection-changed", "activity-list");
    }

    userObj = localStorage.getItem("user_obj");
    if (userObj != null) {
      var userJson = JSON.parse(userObj);
      self.userId = {
        text: userJson.first_name + " " + userJson.last_name,
        value: userJson.id
      };
      self.createdBy = userJson.id;
    }
    self.loadAllData(
      ["Department"],
      false,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    $("#alertError").hide();
    self.getLoginIdPermissions();

    if (
      typeof self.$route.query.customer != "undefined" &&
      self.$route.query.customer != ""
    ) {
          
      self.openFor = "Customer";
      self.getAssingedUserById(self.$route.query.customer);
    }

    if (
      typeof self.$route.query.inventory != "undefined" &&
      self.$route.query.inventory != ""
    ) {
      self.getInventoryById(self.$route.query.inventory);
    }

    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0 && self.$route.name == 'UpdateActivity'
    ) {
      self.getActivityDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      $("#addAndContinueActivityBtn").hide();
      self.pageTitle = "Edit Activity";
      self.showSaveAndContinue = false;
      //self.createdBy = "";
    }else{
      //If new activity add request for Opportunity 
      self.newActivityForOpportunity();
    }
    // self.loadAllData(['Location'], true, self.loadLocationCallback, "undefined", "");


    self.editId = (self.$route.name == 'UpdateActivity' && typeof self.$route.params.id != "undefined" && self.$route.params.id != 0 && self.$route.params.id != "") ? self.$route.params.id : '';
    
    
    self.fetchComments();
  },
  methods: {
    newActivityForOpportunity() {
      if (
          typeof self.$route.query.redirectUrl != "undefined" &&
          self.$route.query.redirectUrl != ""
        ) {
          let redirectUrl = self.$route.query.redirectUrl;

          // Define a regular expression pattern to match "opportunity/tab" followed by a number
          let pattern = /opportunity\/tab\/(\d+)/;

          // Use the RegExp exec() method to find matches in the string
          let matches = pattern.exec(redirectUrl);

          // Check if there is a match
          if (matches && matches.length > 1) {
              // Extract the ID from the matched substring
              let id = matches[1];
              // Need to fetch Opportunity data and need to set 
              self.getOpportunityDetailsById(id);

          } else {
              console.log("No ID found in the string.");
          }
      }
    },
    getOpportunityDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/opportunity/get', config)
                    .then(response => {
                        self.setOpportunityDataForNewActivity(response.data.content);
                    })
    },
    setOpportunityDataForNewActivity(data){
      if(typeof data.customer_id != 'undefined' && typeof data.customer_name != 'undefined'){
        self.contactGroupId = {
          value: data.customer_id,
          text: data.customer_name
        };
        self.contactGroupIdArr = [
          {
            value: data.customer_id,
            text: data.customer_name
          }
        ];
      }

      if(typeof data.customer_contact_id != 'undefined' && typeof data.customer_name != 'undefined'){
          self.customerContactId = {
            text: data.customer_contact_name,
            value: data.customer_contact_id
          };

      }
      if(typeof data.salesman_id != 'undefined' && typeof data.salesman_name != 'undefined'){
          self.assignUserId = {
            text: data.salesman_name,
            value: data.salesman_id
          };
      }

      if(typeof data.reference_no != 'undefined') {
        self.selectedOpportunity = {
            text: data.reference_no,
            value: data.id
          };
      }

      self.abn_no = typeof data.abn_no != "undefined" ? data.abn_no : "";
      

    },
    openAddActivityPopup(data) {
      self.openFor = "";

      if(data.flag && data.flag == 'addFollowUpActivity') {
        self.is_followup_activity = true;

        self.parentActivity = {
          value: data.id,
          text: data.name
        };

      }else if(data.flag && data.flag == 'addCustomerActivity'){
        self.contactGroupId = {
          value: data.id,
          text: data.name
        };
        self.contactGroupIdArr = [
          {
            value: data.id,
            text: data.name
          }
        ];
         
        self.openFor = "Customer";
        //Get CUstomer Contacts as per selected Customer
        self.getCustomerContactList();
      }

      if(data.activityTime){
        self.activityDateTime = data.activityTime;
      }
      
    },
    deleteComment(commentData) {
      //Delete comment Request
      self.$swal({
              title: '',
              text: 'Are you sure that you want to delete this Comment!',
              type: 'info',
              confirmButtonText: 'Yes',
              showCancelButton: true,
              // showCloseButton: true,
              showLoaderOnConfirm: true
          }).then((result) => {

            if(result.value) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token")
                },
                params: {
                  comment_id: commentData.record_id
                }
              };

              HTTP.delete("/comment/delete/"+commentData.record_id, config)
                  .then(function (response) {
                      //re-load comments
                      self.fetchComments();
                      if (response.data.status == "success") {
                        self.showSAlert(response.data.message,'success',false,3);
                      }else if(response.data.code == 201){
                        if(response.data.content && typeof response.data.content.comment != 'undefined') {
                          self.showSAlert('Comment is missing.','error',false,3);
                        }else{
                          self.showSAlert('Exception occurred','error',false,3);
                        }
                      }
                  })
                  .catch(function (err) {
                      if (err.response.status == 0) {
                          self.error = "Remote server can not be reachable";
                      } else {
                          //redirect to login page if user not authenticated
                          if (err.response.status == 401) {
                              //clear previous data
                              localStorage.removeItem("user_obj");
                              localStorage.removeItem("api_token");
                              localStorage.removeItem("profile_image");
                              self.$router.push("/login");
                              //EventBus.$emit("logged-in", true, "", "");
                          }
                      }
                  });
          }
      });    
    },
    enableEditCommentSection(commentData) {
      //Edit comment section 
      self.editComment = commentData.comment;
      self.editCommentId = commentData.record_id;

      let sectionId = "#each-comment-section-"+self.editCommentId;

      $(sectionId+ ' .comment-view-section').addClass('hide');
      $(sectionId+ ' .comment-edit-section').removeClass('hide');

    },
    buttonBack() {
      if(self.openAt == 'pageView') {
        // get redirect url from params
        if (
          typeof self.$route.query.redirectUrl != "undefined" &&
          self.$route.query.redirectUrl != ""
        ) {
          const url = decodeURIComponent(self.$route.query.redirectUrl);
          self.$router.push("/" + url + "?tab=" + self.$route.query.tab);
        } else {
          self.$router.push("/activity/list");
        }
      }else{
        //CloseModalPopup
        self.clearDataOnSaveAndContinue();
        self.$parent.closeModalPopup();
        // self.$emit('call-modal-close');
      }

    },
    loadMasterDataCallback: function(callbackData) {
      self.departmentArr = callbackData.Department;
      if (self.departmentArr.length == 0) {
        self.isdepartmentRecordNotFound = true;
      }
    },
    clearDataOnSaveAndContinue: function() {
      self.moduleData = { text: "Inventory", value: "inventory" };
      let calendarView = '';
        if(self.$route.name != 'undefinde' && self.$route.name == 'Calendar'){
            calendarView = 'Calendar';
        }
      if (
        typeof self.$route.query.customer != "undefined" &&
        self.$route.query.customer != "" || (self.openAt != "pageView" && calendarView != 'Calendar')
      ) {
        // skip
      } else {
        self.contactGroupId = "";
        self.customerContactId = "";
        self.abn_no = "";
      }
      if (
        typeof self.$route.query.inventory != "undefined" &&
        self.$route.query.inventory != ""
      ) {
        // skip
      } else {
        self.assetId = "";
      }
      self.contactGroupIdError = "";
      self.customerContactIdError = "";
      self.subjectError = "";
      self.locationId = "";
      self.assignUserId = "";
      self.type = { text: "Call", value: "call" };
      self.otherActivityTypeText = "";
      self.activityDateTime = moment().format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT);
      self.notes = "";
      self.departmentId = "";
      self.priority = { value: "medium", text: "Medium" };
      self.direction = { value: "outgoing", text: "Outgoing" };
      self.subject = "";
      self.status = { value: "in progress", text: "In Progress" };
      self.is_followup_activity = false;
      self.parentActivity = "";
      self.selectedOpportunity = "";
    },
    getCustomerContactList: function() {
      //Get Customer Contacts as per selected Customer
      if(self.contactGroupId && self.contactGroupId.value){
                  self.loadAllData(
                    ["CustomerContact"],
                    true,
                    self.loadCustomerContactCallback,
                    "",
                    "",
                    self.openFor == "Customer" && self.contactGroupId.value ? self.contactGroupId.value : ""
                  );
                }
    },
    getAssingedUserById: function(id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id
        }
      };
      HTTP.get("/get/contact", config)
        .then(response => {
          self.contactGroupId = {
            value: response.data.content.id,
            text: response.data.content.first_name
          };
          self.contactGroupIdArr = [
            {
              value: response.data.content.id,
              text: response.data.content.first_name
            }
          ];

          if(self.openFor == "Customer") {
            self.getCustomerContactList();
          }

          //   self.cusId = response.data.content.customer_id;
        })
        .catch(function(err) {
          self.catchHandler(err, function() {});
        });
    },
    getInventoryById: function(id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },

        params: {
          id: id
        }
      };

      HTTP.get("/asset/get", config)
        .then(function(data) {
          const assetData = data.data;
          const asset = assetData.content["asset_data"];

          console.log(asset);

          self.assetId = {
            value: asset.asset_id,
            text: asset.vin_no
          };
          self.assetIdArr = [
            {
              value: asset.asset_id,
              text: asset.vin_no
            }
          ];
        })
        .catch(function(err) {
          self.catchHandler(err, function() {});
        });
    },
    parentActivityChangedValue: function(data) {
      if(data.value) {
        self.getParentActivityDetailsById(data.value);   
      } 
    },
    getParentActivityDetailsById: function(id) {
      self.isActivityTypeOther = false;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id,
          browser_timezone: localStorage.getItem("browserTimeZone"),
        }
      };
      HTTP.get("/activity/get", config)
        .then(response => {
          // if (response.data.content.type == "other") {
          //   self.isActivityTypeOther = true;
          // }
          self.moduleData = {
            text: self.formatString(response.data.content.module),
            value: response.data.content.module
          };
          
          if(response.data.content.customer_name != "" && (response.data.content.customer_name.trim()).length) {
            self.contactGroupId = {
                text: response.data.content.customer_name,
                value: response.data.content.customer_id
            };
          }else {
            self.contactGroupId = {
              text: response.data.content.contact_group_name,
              value: response.data.content.contact_group_id
            };
          }
          self.customerContactId = {
            text: response.data.content.customer_contact_name,
            value: response.data.content.customer_contact_id
          };

          self.abn_no = response.data.content.abn_no;
          // self.locationId = {text: response.data.content.location_name, value: response.data.content.location_id};
          self.assignUserId = {
            text: response.data.content.assign_user_first_name + " " + response.data.content.assign_user_last_name,
            value: response.data.content.assgin_user_id
          };
          self.assetId = "";
          if (
            typeof response.data.content.asset_id != "undefined" &&
            response.data.content.asset_id != 0 &&
            response.data.content.asset_id != ""
          ) {
            self.assetId = {
              text: response.data.content.vin_no,
              value: response.data.content.asset_id,
              location_id: response.data.content.location_id,
              companyId: response.data.content.company_id
            };
          }
          // self.type = {
          //   text: self.formatString(response.data.content.type),
          //   value: response.data.content.type
          // };
          self.selectedOpportunity = {
            text: response.data.content.reference_no,
            value: response.data.content.opportunity_master_id
          };

          // self.otherActivityTypeText =
          //   response.data.content.other_activity_type_text;
          // self.activityDateTime = response.data.content.activity_time;
          // self.notes = response.data.content.notes;
          self.priority = {
            text: self.formatString(response.data.content.priority),
            value: response.data.content.priority
          };
          // self.direction = {
          //   text: self.formatString(response.data.content.direction),
          //   value: response.data.content.direction
          // };
          // if (
          //   typeof response.data.content.subject != "undefined" &&
          //   response.data.content.subject != 0 &&
          //   response.data.content.subject != ""
          // ) {
          //   self.subject = response.data.content.subject;
          // }
          // if (
          //   typeof response.data.content.department_id != "undefined" &&
          //   response.data.content.department_id != 0 &&
          //   response.data.content.department_id != ""
          // ) {
          //   self.departmentId = {
          //     text: response.data.content.department_name,
          //     value: response.data.content.department_id
          //   };
          // }
          
          // self.status = {
          //   text: self.formatString(response.data.content.status),
          //   value: response.data.content.status
          // };

          // self.parentActivity = {
          //   text: self.formatString(response.data.content.parent_activity_subject),
          //   value: response.data.content.parent_activity_id
          // };
        })
        
    },
    changedCustomerValue: function(data) {
      // Load Customer contact on Customer select
      if(typeof data != 'undefined' && typeof data.value != 'undefined' && data.value) {
        
        self.loadAllData(
          ["CustomerContact"],
          true,
          self.loadCustomerContactCallback,
          false,
          "",
          data.value
        );
        
        if(typeof data != 'undefined' && typeof data.abn_no != 'undefined' && data.abn_no) {
          self.abn_no = data.abn_no;
        }
      }
    },
    changedValue: function(data) {
      if(typeof data.abn_no != 'undefined') {
        
        self.abn_no = data.abn_no;
        self.contactGroupIdArr = [
          {
            value: data.customer_id,
            text: data.customer_name
          }
        ];

        self.contactGroupId = {
          value: data.customer_id,
          text: data.customer_name
        };

      }
    },
    changeActivityDateTime: function() {
      self.activityDateTimeError = "";
    },
    onSearchCustomerContact(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["CustomerContact"],
          true,
          self.loadCustomerContactCallback,
          loading,
          search,
          (self.contactGroupId && self.contactGroupId.value && self.openFor == "Customer") ? self.contactGroupId && self.contactGroupId.value : ""
        );
      }
    },
    loadCustomerContactCallback: function(callbackData) {
      self.isCustomerContactRecordNotFound = false;
      self.customerContactIdArr = callbackData.CustomerContact;
      if (self.customerContactIdArr.length == 0) {
        self.isCustomerContactRecordNotFound = true;
      }
    },
    onSearchContactGroup(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Contact"],
          true,
          self.loadContactGroupCallback,
          loading,
          search
        );
      }
    },

    loadContactGroupCallback: function(callbackData) {
      self.isContactGroupRecordNotFound = false;
      self.contactGroupIdArr = callbackData.Contact;
      if (self.contactGroupIdArr.length == 0) {
        self.isContactGroupRecordNotFound = true;
      }
    },
    loadLocationCallback: function(callbackData) {
      self.locationIdArr = callbackData;
    },
    onSearchUser(search, loading) {},
    onSearchAssignUser(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadAssignUserCallback,
          loading,
          search
        );
      }
    },
    loadAssignUserCallback: function(callbackData) {

      self.isAssingUserRecordNotFound = false;
      self.assignUserIdArr = callbackData.User;
      if (self.assignUserIdArr.length == 0) {
        self.isAssingUserRecordNotFound = true;
      }
    },
    onSearchActivity(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Activity"],
          true,
          self.loadActivityCallback,
          loading,
          search
        );
      }
    },
    loadActivityCallback: function(callbackData) {
      self.isParentActivityRecordNotFound = false;
      self.parentActivityArr = callbackData.Activity;
      if (self.parentActivityArr.length == 0) {
        self.isParentActivityRecordNotFound = true;
      }
    },
    onSearchAsset(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Asset"],
          true,
          self.loadAssetCallback,
          loading,
          search
        );
      }
    },
    loadAssetCallback: function(callbackData) {
      self.isAssetRecordNotFound = false;
      self.assetIdArr = callbackData.Asset;
      if (self.assetIdArr.length == 0) {
        self.isAssetRecordNotFound = true;
      }
    },
    showAlertOnSave: function(alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function() {
          $("#success-alert").slideUp(500);
        });
    },
    getActivityDetailsById: function(id) {
      self.isActivityTypeOther = false;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id,
          browser_timezone: localStorage.getItem("browserTimeZone"),
        }
      };
      HTTP.get("/activity/get", config)
        .then(response => {
          if (response.data.content.type == "other") {
            self.isActivityTypeOther = true;
          }
          self.moduleData = {
            text: self.formatString(response.data.content.module),
            value: response.data.content.module
          };
          // self.contactGroupId = {
          //   text: response.data.content.contact_group_name,
          //   value: response.data.content.contact_group_id
          // };
          self.is_followup_activity = response.data.content.is_followup_activity ? true : false;
          self.contactGroupId = {
            text: response.data.content.customer_name,
            value: response.data.content.customer_id
          };

          self.customerContactId = {
            text: response.data.content.customer_contact_name,
            value: response.data.content.customer_contact_id
          };

          self.abn_no = response.data.content.abn_no;
          // self.locationId = {text: response.data.content.location_name, value: response.data.content.location_id};
          self.assignUserId = {
            text: response.data.content.assign_user_first_name ? response.data.content.assign_user_first_name + " " + response.data.content.assign_user_last_name : "",
            value: response.data.content.assgin_user_id ? response.data.content.assgin_user_id : ""
          };
          self.assetId = "";
          if (
            typeof response.data.content.asset_id != "undefined" &&
            response.data.content.asset_id != 0 &&
            response.data.content.asset_id != ""
          ) {
            self.assetId = {
              text: response.data.content.vin_no,
              value: response.data.content.asset_id,
              location_id: response.data.content.location_id,
              companyId: response.data.content.company_id
            };
          }
          self.type = {
            text: self.formatString(response.data.content.type),
            value: response.data.content.type
          };

          self.selectedOpportunity = {
            text: response.data.content.reference_no,
            value: response.data.content.opportunity_master_id
          };

          self.otherActivityTypeText =
            response.data.content.other_activity_type_text;
          self.activityDateTime = response.data.content.activity_time;
          self.notes = response.data.content.notes;
          self.priority = {
            text: self.formatString(response.data.content.priority),
            value: response.data.content.priority
          };
          self.direction = {
            text: self.formatString(response.data.content.direction),
            value: response.data.content.direction
          };
          if (
            typeof response.data.content.subject != "undefined" &&
            response.data.content.subject != 0 &&
            response.data.content.subject != ""
          ) {
            self.subject = response.data.content.subject;
          }
          if (
            typeof response.data.content.department_id != "undefined" &&
            response.data.content.department_id != 0 &&
            response.data.content.department_id != ""
          ) {
            self.departmentId = {
              text: response.data.content.department_name,
              value: response.data.content.department_id
            };
          }
          self.status = {
            text: self.formatString(response.data.content.status),
            value: response.data.content.status
          };

          self.parentActivity = {
            text: self.formatString(response.data.content.parent_activity_subject),
            value: response.data.content.parent_activity_id
          };
        })
        .catch(function(err) {
          self.catchHandler(err, function() {});
          self.buttonBack();
        });
    },
    checkForm: function() {
      var checked = true;
      self.statusError = "";
      if (self.moduleData == null || !self.moduleData.value) {
        self.moduleDataError = "Please select module";
        if (checked) {
          checked = false;
        }
      }
      if (self.contactGroupId == null || !self.contactGroupId.value) {
        self.contactGroupIdError = "Please select Customer";
        if (checked) {
          checked = false;
        }
      }
      /* if (self.locationId == null || !self.locationId.value) {
                    self.locationIdError = "Please select location";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* if (self.assignUserId == null || !self.assignUserId.value) {
        self.assignUserIdError = "Please select assign user";
        if (checked) {
          checked = false;
        }
      } */
      /* if (self.assetId == null || !self.assetId.value) {
                    self.assetIdError = "Please select Inventory";
                    if (checked) {
                        checked = false;
                    }
                } */
      if (self.type == null || !self.type.value) {
        self.typeError = "Please select type";
        if (checked) {
          checked = false;
        }
      }
      self.otherActivityTypeTextError = "";
      if (self.type.value == "other") {
        if (!self.otherActivityTypeText) {
          self.otherActivityTypeTextError = "Please enter Other Activity type";
          if (checked) {
            checked = false;
          }
        } else if (
          self.otherActivityTypeText &&
          self.otherActivityTypeText.length >= 191
        ) {
          self.otherActivityTypeTextError =
            "Please enter Other Activity type up to 190 characters only";
          if (checked) {
            checked = false;
          }
        }
      }
      if (!self.activityDateTime) {
        self.activityDateTimeError = "Please select activity Date Time";
        if (checked) {
          checked = false;
        }
      }
      /* if (self.departmentId == null || !self.departmentId.value) {
                    self.departmentError = "select Department";
                    if (checked) {
                        checked = false;
                    }
                } */
      if (!self.subject) {
        self.subjectError = "Please enter subject";
        if (checked) {
          checked = false;
        }
      } else if (self.subject && self.subject.length >= 191) {
        self.subjectError = "Please enter subject up to 190 characters only";
        if (checked) {
          checked = false;
        }
      }
      if (self.status == null || !self.status.value) {
        self.statusError = "Please select status";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    addActivity(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          let opportunityMasterId = (self.selectedOpportunity && self.selectedOpportunity.value) ? self.selectedOpportunity.value : '';
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = new FormData();
          var asset_id = "";
          var company_id = "";
          var location_id = "";
          var parent_activity_id = "";

          if (self.assetId == null || !self.assetId.value) {
          } else {
            asset_id = self.assetId.value;
            if (self.assetId.companyId == null || !self.assetId.companyId) {
            } else {
              company_id = self.assetId.companyId;
            }
            if (self.assetId.location_id == null || !self.assetId.location_id) {
            } else {
              location_id = self.assetId.location_id;
            }
          }

          if (self.is_followup_activity && self.parentActivity != null || self.parentActivity.value) {
            parent_activity_id = self.parentActivity.value;
          }

          /* data.append('department_id', self.departmentId.value); */
          data.append("module", self.moduleData.value);
          
          data.append("customer_contact_id", self.customerContactId.value);
          data.append("contact_master_id", self.contactGroupId.value);

          data.append("contact_group_id", self.contactGroupId.value);
          // data.append('location_id', self.locationId.value);
          let assignUserId = (self.assignUserId && self.assignUserId.value) ? self.assignUserId.value : "";
          data.append("assign_user_id", assignUserId);
          data.append("asset_id", asset_id);
          data.append("company_id", company_id);
          data.append("location_id", location_id);
          data.append("type", self.type.value);
          data.append("other_activity_type_text", self.otherActivityTypeText);
          data.append("activity_date_time", self.activityDateTime);
          data.append("notes", self.notes);
          data.append("direction", self.direction.value);
          data.append("priority", self.priority.value);
          data.append("subject", self.subject);
          data.append("status", self.status.value);
          data.append("browser_timezone", localStorage.getItem("browserTimeZone"));
          data.append("opportunity_master_id", opportunityMasterId);
          data.append("parent_activity_id", parent_activity_id);
          data.append("is_followup_activity", self.is_followup_activity);

          if (self.$route.name == 'UpdateActivity' &&
            typeof self.$route.params.id != "undefined" &&
            self.$route.params.id != 0 &&
            self.$route.params.id != "" 
          ) {
            data.append("id", self.$route.params.id);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post("/activity/add/update", data, config)
            .then(function(response) {
              if (
                typeof self.$route.params.id != "undefined" &&
                self.$route.params.id != 0 &&
                self.$route.params.id != ""
              ) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$route.params.id != 0 && self.$route.params.id != "") {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function() {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function(err) {
              if (self.$route.params.id != 0 && self.$route.params.id != "") {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function() {});
            });
        }
      }, 200);
    },

    updateComment() {
      var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          }
      };
      let data = {
          comment: self.editComment,
          record_for: 'Activity',
          record_id: self.editId,
       };  

       
      HTTP.post("/comment/update/"+self.editCommentId, data, config)
            .then(function(response) {
              //re-load comments
              self.fetchComments();

              if (response.data.status == "success") {
                self.newComment = '';

                let sectionId = "#each-comment-section-"+self.editCommentId;

                $(sectionId+ ' .comment-view-section').removeClass('hide');
                $(sectionId+ ' .comment-edit-section').addClass('hide');
                self.editComment = '';
                self.editCommentId = '';

                self.showSAlert(response.data.message,'success',false,3);
              }else if(response.data.code == 201){
                if(response.data.content && typeof response.data.content.comment != 'undefined') {
                  self.showSAlert('Comment is missing.','error',false,3);
                }else{
                  self.showSAlert('Exception occurred','error',false,3);
                }

              }
            }).catch(function(err) {
                self.catchHandler(err, function () {});
            });
    },
    addComment() {
      var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          }
      };
      let data = {
          comment: self.newComment,
          record_for: 'Activity',
          record_id: self.editId,
       };  

       if(self.editCommentId) {
          data.comment = self.editComment;
          data.record_id = self.editCommentId;
       }
      HTTP.post("/comment/new", data, config)
            .then(function(response) {
              //re-load comments
              self.fetchComments();

              if (response.data.status == "success") {
                self.newComment = '';
                self.editComment = '';
                self.editCommentId = '';

                self.showSAlert(response.data.message,'success',false,3);
              }else if(response.data.code == 201){
                if(response.data.content && typeof response.data.content.comment != 'undefined') {
                  self.showSAlert('Comment is missing.','error',false,3);
                }else{
                  self.showSAlert('Exception occurred','error',false,3);
                }

              }
            }).catch(function(err) {
                self.catchHandler(err, function () {});
            });
    },
    fetchComments() {
      if(self.editId) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            },
            params: {
              record_for: 'Activity',
              record_id: self.editId,
            }
          };

          HTTP.get("/comments", config)
            .then(function(response) {
              if (response.data.status == "success") {
                self.comments = [];
                self.comments = response.data.content.comments;
              }else if(response.data.code == 201){

              }
            }).catch(function(err) {
                self.catchHandler(err, function () {});
            });

      }
    },
    onSearchOpportunity(search, loading) {
        if (search.length > 1) {
            loading(true);
            self.loadAllData(["OpportunityMaster"],true,self.loadOpportunityCallback,loading,search);
        }
    },
    loadOpportunityCallback: function(callbackData) {
        self.isOpportunityRecordNotFound = false;
        self.opportunityIdArr = callbackData.OpportunityMaster;
        if (self.opportunityIdArr.length == 0) {
            self.isOpportunityRecordNotFound = true;
        }
    },
  }
};
EventBus.$on("login-permissions", function(permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>

<style scoped>

.each-comment-section{
  clear: both;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.each-comment-section .user-details{
  float: left;
}
.each-comment-section .addeditactions{
  float: right;
}
.each-comment-section .commentsec{
  padding-left: 200px;
  padding-right: 100px;
}
.each-comment-section .comment-view-section{
  word-break: break-all;
}
@media(max-width: 768px){
  .each-comment-section .commentsec{
  padding-left: 0;
  padding-right: 0;
  clear: both;
}
}
.pr-imp-25 {
  padding-right: 25px !important;
}
</style>


