<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_cso_request')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button id="backBtn" type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>

                <div class="ml-3" style="padding-right: 10px;position: relative;display: inline;">
                    {{staticInputLabelMessages.required}}<span class="text-danger">*</span>
                </div>

                <div class="admin-page-content">
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Sales Person -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="salesperson_id"><b>{{staticInputLabelMessages.salesperson_id}}</b><span class="text-danger">*</span></label>
                                    <v-select
                                        v-model="salespersonId"
                                        name="salespersonId"
                                        @input="salespersonIdError = ''"
                                        label="text"
                                        :filterable="false"
                                        :options="salespersonArr"
                                        @search="onSearchUser"
                                        :clearable="false"
                                        :placeholder="staticMessages.sales_person_place_holder"
                                        :disabled="true"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isUserRecordNotFound">
                                                {{staticMessages.sales_person_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                                <span class="is-danger text-danger">{{salespersonIdError}}</span>
                            </div>

                            <!-- Receiving Dealership -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="receiving_dealership"><b>{{staticInputLabelMessages.receiving_dealership}}</b><span class="text-danger">*</span></label>
                                    <v-select v-model="locationId" name="locationId"
                                        label="text" 
                                        :filterable="false" 
                                        :options="locationArr"
                                        @search="onSearchLocation" 
                                        :clearable="true" 
                                        @input="locationError = ''"
                                        @change="updateLocation"
                                        :placeholder="allStaticMessages.location_custom_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isLocationRecordNotFound">
                                                {{allStaticMessages.location_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <span class="is-danger text-danger">{{locationError}}</span>
                                </div>
                            </div>

                            <!-- Sales Admin Email -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="sales_manager_email"><b>{{staticInputLabelMessages.sales_manager_email}}</b><span class="text-danger">*</span></label>
                                    <v-select v-model="salesManagerEmail" name="salesManagerEmail"
                                        label="text" 
                                        :filterable="false" 
                                        :options="salesManagerEmailArr"
                                        :clearable="true" 
                                        @input="salesManagerEmailError = ''"
                                        :placeholder="allStaticMessages.sm_email_place_holder" 
                                        class="custom-ci-auto-search"
                                    >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isSMEmailRecordNotFound">
                                                {{allStaticMessages.sm_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{allStaticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <span class="is-danger text-danger">{{salesManagerEmailError}}</span>
                                </div>
                            </div>

                            <!-- Customer Id -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="customer_id"><b>{{staticInputLabelMessages.customer_id}}</b>{{staticInputLabelMessages.excede_cusid}}<span class="text-danger">*</span></label>
                                    <input id="customerId" v-model="customerId" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer" @input="customerIdError = null"/>
                                    <span class="is-danger text-danger">{{customerIdError}}</span>
                                </div>
                            </div>

                            <!-- I confirm this has Sales Admin approval prior to submission -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="confirmSmApprovalSubmission"
                                        v-model="confirmSmApprovalSubmission"
                                        name="confirmSmApprovalSubmission"
                                        @change="updatecheckbox"
                                    >
                                        <b>{{staticInputLabelMessages.i_confirm_this_has_sales_manager_approval_prior_to_submission}}</b>
                                        <span class="text-danger">*</span>
                                    </b-form-checkbox>
                                </div>
                                <span class="is-danger text-danger">{{confirmSmApprovalSubmissionError}}</span>
                            </div>

                            <!-- Confirming that Worksheet has acceptable GP -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="isWorksheetHasAcceptableGp"
                                        v-model="isWorksheetHasAcceptableGp"
                                        name="isWorksheetHasAcceptableGp"
                                    >
                                    <b>{{staticInputLabelMessages.confirming_the_worksheet_has_acceptable_gp}}</b>
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <!-- Worksheet with acceptable -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="dropzone"><b>{{staticInputLabelMessages.worksheet_with_acceptable}}</b>{{staticInputLabelMessages.non_anonymous_question}}</label>
                                    <i class="fa fa-question-circle" id="worksheet_with_acceptable"></i><span class="text-danger">*</span>
                                    <b-tooltip target="worksheet_with_acceptable" triggers="hover">
                                        {{staticInputLabelMessages.worksheet_tooltip}}
                                    </b-tooltip>
                                    <vue-dropzone
                                        ref="worksheet_dropzone"
                                        id="worksheet_dropzone"
                                        :options="worksheetDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{worksheetFileUploadError}}</span>
                            </div>

                            <!-- Signed contract of sale -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <b-form-checkbox
                                        id="signedContractOfSale"
                                        v-model="signedContractOfSale"
                                        name="signedContractOfSale"
                                    >
                                    <b>{{staticInputLabelMessages.signed_contract_of_sale_customer_po}}</b>
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <!-- Customer PO -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="dropzone"><b>{{staticInputLabelMessages.signed_contract_of_sale_customer_po}}</b>{{staticInputLabelMessages.with_subject_to_availability_clause}} {{staticInputLabelMessages.non_anonymous_question}}</label>
                                    <i class="fa fa-question-circle" id="signed_contract"></i><span class="text-danger">*</span>
                                    <b-tooltip target="signed_contract" triggers="hover">
                                        {{staticInputLabelMessages.worksheet_tooltip}}
                                    </b-tooltip>
                                    <vue-dropzone
                                        ref="signed_contract_dropzone"
                                        id="signed_contract_dropzone"
                                        :options="signedContractDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{signedContractFileUploadError}}</span>
                            </div>

                            <!-- Proof of deposit paid -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="dropzone"><b>{{staticInputLabelMessages.proof_of_deposit_paid}}</b>{{staticInputLabelMessages.refer_to_inventory_policy}} {{staticInputLabelMessages.non_anonymous_question}}</label>
                                    <i class="fa fa-question-circle" id="proof"></i><span class="text-danger">*</span>
                                    <b-tooltip target="proof" triggers="hover">
                                        {{staticInputLabelMessages.proof_tooltip}}
                                    </b-tooltip>
                                    <vue-dropzone
                                        ref="proof_dropzone"
                                        id="proof_dropzone"
                                        :options="proofDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{proofFileUploadError}}</span>
                            </div>

                            <!-- Estimated date of delivery -->
                            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="estimated_date_of_delivery"><b>{{staticInputLabelMessages.estimated_date_of_delivery}}</b><span class="text-danger">*</span></label>
                                    <date-picker v-model="estimatedDateOfDelivery" :config="options" class="form-control" autocomplete="off" :placeholder="staticInputLabelMessages.estimated_date_of_delivery_place_holder" name="estimatedDateOfDelivery" @input="estimatedDateOfDeliveryError = null">
                                    </date-picker>
                                    <span class="is-danger text-danger">{{estimatedDateOfDeliveryError}}</span>
                                </div>
                            </div> -->

                            <!-- Make and model -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="make_and_model"><b>{{staticInputLabelMessages.make_and_model}}</b>{{staticInputLabelMessages.make_and_model_example}}<span class="text-danger">*</span></label>
                                    <input id="makeAndModel" v-model="makeAndModel" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer" @input="makeAndModelError = null"/>
                                    <span class="is-danger text-danger">{{makeAndModelError}}</span>
                                </div>
                            </div>

                            <!-- Units volume -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="units_volume"><b>{{staticInputLabelMessages.units_volume}}</b><span class="text-danger">*</span></label>
                                    <input id="unitsVolume" v-model="unitsVolume" class="form-control" type="number" :placeholder="staticInputLabelMessages.units_volume_place_holder" @input="inputUnitsVolume" min="0" @keydown="preventNonNumeric($event)"/>
                                    <span class="is-danger text-danger">{{unitsVolumeError}}</span>
                                </div>
                            </div>
                            
                            <!-- Request type -->
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="request_type"><b>{{staticInputLabelMessages.request_type}}</b><span class="text-danger">*</span></label>
                                    <b-form-radio-group v-model="requestType" stacked :options="requestTypeOptions" name="requestType" @input="unitIdError = estimatedDateOfDeliveryError = null"></b-form-radio-group>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-sm-3 col-4" v-if="requestType == 'both' || requestType == 'vehicle_allocation'">
                                <div class="form-group">
                                    <label for="unit_id" class="m-0"><b>{{staticInputLabelMessages.unit_id}}</b><span class="text-danger">*</span></label>
                                </div>
                                <template v-for="(unitId, index) in unitIdsArr">
                                    <input id="unitId" class="mr-1 mb-1" v-bind:style="'border-radius: 0.25rem;padding: 6px;' + [unitId.status == 'error' ? 'border: 1px solid #f44336;' : 'border: 1px solid #ced4da;']" type="number" v-model="unitIdsArr[index].id" :placeholder="staticInputLabelMessages.enter_your_answer" @input="unitIdError = null" min="0" @keydown="preventNonNumeric($event)"/><br/>
                                </template>
                                <span class="is-danger text-danger">{{unitIdError}}</span>
                            </div>

                            <div class="col-lg-7 col-md-7 col-sm-7 col-7" v-if="requestType == 'both' || requestType == 'vehicle_allocation'">
                                <div class="form-group">
                                    <label for="estimated_date_of_delivery" class="m-0"><b>{{staticInputLabelMessages.estimated_date_of_delivery}}</b><span class="text-danger">*</span></label>
                                </div>
                                <template v-for="(unitId, index) in unitIdsArr">
                                    <date-picker v-model="unitIdsArr[index].estimatedDateOfDelivery" class="mb-1 w-50" :config="options" autocomplete="off" :placeholder="staticInputLabelMessages.estimated_date_of_delivery_place_holder" name="estimatedDateOfDelivery" @input="estimatedDateOfDeliveryError = null">
                                    </date-picker>
                                </template>
                                <span class="is-danger text-danger">{{estimatedDateOfDeliveryError}}</span>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="requestType == 'both' || requestType == 'customer_order'">
                                <div class="form-group">
                                    <label for="dropzone"><b>{{staticInputLabelMessages.truck_order_form}}</b>{{staticInputLabelMessages.truck_order_form_example}}</label>
                                    <i class="fa fa-question-circle" id="truck_order_form"></i><span class="text-danger">*</span>
                                    <b-tooltip target="truck_order_form" triggers="hover">
                                        {{staticInputLabelMessages.order_form_tooltip}}
                                    </b-tooltip>
                                    <vue-dropzone
                                        ref="order_form_dropzone"
                                        id="order_form_dropzone"
                                        :options="orderFormDropzoneOptions"
                                        @vdropzone-success = "uploadDocument"
                                        @vdropzone-removed-file="removedSelectedFile"
                                        @vdropzone-error="fileError"
                                        @vdropzone-file-added="fileAdd"
                                        @vdropzone-total-upload-progress="progressOfUload"
                                        :useCustomSlot=true
                                    >
                                        <div class="dropzone-custom-content">
                                            <h4 class="dropzone-custom-title">{{staticInputLabelMessages.drop_files_here_or_click_to_upload}}</h4>
                                            <div class="subtitle">{{staticInputLabelMessages.select_files_from_your_computer}}</div>
                                        </div>
                                    </vue-dropzone>
                                </div>
                                <span class="is-danger text-danger">{{orderFormFileUploadError}}</span>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="notes"><b>{{staticInputLabelMessages.notes}}</b></label>
                                    <textarea id="notes" v-model="notes" class="form-control" type="text" maxlength="190" :placeholder="staticInputLabelMessages.enter_your_answer"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix"></div>
                        <div class="button-demo ml-3">
                            <input id="submitBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="submit()">
                            <input id="cancel" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>
<script>
    var self;
    import Vue from "vue";
    import {commonMixins} from '../../../mixins/commonMixins';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import {HTTP} from '../../../http-common';
    import EventBus from "../.././event-bus";
    export default {
        name: 'AddCSORequest',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone
        },
        mixins: [commonMixins],
        data() {
            return {
                salespersonId: null,
                salespersonArr: [],
                salespersonIdError: null,
                locationId: "",
                locationError: "",
                customerId: null,
                customerIdError: null,
                options: {
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    minDate: new Date()
                },
                unitsVolume: 1,
                unitsVolumeError: null,
                makeAndModel: null,
                makeAndModelError: null,
                unitId: null,
                unitIdError: null,
                notes: null,
                requestType: "vehicle_allocation",
                requestTypeOptions: [
                    {
                        text: 'Vehicle Allocation (all required units available within VTC inventory)', 
                        value: 'vehicle_allocation'
                    },
                    {
                        text: 'Customer Order (no units available within VTC inventory, units require new vehicle order)', 
                        value: 'customer_order'
                    },
                    {
                        text: 'Both (some units available within VTC inventory, additional units require new vehicle order)', 
                        value: 'both'
                    }
                ],
                worksheetDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'worksheet'}
                },
                signedContractDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'signed_contract'}
                },
                proofDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.ppt,.doc,.docx,.xls,.xlsx",
                    params: {'type': 'proof'}
                },
                orderFormDropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 10, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    maxFiles: 10,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    acceptedFiles: ".pdf,.xls,.xlsx",
                    params: {'type': 'order_form'}
                },
                proofFileUploadError : "",
                proofImagesPaths: [],
                proofImagesType: [],
                proofImagesSize: [],
                isDisable: false,
                filesType: [],
                pageTitle: "Request for Vehicle Allocation / Customer Order",
                permissionNameArray : ['can_add_cso_request'],
                staticMessages: "",
                staticInputLabelMessages: "",
                isUserRecordNotFound : false,
                buttonNames: {
                    save: 'Submit',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                locationArr: [],
                isLocationRecordNotFound: false,
                roleId: null,
                salesManagerEmail: null,
                salesManagerEmailError: null,
                salesManagerEmailArr: [],
                isSMEmailRecordNotFound: false,
                worksheetFileUploadError : "",
                signedContractFileUploadError: "",
                orderFormFileUploadError: "",
                isWorksheetHasAcceptableGp: false,
                signedContractOfSale: false,
                unitIdsArr: [],
                ccEmailArr: [],
                unitIds: {
                    id: 0,
                    status: null,
                    estimatedDateOfDelivery: null
                },
                confirmSmApprovalSubmission: false,
                confirmSmApprovalSubmissionError: "",
                estimatedDateOfDeliveryArr: [],
                estimatedDateOfDeliveryError: null
            }
        },
        watch: {
        },
        beforeMount() {
            self = this;
        },
        mounted: function () {
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            self.selectDefaultSalesPerson();
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('can_add_cso_request');
            EventBus.$emit("menu-selection-changed", "can_add_cso_request");

            $("#alertError").hide();
            self.getLoginIdPermissions();
            self.loadAllData(['User', 'Location'], true, self.loadMasterDataCallback, "undefined", '');
            self.roleId = localStorage.getItem("roleId");
            self.unitIdsArr.push(Vue.util.extend({}, self.unitIds));
        },
        methods: {
            fileError: function (file, xhr, error) {
                self.isDisable = true
                if (xhr.code == 400) {
                    self.showSAlert(xhr.message, 'error', false);
                }
            },
            checkForm: function () {
                var checked = true;

                if (!self.salespersonId) {
                    self.salespersonIdError = "Please enter salesperson id";
                    if (checked) {
                        checked = false;
                    }
                }
                self.locationError = "";
                if (self.locationId == null || !self.locationId.value) {
                    self.locationError = "Please select receiving dealership";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.customerId) {
                    self.customerIdError = "Please enter customer id";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.customerId.length > 20) {
                    self.customerIdError = "Please enter customer id less than 20.";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.salesManagerEmail) {
                    self.salesManagerEmailError = "Please select CSO sales administration email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('worksheet'))  {
                    self.worksheetFileUploadError = "Please upload file for worksheet with acceptable";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('signed_contract'))  {
                    self.signedContractFileUploadError = "Please upload file for signed contract of sale or customer po";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.filesType.includes('proof'))  {
                    self.proofFileUploadError = "Please upload file for proof of deposit paid";
                    if (checked) {
                        checked = false;
                    }
                }
                // if (!self.estimatedDateOfDelivery) {
                //     self.estimatedDateOfDeliveryError = "Please select estimated date of delivery";
                //     if (checked) {
                //         checked = false;
                //     }
                // }
                if (!self.unitsVolume) {
                    self.unitsVolumeError = "Please enter units volume";
                    if (checked) {
                        checked = false;
                    }
                } else {
                    if (self.unitsVolume < 1 || self.unitsVolume > 100) {
                        self.unitsVolumeError = "Please enter units volume greater than 1 and less than 100.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.makeAndModel) {
                    self.makeAndModelError = "Please enter make and model";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.makeAndModel.length > 50) {
                    self.makeAndModelError = "Please enter make and model less than 50.";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.requestType == 'vehicle_allocation') {
                    if (self.unitIdsArr.length > 0) {
                        for (let i = 0; i < self.unitIdsArr.length; i++) {
                            if (self.unitIdsArr[i].id == '0' || !self.unitIdsArr[i].id) {
                                self.unitIdError = "Please enter unit id greater than 0 for all units.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (self.unitIdsArr[i].id.length > 6) {
                                self.unitIdError = "Please enter unit id less than 6 digits for all units.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (!self.unitIdsArr[i].estimatedDateOfDelivery) {
                                self.estimatedDateOfDeliveryError = "Please select estimated date of delivery for all unit ids.";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    } else {
                        self.unitIdError = "Please enter unit id for all units.";
                        self.estimatedDateOfDeliveryError = "Please select estimated date of delivery for all unit ids.";
                        if (checked) {
                            checked = false;
                        }
                    }
                } else if (self.requestType == 'both') {
                    let count = self.unitIdsArr.filter(x => x.id == 0).length;
                    let estimatedDateOfDeliveryCount = self.unitIdsArr.filter(x => !x.estimatedDateOfDelivery).length;
                    if (count == self.unitIdsArr.length) {
                        self.unitIdError = "Please enter at least one unit id greater than 0.";
                        if (checked) {
                            checked = false;
                        }
                    }
                    if (estimatedDateOfDeliveryCount == self.unitIdsArr.length) {
                        self.estimatedDateOfDeliveryError = "Please select at least one estimated date of delivery.";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (self.requestType == 'customer_order' || self.requestType == 'both') {
                    if (!self.filesType.includes('order_form'))  {
                        self.orderFormFileUploadError = "Please upload file for truck order form";
                        if (checked) {
                            checked = false;
                        }
                    }
                }
                if (!self.confirmSmApprovalSubmission) {
                    self.confirmSmApprovalSubmissionError = "Please confirm";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            submit (flag) {
                if (self.requestType == 'vehicle_allocation' || self.requestType == 'both') {
                    var checkvalidation = false
                    setTimeout(() => {
                        if (self.checkForm()) {
                            var config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("api_token")
                                }
                            };
                            var data = {
                                'units_volume': self.unitsVolume,
                                'unit_id': self.unitIdsArr
                            };
                            HTTP.post("cso-request/check-unit-ids", data, config)
                                .then(function (response) {
                                    // console.log('then ', response.data.content)
                                    for (let i = 0; i < self.unitIdsArr.length; i++) {
                                        for (let j = 0; j < response.data.content.all.length; j++) {
                                            if (self.unitIdsArr[i].id == response.data.content.all[j].id) {
                                                self.unitIdsArr[i].status = response.data.content.all[j].status
                                            }
                                        }
                                    }
                                    if (response.data.content.duplicate.length > 0) {
                                        self.unitIdError = "Above unit is already been requested, please use a different id"
                                    } else {
                                        self.unitIdError = "";
                                        self.estimatedDateOfDeliveryError = "";
                                        Object.keys(self.unitIdsArr).forEach(key => {
                                            self.unitIdsArr[key].status = null;
                                        });
                                        checkvalidation = true;
                                    }
                                    if (checkvalidation) {
                                        self.addUpdateCSORequest()
                                    }
                                })
                                .catch(function (err) {
                                });
                        }
                    }, 200);
                } else {
                    setTimeout(() => {
                        if (self.checkForm()) {
                            self.addUpdateCSORequest()
                        }
                    }, 200);
                }
            },
            addUpdateCSORequest () {
                setTimeout(() => {
                    let unitId = []
                    let estimatedDateOfDelivery = []
                    if (self.requestType == 'vehicle_allocation' || self.requestType == 'both') {
                        unitId = self.unitIdsArr.map(value => value.id);
                        estimatedDateOfDelivery = self.unitIdsArr.map(value => value.estimatedDateOfDelivery);
                    }
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    var data = {
                        'salesperson_id': self.salespersonId.value,
                        'location_id': self.locationId.value,
                        'customer_id': self.customerId,
                        // 'estimated_date_of_delivery': self.estimatedDateOfDelivery.split("/").reverse().join("/"),
                        'estimated_date_of_delivery': estimatedDateOfDelivery,
                        'units_volume': self.unitsVolume,
                        'make_and_model': self.makeAndModel,
                        'request_type': self.requestType,
                        'unit_id': unitId,
                        'notes': self.notes,
                        'proof_image_path': self.proofImagesPaths,
                        'proof_image_size': self.proofImagesSize,
                        'proof_image_type': self.proofImagesType,
                        'files_type': self.filesType,
                        'sales_manager_email': self.salesManagerEmail,
                        'worksheet_has_acceptable_gp': self.isWorksheetHasAcceptableGp,
                        'signed_contract_of_sale': self.signedContractOfSale,
                        'cc_email': self.ccEmailArr,
                        'confirm_sm_approval_submission': self.confirmSmApprovalSubmission
                    };
                    if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                    } else {
                        self.buttonNames.save = "Saving ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                    }
                    HTTP.post("/csorequest/add/update/vtc", data, config)
                        .then(function (response) {
                            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                            } else {
                                self.buttonNames.save = "Save";
                            }
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message, 'success', false, 3);
                                self.clearDataOnSaveAndContinue();
                                setTimeout(function () {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    self.buttonBack();
                                }, 3000);
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            if (self.$route.params.id != 0 && self.$route.params.id != '' && typeof self.$route.params.id != 'undefined') {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            } else {
                                self.buttonNames.save = "Save";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            }
                            self.catchHandler(err, function () {});
                        });
                }, 200);
            },
            clearDataOnSaveAndContinue: function () {
                // self.selectDefaultUser();
                // self.vendorName = "";

                // for (let i = (self.inputs.length - 1); i >= 0; i--) {
                //     self.deleteRow(i);
                // }
                // self.workCompleteId = {text : "", value : ""};
                // self.descriptionOfPO = "";
                // self.selectDefaultSalesManager();

                // self.imagesPaths = [];
                //     self.imagesType = [];
                //     self.imagesSize = [];
                //     self.isPOAmountOver = false;
                //     self.removeAllFiles();
            },
            uploadDocument (file, response) {
                self.proofImagesPaths.push(response.content.file_path);
                self.proofImagesType.push(file.type);
                self.proofImagesSize.push(file.size);
                self.filesType.push(response.content.type);
                if (self.filesType.includes('worksheet'))  {
                    self.worksheetFileUploadError = "";
                }
                if (self.filesType.includes('signed_contract'))  {
                    self.signedContractFileUploadError = "";
                }
                if (self.filesType.includes('proof'))  {
                    self.proofFileUploadError = "";
                }
                if (self.filesType.includes('order_form'))  {
                    self.orderFormFileUploadError = "";
                }
            },
            removedSelectedFile (file, xhr, error) {
                self.isDisable = false
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                // this.$refs.dropzone.setOption('maxFiles', null);
                
                var removeFile = "";
                // if (this.$refs.proof_dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                // }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.proofImagesPaths.length - 1; i >= 0; i--) {
                        if (self.proofImagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.proofImagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content.file_path;
                    self.removeFileFromServer(removeFile);
                    // removeFile = removeFile.file_path
                }
                for (var i = self.proofImagesPaths.length - 1; i >= 0; i--) {
                    if (self.proofImagesPaths[i] === removeFile) {
                        self.proofImagesPaths.splice(i, 1);
                        self.proofImagesType.splice(i, 1);
                        self.proofImagesSize.splice(i, 1);
                        self.filesType.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileAdd (file, xhr, error) {
                if (this.$refs.proof_dropzone.dropzone.options.maxFiles) {
                }
            },
            progressOfUload (totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                } else {
                }
            },
            selectDefaultSalesPerson : function () {
                let userObj = JSON.parse(localStorage.getItem("user_obj"));
                let firstName = "";
                let lastName = "";
                let salespersonId = 0;
                let fullName = "";
                if (userObj != null && userObj != "" && typeof userObj != "undefined") {
                    firstName = userObj['first_name'];
                    lastName = userObj['last_name'];
                    salespersonId = userObj['id'];
                    fullName = firstName + " " + lastName;

                    self.salespersonId = {text : fullName , value : salespersonId};
                }
            },
            onSearchUser : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadUserTypeCallback, loading, search);
                }
            },
            loadMasterDataCallback: function (callbackData) {
                self.isUserRecordNotFound = false;
                self.salespersonArr = callbackData.User;
                if (self.salespersonArr.lenght == 0) {
                    self.isUserRecordNotFound = true;
                }
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            buttonBack() {
                self.$router.go(-1);
                // self.$router.push({name: "ListCSORequest"});
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.isLocationRecordNotFound = false;
                self.locationArr = callbackData.Location;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
            },
            updateLocation () {
                self.salesManagerEmail = null
                self.salesManagerEmailArr = []
                if (self.locationId && self.locationId.value) {
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        },
                        params: {
                            location_id: self.locationId.value
                        }
                    };
                    HTTP.get('/get-sm-emails', config)
                        .then(response => {
                            self.isSMEmailRecordNotFound = false;
                            self.salesManagerEmailArr = response.data.data.toEmails
                            self.ccEmailArr = response.data.data.ccEmails
                            if(self.salesManagerEmailArr.length == 0) {
                                self.isSMEmailRecordNotFound = true;
                                self.locationError = "There is no available sales admin email. Please select another receiving dealership";
                            }
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
                }
            },
            inputUnitsVolume () {
                if (!self.unitsVolume) {
                    self.unitsVolumeError = "Please enter units volume";
                    self.unitIdsArr.push(Vue.util.extend({}, self.unitIds));
                } else if (self.unitsVolume < 1 || self.unitsVolume > 100) {
                    self.unitsVolumeError = "Please enter units volume greater than 1 and less than 100.";
                } else {
                    self.unitsVolumeError = "";
                    self.unitIdError = "";
                    self.estimatedDateOfDeliveryError = "";
                    self.unitIdsArr = []
                    for (let i = 0; i < self.unitsVolume; i++) {
                        self.unitIdsArr.push(Vue.util.extend({}, self.unitIds));
                    }
                }
            },
            updatecheckbox () {
                if (self.confirmSmApprovalSubmission) {
                    self.confirmSmApprovalSubmissionError = null
                } else {
                    self.confirmSmApprovalSubmissionError = 'Please confirm'
                }
            },
            preventNonNumeric(event) {
                if (
                    event.key.toLowerCase() === 'e' || event.key === 'E' ||
                    event.key === '-' || event.key === '+'
                ) {
                    event.preventDefault();
                }
            }
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>