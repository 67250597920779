<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_image_inventory_master')">
            <div class="container-fluid xyz">
                <div class="admin-page-content">
                    <div class="cards-container">
                        <template v-if="imageList && imageList.length > 0">
                            <!-- <draggable v-model="imageList" v-on:change="changePosition()" class="drag-view"> -->
                            <draggable v-model="imageList" v-on:input="changePosition()" class="drag-view row">
                                <template v-for="(img, index) in imageList">
                                    <div :key="'main-div ' + index" class="col-md-4 text-alignment-center mb-5">
                                        <div class="cards-block">
                                            <div class="card">
                                                <img :src="img.image" :key="'image ' + index">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-0">
                                                        <template v-if="index == 0">
                                                            <span style="/*display:none;*/">{{staticMessages.main_image}} - </span>{{index + 1}} 
                                                            <template v-if="img.is_360_image">
                                                                <span class="badge badge-primary badge-pill bg-color-0771b4 dragable-image-360-tag">
                                                                    {{staticMessages.image_360}}
                                                                </span>   
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            {{index + 1}} 
                                                            <template v-if="img.is_360_image">
                                                                <span class="badge badge-primary badge-pill bg-color-0771b4 dragable-image-360-tag">
                                                                    {{staticMessages.image_360}}
                                                                </span>   
                                                            </template>
                                                        </template>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </template>
                        <template v-else>
                            No Image found!!!
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import $ from 'jquery';
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import FilterComponent from "../FilterComponent/FilterComponent";
    import NoAccessPage from "../NoAccessPage/NoAccessPage";
    import draggable from 'vuedraggable';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect)

    export default {
        name: 'DraggableAssetImage',
        mixins: [commonMixins],
        components: {
            draggable,
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
        },
        data () {
            return {
                imageList: [],
            }
        },
        computed: {
            dragOptions: function () {
                return {
                    staticMessages: "",
                    staticInputLabelMessages: "",
                    group: 'images',
                    handle: ".drag-handle",
                }
            },
        },
        beforeMount() {
            self = this;
        },
        props: {
            permissionNameArray: "",
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        watch: {
        },
        created : function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            self.getInventoryImages();
        },
        methods: {
            getInventoryImages: function() {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    asset_id : self.$route.params.id,
                }
                self.showLoader();
                HTTP.post("/asset/images", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        if (data.status == 200) {
                            self.imageList = data.data.content;
                        }
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            },
            changePosition: function() {
                /* console.log(JSON.stringify(self.imageList)); */
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    imageList : self.imageList,
                    asset_id : self.$route.params.id,
                }
                HTTP.post("/asset/images/position/change", fields, config)
                    .then(function (data) {
                        self.hideLoader();
                        self.getInventoryImages();
                        self.$emit('updateDisplayData',10);
                    })
                    .catch(function (error) {
                        self.hideLoader();
                        self.catchHandler(error, function () {});
                    })
            }
        }
    }
</script>