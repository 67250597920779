<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_close_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                            <tr>
                                <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                <th class="">{{staticInputLabelMessages.close_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('statusIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.hold_status_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('holdStatusIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.hold_customer_name_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('holdCustomerInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sale_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('saleDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.payment_date_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('paymentDateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sale_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('saleTypeIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.payment_type}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('paymentTypeIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sale_price_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('salePriceInput')"></i></th>
                                <!-- <th class="">{{staticInputLabelMessages.funding_source_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('fundingSourceIdInput')"></i></th> -->
                                <th class="">{{staticInputLabelMessages.sold_by_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('soldByInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.sold_to_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('soldToInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.referral_source}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('referralSourceIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.commission}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('commissionTypeIdInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.total_commission_rate_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('commissionRateInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.lot_fee_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('lotFeeInput')"></i></th>
                                <th class="">{{staticInputLabelMessages.notes_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('note')"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(asset, index) in asset.content" :key="index">
                                <!--ID-->
                                <!-- <td class="">
                                    <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                    <span class="form-control">{{asset.asset_id}}</span>
                                </td> -->
                                <!--Vin-->
                                <td class="">
                                    <span class="form-control">{{vinNoInput[asset.asset_id]}}</span>
                                </td>
                                <!--Status-->
                                <td class="">
                                    <v-select style="min-width: 100px;" v-model="statusIdInput[asset.asset_id]"
                                              @change="changedValue('status ' + asset.asset_id)"
                                              @input="statusErrorMsg = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="statusArrInput"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{statusErrorMsg[asset.asset_id]}}</span>
                                </td>
                                <!-- Hold Status-->
                                <td class="">
                                    <v-select style="min-width: 160px;" v-model="holdStatusIdInput[asset.asset_id]"
                                              @change="changedValue('holdstatus ' + asset.asset_id)"
                                              @input="holdStatusErrorMsg = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="holdStatusArrInput"
                                              :placeholder="staticMessages.hold_status_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{holdStatusErrorMsg[asset.asset_id]}}</span>
                                </td>
                                
                                <!--Hold Customer Name-->
                                <td class="">
                                    <input style="min-width: 150px;" id="holdCustomerInput" v-model="holdCustomerInput[asset.asset_id]" class="form-control"/>
                                    <span class="help is-danger">{{holdCustomerMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sale Date-->
                                <td class="">
                                    <date-picker style="min-width: 120px;"
                                            v-model="saleDateInput[asset.asset_id]"
                                            :config="options"
                                            :wrap="true"
                                            autocomplete="off"
                                            class="form-control"
                                            :placeholder="staticMessages.sale_date_place_holder"
                                            name="activityDateTime"
                                            @input="changeSaleDate()"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{saleDateErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Payment Date-->
                                <td class="">
                                    <date-picker style="min-width: 120px;"
                                            v-model="paymentDateInput[asset.asset_id]"
                                            :config="options"
                                            :wrap="true"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.payment_date_place_holder"
                                            name="paymentDateInput"
                                            @input="changeSaleDate()"
                                    >
                                    </date-picker>
                                    <span class="help is-danger">{{paymentDateErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sale Type-->
                                <td class="">
                                    <v-select
                                            style="min-width: 150px;"
                                            v-model="saleTypeIdInput[asset.asset_id]"
                                            @change="changedValue('sale_type ' + asset.asset_id)"
                                            @input="saleTypeErrorMsg = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="saleTypeArrInput"
                                            :placeholder="staticMessages.sale_type_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{saleTypeErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Payment Type-->
                                <td class="">
                                    <v-select
                                            style="min-width: 150px;"
                                            v-model="paymentTypeIdInput[asset.asset_id]"
                                            @change="changedValue('payment_type ' + asset.asset_id)"
                                            @input="paymentTypeErrorMsg = ''"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="paymentTypeArrInput"
                                            :placeholder="staticMessages.payment_type_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{paymentTypeErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sale Price-->
                                <td class="">
                                    <input style="min-width: 120px;" id="salePriceInput" v-model="salePriceInput[asset.asset_id]" class="form-control"/>
                                    <span class="help is-danger">{{salePriceErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!-- Funding Source -->
                                <!-- <td class="">
                                    <v-select style="min-width: 185px;" v-model="fundingSourceIdInput[asset.asset_id]"
                                              @change="changedValue('funding_source ' + asset.asset_id)"
                                              @input="fundingSourceErrorMsg = ''"
                                              name="fundingSource"
                                              id="fundingSource"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="fundingSourceArrInput"
                                              :placeholder="staticMessages.select_funding_source">
                                    </v-select>
                                    <span class="help is-danger">{{fundingSourceErrorMsg[asset.asset_id]}}</span>
                                </td> -->

                                <!--Sold By-->
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="soldByInput[asset.asset_id]"
                                              @change="changedValue('sold_by ' + asset.asset_id)"
                                              @search="onSearchSoldBy"
                                              name="customPerPage"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="soldByArrInput"
                                              :placeholder="staticMessages.managed_by_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isSoldByRecordNotFound">
                                            {{staticMessages.managed_by_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{soldByErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Sold To-->
                                <td class="">
                                    <v-select style="min-width: 165px;" v-model="soldToInput[asset.asset_id]"
                                              @change="changedValue('sold_to ' + asset.asset_id)"
                                              @search="onSearchSoldTo"
                                              name="customPerPage"
                                              id="customPerPag"
                                              label="text"
                                              :clearable="true"
                                              :searchable="true"
                                              :filterable="false"
                                              :options="soldToArrInput"
                                              :placeholder="staticMessages.acquire_from_place_holder">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isSoldToRecordNotFound">
                                            {{staticMessages.acquire_from_search}}
                                        </template>
                                        <template v-else>
                                            {{staticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                    </v-select>
                                    <!--<div class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{soldToErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Referral Source-->
                                <td class="">
                                    <v-select  style="min-width: 185px;"
                                            v-model="referralSourceIdInput[asset.asset_id]"
                                            @change="changedValue('referral_source ' + asset.asset_id)"
                                            @input="referralSourceErrorMsg = ''"
                                            name="referralSourceId"
                                            id="referralSourceId"
                                            label="text"
                                            :clearable="true"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="referralSourceArrInput"
                                            :placeholder="staticMessages.select_referral_source">
                                    </v-select>
                                    <!--<div v-show="isCloseTypeActive" class="requiredstar">*</div>-->
                                    <span class="help is-danger">{{referralSourceErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Commission-->
                                <td class="">
                                    <v-select style="min-width: 195px;"
                                            v-model="commissionTypeIdInput[asset.asset_id]"
                                            @change="changedValue('commission ' + asset.asset_id)"
                                            @input="commissionTypeErrorMsg = ''"
                                            name="commissionTypeId"
                                            id="commissionTypeId"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="commissionTypeArrInput"
                                            :placeholder="staticMessages.commission_type_place_holder">
                                    </v-select>
                                    <span class="help is-danger">{{commissionTypeErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Total Commission Rate-->
                                <td class="">
                                    <input style="min-width: 156px;" id="commissionRateInput" v-model="commissionRateInput[asset.asset_id]" class="form-control"/>
                                    <span class="help is-danger">{{commissionRateErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <!--Lot Fee-->
                                <td class="">
                                    <input style="min-width: 120px;" id="lotFeeInput" v-model="lotFeeInput[asset.asset_id]" class="form-control"/>
                                    <span class="help is-danger">{{lotFeeErrorMsg[asset.asset_id]}}</span>
                                </td>

                                <td class="">
                                    <input style="min-width: 120px;" id="note" v-model="note[asset.asset_id]" class="form-control"/>
                                </td>

                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],

                statusIdInput : [],
                statusErrorMsg : [],
                statusArrInput : [],

                holdStatusIdInput : [],
                holdStatusErrorMsg : [],
                holdStatusArrInput : [],

                holdCustomerInput : [],
                holdCustomerMsg : [],

                saleDateInput : [],
                saleDateErrorMsg : [],

                saleTypeIdInput : [],
                saleTypeArrInput : [],
                saleTypeErrorMsg : [],

                paymentTypeIdInput : [],
                paymentTypeArrInput : [
                    {text: 'Cash', value: 'cash'},
                    {text: 'Our Financing', value: 'our financing'},
                ],
                paymentTypeErrorMsg : [],

                salePriceInput : [],
                salePriceErrorMsg : [],

                soldByInput : [],
                soldByArrInput : [],
                soldByErrorMsg : [],
                isSoldByRecordNotFound : false,

                soldToInput : [],
                soldToArrInput : [],
                isSoldToRecordNotFound : false,
                soldToErrorMsg : [],

                fundingSourceIdInput : [],
                fundingSourceErrorMsg : [],
                fundingSourceArrInput : [],

                referralSourceIdInput : [],
                referralSourceErrorMsg : [],
                referralSourceArrInput :[],

                paymentDateInput : [],
                paymentDateErrorMsg : [],

                commissionTypeIdInput : [],
                commissionTypeErrorMsg : [],
                commissionTypeArrInput : [],

                commissionRateInput : [],
                commissionRateErrorMsg : [],

                lotFeeInput : [],
                lotFeeErrorMsg : [],
                note : [],

                // new
                managedByIdInput : [],
                managedByErrorMsg : [],
                managedByArrInput : [],
                isManagedByRecordNotFound : false,

                acquireByIdInput : [],
                isAcquireByRecordNotFound : false,
                acquireByArrInput : [],
                acquireByErrorMsg : [],

                acquireFromIdInput : [],
                acquireFromErrorMsg : [],
                acquireFromArrInput : [],
                isAcquireFromRecordNotFound : false,

                floorPlanVendorErrorMsg : [],
                floorPlanVendorIdInput : [],
                floorPlanVendorArrInput : [],
                isFloorPlanVendorRecordNotFound : false,

                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    // disabledTimeIntervals: false,
                },

                activetab: 1,
                isAddFrom: false,
                permissionNameArray: ['can_close_inventory_master'],
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";
            

                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.commissionRateInput[self.asset.content[i].asset_id] = self.asset.content[i].total_commission_rate == 0 ? 0 : self.asset.content[i].total_commission_rate;
                        self.lotFeeInput[self.asset.content[i].asset_id] = self.asset.content[i].lot_fee == 0 ? null : self.asset.content[i].lot_fee;
                        if (self.asset.content[i].hold_customer_name != null && typeof self.asset.content[i].hold_customer_name != "undefined" && self.asset.content[i].hold_customer_name != '') {
                            self.holdCustomerInput[self.asset.content[i].asset_id] = self.asset.content[i].hold_customer_name;
                        }
                        if (self.asset.content[i].hold_status_id != null && typeof self.asset.content[i].hold_status_id != "undefined" && self.asset.content[i].hold_status_id != '') {
                            self.holdStatusIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].hold_status_name), value: self.asset.content[i].hold_status_id};
                        }
                        if (self.asset.content[i].price_sold_usd != null && typeof self.asset.content[i].price_sold_usd != "undefined" && self.asset.content[i].price_sold_usd != '') {
                            self.salePriceInput[self.asset.content[i].asset_id] = self.asset.content[i].price_sold_usd;
                        }
                        if (self.asset.content[i].status_id != null && typeof self.asset.content[i].status_id != "undefined" && self.asset.content[i].status_id != '') {
                            self.statusIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].status_name), value: self.asset.content[i].status_id};
                        }
                        if (self.asset.content[i].sale_date != null && typeof self.asset.content[i].sale_date != "undefined" && self.asset.content[i].sale_date != '') {
                            self.saleDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].sale_date);
                        }
                        if (self.asset.content[i].sold_by_id != null && typeof self.asset.content[i].sold_by_id != "undefined" && self.asset.content[i].sold_by_id != '') {
                            self.soldByInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].sold_by_name), value: self.asset.content[i].sold_by_id};
                        }
                        if (self.asset.content[i].sold_to_id != null && typeof self.asset.content[i].sold_to_id != "undefined" && self.asset.content[i].sold_to_id != '') {
                            self.soldToInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].sold_to_name), value: self.asset.content[i].sold_to_id};
                        }
                        if (self.asset.content[i].referral_source_id != null && typeof self.asset.content[i].referral_source_id != "undefined" && self.asset.content[i].referral_source_id != '') {
                            self.referralSourceIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].referral_source_name), value: self.asset.content[i].referral_source_id};
                        }
                        if (self.asset.content[i].sale_type_id != null && typeof self.asset.content[i].sale_type_id != "undefined" && self.asset.content[i].sale_type_id != '') {
                            self.saleTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].sale_type_name), value: self.asset.content[i].sale_type_id};
                        }
                        if (self.asset.content[i].payment_type != null && typeof self.asset.content[i].payment_type != "undefined" && self.asset.content[i].payment_type != '') {
                            self.paymentTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].payment_type_name), value: self.asset.content[i].payment_type};
                        }
                        if (self.asset.content[i].commission_type_id != null && typeof self.asset.content[i].commission_type_id != "undefined" && self.asset.content[i].commission_type_id != '') {
                            self.commissionTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].commission_type_name), value: self.asset.content[i].commission_type_id};
                        }
                        if (self.asset.content[i].payment_date != null && typeof self.asset.content[i].payment_date != "undefined" && self.asset.content[i].payment_date != '') {
                            self.paymentDateInput[self.asset.content[i].asset_id] = self.datePickerDateFormat(self.asset.content[i].payment_date);
                        }
                        if (self.asset.content[i].asset_note != null && typeof self.asset.content[i].asset_note != "undefined" && self.asset.content[i].asset_note != '') {
                            self.note[self.asset.content[i].asset_id] = self.asset.content[i].asset_note;
                        } else {
                            self.note[self.asset.content[i].asset_id] = "";
                        }


                    }
                });
            self.loadAllData(['Status', 'HoldStatus', 'SaleType', 'ReferralSource', 'CommissionType', 'FundingSource'], false, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Status', false, self.loadStatusCallback, "undefined", '');
            self.loadAllData('HoldStatus', false, self.loadHoldStatusCallback, "undefined", '');
            self.loadAllData('SaleType', false, self.loadSaleTypeCallback, "undefined", ''); */
            /* self.loadAllData('FundingSource', true, self.loadFundingSourceCallback, "undefined", ''); */
            /* self.loadAllData('ReferralSource', true, self.loadReferralSourceCallback, "undefined", '');
            self.loadAllData('CommissionType', true, self.loadCommissionTypeCallback, "undefined", ''); */
            
            self.$nextTick(function () {
                $(".table-listing").height(($(".table-listing").height() + 124));
            });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
        },
        methods: {
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'statusIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.statusIdInput[self.asset.content[i].asset_id];
                                    self.statusIdInput = [];
                                } 
                                self.statusIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'holdStatusIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.holdStatusIdInput[self.asset.content[i].asset_id];
                                    self.holdStatusIdInput = [];
                                } 
                                self.holdStatusIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'saleDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.saleDateInput[self.asset.content[i].asset_id];
                                    self.saleDateInput = [];
                                } 
                                self.saleDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'paymentDateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.paymentDateInput[self.asset.content[i].asset_id];
                                    self.paymentDateInput = [];
                                } 
                                self.paymentDateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'saleTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.saleTypeIdInput[self.asset.content[i].asset_id];
                                    self.saleTypeIdInput = [];
                                } 
                                self.saleTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'paymentTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.paymentTypeIdInput[self.asset.content[i].asset_id];
                                    self.paymentTypeIdInput = [];
                                } 
                                self.paymentTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'salePriceInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.salePriceInput[self.asset.content[i].asset_id];
                                    self.salePriceInput = [];
                                } 
                                self.salePriceInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'fundingSourceIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.fundingSourceIdInput[self.asset.content[i].asset_id];
                                    self.fundingSourceIdInput = [];
                                } 
                                self.fundingSourceIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'soldByInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.soldByInput[self.asset.content[i].asset_id];
                                    self.soldByInput = [];
                                } 
                                self.soldByInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'soldToInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.soldToInput[self.asset.content[i].asset_id];
                                    self.soldToInput = [];
                                } 
                                self.soldToInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'referralSourceIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.referralSourceIdInput[self.asset.content[i].asset_id];
                                    self.referralSourceIdInput = [];
                                } 
                                self.referralSourceIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'commissionTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.commissionTypeIdInput[self.asset.content[i].asset_id];
                                    self.commissionTypeIdInput = [];
                                } 
                                self.commissionTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'commissionRateInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.commissionRateInput[self.asset.content[i].asset_id];
                                    self.commissionRateInput = [];
                                } 
                                self.commissionRateInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'lotFeeInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.lotFeeInput[self.asset.content[i].asset_id];
                                    self.lotFeeInput = [];
                                } 
                                self.lotFeeInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'note':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.note[self.asset.content[i].asset_id];
                                    self.note = [];
                                } 
                                self.note[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'holdCustomerInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.holdCustomerInput[self.asset.content[i].asset_id];
                                    self.holdCustomerInput = [];
                                } 
                                self.holdCustomerInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            changePaymentDate : function() {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.paymentDateErrorMsg, i, "");
                    }
                });
            },
            changeSaleDate : function () {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.saleDateErrorMsg, i, "");
                    }
                });
            },
            loadMasterDataCallback: function (callbackData) {
                self.statusArrInput = callbackData.Status;
                self.holdStatusArrInput = callbackData.HoldStatus;
                self.saleTypeArrInput = callbackData.SaleType;
                self.commissionTypeArrInput = callbackData.CommissionType;
                self.referralSourceArrInput = callbackData.ReferralSource;
                self.fundingSourceArrInput = callbackData.FundingSource;
            },
            /* loadHoldStatusCallback: function (callbackData) {
                self.holdStatusArrInput = callbackData.HoldStatus;
            },
            loadCommissionTypeCallback: function (callbackData) {
                self.commissionTypeArrInput = callbackData.CommissionType;
            },
            loadReferralSourceCallback: function (callbackData) {
                self.referralSourceArrInput = callbackData.ReferralSource;
            },
            loadFundingSourceCallback: function (callbackData) {
                self.fundingSourceArrInput = callbackData.FundingSource;
            },
            loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArrInput = callbackData.SaleType;
            },
            loadStatusCallback: function (callbackData) {
                self.statusArrInput = callbackData.Status;
            }, */
            // End new
            onSearchFloorPlanVendor : function () {

            },
            onSearchSoldTo : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadSoldToCallback, loading, search);
                }
            },
            loadSoldToCallback : function (callbackData) {
                self.isSoldToRecordNotFound = false;
                self.soldToArrInput = callbackData.Contact;
                if(self.soldToArrInput.length == 0) {
                    self.isSoldToRecordNotFound = true;
                }
            },
            onSearchSoldBy : function (search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSoldByCallback, loading, search);
                }
            },
            loadSoldByCallback : function (callbackData) {
                self.isSoldByRecordNotFound = false;
                self.soldByArrInput = callbackData.User;
                if(self.soldByArrInput.length == 0) {
                    self.isSoldByRecordNotFound = true;
                }
            },
            onSearchAcquireFrom(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isAcquireFromRecordNotFound = false;
                self.acquireFromArrInput = callbackData.Contact;
                if(self.acquireFromArrInput.length == 0) {
                    self.isAcquireFromRecordNotFound = true;
                }
            },
            onSearchAcquireBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadAcquireByCallback, loading, search);
                }
            },
            loadAcquireByCallback: function (callbackData) {
                self.isAcquireByRecordNotFound = false;
                self.acquireByArrInput = callbackData.User;
                if(self.acquireByArrInput.length == 0) {
                    self.isAcquireByRecordNotFound = true;
                }
            },
            onSearchManagedBy(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadManagedByCallback, loading, search);
                }
            },
            loadManagedByCallback: function (callbackData) {
                self.isManagedByRecordNotFound = false;
                self.managedByArrInput = callbackData.User;
                if(self.managedByArrInput.length == 0) {
                    self.isManagedByRecordNotFound = true;
                }
            },
            changeActivityDateTime: function () {
                $.each(self.assetIdInput, function (i) {
                    if (self.assetIdInput[i] != null) {
                        Vue.set(self.warrantyServiceDateErrorMsg, i, "");
                    }
                });
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArrInput = callbackData;
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'note' : self.note[self.asset.content[i].asset_id],
                            'status_id' : self.statusIdInput[self.asset.content[i].asset_id],
                            'hold_status_id' : self.holdStatusIdInput[self.asset.content[i].asset_id],
                            'hold_customer_name' : self.holdCustomerInput[self.asset.content[i].asset_id],
                            'sale_date' : self.saleDateInput[self.asset.content[i].asset_id],
                            'payment_date' : self.paymentDateInput[self.asset.content[i].asset_id],
                            'sale_type' : self.saleTypeIdInput[self.asset.content[i].asset_id],
                            'payment_type' : self.paymentTypeIdInput[self.asset.content[i].asset_id],
                            'sale_price' : self.salePriceInput[self.asset.content[i].asset_id],
                            'sold_by_id' : self.soldByInput[self.asset.content[i].asset_id],
                            'sold_to_id' : self.soldToInput[self.asset.content[i].asset_id],
                            'referral_source' : self.referralSourceIdInput[self.asset.content[i].asset_id],
                            'commission_id' : self.commissionTypeIdInput[self.asset.content[i].asset_id],
                            'total_commission_rate' : self.commissionRateInput[self.asset.content[i].asset_id],
                            'lot_fee' : self.lotFeeInput[self.asset.content[i].asset_id],
                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },


            changedValue : function (value) {
                let stringValue = value;
                stringValue = stringValue.split(" ");
                let flag = stringValue[0];
                let id = stringValue[1];
                $.each(self.assetIdInput, function (i) {
                    switch (value) {
                        case "status" :
                            if (i == id) {
                                Vue.set(self.statusErrorMsg, i, "");
                            }
                            break;
                        case "holdstatus" :
                            if (i == id) {
                                Vue.set(self.holdStatusErrorMsg, i, "");
                            }
                            break;
                        case "sale_type" :
                            if (i == id) {
                                Vue.set(self.saleTypeErrorMsg, i, "");
                            }
                            break;
                        case "payment_type" :
                            if (i == id) {
                                Vue.set(self.saleTypeErrorMsg, i, "");
                            }
                            break;
                        case "funding_source" :
                            if (i == id) {
                                Vue.set(self.fundingSourceErrorMsg, i, "");
                            }
                            break;
                        case "sold_by" :
                            if (i == id) {
                                Vue.set(self.soldByErrorMsg, i, "");
                            }
                            break;
                        case "sold_to" :
                            if (i == id) {
                                Vue.set(self.soldToErrorMsg, i, "");
                            }
                            break;
                        case "referral_source" :
                            if (i == id) {
                                Vue.set(self.referralSourceErrorMsg, i, "");
                            }
                            break;
                        case "commission" :
                            if (i == id) {
                                Vue.set(self.commissionTypeErrorMsg, i, "");
                            }
                            break;
                    };
                });
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    /* Vue.set(self.statusErrorMsg, i, "");
                    Vue.set(self.saleTypeErrorMsg, i, "");
                    Vue.set(self.saleDateErrorMsg, i, "");
                    Vue.set(self.salePriceErrorMsg, i, "");
                    Vue.set(self.soldToErrorMsg, i, "");
                    Vue.set(self.paymentTypeErrorMsg, i, "");
                    Vue.set(self.soldByErrorMsg, i, "");
                    Vue.set(self.commissionRateErrorMsg, i, "");
                    Vue.set(self.holdStatusErrorMsg, i, "");
                    Vue.set(self.commissionTypeErrorMsg, i, ""); */
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        /* console.log(self.assetIdInput[i]); */
                        if (typeof(self.statusIdInput[i])  == 'undefined' || self.statusIdInput[i] == null || self.statusIdInput[i].value == '') {
                            Vue.set(self.statusErrorMsg, i, "Please select close type");
                            if (checked) {
                                checked = false;
                            }
                        } else {
                            if(self.statusIdInput[i].value == 2 || self.statusIdInput[i].value == 3){
                                if (typeof(self.saleTypeIdInput[i])  == 'undefined' || self.saleTypeIdInput[i] == null || self.saleTypeIdInput[i].value == '') {
                                    Vue.set(self.saleTypeErrorMsg, i, "Please select sale type");
                                    if (checked) {
                                        checked = false;
                                    }
                                }
                                if (!self.saleDateInput[i]) {
                                    Vue.set(self.saleDateErrorMsg, i, "Please enter sale date");
                                    if (checked) {
                                        checked = false;
                                    }
                                }
                                if(self.statusIdInput[i].value == 3){
                                    if (self.salePriceInput[i] != 0) {
                                        if (!self.salePriceInput[i]) {
                                            Vue.set(self.salePriceErrorMsg, i, "Please enter price sold");
                                            if (checked) {
                                                checked = false;
                                            }
                                        } else if (!self.numberFormateValidation(self.salePriceInput[i])) {
                                            Vue.set(self.salePriceErrorMsg, i, "Please enter numeric value");
                                            if (checked) {
                                                checked = false;
                                            }
                                        }
                                    }
                                    if (typeof(self.soldToInput[i])  == 'undefined' || self.soldToInput[i] == null || self.soldToInput[i].value == '') {
                                        Vue.set(self.soldToErrorMsg, i, "Please select sold to");
                                        if (checked) {
                                            checked = false;
                                        }
                                    }
                                    if (typeof(self.paymentTypeIdInput[i])  == 'undefined' || self.paymentTypeIdInput[i] == null || self.paymentTypeIdInput[i].value == '') {
                                        Vue.set(self.paymentTypeErrorMsg, i, "Please select payment type");
                                        if (checked) {
                                            checked = false;
                                        }
                                    }
                                }
                                if (typeof(self.soldByInput[i])  == 'undefined' || self.soldByInput[i] == null || self.soldByInput[i].value == '') {
                                    Vue.set(self.soldByErrorMsg, i, "Please select sold by");
                                    if (checked) {
                                        checked = false;
                                    }
                                }
                                if (self.commissionRateInput[i] != 0) {
                                    if (!self.commissionRateInput[i]) {
                                        if(self.commissionRateInput[i] != 0){
                                            Vue.set(self.commissionRateErrorMsg, i, "Please enter total commission rate");
                                            if (checked) {
                                                checked = false;
                                            }
                                        }
                                    } else if (!self.numberFormateValidation(self.commissionRateInput[i])) {
                                        Vue.set(self.commissionRateErrorMsg, i, "Please enter numeric value");
                                        if (checked) {
                                            checked = false;
                                        }
                                    }
                                }
                                if(self.statusIdInput[i].value == 2) {
                                    if (typeof(self.holdStatusIdInput[i])  == 'undefined' || self.holdStatusIdInput[i] == null || self.holdStatusIdInput[i].value == '') {
                                        Vue.set(self.holdStatusErrorMsg, i, "Please select hold status");
                                        if (checked) {
                                            checked = false;
                                        }
                                    }
                                }
                                if(self.statusIdInput[i].value == 3) {
                                    if (typeof(self.commissionTypeIdInput[i])  == 'undefined' || self.commissionTypeIdInput[i] == null || self.commissionTypeIdInput[i].value == '') {
                                        Vue.set(self.commissionTypeErrorMsg, i, "Please select commission type");
                                        if (checked) {
                                            checked = false;
                                        }
                                    }
                                }
                            }
                            /* if (typeof(self.paymentTypeIdInput[i])  == 'undefined' || self.paymentTypeIdInput[i] == null || self.paymentTypeIdInput[i].value == '') {
                                Vue.set(self.paymentTypeErrorMsg, i, "Please select payment type");
                                if (checked) {
                                    checked = false;
                                }
                            } */
                            if (self.lotFeeInput[i] && !self.numberFormateValidation(self.lotFeeInput[i])) {
                                self.lotFeeErrorMsg = "Please enter numeric value";
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                });
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/close", data, config)
                            .then(function (response) {
                                self.disableButtons.save = true;
                                self.disableButtons.cancel = true;
                                self.buttonNames.save = "Update";
                                if (response.data.status == "success") {
                                    EventBus.$emit("update-multi-edit-tab-view-data", 4);
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message, 'success', false, 3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                            // self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>