<template>
<div>
    <div id="main-header">
        <nav class="navbar navbar-default no-margin app-sidebar-nav">
            <div class="navbar-header fixed-brand">
                <a class="navbar-brand" href="javascript:void(0)"><img src="/static/images/logo.jpg" alt="Velocity Vehicle Group"></a>
            </div>
        </nav>
    </div>
    <div class="admin-content-area mt-70">
        <div class="container-fluid xyz">
            
            <template v-if="data.action == 'move-inventory-approval-request'">
                <move-inventory-approval-request-form :data="data.data" :req_from="data.req_from"></move-inventory-approval-request-form>
            </template>   
            <template v-else-if="data.action == 'record_not_found'">
                <no-access-page-vue></no-access-page-vue>
            </template>
        </div>
    </div>
</div>    
</template>
<script>

    import Vue from 'vue';
    import {HTTP} from '../../http-common';
    import {commonMixins} from '../../mixins/commonMixins';
    import MoveInventoryApprovalRequestForm from './MoveInventoryApprovalRequestForm.vue';
    import NoAccessPageVue from '../NoAccessPage/NoAccessPage.vue';

    var self;

    export default {
        components: { MoveInventoryApprovalRequestForm, NoAccessPageVue},
        name: 'HandleAccess',
        mixins: [commonMixins],
        data() {
            return {
                encryptedString: '',
                data: {
                    action: '',
                    data: ''
                }
            }
        },
        watch: {
            
        },
        created: function () {
        },
        beforeMount: function () {
            self = this;
            
        },
        mounted: function () {
            self.encryptedString = this.$route.params.encrypted_string;
            self.getHandleAccessDetails();
        },
        methods: {
            getHandleAccessDetails(){
                HTTP.get("/getinfo/"+self.encryptedString)
                    .then(function (response) {
                        if (response.status == 200) {
                            if(response.data && response.data.content) {
                                self.data = JSON.parse(JSON.stringify(response.data.content));
                            }
                        }else if(response.status == 201) {
                            self.data.action = 'record_not_found';
                        }
                    })
                    .catch(function (error) {
                        
                    })
            }
            
            
        }
    }
</script>